<div mat-dialog-content>
  <mat-card class="card" style="margin: 20px 120px">
    <form [formGroup]="repassignform" (ngSubmit)="updaterep()">
      <div class="form-row">
        <mat-form-field
          class="example-full-width"
          style="width: 40%; margin-left: 5%; margin-top: 2%"
          appearance="outline"
        >
          <mat-label>Representative</mat-label>
          <input matInput formControlName="rep" readonly />
        </mat-form-field>
        <mat-form-field
          class="example-full-width"
          style="width: 15%; margin-left: 5%; margin-top: 2%"
          appearance="outline"
        >
          <mat-label>District</mat-label>
          <input matInput formControlName="district" readonly />
        </mat-form-field>
        <mat-form-field
          class="example-full-width"
          style="width: 15%; margin-left: 2%; margin-top: 2%"
          appearance="outline"
        >
          <mat-label>Taluka</mat-label>
          <input matInput formControlName="taluka" readonly />
        </mat-form-field>
      </div>
      <div class="form-row" *ngIf="book">
        <mat-form-field
          class="example-full-width"
          style="width: 40%; margin-left: 5%"
          appearance="outline"
        >
          <mat-label>Book Store :</mat-label>
          <input
            matInput
            formControlName="bookstore"
            [matAutocomplete]="auto7"
            required
          />
          <mat-autocomplete autoActiveFirstOption #auto7="matAutocomplete">
            <mat-option
              (onSelectionChange)="selected_bookstore(option)"
              *ngFor="let option of filteredOptions7 | async"
              [value]="option['name']"
            >
              {{ option["name"] }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <div class="form-row" *ngIf="sub">
        <mat-form-field
          class="example-full-width"
          style="width: 40%; margin-left: 5%"
          appearance="outline"
        >
          <mat-label>Subscription :</mat-label>
          <input
            matInput
            formControlName="subscription"
            [matAutocomplete]="auto8"
            required
          />
          <mat-autocomplete autoActiveFirstOption #auto8="matAutocomplete">
            <mat-option
              (onSelectionChange)="selected_subs(option)"
              *ngFor="let option of filteredOptions8 | async"
              [value]="option['name']"
            >
              {{ option["name"] }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <div class="form-row" *ngIf="advt">
        <mat-form-field
          class="example-full-width"
          style="width: 40%; margin-left: 5%"
          appearance="outline"
        >
          <mat-label>Advertiesment :</mat-label>
          <input
            matInput
            formControlName="advt"
            [matAutocomplete]="auto9"
            required
          />
          <mat-autocomplete autoActiveFirstOption #auto9="matAutocomplete">
            <mat-option
              (onSelectionChange)="selected_advt(option)"
              *ngFor="let option of filteredOptions9 | async"
              [value]="option['name']"
            >
              {{ option["name"] }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <div class="form-row" *ngIf="issue">
        <mat-form-field
          class="example-full-width"
          style="width: 40%; margin-left: 5%"
          appearance="outline"
        >
          <mat-label>Issue/Granth Seller :</mat-label>
          <input
            matInput
            formControlName="issue"
            [matAutocomplete]="auto10"
            required
          />
          <mat-autocomplete autoActiveFirstOption #auto10="matAutocomplete">
            <mat-option
              (onSelectionChange)="selected_issue(option)"
              *ngFor="let option of filteredOptions10 | async"
              [value]="option['name']"
            >
              {{ option["name"] }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <div class="form-group">
        <button
          type="submit"
          class="btn btn-primary"
          style="width: 40%; margin-left: 25%"
        >
          Update
        </button>
        <button style="margin-top: 2%" mat-button mat-dialog-close>
          Close
        </button>
      </div>
    </form>
  </mat-card>
</div>
