import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DigisubservicesService } from 'src/app/services/digisubservices.service';
import * as XLSX from 'xlsx';


@Component({
  selector: 'app-creditorsreport',
  templateUrl: './creditorsreport.component.html',
  styleUrls: ['./creditorsreport.component.scss']
})

export class CreditorsreportComponent {
  creditiorsData: any;
  startdate: any;
  enddate: any;
  constructor(
    public service: DigisubservicesService,
    private dialogRef: MatDialogRef<CreditorsreportComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}
  

  ngOnInit(): void {
    

    this.startdate = this.data.startDate
    this.enddate = this.data.endDate
    this.service.getCreditorsReport(this.startdate, this.enddate).subscribe((res) => {
      // console.log(res);
      this.creditiorsData = res;
    });
  }

  exportExcel(fileName): void {
    /* table id is passed over here */
    let element = document.getElementById('print-section');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, fileName + '.xlsx');
  }

}
