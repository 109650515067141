import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-other-state-pincodewise-statement',
  templateUrl: './other-state-pincodewise-statement.component.html',
  styleUrls: ['./other-state-pincodewise-statement.component.scss'],
})
export class OtherStatePincodewiseStatementComponent implements OnInit {
  mumbaipincodewisedata;
  thanepincodewisedata;
  jilhapincodewisedata;
  otherstatepincodewisedata;
  total_pin_sub_count = 0;
  totalparcel = 0;
  totalanksankhya = 0;
  total_mumbai_pin_sub_count = 0;
  totalmumbaiparcel = 0;
  totalmumbaianksankhya = 0;
  total_thane_pin_sub_count = 0;
  totalthaneparcel = 0;
  totalthaneanksankhya = 0;
  total_dist_pin_sub_count = 0;
  totaldistparcel = 0;
  totaldistanksankhya = 0;
  filteredotherstatepincodewisedata = [];
  pincodewisesubcoutprinting = [];
  sundayankDate;
  dispatchankDate;

  constructor(
    private dialogref: MatDialogRef<OtherStatePincodewiseStatementComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.mumbaipincodewisedata = this.data['data'].filter(
      (a) => a['state'] === 'महाराष्ट्र' && a['district'] === 'मुंबई'
    );
    this.thanepincodewisedata = this.data['data'].filter(
      (a) => a['state'] === 'महाराष्ट्र' && a['district'].trim() === 'ठाणे'
    );
    let data = this.data['data']
      .filter((a) => a['state'] === 'महाराष्ट्र')
      .filter((a) => a['district'] !== 'मुंबई');
    this.jilhapincodewisedata = data.filter(
      (a) => a['district'].trim() !== 'ठाणे'
    );
    this.otherstatepincodewisedata = this.data['data'].filter(
      (a) => a['state'] !== 'महाराष्ट्र'
    );
    this.sundayankDate = this.data['sundaydate'];
    this.dispatchankDate = this.data['dispatchdate'];
    // console.log('mumbaipincodewisedata', this.mumbaipincodewisedata);
    // console.log('thanepincodewisedata', this.thanepincodewisedata);
    // console.log('jilhapincodewisedata', this.jilhapincodewisedata);
    // console.log('otherstatepincodewisedata', this.otherstatepincodewisedata);

    this.getOtherStatePincodewiseStatement();
    this.getMumbaiPincodeStatement();
    this.getThanePincodeStatement();
    this.getDistrictwiseStatement();
  }

  getDistrictwiseStatement() {
    this.total_dist_pin_sub_count = 0;
    this.totaldistparcel = 0;
    this.totaldistanksankhya = 0;
    let tempdata = this.uniqueid(
      this.jilhapincodewisedata,
      (a) => a['district']
    );

    for (let index = 0; index < Object.keys(tempdata).length; index++) {
      let arr = this.jilhapincodewisedata.filter(
        (a) => a['district'] == tempdata[index]['district']
      );

      let delivery_mode_unique = this.uniqueid(arr, (a) => a['delivery_mode']);
      // console.log({ delivery_mode_unique });

      let totalTapalCount = 0;
      let parcelval = 0;
      let anksankhya = 0;
      for (let j = 0; j < Object.keys(delivery_mode_unique).length; j++) {
        let arr1 = arr.filter(
          (a) => a['delivery_mode'] == delivery_mode_unique[j]['delivery_mode']
        );

        let hasteTapalJPO = [];
        if (arr1[0]['delivery_mode'] === 'टपाल') {
          totalTapalCount = Object.keys(arr1).length;
          // console.log({ totalTapalCount });
        } else if (arr1[0]['delivery_mode'] === 'हस्ते') {
          hasteTapalJPO = arr1.filter(
            (a) => a['dis_mode'] == 'टपाल' && a['dis_sub_mode'] == 'जी.पी.ओ'
          );

          if (hasteTapalJPO.length > 0) {
            let hasteTapalJPOUnique = this.uniqueid(
              hasteTapalJPO,
              (a) => a['distribution_rep']
            );
            // console.log({ hasteTapalJPOUnique });
            parcelval = hasteTapalJPOUnique.length;
            hasteTapalJPOUnique.map((tapal) => {
              let ank = hasteTapalJPO.filter(
                (a) => a['distribution_rep'] === tapal['distribution_rep']
              );
              // console.log({ ank });
              anksankhya += ank.length;
            });
          }
        }
      }

      this.total_dist_pin_sub_count += totalTapalCount;
      this.totaldistparcel += parcelval;
      this.totaldistanksankhya += anksankhya;
    }
  }

  getThanePincodeStatement() {
    this.total_thane_pin_sub_count = 0;
    this.totalthaneparcel = 0;
    this.totalthaneanksankhya = 0;
    let tempdata = this.uniqueid(
      this.thanepincodewisedata,
      (a) => a['pincode']
    );

    // console.log({ tempdata });
    for (let index = 0; index < Object.keys(tempdata).length; index++) {
      let arr = this.thanepincodewisedata.filter(
        (a) => a['pincode'] == tempdata[index]['pincode']
      );
      // console.log('*********Started***********');
      // console.log({ arr });

      let delivery_mode_unique = this.uniqueid(arr, (a) => a['delivery_mode']);
      // console.log({ delivery_mode_unique });

      let totalTapalCount = 0;
      let parcelval = 0;
      let anksankhya = 0;
      for (let j = 0; j < Object.keys(delivery_mode_unique).length; j++) {
        let arr1 = arr.filter(
          (a) => a['delivery_mode'] == delivery_mode_unique[j]['delivery_mode']
        );
        // console.log({ arr1 });
        let hasteTapalJPO = [];
        if (arr1[0]['delivery_mode'] === 'टपाल') {
          totalTapalCount = Object.keys(arr1).length;
          // console.log({ totalTapalCount });
        } else if (arr1[0]['delivery_mode'] === 'हस्ते') {
          // console.log('this might be hand delivery');

          hasteTapalJPO = arr1.filter(
            (a) => a['dis_mode'] == 'टपाल' && a['dis_sub_mode'] == 'जी.पी.ओ'
          );
          // console.log({ hasteTapalJPO });
          if (hasteTapalJPO.length > 0) {
            let hasteTapalJPOUnique = this.uniqueid(
              hasteTapalJPO,
              (a) => a['distribution_rep']
            );
            // console.log({ hasteTapalJPOUnique });
            parcelval = hasteTapalJPOUnique.length;
            hasteTapalJPOUnique.map((tapal) => {
              let ank = hasteTapalJPO.filter(
                (a) => a['distribution_rep'] === tapal['distribution_rep']
              );
              // console.log({ ank });
              anksankhya += ank.length;
            });
            // console.log({ anksankhya });
          }
        }
      }
      this.total_thane_pin_sub_count += totalTapalCount;
      this.totalthaneparcel += parcelval;
      this.totalthaneanksankhya += anksankhya;
    }
  }

  getMumbaiPincodeStatement() {
    this.total_mumbai_pin_sub_count = 0;
    this.totalmumbaiparcel = 0;
    this.totalmumbaianksankhya = 0;
    let tempdata = this.uniqueid(
      this.mumbaipincodewisedata,
      (a) => a['pincode']
    );

    // console.log({ tempdata });
    for (let index = 0; index < Object.keys(tempdata).length; index++) {
      let arr = this.mumbaipincodewisedata.filter(
        (a) => a['pincode'] == tempdata[index]['pincode']
      );
      // console.log('*********Started***********');
      // console.log({ arr });

      let delivery_mode_unique = this.uniqueid(arr, (a) => a['delivery_mode']);
      // console.log({ delivery_mode_unique });

      let totalTapalCount = 0;
      let parcelval = 0;
      let anksankhya = 0;
      for (let j = 0; j < Object.keys(delivery_mode_unique).length; j++) {
        let arr1 = arr.filter(
          (a) => a['delivery_mode'] == delivery_mode_unique[j]['delivery_mode']
        );
        // console.log({ arr1 });
        let hasteTapalJPO = [];
        if (arr1[0]['delivery_mode'] === 'टपाल') {
          totalTapalCount = Object.keys(arr1).length;
          // console.log({ totalTapalCount });
        } else if (arr1[0]['delivery_mode'] === 'हस्ते') {
          // console.log('this might be hand delivery');

          hasteTapalJPO = arr1.filter(
            (a) => a['dis_mode'] == 'टपाल' && a['dis_sub_mode'] == 'जी.पी.ओ'
          );
          // console.log({ hasteTapalJPO });
          if (hasteTapalJPO.length > 0) {
            let hasteTapalJPOUnique = this.uniqueid(
              hasteTapalJPO,
              (a) => a['distribution_rep']
            );
            // console.log({ hasteTapalJPOUnique });
            parcelval = hasteTapalJPOUnique.length;
            hasteTapalJPOUnique.map((tapal) => {
              let ank = hasteTapalJPO.filter(
                (a) => a['distribution_rep'] === tapal['distribution_rep']
              );
              // console.log({ ank });
              anksankhya += ank.length;
            });
            // console.log({ anksankhya });
          }
        }
      }
      this.total_mumbai_pin_sub_count += totalTapalCount;
      this.totalmumbaiparcel += parcelval;
      this.totalmumbaianksankhya += anksankhya;
    }
  }

  getOtherStatePincodewiseStatement() {
    this.total_pin_sub_count = 0;
    this.totalparcel = 0;
    this.totalanksankhya = 0;
    let tempdata = this.uniqueid(
      this.otherstatepincodewisedata,
      (a) => a['pincode']
    );

    // console.log({ tempdata });
    this.filteredotherstatepincodewisedata = [];
    for (let index = 0; index < Object.keys(tempdata).length; index++) {
      let arr = this.otherstatepincodewisedata.filter(
        (a) => a['pincode'] == tempdata[index]['pincode']
      );
      // console.log('*********Started***********');
      // console.log({ arr });

      let delivery_mode_unique = this.uniqueid(arr, (a) => a['delivery_mode']);
      // console.log({ delivery_mode_unique });

      let totalTapalCount = 0;
      let parcelval = 0;
      let anksankhya = 0;
      for (let j = 0; j < Object.keys(delivery_mode_unique).length; j++) {
        let arr1 = arr.filter(
          (a) => a['delivery_mode'] == delivery_mode_unique[j]['delivery_mode']
        );
        // console.log({ arr1 });
        let hasteTapalJPO = [];
        if (arr1[0]['delivery_mode'] === 'टपाल') {
          totalTapalCount = Object.keys(arr1).length;
          // console.log({ totalTapalCount });
        } else if (arr1[0]['delivery_mode'] === 'हस्ते') {
          // console.log('this might be hand delivery');

          hasteTapalJPO = arr1.filter(
            (a) => a['dis_mode'] == 'टपाल' && a['dis_sub_mode'] == 'जी.पी.ओ'
          );
          // console.log({ hasteTapalJPO });
          if (hasteTapalJPO.length > 0) {
            let hasteTapalJPOUnique = this.uniqueid(
              hasteTapalJPO,
              (a) => a['distribution_rep']
            );
            // console.log({ hasteTapalJPOUnique });
            parcelval = hasteTapalJPOUnique.length;
            hasteTapalJPOUnique.map((tapal) => {
              let ank = hasteTapalJPO.filter(
                (a) => a['distribution_rep'] === tapal['distribution_rep']
              );
              // console.log({ ank });
              anksankhya += ank.length;
            });
            // console.log({ anksankhya });
          }
        }
      }
      // console.log({ totalTapalCount });
      // console.log('*********Ended***********');

      this.filteredotherstatepincodewisedata.push({
        name: tempdata[index]['name'],
        Subscription_Type: tempdata[index]['Subscription_Type'],
        address: tempdata[index]['address'],
        country: tempdata[index]['country'],
        delivery_mode: tempdata[index]['delivery_mode'],
        district: tempdata[index]['district'],
        pincode: tempdata[index]['pincode'],
        state: tempdata[index]['state'],
        sub_perid_to_date: tempdata[index]['sub_perid_to_date'],
        taluka: tempdata[index]['taluka'],
        sub: totalTapalCount,
        parcel: `${parcelval}=${anksankhya}`,
      });

      this.total_pin_sub_count += totalTapalCount;
      this.totalparcel += parcelval;
      this.totalanksankhya += anksankhya;
    }
    // console.log(
    //   'filteredotherstatepincodewisedata',
    // );
    this.filteredotherstatepincodewisedata.sort((a, b) => {
      return a.state.localeCompare(b.state);
    });

    let totalcount = Object.keys(this.filteredotherstatepincodewisedata).length;
    let tempcount = Math.ceil(totalcount / 2);
    let val = 0;
    let a = 0;
    let c = 0;
    for (let i = 0; i < tempcount; i++) {
      val += 2;
      this.pincodewisesubcoutprinting.push({
        state1: '',
        pincode1: 0,
        sub1: 0,
        parcel1: 0,
        state2: '',
        pincode2: 0,
        sub2: 0,
        parcel2: 0,
      });
    }

    let b = Math.ceil(val / 2);
    for (
      let index = 0;
      index < Object.keys(this.filteredotherstatepincodewisedata).length;
      index++
    ) {
      if (index < val) {
        // console.log('val works');
        if (index == a) {
          // console.log('a works', a, index);
          this.pincodewisesubcoutprinting[a]['state1'] =
            this.filteredotherstatepincodewisedata[index]['state'];
          this.pincodewisesubcoutprinting[a]['pincode1'] =
            this.filteredotherstatepincodewisedata[index]['pincode'];
          this.pincodewisesubcoutprinting[a]['sub1'] =
            this.filteredotherstatepincodewisedata[index]['sub'];
          this.pincodewisesubcoutprinting[a]['parcel1'] =
            this.filteredotherstatepincodewisedata[index]['parcel'];
          if (a != tempcount - 1) {
            a += 1;
          }
        } else if (index == b) {
          // console.log('b works', b, c, index);
          this.pincodewisesubcoutprinting[c]['state2'] =
            this.filteredotherstatepincodewisedata[index]['state'];
          this.pincodewisesubcoutprinting[c]['pincode2'] =
            this.filteredotherstatepincodewisedata[index]['pincode'];
          this.pincodewisesubcoutprinting[c]['sub2'] =
            this.filteredotherstatepincodewisedata[index]['sub'];
          this.pincodewisesubcoutprinting[c]['parcel2'] =
            this.filteredotherstatepincodewisedata[index]['parcel'];
          c += 1;
          b += 1;
        }
      }
    }

    // console.log('pincodewisesubcoutprinting', this.pincodewisesubcoutprinting);
  }

  uniqueid(data, key) {
    return [...new Map(data.map((x) => [key(x), x])).values()];
  }
}
