import { EditBookDeliveryComponent } from './../edit-book-delivery/edit-book-delivery.component';
import { formatDate } from '@angular/common';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { DigisubservicesService } from 'src/app/services/digisubservices.service';
import { MatDialog } from '@angular/material/dialog';
import { EncdecriptService } from 'src/app/services/encdecrypt.service';
import { AddNewAdminDialogComponent } from 'src/app/pages/add-new-admin-dialog/add-new-admin-dialog.component';
import { Router } from '@angular/router';
import jsPDF from 'jspdf';
import { FileUploader } from 'ng2-file-upload';
import html2canvas from 'html2canvas';
import { timer } from 'rxjs';
import { EditBookStockComponent } from '../edit-book-stock/edit-book-stock.component';
import { EditBookComponent } from '../edit-book/edit-book.component';
import * as XLSX from 'xlsx';
import { ViewBookComponent } from '../view-book/view-book.component';
import { SubscriptionAccountsComponent } from '../subscription-accounts/subscription-accounts.component';
import { BookinvoiceComponent } from '../bookinvoice/bookinvoice.component';
import { CreditvoucherDialogComponent } from '../creditvoucher-dialog/creditvoucher-dialog.component';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { BookstorereceiptComponent } from '../bookstorereceipt/bookstorereceipt.component';
import { BookstoresendmessageComponent } from '../bookstoresendmessage/bookstoresendmessage.component';
import { UpdateOfficeRepresentativeComponent } from '../update-office-representative/update-office-representative.component';
import { AddBookPerPriceComponent } from '../add-book-per-price/add-book-per-price.component';
import { UpdateBookPerPriceComponent } from '../update-book-per-price/update-book-per-price.component';
import { BookCrdtnoteRcptComponent } from '../book-crdtnote-rcpt/book-crdtnote-rcpt.component';
import { ShowBookInvoiceComponent } from '../show-book-invoice/show-book-invoice.component';
import { ShowBookCrdtNoteRcptComponent } from '../show-book-crdt-note-rcpt/show-book-crdt-note-rcpt.component';
import { AuthorAddressComponent } from '../author-address/author-address.component';
import { BookSellerAddressComponent } from '../book-seller-address/book-seller-address.component';
import { ShowBookSellerOutstandingDataComponent } from '../show-book-seller-outstanding-data/show-book-seller-outstanding-data.component';
import { BooktworeciptComponent } from 'src/app/booktworecipt/booktworecipt.component';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
// import { request } from 'http';
// import { request } from 'http';

export const _filter = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();

  return opt.filter((item) => item.toLowerCase().includes(filterValue));
};

export const _filter1 = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();

  return opt.filter((item) => item.toLowerCase().includes(filterValue));
};

export const _filter2 = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();

  return opt.filter((item) => item.toLowerCase().includes(filterValue));
};

export const _filter7 = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();

  return opt.filter((item) => item.toLowerCase().includes(filterValue));
};

export const _filter3 = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();

  return opt.filter((item) => item.toLowerCase().includes(filterValue));
};

export const _filter4 = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();

  return opt.filter((item) => item.toLowerCase().includes(filterValue));
};

export const _filter5 = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();

  return opt.filter((item) => item.toLowerCase().includes(filterValue));
};

export const _filter6 = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();

  return opt.filter((item) => item.toLowerCase().includes(filterValue));
};

export const _filter8 = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();

  return opt.filter((item) => item.toLowerCase().includes(filterValue));
};

export const _filter9 = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();

  return opt.filter((item) => item.toLowerCase().includes(filterValue));
};

export const _filter10 = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();

  return opt.filter((item) => item.toLowerCase().includes(filterValue));
};

export const _filter11 = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();

  return opt.filter((item) => item.toLowerCase().includes(filterValue));
};

export const _filter12 = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();

  return opt.filter((item) => item.toLowerCase().includes(filterValue));
};

const uploadAPI = 'https://vivekweb.in/VivekApplication/portal_fileUpload.php';
@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
})
export class UserProfileComponent implements OnInit {
  BookForm: FormGroup;
  subform: FormGroup;
  bsform: FormGroup;
  bookpymtrecptform: FormGroup;
  bookrcptregisterform: FormGroup;
  booksellercrdtnoteform: FormGroup;
  bookoutstandingform: FormGroup;
  bookName: FormGroup;
  bookcrdtnoteForm: FormGroup;
  bookselectedForm: FormGroup;
  imgPath: string;
  bookData: any = [];
  bookdata: any = [];
  book_data = [];
  book_data1;
  bookDeliveryData = [];
  deliveredBooks = [];
  displayFilter = false;
  bookimgpath = 'https://vivekweb.in/VivekApplication/uploads/';
  total = 0;
  booksizeform: FormGroup;
  authorForm: FormGroup;
  bookselleraddressForm: FormGroup;
  bookselForm: FormGroup;
  officerepdata;
  officerepnames = [];
  selectedState = '';
  selectedCity = '';
  selectedTaluka = '';

  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });

  Managerange = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });

  rangeRegistration = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });

  BookrangeRegistration = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });

  BookbillingRegister = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });

  public uploader: FileUploader = new FileUploader({
    url: uploadAPI,
    itemAlias: 'file',
  });
  userprofile: any;
  userprofileStartDate: any;
  userprofileEndDate: any;
  userprofileForm: any;
  entryForm: any;
  bookselnamedata1: any;
  bookselnamedata: any;
  priceTotal: number=0;
  commissionTotal: number=0;
  remainingTotal: number=0;
  receivedAmount: number=0;

  contractData = [];
  contractRepName = [];
  contractOffRepName = [];
  contractAdvtName = [];
  booksellerName = [];
 

  constructor(
    private router: Router,
    private fb: FormBuilder,
    public service: DigisubservicesService,
    public dialog: MatDialog,
    public encr: EncdecriptService
  ) {
    this.BookForm = fb.group({
      bookname: [''],
      bookdesc: [''],
      price: [''],
      saleprice: [''],
      bookimg: [''],
      authorname: [''],
      bookpagesbw: [''],
      bkpinsidecolor: [''],
      bkpcover: [''],
      booksize: [''],
      paperbw: [''],
      paperinsidecolor: [''],
      papercover: [''],
      totalpages: [''],
      binding: [''],
      booklang: [''],
      bookyear: [''],
      bookedition: [''],
      bookedtndate: [''],
      bookedtncpoies: [''],
      opening_stock: [''],
      author_honorarium: [''],
      isdelivery: [''],
      isupcoming: [''],
      upcomingdate: [''],
      discountupto: [''],
      bookcategory: [''],
    });

    this.subform = fb.group({
      subname: [''],
      title: ['', [Validators.minLength(2)]],
      name: ['', [Validators.minLength(2)]],
      address: ['', [Validators.minLength(2)]],
      pincode: [
        '',
        [Validators.maxLength(6), Validators.pattern('^[1-9][0-9]{5}$')],
      ],
      country: ['', [Validators.minLength(2)]],
      state: ['', [Validators.minLength(2)]],
      district: ['', [Validators.minLength(2)]],
      taluka: ['', [Validators.minLength(2)]],
      mobileNumber: [
        '',
        [Validators.maxLength(10), Validators.pattern('^[7-9][0-9]{9}$')],
      ],
      email: [
        '',
        [Validators.pattern('[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+.[a-z]{2,3}$')],
      ],
      dob: [''],
      doa: [''],
      crepname: [''],
      repmobno1: [''],
      officerep: [''],
      bookdate: [''],
      orderno: [''],
      bookname: [''],
      availablestock: [''],
      authorname: [''],
      iscomplimentary: [''],
      dispatchto: [''],
      option: [''],
      deliverymode: [''],
      repcompercent: ['', [Validators.pattern('^[0-9]*$')]],
      repcomprice: [''],
      cdno: [''],
      chequeDate: [''],
      bankname: [''],
      pymtmode: [''],
      depositamt: [''],
      bookcopies: [''],
      discount: [''],
      shippingcharging: [''],
    });

    this.bookpymtrecptform = fb.group({
      date: [''],
      crdtvno: [''],
      recptdate: [''],
      repname: [''],
      recptno: [''],
      crdtvamt: [''],
      seamt: [''],
      balamt: [''],
      booksellername: [''],
      bname: [''],
      booksellernamesearch: [''],
    });

    this.bsform = fb.group({
      subname: [''],
    });

    this.bookrcptregisterform = fb.group({
      start: [''],
      end: [''],
      repwise: [''],
      billnowise: [''],
      booksellerwise: [''],
      monthwise: [''],
      vistrut: [''],
      sankshipt: [''],
    });

    this.userprofileForm = fb.group({
      start: [''],
      end: [''],
     
    });

    this.entryForm = fb.group({
      start: [''],
      end: [''],
     
    });

    

    this.booksellercrdtnoteform = fb.group({
      start: [''],
      end: [''],
      repwise: [''],
      prakashanwise: [''],
      booksellerwise: [''],
      monthwise: [''],
      vistrut: [''],
      sankshipt: [''],
    });

    this.bookoutstandingform = fb.group({
      start: [''],
      end: [''],
      officerepwise: [''],
      repwise: [''],
      booksellerwise: [''],
      bookwise: [''],
      repname: [''],
      mob: [''],
    });

    this.bookName = fb.group({
      bookname: [''],
    });

    this.bookcrdtnoteForm = fb.group({
      crdtnoteno: [''],
      date: [''],
      repname: [''],
      offrepname: [''],
      bsname: [''],
    });

    this.bookselectedForm = fb.group({
      returncopies: [''],
      crdtnoteamt: [''],
      remark: [''],
    });

    this.booksizeform = fb.group({
      booksize: [''],
    });

    this.authorForm = fb.group({
      authorname: [''],
      address: ['', [Validators.minLength(2)]],
      pincode: [
        '',
        [Validators.maxLength(6), Validators.pattern('^[1-9][0-9]{5}$')],
      ],
      country: ['', [Validators.minLength(2)]],
      state: ['', [Validators.minLength(2)]],
      district: ['', [Validators.minLength(2)]],
      taluka: ['', [Validators.minLength(2)]],
      mob_no: [
        '',
        [Validators.maxLength(10), Validators.pattern('^[7-9][0-9]{9}$')],
      ],
      email: [
        '',
        [Validators.pattern('[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+.[a-z]{2,3}$')],
      ],
      pan_no: ['', Validators.pattern('[A-Z]{5}[0-9]{4}[A-Z]{1}$')],
      aadhar_no: ['', Validators.pattern('^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$')],
      bank_name: [''],
      branch: [''],
      acc_no: [''],
      ifsc_code: [''],
    });

    this.bookselleraddressForm = fb.group({
      bsname: [''],
      start: [''],
      end: [''],
      country: [''],
      state: [''],
      district: [''],
      taluka: [''],
      office_rep_name: [''],
      repname: [''],
      complimentary: [''],
    });


    this.bookselForm = fb.group({
      vstart_date: [''],
      vend_date: [''],
      estart_date: [''],
      eend_date: [''],
      rep_name: [''],
      book_seller_name: [''],
      
    });


  }

  filteredOptions: Observable<string[]>;
  filteredOptions1: Observable<string[]>;
  filteredOptions2: Observable<string[]>;
  filteredOptions3: Observable<string[]>;
  filteredOptions4: Observable<string[]>;
  filteredOptions5: Observable<string[]>;
  filteredOptions7: Observable<string[]>;
  filteredOptions8: Observable<string[]>;
  filteredOptions9: Observable<string[]>;
  filteredOptions10: Observable<string[]>;
  filteredOptions11: Observable<string[]>;
  filteredOptions12: Observable<string[]>;
  filteredOptions13: Observable<string[]>;
  columnsToDisplay = [];
  bookStock;
  bookregistrationdetails = [];
  booknames = [];
  bookwisereportData = [];
  repnameList = ['all'];
  getAllRepList = [];
  bookpaymentList = [];
  displayRepoption = false;
  displayCustOption = false;
  getRepSpecificBooks = [];
  gstNumber = '';
  RepInvoiceData = [];
  CustInvoiceData = [];
  countries = [];
  states = [];
  cities = [];
  titles = [];
  talukas = [];
  issueSellerName;
  issue_seller_name = [];
  book_seller_data;
  book_sellerdata = [];
  bookdata1: any = [];
  uniquedata;
  date = new Date();
  recptno;
  invoiceno;
  book_price_total = 0;
  book_sale_price_total = 0;
  book_copies_total = 0;
  shipping_charges_total = 0;
  total_total = 0;
  amtinwords;
  totals = 0;
  bookinvoicedata;
  bookorderno;
  bookregistrationdata = [];
  FilteredBookRegistration1 = [];
  names: any = [];
  filteredOptions6: Observable<string[]>;
  booksellerledgercrdtv;
  bookoutstandingdata;
  Filteredbookoutstandingdata = [];
  BookSellerLegderData;
  booksellerledgerrcpt;
  bookbillingdata;
  Filteredbookbillingdata;
  BookBillingData;
  bookRcptRegisterdata;
  FilteredbookRcptRegisterdata = [];
  bookWiseReport;
  bookdeliverydata;
  edition: string = '1st Edition';
  totalpages: number = 0;
  bookwisereplist;
  discount = 0;
  iscomplementary: boolean = false;
  val1 = 0;
  bookslist;
  crdtnoteno;
  bookregistrationdata1;
  FilteredDeliveryManageBooks = [];
  authorData;
  authornames = [];
  bookSellerAddressData;
  showFilteredBookSellerAddress;
  FilteredBookSellerAddress = [];
  repcommissiondata;

  CustGeneratedInvoice = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });

  invoicegetCustBook = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });

  invoicegetBook = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });
  public demo1BtnClick1(tabCount:any) {
    
    this.demo1TabIndex =  Number(tabCount);
    
  }

  



  userprofileDateRangeStartDate = '';
  userprofileDateRangeEndDate = '';
  userprofileDateRange() {
    if (
      this.userprofileForm.get('start').value != null &&
      this.userprofileForm.get('end').value != null
    ) {
      this.userprofileDateRangeStartDate = formatDate(
        this.userprofileForm.get('start').value,
        'yyyy-MM-dd',
        'en-IN'
      );
      // console.log(this.creditdebitStartDate);

      this.userprofileDateRangeEndDate = formatDate(
        this.userprofileForm.get('end').value,
        'yyyy-MM-dd',
        'en-IN'
      );
      // console.log(this.creditdebitEndDate);
    }
  }

  userprofileDateRange1() {
    if (
      this.bookselForm.get('vstart_date').value != null &&
      this.bookselForm.get('vend_date').value != null
    ) {
      this.userprofileDateRangeStartDate = formatDate(
        this.bookselForm.get('vstart_date').value,
        'yyyy-MM-dd',
        'en-IN'
      );
      // console.log(this.creditdebitStartDate);

      this.userprofileDateRangeEndDate = formatDate(
        this.bookselForm.get('vend_date').value,
        'yyyy-MM-dd',
        'en-IN'
      );
     this.bookselForm.patchValue({
      vstart_date:this.userprofileDateRangeStartDate,
      vend_date:this.userprofileDateRangeEndDate
     })
      // console.log(this.creditdebitEndDate);
    }
  }

  entryDateRangeStartDate = '';
  entryDateRangeEndDate = '';
  entryDateRange() {
    if (
      this.entryForm.get('start').value != null &&
      this.entryForm.get('end').value != null
    ) {
      this.entryDateRangeStartDate = formatDate(
        this.entryForm.get('start').value,
        'yyyy-MM-dd',
        'en-IN'
      );
      // console.log(this.creditdebitStartDate);

      this.entryDateRangeEndDate = formatDate(
        this.entryForm.get('end').value,
        'yyyy-MM-dd',
        'en-IN'
      );
      // console.log(this.creditdebitEndDate);
    }
  }

  entryDateRange1() {
    if (
      this.bookselForm.get('eend_date').value != null &&
      this.bookselForm.get('estart_date').value != null
    ) {
      this.entryDateRangeStartDate = formatDate(
        this.bookselForm.get('estart_date').value,
        'yyyy-MM-dd',
        'en-IN'
      );
      // console.log(this.creditdebitStartDate);

      this.entryDateRangeEndDate = formatDate(
        this.bookselForm.get('eend_date').value,
        'yyyy-MM-dd',
        'en-IN'
      );

      this.bookselForm.patchValue({
        estart_date:this.entryDateRangeStartDate,
        eend_date:this.entryDateRangeEndDate
       })
      // console.log(this.creditdebitEndDate);
    }
  }

  ngOnInit() {

    this.demo1BtnClick1( localStorage.getItem("tab"))

    
    this.BookForm.get('bookedition').setValue(this.edition);
    let date = ('0' + this.date.getDate()).slice(-2);
    let month = ('0' + (this.date.getMonth() + 1)).slice(-2);
    let year = this.date.getFullYear().toString().slice(-2);
    let nextyear = (this.date.getFullYear() + 1).toString().slice(-2);

    this.service.getPymtRcptNo().subscribe((data) => {
      console.log('val1', data['val1']);
      if (data['val1'] == undefined) {
        this.val1 = 1;
      } else {
        this.val1 = parseInt(data['val1']) + 1;
      }
    });
    setTimeout(() => {
      this.recptno = `BDR${month}/${year}-${nextyear}/${this.val1}`;
      this.bookpymtrecptform.get('recptno').setValue(this.recptno);
    }, 3000);
    // }

    
    this.service.getCrdtNoteNo().subscribe((data) => {
      let val = 0;
      if (data['id'] != null) {
        val = parseInt(data['id']) + 1;
        this.crdtnoteno = `BDCN${month}/${year}-${nextyear}/${val}`;
        this.bookcrdtnoteForm.get('crdtnoteno').setValue(this.crdtnoteno);
        this.bookcrdtnoteForm
          .get('date')
          .setValue(formatDate(new Date(), 'yyyy-MM-dd', 'en-IN', 'GMT+0530'));
      } else {
        val = 1;
        this.crdtnoteno = `BDCN${month}/${year}-${nextyear}/${val}`;
        this.bookcrdtnoteForm.get('crdtnoteno').setValue(this.crdtnoteno);
        this.bookcrdtnoteForm
          .get('date')
          .setValue(formatDate(new Date(), 'yyyy-MM-dd', 'en-IN', 'GMT+0530'));
      }
    });
    debugger

    this.service.getBookWiseRep().subscribe((data) => {
     debugger
     
      this.bookwisereplist = data;
      this.getContractDetails();
     
    });

    this.service.getRepCommissionData().subscribe((data) => {
      this.repcommissiondata = data;
    });

    this.service.getBookSellerNames().subscribe((data) => {
      for (let index = 0; index < Object.keys(data).length; index++) {
        this.names.push({
          id: data[index]['id'],
          name: data[index]['name'],
        });
      }
    });

    this.service.getBookSellerNames1().subscribe((data) => {
      for (let index = 0; index < Object.keys(data).length; index++) {
        this.names.push({
          id: data[index]['id'],
          name: data[index]['name'],
        });
      }

      this.names = this.uniqueid(this.names, (a) => a.name);
    });

    this.service.getdataforbookbilling().subscribe((data) => {
      this.bookbillingdata = this.uniqueid(data, (a) => a.id);
      this.Filteredbookbillingdata = this.bookbillingdata;
      this.calculateBookBillingData();
    });

    this.service.getBookRcptPymtData().subscribe((data) => {
      this.bookRcptRegisterdata = data;
      // console.log(this.bookRcptRegisterdata);
      this.FilteredbookRcptRegisterdata = this.bookRcptRegisterdata;
      for(let data of this.FilteredbookRcptRegisterdata){
        this.receivedAmount += parseInt(data.select_entry_amt) || 0;
        }
    });

    this.getBookSellerOrderNo(
      formatDate(new Date(), 'yyyy-MM-dd', 'en-In', 'GMT+0530')
    );

    this.service.getBookCrdtVoucher().subscribe((data) => {
      this.booksellerledgercrdtv = data;
    });

    this.service.getBookOutStandingData().subscribe((data) => {
      this.bookoutstandingdata = data;
      this.Filteredbookoutstandingdata = this.bookoutstandingdata;
      // console.log(count);
    });

    // this.service.getBookRcptForLedger().subscribe((data) => {
    //   this.booksellerledgerrcpt = data;
    // });

    // this.service.getBookWiseReport().subscribe((data) => {
    //   this.bookWiseReport = data;
    // });

    this.service.getAllBooksData().subscribe((data) => {
      this.bookslist = this.uniqueid(data, (a) => a.bookname);
    });

    this.service.getAllBookData().subscribe((data) => {
      this.bookData = data;
      this.bookdata = this.uniqueid(this.bookData, (it) => it.bookid);
      // console.log(this.bookData);
      for (let index = 0; index < Object.keys(this.bookdata).length; index++) {
        this.booknames.push({ bookname: this.bookdata[index]['bookname'] });
      }
    });

    //temporary commenting this code

    // let val = 0;
    // this.service.getBookCustInvoiceId().subscribe((data) => {
    //   if (data['val'] == null) {
    //     val = val + 1;
    //   } else {
    //     val = parseInt(data['val']) + 1;
    //   }
    //   this.invoiceno = `VBD04/${year}-${nextyear}/${val}`;
    // });

    this.service.getBookInvoiceData().subscribe((data) => {
      this.bookinvoicedata = data;
    });

    this.bookpymtrecptform
      .get('date')
      .setValue(formatDate(new Date(), 'yyyy-MM-dd', 'en-IN', 'GMT+0530'));

    this.service.get_AllRepresentative_List().subscribe((data) => {
      this.getAllRepList = data;
    });

    this.service.getBookData1().subscribe((data) => {
      this.bookdata1 = data;
      this.uniquedata = this.uniqueid(this.bookdata1, (it) => it.name);
    });

    this.service.getBookDataforDelivery().subscribe((data) => {
      this.bookdeliverydata = data;
      this.FilteredDeliveryManageBooks = this.bookdeliverydata;
    });

    this.service.getBookSellerEntry().subscribe((data) => {
      this.book_seller_data = data;
      for (
        let index = 0;
        index < Object.keys(this.book_seller_data).length;
        index++
      ) {
        this.book_sellerdata.push({
          id: this.book_seller_data[index]['id'],
          name: this.book_seller_data[index]['name'],
          mob_no: this.book_seller_data[index]['mob_no'],
        });
      }
    });

    this.service.getBookSellerEntryForBookRegistration().subscribe((data) => {
      this.bookregistrationdata1 = data;
      for (let index = 0; index < this.bookregistrationdata1.length; index++) {
        this.bookregistrationdata.push({
          booking_date: this.bookregistrationdata1[index]['booking_date'],
          orderno: this.bookregistrationdata1[index]['orderno'],
          name: this.bookregistrationdata1[index]['name'],
          district: this.bookregistrationdata1[index]['district'],
          mob_no: this.bookregistrationdata1[index]['mob_no'],
          crep_name: this.bookregistrationdata1[index]['crep_name'],
          office_rep_name: this.bookregistrationdata1[index]['office_rep_name'],
          bookname: this.bookregistrationdata1[index]['bookname'],
          bookcopies: this.bookregistrationdata1[index]['bookcopies'],
          iscomplimentary: this.bookregistrationdata1[index]['iscomplimentary'],
          bookprice: this.bookregistrationdata1[index]['bookprice'],
          totalafterdiscount:
            this.bookregistrationdata1[index]['totalafterdiscount'],
          total: this.bookregistrationdata1[index]['total'],
          shipping_charges:
            this.bookregistrationdata1[index]['shipping_charges'],
          receivedamt: this.bookregistrationdata1[index]['receivedamt'],
          remainingamt: this.bookregistrationdata1[index]['remainingamt'],
          invoiceno: this.bookregistrationdata1[index]['invoiceno'],
          rcptno: this.bookregistrationdata1[index]['rcptno'],
          repcommissionamt:
            this.bookregistrationdata1[index]['repcommissionamt'],
          delivery_mode: this.bookregistrationdata1[index]['delivery_mode'],
        });
      }
    });

    this.service.getBookRegistrationDataWithoutInvoice().subscribe((data) => {
      for (let index = 0; index < data.length; index++) {
        this.bookregistrationdata.push({
          booking_date: data[index]['booking_date'],
          orderno: data[index]['orderno'],
          name: data[index]['name'],
          district: data[index]['district'],
          mob_no: data[index]['mob_no'],
          crep_name: data[index]['crep_name'],
          office_rep_name: data[index]['office_rep_name'],
          bookname: data[index]['bookname'],
          bookcopies: data[index]['bookcopies'],
          iscomplimentary: data[index]['iscomplimentary'],
          bookprice: data[index]['bookprice'],
          totalafterdiscount: data[index]['totalafterdiscount'],
          total: data[index]['total'],
          shipping_charges: data[index]['shipping_charges'],
          receivedamt: 0,
          remainingamt:
            parseInt(data[index]['total']) +
            parseInt(data[index]['shipping_charges']),
          invoiceno: '',
          rcptno: '',
          repcommissionamt: data[index]['repcommissionamt'],
          delivery_mode: data[index]['delivery_mode'],
        });
      }
      this.FilteredBookRegistration1 = this.bookregistrationdata;
    });

    this.service.getIssuesellersname().subscribe((data) => {
      this.issue_seller_name = data;
    });

    this.service.getIssueSellerName().subscribe((data) => {
      this.issueSellerName = data;
    });

    this.service.getAlltitle().subscribe((data) => {
      this.titles = data;
    });

    // Read All Contries
    // this.service.getAllContries().subscribe((data) => {
    //   this.countries = data;
    // });

    this.service.getCountry().subscribe((data) => {
      this.countries = data;
    });

    this.service.getBookSellerAddress().subscribe((data) => {
      this.bookSellerAddressData = data;
      this.FilteredBookSellerAddress = this.bookSellerAddressData;
      // console.log(this.bookSellerAddressData);
    });

    // this.service.getAllBooks().subscribe((data) => {
    //   this.bookData = data;

    // for (let index = 0; index < this.bookData.length; index++) {
    //   this.booknames.push(this.bookData[index]['bookname']);
    // }
    // });

    this.service.getPortalAllRepList().subscribe((data) => {
      for (let index = 0; index < Object.keys(data).length; index++) {
        this.repnameList.push(data[index]['name']);
      }
    });

    this.uploader.onAfterAddingFile = (file) => {
      file.withCredentials = false;
    };

    this.uploader.onCompleteItem = (
      item: any,
      response: any,
      status: any,
      headers: any
    ) => {
      this.imgPath = item['file']['name'];
      this.BookForm.get('bookimg').setValue(this.imgPath);
      alert('Your file has been uploaded successfully');
    };

    this.service.getBookRegistration().subscribe((data) => {
      for (let index = 0; index < Object.keys(data).length; index++) {
        if (
          this.bookregistrationdetails.find(
            (a) => parseInt(a['mobile']) == parseInt(data[index]['mobile'])
          ) == undefined
        ) {
          this.bookregistrationdetails.push({
            book_amount: data[index]['book_amount'],
            book_commision: data[index]['book_commision'],
            book_name: data[index]['book_name'],
            book_qty: data[index]['book_qty'],
            city: data[index]['city'],
            delivery_charges: data[index]['delivery_charges'],
            mobile: data[index]['mobile'],
            name: data[index]['name'],
            received_date: data[index]['received_date'],
            rep_name: data[index]['rep_name'],
            total_amount:
              parseInt(data[index]['book_amount']) -
              parseInt(data[index]['delivery_charges']),
            transport_method: data[index]['transport_method'],
            transporter_name: data[index]['transporter_name'],
            transport_id: data[index]['transport_id'],
            payment_status: data[index]['payment_status'],
            rep_receipt: data[index]['rep_receipt'],
          });
        } else {
          this.bookregistrationdetails.push({
            book_amount: data[index]['book_amount'],
            book_commision: data[index]['book_commision'],
            book_name: data[index]['book_name'],
            book_qty: data[index]['book_qty'],
            city: data[index]['city'],
            delivery_charges: 0,
            mobile: data[index]['mobile'],
            name: data[index]['name'],
            received_date: data[index]['received_date'],
            rep_name: data[index]['rep_name'],
            total_amount:
              parseInt(data[index]['book_amount']) -
              parseInt(data[index]['delivery_charges']),
            transport_method: data[index]['transport_method'],
            transporter_name: data[index]['transporter_name'],
            transport_id: data[index]['transport_id'],
            payment_status: data[index]['payment_status'],
            rep_receipt: data[index]['rep_receipt'],
          });
        }
      }

      setTimeout(() => {
        for (
          let index = 0;
          index < Object.keys(this.bookregistrationdata).length;
          index++
        ) {
          if (
            this.bookregistration_bookname.find(
              (a) => a == this.bookregistrationdata[index]['bookname']
            ) == undefined
          ) {
            if (this.bookregistrationdata[index]['bookname'] != null) {
              this.bookregistration_bookname.push(
                this.bookregistrationdata[index]['bookname']
              );
            }
          }
        }
        this.FilteredBookRegistration = this.bookregistrationdetails;
      }, 2000);
    });

    this.service.getOfficeRepData().subscribe((data) => {
      // console.log(data);
      this.officerepdata = data;
      for (
        let index = 0;
        index < Object.keys(this.officerepdata).length;
        index++
      ) {
        this.officerepnames.push({
          id: this.officerepdata[index]['id'],
          name: this.officerepdata[index]['officerep'],
        });
      }
    });


 
    this.filteredOptions = this.subform.get('mobileNumber').valueChanges.pipe(
      startWith(''),
      map((value) => (typeof value === 'string' ? value : value.mob_no)),
      map((mob_no) =>
        mob_no ? this._filter(mob_no) : this.book_sellerdata.slice()
      )
    );

    this.filteredOptions1 = this.subform.get('subname').valueChanges.pipe(
      startWith(''),
      map((value) => (typeof value === 'string' ? value : value.name)),
      map((name) => (name ? this._filter1(name) : this.book_sellerdata.slice()))
    );

    this.filteredOptions2 = this.subform.get('bookname').valueChanges.pipe(
      startWith(''),
      map((value) => (typeof value === 'string' ? value : value.bookname)),
      map((bookname) =>
        bookname ? this._filter2(bookname) : this.bookdata.slice()
      )
    );

    this.filteredOptions7 = this.bookName.get('bookname').valueChanges.pipe(
      startWith(''),
      map((value) => (typeof value === 'string' ? value : value.bookname)),
      map((bookname) =>
        bookname ? this._filter7(bookname) : this.bookdata.slice()
      )
    );

    this.filteredOptions3 = this.subform.get('authorname').valueChanges.pipe(
      startWith(''),
      map((value) => (typeof value === 'string' ? value : value.authorname)),
      map((authorname) =>
        authorname ? this._filter3(authorname) : this.bookData.slice()
      )
    );

    this.filteredOptions4 = this.subform.get('crepname').valueChanges.pipe(
      startWith(''),
      map((value) => (typeof value === 'string' ? value : value.name)),
      map((name) =>
        name ? this._filter4(name) : this.issue_seller_name.slice()
      )
    );

    this.filteredOptions5 = this.bsform.get('subname').valueChanges.pipe(
      startWith(''),
      map((value) => (typeof value === 'string' ? value : value.name)),
      map((name) => (name ? this._filter5(name) : this.book_sellerdata.slice()))
    );

    this.filteredOptions6 = this.bookpymtrecptform
      .get('booksellernamesearch')
      .valueChanges.pipe(
        startWith(''),
        map((value) => (typeof value === 'string' ? value : value.name)),
        map((name) => (name ? this._filter6(name) : this.names.slice()))
      );
      

    this.filteredOptions8 = this.BookForm.get('bookname').valueChanges.pipe(
      startWith(''),
      map((value) => (typeof value === 'string' ? value : value.bookname)),
      map((bookname) =>
        bookname ? this._filter8(bookname) : this.booknames.slice()
      )
    );

    this.filteredOptions9 = this.bookcrdtnoteForm
      .get('bsname')
      .valueChanges.pipe(
        startWith(''),
        map((value) => (typeof value === 'string' ? value : value.name)),
        map((name) =>
          name ? this._filter9(name) : this.book_sellerdata.slice()
        )
      );

    this.filteredOptions10 = this.bookcrdtnoteForm
      .get('repname')
      .valueChanges.pipe(
        startWith(''),
        map((value) => (typeof value === 'string' ? value : value.name)),
        map((name) =>
          name ? this._filter10(name) : this.issue_seller_name.slice()
        )
      );

    this.filteredOptions11 = this.authorForm
      .get('authorname')
      .valueChanges.pipe(
        startWith(''),
        map((value) => (typeof value === 'string' ? value : value.name)),
        map((name) => (name ? this._filter11(name) : this.authornames.slice()))
      );

    this.filteredOptions12 = this.bookselleraddressForm
      .get('bsname')
      .valueChanges.pipe(
        startWith(''),
        map((value) => (typeof value === 'string' ? value : value.name)),
        map((name) =>
          name ? this._filter12(name) : this.book_sellerdata.slice()
        )
      );

    this.filteredOptions13 = this.bookselleraddressForm
      .get('repname')
      .valueChanges.pipe(
        startWith(''),
        map((value) => (typeof value === 'string' ? value : value.name)),
        map((name) =>
          name ? this._filter10(name) : this.issue_seller_name.slice()
        )
      );

    this.getAllDeliveryData();
    // this.readAllBookStock();

    this.columnsToDisplay = [
      'Name',
      'Mobile',
      'Book Name',
      'Received Date',
      'Status',
      'Action',
    ];

    this.getAllVouchers();
    this.getBookPayments();
    this.bookcrdtnote();
    this.getAuthorData();
  
  } //end of ngOninit

  // this.service.getBookWiseRep().subscribe((data) => {
  //   this.bookwisereplist = data;
  // });

  contractReportRepName = '';
  getContractRepName(event) {
    this.contractReportRepName = event.value;
  }

  contractReportOffRepName = '';
  getContractOffRepName(event){
    this.contractReportOffRepName = event.value;
  }



  bookingdateChange(event) {
    this.getBookSellerOrderNo(
      formatDate(event.target.value, 'yyyy-MM-dd', 'en-IN', 'GMT+0530')
    );
  }

  getBookSellerOrderNo(today) {
    this.service.getBookSellerId(today).subscribe((data) => {
      // console.log(data);

      let value: number;
      if (Object.keys(data).length > 0) {
        value = parseInt(data[0]['order_no'].split('/')[1]);
      } else {
        value = 0;
      }
      // let date = ('0' + this.date.getDate()).slice(-2);
      let month = ('0' + (new Date(today).getMonth() + 1)).slice(-2);
      let year = new Date(today).getFullYear().toString().slice(-2);
      let nextyear = (new Date(today).getFullYear() + 1).toString().slice(-2);
      this.bookorderno = `BDO${month}${year}-${nextyear}/${
        value <= 9 ? '0' + (value + 1) : value + 1
      }`;
      this.subform.get('orderno').setValue(this.bookorderno);
    });
  }

  BookCreditNoteData;
  FilteredBookCreditNoteData = [];
  bookcrdtnote() {
    this.service.getBookCrdtNote().subscribe((data) => {
      this.BookCreditNoteData = data;
      this.FilteredBookCreditNoteData = this.BookCreditNoteData;
    });
  }

  private _filter(mob_no: string): any[] {
    const filterValue = mob_no;
    return this.book_sellerdata.filter((option) =>
      // option.mobno.toLowerCase().includes(filterValue)
      option.mob_no.toLowerCase().includes(filterValue)
    );
  }

  private _filter1(name: string): any[] {
    const filterValue = name;
    return this.book_sellerdata.filter((option) =>
      // option.mobno.toLowerCase().includes(filterValue)
      option.name.toLowerCase().includes(filterValue)
    );
  }

  private _filter2(bookname: string): any[] {
    const filterValue = bookname;
    return this.bookdata.filter((option) =>
      // option.mobno.toLowerCase().includes(filterValue)
      option.bookname.toLowerCase().includes(filterValue)
    );
  }

  private _filter7(bookname: string): any[] {
    const filterValue = bookname;
    return this.bookdata.filter((option) =>
      // option.mobno.toLowerCase().includes(filterValue)
      option.bookname.toLowerCase().includes(filterValue)
    );
  }

  private _filter3(author: string): any[] {
    const filterValue = author;
    return this.bookData.filter((option) =>
      // option.mobno.toLowerCase().includes(filterValue)
      option.author.toLowerCase().includes(filterValue)
    );
  }

  private _filter4(name: string): any[] {
    const filterValue = name;
    return this.issue_seller_name.filter((option) =>
      // option.mobno.toLowerCase().includes(filterValue)
      option.name.toLowerCase().includes(filterValue)
    );
  }

  private _filter5(name: string): any[] {
    const filterValue = name;
    return this.book_sellerdata.filter((option) =>
      // option.mobno.toLowerCase().includes(filterValue)
      option.name.toLowerCase().includes(filterValue)
    );
  }

  // Function to filter and remove duplicates
private _filter6(name: string): any[] {
  const filterValue = name.toLowerCase();

  return this.removeDuplicates(
    this.names.filter(option => option.name.toLowerCase().includes(filterValue))
  );
}

// Function to remove duplicate names
private removeDuplicates(array: any[]): any[] {
  return array.filter((value, index, self) =>
    index === self.findIndex((t) => t.name === value.name)
  );
}

  // private _filter6(name: string): any[] {
  //   const filterValue = name;
  //   return this.names.filter((option) =>
  //     // option.mobno.toLowerCase().includes(filterValue)
  //     option.name.toLowerCase().includes(filterValue)
  //   );
  // }

  private _filter8(bookname: string): any[] {
    const filterValue = bookname;
    return this.booknames.filter((option) =>
      // option.mobno.toLowerCase().includes(filterValue)
      option.bookname.toLowerCase().includes(filterValue)
    );
  }

  private _filter9(name: string): any[] {
    const filterValue = name;
    return this.book_sellerdata.filter((option) =>
      // option.mobno.toLowerCase().includes(filterValue)
      option.name.toLowerCase().includes(filterValue)
    );
  }

  private _filter10(name: string): any[] {
    const filterValue = name;
    return this.issue_seller_name.filter((option) =>
      // option.mobno.toLowerCase().includes(filterValue)
      option.name.toLowerCase().includes(filterValue)
    );
  }

  private _filter11(name: string): any[] {
    const filterValue = name;
    return this.authornames.filter((option) =>
      // option.mobno.toLowerCase().includes(filterValue)
      option.name.toLowerCase().includes(filterValue)
    );
  }

  private _filter12(name: string): any[] {
    const filterValue = name;
    return this.book_sellerdata.filter((option) =>
      // option.mobno.toLowerCase().includes(filterValue)
      option.name.toLowerCase().includes(filterValue)
    );
  }

  getallVouchersData = [];
  getAllVouchers() {
    this.service.getvoucherbybook().subscribe((data) => {
      this.getallVouchersData = data;
      for (let data1 of this.getallVouchersData) {
        this.priceTotal += parseInt(data1.price) || 0; 
        this.commissionTotal += parseInt(data1.commission) || 0;   
        this.remainingTotal += parseInt(data1.remaining_amt) || 0;     
   
      }
    });
  }

  // getallVouchersData = [];
  // getAllVouchers() {
  //   this.service.getvoucherbybook().subscribe((data) => {
  //     this.getallVouchersData = data;
  //   });
  // }

  


  validmobile = false;
  validemail = false;
  validateMobile(event) {
    if (
      this.subform.get('mobileNumber').valid ||
      this.subform.get('repmobno1').valid
    ) {
      this.validmobile = true;
    } else {
      this.validmobile = false;
    }
  }

  // caltotalpages(event) {
  //   let val = parseInt(event.target.value);
  //   this.totalpages = this.totalpages + val;
  //   this.BookForm.get('totalpages').setValue(this.totalpages);
  // }

  caltotalpages() {
    let bookpagesbw = this.BookForm.get('bookpagesbw').value || 0;
    let bkpinsidecolor = this.BookForm.get('bkpinsidecolor').value || 0;
    let bkpcover = this.BookForm.get('bkpcover').value || 0;
  
    // Ensure values are treated as numbers
    let total = parseInt(bookpagesbw, 10) + parseInt(bkpinsidecolor, 10) + parseInt(bkpcover, 10);
  
    // Update total pages
    this.BookForm.get('totalpages').setValue(total);
  }

  amount = 0;
  amt;
  bookdiscount(event, book) {
    this.discount = event.target.value;
    if (this.discount != 0) {
      this.amount = book['price'] * (this.discount / 100);
      this.amt = book['price'] - this.amount;
    } else {
      // this.amount = book['price'];
      this.amt = book['price'];
    }
    for (let index = 0; index < Object.keys(this.book_data).length; index++) {
      if (this.book_data[index]['bookid'] == book['bookid']) {
        this.book_data[index]['totalad'] = this.amt;
        this.book_data[index]['discount'] = this.discount;
        this.book_data[index]['discountamt'] = this.amount;
      }
    }
  }

  validateEmail(event) {
    if (this.subform.get('email').valid) {
      this.validemail = true;
    } else {
      this.validemail = false;
    }
  }

  validdoa = false;
  validdob = false;
  validgender = false;
  validatedoa(event) {
    this.subform
      .get('doa')
      .setValue(
        formatDate(
          new Date(event.target.value),
          'yyyy-MM-dd',
          'en-IN',
          'GMT+0530'
        )
      );
    if (this.subform.get('doa').valid) {
      this.validdoa = true;
    } else {
      this.validdoa = false;
    }
  }



    allSelected = false;
    @ViewChild('mySel') skillSel: MatSelect;
    toggleAllSelection() {
      this.allSelected = !this.allSelected; // to control select-unselect
      // console.log(this.skillSel.options);
      if (this.allSelected) {
        this.skillSel.options.forEach((item: MatOption) => item.select());
      } else {
        this.skillSel.options.forEach((item: MatOption) => {
          item.deselect();
        });
      }
      this.skillSel.close();
    }

  showrcpt = false;
  showmsg = false;
  selected_booksellername(event, option) {
    if (event.isUserInput) {
      this.showrcpt = true;
      this.showmsg = false;
      this.booksDataofCrdtNote = [];
      this.displaybooks = false;
      this.selectedBooks = [];
      this.selectedbooks = false;
      this.service.getBookBillingData(option['name']).subscribe((data) => {
        this.BookBillingData = data;
        if (this.BookBillingData.length == 0) {
          this.showmsg = true;
        }
        setTimeout(() => {
          this.showrcpt = false;
        }, 3000);
      });
    }
  }

  displaybooks = false;
  booksDataofCrdtNote;
  billSelected(item) {
    this.service.getRcptWiseBooksForCreditNote(item['id']).subscribe((data) => {
      this.booksDataofCrdtNote = data;
      if (this.booksDataofCrdtNote.length > 0) {
        this.displaybooks = true;
      }
    });
  }

  selectedBooks = [];
  selectedbooks = false;
  booksSelected(item) {
    if (Object.keys(this.selectedBooks).length != 0) {
      let flag: Boolean = false;
      for (
        let index = 0;
        index < Object.keys(this.selectedBooks).length;
        index++
      ) {
        if (this.selectedBooks[index]['id'] == item['id']) {
          flag = true;
        }
      }
      if (!flag) {
        this.selectedBooks.push({
          id: item['id'],
          book_name: item['book_name'],
          copies: item['copies'],
          netamt: parseInt(item['netamt']) - parseInt(item['discountamt']),
          received_amt: item['received_amt'],
          remaining_amt: item['remaining_amt'],
          shipping_charges: item['shipping_charges'],
          total: parseInt(item['total']) + parseInt(item['shipping_charges']),
          total_amount: item['total_amount'],
          returncopies: 0,
          crdtnoteamt: 0,
          remark: '',
        });
        this.selectedbooks = true;
      }
    } else {
      this.selectedBooks.push({
        id: item['id'],
        book_name: item['book_name'],
        copies: item['copies'],
        netamt: parseInt(item['netamt']) - parseInt(item['discountamt']),
        received_amt: item['received_amt'],
        remaining_amt: item['remaining_amt'],
        shipping_charges: item['shipping_charges'],
        total: parseInt(item['total']) + parseInt(item['shipping_charges']),
        total_amount: item['total_amount'],
        returncopies: 0,
        crdtnoteamt: 0,
        remark: '',
      });
      this.selectedbooks = true;
    }
    // this.selectedbookslength = Object.keys(this.selectedBooks).length;
  }

  copies(event, item) {
    let val = event.target.value;
    for (
      let index = 0;
      index < Object.keys(this.selectedBooks).length;
      index++
    ) {
      if (this.selectedBooks[index]['id'] == item['id']) {
        this.selectedBooks[index]['returncopies'] = val;
      }
    }
  }

  crdtnotetotal = 0;
  crdtNoteAmt(event, item) {
    let val = event.target.value;
    this.crdtnotetotal = 0;
    for (
      let index = 0;
      index < Object.keys(this.selectedBooks).length;
      index++
    ) {
      if (this.selectedBooks[index]['id'] == item['id']) {
        this.selectedBooks[index]['crdtnoteamt'] = val;
      }
      this.crdtnotetotal =
        this.crdtnotetotal + parseInt(this.selectedBooks[index]['crdtnoteamt']);
    }
  }

  remark(event, item) {
    let val = event.target.value;
    for (
      let index = 0;
      index < Object.keys(this.selectedBooks).length;
      index++
    ) {
      if (this.selectedBooks[index]['id'] == item['id']) {
        this.selectedBooks[index]['remark'] = val;
      }
    }
  }

  submitCrdtNote() {
    let crdtnotedata = {
      crdtnoteno: this.bookcrdtnoteForm.get('crdtnoteno').value,
      date: this.bookcrdtnoteForm.get('date').value,
      repname: this.bookcrdtnoteForm.get('repname').value,
      offrepname: this.bookcrdtnoteForm.get('offrepname').value,
      bsname: this.bookcrdtnoteForm.get('bsname').value,
    };

    this.dialog.open(BookCrdtnoteRcptComponent, {
      data: {
        crdtnotedata: crdtnotedata,
        crdtnotetotal: this.crdtnotetotal,
        BookBillingData: this.BookBillingData,
        selectedBooks: this.selectedBooks,
      },
      height: '1300px',
      width: '1200px',
      maxWidth: '100%',
    });
  }

  validatedob(event) {
    this.subform
      .get('dob')
      .setValue(
        formatDate(
          new Date(event.target.value),
          'yyyy-MM-dd',
          'en-IN',
          'GMT+0530'
        )
      );
    if (this.subform.get('dob').valid) {
      this.validdob = true;
    } else {
      this.validdob = false;
    }
  }

  validatefname = false;
  validateName(event) {
    if (this.subform.get('name').valid) {
      this.validatefname = true;
    } else {
      this.validatefname = false;
    }
  }

  selected_repname(event, option) {
    if (event.isUserInput) {
      let data = this.bookwisereplist.filter(
        (a) => a['rep_name'] == option['name']
      );
      // bookwisereplist;
      // this.bookcrdtnoteForm.get('repmobno1').setValue(data[0]['rep_mob_no']);
      this.bookcrdtnoteForm.get('offrepname').setValue(data[0]['off_rep_name']);
    }
  }

  selected_rep_name(event, option) {
    if (event.isUserInput) {
      let data = this.bookwisereplist.filter(
        (a) => a['rep_name'] == option['name']
      );
      // bookwisereplist;
      this.subform.get('repmobno1').setValue(data[0]['rep_mob_no']);
      this.subform.get('officerep').setValue(data[0]['off_rep_name']);
    }
  }

  saveBookSellerEntry() {
    let bookseller = {
      title: this.subform.get('title').value,
      name: this.subform.get('name').value,
      address: this.subform.get('address').value,
      pincode: this.subform.get('pincode').value,
      country: this.subform.get('country').value,
      state: this.subform.get('state').value,
      district: this.subform.get('district').value,
      taluka: this.subform.get('taluka').value,
      mobileNumber: this.subform.get('mobileNumber').value,
      email: this.subform.get('email').value,
      dob: this.subform.get('dob').value,
      doa: this.subform.get('doa').value,
    };

    this.service.addBookSellerEntry(bookseller).subscribe(
      (data) => {
        this.booksellerid = data['id'];
        window.alert('Book Seller Added Successfully...');
        this.subform.reset();
      },
      (error) => {
        window.alert('The Book Seller is Already Present.');
      }
    );
  }

  addBookSellerEntry() {
    if (
      window.confirm(
        'This Operation is not reversable ,Please try to verify the details before confirmation!'
      )
    ) {
      if (!this.iscomplementary) {
        if (this.bookselleravailable) {
          // let bookseller = {
          //   title: this.subform.get('title').value,
          //   name: this.subform.get('name').value,
          //   address: this.subform.get('address').value,
          //   pincode: this.subform.get('pincode').value,
          //   country: this.subform.get('country').value,
          //   state: this.subform.get('state').value,
          //   district: this.subform.get('district').value,
          //   taluka: this.subform.get('taluka').value,
          //   mobileNumber: this.subform.get('mobileNumber').value,
          //   email: this.subform.get('email').value,
          //   dob: this.subform.get('dob').value,
          //   doa: this.subform.get('doa').value,
          // };

          // this.service.addBookSellerEntry(bookseller).subscribe((data) => {
          //   this.booksellerid = data['id'];

          let booksellerbookingdetails = {
            bse_id: this.booksellerid,
            orderno: this.subform.get('orderno').value,
            bookdate: this.subform.get('bookdate').value,
            dispatchto: this.subform.get('dispatchto').value,
            option: this.option,
            deliverymode: this.subform.get('deliverymode').value,
            crepname: this.subform.get('crepname').value,
            repmobno1: this.subform.get('repmobno1').value,
            officerepname: this.subform.get('officerep').value,
            repcompercent: this.subform.get('repcompercent').value,
            repcomprice: this.subform.get('repcomprice').value,
            cdno: this.subform.get('cdno').value,
            chequeDate: this.subform.get('chequeDate').value,
            bankname: this.subform.get('bankname').value,
            pymtmode: this.pymtmode,
            depositamt: this.subform.get('depositamt').value,
            invoice_generated: 'N',
            iscomplementary: 'N',
          };

          this.service
            .addBookSellerEntryBookingDetails(booksellerbookingdetails)
            .subscribe((data1) => {
              for (
                let index = 0;
                index < Object.keys(this.book_data).length;
                index++
              ) {
                let bookdata = {
                  bse_id: data1['id'],
                  book_name: this.book_data[index]['bookname'],
                  author_name: this.book_data[index]['author'],
                  book_price: this.book_data[index]['price'],
                  book_sale_price: this.book_data[index]['saleprice'],
                  discount: this.book_data[index]['discount'],
                  discountamt: this.book_data[index]['discountamt'],
                  totalad: this.book_data[index]['totalad'],
                  book_copies: this.book_data[index]['bookcopies'],
                  total: this.book_data[index]['total'],
                  total_amt: this.total_amt + this.shipping_total,
                  totalcopies: this.book_data[index]['totalcopies'],
                  shippingcharge: this.book_data[index]['shippingcharges'],
                  repcomprecent: this.book_data[index]['repcomprecent'],
                  commissionamt: this.book_data[index]['commissionamt'],
                };

                let available_stock;
                this.service
                  .getBookData(this.book_data[index]['bookid'])
                  .subscribe((data2) => {
                    available_stock =
                      parseInt(data2[0]['available_stock']) -
                      parseInt(this.book_data[index]['bookcopies']);
                    this.service
                      .UpdateNewBookAvailableStock(
                        this.book_data[index]['bookid'],
                        data2[0]['total_copies'],
                        available_stock
                      )
                      .subscribe((data3) => {});
                  });

                this.service.addBookData(bookdata).subscribe((a) => {});
              }
              setTimeout(() => {
                window.alert('Data Submitted Successfully...');
                this.subform.reset();
                this.book_data = [];
                location.reload();
              }, Object.keys(this.book_data).length * 500);
            });
        }
      } else {
        let booksellerbookingdetails = {
          bse_id: this.booksellerid,
          orderno: this.subform.get('orderno').value,
          bookdate: this.subform.get('bookdate').value,
          dispatchto: this.subform.get('dispatchto').value,
          option: this.option,
          deliverymode: this.subform.get('deliverymode').value,
          crepname: this.subform.get('crepname').value,
          repmobno1: this.subform.get('repmobno1').value,
          officerepname: this.subform.get('officerep').value,
          repcompercent: this.subform.get('repcompercent').value,
          repcomprice: this.subform.get('repcomprice').value,
          cdno: this.subform.get('cdno').value,
          chequeDate: this.subform.get('chequeDate').value,
          bankname: this.subform.get('bankname').value,
          pymtmode: this.pymtmode,
          depositamt: this.subform.get('depositamt').value,
          invoice_generated: 'Y',
          iscomplementary: 'Y',
        };

        this.service
          .addBookSellerEntryBookingDetails(booksellerbookingdetails)
          .subscribe((data1) => {
            for (
              let index = 0;
              index < Object.keys(this.book_data).length;
              index++
            ) {
              let bookdata = {
                bse_id: data1['id'],
                book_name: this.book_data[index]['bookname'],
                author_name: this.book_data[index]['author'],
                book_price: 0,
                book_sale_price: 0,
                discount: 0,
                discountamt: 0,
                totalad: 0,
                book_copies: this.book_data[index]['bookcopies'],
                total: this.book_data[index]['total'],
                total_amt: this.total_amt + this.shipping_total,
                totalcopies: this.book_data[index]['totalcopies'],
                shippingcharge: 0,
                repcomprecent: this.book_data[index]['repcomprecent'],
                commissionamt: this.book_data[index]['commissionamt'],
              };

              let available_stock;
              this.service
                .getBookData(this.book_data[index]['bookid'])
                .subscribe((data2) => {
                  available_stock =
                    parseInt(data2[0]['available_stock']) -
                    parseInt(this.book_data[index]['bookcopies']);
                  this.service
                    .UpdateNewBookAvailableStock(
                      this.book_data[index]['bookid'],
                      data2[0]['total_copies'],
                      available_stock
                    )
                    .subscribe((data3) => {});
                });

              this.service.addBookData(bookdata).subscribe((a) => {});
            }
            setTimeout(() => {
              window.alert('Data Submitted Successfully...');
              this.subform.reset();
              this.book_data = [];
              location.reload();
            }, Object.keys(this.book_data).length * 500);
          });
      }
    }
  }

  updatebookseller() {
    let bookseller = {
      bse_id: this.booksellerid,
      title: this.subform.get('title').value,
      name: this.subform.get('name').value,
      address: this.subform.get('address').value,
      pincode: this.subform.get('pincode').value,
      country: this.subform.get('country').value,
      state: this.subform.get('state').value,
      district: this.subform.get('district').value,
      taluka: this.subform.get('taluka').value,
      mobileNumber: this.subform.get('mobileNumber').value,
      email: this.subform.get('email').value,
      dob: this.subform.get('dob').value,
      doa: this.subform.get('doa').value,
    };

    this.service.updateBookSellerEntry(bookseller).subscribe((data) => {
      window.alert('Book Seller Details Updated Successfully....');
    });
  }

  remove(book) {
    this.tempdata = this.book_data;
    for (
      let index1 = 0;
      index1 <= Object.keys(this.tempdata).length;
      index1++
    ) {
      if (this.tempdata[index1]['bookid'] == book['bookid']) {
        this.tempdata.splice(index1, 1);
      }
      // if (Object.keys(this.tempdata).length == 0) {
      //   // this.tempdata = [];
      //   this.subform.get('bookname').reset();
      //   this.subform.get('authorname').reset();
      // }
    }
    for (let index = 0; index < Object.keys(this.book_data).length; index++) {
      if (this.book_data[index]['bookid'] == book['bookid']) {
        this.book_data.splice(index, 1);
      }
      if (Object.keys(this.book_data).length == 0) {
        // this.tempdata = [];
        this.subform.get('bookname').setValue('');
        this.subform.get('authorname').setValue('');
      }
    }

    this.total_amt = 0;
    this.shipping_total = 0;
    for (let index = 0; index < Object.keys(this.book_data).length; index++) {
      this.total_amt = this.total_amt + this.book_data[index]['total'];
      this.shipping_total =
        this.shipping_total +
        parseInt(this.book_data[index]['shippingcharges']);
    }
  }

  bookcopies = 0;
  total_amt = 0;
  bookcopies1(event, option) {
    this.total_amt = 0;
    this.amount = 0;
    this.bookcopies = event.target.value;
    console.log(this.bookcopies);

    if (!this.iscomplementary) {
      for (let index = 0; index < Object.keys(this.book_data).length; index++) {
        if (this.book_data[index]['bookid'] == option['bookid']) {
          console.log(option);
          if (parseInt(option['discount']) === 0) {
            console.log('discount 0');
            if (option['saleprice'] != 0) {
              this.amount = option['price'] - option['saleprice'];
              this.amt = option['price'] - this.amount;
              this.total =
                this.bookcopies * parseInt(this.book_data[index]['saleprice']);
            } else {
              // this.amount = option['price'] - option['saleprice'];
              // console.log(this.amount);
              console.log('saleprice is 0', option);
              this.amt = option['price'];
              this.total =
                this.bookcopies * parseInt(this.book_data[index]['price']);
              // if()
            }
          } else {
            this.amount =
              parseInt(option['price']) * (parseInt(option['discount']) / 100);
            this.total =
              parseInt(this.book_data[index]['totalad']) * this.bookcopies;
            console.log('!discount 0', this.total);
          }

          this.book_data[index]['bookcopies'] = this.bookcopies;
          this.book_data[index]['total'] = this.total;
          this.book_data[index]['totalad'] = this.amt;
          // this.book_data[index]['discount'] = this.discount;
          this.book_data[index]['discountamt'] = this.amount;
        }
        this.total_amt = this.total_amt + this.book_data[index]['total'];
      }
    } else {
      for (let index = 0; index < Object.keys(this.book_data).length; index++) {
        this.total = this.bookcopies * 0;
        if (this.book_data[index]['bookid'] == option['bookid']) {
          this.book_data[index]['bookcopies'] = this.bookcopies;
          this.book_data[index]['total'] = this.total;
        }
        this.total_amt = this.total_amt + this.book_data[index]['total'];
      }
    }
    this.discount = 0;
    this.bookcopies = 0;
    // this.subform.get('bookcopies').setValue(this.bookcopies);
  }

  shippingcharge = 0;
  shipping_total = 0;
  bookshipping(event, book) {
    this.shippingcharge = event.target.value;
    this.shipping_total = 0;
    for (let index = 0; index < Object.keys(this.book_data).length; index++) {
      if (this.book_data[index]['bookid'] == book['bookid']) {
        this.book_data[index]['shippingcharges'] = this.shippingcharge;
      }
      this.shipping_total =
        this.shipping_total +
        parseInt(this.book_data[index]['shippingcharges']);
    }
    this.shippingcharge = 0;
    // this.subform.get('shippingcharging').setValue(this.shippingcharge);
  }

  comprecentage: number;
  comprecentagetotal;
  commission: number;
  commissiontotal;
  calcommission(event, book) {
    this.comprecentagetotal = 0;
    this.commissiontotal = 0;
    this.comprecentage = event.target.value;
    for (let index = 0; index < Object.keys(this.book_data).length; index++) {
      if (this.book_data[index]['bookid'] == book['bookid']) {
        this.commission = Math.round(
          this.book_data[index]['total'] * (this.comprecentage / 100)
        );
        this.book_data[index]['commissionamt'] = this.commission;
        this.book_data[index]['repcomprecent'] = this.comprecentage;
      }
      this.comprecentagetotal =
        this.comprecentagetotal +
        parseInt(this.book_data[index]['repcomprecent']);
      this.commissiontotal =
        this.commissiontotal + parseInt(this.book_data[index]['commissionamt']);
    }
    this.comprecentage = 0;
    // this.subform.get('repcompercent').setValue(this.comprecentage);
  }

  tempdata = [];
  selected_id(event, option) {
    console.log(option);
    if (event.isUserInput) {
      console.log('isUserInput');
      this.subform.get('authorname').setValue(option['author']);
      this.subform.get('availablestock').setValue(option['available_stock']);
      this.service.getBookData(option['bookid']).subscribe((data) => {
        console.log(data);

        setTimeout(() => {
          this.tempdata.push({
            author: data[0]['author'],
            bookid: data[0]['bookid'],
            bookname: data[0]['bookname'],
            bookcopies: this.bookcopies,
            price: data[0]['price'],
            discount: this.discount,
            discountamt: this.amount,
            totalad: this.amt,
            saleprice: data[0]['saleprice'],
            total: this.total,
            totals: this.total_amt + this.shipping_total,
            totalcopies: data[0]['available_stock'],
            shippingcharges: this.shippingcharge,
            repcomprecent: this.comprecentage,
            commissionamt: this.commission,
          });

          console.log(this.tempdata);
          this.book_data = this.uniqueid(this.tempdata, (it) => it.bookid);
          console.log(this.book_data);
        }, 200);
        // this.tempdata = this.uniqueid(this.tempdata, (it) => it.bookid);
      });

      console.log(this.book_data);
    }
  }

  uniqueid(data, key) {
    return [...new Map(data.map((x) => [key(x), x])).values()];
  }

  bsname = '';
  tempdatas = [];
  selected(event, option) {
    if (event.isUserInput) {
      this.service.getBookSellerLedgerData(option['name']).subscribe((data) => {
        this.BookSellerLegderData = data;
        this.tempdatas = [];
        for (let index = 0; index < Object.keys(data).length; index++) {
          let flag: Boolean = false;
          if (Object.keys(this.tempdatas).length != 0) {
            this.tempdatas = data[index];
            console.log(this.tempdatas);
            // if(this.tempdatas[index]['invoice_no'])
            this.tempdatas[index]['invoice_no'] = '';
            this.tempdatas[index]['invoice_date'] = '0000-00-00';
            this.tempdatas[index]['invoice_total'] = '';
          } else {
            this.tempdatas = data[index];
            console.log(this.tempdatas);
          }
        }
        console.log(this.tempdatas);
      });
    }
  }

  booksellerid = 0;
  bookselleravailable: boolean = false;
  selected_name(event, id) {
    if (event.isUserInput) {
      this.service.getSubData(id.id).subscribe((data) => {
        console.log('data', data);
        this.booksellerid = data[0]['id'];
        this.bookselleravailable = true;
        this.subform.get('title').setValue(data[0]['title']);
        this.subform.get('mobileNumber').setValue(data[0]['mobno']);
        this.subform.get('name').setValue(data[0]['name']);
        this.subform.get('address').setValue(data[0]['address']);
        this.subform.get('pincode').setValue(data[0]['spin']);
        this.getCountry({ value: data[0]['country'] });
        setTimeout(() => {
          this.getState({ value: data[0]['state'] });
        }, 100);
        setTimeout(() => {
          this.getDistricts({ value: data[0]['city'] });
        }, 500);
        this.subform.get('country').setValue(data[0]['country']);
        this.subform.get('state').setValue(data[0]['state']);
        setTimeout(() => {
          this.subform.get('district').setValue(data[0]['city']);
          this.subform.get('taluka').setValue(data[0]['taluka']);
        }, 500);
        // this.subform.get('country').setValue(data[0]['country']);
        // this.subform.get('state').setValue({ state_name: data[0]['state'] });
        // this.subform.get('district').setValue({ name: data[0]['city'] });
        // this.subform.get('taluka').setValue(data[0]['taluka']);
        this.subform.get('email').setValue(data[0]['email']);
        this.subform.get('dob').setValue(data[0]['dob']);
        this.subform.get('doa').setValue(data[0]['anniversary']);
        // this.selectedCity = data[0]['city'];
        // this.selectedState = data[0]['state'];
      });
    }
    // this.enableDisableRule();
  }

  getPincode(event) {
    if (this.subform.get('pincode').valid) {
      // console.log(this.ionicForm.get('pincode').value);
      this.service
        .getPincodeData(parseInt(this.subform.get('pincode').value))
        .subscribe((data) => {
          // console.log('pincode data', data);
          this.getCountry({ value: data[0]['country_name'] });
          setTimeout(() => {
            this.getState({ value: data[0]['state_name'] });
          }, 100);
          setTimeout(() => {
            this.getDistricts({ value: data[0]['district_name'] });
          }, 500);
          this.subform.get('country').setValue(data[0]['country_name']);
          this.subform.get('state').setValue(data[0]['state_name']);
          this.subform.get('district').setValue(data[0]['district_name']);
          setTimeout(() => {
            this.subform.get('taluka').setValue(data[0]['taluka_name']);
          }, 500);
          // this.selectedCity = data[0]['district_name'];
          // this.selectedState = data[0]['state_name'];
          // this.selectedTaluka = data[0]['taluka_name'];
        });
    }
  } //getPincode

  // getPincode(event) {
  //   if (this.subform.get('pincode').valid) {
  //     this.service
  //       .getPincodeDetails(parseInt(this.subform.get('pincode').value))
  //       .subscribe((data) => {
  //         this.subform
  //           .get('taluka')
  //           .setValue(data[0]['PostOffice'][0]['Block']);
  //         this.getCountry({ value: data[0]['PostOffice'][0]['Country'] });
  //         this.subform
  //           .get('district')
  //           .setValue({ name: data[0]['PostOffice'][0]['District'] });
  //         this.subform
  //           .get('state')
  //           .setValue({ state_name: data[0]['PostOffice'][0]['State'] });
  //         this.subform
  //           .get('country')
  //           .setValue(data[0]['PostOffice'][0]['Country']);
  //         this.selectedCity = data[0]['PostOffice'][0]['District'];
  //         this.selectedState = data[0]['PostOffice'][0]['State'];
  //       });
  //   }
  // } //getPincode

  // getCountry(event) {
  //   let country = this.countries.find((a) => a.name == event.value);
  //   if (country != undefined) {
  //     this.service
  //       .getstates(parseInt(country['country_code']))
  //       .subscribe((data) => {
  //         this.states = data;
  //       });
  //   } else {
  //   }
  // } //getcountry

  getCountry(event) {
    // console.log(event);
    let country = this.countries.find((a) => a.country_name == event.value);
    // console.log(country);
    if (country != undefined) {
      this.service.getState(country['country_code']).subscribe((data) => {
        this.states = data;
      });
    } else {
    }
  } //getcountry

  showigst = false;
  getState(event) {
    // console.log(event);
    // console.log(this.states);
    this.selectedState = this.states['state_id'];
    let state = this.states.find((a) => a.state_name === event.value);
    // console.log(state);
    if (state != undefined) {
      if (
        state.state_name != 'Maharashtra' ||
        state.state_name != 'महाराष्ट्र'
      ) {
        this.showigst = true;
      } else {
        this.showigst = false;
      }

      this.service
        .getDistrict(parseInt(state['state_id']))
        .subscribe((data) => {
          this.cities = data;
          this.selectedCity = this.cities['district_name'];
          // console.log('cities', this.cities);
        });
    } else {
    }
  } //get state

  
  getDistricts(event) {
    console.log(event.value);
    this.selectedCity = this.cities['district_id'];
    let district = this.cities.find((a) => a.district_name == event.value);
    if (district != undefined) {
      
      const req={
        "district_id":[district['district_id']]
      }
      this.service.getTaluka1(req).subscribe((data) => {
        this.talukas = data;
        this.selectedTaluka = this.talukas['taluka_name'];
      });
    }
  }

  deliverymode;
  durationn(event) {
    this.deliverymode = event.value;
  }

  pymtmode;
  selectedpymtmode(event) {
    // this.subform.get('deliverymode').setValue(event.target.value);
    this.pymtmode = event.value;
  }

  getBookPayments() {
    this.service.getBookPendingPayment().subscribe((res) => {
      this.bookpaymentList = [];
      for (let index = 0; index < Object.keys(res).length; index++) {
        this.bookpaymentList.push({
          rep_id: parseInt(res[index]['repId']),
          name: res[index]['repName'],
          id: parseInt(res[index]['id']),
          claim_name: res[index]['name'],
          totalAmount: parseInt(res[index]['totalAmount']),
          payoptn: res[index]['payoptn'],
          repName: res[index]['repName'],
          repmobile: res[index]['repmobile'],
          rep_receipt: res[index]['rep_receipt'],
          task: 'book',
        });
      }
    });
  }

  displayBookPayment(paymentdetails) {
    this.dialog.open(SubscriptionAccountsComponent, {
      data: {
        rep: paymentdetails,
        view: false,
      },
    });
  }

  pendingbookdata;
  displayItemId(book, ele) {
    this.pendingbookdata = book;

    this.demo1BtnClick();
  } //end of displayId

  bookregistration_bookname = ['all'];

  readAllBookStock() {
    this.service.ReadAllBookStock().subscribe((bs) => {
      this.bookStock = bs;
    });
  }

  addbooksize() {
    if (this.booksizeform.get('booksize').value != '') {
      this.service
        .addbooksize(this.booksizeform.get('booksize').value)
        .subscribe((data) => {
          window.alert('Book size added successfully...');
          this.booksizeform.reset();
        });
    } else {
      window.alert('Please Enter the book size');
    }
  }

  ConvertToInt(val) {
    return parseFloat(val);
  }

  filterBookwiseReport;
  filterBookwiseReportTotal = {
    totalbookcopies: 0,
    totalbookprice: 0,
    totalafterdiscount: 0,
    total: 0,
    totalshipping_charges: 0,
    totaldepositamt: 0,
    totalamount: 0,
    totalstock: 0,
    availablestock: 0,
  };
  getBookwiseReport(book_name, start_date, end_date) {
    this.service
      .getBookwiseReport(book_name, start_date, end_date)
      .subscribe((data) => {
        this.filterBookwiseReport = data;
        console.log(this.filterBookwiseReport);
        this.filterBookwiseReportTotal = {
          totalbookcopies: 0,
          totalbookprice: 0,
          totalafterdiscount: 0,
          total: 0,
          totalshipping_charges: 0,
          totaldepositamt: 0,
          totalamount: 0,
          totalstock: 0,
          availablestock: 0,
        };
        for (
          let index = 0;
          index < Object.keys(this.filterBookwiseReport).length;
          index++
        ) {
          this.filterBookwiseReportTotal['totalbookcopies'] += parseFloat(
            this.filterBookwiseReport[index]['bookcopies']
          );
          this.filterBookwiseReportTotal['totalbookprice'] += parseFloat(
            this.filterBookwiseReport[index]['bookprice']
          );
          this.filterBookwiseReportTotal['totalafterdiscount'] += parseFloat(
            this.filterBookwiseReport[index]['totalafterdiscount']
          );
          this.filterBookwiseReportTotal['total'] += parseFloat(
            this.filterBookwiseReport[index]['total']
          );
          this.filterBookwiseReportTotal['totaldepositamt'] += parseFloat(
            this.filterBookwiseReport[index]['depositamt']
          );
          this.filterBookwiseReportTotal['totalshipping_charges'] += parseFloat(
            this.filterBookwiseReport[index]['shipping_charges']
          );
          this.filterBookwiseReportTotal['totalamount'] += parseFloat(
            this.filterBookwiseReport[index]['totalamount']
          );
          this.filterBookwiseReportTotal['totalstock'] += parseFloat(
            this.filterBookwiseReport[index]['totalstock']
          );
          this.filterBookwiseReportTotal['availablestock'] += parseFloat(
            this.filterBookwiseReport[index]['availablestock']
          );
        }

        setTimeout(() => {
          this.showbooks = false;
        }, 2000);
      });
  }

  option;
  select(event) {
    this.option = event.value;
  }

  showbooks = false;
  getAllDeliveryData() {
    this.bookDeliveryData = [];
    this.deliveredBooks = [];

    this.service.getalldeliverydetails().subscribe((data) => {
      for (let index = 0; index < Object.keys(data).length; index++) {
        if (data[index]['isdelivered'] == 'N') {
          this.bookDeliveryData.push(data[index]);
        } else {
          this.deliveredBooks.push(data[index]);
        }
      }
    });
  }

  /*name of the excel-file which will be downloaded. */
  fileName = 'ExcelSheet.xlsx';

  exportexcel(): void {
    /* table id is passed over here */
    let element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
  }

  displayDiscount = false;
  getSalePrice(event) {
    if (parseInt(event.target.value) > 0) {
      this.displayDiscount = true;
    } else {
      this.displayDiscount = false;
    }
  }

  displayucoming = false;
  getSearchTerm(event) {
    let status = event.target.value;
    if (status == 'Y') {
      this.displayucoming = true;
    } else {
      this.displayucoming = false;
    }
  }

  // EditBooks(id) {
  //   let book = this.bookData.find((a) => parseInt(a.bookid) == parseInt(id));
  //   let data = this.dialog.open(EditBookComponent, {
  //     data: {
  //       book: book,
  //       view: false,
  //     },
  //   });

  //   data.afterClosed().subscribe((a) => {
  //     this.ngOnInit();
  //   });
  // }

  EditBooks(id) {
    let book = this.bookData.find((a) => parseInt(a.bookid) == parseInt(id));
    let data = this.dialog.open(EditBookComponent, {
      data: {
        book: book,
        view: false,
      },
    });
  
    data.afterClosed().subscribe((a) => {
      if (a) {  // Ensure dialog returned data before reloading
        this.ngOnInit();
        setTimeout(() => {
          this.caltotalpages(); // Recalculate total pages after form updates
        }, 0);
      }
    });
  }
  



  ViewBooks(id) {
    let book = this.bookData.find((a) => parseInt(a.bookid) == parseInt(id));
    this.dialog.open(ViewBookComponent, {
      data: {
        book: book,
        view: false,
      },
    });
  }

  update(item) {
    this.dialog.open(UpdateOfficeRepresentativeComponent, {
      data: {
        item: item,
        type: 'book',
      },
    });
    this.service.getBookWiseRep().subscribe((data) => {
      this.bookwisereplist = data;
    });
  }

  removebook(id) {
    if (window.confirm('Do you really want to remove the book?')) {
      this.service.removeBook(id).subscribe((data) => {
        this.service.getAllBookData().subscribe((data) => {
          this.bookData = data;
          this.bookdata = this.uniqueid(this.bookData, (it) => it.bookid);
        });
      });
    }
  }

  ordinal_suffix_of(i) {
    var j = i % 10,
      k = i % 100;
    if (j == 1 && k != 11) {
      return i + 'st';
    }
    if (j == 2 && k != 12) {
      return i + 'nd';
    }
    if (j == 3 && k != 13) {
      return i + 'rd';
    }
    return i + 'th';
  }

  bookavailable: Boolean = false;
  selected_bookname(option) {
    this.service.getBookEdition(option['bookname']).subscribe((data) => {
      this.edition =
        this.ordinal_suffix_of(parseInt(data[0]['count']) + 1) + ' Edition';
      this.BookForm.get('bookedition').setValue(this.edition);
    });

    for (let index = 0; index < Object.keys(this.bookData).length; index++) {
      if (this.bookData[index]['bookname'] == option['bookname']) {
        this.bookavailable = true;
        this.book_id = this.bookData[index]['bookid'];
        this.BookForm.get('bookdesc').setValue(
          this.bookData[index]['bookdesc']
        );
        this.BookForm.get('authorname').setValue(
          this.bookData[index]['author']
        );
        this.BookForm.get('bookpagesbw').setValue(
          this.bookData[index]['bkpagesbw']
        );
        this.BookForm.get('bkpinsidecolor').setValue(
          this.bookData[index]['bkpinsidecolor']
        );
        this.BookForm.get('bkpcover').setValue(
          this.bookData[index]['bkpcover']
        );
        this.BookForm.get('totalpages').setValue(
          this.bookData[index]['book_pages']
        );
        this.BookForm.get('booksize').setValue(
          this.bookData[index]['booksize']
        );
        this.BookForm.get('paperbw').setValue(this.bookData[index]['paperbw']);
        this.BookForm.get('paperinsidecolor').setValue(
          this.bookData[index]['paperinsidecolor']
        );
        this.BookForm.get('papercover').setValue(
          this.bookData[index]['papercover']
        );
        this.BookForm.get('binding').setValue(this.bookData[index]['binding']);
        this.BookForm.get('booklang').setValue(
          this.bookData[index]['language']
        );
        this.BookForm.get('bookcategory').setValue(
          this.bookData[index]['bookcategory']
        );
        this.BookForm.get('isupcoming').setValue(
          this.bookData[index]['isupcoming']
        );
        this.BookForm.get('upcomingdate').setValue(
          this.bookData[index]['upcomingdate']
        );
      }
    }
  }

  start_date1;
  end_date1;
  getbookoutstaningdate() {
    if (
      this.bookoutstandingform.get('start').value != null &&
      this.bookoutstandingform.get('end').value != null
    ) {
      this.start_date1 = formatDate(
        new Date(this.bookoutstandingform.get('start').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );
      this.end_date1 = formatDate(
        new Date(this.bookoutstandingform.get('end').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );
    }
  }

  officerepname = '';
  getofficerepname(event) {
    this.officerepname = event.target.value;
  }

  contractReportType = '';
  getContractReportType(event) {
    // console.log(event.value);
    this.contractReportType = event.value;
  }
  repname1 = '';
  getrepname(event) {
    this.repname1 = event.target.value;
  }

  booksellername1 = '';
  getbooksellername(event) {
    this.booksellername1 = event.target.value;
  }

  book_name1 = '';
  getbookname(event) {
    this.book_name1 = event.target.value;
  }

  getTotalBilling() {
    if (
      this.start_date1 != undefined &&
      this.end_date1 != undefined &&
      this.officerepname == '' &&
      this.repname1 == '' &&
      this.booksellername1 == '' &&
      this.book_name1 == ''
    ) {
      this.Filteredbookoutstandingdata = this.bookoutstandingdata.filter(
        (a) =>
          a['invoice_date'] >= this.start_date1 &&
          a['invoice_date'] <= this.end_date1
      );
    } else if (
      this.start_date1 == undefined &&
      this.end_date1 == undefined &&
      this.officerepname != '' &&
      this.repname1 == '' &&
      this.booksellername1 == '' &&
      this.book_name1 == ''
    ) {
      this.Filteredbookoutstandingdata = this.bookoutstandingdata.filter(
        (a) =>
          a.office_rep_name
            .toLowerCase()
            .indexOf(this.officerepname.toLowerCase()) > -1
      );
    } else if (
      this.start_date1 == undefined &&
      this.end_date1 == undefined &&
      this.officerepname == '' &&
      this.repname1 != '' &&
      this.booksellername1 == '' &&
      this.book_name1 == ''
    ) {
      this.Filteredbookoutstandingdata = this.bookoutstandingdata.filter(
        (a) =>
          a.rep_name.toLowerCase().indexOf(this.repname1.toLowerCase()) > -1
      );
    } else if (
      this.start_date1 == undefined &&
      this.end_date1 == undefined &&
      this.officerepname == '' &&
      this.repname1 == '' &&
      this.booksellername1 != '' &&
      this.book_name1 == ''
    ) {
      this.Filteredbookoutstandingdata = this.bookoutstandingdata.filter(
        (a) =>
          a.name.toLowerCase().indexOf(this.booksellername1.toLowerCase()) > -1
      );
    } else if (
      this.start_date1 == undefined &&
      this.end_date1 == undefined &&
      this.officerepname == '' &&
      this.repname1 == '' &&
      this.booksellername1 == '' &&
      this.book_name1 != ''
    ) {
      this.Filteredbookoutstandingdata = this.bookoutstandingdata.filter(
        (a) =>
          a.book_name.toLowerCase().indexOf(this.book_name1.toLowerCase()) > -1
      );
    } else if (
      this.start_date1 != undefined &&
      this.end_date1 != undefined &&
      this.officerepname != '' &&
      this.repname1 == '' &&
      this.booksellername1 == '' &&
      this.book_name1 == ''
    ) {
      this.Filteredbookoutstandingdata = this.bookoutstandingdata.filter(
        (a) =>
          a['invoice_date'] >= this.start_date1 &&
          a['invoice_date'] <= this.end_date1 &&
          a.office_rep_name
            .toLowerCase()
            .indexOf(this.officerepname.toLowerCase()) > -1
      );
    } else if (
      this.start_date1 != undefined &&
      this.end_date1 != undefined &&
      this.officerepname == '' &&
      this.repname1 != '' &&
      this.booksellername1 == '' &&
      this.book_name1 == ''
    ) {
      this.Filteredbookoutstandingdata = this.bookoutstandingdata.filter(
        (a) =>
          a['invoice_date'] >= this.start_date1 &&
          a['invoice_date'] <= this.end_date1 &&
          a.rep_name.toLowerCase().indexOf(this.repname1.toLowerCase()) > -1
      );
    } else if (
      this.start_date1 != undefined &&
      this.end_date1 != undefined &&
      this.officerepname == '' &&
      this.repname1 == '' &&
      this.booksellername1 != '' &&
      this.book_name1 == ''
    ) {
      this.Filteredbookoutstandingdata = this.bookoutstandingdata.filter(
        (a) =>
          a['invoice_date'] >= this.start_date1 &&
          a['invoice_date'] <= this.end_date1 &&
          a.name.toLowerCase().indexOf(this.booksellername1.toLowerCase()) > -1
      );
    } else if (
      this.start_date1 != undefined &&
      this.end_date1 != undefined &&
      this.officerepname == '' &&
      this.repname1 == '' &&
      this.booksellername1 == '' &&
      this.book_name1 != ''
    ) {
      this.Filteredbookoutstandingdata = this.bookoutstandingdata.filter(
        (a) =>
          a['invoice_date'] >= this.start_date1 &&
          a['invoice_date'] <= this.end_date1 &&
          a.book_name.toLowerCase().indexOf(this.book_name1.toLowerCase()) > -1
      );
    } else if (
      this.start_date1 == undefined &&
      this.end_date1 == undefined &&
      this.officerepname != '' &&
      this.repname1 != '' &&
      this.booksellername1 == '' &&
      this.book_name1 == ''
    ) {
      this.Filteredbookoutstandingdata = this.bookoutstandingdata.filter(
        (a) =>
          a.office_rep_name
            .toLowerCase()
            .indexOf(this.officerepname.toLowerCase()) > -1 &&
          a.rep_name.toLowerCase().indexOf(this.repname1.toLowerCase()) > -1
      );
    } else if (
      this.start_date1 == undefined &&
      this.end_date1 == undefined &&
      this.officerepname != '' &&
      this.repname1 == '' &&
      this.booksellername1 != '' &&
      this.book_name1 == ''
    ) {
      this.Filteredbookoutstandingdata = this.bookoutstandingdata.filter(
        (a) =>
          a.office_rep_name
            .toLowerCase()
            .indexOf(this.officerepname.toLowerCase()) > -1 &&
          a.name.toLowerCase().indexOf(this.booksellername1.toLowerCase()) > -1
      );
    } else if (
      this.start_date1 == undefined &&
      this.end_date1 == undefined &&
      this.officerepname != '' &&
      this.repname1 == '' &&
      this.booksellername1 == '' &&
      this.book_name1 != ''
    ) {
      this.Filteredbookoutstandingdata = this.bookoutstandingdata.filter(
        (a) =>
          a.office_rep_name
            .toLowerCase()
            .indexOf(this.officerepname.toLowerCase()) > -1 &&
          a.book_name.toLowerCase().indexOf(this.book_name1.toLowerCase()) > -1
      );
    } else if (
      this.start_date1 == undefined &&
      this.end_date1 == undefined &&
      this.officerepname == '' &&
      this.repname1 != '' &&
      this.booksellername1 != '' &&
      this.book_name1 == ''
    ) {
      this.Filteredbookoutstandingdata = this.bookoutstandingdata.filter(
        (a) =>
          a.rep_name.toLowerCase().indexOf(this.repname1.toLowerCase()) > -1 &&
          a.name.toLowerCase().indexOf(this.booksellername1.toLowerCase()) > -1
      );
    } else if (
      this.start_date1 == undefined &&
      this.end_date1 == undefined &&
      this.officerepname == '' &&
      this.repname1 != '' &&
      this.booksellername1 == '' &&
      this.book_name1 != ''
    ) {
      this.Filteredbookoutstandingdata = this.bookoutstandingdata.filter(
        (a) =>
          a.rep_name.toLowerCase().indexOf(this.repname1.toLowerCase()) > -1 &&
          a.book_name.toLowerCase().indexOf(this.book_name1.toLowerCase()) > -1
      );
    } else if (
      this.start_date1 == undefined &&
      this.end_date1 == undefined &&
      this.officerepname == '' &&
      this.repname1 == '' &&
      this.booksellername1 != '' &&
      this.book_name1 != ''
    ) {
      this.Filteredbookoutstandingdata = this.bookoutstandingdata.filter(
        (a) =>
          a.name.toLowerCase().indexOf(this.booksellername1.toLowerCase()) >
            -1 &&
          a.book_name.toLowerCase().indexOf(this.book_name1.toLowerCase()) > -1
      );
    } else if (
      this.start_date1 != undefined &&
      this.end_date1 != undefined &&
      this.officerepname != '' &&
      this.repname1 != '' &&
      this.booksellername1 == '' &&
      this.book_name1 == ''
    ) {
      this.Filteredbookoutstandingdata = this.bookoutstandingdata.filter(
        (a) =>
          a['invoice_date'] >= this.start_date1 &&
          a['invoice_date'] <= this.end_date1 &&
          a.office_rep_name
            .toLowerCase()
            .indexOf(this.officerepname.toLowerCase()) > -1 &&
          a.rep_name.toLowerCase().indexOf(this.repname1.toLowerCase()) > -1
      );
    } else if (
      this.start_date1 != undefined &&
      this.end_date1 != undefined &&
      this.officerepname != '' &&
      this.repname1 == '' &&
      this.booksellername1 != '' &&
      this.book_name1 == ''
    ) {
      this.Filteredbookoutstandingdata = this.bookoutstandingdata.filter(
        (a) =>
          a['invoice_date'] >= this.start_date1 &&
          a['invoice_date'] <= this.end_date1 &&
          a.office_rep_name
            .toLowerCase()
            .indexOf(this.officerepname.toLowerCase()) > -1 &&
          a.name.toLowerCase().indexOf(this.booksellername1.toLowerCase()) > -1
      );
    } else if (
      this.start_date1 != undefined &&
      this.end_date1 != undefined &&
      this.officerepname != '' &&
      this.repname1 == '' &&
      this.booksellername1 == '' &&
      this.book_name1 != ''
    ) {
      this.Filteredbookoutstandingdata = this.bookoutstandingdata.filter(
        (a) =>
          a['invoice_date'] >= this.start_date1 &&
          a['invoice_date'] <= this.end_date1 &&
          a.office_rep_name
            .toLowerCase()
            .indexOf(this.officerepname.toLowerCase()) > -1 &&
          a.book_name.toLowerCase().indexOf(this.book_name1.toLowerCase()) > -1
      );
    } else if (
      this.start_date1 != undefined &&
      this.end_date1 != undefined &&
      this.officerepname == '' &&
      this.repname1 != '' &&
      this.booksellername1 != '' &&
      this.book_name1 == ''
    ) {
      this.Filteredbookoutstandingdata = this.bookoutstandingdata.filter(
        (a) =>
          a['invoice_date'] >= this.start_date1 &&
          a['invoice_date'] <= this.end_date1 &&
          a.rep_name.toLowerCase().indexOf(this.repname1.toLowerCase()) > -1 &&
          a.name.toLowerCase().indexOf(this.booksellername1.toLowerCase()) > -1
      );
    } else if (
      this.start_date1 != undefined &&
      this.end_date1 != undefined &&
      this.officerepname == '' &&
      this.repname1 != '' &&
      this.booksellername1 == '' &&
      this.book_name1 != ''
    ) {
      this.Filteredbookoutstandingdata = this.bookoutstandingdata.filter(
        (a) =>
          a['invoice_date'] >= this.start_date1 &&
          a['invoice_date'] <= this.end_date1 &&
          a.rep_name.toLowerCase().indexOf(this.repname1.toLowerCase()) > -1 &&
          a.book_name.toLowerCase().indexOf(this.book_name1.toLowerCase()) > -1
      );
    } else if (
      this.start_date1 != undefined &&
      this.end_date1 != undefined &&
      this.officerepname == '' &&
      this.repname1 == '' &&
      this.booksellername1 != '' &&
      this.book_name1 != ''
    ) {
      this.Filteredbookoutstandingdata = this.bookoutstandingdata.filter(
        (a) =>
          a['invoice_date'] >= this.start_date1 &&
          a['invoice_date'] <= this.end_date1 &&
          a.name.toLowerCase().indexOf(this.booksellername1.toLowerCase()) >
            -1 &&
          a.book_name.toLowerCase().indexOf(this.book_name1.toLowerCase()) > -1
      );
    } else if (
      this.start_date1 == undefined &&
      this.end_date1 == undefined &&
      this.officerepname == '' &&
      this.repname1 != '' &&
      this.booksellername1 != '' &&
      this.book_name1 != ''
    ) {
      this.Filteredbookoutstandingdata = this.bookoutstandingdata.filter(
        (a) =>
          a.rep_name.toLowerCase().indexOf(this.repname1.toLowerCase()) > -1 &&
          a.name.toLowerCase().indexOf(this.booksellername1.toLowerCase()) >
            -1 &&
          a.book_name.toLowerCase().indexOf(this.book_name1.toLowerCase()) > -1
      );
    } else if (
      this.start_date1 != undefined &&
      this.end_date1 != undefined &&
      this.officerepname != '' &&
      this.repname1 != '' &&
      this.booksellername1 != '' &&
      this.book_name1 == ''
    ) {
      this.Filteredbookoutstandingdata = this.bookoutstandingdata.filter(
        (a) =>
          a['invoice_date'] >= this.start_date1 &&
          a['invoice_date'] <= this.end_date1 &&
          a.office_rep_name
            .toLowerCase()
            .indexOf(this.officerepname.toLowerCase()) > -1 &&
          a.rep_name.toLowerCase().indexOf(this.repname1.toLowerCase()) > -1 &&
          a.name.toLowerCase().indexOf(this.booksellername1.toLowerCase()) > -1
      );
    } else if (
      this.start_date1 != undefined &&
      this.end_date1 != undefined &&
      this.officerepname != '' &&
      this.repname1 != '' &&
      this.booksellername1 == '' &&
      this.book_name1 != ''
    ) {
      this.Filteredbookoutstandingdata = this.bookoutstandingdata.filter(
        (a) =>
          a['invoice_date'] >= this.start_date1 &&
          a['invoice_date'] <= this.end_date1 &&
          a.office_rep_name
            .toLowerCase()
            .indexOf(this.officerepname.toLowerCase()) > -1 &&
          a.rep_name.toLowerCase().indexOf(this.repname1.toLowerCase()) > -1 &&
          a.book_name.toLowerCase().indexOf(this.book_name1.toLowerCase()) > -1
      );
    } else if (
      this.start_date1 != undefined &&
      this.end_date1 != undefined &&
      this.officerepname != '' &&
      this.repname1 == '' &&
      this.booksellername1 != '' &&
      this.book_name1 != ''
    ) {
      this.Filteredbookoutstandingdata = this.bookoutstandingdata.filter(
        (a) =>
          a['invoice_date'] >= this.start_date1 &&
          a['invoice_date'] <= this.end_date1 &&
          a.office_rep_name
            .toLowerCase()
            .indexOf(this.officerepname.toLowerCase()) > -1 &&
          a.name.toLowerCase().indexOf(this.booksellername1.toLowerCase()) >
            -1 &&
          a.book_name.toLowerCase().indexOf(this.book_name1.toLowerCase()) > -1
      );
    } else if (
      this.start_date1 != undefined &&
      this.end_date1 != undefined &&
      this.officerepname == '' &&
      this.repname1 != '' &&
      this.booksellername1 != '' &&
      this.book_name1 != ''
    ) {
      this.Filteredbookoutstandingdata = this.bookoutstandingdata.filter(
        (a) =>
          a['invoice_date'] >= this.start_date1 &&
          a['invoice_date'] <= this.end_date1 &&
          a.rep_name.toLowerCase().indexOf(this.repname1.toLowerCase()) > -1 &&
          a.name.toLowerCase().indexOf(this.booksellername1.toLowerCase()) >
            -1 &&
          a.book_name.toLowerCase().indexOf(this.book_name1.toLowerCase()) > -1
      );
    } else {
      this.service.getBookOutStandingData().subscribe((data) => {
        this.bookoutstandingdata = data;
        this.Filteredbookoutstandingdata = this.bookoutstandingdata;
      });
    }

    console.log(
      'this.Filteredbookoutstandingdata',
      this.Filteredbookoutstandingdata
    );

    this.dialog.open(ShowBookSellerOutstandingDataComponent, {
      data: {
        officerepname: this.officerepname,
        booksellerdata: this.Filteredbookoutstandingdata,
      },
      height: '1300px',
      width: '1200px',
      maxWidth: '100%',
    });
  }

  getPendingBilling() {
    if (
      this.start_date1 != undefined &&
      this.end_date1 != undefined &&
      this.officerepname == '' &&
      this.repname1 == '' &&
      this.booksellername1 == '' &&
      this.book_name1 == ''
    ) {
      this.Filteredbookoutstandingdata = this.bookoutstandingdata.filter(
        (a) =>
          a['invoice_date'] >= this.start_date1 &&
          a['invoice_date'] <= this.end_date1 &&
          a['remaining_amt'] > 0
      );
    } else if (
      this.start_date1 == undefined &&
      this.end_date1 == undefined &&
      this.officerepname != '' &&
      this.repname1 == '' &&
      this.booksellername1 == '' &&
      this.book_name1 == ''
    ) {
      this.Filteredbookoutstandingdata = this.bookoutstandingdata.filter(
        (a) =>
          a.office_rep_name
            .toLowerCase()
            .indexOf(this.officerepname.toLowerCase()) > -1 &&
          a['remaining_amt'] > 0
      );
    } else if (
      this.start_date1 == undefined &&
      this.end_date1 == undefined &&
      this.officerepname == '' &&
      this.repname1 != '' &&
      this.booksellername1 == '' &&
      this.book_name1 == ''
    ) {
      this.Filteredbookoutstandingdata = this.bookoutstandingdata.filter(
        (a) =>
          a.rep_name.toLowerCase().indexOf(this.repname1.toLowerCase()) > -1 &&
          a['remaining_amt'] > 0
      );
    } else if (
      this.start_date1 == undefined &&
      this.end_date1 == undefined &&
      this.officerepname == '' &&
      this.repname1 == '' &&
      this.booksellername1 != '' &&
      this.book_name1 == ''
    ) {
      this.Filteredbookoutstandingdata = this.bookoutstandingdata.filter(
        (a) =>
          a.name.toLowerCase().indexOf(this.booksellername1.toLowerCase()) >
            -1 && a['remaining_amt'] > 0
      );
    } else if (
      this.start_date1 == undefined &&
      this.end_date1 == undefined &&
      this.officerepname == '' &&
      this.repname1 == '' &&
      this.booksellername1 == '' &&
      this.book_name1 != ''
    ) {
      this.Filteredbookoutstandingdata = this.bookoutstandingdata.filter(
        (a) =>
          a.book_name.toLowerCase().indexOf(this.book_name1.toLowerCase()) >
            -1 && a['remaining_amt'] > 0
      );
    } else if (
      this.start_date1 != undefined &&
      this.end_date1 != undefined &&
      this.officerepname != '' &&
      this.repname1 == '' &&
      this.booksellername1 == '' &&
      this.book_name1 == ''
    ) {
      this.Filteredbookoutstandingdata = this.bookoutstandingdata.filter(
        (a) =>
          a['invoice_date'] >= this.start_date1 &&
          a['invoice_date'] <= this.end_date1 &&
          a.office_rep_name
            .toLowerCase()
            .indexOf(this.officerepname.toLowerCase()) > -1 &&
          a['remaining_amt'] > 0
      );
    } else if (
      this.start_date1 != undefined &&
      this.end_date1 != undefined &&
      this.officerepname == '' &&
      this.repname1 != '' &&
      this.booksellername1 == '' &&
      this.book_name1 == ''
    ) {
      this.Filteredbookoutstandingdata = this.bookoutstandingdata.filter(
        (a) =>
          a['invoice_date'] >= this.start_date1 &&
          a['invoice_date'] <= this.end_date1 &&
          a.rep_name.toLowerCase().indexOf(this.repname1.toLowerCase()) > -1 &&
          a['remaining_amt'] > 0
      );
    } else if (
      this.start_date1 != undefined &&
      this.end_date1 != undefined &&
      this.officerepname == '' &&
      this.repname1 == '' &&
      this.booksellername1 != '' &&
      this.book_name1 == ''
    ) {
      this.Filteredbookoutstandingdata = this.bookoutstandingdata.filter(
        (a) =>
          a['invoice_date'] >= this.start_date1 &&
          a['invoice_date'] <= this.end_date1 &&
          a.name.toLowerCase().indexOf(this.booksellername1.toLowerCase()) >
            -1 &&
          a['remaining_amt'] > 0
      );
    } else if (
      this.start_date1 != undefined &&
      this.end_date1 != undefined &&
      this.officerepname == '' &&
      this.repname1 == '' &&
      this.booksellername1 == '' &&
      this.book_name1 != ''
    ) {
      this.Filteredbookoutstandingdata = this.bookoutstandingdata.filter(
        (a) =>
          a['invoice_date'] >= this.start_date1 &&
          a['invoice_date'] <= this.end_date1 &&
          a.book_name.toLowerCase().indexOf(this.book_name1.toLowerCase()) >
            -1 &&
          a['remaining_amt'] > 0
      );
    } else if (
      this.start_date1 == undefined &&
      this.end_date1 == undefined &&
      this.officerepname != '' &&
      this.repname1 != '' &&
      this.booksellername1 == '' &&
      this.book_name1 == ''
    ) {
      this.Filteredbookoutstandingdata = this.bookoutstandingdata.filter(
        (a) =>
          a.office_rep_name
            .toLowerCase()
            .indexOf(this.officerepname.toLowerCase()) > -1 &&
          a.rep_name.toLowerCase().indexOf(this.repname1.toLowerCase()) > -1 &&
          a['remaining_amt'] > 0
      );
    } else if (
      this.start_date1 == undefined &&
      this.end_date1 == undefined &&
      this.officerepname != '' &&
      this.repname1 == '' &&
      this.booksellername1 != '' &&
      this.book_name1 == ''
    ) {
      this.Filteredbookoutstandingdata = this.bookoutstandingdata.filter(
        (a) =>
          a.office_rep_name
            .toLowerCase()
            .indexOf(this.officerepname.toLowerCase()) > -1 &&
          a.name.toLowerCase().indexOf(this.booksellername1.toLowerCase()) >
            -1 &&
          a['remaining_amt'] > 0
      );
    } else if (
      this.start_date1 == undefined &&
      this.end_date1 == undefined &&
      this.officerepname != '' &&
      this.repname1 == '' &&
      this.booksellername1 == '' &&
      this.book_name1 != ''
    ) {
      this.Filteredbookoutstandingdata = this.bookoutstandingdata.filter(
        (a) =>
          a.office_rep_name
            .toLowerCase()
            .indexOf(this.officerepname.toLowerCase()) > -1 &&
          a.book_name.toLowerCase().indexOf(this.book_name1.toLowerCase()) >
            -1 &&
          a['remaining_amt'] > 0
      );
    } else if (
      this.start_date1 == undefined &&
      this.end_date1 == undefined &&
      this.officerepname == '' &&
      this.repname1 != '' &&
      this.booksellername1 != '' &&
      this.book_name1 == ''
    ) {
      this.Filteredbookoutstandingdata = this.bookoutstandingdata.filter(
        (a) =>
          a.rep_name.toLowerCase().indexOf(this.repname1.toLowerCase()) > -1 &&
          a.name.toLowerCase().indexOf(this.booksellername1.toLowerCase()) >
            -1 &&
          a['remaining_amt'] > 0
      );
    } else if (
      this.start_date1 == undefined &&
      this.end_date1 == undefined &&
      this.officerepname == '' &&
      this.repname1 != '' &&
      this.booksellername1 == '' &&
      this.book_name1 != ''
    ) {
      this.Filteredbookoutstandingdata = this.bookoutstandingdata.filter(
        (a) =>
          a.rep_name.toLowerCase().indexOf(this.repname1.toLowerCase()) > -1 &&
          a.book_name.toLowerCase().indexOf(this.book_name1.toLowerCase()) >
            -1 &&
          a['remaining_amt'] > 0
      );
    } else if (
      this.start_date1 == undefined &&
      this.end_date1 == undefined &&
      this.officerepname == '' &&
      this.repname1 == '' &&
      this.booksellername1 != '' &&
      this.book_name1 != ''
    ) {
      this.Filteredbookoutstandingdata = this.bookoutstandingdata.filter(
        (a) =>
          a.name.toLowerCase().indexOf(this.booksellername1.toLowerCase()) >
            -1 &&
          a.book_name.toLowerCase().indexOf(this.book_name1.toLowerCase()) >
            -1 &&
          a['remaining_amt'] > 0
      );
    } else if (
      this.start_date1 != undefined &&
      this.end_date1 != undefined &&
      this.officerepname != '' &&
      this.repname1 != '' &&
      this.booksellername1 == '' &&
      this.book_name1 == ''
    ) {
      this.Filteredbookoutstandingdata = this.bookoutstandingdata.filter(
        (a) =>
          a['invoice_date'] >= this.start_date1 &&
          a['invoice_date'] <= this.end_date1 &&
          a.office_rep_name
            .toLowerCase()
            .indexOf(this.officerepname.toLowerCase()) > -1 &&
          a.rep_name.toLowerCase().indexOf(this.repname1.toLowerCase()) > -1 &&
          a['remaining_amt'] > 0
      );
    } else if (
      this.start_date1 != undefined &&
      this.end_date1 != undefined &&
      this.officerepname != '' &&
      this.repname1 == '' &&
      this.booksellername1 != '' &&
      this.book_name1 == ''
    ) {
      this.Filteredbookoutstandingdata = this.bookoutstandingdata.filter(
        (a) =>
          a['invoice_date'] >= this.start_date1 &&
          a['invoice_date'] <= this.end_date1 &&
          a.office_rep_name
            .toLowerCase()
            .indexOf(this.officerepname.toLowerCase()) > -1 &&
          a.name.toLowerCase().indexOf(this.booksellername1.toLowerCase()) >
            -1 &&
          a['remaining_amt'] > 0
      );
    } else if (
      this.start_date1 != undefined &&
      this.end_date1 != undefined &&
      this.officerepname != '' &&
      this.repname1 == '' &&
      this.booksellername1 == '' &&
      this.book_name1 != ''
    ) {
      this.Filteredbookoutstandingdata = this.bookoutstandingdata.filter(
        (a) =>
          a['invoice_date'] >= this.start_date1 &&
          a['invoice_date'] <= this.end_date1 &&
          a.office_rep_name
            .toLowerCase()
            .indexOf(this.officerepname.toLowerCase()) > -1 &&
          a.book_name.toLowerCase().indexOf(this.book_name1.toLowerCase()) >
            -1 &&
          a['remaining_amt'] > 0
      );
    } else if (
      this.start_date1 != undefined &&
      this.end_date1 != undefined &&
      this.officerepname == '' &&
      this.repname1 != '' &&
      this.booksellername1 != '' &&
      this.book_name1 == ''
    ) {
      this.Filteredbookoutstandingdata = this.bookoutstandingdata.filter(
        (a) =>
          a['invoice_date'] >= this.start_date1 &&
          a['invoice_date'] <= this.end_date1 &&
          a.rep_name.toLowerCase().indexOf(this.repname1.toLowerCase()) > -1 &&
          a.name.toLowerCase().indexOf(this.booksellername1.toLowerCase()) >
            -1 &&
          a['remaining_amt'] > 0
      );
    } else if (
      this.start_date1 != undefined &&
      this.end_date1 != undefined &&
      this.officerepname == '' &&
      this.repname1 != '' &&
      this.booksellername1 == '' &&
      this.book_name1 != ''
    ) {
      this.Filteredbookoutstandingdata = this.bookoutstandingdata.filter(
        (a) =>
          a['invoice_date'] >= this.start_date1 &&
          a['invoice_date'] <= this.end_date1 &&
          a.rep_name.toLowerCase().indexOf(this.repname1.toLowerCase()) > -1 &&
          a.book_name.toLowerCase().indexOf(this.book_name1.toLowerCase()) >
            -1 &&
          a['remaining_amt'] > 0
      );
    } else if (
      this.start_date1 != undefined &&
      this.end_date1 != undefined &&
      this.officerepname == '' &&
      this.repname1 == '' &&
      this.booksellername1 != '' &&
      this.book_name1 != ''
    ) {
      this.Filteredbookoutstandingdata = this.bookoutstandingdata.filter(
        (a) =>
          a['invoice_date'] >= this.start_date1 &&
          a['invoice_date'] <= this.end_date1 &&
          a.name.toLowerCase().indexOf(this.booksellername1.toLowerCase()) >
            -1 &&
          a.book_name.toLowerCase().indexOf(this.book_name1.toLowerCase()) >
            -1 &&
          a['remaining_amt'] > 0
      );
    } else if (
      this.start_date1 == undefined &&
      this.end_date1 == undefined &&
      this.officerepname == '' &&
      this.repname1 != '' &&
      this.booksellername1 != '' &&
      this.book_name1 != ''
    ) {
      this.Filteredbookoutstandingdata = this.bookoutstandingdata.filter(
        (a) =>
          a.rep_name.toLowerCase().indexOf(this.repname1.toLowerCase()) > -1 &&
          a.name.toLowerCase().indexOf(this.booksellername1.toLowerCase()) >
            -1 &&
          a.book_name.toLowerCase().indexOf(this.book_name1.toLowerCase()) >
            -1 &&
          a['remaining_amt'] > 0
      );
    } else if (
      this.start_date1 != undefined &&
      this.end_date1 != undefined &&
      this.officerepname != '' &&
      this.repname1 != '' &&
      this.booksellername1 != '' &&
      this.book_name1 == ''
    ) {
      this.Filteredbookoutstandingdata = this.bookoutstandingdata.filter(
        (a) =>
          a['invoice_date'] >= this.start_date1 &&
          a['invoice_date'] <= this.end_date1 &&
          a.office_rep_name
            .toLowerCase()
            .indexOf(this.officerepname.toLowerCase()) > -1 &&
          a.rep_name.toLowerCase().indexOf(this.repname1.toLowerCase()) > -1 &&
          a.name.toLowerCase().indexOf(this.booksellername1.toLowerCase()) >
            -1 &&
          a['remaining_amt'] > 0
      );
    } else if (
      this.start_date1 != undefined &&
      this.end_date1 != undefined &&
      this.officerepname != '' &&
      this.repname1 != '' &&
      this.booksellername1 == '' &&
      this.book_name1 != ''
    ) {
      this.Filteredbookoutstandingdata = this.bookoutstandingdata.filter(
        (a) =>
          a['invoice_date'] >= this.start_date1 &&
          a['invoice_date'] <= this.end_date1 &&
          a.office_rep_name
            .toLowerCase()
            .indexOf(this.officerepname.toLowerCase()) > -1 &&
          a.rep_name.toLowerCase().indexOf(this.repname1.toLowerCase()) > -1 &&
          a.book_name.toLowerCase().indexOf(this.book_name1.toLowerCase()) >
            -1 &&
          a['remaining_amt'] > 0
      );
    } else if (
      this.start_date1 != undefined &&
      this.end_date1 != undefined &&
      this.officerepname != '' &&
      this.repname1 == '' &&
      this.booksellername1 != '' &&
      this.book_name1 != ''
    ) {
      this.Filteredbookoutstandingdata = this.bookoutstandingdata.filter(
        (a) =>
          a['invoice_date'] >= this.start_date1 &&
          a['invoice_date'] <= this.end_date1 &&
          a.office_rep_name
            .toLowerCase()
            .indexOf(this.officerepname.toLowerCase()) > -1 &&
          a.name.toLowerCase().indexOf(this.booksellername1.toLowerCase()) >
            -1 &&
          a.book_name.toLowerCase().indexOf(this.book_name1.toLowerCase()) >
            -1 &&
          a['remaining_amt'] > 0
      );
    } else if (
      this.start_date1 != undefined &&
      this.end_date1 != undefined &&
      this.officerepname == '' &&
      this.repname1 != '' &&
      this.booksellername1 != '' &&
      this.book_name1 != ''
    ) {
      this.Filteredbookoutstandingdata = this.bookoutstandingdata.filter(
        (a) =>
          a['invoice_date'] >= this.start_date1 &&
          a['invoice_date'] <= this.end_date1 &&
          a.rep_name.toLowerCase().indexOf(this.repname1.toLowerCase()) > -1 &&
          a.name.toLowerCase().indexOf(this.booksellername1.toLowerCase()) >
            -1 &&
          a.book_name.toLowerCase().indexOf(this.book_name1.toLowerCase()) >
            -1 &&
          a['remaining_amt'] > 0
      );
    } else {
      this.service.getBookOutStandingData().subscribe((data) => {
        this.bookoutstandingdata = data;
        this.Filteredbookoutstandingdata = this.bookoutstandingdata;
      });
    }

    this.dialog.open(ShowBookSellerOutstandingDataComponent, {
      data: {
        officerepname: this.officerepname,
        booksellerdata: this.Filteredbookoutstandingdata,
      },
      height: '1300px',
      width: '1200px',
      maxWidth: '100%',
    });
  }

  imageChange = false;
  ImageUploads(event) {
    if (event.target.value == '') {
      this.imageChange = false;
    } else {
      this.imageChange = true;
    }
  }

  book_id = 0;
  save() {
    if (!this.bookavailable) {
      this.uploader.uploadAll();

      setTimeout(() => {
        let bookbasicsdata = {
          bookname: this.BookForm.get('bookname').value,
          authorname: this.BookForm.get('authorname').value,
          bookdesc: this.BookForm.get('bookdesc').value,
          bookpagesbw: this.BookForm.get('bookpagesbw').value,
          bkpinsidecolor: this.BookForm.get('bkpinsidecolor').value,
          bkpcover: this.BookForm.get('bkpcover').value,
          totalpages: this.BookForm.get('totalpages').value,
          booksize: this.BookForm.get('booksize').value,
          paperbw: this.BookForm.get('paperbw').value,
          paperinsidecolor: this.BookForm.get('paperinsidecolor').value,
          papercover: this.BookForm.get('papercover').value,
          binding: this.BookForm.get('binding').value,
          booklang: this.BookForm.get('booklang').value,
          book_img: this.BookForm.get('bookimg').value,
          isupcoming: this.BookForm.get('isupcoming').value,
          upcomingdate: this.BookForm.get('upcomingdate').value,
          bookcategory: this.BookForm.get('bookcategory').value,
        };

        this.service.AddNewBook(bookbasicsdata).subscribe((res) => {
          this.book_id = res['id'];

          let bookeditiondata = {
            bookid: this.book_id,
            bookyear: this.BookForm.value['bookyear'],
            bookprice: this.BookForm.value['price'],
            booksaleprice: this.BookForm.value['saleprice'],
            discountupto: this.BookForm.value['discountupto'],
            bookedition: this.BookForm.value['bookedition'],
            bookedtndate: this.BookForm.value['bookedtndate'],
            bookedtncpoies: this.BookForm.value['bookedtncpoies'],
            opening_stock: this.BookForm.value['opening_stock'],
            author_honorarium: this.BookForm.value['author_honorarium'],
            deliverychargesinclude: this.BookForm.value['isdelivery'],
          };

          this.service
            .AddNewBookAvailableStock(
              this.book_id,
              this.BookForm.value['bookedtncpoies'],
              this.BookForm.value['opening_stock']
            )
            .subscribe((data) => {});

          this.service.AddNewBookStock(bookeditiondata).subscribe((res) => {
            window.alert('Book added Successfully !');
            //fetching all books getAllBooks()
            this.service.getAllBookData().subscribe((data) => {
              this.bookData = data;
              this.bookdata = this.uniqueid(this.bookData, (it) => it.bookid);
              this.BookForm.reset();
            });
          });
        });
      }, 3000);
    } else {
      let bookeditiondata = {
        bookid: this.book_id,
        bookyear: this.BookForm.value['bookyear'],
        bookprice: this.BookForm.value['price'],
        booksaleprice: this.BookForm.value['saleprice'],
        discountupto: this.BookForm.value['discountupto'],
        bookedition: this.BookForm.value['bookedition'],
        bookedtndate: this.BookForm.value['bookedtndate'],
        bookedtncpoies: this.BookForm.value['bookedtncpoies'],
        opening_stock: this.BookForm.value['opening_stock'],
        author_honorarium: this.BookForm.value['author_honorarium'],
        deliverychargesinclude: this.BookForm.value['isdelivery'],
      };

      let total_copies = parseInt(this.BookForm.value['bookedtncpoies']);
      let available_stock = parseInt(this.BookForm.value['bookedtncpoies']);
      for (let index = 0; index < Object.keys(this.bookData).length; index++) {
        if (this.bookData[index]['bookid'] == this.book_id) {
          total_copies =
            total_copies + parseInt(this.bookData[index]['total_copies']);
          available_stock =
            available_stock + parseInt(this.bookData[index]['available_stock']);
          break;
        }
      }

      this.service
        .UpdateNewBookAvailableStock(
          this.book_id,
          total_copies,
          available_stock
        )
        .subscribe((data) => {});

      this.service.AddNewBookStock(bookeditiondata).subscribe((res) => {
        window.alert('Book Edition added Successfully !');
        //fetching all books getAllBooks()
        this.service.getAllBookData().subscribe((data) => {
          this.bookData = data;
          this.bookdata = this.uniqueid(this.bookData, (it) => it.bookid);
        });
      });
    }
  }

  UpdateBookStock(id) {
    let book = this.bookStock.find((a) => {
      return a.bookid == id;
    });
    this.dialog.open(EditBookStockComponent, {
      data: {
        book: book,
        view: false,
      },
    });
  }

  EditBookDelivery(id) {
    let book = this.bookDeliveryData.find((a) => {
      return a.id == id;
    });
    this.dialog.open(EditBookDeliveryComponent, {
      data: {
        book: book,
        view: false,
      },
    });
  }

  EditDeliveredBook(id) {
    let book = this.FilteredDeliveryBooks.find((a) => {
      return a.id == id;
    });
    this.dialog.open(EditBookDeliveryComponent, {
      data: {
        book: book,
        view: true,
      },
    });
  }

  markAsDelivered(book) {
    this.dialog.open(BookstoresendmessageComponent, {
      data: {
        book: book,
      },
      height: '1300px',
      width: '1200px',
      maxWidth: '100%',
    });
  }

  CancelBook(id) {
    if (window.confirm('Do you really want to cancel this book?')) {
      let cancel_date = formatDate(
        new Date(),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );

      this.service.CancelDeliveryStatus(id, cancel_date).subscribe((data) => {
        let singlebook = this.FilteredDeliveryManageBooks.find(
          (a) => (a['bid'] = id)
        );
        let bookstock = this.bookStock.find((a) => {
          return a.bookname == singlebook['book_name'];
        });
        this.updateCancelBookStock(bookstock, singlebook);
        window.alert('Book has been Canceled !');
        this.getAllDeliveryData();
      });
    }
  }

  updateCancelBookStock(bookstock, singlebook) {
    let new_available =
      parseInt(bookstock['available_stock']) + parseInt(singlebook['book_qty']);
    let new_total =
      parseInt(bookstock['total_stock']) + parseInt(singlebook['book_qty']);
    let updateDate = formatDate(new Date(), 'yyyy-MM-dd', 'en-IN', 'GMT+0530');
    this.service.updateBookStock(bookstock['bs_id']).subscribe((bs) => {
      this.service
        .AddupdatedBookStock(
          bookstock['bookid'],
          new_total,
          new_available,
          'Cancel Book Added ' + updateDate
        )
        .subscribe((data) => {
          window.alert('Book Stock has been updated ');
        });
    });
  }

  FilteredDeliveryBooks = [];
  deliveredendDate;
  deliveredStartDate;

  getChangedDate() {
    if (
      this.range.get('start').value != null &&
      this.range.get('end').value != null
    ) {
      let startdate = formatDate(
        new Date(this.range.get('start').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );
      let endDate = formatDate(
        new Date(this.range.get('end').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );
      this.deliveredStartDate = startdate;
      this.deliveredendDate = endDate;
      this.FilteredDeliveryBooks = this.deliveredBooks.filter((a) => {
        return a['delivery_date'] >= startdate && a['delivery_date'] <= endDate;
      });
    }
  }

  cancel(book) {
    if (window.confirm('Do you really want to cancel receipt')) {
      //getting book data to add book copies which is used in this order
      this.service.getBookDataForCancel(book['rcpt_id']).subscribe((data) => {
        for (let index = 0; index < Object.keys(data).length; index++) {
          let available_stock;
          this.service
            .getBookDataforCancel(data[index]['book_name'])
            .subscribe((data1) => {
              available_stock =
                parseInt(data1[0]['available_stock']) +
                parseInt(data[index]['book_copies']);
              this.service
                .UpdateNewBookAvailableStock(
                  data1[0]['bookid'],
                  data1[0]['total_copies'],
                  available_stock
                )
                .subscribe((data2) => {});
            });
        }
        this.service
          .UpdateRcptCancellation(book['rcpt_id'])
          .subscribe((cancel) => {
            window.alert('Receipt has been cancelled..');
            location.reload();
          });
      });
    }
  }

  custname = '';
  repname = '';
  manageStartDate;
  manageendDate;
  getChangedDate_managebook() {
    if (
      this.Managerange.get('start').value != null &&
      this.Managerange.get('end').value != null
    ) {
      let startdate = formatDate(
        new Date(this.Managerange.get('start').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );
      let endDate = formatDate(
        new Date(this.Managerange.get('end').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );
      this.manageStartDate = startdate;
      this.manageendDate = endDate;
      this.FilteredDeliveryManageBooks = this.bookdeliverydata.filter((a) => {
        return a['bookingdate'] >= startdate && a['bookingdate'] <= endDate;
      });
    }
  }

  getRep_name(repname) {
    if (this.custname != '') {
      this.FilteredDeliveryManageBooks = this.bookdeliverydata.filter(
        (item) => {
          return (
            item['bookingdate'] >= this.manageStartDate &&
            item['bookingdate'] <= this.manageendDate &&
            item.name.toLowerCase().indexOf(this.custname.toLowerCase()) > -1 &&
            item.rep.toLowerCase().indexOf(repname.toLowerCase()) > -1
          );
        }
      );
    } else {
      this.FilteredDeliveryManageBooks = this.bookdeliverydata.filter(
        (item) => {
          return (
            item['bookingdate'] >= this.manageStartDate &&
            item['bookingdate'] <= this.manageendDate &&
            item.rep.toLowerCase().indexOf(repname.toLowerCase()) > -1
          );
        }
      );
    }
  }

  reset_repname(event) {
    this.repname = '';

    if (this.custname != '') {
      this.FilteredDeliveryManageBooks = this.bookdeliverydata.filter(
        (item) => {
          return (
            item['bookingdate'] >= this.manageStartDate &&
            item['bookingdate'] <= this.manageendDate &&
            item.name.toLowerCase().indexOf(this.custname.toLowerCase()) > -1
          );
        }
      );
    } else {
      this.FilteredDeliveryManageBooks = this.bookdeliverydata.filter(
        (item) => {
          return (
            item['bookingdate'] >= this.manageStartDate &&
            item['bookingdate'] <= this.manageendDate
          );
        }
      );
    }
  }

  getcust_name(cust_name) {
    if (this.repname != '') {
      this.FilteredDeliveryManageBooks = this.bookdeliverydata.filter(
        (item) => {
          return (
            item['bookingdate'] >= this.manageStartDate &&
            item['bookingdate'] <= this.manageendDate &&
            item.name.toLowerCase().indexOf(cust_name.toLowerCase()) > -1 &&
            item.rep.toLowerCase().indexOf(this.repname.toLowerCase()) > -1
          );
        }
      );
    } else {
      this.FilteredDeliveryManageBooks = this.bookdeliverydata.filter(
        (item) => {
          return (
            item['bookingdate'] >= this.manageStartDate &&
            item['bookingdate'] <= this.manageendDate &&
            item.name.toLowerCase().indexOf(cust_name.toLowerCase()) > -1
          );
        }
      );
    }
  }

  reset_custname(event) {
    this.custname = '';

    if (this.repname != '') {
      this.FilteredDeliveryManageBooks = this.bookdeliverydata.filter(
        (item) => {
          return (
            item['bookingdate'] >= this.manageStartDate &&
            item['bookingdate'] <= this.manageendDate &&
            item.rep.toLowerCase().indexOf(this.repname.toLowerCase()) > -1
          );
        }
      );
    } else {
      this.FilteredDeliveryManageBooks = this.bookdeliverydata.filter(
        (item) => {
          return (
            item['bookingdate'] >= this.manageStartDate &&
            item['bookingdate'] <= this.manageendDate
          );
        }
      );
    }
  }

  cust_name_delivery = '';
  getcust_name_deliveredBook(cust_name_delivery) {
    this.FilteredDeliveryBooks = this.deliveredBooks.filter((item) => {
      return (
        item['delivery_date'] >= this.deliveredStartDate &&
        item['delivery_date'] <= this.deliveredendDate &&
        item.name.toLowerCase().indexOf(cust_name_delivery.toLowerCase()) > -1
      );
    });
  }

  reset_custname_deliveredBook(event) {
    this.cust_name_delivery = '';

    this.FilteredDeliveryBooks = this.bookDeliveryData.filter((item) => {
      return (
        item['delivery_date'] >= this.deliveredStartDate &&
        item['delivery_date'] <= this.deliveredendDate
      );
    });
  }

  FilteredBookRegistration = [];
  startDate_registration = '';
  enddate_registration = '';
  cust_name_registration = '';
  rep_name_registration = '';

  getChangedDateRegistration() {
    if (
      this.rangeRegistration.get('start').value != null &&
      this.rangeRegistration.get('end').value != null
    ) {
      let startdate = formatDate(
        new Date(this.rangeRegistration.get('start').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );
      let endDate = formatDate(
        new Date(this.rangeRegistration.get('end').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );
      this.startDate_registration = startdate;
      this.enddate_registration = endDate;

      this.FilteredBookRegistration1 = this.bookregistrationdata.filter((a) => {
        return (
          new Date(a['booking_date']) >= new Date(startdate) &&
          new Date(a['booking_date']) <= new Date(endDate)
        );
      });
    }
  }

  getRep_name_registration(repname) {
    if (this.cust_name_registration != '') {
      this.FilteredBookRegistration1 = this.bookregistrationdata.filter(
        (item) => {
          return (
            item['booking_date'] >= this.startDate_registration &&
            item['booking_date'] <= this.enddate_registration &&
            item.name
              .toLowerCase()
              .indexOf(this.cust_name_registration.toLowerCase()) > -1 &&
            item.crep_name.toLowerCase().indexOf(repname.toLowerCase()) > -1
          );
        }
      );
    } else {
      this.FilteredBookRegistration1 = this.bookregistrationdata.filter(
        (item) => {
          return (
            item['booking_date'] >= this.startDate_registration &&
            item['booking_date'] <= this.enddate_registration &&
            item.crep_name.toLowerCase().indexOf(repname.toLowerCase()) > -1
          );
        }
      );
    }
  }

  reset_repname_registration(event) {
    this.rep_name_registration = '';

    if (this.cust_name_registration != '') {
      this.FilteredBookRegistration1 = this.bookregistrationdata.filter(
        (item) => {
          return (
            item['booking_date'] >= this.startDate_registration &&
            item['booking_date'] <= this.enddate_registration &&
            item.name
              .toLowerCase()
              .indexOf(this.cust_name_registration.toLowerCase()) > -1
          );
        }
      );
    } else {
      this.FilteredBookRegistration1 = this.bookregistrationdata.filter(
        (item) => {
          return (
            item['booking_date'] >= this.startDate_registration &&
            item['booking_date'] <= this.enddate_registration
          );
        }
      );
    }
  }

  getcust_name_registration(cust_name) {
    if (this.rep_name_registration != '') {
      this.FilteredBookRegistration1 = this.bookregistrationdata.filter(
        (item) => {
          return (
            item['booking_date'] >= this.startDate_registration &&
            item['booking_date'] <= this.enddate_registration &&
            item.name.toLowerCase().indexOf(cust_name.toLowerCase()) > -1 &&
            item.crep_name
              .toLowerCase()
              .indexOf(this.rep_name_registration.toLowerCase()) > -1
          );
        }
      );
    } else {
      this.FilteredBookRegistration1 = this.bookregistrationdata.filter(
        (item) => {
          return (
            item['booking_date'] >= this.startDate_registration &&
            item['booking_date'] <= this.enddate_registration &&
            item.name.toLowerCase().indexOf(cust_name.toLowerCase()) > -1
          );
        }
      );
    }
  }

  reset_custname_registration(event) {
    this.cust_name_registration = '';

    if (this.rep_name_registration != '') {
      this.FilteredBookRegistration1 = this.bookregistrationdata.filter(
        (item) => {
          return (
            item['booking_date'] >= this.startDate_registration &&
            item['booking_date'] <= this.enddate_registration &&
            item.crep_name
              .toLowerCase()
              .indexOf(this.rep_name_registration.toLowerCase()) > -1
          );
        }
      );
    } else {
      this.FilteredBookRegistration1 = this.bookregistrationdata.filter(
        (item) => {
          return (
            item['booking_date'] >= this.startDate_registration &&
            item['booking_date'] <= this.enddate_registration
          );
        }
      );
    }
  }

  paymentStatus = '';
  FilterPayment(event) {
    let status = event.value;
    this.paymentStatus = status;

    if (this.rep_name_registration != '' && this.cust_name_registration != '') {
      this.FilteredBookRegistration = this.bookregistrationdetails.filter(
        (item) => {
          return (
            item['received_date'] >= this.startDate_registration &&
            item['received_date'] <= this.enddate_registration &&
            item.payment_status.toLowerCase().indexOf(status.toLowerCase()) >
              -1 &&
            item.name
              .toLowerCase()
              .indexOf(this.cust_name_registration.toLowerCase()) > -1 &&
            item.rep_name
              .toLowerCase()
              .indexOf(this.rep_name_registration.toLowerCase()) > -1
          );
        }
      );
    } else if (this.cust_name_registration == '' || status == 'all') {
      this.FilteredBookRegistration = this.bookregistrationdetails.filter(
        (item) => {
          return (
            item['received_date'] >= this.startDate_registration &&
            item['received_date'] <= this.enddate_registration &&
            item.payment_status.toLowerCase().indexOf(status.toLowerCase()) >
              -1 &&
            item.rep_name
              .toLowerCase()
              .indexOf(this.rep_name_registration.toLowerCase()) > -1
          );
        }
      );
    } else if (this.rep_name_registration == '' || status == 'all') {
      this.FilteredBookRegistration = this.bookregistrationdetails.filter(
        (item) => {
          return (
            item['received_date'] >= this.startDate_registration &&
            item['received_date'] <= this.enddate_registration &&
            item.payment_status.toLowerCase().indexOf(status.toLowerCase()) >
              -1 &&
            item.name
              .toLowerCase()
              .indexOf(this.cust_name_registration.toLowerCase()) > -1
          );
        }
      );
    } else {
      this.FilteredBookRegistration = this.bookregistrationdetails.filter(
        (item) => {
          return (
            item['received_date'] >= this.startDate_registration &&
            item['received_date'] <= this.enddate_registration
          );
        }
      );
    }
  }

  ischecked = false;
  getMarkAsDevliveryValue(event) {}



  getContractDetails() {
    debugger
    this.bookwisereplist.forEach((book) => {
      if (book['rep_name']) {
        this.contractRepName.push({ name: book['rep_name'] });
      }
      if (book['off_rep_name']) {
        this.contractOffRepName.push({ name: book['off_rep_name'] });
      }
      if (book['advt_name']) {
        this.contractAdvtName.push({ name: book['advt_name'] });
      }
    });
    console.log("representativename",this.contractRepName.length)

    this.contractRepName = this.uniqueid(this.contractRepName, (a) => a.name);
    this.contractOffRepName = this.uniqueid(
      this.contractOffRepName,
      (a) => a.name
    );
    this.contractAdvtName = this.uniqueid(this.contractAdvtName, (a) => a.name);
  }

  getbookrcptStartDate;
  getbookrcptEndDate;
  getBookReceiptDateRange() {
    if (
      this.bookrcptregisterform.get('start').value != null &&
      this.bookrcptregisterform.get('end').value != null
    ) {
      this.getbookrcptStartDate = formatDate(
        new Date(this.bookrcptregisterform.get('start').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );
      this.getbookrcptEndDate = formatDate(
        new Date(this.bookrcptregisterform.get('end').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );

      this.FilteredbookRcptRegisterdata = this.bookRcptRegisterdata.filter(
        (a) => {
          return (
            new Date(a['receipt_date']) >=
              new Date(this.getbookrcptStartDate) &&
            new Date(a['receipt_date']) <= new Date(this.getbookrcptEndDate)
          );
        }
      );
    }
  }

  repnamewise = '';
  getrepwise(event) {
    this.repnamewise = event.target.value;

    if (
      this.repnamewise != '' &&
      this.getbookrcptStartDate != null &&
      this.getbookrcptEndDate != null
    ) {
      this.FilteredbookRcptRegisterdata = this.bookRcptRegisterdata.filter(
        (a) => {
          return (
            new Date(a['receipt_date']) >=
              new Date(this.getbookrcptStartDate) &&
            new Date(a['receipt_date']) <= new Date(this.getbookrcptEndDate) &&
            a.rep_name.toLowerCase().indexOf(this.repnamewise.toLowerCase()) >
              -1
          );
        }
      );
    } else if (
      this.repnamewise != '' &&
      this.getbookrcptStartDate == null &&
      this.getbookrcptEndDate == null
    ) {
      this.FilteredbookRcptRegisterdata = this.bookRcptRegisterdata.filter(
        (a) => {
          return (
            a.rep_name.toLowerCase().indexOf(this.repnamewise.toLowerCase()) >
            -1
          );
        }
      );
    }
  }

  billnowise = '';
  getbillnowise(event) {
    this.billnowise = event.target.value;
    if (
      this.repnamewise != '' &&
      this.getbookrcptStartDate != null &&
      this.getbookrcptEndDate != null &&
      this.billnowise != ''
    ) {
      this.FilteredbookRcptRegisterdata = this.bookRcptRegisterdata.filter(
        (a) => {
          return (
            new Date(a['receipt_date']) >=
              new Date(this.getbookrcptStartDate) &&
            new Date(a['receipt_date']) <= new Date(this.getbookrcptEndDate) &&
            a.rep_name.toLowerCase().indexOf(this.repnamewise.toLowerCase()) >
              -1 &&
            a.bill_no.toLowerCase().indexOf(this.billnowise.toLowerCase()) > -1
          );
        }
      );
    } else if (
      this.repnamewise != '' &&
      this.getbookrcptStartDate == null &&
      this.getbookrcptEndDate == null &&
      this.billnowise != ''
    ) {
      this.FilteredbookRcptRegisterdata = this.bookRcptRegisterdata.filter(
        (a) => {
          return (
            a.rep_name.toLowerCase().indexOf(this.repnamewise.toLowerCase()) >
              -1 &&
            a.bill_no.toLowerCase().indexOf(this.billnowise.toLowerCase()) > -1
          );
        }
      );
    } else if (
      this.repnamewise == '' &&
      this.getbookrcptStartDate != null &&
      this.getbookrcptEndDate != null &&
      this.billnowise != ''
    ) {
      this.FilteredbookRcptRegisterdata = this.bookRcptRegisterdata.filter(
        (a) => {
          return (
            new Date(a['receipt_date']) >=
              new Date(this.getbookrcptStartDate) &&
            new Date(a['receipt_date']) <= new Date(this.getbookrcptEndDate) &&
            a.bill_no.toLowerCase().indexOf(this.billnowise.toLowerCase()) > -1
          );
        }
      );
    } else if (
      this.repnamewise == '' &&
      this.getbookrcptStartDate == null &&
      this.getbookrcptEndDate == null &&
      this.billnowise != ''
    ) {
      this.FilteredbookRcptRegisterdata = this.bookRcptRegisterdata.filter(
        (a) => {
          return (
            a.bill_no.toLowerCase().indexOf(this.billnowise.toLowerCase()) > -1
          );
        }
      );
    }
  }

  booksellerwise = '';
  getbooksellerwise(event) {
    this.booksellerwise = event.target.value;
    if (
      this.repnamewise != '' &&
      this.getbookrcptStartDate != null &&
      this.getbookrcptEndDate != null &&
      this.billnowise != '' &&
      this.booksellerwise != ''
    ) {
      this.FilteredbookRcptRegisterdata = this.bookRcptRegisterdata.filter(
        (a) => {
          return (
            new Date(a['receipt_date']) >=
              new Date(this.getbookrcptStartDate) &&
            new Date(a['receipt_date']) <= new Date(this.getbookrcptEndDate) &&
            a.rep_name.toLowerCase().indexOf(this.repnamewise.toLowerCase()) >
              -1 &&
            a.bill_no.toLowerCase().indexOf(this.billnowise.toLowerCase()) >
              -1 &&
            a.book_seller_name
              .toLowerCase()
              .indexOf(this.booksellerwise.toLowerCase()) > -1
          );
        }
      );
    } else if (
      this.repnamewise != '' &&
      this.getbookrcptStartDate == null &&
      this.getbookrcptEndDate == null &&
      this.billnowise != '' &&
      this.booksellerwise != ''
    ) {
      this.FilteredbookRcptRegisterdata = this.bookRcptRegisterdata.filter(
        (a) => {
          return (
            a.rep_name.toLowerCase().indexOf(this.repnamewise.toLowerCase()) >
              -1 &&
            a.bill_no.toLowerCase().indexOf(this.billnowise.toLowerCase()) >
              -1 &&
            a.book_seller_name
              .toLowerCase()
              .indexOf(this.booksellerwise.toLowerCase()) > -1
          );
        }
      );
    } else if (
      this.repnamewise == '' &&
      this.getbookrcptStartDate != null &&
      this.getbookrcptEndDate != null &&
      this.billnowise != '' &&
      this.booksellerwise != ''
    ) {
      this.FilteredbookRcptRegisterdata = this.bookRcptRegisterdata.filter(
        (a) => {
          return (
            new Date(a['receipt_date']) >=
              new Date(this.getbookrcptStartDate) &&
            new Date(a['receipt_date']) <= new Date(this.getbookrcptEndDate) &&
            a.bill_no.toLowerCase().indexOf(this.billnowise.toLowerCase()) >
              -1 &&
            a.book_seller_name
              .toLowerCase()
              .indexOf(this.booksellerwise.toLowerCase()) > -1
          );
        }
      );
    } else if (
      this.repnamewise == '' &&
      this.getbookrcptStartDate == null &&
      this.getbookrcptEndDate == null &&
      this.billnowise != '' &&
      this.booksellerwise != ''
    ) {
      this.FilteredbookRcptRegisterdata = this.bookRcptRegisterdata.filter(
        (a) => {
          return (
            a.bill_no.toLowerCase().indexOf(this.billnowise.toLowerCase()) >
              -1 &&
            a.book_seller_name
              .toLowerCase()
              .indexOf(this.booksellerwise.toLowerCase()) > -1
          );
        }
      );
    } else if (
      this.repnamewise == '' &&
      this.getbookrcptStartDate != null &&
      this.getbookrcptEndDate != null &&
      this.billnowise == '' &&
      this.booksellerwise != ''
    ) {
      this.FilteredbookRcptRegisterdata = this.bookRcptRegisterdata.filter(
        (a) => {
          return (
            new Date(a['receipt_date']) >=
              new Date(this.getbookrcptStartDate) &&
            new Date(a['receipt_date']) <= new Date(this.getbookrcptEndDate) &&
            a.book_seller_name
              .toLowerCase()
              .indexOf(this.booksellerwise.toLowerCase()) > -1
          );
        }
      );
    } else if (
      this.repnamewise != '' &&
      this.getbookrcptStartDate == null &&
      this.getbookrcptEndDate == null &&
      this.billnowise == '' &&
      this.booksellerwise != ''
    ) {
      this.FilteredbookRcptRegisterdata = this.bookRcptRegisterdata.filter(
        (a) => {
          return (
            a.rep_name.toLowerCase().indexOf(this.repnamewise.toLowerCase()) >
              -1 &&
            a.book_seller_name
              .toLowerCase()
              .indexOf(this.booksellerwise.toLowerCase()) > -1
          );
        }
      );
    } else if (
      this.repnamewise == '' &&
      this.getbookrcptStartDate == null &&
      this.getbookrcptEndDate == null &&
      this.billnowise == '' &&
      this.booksellerwise != ''
    ) {
      this.FilteredbookRcptRegisterdata = this.bookRcptRegisterdata.filter(
        (a) => {
          return (
            a.book_seller_name
              .toLowerCase()
              .indexOf(this.booksellerwise.toLowerCase()) > -1
          );
        }
      );
    }
  }

  getbookcrdtStartDate;
  getbookcrdtEndDate;
  getBookCreditDateRange() {
    if (
      this.booksellercrdtnoteform.get('start').value != null &&
      this.booksellercrdtnoteform.get('end').value != null
    ) {
      this.getbookcrdtStartDate = formatDate(
        new Date(this.booksellercrdtnoteform.get('start').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );
      this.getbookcrdtEndDate = formatDate(
        new Date(this.booksellercrdtnoteform.get('end').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );

      // this.FilteredbookRcptRegisterdata = this.bookRcptRegisterdata.filter(a => { return new Date(a['receipt_date']) >= new Date(this.getbookrcptStartDate) && new Date(a['receipt_date']) <= new Date(this.getbookrcptEndDate) });
    }
  }

  getbookwiseStartDate;
  getbookwiseEndDate;
  getBookDateRange() {
    if (
      this.BookrangeRegistration.get('start').value != null &&
      this.BookrangeRegistration.get('end').value != null
    ) {
      this.getbookwiseStartDate = formatDate(
        new Date(this.BookrangeRegistration.get('start').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );
      this.getbookwiseEndDate = formatDate(
        new Date(this.BookrangeRegistration.get('end').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );

      //this.FilteredBookRegistration = this.bookregistrationdetails.filter(a => { return new Date(a['received_date']) >= new Date(startdate) && new Date(a['received_date']) <= new Date(endDate) });
    }
  }

  getbookbillingStartDate;
  getbookbillingEndDate;
  getBookBillingDateRange() {
    if (
      this.BookbillingRegister.get('start').value != null &&
      this.BookbillingRegister.get('end').value != null
    ) {
      this.getbookbillingStartDate = formatDate(
        new Date(this.BookbillingRegister.get('start').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );
      this.getbookbillingEndDate = formatDate(
        new Date(this.BookbillingRegister.get('end').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );

      this.Filteredbookbillingdata = this.bookbillingdata.filter((a) => {
        return (
          a['invoice_date'] >= this.getbookbillingStartDate &&
          a['invoice_date'] <= this.getbookbillingEndDate
        );
      });

      this.calculateBookBillingData();
    }
  }

  calculateBookBillingData() {
    let totalData = {
      name: 'Total',
      invoice_total: 0,
      receipt: 0,
      pending: 0,
    };

    this.Filteredbookbillingdata.map((a) => {
      totalData['invoice_total'] += parseInt(a['invoice_total']);
      totalData['pending'] += parseInt(a['remaining_amt']);
    });
    totalData['receipt'] = totalData['invoice_total'] - totalData['pending'];
    this.Filteredbookbillingdata.push(totalData);
  }

  invoice_no;
  getinvoice_no(event) {
    if (this.invoice_no != '') {
      this.Filteredbookbillingdata = this.bookbillingdata.filter((a) => {
        return (
          a['invoice_date'] >= this.getbookbillingStartDate &&
          a['invoice_date'] <= this.getbookbillingEndDate &&
          a.invoice_no.toLowerCase().indexOf(this.invoice_no.toLowerCase()) > -1
        );
      });
    } else {
      this.Filteredbookbillingdata = this.bookbillingdata.filter((a) => {
        return (
          a['invoice_date'] >= this.getbookbillingStartDate &&
          a['invoice_date'] <= this.getbookbillingEndDate
        );
      });
    }
    this.calculateBookBillingData();
  }

  reset_invoice_no(event) {
    this.invoice_no = '';
    if (this.cust_name != undefined) {
      this.Filteredbookbillingdata = this.bookbillingdata.filter((a) => {
        return (
          a['invoice_date'] >= this.getbookbillingStartDate &&
          a['invoice_date'] <= this.getbookbillingEndDate &&
          a.name.toLowerCase().indexOf(this.cust_name.toLowerCase()) > -1
        );
      });
    } else {
      this.Filteredbookbillingdata = this.bookbillingdata.filter((a) => {
        return (
          a['invoice_date'] >= this.getbookbillingStartDate &&
          a['invoice_date'] <= this.getbookbillingEndDate
        );
      });
    }
    this.calculateBookBillingData();
  }

  cust_name;
  getcustomer_name(event) {
    if (this.cust_name != '' && this.invoice_no != undefined) {
      this.Filteredbookbillingdata = this.bookbillingdata.filter((a) => {
        return (
          a['invoice_date'] >= this.getbookbillingStartDate &&
          a['invoice_date'] <= this.getbookbillingEndDate &&
          a.invoice_no.toLowerCase().indexOf(this.invoice_no.toLowerCase()) >
            -1 &&
          a.name.toLowerCase().indexOf(this.cust_name.toLowerCase()) > -1
        );
      });
    } else if (this.cust_name != '' && this.invoice_no == undefined) {
      this.Filteredbookbillingdata = this.bookbillingdata.filter((a) => {
        return (
          a['invoice_date'] >= this.getbookbillingStartDate &&
          a['invoice_date'] <= this.getbookbillingEndDate &&
          a.name.toLowerCase().indexOf(this.cust_name.toLowerCase()) > -1
        );
      });
    } else {
      this.Filteredbookbillingdata = this.bookbillingdata.filter((a) => {
        return (
          a['invoice_date'] >= this.getbookbillingStartDate &&
          a['invoice_date'] <= this.getbookbillingEndDate
        );
      });
    }
    this.calculateBookBillingData();
  }

  reset_cust_name(event) {
    this.cust_name = '';
    if (this.invoice_no != '') {
      this.Filteredbookbillingdata = this.bookbillingdata.filter((a) => {
        return (
          a['invoice_date'] >= this.getbookbillingStartDate &&
          a['invoice_date'] <= this.getbookbillingEndDate &&
          a.invoice_no.toLowerCase().indexOf(this.invoice_no.toLowerCase()) > -1
        );
      });
    } else {
      this.Filteredbookbillingdata = this.bookbillingdata.filter((a) => {
        return (
          a['invoice_date'] >= this.getbookbillingStartDate &&
          a['invoice_date'] <= this.getbookbillingEndDate
        );
      });
    }
    this.calculateBookBillingData();
  }

  booksellernamedata;
  booksellernamedata1;
  // booksellernamedata2;
  searchbooksellername() {
    this.booksellernamedata1 = [];
    this.service.getBookSellerSpecificData().subscribe((data) => {
      for (let index = 0; index < data.length; index++) {
        this.booksellernamedata1.push(data[index]);
      }
    });
    this.service.getBookSellerSpecificData1().subscribe((data) => {
      for (let index = 0; index < data.length; index++) {
        this.booksellernamedata1.push(data[index]);
      }
    });
    setTimeout(() => {
      this.booksellernamedata = [];
      this.booksellernamedata = this.booksellernamedata1.filter(
        (a) => a.receiver_name === this.name
      );
    }, 1000);
  }

  searchbookselname() {
    this.bookselnamedata1 = [];  // Clear the previous data
    const request ={
      "req1":this.bookselForm.value
    }
    
    this.service.getBookSelSpecificData(request).subscribe((data) => {
        this.getallVouchersData = data;  // Assign the new data
        this.priceTotal=0
        this.commissionTotal=0
        this.remainingTotal=0
        for (let data1 of this.getallVouchersData) {
          this.priceTotal += parseInt(data1.price) || 0; 
          this.commissionTotal += parseInt(data1.commission) || 0;   
          this.remainingTotal += parseInt(data1.remaining_amt) || 0;     
     
        }
        // this.filterData();  // Now call the filter function after data is fetched/

        // for (let index = 0; index < data.length; index++) {
        //   this.bookselnamedata1.push(data[index]);
        // }
      },
      (error) => {
        console.error('Error fetching data:', error);
      }
    );
    // this.service.getBookSelSpecificData1(request).subscribe((data) => {
    //   for (let index = 0; index < data.length; index++) {
    //     this.booksellernamedata1.push(data[index]);
    //   }
    // });
  }

  searchbook() {
    //bookdata
    this.booksellernamedata1 = [];
    const request = {
        "bookname" : this.bookselForm.value
    } 
    this.service.getBookSearch(request).subscribe((data) => {
      this.bookdata = data;  // Assign the new data
      // for (let index = 0; index < data.length; index++) {
      //    this.booksellernamedata1.push(data[index]);
      // }
    });
  
    setTimeout(() => {
      this.booksellernamedata = [];
  
      // Filter data based on bookname (you can adjust the condition as needed)
      this.booksellernamedata = this.booksellernamedata1.filter(
        (a) => a.bookname === this.bookName // Assuming you want to filter by bookname
      );
    }, 1000);
  }
  


  
  filterData() {
    // Filter based on receiver_name (assuming you want to filter by this.name)
    this.bookselnamedata = this.bookselnamedata1.filter(
      (a) => a.book_seller_name === this.name  // Use book_seller_name here
    );
    console.log('Filtered Data:', this.bookselnamedata);
  }
  
  



  bookpymtrcptdata = [];
  invoice_total = 0;
  remainingamt: number = 0;
  click: boolean = false;
  // selectedItem(book) {
  //   debugger
  //   if (Object.keys(this.bookpymtrcptdata).length != 0) {
  //     let flag: Boolean = false;
  //     for (
  //       let index = 0;
  //       index < Object.keys(this.bookpymtrcptdata).length;
  //       index++
  //     ) {
  //       if (this.bookpymtrcptdata[index]['id'] == book['id']) {
  //         flag = true;
  //       }
  //     }
  //     if (!flag) {
        
  //       this.calculateRcptAmt(book);
  //     } else {
  //       window.alert('Receipt is Already Selected...');
  //     }
  //   } else {
  //     this.calculateRcptAmt(book);
  //   }
  // }

  

  selectedItem(book) {
    debugger;
    this.calculateRcptAmt(book);
  }
  

  dialogdata;
  bookpricedata = [];
  calculateRcptAmt(book) {
    console.log(book);
debugger
    // if (parseInt(book['remaining_amt']) == 0) {
    //   let val = parseInt(book['invoice_total']);
    //   let crdtamt = this.bookpymtrecptform.get('crdtvamt').value;
    //   // this.invoice_total = this.invoice_total + val;
    //   if (crdtamt != '' && parseInt(crdtamt) >= this.invoice_total) {
    //     this.bookpymtrecptform.get('seamt').setValue(this.invoice_total);
    //     this.bookpymtrecptform
    //       .get('balamt')
    //       .setValue(crdtamt - this.invoice_total);
    //     this.bookpymtrcptdata.push(book);
    //   } else if (crdtamt != '' && parseInt(crdtamt) <= this.invoice_total) {
    //     this.bookpymtrecptform.get('seamt').setValue(crdtamt);
    //     this.remainingamt = this.invoice_total - crdtamt;
    //     this.bookpymtrecptform
    //       .get('balamt')
    //       .setValue(crdtamt - this.bookpymtrecptform.get('seamt').value);
    //     for (
    //       let index = 0;
    //       index < Object.keys(this.booksellernamedata).length;
    //       index++
    //     ) {
    //       if (book['id'] == this.booksellernamedata[index]['id']) {
    //         this.booksellernamedata[index]['remaining_amt'] = this.remainingamt;
    //         this.click = !this.click;
    //         this.bookpymtrcptdata.push(book);
    //       }
    //     }
    //   }
    // } else {
    //   let val = parseInt(book['remaining_amt']);
    //   let crdtamt = this.bookpymtrecptform.get('crdtvamt').value;
    //   // this.invoice_total = this.invoice_total + val;
    //   if (crdtamt != '' && parseInt(crdtamt) >= this.invoice_total) {
    //     // this.bookpymtrecptform.get('seamt').setValue(this.invoice_total);
    //     this.bookpymtrecptform
    //       .get('balamt')
    //       .setValue(crdtamt - this.invoice_total);
    //     this.bookpymtrcptdata.push(book);
    //   } else if (crdtamt != '' && parseInt(crdtamt) <= this.invoice_total) {
    //     this.bookpymtrecptform.get('seamt').setValue(crdtamt);
    //     this.remainingamt = this.invoice_total - crdtamt;
    //     this.bookpymtrecptform
    //       .get('balamt')
    //       .setValue(crdtamt - this.bookpymtrecptform.get('seamt').value);
    //     for (
    //       let index = 0;
    //       index < Object.keys(this.booksellernamedata).length;
    //       index++
    //     ) {
    //       if (book['id'] == this.booksellernamedata[index]['id']) {
    //         this.booksellernamedata[index]['remaining_amt'] = this.remainingamt;
    //         this.bookpymtrcptdata.push(book);
    //       }
    //     }
    //   }
    // }

    const previouslyFocusedElement = document.activeElement as HTMLElement;
    this.bookpymtrcptdata.push(book);
   
    const dialogdata = this.dialog.open(AddBookPerPriceComponent, {
      data: {
        book: book,
        crdtAmt: parseFloat(this.bookpymtrecptform.get('crdtvamt').value),
        seAmt: parseFloat(this.bookpymtrecptform.get('seamt').value),
        balAmt: parseFloat(this.bookpymtrecptform.get('balamt').value),
        bookName: this.bookpymtrecptform.get('bname').value,
        bal: book.pay_amt - book.rcv_amt,
      },
      height: '85vh',
      width: '95vw',
      maxWidth: '100%',
    });
    dialogdata.afterClosed().subscribe((res) => {
      debugger
      this.bookpricedata.push(res.data);
      for (
        let index = 0;
        index < Object.keys(this.bookpymtrcptdata).length;
        index++
      ) {
        if (this.bookpymtrcptdata[index]['id'] == res.id) {
          this.booksellernamedata[index]['remaining_amt'] = res.remaining_amt;
        }
      }
      let rcvd_amt = 0;
      for (
        let index = 0;
        index < Object.keys(this.bookpricedata).length;
        index++
      ) {
        for (
          let index1 = 0;
          index1 < Object.keys(this.bookpricedata[index]).length;
          index1++
        ) {
          rcvd_amt =
            rcvd_amt + parseInt(this.bookpricedata[index][index1]['rcvd_amt']);
        }
      }
      let val = this.bookpymtrecptform.get('crdtvamt').value;
      this.bookpymtrecptform.get('seamt').setValue(parseInt(res.selecetdAmt));
      this.bookpymtrecptform.get('balamt').setValue(val - parseInt(res.value));
      this.invoice_total = rcvd_amt;
      if (this.bookpymtrecptform.get('balamt').value <= 0) {
        this.click = !this.click;
      }
    });
    
    // dialogdata.afterClosed().subscribe((res) => {
    //   debugger;
    //   this.bookpricedata.push(res?.data);
    
    //   // Ensure res is valid before proceeding
    //   if (res) {
    //     for (let index = 0; index < this.bookpymtrcptdata.length; index++) {
    //       if (this.bookpymtrcptdata[index]['id'] === res.id) {
    //         this.booksellernamedata[index]['remaining_amt'] = res.remaining_amt;
    //       }
    //     }
    
    //     let rcvd_amt = 0;
    //     this.bookpricedata.forEach((item) => {
    //       item.forEach((subItem) => {
    //         rcvd_amt += parseInt(subItem['rcvd_amt']) || 0;
    //       });
    //     });
    
    //     let val = this.bookpymtrecptform.get('crdtvamt').value;
    //     this.bookpymtrecptform.get('seamt').setValue(parseInt(res.value));
    //     this.bookpymtrecptform.get('balamt').setValue(val - parseInt(res.value));
    //     this.invoice_total = rcvd_amt;
    
    //     if (this.bookpymtrecptform.get('balamt').value <= 0) {
    //       this.click = !this.click;
    //     }
    //   }
    
    //   // Set focus back to a visible button or form field
    //   setTimeout(() => {
    //     if (previouslyFocusedElement) {
    //       previouslyFocusedElement.focus();
    //     } else {
    //     const focusableElement = document.querySelector('button:not([disabled]), input:not([disabled])');
    //     if (focusableElement) {
    //       (focusableElement as HTMLElement).focus();
    //     }
    //   }
    // }, 100);
    // });
    
  }

  updatedata;
  updatePerBooksRate(book) {
    this.updatedata = [];
    for (let index = 0; index < this.bookpricedata.length; index++) {
      for (
        let index1 = 0;
        index1 < this.bookpricedata[index].length;
        index1++
      ) {
        if (this.bookpricedata[index][index1]['id'] === book['id']) {
          this.updatedata.push(this.bookpricedata[index][index1]);
        }
      }
    }
    const dialogdata = this.dialog.open(UpdateBookPerPriceComponent, {
      data: {
        book: book,
        updatedata: this.updatedata,
      },
      height: '85vh',
      width: '95vw',
      maxWidth: '100%',
    });

    dialogdata.afterClosed().subscribe((res) => {
      for (let index2 = 0; index2 < res.data.length; index2++) {
        for (let index = 0; index < this.bookpricedata.length; index++) {
          for (
            let index1 = 0;
            index1 < this.bookpricedata[index].length;
            index1++
          ) {
            if (
              this.bookpricedata[index][index1]['bookid'] ===
              res.data[index2]['bookid']
            ) {
              this.bookpricedata[index][index1]['rcvd_amt'] =
                res.data[index2]['rcvd_amt'];
              this.bookpricedata[index][index1]['remaining_amt'] =
                res.data[index2]['remaining_amt'];
            }
          }
        }
      }

      for (
        let index = 0;
        index < Object.keys(this.bookpymtrcptdata).length;
        index++
      ) {
        if (this.bookpymtrcptdata[index]['id'] == res.id) {
          this.booksellernamedata[index]['remaining_amt'] = res.remaining_amt;
        }
      }
      let rcvd_amt = 0;
      for (
        let index = 0;
        index < Object.keys(this.bookpricedata).length;
        index++
      ) {
        for (
          let index1 = 0;
          index1 < Object.keys(this.bookpricedata[index]).length;
          index1++
        ) {
          rcvd_amt =
            rcvd_amt + parseInt(this.bookpricedata[index][index1]['rcvd_amt']);
        }
      }
      let val = this.bookpymtrecptform.get('crdtvamt').value;
      this.bookpymtrecptform.get('seamt').setValue(parseInt(res.value));
      this.bookpymtrecptform.get('balamt').setValue(val - parseInt(res.value));
      this.invoice_total = rcvd_amt;
      if (this.bookpymtrecptform.get('balamt').value <= 0) {
        this.click = !this.click;
      }
    });
  }

  public demo1TabIndex = 0;
  public demo1BtnClick() {
    const tabCount = 17;
    this.demo1TabIndex = (this.demo1TabIndex + 1) % tabCount;
    this.bookrcptsetvalue();
  }

  bookrcptsetvalue() {
    this.bookpymtrecptform
      .get('crdtvno')
      .setValue(this.pendingbookdata['voucher_no']);
    this.bookpymtrecptform
      .get('recptdate')
      .setValue(this.pendingbookdata['voucher_date']);
    this.bookpymtrecptform
      .get('repname')
      .setValue(this.pendingbookdata['rep_name']);
    this.bookpymtrecptform
      .get('crdtvamt')
      .setValue(this.pendingbookdata['remaining_amt']);
    this.bookpymtrecptform
      .get('booksellername')
      .setValue(this.pendingbookdata['book_seller_name']);
      this.bookpymtrecptform
      .get('bname')
      .setValue(this.pendingbookdata['book_names']);


    let name = this.bookpymtrecptform.get('repname').value;
    let names = this.names.filter(
      (a) => a['name'] === this.pendingbookdata['rep_name']
    );
  }

  addbookpymtrcpt() {
    let data = {
      date: this.bookpymtrecptform.get('date').value,
      crdtvno: this.bookpymtrecptform.get('crdtvno').value,
      recptdate: this.bookpymtrecptform.get('recptdate').value,
      repname: this.bookpymtrecptform.get('repname').value,
      recptno: this.bookpymtrecptform.get('recptno').value,
      crdtvamt: this.bookpymtrecptform.get('crdtvamt').value,
      seamt: this.bookpymtrecptform.get('seamt').value,
      booksellername: this.bookpymtrecptform.get('booksellername').value,
      bname: this.bookpymtrecptform.get('bname').value,
      balamt: this.bookpymtrecptform.get('balamt').value,
      billno: this.booksellernamedata[0]['invoice_no'],
      value: this.val1,
    };

    this.dialog.open(BookstorereceiptComponent, {
      data: {
        data: data,
        bookpymtrcptdata: this.bookpymtrcptdata,
        book: this.pendingbookdata,
        bookpricedata: this.bookpricedata,
        booksellername: this.bookpymtrecptform.get('booksellernamesearch')
          .value,
        billno: this.booksellernamedata[0]['invoice_no'],
        val: data['seamt'],
      },
      height: '1300px',
      width: '1200px',
      maxWidth: '100%',
    });
  }



  openBookTwoReceipt(){
    this.dialog.open(BooktworeciptComponent, {
      data: {
        receiptdata: '',
        
       
      },
      height: '1300px',
      width: '95%',
      maxWidth: '100%',
    });

  }

  exportExcel(fileName) {
    /* table id is passed over here */
    let element = document.getElementById('print-section');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, fileName + '.xlsx');
  }

  getSelectedbookname = '';
  getBookname(event, option) {
    if (event.isUserInput) {
      this.getSelectedbookname = option['bookname'];
    }
  }

  allSelectedtable = false;
  someComplete(): boolean {
    if (this.bookRcptRegisterdata == null) {
      return false;
    }
    return (
      this.bookRcptRegisterdata.filter((t) => t.is_selected).length > 0 &&
      !this.allSelectedtable
    );
  }


  //getBookRcptPymtData
  selectall(event){
    // if(event.checked){
    //   debugger
    //   this.total =0;
    //   this.data_for_two_receipt.forEach(element => {
    //     element.is_selected = true;
    //     // this.total = this.total + element.remain_amt
    //   })
    //   }

    //   else{
    //     this.data_for_two_receipt.forEach(element => {
    //       element.is_selected = false;
    //     });;
    // }
    }

  getSelectedrepname = '';
  getselectedpaymentmode = '';
  getRepname(event) {
    this.getSelectedrepname = event.value;
    if (
      this.getselectedpaymentmode == 'all' ||
      this.getselectedpaymentmode == ''
    ) {
      this.filterBookwiseReport = this.bookwisereportData.filter(
        (a) => a['rep_name'] == this.getSelectedrepname
      );
    } else {
      if (this.getSelectedrepname == 'all') {
        this.filterBookwiseReport = this.bookwisereportData.filter(
          (a) => a['paymentmode'] == this.getselectedpaymentmode
        );
      } else {
        this.filterBookwiseReport = this.bookwisereportData.filter(
          (a) =>
            a['rep_name'] == this.getSelectedrepname &&
            a['paymentmode'] == this.getselectedpaymentmode
        );
      }
    }
  }

  ClearFilter() {
    this.filterBookwiseReport = this.bookwisereportData;
  }

  paymodes = ['all', 'cash', 'complementry', 'online', 'paylater', 'cheque'];
  getpaymentmode(event) {
    this.getselectedpaymentmode = event.value;
    if (this.getSelectedrepname == 'all' || this.getSelectedrepname == '') {
      this.filterBookwiseReport = this.bookwisereportData.filter(
        (a) => a['paymentmode'] == this.getselectedpaymentmode
      );
    } else {
      if (this.getselectedpaymentmode == 'all') {
        this.filterBookwiseReport = this.bookwisereportData.filter(
          (a) => a['rep_name'] == this.getSelectedrepname
        );
      } else {
        this.filterBookwiseReport = this.bookwisereportData.filter(
          (a) =>
            a['rep_name'] == this.getSelectedrepname &&
            a['paymentmode'] == this.getselectedpaymentmode
        );
      }
    }
  }

  getBookwiseReportonclick() {
    this.showbooks = true;
    this.getBookwiseReport(
      this.getSelectedbookname,
      this.getbookwiseStartDate,
      this.getbookwiseEndDate
    );
  }

  bookstatus_name = '';
  FilterBook(event) {
    let status = event.value;
    if (this.rep_name_registration != '' && this.cust_name_registration != '') {
      this.FilteredBookRegistration1 = this.bookregistrationdata.filter(
        (item) => {
          return (
            item['booking_date'] >= this.startDate_registration &&
            item['booking_date'] <= this.enddate_registration &&
            item.name
              .toLowerCase()
              .indexOf(this.cust_name_registration.toLowerCase()) > -1 &&
            item.crep_name
              .toLowerCase()
              .indexOf(this.rep_name_registration.toLowerCase()) > -1 &&
            item.bookname.toLowerCase().indexOf(status.toLowerCase()) > -1
          );
        }
      );
    } else if (
      this.cust_name_registration == ''
      // this.paymentStatus == 'all' ||
      // status == 'all'
    ) {
      this.FilteredBookRegistration1 = this.bookregistrationdata.filter(
        (item) => {
          return (
            item['booking_date'] >= this.startDate_registration &&
            item['booking_date'] <= this.enddate_registration &&
            // item.payment_status
            //   .toLowerCase()
            //   .indexOf(this.paymentStatus.toLowerCase()) > -1 &&
            item.crep_name
              .toLowerCase()
              .indexOf(this.rep_name_registration.toLowerCase()) > -1 &&
            item.bookname.toLowerCase().indexOf(status.toLowerCase()) > -1
          );
        }
      );
    } else if (
      this.rep_name_registration == ''
      // this.paymentStatus == 'all' ||
      // status == 'all'
    ) {
      this.FilteredBookRegistration1 = this.bookregistrationdata.filter(
        (item) => {
          return (
            item['booking_date'] >= this.startDate_registration &&
            item['booking_date'] <= this.enddate_registration &&
            // item.payment_status
            //   .toLowerCase()
            //   .indexOf(this.paymentStatus.toLowerCase()) > -1 &&
            item.name
              .toLowerCase()
              .indexOf(this.cust_name_registration.toLowerCase()) > -1 &&
            item.bookname.toLowerCase().indexOf(status.toLowerCase()) > -1
          );
        }
      );
    } else {
      this.FilteredBookRegistration1 = this.bookregistrationdata.filter(
        (item) => {
          return (
            item['booking_date'] >= this.startDate_registration &&
            item['booking_date'] <= this.enddate_registration &&
            item.bookname.toLowerCase().indexOf(status.toLowerCase()) > -1
          );
        }
      );
    }
  }

  GetUpdatedCommissionValue(id) {
    let commission = window.prompt('Please enter new Comission Percentage!');
    if (commission == '' || commission == null) {
      commission = '0';
    } else {
      this.service
        .updateRep_book_commision(parseInt(id), parseInt(commission))
        .subscribe((data) => {
          this.service.get_AllRepresentative_List().subscribe((data) => {
            this.getAllRepList = data;
          });
        });
    }
  }

  getinvoicestartDate;
  getinvoiceEndDate;
  getDateRange() {
    if (
      this.invoicegetBook.get('start').value != null &&
      this.invoicegetBook.get('end').value != null
    ) {
      this.getinvoicestartDate = formatDate(
        new Date(this.invoicegetBook.get('start').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );
      this.getinvoiceEndDate = formatDate(
        new Date(this.invoicegetBook.get('end').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );
    }
  }

  nameid;
  name;
  selectedname(event, option) {
    if (event.isUserInput) {
      this.nameid = option['id'];
      this.name = option['name'];
    }
  }

  getSelectedRepId;
  getSelectedRep;
  SelectRepresentative(event) {
    this.getSelectedRepId = event.value;
    this.getSelectedRep = this.getAllRepList.find(
      (a) => a['id'] == parseInt(this.getSelectedRepId)
    );
    this.service
      .Rep_invoiceBooks(
        parseInt(this.getSelectedRepId),
        this.getinvoicestartDate,
        this.getinvoiceEndDate
      )
      .subscribe((data) => {
        this.getRepSpecificBooks = data;
      });
  }

  getRepSelectedBookslist;
  getRepSelectedBooks(event) {
    this.getRepSelectedBookslist = event.value;
  }

  isGSTInvoice = 'N';
  displaygstNumberInput = false;
  getInvoiceType(event) {
    this.isGSTInvoice = event.value;
    if (this.isGSTInvoice == 'Y') {
      this.displaygstNumberInput = true;
    } else {
      this.displaygstNumberInput = false;
    }
  }

  getGstNumber(gst) {
    this.gstNumber = gst;
  }

  invoicedate = '';
  invoicedateChange(event) {
    // console.log(event);

    this.invoicedate = formatDate(
      event.target.value,
      'yyyy-MM-dd',
      'en-IN',
      'GMT+0530'
    );
  }

  invoiceDetails;
  viewmode = false;
  repInvoice = 'Y';
  generateBookInvoice() {
    this.invoiceDetails = {
      rep: this.getSelectedRep,
      books: this.getRepSelectedBookslist,
      date: this.invoicedate,
      GST: this.isGSTInvoice,
      GST_no: this.gstNumber,
    };
    this.viewmode = false;
    this.repInvoice = 'Y';
    this.GenerateInvoice();
  }

  GenerateInvoice() {
    this.dialog.open(BookinvoiceComponent, {
      data: {
        book: this.invoiceDetails,
        view: this.viewmode,
        rep_invoice: this.repInvoice,
      },
      height: '1300px',
      width: '1200px',
      maxWidth: '100%',
    });
  }

  show(book) {
    this.dialog.open(ShowBookInvoiceComponent, {
      data: {
        book: book,
      },
      height: '1300px',
      width: '1200px',
      maxWidth: '100%',
    });
  }

  showcrdtrcpt(item) {
    this.dialog.open(ShowBookCrdtNoteRcptComponent, {
      data: {
        data: item,
      },
      height: '1300px',
      width: '1200px',
      maxWidth: '100%',
    });
  }

  getCustinvoicestartDate;
  getCustinvoiceEndDate;
  getAllCustList = [];
  getCustlistWithoutFilter = [];
  getSpecificBooksCust = [];
  getCustDateRange() {
    this.getAllCustList = [];
    if (
      this.invoicegetCustBook.get('start').value != null &&
      this.invoicegetCustBook.get('end').value != null
    ) {
      this.getCustinvoicestartDate = formatDate(
        new Date(this.invoicegetCustBook.get('start').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );
      this.getCustinvoiceEndDate = formatDate(
        new Date(this.invoicegetCustBook.get('end').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );

      this.service
        .getCustInvoicesName(
          this.getCustinvoicestartDate,
          this.getCustinvoiceEndDate
        )
        .subscribe((data) => {
          this.uniquedata = data;
        });
    }
  }

  getbookcustInvoiceId() {
    this.service
      .getBookCustInvoiceId(
        this.invoicedate === ''
          ? formatDate(new Date(), 'yyyy-MM-dd', 'en-IN', 'GMT+0530')
          : this.invoicedate
      )
      .subscribe((data) => {
        // console.log('data', data);
        let value: number;
        if (Object.keys(data).length > 0) {
          // console.log('1', data[0]['invoice_no'].split('/'));
          value = parseInt(data[0]['invoice_no'].split('/')[2]);
        } else {
          value = 0;
        }
        // console.log('value', value);
        let month = ('0' + (new Date(this.invoicedate).getMonth() + 1)).slice(
          -2
        );
        let year = new Date(this.invoicedate)
          .getFullYear()
          .toString()
          .slice(-2);
        let nextyear = (new Date(this.invoicedate).getFullYear() + 1)
          .toString()
          .slice(-2);
        // console.log(
        //   formatDate(
        //     new Date(new Date().getFullYear(), new Date('4').getMonth(), 1),
        //     'yyyy-MM-dd',
        //     'en-IN',
        //     'GMT+0530'
        //   )
        // );
        if (
          this.invoicedate >=
          formatDate(
            new Date(new Date().getFullYear(), new Date('4').getMonth(), 1),
            'yyyy-MM-dd',
            'en-IN',
            'GMT+0530'
          )
        ) {
          this.invoiceno = `VBD${month}/${year}-${nextyear}/${
            value <= 9 ? '0' + (value + 1) : value + 1
          }`;
        } else {
          this.invoiceno = `VBD${month}/${parseInt(year) - 1}-${year}/${
            value <= 9 ? '0' + (value + 1) : value + 1
          }`;
        }
        // console.log('invoice no', this.invoiceno);
      });
  }

  insertdata() {
    if (this.getSelectedCust == undefined) {
      for (
        let index = 0;
        index < Object.keys(this.uniquedata).length;
        index++
      ) {
        this.getbookcustInvoiceId();
        this.getDetailsData(this.uniquedata[index]['id']);
        this.service
          .getSingleBookSellerEntry(this.uniquedata[index]['id'])
          .subscribe((data) => {
            this.service
              .InsertCustInvoice(
                data[0]['crep_name'],
                data[0]['rep_mob_no'],
                data[0]['id'],
                data[0]['address'],
                this.invoiceno,
                this.invoicedate,
                '',
                '',
                this.gstNumber,
                this.totals,
                this.shipping_charges_total,
                0,
                0,
                0,
                0
              )
              .subscribe((custdata) => {
                for (
                  let index = 0;
                  index < Object.keys(this.book_data1).length;
                  index++
                ) {
                  this.service
                    .InsertCustInvoiceData(
                      parseInt(custdata['id']),
                      this.book_data1[index]['bookname'],
                      this.book_data1[index]['authorname'],
                      parseInt(this.book_data1[index]['bookcopies']),
                      parseInt(this.book_data1[index]['discount']),
                      parseInt(this.book_data1[index]['discountamt']),
                      parseInt(this.book_data1[index]['bookprice']),
                      parseInt(this.book_data1[index]['booksaleprice']),
                      parseInt(this.book_data1[index]['total']),
                      parseInt(this.book_data1[index]['shippingcharges']),
                      parseInt(this.book_data1[index]['total_amount']),
                      parseInt(this.book_data1[index]['received_amt']),
                      parseInt(this.book_data1[index]['remaining_amt']),
                      parseInt(
                        this.book_data1[index]['rep_commission_precent']
                      ),
                      parseInt(this.book_data1[index]['rep_commission_amt'])
                    )
                    .subscribe((invoice) => {});
                }
                setTimeout(() => {
                  window.alert(
                    'Invoice ' + parseInt(custdata['id']) + ' has been created.'
                  );
                }, (Object.keys(this.book_data1).length / 2) * 1000);
              });
          });
      }
    }
  }

  getDetailsData(id) {
    this.service.getBooks(id).subscribe((data) => {
      this.book_data1 = data;
      for (
        let index = 0;
        index < Object.keys(this.book_data1).length;
        index++
      ) {
        this.book_price_total =
          this.book_price_total + parseInt(this.book_data1[index]['bookprice']);
        this.book_sale_price_total =
          this.book_sale_price_total +
          parseInt(this.book_data1[index]['booksaleprice']);
        this.book_copies_total =
          this.book_copies_total +
          parseInt(this.book_data1[index]['bookcopies']);
        this.shipping_charges_total =
          this.shipping_charges_total +
          parseInt(this.book_data1[index]['shippingcharges']);
        this.total_total =
          this.total_total + parseInt(this.book_data1[index]['total']);
      }
      this.totals = this.total_total + this.shipping_charges_total;
    });
  }

  getSelectedCust;
  SelectCustomer(event) {
    this.getSelectedCust = event.value;
  }

  generateCustInvoice() {
    if (this.invoicedate !== '') {
      this.insertdata();
      if (this.getSelectedCust != undefined) {
        this.invoiceDetails = {
          id: this.getSelectedCust,
          date: this.invoicedate,
          GST: this.isGSTInvoice,
          GST_no: this.gstNumber,
        };
        this.viewmode = false;
        this.repInvoice = 'N';
        this.GenerateInvoice();
      }
    } else {
      alert('Please select a date');
    }
  }

  repGeneratedInvoice = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });

  getGeneratedInvoiceStartDate;
  getGeneratedInvoiceEndDate;
  getRepInvoiceDateRange() {
    if (
      this.repGeneratedInvoice.get('start').value != null &&
      this.repGeneratedInvoice.get('end').value != null
    ) {
      this.getGeneratedInvoiceStartDate = formatDate(
        new Date(this.repGeneratedInvoice.get('start').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );
      this.getGeneratedInvoiceEndDate = formatDate(
        new Date(this.repGeneratedInvoice.get('end').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );
    }
  }

  getRepInvoice() {
    this.service
      .getRepInvoices(
        this.getGeneratedInvoiceStartDate,
        this.getGeneratedInvoiceEndDate
      )
      .subscribe((data) => {
        this.RepInvoiceData = data;
      });
  }

  getRepInvoiceData = [];
  ViewInvoice(book) {
    this.getSelectedRep = this.getAllRepList.find(
      (a) => a['id'] == parseInt(book['id'])
    );

    if (
      parseInt(book['cgst']) == 0 ||
      parseInt(book['sgst']) == 0 ||
      parseInt(book['igst']) == 0
    ) {
      this.isGSTInvoice = 'N';
    } else {
      this.isGSTInvoice = 'Y';
    }

    this.service
      .getRepInvoicesData(parseInt(book['invoice_id']))
      .subscribe((data) => {
        this.getRepInvoiceData = data;
        let getBookInvoiceDetails = {
          invoice_details: book,
          bookDetail: this.getRepInvoiceData,
        };
        this.invoiceDetails = {
          rep: this.getSelectedRep,
          books: getBookInvoiceDetails,
          GST: this.isGSTInvoice,
          GST_no: book['gst_no'],
        };
        this.viewmode = true;
        this.repInvoice = 'Y';
        setTimeout(() => {
          this.GenerateInvoice();
        }, 1000);
      });
  }

  getGeneratedInvoiceStartDateCust;
  getGeneratedInvoiceEndDateCust;
  getCustInvoiceDateRange() {
    if (
      this.CustGeneratedInvoice.get('start').value != null &&
      this.CustGeneratedInvoice.get('end').value != null
    ) {
      this.getGeneratedInvoiceStartDateCust = formatDate(
        new Date(this.CustGeneratedInvoice.get('start').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );
      this.getGeneratedInvoiceEndDateCust = formatDate(
        new Date(this.CustGeneratedInvoice.get('end').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );
    }
  }

  getCustInvoice() {
    this.service
      .getCustInvoices(
        this.getGeneratedInvoiceStartDateCust,
        this.getGeneratedInvoiceEndDateCust
      )
      .subscribe((data) => {
        this.CustInvoiceData = data;
      });
  }

  ViewCustInvoice(book) {
    this.getSelectedRep = this.getAllRepList.find(
      (a) => a['id'] == parseInt(book['id'])
    );

    if (
      parseInt(book['cgst']) == 0 ||
      parseInt(book['sgst']) == 0 ||
      parseInt(book['igst']) == 0
    ) {
      this.isGSTInvoice = 'N';
    } else {
      this.isGSTInvoice = 'Y';
    }

    this.service
      .getCustInvoicesData(parseInt(book['invoice_id']))
      .subscribe((data) => {
        this.getRepInvoiceData = data;
        let getBookInvoiceDetails = {
          invoice_details: book,
          bookDetail: this.getRepInvoiceData,
        };
        this.invoiceDetails = {
          rep: this.getSelectedRep,
          books: getBookInvoiceDetails,
          GST: this.isGSTInvoice,
          GST_no: book['gst_no'],
        };
        this.viewmode = true;
        this.repInvoice = 'N';
        setTimeout(() => {
          this.GenerateInvoice();
        }, 1000);
      });
  }

  complementry(event: boolean) {
    this.iscomplementary = event;
  }

  saveauthor() {
    if (
      window.confirm(
        'This operation is not reversable. Please confirm before proceed.'
      )
    ) {
      let data = {
        authorname: this.authorForm.get('authorname').value,
        address: this.authorForm.get('address').value,
        pincode: this.authorForm.get('pincode').value,
        country: this.authorForm.get('country').value,
        state: this.authorForm.get('state').value,
        district: this.authorForm.get('district').value,
        taluka: this.authorForm.get('taluka').value,
        mob_no: this.authorForm.get('mob_no').value,
        email: this.authorForm.get('email').value,
        pan_no: this.authorForm.get('pan_no').value,
        aadhar_no: this.authorForm.get('aadhar_no').value,
        bank_name: this.authorForm.get('bank_name').value,
        branch: this.authorForm.get('branch').value,
        acc_no: this.authorForm.get('acc_no').value,
        ifsc_code: this.authorForm.get('ifsc_code').value,
      };
      // console.log({ data });
      this.service.addAuthorAddress(data).subscribe((dataFromDB) => {
        alert('Author Data Added Successfully.');
        // console.log({ dataFromDB });
        setTimeout(() => {
          this.authorForm.reset();
          this.getAuthorData();
        }, 1500);
      });
    }
  }

  getAuthorData() {
    this.service.getAuthorData().subscribe((data) => {
      this.authorData = data;
      // console.log(data);

      for (
        let index = 0;
        index < Object.keys(this.authorData).length;
        index++
      ) {
        this.authornames.push({
          id: this.authorData[index]['id'],
          name: this.authorData[index]['author_name'],
        });
      }
      // console.log(this.authornames);
    });
  }

  isauthor: Boolean = false;
  authorId = 0;
  selected_authorname(event, option) {
    if (event.isUserInput) {
      for (
        let index = 0;
        index < Object.keys(this.authorData).length;
        index++
      ) {
        if (option['id'] == this.authorData[index]['id']) {
          this.isauthor = true;
          this.authorId = this.authorData[index]['id'];
          this.authorForm
            .get('authorname')
            .setValue(this.authorData[index]['author_name']);
          this.authorForm
            .get('address')
            .setValue(this.authorData[index]['author_address']);
          this.authorForm
            .get('pincode')
            .setValue(this.authorData[index]['pincode']);
          this.authorForm
            .get('country')
            .setValue(this.authorData[index]['country']);
          this.authorForm
            .get('state')
            .setValue(this.authorData[index]['state']);
          this.authorForm
            .get('district')
            .setValue(this.authorData[index]['district']);
          this.authorForm
            .get('taluka')
            .setValue(this.authorData[index]['taluka']);
          this.authorForm
            .get('mob_no')
            .setValue(this.authorData[index]['mob_no']);
          this.authorForm
            .get('email')
            .setValue(this.authorData[index]['email']);
          this.authorForm
            .get('pan_no')
            .setValue(this.authorData[index]['pan_no']);
          this.authorForm
            .get('aadhar_no')
            .setValue(
              this.authorData[index]['aadhar_no'] > 0
                ? this.authorData[index]['aadhar_no']
                : ''
            );
          this.authorForm
            .get('bank_name')
            .setValue(this.authorData[index]['bank_name']);
          this.authorForm
            .get('branch')
            .setValue(this.authorData[index]['branch']);
          this.authorForm
            .get('acc_no')
            .setValue(
              this.authorData[index]['account_no'] > 0
                ? this.authorData[index]['account_no']
                : ''
            );
          this.authorForm
            .get('ifsc_code')
            .setValue(this.authorData[index]['ifsc_code']);
        }
      }
    }
  }

  updateAuthor() {
    if (this.authorId != 0) {
      let data = {
        id: this.authorId,
        authorname: this.authorForm.get('authorname').value,
        address: this.authorForm.get('address').value,
        pincode: this.authorForm.get('pincode').value,
        country: this.authorForm.get('country').value,
        state: this.authorForm.get('state').value,
        district: this.authorForm.get('district').value,
        taluka: this.authorForm.get('taluka').value,
        mob_no: this.authorForm.get('mob_no').value,
        email: this.authorForm.get('email').value,
        pan_no: this.authorForm.get('pan_no').value,
        aadhar_no: this.authorForm.get('aadhar_no').value,
        bank_name: this.authorForm.get('bank_name').value,
        branch: this.authorForm.get('branch').value,
        acc_no: this.authorForm.get('acc_no').value,
        ifsc_code: this.authorForm.get('ifsc_code').value,
      };
      // console.log({ data });

      this.service.updateAuthorAddress(data).subscribe((data) => {
        alert('Author Address Updated Successfully..');

        setTimeout(() => {
          this.authorForm.reset();
          this.authorId = 0;
          this.getAuthorData();
        }, 1500);
      });
    }
  }

  deleteAuthorData() {
    if (this.authorId != 0) {
      this.service.deleteAuthorAddress(this.authorId).subscribe((data) => {
        alert('Author Address Deleted Successfully.');

        setTimeout(() => {
          this.authorForm.reset();
          this.authorId = 0;
          this.getAuthorData();
        }, 1500);
      });
    }
  }

  deleteBookPymtreceipt(id:any) {
    debugger
    const req:any = {
      "id":id
    }
    debugger;
    console.log(req, "this delete id")
    if (window.confirm('Are you sure want to delete?')) {
      this.service.deleteBookPymtreceipt(req).subscribe((res) => {     
        console.log(res)
      });
    }
    this.ngOnInit()
  }

  getAuthorAddress() {
    this.dialog.open(AuthorAddressComponent, {
      data: {},
      height: '1300px',
      width: '1200px',
      maxWidth: '100%',
    });
  }

  booksname = '';
  selected_book_name(event, option) {
    if (event.isUserInput) {
      this.booksname = option['name'];
      if (this.booksname != '') {
        // console.log(this.bookSellerAddressData);
        this.FilteredBookSellerAddress = this.bookSellerAddressData.filter(
          (item) => {
            return (
              item.name.toLowerCase().indexOf(this.booksname.toLowerCase()) > -1
            );
          }
        );
      }
    }
    // console.log(this.bookSellerAddressData);
  }

  getauthorStartDate;
  getauthorEndDate;
  getAuthorDate() {
    if (
      this.bookselleraddressForm.get('start').value != null &&
      this.bookselleraddressForm.get('end').value != null
    ) {
      this.getauthorStartDate = formatDate(
        new Date(this.bookselleraddressForm.get('start').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );
      this.getauthorEndDate = formatDate(
        new Date(this.bookselleraddressForm.get('end').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );

      if (
        this.booksname == '' &&
        this.getauthorStartDate != null &&
        this.getauthorEndDate != null
      ) {
        // console.log(this.bookSellerAddressData);
        this.FilteredBookSellerAddress = this.bookSellerAddressData.filter(
          (item) => {
            return (
              item['date'] >= this.getauthorStartDate &&
              item['date'] <= this.getauthorEndDate
            );
          }
        );
      } else if (
        this.booksname != '' &&
        this.getauthorStartDate != null &&
        this.getauthorEndDate != null
      ) {
        // console.log(this.bookSellerAddressData);
        this.FilteredBookSellerAddress = this.bookSellerAddressData.filter(
          (item) => {
            return (
              item.name.toLowerCase().indexOf(this.booksname.toLowerCase()) >
                -1 &&
              item['date'] >= this.getauthorStartDate &&
              item['date'] <= this.getauthorEndDate
            );
          }
        );
      } else {
        this.FilteredBookSellerAddress = this.bookSellerAddressData.filter(
          (item) => {
            return (
              item.name.toLowerCase().indexOf(this.booksname.toLowerCase()) > -1
            );
          }
        );
      }
    }
    // console.log(this.FilteredBookSellerAddress);
  }

  countryname = '';
  country(event) {
    this.countryname = event.target.value;
    console.log(this.countryname);
    if (
      this.countryname != '' &&
      this.booksname != '' &&
      this.getauthorStartDate != null &&
      this.getauthorEndDate != null
    ) {
      this.FilteredBookSellerAddress = this.bookSellerAddressData.filter(
        (item) => {
          return (
            item.name.toLowerCase().indexOf(this.booksname.toLowerCase()) >
              -1 &&
            item['date'] >= this.getauthorStartDate &&
            item['date'] <= this.getauthorEndDate &&
            item.country.toLowerCase().indexOf(this.countryname.toLowerCase()) >
              -1
          );
        }
      );
    } else if (
      this.countryname != '' &&
      this.booksname == '' &&
      this.getauthorStartDate != null &&
      this.getauthorEndDate != null
    ) {
      this.FilteredBookSellerAddress = this.bookSellerAddressData.filter(
        (item) => {
          return (
            item['date'] >= this.getauthorStartDate &&
            item['date'] <= this.getauthorEndDate &&
            item.country.toLowerCase().indexOf(this.countryname.toLowerCase()) >
              -1
          );
        }
      );
    } else if (
      this.countryname != '' &&
      this.booksname != '' &&
      this.getauthorStartDate == null &&
      this.getauthorEndDate == null
    ) {
      this.FilteredBookSellerAddress = this.bookSellerAddressData.filter(
        (item) => {
          return (
            item.name.toLowerCase().indexOf(this.booksname.toLowerCase()) >
              -1 &&
            item.country.toLowerCase().indexOf(this.countryname.toLowerCase()) >
              -1
          );
        }
      );
    } else if (
      this.countryname != '' &&
      this.booksname == '' &&
      this.getauthorStartDate == null &&
      this.getauthorEndDate == null
    ) {
      this.FilteredBookSellerAddress = this.bookSellerAddressData.filter(
        (item) => {
          return (
            item.country.toLowerCase().indexOf(this.countryname.toLowerCase()) >
            -1
          );
        }
      );
    }
  }

  statename = '';
  state(event) {
    this.statename = event.target.value;
    if (
      this.statename != '' &&
      this.countryname != '' &&
      this.booksname != '' &&
      this.getauthorStartDate != null &&
      this.getauthorEndDate != null
    ) {
      this.FilteredBookSellerAddress = this.bookSellerAddressData.filter(
        (item) => {
          return (
            item.name.toLowerCase().indexOf(this.booksname.toLowerCase()) >
              -1 &&
            item['date'] >= this.getauthorStartDate &&
            item['date'] <= this.getauthorEndDate &&
            item.country.toLowerCase().indexOf(this.countryname.toLowerCase()) >
              -1 &&
            item.state.toLowerCase().indexOf(this.statename.toLowerCase()) > -1
          );
        }
      );
    } else if (
      this.statename != '' &&
      this.countryname == '' &&
      this.booksname != '' &&
      this.getauthorStartDate != null &&
      this.getauthorEndDate != null
    ) {
      this.FilteredBookSellerAddress = this.bookSellerAddressData.filter(
        (item) => {
          return (
            item.name.toLowerCase().indexOf(this.booksname.toLowerCase()) >
              -1 &&
            item['date'] >= this.getauthorStartDate &&
            item['date'] <= this.getauthorEndDate &&
            item.state.toLowerCase().indexOf(this.statename.toLowerCase()) > -1
          );
        }
      );
    } else if (
      this.statename != '' &&
      this.countryname != '' &&
      this.booksname == '' &&
      this.getauthorStartDate != null &&
      this.getauthorEndDate != null
    ) {
      this.FilteredBookSellerAddress = this.bookSellerAddressData.filter(
        (item) => {
          return (
            item['date'] >= this.getauthorStartDate &&
            item['date'] <= this.getauthorEndDate &&
            item.country.toLowerCase().indexOf(this.countryname.toLowerCase()) >
              -1 &&
            item.state.toLowerCase().indexOf(this.statename.toLowerCase()) > -1
          );
        }
      );
    } else if (
      this.statename != '' &&
      this.countryname != '' &&
      this.booksname != '' &&
      this.getauthorStartDate == null &&
      this.getauthorEndDate == null
    ) {
      this.FilteredBookSellerAddress = this.bookSellerAddressData.filter(
        (item) => {
          return (
            item.name.toLowerCase().indexOf(this.booksname.toLowerCase()) >
              -1 &&
            item.country.toLowerCase().indexOf(this.countryname.toLowerCase()) >
              -1 &&
            item.state.toLowerCase().indexOf(this.statename.toLowerCase()) > -1
          );
        }
      );
    } else if (
      this.statename != '' &&
      this.countryname == '' &&
      this.booksname == '' &&
      this.getauthorStartDate != null &&
      this.getauthorEndDate != null
    ) {
      this.FilteredBookSellerAddress = this.bookSellerAddressData.filter(
        (item) => {
          return (
            item['date'] >= this.getauthorStartDate &&
            item['date'] <= this.getauthorEndDate &&
            item.state.toLowerCase().indexOf(this.statename.toLowerCase()) > -1
          );
        }
      );
    } else if (
      this.statename != '' &&
      this.countryname == '' &&
      this.booksname != '' &&
      this.getauthorStartDate == null &&
      this.getauthorEndDate == null
    ) {
      this.FilteredBookSellerAddress = this.bookSellerAddressData.filter(
        (item) => {
          return (
            item.name.toLowerCase().indexOf(this.booksname.toLowerCase()) >
              -1 &&
            item.state.toLowerCase().indexOf(this.statename.toLowerCase()) > -1
          );
        }
      );
    } else if (
      this.statename != '' &&
      this.countryname != '' &&
      this.booksname == '' &&
      this.getauthorStartDate == null &&
      this.getauthorEndDate == null
    ) {
      this.FilteredBookSellerAddress = this.bookSellerAddressData.filter(
        (item) => {
          return (
            item.country.toLowerCase().indexOf(this.countryname.toLowerCase()) >
              -1 &&
            item.state.toLowerCase().indexOf(this.statename.toLowerCase()) > -1
          );
        }
      );
    } else if (
      this.statename != '' &&
      this.countryname == '' &&
      this.booksname == '' &&
      this.getauthorStartDate == null &&
      this.getauthorEndDate == null
    ) {
      this.FilteredBookSellerAddress = this.bookSellerAddressData.filter(
        (item) => {
          return (
            item.state.toLowerCase().indexOf(this.statename.toLowerCase()) > -1
          );
        }
      );
    }
  }

  districtname = '';
  district(event) {
    this.districtname = event.target.value;
    if (
      this.districtname != '' &&
      this.statename != '' &&
      this.countryname != '' &&
      this.booksname != '' &&
      this.getauthorStartDate != null &&
      this.getauthorEndDate != null
    ) {
      this.FilteredBookSellerAddress = this.bookSellerAddressData.filter(
        (item) => {
          return (
            item.name.toLowerCase().indexOf(this.booksname.toLowerCase()) >
              -1 &&
            item['date'] >= this.getauthorStartDate &&
            item['date'] <= this.getauthorEndDate &&
            item.country.toLowerCase().indexOf(this.countryname.toLowerCase()) >
              -1 &&
            item.state.toLowerCase().indexOf(this.statename.toLowerCase()) >
              -1 &&
            item.district
              .toLowerCase()
              .indexOf(this.districtname.toLowerCase()) > -1
          );
        }
      );
    } else if (
      this.districtname != '' &&
      this.statename == '' &&
      this.countryname != '' &&
      this.booksname != '' &&
      this.getauthorStartDate != null &&
      this.getauthorEndDate != null
    ) {
      this.FilteredBookSellerAddress = this.bookSellerAddressData.filter(
        (item) => {
          return (
            item.name.toLowerCase().indexOf(this.booksname.toLowerCase()) >
              -1 &&
            item['date'] >= this.getauthorStartDate &&
            item['date'] <= this.getauthorEndDate &&
            item.country.toLowerCase().indexOf(this.countryname.toLowerCase()) >
              -1 &&
            item.district
              .toLowerCase()
              .indexOf(this.districtname.toLowerCase()) > -1
          );
        }
      );
    } else if (
      this.districtname != '' &&
      this.statename != '' &&
      this.countryname == '' &&
      this.booksname != '' &&
      this.getauthorStartDate != null &&
      this.getauthorEndDate != null
    ) {
      this.FilteredBookSellerAddress = this.bookSellerAddressData.filter(
        (item) => {
          return (
            item.name.toLowerCase().indexOf(this.booksname.toLowerCase()) >
              -1 &&
            item['date'] >= this.getauthorStartDate &&
            item['date'] <= this.getauthorEndDate &&
            item.state.toLowerCase().indexOf(this.statename.toLowerCase()) >
              -1 &&
            item.district
              .toLowerCase()
              .indexOf(this.districtname.toLowerCase()) > -1
          );
        }
      );
    } else if (
      this.districtname != '' &&
      this.statename != '' &&
      this.countryname != '' &&
      this.booksname == '' &&
      this.getauthorStartDate != null &&
      this.getauthorEndDate != null
    ) {
      this.FilteredBookSellerAddress = this.bookSellerAddressData.filter(
        (item) => {
          return (
            item['date'] >= this.getauthorStartDate &&
            item['date'] <= this.getauthorEndDate &&
            item.country.toLowerCase().indexOf(this.countryname.toLowerCase()) >
              -1 &&
            item.state.toLowerCase().indexOf(this.statename.toLowerCase()) >
              -1 &&
            item.district
              .toLowerCase()
              .indexOf(this.districtname.toLowerCase()) > -1
          );
        }
      );
    } else if (
      this.districtname != '' &&
      this.statename != '' &&
      this.countryname != '' &&
      this.booksname != '' &&
      this.getauthorStartDate == null &&
      this.getauthorEndDate == null
    ) {
      this.FilteredBookSellerAddress = this.bookSellerAddressData.filter(
        (item) => {
          return (
            item.name.toLowerCase().indexOf(this.booksname.toLowerCase()) >
              -1 &&
            item.country.toLowerCase().indexOf(this.countryname.toLowerCase()) >
              -1 &&
            item.state.toLowerCase().indexOf(this.statename.toLowerCase()) >
              -1 &&
            item.district
              .toLowerCase()
              .indexOf(this.districtname.toLowerCase()) > -1
          );
        }
      );
    } else if (
      this.districtname != '' &&
      this.statename == '' &&
      this.countryname == '' &&
      this.booksname != '' &&
      this.getauthorStartDate != null &&
      this.getauthorEndDate != null
    ) {
      this.FilteredBookSellerAddress = this.bookSellerAddressData.filter(
        (item) => {
          return (
            item.name.toLowerCase().indexOf(this.booksname.toLowerCase()) >
              -1 &&
            item['date'] >= this.getauthorStartDate &&
            item['date'] <= this.getauthorEndDate &&
            item.district
              .toLowerCase()
              .indexOf(this.districtname.toLowerCase()) > -1
          );
        }
      );
    } else if (
      this.districtname != '' &&
      this.statename == '' &&
      this.countryname != '' &&
      this.booksname == '' &&
      this.getauthorStartDate != null &&
      this.getauthorEndDate != null
    ) {
      this.FilteredBookSellerAddress = this.bookSellerAddressData.filter(
        (item) => {
          return (
            item['date'] >= this.getauthorStartDate &&
            item['date'] <= this.getauthorEndDate &&
            item.country.toLowerCase().indexOf(this.countryname.toLowerCase()) >
              -1 &&
            item.district
              .toLowerCase()
              .indexOf(this.districtname.toLowerCase()) > -1
          );
        }
      );
    } else if (
      this.districtname != '' &&
      this.statename == '' &&
      this.countryname != '' &&
      this.booksname != '' &&
      this.getauthorStartDate == null &&
      this.getauthorEndDate == null
    ) {
      this.FilteredBookSellerAddress = this.bookSellerAddressData.filter(
        (item) => {
          return (
            item.name.toLowerCase().indexOf(this.booksname.toLowerCase()) >
              -1 &&
            item.country.toLowerCase().indexOf(this.countryname.toLowerCase()) >
              -1 &&
            item.district
              .toLowerCase()
              .indexOf(this.districtname.toLowerCase()) > -1
          );
        }
      );
    } else if (
      this.districtname != '' &&
      this.statename == '' &&
      this.countryname == '' &&
      this.booksname == '' &&
      this.getauthorStartDate != null &&
      this.getauthorEndDate != null
    ) {
      this.FilteredBookSellerAddress = this.bookSellerAddressData.filter(
        (item) => {
          return (
            item['date'] >= this.getauthorStartDate &&
            item['date'] <= this.getauthorEndDate &&
            item.district
              .toLowerCase()
              .indexOf(this.districtname.toLowerCase()) > -1
          );
        }
      );
    } else if (
      this.districtname != '' &&
      this.statename == '' &&
      this.countryname == '' &&
      this.booksname != '' &&
      this.getauthorStartDate == null &&
      this.getauthorEndDate == null
    ) {
      this.FilteredBookSellerAddress = this.bookSellerAddressData.filter(
        (item) => {
          return (
            item.name.toLowerCase().indexOf(this.booksname.toLowerCase()) >
              -1 &&
            item.district
              .toLowerCase()
              .indexOf(this.districtname.toLowerCase()) > -1
          );
        }
      );
    } else if (
      this.districtname != '' &&
      this.statename != '' &&
      this.countryname == '' &&
      this.booksname == '' &&
      this.getauthorStartDate == null &&
      this.getauthorEndDate == null
    ) {
      this.FilteredBookSellerAddress = this.bookSellerAddressData.filter(
        (item) => {
          return (
            item.state.toLowerCase().indexOf(this.statename.toLowerCase()) >
              -1 &&
            item.district
              .toLowerCase()
              .indexOf(this.districtname.toLowerCase()) > -1
          );
        }
      );
    } else if (
      this.districtname != '' &&
      this.statename == '' &&
      this.countryname != '' &&
      this.booksname == '' &&
      this.getauthorStartDate == null &&
      this.getauthorEndDate == null
    ) {
      this.FilteredBookSellerAddress = this.bookSellerAddressData.filter(
        (item) => {
          return (
            item.country.toLowerCase().indexOf(this.countryname.toLowerCase()) >
              -1 &&
            item.district
              .toLowerCase()
              .indexOf(this.districtname.toLowerCase()) > -1
          );
        }
      );
    } else if (
      this.districtname != '' &&
      this.statename == '' &&
      this.countryname == '' &&
      this.booksname == '' &&
      this.getauthorStartDate == null &&
      this.getauthorEndDate == null
    ) {
      this.FilteredBookSellerAddress = this.bookSellerAddressData.filter(
        (item) => {
          return (
            item.district
              .toLowerCase()
              .indexOf(this.districtname.toLowerCase()) > -1
          );
        }
      );
    }
  }

  talukaname = '';
  taluka(event) {
    this.talukaname = event.target.value;
    if (
      this.talukaname != '' &&
      this.districtname != '' &&
      this.statename != '' &&
      this.countryname != '' &&
      this.booksname != '' &&
      this.getauthorStartDate != null &&
      this.getauthorEndDate != null
    ) {
      this.FilteredBookSellerAddress = this.bookSellerAddressData.filter(
        (item) => {
          return (
            item.name.toLowerCase().indexOf(this.booksname.toLowerCase()) >
              -1 &&
            item['date'] >= this.getauthorStartDate &&
            item['date'] <= this.getauthorEndDate &&
            item.country.toLowerCase().indexOf(this.countryname.toLowerCase()) >
              -1 &&
            item.state.toLowerCase().indexOf(this.statename.toLowerCase()) >
              -1 &&
            item.district
              .toLowerCase()
              .indexOf(this.districtname.toLowerCase()) > -1 &&
            item.taluka.toLowerCase().indexOf(this.talukaname.toLowerCase()) >
              -1
          );
        }
      );
    } else if (
      this.talukaname != '' &&
      this.districtname == '' &&
      this.statename == '' &&
      this.countryname == '' &&
      this.booksname == '' &&
      this.getauthorStartDate == null &&
      this.getauthorEndDate == null
    ) {
      this.FilteredBookSellerAddress = this.bookSellerAddressData.filter(
        (item) => {
          return (
            item.taluka.toLowerCase().indexOf(this.talukaname.toLowerCase()) >
            -1
          );
        }
      );
    }
  }

  // authorrepname = '';
  // selected_authorrepname(event, option){
  //   if(event.isUserInput){
  //     this.authorrepname = option['name'];
  //     if (
  //       this.authorrepname != '' &&
  //       this.talukaname != '' &&
  //       this.districtname != '' &&
  //       this.statename != '' &&
  //       this.countryname != '' &&
  //       this.booksname != '' &&
  //       this.getauthorStartDate != null &&
  //       this.getauthorEndDate != null
  //     ) {
  //       this.FilteredBookSellerAddress = this.bookSellerAddressData.filter(
  //         (item) => {
  //           return (
  //             item.name.toLowerCase().indexOf(this.booksname.toLowerCase()) >
  //               -1 &&
  //             item['date'] >= this.getauthorStartDate &&
  //             item['date'] <= this.getauthorEndDate &&
  //             item.country
  //               .toLowerCase()
  //               .indexOf(this.countryname.toLowerCase()) > -1 &&
  //             item.state.toLowerCase().indexOf(this.statename.toLowerCase()) >
  //               -1 &&
  //             item.district
  //               .toLowerCase()
  //               .indexOf(this.districtname.toLowerCase()) > -1 &&
  //             item.taluka.toLowerCase().indexOf(this.talukaname.toLowerCase()) >
  //               -1 &&
  //             item.taluka
  //               .toLowerCase()
  //               .indexOf(this.authorrepname.toLowerCase()) > -1
  //           );
  //         }
  //       );
  //     }
  //   }
  // }

  isComplimntry = '';
  isComplementaryAddress(event) {
    // console.log('event', event.value);
    this.isComplimntry = event.value;
  }

  printPage() {
    window.print();
  }

  showfulladdress() {
    if (this.isComplimntry != '') {
      this.dialog.open(BookSellerAddressComponent, {
        data: {
          bookselleraddress: this.FilteredBookSellerAddress.filter((a) => {
            return a['complimentary'] == this.isComplimntry;
          }),
        },
        height: '1300px',
        width: '1200px',
        maxWidth: '100%',
      });
    } else {
      this.dialog.open(BookSellerAddressComponent, {
        data: {
          bookselleraddress: this.FilteredBookSellerAddress,
        },
        height: '1300px',
        width: '1200px',
        maxWidth: '100%',
      });
    }
  }

  showlistaddress() {
    if (this.isComplimntry != '') {
      this.showFilteredBookSellerAddress =
        this.FilteredBookSellerAddress.filter((a) => {
          return a['complimentary'] == this.isComplimntry;
        });
    } else {
      this.showFilteredBookSellerAddress = this.FilteredBookSellerAddress;
    }
  }

  toInt(val) {
    // console.log(parseInt(val));
    return parseInt(val);
  }
  onTabClick(event:any){
    console.log(event)
    localStorage.setItem("tab", event.index)
  }
}
