<div>
    <div style="margin-left: 80%;  margin-bottom: 2%">
        <button [useExistingCss]="true" class="noPrint" style="margin-right: 2%; margin-left: 2%" mat-raised-button
            color="primary" printTitle="Pincodewise Statement" printSectionId="print-section" ngxPrint>
            print
        </button>
        <!-- <button mat-raised-button class="btn btn-info noPrint" (click)="print()"
                style="margin-right: 2%; margin-left: 2%">
                Print
            </button> -->
        <button mat-dialog-close class="btn noPrint">Close</button>
    </div>
    <div class="container" id="print-section">
        <div style="margin: 0px 15px;">
            <div class="row"
                style="text-align: center;border-width: 1px 1px 1px 1px; border-style: solid; border-color: black;padding-top: 05px; padding-bottom: 04px;">
                <span style=" font-size: 16px;font-weight: 600;margin: auto;">मा. विवेक, प्रभादेवी - २५ दि
                    {{sundayankDate}}
                    च्या
                    अंकाकरिता
                    पोस्ट स्टेटमेंट
                    वितरण दि {{dispatchankDate}}</span>
            </div>
            <div class="row">
                <div class="col2" style="border-width: 0px 1px 1px 1px;font-weight: 600;">राज्य </div>
                <div class="col1" style="border-width: 0px 1px 1px 0px;font-weight: 600;">पिनकोड</div>
                <div class="col1" style="border-width: 0px 1px 1px 0px;font-weight: 600;">वर्गणीदार संख्या</div>
                <div class="col1" style="border-width: 0px 1px 1px 0px;font-weight: 600;">पार्सल संख्या</div>
                <div class="col2" style="border-width: 0px 1px 1px 0px;font-weight: 600;">राज्य </div>
                <div class="col1" style="border-width: 0px 1px 1px 0px;font-weight: 600;">पिनकोड</div>
                <div class="col1" style="border-width: 0px 1px 1px 0px;font-weight: 600;">वर्गणीदार संख्या</div>
                <div class="col1" style="border-width: 0px 1px 1px 0px;font-weight: 600;">पार्सल संख्या</div>
            </div>
            <div class="row" *ngFor="let data of pincodewisesubcoutprinting; let i=index">
                <div class="col2" style="border-width: 0px 1px 1px 1px;"><span *ngIf="data['state1'] != ''">{{
                        data['state1']}}</span></div>
                <div class="col1" style="border-width: 0px 1px 1px 0px;"><span *ngIf="data['pincode1'] != 0">{{
                        data['pincode1']}}</span></div>
                <div class="col1" style="border-width: 0px 1px 1px 0px;">
                    <span *ngIf="data['sub1'] != 0">{{
                        data['sub1']}}</span>
                </div>
                <div class="col1" style="border-width: 0px 1px 1px 0px;"><span *ngIf="data['parcel1'] != 0">{{
                        data['parcel1']}}</span></div>
                <div class="col2" style="border-width: 0px 1px 1px 0px;"><span *ngIf="data['state2'] != ''">{{
                        data['state2']}}</span></div>
                <div class="col1" style="border-width: 0px 1px 1px 0px;"><span *ngIf="data['pincode2'] != 0">{{
                        data['pincode2']}}</span></div>
                <div class="col1" style="border-width: 0px 1px 1px 0px;">
                    <span *ngIf="data['sub2'] != 0">{{
                        data['sub2']}}</span>
                </div>
                <div class="col1" style="border-width: 0px 1px 1px 0px;"><span *ngIf="data['parcel2'] != 0">{{
                        data['parcel2']}}</span></div>
            </div>
            <div class="row">
                <div class="col2" style="border-width: 0px 1px 1px 1px;"></div>
                <div class="col1" style="border-width: 0px 1px 1px 0px;"></div>
                <div class="col1" style="border-width: 0px 1px 1px 0px;">
                </div>
                <div class="col1" style="border-width: 0px 1px 1px 0px;"></div>
                <div class="col3" style="border-width: 0px 1px 1px 0px;">परप्रांत एकूण</div>
                <div class="col1" style="border-width: 0px 1px 1px 0px;">
                    {{ total_pin_sub_count }}
                </div>
                <div class="col1" style="border-width: 0px 1px 1px 0px;">{{ totalparcel }}={{ totalanksankhya }}</div>
            </div>
            <div class="row">
                <div class="col2" style="border-width: 0px 0px 1px 1px;"></div>
                <div class="col1" style="border-width: 0px 0px 1px 0px;"></div>
                <div class="col1" style="border-width: 0px 0px 1px 0px;">
                </div>
                <div class="col1" style="border-width: 0px 1px 1px 0px;"></div>
                <div class="col3" style="border-width: 0px 1px 1px 0px;">मुंबई एकूण</div>
                <div class="col1" style="border-width: 0px 1px 1px 0px;">
                    {{ total_mumbai_pin_sub_count }}
                </div>
                <div class="col1" style="border-width: 0px 1px 1px 0px;">
                    {{ totalmumbaiparcel }}={{ totalmumbaianksankhya }}
                </div>
            </div>
            <div class="row">
                <div class="col2" style="border-width: 0px 0px 1px 1px;"></div>
                <div class="col1" style="border-width: 0px 0px 1px 0px;"></div>
                <div class="col1" style="border-width: 0px 0px 1px 0px;">
                </div>
                <div class="col1" style="border-width: 0px 1px 1px 0px;"></div>
                <div class="col3" style="border-width: 0px 1px 1px 0px;">ठाणे एकूण</div>
                <div class="col1" style="border-width: 0px 1px 1px 0px;">
                    {{ total_thane_pin_sub_count }}
                </div>
                <div class="col1" style="border-width: 0px 1px 1px 0px;">
                    {{ totalthaneparcel }}={{ totalthaneanksankhya }}
                </div>
            </div>
            <div class="row">
                <div class="col2" style="border-width: 0px 0px 1px 1px;"></div>
                <div class="col1" style="border-width: 0px 0px 1px 0px;"></div>
                <div class="col1" style="border-width: 0px 0px 1px 0px;">
                </div>
                <div class="col1" style="border-width: 0px 1px 1px 0px;"></div>
                <div class="col3" style="border-width: 0px 1px 1px 0px;">जिल्हा एकूण</div>
                <div class="col1" style="border-width: 0px 1px 1px 0px;">
                    {{ total_dist_pin_sub_count }}
                </div>
                <div class="col1" style="border-width: 0px 1px 1px 0px;">
                    {{ totaldistparcel }}={{ totaldistanksankhya }}
                </div>
            </div>
            <div class="row">
                <div class="col8" style="border-width: 0px 1px 1px 0px;"> एकूण</div>
                <div class="col1" style="border-width: 0px 1px 1px 0px;">
                    {{ total_pin_sub_count+total_mumbai_pin_sub_count+total_thane_pin_sub_count +
                    total_dist_pin_sub_count }}
                </div>
                <div class="col1" style="border-width: 0px 1px 1px 0px;">
                    {{ totalparcel+totalmumbaiparcel+totalthaneparcel+totaldistparcel }}={{
                    totalanksankhya+totalmumbaianksankhya+totalthaneanksankhya+totaldistanksankhya }}
                </div>
            </div>
        </div>
    </div>
</div>