<div>
    <div style="margin-left: 80%; margin-top: -4%; margin-bottom: 2%">
        <button [useExistingCss]="true" class="noPrint" style="margin-right: 2%; margin-left: 2%" mat-raised-button
            color="primary" printSectionId="print-section" ngxPrint>
            print
        </button>
        <button mat-dialog-close class="btn noPrint">
            Close
        </button>
    </div>
</div>
<div>
    <div class="container" id="print-section">
        <div style="margin: 0px 15px;">
            <div class="row"
                style="border-width: 1px 1px 1px 1px; border-style: solid; border-color: black;text-align: center;">
                <h2 style="margin-left: auto;margin-right: auto;">Hindustan Prakashan Sanstha GST - {{this.months}}</h2>
            </div>
            <div class="row" style="font-weight: 700;">
                <div class="col" style="border-width: 0px 1px 1px 1px;font-weight: 700;">Sr. No.</div>
                <div class="col1" style="border-width: 0px 1px 1px 0px;font-weight: 700;">Receipt Date</div>
                <div class="col3" style="border-width: 0px 1px 1px 0px;font-weight: 700;">Customer name</div>
                <div class="col1" style="border-width: 0px 1px 1px 0px;font-weight: 700;">Address of Company</div>
                <div class="col2" style="border-width: 0px 1px 1px 0px;font-weight: 700;">Receipt No</div>
                <div class="col2" style="border-width: 0px 1px 1px 0px;font-weight: 700;">GST No</div>
                <div class="col1" style="border-width: 0px 1px 1px 0px;font-weight: 700;">HSN Code</div>
                <div class="col1" style="border-width: 0px 1px 1px 0px;font-weight: 700;">Total Amount</div>
            </div>
            <div class="row" *ngFor="let item of receiptledger; let i=index">
                <div class="col" style="border-width: 0px 1px 1px 1px;">{{ i+1 }}</div>
                <div class="col1" style="border-width: 0px 1px 1px 0px;">{{ item["crdt_voucher_date"] |
                    date:"dd-MM-yyyy"
                    }}</div>
                <div class="col3" style="border-width: 0px 1px 1px 0px;">{{ item["e_name"] }}</div>
                <div class="col1" style="border-width: 0px 1px 1px 0px;">{{ item["e_district"] }}</div>
                <div class="col2" style="border-width: 0px 1px 1px 0px;">{{ item["rcpt_no"] }}</div>
                <div class="col2" style="border-width: 0px 1px 1px 0px;">{{ item["gst_no"] }}</div>
                <div class="col1" style="border-width: 0px 1px 1px 0px;">{{ item["hsn_code"] }}</div>
                <div class="col1" style="border-width: 0px 1px 1px 0px;">{{ item["total_amt"] }}</div>
            </div>
            <div class="row">
                <div class="col" style="border-width: 0px 1px 1px 1px;"></div>
                <div class="col1" style="border-width: 0px 1px 1px 0px;"></div>
                <div class="col3" style="border-width: 0px 1px 1px 0px;"></div>
                <div class="col1" style="border-width: 0px 1px 1px 0px;"></div>
                <div class="col2" style="border-width: 0px 1px 1px 0px;"></div>
                <div class="col2" style="border-width: 0px 1px 1px 0px;"></div>
                <div class="col1" style="border-width: 0px 1px 1px 0px;"></div>
                <div class="col1" style="border-width: 0px 1px 1px 0px;">{{ total_amt }}</div>
            </div>
        </div>
    </div>
</div>