import { Component, OnInit, Inject, Injectable } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { DigisubservicesService } from 'src/app/services/digisubservices.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit
{
  form : FormGroup
  ds_repid 
  data2
  address1
  mobileNumber1
  accountname1
  accountno1
  bankname1
  branchname1
  constructor(private router : Router,private dialogRef : MatDialogRef<ModalComponent>, 
    @Inject(MAT_DIALOG_DATA) public data : any, public fb : FormBuilder, public service : DigisubservicesService) 
  { 
    //console.log("From Modal Component : ",data.message);
    this.ds_repid = parseInt(data.message);
    //console.log("ds_repid = ",this.ds_repid);
    this.form = this.fb.group({
      rep_id : [''],
    //name: ['', [Validators.required, Validators.minLength(2)]],
    //email: ['', [Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')]],
    address: ['', [Validators.required, Validators.minLength(5)]],
    mobileNumber: ['', [Validators.required, Validators.maxLength(10), Validators.pattern('^[7-9][0-9]{9}$')]],
    //pancard: ['', [Validators.required, Validators.pattern('[A-Z]{5}[0-9]{4}[A-Z]{1}')]],
    //adharcard: ['', [Validators.required, Validators.maxLength(12), Validators.pattern('^[0-9]{12}$')]],
    //password: ['', [Validators.required, Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Z0-9a-z\d$@$!%*?&].{6,}')]],
    accountname: ['', [Validators.required, Validators.minLength(2)]],
    accountno: ['', [Validators.required, Validators.minLength(5), Validators.pattern('^[0-9]+$')]],
    bankname: ['', [Validators.required, Validators.minLength(2)]],
    branchname: ['', [Validators.required, Validators.minLength(2)]],
    // paymentmode: ['', [Validators.required]],
    //securityDeposite:[''],
    //is_active:[''],
    //creditLimit:[''],
    //comission:[''],
    // imagepath1: [''],
    // imagepath2: [''],
    // imagepath3: [''],
    // imagepath4: ['']
  })


    let resultState = this.service.read_RepById(this.ds_repid);
    resultState.subscribe((data1)=>{
       this.data2 = data1;
       this.address1 = this.data2[0].address;
       this.mobileNumber1 = parseInt(this.data2[0].mobileNumber);
       this.accountname1 = this.data2[0].accountname;
       this.accountno1 = parseInt(this.data2[0].accountno);
       this.bankname1 = this.data2[0].bankname;
       this.branchname1 = this.data2[0].branchname;
      ////console.log("Address from Server : ",this.address1);

      this.form.setValue({
        rep_id : this.ds_repid,
        address : this.address1,
        mobileNumber : this.mobileNumber1,
        accountname : this.accountname1,
        accountno : this.accountno1,
        bankname : this.bankname1,
        branchname : this.branchname1
      });

   /* setTimeout(() => {
      this.form = this.fb.group({
        //id : data.message,
        // name : [''],
        address : this.address1,
        mobileNumber : this.mobileNumber1,
        // email : [''],
        // password : [''],
        // pancard : [''],
        // adharcard : [''],
        accountname : this.accountname1,
        accountno : this.accountno1,
        bankname : this.bankname1,
        branchname : this.branchname1,
        // paymentmode : [''],
        // securityDeposite : [''],
        // is_active : ['']
      });
    }, 10000);  */    
      
    });
    

    
      

    //this.form.patchValue({rep_id : this.ds_repid});
    //this.form.get('accountname').setValue(this.accountname1)
    //this.form.get('accountno').setValue(this.accountno1)
    //this.form.get('address').setValue(this.address1)
    //this.ionicForm.get('adharcard').setValue(this.adharcard)
    //this.form.get('bankname').setValue(this.bankname1)
    //this.form.get('branchname').setValue(this.branchname1)
    //this.ionicForm.get('email').setValue(this.email)
    //this.form.get('mobileNumber').setValue(this.mobileNumber1)
    //this.ionicForm.get('name').setValue(this.name)
    //this.ionicForm.get('pancard').setValue(this.pancard)
    //this.ionicForm.get('password').setValue(this.password)
    //this.ionicForm.get('paymentmode').setValue(this.paymentmode)
    //this.ionicForm.get('securityDeposite').setValue(this.securityDeposite)
    //this.ionicForm.get('is_active').setValue(this.is_active)
    // this.ionicForm.get('imagepath1').setValue(this.imagepath1)
    // this.ionicForm.get('imagepath2').setValue(this.imagepath2)
    // this.ionicForm.get('imagepath3').setValue(this.imagepath3)
    // this.ionicForm.get('imagepath4').setValue(this.imagepath4)
    // this.imagSrc1 = "https://minimasters.in/digisub/uploads/" + this.imagepath1;
    // this.imagSrc2 = "https://minimasters.in/digisub/uploads/" + this.imagepath2;
    // this.imagSrc3 = "https://minimasters.in/digisub/uploads/" + this.imagepath3;
    // this.imagSrc4 = "https://minimasters.in/digisub/uploads/" + this.imagepath4;
    // //console.log(this.imagSrc1, this.imagSrc2, this.imagSrc3)
    


    /*
    this.dataservice.updaterRepData(parseInt(this.rep_id), parseInt(this.ionicForm.get('mobileNumber').value),
        this.ionicForm.get('address').value,
        this.ionicForm.get('accountname').value,
        parseInt(this.ionicForm.get('accountno').value),
        this.ionicForm.get('bankname').value,
        this.ionicForm.get('branchname').value,
        this.ionicForm.get('imagepath1').value,
        this.ionicForm.get('imagepath2').value,
        this.ionicForm.get('imagepath3').value,
        this.ionicForm.get('imagepath4').value,
        this.ionicForm.get('email').value,
        this.ionicForm.get('adharcard').value,
        this.ionicForm.get('pancard').value).subscribe(res => {

          this.dataservice.updaterNotifREP(parseInt(this.rep_id)).subscribe((id) => {
            //console.log("notification updated");           
          });
        })
    */

  }

  ngOnInit()
  {
    if(sessionStorage.getItem("mobile") == null){
      this.router.navigate(['/']);
    }
    this.dialogRef.updateSize('50%', '90%');
  }

  

  submitForm()
  {
    //console.log("Form Data : ",this.form.value);
    let resultState = this.service.updateRepData(this.form.value);
    resultState.subscribe((data)=>{
      let dataFromServer = data;
      //console.log("Data from update_rep_data : ",dataFromServer); 
    });
    this.form.reset();
  }

  public closeMe()
  {
    this.dialogRef.close();
  }

}
