<div mat-dialog-content>
  <div class="text-center">
    <h1 style="font-size: medium; font-weight: bold">Edit Book</h1>
  </div>

  <form class="form" [formGroup]="BookForm" (ngSubmit)="save()">
    <div class="form-row">
      <div class="col form-group">
        <label style="margin-top: 20px">Book Name :</label>
        <input style="width: 90%" formControlName="bookname" type="text" class="form-control" placeholder="" />
      </div>
      <!-- form-group end.// -->

      <div class="col form-group">
        <label style="margin-left: 35px; margin-top: 20px">Book Description :</label>
        <textarea style="width: 90%; margin-left: 35px" formControlName="bookdesc" type="text" class="form-control"
          placeholder="">
        </textarea>
      </div>
    </div>
    <div class="form-row">
      <div class="col form-group">
        <label style="margin-top: 20px">Author Name :</label>
        <input style="width: 90%" formControlName="authorname" type="text" class="form-control" placeholder="" />
      </div>
      <!-- form-group end.// -->

      <div class="col form-group">
        <label style="margin-left: 35px; margin-top: 25px">Book Pages :</label>
        <mat-form-field class="example-full-width" appearance="outline"
          style="width: 15%; margin-left: 10px; margin-top: 10px">
          <mat-label>B/w</mat-label>
          <input (change)="caltotalpages($event)" matInput type="number" formControlName="bookpagesbw" />
        </mat-form-field>
        <mat-form-field class="example-full-width" appearance="outline"
          style="width: 15%; margin-left: 10px; margin-top: 10px">
          <mat-label>Inside Color</mat-label>
          <input (change)="caltotalpages($event)" matInput type="number" formControlName="bkpinsidecolor" />
        </mat-form-field>
        <mat-form-field class="example-full-width" appearance="outline"
          style="width: 15%; margin-left: 10px; margin-top: 10px">
          <mat-label>Cover</mat-label>
          <input (change)="caltotalpages($event)" matInput type="number" formControlName="bkpcover" />
        </mat-form-field>
        <mat-form-field class="example-full-width" appearance="outline"
          style="width: 15%; margin-left: 15px; margin-top: 10px">
          <mat-label>Total Pages</mat-label>
          <input matInput type="number" formControlName="totalpages" readonly />
        </mat-form-field>
      </div>
    </div>
    <div class="form-row">
      <div class="col form-group">
        <mat-form-field class="example-full-width" appearance="outline" style="width: 90%; margin-top: 20px">
          <mat-label>Book Size</mat-label>
          <input matInput type="text" formControlName="booksize" />
        </mat-form-field>
      </div>
      <div class="col form-group">
        <label style="margin-left: 35px; margin-top: 25px">Paper :</label>
        <mat-form-field class="example-full-width" appearance="outline"
          style="width: 20%; margin-left: 10px; margin-top: 10px">
          <mat-label>B/w</mat-label>
          <input matInput type="text" formControlName="paperbw" />
        </mat-form-field>
        <mat-form-field class="example-full-width" appearance="outline"
          style="width: 20%; margin-left: 05px; margin-top: 10px">
          <mat-label>Insie color</mat-label>
          <input matInput type="text" formControlName="paperinsidecolor" />
        </mat-form-field>
        <mat-form-field class="example-full-width" appearance="outline"
          style="width: 20%; margin-left: 05px; margin-top: 10px">
          <mat-label>Cover</mat-label>
          <input matInput type="text" formControlName="papercover" />
        </mat-form-field>
      </div>
    </div>
    <div class="form-row">
      <div class="col form-group">
        <mat-form-field class="example-full-width" appearance="outline" style="width: 45%; margin-top: 10px">
          <mat-label>Binding</mat-label>
          <input matInput type="text" formControlName="binding" />
        </mat-form-field>
      </div>
    </div>
    <div class="form-row">
      <div class="col form-group">
        <label style="margin-top: 10px">Book Language :</label>
        <input style="width: 90%" formControlName="booklang" type="text" class="form-control" placeholder="" />
      </div>
      <!-- form-group end.// -->

      <div class="col form-group">
        <label style="margin-top: 10px">Book Publish Year : </label>
        <input style="width: 100%" formControlName="bookyear" type="text" class="form-control" />
      </div>
    </div>

    <div class="form-row">
      <div class="col form-group">
        <label style="margin-top: 10px">Book Price :</label>
        <input style="width: 90%" formControlName="price" type="text" class="form-control" placeholder="" />
      </div>
      <!-- form-group end.// -->

      <div class="col form-group">
        <label style="margin-top: 10px">Book Sale Price : </label>
        <input style="width: 100%" formControlName="saleprice" type="text" class="form-control" />
      </div>
    </div>

    <div class="form-row">
      <div class="col form-group">
        <label style="margin-top: 10px">Book Edition :</label>
        <input style="width: 90%" formControlName="bookedition" type="text" class="form-control" placeholder="" />
      </div>
      <!-- form-group end.// -->

      <div class="col form-group">
        <label style="margin-top: 10px">Date : </label>
        <input style="width: 90%" formControlName="bookedtndate" type="date" class="form-control" />
      </div>

      <div class="col form-group">
        <label style="margin-top: 10px">Copies :</label>
        <input style="width: 90%" formControlName="bookedtncpoies" type="text" class="form-control" placeholder="" />
      </div>
    </div>

    <div class="form-row">
      <div class="col form-group">
        <label style="margin-top: 10px">Opening Stock :</label>
        <input style="width: 90%" formControlName="opening_stock" type="text" class="form-control" placeholder="" />
      </div>
      <!-- form-group end.// -->

      <div class="col form-group">
        <label style="margin-top: 10px">लेखक मानधन : </label>
        <input style="width: 100%" formControlName="author_honorarium" type="text" class="form-control" />
      </div>
    </div>

    <div class="form-row">
      <div class="col form-group">
        <label style="margin-top: 10px">Book Category : </label>
        <input style="width: 90%" formControlName="bookcategory" type="text" class="form-control" />
      </div>

      <div class="col form-group">
        <label style="margin-top: 10px">is Deliver charges included in Price : </label>
        <select style="width: 100%" class="form-control" placeholder="Select One" formControlName="isdelivery">
          <option value="Y">Yes</option>
          <option value="N">No</option>
        </select>
      </div>
    </div>

    <div class="form-row">
      <div class="col form-group">
        <label style="margin-top: 20px">Publish Status : </label>
        <select style="width: 60%" class="form-control" placeholder="Select One" formControlName="isupcoming"
          (change)="getSearchTerm($event)">
          <option value="N">Publish Now</option>
          <option value="Y">Upcomming</option>
        </select>
      </div>
      <!-- form-group end.// -->

      <div class="col form-group" *ngIf="isupcoming">
        <label>Upcomming Book Date : </label>
        <input style="width: 61%" formControlName="upcomingdate" type="date" class="form-control" />
      </div>
      <!-- form-group end.// -->
      <!-- </div> -->
    </div>

    <div class="form-row">
      <div class="form-group">
        <label style="margin-left: 35px">Uploaded Book Image : </label>
        <img src="{{ this.bookimgpath }}" width="100" />
      </div>
    </div>
    <div class="form-row">
      <div class="form-group">
        <label style="margin-left: 35px">Upload Book Image : </label>
        <input (change)="ImageUploads($event)" style="margin-left: 35px" type="file" name="file" ng2FileSelect
          [uploader]="uploader" class="form-control" placeholder=" " />
      </div>
    </div>

    <div mat-dialog-actions style="float: right">
      <button mat-button mat-dialog-close>Close</button>
      <button [disabled]="BookForm.invalid" mat-button class="btn btn-info" type="submit">
        Update
      </button>
    </div>
    <!-- form-group// -->
  </form>
</div>