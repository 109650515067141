<div>
    <div style="margin-left: 70%;  margin-bottom: 2%">
        <!-- <button class="btn btn-info" mat-raised-button (click)="assigndisrep()">
                Add Distribution Rep.
            </button> -->
        <button class="noPrint" style="margin-right: 2%; margin-left: 2%" mat-raised-button color="primary"
            printSectionId="print-section" ngxPrint [useExistingCss]="true">
            print
        </button>
        <button mat-dialog-close onclick="closeexp()" class="btn noPrint">Close</button>
    </div>
    <div class="container" id="print-section">
        <div class="wrapper">
            <div class="row rowborder hc">
                <div class="common" style="margin: 0px 15px 0px 10px;">
                    <span>दिनांक : {{ getFormattedDate() }}</span>
                    <div style="margin-bottom: 10px;"></div>
                    <span>पृष्ठ क्र : 1</span>
                </div>
                <div class="common" style="margin: 0px 400px 10px 50px;">
                    <span>हिंदुस्थान प्रकाशन संस्था</span>
                    <div style="margin-bottom: 10px;"></div>
                    <span>ऊप खात्यांचा ट्रायल बॅलन्स मुख्य खाते - </span>
                    <span>{{exp_name}}</span>
                </div>
                <div class="common">
                    <span>दिनांक पासून : </span>
                    <span>{{ startDate | date: 'dd-MM-yyyy' }}</span>
                    <div style="margin-bottom: 10px;"></div>
                    <span>दिनांक पर्यंत : </span>
                    <span>{{ endDate | date: 'dd-MM-yyyy' }}</span>
                </div>
            </div>
            <div class="row rowborder" style="gap: 0;">

                <div class="col-4">
                    <span class="fw_600">खात्याचे नाव </span>
                </div>
                <div class="col-2">
                    <span class="fw_600">डेबिट </span>
                </div>
                <div class="col-2">
                    <span class="fw_600">क्रेडिट </span>
                </div>
            </div>
            <hr style="margin:0px 0px 0px 0px">

            <div class="row" *ngFor="let item of alldebitvoucher1">
                <div class="col-4">
                    <span>{{ item['sub_exp_name'] }}</span>


                </div>
                <div class="col-2">
                    <span>{{ item['total_debit_amount'] | number:'1.2-2'
                        }} </span>
                </div>
                <div class="col-2">
                    <span>{{ item['total_credit_amount'] | number:'1.2-2'
                        }}</span>
                </div>
            </div>
            <hr style="margin:0px 0px 0px 0px">
            <div class="row rowborder rowbordertop" style="gap: 0;">
                <div class="col-2"></div>
                <div class="col-2 common" style="gap: 10px;">
                    <span class="fw_600">एकूण :</span>
                    <!-- <span>बाकी पुढे :</span> -->
                </div>
                <!-- <div class="col-2 common">
                    <span style="padding-bottom: 5px;">{{ debittotal | number:'1.2-2' }}</span>
                    <div class="line"></div>
                    
                </div>
                <div class="col-2">
                    <span> {{credittotal | number:'1.2-2'}} </span>
                </div> -->
                <div class="col-2">
                    <span class="fw_600">{{ getTotalDebit() | number:'1.2-2' }}</span>
                </div>
                <div class="col-2">
                    <span class="fw_600">{{ getTotalCredit() | number:'1.2-2' }}</span>
                </div>
            </div>
        </div>
        <print-preview-layout-settings class="settings-section"></print-preview-layout-settings>

    </div>
</div>