import { formatDate } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-prantwise-varganidar-report',
  templateUrl: './prantwise-varganidar-report.component.html',
  styleUrls: ['./prantwise-varganidar-report.component.scss'],
})
export class PrantwiseVarganidarReportComponent implements OnInit {
  isLoading: boolean = false;
  startDate = '';
  endDate = '';
  prantData;
  showPrantData = [
    { prantName: 'कोकण प्रांत' },
    { prantName: 'पश्चिम प्रांत' },
    { prantName: 'देवगिरी प्रांत' },
    { prantName: 'विदर्भ प्रांत' },
  ];
  showOtherPrantData = [];
  totalPostTotal = 0;
  totalHasteTotal = 0;

  constructor(
    private dialogRef: MatDialogRef<PrantwiseVarganidarReportComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.prantData = this.data['data'];
    // console.log(this.prantData);

    this.endDate = this.data['issuedate'];
    let weekfirstday =
      new Date(this.data['issuedate']).getDate() -
      new Date(this.data['issuedate']).getDay();
    this.startDate = formatDate(
      new Date(this.data['issuedate']).setDate(weekfirstday - 7),
      'yyyy-MM-dd',
      'en-IN',
      'GMT+0530'
    );

    this.setPrantData();
  }

  setPrantData() {
    this.totalPostTotal = 0;
    this.totalHasteTotal = 0;
    let mahaState = this.prantData.filter((a) => {
      return a.state === 'महाराष्ट्र';
    });
    // console.log(mahaState);

    let otherState = this.prantData.filter((a) => {
      return a.state !== 'महाराष्ट्र';
    });
    // console.log(otherState);

    let otherPost = otherState.filter((a) => {
      return a.delivery_mode === 'टपाल';
    });

    let otherHaste = otherState.filter((a) => {
      return a.delivery_mode === 'हस्ते';
    });

    this.showOtherPrantData.push({
      name: 'अन्यप्रांत',
      post: otherPost.length,
      haste: otherHaste.length,
      total: otherPost.length + otherHaste.length,
    });

    this.showOtherPrantData.push({
      name: 'परदेश',
      post: 0,
      haste: 0,
      total: 0,
    });

    this.totalPostTotal += otherPost.length;
    this.totalHasteTotal += otherHaste.length;

    let data = this.uniqueData(mahaState, (a) => a.prant_name);
    // console.log(data);

    for (let i = 0; i < data.length; i++) {
      let district = mahaState.filter((a) => {
        return a.prant_name === data[i]['prant_name'];
      });

      let uniqueDistrict = this.uniqueData(district, (a) => a.district);
      // console.log(uniqueDistrict);

      let prantData = [];
      let postTotal = 0;
      let hasteTotal = 0;

      for (let j = 0; j < uniqueDistrict.length; j++) {
        let districtData = district.filter((a) => {
          return a.district === uniqueDistrict[j]['district'];
        });

        let post = districtData.filter((a) => {
          return a.delivery_mode === 'टपाल';
        });

        let haste = districtData.filter((a) => {
          return a.delivery_mode === 'हस्ते';
        });

        postTotal += post.length;
        hasteTotal += haste.length;

        prantData.push({
          districtName: uniqueDistrict[j]['district'],
          post: post.length,
          haste: haste.length,
          total: post.length + haste.length,
        });

        // console.log(districtData);
        if (j === uniqueDistrict.length - 1) {
          prantData.push({
            name: 'एकूण',
            postTotal,
            hasteTotal,
            total: postTotal + hasteTotal,
          });
        }
      }

      this.totalPostTotal += postTotal;
      this.totalHasteTotal += hasteTotal;

      // this.showPrantData.push({
      //   prantName: data[i]['prant_name'],
      //   data: prantData,
      // });
      this.showPrantData.map((a) => {
        if (a.prantName === data[i]['prant_name']) {
          a['data'] = prantData;
        }
      });
    }
    this.isLoading = false;
    // console.log(this.showPrantData);

    // console.log(
    //   this.showPrantData.sort((a: any, b: any) => {
    //     return a.prantName.localeCompare(b.prantName);
    //   })
    // );
  }

  uniqueData(data, key) {
    return [...new Map(data.map((a) => [key(a), a])).values()];
  }

  exportExcel(fileName) {
    /* table id is passed over here */
    let element = document.getElementById('print-section');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, fileName + '.xlsx');
  }
}
