<form [formGroup]="myForm">

  <div style="border-bottom: 2px solid #525f7f; text-align: center; padding-top: 25px; font-size: 19px;">
    <h3>Pending balance Amount</h3>
  </div>
    <div class="mt-3" style="padding-top: 35px;">
      <!-- <label class="text-size">Credit Voucher:</label>
      <input type="text" formControlName="CreitVC" readonly/> -->
      <mat-form-field appearance="outline" style="width: 27%; margin-left: 6%">
        <mat-label>Credit Voucher:</mat-label>
        <input matInput type="text" formControlName="CreitVC" readonly />
    </mat-form-field>

    <mat-form-field appearance="outline" style="width: 27%; margin-left: 2%">
      <mat-label>Invoice Number:</mat-label>
      <input matInput type="text" formControlName="invcnum" readonly />
  </mat-form-field>
      <mat-form-field appearance="outline" style="width: 27%; margin-left: 2%">
        <mat-label>Advt Name:</mat-label>
        <input matInput type="text" formControlName="advtname" readonly />
    </mat-form-field>
    </div>
  

    <div class="mt-3">
        <!-- <label class="text-size">Advt Name:</label>
        <input type="text" formControlName="advtname" readonly/> -->
        <mat-form-field appearance="outline" style="width: 27%; margin-left: 6%">
          <mat-label>Creation Date:</mat-label>
          <input matInput type="text" formControlName="crndate" readonly />
      </mat-form-field>
      <mat-form-field appearance="outline" style="width: 27%; margin-left: 2%">
        <mat-label>Vocher Date:</mat-label>
        <input matInput type="text" formControlName="Vcdate" readonly />
    </mat-form-field>
    <mat-form-field appearance="outline" style="width: 27%; margin-left: 2%">
      <mat-label>Credit Amt:</mat-label>
      <input matInput type="text" formControlName="CRamt" />
  </mat-form-field>

    </div>
    <!-- <div class="mt-3">
        <label class="text-size">Creation Date:</label>
        <input type="text" formControlName="crndate" readonly/>

        <label class="text-size ms-2">Vocher Date:</label>
        <input type="text" formControlName="Vcdate" readonly/>
    </div> -->
      <!-- <div class="mt-3">
        <label class="text-size">Credit Amt:</label>
        <input type="text" formControlName="CRamt"/>
      </div> -->
      <div class="mt-3">
        <!-- <label class="text-size ms-2" style="margin-left: 6%;">Credit Type:</label>
          <input type="radio" class="ms-2" id="GST" formControlName="Crtype" name="Type" value="GST">
               <label for="GST">GST </label>
                 <input type="radio" class="ms-2" id="Other" formControlName="Crtype" name="Type" value="Other">
                <label for="Other">Other</label> -->
                <!-- <label class="text-size ms-2" style="margin-left: 6%;">Credit Type:</label> -->
                <!-- <select class="ms-2" formControlName="Crtype" style="width: 27%; height: 60px;">
                  <option disabled selected>Select Type</option>
                  <option value="GST">GST</option>
                  <option value="Other">Other</option>
                </select> -->

                <mat-form-field appearance="outline" style="width: 27%; margin-left: 6%">
                  <mat-label>Credit Type:</mat-label>
                  <mat-select formControlName="Crtype">
                    <mat-option value="GST">GST</mat-option>
                    <mat-option value="Other">Other</mat-option>
                  </mat-select>
                </mat-form-field>
                
                
               
                <!-- <mat-select #mySel1  required>
                  <mat-option value="GST">GST</mat-option>
                  <mat-option value="Other" >Other</mat-option>

                  
                </mat-select> -->


                <!-- <mat-form-field appearance="outline" style="width: 27%; margin-left: 2%">
                  <mat-label>Rep Name:</mat-label>
                  <input matInput type="text" formControlName="Repname" />
              </mat-form-field> -->

              <mat-form-field  appearance="outline" style="margin-left: 2%; width: 27%;">
                <mat-label>Representative Name : </mat-label>
                <mat-select #mySel (selectionChange)="getContractRepName($event)" formControlName="Repname" >
                  <!-- <mat-option value="ALL" (click)="toggleAllSelection()">Vilas</mat-option> -->
                  <mat-option *ngFor="let item of repname" value="{{  item['rep_name'] }}">{{ item["rep_name"]
                    }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field appearance="outline" style="width: 27%; margin-left: 2%">
                <mat-label>Remark:</mat-label>
                <input matInput type="text" formControlName="remark" />
            </mat-form-field>    
      </div>
      <!-- <div class="mt-3">
        <label class="text-size">Rep Name:</label>
        <input type="text" id="input2" formControlName="Repname" readonly />

        <label class="text-size ms-2">Remark:</label>
        <input type="text" id="input2" formControlName="remark"/>
      </div> -->
      <div style="float: right; padding-right: 90px;">
        <button type="button" mat-raised-button color="primary"
        (click)="getModaldata()">
        Save
      </button>
      <button class="noPrint" style="margin-top: 2%" class="me-2" mat-button mat-dialog-close>
        Close
    </button>
      </div>
     
    <!-- <div>
      <button type="submit" [disabled]="myForm.invalid">Submit</button>
    </div> -->
  </form>

<div>
  
</div>
  
  