import { formatDate } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-renew-remaining-data',
  templateUrl: './renew-remaining-data.component.html',
  styleUrls: ['./renew-remaining-data.component.scss'],
})
export class RenewRemainingDataComponent implements OnInit {
  renewRemainingData;
  today = new Date();
  startDate = '';
  endDate = '';
  isLoading: boolean = false;
  isData: boolean = false;
  finalData = [];

  constructor(
    private dialogRef: MatDialogRef<RenewRemainingDataComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.startDate = this.data['startDate'];
    this.endDate = this.data['endDate'];
    this.renewRemainingData = this.data['renewRemainingData'];
    console.log(this.renewRemainingData);

    this.setData();
  }

  setData() {
    if (this.renewRemainingData && this.renewRemainingData.length > 0) {
      this.isData = true;
      let uniqueOfficeRepData = this.uniqueData(
        this.renewRemainingData,
        (a) => a.office_rep_name
      );
      // console.log(uniqueOfficeRepData);
      let finalTotalData = {
        April: 0,
        May: 0,
        June: 0,
        July: 0,
        August: 0,
        September: 0,
        October: 0,
        November: 0,
        December: 0,
        January: 0,
        February: 0,
        March: 0,
        total: 0,
      };
      for (let i = 0; i < uniqueOfficeRepData.length; i++) {
        let officeRepData = this.renewRemainingData.filter(
          (a) => a.office_rep_name === uniqueOfficeRepData[i]['office_rep_name']
        );
        // console.log(officeRepData);

        let uniqueRepData = this.uniqueData(officeRepData, (a) => a.rep_name);
        // console.log(uniqueRepData);
        let data = [];
        let totalData = {
          April: 0,
          May: 0,
          June: 0,
          July: 0,
          August: 0,
          September: 0,
          October: 0,
          November: 0,
          December: 0,
          January: 0,
          February: 0,
          March: 0,
          total: 0,
        };
        for (let j = 0; j < uniqueRepData.length; j++) {
          let repData = officeRepData.filter(
            (a) => a.rep_name === uniqueRepData[j]['rep_name']
          );
          // console.log(repData);
          let uniqueMonthwiseData = this.uniqueData(repData, (a) =>
            formatDate(
              new Date(a.sub_perid_to_date),
              'MMMM',
              'en-IN',
              'GMT+0530'
            )
          );
          // console.log(uniqueMonthwiseData);
          let eachData = {
            name: '',
            off_name: '',
            April: 0,
            May: 0,
            June: 0,
            July: 0,
            August: 0,
            September: 0,
            October: 0,
            November: 0,
            December: 0,
            January: 0,
            February: 0,
            March: 0,
            total: 0,
          };
          for (let k = 0; k < uniqueMonthwiseData.length; k++) {
            let monthewiseData = repData.filter((a) => {
              return (
                formatDate(
                  new Date(a.sub_perid_to_date),
                  'MMMM',
                  'en-IN',
                  'GMT+0530'
                ) ===
                formatDate(
                  new Date(uniqueMonthwiseData[k]['sub_perid_to_date']),
                  'MMMM',
                  'en-IN',
                  'GMT+0530'
                )
              );
            });
            console.log(monthewiseData);
            if (k === 0) {
              eachData['name'] = uniqueMonthwiseData[k]['rep_name'];
              eachData['off_name'] = uniqueMonthwiseData[k]['office_rep_name'];
            }
            eachData[
              formatDate(
                new Date(uniqueMonthwiseData[k]['sub_perid_to_date']),
                'MMMM',
                'en-IN',
                'GMT+0530'
              )
            ] = parseInt(monthewiseData.length);
            eachData['total'] += parseInt(monthewiseData.length);
            totalData[
              formatDate(
                new Date(uniqueMonthwiseData[k]['sub_perid_to_date']),
                'MMMM',
                'en-IN',
                'GMT+0530'
              )
            ] += parseInt(monthewiseData.length);
            totalData['total'] += parseInt(monthewiseData.length);
            finalTotalData[
              formatDate(
                new Date(uniqueMonthwiseData[k]['sub_perid_to_date']),
                'MMMM',
                'en-IN',
                'GMT+0530'
              )
            ] += parseInt(monthewiseData.length);
            finalTotalData['total'] += parseInt(monthewiseData.length);
          }

          data.push(eachData);
          if (j === uniqueRepData.length - 1) {
            data.push(totalData);
          }
        }
        this.finalData.push(data);
        if (i === uniqueOfficeRepData.length - 1) {
          this.finalData.push(finalTotalData);
        }
        console.log(this.finalData);
        this.isLoading = false;
      }
    } else {
      this.isLoading = false;
    }
  }

  uniqueData(data, key) {
    return [...new Map(data.map((a) => [key(a), a])).values()];
  }

  exportExcel(fileName) {
    /* table id is passed over here */
    let element = document.getElementById('print-section');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, fileName + '.xlsx');
  }
}
