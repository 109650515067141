import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DigisubservicesService } from 'src/app/services/digisubservices.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-new-sub-otp-modal',
  templateUrl: './add-new-sub-otp-modal.component.html',
  styleUrls: ['./add-new-sub-otp-modal.component.scss'],
})
export class AddNewSubOtpModalComponent implements OnInit {
  form: FormGroup;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    public service: DigisubservicesService,
    private dialogRef: MatDialogRef<AddNewSubOtpModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.form = fb.group({
      otp: [''],
    });
  }

  ngOnInit(): void {
    console.log(this.data);
    if (sessionStorage.getItem('mobile') == null) {
      this.router.navigate(['/']);
    }
  }

  submitForm() {
    if (this.data.message == this.form.get('otp').value) {
      let Form = this.data.Form;
      //console.log("Value Before Converting : ",Form.value);
      let otherD = parseInt(Form.value.otherdonation);
      Form.patchValue({ otherdonation: otherD });
      let mobile = parseInt(Form.value.mobileNumber);
      Form.patchValue({ mobileNumber: mobile });
      let pinCode = parseInt(Form.value.pincode);
      Form.patchValue({ pincode: pinCode });
      let buissPhone = parseInt(Form.value.businessphone);
      Form.patchValue({ businessphone: buissPhone });

      //let userid = sessionStorage.getItem("id")
      let userid = Form.value['rep_id'];
      let totalAmount =
        parseInt(Form.value['totalMagAmount']) +
        parseInt(Form.value['otherdonation']);
      let rep_comm = parseInt(Form.value['rep_comm']);

      console.log('Value After Patching : ', Form.value);
      let paymentCmplt = '';

      if (Form.value['paymentmode'] == 'complementry') {
        paymentCmplt = 'Y';
      } else {
        paymentCmplt = 'N';
      }

      let bphone = 0;
      if (Number.isNaN(parseInt(Form.value['businessphone']))) {
        bphone = 0;
      } else {
        bphone = Form.value['businessphone'];
      }

      let paymode = Form.value['paymentmode'];

      let newsub = {
        address: Form.value['address'],
        businessaddress: Form.value['businessaddress'],
        businessemail: Form.value['businessemail'],
        businessname: Form.value['businessname'],
        businessphone: bphone,
        businesstype: Form.value['businesstype'],
        email: Form.value['email'],
        mobileNumber: Form.value['mobileNumber'],
        name: Form.value['name'],
        otherdonation: Form.value['otherdonation'],
        paymentmode: Form.value['paymentmode'],
        pincode: Form.value['pincode'],
        dob: Form.value['dob'],
        subCat: Form.value['subCat'],
        gender: Form.value['gender'],
        isPaymentCmplt: paymentCmplt,
        ds_subisactive: 'N',
        chequeno: Form.value['chequeno'],
        city: Form.value['city']['name'],
        bcity: Form.value['bcity'],
        bpin: Form.value['bpin'],
        country: Form.value['country'],
        state: Form.value['state']['state_name'],
        taluka: Form.value['taluka'],
        title: Form.value['title'],
        doa: Form.value['doa'],
      };

      let RepCredit = 0;
      let commamt = totalAmount * (rep_comm / 100);

      this.service.getRepcredit(parseInt(userid)).subscribe((res) => {
        console.log(res);
        RepCredit = res;
      });

      let newmag = {
        ds_custmobile: parseInt(Form.value['mobileNumber']),
        ds_repid: parseInt(userid),
        ds_mag1: Form.value['magnames'],
        ds_mag1edition: Form.value['magedition'],
        ds_mag1enddt: Form.value['magenddt'],
        ds_totamt: parseInt(Form.value['magprice']),
        ds_mag1startdt: Form.value['magstartdt'],
      };

      let resultState = this.service.addSubscriber(newsub);
      resultState.subscribe((data) => {
        let data1 = data;
        let ds_new_id = 0;
        window.alert('Subscriber Added SuccessFully');
        console.log('Subscriber Inserted at ID : ', data1);

        if (paymode == 'cash') {
          this.service.NewMagazine(newmag, 'C').subscribe((res) => {
            //console.log(res)
            ds_new_id = res['id'];
            this.insertCommissionClose(
              newsub,
              Form.value['magnames'],
              userid,
              commamt,
              ds_new_id
            );
          });
        } else if (paymode == 'cheque') {
          this.service
            .NewMagazine(newmag, 'CH:' + Form.value['chequeno'])
            .subscribe((res) => {
              //console.log(res)
              ds_new_id = res['id'];
              this.insertCommissionClose(
                newsub,
                Form.value['magnames'],
                userid,
                commamt,
                ds_new_id
              );
            });
        } else if (paymode == 'online') {
          this.service.NewMagazine(newmag, 'O').subscribe((res) => {
            //console.log(res)
            ds_new_id = res['id'];
            this.insertCommissionClose(
              newsub,
              Form.value['magnames'],
              userid,
              commamt,
              ds_new_id
            );
          });
        } else {
          this.service.NewMagazine(newmag, 'P').subscribe((res) => {
            //console.log(res)
            ds_new_id = res['id'];
            this.insertCommissionClose(
              newsub,
              Form.value['magnames'],
              userid,
              commamt,
              ds_new_id
            );
          });
        }

        this.service
          .updateCreditLimit(
            parseInt(RepCredit[0]['ds_repid']),
            0,
            parseInt(RepCredit[0]['ds_tempcredit'])
          )
          .subscribe((res) => {
            //console.log(res)
          });
      });
    } else {
      window.alert('Subscriber Not Added');
    }
  }

  insertCommissionClose(newsub, magname, userid, commamt, ds_id) {
    this.service
      .insertComissionLimit(
        parseInt(userid),
        parseInt(newsub['mobileNumber']),
        commamt,
        ds_id
      )
      .subscribe((res) => {
        let msg =
          'Dear ' +
          newsub['name'] +
          ' , Your ' +
          magname +
          ' has been received to our office. Regards, Hindustan Prakashan Sanstha.';
        let template = 'Vivek31';

        this.service
          .sendSMS(parseInt(newsub['mobileNumber']), msg, template)
          .subscribe((a) => {});
      });
  }
}
