import { formatDate } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-districtwise-talukawise',
  templateUrl: './districtwise-talukawise.component.html',
  styleUrls: ['./districtwise-talukawise.component.scss'],
})
export class DistrictwiseTalukawiseComponent implements OnInit {
  districttalukawisedata;
  today = new Date();
  startDate = '';
  endDate = '';
  isLoading: boolean = false;
  subTypesName = [
    { name: 'वार्षिक', isVisible: false },
    { name: 'त्रैवार्षिक', isVisible: false },
    { name: 'पंचवार्षिक', isVisible: false },
  ];
  totalth = 0;
  finalData = [];

  constructor(
    public dialogref: MatDialogRef<DistrictwiseTalukawiseComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.districttalukawisedata = this.data['districttalukawisedata'];
    // console.log(this.districttalukawisedata);
    this.startDate = this.data['startDate'];
    this.endDate = this.data['endDate'];

    let data = this.uniqueData(
      this.districttalukawisedata,
      (a) => a.subscription_type
    );

    for (let i = 0; i < Object.keys(data).length; i++) {
      let flag = false;
      for (let j = 0; j < Object.keys(this.subTypesName).length; j++) {
        if (data[i]['subscription_type'] === this.subTypesName[j]['name']) {
          this.subTypesName[j]['isVisible'] = true;
          flag = true;
          break;
        }
      }
      if (!flag) {
        this.subTypesName.push({
          name: data[i]['subscription_type'],
          isVisible: true,
        });
      }
    }

    this.totalth =
      this.subTypesName.filter((a) => {
        return a.isVisible === true;
      }).length *
        4 +
      10;

    // this.getdistrict_talukadata();

    this.setData();
    // console.log();
  }

  setData() {
    // console.log(this.rojmelData);
    let uniqueDistrictData = this.uniqueData(
      this.districttalukawisedata,
      (a) => a.district
    );
    // console.log('uniqueDistrictData', uniqueDistrictData);
    let finalTotal = {};
    this.subTypesName.map((b: any, i) => {
      if (b.isVisible) {
        let name = this.getKey(b.name);
        finalTotal[name + '_एकूण'] = 0;
        finalTotal[name + '_नवीन'] = 0;
        finalTotal[name + '_नूतनीकरण'] = 0;
        finalTotal[name + '_नूतनीकरणबाकी'] = 0;
        if (i == 0) {
          finalTotal['name'] = 'एकूण';
          finalTotal['sub'] = 0;
          finalTotal['एकूण'] = 0;
          finalTotal['नवीन'] = 0;
          finalTotal['नूतनीकरण'] = 0;
          finalTotal['नूतनीकरणबाकी'] = 0;
        }
      }
    });

    for (let i = 0; i < uniqueDistrictData.length; i++) {
      let districtwiseData = this.districttalukawisedata.filter((a) => {
        return a.district === uniqueDistrictData[i]['district'];
      });
      // console.log('districtwiseData', districtwiseData);
      let uniqueTalukawiseData = this.uniqueData(
        districtwiseData,
        (a) => a.taluka
      );
      let talukaData = [];
      let subTotal = {};
      this.subTypesName.map((b: any, i) => {
        if (b.isVisible) {
          let name = this.getKey(b.name);
          subTotal[name + '_एकूण'] = 0;
          subTotal[name + '_नवीन'] = 0;
          subTotal[name + '_नूतनीकरण'] = 0;
          subTotal[name + '_नूतनीकरणबाकी'] = 0;
          if (i == 0) {
            subTotal['name'] = 'एकूण';
            subTotal['sub'] = 0;
            subTotal['एकूण'] = 0;
            subTotal['नवीन'] = 0;
            subTotal['नूतनीकरण'] = 0;
            subTotal['नूतनीकरणबाकी'] = 0;
          }
        }
      });
      // console.log('uniqueTalukawiseData', uniqueTalukawiseData);
      for (let k = 0; k < uniqueTalukawiseData.length; k++) {
        let talukawiseData = districtwiseData.filter((a) => {
          return a.taluka === uniqueTalukawiseData[k]['taluka'];
        });

        let totalData = {};
        this.subTypesName.map((b: any, i) => {
          if (b.isVisible) {
            let name = this.getKey(b.name);
            totalData[name + '_एकूण'] = 0;
            totalData[name + '_नवीन'] = 0;
            totalData[name + '_नूतनीकरण'] = 0;
            totalData[name + '_नूतनीकरणबाकी'] = 0;
            if (i == 0) {
              totalData['sub'] = 0;
              totalData['एकूण'] = 0;
              totalData['नवीन'] = 0;
              totalData['नूतनीकरण'] = 0;
              totalData['नूतनीकरणबाकी'] = 0;
            }
          }
        });

        let today = new Date();
        today.setMonth(today.getMonth() + 1);
        talukawiseData.map((a: any, i) => {
          if (i == 0) {
            totalData['district'] = a.district;
            totalData['taluka'] = a.taluka;
            totalData['rep_name'] = a.rep_name;
          }
          let name = this.getKey(a.subscription_type);
          if (
            a['sub_perid_to_date'] >
            formatDate(new Date(today), 'yyyy-MM-dd', 'en-IN', 'GMT+0530')
          ) {
            totalData[name + '_नवीन'] += a.rcpt_type == 'New' ? 1 : 0;
            finalTotal[name + '_नवीन'] += a.rcpt_type == 'New' ? 1 : 0;
            subTotal[name + '_नवीन'] += a.rcpt_type == 'New' ? 1 : 0;
            totalData[name + '_नूतनीकरण'] += a.rcpt_type == 'Renew' ? 1 : 0;
            finalTotal[name + '_नूतनीकरण'] += a.rcpt_type == 'Renew' ? 1 : 0;
            subTotal[name + '_नूतनीकरण'] += a.rcpt_type == 'Renew' ? 1 : 0;
            totalData['नवीन'] += a.rcpt_type == 'New' ? 1 : 0;
            finalTotal['नवीन'] += a.rcpt_type == 'New' ? 1 : 0;
            subTotal['नवीन'] += a.rcpt_type == 'New' ? 1 : 0;
            totalData['नूतनीकरण'] += a.rcpt_type == 'Renew' ? 1 : 0;
            finalTotal['नूतनीकरण'] += a.rcpt_type == 'Renew' ? 1 : 0;
            subTotal['नूतनीकरण'] += a.rcpt_type == 'Renew' ? 1 : 0;
            totalData['sub'] += 1;
            finalTotal['sub'] += 1;
            subTotal['sub'] += 1;
            totalData[name + '_एकूण'] += parseInt(a.total_amt);
            finalTotal[name + '_एकूण'] += parseInt(a.total_amt);
            subTotal[name + '_एकूण'] += parseInt(a.total_amt);
            totalData['एकूण'] += parseInt(a.total_amt);
            finalTotal['एकूण'] += parseInt(a.total_amt);
            subTotal['एकूण'] += parseInt(a.total_amt);
          } else {
            totalData[name + '_नूतनीकरणबाकी'] += 1;
            finalTotal[name + '_नूतनीकरणबाकी'] += 1;
            subTotal[name + '_नूतनीकरणबाकी'] += 1;
            totalData['नूतनीकरणबाकी'] += 1;
            finalTotal['नूतनीकरणबाकी'] += 1;
            subTotal['नूतनीकरणबाकी'] += 1;
          }
        });

        talukaData.push(totalData);
        // }
      }
      talukaData.push(subTotal);
      this.finalData.push(talukaData);
    }
    this.finalData.push(finalTotal);
    // console.log(this.finalData);

    this.isLoading = false;
  }

  getKey(name) {
    return name.replaceAll(' ', '_');
  }

  uniqueData(data, key) {
    return [...new Map(data.map((a) => [key(a), a])).values()];
  }

  exportExcel(fileName) {
    /* table id is passed over here */
    let element = document.getElementById('print-section');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, fileName + '.xlsx');
  }
}
