import { formatDate } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DigisubservicesService } from 'src/app/services/digisubservices.service';

@Component({
  selector: 'app-datewise-representativewise',
  templateUrl: './datewise-representativewise.component.html',
  styleUrls: ['./datewise-representativewise.component.scss'],
})
export class DatewiseRepresentativewiseComponent implements OnInit {
  today;
  datewiserepwisedata;
  datewiserepwiselist = [];
  tempdata = [];
  data1 = [];
  atotal = 0;
  anew = 0;
  arenew = 0;
  vnew = 0;
  vrenew = 0;
  vtotal = 0;
  tnew = 0;
  trenew = 0;
  ttotal = 0;
  pnew = 0;
  prenew = 0;
  ptotal = 0;
  snew = 0;
  srenew = 0;
  stotal = 0;
  anamat = 0;
  punchthev = 0;
  other = 0;

  constructor(
    public service: DigisubservicesService,
    private dialogref: MatDialogRef<DatewiseRepresentativewiseComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.datewiserepwisedata = this.data['dateorrepwisedata'];
    this.today = formatDate(new Date(), 'dd/MM/yyyy', 'en-IN', 'GMT+0530');

    // this.service.getDatewiseAndRepwiseList().subscribe((data) => {
    //   console.log('daterep', data);
    //   this.datewiserepwisedata = data;
    // });
    this.getDateORrepwiselis();
  }

  print() {
    window.print();
  }

  getDateORrepwiselis() {
    for (
      let index = 0;
      index < Object.keys(this.datewiserepwisedata).length;
      index++
    ) {
      let flag = false;
      if (Object.keys(this.tempdata).length == 0) {
        this.tempdata.push({
          district: this.datewiserepwisedata[index]['district'],
          entry_date: this.datewiserepwisedata[index]['entry_date'],
          name: this.datewiserepwisedata[index]['name'],
          office_rep_name: this.datewiserepwisedata[index]['office_rep_name'],
          rcpt_no: this.datewiserepwisedata[index]['rcpt_no'],
          rcpt_type: this.datewiserepwisedata[index]['rcpt_type'],
          rep_name: this.datewiserepwisedata[index]['rep_name'],
          subscription_type:
            this.datewiserepwisedata[index]['subscription_type'],
          taluka: this.datewiserepwisedata[index]['taluka'],
          title: this.datewiserepwisedata[index]['title'],
          total_amt: this.datewiserepwisedata[index]['total_amt'],
          atotal: 0,
          anew: 0,
          arenew: 0,
          vnew: 0,
          vrenew: 0,
          vtotal: 0,
          tnew: 0,
          trenew: 0,
          ttotal: 0,
          pnew: 0,
          prenew: 0,
          ptotal: 0,
          snew: 0,
          srenew: 0,
          stotal: 0,
          anamat: 0,
          punchthev: 0,
          other: 0,
        });
      } else {
        for (
          let index1 = 0;
          index1 < Object.keys(this.tempdata).length;
          index1++
        ) {
          if (
            this.tempdata[index1]['entry_date'] ==
            this.datewiserepwisedata[index]['entry_date']
          ) {
            flag = true;
          }
        }
        if (!flag) {
          this.tempdata.push({
            district: this.datewiserepwisedata[index]['district'],
            entry_date: this.datewiserepwisedata[index]['entry_date'],
            name: this.datewiserepwisedata[index]['name'],
            office_rep_name: this.datewiserepwisedata[index]['office_rep_name'],
            rcpt_no: this.datewiserepwisedata[index]['rcpt_no'],
            rcpt_type: this.datewiserepwisedata[index]['rcpt_type'],
            rep_name: this.datewiserepwisedata[index]['rep_name'],
            subscription_type:
              this.datewiserepwisedata[index]['subscription_type'],
            taluka: this.datewiserepwisedata[index]['taluka'],
            title: this.datewiserepwisedata[index]['title'],
            total_amt: this.datewiserepwisedata[index]['total_amt'],
            atotal: 0,
            anew: 0,
            arenew: 0,
            vnew: 0,
            vrenew: 0,
            vtotal: 0,
            tnew: 0,
            trenew: 0,
            ttotal: 0,
            pnew: 0,
            prenew: 0,
            ptotal: 0,
            snew: 0,
            srenew: 0,
            stotal: 0,
            anamat: 0,
            punchthev: 0,
            other: 0,
          });
        }
      }
    }

    console.log('tempdata', this.tempdata);
    for (let index = 0; index < Object.keys(this.tempdata).length; index++) {
      let arr = this.datewiserepwisedata.filter(
        (a) => a['entry_date'] == this.tempdata[index]['entry_date']
      );

      let atotal = 0;
      let anew = 0;
      let arenew = 0;
      let vnew = 0;
      let vrenew = 0;
      let vtotal = 0;
      let tnew = 0;
      let trenew = 0;
      let ttotal = 0;
      let pnew = 0;
      let prenew = 0;
      let ptotal = 0;
      let snew = 0;
      let srenew = 0;
      let stotal = 0;
      let anamat = 0;
      let punchthev = 0;
      let other = 0;
      for (let index1 = 0; index1 < Object.keys(arr).length; index1++) {
        atotal = atotal + parseInt(arr[index1]['total_amt']);
        if (arr[index1]['rcpt_type'] == 'New') {
          anew = anew + 1;
        } else if (arr[index1]['rcpt_type'] == 'Renew') {
          arenew = arenew + 1;
        }

        if (arr[index1]['subscription_type'] == 'वार्षिक') {
          vtotal = vtotal + parseInt(arr[index1]['total_amt']);
          if (arr[index1]['rcpt_type'] == 'New') {
            vnew = vnew + 1;
          } else if (arr[index1]['rcpt_type'] == 'Renew') {
            vrenew = vrenew + 1;
          }
        } else if (arr[index1]['subscription_type'] == 'त्रैवार्षिक') {
          ttotal = ttotal + parseInt(arr[index1]['total_amt']);
          if (arr[index1]['rcpt_type'] == 'New') {
            tnew = tnew + 1;
          } else if (arr[index1]['rcpt_type'] == 'Renew') {
            trenew = trenew + 1;
          }
        } else if (arr[index1]['subscription_type'] == 'पंचवार्षिक') {
          ptotal = ptotal + parseInt(arr[index1]['total_amt']);
          if (arr[index1]['rcpt_type'] == 'New') {
            pnew = pnew + 1;
          } else if (arr[index1]['rcpt_type'] == 'Renew') {
            prenew = prenew + 1;
          }
        } else if (arr[index1]['subscription_type'] == 'सहयोगी') {
          stotal = stotal + parseInt(arr[index1]['total_amt']);
          if (arr[index1]['rcpt_type'] == 'New') {
            snew = snew + 1;
          } else if (arr[index1]['rcpt_type'] == 'Renew') {
            srenew = srenew + 1;
          }
        } else if (arr[index1]['subscription_type'] == 'सहयोगी') {
          anamat = anamat + parseInt(arr[index1]['total_amt']);
        } else if (arr[index1]['subscription_type'] == 'सहयोगी') {
          punchthev = punchthev + parseInt(arr[index1]['total_amt']);
        } else if (arr[index1]['subscription_type'] == 'सहयोगी') {
          other = other + parseInt(arr[index1]['total_amt']);
        }
      }

      this.tempdata[index]['atotal'] = atotal;
      this.tempdata[index]['anew'] = anew;
      this.tempdata[index]['arenew'] = arenew;
      this.tempdata[index]['vnew'] = vnew;
      this.tempdata[index]['vrenew'] = vrenew;
      this.tempdata[index]['vtotal'] = vtotal;
      this.tempdata[index]['tnew'] = tnew;
      this.tempdata[index]['trenew'] = trenew;
      this.tempdata[index]['ttotal'] = ttotal;
      this.tempdata[index]['pnew'] = pnew;
      this.tempdata[index]['prenew'] = prenew;
      this.tempdata[index]['ptotal'] = ptotal;
      this.tempdata[index]['snew'] = snew;
      this.tempdata[index]['srenew'] = srenew;
      this.tempdata[index]['stotal'] = stotal;
      this.tempdata[index]['anamat'] = anamat;
      this.tempdata[index]['punchthev'] = punchthev;
      this.tempdata[index]['other'] = other;

      this.datewiserepwiselist.push(arr);
    }

    for (let index = 0; index < Object.keys(this.tempdata).length; index++) {
      this.atotal = this.atotal + parseInt(this.tempdata[index]['atotal']);
      this.anew = this.anew + parseInt(this.tempdata[index]['anew']);
      this.arenew = this.arenew + parseInt(this.tempdata[index]['arenew']);
      this.vnew = this.vnew + parseInt(this.tempdata[index]['vnew']);
      this.vrenew = this.vrenew + parseInt(this.tempdata[index]['vrenew']);
      this.vtotal = this.vtotal + parseInt(this.tempdata[index]['vtotal']);
      this.tnew = this.tnew + parseInt(this.tempdata[index]['tnew']);
      this.trenew = this.trenew + parseInt(this.tempdata[index]['trenew']);
      this.ttotal = this.ttotal + parseInt(this.tempdata[index]['ttotal']);
      this.pnew = this.pnew + parseInt(this.tempdata[index]['pnew']);
      this.prenew = this.prenew + parseInt(this.tempdata[index]['prenew']);
      this.ptotal = this.ptotal + parseInt(this.tempdata[index]['ptotal']);
      this.snew = this.snew + parseInt(this.tempdata[index]['snew']);
      this.srenew = this.srenew + parseInt(this.tempdata[index]['srenew']);
      this.stotal = this.stotal + parseInt(this.tempdata[index]['stotal']);
      this.anamat = this.anamat + parseInt(this.tempdata[index]['anamat']);
      this.punchthev =
        this.punchthev + parseInt(this.tempdata[index]['punchthev']);
      this.other = this.other + parseInt(this.tempdata[index]['other']);
    }
    console.log('datewiselist', this.datewiserepwiselist);
  }
}
