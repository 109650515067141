<ng-container>
    <div>
      <div style="margin-left: 70%;  margin-bottom: 2%">
        <button
        [useExistingCss]="true"
          class="noPrint" style="margin-right: 2%; margin-left: 2%" mat-raised-button color="primary"
          printSectionId="print-section" ngxPrint>
          print
        </button>
        <button class="noPrint" style="margin-top: 2% 2% 0 2%;"
          (click)="exportExcel('Office_Representativewise_Subscription_Report')" mat-raised-button color="primary">
          Export
        </button>
        <button mat-dialog-close class="btn noPrint">
          Close
        </button>
      </div>
    </div>
    <div>
      <div class="container" id="print-section">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th *ngIf="totalth == 17" colspan="10" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                साप्ताहिक - वर्गणी विभाग
              </th>
              <th *ngIf="totalth == 18" colspan="11" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                साप्ताहिक - वर्गणी विभाग
              </th>
              <th *ngIf="totalth == 19" colspan="12" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                साप्ताहिक - वर्गणी विभाग
              </th>
              <th *ngIf="totalth == 20" colspan="13" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                साप्ताहिक - वर्गणी विभाग
              </th>
              <th *ngIf="totalth == 21" colspan="14" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                साप्ताहिक - वर्गणी विभाग
              </th>
              <th *ngIf="totalth == 22" colspan="15" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                साप्ताहिक - वर्गणी विभाग
              </th>
              <th *ngIf="totalth == 23" colspan="16" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                साप्ताहिक - वर्गणी विभाग
              </th>
              <th *ngIf="totalth == 24" colspan="17" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                साप्ताहिक - वर्गणी विभाग
              </th>
              <th *ngIf="totalth == 25" colspan="18" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                साप्ताहिक - वर्गणी विभाग
              </th>
              <th *ngIf="totalth == 26" colspan="19" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                साप्ताहिक - वर्गणी विभाग
              </th>
              <th *ngIf="totalth == 27" colspan="20" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                साप्ताहिक - वर्गणी विभाग
              </th>
              <th *ngIf="totalth == 28" colspan="21" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                साप्ताहिक - वर्गणी विभाग
              </th>
              <th *ngIf="totalth == 29" colspan="22" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                साप्ताहिक - वर्गणी विभाग
              </th>
              <th *ngIf="totalth == 30" colspan="23" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                साप्ताहिक - वर्गणी विभाग
              </th>
              <th *ngIf="totalth == 31" colspan="24" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                साप्ताहिक - वर्गणी विभाग
              </th>
              <th *ngIf="totalth == 32" colspan="25" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                साप्ताहिक - वर्गणी विभाग
              </th>
              <th *ngIf="totalth == 33" colspan="26" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                साप्ताहिक - वर्गणी विभाग
              </th>
              <th *ngIf="totalth == 34" colspan="27" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                साप्ताहिक - वर्गणी विभाग
              </th>
              <th *ngIf="totalth == 35" colspan="28" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                साप्ताहिक - वर्गणी विभाग
              </th>
              <th *ngIf="totalth == 36" colspan="29" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                साप्ताहिक - वर्गणी विभाग
              </th>
              <th *ngIf="totalth == 37" colspan="30" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                साप्ताहिक - वर्गणी विभाग
              </th>
              <th *ngIf="totalth == 38" colspan="31" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                साप्ताहिक - वर्गणी विभाग
              </th>
              <th *ngIf="totalth == 39" colspan="32" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                साप्ताहिक - वर्गणी विभाग
              </th>
              <th *ngIf="totalth == 40" colspan="33" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                साप्ताहिक - वर्गणी विभाग
              </th>
              <th *ngIf="totalth == 41" colspan="34" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                साप्ताहिक - वर्गणी विभाग
              </th>
              <th *ngIf="totalth == 42" colspan="35" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                साप्ताहिक - वर्गणी विभाग
              </th>
              <th *ngIf="totalth == 43" colspan="36" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                साप्ताहिक - वर्गणी विभाग
              </th>
              <th *ngIf="totalth == 44" colspan="37" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                साप्ताहिक - वर्गणी विभाग
              </th>
              <th *ngIf="totalth == 45" colspan="38" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                साप्ताहिक - वर्गणी विभाग
              </th>
              <th *ngIf="totalth == 46" colspan="39" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                साप्ताहिक - वर्गणी विभाग
              </th>
              <th *ngIf="totalth == 47" colspan="40" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                साप्ताहिक - वर्गणी विभाग
              </th>
              <th *ngIf="totalth == 48" colspan="41" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                साप्ताहिक - वर्गणी विभाग
              </th>
              <th *ngIf="totalth == 49" colspan="42" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                साप्ताहिक - वर्गणी विभाग
              </th>
              <th colspan="2">दिनांक -</th>
              <th colspan="2">{{ today | date:'dd-MM-yyyy' }}</th>
              <th colspan="3"></th>
            </tr>
            <tr>
              <th *ngIf="totalth == 18" colspan="11" class="fontBold">
                <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण व्यवसाय तपशील -</span> कार्यालय प्रतिनिधीनुसार
              </th>
              <th *ngIf="totalth == 19" colspan="12" class="fontBold">
                <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण व्यवसाय तपशील -</span> कार्यालय प्रतिनिधीनुसार
              </th>
              <th *ngIf="totalth == 20" colspan="13" class="fontBold">
                <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण व्यवसाय तपशील -</span> कार्यालय प्रतिनिधीनुसार
              </th>
              <th *ngIf="totalth == 21" colspan="14" class="fontBold">
                <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण व्यवसाय तपशील -</span> कार्यालय प्रतिनिधीनुसार
              </th>
              <th *ngIf="totalth == 22" colspan="15" class="fontBold">
                <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण व्यवसाय तपशील -</span> कार्यालय प्रतिनिधीनुसार
              </th>
              <th *ngIf="totalth == 23" colspan="16" class="fontBold">
                <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण व्यवसाय तपशील -</span> कार्यालय प्रतिनिधीनुसार
              </th>
              <th *ngIf="totalth == 24" colspan="17" class="fontBold">
                <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण व्यवसाय तपशील -</span> कार्यालय प्रतिनिधीनुसार
              </th>
              <th *ngIf="totalth == 25" colspan="18" class="fontBold">
                <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण व्यवसाय तपशील -</span> कार्यालय प्रतिनिधीनुसार
              </th>
              <th *ngIf="totalth == 26" colspan="19" class="fontBold">
                <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण व्यवसाय तपशील -</span> कार्यालय प्रतिनिधीनुसार
              </th>
              <th *ngIf="totalth == 27" colspan="20" class="fontBold">
                <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण व्यवसाय तपशील -</span> कार्यालय प्रतिनिधीनुसार
              </th>
              <th *ngIf="totalth == 28" colspan="21" class="fontBold">
                <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण व्यवसाय तपशील -</span> कार्यालय प्रतिनिधीनुसार
              </th>
              <th *ngIf="totalth == 29" colspan="22" class="fontBold">
                <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण व्यवसाय तपशील -</span> कार्यालय प्रतिनिधीनुसार
              </th>
              <th *ngIf="totalth == 30" colspan="23" class="fontBold">
                <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण व्यवसाय तपशील -</span> कार्यालय प्रतिनिधीनुसार
              </th>
              <th *ngIf="totalth == 31" colspan="24" class="fontBold">
                <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण व्यवसाय तपशील -</span> कार्यालय प्रतिनिधीनुसार
              </th>
              <th *ngIf="totalth == 32" colspan="25" class="fontBold">
                <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण व्यवसाय तपशील -</span> कार्यालय प्रतिनिधीनुसार
              </th>
              <th *ngIf="totalth == 33" colspan="26" class="fontBold">
                <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण व्यवसाय तपशील -</span> कार्यालय प्रतिनिधीनुसार
              </th>
              <th *ngIf="totalth == 34" colspan="27" class="fontBold">
                <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण व्यवसाय तपशील -</span> कार्यालय प्रतिनिधीनुसार
              </th>
              <th *ngIf="totalth == 35" colspan="28" class="fontBold">
                <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण व्यवसाय तपशील -</span> कार्यालय प्रतिनिधीनुसार
              </th>
              <th *ngIf="totalth == 36" colspan="29" class="fontBold">
                <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण व्यवसाय तपशील -</span> कार्यालय प्रतिनिधीनुसार
              </th>
              <th *ngIf="totalth == 37" colspan="30" class="fontBold">
                <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण व्यवसाय तपशील -</span> कार्यालय प्रतिनिधीनुसार
              </th>
              <th *ngIf="totalth == 38" colspan="31" class="fontBold">
                <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण व्यवसाय तपशील -</span> कार्यालय प्रतिनिधीनुसार
              </th>
              <th *ngIf="totalth == 39" colspan="32" class="fontBold">
                <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण व्यवसाय तपशील -</span> कार्यालय प्रतिनिधीनुसार
              </th>
              <th *ngIf="totalth == 40" colspan="33" class="fontBold">
                <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण व्यवसाय तपशील -</span> कार्यालय प्रतिनिधीनुसार
              </th>
              <th *ngIf="totalth == 41" colspan="34" class="fontBold">
                <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण व्यवसाय तपशील -</span> कार्यालय प्रतिनिधीनुसार
              </th>
              <th *ngIf="totalth == 42" colspan="35" class="fontBold">
                <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण व्यवसाय तपशील -</span> कार्यालय प्रतिनिधीनुसार
              </th>
              <th *ngIf="totalth == 43" colspan="36" class="fontBold">
                <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण व्यवसाय तपशील -</span> कार्यालय प्रतिनिधीनुसार
              </th>
              <th *ngIf="totalth == 44" colspan="37" class="fontBold">
                <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण व्यवसाय तपशील -</span> कार्यालय प्रतिनिधीनुसार
              </th>
              <th *ngIf="totalth == 45" colspan="38" class="fontBold">
                <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण व्यवसाय तपशील -</span> कार्यालय प्रतिनिधीनुसार
              </th>
              <th *ngIf="totalth == 46" colspan="39" class="fontBold">
                <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण व्यवसाय तपशील -</span> कार्यालय प्रतिनिधीनुसार
              </th>
              <th *ngIf="totalth == 47" colspan="40" class="fontBold">
                <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण व्यवसाय तपशील -</span> कार्यालय प्रतिनिधीनुसार
              </th>
              <th *ngIf="totalth == 48" colspan="41" class="fontBold">
                <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण व्यवसाय तपशील -</span> कार्यालय प्रतिनिधीनुसार
              </th>
              <th *ngIf="totalth == 49" colspan="42" class="fontBold">
                <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण व्यवसाय तपशील -</span> कार्यालय प्रतिनिधीनुसार
              </th>
              <th colspan="2">दिनांक -</th>
              <th colspan="2">{{ startDate | date:'dd-MM-yyyy' }}</th>
              <th colspan="1">पर्यंत</th>
              <th colspan="2">{{ endDate | date:'dd-MM-yyyy' }}</th>
            </tr>
            <tr>
              <th rowspan="2">जिल्हा</th>
              <th rowspan="2">तालुका</th>
              <th rowspan="2">प्रतिनिधींचे नाव</th>
              <th colspan="5">एकूण</th>
              <ng-container *ngFor="let data of subTypesName">
                <th *ngIf="data.isVisible" colspan="3">{{ data.name }}</th>
                <th *ngIf="data.isVisible" rowspan="2">रक्कम रुपये</th>
              </ng-container>
            </tr>
            <tr>
              <th>वर्गणी दार संख्या</th>
              <th>नवीन</th>
              <th>नूतनी करण</th>
              <th>नूतनी करण बाकी</th>
              <th>रक्कम रुपये</th>
              <ng-container *ngFor="let data of subTypesName">
                <th *ngIf="data.isVisible">नवीन</th>
                <th *ngIf="data.isVisible">नूतनी करण</th>
                <th *ngIf="data.isVisible">नूतनी करण बाकी</th>
              </ng-container>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let data of finalData;  let i = index">
              <ng-container *ngIf=" i != finalData.length - 1">
               
                <ng-container >
                  <!-- <tr  *ngIf="!end1">
                    <td colspan="3" *ngIf="i == 0" style="text-align: center;">
                      {{data.office_rep_name}}
                    </td>
                  </tr> -->
                  <tr *ngIf="!end1">
                    <td *ngIf="i == 0">{{ data['district'] }}</td>
                    <td *ngIf="i != 0"></td>
                    <td>{{ data['taluka'] }}</td>
                    <td>{{ data['rep_name'] }}</td>
                    <td>{{ data['sub'] }}</td>
                    <td>{{ data['नवीन'] }}</td>
                    <td>{{ data['नूतनीकरण'] }}</td>
                    <td>{{ data['नूतनीकरणबाकी'] }}</td>
                    <td>{{ data['एकूण'] }}</td>
                    <ng-container *ngFor="let sub of subTypesName">
                      <ng-container *ngIf="sub.isVisible">
                        <td>{{ data[getKey(sub.name)+ '_नवीन'] }}</td>
                        <td>{{ data[getKey(sub.name)+ '_नूतनीकरण']}}</td>
                        <td>{{ data[getKey(sub.name)+ '_नूतनीकरणबाकी'] }}</td>
                        <td>{{ data[getKey(sub.name)+ '_एकूण'] }}</td>
                      </ng-container>
                    </ng-container>
                  </tr>
                  <tr *ngIf="end1">
                    <td colspan="3" class="centerText">{{ data['name'] }}</td>
                    <td>{{ data['sub'] }}</td>
                    <td>{{ data['नवीन'] }}</td>
                    <td>{{ data['नूतनीकरण'] }}</td>
                    <td>{{ data['नूतनीकरणबाकी'] }}</td>
                    <td>{{ data['एकूण'] }}</td>
                    <ng-container *ngFor="let sub of subTypesName">
                      <ng-container *ngIf="sub.isVisible">
                        <td>{{ data[getKey(sub.name)+ '_नवीन'] }}</td>
                        <td>{{ data[getKey(sub.name)+ '_नूतनीकरण'] }}</td>
                        <td>{{ data[getKey(sub.name)+ '_नूतनीकरणबाकी'] }}</td>
                        <td>{{ data[getKey(sub.name)+ '_एकूण'] }}</td>
                      </ng-container>
                    </ng-container>
                  </tr>
                </ng-container>
               
              </ng-container>
              <ng-container *ngIf="i === finalData.length - 1">
                <tr class="smallHeading">
                  <td colspan="3">{{ data['name'] }}</td>
                  <td>{{ data['sub'] }}</td>
                  <td>{{ data['नवीन'] }}</td>
                  <td>{{ data['नूतनीकरण'] }}</td>
                  <td>{{ data['नूतनीकरणबाकी'] }}</td>
                  <td>{{ data['एकूण'] }}</td>
                  <ng-container *ngFor="let sub of subTypesName">
                    <ng-container *ngIf="sub.isVisible">
                      <td>{{ data[getKey(sub.name)+ '_नवीन'] }}</td>
                      <td>{{ data[getKey(sub.name)+ '_नूतनीकरण'] }}</td>
                      <td>{{ data[getKey(sub.name)+ '_नूतनीकरणबाकी'] }}</td>
                      <td>{{ data[getKey(sub.name)+ '_एकूण'] }}</td>
                    </ng-container>
                  </ng-container>
                </tr>
              </ng-container>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
  
    <!-- <div id="print-section">
      <div class="container-fluid" style="
          border-width: 0px 0px 1px 0px;
          border-color: black;
          border-style: solid;
          margin-left: 10px;
          width: 1233px;
        ">
        <div class="row" style="
            border-width: 1px 1px 0px 1px;
            border-color: black;
            border-style: solid;
            text-align: center;
            width: 1233px;
          ">
          <div class="col-8" style="padding: 3px; font-size: 14px; font-weight: 600">
            हिंदुस्थान प्रकाशन संस्था - विवेक साप्तहिक - वर्गणी विभाग
          </div>
          <div class="col-1" style="
              border-width: 0px 0px 0px 1px;
              border-color: black;
              border-style: solid;
              padding: 1px;
            ">
            दिनांक -
          </div>
          <div class="col-1" style="
              border-width: 0px 0px 0px 1px;
              border-color: black;
              border-style: solid;
              padding: 1px;
            ">
            {{ today }}
          </div>
          <div class="col-2" style="
              border-width: 0px 0px 0px 1px;
              border-color: black;
              border-style: solid;
              padding: 1px;
            "></div>
        </div>
        <div class="row" style="
            border-width: 1px 1px 0px 1px;
            border-color: black;
            border-style: solid;
            width: 1233px;
          ">
          <div class="col-8" style="padding: 1px; font-size: 14px; font-weight: 600">
            <span style="text-align: left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण व्यवसाय तपशील -</span>
            <span style="margin-left: 50px">कार्यालय प्रतिनिधीनुसार</span>
          </div>
          <div class="col-1" style="
              border-width: 0px 0px 0px 1px;
              border-color: black;
              border-style: solid;
              padding: 1px;
              text-align: center;
            ">
            दिनांक पासून -
          </div>
          <div class="col-1" style="
              border-width: 0px 0px 0px 1px;
              border-color: black;
              border-style: solid;
              padding: 1px;
              text-align: center;
            ">
            {{ today }}
          </div>
          <div class="col-2" style="
              border-width: 0px 0px 0px 1px;
              border-color: black;
              border-style: solid;
              padding: 1px;
            ">
            पर्यंत
          </div>
        </div>
        <div class="row" style="
            border-width: 1px 1px 0px 1px;
            border-color: black;
            border-style: solid;
            width: 1233px;
          ">
          <div class="col-4">
            <span style="text-align: left">कार्यालय प्रतिनिधींचे नाव - {{ off_rep_name }}
            </span>
          </div>
          <div class="col-3">
            <span style="margin-left: 50px">प्रतिनिधींचे नाव : </span>
          </div>
          <div class="col-3">
            <span style="text-align: left">नूतनीकरण दिनांक पासून - </span>
          </div>
          <div class="col-2">
            <span style="text-align: right">पर्यंत </span>
          </div>
        </div>
        <div *ngFor="let item of datalist; let i = index; let end = last" class="row">
          <table class="table table-bordered" style="
              border-width: 1px 1px 0px 1px;
              border-color: black;
              border-style: solid;
              margin-bottom: -02px;
            ">
            <thead *ngIf="i == 0">
              <tr>
                <th style="
                    border-width: 0px 1px 0px 0px;
                    border-color: black;
                    border-style: solid;
                    width: 60px;
                  ">
                  जिल्हा
                </th>
                <th style="
                    border-width: 0px 1px 0px 0px;
                    border-color: black;
                    border-style: solid;
                    width: 55px;
                  ">
                  तालुका
                </th>
                <th style="
                    border-width: 0px 1px 0px 0px;
                    border-color: black;
                    border-style: solid;
                    width: 80px;
                  ">
                  प्रतिनिधींचे नाव
                </th>
                <th colspan="5" style="
                    border-width: 0px 1px 1px 0px;
                    border-color: black;
                    border-style: solid;
                    width: 220px;
                  ">
                  एकूण
                </th>
                <th colspan="3" style="
                    border-width: 0px 1px 1px 0px;
                    border-color: black;
                    border-style: solid;
                    width: 129px;
                  ">
                  वार्षिक
                </th>
                <th style="
                    border-width: 0px 1px 0px 0px;
                    border-color: black;
                    border-style: solid;
                    width: 45px;
                  ">
                  रक्कम रुपये
                </th>
                <th colspan="3" style="
                    border-width: 0px 1px 1px 0px;
                    border-color: black;
                    border-style: solid;
                    width: 129px;
                  ">
                  त्रै वार्षिक
                </th>
                <th style="
                    border-width: 0px 1px 0px 0px;
                    border-color: black;
                    border-style: solid;
                    width: 45px;
                  ">
                  रक्कम रुपये
                </th>
                <th colspan="3" style="
                    border-width: 0px 1px 1px 0px;
                    border-color: black;
                    border-style: solid;
                    width: 129px;
                  ">
                  पंच वार्षिक
                </th>
                <th style="
                    border-width: 0px 1px 0px 0px;
                    border-color: black;
                    border-style: solid;
                    width: 45px;
                  ">
                  रक्कम रुपये
                </th>
                <th colspan="3" style="
                    border-width: 0px 1px 1px 0px;
                    border-color: black;
                    border-style: solid;
                    width: 129px;
                  ">
                  सहयोगी
                </th>
                <th style="
                    border-width: 0px 1px 0px 0px;
                    border-color: black;
                    border-style: solid;
                    width: 45px;
                  ">
                  रक्कम रुपये
                </th>
                <th style="
                    border-width: 0px 1px 0px 0px;
                    border-color: black;
                    border-style: solid;
                    width: 40px;
                  ">
                  अना मत
                </th>
                <th style="
                    border-width: 0px 1px 0px 0px;
                    border-color: black;
                    border-style: solid;
                    width: 40px;
                  ">
                  पंच ठेव
                </th>
                <th style="
                    border-width: 0px 1px 0px 0px;
                    border-color: black;
                    border-style: solid;
                    width: 40px;
                  ">
                  इतर जमा
                </th>
              </tr>
              <tr>
                <th rowspan="2" style="
                    border-width: 0px 1px 0px 0px;
                    border-color: black;
                    border-style: solid;
                  "></th>
                <th rowspan="2" style="
                    border-width: 0px 1px 0px 0px;
                    border-color: black;
                    border-style: solid;
                  "></th>
                <th rowspan="2" style="
                    border-width: 0px 1px 0px 0px;
                    border-color: black;
                    border-style: solid;
                  "></th>
                <th rowspan="2" style="
                    border-width: 0px 1px 0px 0px;
                    border-color: black;
                    border-style: solid;
                  ">
                  वर्गणी दार संख्या
                </th>
                <th style="
                    border-width: 0px 1px 0px 0px;
                    border-color: black;
                    border-style: solid;
                  ">
                  नवीन
                </th>
                <th style="
                    border-width: 0px 1px 0px 0px;
                    border-color: black;
                    border-style: solid;
                  ">
                  नूतनी करण
                </th>
                <th style="
                    border-width: 0px 1px 0px 0px;
                    border-color: black;
                    border-style: solid;
                  ">
                  नूतनी करण बाकी
                </th>
                <th style="
                    border-width: 0px 1px 0px 0px;
                    border-color: black;
                    border-style: solid;
                  ">
                  रक्कम रुपये
                </th>
                <th style="
                    border-width: 0px 1px 0px 0px;
                    border-color: black;
                    border-style: solid;
                  ">
                  नवीन
                </th>
                <th style="
                    border-width: 0px 1px 0px 0px;
                    border-color: black;
                    border-style: solid;
                  ">
                  नूतनी करण
                </th>
                <th style="
                    border-width: 0px 1px 0px 0px;
                    border-color: black;
                    border-style: solid;
                  ">
                  नूतनी करण बाकी
                </th>
                <th rowspan="2" style="
                    border-width: 0px 1px 0px 0px;
                    border-color: black;
                    border-style: solid;
                  "></th>
                <th style="
                    border-width: 0px 1px 0px 0px;
                    border-color: black;
                    border-style: solid;
                  ">
                  नवीन
                </th>
                <th style="
                    border-width: 0px 1px 0px 0px;
                    border-color: black;
                    border-style: solid;
                  ">
                  नूतनी करण
                </th>
                <th style="
                    border-width: 0px 1px 0px 0px;
                    border-color: black;
                    border-style: solid;
                  ">
                  नूतनी करण बाकी
                </th>
                <th rowspan="2" style="
                    border-width: 0px 1px 0px 0px;
                    border-color: black;
                    border-style: solid;
                  "></th>
                <th style="
                    border-width: 0px 1px 0px 0px;
                    border-color: black;
                    border-style: solid;
                  ">
                  नवीन
                </th>
                <th style="
                    border-width: 0px 1px 0px 0px;
                    border-color: black;
                    border-style: solid;
                  ">
                  नूतनी करण
                </th>
                <th style="
                    border-width: 0px 1px 0px 0px;
                    border-color: black;
                    border-style: solid;
                  ">
                  नूतनी करण बाकी
                </th>
                <th rowspan="2" style="
                    border-width: 0px 1px 0px 0px;
                    border-color: black;
                    border-style: solid;
                  "></th>
                <th style="
                    border-width: 0px 1px 0px 0px;
                    border-color: black;
                    border-style: solid;
                  ">
                  नवीन
                </th>
                <th style="
                    border-width: 0px 1px 0px 0px;
                    border-color: black;
                    border-style: solid;
                  ">
                  नूतनी करण
                </th>
                <th style="
                    border-width: 0px 1px 0px 0px;
                    border-color: black;
                    border-style: solid;
                  ">
                  नूतनी करण बाकी
                </th>
                <th rowspan="2" style="
                    border-width: 0px 1px 0px 0px;
                    border-color: black;
                    border-style: solid;
                  "></th>
                <th rowspan="2" style="
                    border-width: 0px 1px 0px 0px;
                    border-color: black;
                    border-style: solid;
                  "></th>
                <th rowspan="2" style="
                    border-width: 0px 1px 0px 0px;
                    border-color: black;
                    border-style: solid;
                  "></th>
                <th rowspan="2" style="
                    border-width: 0px 1px 0px 0px;
                    border-color: black;
                    border-style: solid;
                  "></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of item; let first = index; let end = last">
                <td style="
                    border-width: 1px 1px 0px 0px;
                    border-color: black;
                    border-style: solid;
                    width: 60px;
                  ">
                  <span *ngIf="!first">{{ data["district"] }}</span>
                </td>
                <td style="
                    border-width: 1px 1px 0px 0px;
                    border-color: black;
                    border-style: solid;
                    width: 55px;
                  ">
                  {{ data["taluka"] }}
                </td>
                <td style="
                    border-width: 1px 1px 0px 0px;
                    border-color: black;
                    border-style: solid;
                    width: 80px;
                  ">
                  {{ data["rep_name"] }}
                </td>
                <td style="
                    border-width: 1px 1px 0px 0px;
                    border-color: black;
                    border-style: solid;
                    width: 44px;
                  ">
                  {{ data["subs_total"] }}
                </td>
                <td style="
                    border-width: 1px 1px 0px 0px;
                    border-color: black;
                    border-style: solid;
                    width: 44px;
                  ">
                  {{ data["anew"] }}
                </td>
                <td style="
                    border-width: 1px 1px 0px 0px;
                    border-color: black;
                    border-style: solid;
                    width: 44px;
                  ">
                  {{ data["arenew"] }}
                </td>
                <td style="
                    border-width: 1px 1px 0px 0px;
                    border-color: black;
                    border-style: solid;
                    width: 44px;
                  ">
                  {{ data["arenew_baki"] }}
                </td>
                <td style="
                    border-width: 1px 1px 0px 0px;
                    border-color: black;
                    border-style: solid;
                    width: 44px;
                  ">
                  {{ data["total_amt"] | number: 0 }}
                </td>
                <td style="
                    border-width: 1px 1px 0px 0px;
                    border-color: black;
                    border-style: solid;
                    width: 43px;
                  ">
                  {{ data["vnew"] }}
                </td>
                <td style="
                    border-width: 1px 1px 0px 0px;
                    border-color: black;
                    border-style: solid;
                    width: 43px;
                  ">
                  {{ data["vrenew"] }}
                </td>
                <td style="
                    border-width: 1px 1px 0px 0px;
                    border-color: black;
                    border-style: solid;
                    width: 43px;
                  ">
                  {{ data["vrenew_baki"] }}
                </td>
                <td style="
                    border-width: 1px 1px 0px 0px;
                    border-color: black;
                    border-style: solid;
                    width: 45px;
                  ">
                  {{ data["vtotal"] }}
                </td>
                <td style="
                    border-width: 1px 1px 0px 0px;
                    border-color: black;
                    border-style: solid;
                    width: 43px;
                  ">
                  {{ data["tnew"] }}
                </td>
                <td style="
                    border-width: 1px 1px 0px 0px;
                    border-color: black;
                    border-style: solid;
                    width: 43px;
                  ">
                  {{ data["trenew"] }}
                </td>
                <td style="
                    border-width: 1px 1px 0px 0px;
                    border-color: black;
                    border-style: solid;
                    width: 43px;
                  ">
                  {{ data["trenew_baki"] }}
                </td>
                <td style="
                    border-width: 1px 1px 0px 0px;
                    border-color: black;
                    border-style: solid;
                    width: 45px;
                  ">
                  {{ data["ttotal"] }}
                </td>
                <td style="
                    border-width: 1px 1px 0px 0px;
                    border-color: black;
                    border-style: solid;
                    width: 43px;
                  ">
                  {{ data["pnew"] }}
                </td>
                <td style="
                    border-width: 1px 1px 0px 0px;
                    border-color: black;
                    border-style: solid;
                    width: 43px;
                  ">
                  {{ data["prenew"] }}
                </td>
                <td style="
                    border-width: 1px 1px 0px 0px;
                    border-color: black;
                    border-style: solid;
                    width: 43px;
                  ">
                  {{ data["prenew_baki"] }}
                </td>
                <td style="
                    border-width: 1px 1px 0px 0px;
                    border-color: black;
                    border-style: solid;
                    width: 45px;
                  ">
                  {{ data["ptotal"] }}
                </td>
                <td style="
                    border-width: 1px 1px 0px 0px;
                    border-color: black;
                    border-style: solid;
                    width: 43px;
                  ">
                  {{ data["snew"] }}
                </td>
                <td style="
                    border-width: 1px 1px 0px 0px;
                    border-color: black;
                    border-style: solid;
                    width: 43px;
                  ">
                  {{ data["srenew"] }}
                </td>
                <td style="
                    border-width: 1px 1px 0px 0px;
                    border-color: black;
                    border-style: solid;
                    width: 43px;
                  ">
                  {{ data["srenew_baki"] }}
                </td>
                <td style="
                    border-width: 1px 1px 0px 0px;
                    border-color: black;
                    border-style: solid;
                    width: 45px;
                  ">
                  {{ data["stotal"] }}
                </td>
                <td style="
                    border-width: 1px 1px 0px 0px;
                    border-color: black;
                    border-style: solid;
                    width: 40px;
                  ">
                  {{ data["anamat"] }}
                </td>
                <td style="
                    border-width: 1px 1px 0px 0px;
                    border-color: black;
                    border-style: solid;
                    width: 40px;
                  ">
                  {{ data["punchthev"] }}
                </td>
                <td style="
                    border-width: 1px 1px 0px 0px;
                    border-color: black;
                    border-style: solid;
                    width: 40px;
                  ">
                  {{ data["other"] }}
                </td>
              </tr>
              <tr *ngFor="let val of tempdata; let s = index">
                <td *ngIf="i == s" style="
                    border-width: 1px 1px 0px 0px;
                    border-color: black;
                    border-style: solid;
                  "></td>
                <td *ngIf="i == s" style="
                    border-width: 1px 1px 0px 0px;
                    border-color: black;
                    border-style: solid;
                  "></td>
                <td *ngIf="i == s" style="
                    border-width: 1px 1px 0px 0px;
                    border-color: black;
                    border-style: solid;
                  "></td>
                <td *ngIf="i == s" style="
                    border-width: 1px 1px 0px 0px;
                    border-color: black;
                    border-style: solid;
                  "></td>
                <td *ngIf="i == s" style="
                    border-width: 1px 1px 0px 0px;
                    border-color: black;
                    border-style: solid;
                  "></td>
                <td *ngIf="i == s" style="
                    border-width: 1px 1px 0px 0px;
                    border-color: black;
                    border-style: solid;
                  "></td>
                <td *ngIf="i == s" style="
                    border-width: 1px 1px 0px 0px;
                    border-color: black;
                    border-style: solid;
                  "></td>
                <td *ngIf="i == s" style="
                    border-width: 1px 1px 0px 0px;
                    border-color: black;
                    border-style: solid;
                  "></td>
                <td *ngIf="i == s" style="
                    border-width: 1px 1px 0px 0px;
                    border-color: black;
                    border-style: solid;
                  "></td>
                <td *ngIf="i == s" style="
                    border-width: 1px 1px 0px 0px;
                    border-color: black;
                    border-style: solid;
                  "></td>
                <td *ngIf="i == s" style="
                    border-width: 1px 1px 0px 0px;
                    border-color: black;
                    border-style: solid;
                  "></td>
                <td *ngIf="i == s" style="
                    border-width: 1px 1px 0px 0px;
                    border-color: black;
                    border-style: solid;
                  "></td>
                <td *ngIf="i == s" style="
                    border-width: 1px 1px 0px 0px;
                    border-color: black;
                    border-style: solid;
                  "></td>
                <td *ngIf="i == s" style="
                    border-width: 1px 1px 0px 0px;
                    border-color: black;
                    border-style: solid;
                  "></td>
                <td *ngIf="i == s" style="
                    border-width: 1px 1px 0px 0px;
                    border-color: black;
                    border-style: solid;
                  "></td>
                <td *ngIf="i == s" style="
                    border-width: 1px 1px 0px 0px;
                    border-color: black;
                    border-style: solid;
                  "></td>
                <td *ngIf="i == s" style="
                    border-width: 1px 1px 0px 0px;
                    border-color: black;
                    border-style: solid;
                  "></td>
                <td *ngIf="i == s" style="
                    border-width: 1px 1px 0px 0px;
                    border-color: black;
                    border-style: solid;
                  "></td>
                <td *ngIf="i == s" style="
                    border-width: 1px 1px 0px 0px;
                    border-color: black;
                    border-style: solid;
                  "></td>
                <td *ngIf="i == s" style="
                    border-width: 1px 1px 0px 0px;
                    border-color: black;
                    border-style: solid;
                  "></td>
                <td *ngIf="i == s" style="
                    border-width: 1px 1px 0px 0px;
                    border-color: black;
                    border-style: solid;
                  "></td>
                <td *ngIf="i == s" style="
                    border-width: 1px 1px 0px 0px;
                    border-color: black;
                    border-style: solid;
                  "></td>
                <td *ngIf="i == s" style="
                    border-width: 1px 1px 0px 0px;
                    border-color: black;
                    border-style: solid;
                  "></td>
                <td *ngIf="i == s" style="
                    border-width: 1px 1px 0px 0px;
                    border-color: black;
                    border-style: solid;
                  "></td>
                <td *ngIf="i == s" style="
                    border-width: 1px 1px 0px 0px;
                    border-color: black;
                    border-style: solid;
                  "></td>
                <td *ngIf="i == s" style="
                    border-width: 1px 1px 0px 0px;
                    border-color: black;
                    border-style: solid;
                  "></td>
                <td *ngIf="i == s" style="
                    border-width: 1px 1px 0px 0px;
                    border-color: black;
                    border-style: solid;
                  "></td>
              </tr>
              <tr *ngIf="end">
                <td colspan="3" style="
                    border-width: 1px 1px 0px 0px;
                    border-color: black;
                    border-style: solid;
                    font-weight: bolder;
                  ">
                  एकूण
                </td>
                <td style="
                    border-width: 1px 1px 0px 0px;
                    border-color: black;
                    border-style: solid;
                    font-weight: bolder;
                  ">
                  {{ subs_total }}
                </td>
                <td style="
                    border-width: 1px 1px 0px 0px;
                    border-color: black;
                    border-style: solid;
                    font-weight: bolder;
                  ">
                  {{ anew_total }}
                </td>
                <td style="
                    border-width: 1px 1px 0px 0px;
                    border-color: black;
                    border-style: solid;
                    font-weight: bolder;
                  ">
                  {{ arenew_total }}
                </td>
                <td style="
                    border-width: 1px 1px 0px 0px;
                    border-color: black;
                    border-style: solid;
                    font-weight: bolder;
                  ">
                  {{ arenew_baki_total }}
                </td>
                <td style="
                    border-width: 1px 1px 0px 0px;
                    border-color: black;
                    border-style: solid;
                    padding-left: 05px;
                    font-weight: bolder;
                  ">
                  <span style="padding-left: -10px">{{ atotal_total }}</span>
                </td>
                <td style="
                    border-width: 1px 1px 0px 0px;
                    border-color: black;
                    border-style: solid;
                    font-weight: bolder;
                  ">
                  {{ vnew_total }}
                </td>
                <td style="
                    border-width: 1px 1px 0px 0px;
                    border-color: black;
                    border-style: solid;
                    font-weight: bolder;
                  ">
                  {{ vrenew_total }}
                </td>
                <td style="
                    border-width: 1px 1px 0px 0px;
                    border-color: black;
                    border-style: solid;
                    font-weight: bolder;
                  ">
                  {{ vrenew_baki_total }}
                </td>
                <td style="
                    border-width: 1px 1px 0px 0px;
                    border-color: black;
                    border-style: solid;
                    font-weight: bolder;
                  ">
                  {{ vtotal_total }}
                </td>
                <td style="
                    border-width: 1px 1px 0px 0px;
                    border-color: black;
                    border-style: solid;
                    font-weight: bolder;
                  ">
                  {{ tnew_total }}
                </td>
                <td style="
                    border-width: 1px 1px 0px 0px;
                    border-color: black;
                    border-style: solid;
                    font-weight: bolder;
                  ">
                  {{ trenew_total }}
                </td>
                <td style="
                    border-width: 1px 1px 0px 0px;
                    border-color: black;
                    border-style: solid;
                    font-weight: bolder;
                  ">
                  {{ trenew_baki_total }}
                </td>
                <td style="
                    border-width: 1px 1px 0px 0px;
                    border-color: black;
                    border-style: solid;
                    font-weight: bolder;
                  ">
                  {{ ttotal_total }}
                </td>
                <td style="
                    border-width: 1px 1px 0px 0px;
                    border-color: black;
                    border-style: solid;
                    font-weight: bolder;
                  ">
                  {{ pnew_total }}
                </td>
                <td style="
                    border-width: 1px 1px 0px 0px;
                    border-color: black;
                    border-style: solid;
                    font-weight: bolder;
                  ">
                  {{ prenew_total }}
                </td>
                <td style="
                    border-width: 1px 1px 0px 0px;
                    border-color: black;
                    border-style: solid;
                    font-weight: bolder;
                  ">
                  {{ prenew_baki_total }}
                </td>
                <td style="
                    border-width: 1px 1px 0px 0px;
                    border-color: black;
                    border-style: solid;
                    font-weight: bolder;
                  ">
                  {{ ptotal_total }}
                </td>
                <td style="
                    border-width: 1px 1px 0px 0px;
                    border-color: black;
                    border-style: solid;
                    font-weight: bolder;
                  ">
                  {{ snew_total }}
                </td>
                <td style="
                    border-width: 1px 1px 0px 0px;
                    border-color: black;
                    border-style: solid;
                    font-weight: bolder;
                  ">
                  {{ srenew_total }}
                </td>
                <td style="
                    border-width: 1px 1px 0px 0px;
                    border-color: black;
                    border-style: solid;
                    font-weight: bolder;
                  ">
                  {{ srenew_baki_total }}
                </td>
                <td style="
                    border-width: 1px 1px 0px 0px;
                    border-color: black;
                    border-style: solid;
                    font-weight: bolder;
                  ">
                  {{ stotal_total }}
                </td>
                <td style="
                    border-width: 1px 1px 0px 0px;
                    border-color: black;
                    border-style: solid;
                    font-weight: bolder;
                  ">
                  {{ anamat_total }}
                </td>
                <td style="
                    border-width: 1px 1px 0px 0px;
                    border-color: black;
                    border-style: solid;
                    font-weight: bolder;
                  ">
                  {{ punchthev_total }}
                </td>
                <td style="
                    border-width: 1px 1px 0px 0px;
                    border-color: black;
                    border-style: solid;
                    font-weight: bolder;
                  ">
                  {{ other_total }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <button [useExistingCss]="true" class="noPrint" style="margin-top: 2%" mat-raised-button color="primary"
        printSectionId="print-section" ngxPrint>
        print
      </button>
      <button class="noPrint" style="margin-top: 2%; margin-left: 2%" mat-raised-button color="info" mat-dialog-close>
        Close
      </button>
    </div> -->
  </ng-container>
  <div *ngIf="isLoading" class="main-loading">
    <span class="loader"></span>
    <p>Loading...</p>
  </div>