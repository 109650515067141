<div>
  <div>
    <!-- <form [formGroup]="authoraddressForm">
      <div class="form-row">
        <mat-form-field
          class="example-full-width noPrint"
          appearance="outline"
          style="width: 25%; margin-left: 2%"
        >
          <mat-label>Author Name</mat-label>
          <input
            matInput
            formControlName="authorname"
            [matAutocomplete]="autoauthoradd"
          />
          <mat-autocomplete
            autoActiveFirstOption
            #autoauthoradd="matAutocomplete"
          >
            <mat-option
              *ngFor="let option of filteredOptions1 | async"
              [value]="option['name']"
              (onSelectionChange)="selected_authorname($event, option)"
            >
              {{ option["name"] }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
    </form> -->
    <div style="margin-left: 50%; margin-top: -4%; margin-bottom: 2%">
      <!-- <button
        mat-raised-button
        class="btn btn-info noPrint"
        (click)="print()"
        style="margin-right: 2%; margin-left: 2%"
      >
        Print
      </button> -->
      <button [useExistingCss]="true" class="noPrint" style="margin-right: 2%; margin-left: 2%" mat-raised-button
        color="primary" printSectionId="print-section" ngxPrint>
        print
      </button>
      <button mat-dialog-close class="btn noPrint">Close</button>
    </div>
  </div>
  <div class="container" id="print-section">
    <div class="row">
      <div class="col-3" *ngFor="let item of bookselleraddress" style="margin-bottom: 1.5%">
        <span style="font-size: 20px">{{ item["name"] }}</span><br />
        <span style="font-size: 20px">{{ item["address"] }} {{ item["taluka"] }}
          {{ item["district"] }}</span><br />
        <span style="font-size: 20px">{{ item["state"] }} </span>
        <span style="font-size: 20px">{{ item["country"] }} </span>
        <span style="font-size: 20px" *ngIf="item['pincode'] != 0">- {{ item["pincode"] }}</span><br
          *ngIf="item['pincode'] != 0" />
        <span style="font-size: 20px" *ngIf="item['mob_no'] != 0">{{
          item["mobile_no"]
          }}</span>
      </div>
    </div>
  </div>

  <!-- <div class="wrapper" id="print-section">
    <table style="margin: 15px;">
      <thead>
        <tr>
          <td>
            <div class="header-space">&nbsp;</div>
          </td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <div class="row">
              <div class="col-3" *ngFor="let item of bookselleraddress" style="page-break-inside: avoid;">
                <span style="font-size: 20px">{{ item["name"] }}</span><br />
                <span style="font-size: 20px">{{ item["address"] }} {{ item["taluka"] }}
                  {{ item["district"] }}</span><br />
                <span style="font-size: 20px">{{ item["state"] }} </span>
                <span style="font-size: 20px">{{ item["country"] }} </span>
                <span style="font-size: 20px" *ngIf="item['pincode'] != 0">- {{ item["pincode"] }}</span><br
                  *ngIf="item['pincode'] != 0" />
                <span style="font-size: 20px" *ngIf="item['mob_no'] != 0">{{
                  item["mobile_no"]
                  }}</span>
              </div>

            </div>
          </td>
        </tr>
      </tbody>
    </table>-->
  <!-- <div class="header">दि. १४/०९/२३ साप्तहिक विवेक</div> -->
  <!-- <div class="footer">...</div> -->
  <!-- </div> -->
</div>