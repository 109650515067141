import { formatDate } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'app-repwise-address-list',
  templateUrl: './repwise-address-list.component.html',
  styleUrls: ['./repwise-address-list.component.scss'],
})
export class RepwiseAddressListComponent implements OnInit {
  repwisesubaddresslist = [];
  datalist = [];
  tempdata = [];
  finaldatalist = [];

  constructor(
    public dialogref: MatDialogRef<RepwiseAddressListComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.repwisesubaddresslist = this.data['repwisesubaddresslist'];

    this.get_repwise_add_list();
  }

  get_repwise_add_list() {
    let today = new Date();
    today.setMonth(today.getMonth() + 1);

    this.datalist = this.repwisesubaddresslist.filter((a) => {
      return !(
        a['sub_perid_to_date'] >
        formatDate(today, 'yyyy-MM-dd', 'en-IN', 'GMT+0530')
      );
    });

    console.log(this.datalist);

    for (let index = 0; index < Object.keys(this.datalist).length; index++) {
      let flag = false;
      if (Object.keys(this.tempdata).length == 0) {
        this.tempdata.push({
          subno: this.datalist[index]['subno'],
          title: this.datalist[index]['title'],
          name: this.datalist[index]['name'],
          address: this.datalist[index]['address'],
          district: this.datalist[index]['district'],
          taluka: this.datalist[index]['taluka'],
          state: this.datalist[index]['state'],
          country: this.datalist[index]['country'],
          pincode: this.datalist[index]['pincode'],
          mobile_no: this.datalist[index]['mobile_no'],
          entry_date: this.datalist[index]['entry_date'],
          office_rep_name: this.datalist[index]['office_rep_name'],
          rcpt_no: this.datalist[index]['rcpt_no'],
          rcpt_type: this.datalist[index]['rcpt_type'],
          rep_name: this.datalist[index]['rep_name'],
          subscription_type: this.datalist[index]['subscription_type'],
          total_amt: this.datalist[index]['total_amt'],
          sub_perid_from_date: this.datalist[index]['sub_perid_from_date'],
          sub_perid_to_date: this.datalist[index]['sub_perid_to_date'],
          delivery_mode: this.datalist[index]['delivery_mode'],
        });
      } else {
        for (
          let index1 = 0;
          index1 < Object.keys(this.tempdata).length;
          index1++
        ) {
          if (
            this.tempdata[index1]['rep_name'] ==
            this.datalist[index]['rep_name']
          ) {
            flag = true;
          }
        }
        if (!flag) {
          this.tempdata.push({
            subno: this.datalist[index]['subno'],
            title: this.datalist[index]['title'],
            name: this.datalist[index]['name'],
            address: this.datalist[index]['address'],
            district: this.datalist[index]['district'],
            taluka: this.datalist[index]['taluka'],
            state: this.datalist[index]['state'],
            country: this.datalist[index]['country'],
            pincode: this.datalist[index]['pincode'],
            mobile_no: this.datalist[index]['mobile_no'],
            entry_date: this.datalist[index]['entry_date'],
            office_rep_name: this.datalist[index]['office_rep_name'],
            rcpt_no: this.datalist[index]['rcpt_no'],
            rcpt_type: this.datalist[index]['rcpt_type'],
            rep_name: this.datalist[index]['rep_name'],
            subscription_type: this.datalist[index]['subscription_type'],
            total_amt: this.datalist[index]['total_amt'],
            sub_perid_from_date: this.datalist[index]['sub_perid_from_date'],
            sub_perid_to_date: this.datalist[index]['sub_perid_to_date'],
            delivery_mode: this.datalist[index]['delivery_mode'],
          });
        }
      }
    }

    for (let index = 0; index < Object.keys(this.tempdata).length; index++) {
      let arr = this.datalist.filter(
        (a) => a['rep_name'] == this.tempdata[index]['rep_name']
      );

      this.finaldatalist.push(arr);
    }
    console.log(this.finaldatalist);
  }
}
