<div>
  <div class="container" style="border: 2px solid black">
    <div class="row">
      <!-- style="border-bottom-style: solid" -->
      <div class="col-2">
        <img
          style="margin-top: 6%"
          width="70%"
          src="../../../assets/img/brand/red.png"
        />
      </div>
      <div
        class="col-8"
        style="padding-top: 30px; text-align: center; padding-bottom: 10px"
      >
        <span style="font-size: 35px; font-weight: bold"
          >हिंदुस्थान प्रकाशन संस्था</span
        >
        <span style="font-size: 12px; margin-left: 05px"
          >(Reg.No.-BOM-3/1961(GBBSD)</span
        >
        <hr />
        <!-- <h3 style="margin-left: -130px; font-size: large">
          साप्ताहिक विवेक - पुस्तक विभाग
        </h3> -->
        <div class="row" style="text-align: left">
          <div class="col-6" style="padding-top: 10px">
            <span style="font-size: 12px"
              ><span style="font-weight: bold; font-size: 14px"
                >प्रशासकीय कार्यालय :</span
              >
              विवेक भवन (कृष्णा रिजन्सी), प्लॉट क्र. 40, सेक्टर क्र. 30, सानपाडा
              (प.), नवी मुंबई, जि. ठाणे-400 705.<br
            /></span>
            <span style="font-size: 12px"
              ><span style="font-weight: bold; font-size: 14px">दूरध्वनी:</span>
              27810235, 27810236
              <span style="font-weight: bold; font-size: 14px">फॅक्स:</span>
              27810237</span
            >
          </div>
          <div
            class="col-6"
            style="
              border-style: solid;
              border-width: 0px 0px 0px 2px;
              padding-top: 10px;
            "
          >
            <span style="font-size: 12px"
              ><span style="font-weight: bold; font-size: 14px"
                >मुख्य कार्यालय :
              </span>
              5/12, कामत औद्योगिक वसाहत, 396 स्वा. वीर सावरकर मार्ग, प्रभादेवी,
              मुंबई 4000 25.<br
            /></span>
            <span style="font-size: 12px"
              ><span style="font-weight: bold; font-size: 14px"
                >दूरध्वनी :
              </span>
              24221440
              <span style="font-weight: bold; font-size: 14px">फॅक्स : </span>
              24363756</span
            >
          </div>
        </div>
      </div>
      <div
        class="col-2"
        style="
          border-style: solid;
          border-width: 0px 0px 0px 2px;
          padding-top: 40px;
          text-align: center;
        "
      >
        <span style="font-size: 20px">क्रेडिट नोट</span>
      </div>
    </div>
    <div
      class="row"
      style="
        border-style: solid;
        border-width: 2px 0px 0px 0px;
        text-align: center;
      "
    >
      <div class="col-6" style="padding-top: 20px; text-align: left">
        <span style="font-weight: bold; font-size: 14px"
          >Book Seller Name:&nbsp;</span
        >
        <span style="font-size: 12px">{{
          this.bookcrdtnotedata["book_seller_name"]
        }}</span>
        <br />
        <span style="font-weight: bold; font-size: 14px">Address :&nbsp;</span>
        <span style="font-size: 12px"
          >{{ this.bookcrdtnotedata["book_seller_name"] }}&nbsp;{{
            this.bookcrdtnotedata["taluka"]
          }},&nbsp;{{ this.bookcrdtnotedata["district"] }},&nbsp;{{
            this.bookcrdtnotedata["state"]
          }},&nbsp;{{ this.bookcrdtnotedata["country"] }} - &nbsp;{{
            this.bookcrdtnotedata["pincode"]
          }}&nbsp;</span
        >
        <br />
      </div>
      <div
        class="col-6"
        style="border-style: solid; border-width: 0px 0px 0px 2px"
      >
        <div
          class="row"
          style="
            border-style: solid;
            border-width: 0px 0px 2px 0px;
            text-align: center;
            padding-top: 20px;
            padding-bottom: 10px;
          "
        >
          <div class="col-6" style="text-align: left">
            <span style="font-weight: bold; font-size: 14px"
              >क्रेडिट नोट क्रं.:
            </span>
            <span>{{ this.bookcrdtnotedata["crdtnote_no"] }}</span>
          </div>
          <div class="col-6" style="text-align: left">
            <span style="font-weight: bold; font-size: 14px">दिनांक:&nbsp;</span
            ><span style="font-size: 12px">
              {{ this.bookcrdtnotedata["date"] | date: "dd-MM-yyy" }}
            </span>
          </div>
        </div>
        <div class="row" style="padding-top: 20px">
          <div class="col-12" style="text-align: left">
            <span style="font-weight: bold; font-size: 14px"
              >यांचेकडुन रुपये&nbsp;</span
            >
            <span style="font-size: 12px">
              {{ this.bookcrdtnotedata["crdtnote_total"] }} /-
            </span>
          </div>
        </div>
        <div class="row">
          <div class="col-12" style="text-align: right">
            <span style="font-weight: bold; font-size: 14px"
              >मात्रची क्रेडिट नोट काढण्यात आली.</span
            >
          </div>
        </div>
        <div class="row">
          <div
            class="col-12"
            style="text-align: left; padding-top: 05px; padding-bottom: 10px"
          >
            <span style="font-weight: bold; font-size: 14px"
              >अक्षरी रक्कम.&nbsp;</span
            ><span style="font-size: 12px">
              {{ this.crdtnotetotalwords }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div
      class="row"
      style="
        border-style: solid;
        border-width: 2px 0px 0px 0px;
        text-align: center;
      "
    >
      <!-- <div class="col-12" style="text-align: left">
        <span style="font-weight: bold; font-size: 14px">बिल नंबर: </span>
        <span style="font-size: 12px" *ngFor="let book of bookpymtrcptdata"
          >{{ book["invoice_no"] }}, &nbsp;</span
        >
      </div> -->
      <!-- <div class="row" style="margin-left: 2%"> -->
      <div class="col-2 tableDescheader">बिल नंबर</div>
      <div class="col-2 tableDescheader">बुक नाव</div>
      <div class="col-2 tableDescheader">रक्कम</div>
      <div class="col-2 tableDescheader">क्रेडिट नोट रक्कम</div>
      <div class="col-2 tableDescheader">येणेबाकी</div>
      <div class="col-2 tableDescheader">Remark</div>
      <!-- </div> -->
    </div>
    <div class="row" *ngFor="let item of this.selectedBooks">
      <div class="col-2 tableDesc">
        {{ this.bookcrdtnotedata["crdtnote_no"] }}
      </div>
      <div class="col-2 tableDesc">
        {{ item["book_name"] }}
      </div>
      <div class="col-2 tableDesc">
        {{ item["total"] }}
      </div>
      <div class="col-2 tableDesc">
        {{ item["crdtnote_amt"] }}
      </div>
      <div class="col-2 tableDesc">
        {{ ConvertToInt(item["total"]) - ConvertToInt(item["crdtnote_amt"]) }}
      </div>
      <div class="col-2 tableDesc">
        {{ item["remark"] }}
      </div>
    </div>
    <div class="row">
      <div class="col-4 tableDesc" style="font-weight: bold; font-size: medium">
        Total -
      </div>
      <div class="col-2 tableDesc" style="font-weight: bold; font-size: medium">
        {{ this.totalamount }}
      </div>
      <div class="col-2 tableDesc" style="font-weight: bold; font-size: medium">
        {{ this.crdtnoteamttotal }}
      </div>
      <div class="col-2 tableDesc" style="font-weight: bold; font-size: medium">
        {{ this.totalamount - this.crdtnoteamttotal }}
      </div>
      <div class="col-2 tableDesc"></div>
    </div>
    <!-- </div> -->
    <div class="row" style="margin-top: 3%; padding-bottom: 1%">
      <div
        class="col-12"
        style="text-align: right; font-weight: bold; font-size: 14px"
      >
        <span>व्यवस्थापक / लेखापाल</span>
      </div>
    </div>
  </div>
  <!-- <button
    class="noPrint"
    style="margin-top: 2%"
    (click)="save()"
    mat-raised-button
    color="primary"
  >
    Save
  </button> -->
  <button
    class="noPrint"
    style="margin-top: 2%; margin-left: 10px"
    (click)="print()"
    mat-raised-button
    color="primary"
  >
    Print
  </button>
  <button
    class="noPrint"
    style="margin-top: 2%; margin-left: 10px"
    mat-button
    mat-dialog-close
  >
    Close
  </button>
</div>
