<table  class="table table-bordered">
    <thead>
        <tr>
            <th>Pending Address</th>
        </tr>
    </thead>

    <tbody>
        <div class="row" >

          <ng-container  *ngFor="let data1 of PendingAdd; let i = index">
            <div class="col-md-12" style="text-align: center; font-size: 17px;">
                {{data1.rep_name}}
            </div>
            <div class="col-md-3" style="padding: 40px; font-weight: 40px;"  *ngFor="let data2 of data1.rep_data,; let i1 = index">
                {{data2.name}} ,<br> {{data2.address}} , <br> {{data2.pincode}} , {{data2.country}} , {{data2.state}} , <br>
                {{data2.taluka}} , {{data2.district}} . <br> {{data2.mobileNumber}} , {{data2.telephone}}
            </div>
          </ng-container>


           

        </div>

        <!-- <tr *ngFor="let data1 of PendingAdd; let i = index">
            <td>{{data1.rep_name}}</td>
            <td *ngFor="let data2 of data1.rep_data,; let i1 = index">
                <div>
                    {{data2.name}} ,<br> {{data2.address}} , <br> {{data2.pincode}} , {{data2.country}} , {{data2.state}} , <br> {{data2.taluka}} , {{data2.district}} . <br> 
                    {{data2.mobileNumber}} , {{data2.telephone}}
                </div> <br>
            </td>
        </tr> -->

        <!-- <tr *ngFor="let row of generateRows(PendingAdd, 6)">
            <td *ngFor="let item of row.rep_data">
                <div>
                    {{item.name}} ,<br> {{item.address}} , <br> {{item.pincode}} , {{item.country}} , {{item.state}} , <br> {{item.taluka}} , {{item.district}} . <br> 
                    {{item.mobileNumber}} , {{item.telephone}}
                </div> <br>
            </td>
        </tr> -->

    </tbody>

</table>



  