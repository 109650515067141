import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DigisubservicesService } from 'src/app/services/digisubservices.service';

@Component({
  selector: 'app-update-book-per-price',
  templateUrl: './update-book-per-price.component.html',
  styleUrls: ['./update-book-per-price.component.scss'],
})
export class UpdateBookPerPriceComponent implements OnInit {
  bookreceipt;
  bookdata = [];
  receivedamtform: FormGroup;

  constructor(
    public service: DigisubservicesService,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<UpdateBookPerPriceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.receivedamtform = fb.group({
      receivedamt: [''],
    });
  }

  ngOnInit(): void {
    this.bookreceipt = this.data['book'];
    this.bookdata = this.data['updatedata'];
    // console.log(this.bookdata);
  }

  total_remaining_amt = 0;
  total_val = 0;
  calcamount(event, book) {
    let val = event.target.value;
    this.total_val = 0;
    // console.log({ book });
    let remaining_amt = parseInt(book['total_amount']) - val;
    this.total_remaining_amt = 0;
    for (let index = 0; index < Object.keys(this.bookdata).length; index++) {
      if (this.bookdata[index]['bookid'] == book['bookid']) {
        this.bookdata[index]['rcvd_amt'] = val;
        this.bookdata[index]['remaining_amt'] = remaining_amt;
      }
      this.total_val =
        this.total_val + parseInt(this.bookdata[index]['rcvd_amt']);
      this.total_remaining_amt =
        this.total_remaining_amt +
        parseInt(this.bookdata[index]['remaining_amt']);
    }
  }

  update() {
    this.dialogRef.close({
      data: this.bookdata,
      remaining_amt: this.total_remaining_amt,
      id: this.bookreceipt['id'],
    });
  }
}
