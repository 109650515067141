import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DigisubservicesService } from 'src/app/services/digisubservices.service';
import * as XLSX from 'xlsx';


@Component({
  selector: 'app-creditorsreport',
  templateUrl: './creditorsreport.component.html',
  styleUrls: ['./creditorsreport.component.scss']
})

export class CreditorsreportComponent {
  creditiorsData: any;
  startdate: any;
  enddate: any;
  constructor(
    public service: DigisubservicesService,
    private dialogRef: MatDialogRef<CreditorsreportComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }


  ngOnInit(): void {


    this.startdate = this.data.startDate
    this.enddate = this.data.endDate
    this.service.getCreditorsReport(this.startdate, this.enddate).subscribe((res) => {
      // console.log(res);
      this.creditiorsData = res;
    });
  }

  exportExcel(fileName): void {
    /* table id is passed over here */
    let element = document.getElementById('print-section');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, fileName + '.xlsx');
  }

  calculateNetAmt(data: any): number {
    if (!data || !data.total_amt) {
      return 0; // Ensure valid data
    }

    const cgstTotal = [
      data.cgst1_5, data.cgst2_5, data.cgst3_0,
      data.cgst6_0, data.cgst9_0, data.cgst14_0
    ].reduce((sum, val) => sum + (Number(val) || 0), 0);

    const sgstTotal = [
      data.sgst1_5, data.sgst2_5, data.sgst3_0,
      data.sgst6_0, data.sgst9_0, data.sgst14_0
    ].reduce((sum, val) => sum + (Number(val) || 0), 0);

    const igstTotal = [
      data.igst3, data.igst5, data.igst6,
      data.igst12, data.igst18, data.igst28
    ].reduce((sum, val) => sum + (Number(val) || 0), 0);

    const netAmt = Number(data.total_amt) - (cgstTotal + sgstTotal + igstTotal);

    console.log(`Total Amt: ${data.total_amt}, CGST: ${cgstTotal}, SGST: ${sgstTotal}, IGST: ${igstTotal}, Net Amt: ${netAmt}`);

    return netAmt;
  }

}