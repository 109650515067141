import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DigisubservicesService } from 'src/app/services/digisubservices.service';

@Component({
  selector: 'app-update-district-master',
  templateUrl: './update-district-master.component.html',
  styleUrls: ['./update-district-master.component.scss'],
})
export class UpdateDistrictMasterComponent implements OnInit {
  districtData;
  districtForm: FormGroup;
  prantData;

  constructor(
    private dialogref: MatDialogRef<UpdateDistrictMasterComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private service: DigisubservicesService
  ) {
    this.districtForm = fb.group({
      districtid: [''],
      districtname: [''],
      prant_id: [''],
    });
  }

  ngOnInit(): void {
    this.getAllPrantData();
    this.districtData = this.data['district'];
    // console.log(this.districtData);

    this.districtForm.get('districtid').setValue(this.districtData['id']);
    this.districtForm.get('districtname').setValue(this.districtData['name']);
    if (this.districtData['prant_id'] != null) {
      this.districtForm.get('prant_id').setValue(this.districtData['prant_id']);
    }
  }

  getAllPrantData() {
    this.service.getAllPrantData().subscribe((res) => {
      // console.log(res);
      this.prantData = res;
    });
  }

  updateDistrict() {
    if (this.districtForm.get('districtid').value > 0) {
      this.service
        .updateDistrictName(
          parseInt(this.districtForm.get('districtid').value),
          this.districtForm.get('districtname').value,
          this.districtForm.get('prant_id').value
        )
        .subscribe((data) => {
          alert('District updated successfully...');
          this.dialogref.close({ res: 'close' });
        });
    }
  }
}
