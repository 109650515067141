<div>
  <div>
    <form [formGroup]="returnRcptForm" (ngSubmit)="submit()">
      <div class="form-row">
        <mat-form-field appearance="fill" class="example-full-width"
          style="margin-left: 10%; margin-top: 10%; width: 80%">
          <mat-label>अंक दिनांक</mat-label>
          <input matInput type="date" formControlName="ankdate" />
        </mat-form-field>
      </div>
      <div class="form-row">
        <mat-form-field appearance="fill" class="example-full-width"
          style="margin-left: 10%; margin-top: 10%; width: 80%">
          <mat-label>दिनांक</mat-label>
          <input matInput type="date" formControlName="date" readonly />
        </mat-form-field>
      </div>
      <div class="form-row">
        <mat-form-field appearance="fill" class="example-full-width"
          style="margin-left: 10%; margin-top: 5%; width: 80%">
          <mat-label>कारण</mat-label>
          <textarea matInput type="text" formControlName="reason" required></textarea>
        </mat-form-field>
      </div>
      <div class="form-row">
        <mat-form-field appearance="fill" class="example-full-width"
          style="margin-left: 10%; margin-top: 5%; width: 80%">
          <mat-label>कार्यवाही</mat-label>
          <textarea matInput type="text" formControlName="karyavahi"></textarea>
        </mat-form-field>
      </div>
      <div class="form-group">
        <button [disabled]="returnRcptForm.invalid" type="submit" class="btn btn-primary" style="
            width: 40%;
            margin-left: 30%;
            margin-right: auto;
            margin-top: 5%;
          ">
          Submit
        </button>
      </div>
    </form>
  </div>
</div>