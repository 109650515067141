import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DigisubservicesService } from '../services/digisubservices.service';

@Component({
  selector: 'app-statewisestatement',
  templateUrl: './statewisestatement.component.html',
  styleUrls: ['./statewisestatement.component.scss']
})

export class StatewisestatementComponent {
  filtereddistrictwisesubcout:any
  sundayankDate;
  dispatchankDate;
  type: any;
  state : any
  totalcount: any;
  constructor(
    private dialogref: MatDialogRef<StatewisestatementComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private service: DigisubservicesService
  ) {}

  ngOnInit(): void {
  // this.districtwiseData = this.data['data'];
  this.sundayankDate = this.data['sundaydate'];
  this.dispatchankDate = this.data['dispatchdate'];
  this.type = this.data['type'];
  this.state = this.data.state;
  
  debugger;

  const req:any = {
    "state": this.state,
  }

  this.service.getstatewisecount(req).subscribe((data: any) => {
    console.log("Get statewisedata",data)
      this.filtereddistrictwisesubcout = data as any;  
      this.totalcount = 0;
      for (let data1 of this.filtereddistrictwisesubcout) {
        debugger;
        this.totalcount += parseInt(data1.total_count) || 0;  
           }
  });
 


  }

 

}
