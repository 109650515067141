<div>
    <div style="margin-left: 80%; margin-bottom: 2%">
        <button [useExistingCss]="true" class="noPrint" style="margin-right: 2%; margin-left: 2%" mat-raised-button
            color="primary" printTitle="Pincodewise Statement" printSectionId="print-section" ngxPrint>
            print
        </button>
        <!-- <button mat-raised-button class="btn btn-info noPrint" (click)="print()"
            style="margin-right: 2%; margin-left: 2%">
            Print
        </button> -->
        <button mat-dialog-close class="btn noPrint">Close</button>
    </div>
    <div class="container" id="print-section">
        <div style="margin: 0px 15px;">
            <div class="row"
                style="text-align: center;border-width: 1px 1px 1px 1px; border-style: solid; border-color: black;padding-top: 05px; padding-bottom: 04px;">
                <span style=" font-size: 16px;font-weight: 600;margin: auto;">मा. विवेक, प्रभादेवी - २५ दि
                    {{sundayankDate}}
                    च्या
                    अंकाकरिता
                    पोस्ट स्टेटमेंट
                    वितरण दि {{dispatchankDate}}</span>
            </div>
            <div class="row" style="width: 100%;">
                <div class="col" style="border-width: 0px 1px 1px 1px;">पिनकोड</div>
                <div class="col" style="border-width: 0px 1px 1px 0px;">वर्गणीदार संख्या</div>
                <div class="col" style="border-width: 0px 1px 1px 0px;">पार्सल संख्या</div>
                <div class="col" style="border-width: 0px 1px 1px 0px;">पिनकोड</div>
                <div class="col" style="border-width: 0px 1px 1px 0px;">वर्गणीदार संख्या</div>
                <div class="col" style="border-width: 0px 1px 1px 0px;">पार्सल संख्या</div>
                <div class="col" style="border-width: 0px 1px 1px 0px;">पिनकोड</div>
                <div class="col" style="border-width: 0px 1px 1px 0px;">वर्गणीदार संख्या</div>
                <div class="col" style="border-width: 0px 1px 1px 0px;">पार्सल संख्या</div>
            </div>
            <div class="row" *ngFor="let data of pincodewisesubcoutprinting; let i=index">
                <!-- <div class="col-4" >
                <div class="col" style="border-width: 0px 1px 1px 0px;">{{ data['pincode']}}</div>
                <div class="col" style="border-width: 0px 1px 1px 0px;">{{ data['sub']}}</div>
                <div class="col" style="border-width: 0px 1px 1px 0px;">{{ data['parcel']}}</div>
            </div> *ngFor="let data of filteredpincodewisesubcout; let i=index" -->
                <div class="col" style="border-width: 0px 1px 1px 1px;"><span *ngIf="data['pincode1'] != 0">{{
                        data['pincode1']}}</span></div>
                <div class="col" style="border-width: 0px 1px 1px 0px;">
                    <!-- <span *ngIf="data['sub1'] != 0">{{
                    data['sub1']}}</span> -->
                    {{
                    data['sub1']}}
                </div>
                <div class="col" style="border-width: 0px 1px 1px 0px;"><span *ngIf="data['parcel1'] != 0">{{
                        data['parcel1']}}</span></div>
                <div class="col" style="border-width: 0px 1px 1px 0px;"><span *ngIf="data['pincode2'] != 0">{{
                        data['pincode2']}}</span></div>
                <div class="col" style="border-width: 0px 1px 1px 0px;">
                    <!-- <span *ngIf="data['sub2'] != 0">{{
                    data['sub2']}}</span> -->
                    {{
                    data['sub2']}}
                </div>
                <div class="col" style="border-width: 0px 1px 1px 0px;"><span *ngIf="data['parcel2'] != 0">{{
                        data['parcel2']}}</span></div>
                <div class="col" style="border-width: 0px 1px 1px 0px;"><span *ngIf="data['pincode3'] != 0">{{
                        data['pincode3']}}</span></div>
                <div class="col" style="border-width: 0px 1px 1px 0px;">
                    <!-- <span *ngIf="data['sub3'] != 0">{{
                        data['sub3']}}</span> -->
                    {{
                    data['sub3']}}
                </div>
                <div class="col" style="border-width: 0px 1px 1px 0px;"><span *ngIf="data['parcel3'] != 0">{{
                        data['parcel3']}}</span></div>
            </div>
            <div class="row">
                <div class="col" style="border-width: 0px 1px 1px 1px;"></div>
                <div class="col" style="border-width: 0px 1px 1px 0px;"></div>
                <div class="col" style="border-width: 0px 1px 1px 0px;"></div>
                <div class="col" style="border-width: 0px 1px 1px 0px;"></div>
                <div class="col" style="border-width: 0px 1px 1px 0px;"></div>
                <div class="col" style="border-width: 0px 1px 1px 0px;"></div>
                <div class="col" style="border-width: 0px 1px 1px 0px;">एकूण</div>
                <div class="col" style="border-width: 0px 1px 1px 0px;">{{ total_pin_sub_count }}</div>
                <div class="col" style="border-width: 0px 1px 1px 0px;">{{ totalparcel }}={{ totalanksankhya }}</div>
            </div>
        </div>
    </div>
</div>

