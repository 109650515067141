import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DigisubservicesService } from 'src/app/services/digisubservices.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cancel-rep-dialog',
  templateUrl: './cancel-rep-dialog.component.html',
  styleUrls: ['./cancel-rep-dialog.component.scss']
})
export class CancelRepDialogComponent implements OnInit{

  form : FormGroup
  data2

  constructor(private router : Router, private fb : FormBuilder, public service : DigisubservicesService, 
    private dialogRef : MatDialogRef<CancelRepDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data : any) 
  { 
    this.form = fb.group({
      otp : ['']
    });
  }

  ngOnInit()
  {
    if(sessionStorage.getItem("mobile") == null){
      this.router.navigate(['/']);
    }
    
    let mobile = parseInt(this.data.mobile);
    let resultState = this.service.getRepresentative(mobile);
    resultState.subscribe((data) =>{
      this.data2 = data;
      //console.log("Data from get_representative.php : ",this.data2);
    });
  }

  submitForm()
  {
    if(this.form.get('otp').value == this.data.message)
    {
      let id = this.data2[0].rep_id;
      let resultState = this.service.cancelRepresentative(id);
      resultState.subscribe((data) =>{
        let data2 = data;
        //console.log("Data from cancel_representative.php : ",data2);
      });
    }
    this.dialogRef.close();
    window.alert("Representative Cancelled Successfully.")
  }

}
