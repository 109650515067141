import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DigisubservicesService } from 'src/app/services/digisubservices.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-new-admin-dialog',
  templateUrl: './add-new-admin-dialog.component.html',
  styleUrls: ['./add-new-admin-dialog.component.scss']
})
export class AddNewAdminDialogComponent implements OnInit {

  form : FormGroup;

  constructor(private router : Router, public service : DigisubservicesService, private fb : FormBuilder,
    private dialogRef : MatDialogRef<AddNewAdminDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data : any) {
      this.form = fb.group({
        otp : [''],
        adminMobile : '',
        adminEmail : '',
        adminPassword : '',
        adminid:'',
        adminname:''
      });
     }

  ngOnInit(): void {
    if(sessionStorage.getItem("mobile") == null){
      this.router.navigate(['/']);
    }
    this.form.patchValue({adminMobile : this.data.mobileNumber});
    this.form.patchValue({adminEmail : this.data.email});
    this.form.patchValue({adminPassword : this.data.adPassword})
  }

  submitForm()
  {
    if(this.data.otp == this.form.get("otp").value){
      //console.log("Admin Dialog Form Data : ",this.form.value);

      this.service.getmaxadminid().subscribe(adid=> {
        this.form.get('adminid').setValue(parseInt(adid[0]['maxid']));
        this.form.get('adminname').setValue('office_'+adid[0]['maxid']);


        let resultState = this.service.addNewAdmin(this.form.value);
        resultState.subscribe((data)=>{
          let data1 = data;
          //console.log("",data1);
          this.dialogRef.close();
          window.alert("Admin Added Successfully")
        });


      })
   
  }
  else{
    window.alert("Please, Enter Correct OTP");
  }
  }

}
