<ng-container *ngIf="!isLoading">
  <div>
    <div style="margin-left: 70%; margin-bottom: 2%">
      <button
        [printStyle]="{table : {'border-collapse':'collapse'}, td : {'border': 'solid 1px', 'font-size':'14px', 'font-weight':'600', 'text-align':'center'}, th : {'border': 'solid 1px', 'font-size':'16px', 'font-weight':'600', 'text-align':'center'}, div : {'font-size':'14px', 'font-weight':'600'}, span : {'font-size':'14px', 'font-weight':'600', 'float':'left'}}"
        class="noPrint" style="margin-right: 2%; margin-left: 2%" mat-raised-button color="primary"
        printSectionId="print-section" ngxPrint>
        print
      </button>
      <button class="noPrint" style="margin-top: 2% 2% 0 2%;" (click)="exportExcel('Monthwise_Subscription_Report')"
        mat-raised-button color="primary">
        Export
      </button>
      <button mat-dialog-close class="btn noPrint">
        Close
      </button>
    </div>
  </div>
  <div>
    <div class="container" id="print-section">
      <table class="table table-bordered">
        <thead>
          <tr>
            <th *ngIf="totalth == 11" colspan="07" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
              साप्ताहिक - वर्गणी विभाग
            </th>
            <th *ngIf="totalth == 12" colspan="08" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
              साप्ताहिक - वर्गणी विभाग
            </th>
            <th *ngIf="totalth == 13" colspan="09" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
              साप्ताहिक - वर्गणी विभाग
            </th>
            <th *ngIf="totalth == 14" colspan="10" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
              साप्ताहिक - वर्गणी विभाग
            </th>
            <th *ngIf="totalth == 15" colspan="11" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
              साप्ताहिक - वर्गणी विभाग
            </th>
            <th *ngIf="totalth == 16" colspan="12" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
              साप्ताहिक - वर्गणी विभाग
            </th>
            <th *ngIf="totalth == 17" colspan="13" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
              साप्ताहिक - वर्गणी विभाग
            </th>
            <th *ngIf="totalth == 18" colspan="14" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
              साप्ताहिक - वर्गणी विभाग
            </th>
            <th *ngIf="totalth == 19" colspan="15" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
              साप्ताहिक - वर्गणी विभाग
            </th>
            <th *ngIf="totalth == 20" colspan="16" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
              साप्ताहिक - वर्गणी विभाग
            </th>
            <th *ngIf="totalth == 21" colspan="17" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
              साप्ताहिक - वर्गणी विभाग
            </th>
            <th *ngIf="totalth == 22" colspan="18" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
              साप्ताहिक - वर्गणी विभाग
            </th>
            <th *ngIf="totalth == 23" colspan="19" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
              साप्ताहिक - वर्गणी विभाग
            </th>
            <th *ngIf="totalth == 24" colspan="20" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
              साप्ताहिक - वर्गणी विभाग
            </th>
            <th *ngIf="totalth == 25" colspan="21" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
              साप्ताहिक - वर्गणी विभाग
            </th>
            <th *ngIf="totalth == 26" colspan="22" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
              साप्ताहिक - वर्गणी विभाग
            </th>
            <th *ngIf="totalth == 27" colspan="23" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
              साप्ताहिक - वर्गणी विभाग
            </th>
            <th *ngIf="totalth == 28" colspan="24" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
              साप्ताहिक - वर्गणी विभाग
            </th>
            <th *ngIf="totalth == 29" colspan="25" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
              साप्ताहिक - वर्गणी विभाग
            </th>
            <th *ngIf="totalth == 30" colspan="26" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
              साप्ताहिक - वर्गणी विभाग
            </th>
            <th *ngIf="totalth == 31" colspan="27" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
              साप्ताहिक - वर्गणी विभाग
            </th>
            <th *ngIf="totalth == 32" colspan="28" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
              साप्ताहिक - वर्गणी विभाग
            </th>
            <th *ngIf="totalth == 33" colspan="29" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
              साप्ताहिक - वर्गणी विभाग
            </th>
            <th *ngIf="totalth == 34" colspan="30" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
              साप्ताहिक - वर्गणी विभाग
            </th>
            <th *ngIf="totalth == 35" colspan="31" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
              साप्ताहिक - वर्गणी विभाग
            </th>
            <th *ngIf="totalth == 36" colspan="32" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
              साप्ताहिक - वर्गणी विभाग
            </th>
            <th *ngIf="totalth == 37" colspan="33" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
              साप्ताहिक - वर्गणी विभाग
            </th>
            <th *ngIf="totalth == 38" colspan="34" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
              साप्ताहिक - वर्गणी विभाग
            </th>
            <th *ngIf="totalth == 39" colspan="35" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
              साप्ताहिक - वर्गणी विभाग
            </th>
            <th *ngIf="totalth == 40" colspan="36" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
              साप्ताहिक - वर्गणी विभाग
            </th>
            <th *ngIf="totalth == 41" colspan="37" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
              साप्ताहिक - वर्गणी विभाग
            </th>
            <th *ngIf="totalth == 42" colspan="38" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
              साप्ताहिक - वर्गणी विभाग
            </th>
            <th *ngIf="totalth == 43" colspan="39" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
              साप्ताहिक - वर्गणी विभाग
            </th>
            <th *ngIf="totalth == 44" colspan="40" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
              साप्ताहिक - वर्गणी विभाग
            </th>
            <th *ngIf="totalth == 45" colspan="41" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
              साप्ताहिक - वर्गणी विभाग
            </th>
            <th *ngIf="totalth == 46" colspan="42" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
              साप्ताहिक - वर्गणी विभाग
            </th>
            <th *ngIf="totalth == 47" colspan="43" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
              साप्ताहिक - वर्गणी विभाग
            </th>
            <th *ngIf="totalth == 48" colspan="44" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
              साप्ताहिक - वर्गणी विभाग
            </th>
            <th *ngIf="totalth == 49" colspan="45" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
              साप्ताहिक - वर्गणी विभाग
            </th>
            <th colspan="2">दिनांक -</th>
            <th colspan="2">{{ today | date:'dd-MM-yyyy' }}</th>
          </tr>
          <tr>
            <th *ngIf="totalth == 10" colspan="03" class="fontBold">
              <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण रोजमेळ -</span> महिन्यानुसार
            </th>
            <th *ngIf="totalth == 11" colspan="04" class="fontBold">
              <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण रोजमेळ -</span> महिन्यानुसार
            </th>
            <th *ngIf="totalth == 12" colspan="05" class="fontBold">
              <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण रोजमेळ -</span> महिन्यानुसार
            </th>
            <th *ngIf="totalth == 13" colspan="06" class="fontBold">
              <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण रोजमेळ -</span> महिन्यानुसार
            </th>
            <th *ngIf="totalth == 14" colspan="07" class="fontBold">
              <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण रोजमेळ -</span> महिन्यानुसार
            </th>
            <th *ngIf="totalth == 15" colspan="08" class="fontBold">
              <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण रोजमेळ -</span> महिन्यानुसार
            </th>
            <th *ngIf="totalth == 16" colspan="09" class="fontBold">
              <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण रोजमेळ -</span> महिन्यानुसार
            </th>
            <th *ngIf="totalth == 17" colspan="10" class="fontBold">
              <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण रोजमेळ -</span> महिन्यानुसार
            </th>
            <th *ngIf="totalth == 18" colspan="11" class="fontBold">
              <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण रोजमेळ -</span> महिन्यानुसार
            </th>
            <th *ngIf="totalth == 19" colspan="12" class="fontBold">
              <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण रोजमेळ -</span> महिन्यानुसार
            </th>
            <th *ngIf="totalth == 20" colspan="13" class="fontBold">
              <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण रोजमेळ -</span> महिन्यानुसार
            </th>
            <th *ngIf="totalth == 21" colspan="14" class="fontBold">
              <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण रोजमेळ -</span> महिन्यानुसार
            </th>
            <th *ngIf="totalth == 22" colspan="15" class="fontBold">
              <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण रोजमेळ -</span> महिन्यानुसार
            </th>
            <th *ngIf="totalth == 23" colspan="16" class="fontBold">
              <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण रोजमेळ -</span> महिन्यानुसार
            </th>
            <th *ngIf="totalth == 24" colspan="17" class="fontBold">
              <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण रोजमेळ -</span> महिन्यानुसार
            </th>
            <th *ngIf="totalth == 25" colspan="18" class="fontBold">
              <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण रोजमेळ -</span> महिन्यानुसार
            </th>
            <th *ngIf="totalth == 26" colspan="19" class="fontBold">
              <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण रोजमेळ -</span> महिन्यानुसार
            </th>
            <th *ngIf="totalth == 27" colspan="20" class="fontBold">
              <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण रोजमेळ -</span> महिन्यानुसार
            </th>
            <th *ngIf="totalth == 28" colspan="21" class="fontBold">
              <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण रोजमेळ -</span> महिन्यानुसार
            </th>
            <th *ngIf="totalth == 29" colspan="22" class="fontBold">
              <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण रोजमेळ -</span> महिन्यानुसार
            </th>
            <th *ngIf="totalth == 30" colspan="23" class="fontBold">
              <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण रोजमेळ -</span> महिन्यानुसार
            </th>
            <th *ngIf="totalth == 31" colspan="24" class="fontBold">
              <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण रोजमेळ -</span> महिन्यानुसार
            </th>
            <th *ngIf="totalth == 32" colspan="25" class="fontBold">
              <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण रोजमेळ -</span> महिन्यानुसार
            </th>
            <th *ngIf="totalth == 33" colspan="26" class="fontBold">
              <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण रोजमेळ -</span> महिन्यानुसार
            </th>
            <th *ngIf="totalth == 34" colspan="27" class="fontBold">
              <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण रोजमेळ -</span> महिन्यानुसार
            </th>
            <th *ngIf="totalth == 35" colspan="28" class="fontBold">
              <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण रोजमेळ -</span> महिन्यानुसार
            </th>
            <th *ngIf="totalth == 36" colspan="29" class="fontBold">
              <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण रोजमेळ -</span> महिन्यानुसार
            </th>
            <th *ngIf="totalth == 37" colspan="30" class="fontBold">
              <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण रोजमेळ -</span> महिन्यानुसार
            </th>
            <th *ngIf="totalth == 38" colspan="31" class="fontBold">
              <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण रोजमेळ -</span> महिन्यानुसार
            </th>
            <th *ngIf="totalth == 39" colspan="32" class="fontBold">
              <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण रोजमेळ -</span> महिन्यानुसार
            </th>
            <th *ngIf="totalth == 40" colspan="33" class="fontBold">
              <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण रोजमेळ -</span> महिन्यानुसार
            </th>
            <th *ngIf="totalth == 41" colspan="34" class="fontBold">
              <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण रोजमेळ -</span> महिन्यानुसार
            </th>
            <th *ngIf="totalth == 42" colspan="35" class="fontBold">
              <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण रोजमेळ -</span> महिन्यानुसार
            </th>
            <th *ngIf="totalth == 43" colspan="36" class="fontBold">
              <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण रोजमेळ -</span> महिन्यानुसार
            </th>
            <th *ngIf="totalth == 44" colspan="37" class="fontBold">
              <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण रोजमेळ -</span> महिन्यानुसार
            </th>
            <th *ngIf="totalth == 45" colspan="38" class="fontBold">
              <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण रोजमेळ -</span> महिन्यानुसार
            </th>
            <th *ngIf="totalth == 46" colspan="39" class="fontBold">
              <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण रोजमेळ -</span> महिन्यानुसार
            </th>
            <th *ngIf="totalth == 47" colspan="40" class="fontBold">
              <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण रोजमेळ -</span> महिन्यानुसार
            </th>
            <th *ngIf="totalth == 48" colspan="41" class="fontBold">
              <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण रोजमेळ -</span> महिन्यानुसार
            </th>
            <th *ngIf="totalth == 49" colspan="42" class="fontBold">
              <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण रोजमेळ -</span> महिन्यानुसार
            </th>
            <th colspan="2">दिनांक -</th>
            <th colspan="2">{{ startDate | date:'dd-MM-yyyy' }}</th>
            <th colspan="1">पर्यंत</th>
            <th colspan="2">{{ endDate | date:'dd-MM-yyyy' }}</th>
          </tr>
          <tr>
            <th rowspan="2">महिना</th>
            <th colspan="3">एकूण</th>
            <ng-container *ngFor="let data of subTypesName">
              <th *ngIf="data.isVisible" colspan="2">{{ data.name }}</th>
              <th *ngIf="data.isVisible" rowspan="2">रक्कम रुपये</th>
            </ng-container>
          </tr>
          <tr>
            <th>रक्कम रुपये</th>
            <th>नवीन</th>
            <th>नूतनीकरण</th>
            <ng-container *ngFor="let data of subTypesName">
              <th *ngIf="data.isVisible">नवीन</th>
              <th *ngIf="data.isVisible" rowspan="2">नूतनीकरण</th>
            </ng-container>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let item of finalData; let end=last;">
            <tr *ngIf="!end">
              <td>{{ item['crdt_voucher_date'] | date:'MMMM' }}</td>
              <td>{{ item['एकूण'] }}</td>
              <td>{{ item['नवीन'] }}</td>
              <td>{{ item['नूतनीकरण'] }}</td>
              <ng-container *ngFor="let sub of subTypesName">
                <ng-container *ngIf="sub.isVisible">
                  <td>{{ item[getKey(sub.name)+ '_नवीन'] }}</td>
                  <td>{{ item[getKey(sub.name)+ '_नूतनीकरण'] }}</td>
                  <td>{{ item[getKey(sub.name)+ '_एकूण'] }}</td>
                </ng-container>
              </ng-container>
            </tr>
            <tr class="smallHeading" *ngIf="end">
              <td>{{ item['name'] }}</td>
              <td>{{ item['एकूण'] }}</td>
              <td>{{ item['नवीन'] }}</td>
              <td>{{ item['नूतनीकरण'] }}</td>
              <ng-container *ngFor="let sub of subTypesName">
                <ng-container *ngIf="sub.isVisible">
                  <td>{{ item[getKey(sub.name)+ '_नवीन'] }}</td>
                  <td>{{ item[getKey(sub.name)+ '_नूतनीकरण'] }}</td>
                  <td>{{ item[getKey(sub.name)+ '_एकूण'] }}</td>
                </ng-container>
              </ng-container>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>
  <!-- <div id="print-section">
    <div class="container" style="
        border-width: 0px 0px 1px 0px;
        border-color: black;
        border-style: solid;
      ">
      <div class="row" style="
          border-width: 1px 1px 0px 1px;
          border-color: black;
          border-style: solid;
          text-align: center;
        ">
        <div class="col-8" style="padding: 3px; font-size: 14px; font-weight: 600">
          हिंदुस्थान प्रकाशन संस्था - विवेक साप्तहिक - वर्गणी विभाग
        </div>
        <div class="col-1" style="
            border-width: 0px 0px 0px 1px;
            border-color: black;
            border-style: solid;
            padding: 1px;
          ">
          दिनांक -
        </div>
        <div class="col-1" style="
            border-width: 0px 0px 0px 1px;
            border-color: black;
            border-style: solid;
            padding: 1px;
          ">
          {{ today }}
        </div>
        <div class="col-2" style="
            border-width: 0px 0px 0px 1px;
            border-color: black;
            border-style: solid;
            padding: 1px;
          "></div>
      </div>
      <div class="row" style="
          border-width: 1px 1px 0px 1px;
          border-color: black;
          border-style: solid;
        ">
        <div class="col-8" style="padding: 1px; font-size: 14px; font-weight: 600">
          <span style="text-align: left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण रोजमेळ -</span>
          <span style="margin-left: 50px">महिन्यानुसार</span>
        </div>
        <div class="col-1" style="
            border-width: 0px 0px 0px 1px;
            border-color: black;
            border-style: solid;
            padding: 1px;
            text-align: center;
          ">
          दिनांक - पासून
        </div>
        <div class="col-1" style="
            border-width: 0px 0px 0px 1px;
            border-color: black;
            border-style: solid;
            padding: 1px;
            text-align: center;
          ">
          {{ startdate }}
        </div>
        <div class="col-1" style="
            border-width: 0px 0px 0px 1px;
            border-color: black;
            border-style: solid;
            padding: 1px;
            text-align: center;
          ">
          पर्यंत
        </div>
        <div class="col-1" style="
            border-width: 0px 0px 0px 1px;
            border-color: black;
            border-style: solid;
            padding: 1px;
            text-align: center;
          ">
          {{ enddate }}
        </div>
      </div>
      <div class="row">
        <table class="table table-bordered" style="
            border-width: 1px 1px 0px 1px;
            border-color: black;
            border-style: solid;
            margin-bottom: -02px;
          ">
          <thead>
            <tr>
              <th style="
                  border-width: 0px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                  width: 90px;
                ">
                महिना
              </th>

              <th colspan="3" style="
                  border-width: 0px 1px 1px 0px;
                  border-color: black;
                  border-style: solid;
                  width: 150px;
                ">
                एकूण
              </th>
              <th colspan="2" style="
                  border-width: 0px 1px 1px 0px;
                  border-color: black;
                  border-style: solid;
                  width: 100px;
                ">
                वार्षिक
              </th>
              <th style="
                  border-width: 0px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                  width: 50px;
                ">
                रक्कम रुपये
              </th>
              <th colspan="2" style="
                  border-width: 0px 1px 1px 0px;
                  border-color: black;
                  border-style: solid;
                  width: 100px;
                ">
                त्रै वार्षिक
              </th>
              <th style="
                  border-width: 0px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                  width: 50px;
                ">
                रक्कम रुपये
              </th>
              <th colspan="2" style="
                  border-width: 0px 1px 1px 0px;
                  border-color: black;
                  border-style: solid;
                  width: 100px;
                ">
                पंच वार्षिक
              </th>
              <th style="
                  border-width: 0px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                  width: 50px;
                ">
                रक्कम रुपये
              </th>
              <th colspan="2" style="
                  border-width: 0px 1px 1px 0px;
                  border-color: black;
                  border-style: solid;
                  width: 100px;
                ">
                सहयोगी
              </th>
              <th style="
                  border-width: 0px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                  width: 50px;
                ">
                रक्कम रुपये
              </th>
              <th style="
                  border-width: 0px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                  width: 50px;
                ">
                अनामत
              </th>
              <th style="
                  border-width: 0px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                  width: 50px;
                ">
                पंचठेव
              </th>
              <th style="
                  border-width: 0px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                  width: 50px;
                ">
                इतर जमा
              </th>
            </tr>
            <tr>
              <th rowspan="2" style="
                  border-width: 0px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                "></th>
              <th style="
                  border-width: 0px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                ">
                रक्कम रुपये
              </th>
              <th style="
                  border-width: 0px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                ">
                नवीन
              </th>
              <th style="
                  border-width: 0px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                ">
                नूतनी करण
              </th>
              <th style="
                  border-width: 0px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                ">
                नवीन
              </th>
              <th style="
                  border-width: 0px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                ">
                नूतनी करण
              </th>
              <th rowspan="2" style="
                  border-width: 0px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                "></th>
              <th style="
                  border-width: 0px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                ">
                नवीन
              </th>
              <th style="
                  border-width: 0px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                ">
                नूतनी करण
              </th>
              <th rowspan="2" style="
                  border-width: 0px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                "></th>
              <th style="
                  border-width: 0px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                ">
                नवीन
              </th>
              <th style="
                  border-width: 0px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                ">
                नूतनी करण
              </th>
              <th rowspan="2" style="
                  border-width: 0px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                "></th>
              <th style="
                  border-width: 0px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                ">
                नवीन
              </th>
              <th style="
                  border-width: 0px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                ">
                नूतनी करण
              </th>
              <th rowspan="2" style="
                  border-width: 0px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                "></th>
              <th rowspan="2" style="
                  border-width: 0px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                "></th>
              <th rowspan="2" style="
                  border-width: 0px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                "></th>
              <th rowspan="2" style="
                  border-width: 0px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                "></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let val of tempdata; let s = index">
              <td style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                ">
                {{ val["entry_date"] | date: "MMMM" }}
              </td>
              <td style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                ">
                {{ val["atotal"] }}
              </td>
              <td style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                ">
                {{ val["anew"] }}
              </td>
              <td style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                ">
                {{ val["arenew"] }}
              </td>
              <td style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                ">
                {{ val["vnew"] }}
              </td>
              <td style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                ">
                {{ val["vrenew"] }}
              </td>
              <td style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                ">
                {{ val["vtotal"] }}
              </td>
              <td style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                ">
                {{ val["tnew"] }}
              </td>
              <td style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                ">
                {{ val["trenew"] }}
              </td>
              <td style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                ">
                {{ val["ttotal"] }}
              </td>
              <td style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                ">
                {{ val["pnew"] }}
              </td>
              <td style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                ">
                {{ val["prenew"] }}
              </td>
              <td style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                ">
                {{ val["ptotal"] }}
              </td>
              <td style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                ">
                {{ val["snew"] }}
              </td>
              <td style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                ">
                {{ val["srenew"] }}
              </td>
              <td style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                ">
                {{ val["stotal"] }}
              </td>
              <td style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                ">
                {{ val["anamat"] }}
              </td>
              <td style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                ">
                {{ val["punchthev"] }}
              </td>
              <td style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                ">
                {{ val["other"] }}
              </td>
            </tr>
            <tr>
              <td style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                ">
                एकूण
              </td>
              <td style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                ">
                {{ atotal_total }}
              </td>
              <td style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                ">
                {{ anew_total }}
              </td>
              <td style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                ">
                {{ arenew_total }}
              </td>
              <td style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                ">
                {{ vnew_total }}
              </td>
              <td style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                ">
                {{ vrenew_total }}
              </td>
              <td style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                ">
                {{ vtotal_total }}
              </td>
              <td style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                ">
                {{ tnew_total }}
              </td>
              <td style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                ">
                {{ trenew_total }}
              </td>
              <td style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                ">
                {{ ttotal_total }}
              </td>
              <td style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                ">
                {{ pnew_total }}
              </td>
              <td style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                ">
                {{ prenew_total }}
              </td>
              <td style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                ">
                {{ ptotal_total }}
              </td>
              <td style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                ">
                {{ snew_total }}
              </td>
              <td style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                ">
                {{ srenew_total }}
              </td>
              <td style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                ">
                {{ stotal_total }}
              </td>
              <td style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                ">
                {{ anamat_total }}
              </td>
              <td style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                ">
                {{ punchthev_total }}
              </td>
              <td style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                ">
                {{ other_total }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <button [useExistingCss]="true" class="noPrint" style="margin-top: 2%" mat-raised-button color="primary"
      printSectionId="print-section" ngxPrint>
      print
    </button>
    <button class="noPrint" style="margin-top: 2%; margin-left: 2%" mat-raised-button color="info" mat-dialog-close>
      Close
    </button>
  </div> -->
</ng-container>
<div *ngIf="isLoading" class="main-loading">
  <span class="loader"></span>
  <p>Loading...</p>
</div>