import { formatDate } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DigisubservicesService } from 'src/app/services/digisubservices.service';
import { ToWords } from 'to-words';

@Component({
  selector: 'app-bookstorereceipt',
  templateUrl: './bookstorereceipt.component.html',
  styleUrls: ['./bookstorereceipt.component.scss'],
})
export class BookstorereceiptComponent implements OnInit {
  bookdata;
  date = formatDate(new Date(), 'yyyy-MM-dd', 'en-IN', 'GMT+0530');
  receiptno;
  billno;
  value = 0;
  booksellername;
  day = new Date();
  data;
  bookpymtrcptdata;
  bookpricedata;
  toWords;
  totalinWords: String;
  rcvdAmt = 0;


  constructor(
    public service: DigisubservicesService,
    private dialogRef: MatDialogRef<BookstorereceiptComponent>,
    @Inject(MAT_DIALOG_DATA) public dataa: any
  ) {
    // console.log('bookstore data', this.dataa);
  }

  ngOnInit(): void {
    this.data = this.dataa['data'];
    // console.log('data', this.data);
    this.bookpymtrcptdata = this.dataa['bookpymtrcptdata'];
    this.bookpricedata = this.dataa['bookpricedata'];
    // console.log('bookpricedata', this.bookpricedata);
    // console.log('bookpymtrecptdata', this.bookpymtrcptdata);
    this.billno = this.dataa['billno'];
    let month = ('0' + (this.day.getMonth() + 1)).slice(-2);
    let year = this.day.getFullYear().toString().slice(-2);
    let nextyear = (this.day.getFullYear() + 1).toString().slice(-2);
    this.receiptno = this.data['recptno'];
    this.bookdata = this.dataa['book'];
    // console.log('voucherdata', this.bookdata);
    this.booksellername = this.dataa['booksellername'];
    this.value = this.dataa['val'];
    
    // console.log('books receipt', this.bookdata);

    for (
      let index = 0;
      index < Object.keys(this.bookpricedata).length;
      index++
    ) {
      for (
        let index1 = 0;
        index1 < Object.keys(this.bookpricedata[index]).length;
        index1++
      ) {
        this.rcvdAmt =
          this.rcvdAmt +
          parseInt(this.bookpricedata[index][index1]['rcvd_amt']);
      }
    }
    console.log('rcvdAmt', this.rcvdAmt);
    this.toWords = new ToWords();
    this.totalinWords = this.toWords.convert(this.dataa['val'], {
      currency: true,
    });
  }

  print() {
    window.print();
  }

  save() {
    this.service.insertBookPymtRcpt(this.data).subscribe((output) => {
      console.log('bookpymtrcpt id', output);
      // for (
      //   let index = 0;
      //   index < Object.keys(this.bookpymtrcptdata).length;
      //   index++
      // ) {
      //   this.service
      //     .insertBookPymtRcptandInvoicedata(
      //       output['id'],
      //       this.bookpymtrcptdata[index]['id']
      //     )
      //     .subscribe((data) => {});
      // }

      this.service
        .updatecreditvoucher(
          this.bookdata['id'],
          this.data['crdtvno'],
          this.data['balamt']
        )
        .subscribe((data) => {
          // console.log('updated data', data);
        });

      for (
        let index = 0;
        index < Object.keys(this.bookpymtrcptdata).length;
        index++
      ) {
        if (this.bookpymtrcptdata[index]['invoice_no'].includes('BDO')) {
          this.service
            .updatebookinvoice(
              this.bookpymtrcptdata[index]['id'],
              this.bookpymtrcptdata[index]['invoice_no'],
              this.bookpymtrcptdata[index]['remaining_amt'],
              'bookdata'
            )
            .subscribe((data) => {
              // console.log('update book receipt', data);
            });
        } else {
          this.service
            .updatebookinvoice(
              this.bookpymtrcptdata[index]['id'],
              this.bookpymtrcptdata[index]['invoice_no'],
              this.bookpymtrcptdata[index]['remaining_amt'],
              'bookinvoice'
            )
            .subscribe((data) => {
              // console.log('update book receipt', data);
            });
        }
        this.service
          .insertBookPymtRcptandInvoicedata(
            output['id'],
            this.bookpymtrcptdata[index]['id']
          )
          .subscribe((data) => {});
      }

      for (
        let index = 0;
        index < Object.keys(this.bookpricedata).length;
        index++
      ) {
        for (
          let index1 = 0;
          index1 < Object.keys(this.bookpricedata[index]).length;
          index1++
        ) {
          if (this.bookpricedata[index][index1]['invoice_no'].includes('BDO')) {
            this.service
              .updateRcptWiseBooks1(
                this.bookpricedata[index][index1]['bookid'],
                this.bookpricedata[index][index1]['rcvd_amt'],
                this.bookpricedata[index][index1]['remaining_amt']
              )
              .subscribe((data) => {
                console.log(data);
                // alert('Books price added successfully...');
                // this.dialogRef.close();
              });
          } else {
            this.service
              .updateRcptWiseBooks(
                this.bookpricedata[index][index1]['id'],
                this.bookpricedata[index][index1]['bookid'],
                this.bookpricedata[index][index1]['book_name'],
                this.bookpricedata[index][index1]['rcvd_amt'],
                this.bookpricedata[index][index1]['remaining_amt']
              )
              .subscribe((data) => {
                console.log(data);
                // this.dialogRef.close();
              });
          }
        }
      }

      setTimeout(() => {
        alert('Book payment receipt generated successfully...');
        location.reload();
        this.dialogRef.close();
      }, 1200);
    });
  }
}
