import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DigisubservicesService } from 'src/app/services/digisubservices.service';
import { FileUploader } from 'ng2-file-upload';

@Component({
  selector: 'app-view-book',
  templateUrl: './view-book.component.html',
  styleUrls: ['./view-book.component.scss'],
})
export class ViewBookComponent implements OnInit {
  BookForm: FormGroup;
  isupcoming = false;
  displayDiscount = false;
  imgPath: string;
  bookimgpath = 'https://vivekweb.in/VivekApplication/uploads/';

  constructor(
    private fb: FormBuilder,
    public service: DigisubservicesService,
    private dialogRef: MatDialogRef<ViewBookComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.BookForm = this.fb.group({
      bookname: this.data.book['bookname'],
      bookdesc: this.data.book['bookdesc'],
      price: this.data.book['price'],
      saleprice: this.data.book['saleprice'],
      book_img: this.data.book['bookimg'],
      authorname: this.data.book['author'],
      bookpages: this.data.book['book_pages'],
      booklang: this.data.book['language'],
      bookyear: this.data.book['year'],
      isdelivery: this.data.book['deliveryIncluded'],
      isupcoming: this.data.book['isupcoming'],
      upcomingdate: this.data.book['upcomingdate'],
      bookid: this.data.book['bookid'],
      discountupto: this.data.book['discountupto'],
      bookcategory: this.data.book['bookcategory'],
    });

    this.bookimgpath =
      'https://vivekweb.in/VivekApplication/uploads/' +
      this.data.book['bookimg'];
    console.log('bookimgpath', this.bookimgpath);

    if (this.data.book['isupcoming'] == 'Y') {
      this.isupcoming = true;
    } else {
      this.isupcoming = false;
    }

    if (parseInt(this.data.book['saleprice']) > 0) {
      this.displayDiscount = true;
    } else {
      this.displayDiscount = false;
    }
  }

  save() {
    setTimeout(() => {
      console.log(this.BookForm.value);
      this.service.updateBook(this.BookForm).subscribe((up) => {
        window.alert(
          'Book details has been added. Please try to refresh the page.'
        );
        this.dialogRef.close();
      });

      console.log(this.BookForm.value);
      this.service.updateBook(this.BookForm).subscribe((up) => {
        window.alert(
          'Book details has been added. Please try to refresh the page.'
        );
        this.dialogRef.close();
      });
    }, 5000);
  }

  getSearchTerm(event) {
    if (event.target.value == 'Y') {
      this.isupcoming = true;
    } else {
      this.isupcoming = false;
    }
  }

  getSalePrice(event) {
    if (parseInt(event.target.value) > 0) {
      this.displayDiscount = true;
    } else {
      this.displayDiscount = false;
    }
  }
}
