<ng-container *ngIf="!isLoading">
    <div>
        <div style="margin-left: 70%;  margin-bottom: 2%">
            <button
                [printStyle]="{table : {'border-collapse':'collapse'}, td : {'border': 'solid 1px', 'font-size':'12px', 'font-weight':'400', 'text-align':'center'}, th : {'border': 'solid 1px', 'font-size':'15px', 'font-weight':'600', 'text-align':'center'}, div : {'font-size':'14px', 'font-weight':'600'}, span : {'font-size':'14px', 'font-weight':'600', 'float':'left'}}"
                class="noPrint" style="margin-right: 2%; margin-left: 2%" mat-raised-button color="primary"
                printSectionId="print-section" ngxPrint>
                print
            </button>
            <button class="noPrint" style="margin-top: 2% 2% 0 2%;"
                (click)="exportExcel('Datewise_Subscription_Report')" mat-raised-button color="primary">
                Export
            </button>
            <button mat-dialog-close class="btn noPrint">
                Close
            </button>
        </div>
    </div>
    <div *ngIf="datewisedata == 'first'">
        <div class="container" id="print-section">
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th *ngIf="totalth == 17" colspan="10" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                            साप्ताहिक - वर्गणी विभाग
                        </th>
                        <th *ngIf="totalth == 18" colspan="11" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                            साप्ताहिक - वर्गणी विभाग
                        </th>
                        <th *ngIf="totalth == 19" colspan="12" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                            साप्ताहिक - वर्गणी विभाग
                        </th>
                        <th *ngIf="totalth == 20" colspan="13" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                            साप्ताहिक - वर्गणी विभाग
                        </th>
                        <th *ngIf="totalth == 21" colspan="14" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                            साप्ताहिक - वर्गणी विभाग
                        </th>
                        <th *ngIf="totalth == 22" colspan="15" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                            साप्ताहिक - वर्गणी विभाग
                        </th>
                        <th *ngIf="totalth == 23" colspan="16" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                            साप्ताहिक - वर्गणी विभाग
                        </th>
                        <th *ngIf="totalth == 24" colspan="17" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                            साप्ताहिक - वर्गणी विभाग
                        </th>
                        <th *ngIf="totalth == 25" colspan="18" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                            साप्ताहिक - वर्गणी विभाग
                        </th>
                        <th *ngIf="totalth == 26" colspan="19" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                            साप्ताहिक - वर्गणी विभाग
                        </th>
                        <th *ngIf="totalth == 27" colspan="20" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                            साप्ताहिक - वर्गणी विभाग
                        </th>
                        <th *ngIf="totalth == 28" colspan="21" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                            साप्ताहिक - वर्गणी विभाग
                        </th>
                        <th *ngIf="totalth == 29" colspan="22" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                            साप्ताहिक - वर्गणी विभाग
                        </th>
                        <th *ngIf="totalth == 30" colspan="23" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                            साप्ताहिक - वर्गणी विभाग
                        </th>
                        <th *ngIf="totalth == 31" colspan="24" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                            साप्ताहिक - वर्गणी विभाग
                        </th>
                        <th *ngIf="totalth == 32" colspan="25" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                            साप्ताहिक - वर्गणी विभाग
                        </th>
                        <th *ngIf="totalth == 33" colspan="26" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                            साप्ताहिक - वर्गणी विभाग
                        </th>
                        <th *ngIf="totalth == 34" colspan="27" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                            साप्ताहिक - वर्गणी विभाग
                        </th>
                        <th *ngIf="totalth == 35" colspan="28" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                            साप्ताहिक - वर्गणी विभाग
                        </th>
                        <th *ngIf="totalth == 36" colspan="29" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                            साप्ताहिक - वर्गणी विभाग
                        </th>
                        <th *ngIf="totalth == 37" colspan="30" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                            साप्ताहिक - वर्गणी विभाग
                        </th>
                        <th *ngIf="totalth == 38" colspan="31" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                            साप्ताहिक - वर्गणी विभाग
                        </th>
                        <th *ngIf="totalth == 39" colspan="32" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                            साप्ताहिक - वर्गणी विभाग
                        </th>
                        <th *ngIf="totalth == 40" colspan="33" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                            साप्ताहिक - वर्गणी विभाग
                        </th>
                        <th *ngIf="totalth == 41" colspan="34" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                            साप्ताहिक - वर्गणी विभाग
                        </th>
                        <th *ngIf="totalth == 42" colspan="35" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                            साप्ताहिक - वर्गणी विभाग
                        </th>
                        <th *ngIf="totalth == 43" colspan="36" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                            साप्ताहिक - वर्गणी विभाग
                        </th>
                        <th *ngIf="totalth == 44" colspan="37" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                            साप्ताहिक - वर्गणी विभाग
                        </th>
                        <th *ngIf="totalth == 45" colspan="38" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                            साप्ताहिक - वर्गणी विभाग
                        </th>
                        <th *ngIf="totalth == 46" colspan="39" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                            साप्ताहिक - वर्गणी विभाग
                        </th>
                        <th *ngIf="totalth == 47" colspan="40" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                            साप्ताहिक - वर्गणी विभाग
                        </th>
                        <th *ngIf="totalth == 48" colspan="41" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                            साप्ताहिक - वर्गणी विभाग
                        </th>
                        <th *ngIf="totalth == 49" colspan="42" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                            साप्ताहिक - वर्गणी विभाग
                        </th>
                        <th colspan="2">दिनांक -</th>
                        <th colspan="2">{{ today | date:'dd-MM-yyyy' }}</th>
                        <th colspan="3"></th>
                    </tr>
                    <tr>
                        <th *ngIf="totalth == 18" colspan="11" class="fontBold">
                            <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण  -</span> दिनांकानुसार /
                            प्रतिनिधीनुसार
                        </th>
                        <th *ngIf="totalth == 19" colspan="12" class="fontBold">
                            <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण  -</span> दिनांकानुसार /
                            प्रतिनिधीनुसार
                        </th>
                        <th *ngIf="totalth == 20" colspan="13" class="fontBold">
                            <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण  -</span> दिनांकानुसार /
                            प्रतिनिधीनुसार
                        </th>
                        <th *ngIf="totalth == 21" colspan="14" class="fontBold">
                            <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण  -</span> दिनांकानुसार /
                            प्रतिनिधीनुसार
                        </th>
                        <th *ngIf="totalth == 22" colspan="15" class="fontBold">
                            <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण  -</span> दिनांकानुसार /
                            प्रतिनिधीनुसार
                        </th>
                        <th *ngIf="totalth == 23" colspan="16" class="fontBold">
                            <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण  -</span> दिनांकानुसार /
                            प्रतिनिधीनुसार
                        </th>
                        <th *ngIf="totalth == 24" colspan="17" class="fontBold">
                            <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण  -</span> दिनांकानुसार /
                            प्रतिनिधीनुसार
                        </th>
                        <th *ngIf="totalth == 25" colspan="18" class="fontBold">
                            <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण  -</span> दिनांकानुसार /
                            प्रतिनिधीनुसार
                        </th>
                        <th *ngIf="totalth == 26" colspan="19" class="fontBold">
                            <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण  -</span> दिनांकानुसार /
                            प्रतिनिधीनुसार
                        </th>
                        <th *ngIf="totalth == 27" colspan="20" class="fontBold">
                            <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण  -</span> दिनांकानुसार /
                            प्रतिनिधीनुसार
                        </th>
                        <th *ngIf="totalth == 28" colspan="21" class="fontBold">
                            <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण  -</span> दिनांकानुसार /
                            प्रतिनिधीनुसार
                        </th>
                        <th *ngIf="totalth == 29" colspan="22" class="fontBold">
                            <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण  -</span> दिनांकानुसार /
                            प्रतिनिधीनुसार
                        </th>
                        <th *ngIf="totalth == 30" colspan="23" class="fontBold">
                            <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण  -</span> दिनांकानुसार /
                            प्रतिनिधीनुसार
                        </th>
                        <th *ngIf="totalth == 31" colspan="24" class="fontBold">
                            <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण  -</span> दिनांकानुसार /
                            प्रतिनिधीनुसार
                        </th>
                        <th *ngIf="totalth == 32" colspan="25" class="fontBold">
                            <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण  -</span> दिनांकानुसार /
                            प्रतिनिधीनुसार
                        </th>
                        <th *ngIf="totalth == 33" colspan="26" class="fontBold">
                            <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण  -</span> दिनांकानुसार /
                            प्रतिनिधीनुसार
                        </th>
                        <th *ngIf="totalth == 34" colspan="27" class="fontBold">
                            <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण  -</span> दिनांकानुसार /
                            प्रतिनिधीनुसार
                        </th>
                        <th *ngIf="totalth == 35" colspan="28" class="fontBold">
                            <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण  -</span> दिनांकानुसार /
                            प्रतिनिधीनुसार
                        </th>
                        <th *ngIf="totalth == 36" colspan="29" class="fontBold">
                            <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण  -</span> दिनांकानुसार /
                            प्रतिनिधीनुसार
                        </th>
                        <th *ngIf="totalth == 37" colspan="30" class="fontBold">
                            <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण  -</span> दिनांकानुसार /
                            प्रतिनिधीनुसार
                        </th>
                        <th *ngIf="totalth == 38" colspan="31" class="fontBold">
                            <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण  -</span> दिनांकानुसार /
                            प्रतिनिधीनुसार
                        </th>
                        <th *ngIf="totalth == 39" colspan="32" class="fontBold">
                            <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण  -</span> दिनांकानुसार /
                            प्रतिनिधीनुसार
                        </th>
                        <th *ngIf="totalth == 40" colspan="33" class="fontBold">
                            <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण  -</span> दिनांकानुसार /
                            प्रतिनिधीनुसार
                        </th>
                        <th *ngIf="totalth == 41" colspan="34" class="fontBold">
                            <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण  -</span> दिनांकानुसार /
                            प्रतिनिधीनुसार
                        </th>
                        <th *ngIf="totalth == 42" colspan="35" class="fontBold">
                            <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण  -</span> दिनांकानुसार /
                            प्रतिनिधीनुसार
                        </th>
                        <th *ngIf="totalth == 43" colspan="36" class="fontBold">
                            <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण  -</span> दिनांकानुसार /
                            प्रतिनिधीनुसार
                        </th>
                        <th *ngIf="totalth == 44" colspan="37" class="fontBold">
                            <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण  -</span> दिनांकानुसार /
                            प्रतिनिधीनुसार
                        </th>
                        <th *ngIf="totalth == 45" colspan="38" class="fontBold">
                            <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण  -</span> दिनांकानुसार /
                            प्रतिनिधीनुसार
                        </th>
                        <th *ngIf="totalth == 46" colspan="39" class="fontBold">
                            <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण  -</span> दिनांकानुसार /
                            प्रतिनिधीनुसार
                        </th>
                        <th *ngIf="totalth == 47" colspan="40" class="fontBold">
                            <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण  -</span> दिनांकानुसार /
                            प्रतिनिधीनुसार
                        </th>
                        <th *ngIf="totalth == 48" colspan="41" class="fontBold">
                            <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण  -</span> दिनांकानुसार /
                            प्रतिनिधीनुसार
                        </th>
                        <th *ngIf="totalth == 49" colspan="42" class="fontBold">
                            <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण  -</span> दिनांकानुसार /
                            प्रतिनिधीनुसार
                        </th>
                        <th colspan="2">दिनांक -</th>
                        <th colspan="2">{{ startDate | date:'dd-MM-yyyy' }}</th>
                        <th colspan="1">पर्यंत</th>
                        <th colspan="2">{{ endDate | date:'dd-MM-yyyy' }}</th>
                    </tr>
                    <tr>
                        <th rowspan="2">पावती क्र</th>
                        <th rowspan="2">वर्गणीदाराचे नाव</th>
                        <th rowspan="2">तालुका</th>
                        <th rowspan="2">जिल्हा</th>
                        <th rowspan="2">प्रतिनिधींचे नाव</th>
                        <th rowspan="2">कार्यालय प्रतिनिधींचे नाव</th>
                        <th colspan="3">एकूण</th>
                        <ng-container *ngFor="let data of subTypesName">
                            <th *ngIf="data.isVisible" colspan="2">{{ data.name }}</th>
                            <th *ngIf="data.isVisible" rowspan="2">रक्कम रुपये</th>
                        </ng-container>
                        <!-- <th colspan="2">वार्षिक</th>
                        <th rowspan="2">रक्कम रुपये</th>
                        <th colspan="2">त्रैवार्षिक</th>
                        <th rowspan="2">रक्कम रुपये</th>
                        <th colspan="2">पंचवार्षिक</th>
                        <th rowspan="2">रक्कम रुपये</th>
                        <th colspan="2">सहयोगी</th>
                        <th rowspan="2">रक्कम रुपये</th> -->
                        <!-- <th rowspan="2">अनामत</th>
                        <th rowspan="2">पंचठेव</th>
                        <th rowspan="2">इतर जमा</th> -->
                    </tr>
                    <tr>
                        <th>रक्कम रुपये</th>
                        <th>नवीन</th>
                        <th>नूतनीकरण</th>
                        <ng-container *ngFor="let data of subTypesName">
                            <th *ngIf="data.isVisible">नवीन</th>
                            <th *ngIf="data.isVisible" rowspan="2">नूतनीकरण</th>
                        </ng-container>
                        <!-- <th>नवीन</th>
                        <th>नूतनीकरण</th>
                        <th>नवीन</th>
                        <th>नूतनीकरण</th>
                        <th>नवीन</th>
                        <th>नूतनीकरण</th>
                        <th>नवीन</th>
                        <th>नूतनीकरण</th> -->
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let item of finalData; let end=last;">
                        <ng-container *ngIf="!end">
                            <ng-container *ngFor="let data of item; let end1=last;">
                                <tr *ngIf="!end1">
                                    <td>{{ data['rcpt_no'] }}</td>
                                    <td>{{ data['name'] }}</td>
                                    <td>{{ data['taluka'] }}</td>
                                    <td>{{ data['district'] }}</td>
                                    <td>{{ data['rep_name'] }}</td>
                                    <td>{{ data['office_rep_name'] }}</td>
                                    <td>{{ data['total_amt'] }}</td>
                                    <td>
                                        <!-- <span *ngIf="data['rcpt_type'] == ''">1</span> -->
                                        <span *ngIf="data['rcpt_type'] == 'New';else other_content">1</span>
                                        <ng-template #other_content>0</ng-template>
                                    </td>
                                    <td>
                                        <span *ngIf="data['rcpt_type'] == 'Renew';else other_content">1</span>
                                        <ng-template #other_content>0</ng-template>
                                    </td>
                                    <ng-container *ngFor="let sub of subTypesName">
                                        <ng-container *ngIf="sub['isVisible']">
                                            <td>
                                                
                                                <span
                                                    *ngIf="data['subscription_type'] == sub['name'] && data['rcpt_type'] == 'New';else other_content">
                                                <ng-container *ngIf="(sub['name']=='वार्षिक' || sub['name']=='रा.स्व.संघ ग्रंथ') && data['total_amt']=='1000.00' ">1</ng-container>
                                                <ng-container *ngIf="sub['name']=='त्रैवार्षिक' && data['total_amt']=='2500.00'">1</ng-container>
                                                <ng-container *ngIf="sub['name']=='पंचवार्षिक' && data['total_amt']=='4000.00'">1</ng-container>
                                                </span>
                                                <ng-template #other_content>0</ng-template>
                                            </td>
                                            <td>
                                                <span
                                                    *ngIf="data['subscription_type'] == sub['name'] && data['rcpt_type'] == 'Renew';else other_content">
                                                    <ng-container *ngIf="(sub['name']=='वार्षिक' || sub['name']=='रा.स्व.संघ ग्रंथ') && data['total_amt']=='1000.00'">1</ng-container>
                                                    <ng-container *ngIf="sub['name']=='त्रैवार्षिक' && data['total_amt']=='2500.00'">1</ng-container>
                                                    <ng-container *ngIf="sub['name']=='पंचवार्षिक' && data['total_amt']=='4000.00' ">1</ng-container></span>
                                                <ng-template #other_content>0</ng-template>
                                            </td>
                                            <td>
                                                <span
                                                    *ngIf="data['subscription_type'] == sub['name'];else other_content">{{
                                                    data.total_amt
                                                    }}</span>
                                                <span *ngIf="data['subscription_type'] != sub['name']">0</span>
                                                <ng-template #other_content>0</ng-template>
                                            </td>
                                        </ng-container>
                                    </ng-container>
                                </tr>
                                <!-- <tr *ngIf="end1">
                                    <td>{{ data['entry_date'] | date:'dd/MM/yyyy' }}</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>{{ data['एकूण'] }}</td>
                                    <td>{{ data['नवीन'] }}</td>
                                    <td>{{ data['नूतनीकरण'] }}</td>
                                    <ng-container *ngFor="let sub of subTypesName">
                                        <ng-container *ngIf="sub.isVisible">
                                            <td>{{ data[getKey(sub.name)+ '_नवीन'] }}</td>
                                            <td>{{ data[getKey(sub.name)+ '_नूतनीकरण'] }}</td>
                                            <td>{{ data[getKey(sub.name)+ '_एकूण'] }}</td>
                                        </ng-container>
                                    </ng-container>
                                </tr> -->
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="end">
                            <tr class="smallHeading">
                                <td colspan="3">{{ item['name'] }}</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>{{ item['एकूण'] }}</td>
                                <td>{{ item['नवीन'] }}</td>
                                <td>{{ item['नूतनीकरण'] }}</td>
                                <ng-container *ngFor="let sub of subTypesName">
                                    <ng-container *ngIf="sub.isVisible">
                                        <td>{{ item[getKey(sub.name)+ '_नवीन'] }}</td>
                                        <td>{{ item[getKey(sub.name)+ '_नूतनीकरण'] }}</td>
                                        <td>{{ item[getKey(sub.name)+ '_एकूण'] }}</td>
                                    </ng-container>
                                </ng-container>
                            </tr>
                        </ng-container>
                        <tr></tr>
                    </ng-container>
                </tbody>
            </table>
        </div>
    </div>

    <!-- second -->

    <div *ngIf="datewisedata == 'active'">
        <div class="container" id="print-section">
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th *ngIf="totalth == 17" colspan="10" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                            साप्ताहिक - वर्गणी विभाग - second
                        </th>
                        <th *ngIf="totalth == 18" colspan="11" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                            साप्ताहिक - वर्गणी विभाग
                        </th>
                        <th *ngIf="totalth == 19" colspan="12" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                            साप्ताहिक - वर्गणी विभाग
                        </th>
                        <th *ngIf="totalth == 20" colspan="13" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                            साप्ताहिक - वर्गणी विभाग
                        </th>
                        <th *ngIf="totalth == 21" colspan="14" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                            साप्ताहिक - वर्गणी विभाग
                        </th>
                        <th *ngIf="totalth == 22" colspan="15" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                            साप्ताहिक - वर्गणी विभाग
                        </th>
                        <th *ngIf="totalth == 23" colspan="16" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                            साप्ताहिक - वर्गणी विभाग
                        </th>
                        <th *ngIf="totalth == 24" colspan="17" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                            साप्ताहिक - वर्गणी विभाग
                        </th>
                        <th *ngIf="totalth == 25" colspan="18" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                            साप्ताहिक - वर्गणी विभाग
                        </th>
                        <th *ngIf="totalth == 26" colspan="19" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                            साप्ताहिक - वर्गणी विभाग
                        </th>
                        <th *ngIf="totalth == 27" colspan="20" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                            साप्ताहिक - वर्गणी विभाग
                        </th>
                        <th *ngIf="totalth == 28" colspan="21" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                            साप्ताहिक - वर्गणी विभाग
                        </th>
                        <th *ngIf="totalth == 29" colspan="22" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                            साप्ताहिक - वर्गणी विभाग
                        </th>
                        <th *ngIf="totalth == 30" colspan="23" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                            साप्ताहिक - वर्गणी विभाग
                        </th>
                        <th *ngIf="totalth == 31" colspan="24" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                            साप्ताहिक - वर्गणी विभाग
                        </th>
                        <th *ngIf="totalth == 32" colspan="25" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                            साप्ताहिक - वर्गणी विभाग
                        </th>
                        <th *ngIf="totalth == 33" colspan="26" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                            साप्ताहिक - वर्गणी विभाग
                        </th>
                        <th *ngIf="totalth == 34" colspan="27" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                            साप्ताहिक - वर्गणी विभाग
                        </th>
                        <th *ngIf="totalth == 35" colspan="28" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                            साप्ताहिक - वर्गणी विभाग
                        </th>
                        <th *ngIf="totalth == 36" colspan="29" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                            साप्ताहिक - वर्गणी विभाग
                        </th>
                        <th *ngIf="totalth == 37" colspan="30" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                            साप्ताहिक - वर्गणी विभाग
                        </th>
                        <th *ngIf="totalth == 38" colspan="31" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                            साप्ताहिक - वर्गणी विभाग
                        </th>
                        <th *ngIf="totalth == 39" colspan="32" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                            साप्ताहिक - वर्गणी विभाग
                        </th>
                        <th *ngIf="totalth == 40" colspan="33" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                            साप्ताहिक - वर्गणी विभाग
                        </th>
                        <th *ngIf="totalth == 41" colspan="34" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                            साप्ताहिक - वर्गणी विभाग
                        </th>
                        <th *ngIf="totalth == 42" colspan="35" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                            साप्ताहिक - वर्गणी विभाग
                        </th>
                        <th *ngIf="totalth == 43" colspan="36" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                            साप्ताहिक - वर्गणी विभाग
                        </th>
                        <th *ngIf="totalth == 44" colspan="37" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                            साप्ताहिक - वर्गणी विभाग
                        </th>
                        <th *ngIf="totalth == 45" colspan="38" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                            साप्ताहिक - वर्गणी विभाग
                        </th>
                        <th *ngIf="totalth == 46" colspan="39" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                            साप्ताहिक - वर्गणी विभाग
                        </th>
                        <th *ngIf="totalth == 47" colspan="40" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                            साप्ताहिक - वर्गणी विभाग
                        </th>
                        <th *ngIf="totalth == 48" colspan="41" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                            साप्ताहिक - वर्गणी विभाग
                        </th>
                        <th *ngIf="totalth == 49" colspan="42" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                            साप्ताहिक - वर्गणी विभाग
                        </th>
                        <th colspan="2">दिनांक -</th>
                        <th colspan="2">{{ today | date:'dd-MM-yyyy' }}</th>
                        <th colspan="3"></th>
                    </tr>
                    <tr>
                        <th *ngIf="totalth == 18" colspan="11" class="fontBold">
                            <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण  -</span> दिनांकानुसार /
                            प्रतिनिधीनुसार
                        </th>
                        <th *ngIf="totalth == 19" colspan="12" class="fontBold">
                            <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण  -</span> दिनांकानुसार /
                            प्रतिनिधीनुसार
                        </th>
                        <th *ngIf="totalth == 20" colspan="13" class="fontBold">
                            <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण  -</span> दिनांकानुसार /
                            प्रतिनिधीनुसार
                        </th>
                        <th *ngIf="totalth == 21" colspan="14" class="fontBold">
                            <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण  -</span> दिनांकानुसार /
                            प्रतिनिधीनुसार
                        </th>
                        <th *ngIf="totalth == 22" colspan="15" class="fontBold">
                            <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण  -</span> दिनांकानुसार /
                            प्रतिनिधीनुसार
                        </th>
                        <th *ngIf="totalth == 23" colspan="16" class="fontBold">
                            <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण  -</span> दिनांकानुसार /
                            प्रतिनिधीनुसार
                        </th>
                        <th *ngIf="totalth == 24" colspan="17" class="fontBold">
                            <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण  -</span> दिनांकानुसार /
                            प्रतिनिधीनुसार
                        </th>
                        <th *ngIf="totalth == 25" colspan="18" class="fontBold">
                            <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण  -</span> दिनांकानुसार /
                            प्रतिनिधीनुसार
                        </th>
                        <th *ngIf="totalth == 26" colspan="19" class="fontBold">
                            <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण  -</span> दिनांकानुसार /
                            प्रतिनिधीनुसार
                        </th>
                        <th *ngIf="totalth == 27" colspan="20" class="fontBold">
                            <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण  -</span> दिनांकानुसार /
                            प्रतिनिधीनुसार
                        </th>
                        <th *ngIf="totalth == 28" colspan="21" class="fontBold">
                            <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण  -</span> दिनांकानुसार /
                            प्रतिनिधीनुसार
                        </th>
                        <th *ngIf="totalth == 29" colspan="22" class="fontBold">
                            <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण  -</span> दिनांकानुसार /
                            प्रतिनिधीनुसार
                        </th>
                        <th *ngIf="totalth == 30" colspan="23" class="fontBold">
                            <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण  -</span> दिनांकानुसार /
                            प्रतिनिधीनुसार
                        </th>
                        <th *ngIf="totalth == 31" colspan="24" class="fontBold">
                            <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण  -</span> दिनांकानुसार /
                            प्रतिनिधीनुसार
                        </th>
                        <th *ngIf="totalth == 32" colspan="25" class="fontBold">
                            <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण  -</span> दिनांकानुसार /
                            प्रतिनिधीनुसार
                        </th>
                        <th *ngIf="totalth == 33" colspan="26" class="fontBold">
                            <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण  -</span> दिनांकानुसार /
                            प्रतिनिधीनुसार
                        </th>
                        <th *ngIf="totalth == 34" colspan="27" class="fontBold">
                            <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण  -</span> दिनांकानुसार /
                            प्रतिनिधीनुसार
                        </th>
                        <th *ngIf="totalth == 35" colspan="28" class="fontBold">
                            <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण  -</span> दिनांकानुसार /
                            प्रतिनिधीनुसार
                        </th>
                        <th *ngIf="totalth == 36" colspan="29" class="fontBold">
                            <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण  -</span> दिनांकानुसार /
                            प्रतिनिधीनुसार
                        </th>
                        <th *ngIf="totalth == 37" colspan="30" class="fontBold">
                            <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण  -</span> दिनांकानुसार /
                            प्रतिनिधीनुसार
                        </th>
                        <th *ngIf="totalth == 38" colspan="31" class="fontBold">
                            <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण  -</span> दिनांकानुसार /
                            प्रतिनिधीनुसार
                        </th>
                        <th *ngIf="totalth == 39" colspan="32" class="fontBold">
                            <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण  -</span> दिनांकानुसार /
                            प्रतिनिधीनुसार
                        </th>
                        <th *ngIf="totalth == 40" colspan="33" class="fontBold">
                            <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण  -</span> दिनांकानुसार /
                            प्रतिनिधीनुसार
                        </th>
                        <th *ngIf="totalth == 41" colspan="34" class="fontBold">
                            <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण  -</span> दिनांकानुसार /
                            प्रतिनिधीनुसार
                        </th>
                        <th *ngIf="totalth == 42" colspan="35" class="fontBold">
                            <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण  -</span> दिनांकानुसार /
                            प्रतिनिधीनुसार
                        </th>
                        <th *ngIf="totalth == 43" colspan="36" class="fontBold">
                            <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण  -</span> दिनांकानुसार /
                            प्रतिनिधीनुसार
                        </th>
                        <th *ngIf="totalth == 44" colspan="37" class="fontBold">
                            <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण  -</span> दिनांकानुसार /
                            प्रतिनिधीनुसार
                        </th>
                        <th *ngIf="totalth == 45" colspan="38" class="fontBold">
                            <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण  -</span> दिनांकानुसार /
                            प्रतिनिधीनुसार
                        </th>
                        <th *ngIf="totalth == 46" colspan="39" class="fontBold">
                            <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण  -</span> दिनांकानुसार /
                            प्रतिनिधीनुसार
                        </th>
                        <th *ngIf="totalth == 47" colspan="40" class="fontBold">
                            <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण  -</span> दिनांकानुसार /
                            प्रतिनिधीनुसार
                        </th>
                        <th *ngIf="totalth == 48" colspan="41" class="fontBold">
                            <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण  -</span> दिनांकानुसार /
                            प्रतिनिधीनुसार
                        </th>
                        <th *ngIf="totalth == 49" colspan="42" class="fontBold">
                            <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण  -</span> दिनांकानुसार /
                            प्रतिनिधीनुसार
                        </th>
                        <th colspan="2">दिनांक -</th>
                        <th colspan="2">{{ startDate | date:'dd-MM-yyyy' }} to {{ endDate | date:'dd-MM-yyyy' }}</th>
                        <th colspan="1">पर्यंत</th>
                        <th colspan="2">{{datewisedata}}</th>
                    </tr>
                    <tr>
                        <th rowspan="2">पावती क्र</th>
                        <th rowspan="2">वर्गणीदाराचे नाव</th>
                        <th rowspan="2">तालुका</th>
                        <th rowspan="2">जिल्हा</th>
                        <th rowspan="2">प्रतिनिधींचे नाव</th>
                        <th rowspan="2">कार्यालय प्रतिनिधींचे नाव</th>
                        <th colspan="3">एकूण</th>
                        <ng-container *ngFor="let data of subTypesName">
                            <th *ngIf="data.isVisible" colspan="2">{{ data.name }}</th>
                            <th *ngIf="data.isVisible" rowspan="2">रक्कम रुपये</th>
                        </ng-container>
                        <!-- <th colspan="2">वार्षिक</th>
                        <th rowspan="2">रक्कम रुपये</th>
                        <th colspan="2">त्रैवार्षिक</th>
                        <th rowspan="2">रक्कम रुपये</th>
                        <th colspan="2">पंचवार्षिक</th>
                        <th rowspan="2">रक्कम रुपये</th>
                        <th colspan="2">सहयोगी</th>
                        <th rowspan="2">रक्कम रुपये</th> -->
                        <!-- <th rowspan="2">अनामत</th>
                        <th rowspan="2">पंचठेव</th>
                        <th rowspan="2">इतर जमा</th> -->
                    </tr>
                    <tr>
                        <th>रक्कम रुपये</th>
                        <th>नवीन</th>
                        <th>नूतनीकरण</th>
                        <ng-container *ngFor="let data of subTypesName">
                            <th *ngIf="data.isVisible">नवीन</th>
                            <th *ngIf="data.isVisible" rowspan="2">नूतनीकरण</th>
                        </ng-container>
                        <!-- <th>नवीन</th>
                        <th>नूतनीकरण</th>
                        <th>नवीन</th>
                        <th>नूतनीकरण</th>
                        <th>नवीन</th>
                        <th>नूतनीकरण</th>
                        <th>नवीन</th>
                        <th>नूतनीकरण</th> -->
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let item of finalData; let end=last;">
                        <ng-container *ngIf="!end">
                            <ng-container *ngFor="let data of item; let end1=last;">
                                <tr *ngIf="!end1">
                                    <td>{{ data['rcpt_no'] }}</td>
                                    <td>{{ data['name'] }}</td>
                                    <td>{{ data['taluka'] }}</td>
                                    <td>{{ data['district'] }}</td>
                                    <td>{{ data['rep_name'] }}</td>
                                    <td>{{ data['office_rep_name'] }}</td>
                                    <td>{{ data['total_amt'] }}</td>
                                    <td>
                                        <!-- <span *ngIf="data['rcpt_type'] == ''">1</span> -->
                                        <span *ngIf="data['rcpt_type'] == 'New';else other_content">1</span>
                                        <ng-template #other_content>0</ng-template>
                                    </td>
                                    <td>
                                        <span *ngIf="data['rcpt_type'] == 'Renew';else other_content">1</span>
                                        <ng-template #other_content>0</ng-template>
                                    </td>
                                    <ng-container *ngFor="let sub of subTypesName">
                                        <ng-container *ngIf="sub['isVisible']">
                                            <td>
                                                
                                                <span
                                                    *ngIf="data['subscription_type'] == sub['name'] && data['rcpt_type'] == 'New';else other_content">
                                                <ng-container *ngIf="(sub['name']=='वार्षिक' || sub['name']=='रा.स्व.संघ ग्रंथ') && data['total_amt']=='1000.00' ">1</ng-container>
                                                <ng-container *ngIf="sub['name']=='त्रैवार्षिक' && data['total_amt']=='2500.00'">1</ng-container>
                                                <ng-container *ngIf="sub['name']=='पंचवार्षिक' && data['total_amt']=='4000.00'">1</ng-container>
                                                </span>
                                                <ng-template #other_content>0</ng-template>
                                            </td>
                                            <td>
                                                <span
                                                    *ngIf="data['subscription_type'] == sub['name'] && data['rcpt_type'] == 'Renew';else other_content">
                                                    <ng-container *ngIf="(sub['name']=='वार्षिक' || sub['name']=='रा.स्व.संघ ग्रंथ') && data['total_amt']=='1000.00'">1</ng-container>
                                                    <ng-container *ngIf="sub['name']=='त्रैवार्षिक' && data['total_amt']=='2500.00'">1</ng-container>
                                                    <ng-container *ngIf="sub['name']=='पंचवार्षिक' && data['total_amt']=='4000.00' ">1</ng-container></span>
                                                <ng-template #other_content>0</ng-template>
                                            </td>
                                            <td>
                                                <span
                                                    *ngIf="data['subscription_type'] == sub['name'];else other_content">{{
                                                    data.total_amt
                                                    }}</span>
                                                <span *ngIf="data['subscription_type'] != sub['name']">0</span>
                                                <ng-template #other_content>0</ng-template>
                                            </td>
                                        </ng-container>
                                    </ng-container>
                                </tr>
                                <!-- <tr *ngIf="end1">
                                    <td>{{ data['entry_date'] | date:'dd/MM/yyyy' }}</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>{{ data['एकूण'] }}</td>
                                    <td>{{ data['नवीन'] }}</td>
                                    <td>{{ data['नूतनीकरण'] }}</td>
                                    <ng-container *ngFor="let sub of subTypesName">
                                        <ng-container *ngIf="sub.isVisible">
                                            <td>{{ data[getKey(sub.name)+ '_नवीन'] }}</td>
                                            <td>{{ data[getKey(sub.name)+ '_नूतनीकरण'] }}</td>
                                            <td>{{ data[getKey(sub.name)+ '_एकूण'] }}</td>
                                        </ng-container>
                                    </ng-container>
                                </tr> -->
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="end">
                            <tr class="smallHeading">
                                <td colspan="3">{{ item['name'] }}</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>{{ item['एकूण'] }}</td>
                                <td>{{ item['नवीन'] }}</td>
                                <td>{{ item['नूतनीकरण'] }}</td>
                                <ng-container *ngFor="let sub of subTypesName">
                                    <ng-container *ngIf="sub.isVisible">
                                        <td>{{ item[getKey(sub.name)+ '_नवीन'] }}</td>
                                        <td>{{ item[getKey(sub.name)+ '_नूतनीकरण'] }}</td>
                                        <td>{{ item[getKey(sub.name)+ '_एकूण'] }}</td>
                                    </ng-container>
                                </ng-container>
                            </tr>
                        </ng-container>
                        <tr></tr>
                    </ng-container>
                </tbody>
            </table>
        </div>
    </div>

    
    <div *ngIf="datewisedata == 'inactive'">
        <div class="container" id="print-section">
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th *ngIf="totalth == 17" colspan="10" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                            साप्ताहिक - वर्गणी विभाग - second
                        </th>
                        <th *ngIf="totalth == 18" colspan="11" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                            साप्ताहिक - वर्गणी विभाग
                        </th>
                        <th *ngIf="totalth == 19" colspan="12" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                            साप्ताहिक - वर्गणी विभाग
                        </th>
                        <th *ngIf="totalth == 20" colspan="13" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                            साप्ताहिक - वर्गणी विभाग
                        </th>
                        <th *ngIf="totalth == 21" colspan="14" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                            साप्ताहिक - वर्गणी विभाग
                        </th>
                        <th *ngIf="totalth == 22" colspan="15" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                            साप्ताहिक - वर्गणी विभाग
                        </th>
                        <th *ngIf="totalth == 23" colspan="16" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                            साप्ताहिक - वर्गणी विभाग
                        </th>
                        <th *ngIf="totalth == 24" colspan="17" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                            साप्ताहिक - वर्गणी विभाग
                        </th>
                        <th *ngIf="totalth == 25" colspan="18" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                            साप्ताहिक - वर्गणी विभाग
                        </th>
                        <th *ngIf="totalth == 26" colspan="19" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                            साप्ताहिक - वर्गणी विभाग
                        </th>
                        <th *ngIf="totalth == 27" colspan="20" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                            साप्ताहिक - वर्गणी विभाग
                        </th>
                        <th *ngIf="totalth == 28" colspan="21" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                            साप्ताहिक - वर्गणी विभाग
                        </th>
                        <th *ngIf="totalth == 29" colspan="22" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                            साप्ताहिक - वर्गणी विभाग
                        </th>
                        <th *ngIf="totalth == 30" colspan="23" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                            साप्ताहिक - वर्गणी विभाग
                        </th>
                        <th *ngIf="totalth == 31" colspan="24" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                            साप्ताहिक - वर्गणी विभाग
                        </th>
                        <th *ngIf="totalth == 32" colspan="25" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                            साप्ताहिक - वर्गणी विभाग
                        </th>
                        <th *ngIf="totalth == 33" colspan="26" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                            साप्ताहिक - वर्गणी विभाग
                        </th>
                        <th *ngIf="totalth == 34" colspan="27" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                            साप्ताहिक - वर्गणी विभाग
                        </th>
                        <th *ngIf="totalth == 35" colspan="28" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                            साप्ताहिक - वर्गणी विभाग
                        </th>
                        <th *ngIf="totalth == 36" colspan="29" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                            साप्ताहिक - वर्गणी विभाग
                        </th>
                        <th *ngIf="totalth == 37" colspan="30" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                            साप्ताहिक - वर्गणी विभाग
                        </th>
                        <th *ngIf="totalth == 38" colspan="31" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                            साप्ताहिक - वर्गणी विभाग
                        </th>
                        <th *ngIf="totalth == 39" colspan="32" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                            साप्ताहिक - वर्गणी विभाग
                        </th>
                        <th *ngIf="totalth == 40" colspan="33" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                            साप्ताहिक - वर्गणी विभाग
                        </th>
                        <th *ngIf="totalth == 41" colspan="34" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                            साप्ताहिक - वर्गणी विभाग
                        </th>
                        <th *ngIf="totalth == 42" colspan="35" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                            साप्ताहिक - वर्गणी विभाग
                        </th>
                        <th *ngIf="totalth == 43" colspan="36" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                            साप्ताहिक - वर्गणी विभाग
                        </th>
                        <th *ngIf="totalth == 44" colspan="37" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                            साप्ताहिक - वर्गणी विभाग
                        </th>
                        <th *ngIf="totalth == 45" colspan="38" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                            साप्ताहिक - वर्गणी विभाग
                        </th>
                        <th *ngIf="totalth == 46" colspan="39" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                            साप्ताहिक - वर्गणी विभाग
                        </th>
                        <th *ngIf="totalth == 47" colspan="40" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                            साप्ताहिक - वर्गणी विभाग
                        </th>
                        <th *ngIf="totalth == 48" colspan="41" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                            साप्ताहिक - वर्गणी विभाग
                        </th>
                        <th *ngIf="totalth == 49" colspan="42" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
                            साप्ताहिक - वर्गणी विभाग
                        </th>
                        <th colspan="2">दिनांक -</th>
                        <th colspan="2">{{ today | date:'dd-MM-yyyy' }}</th>
                        <th colspan="3"></th>
                    </tr>
                    <tr>
                        <th *ngIf="totalth == 18" colspan="11" class="fontBold">
                            <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण  -</span> दिनांकानुसार /
                            प्रतिनिधीनुसार
                        </th>
                        <th *ngIf="totalth == 19" colspan="12" class="fontBold">
                            <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण  -</span> दिनांकानुसार /
                            प्रतिनिधीनुसार
                        </th>
                        <th *ngIf="totalth == 20" colspan="13" class="fontBold">
                            <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण  -</span> दिनांकानुसार /
                            प्रतिनिधीनुसार
                        </th>
                        <th *ngIf="totalth == 21" colspan="14" class="fontBold">
                            <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण  -</span> दिनांकानुसार /
                            प्रतिनिधीनुसार
                        </th>
                        <th *ngIf="totalth == 22" colspan="15" class="fontBold">
                            <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण  -</span> दिनांकानुसार /
                            प्रतिनिधीनुसार
                        </th>
                        <th *ngIf="totalth == 23" colspan="16" class="fontBold">
                            <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण  -</span> दिनांकानुसार /
                            प्रतिनिधीनुसार
                        </th>
                        <th *ngIf="totalth == 24" colspan="17" class="fontBold">
                            <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण  -</span> दिनांकानुसार /
                            प्रतिनिधीनुसार
                        </th>
                        <th *ngIf="totalth == 25" colspan="18" class="fontBold">
                            <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण  -</span> दिनांकानुसार /
                            प्रतिनिधीनुसार
                        </th>
                        <th *ngIf="totalth == 26" colspan="19" class="fontBold">
                            <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण  -</span> दिनांकानुसार /
                            प्रतिनिधीनुसार
                        </th>
                        <th *ngIf="totalth == 27" colspan="20" class="fontBold">
                            <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण  -</span> दिनांकानुसार /
                            प्रतिनिधीनुसार
                        </th>
                        <th *ngIf="totalth == 28" colspan="21" class="fontBold">
                            <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण  -</span> दिनांकानुसार /
                            प्रतिनिधीनुसार
                        </th>
                        <th *ngIf="totalth == 29" colspan="22" class="fontBold">
                            <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण  -</span> दिनांकानुसार /
                            प्रतिनिधीनुसार
                        </th>
                        <th *ngIf="totalth == 30" colspan="23" class="fontBold">
                            <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण  -</span> दिनांकानुसार /
                            प्रतिनिधीनुसार
                        </th>
                        <th *ngIf="totalth == 31" colspan="24" class="fontBold">
                            <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण  -</span> दिनांकानुसार /
                            प्रतिनिधीनुसार
                        </th>
                        <th *ngIf="totalth == 32" colspan="25" class="fontBold">
                            <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण  -</span> दिनांकानुसार /
                            प्रतिनिधीनुसार
                        </th>
                        <th *ngIf="totalth == 33" colspan="26" class="fontBold">
                            <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण  -</span> दिनांकानुसार /
                            प्रतिनिधीनुसार
                        </th>
                        <th *ngIf="totalth == 34" colspan="27" class="fontBold">
                            <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण  -</span> दिनांकानुसार /
                            प्रतिनिधीनुसार
                        </th>
                        <th *ngIf="totalth == 35" colspan="28" class="fontBold">
                            <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण  -</span> दिनांकानुसार /
                            प्रतिनिधीनुसार
                        </th>
                        <th *ngIf="totalth == 36" colspan="29" class="fontBold">
                            <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण  -</span> दिनांकानुसार /
                            प्रतिनिधीनुसार
                        </th>
                        <th *ngIf="totalth == 37" colspan="30" class="fontBold">
                            <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण  -</span> दिनांकानुसार /
                            प्रतिनिधीनुसार
                        </th>
                        <th *ngIf="totalth == 38" colspan="31" class="fontBold">
                            <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण  -</span> दिनांकानुसार /
                            प्रतिनिधीनुसार
                        </th>
                        <th *ngIf="totalth == 39" colspan="32" class="fontBold">
                            <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण  -</span> दिनांकानुसार /
                            प्रतिनिधीनुसार
                        </th>
                        <th *ngIf="totalth == 40" colspan="33" class="fontBold">
                            <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण  -</span> दिनांकानुसार /
                            प्रतिनिधीनुसार
                        </th>
                        <th *ngIf="totalth == 41" colspan="34" class="fontBold">
                            <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण  -</span> दिनांकानुसार /
                            प्रतिनिधीनुसार
                        </th>
                        <th *ngIf="totalth == 42" colspan="35" class="fontBold">
                            <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण  -</span> दिनांकानुसार /
                            प्रतिनिधीनुसार
                        </th>
                        <th *ngIf="totalth == 43" colspan="36" class="fontBold">
                            <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण  -</span> दिनांकानुसार /
                            प्रतिनिधीनुसार
                        </th>
                        <th *ngIf="totalth == 44" colspan="37" class="fontBold">
                            <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण  -</span> दिनांकानुसार /
                            प्रतिनिधीनुसार
                        </th>
                        <th *ngIf="totalth == 45" colspan="38" class="fontBold">
                            <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण  -</span> दिनांकानुसार /
                            प्रतिनिधीनुसार
                        </th>
                        <th *ngIf="totalth == 46" colspan="39" class="fontBold">
                            <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण  -</span> दिनांकानुसार /
                            प्रतिनिधीनुसार
                        </th>
                        <th *ngIf="totalth == 47" colspan="40" class="fontBold">
                            <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण  -</span> दिनांकानुसार /
                            प्रतिनिधीनुसार
                        </th>
                        <th *ngIf="totalth == 48" colspan="41" class="fontBold">
                            <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण  -</span> दिनांकानुसार /
                            प्रतिनिधीनुसार
                        </th>
                        <th *ngIf="totalth == 49" colspan="42" class="fontBold">
                            <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण  -</span> दिनांकानुसार /
                            प्रतिनिधीनुसार
                        </th>
                        <th colspan="2">दिनांक -</th>
                        <th colspan="2">{{ startDate | date:'dd-MM-yyyy' }} to {{ endDate | date:'dd-MM-yyyy' }}</th>
                        <th colspan="1">पर्यंत</th>
                        <th colspan="2">{{datewisedata}}</th>
                    </tr>
                    <tr>
                        <th rowspan="2">पावती क्र</th>
                        <th rowspan="2">वर्गणीदाराचे नाव</th>
                        <th rowspan="2">तालुका</th>
                        <th rowspan="2">जिल्हा</th>
                        <th rowspan="2">प्रतिनिधींचे नाव</th>
                        <th rowspan="2">कार्यालय प्रतिनिधींचे नाव</th>
                        <th colspan="3">एकूण</th>
                        <ng-container *ngFor="let data of subTypesName">
                            <th *ngIf="data.isVisible" colspan="2">{{ data.name }}</th>
                            <th *ngIf="data.isVisible" rowspan="2">रक्कम रुपये</th>
                        </ng-container>
                        <!-- <th colspan="2">वार्षिक</th>
                        <th rowspan="2">रक्कम रुपये</th>
                        <th colspan="2">त्रैवार्षिक</th>
                        <th rowspan="2">रक्कम रुपये</th>
                        <th colspan="2">पंचवार्षिक</th>
                        <th rowspan="2">रक्कम रुपये</th>
                        <th colspan="2">सहयोगी</th>
                        <th rowspan="2">रक्कम रुपये</th> -->
                        <!-- <th rowspan="2">अनामत</th>
                        <th rowspan="2">पंचठेव</th>
                        <th rowspan="2">इतर जमा</th> -->
                    </tr>
                    <tr>
                        <th>रक्कम रुपये</th>
                        <th>नवीन</th>
                        <th>नूतनीकरण</th>
                        <ng-container *ngFor="let data of subTypesName">
                            <th *ngIf="data.isVisible">नवीन</th>
                            <th *ngIf="data.isVisible" rowspan="2">नूतनीकरण</th>
                        </ng-container>
                        <!-- <th>नवीन</th>
                        <th>नूतनीकरण</th>
                        <th>नवीन</th>
                        <th>नूतनीकरण</th>
                        <th>नवीन</th>
                        <th>नूतनीकरण</th>
                        <th>नवीन</th>
                        <th>नूतनीकरण</th> -->
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let item of finalData; let end=last;">
                        <ng-container *ngIf="!end">
                            <ng-container *ngFor="let data of item; let end1=last;">
                                <tr *ngIf="!end1">
                                    <td>{{ data['rcpt_no'] }}</td>
                                    <td>{{ data['name'] }}</td>
                                    <td>{{ data['taluka'] }}</td>
                                    <td>{{ data['district'] }}</td>
                                    <td>{{ data['rep_name'] }}</td>
                                    <td>{{ data['office_rep_name'] }}</td>
                                    <td>{{ data['total_amt'] }}</td>
                                    <td>
                                        <!-- <span *ngIf="data['rcpt_type'] == ''">1</span> -->
                                        <span *ngIf="data['rcpt_type'] == 'New';else other_content">1</span>
                                        <ng-template #other_content>0</ng-template>
                                    </td>
                                    <td>
                                        <span *ngIf="data['rcpt_type'] == 'Renew';else other_content">1</span>
                                        <ng-template #other_content>0</ng-template>
                                    </td>
                                    <ng-container *ngFor="let sub of subTypesName">
                                        <ng-container *ngIf="sub['isVisible']">
                                            <td>
                                                
                                                <span
                                                    *ngIf="data['subscription_type'] == sub['name'] && data['rcpt_type'] == 'New';else other_content">
                                                <ng-container *ngIf="(sub['name']=='वार्षिक' || sub['name']=='रा.स्व.संघ ग्रंथ') && data['total_amt']=='1000.00' ">1</ng-container>
                                                <ng-container *ngIf="sub['name']=='त्रैवार्षिक' && data['total_amt']=='2500.00'">1</ng-container>
                                                <ng-container *ngIf="sub['name']=='पंचवार्षिक' && data['total_amt']=='4000.00'">1</ng-container>
                                                </span>
                                                <ng-template #other_content>0</ng-template>
                                            </td>
                                            <td>
                                                <span
                                                    *ngIf="data['subscription_type'] == sub['name'] && data['rcpt_type'] == 'Renew';else other_content">
                                                    <ng-container *ngIf="(sub['name']=='वार्षिक' || sub['name']=='रा.स्व.संघ ग्रंथ') && data['total_amt']=='1000.00'">1</ng-container>
                                                    <ng-container *ngIf="sub['name']=='त्रैवार्षिक' && data['total_amt']=='2500.00'">1</ng-container>
                                                    <ng-container *ngIf="sub['name']=='पंचवार्षिक' && data['total_amt']=='4000.00' ">1</ng-container></span>
                                                <ng-template #other_content>0</ng-template>
                                            </td>
                                            <td>
                                                <span
                                                    *ngIf="data['subscription_type'] == sub['name'];else other_content">{{
                                                    data.total_amt
                                                    }}</span>
                                                <span *ngIf="data['subscription_type'] != sub['name']">0</span>
                                                <ng-template #other_content>0</ng-template>
                                            </td>
                                        </ng-container>
                                    </ng-container>
                                </tr>
                                <!-- <tr *ngIf="end1">
                                    <td>{{ data['entry_date'] | date:'dd/MM/yyyy' }}</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>{{ data['एकूण'] }}</td>
                                    <td>{{ data['नवीन'] }}</td>
                                    <td>{{ data['नूतनीकरण'] }}</td>
                                    <ng-container *ngFor="let sub of subTypesName">
                                        <ng-container *ngIf="sub.isVisible">
                                            <td>{{ data[getKey(sub.name)+ '_नवीन'] }}</td>
                                            <td>{{ data[getKey(sub.name)+ '_नूतनीकरण'] }}</td>
                                            <td>{{ data[getKey(sub.name)+ '_एकूण'] }}</td>
                                        </ng-container>
                                    </ng-container>
                                </tr> -->
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="end">
                            <tr class="smallHeading">
                                <td colspan="3">{{ item['name'] }}</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>{{ item['एकूण'] }}</td>
                                <td>{{ item['नवीन'] }}</td>
                                <td>{{ item['नूतनीकरण'] }}</td>
                                <ng-container *ngFor="let sub of subTypesName">
                                    <ng-container *ngIf="sub.isVisible">
                                        <td>{{ item[getKey(sub.name)+ '_नवीन'] }}</td>
                                        <td>{{ item[getKey(sub.name)+ '_नूतनीकरण'] }}</td>
                                        <td>{{ item[getKey(sub.name)+ '_एकूण'] }}</td>
                                    </ng-container>
                                </ng-container>
                            </tr>
                        </ng-container>
                        <tr></tr>
                    </ng-container>
                </tbody>
            </table>
        </div>
    </div>
</ng-container>
<div *ngIf="isLoading" class="main-loading">
    <span class="loader"></span>
    <p>Loading...</p>
</div>