import {
  Component,
  OnInit,
  Inject,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import { DatePipe, formatDate } from '@angular/common';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as XLSX from 'xlsx';
import { DigisubservicesService } from 'src/app/services/digisubservices.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-contract-brief-report',
  templateUrl: './contract-brief-report.component.html',
  styleUrls: ['./contract-brief-report.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContractBriefReportComponent implements OnInit {
  contractData = [];
  advtFormBeans:any=[]
  advtVBTotal:number=0;
  advtFormBean:any
  advtBrieflyFormBeans:any=[]
  advtBrieflyTotal: number=0;
  advtMonthwiseFormBeans:any=[]
  GSTfullreportFormBeans:any=[]
  advtMonthwiseTotal:number=0;
  advtOfficierbrieflyFormBeans:any=[]
  advtOfficierbrieflyTotal:number=0;
  advtPaymentRegiInDetailsFormBeans:any=[]
  advtCreditRegiInDetailsFormBeans:any=[]
  advtCreditRegiInBrieflyFormBeans:any=[]
  OutstandingInDetailFormBeans:any=[]
  CreditadvtOffMTotal:number=0;
  CreditadvtOffVBMTotal:number=0;
  OutStandingIDTotal:number=0;
  OutStandingIDNetAmtTotal:number=0;
  OutStandingIDUnPaidTotal:number=0;
  PaymentadvtOffInDFormBenas:any=[]
  CreditadvtOffInDFormBenas:any=[]
  PaymentadvtOffInDTotal:number=0;
  PaymentadvtOffInDTotal2:number=0;
  PaymentadvtOffInDTotal3:number=0;

  CreditadvtOffInDTotal:number=0;
  CredittadvtInDTotal:number=0;
  CredittadvtInBrieflyTotal:number=0;
  CreditNoteVBFormBeans:any=[]
  CreditNoteVFormBeans:any=[]
  CreditNoteMFormBeans:any=[]
  CreditNoteNBFormBeans:any=[]
  OutstandingB:any=[]
  totalAdvtAmount: number = 0;
  totalNetAmount: number = 0;
  totalUnpaidAmount: number = 0;
  totalCR: number = 0;
  totalPaymentRegiInDetails: number = 0;
  CreditadvtOffBrieflyTotal:number =0;
  // gst
  GSTAdvtAmt:number=0;
  GSTDiscount:number=0;
  GSTNetAdvtAmt:number=0;
  CGSTAmount:number=0;
  SGSTAmount:number=0;
  AllGSTAmount:number=0;
  filteredcontractData = [];
  startDate:any = '';
  endDate:any = '';
  repName = '';
  offRepName = '';
  advtName = '';
  reportType = '';
  totalData = {
    contract_prev_bal: 0,
    remaining_contract: 0,
    contract_amt: 0,
    contract_discnt_amt: 0,
    contract_net_amt: 0,
    contract_net_gst_amt: 0,
    contract_net_total: 0,
    total_march_end: 0,
    next_year: 0,
    till_date_used: 0,
    use_till_march_end: 0,
  };

  today;
  nexttoday;
  aprilFirstSunday;
  day = 0;
  diffDays = 0;
  totalweeks = 0;
  indetailsData = [];
  contractDetailsForm: FormGroup;
  selectedDateRange: string;
  currentDate: any;
  oneMonthAgo: any;
  onlyOutstandingInDetailFormBeans: any;
  onlyOutstandingB: any;
  onlyOutStandingIDTotal: number;
  onlyOutStandingIDNetAmtTotal: number;
  onlyOutStandingIDUnPaidTotal: number;
  onlytotalUnpaidAmount: number;
  onlytotalNetAmount: number;
  onlytotalAdvtAmount: number;
  getpaiddata: any;
  getpersonalleddata: any;
  balancecfamt: number;
  Totalop: number;
  TotalDebit: number;
  TotalCredit: number;
  Totalbal: number;
  getpersonalledBridflydata: any;
  paidcommTotal: number;
  getpendingreceipt: any;
  PendingReceiptAmt: number;
  PendingBalanceAmt: number;
  start: any;
  officer: any;
  taluka: any;
  district: any;
  end : any;
  rep : any;
  SubByZilha: any;
  districtName: any;
  talukaName: any;
  date: any;
  creditoutstandingindetails: any;
  creditonlyOutStandingIDTotal: number;
  creditonlyOutStandingIDNetAmtTotal: number;
  creditonlyOutStandingIDUnPaidTotal: number;
  creditlastonlytotalAdvtAmount: number;
  creditlastonlytotalNetAmount: number;
  creditlastonlytotalUnpaidAmount: number;
  creditoutstandongbrief: any;
  totalsubhilhapost: number;
  totalsubhilhahaste: number;
  totalsubhilhatotal: number;
  SubByTaluka: any;
  SubByRep: any;
  SubByOffRep: any;
  totaloffrepsubhilhapost: number;
  totaloffrepsubhilhahaste: number;
  totaloffrepsubhilhatotal: number;
  totalrepsubhilhapost: number;
  totalrepsubhilhahaste: number;
  totalrepsubhilhatotal: number;
  totalzilhahapost: number;
  totalzilhahahaste: number;
  totalzilhahatotal: number;
  aprilpost :number;
  aprilhaste : number;
  apriltotal : number;
  maypost :number;
  mayhaste : number;
  maytotal : number;
  junepost :number;
  junehaste : number;
  junetotal : number;
  julypost :number;
  julyhaste : number;
  julytotal : number;
  augpost :number;
  aughaste : number;
  augtotal : number;
  seppost :number;
  sephaste : number;
  septotal : number;
  octopost :number;
  octohaste : number;
  octototal : number;
  novpost :number;
  novhaste : number;
  novtotal : number;
  decpost :number;
  dechaste : number;
  dectotal : number;
  janpost :number;
  janhaste : number;
  jantotal : number;
  febpost :number;
  febhaste : number;
  febtotal : number;
  marchpost :number;
  marchhaste : number;
  marchtotal : number;
  columnSums: number[] = []; 
  typewisedistrict: any;
  pvarshik: number;
  ptrevarshik: number;
  ppanchvarshik: number;
  phindutva: number;
  hlova: number;
  hpanchvarshik: number;
  ptotal: number;
  htrevarshik: number;
  hhindutva: number;
  htotal: number;
  plova: number;
  typewisetaluka: any;
  hvarshik: number;
  gtotal: number;
  typewiseofficer: any;
  typewiserep: any;
  SubRemDistrict: any;
  rem_april_post_total: any;
  rem_april_haste_total: any;
  rem_may_post_total: any;
  rem_may_haste_total: any;
  rem_jun_post_total: any;
  rem_jun_haste_total: any;
  rem_jul_post_total: any;
  rem_jul_haste_total: any;
  rem_aug_post_total: any;
  rem_aug_haste_total: any;
  rem_sep_post_total: any;
  rem_sep_haste_total: any;
  rem_oct_post_total: any;
  rem_oct_haste_total: any;
  rem_nov_post_total: any;
  rem_nov_haste_total: any;
  rem_dec_post_total: any;
  rem_dec_haste_total: any;
  rem_jan_post_total: any;
  rem_jan_haste_total: any;
  rem_feb_post_total: any;
  rem_feb_haste_total: any;
  rem_march_post_total: any;
  rem_march_haste_total: any;
  grandremtotal: any;
  SubRemTaluka: any;
  SubRemOffRep: any;
  SubRemRep: any;
  old_data: any;
  getpendingreceiptrep: any;
  lastcontract: number;
  remaincontract: number;
  newcontract: number;
  agencydiscontract: number;
  totalcontract: number;
  gstcontract: number;
  grandtotalcontract: number;
  getdeliverytype: any;
  prant_name: any;
  post_total_ROR: any;
  haste_total_ROR: any;
  post_jama:any;
  PaymentadvtOffInDTotal_netamt: any;
  beforegstamt: any;

  constructor(
    private dialogRef: MatDialogRef<ContractBriefReportComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private service: DigisubservicesService,
    private cdr: ChangeDetectorRef,private fb: FormBuilder,private datePipe:DatePipe
  ) {
    this.contractDetailsForm = this.fb.group({
      start: ['', Validators.required],
      end: ['', Validators.required],
      // other form controls
    });
  }
  months:any
  ngOnInit(): void {
    // this.filterData()
    // this.getAdvtOfficerVb();
// this.getAdvtBriefly()
// this.getAdvtMonthwise()
// this.getAdvtOfficerBriefly()
// this.getIndetailsContractDetails()
this.service.getAllContractDetails().subscribe((res) => {
  // console.log(res);
  this.contractData = res;

  this.lastcontract = 0;
  this.remaincontract = 0;
  this.newcontract = 0;
  this.agencydiscontract = 0;
  this.totalcontract = 0;
  this.gstcontract = 0;
  this.grandtotalcontract = 0;
  for (let data1 of this.contractData) {
    this.lastcontract += parseInt(data1.contract_prev_bal) || 0;
    this.newcontract += parseInt(data1.contract_amt) || 0;
    this.agencydiscontract += parseInt(data1.contract_discnt_amt) || 0;
    this.totalcontract += parseInt(data1.contract_net_amt) || 0;
    this.gstcontract += parseInt(data1.contract_net_gst_amt) || 0;
    this.grandtotalcontract += parseInt(data1.contract_net_total) || 0;

         
       }
  // this.getContractDetails();
  console.log(res, "briefly");
});



    
    this.reportType = this.data['reportType'];
    this.months=this.data['months']
    this.today = new Date();
    this.nexttoday = new Date().setFullYear(new Date().getFullYear() + 1);
    // console.log(this.contractData);
    this.startDate =
      this.data['startDate'] === undefined ? '' : this.data['startDate'];
    this.endDate =
      this.data['endDate'] === undefined ? '' : this.data['endDate'];
    this.repName = this.data['repName'];
    this.offRepName = this.data['offRepName'];
    this.advtName = this.data['advtName'];
    this.districtName = this.data['districtName'];
    this.talukaName = this.data['talukaName'];
    this.date = this.data['endDate'];
    this.prant_name = this.data['prant_name'];

    // this.startdate = this.data['start']
    this.getReportSubscription()
    // console.log('reportType', this.reportType);

    if (this.reportType == 'indetail') {
      this.getIndetailsContractDetails();
      // setTimeout(() => {
      //   this.filterData();
      //   this.getData();
      // }, 500);
    } else if(this.reportType == 'indetailcontract'){
      this.getIndetailsContractDetails();
    }else {
      this.contractData = this.data['contractData'];
      this.filterData();
    }
    this.aprilFirstSunday = formatDate(
      new Date(new Date().setMonth(3)),
      'YYYY-MM-DD',
      'en-IN',
      'GMT+0530'
    );
    // console.log('aprilFirstSunday', this.aprilFirstSunday);





    this.filters()
    this.calculatetotalweeks();
   
    this.getCreditNoteVB()
this.getCreditNoteV()
this.getCreditNoteM()
this.getCreditNoteNB()
// this.getOutstandingB()
// this.getPaymentRegiInDetails()
// this.getPaymentRegiOffInDetails()
this.getCreditRegiInDetails()
this.getCreditRegiInBriefly()
this.getCreditRegiOffInDetails()
// this.getAdvtOutstanding() 
// this.filters()
  }

  getIndetailsContractDetails() {
    const req:any = {
      "rep_name":this.repName,
      "start_date":this.startDate,
      "end_date":this.endDate,
      "off_rep_name":this.offRepName,
      "issue_name":""
    }


    this.service.getAllIndetailsContractDetails(req).subscribe((a) => {
      // console.log(a);
      this.contractData = a;
      this.filteredcontractData=a
      console.log(this.contractData,"contract deatail");
      // this.filterData();
      this.calculateTotal();
      this.getData();
    });
  }

  getChangedDateContract() {
    // update the selectedDateRange variable when the date range changes
    const startDate = this.contractDetailsForm.get('start').value;
    const endDate = this.contractDetailsForm.get('end').value;

    if (startDate && endDate) {
      this.selectedDateRange = `${startDate} to ${endDate}`;
    }
  }

  // getAdvtOfficerVb() {
   
  //   this.service.getAdvtOfficerVb().subscribe((data:any) => {
  //      console.log("ADVT Data=",data);
  //     this.advtFormBeans = (data as any);;

  //     // Reset totals
  //     this.advtVBTotal = 0;

  //     // Calculate totals
  //     for (let repData of this.advtFormBeans) {
  //         for (let data1 of repData.rep_data) {
  //             this.advtVBTotal += parseInt(data1.rec_amt) || 0;
  //         }
  //     }
  //   });
  // }

  // getAdvtBriefly() {
  
  //   this.service.getAdvtBriefly().subscribe((data:any) => {
  //      console.log("ADVT Brielfy Data=",data);
  //     this.advtBrieflyFormBeans = (data as any);;

  //     this.advtBrieflyTotal = 0;

  //     // Calculate totals
  //     // for (let data of this.advtBrieflyFormBeans) {
          
  //     //         this.advtBrieflyTotal += parseInt(data.rec_amt) || 0;
  //     //        }
  //     for (let repData of this.advtBrieflyFormBeans) {
  //       for (let data1 of repData.rep_data) {
  //           this.advtBrieflyTotal += parseInt(data1.rec_amt) || 0;
           
  //       }
  //   }
  //   });
  // }
  
  // getAdvtMonthwise() {
   
  //   this.service.getAdvtMonthwise().subscribe((data:any) => {
  //      console.log("ADVT Data=",data);
  //     this.advtMonthwiseFormBeans = (data as any);;
  //     // Reset totals
  //     this.advtMonthwiseTotal = 0;

  //     // Calculate totals
  //     for (let data of this.advtMonthwiseFormBeans) {
          
  //             this.advtMonthwiseTotal += parseInt(data.total_amt) || 0;
  //            }
  //   });
  // }

  // getAdvtOfficerBriefly() {
   
  //   this.service.getAdvtOfficerBriefly().subscribe((data:any) => {
  //      console.log("ADVT Data=",data);
  //     this.advtOfficierbrieflyFormBeans = (data as any);;
  //     // Reset totals
  //     this.advtOfficierbrieflyTotal = 0;

  //     // Calculate totals
  //     for (let data of this.advtOfficierbrieflyFormBeans) {
  //       for (let data1 of data.rep_info) {
  //           this.advtOfficierbrieflyTotal += parseInt(data1.rep_total) || 0;
           
  //       }
  //   }
      
  //   });
  // }

  
  getCreditNoteVB() {
    const req:any = {
      "rep_name":this.repName,
      "start_date":this.startDate,
      "end_date":this.endDate,
      "off_rep_name":this.offRepName,
      "issue_name":""
    }
   
    this.service.getCreditNoteVB(req).subscribe((data:any) => {
       console.log("ADVT Data=",data);
       this.CreditadvtOffVBMTotal = 0;


      this.CreditNoteVBFormBeans = (data as any);;
      for (let data of this.CreditNoteVBFormBeans) {
         
        this.CreditadvtOffVBMTotal += parseInt(data.total) || 0;
       }
    });
  }
  
  getCreditNoteV() {

    const req:any = {
      "rep_name":this.repName,
      "start_date":this.startDate,
      "end_date":this.endDate,
      "off_rep_name":this.offRepName,
      "issue_name":""
    }
   
    this.service.getCreditNoteV(req).subscribe((data:any) => {
       console.log("ADVT Data=",data);
      this.CreditNoteVFormBeans = (data as any);;

      this.CreditadvtOffBrieflyTotal = 0;

     // Calculate totals
     for (let data of this.CreditNoteVFormBeans) {
         
             this.CreditadvtOffBrieflyTotal += parseInt(data.total) || 0;
            }
    });
  }
  
  getCreditNoteM() {
    
    const req:any = {
      "rep_name":this.repName,
      "start_date":this.startDate,
      "end_date":this.endDate,
      "off_rep_name":this.offRepName,
      "issue_name":""
    }
    this.service.getCreditNoteM().subscribe((data:any) => {
       console.log("ADVT Data=",data);
      this.CreditNoteMFormBeans = (data as any);;

      // Reset totals
     this.CreditadvtOffMTotal = 0;

     // Calculate totals
     for (let data of this.CreditNoteMFormBeans) {
         
             this.CreditadvtOffMTotal += parseInt(data.total_amt) || 0;
            }

    });
  }

  
  getCreditNoteNB() {
    this.service.getCreditNoteNB().subscribe((data:any) => {
       console.log("ADVT Data=",data);
      this.CreditNoteNBFormBeans = (data as any);;

       // Reset totals
       this.totalCR = 0;

       // Calculate totals
       for (let data of this.CreditNoteNBFormBeans) {
           
               this.totalCR += parseInt(data.credit_amt) || 0;
              }
    });
  }
  
//   getOutstandingB() {
//     this.service.getOutstandingB().subscribe((data: any) => {
//         this.OutstandingB = data as any;

//         // Reset totals
//         this.totalAdvtAmount = 0;
//         this.totalNetAmount = 0;
//         this.totalUnpaidAmount = 0;

//         // Calculate totals
//         for (let repData of this.OutstandingB) {
//             for (let data1 of repData.rep_data) {
//                 this.totalAdvtAmount += parseInt(data1.contract_bkng_advt_amt) || 0;
//                 this.totalNetAmount += parseInt(data1.contract_net_total) || 0;
//                 this.totalUnpaidAmount += parseInt(data1.contract_bal_amt) || 0;
//             }
//         }
//     });
// }

// getPaymentRegiInDetails() {
  
//   this.service.getPaymentRegiInDetails().subscribe((data:any) => {
//      console.log("ADVT Indetailsoff Data=",data);
//     this.advtPaymentRegiInDetailsFormBeans = (data as any);;

//      // Reset totals
//      this.PaymentadvtOffInDTotal = 0;

//      // Calculate totals
//      for (let data of this.advtPaymentRegiInDetailsFormBeans) {
         
//              this.PaymentadvtOffInDTotal += parseInt(data.rcv_amt) || 0;
//             }
//   });
// }
getCreditRegiInDetails() {
  const req:any = {
    "rep_name":this.repName,
    "start_date":this.startDate,
    "end_date":this.endDate,
    "off_rep_name":this.offRepName,
    "issue_name":""
  }
  this.service.getCreditRegiInDetails(req).subscribe((data:any) => {
     console.log("ADVT Indetailscredit Data=",data);
    this.advtCreditRegiInDetailsFormBeans = (data as any);;

     // Reset totals
     this.CredittadvtInDTotal = 0;

     // Calculate totals
     for (let data of this.advtCreditRegiInDetailsFormBeans) {
         
             this.CredittadvtInDTotal += parseInt(data.credit_v_amt) || 0;
            }
  });
}

getCreditRegiInBriefly() {
  const req:any = {
    "rep_name":this.repName,
    "start_date":this.startDate,
    "end_date":this.endDate,
    "off_rep_name":this.offRepName,
    "issue_name":""
  }
  this.service.getCreditRegiInBriefly().subscribe((data:any) => {
     console.log("ADVT Indetailscredit Data=",data);
    this.advtCreditRegiInBrieflyFormBeans = (data as any);;

     // Reset totals
     this.CredittadvtInBrieflyTotal = 0;

     // Calculate totals
     for (let repData of this.advtCreditRegiInBrieflyFormBeans) {
      for (let data1 of repData.rep_data) {
          this.CredittadvtInBrieflyTotal += parseInt(data1.rec_amt) || 0;
      }
  }
  });
}

// getPaymentRegiOffInDetails() {
//   this.service.getPaymentRegiOffInDetails().subscribe((data: any) => {
//     console.log("new",data)
//       this.PaymentadvtOffInDFormBenas = data as any;
//       // Reset totals
//       this.PaymentadvtOffInDTotal = 0;

//       // Calculate totals
//       for (let repData of this.PaymentadvtOffInDFormBenas) {
//           for (let data1 of repData.rep_data) {
//               this.PaymentadvtOffInDTotal += parseInt(data1.credit_v_amt) || 0;
//           }
//       }
//       console.log("offcr total",this.PaymentadvtOffInDTotal)
//   });
// }

getCreditRegiOffInDetails() {
  const req:any = {
    "rep_name":this.repName,
    "start_date":this.startDate,
    "end_date":this.endDate,
    "off_rep_name":this.offRepName,
    "issue_name":""
  }
  this.service.getCreditRegiOffInDetails().subscribe((data: any) => {
    console.log("creditIndetailoff",data)
      this.CreditadvtOffInDFormBenas = data as any;
      // Reset totals
      this.CreditadvtOffInDTotal = 0;

      // Calculate totals
      for (let repData of this.CreditadvtOffInDFormBenas) {
        this.CreditadvtOffInDTotal += parseInt(repData.total) || 0;
          // for (let data1 of repData.rep_data) {
          //     this.CreditadvtOffInDTotal += parseInt(data1.credit_v_amt) || 0;
          // }
      }
  });
}
// getAdvtOutstanding() {
//   this.service.getAdvtOutstanding().subscribe((data: any) => {
//     console.log("outstanding",data)
//       this.OutstandingInDetailFormBeans = data as any;
      
//       this.OutStandingIDTotal = 0;
//       this.OutStandingIDNetAmtTotal = 0;
//       this.OutStandingIDUnPaidTotal = 0;
//       for (let data of this.OutstandingInDetailFormBeans) {
//         for(let data1 of data.rep_data){
//           this.OutStandingIDTotal += parseInt(data1.advt_amt_total) || 0;
//           this.OutStandingIDNetAmtTotal += parseInt(data1.net_amt_total) || 0;
//           this.OutStandingIDUnPaidTotal += parseInt(data1.unpaid_amt_total) || 0;
//         } 
//       }
//   });
// }

getReportSubscription(){
  
  const req:any = {
    
    "off_rep_name":this.offRepName,
    "financialYear":this.date,
    "rep_name":this.repName,
    "district":this.districtName,
    "taluka":this.talukaName,
    "startDate":this.startDate,
    "endDate":this.endDate,
    "prant_name" : this.prant_name

  }
  const req1:any = {
    
    "off_rep_name":this.offRepName,
    // "financialYear":this.date,
    "rep_name":this.repName,
    "district":this.districtName,
    "taluka":this.talukaName,
    "startDate":this.startDate,
    "endDate":this.endDate,

  }
  const req3:any = {
    
    // "financialYear":this.date,
    "rep_name":this.repName,
    "district":this.districtName,
    "taluka":this.talukaName,
    

  }
  // const reqArray: any[] = Object.entries(req);
  // const payloadList: any[] = [];
  // payloadList.push(req1);

if(this.reportType=="Renewaljilha"){
  this.service.getSubByZilha(req).subscribe((data) => {
    this.SubByZilha = data;
    // this.PaymentadvtOffInDTotal = 0;
    console.log("subbyjilha", data);
    this.totalzilhahapost = 0;
    this.totalzilhahahaste = 0;
    this.totalzilhahatotal = 0;
    // month cal
  this.aprilpost = 0;
  this.aprilhaste  = 0;
  this.apriltotal  = 0;
  this.maypost = 0;
  this.mayhaste  = 0;
  this.maytotal  = 0;
  this.junepost = 0;
  this.junehaste  = 0;
  this.junetotal  = 0;
  this.julypost = 0;
  this.julyhaste  = 0;
  this.julytotal  = 0;
  this.augpost = 0;
  this.aughaste  = 0;
  this.augtotal  = 0;
  this.seppost = 0;
  this.sephaste  = 0;
  this.septotal  = 0;
  this.octopost = 0;
  this.octohaste  = 0;
  this.octototal  = 0;
  this.novpost = 0;
  this.novhaste  = 0;
  this.novtotal  = 0;
  this.decpost = 0;
  this.dechaste  = 0;
  this.dectotal  = 0;
  this.janpost = 0;
  this.janhaste  = 0;
  this.jantotal  = 0;
  this.febpost = 0;
  this.febhaste  = 0;
  this.febtotal  = 0;
  this.marchpost = 0;
  this.marchhaste  = 0;
  this.marchtotal  = 0;
     for (let data1 of this.SubByZilha) {
      // pending work
       
             this.totalzilhahapost += parseInt(data1.post_total)|| 0;
             this.totalzilhahahaste += parseInt(data1.haste_total)|| 0;
             this.totalzilhahatotal += parseInt(data1.grand_total)|| 0;
            //  month
            this.aprilpost +=parseInt(data1.april_post_count)
            this.aprilhaste +=parseInt(data1.april_haste_count);
            this.apriltotal +=parseInt(data1.april_total);
            this.maypost+=parseInt(data1.may_post_count);
            this.mayhaste +=parseInt(data1.may_haste_count);
            this.maytotal +=parseInt(data1.may_total);
            this.junepost+=parseInt(data1.jun_post_count);
            this.junehaste +=parseInt(data1.jun_haste_count);
            this.junetotal +=parseInt(data1.jun_total);
            this.julypost+=parseInt(data1.jul_post_count);
            this.julyhaste +=parseInt(data1.jul_haste_count);
            this.julytotal +=parseInt(data1.jul_total);
            this.augpost+=parseInt(data1.aug_post_count);
            this.aughaste +=parseInt(data1.aug_haste_count);
            this.augtotal +=parseInt(data1.aug_total);
            this.seppost+=parseInt(data1.sep_post_count);
            this.sephaste +=parseInt(data1.sep_haste_count);
            this.septotal +=parseInt(data1.sep_total);
            this.octopost+=parseInt(data1.oct_post_count);
            this.octohaste +=parseInt(data1.oct_haste_count);
            this.octototal +=parseInt(data1.oct_total);
            this.novpost+=parseInt(data1.nov_post_count);
            this.novhaste +=parseInt(data1.nov_haste_count);
            this.novtotal +=parseInt(data1.nov_total);
            this.decpost+=parseInt(data1.dec_post_count);
            this.dechaste +=parseInt(data1.dec_haste_count);
            this.dectotal +=parseInt(data1.dec_total);
            this.janpost+=parseInt(data1.jan_post_count);
            this.janhaste +=parseInt(data1.jan_haste_count);
            this.jantotal +=parseInt(data1.jan_total);
            this.febpost+=parseInt(data1.feb_post_count);
            this.febhaste +=parseInt(data1.feb_haste_count);
            this.febtotal +=parseInt(data1.feb_total);
            this.marchpost+=parseInt(data1.mar_post_count);
            this.marchhaste +=parseInt(data1.mar_haste_count);
            this.marchtotal +=parseInt(data1.mar_total);
             console.log("total", this.PaymentadvtOffInDTotal)
            }
     });
}

if(this.reportType=="PrantwiseDeliverymode"){
  this.service.getDeliverytype(req).subscribe((data) => {
    this.getdeliverytype = data;
    // this.PaymentadvtOffInDTotal = 0;
    console.log("subbyjilha", data);
    this.totalzilhahapost = 0;
    this.totalzilhahahaste = 0;
    this.totalzilhahatotal = 0;
    // month cal
  this.aprilpost = 0;
  this.aprilhaste  = 0;
  this.apriltotal  = 0;
  this.maypost = 0;
  this.mayhaste  = 0;
  this.maytotal  = 0;
  this.junepost = 0;
  this.junehaste  = 0;
  this.junetotal  = 0;
  this.julypost = 0;
  this.julyhaste  = 0;
  this.julytotal  = 0;
  this.augpost = 0;
  this.aughaste  = 0;
  this.augtotal  = 0;
  this.seppost = 0;
  this.sephaste  = 0;
  this.septotal  = 0;
  this.octopost = 0;
  this.octohaste  = 0;
  this.octototal  = 0;
  this.novpost = 0;
  this.novhaste  = 0;
  this.novtotal  = 0;
  this.decpost = 0;
  this.dechaste  = 0;
  this.dectotal  = 0;
  this.janpost = 0;
  this.janhaste  = 0;
  this.jantotal  = 0;
  this.febpost = 0;
  this.febhaste  = 0;
  this.febtotal  = 0;
  this.marchpost = 0;
  this.marchhaste  = 0;
  this.marchtotal  = 0;
  for (let data of this.getdeliverytype){
     for (let data1 of data.sub_data) {
      // pending work
       
             this.totalzilhahapost += parseInt(data1.post_total)|| 0;
             this.totalzilhahahaste += parseInt(data1.haste_total)|| 0;
             this.totalzilhahatotal += parseInt(data1.grand_total)|| 0;
            //  month
            this.aprilpost +=parseInt(data1.april_post_count)
            this.aprilhaste +=parseInt(data1.april_haste_count);
            this.apriltotal +=parseInt(data1.april_total);
            this.maypost+=parseInt(data1.may_post_count);
            this.mayhaste +=parseInt(data1.may_haste_count);
            this.maytotal +=parseInt(data1.may_total);
            this.junepost+=parseInt(data1.jun_post_count);
            this.junehaste +=parseInt(data1.jun_haste_count);
            this.junetotal +=parseInt(data1.jun_total);
            this.julypost+=parseInt(data1.jul_post_count);
            this.julyhaste +=parseInt(data1.jul_haste_count);
            this.julytotal +=parseInt(data1.jul_total);
            this.augpost+=parseInt(data1.aug_post_count);
            this.aughaste +=parseInt(data1.aug_haste_count);
            this.augtotal +=parseInt(data1.aug_total);
            this.seppost+=parseInt(data1.sep_post_count);
            this.sephaste +=parseInt(data1.sep_haste_count);
            this.septotal +=parseInt(data1.sep_total);
            this.octopost+=parseInt(data1.oct_post_count);
            this.octohaste +=parseInt(data1.oct_haste_count);
            this.octototal +=parseInt(data1.oct_total);
            this.novpost+=parseInt(data1.nov_post_count);
            this.novhaste +=parseInt(data1.nov_haste_count);
            this.novtotal +=parseInt(data1.nov_total);
            this.decpost+=parseInt(data1.dec_post_count);
            this.dechaste +=parseInt(data1.dec_haste_count);
            this.dectotal +=parseInt(data1.dec_total);
            this.janpost+=parseInt(data1.jan_post_count);
            this.janhaste +=parseInt(data1.jan_haste_count);
            this.jantotal +=parseInt(data1.jan_total);
            this.febpost+=parseInt(data1.feb_post_count);
            this.febhaste +=parseInt(data1.feb_haste_count);
            this.febtotal +=parseInt(data1.feb_total);
            this.marchpost+=parseInt(data1.mar_post_count);
            this.marchhaste +=parseInt(data1.mar_haste_count);
            this.marchtotal +=parseInt(data1.mar_total);
             console.log("total", this.PaymentadvtOffInDTotal)
            }}
     });
}

if(this.reportType=="RemainingSubscriptionDistrict"){
  this.service.getSubRemDist(req).subscribe((data) => {
    this.SubRemDistrict = data;
    // this.PaymentadvtOffInDTotal = 0;
  this.rem_april_post_total = 0;
  this.rem_april_haste_total = 0;
  this.rem_may_post_total = 0;
  this.rem_may_haste_total = 0;
  this.rem_jun_post_total = 0;
  this.rem_jun_haste_total = 0;
  this.rem_jul_post_total = 0;
  this.rem_jul_haste_total = 0;
  this.rem_aug_post_total = 0;
  this.rem_aug_haste_total = 0;
  this.rem_sep_post_total = 0;
  this.rem_sep_haste_total = 0;
  this.rem_oct_post_total = 0;
  this.rem_oct_haste_total = 0;
  this.rem_nov_post_total = 0;
  this.rem_nov_haste_total = 0;
  this.rem_dec_post_total = 0;
  this.rem_dec_haste_total = 0;
  this.rem_jan_post_total = 0;
  this.rem_jan_haste_total = 0;
  this.rem_feb_post_total = 0;
  this.rem_feb_haste_total = 0;
  this.rem_march_post_total = 0;
  this.rem_march_haste_total = 0;

  for (let data2 of this.SubRemDistrict) {

    data2.rem_april_post_total = parseInt(data2.tapal_Renew_4) + parseInt(data2.tapal_New_4);
      data2.rem_april_haste_total = parseInt(data2.haste_Renew_4) + parseInt(data2.haste_New_4);
  
      data2.rem_may_post_total = parseInt(data2.tapal_Renew_5) + parseInt(data2.tapal_New_5);
      data2.rem_may_haste_total = parseInt(data2.haste_Renew_5) + parseInt(data2.haste_New_5);
  
      data2.rem_jun_post_total = parseInt(data2.tapal_Renew_6) + parseInt(data2.tapal_New_6);
      data2.rem_jun_haste_total = parseInt(data2.haste_Renew_6) + parseInt(data2.haste_New_6);
  
      data2.rem_jul_post_total = parseInt(data2.tapal_Renew_7) + parseInt(data2.tapal_New_7);
      data2.rem_jul_haste_total = parseInt(data2.haste_Renew_7) + parseInt(data2.haste_New_7);
  
      data2.rem_aug_post_total = parseInt(data2.tapal_Renew_8) + parseInt(data2.tapal_New_8);
      data2.rem_aug_haste_total = parseInt(data2.haste_Renew_8) + parseInt(data2.haste_New_8);
  
      data2.rem_sep_post_total = parseInt(data2.tapal_Renew_9) + parseInt(data2.tapal_New_9);
      data2.rem_sep_haste_total = parseInt(data2.haste_Renew_9) + parseInt(data2.haste_New_9);
  
      data2.rem_oct_post_total = parseInt(data2.tapal_Renew_10) + parseInt(data2.tapal_New_10);
      data2.rem_oct_haste_total = parseInt(data2.haste_Renew_10) + parseInt(data2.haste_New_10);
  
      data2.rem_nov_post_total = parseInt(data2.tapal_Renew_11) + parseInt(data2.tapal_New_11);
      data2.rem_nov_haste_total = parseInt(data2.haste_Renew_11) + parseInt(data2.haste_New_11);
  
      data2.rem_dec_post_total = parseInt(data2.tapal_Renew_12) + parseInt(data2.tapal_New_12);
      data2.rem_dec_haste_total = parseInt(data2.haste_Renew_12) + parseInt(data2.haste_New_12);
  
      data2.rem_jan_post_total = parseInt(data2.tapal_Renew_1) + parseInt(data2.tapal_New_1);
      data2.rem_jan_haste_total = parseInt(data2.haste_Renew_1) + parseInt(data2.haste_New_1);
  
      data2.rem_feb_post_total  = parseInt(data2.tapal_Renew_2) + parseInt(data2.tapal_New_2);
      data2.rem_feb_haste_total  = parseInt(data2.haste_Renew_2) + parseInt(data2.haste_New_2);
  
      data2.rem_march_post_total  = parseInt(data2.tapal_Renew_3) + parseInt(data2.tapal_New_3);
      data2.rem_march_haste_total  = parseInt(data2.haste_Renew_3) + parseInt(data2.haste_New_3);
    // const total = this.rem_march_haste_total + this.rem_march_post_total

    this.grandremtotal == this.rem_march_haste_total + this.rem_march_post_total;

  }


    console.log("subbyjilha renewal reming", data);
     });
}

if(this.reportType=="RemainingSubscriptionTaluka"){
  this.service.getSubRemTaluka(req).subscribe((data) => {
    this.SubRemTaluka = data;
    // this.PaymentadvtOffInDTotal = 0;
  this.rem_april_post_total = 0;
  this.rem_april_haste_total = 0;
  this.rem_may_post_total = 0;
  this.rem_may_haste_total = 0;
  this.rem_jun_post_total = 0;
  this.rem_jun_haste_total = 0;
  this.rem_jul_post_total = 0;
  this.rem_jul_haste_total = 0;
  this.rem_aug_post_total = 0;
  this.rem_aug_haste_total = 0;
  this.rem_sep_post_total = 0;
  this.rem_sep_haste_total = 0;
  this.rem_oct_post_total = 0;
  this.rem_oct_haste_total = 0;
  this.rem_nov_post_total = 0;
  this.rem_nov_haste_total = 0;
  this.rem_dec_post_total = 0;
  this.rem_dec_haste_total = 0;
  this.rem_jan_post_total = 0;
  this.rem_jan_haste_total = 0;
  this.rem_feb_post_total = 0;
  this.rem_feb_haste_total = 0;
  this.rem_march_post_total = 0;
  this.rem_march_haste_total = 0;
  

  for (let data2 of this.SubRemTaluka) {

    for (let data of data2.rep_info){
      // if(data.old_month==4){
        data.rem_april_haste_total = Number(data.haste_count )+ Number(data.new_haste_count);
        data.rem_april_post_total =Number( data.tapal_count) + Number(data.new_tapal_count);
      // }

      // if(data.old_month==5){
      //   this.rem_may_haste_total = data.haste_count + data.new_haste_count;
      //   this.rem_may_post_total = data.tapal_count + data.new_tapal_count; 
      // }
      

     

      // this.rem_jun_haste_total = data.haste_count + data.new_haste_count;
      // this.rem_jun_post_total = data.tapal_count + data.new_tapal_count; 

      // this.rem_jul_haste_total = data.haste_count + data.new_haste_count;
      // this.rem_jul_post_total = data.tapal_count + data.new_tapal_count; 

      // this.rem_aug_haste_total = data.haste_count + data.new_haste_count;
      // this.rem_aug_post_total = data.tapal_count + data.new_tapal_count; 

      // this.rem_sep_haste_total = data.haste_count + data.new_haste_count;
      // this.rem_sep_post_total = data.tapal_count + data.new_tapal_count; 

      // this.rem_oct_haste_total = data.haste_count + data.new_haste_count;
      // this.rem_oct_post_total = data.tapal_count + data.new_tapal_count; 

      // this.rem_nov_haste_total = data.haste_count + data.new_haste_count;
      // this.rem_nov_post_total = data.tapal_count + data.new_tapal_count; 

      // this.rem_dec_haste_total = data.haste_count + data.new_haste_count;
      // this.rem_dec_post_total = data.tapal_count + data.new_tapal_count; 

      // if(data.old_month==1){
      //   this.rem_jan_haste_total = data.haste_count + data.new_haste_count;
      // this.rem_jan_post_total = data.tapal_count + data.new_tapal_count;
      // }

      // if(data.old_month==2){
      //   this.rem_feb_haste_total = data.haste_count + data.new_haste_count;
      //   this.rem_feb_post_total = data.tapal_count + data.new_tapal_count; 
      // }
      

     

      // this.rem_march_haste_total = data.haste_count + data.new_haste_count;
      // this.rem_march_post_total = data.tapal_count + data.new_tapal_count; 
    }

    // data2.rem_april_post_total = parseInt(data2.tapal_Renew_4) + parseInt(data2.tapal_New_4);
    //   data2.rem_april_haste_total = parseInt(data2.haste_Renew_4) + parseInt(data2.haste_New_4);
  
    //   data2.rem_may_post_total = parseInt(data2.tapal_Renew_5) + parseInt(data2.tapal_New_5);
    //   data2.rem_may_haste_total = parseInt(data2.haste_Renew_5) + parseInt(data2.haste_New_5);
  
    //   data2.rem_jun_post_total = parseInt(data2.tapal_Renew_6) + parseInt(data2.tapal_New_6);
    //   data2.rem_jun_haste_total = parseInt(data2.haste_Renew_6) + parseInt(data2.haste_New_6);
  
    //   data2.rem_jul_post_total = parseInt(data2.tapal_Renew_7) + parseInt(data2.tapal_New_7);
    //   data2.rem_jul_haste_total = parseInt(data2.haste_Renew_7) + parseInt(data2.haste_New_7);
  
    //   data2.rem_aug_post_total = parseInt(data2.tapal_Renew_8) + parseInt(data2.tapal_New_8);
    //   data2.rem_aug_haste_total = parseInt(data2.haste_Renew_8) + parseInt(data2.haste_New_8);
  
    //   data2.rem_sep_post_total = parseInt(data2.tapal_Renew_9) + parseInt(data2.tapal_New_9);
    //   data2.rem_sep_haste_total = parseInt(data2.haste_Renew_9) + parseInt(data2.haste_New_9);
  
    //   data2.rem_oct_post_total = parseInt(data2.tapal_Renew_10) + parseInt(data2.tapal_New_10);
    //   data2.rem_oct_haste_total = parseInt(data2.haste_Renew_10) + parseInt(data2.haste_New_10);
  
    //   data2.rem_nov_post_total = parseInt(data2.tapal_Renew_11) + parseInt(data2.tapal_New_11);
    //   data2.rem_nov_haste_total = parseInt(data2.haste_Renew_11) + parseInt(data2.haste_New_11);
  
    //   data2.rem_dec_post_total = parseInt(data2.tapal_Renew_12) + parseInt(data2.tapal_New_12);
    //   data2.rem_dec_haste_total = parseInt(data2.haste_Renew_12) + parseInt(data2.haste_New_12);
  
    //   data2.rem_jan_post_total = parseInt(data2.tapal_Renew_1) + parseInt(data2.tapal_New_1);
    //   data2.rem_jan_haste_total = parseInt(data2.haste_Renew_1) + parseInt(data2.haste_New_1);
  
    //   data2.rem_feb_post_total  = parseInt(data2.tapal_Renew_2) + parseInt(data2.tapal_New_2);
    //   data2.rem_feb_haste_total  = parseInt(data2.haste_Renew_2) + parseInt(data2.haste_New_2);
  
    //   data2.rem_march_post_total  = parseInt(data2.tapal_Renew_3) + parseInt(data2.tapal_New_3);
    //   data2.rem_march_haste_total  = parseInt(data2.haste_Renew_3) + parseInt(data2.haste_New_3);
    // // const total = this.rem_march_haste_total + this.rem_march_post_total

    // this.grandremtotal == this.rem_march_haste_total + this.rem_march_post_total;

  }


    console.log("taluka renewal reming", data);
     });
}

if(this.reportType=="RemainingSubscriptionOffReprensantive"){
  this.service.getSubRemOffRep(req).subscribe((data) => {
    this.SubRemOffRep = data;
    // this.PaymentadvtOffInDTotal = 0;
  this.rem_april_post_total = 0;
  this.rem_april_haste_total = 0;
  this.rem_may_post_total = 0;
  this.rem_may_haste_total = 0;
  this.rem_jun_post_total = 0;
  this.rem_jun_haste_total = 0;
  this.rem_jul_post_total = 0;
  this.rem_jul_haste_total = 0;
  this.rem_aug_post_total = 0;
  this.rem_aug_haste_total = 0;
  this.rem_sep_post_total = 0;
  this.rem_sep_haste_total = 0;
  this.rem_oct_post_total = 0;
  this.rem_oct_haste_total = 0;
  this.rem_nov_post_total = 0;
  this.rem_nov_haste_total = 0;
  this.rem_dec_post_total = 0;
  this.rem_dec_haste_total = 0;
  this.rem_jan_post_total = 0;
  this.rem_jan_haste_total = 0;
  this.rem_feb_post_total = 0;
  this.rem_feb_haste_total = 0;
  this.rem_march_post_total = 0;
  this.rem_march_haste_total = 0;
  // this.post_jama = 0;

  // for (let data3 of this.SubRemOffRep) {

  //   for(let data2 of data3.rep_info){
  //     data2.rem_april_post_total = parseInt(data2.tapal_Renew_4) + parseInt(data2.tapal_New_4);
  //     data2.rem_april_haste_total = parseInt(data2.haste_Renew_4) + parseInt(data2.haste_New_4);
  
  //     data2.rem_may_post_total = parseInt(data2.tapal_Renew_5) + parseInt(data2.tapal_New_5);
  //     data2.rem_may_haste_total = parseInt(data2.haste_Renew_5) + parseInt(data2.haste_New_5);
  
  //     data2.rem_jun_post_total = parseInt(data2.tapal_Renew_6) + parseInt(data2.tapal_New_6);
  //     data2.rem_jun_haste_total = parseInt(data2.haste_Renew_6) + parseInt(data2.haste_New_6);
  
  //     data2.rem_jul_post_total = parseInt(data2.tapal_Renew_7) + parseInt(data2.tapal_New_7);
  //     data2.rem_jul_haste_total = parseInt(data2.haste_Renew_7) + parseInt(data2.haste_New_7);
  
  //     data2.rem_aug_post_total = parseInt(data2.tapal_Renew_8) + parseInt(data2.tapal_New_8);
  //     data2.rem_aug_haste_total = parseInt(data2.haste_Renew_8) + parseInt(data2.haste_New_8);
  
  //     data2.rem_sep_post_total = parseInt(data2.tapal_Renew_9) + parseInt(data2.tapal_New_9);
  //     data2.rem_sep_haste_total = parseInt(data2.haste_Renew_9) + parseInt(data2.haste_New_9);
  
  //     data2.rem_oct_post_total = parseInt(data2.tapal_Renew_10) + parseInt(data2.tapal_New_10);
  //     data2.rem_oct_haste_total = parseInt(data2.haste_Renew_10) + parseInt(data2.haste_New_10);
  
  //     data2.rem_nov_post_total = parseInt(data2.tapal_Renew_11) + parseInt(data2.tapal_New_11);
  //     data2.rem_nov_haste_total = parseInt(data2.haste_Renew_11) + parseInt(data2.haste_New_11);
  
  //     data2.rem_dec_post_total = parseInt(data2.tapal_Renew_12) + parseInt(data2.tapal_New_12);
  //     data2.rem_dec_haste_total = parseInt(data2.haste_Renew_12) + parseInt(data2.haste_New_12);
  
  //     data2.rem_jan_post_total = parseInt(data2.tapal_Renew_1) + parseInt(data2.tapal_New_1);
  //     data2.rem_jan_haste_total = parseInt(data2.haste_Renew_1) + parseInt(data2.haste_New_1);
  
  //     data2.rem_feb_post_total  = parseInt(data2.tapal_Renew_2) + parseInt(data2.tapal_New_2);
  //     data2.rem_feb_haste_total  = parseInt(data2.haste_Renew_2) + parseInt(data2.haste_New_2);
  
  //     data2.rem_march_post_total  = parseInt(data2.tapal_Renew_3) + parseInt(data2.tapal_New_3);
  //     data2.rem_march_haste_total  = parseInt(data2.haste_Renew_3) + parseInt(data2.haste_New_3);
  //     // const total = this.rem_march_haste_total + this.rem_march_post_total
  
  //     this.grandremtotal == this.rem_march_haste_total + this.rem_march_post_total;
  //   }

    

  // }

  for (let data2 of this.SubRemOffRep){

    for(let data1 of data2.representatives){

      
      this.post_jama = 0;
      for(let data of data1.rep_info){
        

        data.post_total_ROR = Number(data.tapal_count) + Number(data.new_tapal_count)
        data.haste_total_ROR =  Number(data.haste_count) + Number(data.new_haste_count)
        // this.post_jama +=  Number(data.tapal_count)
      }
    }
  }

    console.log("taluka renewal reming", data);

     });
}

if(this.reportType=="RemainingSubscriptionReprensantive"){
  this.service.getSubRemRep(req).subscribe((data) => {
    this.SubRemRep = data;
    // this.PaymentadvtOffInDTotal = 0;
  this.rem_april_post_total = 0;
  this.rem_april_haste_total = 0;
  this.rem_may_post_total = 0;
  this.rem_may_haste_total = 0;
  this.rem_jun_post_total = 0;
  this.rem_jun_haste_total = 0;
  this.rem_jul_post_total = 0;
  this.rem_jul_haste_total = 0;
  this.rem_aug_post_total = 0;
  this.rem_aug_haste_total = 0;
  this.rem_sep_post_total = 0;
  this.rem_sep_haste_total = 0;
  this.rem_oct_post_total = 0;
  this.rem_oct_haste_total = 0;
  this.rem_nov_post_total = 0;
  this.rem_nov_haste_total = 0;
  this.rem_dec_post_total = 0;
  this.rem_dec_haste_total = 0;
  this.rem_jan_post_total = 0;
  this.rem_jan_haste_total = 0;
  this.rem_feb_post_total = 0;
  this.rem_feb_haste_total = 0;
  this.rem_march_post_total = 0;
  this.rem_march_haste_total = 0;

  for (let data3 of this.SubRemRep) {

    for(let data2 of data3.rep_info){
      data2.rem_april_post_total = parseInt(data2.tapal_Renew_4) + parseInt(data2.tapal_New_4);
      data2.rem_april_haste_total = parseInt(data2.haste_Renew_4) + parseInt(data2.haste_New_4);
  
      data2.rem_may_post_total = parseInt(data2.tapal_Renew_5) + parseInt(data2.tapal_New_5);
      data2.rem_may_haste_total = parseInt(data2.haste_Renew_5) + parseInt(data2.haste_New_5);
  
      data2.rem_jun_post_total = parseInt(data2.tapal_Renew_6) + parseInt(data2.tapal_New_6);
      data2.rem_jun_haste_total = parseInt(data2.haste_Renew_6) + parseInt(data2.haste_New_6);
  
      data2.rem_jul_post_total = parseInt(data2.tapal_Renew_7) + parseInt(data2.tapal_New_7);
      data2.rem_jul_haste_total = parseInt(data2.haste_Renew_7) + parseInt(data2.haste_New_7);
  
      data2.rem_aug_post_total = parseInt(data2.tapal_Renew_8) + parseInt(data2.tapal_New_8);
      data2.rem_aug_haste_total = parseInt(data2.haste_Renew_8) + parseInt(data2.haste_New_8);
  
      data2.rem_sep_post_total = parseInt(data2.tapal_Renew_9) + parseInt(data2.tapal_New_9);
      data2.rem_sep_haste_total = parseInt(data2.haste_Renew_9) + parseInt(data2.haste_New_9);
  
      data2.rem_oct_post_total = parseInt(data2.tapal_Renew_10) + parseInt(data2.tapal_New_10);
      data2.rem_oct_haste_total = parseInt(data2.haste_Renew_10) + parseInt(data2.haste_New_10);
  
      data2.rem_nov_post_total = parseInt(data2.tapal_Renew_11) + parseInt(data2.tapal_New_11);
      data2.rem_nov_haste_total = parseInt(data2.haste_Renew_11) + parseInt(data2.haste_New_11);
  
      data2.rem_dec_post_total = parseInt(data2.tapal_Renew_12) + parseInt(data2.tapal_New_12);
      data2.rem_dec_haste_total = parseInt(data2.haste_Renew_12) + parseInt(data2.haste_New_12);
  
      data2.rem_jan_post_total = parseInt(data2.tapal_Renew_1) + parseInt(data2.tapal_New_1);
      data2.rem_jan_haste_total = parseInt(data2.haste_Renew_1) + parseInt(data2.haste_New_1);
  
      data2.rem_feb_post_total  = parseInt(data2.tapal_Renew_2) + parseInt(data2.tapal_New_2);
      data2.rem_feb_haste_total  = parseInt(data2.haste_Renew_2) + parseInt(data2.haste_New_2);
  
      data2.rem_march_post_total  = parseInt(data2.tapal_Renew_3) + parseInt(data2.tapal_New_3);
      data2.rem_march_haste_total  = parseInt(data2.haste_Renew_3) + parseInt(data2.haste_New_3);
      // const total = this.rem_march_haste_total + this.rem_march_post_total
  
      this.grandremtotal == this.rem_march_haste_total + this.rem_march_post_total;
    }

    

  }


    console.log("taluka renewal reming", data);
     });
}

if(this.reportType=="Renewalrep"){
     this.service.getSubByRep(req).subscribe((data) => {
      this.SubByRep = data;
      // this.PaymentadvtOffInDTotal = 0;
      console.log("subbyjilha", data);
      this.totalrepsubhilhapost = 0;
      this.totalrepsubhilhahaste = 0;
      this.totalrepsubhilhatotal = 0;

      // months
      this.aprilpost = 0;
  this.aprilhaste  = 0;
  this.apriltotal  = 0;
  this.maypost = 0;
  this.mayhaste  = 0;
  this.maytotal  = 0;
  this.junepost = 0;
  this.junehaste  = 0;
  this.junetotal  = 0;
  this.julypost = 0;
  this.julyhaste  = 0;
  this.julytotal  = 0;
  this.augpost = 0;
  this.aughaste  = 0;
  this.augtotal  = 0;
  this.seppost = 0;
  this.sephaste  = 0;
  this.septotal  = 0;
  this.octopost = 0;
  this.octohaste  = 0;
  this.octototal  = 0;
  this.novpost = 0;
  this.novhaste  = 0;
  this.novtotal  = 0;
  this.decpost = 0;
  this.dechaste  = 0;
  this.dectotal  = 0;
  this.janpost = 0;
  this.janhaste  = 0;
  this.jantotal  = 0;
  this.febpost = 0;
  this.febhaste  = 0;
  this.febtotal  = 0;
  this.marchpost = 0;
  this.marchhaste  = 0;
  this.marchtotal  = 0;

       for (let data2 of this.SubByRep) {

        for(let data1 of data2.rep_data){

          this.totalrepsubhilhapost += parseInt(data1.post_total)|| 0;
          this.totalrepsubhilhahaste += parseInt(data1.haste_total)|| 0;
          this.totalrepsubhilhatotal += parseInt(data1.grand_total)|| 0;
          // months
          this.aprilpost +=parseInt(data1.april_post_count)
          this.aprilhaste +=parseInt(data1.april_haste_count);
          this.apriltotal +=parseInt(data1.april_total);
          this.maypost+=parseInt(data1.may_post_count);
          this.mayhaste +=parseInt(data1.may_haste_count);
          this.maytotal +=parseInt(data1.may_total);
          this.junepost+=parseInt(data1.jun_post_count);
          this.junehaste +=parseInt(data1.jun_haste_count);
          this.junetotal +=parseInt(data1.jun_total);
          this.julypost+=parseInt(data1.jul_post_count);
          this.julyhaste +=parseInt(data1.jul_haste_count);
          this.julytotal +=parseInt(data1.jul_total);
          this.augpost+=parseInt(data1.aug_post_count);
          this.aughaste +=parseInt(data1.aug_haste_count);
          this.augtotal +=parseInt(data1.aug_total);
          this.seppost+=parseInt(data1.sep_post_count);
          this.sephaste +=parseInt(data1.sep_haste_count);
          this.septotal +=parseInt(data1.sep_total);
          this.octopost+=parseInt(data1.oct_post_count);
          this.octohaste +=parseInt(data1.oct_haste_count);
          this.octototal +=parseInt(data1.oct_total);
          this.novpost+=parseInt(data1.nov_post_count);
          this.novhaste +=parseInt(data1.nov_haste_count);
          this.novtotal +=parseInt(data1.nov_total);
          this.decpost+=parseInt(data1.dec_post_count);
          this.dechaste +=parseInt(data1.dec_haste_count);
          this.dectotal +=parseInt(data1.dec_total);
          this.janpost+=parseInt(data1.jan_post_count);
          this.janhaste +=parseInt(data1.jan_haste_count);
          this.jantotal +=parseInt(data1.jan_total);
          this.febpost+=parseInt(data1.feb_post_count);
          this.febhaste +=parseInt(data1.feb_haste_count);
          this.febtotal +=parseInt(data1.feb_total);
          this.marchpost+=parseInt(data1.mar_post_count);
          this.marchhaste +=parseInt(data1.mar_haste_count);
          this.marchtotal +=parseInt(data1.mar_total);
          console.log("total", this.PaymentadvtOffInDTotal)
        }
              }
       });
      }

      if(this.reportType=="RenewalOff"){
       this.service.getSubByOffRep(req).subscribe((data) => {
        this.SubByOffRep = data;
        // this.PaymentadvtOffInDTotal = 0;
        console.log("subbyjilha", data);
        this.totaloffrepsubhilhapost = 0;
        this.totaloffrepsubhilhahaste = 0;
        this.totaloffrepsubhilhatotal = 0;
        // months
        this.aprilpost = 0;
  this.aprilhaste  = 0;
  this.apriltotal  = 0;
  this.maypost = 0;
  this.mayhaste  = 0;
  this.maytotal  = 0;
  this.junepost = 0;
  this.junehaste  = 0;
  this.junetotal  = 0;
  this.julypost = 0;
  this.julyhaste  = 0;
  this.julytotal  = 0;
  this.augpost = 0;
  this.aughaste  = 0;
  this.augtotal  = 0;
  this.seppost = 0;
  this.sephaste  = 0;
  this.septotal  = 0;
  this.octopost = 0;
  this.octohaste  = 0;
  this.octototal  = 0;
  this.novpost = 0;
  this.novhaste  = 0;
  this.novtotal  = 0;
  this.decpost = 0;
  this.dechaste  = 0;
  this.dectotal  = 0;
  this.janpost = 0;
  this.janhaste  = 0;
  this.jantotal  = 0;
  this.febpost = 0;
  this.febhaste  = 0;
  this.febtotal  = 0;
  this.marchpost = 0;
  this.marchhaste  = 0;
  this.marchtotal  = 0;
         for (let data2 of this.SubByOffRep) {
          for(let data1 of data2.rep_data){
            this.totaloffrepsubhilhapost += parseInt(data1.post_total)|| 0;
            this.totaloffrepsubhilhahaste += parseInt(data1.haste_total)|| 0;
            this.totaloffrepsubhilhatotal += parseInt(data1.grand_total)|| 0;
            // months
            this.aprilpost +=parseInt(data1.april_post_count)
            this.aprilhaste +=parseInt(data1.april_haste_count);
            this.apriltotal +=parseInt(data1.april_total);
            this.maypost+=parseInt(data1.may_post_count);
            this.mayhaste +=parseInt(data1.may_haste_count);
            this.maytotal +=parseInt(data1.may_total);
            this.junepost+=parseInt(data1.jun_post_count);
            this.junehaste +=parseInt(data1.jun_haste_count);
            this.junetotal +=parseInt(data1.jun_total);
            this.julypost+=parseInt(data1.jul_post_count);
            this.julyhaste +=parseInt(data1.jul_haste_count);
            this.julytotal +=parseInt(data1.jul_total);
            this.augpost+=parseInt(data1.aug_post_count);
            this.aughaste +=parseInt(data1.aug_haste_count);
            this.augtotal +=parseInt(data1.aug_total);
            this.seppost+=parseInt(data1.sep_post_count);
            this.sephaste +=parseInt(data1.sep_haste_count);
            this.septotal +=parseInt(data1.sep_total);
            this.octopost+=parseInt(data1.oct_post_count);
            this.octohaste +=parseInt(data1.oct_haste_count);
            this.octototal +=parseInt(data1.oct_total);
            this.novpost+=parseInt(data1.nov_post_count);
            this.novhaste +=parseInt(data1.nov_haste_count);
            this.novtotal +=parseInt(data1.nov_total);
            this.decpost+=parseInt(data1.dec_post_count);
            this.dechaste +=parseInt(data1.dec_haste_count);
            this.dectotal +=parseInt(data1.dec_total);
            this.janpost+=parseInt(data1.jan_post_count);
            this.janhaste +=parseInt(data1.jan_haste_count);
            this.jantotal +=parseInt(data1.jan_total);
            this.febpost+=parseInt(data1.feb_post_count);
            this.febhaste +=parseInt(data1.feb_haste_count);
            this.febtotal +=parseInt(data1.feb_total);
            this.marchpost+=parseInt(data1.mar_post_count);
            this.marchhaste +=parseInt(data1.mar_haste_count);
            this.marchtotal +=parseInt(data1.mar_total);
            console.log("total", this.PaymentadvtOffInDTotal)
              } 
            }
         });
        }

        if(this.reportType=="Renewaltaluka"){
     this.service.getSubByTaluka(req).subscribe((data) => {
      this.SubByTaluka = data;
      // this.PaymentadvtOffInDTotal = 0;
      console.log("subbyjilha", data);
      this.totalsubhilhapost = 0;
      this.totalsubhilhahaste = 0;
      this.totalsubhilhatotal = 0;

      // months
  this.aprilpost = 0;
  this.aprilhaste  = 0;
  this.apriltotal  = 0;
  this.maypost = 0;
  this.mayhaste  = 0;
  this.maytotal  = 0;
  this.junepost = 0;
  this.junehaste  = 0;
  this.junetotal  = 0;
  this.julypost = 0;
  this.julyhaste  = 0;
  this.julytotal  = 0;
  this.augpost = 0;
  this.aughaste  = 0;
  this.augtotal  = 0;
  this.seppost = 0;
  this.sephaste  = 0;
  this.septotal  = 0;
  this.octopost = 0;
  this.octohaste  = 0;
  this.octototal  = 0;
  this.novpost = 0;
  this.novhaste  = 0;
  this.novtotal  = 0;
  this.decpost = 0;
  this.dechaste  = 0;
  this.dectotal  = 0;
  this.janpost = 0;
  this.janhaste  = 0;
  this.jantotal  = 0;
  this.febpost = 0;
  this.febhaste  = 0;
  this.febtotal  = 0;
  this.marchpost = 0;
  this.marchhaste  = 0;
  this.marchtotal  = 0;
       for (let data1 of this.SubByTaluka) {
        // pending work
         
               this.totalsubhilhapost += parseInt(data1.post_total)|| 0;
               this.totalsubhilhahaste += parseInt(data1.haste_total)|| 0;
               this.totalsubhilhatotal += parseInt(data1.grand_total)|| 0;

              //  months
              this.aprilpost +=parseInt(data1.april_post_count)
            this.aprilhaste +=parseInt(data1.april_haste_count);
            this.apriltotal +=parseInt(data1.april_total);
            this.maypost+=parseInt(data1.may_post_count);
            this.mayhaste +=parseInt(data1.may_haste_count);
            this.maytotal +=parseInt(data1.may_total);
            this.junepost+=parseInt(data1.jun_post_count);
            this.junehaste +=parseInt(data1.jun_haste_count);
            this.junetotal +=parseInt(data1.jun_total);
            this.julypost+=parseInt(data1.jul_post_count);
            this.julyhaste +=parseInt(data1.jul_haste_count);
            this.julytotal +=parseInt(data1.jul_total);
            this.augpost+=parseInt(data1.aug_post_count);
            this.aughaste +=parseInt(data1.aug_haste_count);
            this.augtotal +=parseInt(data1.aug_total);
            this.seppost+=parseInt(data1.sep_post_count);
            this.sephaste +=parseInt(data1.sep_haste_count);
            this.septotal +=parseInt(data1.sep_total);
            this.octopost+=parseInt(data1.oct_post_count);
            this.octohaste +=parseInt(data1.oct_haste_count);
            this.octototal +=parseInt(data1.oct_total);
            this.novpost+=parseInt(data1.nov_post_count);
            this.novhaste +=parseInt(data1.nov_haste_count);
            this.novtotal +=parseInt(data1.nov_total);
            this.decpost+=parseInt(data1.dec_post_count);
            this.dechaste +=parseInt(data1.dec_haste_count);
            this.dectotal +=parseInt(data1.dec_total);
            this.janpost+=parseInt(data1.jan_post_count);
            this.janhaste +=parseInt(data1.jan_haste_count);
            this.jantotal +=parseInt(data1.jan_total);
            this.febpost+=parseInt(data1.feb_post_count);
            this.febhaste +=parseInt(data1.feb_haste_count);
            this.febtotal +=parseInt(data1.feb_total);
            this.marchpost+=parseInt(data1.mar_post_count);
            this.marchhaste +=parseInt(data1.mar_haste_count);
            this.marchtotal +=parseInt(data1.mar_total);
               console.log("total", this.PaymentadvtOffInDTotal)
              }
       });
      }

      if(this.reportType=="SubscriptionTypeAll"){
      //  type wise
      this.service.getSubByTypewise(req1).subscribe((data) => {
        
        this.typewisedistrict = data;
        console.log(data, "sub deli details")
        // this.PaymentadvtOffInDTotal = 0;
        this.pvarshik = 0;
        this.ptrevarshik = 0;
        this.ppanchvarshik = 0;
        this.phindutva = 0;
        this.plova = 0;
        this.ptotal = 0;
        this.pvarshik = 0;
        this.htrevarshik = 0;
        this.hpanchvarshik = 0;
        this.hhindutva = 0;
        this.hlova = 0;
        this.htotal = 0;
        this.gtotal=0
        this.hvarshik=0
        for (let data1 of this.typewisedistrict) {
          this.pvarshik +=parseInt(data1.varshik_tapal_count);
          this.ptrevarshik +=parseInt(data1.trivarshik_tapal_count);
          this.ppanchvarshik +=parseInt(data1.panvarshik_tapal_count);
          this.phindutva +=parseInt(data1.hindutva_tapal_count);
          // this.plova +=data1.;
          this.ptotal +=parseInt(data1.post_total);
          this.hvarshik +=parseInt(data1.varshik_haste_count);
          this.htrevarshik +=parseInt(data1.trivarshik_haste_count);
          this.hpanchvarshik +=parseInt(data1.panvarshik_haste_count);
          this.hhindutva +=parseInt(data1.hindutva_haste_count);
          // this.hlova +=data1.;
          this.htotal +=parseInt(data1.haste_total);
          this.gtotal += parseInt(data1.grand_total);

        }
        // months
      
         });
        }

      if(this.reportType=="SubscriptionTypeTaluka"){
         this.service.getSubByTalukawise(req1).subscribe((data) => {
          this.typewisetaluka = data;
          console.log(data, "sub taluka details")
          // this.PaymentadvtOffInDTotal = 0;
          this.pvarshik = 0;
          this.ptrevarshik = 0;
          this.ppanchvarshik = 0;
          this.phindutva = 0;
          this.plova = 0;
          this.ptotal = 0;
          this.pvarshik = 0;
          this.htrevarshik = 0;
          this.hpanchvarshik = 0;
          this.hhindutva = 0;
          this.hlova = 0;
          this.htotal = 0;
          this.gtotal=0
          this.hvarshik=0
          // months
          for (let data1 of this.typewisetaluka) {
            this.pvarshik +=parseInt(data1.varshik_tapal_count);
            this.ptrevarshik +=parseInt(data1.trivarshik_tapal_count);
            this.ppanchvarshik +=parseInt(data1.panvarshik_tapal_count);
            this.phindutva +=parseInt(data1.hindutva_tapal_count);
            // this.plova +=data1.;
            this.ptotal +=parseInt(data1.post_total);
            this.hvarshik +=parseInt(data1.varshik_haste_count);
            this.htrevarshik +=parseInt(data1.trivarshik_haste_count);
            this.hpanchvarshik +=parseInt(data1.panvarshik_haste_count);
            this.hhindutva +=parseInt(data1.hindutva_haste_count);
            // this.hlova +=data1.;
            this.htotal +=parseInt(data1.haste_total);
            this.gtotal += parseInt(data1.grand_total);
  
          }
        
           });
          }

      if(this.reportType=="SubscriptionTypeOffReprensantive"){
           this.service.getSubByoffcierwise(req1).subscribe((data) => {
            this.typewiseofficer = data;
            console.log(data, "sub off details")
            // this.PaymentadvtOffInDTotal = 0;
            this.pvarshik = 0;
            this.ptrevarshik = 0;
            this.ppanchvarshik = 0;
            this.phindutva = 0;
            this.plova = 0;
            this.ptotal = 0;
            this.pvarshik = 0;
            this.htrevarshik = 0;
            this.hpanchvarshik = 0;
            this.hhindutva = 0;
            this.hlova = 0;
            this.htotal = 0;
            this.gtotal=0
            this.hvarshik=0
            // months
            for (let data2 of this.typewiseofficer) {

              for(let data1 of data2.rep_data){
                this.pvarshik +=parseInt(data1.varshik_tapal_count);
                this.ptrevarshik +=parseInt(data1.trivarshik_tapal_count);
                this.ppanchvarshik +=parseInt(data1.panvarshik_tapal_count);
                this.phindutva +=parseInt(data1.hindutva_tapal_count);
                // this.plova +=data1.;
                this.ptotal +=parseInt(data1.post_total);
                this.hvarshik +=parseInt(data1.varshik_haste_count);
                this.htrevarshik +=parseInt(data1.trivarshik_haste_count);
                this.hpanchvarshik +=parseInt(data1.panvarshik_haste_count);
                this.hhindutva +=parseInt(data1.hindutva_haste_count);
                // this.hlova +=data1.;
                this.htotal +=parseInt(data1.haste_total);
                this.gtotal += parseInt(data1.grand_total);
              }
             
    
            }
          
             });
            }

      if(this.reportType=="SubscriptionTypeReprensantive"){
             this.service.getSubByReprwise(req1).subscribe((data) => {
              this.typewiserep = data;
              console.log(data, "sub rep details")
              // this.PaymentadvtOffInDTotal = 0;
              this.pvarshik = 0;
              this.ptrevarshik = 0;
              this.ppanchvarshik = 0;
              this.phindutva = 0;
              this.plova = 0;
              this.ptotal = 0;
              this.pvarshik = 0;
              this.htrevarshik = 0;
              this.hpanchvarshik = 0;
              this.hhindutva = 0;
              this.hlova = 0;
              this.htotal = 0;
              this.gtotal=0
              this.hvarshik=0
              // months
              for (let data2 of this.typewiserep) {
  
                for(let data1 of data2.rep_data){
                  this.pvarshik +=parseInt(data1.varshik_tapal_count);
                  this.ptrevarshik +=parseInt(data1.trivarshik_tapal_count);
                  this.ppanchvarshik +=parseInt(data1.panvarshik_tapal_count);
                  this.phindutva +=parseInt(data1.hindutva_tapal_count);
                  // this.plova +=data1.;
                  this.ptotal +=parseInt(data1.post_total);
                  this.hvarshik +=parseInt(data1.varshik_haste_count);
                  this.htrevarshik +=parseInt(data1.trivarshik_haste_count);
                  this.hpanchvarshik +=parseInt(data1.panvarshik_haste_count);
                  this.hhindutva +=parseInt(data1.hindutva_haste_count);
                  // this.hlova +=data1.;
                  this.htotal +=parseInt(data1.haste_total);
                  this.gtotal += parseInt(data1.grand_total);
                }
               
      
              }
            
               });
              }

      // if(this.reportType=="PendingAll"){
      //           this.service.getSubByReprwise(req3).subscribe((data) => {
      //            this.typewiserep = data;
      //            console.log(data, "sub rep details")
               
      //             });
      //            }

      

      
}

filters(){

let repname:any=this.repName
if(repname!=""){
  repname=[this.repName]
}
  const req:any = {
    "rep_name":repname,
    "start_date":this.startDate,
    "end_date":this.endDate,
    "off_rep_name":this.offRepName,
    "advt_name":"",
    "issue_name" : this.advtName
  }
  const req1:any = {
   
    "start_date":this.startDate,
    "end_date":this.endDate,
    "advt_name" : this.advtName
  }
  // persoanl ledger
  this.service.getPersoanlLed(req1).subscribe((data: any) => {
    console.log("Get ledgerdata",data)
      this.getpersonalleddata = data as any;  
      this.balancecfamt =0;

      for(let data1 of this.getpersonalleddata){
        this.balancecfamt = parseInt(data1.invoice_total) - parseInt(data1.rcv_total) ||0;
      }
  });
  

  this.service.getPaymentRegiInDetails1(req).subscribe((data) => {
    
   this.advtPaymentRegiInDetailsFormBeans = data;
   console.log( this.advtPaymentRegiInDetailsFormBeans , "off rep check")
   this.PaymentadvtOffInDTotal = 0;
   this.PaymentadvtOffInDTotal_netamt = 0
    
    for (let data1 of this.advtPaymentRegiInDetailsFormBeans) {
      
            this.PaymentadvtOffInDTotal += parseFloat(data1.rcv_amt) || 0;
            this.PaymentadvtOffInDTotal_netamt += parseFloat(data1.net_amt) || 0;

       
           }
    });

    // getoffBriefly
  this.service.getAdvtOfficerBriefly1(req).subscribe((data) => {
    this.advtOfficierbrieflyFormBeans = data;
    this.OutStandingIDTotal = 0;
      this.OutStandingIDNetAmtTotal = 0;
      this.OutStandingIDUnPaidTotal = 0;
      // for (let data of this.advtOfficierbrieflyFormBeans) {
      //   for(let data1 of data.rep_data){
      //     this.OutStandingIDTotal += parseInt(data1.advt_amt_total) || 0;
      //     this.OutStandingIDNetAmtTotal += parseInt(data1.net_amt_total) || 0;
      //     this.OutStandingIDUnPaidTotal += parseInt(data1.unpaid_amt_total) || 0;
      //   } 
      // }
      for (let data of this.advtOfficierbrieflyFormBeans) {
              for (let data1 of data.rep_data) {
                  this.advtOfficierbrieflyTotal += parseInt(data1.rcv_amt) || 0;
                 
              }
          }
  });
  
 
// in officer vb
      this.service.getAdvtOfficerVb(req).subscribe((data:any) => {
        console.log("ADVT Data=",data);
      this.advtFormBeans = (data as any);;

      // Reset totals
      this.advtVBTotal = 0;

      // Calculate totals
      for (let repData of this.advtFormBeans) {
          for (let data1 of repData.rep_data) {
              this.advtVBTotal += parseInt(data1.rec_amt) || 0;
          }
      }
      });

  // officer in detail
  this.service.getPaymentRegiOffInDetails(req).subscribe((data: any) => {
    console.log("new",data)
      this.PaymentadvtOffInDFormBenas = data as any;
      console.log(this.PaymentadvtOffInDFormBenas, "newlist 3 loop" )
      // Reset totals
      // this.PaymentadvtOffInDTotal = 0;
      this.PaymentadvtOffInDTotal2 = 0;
      this.PaymentadvtOffInDTotal3 = 0;



      // Calculate totals
      for (let repData of this.PaymentadvtOffInDFormBenas) {
        this.PaymentadvtOffInDTotal2 += parseInt(repData.total) || 0;

          for (let data1 of repData.rep_data) {
              // this.PaymentadvtOffInDTotal += parseInt(data1.rcv_amt) || 0;
              // // this.PaymentadvtOffInDTotal2 += parseInt(repdata.total) || 0;
              // this.PaymentadvtOffInDTotal3 += parseInt(data1.rcv_amt) || 0;
          }
      }
      console.log("offcr total",this.PaymentadvtOffInDTotal)
  });
    // payment briefly
    this.service.getAdvtBriefly(req).subscribe((data:any) => {
      console.log("ADVT Brielfy Data=",data);
     this.advtBrieflyFormBeans = (data as any);;

     this.advtBrieflyTotal = 0;
     for (let repData of this.advtBrieflyFormBeans) {
           this.advtBrieflyTotal += parseInt(repData.rcv_amt) || 0;   
       
   }
   });
  //  payment monthwise
  this.service.getAdvtMonthwise(req).subscribe((data:any) => {
    console.log("ADVT Data=",data);
   this.advtMonthwiseFormBeans = (data as any);;
   // Reset totals
   this.advtMonthwiseTotal = 0;

   // Calculate totals
   for (let data of this.advtMonthwiseFormBeans) {
       
           this.advtMonthwiseTotal += parseFloat(data.rcv_amt) || 0;
          }
 });

   // GST Report
        this.service.GSTFullReport(req).subscribe((data:any) => {
          console.log("GST",data);
        this.GSTfullreportFormBeans = (data as any);;
       
        this.GSTAdvtAmt=0;
        this.GSTDiscount=0;
        this.GSTNetAdvtAmt=0;
        this.CGSTAmount=0;
        this.SGSTAmount=0;
        this.AllGSTAmount=0;
        this.beforegstamt =0

        // Calculate totals
        for (let data of this.GSTfullreportFormBeans) {
            
                // this.advtMonthwiseTotal += parseInt(data.total_amt) || 0;
                  // this.advtMonthwiseTotal += parseInt(data.total_amt) || 0;
                  this.GSTAdvtAmt +=parseInt(data.adv_amt) || 0;
                  this.GSTDiscount +=parseInt(data.advt_discount) || 0;
                  this.GSTNetAdvtAmt +=parseInt(data.net_amt) || 0;
                  this.CGSTAmount +=parseInt(data.cgst) || 0;
                  this.SGSTAmount +=parseInt(data.sgst) || 0;
                  this.AllGSTAmount +=parseInt(data.gst_amount) || 0;
                  this.beforegstamt +=parseInt(data.before_gst_amt) || 0;
                        }
        });

        this.service.getAdvtOutstanding(req).subscribe((data: any) => {
              console.log("outstanding 3",data)
                this.OutstandingInDetailFormBeans = data as any;
                
                this.OutStandingIDTotal = 0;
                this.OutStandingIDNetAmtTotal = 0;
                this.OutStandingIDUnPaidTotal = 0;
                for (let data of this.OutstandingInDetailFormBeans) {
                  for(let data1 of data.rep_data){
                    this.OutStandingIDTotal += parseInt(data1.advt_amt_total) || 0;
                    this.OutStandingIDNetAmtTotal += parseInt(data1.net_amt_total) || 0;
                    this.OutStandingIDUnPaidTotal += parseInt(data1.unpaid_amt_total) || 0;
                  } 
                }
            });
            
      this.service.getOutstandingB(req).subscribe((data: any) => {
        this.OutstandingB = data as any;

        // Reset totals
        this.totalAdvtAmount = 0;
        this.totalNetAmount = 0;
        this.totalUnpaidAmount = 0;

        // Calculate totals
        for (let repData of this.OutstandingB) {
            for (let data1 of repData.rep_data) {
                this.totalAdvtAmount += parseInt(data1.contract_bkng_advt_amt) || 0;
                this.totalNetAmount += parseInt(data1.contract_net_total) || 0;
                this.totalUnpaidAmount += parseInt(data1.contract_bal_amt) || 0;
            }
        }
    });

    // Paid Commission 
    this.service.getPaidCommData(req).subscribe((data: any) => {
      console.log("Get Paid Comm",data)
        this.getpaiddata = data as any; 

        this.paidcommTotal=0;
        for(let data1 of this.getpaiddata){
          for(let data2 of data1.rep_data){
            this.paidcommTotal += parseInt(data2.rcv_amt) || 0;
          }
        }
        
    });

     // persoanl ledger indeatils 
    

     // persoanl briefly ledger indeatils 
     this.service.getPersoanlLedBriefly(req).subscribe((data: any) => {
      console.log("Get ledgerdata brifly",data)
        this.getpersonalledBridflydata = data as any;  
        this.Totalop = 0;
        this.TotalDebit= 0;
        this.TotalCredit = 0;
        this.Totalbal=0;

        for(let data of this.getpersonalledBridflydata ){
          this.Totalop += parseInt(data.opening_bal) ||0;
          this.TotalDebit += parseInt(data.net_amt) ||0;
          this.TotalCredit += parseInt(data.credit_amt) ||0;
          this.Totalbal += parseInt(data.balance) ||0;
          data.totalbalance = parseFloat(data.bal_amt) +  parseFloat(data.opening_bal)

        }
    });

// creditoutstanidngindetails
this.service.creditoutstaqndingindetails(req).subscribe((data: any) => {
  console.log("credit outstanding ",data)
    this.creditoutstandingindetails = data as any;
    
    this.creditonlyOutStandingIDTotal = 0;
    this.creditonlyOutStandingIDNetAmtTotal = 0;
    this.creditonlyOutStandingIDUnPaidTotal = 0;
    for (let data of this.creditoutstandingindetails) {
      for(let data1 of data.rep_data){
        this.creditonlyOutStandingIDTotal += parseInt(data1.advt_amt_total) || 0;
        this.creditonlyOutStandingIDNetAmtTotal += parseInt(data1.net_amt_total) || 0;
        this.creditonlyOutStandingIDUnPaidTotal += parseInt(data1.unpaid_amt_total) || 0;
      } 
    }
});



    // only outstanidng
    this.service.getonlyOutstandingindetail(req).subscribe((data: any) => {
      console.log("outstanding 3",data)
        this.onlyOutstandingInDetailFormBeans = data as any;
        
        this.onlyOutStandingIDTotal = 0;
        this.onlyOutStandingIDNetAmtTotal = 0;
        this.onlyOutStandingIDUnPaidTotal = 0;
        for (let data of this.onlyOutstandingInDetailFormBeans) {
          for(let data1 of data.rep_data){
            this.onlyOutStandingIDTotal += parseInt(data1.advt_amt_total) || 0;
            this.onlyOutStandingIDNetAmtTotal += parseInt(data1.net_amt_total) || 0;
            this.onlyOutStandingIDUnPaidTotal += parseInt(data1.unpaid_amt_total) || 0;
          } 
        }
    });
    // only outstanding briefly


    this.service.getonlyOutstandingB(req).subscribe((data: any) => {
      this.onlyOutstandingB = data as any;

      // Reset totals
      this.onlytotalAdvtAmount = 0;
      this.onlytotalNetAmount = 0;
      this.onlytotalUnpaidAmount = 0;

      // Calculate totals
      for (let repData of this.onlyOutstandingB) {
          for (let data1 of repData.rep_data) {
              this.onlytotalAdvtAmount += parseInt(data1.contract_bkng_advt_amt) || 0;
              this.onlytotalNetAmount += parseInt(data1.contract_net_total) || 0;
              this.onlytotalUnpaidAmount += parseInt(data1.contract_bal_amt) || 0;
          }
      }
  });

  // credit briefly outstanidng
  this.service.getcreditbrieflyoutstanding(req).subscribe((data: any) => {
    this.creditoutstandongbrief = data as any;

    // Reset totals
    this.creditlastonlytotalAdvtAmount = 0;
    this.creditlastonlytotalNetAmount = 0;
    this.creditlastonlytotalUnpaidAmount = 0;

    // Calculate totals
    for (let repData of this.creditoutstandongbrief) {
        for (let data1 of repData.rep_data) {
            this.creditlastonlytotalAdvtAmount += parseInt(data1.contract_bkng_advt_amt) || 0;
            this.creditlastonlytotalNetAmount += parseInt(data1.contract_net_total) || 0;
            this.creditlastonlytotalUnpaidAmount += parseInt(data1.contract_bal_amt) || 0;
        }
    }
});

  // pendingreceipt one
  this.service.getpendingreceipt(req).subscribe((data: any) => {
    this.getpendingreceipt = data as any;
    console.log("pending receipt",data)


    // Reset totals
    this.PendingReceiptAmt = 0;
    this.PendingBalanceAmt = 0;
    // this.onlytotalUnpaidAmount = 0;

    // Calculate totals
    for (let data1 of this.getpendingreceipt) {
            this.PendingReceiptAmt += parseInt(data1.advt_amt) || 0;
            this.PendingBalanceAmt += parseInt(data1.remain_amt) || 0;
            // this.onlytotalUnpaidAmount += parseInt(data1.contract_bal_amt) || 0;
       
    }
});

this.service.getpendingreceiptRep(req).subscribe((data: any) => {
  this.getpendingreceiptrep = data as any;
  console.log("pending receipt",data)


  // Reset totals
  this.PendingReceiptAmt = 0;
  this.PendingBalanceAmt = 0;
  // this.onlytotalUnpaidAmount = 0;

  // Calculate totals
  for (let data1 of this.getpendingreceiptrep) {
          this.PendingReceiptAmt += parseInt(data1.advt_amt) || 0;
          this.PendingBalanceAmt += parseInt(data1.remain_amt) || 0;
          // this.onlytotalUnpaidAmount += parseInt(data1.contract_bal_amt) || 0;
     
  }
});


}

  calculatetotalweeks() {
    for (let index = 0; index < 12; index++) {
      if (index < 3) {
        this.totalweeks += this.sundays(
          formatDate(this.nexttoday, 'yyyy', 'en-IN', 'GMT+0530'),
          index
        );
      } else {
        this.totalweeks += this.sundays(
          formatDate(this.today, 'yyyy', 'en-IN', 'GMT+0530'),
          index
        );
      }
    }
    // console.log(this.totalweeks);
  }

  firstSunday(month, year) {
    let tempDate = new Date();
    tempDate.setHours(0, 0, 0, 0);
    // first SUNDAY of april
    tempDate.setMonth(month);
    tempDate.setFullYear(year);
    tempDate.setDate(1);

    let day = tempDate.getDay();
    let toNextSun = day !== 0 ? 7 - day : 0;
    tempDate.setDate(tempDate.getDate() + toNextSun);

    // return formatDate(tempDate, 'dd/MM/yy', 'en-IN', 'GMT+0530');
    return tempDate;
  }

  secondSunday(month, year) {
    // console.log(
    //   'this.firstSunday(month, year)',
    //   formatDate(
    //     this.firstSunday(month, year),
    //     'dd-MM-yyyy',
    //     'en-IN',
    //     'GMT+0530'
    //   )
    // );
    let tempDate = new Date(this.firstSunday(month, year));
    // console.log('tempdate', tempDate);

    tempDate.setHours(0, 0, 0, 0);
    // first SUNDAY of april
    // tempDate.setMonth(month);
    // tempDate.setFullYear(year);
    tempDate.setDate(tempDate.getDate() + 7);

    let day = tempDate.getDay();
    // console.log('day', day);

    let toNextSun = day !== 0 ? 7 - day : 0;
    // console.log('toNextSun', toNextSun);
    tempDate.setDate(tempDate.getDate() + toNextSun);

    return tempDate;
  }

  thirdSunday(month, year) {
    // console.log(
    //   'this.secondSunday(month, year)',
    //   formatDate(
    //     this.secondSunday(month, year),
    //     'dd-MM-yyyy',
    //     'en-IN',
    //     'GMT+0530'
    //   )
    // );
    let tempDate = new Date(this.secondSunday(month, year));
    // console.log('tempdate', tempDate);
    tempDate.setHours(0, 0, 0, 0);
    // first SUNDAY of april
    // tempDate.setMonth(month);
    // tempDate.setFullYear(year);
    tempDate.setDate(tempDate.getDate() + 7);

    let day = tempDate.getDay();
    // console.log('thirdsunday', day);

    let toNextSun = day !== 0 ? 7 - day : 0;
    // console.log('toNextSun', toNextSun);
    tempDate.setDate(tempDate.getDate() + toNextSun);

    return tempDate;
  }

  fourthSunday(month, year) {
    let tempDate = new Date(this.thirdSunday(month, year));
    tempDate.setHours(0, 0, 0, 0);
    // first SUNDAY of april
    // tempDate.setMonth(month);
    // tempDate.setFullYear(year);
    // console.log('tempDate', tempDate);
    tempDate.setDate(tempDate.getDate() + 7);
    let day = tempDate.getDay();
    // console.log('thirdsunday', day);
    let toNextSun = day !== 0 ? 7 - day : 0;
    // console.log('toNextSun', toNextSun);
    tempDate.setDate(tempDate.getDate() + toNextSun);

    return tempDate;
  }

  fifthSunday(month, year) {
    let tempDate = new Date(this.fourthSunday(month, year));
    tempDate.setHours(0, 0, 0, 0);
    // first SUNDAY of april
    // tempDate.setMonth(month);
    // tempDate.setFullYear(year);
    // console.log('tempDate', tempDate);
    tempDate.setDate(tempDate.getDate() + 7);

    let day = tempDate.getDay();
    // console.log('thirdsunday', day);
    let toNextSun = day !== 0 ? 7 - day : 0;
    // console.log('toNextSun', toNextSun);
    tempDate.setDate(tempDate.getDate() + toNextSun);

    return tempDate;
  }

  sundays(year, month) {
    let day = 1;
    let counter = 0;
    let date = new Date(year, month, day);
    while (date.getMonth() === month) {
      if (date.getDay() === 6) {
        counter++;
      }
      day++;
      date = new Date(year, month, day);
    }
    return counter;
  }

  filterData() {
    if (
      this.startDate === '' &&
      this.endDate === '' &&
      this.repName === '' &&
      this.offRepName === '' &&
      this.advtName === ''
    ) {
      this.filteredcontractData = this.contractData;
    } else if (
      this.startDate !== '' &&
      this.endDate !== '' &&
      this.repName == '' &&
      this.offRepName == '' &&
      this.advtName == ''
    ) {
      this.filteredcontractData = this.contractData.filter((a) => {
        return (
          a['contract_date'] >= this.startDate &&
          a['contract_date'] <= this.endDate
        );
      });
    } else if (
      this.startDate == '' &&
      this.endDate == '' &&
      this.repName !== '' &&
      this.offRepName == '' &&
      this.advtName == ''
    ) {
      this.filteredcontractData = this.contractData.filter((a) => {
        return this.repName.includes(a['rep_name']);
      });
    } else if (
      this.startDate == '' &&
      this.endDate == '' &&
      this.repName == '' &&
      this.offRepName !== '' &&
      this.advtName == ''
    ) {
      this.filteredcontractData = this.contractData.filter((a) => {
        return this.offRepName.includes(a['off_rep_name']);
      });
    } else if (
      this.startDate == '' &&
      this.endDate == '' &&
      this.repName == '' &&
      this.offRepName == '' &&
      this.advtName !== ''
    ) {
      this.filteredcontractData = this.contractData.filter((a) => {
        return this.advtName.includes(a['advt_name']);
      });
    } else if (
      this.startDate !== '' &&
      this.endDate !== '' &&
      this.repName !== '' &&
      this.offRepName == '' &&
      this.advtName == ''
    ) {
      this.filteredcontractData = this.contractData.filter((a) => {
        return (
          a['contract_date'] >= this.startDate &&
          a['contract_date'] <= this.endDate &&
          this.repName.includes(a['rep_name'])
        );
      });
    } else if (
      this.startDate !== '' &&
      this.endDate !== '' &&
      this.repName == '' &&
      this.offRepName !== '' &&
      this.advtName == ''
    ) {
      this.filteredcontractData = this.contractData.filter((a) => {
        return (
          a['contract_date'] >= this.startDate &&
          a['contract_date'] <= this.endDate &&
          this.offRepName.includes(a['off_rep_name'])
        );
      });
    } else if (
      this.startDate !== '' &&
      this.endDate !== '' &&
      this.repName == '' &&
      this.offRepName == '' &&
      this.advtName !== ''
    ) {
      this.filteredcontractData = this.contractData.filter((a) => {
        return (
          a['contract_date'] >= this.startDate &&
          a['contract_date'] <= this.endDate &&
          this.advtName.includes(a['advt_name'])
        );
      });
    } else if (
      this.startDate == '' &&
      this.endDate == '' &&
      this.repName !== '' &&
      this.offRepName !== '' &&
      this.advtName == ''
    ) {
      this.filteredcontractData = this.contractData.filter((a) => {
        return (
          this.repName.includes(a['rep_name']) &&
          this.offRepName.includes(a['off_rep_name'])
        );
      });
    } else if (
      this.startDate == '' &&
      this.endDate == '' &&
      this.repName !== '' &&
      this.offRepName == '' &&
      this.advtName !== ''
    ) {
      this.filteredcontractData = this.contractData.filter((a) => {
        return (
          this.repName.includes(a['rep_name']) &&
          this.advtName.includes(a['advt_name'])
        );
      });
    } else if (
      this.startDate == '' &&
      this.endDate == '' &&
      this.repName == '' &&
      this.offRepName !== '' &&
      this.advtName !== ''
    ) {
      this.filteredcontractData = this.contractData.filter((a) => {
        return (
          this.offRepName.includes(a['off_rep_name']) &&
          this.advtName.includes(a['advt_name'])
        );
      });
    } else if (
      this.startDate !== '' &&
      this.endDate !== '' &&
      this.repName !== '' &&
      this.offRepName !== '' &&
      this.advtName == ''
    ) {
      this.filteredcontractData = this.contractData.filter((a) => {
        return (
          a['contract_date'] >= this.startDate &&
          a['contract_date'] <= this.endDate &&
          this.repName.includes(a['rep_name']) &&
          this.offRepName.includes(a['off_rep_name'])
        );
      });
    } else if (
      this.startDate !== '' &&
      this.endDate !== '' &&
      this.repName !== '' &&
      this.offRepName == '' &&
      this.advtName !== ''
    ) {
      this.filteredcontractData = this.contractData.filter((a) => {
        return (
          a['contract_date'] >= this.startDate &&
          a['contract_date'] <= this.endDate &&
          this.repName.includes(a['rep_name']) &&
          this.advtName.includes(a['advt_name'])
        );
      });
    } else if (
      this.startDate == '' &&
      this.endDate == '' &&
      this.repName !== '' &&
      this.offRepName !== '' &&
      this.advtName !== ''
    ) {
      this.filteredcontractData = this.contractData.filter((a) => {
        return (
          this.repName.includes(a['rep_name']) &&
          this.offRepName.includes(a['off_rep_name']) &&
          this.advtName.includes(a['advt_name'])
        );
      });
    } else if (
      this.startDate !== '' &&
      this.endDate !== '' &&
      this.repName !== '' &&
      this.offRepName !== '' &&
      this.advtName !== ''
    )
     {
      this.filteredcontractData = this.contractData.filter((a) => {
        return (
          
          a['contract_date'] >= this.datePipe.transform(this.startDate,'yyyy-MM-dd') &&
          a['contract_date'] <= this.datePipe.transform(this.endDate,'yyyy-MM-dd') &&
          this.repName.includes(a['rep_name']) &&
          this.offRepName.includes(a['off_rep_name']) &&
          this.advtName.includes(a['advt_name'])
        );
      });
    }
    this.calculateTotal();
    // console.log(this.filteredcontractData);
  }

  calculateTotal() {
    for (
      let index = 0;
      index < Object.keys(this.filteredcontractData).length;
      index++
    ) {
      this.totalData['contract_prev_bal'] += parseFloat(
        this.filteredcontractData[index]['contract_prev_bal']
      );
      this.totalData['contract_amt'] += parseFloat(
        this.filteredcontractData[index]['contract_amt']
      );
      this.totalData['contract_discnt_amt'] += parseFloat(
        this.filteredcontractData[index]['contract_discnt_amt']
      );
      this.totalData['contract_net_amt'] += parseFloat(
        this.filteredcontractData[index]['contract_net_amt']
      );
      this.totalData['contract_net_gst_amt'] += parseFloat(
        this.filteredcontractData[index]['contract_net_gst_amt']
      );
      this.totalData['contract_net_total'] += parseFloat(
        this.filteredcontractData[index]['contract_net_total']
      );
    }
  }

  exportExcel(fileName): void {
    /* table id is passed over here */
    let element = document.getElementById('print-section');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, fileName + '.xlsx');
  }

  printPage() {
    window.print();
  }

  getData() {
    let data = this.uniqueData(
      this.filteredcontractData,
      (a) => a.off_rep_name
    );
    // console.log(data);

    for (let index = 0; index < data.length; index++) {
      let commonData = this.filteredcontractData.filter((a) => {
        return a.off_rep_name == data[index]['off_rep_name'];
      });

      // console.log(commonData);
      let sortedDataCommon = [];
      let contract_prev_bal = 0;
      let remaining_contract = 0;
      let contract_amt = 0;
      let contract_discnt_amt = 0;
      let contract_net_amt = 0;
      let contract_net_gst_amt = 0;
      let contract_net_total = 0;
      for (let i = 0; i < commonData.length; i++) {
        contract_prev_bal += parseFloat(commonData[i]['contract_prev_bal']);
        remaining_contract += parseFloat(commonData[i]['contract_bal_amt']);
        contract_amt += parseFloat(commonData[i]['contract_amt']);
        contract_discnt_amt += parseFloat(commonData[i]['contract_discnt_amt']);
        contract_net_amt += parseFloat(commonData[i]['contract_net_amt']);
        contract_net_gst_amt += parseFloat(
          commonData[i]['contract_net_gst_amt']
        );
        contract_net_total += parseFloat(commonData[i]['contract_net_total']);

        // console.log();

        sortedDataCommon.push({
          DS_ADVTID: commonData[i]['DS_ADVTID'],
          advt_amt: commonData[i]['advt_amt'],
          advt_discnt: commonData[i]['advt_discnt'],
          advt_name: commonData[i]['advt_name'],
          amount: commonData[i]['amount'],
          booking_date: commonData[i]['booking_date'],
          booking_no: commonData[i]['booking_no'],
          cgst: commonData[i]['cgst'],
          contract_amt: commonData[i]['contract_amt'],
          contract_bal_amt: commonData[i]['contract_bal_amt'],
          contract_bkng_advt_amt: commonData[i]['contract_bkng_advt_amt'],
          contract_date: commonData[i]['contract_date'],
          contract_discnt_amt: commonData[i]['contract_discnt_amt'],
          contract_id: commonData[i]['contract_id'],
          contract_net_amt: commonData[i]['contract_net_amt'],
          contract_net_gst_amt: commonData[i]['contract_net_gst_amt'],
          contract_net_total: commonData[i]['contract_net_total'],
          contract_no: commonData[i]['contract_no'],
          contract_period_from: commonData[i]['contract_period_from'],
          contract_period_to: commonData[i]['contract_period_to'],
          contract_prev_bal: commonData[i]['contract_prev_bal'],
          cust_name: commonData[i]['cust_name'],
          gst_include_exclude: commonData[i]['gst_include_exclude'],
          igst: commonData[i]['igst'],
          instruction: commonData[i]['instruction'],
          issue_date: commonData[i]['issue_date'],
          issue_name: commonData[i]['issue_name'],
          off_rep_name: commonData[i]['off_rep_name'],
          page_no: commonData[i]['page_no'],
          pages_no: commonData[i]['pages_no'],
          rep_mob_no: commonData[i]['rep_mob_no'],
          rep_name: commonData[i]['rep_name'],
          sgst: commonData[i]['sgst'],
          size: commonData[i]['size'],
          sub_gst_include_exclude: commonData[i]['sub_gst_include_exclude'],
          sub_id: commonData[i]['sub_id'],
          total_amount: commonData[i]['total_amount'],
          april1stWeekSize:
            this.formattedDate(
              this.firstSunday(3, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['size']
              : '',
          april1stWeekAmt:
            this.formattedDate(
              this.firstSunday(3, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['total_amount']
              : '',
          april1stWeekPageNo:
            this.formattedDate(
              this.firstSunday(3, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['pages_no']
              : '',
          april2ndWeekSize:
            this.formattedDate(
              this.secondSunday(3, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['size']
              : '',
          april2ndWeekAmt:
            this.formattedDate(
              this.secondSunday(3, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['total_amount']
              : '',
          april2ndWeekPageNo:
            this.formattedDate(
              this.secondSunday(3, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['pages_no']
              : '',
          april3rdWeekSize:
            this.formattedDate(
              this.thirdSunday(3, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['size']
              : '',
          april3rdWeekAmt:
            this.formattedDate(
              this.thirdSunday(3, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['total_amount']
              : '',
          april3rdWeekPageNo:
            this.formattedDate(
              this.thirdSunday(3, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['pages_no']
              : '',
          april4thWeekSize:
            this.formattedDate(
              this.fourthSunday(3, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['size']
              : '',
          april4thWeekAmt:
            this.formattedDate(
              this.fourthSunday(3, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['total_amount']
              : '',
          april4thWeekPageNo:
            this.formattedDate(
              this.fourthSunday(3, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['pages_no']
              : '',
          april5thWeekSize:
            this.formattedDate(
              this.fifthSunday(3, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['size']
              : '',
          april5thWeekAmt:
            this.formattedDate(
              this.fifthSunday(3, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['total_amount']
              : '',
          april5thWeekPageNo:
            this.formattedDate(
              this.fifthSunday(3, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['pages_no']
              : '',
          may1stWeekSize:
            this.formattedDate(
              this.firstSunday(4, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['size']
              : '',
          may1stWeekAmt:
            this.formattedDate(
              this.firstSunday(4, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['total_amount']
              : '',
          may1stWeekPageNo:
            this.formattedDate(
              this.firstSunday(4, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['pages_no']
              : '',
          may2ndWeekSize:
            this.formattedDate(
              this.secondSunday(4, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['size']
              : '',
          may2ndWeekAmt:
            this.formattedDate(
              this.secondSunday(4, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['total_amount']
              : '',
          may2ndWeekPageNo:
            this.formattedDate(
              this.secondSunday(4, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['pages_no']
              : '',
          may3rdWeekSize:
            this.formattedDate(
              this.thirdSunday(4, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['size']
              : '',
          may3rdWeekAmt:
            this.formattedDate(
              this.thirdSunday(4, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['total_amount']
              : '',
          may3rdWeekPageNo:
            this.formattedDate(
              this.thirdSunday(4, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['pages_no']
              : '',
          may4thWeekSize:
            this.formattedDate(
              this.fourthSunday(4, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['size']
              : '',
          may4thWeekAmt:
            this.formattedDate(
              this.fourthSunday(4, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['total_amount']
              : '',
          may4thWeekPageNo:
            this.formattedDate(
              this.fourthSunday(4, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['pages_no']
              : '',
          may5thWeekSize:
            this.formattedDate(
              this.fifthSunday(4, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['size']
              : '',
          may5thWeekAmt:
            this.formattedDate(
              this.fifthSunday(4, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['total_amount']
              : '',
          may5thWeekPageNo:
            this.formattedDate(
              this.fifthSunday(4, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['pages_no']
              : '',
          june1stWeekSize:
            this.formattedDate(
              this.firstSunday(5, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['size']
              : '',
          june1stWeekAmt:
            this.formattedDate(
              this.firstSunday(5, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['total_amount']
              : '',
          june1stWeekPageNo:
            this.formattedDate(
              this.firstSunday(5, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['pages_no']
              : '',
          june2ndWeekSize:
            this.formattedDate(
              this.secondSunday(5, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['size']
              : '',
          june2ndWeekAmt:
            this.formattedDate(
              this.secondSunday(5, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['total_amount']
              : '',
          june2ndWeekPageNo:
            this.formattedDate(
              this.secondSunday(5, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['pages_no']
              : '',
          june3rdWeekSize:
            this.formattedDate(
              this.thirdSunday(5, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['size']
              : '',
          june3rdWeekAmt:
            this.formattedDate(
              this.thirdSunday(5, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['total_amount']
              : '',
          june3rdWeekPageNo:
            this.formattedDate(
              this.thirdSunday(5, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['pages_no']
              : '',
          june4thWeekSize:
            this.formattedDate(
              this.fourthSunday(5, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['size']
              : '',
          june4thWeekAmt:
            this.formattedDate(
              this.fourthSunday(5, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['total_amount']
              : '',
          june4thWeekPageNo:
            this.formattedDate(
              this.fourthSunday(5, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['pages_no']
              : '',
          june5thWeekSize:
            this.formattedDate(
              this.fifthSunday(5, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['size']
              : '',
          june5thWeekAmt:
            this.formattedDate(
              this.fifthSunday(5, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['total_amount']
              : '',
          june5thWeekPageNo:
            this.formattedDate(
              this.fifthSunday(5, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['pages_no']
              : '',
          july1stWeekSize:
            this.formattedDate(
              this.firstSunday(6, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['size']
              : '',
          july1stWeekAmt:
            this.formattedDate(
              this.firstSunday(6, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['total_amount']
              : '',
          july1stWeekPageNo:
            this.formattedDate(
              this.firstSunday(6, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['pages_no']
              : '',
          july2ndWeekSize:
            this.formattedDate(
              this.secondSunday(6, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['size']
              : '',
          july2ndWeekAmt:
            this.formattedDate(
              this.secondSunday(6, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['total_amount']
              : '',
          july2ndWeekPageNo:
            this.formattedDate(
              this.secondSunday(6, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['pages_no']
              : '',
          july3rdWeekSize:
            this.formattedDate(
              this.thirdSunday(6, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['size']
              : '',
          july3rdWeekAmt:
            this.formattedDate(
              this.thirdSunday(6, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['total_amount']
              : '',
          july3rdWeekPageNo:
            this.formattedDate(
              this.thirdSunday(6, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['pages_no']
              : '',
          july4thWeekSize:
            this.formattedDate(
              this.fourthSunday(6, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['size']
              : '',
          july4thWeekAmt:
            this.formattedDate(
              this.fourthSunday(6, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['total_amount']
              : '',
          july4thWeekPageNo:
            this.formattedDate(
              this.fourthSunday(6, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['pages_no']
              : '',
          july5thWeekSize:
            this.formattedDate(
              this.fifthSunday(6, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['size']
              : '',
          july5thWeekAmt:
            this.formattedDate(
              this.fifthSunday(6, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['total_amount']
              : '',
          july5thWeekPageNo:
            this.formattedDate(
              this.fifthSunday(6, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['pages_no']
              : '',
          august1stWeekSize:
            this.formattedDate(
              this.firstSunday(7, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['size']
              : '',
          august1stWeekAmt:
            this.formattedDate(
              this.firstSunday(7, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['total_amount']
              : '',
          august1stWeekPageNo:
            this.formattedDate(
              this.firstSunday(7, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['pages_no']
              : '',
          august2ndWeekSize:
            this.formattedDate(
              this.secondSunday(7, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['size']
              : '',
          august2ndWeekAmt:
            this.formattedDate(
              this.secondSunday(7, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['total_amount']
              : '',
          august2ndWeekPageNo:
            this.formattedDate(
              this.secondSunday(7, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['pages_no']
              : '',
          august3rdWeekSize:
            this.formattedDate(
              this.thirdSunday(7, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['size']
              : '',
          august3rdWeekAmt:
            this.formattedDate(
              this.thirdSunday(7, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['total_amount']
              : '',
          august3rdWeekPageNo:
            this.formattedDate(
              this.thirdSunday(7, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['pages_no']
              : '',
          august4thWeekSize:
            this.formattedDate(
              this.fourthSunday(7, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['size']
              : '',
          august4thWeekAmt:
            this.formattedDate(
              this.fourthSunday(7, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['total_amount']
              : '',
          august4thWeekPageNo:
            this.formattedDate(
              this.fourthSunday(7, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['pages_no']
              : '',
          august5thWeekSize:
            this.formattedDate(
              this.fifthSunday(7, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['size']
              : '',
          august5thWeekAmt:
            this.formattedDate(
              this.fifthSunday(7, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['total_amount']
              : '',
          august5thWeekPageNo:
            this.formattedDate(
              this.fifthSunday(7, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['pages_no']
              : '',
          september1stWeekSize:
            this.formattedDate(
              this.firstSunday(8, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['size']
              : '',
          september1stWeekAmt:
            this.formattedDate(
              this.firstSunday(8, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['total_amount']
              : '',
          september1stWeekPageNo:
            this.formattedDate(
              this.firstSunday(8, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['pages_no']
              : '',
          september2ndWeekSize:
            this.formattedDate(
              this.secondSunday(8, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['size']
              : '',
          september2ndWeekAmt:
            this.formattedDate(
              this.secondSunday(8, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['total_amount']
              : '',
          september2ndWeekPageNo:
            this.formattedDate(
              this.secondSunday(8, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['pages_no']
              : '',
          september3rdWeekSize:
            this.formattedDate(
              this.thirdSunday(8, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['size']
              : '',
          september3rdWeekAmt:
            this.formattedDate(
              this.thirdSunday(8, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['total_amount']
              : '',
          september3rdWeekPageNo:
            this.formattedDate(
              this.thirdSunday(8, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['pages_no']
              : '',
          september4thWeekSize:
            this.formattedDate(
              this.fourthSunday(8, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['size']
              : '',
          september4thWeekAmt:
            this.formattedDate(
              this.fourthSunday(8, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['total_amount']
              : '',
          september4thWeekPageNo:
            this.formattedDate(
              this.fourthSunday(8, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['pages_no']
              : '',
          september5thWeekSize:
            this.formattedDate(
              this.fifthSunday(8, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['size']
              : '',
          september5thWeekAmt:
            this.formattedDate(
              this.fifthSunday(8, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['total_amount']
              : '',
          september5thWeekPageNo:
            this.formattedDate(
              this.fifthSunday(8, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['pages_no']
              : '',
          october1stWeekSize:
            this.formattedDate(
              this.firstSunday(9, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['size']
              : '',
          october1stWeekAmt:
            this.formattedDate(
              this.firstSunday(9, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['total_amount']
              : '',
          october1stWeekPageNo:
            this.formattedDate(
              this.firstSunday(9, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['pages_no']
              : '',
          october2ndWeekSize:
            this.formattedDate(
              this.secondSunday(9, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['size']
              : '',
          october2ndWeekAmt:
            this.formattedDate(
              this.secondSunday(9, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['total_amount']
              : '',
          october2ndWeekPageNo:
            this.formattedDate(
              this.secondSunday(9, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['pages_no']
              : '',
          october3rdWeekSize:
            this.formattedDate(
              this.thirdSunday(9, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['size']
              : '',
          october3rdWeekAmt:
            this.formattedDate(
              this.thirdSunday(9, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['total_amount']
              : '',
          october3rdWeekPageNo:
            this.formattedDate(
              this.thirdSunday(9, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['pages_no']
              : '',
          october4thWeekSize:
            this.formattedDate(
              this.fourthSunday(9, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['size']
              : '',
          october4thWeekAmt:
            this.formattedDate(
              this.fourthSunday(9, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['total_amount']
              : '',
          october4thWeekPageNo:
            this.formattedDate(
              this.fourthSunday(9, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['pages_no']
              : '',
          october5thWeekSize:
            this.formattedDate(
              this.fifthSunday(9, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['size']
              : '',
          october5thWeekAmt:
            this.formattedDate(
              this.fifthSunday(9, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['total_amount']
              : '',
          october5thWeekPageNo:
            this.formattedDate(
              this.fifthSunday(9, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['pages_no']
              : '',
          november1stWeekSize:
            this.formattedDate(
              this.firstSunday(10, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['size']
              : '',
          november1stWeekAmt:
            this.formattedDate(
              this.firstSunday(10, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['total_amount']
              : '',
          november1stWeekPageNo:
            this.formattedDate(
              this.firstSunday(10, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['pages_no']
              : '',
          november2ndWeekSize:
            this.formattedDate(
              this.secondSunday(10, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['size']
              : '',
          november2ndWeekAmt:
            this.formattedDate(
              this.secondSunday(10, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['total_amount']
              : '',
          november2ndWeekPageNo:
            this.formattedDate(
              this.secondSunday(10, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['pages_no']
              : '',
          november3rdWeekSize:
            this.formattedDate(
              this.thirdSunday(10, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['size']
              : '',
          november3rdWeekAmt:
            this.formattedDate(
              this.thirdSunday(10, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['total_amount']
              : '',
          november3rdWeekPageNo:
            this.formattedDate(
              this.thirdSunday(10, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['pages_no']
              : '',
          november4thWeekSize:
            this.formattedDate(
              this.fourthSunday(10, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['size']
              : '',
          november4thWeekAmt:
            this.formattedDate(
              this.fourthSunday(10, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['total_amount']
              : '',
          november4thWeekPageNo:
            this.formattedDate(
              this.fourthSunday(10, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['pages_no']
              : '',
          november5thWeekSize:
            this.formattedDate(
              this.fifthSunday(10, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['size']
              : '',
          november5thWeekAmt:
            this.formattedDate(
              this.fifthSunday(10, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['total_amount']
              : '',
          november5thWeekPageNo:
            this.formattedDate(
              this.fifthSunday(10, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['pages_no']
              : '',
          december1stWeekSize:
            this.formattedDate(
              this.firstSunday(11, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['size']
              : '',
          december1stWeekAmt:
            this.formattedDate(
              this.firstSunday(11, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['total_amount']
              : '',
          december1stWeekPageNo:
            this.formattedDate(
              this.firstSunday(11, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['pages_no']
              : '',
          december2ndWeekSize:
            this.formattedDate(
              this.secondSunday(11, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['size']
              : '',
          december2ndWeekAmt:
            this.formattedDate(
              this.secondSunday(11, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['total_amount']
              : '',
          december2ndWeekPageNo:
            this.formattedDate(
              this.secondSunday(11, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['pages_no']
              : '',
          december3rdWeekSize:
            this.formattedDate(
              this.thirdSunday(11, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['size']
              : '',
          december3rdWeekAmt:
            this.formattedDate(
              this.thirdSunday(11, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['total_amount']
              : '',
          december3rdWeekPageNo:
            this.formattedDate(
              this.thirdSunday(11, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['pages_no']
              : '',
          december4thWeekSize:
            this.formattedDate(
              this.fourthSunday(11, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['size']
              : '',
          december4thWeekAmt:
            this.formattedDate(
              this.fourthSunday(11, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['total_amount']
              : '',
          december4thWeekPageNo:
            this.formattedDate(
              this.fourthSunday(11, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['pages_no']
              : '',
          december5thWeekSize:
            this.formattedDate(
              this.fifthSunday(11, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['size']
              : '',
          december5thWeekAmt:
            this.formattedDate(
              this.fifthSunday(11, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['total_amount']
              : '',
          december5thWeekPageNo:
            this.formattedDate(
              this.fifthSunday(11, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['pages_no']
              : '',
          january1stWeekSize:
            this.formattedDate(
              this.firstSunday(0, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['size']
              : '',
          january1stWeekAmt:
            this.formattedDate(
              this.firstSunday(0, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['total_amount']
              : '',
          january1stWeekPageNo:
            this.formattedDate(
              this.firstSunday(0, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['pages_no']
              : '',
          january2ndWeekSize:
            this.formattedDate(
              this.secondSunday(0, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['size']
              : '',
          january2ndWeekAmt:
            this.formattedDate(
              this.secondSunday(0, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['total_amount']
              : '',
          january2ndWeekPageNo:
            this.formattedDate(
              this.secondSunday(0, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['pages_no']
              : '',
          january3rdWeekSize:
            this.formattedDate(
              this.thirdSunday(0, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['size']
              : '',
          january3rdWeekAmt:
            this.formattedDate(
              this.thirdSunday(0, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['total_amount']
              : '',
          january3rdWeekPageNo:
            this.formattedDate(
              this.thirdSunday(0, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['pages_no']
              : '',
          january4thWeekSize:
            this.formattedDate(
              this.fourthSunday(0, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['size']
              : '',
          january4thWeekAmt:
            this.formattedDate(
              this.fourthSunday(0, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['total_amount']
              : '',
          january4thWeekPageNo:
            this.formattedDate(
              this.fourthSunday(0, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['pages_no']
              : '',
          january5thWeekSize:
            this.formattedDate(
              this.fifthSunday(0, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['size']
              : '',
          january5thWeekAmt:
            this.formattedDate(
              this.fifthSunday(0, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['total_amount']
              : '',
          january5thWeekPageNo:
            this.formattedDate(
              this.fifthSunday(0, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['pages_no']
              : '',
          feb1stWeekSize:
            this.formattedDate(
              this.firstSunday(1, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['size']
              : '',
          feb1stWeekAmt:
            this.formattedDate(
              this.firstSunday(1, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['total_amount']
              : '',
          feb1stWeekPageNo:
            this.formattedDate(
              this.firstSunday(1, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['pages_no']
              : '',
          feb2ndWeekSize:
            this.formattedDate(
              this.secondSunday(1, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['size']
              : '',
          feb2ndWeekAmt:
            this.formattedDate(
              this.secondSunday(1, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['total_amount']
              : '',
          feb2ndWeekPageNo:
            this.formattedDate(
              this.secondSunday(1, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['pages_no']
              : '',
          feb3rdWeekSize:
            this.formattedDate(
              this.thirdSunday(1, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['size']
              : '',
          feb3rdWeekAmt:
            this.formattedDate(
              this.thirdSunday(1, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['total_amount']
              : '',
          feb3rdWeekPageNo:
            this.formattedDate(
              this.thirdSunday(1, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['pages_no']
              : '',
          feb4thWeekSize:
            this.formattedDate(
              this.fourthSunday(1, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['size']
              : '',
          feb4thWeekAmt:
            this.formattedDate(
              this.fourthSunday(1, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['total_amount']
              : '',
          feb4thWeekPageNo:
            this.formattedDate(
              this.fourthSunday(1, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['pages_no']
              : '',
          feb5thWeekSize:
            this.formattedDate(
              this.fifthSunday(1, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['size']
              : '',
          feb5thWeekAmt:
            this.formattedDate(
              this.fifthSunday(1, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['total_amount']
              : '',
          feb5thWeekPageNo:
            this.formattedDate(
              this.fifthSunday(1, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['pages_no']
              : '',
          march1stWeekSize:
            this.formattedDate(
              this.firstSunday(2, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['size']
              : '',
          march1stWeekAmt:
            this.formattedDate(
              this.firstSunday(2, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['total_amount']
              : '',
          march1stWeekPageNo:
            this.formattedDate(
              this.firstSunday(2, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['pages_no']
              : '',
          march2ndWeekSize:
            this.formattedDate(
              this.secondSunday(2, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['size']
              : '',
          march2ndWeekAmt:
            this.formattedDate(
              this.secondSunday(2, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['total_amount']
              : '',
          march2ndWeekPageNo:
            this.formattedDate(
              this.secondSunday(2, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['pages_no']
              : '',
          march3rdWeekSize:
            this.formattedDate(
              this.thirdSunday(2, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['size']
              : '',
          march3rdWeekAmt:
            this.formattedDate(
              this.thirdSunday(2, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['total_amount']
              : '',
          march3rdWeekPageNo:
            this.formattedDate(
              this.thirdSunday(2, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['pages_no']
              : '',
          march4thWeekSize:
            this.formattedDate(
              this.fourthSunday(2, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['size']
              : '',
          march4thWeekAmt:
            this.formattedDate(
              this.fourthSunday(2, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['total_amount']
              : '',
          march4thWeekPageNo:
            this.formattedDate(
              this.fourthSunday(2, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['pages_no']
              : '',
          march5thWeekSize:
            this.formattedDate(
              this.fifthSunday(2, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['size']
              : '',
          march5thWeekAmt:
            this.formattedDate(
              this.fifthSunday(2, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['total_amount']
              : '',
          march5thWeekPageNo:
            this.formattedDate(
              this.fifthSunday(2, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['pages_no']
              : '',
        });
      }

      this.indetailsData.push({
        name: data[index]['off_rep_name'],
        data: sortedDataCommon,
        contract_prev_bal,
        remaining_contract,
        contract_amt,
        contract_discnt_amt,
        contract_net_amt,
        contract_net_gst_amt,
        contract_net_total,
      });

     

      // setTimeout(() => {
      //   this.count = 5;
      this.cdr.detectChanges();
      // }, 1000);
    }

    console.log(this.indetailsData,"indetailsData");
  }

  formatYear(date) {
    return formatDate(date, 'yyyy', 'en-IN', 'GMT+0530');
  }

  formattedDate(date) {
    return formatDate(date, 'dd/MM/yyyy', 'en-IN', 'GMT+0530');
  }

  uniqueData(data, key) {
    return [...new Map(data.map((a) => [key(a), a])).values()];
  }

  calculateTotalAmount(...args) {
    return args.reduce((acc, curr) => {
      // console.log(curr == '' ? 0 : parseFloat(curr));
      let a = curr == '' ? 0 : parseFloat(curr);
      return acc + a;
    }, 0);
  }
}
