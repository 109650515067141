import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DigisubservicesService } from 'src/app/services/digisubservices.service';

@Component({
  selector: 'app-book-seller-address',
  templateUrl: './book-seller-address.component.html',
  styleUrls: ['./book-seller-address.component.scss'],
})
export class BookSellerAddressComponent implements OnInit {
  bookselleraddress;
  constructor(
    private service: DigisubservicesService,
    private dialogRef: MatDialogRef<BookSellerAddressComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.bookselleraddress = this.data['bookselleraddress'];
    console.log(this.bookselleraddress);
  }

  print() {
    window.print();
  }
}
