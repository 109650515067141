import { formatDate } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-subscription-renewal-remainderletter',
  templateUrl: './subscription-renewal-remainderletter.component.html',
  styleUrls: ['./subscription-renewal-remainderletter.component.scss'],
})
export class SubscriptionRenewalRemainderletterComponent implements OnInit {
  subrenewletterdata = [];
  today;

  constructor(
    public dialogref: MatDialogRef<SubscriptionRenewalRemainderletterComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.subrenewletterdata = this.data['subrenewletterdata'];
    this.today = formatDate(new Date(), 'dd/MM/yyyy', 'en-IN', 'GMT+0530');
  }
}
