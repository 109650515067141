import { formatDate } from '@angular/common';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { DigisubservicesService } from 'src/app/services/digisubservices.service';
import { ToWords } from 'to-words';

@Component({
  selector: 'app-advt-invoice-payment-receipt',
  templateUrl: './advt-invoice-payment-receipt.component.html',
  styleUrls: ['./advt-invoice-payment-receipt.component.scss'],
})
export class AdvtInvoicePaymentReceiptComponent implements OnInit {
  data;
  pendingAdvtData;
  advtInvoicePymtRcptData;
  advtInvoicePricePerIssue;
  advtname;
  value = 0;
  date = formatDate(new Date(), 'yyyy-MM-dd', 'en-IN', 'GMT+0530');
  receiptno;
  toWords;
  totalinWords: String;
  stringInvoicedata = [];
  amount = [];

  address: any;
  mobile: any;
  dist: any;
  taluka: any;
  state: any;
  pincode: any;
  newval: any;
  stringbookingdata: any;
  invoicenumberlist: any=[];
  payloadArray: any;
  receipt_data: any;
  checkdate: any;

  constructor(
    private dialogRef: MatDialogRef<AdvtInvoicePaymentReceiptComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private service: DigisubservicesService,
    private toaster: ToastrService
  ) {}
  a:any
  ab:any
  ngOnInit(): void {
    
    debugger;
    this.invoicenumberlist =this.dialogData.invoicenumberlist
    console.log(this.invoicenumberlist, "invoice number list check")
    this.data = this.dialogData['data'];
    // this.receipt_data = this.formatDateToDDMMYYYY(this.data.recptdate),
    this.receipt_data = this.data.recptdate;
    this.receiptno = this.data['recptno'];
    this.pendingAdvtData = this.dialogData['pendingAdvtData'];
    this.advtInvoicePymtRcptData = this.dialogData['advtInvoicePymtRcptData'];
    this.a = this.advtInvoicePymtRcptData[0];
    this.ab =this.a['advtInvoiceList'];
    this.newval = this.dialogData['newval'];
    this.checkdate = this.pendingAdvtData.chequeDate === '0000-00-00'
    ? ''
    : this.formatDateToDDMMYYYY( this.pendingAdvtData.chequeDate);
  
    console.log(this.newval, "new data")
    

    this.advtInvoicePricePerIssue = this.dialogData['advtInvoicePricePerIssue'];
    this.advtname = this.dialogData['advtname'];
    this.value = this.data['seamt'];
    this.address= this.ab[0]['address'];
    this.dist = this.ab[0]['district'];
    this.taluka = this.ab[0]['taluka'];
    this.state = this.ab[0]['state'];
    this.pincode = this.ab[0]['pincode'];
    this.mobile= this.ab[0]['mobile'];
    console.log(this.dialogData);

    this.toWords = new ToWords();
    this.totalinWords = this.toWords.convert(this.data['seamt'], {
      currency: true,
    });
    this.advtInvoicePricePerIssue.map((advt) => {
      this.stringInvoicedata.push(advt['invoice_no']);
      this.stringbookingdata.push(advt['booking_no']);



    });
    console.log(this.stringInvoicedata);
    console.log(this.advtInvoicePricePerIssue, "data");

  }
  formatDateToDDMMYYYY(date: string | Date): string {
    const d = new Date(date);
    const day = ('0' + d.getDate()).slice(-2);
    const month = ('0' + (d.getMonth() + 1)).slice(-2);
    const year = d.getFullYear();
    return `${day}-${month}-${year}`;
  }

  print() {
    window.print();
  }

  save() {
    this.payloadArray = this.invoicenumberlist.map((invoice) => ({
      date: this.data['date'],
      crdtvno: this.data['crdtvno'],
      recptdate: this.data['recptdate'],
      repname: this.data['repname'],
      recptno: this.data['recptno'],
      crdtvamt: this.data['crdtvamt'],
      seamt: this.data['seamt'],
      advtname: this.data['advtname'],
      balamt: this.data['balamt'],
      invoice_no: invoice, // Set current invoice number
      booking_no: this.a.advtInvoiceList[0].main_booking_no,
      newval: this.newval,
    }));
    
    
    this.service.insertAdvtPymtRcptData(this.payloadArray).subscribe((res) => {
      console.log(res);
    });
  
  console.log(this.payloadArray, "payload array for insert");
  
    // this.invoicenumberlist.forEach((invoice) => {
    //   let data = {
    //       date: this.data['date'],
    //       crdtvno: this.data['crdtvno'],
    //       recptdate: this.data['recptdate'],
    //       repname: this.data['repname'],
    //       recptno: this.data['recptno'],
    //       crdtvamt: this.data['crdtvamt'],
    //       seamt: this.data['seamt'],
    //       advtname: this.data['advtname'],
    //       balamt: this.data['balamt'],
    //       invoice_no: invoice, // Assign the current invoice
    //       booking_no: this.a.advtInvoiceList[0].main_booking_no,
    //       newval: this.newval,
    //   };
    //   console.log(data, "payload for insert");
    // });
    // let data = {
    //   date: this.data['date'],
    //   crdtvno: this.data['crdtvno'],
    //   recptdate: this.data['recptdate'],
    //   repname: this.data['repname'],
    //   recptno: this.data['recptno'],
    //   crdtvamt: this.data['crdtvamt'],
    //   seamt: this.data['seamt'],
    //   advtname: this.data['advtname'],
    //   balamt: this.data['balamt'],
    //   invoice_no: this.invoicenumberlist,
    //   // booking_no: this.a['booking_no'],
    //   booking_no: this.a.advtInvoiceList[0].main_booking_no,
    //   // main_booking_number : this.a.advtInvoiceList[0].main_booking_no,

    //   newval: this.newval,

    // };
    // console.log(data, "payload for insert ");

  
    this.toaster.success('success', 'Payment receipt created successfully');
    this.dialogRef.close();
    this.ngOnInit();
  }
}
