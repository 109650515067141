<div>
    <div style="margin-left: 80%; margin-top: -4%; margin-bottom: 2%">
        <button [useExistingCss]="true" class="noPrint" style="margin-right: 2%; margin-left: 2%" mat-raised-button
            color="primary" printSectionId="print-section" ngxPrint>
            print
        </button>
        <button mat-dialog-close class="btn noPrint">Close</button>
    </div>
    <div class="container" id="print-section">
        <div style="margin: 0px 15px;">
            <div class="row">
                <div class="col-3">
                    <img src="../../../assets/img/brand/red.png" alt="logo" width="100" height="100">
                </div>
                <div class="col-6" style="margin-top: 25px;">
                    <span style="font-size: 18px;">
                        हिंदुस्थान प्रकाशन संस्था  <br>
                        303, वडाळा उद्योग भवन, नायगाव क्राॅस रोड, <br>
                        वडाळा, मुंबई 400 o31 दूरध्वनीः 9967570531
                    </span>
                </div>
                <div class="col-3" style="margin-top: 15px;">
                    <span style="font-size: 18px;">दिनांक - <span style="float: right;">{{ today }}</span><br />
                        अंक दिनांक - <span style="float: right;">{{ prakashandate }}</span><br />
                        वितरण दिनांक - <span style="float: right;">{{ vitarandate }}</span>
                    </span>
                </div>
            </div>
            <div class="row" style="margin-top: 15px;">
                <div class="col-5">
                    <span style="margin-left: 7%;font-size: 18px;">प्रकाशन वितरण यादी - टपाल खाते</span>
                </div>
                <div class="col-7">
                    <span style="font-size: 18px;">{{ sub_mode }}</span>
                </div>
            </div>
            <div>
                <table class="table" style="margin-top: 15px;font-size: 18px;">
                    <thead>
                        <tr>
                            <th></th>
                            <th>एका गट्ठ्यातील प्रतीची संख्या</th>
                            <th>एकूण गठ्ठे</th>
                            <th>एकूण अंक</th>
                            <th>एका प्रतीचे वजन</th>
                            <th>गठ्ठ्यांचे वजन</th>
                            <th>टपाल दर</th>
                            <th>आकार</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of FilteredData" style="text-align: right;">
                            <td></td>
                            <td>{{ item['prati'] }}</td>
                            <td>{{ item['gatthe'] }}</td>
                            <td>{{ item['ank'] }}</td>
                            <td>{{ item['vajan'] }}</td>
                            <td>{{ item['gatthchevajan'] }}</td>
                            <td>{{ item['tapalrate'] }}</td>
                            <td>{{ item['aakar'] }}</td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colspan="2" style="font-weight: 600;font-size: 17px;">गठ्ठ्यांची एकूण संख्या</td>
                            <td style="font-weight: 600; font-size: 17px;">{{ totalgatthe }}</td>
                            <td style="font-weight: 600; font-size: 17px;">{{ totalank }}</td>
                            <td></td>
                            <td style="font-weight: 600; font-size: 17px;">{{ totalvajan }}</td>
                            <td colspan="2" style="text-align: right;font-weight: 600; font-size: 17px;">{{ totalaakar
                                }}</td>
                        </tr>
                        <tr>
                            <td colspan="2" style="font-weight: 600;font-size: 17px;">सुट्या अंक प्रतींची संख्या</td>
                            <td></td>
                            <td style="font-weight: 600;font-size: 17px;">{{ this.totaltapalank }}</td>
                            <td></td>
                            <td></td>
                            <td style="font-weight: 600;font-size: 17px;">{{ tapalrate }}</td>
                            <td style="font-weight: 600;font-size: 17px;">{{ this.totaltapalank * tapalrate }}</td>
                        </tr>
                        <tr>
                            <td colspan="2" style="font-weight: 600;font-size: 17px;">एकूण</td>
                            <td style="font-weight: 600;font-size: 17px;">{{ totalgatthe }}</td>
                            <td style="font-weight: 600;font-size: 17px;">{{ this.totaltapalank + totalank }}</td>
                            <td></td>
                            <td></td>
                            <td colspan="2" style="text-align: right;font-weight: 600;font-size: 17px;">{{ totalaakar +
                                this.totaltapalank * tapalrate }}
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
            <table class="table" style="margin-top: 15px;font-size: 18px; ">
                <thead>
                    <tr>
                        <th></th>
                        <th colspan="2">वजन</th>
                        <th>टपाल दर</th>
                        <th>एकूण संख्या</th>
                        <th>बंडल</th>
                        <th>रक्कम</th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td></td>
                        <td colspan="2"></td>
                        <!-- <td></td> -->
                        <td style="font-weight: 600;font-size: 17px;">{{ tapalrate }}</td>
                        <td style="font-weight: 600;font-size: 17px;">{{ this.totaltapalank + totalank }}</td>
                        <td></td>
                        <td style="font-weight: 600;font-size: 17px;">{{ totalaakar + this.totaltapalank * tapalrate }}
                        </td>
                        <td></td>
                    </tr>
                </tbody>
            </table>
            <div class="row" style="font-size: 18px;margin-top: 15px;">
                <div class="col-3" style="text-align: center;">
                    <span>स्वाक्षरी</span>
                </div>
                <div class="col-9"></div>
            </div>
            <div class="row" style="font-size: 18px;margin-top: 35px;text-align: center;">
                <div class="col-3">
                    <span>वितरण विभागाकरिता</span>
                </div>
                <div class="col-5"></div>
                <div class="col-4">
                    <span>व्यवस्थापक</span>
                </div>
            </div>
        </div>
    </div>
</div>