import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
}
export const ROUTES: RouteInfo[] = [
  {
    path: '/board/dashboard',
    title: 'Dashboard',
    icon: 'ni-tv-2 text-primary',
    class: '',
  },
  {
    path: '/board/admin',
    title: 'Admin',
    icon: 'ni-single-02 text-yellow',
    class: '',
  },
  // {
  //   path: '/board/masters',
  //   title: 'Master tables',
  //   icon: 'ni-circle-08 text-orange',
  //   class: '',
  // },
  {
    path: '/board/subscription',
    title: 'Subscription',
    icon: 'ni-badge text-red',
    class: '',
  },
  {
    path: '/board/book-store',
    title: 'Book Store',
    icon: 'ni-books text-yellow',
    class: '',
  },
  {
    path: '/board/advertisement',
    title: 'Advertisement',
    icon: 'ni-chart-bar-32 text-orange',
    class: '',
  },
  // {
  //   path: '/board/representative',
  //   title: 'Representative',
  //   icon: 'ni-circle-08 text-orange',
  //   class: '',
  // },
  {
    path: '/board/book-seller',
    title: 'Issue/Granth Seller',
    icon: 'ni-paper-diploma text-red',
    class: '',
  },
  {
    path: '/board/accounts',
    title: 'Accounts',
    icon: 'ni-key-25 text-info',
    class: '',
  },
  //{ path: '/board/reports', title: 'Reports',  icon:'ni-chart-bar-32 text-pink', class: '' },
  // {
  //   path: '/board/invoice',
  //   title: 'Invoice',
  //   icon: 'ni-single-copy-04 text-orange',
  //   class: '',
  // },
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  public menuItems: any[];
  public isCollapsed = true;

  constructor(private router: Router) {}

  ngOnInit() {
    this.menuItems = ROUTES.filter((menuItem) => menuItem);
    this.router.events.subscribe((event) => {
      this.isCollapsed = true;
    });
  }

  logout() {
    sessionStorage.clear();
  }

  setTabIndex(){
    localStorage.setItem("tab", "0")
  }
}
