import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
// import { DigisubservicesService } from 'src/app/services/digisubservices.service';
import { FormBuilder } from '@angular/forms';
import { AdvertisementDetailsComponent } from '../pages/advertisement-details/advertisement-details.component';
import { DigisubservicesService } from 'src/app/services/digisubservices.service';
import { ToWords } from 'to-words';

@Component({
  selector: 'app-tworeceipt',

  templateUrl: './tworeceipt.component.html',
  styleUrl: './tworeceipt.component.scss'
})
export class TworeceiptComponent {
  receiptdata: any;
  totalinWords: String;
  toWords: any;
  receiptnumber: any
  recpt_no: any;
  data: any;

  constructor(private dialogRef: MatDialogRef<TworeceiptComponent>,
    @Inject(MAT_DIALOG_DATA) public data1: any,
    private service: DigisubservicesService,
    private cdr: ChangeDetectorRef, private fb: FormBuilder
  ) { }

  ngOnInit(): void {


    this.receiptdata = this.data1['receiptdata'];
    debugger;

    this.recpt_no = this.receiptdata.map(item => item.rcpt_no);
    console.log(this.recpt_no, "recpt number");

    this.service.getdatabyreceipt(this.recpt_no).subscribe((res) => {
      // console.log(res);
      this.data = res;

      // this.numbertotext();
    });

   

  }

  // numbertotext() {
  //   debugger
  //   this.toWords = new ToWords();
  //   this.totalinWords = this.toWords.convert(this.data['receiptdata.rcv_amt'], {
  //     currency: true,
  //   });
  //   console.log(this.totalinWords, "numbertotext")
  //   return this.totalinWords
  // }

  numbertotext(rcv_amt:any){
    this.toWords = new ToWords();
    this.totalinWords = this.toWords.convert(rcv_amt, {
      currency: true,
    });
    console.log(this.totalinWords,"numbertotext")
    return this.totalinWords
  }

  print(): void {
    window.print();
  }


}
