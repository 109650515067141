<div>
    <div id="print-section" class="container">
      
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th colspan="15" style="text-align: center !important; font-size: 20px;">Active / Inactive </th>
                    <!-- <th colspan="2">Advt. Outstanding Report</th> -->
                    <!-- <th colspan="2">दिनांक</th>
                    <th colspan="2">पृष्ठ क्र</th> -->
                </tr>
                <tr>
                    <th colspan="10" style="text-align: center !important;">हिंदुस्तान प्रकाशन संस्था - विवेक साप्ताहिक - वर्गणी विभाग</th>
                    <th colspan="3" style="text-align: center !important;">Date From  {{startDate}}</th>
                    <th colspan="2" style="text-align: center !important;">Page No</th>
                    <!-- <th colspan="2">To {{endDate}}</th>
                     <th colspan="1">In Details</th>  -->
                </tr>
                <tr>
                    <th colspan="3" style="text-align: center !important;">वर्गणीदार नोंदणी - नवीन व्यवसाय तपशिल </th>
                    <th colspan="2" style="text-align: center !important;">कोकण प्रांत</th>
                    <th colspan="3" style="text-align: center !important;"></th>
                    <th colspan="7" style="text-align: center !important;">Date</th>
                    <!-- <th colspan="4" style="text-align: center !important;">From</th>
                    <th colspan="4" style="text-align: center !important;">To</th>  -->
                </tr>
                <tr>
                    <th colspan="2">नूतनीकरण येणे बाकी</th>
                    <!-- <th colspan="3">January</th>
                    <th colspan="3">February</th>
                    <th colspan="3">March</th> -->
                    <th colspan="6">पोस्ट </th>
                    <th colspan="6">हस्ते </th>
                    <th colspan="1">एकूण </th>
                </tr>
                <tr>
                    <th>जिल्हा</th>
                    <th>तालुका</th>
                    <th>वार्षिक</th>
                    <th>त्रेवार्षिक</th>
                    <th>पंचवार्षिक</th>
                    <th>हिंदूत्व</th>
                    <th>लो. वा</th>
                    <th>एकूण</th>
                    <th>वार्षिक</th>
                    <th>त्रेवार्षिक</th>
                    <th>पंचवार्षिक</th>
                    <th>हिंदूत्व</th>
                    <th>लो. वा</th>
                    <th>एकूण</th>
                    <th>एकूण</th>
                   
                  </tr>   
            </thead>
            <!-- *ngFor="let data of OutstandingInDetailFormBeans; let i = index" -->
            <tbody>

                <!-- <tr *ngFor="let data of typewisetaluka; let i = index">
                   <td>{{data.district}}</td>
                   <td>{{data.taluka}}</td>
                   <td>{{data.varshik_tapal_count}}</td>
                   <td>{{data.trivarshik_tapal_count}}</td>
                   <td>{{data.panvarshik_tapal_count}}</td>
                   <td>{{data.hindutva_tapal_count}}</td>
                   
                   <td>null</td>
                   <td>{{data.post_total}}</td>
                   <td>{{data.varshik_haste_count}}</td>
                   <td>{{data.trivarshik_haste_count}}</td>
                   <td>{{data.panvarshik_haste_count}}</td>
                   <td>{{data.hindutva_haste_count}}</td>
                   <td>null</td>
                   <td>{{data.haste_total}}</td>
                   <td>{{data.grand_total}}</td>
                   
                  </tr>   -->
                 
            </tbody>

            <tfoot>
                <!-- <tr >
                    <td colspan="2">Total</td>
                    <td>{{pvarshik}}</td>
                    <td>{{ptrevarshik}}</td>
                    <td>{{ppanchvarshik}}</td>
                    <td>{{phindutva}}</td>
                    <td>null</td>
                    <td>{{ptotal}}</td>
                    <td>{{hvarshik}}</td>
                    <td>{{htrevarshik}}</td>
                    <td>{{hpanchvarshik}}</td>
                    <td>{{hhindutva}}</td>
                    <td>null</td>
                    <td>{{htotal}}</td>
                    <td>{{gtotal}}</td>
                   </tr>  -->
                
            </tfoot>    
                <!-- <tfoot>
                    <tr>
                        {{columnSums | json}}
                      <td *ngFor="let sum of columnSums">{{ sum }}</td>
                    </tr>
                </tfoot> -->
        </table>
      
      
    </div>
    <div style="margin-left: 80%; margin-bottom: 2%;">
        <!-- <button class="noPrint" style="margin-top: 2%;" mat-raised-button color="primary"
            [printStyle]="{table : {'border-collapse':'collapse'}, td : {'border': 'solid 1px'}, th : {'border': 'solid 1px'}}"
            printSectionId="print-section2" ngxPrint>
            print
        </button> -->
        <button class="noPrint" style="margin-top: 2%; margin-left: 2%"
            (click)="exportExcel('Contract_InDetails_Report')" mat-raised-button color="primary">
            Export
        </button>

        <button class="noPrint" style="margin-top: 2%;" mat-raised-button color="primary"
           [useExistingCss]="true"
            printSectionId="print-section" ngxPrint>
            print
        </button>
        
        <button class="noPrint" style="margin-top: 2%" mat-button mat-dialog-close>
            Close
        </button>
    </div>
</div>