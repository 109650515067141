import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DigisubservicesService } from 'src/app/services/digisubservices.service';

import * as XLSX from 'xlsx';

@Component({
  selector: 'app-view-print-order',
  templateUrl: './view-print-order.component.html',
  styleUrls: ['./view-print-order.component.scss'],
})
export class ViewPrintOrderComponent implements OnInit {
  printData;
  prusthaData;
  typeData;

  testdata = [
    { name: '५० जी एस एम मफलीथो पेपर ' },
    { name: '६५ जी एस एम एल डब्लु सी पेपर ' },
    { name: '९० जी एस एम आर्ट  पेपर' },
  ];

  constructor(
    private dialogRef: MatDialogRef<ViewPrintOrderComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private service: DigisubservicesService
  ) {}

  ngOnInit(): void {
    this.printData = this.data['data'];

    this.getPrintDataById(this.printData['order_id']);
  }

  getPrintDataById(id: number) {
    this.service.getPrintDataByID(id).subscribe((res) => {
      // console.log(res);
      if (res['success']) {
        this.prusthaData = res['prustha_data'];
        this.typeData = res['type_data'];
      }
    });
  }

  exportexcel(fileName): void {
    /* table id is passed over here */
    let element = document.getElementById('print-section');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, fileName + '.xlsx');
  }
}
