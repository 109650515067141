<div>
    <div style="margin-left: 80%; margin-bottom: 2%;">
        <button class="noPrint" [useExistingCss]="true" style="margin-top: 2%;" mat-raised-button color="primary"
            printSectionId="print-section" ngxPrint>
            print
        </button>
        <button class="noPrint" style="margin-top: 2%; margin-left: 2%"
            (click)="exportExcel('Creditors_InDetails_Report')" mat-raised-button color="primary">
            Export
        </button>
        <button class="noPrint" style="margin-top: 2%" mat-button mat-dialog-close>
            Close
        </button>
    </div>
    <div class="container" id="print-section">
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th colspan="5" class="br-bottom br-top br-right">खरेदी खाते</th>
                    <th colspan="6" class="br-bottom br-top text-center">CGST</th>
                    <th colspan="6" class="br-bottom br-top text-center">SGST</th>
                    <th colspan="6" class="br-bottom br-top text-center">ICSGT</th>
                    <th colspan="1" class="br-bottom br-top"></th>
                </tr>
                <tr>
                    <th class="br-bottom  br-top">Sr</th>
                    <th class="br-bottom  br-top">Voucher No</th>
                    <th class="br-bottom  br-top">Credititor Name</th>
                    <th class="br-bottom  br-top">Bill No</th>
                    <th class="br-bottom  br-top">Net Amount</th>
                    <!-- <th class="br-bottom  br-top">{{data.total_amt}}</th> -->
                    <th class="br-bottom  br-top">1.50%</th>
                    <th class="br-bottom  br-top">2.50%</th>
                    <th class="br-bottom  br-top">3.00%</th>
                    <th class="br-bottom  br-top">6.00% </th>
                    <th class="br-bottom  br-top">9.00%</th>
                    <th class="br-bottom  br-top">14.00%</th>
                    <th class="br-bottom  br-top">1.50%</th>
                    <th class="br-bottom  br-top">2.50%</th>
                    <th class="br-bottom  br-top">3.00%</th>
                    <th class="br-bottom  br-top">6.00% </th>
                    <th class="br-bottom  br-top">9.00%</th>
                    <th class="br-bottom  br-top">14.00%</th>
                    <th class="br-bottom  br-top">3%</th>
                    <th class="br-bottom  br-top">5%</th>
                    <th class="br-bottom  br-top">6%</th>
                    <th class="br-bottom  br-top">12% </th>
                    <th class="br-bottom  br-top">18%</th>
                    <th class="br-bottom  br-top">28%</th>
                    <th class="br-bottom  br-top">एकूण</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let data of creditiorsData; let i = index">
                    <th class="br-bottom  br-top">{{ i +1}}</th>
                    <th class="br-bottom  br-top">{{data.creditor_number}} <br> {{data.creditor_date}}</th>
                    <th class="br-bottom  br-top">{{data.narration}}</th>
                    <th class="br-bottom  br-top">{{data.bill_no}}</th>
                    <th class="br-bottom br-top">
                        {{ calculateNetAmt(data) | number: '1.2-2' }}
                    </th>
                    <th class="br-bottom  br-top">{{data.cgst1_5}}</th>
                    <th class="br-bottom  br-top">{{data.cgst2_5}}</th>
                    <th class="br-bottom  br-top">{{data.cgst3_0}}</th>
                    <th class="br-bottom  br-top">{{data.cgst6_0}} </th>
                    <th class="br-bottom  br-top">{{data.cgst9_0}}</th>
                    <th class="br-bottom  br-top">{{data.cgst14_0}}</th>
                    <th class="br-bottom  br-top">{{data.sgst1_5}}</th>
                    <th class="br-bottom  br-top">{{data.sgst2_5}}</th>
                    <th class="br-bottom  br-top">{{data.sgst3_0}}</th>
                    <th class="br-bottom  br-top">{{data.sgst6_0}}</th>
                    <th class="br-bottom  br-top">{{data.sgst9_0}}</th>
                    <th class="br-bottom  br-top">{{data.sgst14_0}}</th>
                    <th class="br-bottom  br-top">{{data.igst3}}</th>
                    <th class="br-bottom  br-top">{{data.igst5}}</th>
                    <th class="br-bottom  br-top">{{data.igst6}}</th>
                    <th class="br-bottom  br-top">{{data.igst12}} </th>
                    <th class="br-bottom  br-top">{{data.igst18}}</th>
                    <th class="br-bottom  br-top">{{data.igst28}}</th>
                    <th class="br-bottom  br-top">{{data.total_amt}}</th>
                </tr>

            </tbody>
        </table>

    </div>
</div>