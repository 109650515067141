<!-- <h1  mat-dialog-title>Modal</h1>
    <mat-dialog-content>  </mat-dialog-content>
<mat-dialog-actions>
    <button  mat-raised-button (click)="closeMe()">Close</button>
</mat-dialog-actions> -->

<!-- <form [formGroup] = "form" (ngSubmit) = submitForm() class="your-class">
    
	<div class="form-group input-group">
		<div class="input-group-prepend">
		    <span class="input-group-text"> <i class="fa fa-user"></i> </span>
         </div>
         <h2 style="text-align: justify;">Mobile</h2>
        <input formControlName = "mobileNumber" name="" class="input-box" placeholder="Mobile Number" type="text">
    </div>

    <div>
		<div>
		    <h2>Account Name</h2>
		 </div>
        <input formControlName = "accountname" name="" class="form-control" placeholder="Account Name" type="text">
    </div>

    <div class="form-group input-group">
		<div class="input-group-prepend">
		    <span class="input-group-text"> <i class="fa fa-user"></i> </span>
		 </div>
        <input formControlName = "address" class="form-control" placeholder="Address" type="text">
    </div>

    <div class="form-group input-group">
		<div class="input-group-prepend">
		    <span class="input-group-text"> <i class="fa fa-user"></i> </span>
		 </div>
        <input formControlName = "accountno" class="form-control" placeholder="Account No." type="text">
    </div>


    <div class="form-group input-group">
		<div class="input-group-prepend">
		    <span class="input-group-text"> <i class="fa fa-user"></i> </span>
        </div>
        <input formControlName = "bankname" name="" class="form-control" placeholder="Bank Name" type="text">
    </div> 

    <div class="form-group input-group">
		<div class="input-group-prepend">
		    <span class="input-group-text"> <i class="fa fa-user"></i> </span>
		 </div>
        <input formControlName = "branchname" name="" class="form-control" placeholder="Branch Name" type="text">
    </div>
                                          
    <div class="form-group">
        <button type="submit" class="btn btn-primary btn-block"> Update </button>
    </div>                                                               
</form> -->

<form [formGroup] = "form" (ngSubmit) = submitForm()>
	<div class="form-row">
		<div class="col form-group">
			<label>
        Mobile Number 
      </label>   
		  	<input formControlName = "mobileNumber" type="text" class="form-control" placeholder="">
    </div> <!-- form-group end.// -->
        
		<div class="col form-group">
      <label>Address</label>
      <input formControlName = "address" type="text" class="form-control" placeholder="">
    </div> <!-- form-group end.// -->

  </div> <!-- form-row end.// -->
    

    <div class="form-group">
			<label>Account Name</label>
		  	<input formControlName = "accountname" type="text" class="form-control" placeholder=" ">
    </div> <!-- form-group end.// -->
      
	

    <div class="form-group">
		<label>Account No.</label>
		<input formControlName = "accountno" type="text" class="form-control" placeholder="">
    </div> <!-- form-group end.// -->

    <div class="form-group">
		<label>Bank</label>
		<input formControlName = "bankname" type="text" class="form-control" placeholder="">
    </div> <!-- form-group end.// -->

    <div class="form-group">
		<label>Branch</label>
		<input formControlName = "branchname" type="text" class="form-control" placeholder="">
    </div> <!-- form-group end.// -->
 
    <div class="form-group">
        <button type="submit" class="btn btn-primary btn-block"> Update  </button>
    </div> <!-- form-group// -->                                                
</form>