import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DigisubservicesService } from 'src/app/services/digisubservices.service';

@Component({
  selector: 'app-add-book-per-price',
  templateUrl: './add-book-per-price.component.html',
  styleUrls: ['./add-book-per-price.component.scss'],
})
export class AddBookPerPriceComponent implements OnInit {
  bookreceipt;
  bookdata = [];
  initial_total_val: number = 0;
  crdtvAmt = 0;
  selectedAmt = 0;
  balanceAmt = 0;
  bal =0;
  bname:any;

  constructor(
    public service: DigisubservicesService,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<AddBookPerPriceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
   
  }

  ngOnInit(): void {
    debugger;
    this.bookreceipt = this.data['book'];

    this.crdtvAmt = isNaN(this.data['crdtAmt']) ? 0 : this.data['crdtAmt'];
    this.selectedAmt = isNaN(this.data['seAmt']) ? 0 : this.data['seAmt'];
    this.balanceAmt = isNaN(this.data['balAmt']) ? 0 : this.data['balAmt'];
    this.bal = isNaN(this.data['bal']) ? 0 : this.data['bal'];
    this.bname = this.data?.['bookName'] ?? '';

    console.log(this.bname);
    console.log('rcpt data', this.bookreceipt);

    const fetchBooks = this.bookreceipt['invoice_no'].includes('BDO')
    ? this.service.getRcptWiseBooks1(parseInt(this.bookreceipt['id']))
    : this.service.getRcptWiseBooks(parseInt(this.bookreceipt['id']));

    fetchBooks.subscribe((data) => {
      console.log({ data });
      // this.total_val = 0;
      // this.total_remaining_amt = 0;
    
      data.forEach((book) => {
        const total_amount = parseInt(book['total']) + parseInt(book['shipping_charges']);
        const rcvd_amt = parseInt(book['received_amt']) || 0;
        const remaining_amt:number = total_amount - rcvd_amt;
    
        this.total_val += rcvd_amt;
        this.total_remaining_amt += remaining_amt;
    
        this.bookdata.push({
          id: this.bookreceipt['id'],
          bookid: book['id'],
          book_name: book['book_name'],
          netamt: book['netamt'],
          copies: book['copies'],
          total: book['total'],
          shipping_charges: book['shipping_charges'],
          total_amount: total_amount,
          rcvd_amt: rcvd_amt,
          remaining_amt: remaining_amt,
          invoice_no: this.bookreceipt['invoice_no'],
          isDisabled: remaining_amt === 0 || remaining_amt === 0.00,
        });
      });
      console.log("data",this.bookdata)
    });
    
}

  total_remaining_amt = 0;
  total_val = 0;
  value = 0;
  newvalue=0;

  calcamount(event, book) {
    debugger
    let val = event.target.value;
    if(this.newvalue==0){
      this.newvalue=val;
    }else{
      this.newvalue += val
    }
    this.newvalue=val;
    this.total_val = 0;
    // console.log({ book });
    let remaining_amt =
      parseInt(book['total_amount']) -
      (parseInt(book['rcvd_amt']) + parseInt(val));
    this.total_remaining_amt = 0;
    for (let index = 0; index < Object.keys(this.bookdata).length; index++) {
      if (this.bookdata[index]['bookid'] == book['bookid']) {
        this.bookdata[index]['rcvd_amt'] =
          parseInt(this.bookdata[index]['rcvd_amt']) + parseInt(val);
        this.value = this.value + parseInt(val);
        this.bookdata[index]['remaining_amt'] = remaining_amt;
          // Disable input if remaining amount is 0
          this.bookdata[index]['isDisabled'] = remaining_amt === 0 || remaining_amt === 0.00; // ✅ Ensure both 0 and 0.00 are considered

        this.selectedAmt = this.selectedAmt + Number(this.newvalue);
        this.balanceAmt = this.crdtvAmt - this.selectedAmt;


        
      }
      debugger
      this.total_val =
        this.total_val + parseInt(this.bookdata[index]['rcvd_amt']);
      this.total_remaining_amt =
        this.total_remaining_amt +
        parseInt(this.bookdata[index]['remaining_amt']);
    }
  }

  


  save() {
    console.log(this.value);

    this.dialogRef.close({
      data: this.bookdata,
      selecetdAmt: this.selectedAmt,
      remaining_amt: this.total_remaining_amt,
      value: this.value,
      id: this.bookreceipt['id'],
    });
    // if (confirm('This operation is not reversable. Do you want to proceed.')) {
    //   for (let index = 0; index < Object.keys(this.bookdata).length; index++) {
    //     this.service
    //       .updateRcptWiseBooks(
    //         this.bookdata[index]['bookid'],
    //         this.bookdata[index]['rcvd_amt'],
    //         this.bookdata[index]['remaining_amt']
    //       )
    //       .subscribe((data) => {
    //         console.log(data);
    //         alert('Books price added successfully...');
    //         // this.dialogRef.close();
    //       });
    //   }
    // }
  }
}
