import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DigisubservicesService } from 'src/app/services/digisubservices.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-datewise-varganidar-rojmel-report',
  templateUrl: './datewise-varganidar-rojmel-report.component.html',
  styleUrls: ['./datewise-varganidar-rojmel-report.component.scss'],
})
export class DatewiseVarganidarRojmelReportComponent implements OnInit {
  startDate = '';
  endDate = '';
  rep_name;
  today = new Date();
  rojmelData;
  isLoading: boolean = false;
  subTypesName = [
    { name: 'वार्षिक', isVisible: false },
    { name: 'त्रैवार्षिक', isVisible: false },
    { name: 'पंचवार्षिक', isVisible: false },
    { name: 'रा.स्व.संघ ग्रंथ', isVisible: false },
    // { name: 'मुदत ठेव (पंचवार्षिक वर्गणीदार)', isVisible: false },
    // { name: 'रामसेवक ग्रंथयात्री', isVisible: false },
    // { name: 'लोकनेता गंथयात्री वार्षिक', isVisible: false },
    // { name: 'लोकमान्य ग्रंथयात्री वार्षिक वर्गणी', isVisible: false },
    // { name: 'हिंदुत्व ग्रंथयात्री', isVisible: false },
    // { name: 'हिंदुत्व 1', isVisible: false },
    // { name: 'हिंदुत्व 2', isVisible: false },
    // { name: 'आजीव', isVisible: false },
    // { name: 'अनामत', isVisible: false },
  ];
  totalth = 0;
  finalData = [];
  datewisedata: any;

  constructor(
    private service: DigisubservicesService,
    private dialogRef: MatDialogRef<DatewiseVarganidarRojmelReportComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    console.log(this.data, "thid data")
    this.isLoading = true;
    this.startDate = this.data['startDate'];
    this.endDate = this.data['endDate'];
    this.rep_name = this.data['rep_name'];
    // console.log(this.startDate, this.endDate);
    // console.log(this.data['data']);
    this.datewisedata = this.data['dataset'];

console.log( this.datewisedata, "new data set")
    this.rojmelData = this.data['data'];
    // .filter((a) => {
    //   return (
    //     new Date(a['entry_date']) >= new Date(this.startDate) &&
    //     new Date(a['entry_date']) <= new Date(this.endDate)
    //   );
    // });
    // console.log(this.rojmelData);

    let data = this.uniqueData(this.rojmelData, (a) => a.subscription_type);

    for (let i = 0; i < Object.keys(data).length; i++) {
      let flag = false;
      for (let j = 0; j < Object.keys(this.subTypesName).length; j++) {
        if (data[i]['subscription_type'] === this.subTypesName[j]['name']) {
          this.subTypesName[j]['isVisible'] = true;
          flag = true;
          break;
        }
      }
      if (!flag) {
        // if (
        //   data[i]['Subscription_Type'] !== 'हितचिंतक सप्रेम भेट' ||
        //   data[i]['Subscription_Type'] !== 'सप्रेम भेट - संपादकीय विभाग' ||
        //   data[i]['Subscription_Type'] !== 'जाहिरात स. भेट' ||
        //   data[i]['Subscription_Type'] !== 'संघ परिवार सप्रेम भेट' ||
        //   data[i]['Subscription_Type'] !== 'वर्गणी सप्रेम भेट'
        // ) {
        this.subTypesName.push({
          name: data[i]['subscription_type'],
          isVisible: true,
        });
        // }
      }
    }

    // console.log(this.subTypesName);
    this.totalth = this.subTypesName.length * 3 + 9;

    this.setData();
  }
  setData() {
    // console.log(this.rojmelData);
    let uniqueDateData = this.uniqueData(this.rojmelData, (a) => a.entry_date);
    uniqueDateData.sort((a: any, b: any) => {
      return (
        new Date(a.entry_date).getTime() - new Date(b.entry_date).getTime()
      );
    });
    // console.log(
    // );
    let finalTotal = {};
    this.subTypesName.map((b: any, i) => {
      if (b.isVisible) {
        let name = this.getKey(b.name);
        finalTotal[name + '_एकूण'] = 0;
        finalTotal[name + '_नवीन'] = 0;
        finalTotal[name + '_नूतनीकरण'] = 0;
        if (i == 0) {
          finalTotal['name'] = 'एकूण';
          finalTotal['एकूण'] = 0;
          finalTotal['नवीन'] = 0;
          finalTotal['नूतनीकरण'] = 0;
        }
      }
    });

    for (let i = 0; i < uniqueDateData.length; i++) {
      let datewiseData = this.rojmelData.filter((a) => {
        return a.entry_date === uniqueDateData[i]['entry_date'];
      });
      // console.log(datewiseData);
      let totalData = {};
      this.subTypesName.map((b: any, i) => {
        if (b.isVisible) {
          let name = this.getKey(b.name);
          totalData[name + '_एकूण'] = 0;
          totalData[name + '_नवीन'] = 0;
          totalData[name + '_नूतनीकरण'] = 0;
          if (i == 0) {
            totalData['एकूण'] = 0;
            totalData['नवीन'] = 0;
            totalData['नूतनीकरण'] = 0;
          }
        }
      });
      datewiseData.map((a: any, i) => {
        if (i == 0) totalData['entry_date'] = a.entry_date;
        let name = this.getKey(a.subscription_type);
        totalData[name + '_एकूण'] += parseInt(a.total_amt);
        finalTotal[name + '_एकूण'] += parseInt(a.total_amt);
        totalData[name + '_नवीन'] += a.rcpt_type == 'New' ? 1 : 0;
        finalTotal[name + '_नवीन'] += a.rcpt_type == 'New' ? 1 : 0;
        totalData[name + '_नूतनीकरण'] += a.rcpt_type == 'Renew' ? 1 : 0;
        finalTotal[name + '_नूतनीकरण'] += a.rcpt_type == 'Renew' ? 1 : 0;
        totalData['एकूण'] += parseInt(a.total_amt);
        finalTotal['एकूण'] += parseInt(a.total_amt);
        totalData['नवीन'] += a.rcpt_type == 'New' ? 1 : 0;
        finalTotal['नवीन'] += a.rcpt_type == 'New' ? 1 : 0;
        totalData['नूतनीकरण'] += a.rcpt_type == 'Renew' ? 1 : 0;
        finalTotal['नूतनीकरण'] += a.rcpt_type == 'Renew' ? 1 : 0;
      });
      datewiseData.push(totalData);
      this.finalData.push(datewiseData);
    }
    this.finalData.push(finalTotal);

    
    console.log(this.finalData, "final data");
    this.isLoading = false;
  }

  getKey(name) {
    return name.replaceAll(' ', '_');
  }

  uniqueData(data, key) {
    return [...new Map(data.map((a) => [key(a), a])).values()];
  }

  exportExcel(fileName) {
    /* table id is passed over here */
    let element = document.getElementById('print-section');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, fileName + '.xlsx');
  }
}
