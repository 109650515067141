<div>
    <mat-dialog-actions>
        <mat-icon mat-dialog-close>close</mat-icon>
    </mat-dialog-actions>
    <div class="container">
        <div class="row">
            <div class="col-12">
                <h1 style="text-align: center;">हिंदुस्थान प्रकाशन संस्था - विवेक साप्ताहिक - वर्गणी विभाग</h1>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <h1 style="text-align: center;">हस्ते वाटणावळ</h1>
            </div>
        </div>
        <div class="row">

            <div class="col-4">
                <h3>हस्ते वितरण करणारा प्रतिनिधी</h3>
            </div>
            <div class="col-4">
                <form [formGroup]="distform">
                    <mat-form-field appearance="outline" class="example-full-width" style="width: 50%;">
                        <mat-label>Representative Name</mat-label>
                        <input matInput formControlName="rep_name" [matAutocomplete]="autorep_name" />
                        <button *ngIf="rep_name1" matSuffix mat-icon-button aria-label="Clear"
                            (click)="reset_rep_name()">
                            <mat-icon>close</mat-icon>
                        </button>
                        <mat-autocomplete autoActiveFirstOption #autorep_name="matAutocomplete">
                            <mat-option (onSelectionChange)="getrep_name($event, option)"
                                *ngFor="let option of filteredOptions | async" [value]="option['rep_name']">
                                {{ option["rep_name"] }}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </form>
            </div>
            <div class="col-4">
                <h3>दिनांक -
                    {{startDate | date:"dd-MM-yyy"}} - {{endDate | date:"dd-MM-yyy"}}</h3>
                <!-- <mat-form-field appearance="fill" style="width: 40%;">
                    <mat-label>Enter a date range</mat-label>
                    <mat-date-range-input [formGroup]="dateform" [rangePicker]="picker5">
                        <input matStartDate formControlName="start" placeholder="Start date" />
                        <input matEndDate formControlName="end" placeholder="End date"
                            (dateChange)="getChangedDaterep()" />
                    </mat-date-range-input>
                    <mat-datepicker-toggle matSuffix [for]="picker5"></mat-datepicker-toggle>
                    <mat-date-range-picker #picker5></mat-date-range-picker>

                    <mat-error *ngIf="
                                dateform.controls.start.hasError(
                                  'matStartDateInvalid'
                                )
                              ">Invalid start date</mat-error>
                    <mat-error *ngIf="
                                dateform.controls.end.hasError('matEndDateInvalid')
                              ">Invalid end date</mat-error>
                </mat-form-field> -->
            </div>
        </div>
        <div class="row" style="padding-top: 0px;">
            <div class="col-1" style="border-width: 0px 1.5px 0px 0px;border-style: solid;border-color: black;">
                <h3 style="font-weight: 600;">नाव -</h3>
            </div>
            <div class="col-5" style="border-width: 0px 1.5px 0px 0px;border-style: solid;border-color: black;">
                <h3></h3>
            </div>
            <div class="col-2" style="border-width: 0px 1.5px 0px 0px;border-style: solid;border-color: black;">
                <h3>आधार नं. -</h3>
            </div>
            <div class="col-4"></div>
        </div>
        <div class="row" style="padding-top: 0px;">
            <div class="col-1" style="border-width: 0px 1.5px 0px 0px;border-style: solid;border-color: black;">
                <h3 style="font-weight: 600;">पत्ता -</h3>
            </div>
            <div class="col-5" style="border-width: 0px 1.5px 0px 0px;border-style: solid;border-color: black;">

            </div>
            <div class="col-2" style="border-width: 0px 1.5px 0px 0px;border-style: solid;border-color: black;">
                <h3>पॅन नं. -</h3>
            </div>
            <div class="col-4">
            </div>
            <div class="col-1" style="border-width: 1.5px 1.5px 0px 0px;border-style: solid;border-color: black;"></div>
            <div class="col-5" style="border-width: 0px 1.5px 0px 0px;border-style: solid;border-color: black;">

            </div>
            <div class="col-2" style="border-width: 1.5px 1.5px 0px 0px;border-style: solid;border-color: black;">
                <h3>बँक डिटेल्स -</h3>
            </div>
            <div class="col-4" style="border-width: 1.5px 0px 0px 0px;border-style: solid;border-color: black;">
            </div>
            <div class="col-1" style="border-width: 1.5px 1.5px 0px 0px;border-style: solid;border-color: black;"></div>
            <div class="col-5" style="border-width: 0px 1.5px 0px 0px;border-style: solid;border-color: black;">

            </div>
            <div class="col-2" style="border-width: 1.5px 1.5px 0px 0px;border-style: solid;border-color: black;">
                <h3>खाते क्र. </h3>
            </div>
            <div class="col-4" style="border-width: 1.5px 0px 0px 0px;border-style: solid;border-color: black;">
            </div>
            <div class="col-1" style="border-width: 1.5px 1.5px 0px 0px;border-style: solid;border-color: black;">
                <h3>मोबाईल नं.</h3>
            </div>
            <div class="col-5" style="border-width: 0px 1.5px 0px 0px;border-style: solid;border-color: black;">

            </div>
            <div class="col-2" style="border-width: 1.5px 1.5px 0px 0px;border-style: solid;border-color: black;">
                <h3>IFSC NO. </h3>
            </div>
            <div class="col-4" style="border-width: 1.5px 0px 0px 0px;border-style: solid;border-color: black;">
            </div>
        </div>
        <div class="row" style="padding-top: 0px;">
            <div class="col-2" style="border-width: 0px 1.5px 0px 0px;border-style: solid;border-color: black;">
                <h3>अंकाचे नाव</h3>
            </div>
            <div class="col-1" style="border-width: 0px 1.5px 0px 0px;border-style: solid;border-color: black;">
                <h3>अंक संख्या</h3>
            </div>
            <div class="col-2" style="border-width: 0px 1.5px 0px 0px;border-style: solid;border-color: black;">
                <h3>वाटणावळ दर</h3>
            </div>
            <div class="col-1" style="border-width: 0px 1.5px 0px 0px;border-style: solid;border-color: black;">
                <h3>एकूण वाटणावळ</h3>
            </div>
            <div class="col-4" style="border-width: 0px 1.5px 0px 0px;border-style: solid;border-color: black;">
                <h3>वाटणावळ दिल्याचा तपशिल</h3>
            </div>
            <div class="col-2">
                <h3>वाटणावळ देणे बाकी</h3>
            </div>
            <div class="col-2" style="border-width: 0px 1.5px 0px 0px;border-style: solid;border-color: black;">
            </div>
            <div class="col-1" style="border-width: 0px 1.5px 0px 0px;border-style: solid;border-color: black;">
            </div>
            <div class="col-2" style="border-width: 0px 1.5px 0px 0px;border-style: solid;border-color: black;">
            </div>
            <div class="col-1" style="border-width: 0px 1.5px 0px 0px;border-style: solid;border-color: black;">
            </div>
            <div class="col-1" style="border-width: 1.5px 1.5px 0px 0px;border-style: solid;border-color: black;">
                <h3>दिलेली रक्कम</h3>
            </div>
            <div class="col-1" style="border-width: 1.5px 1.5px 0px 0px;border-style: solid;border-color: black;">
                <h3>दिनांक</h3>
            </div>
            <div class="col-2" style="border-width: 1.5px 1.5px 0px 0px;border-style: solid;border-color: black;">
                <h3>बँक तपशिल</h3>
            </div>
            <div class="col-2">
            </div>
        </div>
        <div class="row" style="padding-top: 0px;">
            <div class="col-2" style="border-width: 0px 1.5px 0px 0px;border-style: solid;border-color: black;">

            </div>
            <div class="col-1" style="border-width: 0px 1.5px 0px 0px;border-style: solid;border-color: black;">

            </div>
            <div class="col-2" style="border-width: 0px 1.5px 0px 0px;border-style: solid;border-color: black;">
                <form [formGroup]="vatnavalform">
                    <mat-form-field class="example-full-width" appearance="outline" style="width: 40%">
                        <input matInput (change)="calcamount($event)" formControlName="vatdar" type="number" />
                    </mat-form-field>
                </form>
            </div>
            <div class="col-1" style="border-width: 0px 1.5px 0px 0px;border-style: solid;border-color: black;">

            </div>
            <div class="col-1" style="border-width: 0px 1.5px 0px 0px;border-style: solid;border-color: black;">

            </div>
            <div class="col-1" style="border-width: 0px 1.5px 0px 0px;border-style: solid;border-color: black;">

            </div>
            <div class="col-2" style="border-width: 0px 1.5px 0px 0px;border-style: solid;border-color: black;">

            </div>
            <div class="col-2">

            </div>
        </div>
        <div class="row" style="padding-top: 0px;">
            <div class="col-2" style="border-width: 0px 1.5px 1.5px 0px;border-style: solid;border-color: black;">
                <h3>एकूण</h3>
            </div>
            <div class="col-1" style="border-width: 0px 1.5px 1.5px 0px;border-style: solid;border-color: black;">

            </div>
            <div class="col-2" style="border-width: 0px 1.5px 1.5px 0px;border-style: solid;border-color: black;">
            </div>
            <div class="col-1" style="border-width: 0px 1.5px 1.5px 0px;border-style: solid;border-color: black;">

            </div>
            <div class="col-1" style="border-width: 0px 1.5px 1.5px 0px;border-style: solid;border-color: black;">

            </div>
            <div class="col-1" style="border-width: 0px 1.5px 1.5px 0px;border-style: solid;border-color: black;">

            </div>
            <div class="col-2" style="border-width: 0px 1.5px 1.5px 0px;border-style: solid;border-color: black;">

            </div>
            <div class="col-2" style="border-width: 0px 0px 1.5px 0px;border-style: solid;border-color: black;">

            </div>
        </div>
    </div>
</div>