<div>
    <div style="margin-left: 80%; margin-top: -4%; margin-bottom: 2%">
        <button [useExistingCss]="true" class="noPrint" style="margin-right: 2%; margin-left: 2%" mat-raised-button
            color="primary" printSectionId="print-section" ngxPrint>
            print
        </button>
        <button mat-dialog-close class="btn noPrint">
            Close
        </button>
    </div>
</div>
<div>
    <div class="container" id="print-section">
        <div style="padding: 0 15%;">
            <div class="row"
                style="border-width: 1px 1px 1px 1px; border-style: solid; border-color: black;text-align: center;">
                <h3 style="margin-left: auto;margin-right: auto;font-weight: 600;">{{ endDate | date:"dd/MM/yyyy" }}
                </h3>
            </div>
            <div class="row">
                <div class="col-3" style="border-width: 0 1px 0 1px; font-weight: 600;">
                    वितरण माध्यम
                </div>
                <div class="col-2" style="border-width: 0 1px 0 0;font-weight: 600;">
                    वर्गणी प्रती
                </div>
                <div class="col-2" style="border-width: 0 1px 0 0;font-weight: 600;">
                    विक्री प्रती
                </div>
                <div class="col-3" style="border-width: 0 1px 0 0;font-weight: 600;">
                    सप्रेम भेट प्रती
                </div>
                <div class="col-2" style="border-width: 0 1px 0 0;font-weight: 600;">
                    एकूण
                </div>
            </div>
            <div class="row">
                <div class="col-3" style="border-width: 1px 1px 0 1px; font-weight: 600;">
                </div>
                <div class="col-2" style="border-width: 1px 1px 0 0;font-weight: 600;">
                    {{ totalVargini }}
                </div>
                <div class="col-2" style="border-width: 1px 1px 0 0;font-weight: 600;">
                    {{ totalVikri }}
                </div>
                <div class="col-3" style="border-width: 1px 1px 0 0;font-weight: 600;">
                    {{ totalSBhet }}
                </div>
                <div class="col-2" style="border-width: 1px 1px 0 0;font-weight: 600;">
                    {{ totalAikun }}
                </div>
            </div>
            <ng-container *ngFor="let item of tempData;">
                <div class="row">
                    <div class="col-3" style="border-width: 1px 1px 0 1px; font-weight: 600;">
                        {{ item['name'] }}
                    </div>
                    <div class="col-2" style="border-width: 1px 1px 0 0;">
                        {{ item['vprati'] }}
                    </div>
                    <div class="col-2" style="border-width: 1px 1px 0 0;">
                        {{ item['vkprati'] }}
                    </div>
                    <div class="col-3" style="border-width: 1px 1px 0 0;">
                        {{ item['sbprati'] }}
                    </div>
                    <div class="col-2" style="border-width: 1px 1px 0 0;font-weight: 600;">
                        {{ item['aikun'] }}
                    </div>
                </div>
            </ng-container>
            <div class="row">
                <div class="col-3" style="border-width: 1px 1px 0 1px; font-weight: 600;">
                    {{ tempDataTotal['name'] }}
                </div>
                <div class="col-2" style="border-width: 1px 1px 0 0;">
                    {{ tempDataTotal['totalVprati'] }}
                </div>
                <div class="col-2" style="border-width: 1px 1px 0 0;">
                    {{ tempDataTotal['totalVkprati'] }}
                </div>
                <div class="col-3" style="border-width: 1px 1px 0 0;">
                    {{ tempDataTotal['totalSbhet'] }}
                </div>
                <div class="col-2" style="border-width: 1px 1px 0 0;font-weight: 600;">
                    {{ tempDataTotal['totalAikun'] }}
                </div>
            </div>
            <div class="row">
                <div class="col-3" style="border-width: 1px 1px 0 1px; font-weight: 600;">
                </div>
                <div class="col-2" style="border-width: 1px 1px 0 0;font-weight: 600;">
                    {{ totalVargini - tempDataTotal['totalVprati'] }}
                </div>
                <div class="col-2" style="border-width: 1px 1px 0 0;font-weight: 600;">
                    {{ totalVikri - tempDataTotal['totalVkprati'] }}
                </div>
                <div class="col-3" style="border-width: 1px 1px 0 0;font-weight: 600;">
                    {{ totalSBhet - tempDataTotal['totalSbhet'] }}
                </div>
                <div class="col-2" style="border-width: 1px 1px 0 0;font-weight: 600;">
                    {{ totalAikun - tempDataTotal['totalAikun'] }}
                </div>
            </div>
            <div class="row" *ngFor="let item of testData">
                <div class="col-3" style="border-width: 1px 1px 0 1px; font-weight: 600;">
                    {{ item['name'] }}
                </div>
                <div class="col-2" style="border-width: 1px 1px 0 0;">
                </div>
                <div class="col-2" style="border-width: 1px 1px 0 0;">
                </div>
                <div class="col-3" style="border-width: 1px 1px 0 0;">
                </div>
                <div class="col-2" style="border-width: 1px 1px 0 0;font-weight: 600;">
                    {{ item['aikun'] }}
                </div>
            </div>
            <div class="row">
                <div class="col-10" style="border-width: 1px 1px 0 1px; font-weight: 600;">
                </div>
                <div class="col-2" style="border-width: 1px 1px 0 0;font-weight: 600;">
                    {{tempDataTotal['totalAikun'] }}
                </div>
            </div>
            <!-- <div class="row" *ngFor="let item of testData2">
                <div class="col-10" style="border-width: 1px 1px 0 1px; font-weight: 600;">
                    {{ item['name'] }}
                </div>
                <div class="col-2" style="border-width: 1px 1px 0 0;font-weight: 600;">
                    {{ item['aikun'] }}
                </div>
            </div> -->


           
           
            <div class="row" style="border-width: 0 0 1px 0; border-style: solid; border-color: black;"></div>

             <div class="row">
                <div class="col-10" style="border-width: 1px 1px 0 1px; font-weight: 600;text-align: end;">
                    Chapai
                </div>
                <div class="col-2" style="border-width: 1px 1px 0 0;font-weight: 600;">
                    {{chapai}}
                </div>
            </div>
            <div class="row">
                <div class="col-10" style="border-width: 1px 1px 0 1px; font-weight: 600; text-align: end;">
                    Remaining
                </div>
                <div class="col-2" style="border-width: 1px 1px 0 0;font-weight: 600;">
                    {{chapai - tempDataTotal['totalAikun'] }}
                </div>
            </div>

            
           
            <div class="row" style="border-width: 0 0 1px 0; border-style: solid; border-color: black;"></div>
        </div>
    </div>
</div>