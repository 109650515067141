import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DigisubservicesService } from 'src/app/services/digisubservices.service';

@Component({
  selector: 'app-recipt',
  templateUrl: './recipt.component.html',
  styleUrls: ['./recipt.component.scss'],
})
export class ReciptComponent implements OnInit {
  id;
  constructor(
    private router: Router,
    public service: DigisubservicesService,
    private dialogRef: MatDialogRef<ReciptComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.id = this.data['id'];
  }
}
