import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DigisubservicesService } from 'src/app/services/digisubservices.service';


@Component({
  selector: 'app-handdeliverylabelslist',
  templateUrl: './handdeliverylabelslist.component.html',
  styleUrls: ['./handdeliverylabelslist.component.scss'],
})
export class HanddeliverylabelslistComponent implements OnInit {
  
  dis_mode;
  sub_mode;
  vitarandate;
  tempdata;
  dist_rep_name: any;
  shortName = {
    वार्षिक: 'वा.',
    त्रैवार्षिक: 'त्रै.',
    पंचवार्षिक: 'पंच.',
    लोकनेता_गंथयात्री_वार्षिक: 'लो.गं.वा.',
    लोकमान्य_ग्रंथयात्री_वार्षिक_वर्गणी: 'लो.ग्रं.वा.व.',
    हिंदुत्व_ग्रंथयात्री: 'हिं.ग्रं.',
    हिंदुत्व_2: 'हिं.2.',
    रामसेवक_ग्रंथयात्री: 'रा.ग्रं.',
  };
  issuedate:any
  labelsData:any
  start_date: any;
  end_date: any;
  title: any;
  sub_name: any;
  sub_type: any;
  country_name: any;
  state_name: any;
  dist_name: any;
  taluka_name: any;
  pincode: any;
  offrep_name: any;
  rep_name: any;
  del_mode: any;

  constructor(
    private dialogref: MatDialogRef<HanddeliverylabelslistComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public service: DigisubservicesService,
  ) {}

  ngOnInit(): void {

    this.issuedate = this.data.issue_date
    
    // this.labelsData = this.data['data'].filter((a) => {
    //   return a['delivery_mode'] === 'हस्ते';
    // });
    this.dist_rep_name = this.data.dist_rep_name
    this.dis_mode = this.data['dis_mode'];
    this.sub_mode = this.data['mode'];
    this.vitarandate = this.data['vitarandate'];

    console.log(this.data['data'], "dis data");
    //  data: this.disRepData,
    // dis_mode:this.dis_mode,
    // mode: this.sub_mode,
    // vitarandate: this.dispatchdate,
    // dist_rep_name : this.dist_rep_name,
    // issue_date : this.issuedate,
    // start_date : this.getbookrcptStartDate,
    // end_date : this.getbookrcptEndDate,
    // title : this.title1,
    // sub_name : this.sub_names,
    // sub_type : this.sub_types,
    // country_name : this.country_names,
    // state_name : this.state_names,
    // dist_name : this.district_names,
    // taluka_name : this.taluka_names,
    // pincode : this.pincodes,
    // offrep_name : this.officerepnames,
    // rep_name : this.repnames,
    // del_mode : this.mode_names,

    debugger;
        this.start_date =this.data.start_date,
        this.end_date =this.data.end_date,
        this.title =this.data.title,
        this.sub_name= this.data.sub_name,
        this.sub_type =this.data.sub_type,
        this.country_name =this.data.country_name,
        this.state_name =this.data.state_name,
        this.dist_name =this.data.dist_name,
        this.taluka_name =this.data.taluka_name,
        this.pincode =this.data.pincode,
        this.offrep_name =this.data.offrep_name,
        this.rep_name =this.data.rep_name,
        this.del_mode =this.data.del_mode

    const req1:any = {
      "issue_date":this.issuedate,
      "dist_type":this.dis_mode,
      "sub_dist_type":this.sub_mode,
      "dist_rep":this.dist_rep_name,
      "country":this.country_name,
           "state":this.state_name,
           "rep_name":this.rep_name,
           "off_rep_name": this.offrep_name,
           "district":this.dist_name,
           "taluka": this.taluka_name,
           "dev_mode":this.del_mode,
           "pincode": this.pincode,
           "title":this.title,
           "subtype":this.sub_type, 
    }
    this.service.getlabeldata(req1).subscribe((data) => {
      // console.log('Database data', data);
      this.labelsData = data
      debugger;
      console.log(this.labelsData,"second data" )
     
    });

    // this.service.getlabeldata(req1) {().subscribe((data) => {
    //   // console.log('Database data', data);
    //   this.issuesub = data;
    //   this.oldissuename = data;
    //   console.log(this.issuesub, "issue sub name")
    // });

    // this.labelsData.map((a) => {
    //   a.shortname = this.shortName[a.Subscription_Type.split(' ').join('_')];
    // });

    // this.tempdata = this.uniqueid(this.labelsData, (a) => a['rep_name']);
    // console.log(this.tempdata);
    // if (this.labelsData.length <= 4) {
    //   const requiredLength = 5;
    //   const numberOfItemsToAdd = requiredLength - this.labelsData.length;
  
    //   // Push empty data to fill up the list
    //   for (let i = 0; i < numberOfItemsToAdd; i++) {
    //     this.labelsData.push({
    //       Subscription_Type: "",
    //       address: "",
    //       country: "",
    //       delivery_mode: "",
    //       dis_add: "",
    //       dis_country: "",
    //       dis_dist: "",
    //       dis_mode: "",
    //       dis_pin: "",
    //       dis_state: "",
    //       dis_sub_mode: "",
    //       dis_tal: "",
    //       discount: "",
    //       distribution_rep: "",
    //       district: "",
    //       entry_date: "",
    //       mobileNumber: "",
    //       month: "",
    //       name: "",
    //       net_amt: "",
    //       office_rep_name: "",
    //       pincode: "",
    //       prant_id: "",
    //       prant_name: "",
    //       rcpt_no: "",
    //       rcpt_type: "",
    //       rep_mob_no: "",
    //       rep_name: "",
    //       s_bhet: "",
    //       shortname: "",
    //       state: "",
    //       sub_perid_to_date: "",
    //       subno: "",
    //       taluka: "",
    //       title: "",
    //       total_amt: "",
    //       year: ""
    //     });
    //   }
    // }
    // console.log(this.labelsData, "labelsData")

  }

  uniqueid(data, key) {
    return [...new Map(data.map((x) => [key(x), x])).values()];
  }
}
