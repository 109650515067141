import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DigisubservicesService } from '../services/digisubservices.service';
import { PrintOptions } from 'ngx-print';
// import { resolve } from 'dns';

@Component({
  selector: 'app-expenseledger',
  templateUrl: './expenseledger.component.html',
  styleUrls: ['./expenseledger.component.scss']
})
export class ExpenseledgerComponent {
  printOptions: PrintOptions = {
    printSectionId: 'print-section',
    printTitle: 'My Print Title',
    useExistingCss: true,
    bodyClass: 'print-body',
    openNewTab: true,
    previewOnly: false,
    closeWindow: false,
    printDelay: 500
  };
  currentDate: Date;
  startDate: any;
  endDate: any;
  mainexp: any;
  subexp: any;
  alldebitvoucher: any;
  allcreditvoucher: any;
  allvoucherdata: any = [];
  credittotal: number = 0;
  debittotal: number = 0;
  expname: any;
  cr_opening_bal: any;
  dr_opening_bal: any;

  constructor(
    private datePipe: DatePipe,
    private dialogRef: MatDialogRef<ExpenseledgerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private service: DigisubservicesService,
    private cdr: ChangeDetectorRef
  ) {

  }
  getFormattedDate(): string {
    return this.datePipe.transform(this.currentDate, 'dd-MM-yyyy');
  }


  ngOnInit(): void {

    // const specialExpIds = ['30', '32', '33', '34', '35', '36', '37', '38', '39', '59', '60', '61', '62', '63', '64'];
    this.currentDate = new Date();
    this.startDate = this.data['startDate'];
    this.endDate = this.data['endDate'];
    this.mainexp = this.data['mainexp'];
    this.subexp = this.data['subexp'];
    console.log(this.startDate, this.endDate, this.mainexp, this.subexp);

    const specialExpIds = [];
    const calls = ['30', '38', '39', '60', '73', '74', '75'];
    const calls1 = ['62', '64', '67', '70', '82'];

    let apiCalls: Promise<any>[] = [];

    // if (calls.includes(this.mainexp)) {
    //   apiCalls.push(this.subscriptiontype());
    // } else {
    //   apiCalls.push(this.getdebitvocher(), this.getcreditvocher());
    // }

    if (calls1.includes(this.mainexp)) {
      apiCalls.push(this.getdebitvocher(), this.getcreditvocher(), this.subscriptiontype());
    } else if (calls.includes(this.mainexp)) {
      apiCalls.push(this.subscriptiontype());
    } else {
      apiCalls.push(this.getdebitvocher(), this.getcreditvocher());
    }

    // Execute all API calls first
    Promise.all(apiCalls)
      .then(() => {
        console.log('All promises resolved successfully');
        this.calculation()
        if (this.allvoucherdata.length > 0) {

          if (this.allvoucherdata[0]['exp_name'] != undefined) {
            this.expname = this.allvoucherdata[0]['exp_name'];
          }
        }



        this.allvoucherdata = this.allvoucherdata.sort((a, b) => {
          return new Date(a.voucher_date).getTime() - new Date(b.voucher_date).getTime();
        });

        this.allvoucherdata.forEach((element, index) => {
          debugger;
          if (index > 0) {


            if (!specialExpIds.includes(this.mainexp)) {

              if (element.voucher == 'cr') {
                element.total = Number(this.allvoucherdata[index - 1].total) - Number(element.exp_amt)

              } else {
                element.total = Number(this.allvoucherdata[index - 1].total) + Number(element.exp_amt)
              }
            } else {
              if (element.voucher == 'cr') {
                element.total = Number(this.allvoucherdata[index - 1].total) - Number(element.exp_amt)
              } else {
                element.total = Number(this.allvoucherdata[index - 1].total) + Number(element.exp_amt)
              }
            }

          } else {

            element.total = Number(this.allvoucherdata[0].exp_amt);


          }
        });
        console.log(this.allvoucherdata)
        if (this.cr_opening_bal == '' || this.cr_opening_bal == undefined || this.cr_opening_bal == null) {
          this.cr_opening_bal = 0;
        }

        if (this.dr_opening_bal == '' || this.dr_opening_bal == undefined || this.dr_opening_bal == null) {
          this.dr_opening_bal = 0;
        }

        this.allvoucherdata.forEach((element, index) => {

          if (element.voucher != undefined) {
            if (!specialExpIds.includes(this.mainexp)) {
              if (this.cr_opening_bal == '' && this.dr_opening_bal == '') {
                if (element.voucher == 'cr') {
                  if (index == 0) {
                    this.allvoucherdata[0].total = Number(this.allvoucherdata[0].total);
                  } else {
                    element.total = Number(this.allvoucherdata[index - 1].total) + Number(element.exp_amt)
                  }
                } else {
                  if (index == 0) {
                    this.allvoucherdata[0].total = Number(this.allvoucherdata[0].total);
                  } else {
                    element.total = Number(this.allvoucherdata[index - 1].total) + Number(element.exp_amt)
                  }
                }
              } else {
                if (element.voucher == 'cr') {
                  if (index == 0) {
                    if (this.cr_opening_bal != '') {
                      this.allvoucherdata[0].total = Number(this.cr_opening_bal) + Number(this.allvoucherdata[0].total);
                    }
                    if (this.dr_opening_bal != '') {
                      this.allvoucherdata[0].total = Number(this.dr_opening_bal) + Number(this.allvoucherdata[0].total);
                    }
                  } else {
                    element.total = Number(this.allvoucherdata[index - 1].total) + Number(element.exp_amt)
                  }

                } else {
                  if (index == 0) {
                    // this.allvoucherdata[0].total = Number(this.allvoucherdata[0].total) + Number(this.dr_opening_bal)
                    //
                    if (this.cr_opening_bal != '') {
                      this.allvoucherdata[0].total = Number(this.cr_opening_bal) + Number(this.allvoucherdata[0].total);
                    }
                    if (this.dr_opening_bal != '') {
                      this.allvoucherdata[0].total = Number(this.dr_opening_bal) + Number(this.allvoucherdata[0].total);
                    }

                  } else {
                    element.total = Number(this.allvoucherdata[index - 1].total) + Number(element.exp_amt)
                  }

                }
              }
            }
            //  else {
            //   if (this.cr_opening_bal == '' && this.dr_opening_bal == '') {
            //     if (element.voucher == 'cr') {
            //       if (index == 0) {
            //         this.allvoucherdata[0].total = Number(this.allvoucherdata[0].total);
            //       } else {
            //         element.total = Number(this.allvoucherdata[index - 1].total) + Number(element.exp_amt)
            //       }
            //     } else {
            //       if (index == 0) {
            //         this.allvoucherdata[0].total = Number(this.allvoucherdata[0].total);
            //       } else {
            //         element.total = Number(this.allvoucherdata[index - 1].total) - Number(element.exp_amt)
            //       }
            //     }
            //   } else {
            //     if (element.voucher == 'cr') {
            //       if (index == 0) {
            //         if (this.cr_opening_bal != '') {
            //           this.allvoucherdata[0].total = Number(this.cr_opening_bal) + Number(this.allvoucherdata[0].total);
            //         }
            //         if (this.dr_opening_bal != '') {
            //           this.allvoucherdata[0].total = Number(this.dr_opening_bal) - Number(this.allvoucherdata[0].total);
            //         }
            //       } else {
            //         element.total = Number(this.allvoucherdata[index - 1].total) + Number(element.exp_amt)
            //       }

            //     } else {
            //       if (index == 0) {
            //         // this.allvoucherdata[0].total = Number(this.allvoucherdata[0].total) + Number(this.dr_opening_bal)
            //         //
            //         if (this.cr_opening_bal != '') {
            //           this.allvoucherdata[0].total = Number(this.cr_opening_bal) - Number(this.allvoucherdata[0].total);
            //         }
            //         if (this.dr_opening_bal != '') {
            //           this.allvoucherdata[0].total = Number(this.dr_opening_bal) + Number(this.allvoucherdata[0].total);
            //         }

            //       } else {
            //         element.total = Number(this.allvoucherdata[index - 1].total) - Number(element.exp_amt)
            //       }

            //     }
            //   }
            // }

          }

        });
        this.cdr.detectChanges(); // Force Angular to update bindings
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  getdebitvocher(): Promise<any> {
    return new Promise((resolve: any, reject) => {
      const dv: any = {
        "exp_id": this.mainexp,
        "subexp_id": this.subexp,
        "startdate": this.startDate,
        "enddate": this.endDate,

      }
      this.service.getAllDebitVocher(dv).subscribe((a) => {
        // console.log(a);
        // this.alldebitvoucher = a;
        // let debitlist = a
        // debitlist.forEach(element => {
        //   this.allvoucherdata.push(element);

        // });

        this.allvoucherdata = [...this.allvoucherdata, ...a]

        this.dr_opening_bal = a[0].opening_bal_dr


        console.log(this.allvoucherdata, "contract deatail dv");
      });

      setTimeout(() => {
        resolve();
      }, 2000);
    })

  }

  getcreditvocher() {

    return new Promise((resolve: any, reject) => {
      const cv: any = {
        "exp_id": this.mainexp,
        "subexp_id": this.subexp,
        "startdate": this.startDate,
        "enddate": this.endDate,
      }
      this.service.getAllCreditVocher(cv).subscribe((a) => {
        // console.log(a);
        // let creditlist = a;
        // this.allvoucherdata.push(a);
        // creditlist.forEach(element => {
        //   this.allvoucherdata.push(element);
        // });
        this.allvoucherdata = [...this.allvoucherdata, ...a]
        this.cr_opening_bal = a[0].opening_bal_cr
        console.log(this.allvoucherdata, "contract deatail cv");
      });

      setTimeout(() => {
        resolve();
      }, 2000);
    })


  }

  subscriptiontype(): Promise<any> {
    return new Promise((resolve: any, reject) => {
      const cv: any = {
        "exp_id": this.mainexp,
        "subexp_id": this.subexp,
        "startdate": this.startDate,
        "enddate": this.endDate,

      }
      this.service.getAllSubscriptiontype(cv).subscribe((a: any) => {
        // console.log(a);
        // this.alldebitvoucher = a;
        // let debitlist = a
        // debitlist.forEach(element => {

        //   this.allvoucherdata.push(element);

        // });
        this.allvoucherdata = [...this.allvoucherdata, ...a]

        this.dr_opening_bal = 0


        console.log(this.allvoucherdata, "subscription type");
      });

      setTimeout(() => {
        resolve();
      }, 2000);
    })



  }
  calculation() {
    // if (this.allvoucherdata['exp_amt'] === 'cr') {
    //   this.credittotal += parseFloat(this.allvoucherdata['exp_amt']);
    // } else if (this.allvoucherdata['exp_amt'] === 'dr') {
    //   this.debittotal += parseFloat(this.allvoucherdata['exp_amt']);
    // }

    this.allvoucherdata.forEach(element => {
      if (element['voucher'] === 'cr') {
        this.credittotal -= Number(element['exp_amt']);
      } else if (element['voucher'] === 'dr') {
        this.debittotal -= Number(element['exp_amt']);
      }

    });
  }

  closeexp() {
    this.dialogRef.close()
  }


}