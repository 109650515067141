import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-print-gstreport',
  templateUrl: './print-gstreport.component.html',
  styleUrls: ['./print-gstreport.component.scss'],
})
export class PrintGSTReportComponent implements OnInit {
  receiptledger;
  total_amt;
  startdate: any;
  enddate: any;
  months: string[] = [];
  

  constructor(
    private dialogref: MatDialogRef<PrintGSTReportComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    
    this.receiptledger = this.data['val'];
    this.total_amt = this.data['totalamt'];
    this.startdate = this.data['startdate'];
    this.enddate = this.data['enddate'];

    console.log(this.startdate, this.enddate)
    this.calculateMonths(this.startdate, this.enddate);


  }
  calculateMonths(start: string, end: string): void {
    const startDate = new Date(start);
    const endDate = new Date(end);

    const monthNames = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];

    const startMonth = startDate.getMonth();
    const startYear = startDate.getFullYear();
    const endMonth = endDate.getMonth();
    const endYear = endDate.getFullYear();

    for (let year = startYear; year <= endYear; year++) {
      let monthStart = year === startYear ? startMonth : 0;
      let monthEnd = year === endYear ? endMonth : 11;

      for (let month = monthStart; month <= monthEnd; month++) {
        this.months.push(monthNames[month]);
      }
    }

    console.log(this.months.join(', ')); // Example output: "April, May, June"
  }
}
