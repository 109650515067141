import { DatePipe, formatDate } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { DigisubservicesService } from '../services/digisubservices.service';
import { EncdecriptService } from '../services/encdecrypt.service';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { ContractBriefReportComponent } from '../pages/contract-brief-report/contract-brief-report.component';
import * as XLSX from 'xlsx';
import * as fileSaver from 'file-saver';
import { AlladdlistComponent } from '../alladdlist/alladdlist.component';
import { AlladdComponent } from '../alladd/alladd.component';
import { AddmodalComponent } from '../addmodal/addmodal.component';

@Component({
  selector: 'app-subscription1',
  templateUrl: './subscription1.component.html',
  styleUrls: ['./subscription1.component.scss']
})


export class Subscription1Component implements OnInit {

  public demo1TabIndex = 0;
  RenewalreportForm: FormGroup;
  RenewalTypereportForm: FormGroup;
  RemainingRenewalreportForm: FormGroup;
  AllAddForm: FormGroup;
  PendingAddForm: FormGroup;
  databyvocuher : FormGroup;

  countries = [];
  states = [];
  cities = [];
  talukas = [];
  titles = [];
  GetOffRep: any;
  databyvc: Object;
  totalcvamt: number;
  rep_nameforcv: any;
  voucherNoList: any[];
  rep_name_data: any[];
  pindcodelist: any;
  daterepForm: any;
  generateform: any;
  selectedCity: any;
  selectedTaluka: any;
  districtname:any;
  talukaname:any;

  constructor(private fb: FormBuilder, public service: DigisubservicesService, public dialog: MatDialog, private datePipe: DatePipe) {
    this.RenewalreportForm = fb.group({
      date: [''],
      reportType: [''],
      rep: [''],
      start: [''],
      end: [''],
      officer: [''],
      districtname: [''],
      talukaname: [''],
      prantname: [''],
      country: [''],
      statename: [''],
    });

    this.RenewalTypereportForm = fb.group({
      // date: [''],
      start: [''],
      end: [''],
      reportType: [''],
      rep: [''],

      officer: [''],
      districtname: [''],
      talukaname: [''],
      country:[''],
      statename:[''],
      prantname:[''],

    });
    this.databyvocuher = fb.group({
      start : [''],
      end : [''],
      cvnumber : [''],
      repname : ['']
    })

    this.RemainingRenewalreportForm = fb.group({
      start: [''],
      end: [''],
      reportType: [''],
      rep: [''],
      officer: [''],
      districtname: [''],
      talukaname: [''],
      country:[''],
      statename:[''],
      prantname:[''],
    });

     this.AllAddForm = fb.group({
          subtype: [''],
          districtname: [''],
          talukaname: [''],
          modetype: [''],
          start: [''],
          end: [''],
          country:[''],
          statename:[''],
          prantname: [''],
          duration:[''],
          reportType: ['',Validators.required],
        });

        this.PendingAddForm = fb.group({
          date: [''],
          start: [''],
          end: [''],
          reportType: [''],
          rep: [''],
          prantname: [''],
          country:[''],
          statename:[''],
          duration:[''],
          subtype:[''],
          districtname: [''],
          talukaname: [''],
          modetype: [''],
        });
  }

  Getprantname: any;
  ngOnInit(): void {
    this.demo1BtnClick11(localStorage.getItem("reporttab"))
    this.getLabelforDispatch();
    this.getOffRep();
    this.getprantname();

    this.service.getCountry().subscribe((data) => {
      this.countries = data;
    });
  }

  getprantname() {

    this.service.getprantname().subscribe((data: any) => {
      console.log("ADVT Data1=", data);
      this.Getprantname = (data as any);;
    });
  }

  public demo1BtnClick11(tabCount: any) {
    this.demo1TabIndex = Number(tabCount);
  }

  getOffRep() {

    this.service.getOffRepSub().subscribe((data: any) => {
      console.log("ADVT Data1=", data);
      this.GetOffRep = (data as any);;
    });
  }

  onTabClick(event: any) {
    console.log(event)
    localStorage.setItem("reporttab", event.index)
  }

  startDate;
  endDate;
  getChangedDatedist() {
    if (
      this.RenewalreportForm.get('start').value != '' &&
      this.RenewalreportForm.get('end').value != ''
    ) {
      this.startDate = formatDate(
        this.RenewalreportForm.get('start').value,
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );

      this.endDate = formatDate(
        this.RenewalreportForm.get('end').value,
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );
    }
  }

  state_names = '';
  getstatename(event) {
    console.log(event.value);
    this.state_names = event.value;
    let state = this.states.find((a) =>
      this.state_names.includes(a.state_name)
    );
    console.log(state);
    this.service.getDistrict(parseInt(state['state_id'])).subscribe((data) => {
      this.cities = data;
    });
  }


getDistricts(event) {
    let distname = event.value + '';
    this.selectedCity = this.cities['district_id'];
    // let district = this.cities.find((a) => a.district_name === distname.trim());
    let districtNames = distname.split(',');

    // Trim each district name
    districtNames = districtNames.map(name => name.trim());
    
    // Find the city object that matches any of the district names
    // let district = this.cities.find(city => districtNames.includes(city.district_name));
    let district =[]
    this.cities.forEach(city => {
     districtNames.forEach(element => {
      if (city.district_name==element){
        district.push(city.district_id)
      }
     });
    });
    if (district != undefined) {
      const req={
        "district_id":district
      }
      
      this.service.getTaluka(district).subscribe((data) => {
        this.talukas = data;
        this.selectedTaluka = this.talukas['taluka_name'];
        console.log( this.talukas, this.selectedCity, " this.talukas")
      });
    }
  }


  district_names = '';
  getdistrictname(event) {
    this.district_names = event.value;

  }
  reset_district_names() {
    this.district_names = '';
    this.generateform.get('districtname').reset();
    this.filteredLabelDispatchData();
  }



  taluka_names = '';
  gettalukaname(event) {
    this.taluka_names = event.value;
    this.filteredLabelDispatchData();
    this.service.pincodebttaluka(this.taluka_names).subscribe((data) => {
      this.pindcodelist = data
    });
  }

  reset_taluka_names() {
    this.taluka_names = '';
    this.generateform.get('talukaname').reset();
    this.filteredLabelDispatchData();
  }

  getDailyReportData(daterepstartdate: any, daterependdate: any, rep_name: any, off_rep_name1: any, value: any, value1: any) {
    throw new Error('Method not implemented.');
  }
  daterepstartdate(daterepstartdate: any, daterependdate: any, rep_name: any, off_rep_name1: any, value: any, value1: any) {
    throw new Error('Method not implemented.');
  }
  daterependdate(daterepstartdate: any, daterependdate: any, rep_name: any, off_rep_name1: any, value: any, value1: any) {
    throw new Error('Method not implemented.');
  }
  rep_name(daterepstartdate: any, daterependdate: any, rep_name: any, off_rep_name1: any, value: any, value1: any) {
    throw new Error('Method not implemented.');
  }
  off_rep_name1(daterepstartdate: any, daterependdate: any, rep_name: any, off_rep_name1: any, value: any, value1: any) {
    throw new Error('Method not implemented.');
  }


  allSelected6 = false;
  @ViewChild('mySel6') skillsel6: MatSelect;
  toggleAllSelection6() {
   
  }
 
  filteredLabelDispatchData() {
    throw new Error('Method not implemented.');
  }


  getsubaddStartDate;
  getsubaddEndDate;
  Filteredsubscribersaddressdata = [];

  getdistrict(event) {
    let district = event.value;
    if (this.getsubaddStartDate != null && this.getsubaddEndDate != null) {
      this.service
        .getSubscribersAddressData(
          this.getsubaddStartDate,
          this.getsubaddEndDate,
          'lmn',
          district,
          'stu',
          'vwx',
          'jkl'
        )
        .subscribe((res) => {
          // console.log(res);
          this.Filteredsubscribersaddressdata = res;
        });
    } else {
      this.service
        .getSubscribersAddressData(
          'abc',
          'def',
          'lmn',
          district,
          'stu',
          'vwx',
          'jkl'
        )
        .subscribe((res) => {
          // console.log(res);
          this.Filteredsubscribersaddressdata = res;
        });
    }
  }

  gettaluka(event) {
    let taluka = event.value;
    if (this.getsubaddStartDate != null && this.getsubaddEndDate != null) {
      this.service
        .getSubscribersAddressData(
          this.getsubaddStartDate,
          this.getsubaddEndDate,
          'lmn',
          'pqr',
          taluka,
          'vwx',
          'jkl'
        )
        .subscribe((res) => {
          // console.log(res);
          this.Filteredsubscribersaddressdata = res;
        });
    } else {
      this.service
        .getSubscribersAddressData(
          'abc',
          'def',
          'lmn',
          'pqr',
          taluka,
          'vwx',
          'jkl'
        )
        .subscribe((res) => {
          // console.log(res);
          this.Filteredsubscribersaddressdata = res;
        });
    }
  }

  getLabelforDispatch() {
    // let date = formatDate(new Date(), 'yyyy-MM-dd', 'en-IN', 'GMT+0530');
    let date = new Date();
    let weekfirstday = date.getDate() - date.getDay();
    let prevfirestday = date.getDate() - date.getDay();
    this.getLabelforDispatchData(
      formatDate(date.setDate(weekfirstday), 'yyyy-MM-dd', 'en-IN', 'GMT+0530'),
      formatDate(
        date.setDate(prevfirestday - 7),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      )
    );
  }

  labelsforDispatch = [];
  FilteredlabelsforPostStatement = [];
  FilteredlabelsforDispatch = [];

  getLabelforDispatchData(curweekfirstday, preweekfirstday) {
    this.service.getlabelsforDispatch(curweekfirstday, preweekfirstday)
      .subscribe((data) => {
        console.log(data, "labelsforDispatch");
        this.labelsforDispatch = data;
        this.FilteredlabelsforDispatch = this.labelsforDispatch;
        this.FilteredlabelsforPostStatement = this.labelsforDispatch;
        this.getDatafromLabelsDispatch();
        this.getFilteredlabelslistfordispatch();
      });


  }

  contractReportType = '';
  getContractReportType(event) {
    this.contractReportType = event.value;
  }

  allSelected = false;
  @ViewChild('mySel') skillSel: MatSelect;
  toggleAllSelection() {
    this.allSelected = !this.allSelected; // to control select-unselect
    // console.log(this.skillSel.options);
    if (this.allSelected) {
      this.skillSel.options.forEach((item: MatOption) => item.select());
    } else {
      this.skillSel.options.forEach((item: MatOption) => {
        item.deselect();
      });
    }
    this.skillSel.close();
  }

  getCountry(event) {
    let country = this.countries.find((a) => a.country_name == event.value);
    if (country != undefined) {
      this.service.getState(country['country_code']).subscribe((data) => {
        this.states = data;
      });
    } else {
    }
    console.log(this.states)
  }

  LabelsDispatchTitles = [];
  LabelsDispatchSubnames = [];
  LabelsDispatchSubtypes = [];
  LabelsDispatchPincode = [];
  LabelsDispatchRepNames = [];
  LabelsDispatchOffRepNames = [];
  LabelsDispatchDisRepNames = [];
  LabelsDisModes = [];
  LabelsSubDisModes = [];
  FilteredLabelsSubDisModes = [];
  LabelsDispatchDeliveryModes = [];

  getDatafromLabelsDispatch() {
    for (
      let index = 0;
      index < Object.keys(this.FilteredlabelsforDispatch).length;
      index++
    ) {
      // if (this.FilteredlabelsforDispatch[index]['title'] != '') {
      this.LabelsDispatchTitles.push({
        title: this.FilteredlabelsforDispatch[index]['title'],
      });
      // }

      this.LabelsDispatchSubnames.push({
        sub_name: this.FilteredlabelsforDispatch[index]['name'],
      });

      this.LabelsDispatchSubtypes.push({
        sub_type: this.FilteredlabelsforDispatch[index]['Subscription_Type'],
      });


      this.LabelsDispatchPincode.push({
        pincode: this.FilteredlabelsforDispatch[index]['pincode'],
      });

      this.LabelsDispatchRepNames.push({
        rep_name: this.FilteredlabelsforDispatch[index]['rep_name'],
      });

      this.LabelsDispatchOffRepNames.push({
        off_rep_name: this.FilteredlabelsforDispatch[index]['office_rep_name'],
      });

      // Step 1: Add items to LabelsDispatchDisRepNames
      this.LabelsDispatchDisRepNames.push({
        dis_rep_name: this.FilteredlabelsforDispatch[index]['distribution_rep'],
      });




      this.LabelsDisModes.push({
        dis_mode: this.FilteredlabelsforDispatch[index]['dis_mode'],
      });

      this.LabelsDispatchDeliveryModes.push({
        mode_name: this.FilteredlabelsforDispatch[index]['delivery_mode'],
      });
    }

    this.LabelsDispatchTitles = this.uniqueid(
      this.LabelsDispatchTitles,
      (a) => a['title']
    );

    this.LabelsDispatchSubnames = this.uniqueid(
      this.LabelsDispatchSubnames,
      (a) => a['sub_name']
    );

    this.LabelsDispatchSubtypes = this.uniqueid(
      this.LabelsDispatchSubtypes,
      (a) => a['sub_type']
    );
    this.LabelsDispatchSubtypes.sort((a, b) => a.sub_type.localeCompare(b.sub_type));


    this.LabelsDispatchPincode = this.uniqueid(
      this.LabelsDispatchPincode,
      (a) => a['pincode']
    );

    this.LabelsDispatchRepNames = this.uniqueid(
      this.LabelsDispatchRepNames,
      (a) => a['rep_name']
    );

    this.LabelsDispatchOffRepNames = this.uniqueid(
      this.LabelsDispatchOffRepNames,
      (a) => a['off_rep_name']
    );

    this.LabelsDispatchDisRepNames = this.uniqueid(
      this.LabelsDispatchDisRepNames,
      (a) => a['dis_rep_name']
    );

    this.LabelsDisModes = this.uniqueid(
      this.LabelsDisModes,
      (a) => a['dis_mode']
    );

    this.LabelsSubDisModes = this.uniqueid(
      this.LabelsSubDisModes,
      (a) => a['sub_dis_mode']
    );

    this.LabelsDispatchDeliveryModes = this.uniqueid(
      this.LabelsDispatchDeliveryModes,
      (a) => a['mode_name']
    );

    let uniqueMap = new Map<string, any>();
    this.LabelsDispatchDisRepNames.forEach(item => {
      uniqueMap.set(item.dis_rep_name, item);
    });

    let uniqueArrayList: any[] = Array.from(uniqueMap.values());
    this.LabelsDispatchDisRepNames = uniqueArrayList

  }

  uniqueid(data, key) {
    return [...new Map(data.map((x) => [key(x), x])).values()];
  }

  disrepnames = '';
  dist_rep_name: any;
  person_name = 'प्रतिनिधीनुसार';

  getdistributionrep(event) {
    debugger
    this.dist_rep_name = event.value
    // console.log(event.value.length);
    if (event.value.length <= 1) {
      this.person_name = event.value[0];
      // console.log(this.person_name);
    } else {
      this.person_name = 'प्रतिनिधीनुसार';
    }
    this.disrepnames = event.value;
    if (this.disrepnames != '') {
      this.FilteredlabelsforDispatch = this.labelsforDispatch.filter((a) => {
        return (
          this.disrepnames.includes(a['distribution_rep']) &&
          a['delivery_mode'] === 'हस्ते'
        );
      });
      this.getFilteredlabelslistfordispatch();
    }
  }

  subdismode = '';
  sub_mode = '';
  getsubdismode(event) {

    this.subdismode = event.value;
    this.sub_mode = this.subdismode;
    if (this.subdismode != '' && this.dismode1 != '') {
      this.FilteredlabelsforPostStatement = this.labelsforDispatch.filter(
        (a) =>
          a['dis_sub_mode'] == this.subdismode && a['dis_mode'] == this.dismode1
      );
      this.getFilteredlabelslistfordispatch();
    }
  }

  dismode1 = '';
  dis_mode: any
  subdistmode: any;
  getdismode(event: any) {
    this.dismode1 = event.value;
    this.dis_mode = this.dismode1
    this.service.getsubdistmode(this.dis_mode).subscribe((data) => {
      // console.log('pkgBookingData', data);
      this.subdistmode = data;
    });
  }


  FilteredLabelsListForDispatch = [];
  getFilteredlabelslistfordispatch() {
    if (
      this.disrepnames != '' &&
      this.subdismode != '' &&
      this.dismode1 != ''
    ) {
      this.FilteredLabelsListForDispatch = this.labelsforDispatch.filter(
        (a) => {
          return (
            this.disrepnames.includes(a['distribution_rep']) &&
            a['dis_sub_mode'] == this.subdismode &&
            a['dis_mode'] == this.dismode1 &&
            a['delivery_mode'] === 'हस्ते'
          );
        }
      );
      // console.log(this.FilteredLabelsListForDispatch);
    } else if (
      this.disrepnames != '' &&
      this.dismode1 != '' &&
      this.subdismode == ''
    ) {
      this.FilteredLabelsListForDispatch = this.labelsforDispatch.filter(
        (a) => {
          return (
            this.disrepnames.includes(a['distribution_rep']) &&
            a['dis_mode'] == this.dismode1 &&
            a['delivery_mode'] === 'हस्ते'
          );
        }
      );
      // console.log(this.FilteredLabelsListForDispatch);
    } else if (
      this.disrepnames != '' &&
      this.dismode1 == '' &&
      this.subdismode == ''
    ) {
      this.FilteredLabelsListForDispatch = this.labelsforDispatch.filter(
        (a) => {
          return (
            this.disrepnames.includes(a['distribution_rep']) &&
            a['delivery_mode'] === 'हस्ते'
          );
        }
      );
      // console.log(this.FilteredLabelsListForDispatch);
    } else {
      this.FilteredLabelsListForDispatch = this.labelsforDispatch.filter(
        (a) => {
          return a['delivery_mode'] === 'हस्ते';
        }
      );
      // console.log(this.FilteredLabelsListForDispatch);
    }
  }


  getrenewalreport1() {


    // console.log(this.contractData);
    if (this.contractReportType !== '') {

      console.log(this.contractReportType)

      this.dialog.open(ContractBriefReportComponent, {

        data: {
          // contractData: this.contractData,
          startDate: this.datePipe.transform(this.RenewalreportForm.value.start, 'yyyy-MM-dd') || '',

          endDate: this.datePipe.transform(this.RenewalreportForm.value.end, 'yyyy-MM-dd') || '',
          repName: this.RenewalreportForm.value.rep,
          offRepName: this.RenewalreportForm.value.officer,
          prantname: this.RenewalreportForm.value.prantname,
          // advtName: this.RenewalreportForm.value.rep district,
          districtName: this.RenewalreportForm.value.districtname == "" ? "" : this.RenewalreportForm.value.districtname,
          talukaName: this.RenewalreportForm.value.talukaname == "" ? "" : this.RenewalreportForm.value.talukaname,
          reportType: this.RenewalreportForm.value.reportType,
          country: this.RenewalreportForm.value.country == "" ? "" : this.RenewalreportForm.value.country,
          state: this.RenewalreportForm.value.statename == "" ? "" : this.RenewalreportForm.value.statename,
        },
        height: '1300px',
        width: '95%',
        maxWidth: '100%',
      });
    }
    else {
      alert('Please select report type');
    }
  }
  exportGSTReceiptData = [];
  excelToExportGSTReport(name) {
      const EXCEL_TYPE =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const EXCEL_EXTENSION = '.xlsx';
  
      // custom code
      const worksheet = XLSX.utils.json_to_sheet(this.exportGSTReceiptData);
  
      const workbook = {
        Sheets: {
          dataSheet: worksheet,
        },
        SheetNames: ['dataSheet'],
      };
  
      const excelBuffer = XLSX.write(workbook, {
        bookType: 'xlsx',
        type: 'array',
      });
  
      const blobData = new Blob([excelBuffer], { type: EXCEL_TYPE });
  
      fileSaver.saveAs(blobData, name);
    }

    startDate_contract;
    enddate_contract; 
    getChangedDateContractM1() {
      if (
        this.RenewalTypereportForm.get('start').value != null &&
        this.RenewalTypereportForm.get('end').value != null
      ) {
        // console.log(this.contractDetailsForm.value);
        this.startDate_contract = formatDate(
          new Date(this.RenewalTypereportForm.get('start').value),
          'yyyy-MM-dd',
          'en-IN',
          'GMT+0530'
        );
        
        this.enddate_contract = formatDate(
          new Date(this.RenewalTypereportForm.get('end').value),
          'yyyy-MM-dd',
          'en-IN',
          'GMT+0530'
        );
      }
    }

    getChangedDateContractM2() {
      if (
        this.RenewalTypereportForm.get('start').value != null &&
        this.RenewalTypereportForm.get('end').value != null
      ) {
        // console.log(this.contractDetailsForm.value);
        this.startDate_contract = formatDate(
          new Date(this.RenewalTypereportForm.get('start').value),
          'yyyy-MM-dd',
          'en-IN',
          'GMT+0530'
        );
        
        this.enddate_contract = formatDate(
          new Date(this.RenewalTypereportForm.get('end').value),
          'yyyy-MM-dd',
          'en-IN',
          'GMT+0530'
        );
      }
    }


    getrenewalreport3() {
      if (this.contractReportType !== '') {
        console.log(this.contractReportType)
        this.dialog.open(ContractBriefReportComponent, {
          data: {
            startDate: this.datePipe.transform(this.RenewalTypereportForm.value.start, 'yyyy-MM-dd') || '',
            endDate: this.datePipe.transform(this.RenewalTypereportForm.value.end, 'yyyy-MM-dd') || '',
            repName: this.RenewalTypereportForm.value.rep,
            offRepName: this.RenewalTypereportForm.value.officer,
            // advtName: this.RenewalreportForm.value.rep district,
            districtName: this.RenewalTypereportForm.value.districtname == "" ? "" : this.RenewalTypereportForm.value.districtname,
            talukaName: this.RenewalTypereportForm.value.talukaname == "" ? "" : this.RenewalTypereportForm.value.talukaname,
            reportType: this.RenewalTypereportForm.value.reportType,
            country: this.RenewalTypereportForm.value.country == "" ? "" : this.RenewalTypereportForm.value.country,
            state: this.RenewalTypereportForm.value.statename == "" ? "" : this.RenewalTypereportForm.value.statename,
            prantname : this.RenewalTypereportForm.value.prantname,
          },
          height: '1300px',
          width: '95%',
          maxWidth: '100%',
        });
      }
      else {
        alert('Please select report type');
      }
    }

    getrenewalreportRemaing() {
      debugger
      this.generateMonthNames( this.datePipe.transform(this.RemainingRenewalreportForm.value.start, 'yyyy-MM-dd') || '', this.datePipe.transform(this.RemainingRenewalreportForm.value.end, 'yyyy-MM-dd') || '')
  
      if (this.contractReportType !== '') {
        console.log(this.contractReportType)
        this.dialog.open(ContractBriefReportComponent, {
          data: {
            // contractData: this.contractData,
            startDate: this.datePipe.transform(this.RemainingRenewalreportForm.value.start, 'yyyy-MM-dd') || '',
            endDate: this.datePipe.transform(this.RemainingRenewalreportForm.value.end, 'yyyy-MM-dd') || '',
            repName: this.RemainingRenewalreportForm.value.rep,
            offRepName: this.RemainingRenewalreportForm.value.officer,
            months: this.months,
            districtName: this.RemainingRenewalreportForm.value.districtname == "" ? "" : this.RemainingRenewalreportForm.value.districtname,
            talukaName: this.RemainingRenewalreportForm.value.talukaname == "" ? "" : this.RemainingRenewalreportForm.value.talukaname,
            reportType: this.RemainingRenewalreportForm.value.reportType,
            country: this.RemainingRenewalreportForm.value.country == "" ? "" : this.RemainingRenewalreportForm.value.country,
            state: this.RemainingRenewalreportForm.value.statename == "" ? "" : this.RemainingRenewalreportForm.value.statename,
            prantname : this.RemainingRenewalreportForm.value.prantname,
  
          },
          height: '1300px',
          width: '95%',
          maxWidth: '100%',
        });
      }
      else {
        alert('Please select report type');
      }
    }
    months:any

  generateMonthNames(startDate: any, endDate: any){
    
    const months = [];
    const start = new Date(startDate);
    const end = new Date(endDate);
    const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 
                        'July', 'August', 'September', 'October', 'November', 'December'];

    while (start <= end) {
      months.push(monthNames[start.getMonth()]);
      start.setMonth(start.getMonth() + 1);
    }

    this.months=months;
  }

  
  getChangedDateContractA1() {
    if (
      this.AllAddForm.get('start').value != null &&
      this.AllAddForm.get('end').value != null
    ) {
      // console.log(this.contractDetailsForm.value);
      this.startDate_contract = formatDate(
        new Date(this.AllAddForm.get('start').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );
      this.enddate_contract = formatDate(
        new Date(this.AllAddForm.get('end').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );
    }
  }

  getrenewalreport6() {
  
  
      // console.log(this.contractData);
      if (this.AllAddForm.value.reportType !== 'Address List') {
      // console.log(this.contractReportType)
      debugger
      this.dialog.open(AlladdComponent, {
        data: {
          // contractData: this.contractData,
          date: this.datePipe.transform(this.AllAddForm.value.start, 'yyyy-MM-dd') || '',
          endDate: this.datePipe.transform(this.AllAddForm.value.end, 'yyyy-MM-dd') || '',
          // repName: this.PendingAddForm.value.rep,
          // repName: this.AllAddForm.value.rep,
          districtName: this.AllAddForm.value.districtname == "" ? "" : this.AllAddForm.value.districtname,
          talukaName: this.AllAddForm.value.talukaname == "" ? "" : this.AllAddForm.value.talukaname,
          modeType: this.AllAddForm.value.modetype,
          country: this.AllAddForm.value.country == "" ? "" : this.AllAddForm.value.country,
          state: this.AllAddForm.value.statename == "" ? "" : this.AllAddForm.value.statename,
          // stateName: this.AllAddForm.value.statename == "" ? "" : this.AllAddForm.value.statemame,
          prantname: this.AllAddForm.value.prantname == "" ? "" : this.AllAddForm.value.prantname,
          duration: this.AllAddForm.value.duration == "" ? "" : this.AllAddForm.value.duration,
          reportType: this.AllAddForm.value.reportType,
        },
  
        height: '1300px',
        width: '95%',
        maxWidth: '100%',
      });
    }else {
      debugger
      this.dialog.open(AlladdlistComponent, {
        data: {
          date: this.datePipe.transform(this.AllAddForm.value.start, 'yyyy-MM-dd') || '',
          endDate: this.datePipe.transform(this.AllAddForm.value.end, 'yyyy-MM-dd') || '',
          districtName: this.AllAddForm.value.districtname == "" ? "" : this.AllAddForm.value.districtname,
          talukaName: this.AllAddForm.value.talukaname == "" ? "" : this.AllAddForm.value.talukaname,
          modeType: this.AllAddForm.value.modetype,
          country: this.AllAddForm.value.country == "" ? "" : this.AllAddForm.value.country,
          state: this.AllAddForm.value.statename == "" ? "" : this.AllAddForm.value.statename,
          prantname: this.AllAddForm.value.prantname == "" ? "" : this.AllAddForm.value.prantname,
          duration: this.AllAddForm.value.duration == "" ? "" : this.AllAddForm.value.duration,
          reportType: this.AllAddForm.value.reportType,
        },
  
        height: '1300px',
        width: '95%',
        maxWidth: '100%',
      });
      
    }
      // console.log(this.data, "all add")
    }

    getChangedDateContractA4() {
      if (
        this.PendingAddForm.get('start').value != null &&
        this.PendingAddForm.get('end').value != null
      ) {
        // console.log(this.contractDetailsForm.value);
        this.startDate_contract = formatDate(
          new Date(this.PendingAddForm.get('start').value),
          'yyyy-MM-dd',
          'en-IN',
          'GMT+0530'
        );
        this.enddate_contract = formatDate(
          new Date(this.PendingAddForm.get('end').value),
          'yyyy-MM-dd',
          'en-IN',
          'GMT+0530'
        );
      }
    }
  

    getrenewalreport5() {
    
    
        // console.log(this.contractData);
        // if (this.contractReportType !== '') {
        console.log(this.contractReportType)
        this.dialog.open(AddmodalComponent, {
          data: {
            // contractData: this.contractData,
            start_date: this.datePipe.transform(this.PendingAddForm.value.start, 'yyyy-MM-dd') || '',
            end_date: this.datePipe.transform(this.PendingAddForm.value.end, 'yyyy-MM-dd') || '',
            // repName: this.PendingAddForm.value.rep,
            repName: this.PendingAddForm.value.rep,
            districtName: this.PendingAddForm.value.districtname == "" ? "" : this.PendingAddForm.value.districtname,
            talukaName: this.PendingAddForm.value.talukaname == "" ? "" : this.PendingAddForm.value.talukaname,
            modeType: this.PendingAddForm.value.modetype,
            country: this.PendingAddForm.value.country == "" ? "" : this.PendingAddForm.value.country,
            state: this.PendingAddForm.value.statename == "" ? "" : this.PendingAddForm.value.statename,
            prantname: this.PendingAddForm.value.prantname == "" ? "" : this.PendingAddForm.value.prantname,
            duration: this.PendingAddForm.value.duration == "" ? "" : this.PendingAddForm.value.duration,
            reportType: this.PendingAddForm.value.reportType,
    
    
          },
    
          height: '1300px',
          width: '95%',
          maxWidth: '100%',
        });
    
      }

      startDate_contract_data;
  enddate_contract_data; 
  getChangedDateContractByVC() {
    if (
      this.databyvocuher.get('start').value != null &&
      this.databyvocuher.get('end').value != null
    ) {
      // console.log(this.contractDetailsForm.value);
      this.startDate_contract_data = formatDate(
        new Date(this.databyvocuher.get('start').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );
      
      this.enddate_contract_data = formatDate(
        new Date(this.databyvocuher.get('end').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );
    }
    console.log( this.startDate_contract_data , this.enddate_contract_data)

    const req1:any = { 
      "start_date":this.startDate_contract_data,
      "invoice_rep_name" : this.databyvocuher.value.repname,
      "end_date":this.enddate_contract_data,
      "voucher_no":this.databyvocuher.value.cvnumber,
    }

    this.service.getdatabyvc(req1).subscribe((data) => {
      
     
      this.databyvc = data;
      this.totalcvamt = 0
    });

    
  }

  selected_rep(event){
    this.rep_nameforcv = event.source.value;
  }

  searchdatabycv(){
    console.log(this.databyvocuher.value.repname);
    this.getChangedDateContractByVC();
  }

  getRepNames(event) {
    if (event.target.value) {
      this.service.getRepNames(event.target.value).subscribe((res) => {
        // console.log(res);
        this.rep_name_data = res;
      });
    }
  }
  
  getVoucherNo(event) {
    // console.log('length',event.target.value.length);
    if (event.target.value.length > 5) {
      this.service.getCreditVoucherNo(event.target.value).subscribe((res) => {
        this.voucherNoList = res;
        console.log(this.voucherNoList);
      });
    }
  }
}

