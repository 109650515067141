// import { Component } from '@angular/core';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DigisubservicesService } from '../services/digisubservices.service';


@Component({
  selector: 'app-repledger',
  templateUrl: './repledger.component.html',
  styleUrls:[ './repledger.component.scss']
})
export class RepledgerComponent {

  currentDate: Date;
  
  start_date = '';
  end_date= '';
  rep_name= '';
  getalldata: any[];
  getalldata1: any;
  getallentries: any[];
  combinedData: any;

  constructor(private dialogRef: MatDialogRef<RepledgerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private service: DigisubservicesService,
    // private cdr: ChangeDetectorRef,private fb: FormBuilder
  )
   { }

   ngOnInit(): void {
    
    // Uncomment and use if needed
    // this.service.getIssuesellersname().subscribe((data) => {
    //   this.issue_seller_name = data;
    // });

    this.currentDate = new Date();
    this.start_date = this.data['startDate'];
    this.end_date = this.data['endDate'];
    this.rep_name = this.data['repname'][0];

    this.service.getrepledgerinfo(this.start_date, this.end_date, this.rep_name).subscribe((data1) => {
      this.combinedData = data1;

      this.service.getrepledgerinfo1(this.start_date, this.end_date, this.rep_name).subscribe((data2) => {
        this.combinedData = this.combinedData.concat(data2);

        console.log('Combined Data:', this.combinedData);
      });
    });
  }

}
