<div mat-dialog id="print-section">
    <div class="container">
        <div class="row" style="margin-bottom: 10px">
            <div class="col-3" *ngFor="let data of representativelist" style="margin-bottom: 10px">
                <span style="font-size: 20px">{{ data["rep_name"] }}</span><br />
                <span style="font-size: 20px">{{ data["address"] }}, {{ data["taluka"] }},
                    {{ data["district"] }},</span><br />
                <span style="font-size: 20px">{{ data["state"] }}, </span>
                <span style="font-size: 20px">{{ data["country"] }} </span>
                <span style="font-size: 20px" *ngIf="data['pincode'] != 0">- {{ data["pincode"] }}</span><br
                    *ngIf="data['pincode'] != 0" />
                <span style="font-size: 20px" *ngIf="data['mob_no'] != 0">{{
                    data["rep_mob_no"]
                    }}</span>
            </div>
        </div>
        <!-- </div> -->
        <button [useExistingCss]="true" class="noPrint" style="margin-top: 2%" mat-raised-button color="primary"
            printSectionId="print-section" ngxPrint>
            print
        </button>
        <button class="noPrint" style="margin-top: 2%; margin-left: 2%" mat-raised-button color="info" mat-dialog-close>
            Close
        </button>
    </div>
</div>