<div>
    <div style="margin-left: 70%; margin-bottom: 2%">
        <!-- <button class="btn btn-info" mat-raised-button (click)="assigndisrep()">
            Add Distribution Rep.
        </button> -->
        <!-- <button [useExistingCss]="true" class="noPrint" style="margin-right: 2%; margin-left: 2%" mat-raised-button
            color="primary" printSectionId="print-section" ngxPrint>
            print
        </button> -->
        <button
        class="noPrint" style="margin-right: 2%; margin-left: 2%" mat-raised-button color="primary"
        printSectionId="print-section" ngxPrint [useExistingCss]="true">
        print
    </button>
        <button mat-dialog-close class="btn noPrint">Close</button>
    </div>
    <div class="container" id="print-section">
        <div style="margin: 0px 15px;">
            <div class="row text-center">
                <div class="col-3">
                    <img src="../../../assets/img/brand/red.png" alt="logo" width="100" height="100">
                </div>
                <div class="col-5 text-center mt-1" >
                    <span style="font-size: 18px;">
                        हिंदुस्थान प्रकाशन संस्था,  विवेक साप्तहिक<br> 
                        ११६, ११७, ११८, ११९,  पहिला मजला, शिल्पीन सेंटर,<br>
                        ग. द. आंबेकर मार्ग, वडाला, मुंबई - ४०००३१
                    </span>

                </div>
                <div class="col-4">
                    <div class="text-end" style="font-size: 14px;">दिनांक:
                        {{ currentDate | date:'dd-MM-yyyy' }}
                    </div>
                    <div class="text-end" style="font-size: 14px;">&nbsp;From: 
                        {{ start_date | date:'dd-MM-yyyy' }}
                    </div>
                    <div class="text-end" style="font-size: 14px;">&nbsp;To: 
                        {{ end_date | date:'dd-MM-yyyy' }}
                    </div>

                </div>
                
            </div>
            <div class="col-12 text-center">
                <div class="text-end" style="font-size: 18px;padding-bottom: 20px; font-weight: bold;">प्रतिनिधी नाव: 
                    {{ rep_name }}
                </div>
            </div>
            <div class="row">
              <div class="col-6" style="border-right: 2px solid #000;border-top: 2px solid #000;border-bottom: 2px solid #000;">
                <p class="cr-dr">Cr</p>
                <div style="display: flex; justify-content: space-between; padding-bottom: 10px;">
                    <span class="text" style="width: 25%;">वो. क्रमांक <span class="text" style="width: 25%;"><br>दिनांक</span></span>
                    <span class="text" style="width: 50%;">खर्च खाते व तपशील</span>
                    <span class="text" style="width: 25%;">जमा रक्कम</span> <br>
                  </div>
              </div>
              <div class="col-6" style="border-top: 2px solid #000;border-bottom: 2px solid #000;">
                <p class="cr-dr">Dr</p>
                <div style="display: flex; justify-content: space-between; padding-bottom: 10px;">
                    <span class="text" style="width: 25%;">वो. क्रमांक  <span class="text" style="width: 25%;"><br>दिनांक</span></span>
                    <span class="text" style="width: 50%;">खर्च खाते व तपशील</span>
                    <span class="text" style="width: 25%;">जमा रक्कम</span> <br>
                </div>
              </div>
            </div>

            <div class="row">
                 <ng-container *ngFor="let item of combinedData; let i = index">
                    <div class="col-6" style="border-right: 2px solid #000;">
                    <div *ngIf="item.voucher_type ==='CR'" >
                        <div style="display: flex; justify-content: space-between; padding-top: 10px;">
                            <span class="text" style="width: 25%;">{{item.voucher_no}} <span class="text" style="width: 25%;"><br>{{item.voucher_date}}</span></span>
                            <span class="text" style="width: 50%;">{{item.department_type}}</span>
                            <span class="text" style="width: 25%;">{{item.voucher_amt}}</span> 
                        </div>
                    </div>
                    </div>
                    <div class="col-6">
                    <div *ngIf="item.voucher_type ==='DB'" style="padding-top: 10px;">
                        <div style="display: flex; justify-content: space-between;">
                            <span class="text" style="width: 25%;">{{item.voucher_no}} <span class="text" style="width: 25%;"><br>{{item.voucher_date}}</span></span>
                            <span class="text" style="width: 50%;">{{item.department_type}}</span>
                            <span class="text" style="width: 25%;">{{item.voucher_amt}}</span>
                        </div>
                    </div>
                    </div>
                </ng-container>
        
                  
                  
                
            </div>
            
          
    
        </div>
    </div>
</div>