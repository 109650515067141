import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PrintchallanComponent } from 'src/app/printchallan/printchallan.component';
import { DigisubservicesService } from 'src/app/services/digisubservices.service';

@Component({
  selector: 'app-hand-delivery',
  templateUrl: './hand-delivery.component.html',
  styleUrls: ['./hand-delivery.component.scss'],
})
export class HandDeliveryComponent implements OnInit {
  handdeliverydata;
  tempdata = [];
  data1 : any;
  Filteredhanddeliverydata = [];
  total_sub_count = 0;
  total_s_bhet = 0;
  name;
  disRepData = [];
  issuedate: any;
  FilteredlabelsforDispatch: any;
  amount = [];
  names = [];
  issuedatenew: any;
  challanname: any;
  combinedList: any;
  total_saprem_bhet: number;
  total_sub: any;
  unqiuelist: any;

  constructor(
    private dialogref: MatDialogRef<HandDeliveryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialog,
    private router: Router,
    private service: DigisubservicesService
  ) { }

  ngOnInit(): void {
    // console.log(this.Filteredhanddeliverydata, "Filteredhanddeliverydata tapal")
    // console.log(this.data1, "Filteredhanddeliverydata tapal")

    this.data1 = this.data['data1']
    this.issuedate = this.data['isuuedate'];
    this.amount = this.data['']
   

   

    this.handdeliverydata = this.data['data'];
    console.log( this.handdeliverydata, " this.data")
    console.log( this.data1, " this.data1")
    // this.service
    //   .getDistributionRepresentativeSBhetHastewise()
    //   .subscribe((res) => {
    //     this.disRepData = res;

    // 
    // this.handdeliverydata.forEach((element: any) => {
    //   element.s_bhet = 0;
    //   this.data1.forEach((data: any) => {
    //     if (element.distribution_rep === data.dis_rep_name) {
    //       element.s_bhet = data.dis_s_bhet
    //     }
    //     else {
    //       element.s_bhet = 0;
    //     }

    //   });
    //   // console.log(this.disRepData);
    // });
    
    // this.handdeliverydata.forEach((element: any) => {
    //   // Set a flag to check if a match is found
    //   let matchFound = false;
    // debugger;
    //   // Iterate over data1 to find a match
    //   this.data1.forEach((data: any) => {
    //     if (element.distribution_rep === data.dis_rep_name) {
    //       element.s_bhet = data.dis_s_bhet;
    //       matchFound = true;
    //     }
        
    //   });
    
    //   // If no match was found, set s_bhet to 0
    //   if (!matchFound) {
    //     element.s_bhet = 0;
    //   }
    // });

    this.combinedList = this.handdeliverydata;

    // for (let i = 0; i < this.combinedList.length; i++){

    //   for (let j = 0; j < this.data1.length; j++) {

    //       if(this.combinedList[i].dis_rep_name === this.data1[j].dsi_rep){

    //         const req={
                       
    //                     "sub_count" : this.data1[j].count
    //                   }
    //                 this.combinedList[i].push(req)
    //                 console.log(this.combinedList,"name same")
    //       }
    //       else{
    //         const req={
    //           "name" : this.data1[j].dsi_rep,     
    //           "sub_count" : this.data1[j].count,
    //           "dist" : this.data1[j].district,     
    //           "taluka" : this.data1[j].taluka,
    //           "dis_mode" : this.data1[j].dis_mode,     
    //           "dis_sub_mode" : this.data1[j].taluka,
    //           "sbhet" : 0
    //         }
    //       this.combinedList[i].push(req)
    //       }
    //   }

    // }
    // this.combinedList = this.handdeliverydata;
    console.log(this.combinedList,"Intial list")

    for (let j = 0; j < this.data1.length; j++) {
      let matchFound = false;
    
      for (let i = 0; i < this.combinedList.length; i++) {
        debugger;
        // Check if `dis_rep_name` matches `dsi_rep`
        if (this.combinedList[i].issueSellerName === this.data1[j].dsi_rep) {
          // Update the existing object
          this.combinedList[i].sub_count = this.data1[j].count;
          this.combinedList[i].dis_dist = this.data1[j].district;
          this.combinedList[i].dis_tal = this.data1[j].taluka;
          this.combinedList[i].dis_mode = this.data1[j].dis_mode;
          this.combinedList[i].dis_sub_mode = this.data1[j].dis_sub_mode;
          this.combinedList[i].dis_s_bhet = 0;
          
          matchFound = true; // Set match found flag to true
          break; // Exit the loop as we've found a match
          debugger;
        }
      }
    
      // If no match was found, add a new object to `combinedList`
      if (!matchFound) {
        const newEntry = {
          issueSellerName: this.data1[j].dsi_rep,
          sub_count: this.data1[j].count,
          dis_dist: this.data1[j].district,
          dis_tal: this.data1[j].taluka,
          dis_mode: this.data1[j].dis_mode,
          dis_sub_mode: this.data1[j].dis_sub_mode,
         
        };
        debugger;
        this.combinedList.push(newEntry); // Add new object to `combinedList`
      }
    }
    
    console.log(this.combinedList, "finale list"); // Check the final updated `combinedList`
    

    // for (let i = 0; i < this.handdeliverydata.length; i++) {
      
    //   for (let j = 0; j < this.data1.length; j++) {
    //     if( this.handdeliverydata[i].dis_rep_name === this.data1[j].dsi_rep){
    //       console.log(this.handdeliverydata[i].dis_rep_name, "same name")

    //       if(this.handdeliverydata[i].dis_s_bhet != '0' && this.data1[j].count != '0'){
    //         const req={
    //           "name": this.handdeliverydata[i].dis_rep_name,
    //           "sbhet" : this.handdeliverydata[i].dis_s_bhet,
    //           "sub_count" : this.data1[j].count
    //         }
    //         this.combinedList.push(req)
            
    //       }
    //     }
    //     else{
    //       const req={
    //         "name": this.data1[j].dsi_rep,
    //         "sbhet" : 0,
    //         "sub_count" : this.data1[j].count,
    //         "dis_mode" : this.data1[j].count,
    //         "dis_sub_mode" : this.data1[j].count,

    //       }
    //       this.combinedList.push(req)
         
    //     }
       
    //   }
    //       const req1={
    //         "name": this.handdeliverydata[i].dis_rep_name,
    //         "sbhet" :  this.handdeliverydata[i].dis_s_bhet,
    //         "sub_count" : 0
    //       }
    //       this.combinedList.push(req1)
    // }

    

    this.total_saprem_bhet = 0;
    this.total_sub = 0;
    for (let data1 of this.combinedList){
    this.total_saprem_bhet += parseInt(data1.s_bhet) || 0;
    this.total_sub += parseInt(data1.sub_count) || 0;

    }
    console.log(this.total_saprem_bhet,this.total_sub, "finale total")


    
    // if (this.handdeliverydata.dis_s_bhet !== "0" && this.data1.count !== 0) {
    //   if (this.handdeliverydata.dis_rep_name === this.data1.dsi_rep || this.handdeliverydata.dis_rep_name || this.data1.dsi_rep) {
    //     const combinedList = {
    //       dis_rep_name: this.handdeliverydata.dis_rep_name || this.data1.dsi_rep,
    //       dis_s_bhet: this.handdeliverydata.dis_s_bhet,
    //       count: this.data1.count
    //     };
    
    //     console.log(combinedList, "combinedList");
    //   }
    // }
    
    


    // console.log(this.handdeliverydata);

    this.name = this.data['name'];
    // console.log('handdeliverydata', this.handdeliverydata);

    this.tempdata = this.uniqueid(
      this.handdeliverydata,
      (a) => a['distribution_rep']
    );

    let arr = [];
    this.tempdata.map((a) => {
      arr.push(a.distribution_rep);
    });

    setTimeout(() => {
      this.disRepData = this.disRepData.filter(
        (a) => !arr.includes(a.dis_rep_name)
      );
      // console.log(this.disRepData);

      // this.disRepData.map((a) => {
      //   this.handdeliverydata.push({
      //     Subscription_Type: '',
      //     address: '',
      //     country: '',
      //     delivery_mode: a['delivery_mode'],
      //     dis_add: a['dis_add'],
      //     dis_country: a['dis_country'],
      //     dis_dist: a['dis_dist'],
      //     dis_mode: a['dis_mode'],
      //     dis_pin: a['dis_pin'],
      //     dis_state: a['dis_state'],
      //     dis_sub_mode: a['dis_sub_mode'],
      //     dis_tal: a['dis_tal'],
      //     distribution_rep: a['dis_rep_name'],
      //     district: '',
      //     entry_date: '',
      //     mobileNumber: '',
      //     name: '',
      //     office_rep_name: '',
      //     pincode: '',
      //     rep_mob_no: '',
      //     rep_name: '',
      //     s_bhet: a['dis_s_bhet'],
      //     state: '',
      //     sub_perid_to_date: '',
      //     taluka: '',
      //     title: '',
      //   });
      // });
      // console.log(this.handdeliverydata);
    }, 600);
    this.amount = []
    // this.sortDataRepresentativewise();
  }

  printchallan() {


    this.dialog.open(PrintchallanComponent, {
      data: {
        issuedate: this.issuedatenew,
        amount: this.amount,
        names: this.names
      },
      width: '1200px',
      height: '1300px',
      maxWidth: '100%',
    });
  }

  checkboxcheck(event, value, name) {
    this.issuedatenew = this.issuedate
    if (event.target.checked) {

      this.amount.push(value)
      this.names.push(name)
    }
    else {
      const index = this.amount.findIndex(item => item.value === value);

      if (index !== -1) {
        this.amount.splice(index, 1);
        this.names.splice(index, 1);
      }
    }
    console.log("checked amt", this.amount)

  }

  // sortDataRepresentativewise() {
  //   this.total_sub_count = 0;
  //   this.total_s_bhet = 0;
  //   for (let index = 0; index < Object.keys(this.tempdata).length; index++) {
  //     let data = this.handdeliverydata.filter(
  //       (a) => a['distribution_rep'] == this.tempdata[index]['distribution_rep']
  //     );

  //     // console.log(data);
  //     let val =
  //       this.tempdata[index]['s_bhet'] === null
  //         ? 0
  //         : parseInt(this.tempdata[index]['s_bhet']);
  //     this.total_sub_count += Object.keys(data).length;
  //     this.total_s_bhet += val;

  //     // this.data1.push(data);
  //     this.Filteredhanddeliverydata.push({
  //       Subscription_Type: this.tempdata[index]['Subscription_Type'],
  //       address: this.tempdata[index]['address'],
  //       country: this.tempdata[index]['country'],
  //       delivery_mode: this.tempdata[index]['delivery_mode'],
  //       district: this.tempdata[index]['district'],
  //       entry_date: this.tempdata[index]['entry_date'],
  //       mobileNumber: this.tempdata[index]['mobileNumber'],
  //       name: this.tempdata[index]['name'],
  //       office_rep_name: this.tempdata[index]['office_rep_name'],
  //       pincode: this.tempdata[index]['pincode'],
  //       rep_mob_no: this.tempdata[index]['rep_mob_no'],
  //       rep_name: this.tempdata[index]['rep_name'],
  //       dis_rep_name: this.tempdata[index]['distribution_rep'],
  //       state: this.tempdata[index]['state'],
  //       sub_perid_to_date: this.tempdata[index]['sub_perid_to_date'],
  //       taluka: this.tempdata[index]['taluka'],
  //       title: this.tempdata[index]['title'],
  //       dis_mode: this.tempdata[index]['dis_mode'],
  //       dis_sub_mode: this.tempdata[index]['dis_sub_mode'],
  //       dis_add: this.tempdata[index]['dis_add'],
  //       dis_country: this.tempdata[index]['dis_country'],
  //       dis_dist: this.tempdata[index]['dis_dist'],
  //       dis_pin: this.tempdata[index]['dis_pin'],
  //       dis_state: this.tempdata[index]['dis_state'],
  //       dis_tal: this.tempdata[index]['dis_tal'],
  //       dis_s_bhet:
  //         this.tempdata[index]['s_bhet'] === null
  //           ? 0
  //           : this.tempdata[index]['s_bhet'],
  //       sub: Object.keys(data).length,
  //     });



  //   }

  //   this.sortDataByTaluka(this.Filteredhanddeliverydata)
  //   // console.log(this.data1);
  //   // console.log(this.Filteredhanddeliverydata);
  //   setTimeout(() => {
  //     this.disRepData.map((a) => {
  //       let val = a['dis_s_bhet'] === null ? 0 : parseInt(a['dis_s_bhet']);
  //       this.total_s_bhet += val;
  //       this.Filteredhanddeliverydata.push({
  //         Subscription_Type: '',
  //         address: '',
  //         country: '',
  //         delivery_mode: a['delivery_mode'],
  //         district: '',
  //         entry_date: '',
  //         mobileNumber: '',
  //         name: '',
  //         office_rep_name: '',
  //         pincode: '',
  //         rep_mob_no: '',
  //         rep_name: '',
  //         dis_rep_name: a['dis_rep_name'],
  //         state: '',
  //         sub_perid_to_date: '',
  //         taluka: '',
  //         title: '',
  //         dis_mode: a['dis_mode'],
  //         dis_sub_mode: a['dis_sub_mode'],
  //         dis_add: a['dis_add'],
  //         dis_country: a['dis_country'],
  //         dis_dist: a['dis_dist'],
  //         dis_pin: a['dis_pin'],
  //         dis_state: a['dis_state'],
  //         dis_tal: a['dis_tal'],
  //         dis_s_bhet: a['dis_s_bhet'] === null ? 0 : a['dis_s_bhet'],
  //         sub: 0,
  //       });
  //     });
  //     this.sortDataByTaluka(this.Filteredhanddeliverydata)
  //   }, 600);



  // }

  sortDataByTaluka(data) {
    data.sort((a, b) => {
      const nameA = a.dis_tal || '';
      const nameB = b.dis_tal || '';
      return nameA.localeCompare(nameB);
    });
  }

  assigndisrep(item) {
    // localStorage.setItem('goto', 'delivery_mode');
    this.dialogref.close({ data: 'refresh', val: item });
  }

  uniqueid(data, key) {
    return [...new Map(data.map((x) => [key(x), x])).values()];
  }

  toInt(no:any) {
    if(no === undefined){
      no = 0
    }
   
    return parseInt(no);
  }
}
