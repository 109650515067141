<div class="container" id="print-section">
    <table class="table table-bordered">
        <thead>
            <tr>
                <th colspan="1" style="font-size: 14px;">Vivek Saptahik</th>
                <th colspan="4" style="font-size: 14px;">Subscriber Details</th>
                <th colspan="2" style="font-size: 14px;">Briefly</th>
            </tr>
            <tr>
                <th style="font-size: 14px;">वर्गणीदार नं</th>
                <th style="font-size: 14px;">वर्गणीदार नाव</th>
                <th style="font-size: 14px;">पत्ता</th>
                <th style="font-size: 14px;">वर्गणीदार प्रकार</th>
                <th style="font-size: 14px;">वर्गणी कालावधी</th>
                <th style="font-size: 14px;">संपर्क</th>
            </tr>
        </thead>

        <tbody>
            <tr *ngFor="let data1 of subscriberdetailsbrief; let i = index">
                <td>{{data1.subscription_type}}{{data1.month}}/{{data1.subno}}</td>
                <td>{{data1.title}}.{{data1.name}}</td>
                <td>{{data1.address}} {{data1.taluka}},{{data1.district}}{{data1.state}} {{data1.country}},{{data1.pincode}} </td>
                <td>{{data1.subscription_type}}</td>
                <td>{{data1.sub_perid_from_date}}-{{data1.sub_perid_to_date}}</td>
                <td>{{data1.mobileNumber}}</td>
                
            </tr>

        </tbody>
    </table>
    <!-- </div> -->
</div>
