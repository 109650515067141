import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DigisubservicesService } from 'src/app/services/digisubservices.service';
import { EncdecriptService } from '../services/encdecrypt.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { AdvertisementDetailsComponent } from '../pages/advertisement-details/advertisement-details.component';



@Component({
  selector: 'app-olddataupdate', 
  templateUrl: './olddataupdate.component.html',
  styleUrls: ['./olddataupdate.component.scss']
})
export class OlddataupdateComponent implements OnInit{
  advt_names: any;
  owner_name: any;
  mob_no1: any;
  filterrep: any;
  GetOffRep: any;
  Advtremainingamt = new FormGroup({
    invoiceNumber: new FormControl(),
    invoiceDate: new FormControl(),
    advtName: new FormControl(),
    issueName: new FormControl(),
    issueDate: new FormControl(),
    receivedAmount: new FormControl(),
    totalamt: new FormControl(),
    pendingamt: new FormControl(),
    rep_name : new FormControl(),
    invoicedate : new FormControl(),
    issuedate : new FormControl(),  
    off_rep_name : new FormControl(),
});
  invoicenumber: any;
  oldissuename: any;
  issuesub : any
  advtNames: any;
  getMagIssueDatesandAdvtNames1: any[];
  filterissuedate: any[];
  advtname: any;
  repname: any;
  invoiceNumber: any;
  invoicedate: any;
  issueName: any;
  issuedate: any;
  totalamt: any;
  receivedAmount: any;
  pendingamt: any;
  id: any;
  off_rep_name: any;
  filterofcrep: any;

  ngOnInit(){
    this.getOffRep1()
    
    console.log(this.data, "checked data for update")
    this.advtname =  this.data['advt_name'],
    this.repname =  this.data['rep_name'],
    this.invoiceNumber =  this.data['invoice_number'],
    this.invoicedate =  this.data['invoice_date'],
    this.issueName =  this.data['issue_name'],
    this.issuedate =  this.data['issue_date'],
    this.totalamt =  this.data['total_amt'],
    this.receivedAmount =  this.data['rcv_amt'],
    this.pendingamt =  this.data['pending_amt'],
    this.id =  this.data['id'],
    this.off_rep_name = this.data['office_rep_name']

this.getissueDate()
    this.Advtremainingamt.patchValue({
      advtName: this.advtname,
      rep_name : this.repname,
      invoiceNumber : this.invoiceNumber,
      invoiceDate: this.invoicedate,
      issueName : this.issueName,
      issueDate : this.issuedate, 
      totalamt: this.totalamt,
      receivedAmount : this.receivedAmount,
      pendingamt : this.pendingamt,
      off_rep_name : this.off_rep_name,
      
    })
    this.Advtremainingamt.get('invoiceDate')?.setValue(new Date());

    this.Advtremainingamt.get('totalamt').valueChanges.subscribe(() => {
      this.updatePendingAmount();
    });
    
    this.Advtremainingamt.get('receivedAmount').valueChanges.subscribe(() => {
      this.updatePendingAmount();
    });
    this.getOffRep();
    this.service.getAdvtissuename().subscribe((data) => {
      // console.log('Database data', data);
      this.issuesub = data;
      this.oldissuename = data;
      console.log(this.issuesub, "issue sub name")
    });
    
    
  }
  getissueDate(){
   
    this.service.getAdvtDatesAndNames1(this.issueName).subscribe((data) => {
      this.getMagIssueDatesandAdvtNames1 = data;
      this.filterissuedate = data;

      this.filterissuedate.forEach(element => {
        if(this.datePipe.transform(element.issueDate, 'dd-MM-yyyy')==this.issuedate){
          this.Advtremainingamt.patchValue({
            issueDate:this.datePipe.transform(element.issueDate, 'dd/MM/yyyy')+","+element.issueName+","+this.datePipe.transform(element.publishedDate, 'dd/MM/yyyy'),
            issuedate:element.issueDate
          })

          // this.setissueDate(element.issueDate)
        }
      });

      
    });
  }
  updateolddata(){
    
    const data:any = {
      "advtName":this.Advtremainingamt.value.advtName,
      "invoiceNumber":this.Advtremainingamt.value.invoiceNumber,
      "rep_name":this.Advtremainingamt.value.rep_name,
      "invoiceDate":this.Advtremainingamt.value.invoiceDate,
      "issueName":this.Advtremainingamt.value.issueName,
      "issue_date":this.Advtremainingamt.value.issueDate,      
      "issueDate":this.Advtremainingamt.value.issueDate,      
      "issuedate":this.Advtremainingamt.value.issuedate,
      "totalamt":this.Advtremainingamt.value.totalamt,
      "receivedAmount":this.Advtremainingamt.value.receivedAmount,
      "pendingamt":this.Advtremainingamt.value.pendingamt,  
      "id": this.id, 
      "off_rep_name" : this.Advtremainingamt.value.off_rep_name,
    }

    this.service.getupdateolddata(data).subscribe((data) => {
      // console.log('Database data', data);
      
    });
    this.toaster.success('success', 'Data Updated Succesfully');
    this.dialog.closeAll()

  }

  constructor(private dialogRef: MatDialogRef<OlddataupdateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,

    private router: Router,
    private formBuilder: FormBuilder,
    public service: DigisubservicesService,
    public dialog: MatDialog,
    public encr: EncdecriptService,
    private _snackBar: MatSnackBar,
    private datePipe: DatePipe,
    private toaster: ToastrService
  ) { }

  getAdvtNames(event) {
    if (event.target.value.length > 2) {
      this.service.getAdvtNames(event.target.value).subscribe((res) => {
        this.advt_names = res;
        // console.log(res);
      });
    }
  }
  addEvent(event: MatDatepickerInputEvent<Date>): void {
    this.Advtremainingamt.get('invoiceDate').setValue(event.value);
    console.log(this.Advtremainingamt)
  }
  advt_name1;
  selected_name1(event, option) {
    if (event.isUserInput) {
      this.advt_name1 = option['name'];
      this.owner_name = 'abc';
      this.mob_no1 = 777;
    }
  }
  getRepNames(event:any){
    if(event.target.value!=""){
      this.filterrep=this.GetOffRep.filter((data:any)=>
        data.rep_name.toLowerCase().includes(event.target.value.toLowerCase())
    )
    }else{
      this.filterrep=this.GetOffRep
    }
  }
  getOffRep(){
    
    this.service.getOffRepSub().subscribe((data:any) => {
      console.log("represntive name",data);
    this.GetOffRep = (data as any);
    this.filterrep = (data as any);
    });
  }

  getOffRep1(){
    
    
    this.service.getOffRepSub1().subscribe((data:any) => {
      console.log("represntive name",data);
    this.GetOffRep = (data as any);
    this.filterofcrep = (data as any);
    });
  }
  getContractRepNames(event) {
    this.Advtremainingamt.patchValue({
     rep_name: event.value
    })
   }

   getContractOffRepNames(event) {
    this.Advtremainingamt.patchValue({
     rep_name: event.value
    })
   }
   

   getIssueNames(event:any){
    if(event.target.value!=""){
      this.oldissuename=this.issuesub.filter((data:any)=>
        data.issueName.toLowerCase().includes(event.target.value.toLowerCase())
    )
    }else{
      this.oldissuename=this.issuesub
    }
  }
  getIssuesAdvtNames1(e) {
    // console.log(e);
    this.advtNames = e.source.value;
    // console.log('jgfdfgh', this.advtNames);
    this.service.getAdvtDatesAndNames1(e.source.value).subscribe((data) => {
      this.getMagIssueDatesandAdvtNames1 = data;
      this.filterissuedate = data;
    });
  }
  setissueDate(date:any,issueName:any,publishDate:any){
    this.Advtremainingamt.patchValue({
      issuedate:date,
      issueDate:this.datePipe.transform(date, 'dd/MM/yyyy')+","+issueName+","+this.datePipe.transform(publishDate, 'dd/MM/yyyy')

    })
  }
  GenerateCRno2(){
    let indate = this.datePipe.transform(this.Advtremainingamt.value.invoiceDate, 'dd-MM-yyyy');

    // let indate = this.Advtremainingamt.value.invoiceDate;
    console.log(indate, "indate")
  }


  getIssuedateNames(event:any){
    if(event.target.value!=""){
      this.filterissuedate=this.getMagIssueDatesandAdvtNames1.filter((data:any)=>
        data.issueDate.toLowerCase().includes(event.target.value.toLowerCase())
    )
    }else{
      this.filterissuedate=this.getMagIssueDatesandAdvtNames1
    }
  }
 
  generateinv(event){

    let parts = event.target.value.split('-');

    if(parts[0]!="VSJ"){
      return;

    }
    let parts1 = parts[1].split('/');
    if(parts1[0].length==2){
      let val =parts[0]+"-"+ parts1[0] + "/"
      if(parts1[1]==undefined) {
        this.invoicenumber=val
        return
      }
      
      if(parts1[1].length==4){
        val =val + parts1[1] + "/"
        if(parts1[2]==undefined) {
          this.invoicenumber=val
          return
        }
        if(parts1[2].length==4){
          val=val+ parts1[2]
          return
        }else{
          this.invoicenumber=event.target.value
        }
      }else{
        this.invoicenumber=event.target.value
      }
      this.invoicenumber=event.target.value
    }
    
  }
  updatePendingAmount(): void {
    const totalamt = this.Advtremainingamt.get('totalamt').value;
    const receivedAmount = this.Advtremainingamt.get('receivedAmount').value;
  
    if (totalamt !== null && receivedAmount !== null) {
      const pendingamt = totalamt - receivedAmount;
      this.Advtremainingamt.patchValue({ pendingamt }, { emitEvent: false });
    }
  }


  

  
}
