<div mat-dialog id="print-section" style="padding-top: 10px;padding-bottom:10px;">
    <div class="container">
        <div class="row" style="margin-bottom: 15px;margin-top: 15px;">
            <div class="col-3" *ngFor="let data of subscribersaddressdata" style="margin-bottom: 20px">
                <span style="font-size: 20px">{{ data["title"] }}{{ data["name"] }}</span><br />
                <span style="font-size: 20px">{{ data["address"] }}, <span *ngIf="data['taluka'] != ''">{{
                        data["taluka"] }},</span>
                    <span *ngIf="data['district'] != ''">{{ data["district"] }},</span>
                    <span style="font-size: 20px" *ngIf="data['pincode'] != 0">- {{ data["pincode"]
                        }}</span></span><br />
                <span style="font-size: 20px" *ngIf="data['state'] != ''">{{ data["state"] }} </span>
                <!-- <span style="font-size: 20px" *ngIf="data['country'] != ''">{{ data["country"] }} </span> -->
                <!-- <br /> -->
                <span style="font-size: 20px" *ngIf="data['mobileNumber'] != 0">{{
                    data["mobileNumber"]
                    }}</span>
            </div>
        </div>
        <!-- </div> -->
        <button [useExistingCss]="true" class="noPrint" style="margin-top: 2%" mat-raised-button color="primary"
            printSectionId="print-section" ngxPrint>
            print
        </button>
        <button class="noPrint" style="margin-top: 2%; margin-left: 2%" mat-raised-button color="info" mat-dialog-close>
            Close
        </button>
    </div>
</div>