<div>
    <div style="margin-left: 70%;  margin-bottom: 2%">
        <!-- <button class="btn btn-info" mat-raised-button (click)="assigndisrep()">
                Add Distribution Rep.
            </button> -->
        <button class="noPrint" style="margin-right: 2%; margin-left: 2%" mat-raised-button
            color="primary" printSectionId="print-section" ngxPrint  [useExistingCss]="true">
            print
        </button>
        <button mat-dialog-close onclick="closeexp()" class="btn noPrint">Close</button>
    </div>
    <div class="container"  id="print-section">
        <div class="wrapper">
            <div class="row rowborder">
                <div class="common">
                    <span>दिनांक : {{ getFormattedDate() }}</span>
                    <span>पृष्ठ क्र : 1</span>
                </div>
                <div class="common">
                    <span>हिंदुस्थान प्रकाशन संस्था</span>
                    <span>विवेक साप्ताहिक</span>
                    <span>खर्च खाते अनूपुस्तिका</span>
                </div>
            </div>
            <div class="row rowborder" style="gap: 0;">
                <div class="col-2 common">
                    <span>सांकेतांक</span>
                    <span>दिनांक</span>
                </div>
                <div class="col-4">
                    <span>तपशील  {{expname}}</span>
                </div>
                <div class="col-2">
                    <span>डेबिट</span>
                </div>
                <div class="col-2">
                    <span>क्रेडिट</span>
                </div>
                <div class="col-2">
                    <span>रक्कम</span>
                </div>
            </div>
            <div class="row rowborder">
                <div class="col-2">
                    <span>हिशेब खाते :</span>
                </div>
                <!-- <div class="col-1"></div> -->
                <div class="col-2">
                    <span>expense name</span>
                </div>
                <div class="col-2">
                    <span>मागील बाकी :</span>
                </div>
                <div class="col-2">
                    <span>{{dr_opening_bal}}</span>
                     
                </div>
                <div class="col-2">
                    <span>{{cr_opening_bal}}</span>
                 </div>
            </div>
            <div class="row" *ngFor="let item of allvoucherdata">
                <div class="col-2 common">
                    <span>{{ item['voucher_no'] }}</span>
                    <span>{{ item['voucher_date'] | date:"dd-MM-yyyy" }}</span>
                </div>
                <div class="col-4">
                    <span *ngIf="item['exp_name']">{{ item['exp_name'] }} <span style="font-size: 10px;">{{item['narration']}} -  {{item['pan_card']}}</span></span>
                    <!-- <span *ngIf="item['rep_name'] == ''">{{ item['other_name'] }}</span> -->

                </div>
                <div class="col-2">
                    <span *ngIf="item.voucher =='dr'">{{ item['exp_amt'] | number:'1.2-2'
                        }} </span>
                </div>
                <div class="col-2">
                    <span *ngIf="item.voucher == 'cr'">{{ item['exp_amt'] | number:'1.2-2'
                        }}</span>
                </div>
                <div class="col-2">
                    {{ item['total'] | number:'1.2-2' }}
                </div>
            </div>
            <div class="row rowborder rowbordertop" style="gap: 0;">
                <div class="col-4"></div>
                <div class="col-2 common" style="gap: 10px;">
                    <span>एकूण :</span>
                    <!-- <span>बाकी पुढे :</span> -->
                </div>
                <div class="col-2 common">
                    <span style="padding-bottom: 5px;">{{ debittotal | number:'1.2-2' }}</span>
                    <div class="line"></div>
                    <!-- <span style="padding-top: 5px;">nextbaki</span> 
                        <span style="padding-bottom: 5px;">{{ debittotal | number:'1.2-2' }}</span>
-->
                </div>
                <div class="col-2">
                    <span> {{credittotal | number:'1.2-2'}} </span>
                </div>
            </div>
        </div>
        <print-preview-layout-settings class="settings-section"></print-preview-layout-settings>

    </div>
</div>