<div>
    <div style="margin-left: 70%;  margin-bottom: 2%">
        <!-- <button class="btn btn-info" mat-raised-button (click)="assigndisrep()">
                Add Distribution Rep.
            </button> -->
        <button class="noPrint" style="margin-right: 2%; margin-left: 2%" mat-raised-button color="primary"
            printSectionId="print-section" ngxPrint [useExistingCss]="true">
            print
        </button>
        <button mat-dialog-close onclick="closeexp()" class="btn noPrint">Close</button>
    </div>
    <div class="container" id="print-section">
        <div class="wrapper">
            <div class="row rowborder">
                <div class="common">
                    <span>दिनांक : {{ getFormattedDate() }}</span>
                    <span>पृष्ठ क्र : 1</span>
                </div>
                <div class="common">
                    <span>हिंदुस्थान प्रकाशन संस्था</span>
                    <span>विवेक साप्ताहिक</span>
                    <span>खर्च खाते अनूपुस्तिका</span>
                </div>
            </div>
            <div class="row rowborder" style="gap: 0;">
                <div class="col-2 common">
                    <span>सांकेतांक</span>
                    <span>दिनांक</span>
                </div>
                <div class="col-4">
                    <span>तपशील: {{expname}}</span>
                </div>
                <div class="col-2">
                    <span>डेबिट</span>
                </div>
                <div class="col-2">
                    <span>क्रेडिट</span>
                </div>
                <div class="col-2">
                    <span>रक्कम</span>
                </div>
            </div>
            <div class="row rowborder">
                <div class="col-2">
                    <span>हिशेब खाते :</span>
                </div>
                <!-- <div class="col-1"></div> -->
                <div class="col-2">
                    <span>expense name</span>
                </div>
                <div class="col-2">
                    <span>मागील बाकी :</span>
                </div>
                <div class="col-2">
                    <span>{{ dr_opening_bal }}</span>

                </div>
                <div class="col-2">
                    <span>{{cr_opening_bal}}</span>
                </div>
            </div>
            <div class="row" *ngFor="let item of allvoucherdata">
                <div class="col-2 common">
                    <span>{{ item['voucher_no'] }}</span>
                    <span>{{ item['voucher_date'] | date:"dd-MM-yyyy" }}</span>
                </div>
                <div class="col-4">
                    <span *ngIf="item['exp_name']">
                        {{ item['exp_name'] }}

                        <span *ngIf="item['rep_name']" style="font-size: 10px; margin-left: 2px;">
                            (rep_name: {{ item['rep_name'] }})
                        </span>

                        <span *ngIf="item['narration']" style="font-size: 10px; margin-left: 2px;">
                            (narration: {{ item['narration'] }})
                        </span>

                        <span *ngIf="item['pan_card']" style="font-size: 10px; margin-left: 2px; color: gray;">
                            [PAN: {{ item['pan_card'] }}]
                        </span>
                    </span>
                </div>
                <div class="col-2">
                    <span *ngIf="item.voucher =='dr'">{{ item['exp_amt'] | number:'1.2-2'
                        }} </span>
                </div>
                <div class="col-2">
                    <span *ngIf="item.voucher == 'cr'">{{ item['exp_amt'] | number:'1.2-2'
                        }}</span>
                </div>
                <div class="col-2">
                    {{ item['total'] | number:'1.2-2' }}
                </div>
            </div>
            <div class="row rowborder rowbordertop" style="gap: 2;">
                <div class="col-4"></div>

                <!-- Left Column with Labels -->
                <!-- <div class="col-2 common d-flex flex-column justify-content-center"> -->
                <div class="col-2 common d-flex flex-column text-end">
                    <span style="margin-bottom: 5px;">एकूण :-</span>
                    <span>बाकी पुढे :-</span>
                </div>

                <!-- Debit Column -->
                <div class="col-2 common d-flex flex-column text-end">
                    <span style="padding-bottom: 5px;">
                        {{ (dr_opening_bal - debittotal) | number:'1.2-2' }}
                    </span>
                    <!-- Show line if the condition is met -->
                    <div class="line" *ngIf="(cr_opening_bal - credittotal) + (dr_opening_bal - debittotal) < 0"></div>
                    <span *ngIf="(cr_opening_bal - credittotal) + (dr_opening_bal - debittotal) < 0"
                        style="padding-bottom: 5px;">
                        {{ (cr_opening_bal - credittotal) + (dr_opening_bal - debittotal) | number:'1.2-2' }}
                    </span>
                </div>

                <!-- Credit Column -->
                <div class="col-2 common d-flex flex-column text-end">
                    <span style="padding-bottom: 5px;">
                        {{ cr_opening_bal - credittotal | number:'1.2-2' }}
                    </span>
                    <!-- Show line if the condition is met -->
                    <div class="line" *ngIf="(cr_opening_bal - credittotal) + (dr_opening_bal - debittotal) >= 0"></div>
                    <span *ngIf="(cr_opening_bal - credittotal) + (dr_opening_bal - debittotal) >= 0"
                        style="padding-bottom: 5px;">
                        {{ (cr_opening_bal - credittotal) + (dr_opening_bal - debittotal) | number:'1.2-2' }}
                    </span>
                </div>
            </div>

        </div>
        <print-preview-layout-settings class="settings-section"></print-preview-layout-settings>

    </div>
</div>