import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
// import { DigisubservicesService } from 'src/app/services/digisubservices.service';
import { FormBuilder } from '@angular/forms';
import { AdvertisementDetailsComponent } from '../pages/advertisement-details/advertisement-details.component';
import { DigisubservicesService } from 'src/app/services/digisubservices.service';

@Component({
  selector: 'app-alladd',
  templateUrl: './alladd.component.html',
  styleUrls: ['./alladd.component.scss']
})
export class AlladdComponent implements OnInit {
  AllAdd: any;

  constructor(private dialogRef: MatDialogRef<AlladdComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private service: DigisubservicesService,
    private cdr: ChangeDetectorRef,private fb: FormBuilder
  )
   { }

   district:any
   taluka:any;
   modetype:any
   start_date:any
   end_date;any
  ngOnInit(): void {

    this.district= this.data['districtName']
    this.taluka= this.data['talukaName']
    this.modetype= this.data['modeType']
    this.start_date=  this.data['date']
    this.end_date=  this.data['endDate']


    const req:any = {
    
      "delivery_mode":this.modetype,
      "district":this.district,
      "taluka":this.taluka,
      "start_date": this.start_date,
      "end_date": this.end_date,
    
    }

    console.log(req, "new all address")

    this.service.getgetAllAdd(req).subscribe((data:any) => {
      console.log("pending add=",data);
     this.AllAdd = (data as any);;
    });

    console.log("req 2 for add", req)
  }

}
