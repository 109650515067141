<!-- <head>
  <link
    rel="stylesheet"
    href="https://netdna.bootstrapcdn.com/bootstrap/3.0.0/css/bootstrap.min.css"
  />
  <link
    rel="stylesheet"
    href="https://code.jquery.com/ui/1.11.4/themes/smoothness/jquery-ui.css"
  />
</head> -->
<body>
  <div id="print-section">
    <div
      class="container"
      style="
        border-width: 0px 0px 1px 0px;
        border-color: black;
        border-style: solid;
      "
    >
      <div
        class="row"
        style="
          border-width: 1px 1px 0px 1px;
          border-color: black;
          border-style: solid;
          text-align: center;
        "
      >
        <div
          class="col-8"
          style="padding: 3px; font-size: 14px; font-weight: 600"
        >
          हिंदुस्थान प्रकाशन संस्था - विवेक साप्तहिक - वर्गणी विभाग
        </div>
        <div
          class="col-1"
          style="
            border-width: 0px 0px 0px 1px;
            border-color: black;
            border-style: solid;
            padding: 1px;
          "
        >
          दिनांक -
        </div>
        <div
          class="col-1"
          style="
            border-width: 0px 0px 0px 1px;
            border-color: black;
            border-style: solid;
            padding: 1px;
          "
        >
          {{ today }}
        </div>
        <div
          class="col-2"
          style="
            border-width: 0px 0px 0px 1px;
            border-color: black;
            border-style: solid;
            padding: 1px;
          "
        ></div>
      </div>
      <div
        class="row"
        style="
          border-width: 1px 1px 0px 1px;
          border-color: black;
          border-style: solid;
        "
      >
        <div
          class="col-8"
          style="padding: 1px; font-size: 14px; font-weight: 600"
        >
          <span style="text-align: left"
            >वर्गणीदार नोंदणी - नवीन / नूतनीकरण रोजमेळ -</span
          >
          <span style="margin-left: 50px">दिनांकानुसार / प्रतिनिधीनुसार</span>
        </div>
        <div
          class="col-1"
          style="
            border-width: 0px 0px 0px 1px;
            border-color: black;
            border-style: solid;
            padding: 1px;
            text-align: center;
          "
        >
          दिनांक -
        </div>
        <div
          class="col-1"
          style="
            border-width: 0px 0px 0px 1px;
            border-color: black;
            border-style: solid;
            padding: 1px;
            text-align: center;
          "
        >
          {{ today }}
        </div>
        <div
          class="col-2"
          style="
            border-width: 0px 0px 0px 1px;
            border-color: black;
            border-style: solid;
            padding: 1px;
          "
        ></div>
      </div>
      <div
        *ngFor="let item of datewiserepwiselist; let i = index; let l = last"
        class="row"
      >
        <table
          class="table table-bordered"
          style="
            border-width: 1px 1px 0px 1px;
            border-color: black;
            border-style: solid;
            margin-bottom: -02px;
          "
        >
          <thead *ngIf="i == 0">
            <tr>
              <th
                style="
                  border-width: 0px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                "
              >
                पावती क्र
              </th>
              <th
                style="
                  border-width: 0px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                "
              >
                वर्गणीदाराचे नाव
              </th>
              <th
                style="
                  border-width: 0px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                "
              >
                तालुका
              </th>
              <th
                style="
                  border-width: 0px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                "
              >
                जिल्हा
              </th>
              <th
                style="
                  border-width: 0px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                "
              >
                प्रतिनिधींचे नाव
              </th>
              <th
                style="
                  border-width: 0px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                "
              >
                कार्यालय प्रतिनिधींचे नाव
              </th>
              <th
                colspan="3"
                style="
                  border-width: 0px 1px 1px 0px;
                  border-color: black;
                  border-style: solid;
                "
              >
                एकूण
              </th>
              <th
                colspan="2"
                style="
                  border-width: 0px 1px 1px 0px;
                  border-color: black;
                  border-style: solid;
                "
              >
                वार्षिक
              </th>
              <th
                style="
                  border-width: 0px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                "
              >
                रक्कम रुपये
              </th>
              <th
                colspan="2"
                style="
                  border-width: 0px 1px 1px 0px;
                  border-color: black;
                  border-style: solid;
                "
              >
                त्रै वार्षिक
              </th>
              <th
                style="
                  border-width: 0px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                "
              >
                रक्कम रुपये
              </th>
              <th
                colspan="2"
                style="
                  border-width: 0px 1px 1px 0px;
                  border-color: black;
                  border-style: solid;
                "
              >
                पंच वार्षिक
              </th>
              <th
                style="
                  border-width: 0px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                "
              >
                रक्कम रुपये
              </th>
              <th
                colspan="2"
                style="
                  border-width: 0px 1px 1px 0px;
                  border-color: black;
                  border-style: solid;
                "
              >
                सहयोगी
              </th>
              <th
                style="
                  border-width: 0px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                "
              >
                रक्कम रुपये
              </th>
              <th
                style="
                  border-width: 0px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                "
              >
                अनामत
              </th>
              <th
                style="
                  border-width: 0px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                "
              >
                पंचठेव
              </th>
              <th
                style="
                  border-width: 0px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                "
              >
                इतर जमा
              </th>
            </tr>
            <tr>
              <th
                rowspan="2"
                style="
                  border-width: 0px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                "
              ></th>
              <th
                rowspan="2"
                style="
                  border-width: 0px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                "
              ></th>
              <th
                rowspan="2"
                style="
                  border-width: 0px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                "
              ></th>
              <th
                rowspan="2"
                style="
                  border-width: 0px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                "
              ></th>
              <th
                rowspan="2"
                style="
                  border-width: 0px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                "
              ></th>
              <th
                rowspan="2"
                style="
                  border-width: 0px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                "
              ></th>
              <th
                style="
                  border-width: 0px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                "
              >
                रक्कम रुपये
              </th>
              <th
                style="
                  border-width: 0px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                "
              >
                नवीन
              </th>
              <th
                style="
                  border-width: 0px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                "
              >
                नूतनी करण
              </th>
              <th
                style="
                  border-width: 0px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                "
              >
                नवीन
              </th>
              <th
                style="
                  border-width: 0px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                "
              >
                नूतनी करण
              </th>
              <th
                rowspan="2"
                style="
                  border-width: 0px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                "
              ></th>
              <th
                style="
                  border-width: 0px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                "
              >
                नवीन
              </th>
              <th
                style="
                  border-width: 0px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                "
              >
                नूतनी करण
              </th>
              <th
                rowspan="2"
                style="
                  border-width: 0px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                "
              ></th>
              <th
                style="
                  border-width: 0px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                "
              >
                नवीन
              </th>
              <th
                style="
                  border-width: 0px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                "
              >
                नूतनी करण
              </th>
              <th
                rowspan="2"
                style="
                  border-width: 0px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                "
              ></th>
              <th
                style="
                  border-width: 0px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                "
              >
                नवीन
              </th>
              <th
                style="
                  border-width: 0px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                "
              >
                नूतनी करण
              </th>
              <th
                rowspan="2"
                style="
                  border-width: 0px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                "
              ></th>
              <th
                rowspan="2"
                style="
                  border-width: 0px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                "
              ></th>
              <th
                rowspan="2"
                style="
                  border-width: 0px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                "
              ></th>
              <th
                rowspan="2"
                style="
                  border-width: 0px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                "
              ></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of item; let end = last">
              <td
                style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                "
              >
                {{ data["rcpt_no"] }}
              </td>
              <td
                style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                "
              >
                <span *ngIf="data['title'] != ''">{{ data["title"] }}.</span>
                {{ data["name"] }}
              </td>
              <td
                style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                "
              >
                {{ data["taluka"] }}
              </td>
              <td
                style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                "
              >
                {{ data["district"] }}
              </td>
              <td
                style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                "
              >
                {{ data["rep_name"] }}
              </td>
              <td
                style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                "
              >
                {{ data["office_rep_name"] }}
              </td>
              <td
                style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                "
              >
                {{ data["total_amt"] }}
              </td>
              <td
                style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                "
              >
                <span *ngIf="data['rcpt_type'] == 'New'">1</span>
                <span *ngIf="data['rcpt_type'] != 'New'">0</span>
              </td>
              <td
                style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                "
              >
                <span *ngIf="data['rcpt_type'] == 'Renew'">1</span>
                <span *ngIf="data['rcpt_type'] != 'Renew'">0</span>
              </td>
              <td
                style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                "
              >
                <span *ngIf="data['subscription_type'] == 'वार्षिक'">
                  <span *ngIf="data['rcpt_type'] == 'New'">1</span>
                  <span *ngIf="data['rcpt_type'] != 'New'">0</span>
                </span>
                <span *ngIf="data['subscription_type'] != 'वार्षिक'">0</span>
              </td>
              <td
                style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                "
              >
                <span *ngIf="data['subscription_type'] == 'वार्षिक'">
                  <span *ngIf="data['rcpt_type'] == 'Renew'">1</span>
                  <span *ngIf="data['rcpt_type'] != 'Renew'">0</span>
                </span>
                <span *ngIf="data['subscription_type'] != 'वार्षिक'">0</span>
              </td>
              <td
                style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                "
              >
                <span *ngIf="data['subscription_type'] == 'वार्षिक'">{{
                  data["total_amt"]
                }}</span>
                <span *ngIf="data['subscription_type'] != 'वार्षिक'">0</span>
              </td>
              <td
                style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                "
              >
                <span *ngIf="data['subscription_type'] == 'त्रैवार्षिक'">
                  <span *ngIf="data['rcpt_type'] == 'New'">1</span>
                  <span *ngIf="data['rcpt_type'] != 'New'">0</span>
                </span>
                <span *ngIf="data['subscription_type'] != 'त्रैवार्षिक'"
                  >0</span
                >
              </td>
              <td
                style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                "
              >
                <span *ngIf="data['subscription_type'] == 'त्रैवार्षिक'">
                  <span *ngIf="data['rcpt_type'] == 'Renew'">1</span>
                  <span *ngIf="data['rcpt_type'] != 'Renew'">0</span>
                </span>
                <span *ngIf="data['subscription_type'] != 'त्रैवार्षिक'"
                  >0</span
                >
              </td>
              <td
                style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                "
              >
                <span *ngIf="data['subscription_type'] == 'त्रैवार्षिक'">{{
                  data["total_amt"]
                }}</span>
                <span *ngIf="data['subscription_type'] != 'त्रैवार्षिक'"
                  >0</span
                >
              </td>
              <td
                style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                "
              >
                <span *ngIf="data['subscription_type'] == 'पंचवार्षिक'">
                  <span *ngIf="data['rcpt_type'] == 'New'">1</span>
                  <span *ngIf="data['rcpt_type'] != 'New'">0</span>
                </span>
                <span *ngIf="data['subscription_type'] != 'पंचवार्षिक'">0</span>
              </td>
              <td
                style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                "
              >
                <span *ngIf="data['subscription_type'] == 'पंचवार्षिक'">
                  <span *ngIf="data['rcpt_type'] == 'Renew'">1</span>
                  <span *ngIf="data['rcpt_type'] != 'Renew'">0</span>
                </span>
                <span *ngIf="data['subscription_type'] != 'पंचवार्षिक'">0</span>
              </td>
              <td
                style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                "
              >
                <span *ngIf="data['subscription_type'] == 'पंचवार्षिक'">{{
                  data["total_amt"]
                }}</span>
                <span *ngIf="data['subscription_type'] != 'पंचवार्षिक'">0</span>
              </td>
              <td
                style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                "
              >
                <span *ngIf="data['subscription_type'] == 'सहयोगी'">
                  <span *ngIf="data['rcpt_type'] == 'New'">1</span>
                  <span *ngIf="data['rcpt_type'] != 'New'">0</span>
                </span>
                <span *ngIf="data['subscription_type'] != 'सहयोगी'">0</span>
              </td>
              <td
                style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                "
              >
                <span *ngIf="data['subscription_type'] == 'सहयोगी'">
                  <span *ngIf="data['rcpt_type'] == 'Renew'">1</span>
                  <span *ngIf="data['rcpt_type'] != 'Renew'">0</span>
                </span>
                <span *ngIf="data['subscription_type'] != 'सहयोगी'">0</span>
              </td>
              <td
                style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                "
              >
                <span *ngIf="data['subscription_type'] == 'सहयोगी'">{{
                  data["total_amt"]
                }}</span>
                <span *ngIf="data['subscription_type'] != 'सहयोगी'">0</span>
              </td>
              <td
                style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                "
              >
                <span *ngIf="data['subscription_type'] == 'अनामत'">{{
                  data["total_amt"]
                }}</span>
                <span *ngIf="data['subscription_type'] != 'अनामत'">0</span>
              </td>
              <td
                style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                "
              >
                <span *ngIf="data['subscription_type'] == 'पंचठेव'">{{
                  data["total_amt"]
                }}</span>
                <span *ngIf="data['subscription_type'] != 'पंचठेव'">0</span>
              </td>
              <td
                style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                "
              >
                <span *ngIf="data['subscription_type'] == 'इतर जमा'">{{
                  data["total_amt"]
                }}</span>
                <span *ngIf="data['subscription_type'] != 'इतर जमा'">0</span>
              </td>
            </tr>
            <tr *ngFor="let val of tempdata; let s = index">
              <td
                *ngIf="i == s"
                style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                  font-weight: bolder;
                "
              >
                {{ val["entry_date"] | date: "dd-MM-yyy" }}
              </td>
              <td
                *ngIf="i == s"
                style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                  font-weight: bolder;
                "
              ></td>
              <td
                *ngIf="i == s"
                style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                  font-weight: bolder;
                "
              ></td>
              <td
                *ngIf="i == s"
                style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                  font-weight: bolder;
                "
              ></td>
              <td
                *ngIf="i == s"
                style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                  font-weight: bolder;
                "
              ></td>
              <td
                *ngIf="i == s"
                style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                  font-weight: bolder;
                "
              ></td>
              <td
                *ngIf="i == s"
                style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                  font-weight: bolder;
                "
              >
                {{ val["atotal"] }}
              </td>
              <td
                *ngIf="i == s"
                style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                  font-weight: bolder;
                "
              >
                {{ val["anew"] }}
              </td>
              <td
                *ngIf="i == s"
                style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                  font-weight: bolder;
                "
              >
                {{ val["arenew"] }}
              </td>
              <td
                *ngIf="i == s"
                style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                  font-weight: bolder;
                "
              >
                {{ val["vnew"] }}
              </td>
              <td
                *ngIf="i == s"
                style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                  font-weight: bolder;
                "
              >
                {{ val["vrenew"] }}
              </td>
              <td
                *ngIf="i == s"
                style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                  font-weight: bolder;
                "
              >
                {{ val["vtotal"] }}
              </td>
              <td
                *ngIf="i == s"
                style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                  font-weight: bolder;
                "
              >
                {{ val["tnew"] }}
              </td>
              <td
                *ngIf="i == s"
                style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                  font-weight: bolder;
                "
              >
                {{ val["trenew"] }}
              </td>
              <td
                *ngIf="i == s"
                style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                  font-weight: bolder;
                "
              >
                {{ val["ttotal"] }}
              </td>
              <td
                *ngIf="i == s"
                style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                  font-weight: bolder;
                "
              >
                {{ val["pnew"] }}
              </td>
              <td
                *ngIf="i == s"
                style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                  font-weight: bolder;
                "
              >
                {{ val["prenew"] }}
              </td>
              <td
                *ngIf="i == s"
                style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                  font-weight: bolder;
                "
              >
                {{ val["ptotal"] }}
              </td>
              <td
                *ngIf="i == s"
                style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                  font-weight: bolder;
                "
              >
                {{ val["snew"] }}
              </td>
              <td
                *ngIf="i == s"
                style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                  font-weight: bolder;
                "
              >
                {{ val["srenew"] }}
              </td>
              <td
                *ngIf="i == s"
                style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                  font-weight: bolder;
                "
              >
                {{ val["stotal"] }}
              </td>
              <td
                *ngIf="i == s"
                style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                  font-weight: bolder;
                "
              >
                {{ val["anamat"] }}
              </td>
              <td
                *ngIf="i == s"
                style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                  font-weight: bolder;
                "
              >
                {{ val["punchthev"] }}
              </td>
              <td
                *ngIf="i == s"
                style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                  font-weight: bolder;
                "
              >
                {{ val["other"] }}
              </td>
            </tr>
            <tr *ngIf="l">
              <td
                colspan="6"
                style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                  font-weight: bolder;
                  text-align: center;
                "
              >
                एकूण
              </td>
              <td
                style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                  font-weight: bolder;
                "
              >
                {{ atotal }}
              </td>
              <td
                style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                  font-weight: bolder;
                "
              >
                {{ anew }}
              </td>
              <td
                style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                  font-weight: bolder;
                "
              >
                {{ arenew }}
              </td>
              <td
                style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                  font-weight: bolder;
                "
              >
                {{ vnew }}
              </td>
              <td
                style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                  font-weight: bolder;
                "
              >
                {{ vrenew }}
              </td>
              <td
                style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                  font-weight: bolder;
                "
              >
                {{ vtotal }}
              </td>
              <td
                style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                  font-weight: bolder;
                "
              >
                {{ tnew }}
              </td>
              <td
                style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                  font-weight: bolder;
                "
              >
                {{ trenew }}
              </td>
              <td
                style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                  font-weight: bolder;
                "
              >
                {{ ttotal }}
              </td>
              <td
                style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                  font-weight: bolder;
                "
              >
                {{ pnew }}
              </td>
              <td
                style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                  font-weight: bolder;
                "
              >
                {{ prenew }}
              </td>
              <td
                style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                  font-weight: bolder;
                "
              >
                {{ ptotal }}
              </td>
              <td
                style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                  font-weight: bolder;
                "
              >
                {{ snew }}
              </td>
              <td
                style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                  font-weight: bolder;
                "
              >
                {{ srenew }}
              </td>
              <td
                style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                  font-weight: bolder;
                "
              >
                {{ stotal }}
              </td>
              <td
                style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                  font-weight: bolder;
                "
              >
                {{ anamat }}
              </td>
              <td
                style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                  font-weight: bolder;
                "
              >
                {{ punchthev }}
              </td>
              <td
                style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                  font-weight: bolder;
                "
              >
                {{ other }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <button
      [useExistingCss]="true"
      class="noPrint"
      style="margin-top: 2%"
      mat-raised-button
      color="primary"
      printSectionId="print-section"
      ngxPrint
    >
      print
    </button>
    <button
      class="noPrint"
      style="margin-top: 2%; margin-left: 2%"
      mat-raised-button
      color="info"
      mat-dialog-close
    >
      Close
    </button>
  </div>
</body>
