import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-subscriberdetailsbrief',
  templateUrl: './subscriberdetailsbrief.component.html',
  styleUrls: ['./subscriberdetailsbrief.component.scss']
})
export class SubscriberdetailsbriefComponent {
  subscriberdetailsbrief: any;
  constructor(
    public dialogref: MatDialogRef<SubscriberdetailsbriefComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    debugger
    this.subscriberdetailsbrief = this.data['subscriberbrieflist'];
    console.log(this.subscriberdetailsbrief)
  }
}
