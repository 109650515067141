import { DigisubservicesService } from './../../services/digisubservices.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';

@Component({
  selector: 'app-edit-book-delivery',
  templateUrl: './edit-book-delivery.component.html',
  styleUrls: ['./edit-book-delivery.component.scss']
})
export class EditBookDeliveryComponent implements OnInit {

  BookForm: FormGroup;
  viewmode = false
  constructor(private fb: FormBuilder, public service: DigisubservicesService,
    private dialogRef: MatDialogRef<EditBookDeliveryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    console.log(this.data);
    this.BookForm = fb.group({
      address: this.data.book['address'],
      mobile: this.data.book['mobile'],
      bid: this.data.book['bid'],
      book_amount: this.data.book['book_amount'],
      book_name: this.data.book['book_name'],
      book_qty: this.data.book['book_qty'],
      chequeno: this.data.book['chequeno'],
      city: this.data.book['city'],
      country: this.data.book['country'],
      email: this.data.book['email'],
      id: this.data.book['id'],
      isApproved: this.data.book['isApproved'],
      isdelivered: this.data.book['isdelivered'],
      name: this.data.book['name'],
      pincode: this.data.book['pincode'],
      state: this.data.book['state'],
      tracking_id: this.data.book['tracking_id'],
      transport_method: this.data.book['transport_method'],
      paymentmode: this.data.book['paymentmode'],
      transporter_name: this.data.book['transporter_name']
    });

  }

  ngOnInit(): void {
    console.log(this.data)
    this.viewmode = this.data.view
    this.trackingId = this.data.book['tracking_id'];
    this.trackingMethod = this.data.book['transport_method'];

    if (this.trackingMethod == "byhand" || this.trackingMethod == "byhandpost") {
      this.displayByhand = true;
    } else {
      this.displayByhand = false
    }
  }


  trackingId = ""
  trackingMethod = "";
  enablebutton = true
  getTrackingId(_event) {
    console.log(_event.target.value)
    this.trackingId = _event.target.value

    if (this.trackingId != "" && this.trackingMethod != "") {
      this.enablebutton = false;
    } else {
      this.enablebutton = true;
    }

  }

  displayByhand = false
  getTrackingMethod(_event) {
    if (!this.viewmode) {
      console.log(_event.target.value)

      this.trackingMethod = _event.target.value

      if (this.trackingId != "" && this.trackingMethod != "") {
        this.enablebutton = false;
      } else {
        this.enablebutton = true;
      }

      if (this.trackingMethod == "byhand" || this.trackingMethod == "byhandpost") {
        this.displayByhand = true;
      } else {
        this.displayByhand = false
      }

    }

  }


  save() {

  }



  update() {
    console.log("inupdate method")
    this.service.updateBookDelivery(this.trackingId, this.trackingMethod, this.data.book['id'] , this.BookForm.get('transporter_name').value).subscribe(data => {
      console.log(data)
      window.alert("Records has been updated !")
      this.dialogRef.close()
    })
  }

}
