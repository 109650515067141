import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DigisubservicesService } from 'src/app/services/digisubservices.service';

@Component({
  selector: 'app-update-advt-issue-per-price',
  templateUrl: './update-advt-issue-per-price.component.html',
  styleUrls: ['./update-advt-issue-per-price.component.scss'],
})
export class UpdateAdvtIssuePerPriceComponent implements OnInit {
  advtInvoiceData;
  advtInvoiceList;
  crdtvAmt = 0;
  selectedAmt = 0;
  balanceAmt = 0;

  constructor(
    private dialogRef: MatDialogRef<UpdateAdvtIssuePerPriceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private service: DigisubservicesService
  ) {}

  ngOnInit(): void {
    
    this.advtInvoiceData = this.data['advtInvoice'];
    this.advtInvoiceList = this.advtInvoiceData.advtInvoiceList;
    this.crdtvAmt = isNaN(this.advtInvoiceData['advt_cv_amt']) ? 0 : this.advtInvoiceData['advt_cv_amt'];
    this.selectedAmt = isNaN(this.advtInvoiceData['net_amt']) ? 0 : this.advtInvoiceData['net_amt'];
    this.balanceAmt = isNaN(this.advtInvoiceData['pending_amount']) ? 0 : this.advtInvoiceData['pending_amount'];
    console.log(this.data);
   
  }
  

  // total_val = 0;
  // calcamount(event, advtInvoice) {
  //   console.log(event.target.value, advtInvoice);
  //   let val = event.target.value;
  //   let total_val = 0;
  //   let remaining_amt = parseInt(advtInvoice['amount']) - val;
  //   let updatedamount = 0;
  //   for (
  //     let index = 0;
  //     index < Object.keys(this.advtInvoiceList).length;
  //     index++
  //   ) {
  //     if (this.advtInvoiceList[index]['id'] == advtInvoice['id']) {
  //       updatedamount = parseInt(this.advtInvoiceList[index]['rcvd_amt']) - val;
  //       this.advtInvoiceList[index]['rcvd_amt'] = val;
  //       this.advtInvoiceList[index]['remaining_amount'] = remaining_amt;
  //       this.advtInvoiceData.remaining_amt = remaining_amt;
  //     }
  //     total_val = parseInt(this.advtInvoiceList[index]['rcvd_amt']);
  //     console.log(updatedamount, total_val);
  //     if (Math.sign(updatedamount)) {
  //       this.selectedAmt = this.selectedAmt - updatedamount;
  //     } else {
  //       this.selectedAmt = this.selectedAmt - updatedamount;
  //     }
  //     this.balanceAmt = this.crdtvAmt - this.selectedAmt;
  //   }
  // }

  update() {
    this.dialogRef.close({
      advtInvoiceData: this.advtInvoiceData,
      selecetdAmt: this.selectedAmt,
      balanceAmt: this.balanceAmt,
    });
  }

  value = 0;
  newvalue=0;
  id=0;
  val:any=0;
  advt_cv_amt=0;
  remaining_amt=0;
  calcamount(event, book) {
    
    
     this.val = event.target.value;
    this.id = book.id;
    this.advt_cv_amt = book.advt_cv_amt;
   
    // this.selectedAmt = 0;
    // console.log({ book });
     this.remaining_amt =
      parseFloat(book['advt_cv_amt']) - (parseFloat(this.val));
    console.log(this.remaining_amt);

    this.balanceAmt = this.remaining_amt;
    
     let remain = parseFloat(book.net_amt) - (parseFloat(this.val))
     console.log({ book } , "val");

     book.remaining_amount = remain

     
    

  }

  update1(){
    const data ={
      "id" : this.id,
      "credit_v_amt" : this.advt_cv_amt,
      "seamt" : this.val,
      "bal_amt" :this.remaining_amt
     }

     
  this.service.updatepaymentreceipt(data).subscribe((details) => {
    // console.log(details);
  });
  this.dialogRef.close();
  this.ngOnInit()
  }

}
