import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DigisubservicesService } from 'src/app/services/digisubservices.service';

@Component({
  selector: 'app-update-state-master',
  templateUrl: './update-state-master.component.html',
  styleUrls: ['./update-state-master.component.scss'],
})
export class UpdateStateMasterComponent implements OnInit {
  stateData;
  stateForm: FormGroup;

  constructor(
    private dialogref: MatDialogRef<UpdateStateMasterComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public fb: FormBuilder,
    public service: DigisubservicesService
  ) {
    this.stateForm = fb.group({
      stateid: [''],
      statename: [''],
    });
  }

  ngOnInit(): void {
    this.stateData = this.data['state'];
    this.stateForm.get('stateid').setValue(this.stateData['state_id']);
    this.stateForm.get('statename').setValue(this.stateData['state_name']);
  }

  updateState() {
    if (this.stateForm.get('stateid').value > 0) {
      this.service
        .updateStateName(
          parseInt(this.stateForm.get('stateid').value),
          this.stateForm.get('statename').value
        )
        .subscribe((res) => {
          alert('State Name Updated Successfully...');
          this.dialogref.close({ res: 'close' });
        });
    }
  }
}
