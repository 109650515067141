<div>
    <div style="margin-left: 70%; margin-bottom: 2%">
        <button type="button" mat-raised-button matTooltip="Download Execelsheet" class="btn btn-danger"
            (click)="exportexcel('Print_Order')">Export</button>
        <button [useExistingCss]="true" class="noPrint" style="margin-right: 2%; margin-left: 2%" mat-raised-button
            color="primary" printTitle="Label" printSectionId="print-section" ngxPrint>
            print
        </button>
        <button mat-dialog-close class="btn noPrint">Close</button>
    </div>
    <div class="container" id="print-section">
        <div style="padding-left: 0%; padding-right: 0%; font-weight: 600;">
            <table class="table table-striped text-center">
                <tr style="width: 100%;">
                    <td colspan="7" style="border-width: 2px 2px 0 2px; width: 70%;"><span class="fontBold">सा. विवेक -
                            अंक
                            छपाई आदेश</span></td>
                    <td colspan="3" style="border-width: 2px 2px 0 0; width: 30%;">दिनांक : {{ printData['order_date'] |
                        date:'dd-MM-yyyy'
                        }}</td>
                </tr>
                <tr>
                    <td colspan="5" style="border-width: 2px 2px 0 2px; width: 50%;">आदेश क्र : {{ printData['order_id']
                        }}</td>
                    <td colspan="2" style="border-width: 2px 2px 0 0;width: 20%;">पृष्ठ क्रं.</td>
                    <td style="border-width: 2px 2px 0 0; width: 10%;">पृष्ठ संख्या</td>
                    <td style="border-width: 2px 2px 0 0; width: 10%;">प्रकार</td>
                    <td style="border-width: 2px 2px 0 0; width: 10%;">पाने देणार</td>
                </tr>
                <tr *ngFor="let item of prusthaData; let i=index">
                    <td *ngIf="i == 0" colspan="5" style="border-width: 2px 2px 0 2px; width: 50%;">अंक दिनांक : {{
                        printData['issue_date_from'] |
                        date:"dd MMMM yy" }} ते {{ printData['issue_date_to'] | date:"dd MMMM yy" }}</td>
                    <td *ngIf="i == 1" colspan="5" style="border-width: 0 2px 0 2px; width: 50%;">च्या अंकाकरिता</td>
                    <td *ngIf="i == 2" colspan="5" style="border-width: 0 2px 0 2px; width: 50%;"></td>
                    <td *ngIf="i == 3" colspan="5" style="border-width: 2px 2px 0 2px; width: 50%;"></td>
                    <td *ngIf="i == 4" colspan="5" style="border-width: 0 2px 0 2px; width: 50%;"></td>
                    <td colspan="2" style="border-width: 2px 2px 0 0;width: 20%;">
                        {{ item['prustha_kr'] }}
                    </td>
                    <td style="border-width: 2px 2px 0 0; width: 10%;">
                        {{ item['prustha_sankhya'] }}
                    </td>
                    <td style="border-width: 2px 2px 0 0; width: 10%;">
                        {{ item['prakar'] }}
                    </td>
                    <td style="border-width: 2px 2px 0 0; width: 10%;">
                        {{ item['pane_denar'] }}
                    </td>
                </tr>
                <tr>
                    <td colspan="5" style="border-width: 0 2px 0 2px; width: 50%;">एकूण प्रती : {{
                        printData['total_prati'] }}</td>
                    <td colspan="2" style="border-width: 2px 2px 0 0;width: 20%;"></td>
                    <td style="border-width: 2px 2px 0 0; width: 10%;"></td>
                    <td style="border-width: 2px 2px 0 0; width: 10%;"></td>
                    <td style="border-width: 2px 2px 0 0; width: 10%;"></td>
                </tr>
                <tr>
                    <td colspan="5" style="border-width: 0 2px 0 2px; width: 50%;"></td>
                    <td colspan="2" style="border-width: 2px 2px 0 0;width: 20%;">एकूण पृष्ठ :</td>
                    <td style="border-width: 2px 2px 0 0; width: 10%;">{{ printData['total_prustha'] }}</td>
                    <td style="border-width: 2px 2px 0 0; width: 10%;"></td>
                    <td style="border-width: 2px 2px 0 0; width: 10%;"></td>
                </tr>
                <tr>
                    <td colspan="1" style="border-width: 2px 2px 0 2px; width: 10%;">क्रमांक</td>
                    <td colspan="2" style="border-width: 2px 2px 0 0; width: 20%;">विगत</td>
                    <td colspan="2" style="border-width: 2px 2px 0 0; width: 20%;">प्रती</td>
                    <td colspan="3" style="border-width: 2px 2px 0 0;width: 30%;">शेरा</td>
                    <td colspan="2" style="border-width: 2px 2px 0 0; width: 20%;"></td>
                </tr>
                <tr *ngFor="let item of typeData;let i=index">
                    <td colspan="1" style="border-width: 2px 2px 0 2px; width: 10%;">{{ i+1 }}</td>
                    <td colspan="2" style="border-width: 2px 2px 0 0; width: 20%;">{{ item['name'] }}</td>
                    <td colspan="2" style="border-width: 2px 2px 0 0; width: 20%;">{{
                        item['prati'] }}
                    </td>
                    <td colspan="3" style="border-width: 2px 2px 0 0;width: 30%;">{{
                        item['shera'] }}</td>
                    <td colspan="2" style="border-width: 2px 2px 0 0; width: 20%;"></td>
                </tr>
                <tr>
                    <td colspan="1" style="border-width: 2px 2px 0 2px; width: 10%;"></td>
                    <td colspan="2" style="border-width: 2px 2px 0 0; width: 20%;">एकूण</td>
                    <td colspan="2" style="border-width: 2px 2px 0 0; width: 20%;">{{ printData['total_prati'] }}</td>
                    <td colspan="3" style="border-width: 2px 2px 0 0;width: 30%;"></td>
                    <td colspan="2" style="border-width: 2px 2px 0 0; width: 20%;"></td>
                </tr>
                <tr>
                    <td colspan="12"
                        style="border-width: 2px 2px 0 2px; width: 100%;padding: 1%; text-align: end;padding-top: 5%;">
                        कार्यालय प्रमुख / व्यवस्थापक
                    </td>
                </tr>
                <tr>
                    <td colspan="12"
                        style="border-width: 2px 2px 0 2px; padding: 0.5%; text-align: center; width: 100%;">
                        पेपर तपशिल</td>
                </tr>
                <tr>
                    <td colspan="1" style="border-width: 2px 2px 0 2px; width: 10%;">पेपर प्रकार</td>
                    <td colspan="2" style="border-width: 2px 2px 0 0; width: 20%;">मागील शिल्लक पेपर रीम</td>
                    <td colspan="2" style="border-width: 2px 2px 0 0; width: 20%;">नवीन आला</td>
                    <td colspan="2" style="border-width: 2px 2px 0 0;width: 20%;">एकूण रीम</td>
                    <td colspan="2" style="border-width: 2px 2px 0 0; width: 20%;">या अंकाला लागणारा पेपर</td>
                    <td style="border-width: 2px 2px 0 0; width: 10%;">शिल्लक पेपर रीम</td>
                </tr>
                <tr *ngFor="let data of testdata">
                    <td colspan="1" style="border-width: 2px 2px 0 2px; width: 10%;">{{ data['name'] }}</td>
                    <td colspan="2" style="border-width: 2px 2px 0 0; width: 20%;"></td>
                    <td colspan="2" style="border-width: 2px 2px 0 0; width: 20%;"></td>
                    <td colspan="2" style="border-width: 2px 2px 0 0;width: 20%;"></td>
                    <td colspan="2" style="border-width: 2px 2px 0 0; width: 20%;"></td>
                    <td style="border-width: 2px 2px 0 0; width: 10%;"></td>
                </tr>
                <tr>
                    <td colspan="12" style="border-width: 2px 0 0 0; width: 100%;"></td>
                </tr>
            </table>
        </div>
    </div>
</div>