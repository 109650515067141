import { formatDate } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-districtwise-subscription-report',
  templateUrl: './districtwise-subscription-report.component.html',
  styleUrls: ['./districtwise-subscription-report.component.scss'],
})
export class DistrictwiseSubscriptionReportComponent implements OnInit {
  startDate = '';
  endDate = '';
  subData;
  districtwiseData = [];
  districtwiseDataTotal = {};
  districtwiseDataTotalTest = {};
  subTypesName = [
    { name: 'वार्षिक', isVisible: false },
    { name: 'त्रैवार्षिक', isVisible: false },
    { name: 'पंचवार्षिक', isVisible: false },
    { name: 'मुदत ठेव (पंचवार्षिक वर्गणीदार)', isVisible: false },
    { name: 'रामसेवक ग्रंथयात्री', isVisible: false },
    { name: 'लोकनेता गंथयात्री वार्षिक', isVisible: false },
    { name: 'लोकमान्य ग्रंथयात्री वार्षिक वर्गणी', isVisible: false },
    { name: 'हिंदुत्व ग्रंथयात्री', isVisible: false },
    { name: 'हिंदुत्व 1', isVisible: false },
    { name: 'हिंदुत्व 2', isVisible: false },
    { name: 'आजीव', isVisible: false },
    { name: 'अनामत', isVisible: false },
  ];
  isLoading = false;
  test = [
    'हितचिंतक सप्रेम भेट',
    'सप्रेम भेट - संपादकीय विभाग',
    'जाहिरात स. भेट',
    'संघ परिवार सप्रेम भेट',
    'वर्गणी सप्रेम भेट',
  ];
  totalth = 0;

  constructor(
    private dialogRef: MatDialogRef<DistrictwiseSubscriptionReportComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.subData = this.data['data'].filter((a) => {
      return a.delivery_mode == 'टपाल';
    });
    let data = this.uniqueData(this.subData, (a) => a.Subscription_Type);

    for (let i = 0; i < Object.keys(data).length; i++) {
      let flag = false;
      for (let j = 0; j < Object.keys(this.subTypesName).length; j++) {
        if (data[i]['Subscription_Type'] === this.subTypesName[j]['name']) {
          this.subTypesName[j]['isVisible'] = true;
          flag = true;
          break;
        }
      }
      if (!flag) {
        if (
          data[i]['Subscription_Type'] !== 'हितचिंतक सप्रेम भेट' ||
          data[i]['Subscription_Type'] !== 'सप्रेम भेट - संपादकीय विभाग' ||
          data[i]['Subscription_Type'] !== 'जाहिरात स. भेट' ||
          data[i]['Subscription_Type'] !== 'संघ परिवार सप्रेम भेट' ||
          data[i]['Subscription_Type'] !== 'वर्गणी सप्रेम भेट'
        ) {
          this.subTypesName.push({
            name: data[i]['Subscription_Type'],
            isVisible: true,
          });
        }
      }
    }

    this.subTypesName.map((a: any) => {
      let name = a['name'].replaceAll(' ', '_');
      this.districtwiseDataTotalTest[name] = 0;
    });

    this.districtwiseDataTotal = {
      ...this.districtwiseDataTotalTest,
      सप्रेम_भेट_1: 0,
      सप्रेम_भेट_2: 0,
      एकूणसप्रेम_भेट: 0,
      एकूण: 0,
      सप्रेम_एकूण: 0,
    };
    this.totalth = this.subTypesName.length + 8;

    this.endDate = this.data['issuedate'];
    let weekfirstday =
      new Date(this.data['issuedate']).getDate() -
      new Date(this.data['issuedate']).getDay();
    this.startDate = formatDate(
      new Date(this.data['issuedate']).setDate(weekfirstday - 7),
      'yyyy-MM-dd',
      'en-IN',
      'GMT+0530'
    );

    this.setSubData();
  }

  setSubData() {
    let data = this.uniqueData(this.subData, (a) => a.district);
    data.sort((a: any, b: any) => {
      return b.district.localeCompare(a.district);
    });

    for (let i = 0; i < data.length; i++) {
      let districtData = this.subData.filter((a) => {
        return a.district == data[i]['district'];
      });
      let subType = this.uniqueData(districtData, (a) => a.Subscription_Type);

      for (let j = 0; j < subType.length; j++) {
        let subTypeData = districtData.filter((a) => {
          return a.Subscription_Type == subType[j]['Subscription_Type'];
        });

        let totalSubType = 0;
        subTypeData.map((a) => {
          if (a.s_bhet != null) {
            totalSubType += parseInt(a.s_bhet);
          }
        });

        this.setSubTypeData(
          data[i]['district'],
          this.getKey(subType[j]['Subscription_Type']),
          subTypeData.length,
          totalSubType,
          j == subType.length - 1 ? true : false
        );
      }
    }
    this.calculateTotal();
  }

  setSubTypeData(
    districtName: string,
    subType: string,
    total: number,
    sbhet: number,
    last: boolean
  ) {
    let foundDistrict = this.districtwiseData.filter((a) => {
      return a.districtName == districtName;
    });

    if (foundDistrict.length == 0) {
      this.subTypesName.map((a: any) => {
        let name = a['name'].replaceAll(' ', '_');
        this.districtwiseDataTotalTest[name] = 0;
      });

      this.districtwiseData.push({
        districtName,
        ...this.districtwiseDataTotalTest,
        सप्रेम_भेट_1: 0,
        सप्रेम_भेट_2: 0,
        एकूणसप्रेम_भेट: 0,
        एकूण: 0,
        सप्रेम_एकूण: 0,
      });
    }

    if (!this.test.includes(subType)) {
      this.setDistrictData(districtName, subType, 'एकूण', total);
    } else {
      this.districtwiseData.map((a) => {
        if (a.districtName == districtName) {
          a['सप्रेम_भेट_1'] += total;
          a['एकूण'] += total;
          return;
        }
      });
    }

    this.districtwiseData.map((a) => {
      if (a.districtName == districtName) {
        a['सप्रेम_भेट_1'] += sbhet;
        a['एकूणसप्रेम_भेट'] += sbhet;
        return;
      }
    });

    if (last) {
      this.districtwiseData.map((a) => {
        if (a.districtName == districtName) {
          a['सप्रेम_एकूण'] =
            parseInt(a['एकूण']) + parseInt(a['एकूणसप्रेम_भेट']);
          return;
        }
      });
    }
  }

  getKey(name) {
    return name.replaceAll(' ', '_');
  }

  setDistrictData(
    districtName: string,
    subtype: string,
    aikun: string,
    total: number
  ) {
    this.districtwiseData.map((a) => {
      if (a.districtName == districtName) {
        a[subtype] = total;
        a[aikun] += total;
        return;
      }
    });
  }

  calculateTotal() {
    this.districtwiseData.map((a) => {
      this.subTypesName.map((b) => {
        let key = this.getKey(b.name);
        this.districtwiseDataTotal[key] += a[key];
      });
      this.districtwiseDataTotal['सप्रेम_भेट_1'] += a['सप्रेम_भेट_1'];
      this.districtwiseDataTotal['सप्रेम_भेट_2'] += a['सप्रेम_भेट_2'];
      this.districtwiseDataTotal['एकूणसप्रेम_भेट'] += a['एकूणसप्रेम_भेट'];
      this.districtwiseDataTotal['एकूण'] += a['एकूण'];
      this.districtwiseDataTotal['सप्रेम_एकूण'] += a['सप्रेम_एकूण'];
    });
    this.isLoading = false;
  }

  uniqueData(data, key) {
    return [...new Map(data.map((a) => [key(a), a])).values()];
  }

  exportExcel(fileName) {
    /* table id is passed over here */
    let element = document.getElementById('print-section');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, fileName + '.xlsx');
  }
}
