<div class="dialog">

  <div class="form-row">
    <mat-form-field appearance="outline" style="width: 22%; margin-left: 5%">
      <mat-label>Credit Voucher Amount</mat-label>
      <input matInput type="number" [(ngModel)]="crdtvAmt" readonly />
    </mat-form-field>
    <mat-form-field appearance="outline" style="width: 22%; margin-left: 2%">
      <mat-label>Select Entry Amount</mat-label>
      <input matInput type="number" [(ngModel)]="selectedAmt" readonly />
    </mat-form-field>
    <mat-form-field appearance="outline" style="width: 22%; margin-left: 2%">
      <mat-label>Balance Amount</mat-label>
      <input matInput type="number" [(ngModel)]="balanceAmt" readonly />
    </mat-form-field>
    <mat-form-field appearance="outline" style="width: 22%; margin-left: 2%">
      <mat-label>Book Name</mat-label>
      <input matInput type="string" [(ngModel)]="bname"  readonly />
    </mat-form-field>
  </div>

  <table class="table table-bordered border-primary">
    <thead>
      <tr>
        <th>Sr. No.</th>
        <th>Book Name</th>
        <th>Net Amount</th>
        <th>Book Copies</th>
        <th>Total</th>
        <th>Shipping Charges</th>
        <th>Total Value</th>
        <th>Already Received Amount</th>
        <th>Received Amount</th>
        <th>Remaining Amount</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let book of bookdata; let i = index">
        <td>{{ i + 1 }}</td>
        <td>{{ book["book_name"] }}</td>
        <td>{{ book["netamt"] }}</td>
        <td>{{ book["copies"] }}</td>
        <td>{{ book["total"] }}</td>
        <td>{{ book["shipping_charges"] }}</td>
        <td>{{ book["total_amount"] }}</td>
        <td>{{ book["rcvd_amt"] }}</td>
        <td>
            <mat-form-field class="example-full-width" appearance="outline" style="width: 50%">
              <input matInput 
              (change)="calcamount($event, book)"
              
               type="number"
                style="height: 35px !important;" 
                 [disabled]="book.isDisabled"
                
                />
            </mat-form-field>
        
          <!-- Previous Value :{{ book["rcvd_amt"] }} -->
        </td>
        <td>{{ book["remaining_amt"] }}</td>
      </tr>
      <tr>
        <td colspan="6"></td>
        <td></td>
        <td>{{ total_val }}</td>
        <td></td>
        <td>{{ total_remaining_amt }}</td>
      </tr>
    </tbody>
  </table>
</div>

<div class="dialog">
  <button
    class="btn btn-primary"
    style="margin-top: 2%; width: 10%"
    (click)="save()"
    mat-button
  >
    Save
  </button>
  <!-- <button
    class="btn btn-danger noPrint"
    style="margin-top: 5%; margin-left: 2%"
    mat-button
    mat-dialog-close
  >
    Close
  </button> -->
</div>
