<!-- <button style="width: 10%; margin-left: 80%;"  class="noPrint btn-primary"  printSectionId="print-section" (click)="print()">Print</button> -->
<button class="noPrint btn-primary" style="width: 10%; margin-left: 80%;" mat-raised-button color="primary"
printSectionId="print-section" ngxPrint [useExistingCss]="true">
print
</button>
<div id="print-section">
  
<div  *ngFor="let data of data; let i = index" [ngClass]="{ 'print-margin-top': i % 2 === 0 && i !== 0}">
    <div class="mb-5 mt-55" [ngClass]="{'mb-5': i % 2 <2}">
        <div *ngIf="i > 2 &&  i % 2 ===0;  " class="page-break "></div>
        <div class="container" style="border: 3px solid black">
            <div class="row">
                <div class="col-3 image">
                    <img style="margin-top: 8%" width="90%" src="../../../assets/img/brand/red.png" />
                </div>
                <div class="col-7" style="padding-top: 30px; text-align: center; padding-bottom: 10px">
                    <span style="font-size: 30px; font-weight: bold">हिंदुस्थान प्रकाशन संस्था</span>
                    <span style="font-size: 16px; margin-left: 05px">(Reg.No.-BOM-3/1961[GBBSD])</span>
                    <hr />
                    <div class="row" style="text-align: left">
                        <div class="col-6" style="padding-top: 10px">
                            <span style="font-size: 14px"><span style="font-weight: bold; font-size: 16px">प्रशासकीय
                                    कार्यालय : <br></span>
                                    116, 117, 118, 119, पहिला मजला, <br> शिल्पीन सेंटर, 40, ग. द. आंबेकर मार्ग, <br> वडाळा , मुंबई - 400031<br /></span>
                            <span style="font-size: 16px"><span style="font-weight: bold; font-size: 16px">दूरध्वनी :</span>
                                9967570531
                                <!-- <span style="font-weight: bold; font-size: 16px">फॅक्स:</span>
                                27810237 -->
                            </span>
                        </div>
                        <div class="col-6" style="
                  border-style: solid;
                  border-width: 0px 0px 0px 3px;
                  padding-top: 10px;
                ">
                            <span style="font-size: 14px"><span style="font-weight: bold; font-size: 16px">मुख्य कार्यालय : <br>
                                </span>
                                5/12, कामत औद्योगिक वसाहत, <br>
                                396, स्वा. वीर सावरकर मार्ग, <br> प्रभादेवी, मुंबई 400025.<br /></span>
                            <span style="font-size: 16px"><span style="font-weight: bold; font-size: 16px">दूरध्वनी :
                                </span>
                                24221440
                                <!-- <span style="font-weight: bold; font-size: 16px">फॅक्स : </span>
                                24363756 -->
                            </span>
                        </div>
                    </div>
                </div>
                <div class="col-2" style="
              border-style: solid;
              border-width: 0px 0px 0px 3px;
              /* padding-top: 40px; */
              text-align: center;
              display: flex;
              align-items: center;
            ">
                    <span style="font-size: 24px">जाहिरात जमा <br> <br> पावती</span>
                </div>
            </div>
            <div class="row" style="
            border-style: solid;
            border-width: 3px 0px 0px 0px;
            text-align: center;
          ">
                <div class="col-6" style="padding-top: 20px; text-align: left">
                    <span style="font-weight: bold; font-size: 16px">Advertieser Name : &nbsp;
                    </span><span style="font-size: 16px; font-weight: bold;">{{data.advt_name}}</span><br />
                    <span style="font-weight: bold; font-size: 16px">Address :{{data.address}}, {{data.taluka}} , {{data.district}}, {{data.pincode}} &nbsp;</span><span style="font-size: 14px"></span><br />
                    <span style="font-weight: bold; font-size: 16px">Mobile No :{{data.mobile}}&nbsp;</span><span
                        style="font-size: 14px"></span>
                </div>
                <div class="col-6" style="border-style: solid; border-width: 0px 0px 0px 3px">
                    <div class="row" style="
                border-style: solid;
                border-width: 0px 0px 3px 0px;
                text-align: center;
                padding-top: 20px;
                padding-bottom: 10px;
              ">
                        <div class="col-6" style="text-align: left">
                            <span style="font-weight: bold; font-size: 16px">पावती क्र.: </span><span style="font-size: 16px">{{data.rcpt_no}}</span>
                        </div>
                        <div class="col-6" style="text-align: left">
                            <span style="font-weight: bold; font-size: 16px">दिनांक :&nbsp;</span>{{data.rcpt_date}}<span
                                style="font-size: 14px">
                               
                                <!-- {{this.date | date : "dd-MM-yyy"}} -->
                            </span>
                        </div>
                    </div>
                    <div class="row" style="padding-top: 20px">
                        <div class="col-12" style="text-align: left">
                            <span style="font-weight: bold; font-size: 16px">यांच्याकडून रुपये&nbsp;</span>
                            <span style="font-size: 16px">{{data.totalinWords}} /-</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12" style="text-align: right">
                            <span style="font-weight: bold; font-size: 16px">मात्र साभार पोहोचले.</span>
                        </div>
                    </div>
                    <div class="row" style="padding-top: 05px">
                        <div class="col-6" style="text-align: left">
                            <span style="font-weight: bold; font-size: 16px">धनादेश क्र:&nbsp;</span>
                            <span style="font-size: 16px">{{data.cheque_no}}</span>
                        </div>
                        <div class="col-6" style="text-align: left">
                            <span style="font-weight: bold; font-size: 16px">दिनांक :&nbsp;</span>
                            <span style="font-size: 16px">
                                {{data.cheque_date}}
                            </span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12" style="text-align: left; padding-top: 05px">
                            <span style="font-weight: bold; font-size: 16px">बँक :&nbsp;</span>
                            <span style="font-size: 16px">
                                {{data.bank}}
                            </span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12" style="text-align: left; padding-top: 05px; padding-bottom: 10px">
                            <span style="font-weight: bold; font-size: 16px">जमा रक्कम रु.&nbsp;</span><span
                                style="font-size: 16px">{{data.rcv_amt}} </span>
                        </div>
                    </div>
                    <!-- <div class="row">
                        <div class="col-12" style="text-align: left; padding-top: 05px; padding-bottom: 10px">
                            <span style="font-weight: bold; font-size: 16px">अक्षरी रक्कम.&nbsp;</span><span
                                style="font-size: 14px">{{data.totalinWords}} </span>
                        </div>
                    </div> -->
                </div>
            </div>
            <div class="row" style="border-style: solid;border-width: 3px 0px 0px 0px;text-align: center;padding-top: 20px;">
                <div class="col-12" style="text-align: left">
                    <span style="font-weight: bold; font-size: 20px">तपशिल नंबर : </span>
                    <!-- <span style="font-size: 14px"> 
                        &nbsp;{{data.advt_invoice_no}}</span> -->
                        <span style="font-size: 16px" > {{data.advt_invoice_no}}
                            &nbsp;</span>
                </div>
            </div>
            <div class="row" style="margin-top: 80px; padding-bottom: 10px">
                <div class="col-6">
                    <span style="font-weight: bold; font-size: 16px">(धनादेश वटल्यावरच पावती अधिकृत होईल)</span>
                </div>
                <div class="col-6" style="text-align: right; font-weight: bold; font-size: 16px">
                    <span>व्यवस्थापक / लेखापाल</span>
                </div>
            </div>
        </div>
        <!-- <button class="noPrint" style="margin-top: 2%" (click)="save()" mat-raised-button color="primary">
            Save
        </button>
        <button class="noPrint" style="margin-top: 2%; margin-left: 10px" (click)="print()" mat-raised-button
            color="primary">
            Print
        </button>
        <button class="noPrint" style="margin-top: 2%; margin-left: 10px" mat-button mat-dialog-close>
            Close
        </button> -->
    </div>
</div>
</div>

