<ng-container *ngIf="!isLoading">
    <div>
        <!-- [useExistingCss]="true" -->
        <div style="margin-left: 70%; margin-top: -4%; margin-bottom: 2%">
            <button
                [printStyle]="{table : {'border-collapse':'collapse'}, td : {'border': 'solid 1px', 'font-size':'14px', 'font-weight':'600', 'text-align':'center'}, th : {'border': 'solid 1px', 'font-size':'16px', 'font-weight':'600', 'text-align':'center'}, div : {'font-size':'14px', 'font-weight':'600'}}"
                class="noPrint" style="margin-right: 2%; margin-left: 2%" mat-raised-button color="primary"
                printSectionId="print-section" ngxPrint>
                print
            </button>
            <button class="noPrint" style="margin-top: 2% 2% 0 2%;"
                (click)="exportExcel('Districtwise_Subscription_Report')" mat-raised-button color="primary">
                Export
            </button>
            <button mat-dialog-close class="btn noPrint">
                Close
            </button>
        </div>
    </div>
    <div>
        <div class="container" id="print-section">
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th *ngIf="totalth == 15" colspan="14">दि {{ endDate | date:"dd/MM/yyyy" }} च्या अंकाकरिता</th>
                        <th *ngIf="totalth == 16" colspan="15">दि {{ endDate | date:"dd/MM/yyyy" }} च्या अंकाकरिता</th>
                        <th *ngIf="totalth == 17" colspan="16">दि {{ endDate | date:"dd/MM/yyyy" }} च्या अंकाकरिता</th>
                        <th *ngIf="totalth == 18" colspan="17">दि {{ endDate | date:"dd/MM/yyyy" }} च्या अंकाकरिता</th>
                        <th *ngIf="totalth == 19" colspan="18">दि {{ endDate | date:"dd/MM/yyyy" }} च्या अंकाकरिता</th>
                        <th *ngIf="totalth == 20" colspan="19">दि {{ endDate | date:"dd/MM/yyyy" }} च्या अंकाकरिता</th>
                        <th *ngIf="totalth == 21" colspan="20">दि {{ endDate | date:"dd/MM/yyyy" }} च्या अंकाकरिता</th>
                        <th *ngIf="totalth == 22" colspan="21">दि {{ endDate | date:"dd/MM/yyyy" }} च्या अंकाकरिता</th>
                        <th *ngIf="totalth == 23" colspan="22">दि {{ endDate | date:"dd/MM/yyyy" }} च्या अंकाकरिता</th>
                        <th *ngIf="totalth == 24" colspan="23">दि {{ endDate | date:"dd/MM/yyyy" }} च्या अंकाकरिता</th>
                        <th *ngIf="totalth == 25" colspan="24">दि {{ endDate | date:"dd/MM/yyyy" }} च्या अंकाकरिता</th>
                        <th *ngIf="totalth == 26" colspan="25">दि {{ endDate | date:"dd/MM/yyyy" }} च्या अंकाकरिता</th>
                        <th *ngIf="totalth == 27" colspan="26">दि {{ endDate | date:"dd/MM/yyyy" }} च्या अंकाकरिता</th>
                        <th *ngIf="totalth == 28" colspan="27">दि {{ endDate | date:"dd/MM/yyyy" }} च्या अंकाकरिता</th>
                        <th *ngIf="totalth == 29" colspan="28">दि {{ endDate | date:"dd/MM/yyyy" }} च्या अंकाकरिता</th>
                        <th *ngIf="totalth == 30" colspan="29">दि {{ endDate | date:"dd/MM/yyyy" }} च्या अंकाकरिता</th>
                        <th *ngIf="totalth == 31" colspan="30">दि {{ endDate | date:"dd/MM/yyyy" }} च्या अंकाकरिता</th>
                    </tr>
                    <tr>
                        <th>अनु क्र.</th>
                        <th>जिल्हा</th>
                        <ng-container *ngFor="let item of subTypesName">
                            <th *ngIf="item['isVisible']">{{ item['name'] }}</th>
                        </ng-container>
                        <th colspan="2">सप्रेम भेट</th>
                        <th>एकूणसप्रेम<br />भेट</th>
                        <th>एकूण</th>
                        <th>सप्रेम + एकूण</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of districtwiseData; let i=index">
                        <td>{{ i+1 }}</td>
                        <td>{{ item['districtName'] }}</td>
                        <ng-container *ngFor="let data of subTypesName">
                            <td *ngIf="data['isVisible']">{{ item[getKey(data['name'])] }}</td>
                        </ng-container>
                        <td>{{ item['सप्रेम_भेट_1'] }}</td>
                        <td>{{ item['सप्रेम_भेट_2'] }}</td>
                        <td>{{ item['एकूणसप्रेम_भेट'] }}</td>
                        <td>{{ item['एकूण'] }}</td>
                        <td>{{ item['सप्रेम_एकूण'] }}</td>
                        <td>{{ item['districtName'] }}</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>एकूण</td>
                        <ng-container *ngFor="let data of subTypesName">
                            <td *ngIf="data['isVisible']">{{ districtwiseDataTotal[getKey(data['name'])] }}</td>
                        </ng-container>
                        <td>{{ districtwiseDataTotal['सप्रेम_भेट_1'] }}</td>
                        <td>{{ districtwiseDataTotal['सप्रेम_भेट_2'] }}</td>
                        <td>{{ districtwiseDataTotal['एकूणसप्रेम_भेट'] }}</td>
                        <td>{{ districtwiseDataTotal['एकूण'] }}</td>
                        <td>{{ districtwiseDataTotal['सप्रेम_एकूण'] }}</td>
                        <td></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</ng-container>
<div *ngIf="isLoading" class="main-loading">
    <span class="loader"></span>
    <p>Loading...</p>
</div>