<div class="header bg-gradient-danger pb-1 pt-5 pt-md-8">
  <div class="container-fluid">
    <div class="header-body"></div>
  </div>
</div>

<div class="container" style="margin-top: -4.1em">
  <mat-tab-group dynamicHeight [(selectedIndex)]="demo1TabIndex" (selectedTabChange)="onTabClick($event)">
    <mat-tab label="Credit Voucher">
      <mat-card class="credit_voucher">
        <form [formGroup]="creditvoucherForm">
          <div class="form-row">
            <mat-form-field class="example-full-width" appearance="outline" style="width: 30%; margin-left: 7%">
              <mat-label>Search Credit Voucher No :</mat-label>
              <!-- formControlName="sadvtname" -->
              <input matInput formControlName="v_no" (keyup)="getVoucherNo($event)" [matAutocomplete]="auto1" />
              <mat-autocomplete autoActiveFirstOption #auto1="matAutocomplete">
                <mat-option (onSelectionChange)="selected_vouchers($event, option)" *ngFor="let option of vouchers_no"
                  [value]="option['voucher_no']">
                  {{ option["voucher_no"] }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <mat-form-field style="margin-left: 5%; width: 19%" appearance="outline">
              <mat-label>Entry date</mat-label>
              <input style="height: 16px;" matInput type="date" formControlName="entrydate" readonly />
            </mat-form-field>
          </div>
          <div style="width: 100%;height: 2px;background-color: #2c2c2c;margin-bottom: 10px;"></div>
          <div class="form-row">
            <mat-form-field style="margin-left: 7%; width: 30%" class="example-full-width" appearance="outline">
              <mat-label>Select Bank/Cash Account</mat-label>
              <mat-select formControlName="bcaccount" (selectionChange)="getBankCash($event)">
                <mat-option *ngFor="let bank of BankData1" value="{{ bank['short_name'] }}">{{ bank["sub_exp_name"]
                  }}</mat-option>
              </mat-select>
            </mat-form-field>
            <!-- </div>
          <div class="form-row"> -->

            <mat-form-field style="margin-left: 2%; width: 20%" appearance="outline">
              <mat-label>Voucher date</mat-label>
              <input matInput type="date" style="height: 16px;" formControlName="voucherdate"
                (change)="generateVoucherNo($event)" />
            </mat-form-field>

            <mat-form-field style="margin-left: 2%; width: 30%" class="example-full-width" appearance="outline">
              <mat-label>Voucher Number :</mat-label>
              <input type="text" formControlName="vnumber" matInput readonly />
            </mat-form-field>

            <!-- <mat-form-field style="margin-left: 2%; width: 20%" appearance="outline">
              <mat-label>Voucher date</mat-label>
              <input matInput formControlName="voucherdate" [matDatepicker]="picker1v" />
              <mat-datepicker-toggle matSuffix [for]="picker1v"></mat-datepicker-toggle>
              <mat-datepicker #picker1v></mat-datepicker>
            </mat-form-field> -->

          </div>
          <div class="form-row">
            <!-- <mat-form-field
              style="margin-left: 7%; width: 60%"
              class="example-full-width"
              appearance="outline"
            >
              <mat-label>Representative Name :</mat-label>
              <input type="text" formControlName="rname" matInput required />
            </mat-form-field> -->
            <mat-form-field style="margin-left: 7%; width: 40%" class="example-full-width" appearance="outline">
              <mat-label>Representative Name :</mat-label>
              <input formControlName="rname" matInput [matAutocomplete]="autorepname" required />
              <mat-autocomplete autoActiveFirstOption #autorepname="matAutocomplete">
                <mat-option (onSelectionChange)="SelectRepresentative(option)"
                  *ngFor="let option of filteredOptions1 | async" [value]="option['name']">
                  {{ option["name"] }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <mat-form-field style="margin-left: 5%; width: 30%" class="example-full-width" appearance="outline">
              <mat-label>Narration :</mat-label>
              <input type="text" formControlName="othername" matInput />
            </mat-form-field>
          </div>
          <div class="form-row">
            <mat-form-field style="margin-left: 7%; width: 20%" class="example-full-width" appearance="outline">
              <mat-label>Deposit Amount :</mat-label>
              <input pattern="^(\d*\.)?\d+$" formControlName="damount" matInput required />
              <mat-error *ngIf="creditvoucherForm.controls.damount.invalid">Please enter numbers only</mat-error>
            </mat-form-field>
            <!-- </div>
          <div class="form-row"> -->
            <label style="margin-left: 2%;">Payment Options</label>
            <mat-radio-group style="margin-left: -120px; margin-top: 15px" formControlName="pymtoptions">
              <!-- (change)="changeType($event)" (change)="changeType($event)" -->
              <mat-radio-button [disabled]="cashtype" value="cash" style="margin-right: 10px">Cash</mat-radio-button>
              <mat-radio-button [disabled]="!cashtype" value="cheque"
                style="margin-right: 10px">Cheque</mat-radio-button>
              <mat-radio-button [disabled]="!cashtype" value="dd" style="margin-right: 10px">DD</mat-radio-button>
              <mat-radio-button [disabled]="!cashtype" value="rtgs" style="margin-right: 10px">RTGS</mat-radio-button>
              <mat-radio-button [disabled]="cashtype" value="morder" style="margin-right: 10px">Money
                Order</mat-radio-button>
              <mat-radio-button [disabled]="!cashtype" value="other" style="margin-right: 10px">other</mat-radio-button>
            </mat-radio-group>
          </div>
          <div *ngIf="this.cashtype">
            <div class="form-row">
              <mat-form-field style="margin-left: 7%; width: 40%" class="example-full-width" appearance="outline">
                <mat-label>Cheque Number :</mat-label>
                <input type="text" formControlName="cnumber" matInput />
                <mat-error *ngIf="this.creditvoucherForm.controls.cnumber.invalid">Please fill this field</mat-error>
              </mat-form-field>
              <!-- [required]="check()" -->
              <mat-form-field style="margin-left: 20px; width: 40%" appearance="outline">
                <mat-label>Cheque date</mat-label>
                <input matInput formControlName="chequedate" [max]="creditvoucherForm.get('voucherdate').value"
                  [matDatepicker]="picker1" />
                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                <mat-datepicker #picker1></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="form-row">
              <mat-form-field style="margin-left: 7%; width: 40%; height: 40px;" class="example-full-width"
                appearance="outline">
                <mat-label>Bank :</mat-label>
                <input type="text" formControlName="bank" matInput (selectionChange)="getBankCash($event)" />
                <!-- <mat-select formControlName="bank" >

                <mat-option style="height: 40px;" *ngFor="let bank of BankData" value="{{ bank['bank_name'] }}">{{ bank["bank_name"]
                }}</mat-option>
                <mat-option *ngFor="let bank of BankData1" value="{{ bank['short_name'] }}">{{ bank["sub_exp_name"]
                }}</mat-option>
            </mat-select> -->
                <!-- <input type="text" formControlName="bank" matInput (selectionChange)="getBankCash($event)"/>   -->
              </mat-form-field>
              <!-- <mat-form-field style="margin-left: 7%; width: 30%" class="example-full-width" appearance="outline">
                <mat-label>Select Bank/Cash Account</mat-label>
                <mat-select formControlName="bcaccount" >
                  <mat-option *ngFor="let bank of BankData1" value="{{ bank['bank_name'] }}">{{ bank["sub_exp_name"]
                    }}</mat-option>
                </mat-select>
              </mat-form-field> -->
              <mat-form-field style="margin-left: 20px; width: 40%; height: 40px" class="example-full-width"
                appearance="outline">
                <mat-label>Branch Name :</mat-label>
                <input type="text" formControlName="branch" matInput />
              </mat-form-field>
            </div>
          </div>
          <!-- <div class="form-row">
            <mat-form-field
              style="margin-left: 7%; width: 60%"
              class="example-full-width"
              appearance="outline"
            >
              <mat-label>Payment Type :</mat-label>
              <input type="text" formControlName="ptype" matInput />
            </mat-form-field>
          </div> -->
          <div class="form-row">
            <label style="margin-left: 7%; margin-top: 30px">Select the type</label>
            <mat-radio-group style="margin-left: 10px; margin-top: 20px" formControlName="types"
              (change)="changeType($event)">
              <!-- (change)="changeType($event)" (change)="changeType($event)" -->
              <mat-radio-button value="package" style="margin-right: 10px; margin-top: 10px">Package</mat-radio-button>
              <mat-radio-button value="nopackage" style="margin-right: 10px; margin-top: 10px">No
                Package</mat-radio-button>
            </mat-radio-group>
          </div>

          <!-- <div class="row" style="margin-left: 5%;" *ngIf="showpkg">
            <div class="col-4" *ngFor="let package of packageData">
              <div style="padding: 10%;
                                      border: solid 1px;
                                      margin-top: 5%;
                                      width: 90%;
                                      margin-left: 3%;
                                      border-radius: 4px;">
                <mat-radio-button (click)="selected_package_details(package['pkg_id'])
                  ">
                  Name : {{ package['pkg_name'] | titlecase }}<br />
                  Price : {{ package['pkg_amt'] }}
                </mat-radio-button>
              </div>
            </div>
          </div> -->

          <div class="tableFixHead" *ngIf="showpkg">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th>Sr. No.</th>
                  <th>Package Name</th>
                  <th>Price</th>
                  <th>Package Start Date</th>
                  <th>Package End Date</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let package of packageData; let i = index">
                  <td>{{ i + 1 }}</td>
                  <td>{{ package["pkg_name"] | titlecase }}</td>
                  <td>{{ package["pkg_amt"] }}</td>
                  <td>{{ package["pkg_start_date"] }}</td>
                  <td>{{ package["pkg_end_date"] }}</td>
                  <td>
                    <button class="btn btn-primary" type="button" mat-raised-button
                      (click)="selectpackage(package['pkg_id'])">
                      select
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="form-row">
            <mat-form-field style="margin-left: 7%; width: 40%" class="example-full-width" appearance="outline">
              <mat-label>Subscription &#8377; :</mat-label>
              <input pattern="^(\d*\.)?\d+$" formControlName="subscription" matInput (change)="total($event)" />
              <mat-error *ngIf="creditvoucherForm.controls.subscription.invalid">Please enter numbers only</mat-error>
            </mat-form-field>
            <!-- <mat-form-field style="margin-left: 20px; width: 40%" class="example-chip-list" appearance="outline">
              <mat-label>Name</mat-label>
              <mat-chip-list #chipList aria-label="Fruit selection">
                <mat-chip *ngFor="let fruit of names" (removed)="remove(fruit)">
                  {{ fruit }}
                  <button matChipRemove>
                    <mat-icon>cancel</mat-icon>
                  </button>
                </mat-chip>
                <input placeholder="Enter Name..." [matChipInputFor]="chipList"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur"
                  (matChipInputTokenEnd)="sadd($event)" />
              </mat-chip-list>
            </mat-form-field> -->
            <mat-form-field style="margin-left: 20px; width: 40%" class="example-chip-list" appearance="outline">
              <mat-label>Subscription Name</mat-label>
              <mat-chip-grid #chipGrid aria-label="Enter fruits">
                @for (fruit of names; track fruit) {
                <mat-chip-row (removed)="remove(fruit)" [editable]="true"
                  [aria-description]="'press enter to edit ' + fruit.name">
                  {{fruit}}
                  <button matChipRemove [attr.aria-label]="'remove ' + fruit.name">
                    <mat-icon>cancel</mat-icon>
                  </button>
                </mat-chip-row>
                }
                <input placeholder="New Subscription..." [matChipInputFor]="chipGrid"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur"
                  (matChipInputTokenEnd)="sadd($event)" />
              </mat-chip-grid>
            </mat-form-field>
          </div>
          <div class="form-row">
            <mat-form-field style="margin-left: 7%; margin-top: 10px; width: 40%" class="example-full-width"
              appearance="outline">
              <mat-label>Issue Sale &#8377; :</mat-label>
              <input pattern="^(\d*\.)?\d+$" formControlName="isale" matInput (change)="total($event)" />
              <mat-error *ngIf="creditvoucherForm.controls.isale.invalid">Please enter numbers only</mat-error>
            </mat-form-field>
            <!-- <mat-form-field style="margin-left: 20px; margin-top: 10px; width: 40%" class="example-chip-list"
              appearance="outline">
              <mat-label>Name</mat-label>
              <mat-chip-list #chipList1 aria-label="Fruit selection">
                <mat-chip *ngFor="let fruit of names1" (removed)="remove(fruit)">
                  {{ fruit }}
                  <button matChipRemove>
                    <mat-icon>cancel</mat-icon>
                  </button>
                </mat-chip>
                <input placeholder="Enter Name..." [matChipInputFor]="chipList1"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur"
                  (matChipInputTokenEnd)="isadd($event)" />
              </mat-chip-list>
            </mat-form-field> -->
            <mat-form-field style="margin-left: 20px; width: 40%; padding-top: 10px;" class="example-chip-list"
              appearance="outline">
              <mat-label>Issue Name</mat-label>
              <mat-chip-grid #chipGrid1 aria-label="Enter fruits">
                @for (fruit of names1; track fruit) {
                <mat-chip-row (removed)="remove(fruit)" [editable]="true"
                  [aria-description]="'press enter to edit ' + fruit.name">
                  {{fruit}}
                  <button matChipRemove [attr.aria-label]="'remove ' + fruit.name">
                    <mat-icon>cancel</mat-icon>
                  </button>
                </mat-chip-row>
                }
                <input placeholder="New Issue..." [matChipInputFor]="chipGrid1"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur"
                  (matChipInputTokenEnd)="isadd($event)" />
              </mat-chip-grid>
            </mat-form-field>

          </div>
          <div class="form-row">
            <mat-form-field style="margin-left: 7%; margin-top: 10px; width: 40%" class="example-full-width"
              appearance="outline">
              <mat-label>Adverties &#8377; :</mat-label>
              <input pattern="^(\d*\.)?\d+$" formControlName="adverties" matInput (change)="total($event)" />
              <mat-error *ngIf="creditvoucherForm.controls.adverties.invalid">Please enter numbers only</mat-error>
            </mat-form-field>
            <!-- <mat-form-field style="margin-left: 20px; margin-top: 10px; width: 40%" class="example-chip-list"
              appearance="outline">
              <mat-label>Name</mat-label>
              <mat-chip-list #chipList2 aria-label="Fruit selection">
                <mat-chip *ngFor="let fruit of names2" (removed)="remove(fruit)">
                  {{ fruit }}
                  <button matChipRemove>
                    <mat-icon>cancel</mat-icon>
                  </button>
                </mat-chip>
                <input placeholder="Enter Name..." [matChipInputFor]="chipList2"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur"
                  (matChipInputTokenEnd)="aadd($event)" />
              </mat-chip-list>
            </mat-form-field> -->
            <mat-form-field style="margin-left: 20px; width: 40% ;padding-top: 10px" class="example-chip-list"
              appearance="outline">
              <mat-label>Adverties Name</mat-label>
              <mat-chip-grid #chipGrid2 aria-label="Enter fruits">
                @for (fruit of names2; track fruit) {
                <mat-chip-row (removed)="remove(fruit)" [editable]="true"
                  [aria-description]="'press enter to edit ' + fruit.name">
                  {{fruit}}
                  <button matChipRemove [attr.aria-label]="'remove ' + fruit.name">
                    <mat-icon>cancel</mat-icon>
                  </button>
                </mat-chip-row>
                }
                <input placeholder="New Adverties..." [matChipInputFor]="chipGrid2"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur"
                  (matChipInputTokenEnd)="aadd($event)" />
              </mat-chip-grid>
            </mat-form-field>
          </div>
          <div class="form-row">
            <mat-form-field style="margin-left: 7%; margin-top: 10px; width: 40%" class="example-full-width"
              appearance="outline">
              <mat-label>Deposits &#8377; :</mat-label>
              <input pattern="^(\d*\.)?\d+$" formControlName="deposits" matInput (change)="total($event)" />
              <mat-error *ngIf="creditvoucherForm.controls.deposits.invalid">Please enter numbers only</mat-error>
            </mat-form-field>
            <!-- <mat-form-field style="margin-left: 20px; margin-top: 10px; width: 40%" class="example-chip-list"
              appearance="outline">
              <mat-label>Name</mat-label>
              <mat-chip-list #chipList3 aria-label="Fruit selection">
                <mat-chip *ngFor="let fruit of names3" (removed)="remove(fruit)">
                  {{ fruit }}
                  <button matChipRemove>
                    <mat-icon>cancel</mat-icon>
                  </button>
                </mat-chip>
                <input placeholder="Enter Name..." [matChipInputFor]="chipList3"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur"
                  (matChipInputTokenEnd)="dadd($event)" />
              </mat-chip-list>
            </mat-form-field> -->
            <mat-form-field style="margin-left: 20px; width: 40%; padding-top: 10px" class="example-chip-list"
              appearance="outline">
              <mat-label>Deposits Name</mat-label>
              <mat-chip-grid #chipGrid3 aria-label="Enter fruits">
                @for (fruit of names3; track fruit) {
                <mat-chip-row (removed)="remove(fruit)" [editable]="true"
                  [aria-description]="'press enter to edit ' + fruit.name">
                  {{fruit}}
                  <button matChipRemove [attr.aria-label]="'remove ' + fruit.name">
                    <mat-icon>cancel</mat-icon>
                  </button>
                </mat-chip-row>
                }
                <input placeholder="New deposit..." [matChipInputFor]="chipGrid3"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur"
                  (matChipInputTokenEnd)="dadd($event)" />
              </mat-chip-grid>
            </mat-form-field>
          </div>
          <!-- <div class="form-row">
            <mat-form-field style="margin-left: 7%; margin-top: 10px; width: 25%" class="example-full-width"
              appearance="outline">
              <mat-label>Book Seller Name : </mat-label>
              <input matInput type="text" placeholder="Enter Book Seller Name" formControlName="granthbook" />
            </mat-form-field>

            <mat-form-field style=" margin-left: 2%; margin-top: 10px; width: 25%" class="example-full-width"
              appearance="outline">
              <mat-label>Book Names :</mat-label>
              <input matInput type="text" placeholder="Enter Book Names" formControlName="granthbookname" />
            </mat-form-field>
          </div> -->
          <div>
            <mat-form-field style="margin-left: 7%; margin-top: 10px; width: 30%" class="example-full-width"
              appearance="outline">
              <mat-label>Granth/Book Price &#8377;:</mat-label>
              <input pattern="^(\d*\.)?\d+$" formControlName="granthbook" matInput (change)="total($event)" />
              <mat-error *ngIf="creditvoucherForm.controls.granthbook.invalid">Please enter numbers only</mat-error>
            </mat-form-field>
            <mat-form-field style="margin-left: 3%; margin-top: 10px; width: 25%" class="example-full-width"
              appearance="outline">
              <mat-label>Book Seller Name : </mat-label>
              <input matInput type="text" placeholder="Enter Book Seller Name" formControlName="booksellername" />
            </mat-form-field>
            <mat-form-field style="margin-left: 3%; margin-top: 10px; width: 25%" class="example-full-width"
              appearance="outline">
              <mat-label>Book Name(s) : </mat-label>
              <input matInput type="text" placeholder="Enter Book Names" formControlName="granthbooknames" />
            </mat-form-field>
            <!-- <mat-form-field style="margin-left: 2%; margin-top: 10px; width: 28%" class="example-full-width"
              appearance="outline">
              <mat-label>Books :</mat-label>
              <mat-select formControlName="bookName" (selectionChange)="getGetBookIds($event)" multiple>
                <mat-option *ngFor="let exp of bookList" [value]="exp['bd_id']">
                  {{ exp["book_name"] }}
                </mat-option>
              </mat-select>
            </mat-form-field> -->
          </div>
          <!-- <div>
            <button (click)="addNameData()">add data</button>
          </div> -->
          <div class="form-row " style="margin-top: 10px;">
            <mat-form-field style="margin-left: 7%; width: 30%" class="example-full-width" appearance="outline">
              <mat-label>Package</mat-label>
              <input type="text" formControlName="package" matInput readonly />
            </mat-form-field>
            <mat-form-field style="margin-left: 1%; width: 25%" class="example-full-width" appearance="outline">
              <mat-label>Client Name</mat-label>
              <input type="text" formControlName="packagename" matInput />
            </mat-form-field>
            <mat-form-field style="margin-left: 1%; width: 15%" class="example-full-width" appearance="outline">
              <mat-label>Amount</mat-label>
              <input type="text" formControlName="packageamt" (change)="packageAmount($event)" matInput />
            </mat-form-field>
            <div style="margin-left: 3%">
              <button *ngIf="!isUpdate" style="margin-top: 10px; width: 15%" class="btn btn-primary" mat-raised-button
                type="button" (click)="addPackage()">
                Add
              </button>
              <button *ngIf="isUpdate" style="margin-top: 10px; width: 75%" class="btn btn-info" mat-raised-button
                type="button" (click)="updatePackage()">
                Update
              </button>
            </div>
            <!-- <mat-form-field style="margin-left: 20px; margin-top: 10px; width: 40%" class="example-chip-list"
              appearance="outline">
              <mat-label>Name</mat-label>
              <mat-chip-list #chipList4 aria-label="Fruit selection">
                <mat-chip *ngFor="let fruit of names4" (removed)="remove(fruit)">
                  {{ fruit }}
                  <button matChipRemove>
                    <mat-icon>cancel</mat-icon>
                  </button>
                </mat-chip>
                <input placeholder="Enter Name..." [matChipInputFor]="chipList4"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur"
                  (matChipInputTokenEnd)="gbadd($event)" />
              </mat-chip-list>
            </mat-form-field> -->
          </div>
          <div class="tableFixHead" *ngIf="selectedPackages.length > 0">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th>Package Name</th>
                  <th>Package Amount</th>
                  <th>Client Name</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of selectedPackages; let i = index">
                  <td>{{ item["pkg_name"] }}</td>
                  <td>{{ item["pkg_amt"] }}</td>
                  <td>{{ item["client_name"] }}</td>
                  <td>
                    <div>
                      <button *ngIf="item['id']" mat-button-raised class="btn btn-info btn-sm" type="button"
                        style="float: none;" (click)="updatepkg(item)">
                        <mat-icon>edit</mat-icon>
                      </button>
                      <button *ngIf="!item['id']" mat-button-raised class="btn btn-danger btn-sm" type="button"
                        style="float: none;" (click)="removepkg(i)">
                        <mat-icon>clear</mat-icon>
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="form-row" *ngIf="creditvoucherForm.value.expnameotherd==35">
            <!-- <mat-form-field style="margin-left: 7%; margin-top: 10px; width: 25%" class="example-full-width"
              appearance="outline">
              <mat-label>Donation &#8377;:</mat-label>
              <input pattern="^(\d*\.)?\d+$" formControlName="donation" matInput (change)="total($event)" />
              <mat-error *ngIf="creditvoucherForm.controls.donation.invalid">Please enter numbers only</mat-error>
            </mat-form-field> -->
            <!-- <mat-form-field  style="margin-left: 7%; margin-top: 10px; width: 25%" class="example-full-width"
            appearance="outline">
            <mat-label>Pan Card :</mat-label>
            <input type="text" formControlName="pancard" matInput />
          </mat-form-field>
          <mat-form-field  style="margin-left: 3%; margin-top: 10px; width: 25%" class="example-full-width"
          appearance="outline">
          <mat-label>Narration :</mat-label>
          <input type="text" formControlName="narration" matInput />
        </mat-form-field> -->

            <!-- <mat-form-field style="margin-left: 20px; margin-top: 10px; width: 40%" class="example-chip-list"
              appearance="outline">
              <mat-label>Name</mat-label>
              <mat-chip-list #chipList5 aria-label="Fruit selection">
                <mat-chip *ngFor="let fruit of names5" (removed)="remove(fruit)">
                  {{ fruit }}
                  <button matChipRemove>
                    <mat-icon>cancel</mat-icon>
                  </button>
                </mat-chip>
                <input placeholder="Enter Name..." [matChipInputFor]="chipList5"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur"
                  (matChipInputTokenEnd)="donadd($event)" />
              </mat-chip-list>
            </mat-form-field> -->
          </div>
          <div class="form-row">
            <mat-form-field style="margin-left: 7%; margin-top: 10px; width: 40%" class="example-full-width"
              appearance="outline">
              <mat-label>Other Deposit &#8377;:</mat-label>
              <input pattern="^(\d*\.)?\d+$" formControlName="odeposit" matInput (change)="total($event)" />
              <mat-error *ngIf="creditvoucherForm.controls.odeposit.invalid">Please enter numbers only</mat-error>
            </mat-form-field>
            <!-- <mat-form-field style="margin-left: 20px; margin-top: 10px; width: 40%" class="example-chip-list"
              appearance="outline">
              <mat-label>Name</mat-label>
              <mat-chip-list #chipList6 aria-label="Fruit selection">
                <mat-chip *ngFor="let fruit of names6" (removed)="remove(fruit)">
                  {{ fruit }}
                  <button matChipRemove>
                    <mat-icon>cancel</mat-icon>
                  </button>
                </mat-chip>
                <input placeholder="Enter Name..." [matChipInputFor]="chipList6"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur"
                  (matChipInputTokenEnd)="odadd($event)" />
              </mat-chip-list>
            </mat-form-field> -->
          </div>
          <!-- new other -->
          <div class="form-row" style="margin-top: 10px;">
            <mat-form-field style="margin-left: 7%; width: 20%" class="example-full-width" appearance="outline">
              <mat-label>Select Main Expense</mat-label>
              <mat-select formControlName="expnameotherd" (selectionChange)="getMainExpName1($event)">
                <mat-option *ngFor="let exp of mainExpData" [value]="exp['id']">
                  {{ exp["exp_name"] }}
                </mat-option>
              </mat-select>

            </mat-form-field>
            <mat-form-field *ngIf="subexpense1" style="margin-left: 2%; width: 20%" class="example-full-width"
              appearance="outline">
              <mat-label>Select Sub Expense</mat-label>
              <mat-select formControlName="subexpnameotherd" (selectionChange)="getSubExpName1($event)">
                <!-- (selectionChange)="getMainExpName($event)" -->
                <mat-option *ngFor="let exp of getSubExpData1" value="{{ exp['sub_id'] }}">{{ exp["sub_exp_name"]
                  }}</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field style="margin-left: 2%; width: 20%" class="example-full-width" appearance="outline">
              <mat-label>Enter Expense:</mat-label>
              <input pattern="^(\d*\.)?\d+$" formControlName="exp_amtother" matInput />
            </mat-form-field>
            <!-- <mat-form-field
              *ngIf="creditvoucherForm.value.expnameotherd==35 ||creditvoucherForm.value.expnameotherd==36 "
              style="margin-left: 2%; width: 15%" class="example-full-width" appearance="outline">
              <mat-label>Pan Card :</mat-label>
              <input type="text" formControlName="pancard" matInput maxlength="10" />
              <mat-error *ngIf="creditvoucherForm.get('pancard').hasError('maxlength')">
                Maximum 10 characters allowed
              </mat-error>
            </mat-form-field>
            <mat-form-field
              *ngIf="creditvoucherForm.value.expnameotherd==35 || creditvoucherForm.value.expnameotherd==36"
              style="margin-left: 2%; width: 15%" class="example-full-width" appearance="outline">
              <mat-label>Narration :</mat-label>
              <input type="text" formControlName="narration" matInput />
            </mat-form-field>
            <div>
              <button *ngIf="!showUpdateOnMainOrSubOther" style="margin-top: 10px; margin-left: 20px; width: 15%"
                class="btn btn-info" mat-raised-button type="button" (click)="addMainExpense1()">
                Add
              </button>
              <button *ngIf="showUpdateOnMainOrSubOther" style="margin-top: 10px; width: 75%" class="btn btn-info"
                mat-raised-button type="button" (click)="updateMainExpense1()">
                Update
              </button>
            </div> -->
            <div *ngIf="creditvoucherForm.value.expnameotherd!=35 && creditvoucherForm.value.expnameotherd!=36 ">
              <button *ngIf="!showUpdateOnMainOrSubOther" style="margin-top: 10px; margin-left: 20px; width: 15%"
                class="btn btn-info" mat-raised-button type="button" (click)="addMainExpense1()">
                Add
              </button>
              <button *ngIf="showUpdateOnMainOrSubOther" style="margin-top: 10px; width: 75%" class="btn btn-info"
                mat-raised-button type="button" (click)="updateMainExpense1()">
                Update
              </button>
            </div>
          </div>
          <div class="form-row" style="margin-top: 10px;"
            *ngIf="creditvoucherForm.value.expnameotherd==35 ||creditvoucherForm.value.expnameotherd==36 ">
            <mat-form-field
              *ngIf="creditvoucherForm.value.expnameotherd==35 ||creditvoucherForm.value.expnameotherd==36 "
              style="margin-left: 7%; width: 15%" class="example-full-width" appearance="outline">
              <mat-label>Pan Card :</mat-label>
              <input type="text" formControlName="pancard" matInput maxlength="10" />
              <mat-error *ngIf="creditvoucherForm.get('pancard').hasError('maxlength')">
                Maximum 10 characters allowed
              </mat-error>
            </mat-form-field>
            <mat-form-field
              *ngIf="creditvoucherForm.value.expnameotherd==35 || creditvoucherForm.value.expnameotherd==36"
              style="margin-left: 2%; width: 15%" class="example-full-width" appearance="outline">
              <mat-label>Narration :</mat-label>
              <input type="text" formControlName="narration" matInput />
            </mat-form-field>
            <div>
              <button *ngIf="!showUpdateOnMainOrSubOther" style="margin-top: 10px; margin-left: 20px; width: 15%"
                class="btn btn-info" mat-raised-button type="button" (click)="addMainExpense1()">
                Add
              </button>
              <button *ngIf="showUpdateOnMainOrSubOther" style="margin-top: 10px; width: 75%" class="btn btn-info"
                mat-raised-button type="button" (click)="updateMainExpense1()">
                Update
              </button>
            </div>
          </div>
          <div class="tableFixHead" *ngIf="selectedMainExpenses1.length > 0" style="margin-top: 25px;">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th>Main Exp_name</th>
                  <th>Amount</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of selectedMainExpenses1; let i = index">
                  <td>{{ item["mainexpname"] }}</td>
                  <td>{{ item["expamt"] }}</td>
                  <td>
                    <div>
                      <!-- *ngIf="item['isSubExp'] == 'N'" -->
                      <button *ngIf="item['isSubExp'] == 'N'" mat-button-raised style="float: none;"
                        class="btn btn-info btn-sm" type="button" (click)="updateMainExp1(item)">
                        <mat-icon>edit</mat-icon>
                      </button>
                      <button *ngIf="!item['id']" mat-button-raised class="btn btn-danger btn-sm" type="button"
                        (click)="removeMainExp1(item['mainexpid'])">
                        <mat-icon>clear</mat-icon>
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="tableFixHead" *ngIf="selectedsubExpenses1.length > 0">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th>Main Exp_name</th>
                  <th>Sub Exp_name</th>
                  <th>Amount</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of selectedsubExpenses1; let i = index">
                  <td>{{ item["mainexpname"] }}</td>
                  <td>{{ item["subexpname"] }}</td>
                  <td>{{ item["expamt"] }}</td>
                  <td>
                    <div>
                      <button *ngIf="item['id']" mat-button-raised style="float: none;" class="btn btn-info btn-sm"
                        type="button" (click)="updateSubExp1(item)">
                        <mat-icon>edit</mat-icon>
                      </button>
                      <button *ngIf="!item['id']" mat-button-raised style="float: none;" class="btn btn-danger btn-sm"
                        type="button" (click)="removeSubExp1(item['subexpid'])">
                        <mat-icon>clear</mat-icon>
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- end new other -->
          <div class="form-row">
            <mat-form-field style="margin-left: 7%; margin-top: 10px; width: 15%" class="example-full-width"
              appearance="outline">
              <mat-label>Package Count:</mat-label>
              <input type="number" formControlName="packagecount" matInput readonly />
            </mat-form-field>
            <mat-form-field style="margin-left: 1%; margin-top: 10px; width: 20%" class="example-full-width"
              appearance="outline">
              <mat-label>Package Amount &#8377;:</mat-label>
              <input type="number" formControlName="packageamount" matInput readonly />
            </mat-form-field>
            <mat-form-field style="margin-left: 1%; margin-top: 10px; width: 20%" class="example-full-width"
              appearance="outline">
              <mat-label>Other Amount &#8377;:</mat-label>
              <input type="number" formControlName="otheramount" matInput readonly />
            </mat-form-field>
            <mat-form-field style="margin-left: 1%; margin-top: 10px; width: 24%" class="example-full-width"
              appearance="outline">
              <mat-label>Total &#8377;:</mat-label>
              <input type="number" formControlName="total" matInput readonly />
            </mat-form-field>
          </div>
          <hr />
          <div class="form-row">
            <mat-form-field style="margin-left: 7%; margin-top: 10px; width: 60%" class="example-full-width"
              appearance="outline">
              <mat-label>Expenditure &#8377;:</mat-label>
              <input pattern="^(\d*\.)?\d+$" formControlName="expenditure" matInput />
              <mat-error *ngIf="creditvoucherForm.controls.expenditure.invalid">Please enter numbers only</mat-error>
            </mat-form-field>
          </div>
          <div class="form-row" style="margin-top: 10px;">
            <mat-form-field style="margin-left: 7%; width: 20%" class="example-full-width" appearance="outline">
              <mat-label>Select Main Expense</mat-label>
              <mat-select formControlName="expname" (selectionChange)="getMainExpName($event)">
                <!-- (selectionChange)="getMainExpName($event)" -->
                <mat-option *ngFor="let exp of mainExpData" value="{{ exp['id'] }}">{{ exp["exp_name"]
                  }}</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field *ngIf="subexpense" style="margin-left: 2%; width: 20%" class="example-full-width"
              appearance="outline">
              <mat-label>Select Sub Expense</mat-label>
              <mat-select formControlName="subexpname" (selectionChange)="getSubExpName($event)">
                <!-- (selectionChange)="getMainExpName($event)" -->
                <mat-option *ngFor="let exp of getSubExpData" value="{{ exp['sub_id'] }}">{{ exp["sub_exp_name"]
                  }}</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field style="margin-left: 2%; width: 20%" class="example-full-width" appearance="outline">
              <mat-label>Enter Expense:</mat-label>
              <input pattern="^(\d*\.)?\d+$" formControlName="exp_amt" matInput />
            </mat-form-field>
            <div style="margin-left: 3%">
              <button *ngIf="!showUpdateOnMainOrSub" style="margin-top: 10px; width: 15%" class="btn btn-info"
                mat-raised-button type="button" (click)="addMainExpense()">
                Add
              </button>
              <button *ngIf="showUpdateOnMainOrSub" style="margin-top: 10px; width: 75%" class="btn btn-info"
                mat-raised-button type="button" (click)="updateMainExpense()">
                Update
              </button>
            </div>
          </div>
          <div class="tableFixHead" *ngIf="selectedMainExpenses.length > 0">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th>Main Exp_name</th>
                  <th>Amount</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of selectedMainExpenses; let i = index">
                  <td>{{ item["mainexpname"] }}</td>
                  <td>{{ item["expamt"] }}</td>
                  <td>
                    <div>
                      <!-- *ngIf="item['isSubExp'] == 'N'" -->
                      <button *ngIf="item['isSubExp'] == 'N'" mat-button-raised style="float: none;"
                        class="btn btn-info btn-sm" type="button" (click)="updateMainExp(item)">
                        <mat-icon>edit</mat-icon>
                      </button>
                      <button *ngIf="!item['id']" mat-button-raised class="btn btn-danger btn-sm" type="button"
                        (click)="removeMainExp(item['mainexpid'])">
                        <mat-icon>clear</mat-icon>
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="tableFixHead" *ngIf="selectedsubExpenses.length > 0">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th>Main Exp_name</th>
                  <th>Sub Exp_name</th>
                  <th>Amount</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of selectedsubExpenses; let i = index">
                  <td>{{ item["mainexpname"] }}</td>
                  <td>{{ item["subexpname"] }}</td>
                  <td>{{ item["expamt"] }}</td>
                  <td>
                    <div>
                      <button *ngIf="item['id']" mat-button-raised style="float: none;" class="btn btn-info btn-sm"
                        type="button" (click)="updateSubExp(item)">
                        <mat-icon>edit</mat-icon>
                      </button>
                      <button *ngIf="!item['id']" mat-button-raised style="float: none;" class="btn btn-danger btn-sm"
                        type="button" (click)="removeSubExp(item['subexpid'])">
                        <mat-icon>clear</mat-icon>
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="form-row">
            <mat-form-field style="margin-left: 7%; width: 30%" class="example-full-width" appearance="outline">
              <mat-label>Total &#8377;:</mat-label>
              <input formControlName="totalexp" matInput readonly />
            </mat-form-field>
            <!-- </div>
          <div class="form-row"> -->
            <mat-form-field style="margin-left: 5%; width: 30%" class="example-full-width" appearance="outline">
              <mat-label>Net Deposit &#8377;:</mat-label>
              <input type="number" formControlName="ndeposit" matInput readonly />
            </mat-form-field>
          </div>
          <div class="form-group">
            <button *ngIf="!showCreditupdate" style="margin-left: 125px; width: 70%" type="button" mat-raised-button
              color="primary" (click)="save_creditvoucher()">
              Generate Credit Voucher
            </button>
            <button *ngIf="showCreditupdate" style="margin-left: 65px; width: 40%;float: none;" type="button"
              mat-raised-button class="btn btn-info" (click)="update_creditvoucher()">
              Update Credit Voucher
            </button>
            <button *ngIf="showCreditupdate" style="margin-left: 40px; width: 35%;float: none; background-color: red;"
              type="button" mat-raised-button class="btn btn-danger" (click)="delete_creditvoucher()">
              Delete Credit Voucher
            </button>
          </div>
        </form>
      </mat-card>
    </mat-tab>

    <mat-tab label="Debit Voucher">
      <mat-card class="credit_voucher">
        <form [formGroup]="debitForm">
          <div class="form-row">
            <!-- <mat-form-field style="margin-left: 7%; width: 30%" class="example-full-width" appearance="outline">
              <mat-label>Select Bank/Cash Account</mat-label>
              <mat-select formControlName="bcaccount" (selectionChange)="getBankCashDebit($event)">
                <mat-option *ngFor="let bank of BankData" value="{{ bank['bank_short_name'] }}">{{ bank["bank_name"]
                  }}</mat-option>
              </mat-select>
            </mat-form-field> -->
            <mat-form-field class="example-full-width" appearance="outline" style="width: 25%; margin-left: 7%">
              <mat-label>Search Debit Voucher No :</mat-label>
              <!-- formControlName="sadvtname" -->
              <input matInput formControlName="dv_no" (keyup)="getDVVoucherNo($event)" [matAutocomplete]="auto2" />
              <mat-autocomplete autoActiveFirstOption #auto2="matAutocomplete">
                <mat-option (onSelectionChange)="selected_d_vouchers($event, option)" *ngFor="let option of vouchers_no"
                  [value]="option['voucher_no']">
                  {{ option["voucher_no"] }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <mat-form-field style="margin-left: 1%; width: 25%" class="example-full-width" appearance="outline">
              <mat-label>Select Bank/Cash Account</mat-label>
              <mat-select formControlName="bcaccount" (selectionChange)="getBankCashDebit($event)">
                <mat-option *ngFor="let bank of BankData1" value="{{ bank['short_name'] }}">{{ bank["sub_exp_name"]
                  }}</mat-option>
              </mat-select>
            </mat-form-field>
            <!-- </div>
          <div class="form-row"> -->
            <mat-form-field style="margin-left: 1%; width: 25%" appearance="outline">
              <mat-label>Voucher date</mat-label>
              <input matInput type="date" style="height: 16px;" formControlName="dvoucherdate"
                (change)="generateDebitVoucherNo($event)" />
            </mat-form-field>

          </div>
          <div class="form-row">
            <mat-form-field style="margin-left: 7%; width: 25%" class="example-full-width" appearance="outline">
              <mat-label>Debit Voucher Number :</mat-label>
              <input type="text" formControlName="dvnumber" matInput readonly />
            </mat-form-field>
            <mat-form-field style="margin-left: 1%; width: 25%" class="example-full-width" appearance="outline">
              <mat-label>Representative Name :</mat-label>
              <input formControlName="rname" matInput [matAutocomplete]="autorepname1" />
              <mat-autocomplete autoActiveFirstOption #autorepname1="matAutocomplete">
                <!-- (onSelectionChange)="SelectRepresentative(option)" -->
                <mat-option *ngFor="let option of filteredOptions4 | async" [value]="option['name']">
                  {{ option["name"] }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <mat-form-field style="margin-left: 1%; width: 25%" class="example-full-width" appearance="outline">
              <mat-label>Narration :</mat-label>
              <input type="text" formControlName="othername" matInput />
            </mat-form-field>
          </div>
          <div class="form-row">
            <mat-form-field style="margin-left: 7%; width: 25%" class="example-full-width" appearance="outline">
              <mat-label>Expenditure :</mat-label>
              <input pattern="^(\d*\.)?\d+$" formControlName="exp_amt" matInput required />
              <mat-error *ngIf="debitForm.controls.exp_amt.invalid">Please enter numbers only</mat-error>
            </mat-form-field>
            <!-- <mat-form-field style="margin-left: 1%; width: 25%" class="example-full-width" appearance="outline">
              <mat-label>Cheque Date :</mat-label>
              <input type="text" formControlName="chequedate" [max]="debitForm.get('dvoucherdate').value" matInput />
            </mat-form-field> -->
            <mat-form-field style="margin-left: 1%; width: 25%" appearance="outline">
              <mat-label>Cheque date</mat-label>
              <input matInput formControlName="chequedate" [max]="debitForm.get('dvoucherdate').value"
                [matDatepicker]="picker2" />
              <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
              <mat-datepicker #picker2></mat-datepicker>
            </mat-form-field>
            <mat-form-field style="margin-left: 1%; width: 25%" class="example-full-width" appearance="outline">
              <mat-label>Cheque No :</mat-label>
              <input type="text" formControlName="chequeno" matInput />
            </mat-form-field>
          </div>
          <!-- bind -->
          <div class="tableFixHead" *ngIf="debitlist">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th>Sr No</th>
                  <th>Main Exp_name</th>
                  <th>Main Exp Amount</th>
                  <th>Sub Exp name</th>
                  <th>Sub Exp Amount</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let item of debitvoucherlist; let i = index">
                  <tr *ngIf="item.visible">
                    <td>{{ i+1 }}</td>
                    <td>{{ item["exp_name"] }}</td>
                    <td>{{ item["main_exp_amt"] }}</td>
                    <td>{{ item["sub_exp_name"] }}</td>
                    <td>{{ item["sub_exp_amt"] }}</td>
                    <td>
                      <div>
                        <button mat-button-raised class="btn btn-danger btn-sm" type="button"
                          (click)="removeDebitlist(item , i)">
                          <mat-icon>clear</mat-icon>
                        </button>
                      </div>
                    </td>
                  </tr>
                </ng-container>

              </tbody>
            </table>
          </div>
          <!--  -->
          <div class="form-row">
            <mat-form-field style="margin-left: 7%; width: 20%" class="example-full-width" appearance="outline">
              <mat-label>Select Main Expense</mat-label>
              <mat-select formControlName="expname" (selectionChange)="getDebitMainExpName($event)">
                <!-- (selectionChange)="getMainExpName($event)" -->
                <mat-option *ngFor="let exp of mainExpData" value="{{ exp['id'] }}">{{ exp["exp_name"]
                  }}</mat-option>
              </mat-select>
            </mat-form-field>
            <!-- <mat-form-field *ngIf="debitsubexpense" style="margin-left: 2%; width: 20%" class="example-full-width"
              appearance="outline">
              <mat-label>Select Sub Expense</mat-label>
              <mat-select formControlName="subexpname" (selectionChange)="getDebitSubExpName($event)">
                (selectionChange)="getMainExpName($event)"
                <mat-option *ngFor="let exp of getDebitSubExpData" value="{{ exp['sub_id'] }}">{{ exp["sub_exp_name"]
                  }}</mat-option>
              </mat-select>
            </mat-form-field> -->
            <mat-form-field *ngIf="debitsubexpense" style="margin-left: 2%; width: 20%" class="example-full-width"
              appearance="outline">
              <mat-label>Select Sub Expense</mat-label>
              <mat-select formControlName="subexpname" (selectionChange)="getDebitSubExpName($event)">
                <!-- (selectionChange)="getMainExpName($event)" -->
                <mat-option *ngFor="let exp of getDebitSubExpData" value="{{ exp['sub_id'] }}">{{ exp["sub_exp_name"]
                  }}</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field style="margin-left: 2%; width: 20%" class="example-full-width" appearance="outline">
              <mat-label>Enter Expense:</mat-label>
              <input pattern="^(\d*\.)?\d+$" formControlName="expamt" matInput />
            </mat-form-field>
            <div style="margin-left: 3%">
              <button style="margin-top: 10px; width: 15%" class="btn btn-info" mat-raised-button type="button"
                (click)="addDebitExpense()">
                Add
              </button>
            </div>
          </div>
          <div class="tableFixHead">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th>Main Exp_name</th>
                  <th>Amount</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of selecteddebitMainExpenses; let i = index">
                  <td>{{ item["mainexpname"] }}</td>
                  <td>{{ item["expamt"] }}</td>
                  <td>
                    <div>
                      <button mat-button-raised class="btn btn-danger btn-sm" type="button"
                        (click)="removeDebitMainExp(item['mainexpid'])">
                        <mat-icon>clear</mat-icon>
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="tableFixHead">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th>Main Exp_name</th>
                  <th>Sub Exp_name</th>
                  <th>Amount</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of selecteddebitsubExpenses; let i = index">
                  <td>{{ item["mainexpname"] }}</td>
                  <td>{{ item["subexpname"] }}</td>
                  <td>{{ item["expamt"] }}</td>
                  <td>
                    <div>
                      <button mat-button-raised style="float: none;" class="btn btn-danger btn-sm" type="button"
                        (click)="removeDebitSubExp(item['subexpid'])">
                        <mat-icon>clear</mat-icon>
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- <div class="form-row">
            <mat-form-field style="margin-left: 7%; width: 60%" class="example-full-width" appearance="outline">
              <mat-label>Travels :</mat-label>
              <input pattern="^(\d*\.)?\d+$" formControlName="travels" matInput (change)="totaldebitExpense()" />
              <mat-error *ngIf="debitForm.controls.travels.invalid">Please enter numbers only</mat-error>
            </mat-form-field>
          </div>
          <div class="form-row">
            <mat-form-field style="margin-left: 7%; width: 30%" class="example-full-width" appearance="outline">
              <mat-label>Name :</mat-label>
              <input type="text" formControlName="otherexpname" matInput />
            </mat-form-field>
            <mat-form-field style="margin-left: 2.5%; width: 30%" class="example-full-width" appearance="outline">
              <mat-label>Other :</mat-label>
              <input pattern="^(\d*\.)?\d+$" formControlName="other" matInput (change)="totaldebitExpense()" />
              <mat-error *ngIf="debitForm.controls.other.invalid">Please enter numbers only</mat-error>
            </mat-form-field>
          </div>
          <div class="form-row">
            <mat-form-field style="margin-left: 7%; width: 60%" class="example-full-width" appearance="outline">
              <mat-label>Subscription Commission :</mat-label>
              <input pattern="^(\d*\.)?\d+$" formControlName="subscommission" matInput (change)="totaldebitExpense()" />
              <mat-error *ngIf="debitForm.controls.subscommission.invalid">Please enter numbers only</mat-error>
            </mat-form-field>
          </div>
          <div class="form-row">
            <mat-form-field style="margin-left: 7%; width: 60%" class="example-full-width" appearance="outline">
              <mat-label>Issue Sale Commission :</mat-label>
              <input pattern="^(\d*\.)?\d+$" formControlName="issuecommission" matInput
                (change)="totaldebitExpense()" />
              <mat-error *ngIf="debitForm.controls.issuecommission.invalid">Please enter numbers only</mat-error>
            </mat-form-field>
          </div> -->
          <!-- <div class="form-row">
            <mat-form-field style="margin-left: 7%; width: 50%" class="example-full-width" appearance="outline">
              <mat-label>Advertisement Commission :</mat-label>
              <input pattern="^(\d*\.)?\d+$" formControlName="advtcommission" matInput (change)="totaldebitExpense()" />
              <mat-error *ngIf="debitForm.controls.advtcommission.invalid">Please enter numbers only</mat-error>
            </mat-form-field>
          </div>
          <div class="form-row">
            <mat-form-field style="margin-left: 7%; width: 60%" class="example-full-width" appearance="outline">
              <mat-label>Granth/Book Commission :</mat-label>
              <input pattern="^(\d*\.)?\d+$" formControlName="bookcommission" matInput (change)="totaldebitExpense()" />
              <mat-error *ngIf="debitForm.controls.bookcommission.invalid">Please enter numbers only</mat-error>
            </mat-form-field>
          </div> -->
          <div class="form-row">
            <mat-form-field style="margin-left: 7%; width: 60%" class="example-full-width" appearance="outline">
              <mat-label>Total :</mat-label>
              <input pattern="^(\d*\.)?\d+$" formControlName="totalexp" matInput readonly />
              <mat-error *ngIf="debitForm.controls.totalexp.invalid">Please enter numbers only</mat-error>
            </mat-form-field>
          </div>
          <!-- <div class="form-group">
            <button style="margin-left: 125px; width: 70%" type="button" mat-raised-button color="primary"
              (click)="checktheData()">
              check Data
            </button>
          </div> -->
          <div class="form-group">
            <button *ngIf="!debitlistactive" style="margin-left: 125px; width: 30%" type="button"
              [disabled]="debitForm.invalid" mat-raised-button color="primary" (click)="save_debitvoucher()">
              Generate Debit Voucher
            </button>
            <button *ngIf="debitlistactive" style="margin-left: 65px; width: 30%" type="button"
              [disabled]="debitForm.invalid" mat-raised-button color="primary" (click)="update_debitvoucher()">
              Update Debit Voucher
            </button>

            <button *ngIf="debitlistactive" style="margin-left: 400px; width: 30%; margin-top: -55px;" type="button"
              [disabled]="debitForm.invalid" mat-raised-button color="primary" (click)="delete_debitvoucher1()">
              Delete Debit Voucher
            </button>
          </div>
        </form>
      </mat-card>
    </mat-tab>

    <!-- <mat-tab label="Bank Master">
      <mat-card style="margin: 0 auto; margin-top: 2%; width: 80%; border-radius: 20px">
        <form [formGroup]="bankForm">
          <div class="form-row">
            <mat-form-field style="margin-left: 7%; width: 40%" class="example-full-width" appearance="outline">
              <mat-label>Bank Name :</mat-label>
              <input type="text" formControlName="bank_name" matInput required />
            </mat-form-field>
            <mat-form-field style="margin-left: 5%; width: 40%" class="example-full-width" appearance="outline">
              <mat-label>Bank Short Name :</mat-label>
              <input type="text" formControlName="bank_short_name" placeholder="enter in capital letter" matInput
                required />
            </mat-form-field>
          </div>
          <div class="form-row">
            <mat-form-field style="margin-left: 7%; width: 40%" class="example-full-width" appearance="outline">
              <mat-label>Bank Opening Balance :</mat-label>
             
                <input type="number" formControlName="bank_o_bal" placeholder="Enter in Number" matInput required
                 pattern="[0-9]*" />
              <mat-error *ngIf="bankForm.controls.bank_o_bal.invalid">Please enter numbers only</mat-error>
            </mat-form-field>
            <mat-form-field style="margin-left: 5%;width: 40%" class="example-full-width" appearance="outline">
              <mat-label>Select Credit / Debit</mat-label>
              <mat-select formControlName="crdtdebt" required>
                <mat-option value="Cr">Credit</mat-option>
                <mat-option value="Dr">Debit</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="form-group" style="display: flex; justify-content: center">
            <button type="button" *ngIf="!showUpdate" mat-raised-buttton style="float: none; width: 40%"
              class="btn btn-primary" [disabled]="bankForm.invalid" (click)="addBankName()">
              Add Bank Name
            </button>
            <button *ngIf="showUpdate" mat-raised-buttton style="float: none; width: 40%" class="btn btn-info"
              [disabled]="bankForm.invalid" (click)="updateBankMaster()">
              Update Bank Details
            </button>
          </div>
        </form>

        <div class="tableFixHead">
          <table class="table table-striped">
            <thead>
              <tr>
                <th>Bank name</th>
                <th>Bank Short Name</th>
                <th>Opening Balance</th>
                <th>Credit/Debit</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let bank of BankData">
                <td>{{ bank["bank_name"] }}</td>
                <td>
                  {{ bank["bank_short_name"] }}
                </td>
                <td>{{ bank['opening_bal'] }}</td>
                <td>{{ bank['crdt_debt'] }}</td>
                <td>
                  <button type="button" mat-raised-button class="btn-sm btn-outline-info"
                    (click)="selectBankMaster(bank)">
                    <mat-icon>edit</mat-icon>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </mat-card>
    </mat-tab> -->

    <mat-tab label="Credit Debit Ledger">
      <mat-card
        style="margin: 0 auto; margin-top: 2%; width: 90%; border-radius: 20px; padding-top: 30px; padding-bottom: 30px; margin-top: 50px;">
        <div class="form-row">
          <mat-form-field appearance="outline" style="margin-left: 7%; width: 40%">
            <mat-label>Enter a date from...to </mat-label>
            <mat-date-range-input [rangePicker]="picker" [formGroup]="creditdebitDateRange">
              <input matStartDate formControlName="start" placeholder="Start date" />
              <input matEndDate formControlName="end" placeholder="End date" (dateChange)="getCreditDebitDateRange()" />
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>

            <mat-error *ngIf="
                          creditdebitDateRange.controls.start.hasError('matStartDateInvalid')
                        ">Invalid start date</mat-error>
            <mat-error *ngIf="creditdebitDateRange.controls.end.hasError('matEndDateInvalid')">Invalid end
              date</mat-error>
          </mat-form-field>

          <mat-form-field style="margin-left: 5%;width: 40%" class="example-full-width" appearance="outline">
            <mat-label>Select Bank/Cash Account</mat-label>
            <mat-select (selectionChange)="getBankCashOnCD($event)">
              <mat-option *ngFor="let bank of BankData1" value="{{ bank['short_name'] }}">{{ bank["sub_exp_name"]
                }}</mat-option>
            </mat-select>
          </mat-form-field>


        </div>

        <!-- <div class="form-row">
          <mat-form-field style="margin-left: 7%;width: 30%" class="example-full-width" appearance="outline">
            <mat-label>Select Bank/Cash Account</mat-label>
            <mat-select (selectionChange)="getBankCashOnCD($event)">
              <mat-option *ngFor="let bank of BankData1" value="{{ bank['sub_exp_name'] }}">{{ bank["sub_exp_name"]
                }}</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field *ngIf="debitsubexpense" style="margin-left: 2%; width: 20%" class="example-full-width"
          appearance="outline">
          <mat-label>Select Sub Expense</mat-label>
          <mat-select formControlName="subexpname" (selectionChange)="getDebitSubExpName($event)">
            <mat-option *ngFor="let exp of BankData1" value="{{ exp['sub_exp_name'] }}">{{ exp["sub_exp_name"]
              }}</mat-option>
          </mat-select>
        </mat-form-field>
        </div> -->

        <button class="btn btn-primary btn-property" mat-raised-button type="button"
          style="float: none; width: 30%; margin-left: 35%; margin-top: 20px;" (click)="creditdebitledgerdata()">
          credit ledger
        </button>
      </mat-card>
    </mat-tab>

    <mat-tab label="Reconsilation Report">
      <mat-card
        style="margin: 0 auto; margin-top: 2%; width: 100%; border-radius: 20px; padding-top: 30px; padding-bottom: 30px; margin-top: 50px; height: 120px;">
        <div class="form-row" style="width: 100%">
          <mat-form-field appearance="outline" style="margin-left: 2%; width: 26%">
            <mat-label>Enter a date from...to </mat-label>
            <mat-date-range-input [rangePicker]="pickerrcns" [formGroup]="reconsilationDateRange">
              <input matStartDate formControlName="start" placeholder="Start date" />
              <input matEndDate formControlName="end" placeholder="End date"
                (dateChange)="getReconsilationDateRange()" />
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="pickerrcns"></mat-datepicker-toggle>
            <mat-date-range-picker #pickerrcns></mat-date-range-picker>

            <mat-error *ngIf="reconsilationDateRange.controls.start.hasError('matStartDateInvalid')
                              ">Invalid start date</mat-error>
            <mat-error *ngIf="reconsilationDateRange.controls.end.hasError('matEndDateInvalid')">Invalid end
              date</mat-error>
          </mat-form-field>

          <mat-form-field style="margin-left: 2%;width: 26%" class="example-full-width" appearance="outline">
            <mat-label>Select Bank Account</mat-label>
            <!-- <mat-select (selectionChange)="getBankCashOnReconsilation($event)">
                <mat-option *ngFor="let bank of BankData1" value="{{ bank['short_name'] }}">{{ bank["sub_exp_name"]
                }}</mat-option>
            </mat-select> -->
            <mat-select (selectionChange)="getBankCashOnReconsilation($event.value)">
              <mat-option *ngFor="let bank of BankData1"
                [value]="{shortName: bank['short_name'], subExpName: bank['sub_exp_name']}">
                {{ bank["sub_exp_name"] }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="outline" style="margin-left: 2%; width: 20%">
            <mat-label>Search by Cheque Number</mat-label>
            <input matInput (blur)="getcheque_no($event)" placeholder="Enter cheque number">
          </mat-form-field>

          <button class="btn btn-primary btn-property" mat-raised-button type="button" appearance="outline"
            style="margin-left: 2%;width: 16%;height: 55px;" (click)="getReconsilationData()">
            Get Reconsilation Report
          </button>

        </div>

      </mat-card>

      <mat-card style="margin: 0 auto; margin-top: 2%; width: 100%; border-radius: 20px;">
        <div style="margin-top: 20px" class="tableFixHead1">
          <table class="table table-bordered border-primary text-center">
            <thead>
              <tr>
                <th>Voucher No</th>
                <th>Voucher Date</th>
                <th>Cheq. No.</th>
                <th>Clearing</th>
                <th>Amount</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of mergedArray1">
                <td>{{ item['voucher_no'] }}</td>
                <td>{{ item['voucher_date'] | date:"dd/MM/yyyy" }}</td>
                <td>{{ item['cheque_no'] }}</td>
                <td style="width: 15%;">
                  <span *ngIf="item['id'] != clearUpdateId">
                    <span *ngIf="item['cheq_clearing_date'] === '0000-00-00'">
                      <mat-form-field style="width: 80%;">
                        <mat-label>Select a date</mat-label>
                        <input matInput [matDatepicker]="picker" (dateChange)="updateReconsilationReport($event,item)"
                          [value]="item['cheq_clearing_date']">
                        <mat-datepicker #picker></mat-datepicker>
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                      </mat-form-field>
                    </span>
                    <span *ngIf="item['cheq_clearing_date'] !== '0000-00-00'">{{
                      item['cheq_clearing_date'] |
                      date:"dd/MM/yyyy" }}</span>
                  </span>
                  <span *ngIf="item['id'] == clearUpdateId">
                    <mat-form-field style="width: 80%;">
                      <mat-label>Select a date update</mat-label>
                      <input matInput [matDatepicker]="picker" (dateChange)="updateReconsilationReport($event,item)"
                        [value]="item['cheq_clearing_date']">
                      <mat-datepicker #picker></mat-datepicker>
                      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    </mat-form-field>
                  </span>
                </td>
                <td>{{ item['deposit_amt'] }}</td>
                <td>
                  <span *ngIf="item['cheq_clearing_date'] !== '0000-00-00'">
                    <button class="btn-sm btn-outline-info"
                      (click)="updateClearingDate(item)"><mat-icon>edit</mat-icon></button>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </mat-card>
    </mat-tab>

    <mat-tab label="Credit Voucher Report">
      <div class="add-new-subscriber mat-elevation-z4">
        <div style="margin-top: 20px" class="tableFixHead1">
          <table class="table table-bordered border-primary text-center">
            <thead>
              <tr>
                <th colspan="2">Date</th>
                <th rowspan="2">Voucher No.</th>
                <th rowspan="2">Representative Name</th>
                <th rowspan="2">Deposit Amount</th>
                <th rowspan="2">Payment Options</th>
                <!-- <th rowspan="2">Cash</th> -->
                <th rowspan="2">Cheque No.</th>
                <th rowspan="2">Cheque Date</th>
                <th colspan="3">Our Bank Name</th>
                <th rowspan="2">Subscription</th>
                <th rowspan="2">Issue Sale</th>
                <th rowspan="2">Advertisement</th>
                <th rowspan="2">Deposits</th>
                <th rowspan="2">Granth Book</th>
                <th rowspan="2">Package</th>
                <th rowspan="2">Narration</th>
                <th rowspan="2">Donation</th>
                <th rowspan="2">Other Deposit</th>
                <th rowspan="2">Total Income</th>
                <!-- <th colspan="6">Expenditure</th> -->
                <th rowspan="2">Total Expenditure</th>
                <th rowspan="2">Net Deposit</th>
              </tr>
              <tr>
                <th>Entry</th>
                <th>Voucher</th>
                <th>Janata</th>
                <th>Bank of Maharashtra</th>
                <th>Jankalyan</th>
                <!-- <th>travels</th>
                <th>Other</th> -->
                <!-- <th>Sub. Commission</th>
                <th>Issue Sale Commission</th>
                <th>Advt. Commission</th>
                <th>Granth Book Commission</th> -->
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="
                  let book of this.allCreditVoucherListData;
                  let i = index
                ">
                <td>{{ book["entry_date"] | date:"dd-MM-yyyy" }}</td>
                <td>{{ book["voucher_date"] | date:"dd-MM-yyyy" }}</td>
                <td>{{ book["voucher_no"] }}</td>
                <td>{{ book["rep_name"] }}</td>
                <td>{{ book["deposit_amt"] }}</td>
                <td>{{ book["pymt_options"] }}</td>
                <td>
                  <span *ngIf="book['cheque_no'] == 0">-</span>
                  <span *ngIf="book['cheque_no'] != 0">{{ book["cheque_no"] }}</span>
                </td>
                <td><span *ngIf="book['cheque_no'] == 0">-</span>
                  <span *ngIf="book['cheque_no'] != 0">{{ book["cheque_date"] }}</span>
                </td>
                <td>
                  <span *ngIf="book['bank'] === ''">-</span>
                  <span *ngIf="book['bank'] !== ''">{{ book["bank"] }}</span>
                </td>
                <td><span *ngIf="book['bank'] === ''">-</span>
                  <span *ngIf="book['bank'] !== ''">{{ book["bank"] }}</span>
                </td>
                <td><span *ngIf="book['bank'] === ''">-</span>
                  <span *ngIf="book['bank'] !== ''">{{ book["bank"] }}</span>
                </td>
                <td>{{ book["subscription"] }}
                </td>
                <td>{{ book["issuesale"] }}
                </td>
                <td>{{ book['advertiesment']}}</td>
                <td>{{ book["deposits"] }}</td>
                <td>{{ book["book"] }}</td>
                <td>{{ book["packageamount"] }}</td>
                <td></td>

                <td>{{ book["donation"] }}</td>
                <td>{{ book["other_deposit"] }}</td>
                <td>{{book['total']}}</td>
                <!-- <td>{{ book["travels"] }}</td>
                <td>{{ book["other"] }}</td>
                <td>{{ book["sub_commission"] }}</td>
                <td>{{ book["issue_commission"] }}</td>
                <td>{{ book["advt_commission"] }}</td>
                <td>{{ book["book_commission"] }}</td> -->
                <td>{{ book["totalexp"] }}</td>
                <td>{{ book["net_deposit"] }}</td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td colspan="4"></td>
                <td>{{this.totalcrdtvreport['depositamt']}}</td>
                <td colspan="6"></td>
                <td>{{ this.totalcrdtvreport['sub']}}</td>
                <td>{{ this.totalcrdtvreport['issue'] }}</td>
                <td>{{ this.totalcrdtvreport['advt'] }}</td>
                <td>{{ this.totalcrdtvreport['deposit'] }}</td>
                <td>{{ this.totalcrdtvreport['book'] }}</td>
                <td>{{ this.totalcrdtvreport['pkg_amt'] }}</td>
                <td>{{ this.totalcrdtvreport['donation'] }}</td>
                <td>{{ this.totalcrdtvreport['other_deposit'] }}</td>
                <td>
                  {{ this.totalcrdtvreport['total'] }}
                </td>
                <!-- <td>{{ this.totalcrdtvreport['travel'] }}</td>
                <td>{{ this.totalcrdtvreport['other'] }}</td>
                <td>{{ this.totalcrdtvreport['sub_com'] }}</td>
                <td>{{ this.totalcrdtvreport['is_com'] }}</td>
                <td>{{ this.totalcrdtvreport['advt_com'] }}</td>
                <td>{{ this.totalcrdtvreport['gb_com'] }}</td> -->
                <td>{{ this.totalcrdtvreport['total_exp'] }}</td>
                <td>{{ this.totalcrdtvreport['net_deposit'] }}</td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </mat-tab>

    <mat-tab label="Package Manager">
      <mat-card class="credit_voucher">
        <mat-card-title style="text-align: center; margin-bottom: 2%; margin-top: 1%">Add Package</mat-card-title>
        <form [formGroup]="pkgForm">
          <div class="form-row">
            <!-- (ngSubmit)="AddPackage()" <mat-form-field appearance="outline" style="margin-left: 5%;width: 40%;">
              <mat-label>Package Name</mat-label>
              <input matInput type="text" formControlName="pkgname" required>
            </mat-form-field> -->

            <mat-form-field appearance="outline" style="margin-left: 5%; width: 40%">
              <mat-label>Package Name</mat-label>
              <input matInput formControlName="pkgname" [matAutocomplete]="auto_pkgname" required />
              <mat-autocomplete autoActiveFirstOption #auto_pkgname="matAutocomplete">
                <mat-option (onSelectionChange)="getpkgname($event, option)"
                  *ngFor="let option of filteredOptions2 | async" [value]="option['pkgname']">
                  {{ option["pkgname"] }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>

            <mat-form-field appearance="outline" style="margin-left: 2%; width: 40%">
              <mat-label>Select Package Validity</mat-label>
              <mat-date-range-input [rangePicker]="pickerpkgv">
                <input matStartDate placeholder="Start date" formControlName="start" required />
                <input matEndDate placeholder="End date" formControlName="end" required
                  (dateChange)="getChangedDatepkgv()" />
              </mat-date-range-input>
              <mat-datepicker-toggle matSuffix [for]="pickerpkgv"></mat-datepicker-toggle>
              <mat-date-range-picker #pickerpkgv></mat-date-range-picker>
            </mat-form-field>
          </div>
          <div class="form-row">
            <mat-form-field appearance="outline" style="margin-left: 5%; width: 40%">
              <mat-label>Package Amount</mat-label>
              <input matInput pattern="^(\d*\.)?\d+$" formControlName="pkgamt" required />
            </mat-form-field>

            <mat-form-field appearance="outline" style="margin-left: 2%; width: 40%">
              <mat-label>Select Periodicity</mat-label>
              <mat-select formControlName="periodicity" (selectionChange)="getPeriodicity($event)" multiple>
                <mat-option value="1 year">1 Year </mat-option>
                <mat-option value="3 year">3 Year</mat-option>
                <mat-option value="5 year">5 Year</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <!-- <div class="form-row">
            <mat-form-field appearance="outline" style="margin-left: 5%; width: 40%">
              <mat-label>Select Periodicity</mat-label>
              <mat-select formControlName="periodicity" (selectionChange)="getPeriodicity($event)" multiple>
                <mat-option value="1 year">1 Year </mat-option>
                <mat-option value="3 year">3 Year</mat-option>
                <mat-option value="5 year">5 Year</mat-option>
              </mat-select>
            </mat-form-field>
          </div> -->

          <div *ngIf="show1year" style="
              margin: 0% 4% 2% 4%;
              border: 1px solid;
              padding-bottom: 10px;
              background-color: rgba(247, 244, 244, 0.4);
            ">
            <div class="form-row">
              <h2 style="margin-left: 7%; margin-top: 1%">1 Year</h2>
            </div>
            <div class="form-row">
              <mat-form-field appearance="outline" style="margin-left: 2%; width: 45%">
                <mat-label>Subscription Actual Rate</mat-label>
                <input matInput pattern="^(\d*\.)?\d+$" formControlName="vivekm1actualamt"
                  (change)="getActualAmt1($event)" />
              </mat-form-field>
              <mat-form-field appearance="outline" style="margin-left: 2%; width: 45%">
                <mat-label>Subscription Concession Rate</mat-label>
                <input matInput pattern="^(\d*\.)?\d+$" formControlName="vivekm1concessionamt"
                  (change)="getConcessionAmt1($event)" />
              </mat-form-field>
            </div>

            <div class="form-row">
              <mat-form-field appearance="outline" style="margin-left: 2%; width: 45%">
                <mat-label>Vivek (H) Actual Rate</mat-label>
                <input matInput pattern="^(\d*\.)?\d+$" formControlName="vivekh1actualamt"
                  (change)="getActualAmt1($event)" />
              </mat-form-field>
              <mat-form-field appearance="outline" style="margin-left: 2%; width: 45%">
                <mat-label>Vivek (H) Concession Rate</mat-label>
                <input matInput pattern="^(\d*\.)?\d+$" formControlName="vivekh1concessionamt"
                  (change)="getConcessionAmt1($event)" />
              </mat-form-field>
            </div>

            <div class="form-row">
              <mat-form-field appearance="outline" style="margin-left: 2%; width: 45%">
                <mat-label>Vaidyraj Actual Rate</mat-label>
                <input matInput pattern="^(\d*\.)?\d+$" formControlName="vaidyraj1actualamt"
                  (change)="getActualAmt1($event)" />
              </mat-form-field>
              <mat-form-field appearance="outline" style="margin-left: 2%; width: 45%">
                <mat-label>Vaidyraj Concession Rate</mat-label>
                <input matInput pattern="^(\d*\.)?\d+$" formControlName="vaidyraj1concessionamt"
                  (change)="getConcessionAmt1($event)" />
              </mat-form-field>
            </div>

            <div class="form-row">
              <mat-form-field appearance="outline" style="margin-left: 2%; width: 45%">
                <mat-label>Granth 1 Actual Rate</mat-label>
                <input matInput pattern="^(\d*\.)?\d+$" formControlName="granth11actualamt"
                  (change)="getActualAmt1($event)" />
              </mat-form-field>
              <mat-form-field appearance="outline" style="margin-left: 2%; width: 45%">
                <mat-label>Granth 1 Concession Rate</mat-label>
                <input matInput pattern="^(\d*\.)?\d+$" formControlName="granth11concessionamt"
                  (change)="getConcessionAmt1($event)" />
              </mat-form-field>
            </div>

            <div class="form-row">
              <mat-form-field appearance="outline" style="margin-left: 2%; width: 45%">
                <mat-label>Books Actual Rate</mat-label>
                <input matInput pattern="^(\d*\.)?\d+$" formControlName="granth21actualamt"
                  (change)="getActualAmt1($event)" />
              </mat-form-field>
              <mat-form-field appearance="outline" style="margin-left: 2%; width: 45%">
                <mat-label>Books Concession Rate</mat-label>
                <input matInput pattern="^(\d*\.)?\d+$" formControlName="granth21concessionamt"
                  (change)="getConcessionAmt1($event)" />
              </mat-form-field>
            </div>

            <div class="form-row">
              <mat-form-field appearance="outline" style="margin-left: 2%; width: 45%">
                <mat-label>Donation Actual Rate</mat-label>
                <input matInput pattern="^(\d*\.)?\d+$" formControlName="donation1actualamt"
                  (change)="getActualAmt1($event)" />
              </mat-form-field>
              <mat-form-field appearance="outline" style="margin-left: 2%; width: 45%">
                <mat-label>Donation Concession Rate</mat-label>
                <input matInput pattern="^(\d*\.)?\d+$" formControlName="donation1concessionamt"
                  (change)="getConcessionAmt1($event)" />
              </mat-form-field>
            </div>

            <div class="form-row">
              <mat-form-field appearance="outline" style="margin-left: 2%; width: 45%">
                <mat-label>Advt(spl) Actual Rate</mat-label>
                <input matInput pattern="^(\d*\.)?\d+$" formControlName="advt1actualamt"
                  (change)="getActualAmt1($event)" />
              </mat-form-field>
              <mat-form-field appearance="outline" style="margin-left: 2%; width: 45%">
                <mat-label>Advt(spl) Concession Rate</mat-label>
                <input matInput pattern="^(\d*\.)?\d+$" formControlName="advt1concessionamt"
                  (change)="getConcessionAmt1($event)" />
              </mat-form-field>
            </div>

            <div class="form-row">
              <mat-form-field appearance="outline" style="margin-left: 2%; width: 45%">
                <mat-label>Courier Actual Rate</mat-label>
                <input matInput pattern="^(\d*\.)?\d+$" formControlName="courier1actualamt"
                  (change)="getActualAmt1($event)" />
              </mat-form-field>
              <mat-form-field appearance="outline" style="margin-left: 2%; width: 45%">
                <mat-label>Courier Concession Rate</mat-label>
                <input matInput pattern="^(\d*\.)?\d+$" formControlName="courier1concessionamt"
                  (change)="getConcessionAmt1($event)" />
              </mat-form-field>
            </div>

            <div class="form-row">
              <mat-form-field appearance="outline" style="margin-left: 2%; width: 45%">
                <mat-label>Other Actual Rate</mat-label>
                <input matInput pattern="^(\d*\.)?\d+$" formControlName="other1actualamt"
                  (change)="getActualAmt1($event)" />
              </mat-form-field>
              <mat-form-field appearance="outline" style="margin-left: 2%; width: 45%">
                <mat-label>Other Concession Rate</mat-label>
                <input matInput pattern="^(\d*\.)?\d+$" formControlName="other1concessionamt"
                  (change)="getConcessionAmt1($event)" />
              </mat-form-field>
            </div>

            <div class="form-row">
              <span style="margin-left: 2%; font-weight: 600; font-size: 17px">Total Actual Amount: {{ actualamt1total
                }}/-</span>
              <span style="margin-left: 20%; font-weight: 600; font-size: 17px">Total Concession Amount: {{
                concessionamt1total }}/-</span>
            </div>
          </div>

          <div *ngIf="show3year" style="
              margin: 0% 4% 2% 4%;
              border: 1px solid;
              padding-bottom: 10px;
              background-color: rgba(247, 244, 244, 0.4);
            ">
            <div class="form-row">
              <h2 style="margin-left: 7%; margin-top: 1%">3 Year</h2>
            </div>
            <div class="form-row">
              <mat-form-field appearance="outline" style="margin-left: 2%; width: 45%">
                <mat-label>Subscription Actual Rate</mat-label>
                <input matInput pattern="^(\d*\.)?\d+$" formControlName="vivekm3actualamt"
                  (change)="getActualAmt3($event)" />
              </mat-form-field>
              <mat-form-field appearance="outline" style="margin-left: 2%; width: 45%">
                <mat-label>Subscription Concession Rate</mat-label>
                <input matInput pattern="^(\d*\.)?\d+$" formControlName="vivekm3concessionamt"
                  (change)="getConcessionAmt3($event)" />
              </mat-form-field>
            </div>

            <div class="form-row">
              <mat-form-field appearance="outline" style="margin-left: 2%; width: 45%">
                <mat-label>Vivek (H) Actual Rate</mat-label>
                <input matInput pattern="^(\d*\.)?\d+$" formControlName="vivekh3actualamt"
                  (change)="getActualAmt3($event)" />
              </mat-form-field>
              <mat-form-field appearance="outline" style="margin-left: 2%; width: 45%">
                <mat-label>Vivek (H) Concession Rate</mat-label>
                <input matInput pattern="^(\d*\.)?\d+$" formControlName="vivekh3concessionamt"
                  (change)="getConcessionAmt3($event)" />
              </mat-form-field>
            </div>

            <div class="form-row">
              <mat-form-field appearance="outline" style="margin-left: 2%; width: 45%">
                <mat-label>Vaidyraj Actual Rate</mat-label>
                <input matInput pattern="^(\d*\.)?\d+$" formControlName="vaidyraj3actualamt"
                  (change)="getActualAmt3($event)" />
              </mat-form-field>
              <mat-form-field appearance="outline" style="margin-left: 2%; width: 45%">
                <mat-label>Vaidyraj Concession Rate</mat-label>
                <input matInput pattern="^(\d*\.)?\d+$" formControlName="vaidyraj3concessionamt"
                  (change)="getConcessionAmt3($event)" />
              </mat-form-field>
            </div>

            <div class="form-row">
              <mat-form-field appearance="outline" style="margin-left: 2%; width: 45%">
                <mat-label>Granth 1 Actual Rate</mat-label>
                <input matInput pattern="^(\d*\.)?\d+$" formControlName="granth13actualamt"
                  (change)="getActualAmt3($event)" />
              </mat-form-field>
              <mat-form-field appearance="outline" style="margin-left: 2%; width: 45%">
                <mat-label>Granth 1 Concession Rate</mat-label>
                <input matInput pattern="^(\d*\.)?\d+$" formControlName="granth13concessionamt"
                  (change)="getConcessionAmt3($event)" />
              </mat-form-field>
            </div>

            <div class="form-row">
              <mat-form-field appearance="outline" style="margin-left: 2%; width: 45%">
                <mat-label>Books Actual Rate</mat-label>
                <input matInput pattern="^(\d*\.)?\d+$" formControlName="granth23actualamt"
                  (change)="getActualAmt3($event)" />
              </mat-form-field>
              <mat-form-field appearance="outline" style="margin-left: 2%; width: 45%">
                <mat-label>Books Concession Rate</mat-label>
                <input matInput pattern="^(\d*\.)?\d+$" formControlName="granth23concessionamt"
                  (change)="getConcessionAmt3($event)" />
              </mat-form-field>
            </div>

            <div class="form-row">
              <mat-form-field appearance="outline" style="margin-left: 2%; width: 45%">
                <mat-label>Donation Actual Rate</mat-label>
                <input matInput pattern="^(\d*\.)?\d+$" formControlName="donation3actualamt"
                  (change)="getActualAmt3($event)" />
              </mat-form-field>
              <mat-form-field appearance="outline" style="margin-left: 2%; width: 45%">
                <mat-label>Donation Concession Rate</mat-label>
                <input matInput pattern="^(\d*\.)?\d+$" formControlName="donation3concessionamt"
                  (change)="getConcessionAmt3($event)" />
              </mat-form-field>
            </div>

            <div class="form-row">
              <mat-form-field appearance="outline" style="margin-left: 2%; width: 45%">
                <mat-label>Advt(spl) Actual Rate</mat-label>
                <input matInput pattern="^(\d*\.)?\d+$" formControlName="advt3actualamt"
                  (change)="getActualAmt3($event)" />
              </mat-form-field>
              <mat-form-field appearance="outline" style="margin-left: 2%; width: 45%">
                <mat-label>Advt(spl) Concession Rate</mat-label>
                <input matInput pattern="^(\d*\.)?\d+$" formControlName="advt3concessionamt"
                  (change)="getConcessionAmt3($event)" />
              </mat-form-field>
            </div>

            <div class="form-row">
              <mat-form-field appearance="outline" style="margin-left: 2%; width: 45%">
                <mat-label>Courier Actual Rate</mat-label>
                <input matInput pattern="^(\d*\.)?\d+$" formControlName="courier3actualamt"
                  (change)="getActualAmt3($event)" />
              </mat-form-field>
              <mat-form-field appearance="outline" style="margin-left: 2%; width: 45%">
                <mat-label>Courier Concession Rate</mat-label>
                <input matInput pattern="^(\d*\.)?\d+$" formControlName="courier3concessionamt"
                  (change)="getConcessionAmt3($event)" />
              </mat-form-field>
            </div>

            <div class="form-row">
              <mat-form-field appearance="outline" style="margin-left: 2%; width: 45%">
                <mat-label>Other Actual Rate</mat-label>
                <input matInput pattern="^(\d*\.)?\d+$" formControlName="other3actualamt"
                  (change)="getActualAmt3($event)" />
              </mat-form-field>
              <mat-form-field appearance="outline" style="margin-left: 2%; width: 45%">
                <mat-label>Other Concession Rate</mat-label>
                <input matInput pattern="^(\d*\.)?\d+$" formControlName="other3concessionamt"
                  (change)="getConcessionAmt3($event)" />
              </mat-form-field>
            </div>

            <div class="form-row">
              <span style="margin-left: 2%; font-weight: 600; font-size: 17px">Total Actual Amount: {{ actualamt3total
                }}/-</span>
              <span style="margin-left: 20%; font-weight: 600; font-size: 17px">Total Concession Amount: {{
                concessionamt3total }}/-</span>
            </div>
          </div>

          <div *ngIf="show5year" style="
              margin: 0% 4% 2% 4%;
              border: 1px solid;
              padding-bottom: 10px;
              background-color: rgba(247, 244, 244, 0.4);
            ">
            <div class="form-row">
              <h2 style="margin-left: 7%; margin-top: 1%">5 Year</h2>
            </div>
            <div class="form-row">
              <mat-form-field appearance="outline" style="margin-left: 2%; width: 45%">
                <mat-label>Subscription Actual Rate</mat-label>
                <input matInput pattern="^(\d*\.)?\d+$" formControlName="vivekm5actualamt"
                  (change)="getActualAmt5($event)" />
              </mat-form-field>
              <mat-form-field appearance="outline" style="margin-left: 2%; width: 45%">
                <mat-label>Subscription Concession Rate</mat-label>
                <input matInput pattern="^(\d*\.)?\d+$" formControlName="vivekm5concessionamt"
                  (change)="getConcessionAmt5($event)" />
              </mat-form-field>
            </div>

            <div class="form-row">
              <mat-form-field appearance="outline" style="margin-left: 2%; width: 45%">
                <mat-label>Vivek (H) Actual Rate</mat-label>
                <input matInput pattern="^(\d*\.)?\d+$" formControlName="vivekh5actualamt"
                  (change)="getActualAmt5($event)" />
              </mat-form-field>
              <mat-form-field appearance="outline" style="margin-left: 2%; width: 45%">
                <mat-label>Vivek (H) Concession Rate</mat-label>
                <input matInput pattern="^(\d*\.)?\d+$" formControlName="vivekh5concessionamt"
                  (change)="getConcessionAmt5($event)" />
              </mat-form-field>
            </div>

            <div class="form-row">
              <mat-form-field appearance="outline" style="margin-left: 2%; width: 45%">
                <mat-label>Vaidyraj Actual Rate</mat-label>
                <input matInput pattern="^(\d*\.)?\d+$" formControlName="vaidyraj5actualamt"
                  (change)="getActualAmt5($event)" />
              </mat-form-field>
              <mat-form-field appearance="outline" style="margin-left: 2%; width: 45%">
                <mat-label>Vaidyraj Concession Rate</mat-label>
                <input matInput pattern="^(\d*\.)?\d+$" formControlName="vaidyraj5concessionamt"
                  (change)="getConcessionAmt5($event)" />
              </mat-form-field>
            </div>

            <div class="form-row">
              <mat-form-field appearance="outline" style="margin-left: 2%; width: 45%">
                <mat-label>Granth 1 Actual Rate</mat-label>
                <input matInput pattern="^(\d*\.)?\d+$" formControlName="granth15actualamt"
                  (change)="getActualAmt5($event)" />
              </mat-form-field>
              <mat-form-field appearance="outline" style="margin-left: 2%; width: 45%">
                <mat-label>Granth 1 Concession Rate</mat-label>
                <input matInput pattern="^(\d*\.)?\d+$" formControlName="granth15concessionamt"
                  (change)="getConcessionAmt5($event)" />
              </mat-form-field>
            </div>

            <div class="form-row">
              <mat-form-field appearance="outline" style="margin-left: 2%; width: 45%">
                <mat-label>Books Actual Rate</mat-label>
                <input matInput pattern="^(\d*\.)?\d+$" formControlName="granth25actualamt"
                  (change)="getActualAmt5($event)" />
              </mat-form-field>
              <mat-form-field appearance="outline" style="margin-left: 2%; width: 45%">
                <mat-label>Books Concession Rate</mat-label>
                <input matInput pattern="^(\d*\.)?\d+$" formControlName="granth25concessionamt"
                  (change)="getConcessionAmt5($event)" />
              </mat-form-field>
            </div>

            <div class="form-row">
              <mat-form-field appearance="outline" style="margin-left: 2%; width: 45%">
                <mat-label>Donation Actual Rate</mat-label>
                <input matInput pattern="^(\d*\.)?\d+$" formControlName="donation5actualamt"
                  (change)="getActualAmt5($event)" />
              </mat-form-field>
              <mat-form-field appearance="outline" style="margin-left: 2%; width: 45%">
                <mat-label>Donation Concession Rate</mat-label>
                <input matInput pattern="^(\d*\.)?\d+$" formControlName="donation5concessionamt"
                  (change)="getConcessionAmt5($event)" />
              </mat-form-field>
            </div>

            <div class="form-row">
              <mat-form-field appearance="outline" style="margin-left: 2%; width: 45%">
                <mat-label>Advt(spl) Actual Rate</mat-label>
                <input matInput pattern="^(\d*\.)?\d+$" formControlName="advt5actualamt"
                  (change)="getActualAmt5($event)" />
              </mat-form-field>
              <mat-form-field appearance="outline" style="margin-left: 2%; width: 45%">
                <mat-label>Advt(spl) Concession Rate</mat-label>
                <input matInput pattern="^(\d*\.)?\d+$" formControlName="advt5concessionamt"
                  (change)="getConcessionAmt5($event)" />
              </mat-form-field>
            </div>

            <div class="form-row">
              <mat-form-field appearance="outline" style="margin-left: 2%; width: 45%">
                <mat-label>Courier Actual Rate</mat-label>
                <input matInput pattern="^(\d*\.)?\d+$" formControlName="courier5actualamt"
                  (change)="getActualAmt5($event)" />
              </mat-form-field>
              <mat-form-field appearance="outline" style="margin-left: 2%; width: 45%">
                <mat-label>Courier Concession Rate</mat-label>
                <input matInput pattern="^(\d*\.)?\d+$" formControlName="courier5concessionamt"
                  (change)="getConcessionAmt5($event)" />
              </mat-form-field>
            </div>

            <div class="form-row">
              <mat-form-field appearance="outline" style="margin-left: 2%; width: 45%">
                <mat-label>Other Actual Rate</mat-label>
                <input matInput pattern="^(\d*\.)?\d+$" formControlName="other5actualamt"
                  (change)="getActualAmt5($event)" />
              </mat-form-field>
              <mat-form-field appearance="outline" style="margin-left: 2%; width: 45%">
                <mat-label>Other Concession Rate</mat-label>
                <input matInput pattern="^(\d*\.)?\d+$" formControlName="other5concessionamt"
                  (change)="getConcessionAmt5($event)" />
              </mat-form-field>
            </div>

            <div class="form-row">
              <span style="margin-left: 2%; font-weight: 600; font-size: 17px">Total Actual Amount: {{ actualamt5total
                }}/-</span>
              <span style="margin-left: 20%; font-weight: 600; font-size: 17px">Total Concession Amount: {{
                concessionamt5total }}/-</span>
            </div>
          </div>

          <div class="form-group" *ngIf="!isUser">
            <button style="margin-left: 125px; width: 70%" type="button" [disabled]="pkgForm.invalid"
              (click)="AddPackage()" mat-raised-button color="primary">
              Add Package
            </button>
          </div>
          <div class="form-group" *ngIf="isUser">
            <button style="margin-left: 125px; width: 70%; float: none;" type="button" mat-raised-button
              (click)="updatePackage()" class="btn btn-info">
              Update Package
            </button>
          </div>
        </form>
      </mat-card>
    </mat-tab>

    <mat-tab label="Package Booking Register">
      <mat-card style="margin-top: 2%; border-radius: 15px; padding: 35px 0px;">
        <form [formGroup]="pkgBookingregisterForm">
          <div class="form-row">
            <mat-form-field appearance="outline" style="margin-left: 5%; width: 30%">
              <mat-label>Package Name</mat-label>
              <input matInput formControlName="pkg_name" [matAutocomplete]="autopkgbr" />
              <button *ngIf="pkg_name1" matSuffix mat-icon-button aria-label="Clear" (click)="reset_pkg_name()">
                <mat-icon>close</mat-icon>
              </button>
              <mat-autocomplete autoActiveFirstOption #autopkgbr="matAutocomplete">
                <mat-option (onSelectionChange)="
                    getPackageName($event, option['pkg_name'])
                  " *ngFor="let option of filteredOptions3 | async" [value]="option['pkg_name']">
                  {{ option["pkg_name"] }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>

            <mat-form-field appearance="outline" style="margin-left: 5%; width: 30%">
              <mat-label>Date</mat-label>
              <mat-date-range-input [rangePicker]="pickerpkgbr1">
                <input matStartDate placeholder="Start date" formControlName="start" />
                <input matEndDate placeholder="End date" formControlName="end" (dateChange)="getchangedDates1()" />
              </mat-date-range-input>
              <mat-datepicker-toggle matSuffix [for]="pickerpkgbr1"></mat-datepicker-toggle>
              <mat-date-range-picker #pickerpkgbr1></mat-date-range-picker>

              <mat-error *ngIf="
                  pkgBookingregisterForm.controls.start.hasError(
                    'matStartDateInvalid'
                  )
                ">
                Invalid Start Date
              </mat-error>
              <mat-error *ngIf="
                  pkgBookingregisterForm.controls.end.hasError(
                    'matEndDateInvalid'
                  )
                ">Invalid End Date
              </mat-error>
            </mat-form-field>
          </div>

          <div class="form-row">
            <mat-form-field appearance="outline" style="margin-left: 5%; width: 30%">
              <mat-label>Office Representative Name</mat-label>
              <mat-select #mySel formControlName="off_rep_name" (selectionChange)="getOffrepnames($event)" multiple>
                <mat-option value="all" (click)="toggleAllSelection()">All</mat-option>
                <mat-option *ngFor="let topping of pkgBookingoffrepnames" [value]="topping['off_rep_name']">
                  {{ topping["off_rep_name"] }}</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline" style="margin-left: 5%; width: 30%">
              <mat-label>Representative Name</mat-label>
              <mat-select #mySel1 formControlName="rep_name" (selectionChange)="getrepnames($event)" multiple>
                <mat-option value="all" (click)="toggleAllSelection1()">All</mat-option>
                <mat-option *ngFor="let topping of pkgBookingrepnames" [value]="topping['rep_name']">
                  {{ topping["rep_name"] }}</mat-option>
              </mat-select>
              <button *ngIf="rep_name1" matSuffix mat-icon-button aria-label="Clear" (click)="reset_rep_name()">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>

          <div class="form-row">
            <mat-form-field appearance="outline" style="margin-left: 5%; width: 30%">
              <mat-label>Customer Name</mat-label>
              <mat-select #mySel2 formControlName="cust_name" (selectionChange)="getcustnames($event)" multiple>
                <mat-option value="all" (click)="toggleAllSelection2()">All</mat-option>
                <mat-option *ngFor="let topping of pkgBookingcustnames" [value]="topping['cust_name']">
                  {{ topping["cust_name"] }}</mat-option>
              </mat-select>
              <button *ngIf="cust_name1" matSuffix mat-icon-button aria-label="Clear" (click)="reset_cust_name()">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
        </form>
        <div class="tableFixHead1">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th rowspan="2">अ. क्र.</th>
                <th rowspan="2">व्हावचर क्र.</th>
                <th rowspan="2">दिनांक</th>
                <th rowspan="2">ग्राहकाचे नाव</th>
                <th rowspan="2">ठिकाण</th>
                <th rowspan="2">प्रतिनिधीचे नाव</th>
                <th rowspan="2">कार्यालयीन प्रतिनिधीचे नाव</th>
                <th rowspan="2">सा. विवेक</th>
                <th rowspan="2">हिंदी विवेक</th>
                <th rowspan="2">वैद्यराज</th>
                <th rowspan="2">ग्रंथ</th>
                <th rowspan="2">पुस्तक</th>
                <th rowspan="2">देणगी</th>
                <th rowspan="2">जाहिरात</th>
                <th rowspan="2">कुरिअर खर्च</th>
                <th rowspan="2">अन्य</th>
                <th rowspan="2">एकूण रु</th>
                <th colspan="5">जमा तपशिल</th>
                <th>Subscription</th>
                <th rowspan="2">Subscriber Name</th>
                <th rowspan="2">नवीन</th>
                <th rowspan="2">नूतनीकरण</th>
                <th colspan="2">पाठवण्याचा - तपशिल</th>
                <th colspan="7">पाठवण्याचे माध्यम</th>
              </tr>
              <tr>
                <th>कॅश</th>
                <th>चेक नं.</th>
                <th>दिनांक</th>
                <th>ट्रान्सफर</th>
                <th>ऑनलाईन</th>
                <th>Receipt No</th>
                <th>ग्राहक</th>
                <th>प्रतिनिधी</th>
                <th>ट्रान्सपोर्ट</th>
                <th>एस टी</th>
                <th>रेल्वे</th>
                <th>टेम्पो /टॅक्सी</th>
                <th>कुरिअर</th>
                <th>पोस्ट</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of FilteredpkgBookingData; let i = index">
                <td>{{ i + 1 }}</td>
                <td>{{ item["voucher_no"] }}</td>
                <td>{{ item["voucher_date"] }}</td>
                <td>{{ item["client_name"] }}</td>
                <td></td>
                <td>{{ item["rep_name"] }}</td>
                <td></td>
                <td>{{ item["subscription_actual_amt"] }}</td>
                <td>{{ item["vivek_h_actual_amt"] }}</td>
                <td>{{ item["vaidyraj_actual_amt"] }}</td>
                <td>{{ item["granth_actual_amt"] }}</td>
                <td>{{ item["book_actual_amt"] }}</td>
                <td>{{ item["donation_actual_amt"] }}</td>
                <td>{{ item["advt_actual_amt"] }}</td>
                <td>{{ item["courier_actual_amt"] }}</td>
                <td>{{ item["other_actual_amt"] }}</td>
                <td>{{ item["pkg_price"] }}</td>
                <td>
                  <span *ngIf="item['pymt_options'] == 'cash'">{{
                    item["pkg_price"]
                    }}</span>
                </td>
                <td>
                  <span *ngIf="item['pymt_options'] == 'cheque'">{{
                    item["cheque_no"]
                    }}</span>
                </td>
                <td>
                  <span *ngIf="item['pymt_options'] == 'cheque'">{{
                    item["cheque_date"]
                    }}</span>
                </td>
                <td>{{ item["pymt_options"] }}</td>
                <td></td>
                <td>{{ item["rcpt_no"] }}</td>
                <td>{{ item["sub_name"] }}</td>
                <td>
                  <span *ngIf="item['rcpt_type'] == 'New'">{{
                    item["rcpt_type"]
                    }}</span>
                </td>
                <td>
                  <span *ngIf="item['rcpt_type'] == 'Renew'">{{
                    item["rcpt_type"]
                    }}</span>
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      </mat-card>
    </mat-tab>

    <mat-tab label="Expense Master">
      <mat-card class="credit_voucher">
        <form [formGroup]="ExpenseLedgerForm">
          <div class="form-group">
            <mat-form-field style="margin-left: 7%; width: 25%" class="example-full-width" appearance="outline">
              <mat-label>Main Expense Category Name :</mat-label>
              <input type="text" formControlName="expensename" matInput required />
              <mat-error *ngIf="ExpenseLedgerForm.controls.expensename.invalid">Please enter valid name</mat-error>
            </mat-form-field>

            <mat-form-field style="margin-left: 7%; width: 20%" class="example-full-width" appearance="outline">
              <mat-label>Select Main Category</mat-label>
              <mat-select formControlName="exptype">
                <!-- (selectionChange)="getMainExpName($event)" -->
                <mat-option value="Assent">Asset</mat-option>
                <mat-option value="Expenditure">Expenditure</mat-option>
                <mat-option value="Income">Income</mat-option>
                <mat-option value="Liabilities">Liabilities</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field style="margin-left: 7%; width: 25%" class="example-full-width" appearance="outline">
              <mat-label>Opening Balance :</mat-label>
              <input pattern="^(\d*\.)?\d+$" formControlName="openingbal" matInput />
              <mat-error *ngIf="ExpenseLedgerForm.controls.openingbal.invalid">Please enter numbers only</mat-error>
            </mat-form-field>

            <mat-form-field style="margin-left: 7%; width: 20%" class="example-full-width" appearance="outline">
              <mat-label>Opening Yes/No</mat-label>
              <mat-select formControlName="opening_yes_no">
                <!-- (selectionChange)="getMainExpName($event)" -->
                <mat-option value="Yes">Yes</mat-option>
                <mat-option value="No">No</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field style="margin-left: 7%; width: 20%" class="example-full-width" appearance="outline">
              <mat-label>CR/DR</mat-label>
              <mat-select formControlName="crdr">
                <!-- (selectionChange)="getMainExpName($event)" -->
                <mat-option value="CR">CR</mat-option>
                <mat-option value="DR">DR</mat-option>
              </mat-select>
            </mat-form-field>

            <button *ngIf="!mainExpSelected" style="margin-left: 15%; width: 30%" type="button"
              [disabled]="ExpenseLedgerForm.invalid" mat-raised-button color="primary" (click)="save_ExpenseLedger()">
              Add Expense
            </button>
            <button *ngIf="mainExpSelected" style="margin-left: 15%; width: 30%" type="button"
              [disabled]="ExpenseLedgerForm.invalid" mat-raised-button color="primary" (click)="update_ExpenseLedger()">
              Update Expense
            </button>
          </div>

        </form>
        <div class="tableFixHead">
          <table class="table table-striped">
            <thead>
              <tr>
                <th>Sr. No</th>
                <th>Main Exp_name</th>
                <th>Action</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let exp of mainExpData; let i=index">
                <td>{{ i+1 }}</td>
                <td>{{ exp['exp_name'] }}</td>
                <td>
                  <button type="button" mat-raised-button class="btn-sm btn-outline-info" (click)="selectMainExp(exp)">
                    <mat-icon>edit</mat-icon>
                  </button>
                </td>
                <td>
                  <button type="button" mat-raised-button class="btn-sm btn-outline-info"
                    (click)="getDeleteMainExpense(exp)">
                    <mat-icon>delete</mat-icon>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </mat-card>
      <mat-card class="credit_voucher">
        <form [formGroup]="SubExpenseLedgerForm">
          <div class="form-row">
            <mat-form-field style="margin-left: 7%; width: 40%" class="example-full-width" appearance="outline">
              <mat-label>Select Main Expense</mat-label>
              <mat-select formControlName="expname" required>
                <!-- (selectionChange)="getMainExpName($event)" -->
                <mat-option *ngFor="let exp of mainExpData" (onSelectionChange)="checkvalue_yes_no(exp)"
                  value="{{ exp['id'] }}">{{ exp["exp_name"]
                  }}</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field style="margin-left: 5%; width: 40%" class="example-full-width" appearance="outline">
              <mat-label>Sub Expense Category Name :</mat-label>
              <input type="text" formControlName="subexpname" matInput required />
              <mat-error *ngIf="SubExpenseLedgerForm.controls.subexpname.invalid">Please enter valid name</mat-error>
            </mat-form-field>
          </div>

          <div class="form-row" *ngIf="opening_yes_no=='Yes'">
            <!-- <mat-form-field style="margin-left: 7%; width: 40%" class="example-full-width" appearance="outline">
              <mat-label>Bank Name :</mat-label>
              <input type="text" formControlName="bank_name" matInput required />
            </mat-form-field> -->
            <mat-form-field style="margin-left: 7%; width: 40%" class="example-full-width" appearance="outline">
              <mat-label>Bank Opening Balance :</mat-label>
              <!-- <input pattern="\d+" formControlName="bank_o_bal" placeholder="enter in capital letter" matInput
                required /> -->
              <input type="text" formControlName="bank_o_bal" placeholder="Enter a Number" matInput required
                pattern="[0-9]+(\.[0-9]+)?" />
              <mat-error *ngIf="bankForm.controls.bank_o_bal.invalid">Please enter numbers only</mat-error>
            </mat-form-field>
            <mat-form-field style="margin-left: 5%; width: 40%" class="example-full-width" appearance="outline">
              <mat-label>Bank Short Name :</mat-label>
              <input type="text" formControlName="bank_short_name" placeholder="enter in capital letter" matInput
                required />
            </mat-form-field>
          </div>

          <div class="form-row" *ngIf="opening_yes_no=='Yes'">
            <!-- <mat-form-field style="margin-left: 7%; width: 40%" class="example-full-width" appearance="outline">
              <mat-label>Bank Opening Balance :</mat-label>
              <input pattern="\d+" formControlName="bank_o_bal" placeholder="enter in capital letter" matInput
                required />
                <input type="number" formControlName="bank_o_bal" placeholder="Enter in Number" matInput required
                 pattern="[0-9]*" />
              <mat-error *ngIf="bankForm.controls.bank_o_bal.invalid">Please enter numbers only</mat-error>
            </mat-form-field> -->
            <mat-form-field style="margin-left: 7%;width: 40%" class="example-full-width" appearance="outline">
              <mat-label>Select Credit / Debit</mat-label>
              <mat-select formControlName="crdtdebt" required>
                <!-- (selectionChange)="getBankCash($event)" -->
                <mat-option value="Cr">Credit</mat-option>
                <mat-option value="Dr">Debit</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="form-group" style="margin-left: 35%;">
            <button *ngIf="!subExpSelected" style="margin-left: 5%; width: 30%;" type="button"
              [disabled]="SubExpenseLedgerForm.invalid" mat-raised-button color="primary"
              (click)="save_SubExpenseLedger()">
              Add Sub Expense
            </button>
            <button *ngIf="subExpSelected" style="margin-left: 5%; width: 30%" type="button"
              [disabled]="SubExpenseLedgerForm.invalid" mat-raised-button color="primary"
              (click)="update_SubExpenseLedger()">
              Update Sub Expense
            </button>
          </div>

        </form>
        <div class="tableFixHead">
          <table class="table table-striped">
            <thead>
              <tr>
                <th>Sr. No</th>
                <th>Sub Exp_name</th>
                <th>Opening Bal</th>
                <th>Short Name</th>
                <th>Type</th>
                <th>Action</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let exp of subExpData; let i=index">
                <td>{{ i+1 }}</td>
                <td>{{ exp['sub_exp_name'] }}</td>
                <td>{{ exp['opening_bal'] }}</td>
                <td>{{ exp['short_name'] }}</td>
                <td>{{ exp['status'] }}</td>
                <td>
                  <button type="button" mat-raised-button class="btn-sm btn-outline-info" (click)="selectSubExp(exp)">
                    <mat-icon>edit</mat-icon>
                  </button>
                </td>
                <td>
                  <button type="button" mat-raised-button class="btn-sm btn-outline-info"
                    (click)="getDeleteSubExpense(exp)">
                    <mat-icon>delete</mat-icon>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </mat-card>


    </mat-tab>

    <mat-tab label="Reprensantive Ledger">
      <mat-card class="credit_voucher">
        <form [formGroup]="repledger">
          <div class="form-group">
            <mat-form-field appearance="outline" style="margin-left: 5%; width: 30%">
              <mat-label>Date</mat-label>
              <mat-date-range-input [rangePicker]="repledgers">
                <input matStartDate placeholder="Start date" formControlName="start" />
                <input matEndDate placeholder="End date" formControlName="end"
                  (dateChange)="getchangedDatesrepledger()" />
              </mat-date-range-input>
              <!-- <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint> -->
              <mat-datepicker-toggle matSuffix [for]="repledgers"></mat-datepicker-toggle>
              <mat-date-range-picker #repledgers></mat-date-range-picker>
            </mat-form-field>

            <mat-form-field appearance="outline" style="margin-left: 5%; width: 30%">
              <mat-label>Representative Name</mat-label>
              <mat-select #mySel formControlName="off_rep_name" (selectionChange)="getrepnames1($event)" multiple>
                <mat-option value="all" (click)="toggleAllSelection()">All</mat-option>
                <mat-option *ngFor="let topping of issue_seller_name" [value]="topping['name']">
                  {{ topping["name"] }}</mat-option>
              </mat-select>
            </mat-form-field>

            <button style="margin-left: 5%; width: 10%;" type="button" mat-raised-button color="primary"
              (click)="getrepledger()">Fetch data</button>

          </div>

        </form>

      </mat-card>
      <mat-card class="credit_voucher">
        <form [formGroup]="SubExpenseLedgerForm">
          <div class="form-row">
            <mat-form-field style="margin-left: 7%; width: 40%" class="example-full-width" appearance="outline">
              <mat-label>Select Main Expense</mat-label>
              <mat-select formControlName="expname" required>
                <!-- (selectionChange)="getMainExpName($event)" -->
                <mat-option *ngFor="let exp of mainExpData" value="{{ exp['id'] }}">{{ exp["exp_name"]
                  }}</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field style="margin-left: 5%; width: 40%" class="example-full-width" appearance="outline">
              <mat-label>Sub Expense Category Name :</mat-label>
              <input type="text" formControlName="subexpname" matInput required />
              <mat-error *ngIf="SubExpenseLedgerForm.controls.subexpname.invalid">Please enter valid name</mat-error>
            </mat-form-field>
          </div>

          <div class="form-row" *ngIf="SubExpenseLedgerForm.value.expname==28">
            <!-- <mat-form-field style="margin-left: 7%; width: 40%" class="example-full-width" appearance="outline">
              <mat-label>Bank Name :</mat-label>
              <input type="text" formControlName="bank_name" matInput required />
            </mat-form-field> -->
            <mat-form-field style="margin-left: 7%; width: 40%" class="example-full-width" appearance="outline">
              <mat-label>Bank Opening Balance :</mat-label>
              <!-- <input pattern="\d+" formControlName="bank_o_bal" placeholder="enter in capital letter" matInput
                required /> -->
              <input type="text" formControlName="bank_o_bal" placeholder="Enter a Number" matInput required
                pattern="[0-9]+(\.[0-9]+)?" />
              <mat-error *ngIf="bankForm.controls.bank_o_bal.invalid">Please enter numbers only</mat-error>
            </mat-form-field>
            <mat-form-field style="margin-left: 5%; width: 40%" class="example-full-width" appearance="outline">
              <mat-label>Bank Short Name :</mat-label>
              <input type="text" formControlName="bank_short_name" placeholder="enter in capital letter" matInput
                required />
            </mat-form-field>
          </div>

          <div class="form-row" *ngIf="SubExpenseLedgerForm.value.expname==28">
            <!-- <mat-form-field style="margin-left: 7%; width: 40%" class="example-full-width" appearance="outline">
              <mat-label>Bank Opening Balance :</mat-label>
              <input pattern="\d+" formControlName="bank_o_bal" placeholder="enter in capital letter" matInput
                required />
                <input type="number" formControlName="bank_o_bal" placeholder="Enter in Number" matInput required
                 pattern="[0-9]*" />
              <mat-error *ngIf="bankForm.controls.bank_o_bal.invalid">Please enter numbers only</mat-error>
            </mat-form-field> -->
            <mat-form-field style="margin-left: 7%;width: 40%" class="example-full-width" appearance="outline">
              <mat-label>Select Credit / Debit</mat-label>
              <mat-select formControlName="crdtdebt" required>
                <!-- (selectionChange)="getBankCash($event)" -->
                <mat-option value="Cr">Credit</mat-option>
                <mat-option value="Dr">Debit</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="form-group" style="margin-left: 35%;">
            <button *ngIf="!subExpSelected" style="margin-left: 5%; width: 30%;" type="button"
              [disabled]="SubExpenseLedgerForm.invalid" mat-raised-button color="primary"
              (click)="save_SubExpenseLedger()">
              Add Sub Expense
            </button>
            <button *ngIf="subExpSelected" style="margin-left: 5%; width: 30%" type="button"
              [disabled]="SubExpenseLedgerForm.invalid" mat-raised-button color="primary"
              (click)="update_SubExpenseLedger()">
              Update Sub Expense
            </button>
          </div>

        </form>
        <div class="tableFixHead">
          <table class="table table-striped">
            <thead>
              <tr>
                <th>Sr. No</th>
                <th>Sub Exp_name</th>
                <th>Opening Bal</th>
                <th>Short Name</th>
                <th>Type</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let exp of subExpData; let i=index">
                <td>{{ i+1 }}</td>
                <td>{{ exp['sub_exp_name'] }}</td>
                <td>{{ exp['opening_bal'] }}</td>
                <td>{{ exp['short_name'] }}</td>
                <td>{{ exp['status'] }}</td>
                <td>
                  <button type="button" mat-raised-button class="btn-sm btn-outline-info" (click)="selectSubExp(exp)">
                    <mat-icon>edit</mat-icon>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </mat-card>
    </mat-tab>

    <mat-tab label="Creditors">
      <mat-card class="credit_voucher">
        <form [formGroup]="kharedi">
          <div class="form-group">
            <mat-form-field style="margin-left: 2%; width: 25%" class="example-full-width" appearance="outline">
              <mat-label>Select Main Expense</mat-label>
              <mat-select formControlName="expnameother" [(ngModel)]="creditors"
                (selectionChange)="getMainExpName1($event)">
                <!-- (selectionChange)="getMainExpName($event)" -->
                <mat-option *ngFor="let exp of mainExpData1" value="{{ exp['id'] }}">{{ exp["exp_name"]
                  }}</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field style="margin-left: 2%; width: 25%" class="example-full-width" appearance="outline">
              <mat-label>Select Sub Expense</mat-label>
              <mat-select formControlName="subexpnameother"
                (selectionChange)="getSubExpName1($event); getnarration($event)">
                <!-- (selectionChange)="getMainExpName($event)" -->
                <mat-option *ngFor="let exp of getSubExpData1" value="{{ exp['sub_id'] }}">{{ exp["sub_exp_name"]
                  }}</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field style="margin-left: 2%; width: 25%" appearance="outline">
              <mat-label>Voucher date</mat-label>
              <input matInput type="date" style="height: 16px;" formControlName="voucherdate"
                (change)="generateCreditorsdate($event)" />
            </mat-form-field>

            <!-- <mat-form-field style="margin-left: 2%; width: 30%" class="example-full-width" appearance="outline">
              <mat-label>Creditors Number :</mat-label>
              <input type="text" formControlName="creditorsnum" matInput  />
            </mat-form-field> -->


          </div>

          <div class="form-group">
            <mat-form-field style="margin-left: 2%; width: 25%" class="example-full-width" appearance="outline">
              <mat-label>Creditors Number :</mat-label>
              <input type="text" formControlName="creditorsnum" matInput />
            </mat-form-field>

            <mat-form-field style="margin-left: 2%; width: 25%" class="example-full-width" appearance="outline">
              <mat-label>Total Amount :</mat-label>
              <input type="text" formControlName="totalamount" matInput />
            </mat-form-field>

            <mat-form-field style="margin-left: 2%; width: 25%" class="example-full-width" appearance="outline">
              <mat-label>Bill no :</mat-label>
              <input type="text" formControlName="billno" matInput />
            </mat-form-field>


          </div>

          <div class="form-group">
            <mat-form-field style="margin-left: 2%; width: 30%" class="example-full-width" appearance="outline">
              <mat-label>Narration :</mat-label>
              <input type="text" formControlName="narration" matInput />

            </mat-form-field>
          </div>

        </form>
        <div>
          <table class="table" style="margin-top: 50px;">
            <thead>
              <tr>
                <th colspan="1" style="border:none" class="br-bottom br-right br-top">Sr. No</th>
                <th colspan="1" style="border:none" class="br-bottom br-right br-top">Expenses</th>
                <th colspan="1" style="border:none" class="br-bottom br-right br-top">Sub Expenses</th>
                <th colspan="1" style="border:none" class="br-bottom br-right br-top">Net Amount</th>
                <th colspan="1" style="border:none" class="br-bottom br-top">Total Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of items; let i = index">
                <td>{{ i + 1 }}</td>
                <td>
                  <mat-select (selectionChange)="getMainExpName2($event,item)" [(ngModel)]="item.expense"
                    name="expense{{i}}">
                    <!-- (selectionChange)="getMainExpName($event)" -->
                    <mat-option *ngFor="let exp of mainExpData" value="{{ exp['id'] }}">{{
                      exp["exp_name"]}}</mat-option>
                  </mat-select>
                </td>
                <td>
                  <mat-select (selectionChange)="getSubExpName2($event , item)" [(ngModel)]="item.subExpense"
                    name="subExpense{{i}}">
                    <!-- (selectionChange)="getMainExpName($event)" -->
                    <mat-option *ngFor="let exp of item.getSubExpData1" value="{{ exp['sub_id'] }}">{{
                      exp["sub_exp_name"]
                      }}</mat-option>
                  </mat-select>
                </td>

                <td>
                  <input class="area" type="number" (keyup)="calTotal($event,item)" [(ngModel)]="item.netAmount"
                    name="netAmount{{i}}">
                </td>

                <td>
                  <input class="area" type="number" [(ngModel)]="item.totalAmount" name="totalAmount{{i}}">
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td colspan="5">
                  <!-- <button (click)="addItem()">Add Row</button> -->

                  <button type="button" class="btn-sm btn-outline-info" matTooltip="Add" (click)="addItem()">
                    <mat-icon>add</mat-icon>
                  </button>
                </td>
              </tr>
              <tr>
                <td colspan="3">
                </td>
                <td colspan="2">
                  Total: {{ totalAmounts }} <br>
                </td>
              </tr>
            </tfoot>
          </table>

        </div>
        <button [disabled]="kharedi.value.totalamount != totalAmounts" style="margin-left: 125px; width: 70%"
          type="button" mat-raised-button color="primary" (click)="save_creditors()">
          Generate Creditor
        </button>
      </mat-card>

      <mat-card class="credit_voucher mt-3">
        <h3 class="text-center" style="font-size: 25px; padding-top: 15px;">Creditiors Report</h3>
        <form [formGroup]="expensereport">
          <mat-form-field appearance="outline" style="margin-left: 2%; width: 30%">
            <mat-label>Enter a date from...to </mat-label>
            <mat-date-range-input [rangePicker]="pickerrep">
              <input matStartDate formControlName="start" placeholder="Start date" />
              <input matEndDate formControlName="end" placeholder="End date" (dateChange)="expenseReportDateRange()" />
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="pickerrep"></mat-datepicker-toggle>
            <mat-date-range-picker #pickerrep></mat-date-range-picker>
          </mat-form-field>

          <button style="margin-left: 25px; width: 30%" type="button" mat-raised-button color="primary"
            (click)="creditors_report()">
            Creditor Report
          </button>
        </form>
      </mat-card>

    </mat-tab>

    <mat-tab label="Journal Voucher">
      <mat-card class="credit_voucher">
        <h3 style="font-size: 25px;text-align: center;padding-top: 10px;">Add Journal Voucher</h3>
        <form [formGroup]="journalvoucher">
          <div class="form-group" style="width: 100%;">
            <mat-form-field style="margin-left: 2%; width: 20%" appearance="outline">
              <mat-label>Search Journal Voucher No</mat-label>
              <input matInput type="text" formControlName="searchVoucherNum" (keyup.enter)="searchJournalVoucher()" />
            </mat-form-field>
            <mat-form-field style="margin-left: 2%; width: 20%" appearance="outline">
              <mat-label>Journal Voucher date</mat-label>
              <input matInput type="date" style="height: 16px;" formControlName="voucherdate"
                (blur)="generateJvocuhernum($event)" />
            </mat-form-field>

            <mat-form-field style="margin-left: 2%; width: 20%" class="example-full-width" appearance="outline">
              <mat-label>Journal Creditors Number :</mat-label>
              <input type="text" formControlName="jvouchernum" matInput />
            </mat-form-field>

            <mat-form-field style="margin-left: 2%; width: 30%" class="example-full-width" appearance="outline">
              <mat-label>Narration :</mat-label>
              <input type="text" formControlName="narration" matInput />
            </mat-form-field>
          </div>
        </form>

        <div>
          <table class="table" style="margin-top: 10px;">
            <thead>
              <tr>
                <th colspan="1" style="border:none" class="br-bottom br-right br-top">Sr. No</th>
                <th colspan="1" style="border:none" class="br-bottom br-right br-top">Expenses</th>
                <th colspan="1" style="border:none" class="br-bottom br-right br-top">Sub Expenses</th>
                <th colspan="1" style="border:none" class="br-bottom br-right br-top">Net Amount</th>
                <th colspan="1" style="border:none" class="br-bottom br-top">Status</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of items1; let i = index">
                <td>{{ i + 1 }}</td>
                <td>
                  <mat-select (selectionChange)="getMainExpName2($event,item)" [(ngModel)]="item.expense"
                    name="expense{{i}}">
                    <mat-option *ngFor="let exp of mainExpData" value="{{ exp['id'] }}">{{
                      exp["exp_name"]}}</mat-option>
                  </mat-select>
                </td>
                <td>
                  <mat-select (selectionChange)="getSubExpName2($event , item)" [(ngModel)]="item.subExpense"
                    name="subExpense{{i}}">
                    <mat-option *ngFor="let exp of getSubExpData1" value="{{ exp['sub_id'] }}">{{ exp["sub_exp_name"]
                      }}</mat-option>
                  </mat-select>
                </td>

                <td>
                  <input class="area" type="number" [(ngModel)]="item.amount" (keyup)="calTotal1($event, item)"
                    name="amt{{i}}">
                </td>
                <td>
                  <mat-select [(ngModel)]="item.amt_status" (selectionChange)="selectstatus($event,item)"
                    name="amt_status{{i}}">
                    <mat-option value="DR">Debit</mat-option>
                    <mat-option value="CR">Credit</mat-option>
                  </mat-select>
                </td>
                <td>
                  <button type="button" class="btn-sm btn-outline-danger" matTooltip="Remove"
                    (click)="removeItem1(i, item.journal_voucher)">
                    <mat-icon>delete</mat-icon>
                  </button>
                </td>
                <!-- <td>
                  <button *ngIf="item.id" type="button" class="btn-sm btn-outline-success" matTooltip="Update"
                    (click)="updateItem(item)">
                    <mat-icon>edit</mat-icon>
                  </button>

                  <button *ngIf="!item.id" type="button" class="btn-sm btn-outline-primary" matTooltip="Save"
                    (click)="saveNewItem(item)">
                    <mat-icon>save</mat-icon>
                  </button>

                  <button type="button" class="btn-sm btn-outline-danger" matTooltip="Remove"
                    (click)="removeItem1(i, item.journal_voucher)">
                    <mat-icon>delete</mat-icon>
                  </button>
                </td> -->
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td colspan="5">
                  <button type="button" class="btn-sm btn-outline-info" matTooltip="Add" (click)="addItem1()">
                    <mat-icon>add</mat-icon>
                  </button>
                </td>
              </tr>
            </tfoot>
          </table>

        </div>
        <div class="form-group">
          <button *ngIf="!showJVupdate" style="margin-left: 125px; width: 70%" type="button" mat-raised-button
            color="primary" (click)="save_jvocuher()">
            Generate Journal Voucher
          </button>
          <button *ngIf="showJVupdate" style="margin-left: 65px; width: 40%;float: none;" type="button"
            mat-raised-button class="btn btn-info" (click)="update_jvoucher()">
            Update Journal Voucher
          </button>
          <button *ngIf="showJVupdate" style="margin-left: 40px; width: 35%;float: none; background-color: red;"
            type="button" mat-raised-button class="btn btn-danger" (click)="delete_creditvoucher()">
            Delete Journal Voucher
          </button>
        </div>
        <!-- <button class="mb-5" style="margin-left: 125px; width: 70%" type="button" mat-raised-button color="primary"
          (click)="save_jvocuher()">
          Generate Journal Voucher
        </button> -->

        <hr style="border: 1px solid #ccc;"> <!-- Horizontal Line -->

        <div style="margin-left: 0% !important;" class="tableFixHead ">
          <h3 style="font-size: 25px;text-align: center;padding-top: 10px;">Journal Voucher Report</h3>
          <form [formGroup]="journalvouchergetdate">
            <div class="mt-5">
              <mat-form-field appearance="outline" style="margin-left: 10%; width: 35%">
                <mat-label>Enter a date from...to </mat-label>
                <mat-date-range-input [rangePicker]="pickerrepp">
                  <input matStartDate formControlName="start" placeholder="Start date" />
                  <input matEndDate formControlName="end" placeholder="End date"
                    (dateChange)="expenseReportDateRange11()" (input)="expenseReportDateRange11()" />
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="pickerrepp"></mat-datepicker-toggle>
                <mat-date-range-picker #pickerrepp></mat-date-range-picker>
              </mat-form-field>

              <button style="margin-left: 10%; width: 35%;" type="button" [disabled]="expenseledger1.invalid"
                mat-raised-button color="primary" (click)="journal_report()">
                Get Report
              </button>
            </div>

          </form>
          <!-- <table class="table table-striped">
              <thead>
                <tr>
                  <th>Sr. No</th>
                  <th>Voucher Date</th>
                  <th>Main Exp_name</th>
                  <th>Sub Exp_name</th>
                  <th>Amount</th>
                  <th>Voucher Number</th>
                  <th>Narration</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let exp of getjournaldata; let i=index">
                  <td>{{i+1}}</td>
                  <td>{{exp.voucher_date}}</td>
                  <td>{{exp.exp_name}}</td>
                  <td>{{exp.sub_exp_name}}</td>
                  <td>{{exp.exp_amt}}</td>
                  <td>{{exp.voucher_no}}</td>
                  <td>{{exp.narration}}</td>
                  <td>
                    <button type="button" mat-raised-button class="btn-sm btn-outline-info" (click)="selectJournal(exp)">
                      <mat-icon>edit</mat-icon>
                    </button>
                    <button type="button" mat-raised-button class="btn-sm btn-outline-info" (click)="deletejvoucher(exp.id)">
                      <mat-icon>delete</mat-icon>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table> -->
        </div>

        <!-- <div style="padding-top: 50px;" class="journalvouchergetdate_o">
          <h3 style="font-size: 25px;text-align: center;padding-top: 10px;">Journal Voucher List</h3>
          <table class="table table-striped">
            <thead>
              <tr>
                <th>SR. NO</th>
                <th>Voucher Number</th>
                <th>Amount</th>
                <th>Amount Status</th>
                <th>Creation Date</th>
                <th>Entry Date</th>
                <th>Narration</th>
                <th>Action</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let exp of JournalVoucherList; let i=index">
                <td>{{ i+1 }}</td>
                <td>{{ exp['jv_journal_voucher'] }}</td>
                <td>{{exp['jv_amt']}}</td>
                <td>{{exp['jv_amt_status']}}</td>
                <td>{{exp['jv_creation_date']}}</td>
                <td>{{exp['jv_entry_date']}}</td>
                <td>{{exp['jv_narration']}}</td>

                <td>
                  <button type="button" mat-raised-button class="btn-sm btn-outline-info" (click)="selectMainExp(exp)">
                    <mat-icon>edit</mat-icon>
                  </button>
                </td>
                <td>
                  <button type="button" mat-raised-button class="btn-sm btn-outline-info"
                    (click)="getDeleteJournalVoucher(exp)">
                    <mat-icon>delete</mat-icon>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div> -->
      </mat-card>


    </mat-tab>

    <mat-tab label="Expense Report">
      <mat-card class="credit_voucher">
        <form [formGroup]="expenseledger1">
          <h3 class="text-center" style="font-size: 25px;">Select Expense Report</h3>
          <div class="form-row" style="margin-top: 10px;">
            <mat-form-field appearance="outline" style="margin-left: 2%; width: 30%">
              <mat-label>Enter a date from...to </mat-label>
              <mat-date-range-input [rangePicker]="pickerrel">
                <input matStartDate formControlName="start" placeholder="Start date" />
                <input matEndDate formControlName="end" placeholder="End date"
                  (dateChange)="expenseledgerDateRange()" />
              </mat-date-range-input>
              <mat-datepicker-toggle matSuffix [for]="pickerrel"></mat-datepicker-toggle>
              <mat-date-range-picker #pickerrel></mat-date-range-picker>

              <mat-error *ngIf="ExpenseLedgerForm.controls.start.hasError('matStartDateInvalid')
              ">Invalid start date</mat-error>
              <mat-error *ngIf="ExpenseLedgerForm.controls.end.hasError('matEndDateInvalid')">Invalid end
                date</mat-error>
            </mat-form-field>
            <mat-form-field style="margin-left: 2%; width: 30%" class="example-full-width" appearance="outline">
              <mat-label>Select Main Expense</mat-label>
              <mat-select formControlName="expnameother" (selectionChange)="getMainExpName($event)">
                <!-- (selectionChange)="getMainExpName($event)" -->
                <mat-option *ngFor="let exp of mainExpData" value="{{ exp['id'] }}">{{ exp["exp_name"]
                  }}</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field *ngIf="subexpense" style="margin-left: 2%; width: 30%" class="example-full-width"
              appearance="outline">
              <mat-label>Select Sub Expense</mat-label>
              <mat-select formControlName="subexpnameother" (selectionChange)="getSubExpName1($event)">
                <!-- (selectionChange)="getMainExpName($event)" -->
                <mat-option *ngFor="let exp of getSubExpData" value="{{ exp['sub_id'] }}">{{ exp["sub_exp_name"]
                  }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div>
            <button style="margin-left: 30%; width: 30%;" type="button" [disabled]="expenseledger1.invalid"
              mat-raised-button color="primary" (click)="expense_report()">
              Get Report
            </button>
          </div>
        </form>
      </mat-card>


      <mat-card class="credit_voucher">
        <form [formGroup]="expenseledger2">
          <h3 class="text-center" style="font-size: 25px;">Select Expense Report</h3>
          <div class="form-row" style="margin-top: 10px;">
            <mat-form-field appearance="outline" style="margin-left: 2%; width: 30%">
              <mat-label>Enter a date from...to </mat-label>
              <mat-date-range-input [rangePicker]="pickerrel_1">
                <input matStartDate formControlName="start" placeholder="Start date" />
                <input matEndDate formControlName="end" placeholder="End date"
                  (dateChange)="expenseledger2DateRange()" />
              </mat-date-range-input>
              <mat-datepicker-toggle matSuffix [for]="pickerrel_1"></mat-datepicker-toggle>
              <mat-date-range-picker #pickerrel_1></mat-date-range-picker>

              <mat-error *ngIf="ExpenseLedgerForm.controls.start.hasError('matStartDateInvalid')
              ">Invalid start date</mat-error>
              <mat-error *ngIf="ExpenseLedgerForm.controls.end.hasError('matEndDateInvalid')">Invalid end
                date</mat-error>
            </mat-form-field>
            <mat-form-field style="margin-left: 2%; width: 30%" class="example-full-width" appearance="outline">
              <mat-label>Select Main Expense</mat-label>
              <mat-select formControlName="expnameother" (selectionChange)="getMainExpName3($event)">
                <!-- (selectionChange)="getMainExpName($event)" -->
                <mat-option *ngFor="let exp of mainExpData" value="{{ exp['id'] }}">{{ exp["exp_name"]
                  }}</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field *ngIf="subexpense2" style="margin-left: 2%; width: 30%" class="example-full-width"
              appearance="outline">

              <mat-label>Select Sub Expense</mat-label>
              <mat-select #mySel3 formControlName="subexpnameother"
                (selectionChange)="getSubExpName1($event);getSubNameids($event)" multiple>
                <mat-option value="all" (click)="toggleAllSelection3()">Select All</mat-option>
                <mat-option *ngFor="let exp of getSubExpData" value="{{ exp['sub_id'] }}">{{ exp["sub_exp_name"]
                  }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div>
            <button style="margin-left: 30%; width: 30%;" type="button" [disabled]="expenseledger1.invalid"
              mat-raised-button color="primary" (click)="ExpenseEedgeCvDvReport()">
              Get Report
            </button>
          </div>
        </form>
      </mat-card>
    </mat-tab>



    <mat-tab label="Cheque Cancle">
      <mat-card class="credit_voucher">
        <h3 class="text-center" style="font-size: 25px;">Cancle Cheque</h3>
        <table class="table table-striped">
          <thead>
            <tr>
              <th>Sr. No</th>
              <th>Cheque No</th>
              <th>Bank Name</th>
              <th>Branch_name</th>
              <th>Voucher_no</th>
              <th>Voucher Date</th>
              <th>Check Date</th>
              <th>Clearing Date</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let exp of getchquedata; let i=index">
              <td>{{i+1}}</td>
              <td>{{exp.cheq_no}}</td>
              <td>{{exp.bank}}</td>
              <td>{{exp.branch_name}}</td>
              <td>{{exp.voucher_no}}</td>
              <td>{{exp.voucher_date}}</td>
              <td>{{exp.cheq_date}}</td>
              <td>{{exp.cheq_clear_date}}</td>

              <td>
                <button type="button" mat-raised-button class="btn-sm btn-outline-info"
                  (click)="canclecheque(exp.id, exp.voucher_no, exp.cheq_no)">
                  <mat-icon>delete</mat-icon>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </mat-card>
    </mat-tab>
  </mat-tab-group>
</div>