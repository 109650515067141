import { formatDate } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as XLSX from 'xlsx';


@Component({
  selector: 'app-repbusinessreport',
  templateUrl: './repbusinessreport.component.html',
  styleUrls:['./repbusinessreport.component.scss'],
})


export class RepbusinessreportComponent implements OnInit {
  offrepwiseData;
  today = new Date();
  startDate = '';
  endDate = '';
  isLoading: boolean = false;
  subTypesName = [
    { name: 'वार्षिक', isVisible: false },
    { name: 'त्रैवार्षिक', isVisible: false },
    { name: 'पंचवार्षिक', isVisible: false },
  ];
  totalth = 0;
  finalData = [];
  // tempdata = [];
  // offrepwiselist = [];
  // datalist = [];
  // subs_total = 0;
  // atotal_total = 0;
  // anew_total = 0;
  // arenew_total = 0;
  // arenew_baki_total = 0;
  // vnew_total = 0;
  // vrenew_total = 0;
  // vrenew_baki_total = 0;
  // vtotal_total = 0;
  // tnew_total = 0;
  // trenew_total = 0;
  // trenew_baki_total = 0;
  // ttotal_total = 0;
  // pnew_total = 0;
  // prenew_total = 0;
  // prenew_baki_total = 0;
  // ptotal_total = 0;
  // snew_total = 0;
  // srenew_total = 0;
  // srenew_baki_total = 0;
  // stotal_total = 0;
  // anamat_total = 0;
  // punchthev_total = 0;
  // other_total = 0;
  off_rep_name;

  constructor(
    public dialogref: MatDialogRef<RepbusinessreportComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.offrepwiseData = this.data['offrepwisedata'];
    this.off_rep_name = this.data['off_rep_name'];
    this.startDate = this.data['startDate'];
    this.endDate = this.data['endDate'];
    // console.log(this.offrepwiseData);
    

    let data = this.uniqueData(this.offrepwiseData, (a) => a.subscription_type);

    for (let i = 0; i < Object.keys(data).length; i++) {
      let flag = false;
      for (let j = 0; j < Object.keys(this.subTypesName).length; j++) {
        if (data[i]['subscription_type'] === this.subTypesName[j]['name']) {
          this.subTypesName[j]['isVisible'] = true;
          flag = true;
          break;
        }
      }
      if (!flag) {
        // if (
        //   data[i]['Subscription_Type'] !== 'हितचिंतक सप्रेम भेट' ||
        //   data[i]['Subscription_Type'] !== 'सप्रेम भेट - संपादकीय विभाग' ||
        //   data[i]['Subscription_Type'] !== 'जाहिरात स. भेट' ||
        //   data[i]['Subscription_Type'] !== 'संघ परिवार सप्रेम भेट' ||
        //   data[i]['Subscription_Type'] !== 'वर्गणी सप्रेम भेट'
        // ) {
        this.subTypesName.push({
          name: data[i]['subscription_type'],
          isVisible: true,
        });
        // }
      }
    }

    // console.log(this.subTypesName);
    this.totalth = this.subTypesName.length * 4 + 8;

    // this.getoffdata();
    this.setData();
  }
  setData() {
    let finalTotal = {};

    this.subTypesName.forEach((b: any, i) => {
        if (b.isVisible) {
            let name = this.getKey(b.name);
            finalTotal[name + '_एकूण'] = 0;
            finalTotal[name + '_नवीन'] = 0;
            finalTotal[name + '_नूतनीकरण'] = 0;
            finalTotal[name + '_नूतनीकरणबाकी'] = 0;
            if (i === 0) {
                finalTotal['name'] = 'एकूण';
                finalTotal['sub'] = 0;
                finalTotal['एकूण'] = 0;
                finalTotal['नवीन'] = 0;
                finalTotal['नूतनीकरण'] = 0;
                finalTotal['नूतनीकरणबाकी'] = 0;
            }
        }
    });

    let uniqueRepwiseData = this.uniqueData(this.offrepwiseData, (a) => a.rep_name);

    uniqueRepwiseData.forEach((repDataItem: any) => {
        let repwiseData = this.offrepwiseData.filter((a) => a.rep_name === repDataItem.rep_name);
        let totalData = {};

        this.subTypesName.forEach((b: any, i) => {
            if (b.isVisible) {
                let name = this.getKey(b.name);
                totalData[name + '_एकूण'] = 0;
                totalData[name + '_नवीन'] = 0;
                totalData[name + '_नूतनीकरण'] = 0;
                totalData[name + '_नूतनीकरणबाकी'] = 0;
                if (i === 0) {
                    totalData['sub'] = 0;
                    totalData['एकूण'] = 0;
                    totalData['नवीन'] = 0;
                    totalData['नूतनीकरण'] = 0;
                    totalData['नूतनीकरणबाकी'] = 0;
                }
            }
        });

        repwiseData.forEach((a: any, i) => {
            if (i === 0) {
                totalData['rep_name'] = a.rep_name;
                totalData['office_rep_name'] = a.office_rep_name;
            }

            let name = this.getKey(a.subscription_type);
            let today = new Date();
            today.setMonth(today.getMonth() + 1);

            if (a['sub_perid_to_date'] > formatDate(new Date(today), 'yyyy-MM-dd', 'en-IN', 'GMT+0530')) {
                totalData[name + '_नवीन'] += a.rcpt_type === 'New' ? 1 : 0;
                finalTotal[name + '_नवीन'] += a.rcpt_type === 'New' ? 1 : 0;

                totalData[name + '_नूतनीकरण'] += a.rcpt_type === 'Renew' ? 1 : 0;
                finalTotal[name + '_नूतनीकरण'] += a.rcpt_type === 'Renew' ? 1 : 0;

                totalData['नवीन'] += a.rcpt_type === 'New' ? 1 : 0;
                finalTotal['नवीन'] += a.rcpt_type === 'New' ? 1 : 0;

                totalData['नूतनीकरण'] += a.rcpt_type === 'Renew' ? 1 : 0;
                finalTotal['नूतनीकरण'] += a.rcpt_type === 'Renew' ? 1 : 0;

                totalData['sub'] += 1;
                finalTotal['sub'] += 1;

                totalData[name + '_एकूण'] += parseInt(a.total_amt);
                finalTotal[name + '_एकूण'] += parseInt(a.total_amt);

                totalData['एकूण'] += parseInt(a.total_amt);
                finalTotal['एकूण'] += parseInt(a.total_amt);
            } else {
                totalData[name + '_नूतनीकरणबाकी'] += 1;
                finalTotal[name + '_नूतनीकरणबाकी'] += 1;

                totalData['नूतनीकरणबाकी'] += 1;
                finalTotal['नूतनीकरणबाकी'] += 1;
            }
        });

        this.finalData.push(totalData);
    });

    this.finalData.push(finalTotal);
    console.log(this.finalData);

    this.isLoading = false;
}



  getKey(name) {
    return name.replaceAll(' ', '_');
  }

  uniqueData(data, key) {
    return [...new Map(data.map((a) => [key(a), a])).values()];
  }

  exportExcel(fileName) {
    /* table id is passed over here */
    let element = document.getElementById('print-section');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, fileName + '.xlsx');
  }

  // getoffdata() {
  //   for (
  //     let index = 0;
  //     index < Object.keys(this.offrepwiseData).length;
  //     index++
  //   ) {
  //     let flag = false;
  //     if (Object.keys(this.tempdata).length == 0) {
  //       this.tempdata.push({
  //         district: this.offrepwiseData[index]['district'],
  //         entry_date: this.offrepwiseData[index]['entry_date'],
  //         name: this.offrepwiseData[index]['name'],
  //         office_rep_name: this.offrepwiseData[index]['office_rep_name'],
  //         rcpt_no: this.offrepwiseData[index]['rcpt_no'],
  //         rcpt_type: this.offrepwiseData[index]['rcpt_type'],
  //         rep_name: this.offrepwiseData[index]['rep_name'],
  //         subscription_type: this.offrepwiseData[index]['subscription_type'],
  //         taluka: this.offrepwiseData[index]['taluka'],
  //         title: this.offrepwiseData[index]['title'],
  //         total_amt: this.offrepwiseData[index]['total_amt'],
  //         sub_perid_from_date:
  //           this.offrepwiseData[index]['sub_perid_from_date'],
  //         sub_perid_to_date: this.offrepwiseData[index]['sub_perid_to_date'],
  //         subs_total: 0,
  //         atotal: 0,
  //         anew: 0,
  //         arenew: 0,
  //         arenew_baki: 0,
  //         vnew: 0,
  //         vrenew: 0,
  //         vrenew_baki: 0,
  //         vtotal: 0,
  //         tnew: 0,
  //         trenew: 0,
  //         trenew_baki: 0,
  //         ttotal: 0,
  //         pnew: 0,
  //         prenew: 0,
  //         prenew_baki: 0,
  //         ptotal: 0,
  //         snew: 0,
  //         srenew: 0,
  //         srenew_baki: 0,
  //         stotal: 0,
  //         anamat: 0,
  //         punchthev: 0,
  //         other: 0,
  //       });
  //     } else {
  //       for (
  //         let index1 = 0;
  //         index1 < Object.keys(this.tempdata).length;
  //         index1++
  //       ) {
  //         if (
  //           this.tempdata[index1]['district'] ==
  //           this.offrepwiseData[index]['district']
  //         ) {
  //           flag = true;
  //           //  &&
  //           // this.tempdata[index1]['name'] == this.offrepwiseData[index]['name']
  //         }
  //       }
  //       if (!flag) {
  //         this.tempdata.push({
  //           district: this.offrepwiseData[index]['district'],
  //           entry_date: this.offrepwiseData[index]['entry_date'],
  //           name: this.offrepwiseData[index]['name'],
  //           office_rep_name: this.offrepwiseData[index]['office_rep_name'],
  //           rcpt_no: this.offrepwiseData[index]['rcpt_no'],
  //           rcpt_type: this.offrepwiseData[index]['rcpt_type'],
  //           rep_name: this.offrepwiseData[index]['rep_name'],
  //           subscription_type: this.offrepwiseData[index]['subscription_type'],
  //           taluka: this.offrepwiseData[index]['taluka'],
  //           title: this.offrepwiseData[index]['title'],
  //           total_amt: this.offrepwiseData[index]['total_amt'],
  //           sub_perid_from_date:
  //             this.offrepwiseData[index]['sub_perid_from_date'],
  //           sub_perid_to_date: this.offrepwiseData[index]['sub_perid_to_date'],
  //           subs_total: 0,
  //           atotal: 0,
  //           anew: 0,
  //           arenew: 0,
  //           arenew_baki: 0,
  //           vnew: 0,
  //           vrenew: 0,
  //           vrenew_baki: 0,
  //           vtotal: 0,
  //           tnew: 0,
  //           trenew: 0,
  //           trenew_baki: 0,
  //           ttotal: 0,
  //           pnew: 0,
  //           prenew: 0,
  //           prenew_baki: 0,
  //           ptotal: 0,
  //           snew: 0,
  //           srenew: 0,
  //           srenew_baki: 0,
  //           stotal: 0,
  //           anamat: 0,
  //           punchthev: 0,
  //           other: 0,
  //         });
  //       }
  //     }
  //   }

  //   console.log(this.tempdata);
  //   for (let index = 0; index < Object.keys(this.tempdata).length; index++) {
  //     let arr = this.offrepwiseData.filter(
  //       (a) => a['district'] == this.tempdata[index]['district']
  //     );

  //     //  &&
  //     //     a['name'] == this.tempdata[index]['name']

  //     let subs_total = 0;
  //     let atotal = 0;
  //     let anew = 0;
  //     let arenew = 0;
  //     let arenew_baki = 0;
  //     let vnew = 0;
  //     let vrenew = 0;
  //     let vrenew_baki = 0;
  //     let vtotal = 0;
  //     let tnew = 0;
  //     let trenew = 0;
  //     let trenew_baki = 0;
  //     let ttotal = 0;
  //     let pnew = 0;
  //     let prenew = 0;
  //     let prenew_baki = 0;
  //     let ptotal = 0;
  //     let snew = 0;
  //     let srenew = 0;
  //     let srenew_baki = 0;
  //     let stotal = 0;
  //     let anamat = 0;
  //     let punchthev = 0;
  //     let other = 0;
  //     let today = new Date();
  //     today.setMonth(today.getMonth() + 1);
  //     for (let index1 = 0; index1 < Object.keys(arr).length; index1++) {
  //       subs_total = subs_total + 1;
  //       atotal = atotal + parseInt(arr[index1]['total_amt']);
  //       // console.log(
  //       //   'before',
  //       //   formatDate(new Date(today), 'yyyy-MM-dd', 'en-IN', 'GMT+0530')
  //       // );

  //       if (
  //         arr[index1]['sub_perid_to_date'] >
  //         formatDate(new Date(today), 'yyyy-MM-dd', 'en-IN', 'GMT+0530')
  //       ) {
  //         // console.log('first');
  //         if (arr[index1]['rcpt_type'] == 'New') {
  //           anew = anew + 1;
  //         } else if (arr[index1]['rcpt_type'] == 'Renew') {
  //           arenew = arenew + 1;
  //         }
  //       } else {
  //         arenew_baki = arenew_baki + 1;
  //       }

  //       if (arr[index1]['subscription_type'] == 'वार्षिक') {
  //         vtotal = vtotal + parseInt(arr[index1]['total_amt']);
  //         if (
  //           arr[index1]['sub_perid_to_date'] >
  //           formatDate(new Date(today), 'yyyy-MM-dd', 'en-IN', 'GMT+0530')
  //         ) {
  //           // console.log('second');
  //           if (arr[index1]['rcpt_type'] == 'New') {
  //             vnew = vnew + 1;
  //           } else if (arr[index1]['rcpt_type'] == 'Renew') {
  //             vrenew = vrenew + 1;
  //           }
  //         } else {
  //           vrenew_baki = vrenew_baki + 1;
  //         }
  //       } else if (arr[index1]['subscription_type'] == 'त्रैवार्षिक') {
  //         ttotal = ttotal + parseInt(arr[index1]['total_amt']);
  //         if (
  //           arr[index1]['sub_perid_to_date'] >
  //           formatDate(new Date(today), 'yyyy-MM-dd', 'en-IN', 'GMT+0530')
  //         ) {
  //           // console.log('third');
  //           if (arr[index1]['rcpt_type'] == 'New') {
  //             tnew = tnew + 1;
  //           } else if (arr[index1]['rcpt_type'] == 'Renew') {
  //             trenew = trenew + 1;
  //           }
  //         } else {
  //           trenew_baki = trenew_baki + 1;
  //         }
  //       } else if (arr[index1]['subscription_type'] == 'पंचवार्षिक') {
  //         ptotal = ptotal + parseInt(arr[index1]['total_amt']);
  //         if (
  //           arr[index1]['sub_perid_to_date'] >
  //           formatDate(new Date(today), 'yyyy-MM-dd', 'en-IN', 'GMT+0530')
  //         ) {
  //           // console.log('fourth');
  //           if (arr[index1]['rcpt_type'] == 'New') {
  //             pnew = pnew + 1;
  //           } else if (arr[index1]['rcpt_type'] == 'Renew') {
  //             prenew = prenew + 1;
  //           }
  //         } else {
  //           prenew_baki = prenew_baki + 1;
  //         }
  //       } else if (arr[index1]['subscription_type'] == 'सहयोगी') {
  //         stotal = stotal + parseInt(arr[index1]['total_amt']);
  //         if (
  //           arr[index1]['sub_perid_to_date'] >
  //           formatDate(new Date(today), 'yyyy-MM-dd', 'en-IN', 'GMT+0530')
  //         ) {
  //           // console.log('fifth');
  //           if (arr[index1]['rcpt_type'] == 'New') {
  //             snew = snew + 1;
  //           } else if (arr[index1]['rcpt_type'] == 'Renew') {
  //             srenew = srenew + 1;
  //           }
  //         } else {
  //           srenew_baki = srenew_baki + 1;
  //         }
  //       } else if (arr[index1]['subscription_type'] == 'अनामत') {
  //         anamat = anamat + parseInt(arr[index1]['total_amt']);
  //       } else if (arr[index1]['subscription_type'] == 'पंचठेव') {
  //         punchthev = punchthev + parseInt(arr[index1]['total_amt']);
  //       } else if (arr[index1]['subscription_type'] == 'इतर जमा') {
  //         other = other + parseInt(arr[index1]['total_amt']);
  //       }
  //     }

  //     this.tempdata[index]['subs_total'] = subs_total;
  //     this.tempdata[index]['atotal'] = atotal;
  //     this.tempdata[index]['anew'] = anew;
  //     this.tempdata[index]['arenew'] = arenew;
  //     this.tempdata[index]['arenew_baki'] = arenew_baki;
  //     this.tempdata[index]['vnew'] = vnew;
  //     this.tempdata[index]['vrenew'] = vrenew;
  //     this.tempdata[index]['vrenew_baki'] = vrenew_baki;
  //     this.tempdata[index]['vtotal'] = vtotal;
  //     this.tempdata[index]['tnew'] = tnew;
  //     this.tempdata[index]['trenew'] = trenew;
  //     this.tempdata[index]['trenew_baki'] = trenew_baki;
  //     this.tempdata[index]['ttotal'] = ttotal;
  //     this.tempdata[index]['pnew'] = pnew;
  //     this.tempdata[index]['prenew'] = prenew;
  //     this.tempdata[index]['prenew_baki'] = prenew_baki;
  //     this.tempdata[index]['ptotal'] = ptotal;
  //     this.tempdata[index]['snew'] = snew;
  //     this.tempdata[index]['srenew'] = srenew;
  //     this.tempdata[index]['srenew_baki'] = srenew_baki;
  //     this.tempdata[index]['stotal'] = stotal;
  //     this.tempdata[index]['anamat'] = anamat;
  //     this.tempdata[index]['punchthev'] = punchthev;
  //     this.tempdata[index]['other'] = other;

  //     this.offrepwiselist.push(arr);
  //   }

  //   console.log(this.offrepwiselist);
  //   for (
  //     let index = 0;
  //     index < Object.keys(this.offrepwiselist).length;
  //     index++
  //   ) {
  //     let data = [];
  //     for (
  //       let index1 = 0;
  //       index1 < Object.keys(this.offrepwiselist[index]).length;
  //       index1++
  //     ) {
  //       let arr = this.offrepwiselist[index].filter(
  //         (a) =>
  //           a['taluka'] == this.tempdata[index]['taluka'] &&
  //           a['rep_name'] == this.tempdata[index]['rep_name']
  //       );
  //       console.log(arr);
  //       let flag = false;
  //       if (Object.keys(data).length == 0) {
  //         data.push({
  //           district: this.offrepwiselist[index][index1]['district'],
  //           entry_date: this.offrepwiselist[index][index1]['entry_date'],
  //           name: this.offrepwiselist[index][index1]['name'],
  //           office_rep_name:
  //             this.offrepwiselist[index][index1]['office_rep_name'],
  //           rcpt_no: this.offrepwiselist[index][index1]['rcpt_no'],
  //           rcpt_type: this.offrepwiselist[index][index1]['rcpt_type'],
  //           rep_name: this.offrepwiselist[index][index1]['rep_name'],
  //           subscription_type:
  //             this.offrepwiselist[index][index1]['subscription_type'],
  //           taluka: this.offrepwiselist[index][index1]['taluka'],
  //           title: this.offrepwiselist[index][index1]['title'],
  //           total_amt: this.offrepwiselist[index][index1]['total_amt'],
  //           sub_perid_from_date:
  //             this.offrepwiselist[index][index1]['sub_perid_from_date'],
  //           sub_perid_to_date:
  //             this.offrepwiselist[index][index1]['sub_perid_to_date'],
  //           subs_total: 0,
  //           atotal: 0,
  //           anew: 0,
  //           arenew: 0,
  //           arenew_baki: 0,
  //           vnew: 0,
  //           vrenew: 0,
  //           vrenew_baki: 0,
  //           vtotal: 0,
  //           tnew: 0,
  //           trenew: 0,
  //           trenew_baki: 0,
  //           ttotal: 0,
  //           pnew: 0,
  //           prenew: 0,
  //           prenew_baki: 0,
  //           ptotal: 0,
  //           snew: 0,
  //           srenew: 0,
  //           srenew_baki: 0,
  //           stotal: 0,
  //           anamat: 0,
  //           punchthev: 0,
  //           other: 0,
  //         });
  //         let today = new Date();
  //         today.setMonth(today.getMonth() + 1);
  //         for (let i = 0; i < Object.keys(data).length; i++) {
  //           data[i]['subs_total'] = parseInt(data[i]['subs_total']) + 1;
  //           if (
  //             data[i]['sub_perid_to_date'] >
  //             formatDate(new Date(today), 'yyyy-MM-dd', 'en-IN', 'GMT+0530')
  //           ) {
  //             if (data[i]['rcpt_type'] == 'New') {
  //               data[i]['anew'] = parseInt(data[i]['anew']) + 1;
  //             } else if (data[i]['rcpt_type'] == 'Renew') {
  //               data[i]['arenew'] = parseInt(data[i]['arenew']) + 1;
  //             }
  //           } else {
  //             data[i]['arenew_baki'] = parseInt(data[i]['arenew_baki']) + 1;
  //           }

  //           if (data[i]['subscription_type'] == 'वार्षिक') {
  //             data[i]['vtotal'] =
  //               parseInt(data[i]['vtotal']) + parseInt(data[i]['total_amt']);
  //             if (
  //               data[i]['sub_perid_to_date'] >
  //               formatDate(new Date(today), 'yyyy-MM-dd', 'en-IN', 'GMT+0530')
  //             ) {
  //               if (data[i]['rcpt_type'] == 'New') {
  //                 data[i]['vnew'] = parseInt(data[i]['vnew']) + 1;
  //               } else if (data[i]['rcpt_type'] == 'Renew') {
  //                 data[i]['vrenew'] = parseInt(data[i]['vrenew']) + 1;
  //               }
  //             } else {
  //               data[i]['vrenew_baki'] = parseInt(data[i]['vrenew_baki']) + 1;
  //             }
  //           } else if (data[i]['subscription_type'] == 'त्रैवार्षिक') {
  //             data[i]['ttotal'] =
  //               parseInt(data[i]['ttotal']) + parseInt(data[i]['total_amt']);
  //             if (
  //               data[i]['sub_perid_to_date'] >
  //               formatDate(new Date(today), 'yyyy-MM-dd', 'en-IN', 'GMT+0530')
  //             ) {
  //               if (data[i]['rcpt_type'] == 'New') {
  //                 data[i]['tnew'] = parseInt(data[i]['tnew']) + 1;
  //               } else if (data[i]['rcpt_type'] == 'Renew') {
  //                 data[i]['trenew'] = parseInt(data[i]['trenew']) + 1;
  //               }
  //             } else {
  //               data[i]['trenew_baki'] = parseInt(data[i]['trenew_baki']) + 1;
  //             }
  //           } else if (data[i]['subscription_type'] == 'पंचवार्षिक') {
  //             data[i]['ptotal'] =
  //               parseInt(data[i]['ptotal']) + parseInt(data[i]['total_amt']);
  //             if (
  //               data[i]['sub_perid_to_date'] >
  //               formatDate(new Date(today), 'yyyy-MM-dd', 'en-IN', 'GMT+0530')
  //             ) {
  //               if (data[i]['rcpt_type'] == 'New') {
  //                 data[i]['pnew'] = parseInt(data[i]['pnew']) + 1;
  //               } else if (data[i]['rcpt_type'] == 'Renew') {
  //                 data[i]['prenew'] = parseInt(data[i]['prenew']) + 1;
  //               }
  //             } else {
  //               data[i]['prenew_baki'] = parseInt(data[i]['prenew_baki']) + 1;
  //             }
  //           } else if (data[i]['subscription_type'] == 'सहयोगी') {
  //             data[i]['stotal'] =
  //               parseInt(data[i]['stotal']) + parseInt(data[i]['total_amt']);
  //             if (
  //               data[i]['sub_perid_to_date'] >
  //               formatDate(new Date(today), 'yyyy-MM-dd', 'en-IN', 'GMT+0530')
  //             ) {
  //               if (data[i]['rcpt_type'] == 'New') {
  //                 data[i]['snew'] = parseInt(data[i]['snew']) + 1;
  //               } else if (data[i]['rcpt_type'] == 'Renew') {
  //                 data[i]['srenew'] = parseInt(data[i]['srenew']) + 1;
  //               }
  //             } else {
  //               data[i]['srenew_baki'] = parseInt(data[i]['srenew_baki']) + 1;
  //             }
  //           } else if (data[i]['subscription_type'] == 'अनामत') {
  //             data[i]['anamat'] =
  //               parseInt(data[i]['anamat']) + parseInt(data[i]['total_amt']);
  //           } else if (data[i]['subscription_type'] == 'पंचठेव') {
  //             data[i]['punchthev'] =
  //               parseInt(data[i]['punchthev']) + parseInt(data[i]['total_amt']);
  //           } else if (data[i]['subscription_type'] == 'इतर जमा') {
  //             data[i]['other'] =
  //               parseInt(data[i]['other']) + parseInt(data[i]['total_amt']);
  //           }
  //         }
  //       } else {
  //         for (let index2 = 0; index2 < Object.keys(data).length; index2++) {
  //           if (
  //             data[index2]['taluka'].toLowerCase() ==
  //               this.offrepwiselist[index][index1]['taluka'].toLowerCase() &&
  //             data[index2]['rep_name'].toLowerCase() ==
  //               this.offrepwiselist[index][index1]['rep_name'].toLowerCase()
  //           ) {
  //             flag = true;
  //             let today = new Date();
  //             today.setMonth(today.getMonth() + 1);
  //             data[index2]['total_amt'] =
  //               parseInt(data[index2]['total_amt']) +
  //               parseInt(this.offrepwiselist[index][index1]['total_amt']);
  //             data[index2]['subs_total'] =
  //               parseInt(data[index2]['subs_total']) + 1;
  //             if (
  //               this.offrepwiselist[index][index1]['sub_perid_to_date'] >
  //               formatDate(new Date(today), 'yyyy-MM-dd', 'en-IN', 'GMT+0530')
  //             ) {
  //               if (this.offrepwiselist[index][index1]['rcpt_type'] == 'New') {
  //                 data[index2]['anew'] = parseInt(data[index2]['anew']) + 1;
  //               } else if (
  //                 this.offrepwiselist[index][index1]['rcpt_type'] == 'Renew'
  //               ) {
  //                 data[index2]['arenew'] = parseInt(data[index2]['arenew']) + 1;
  //               }
  //             } else {
  //               data[index2]['arenew_baki'] =
  //                 parseInt(data[index2]['arenew_baki']) + 1;
  //             }

  //             if (
  //               this.offrepwiselist[index][index1]['subscription_type'] ==
  //               'वार्षिक'
  //             ) {
  //               data[index2]['vtotal'] =
  //                 parseInt(data[index2]['vtotal']) +
  //                 parseInt(this.offrepwiselist[index][index1]['total_amt']);
  //               if (
  //                 this.offrepwiselist[index][index1]['sub_perid_to_date'] >
  //                 formatDate(new Date(today), 'yyyy-MM-dd', 'en-IN', 'GMT+0530')
  //               ) {
  //                 if (
  //                   this.offrepwiselist[index][index1]['rcpt_type'] == 'New'
  //                 ) {
  //                   data[index2]['vnew'] = parseInt(data[index2]['vnew']) + 1;
  //                 } else if (
  //                   this.offrepwiselist[index][index1]['rcpt_type'] == 'Renew'
  //                 ) {
  //                   data[index2]['vrenew'] =
  //                     parseInt(data[index2]['vrenew']) + 1;
  //                 }
  //               } else {
  //                 data[index2]['vrenew_baki'] =
  //                   parseInt(data[index2]['vrenew_baki']) + 1;
  //               }
  //             } else if (
  //               this.offrepwiselist[index][index1]['subscription_type'] ==
  //               'त्रैवार्षिक'
  //             ) {
  //               data[index2]['ttotal'] =
  //                 parseInt(data[index2]['ttotal']) +
  //                 parseInt(this.offrepwiselist[index][index1]['total_amt']);
  //               if (
  //                 this.offrepwiselist[index][index1]['sub_perid_to_date'] >
  //                 formatDate(new Date(today), 'yyyy-MM-dd', 'en-IN', 'GMT+0530')
  //               ) {
  //                 if (
  //                   this.offrepwiselist[index][index1]['rcpt_type'] == 'New'
  //                 ) {
  //                   data[index2]['tnew'] = parseInt(data[index2]['tnew']) + 1;
  //                 } else if (
  //                   this.offrepwiselist[index][index1]['rcpt_type'] == 'Renew'
  //                 ) {
  //                   data[index2]['trenew'] =
  //                     parseInt(data[index2]['trenew']) + 1;
  //                 }
  //               } else {
  //                 data[index2]['trenew_baki'] =
  //                   parseInt(data[index2]['trenew_baki']) + 1;
  //               }
  //             } else if (
  //               this.offrepwiselist[index][index1]['subscription_type'] ==
  //               'पंचवार्षिक'
  //             ) {
  //               data[index2]['ptotal'] =
  //                 parseInt(data[index2]['ptotal']) +
  //                 parseInt(this.offrepwiselist[index][index1]['total_amt']);
  //               if (
  //                 this.offrepwiselist[index][index1]['sub_perid_to_date'] >
  //                 formatDate(new Date(today), 'yyyy-MM-dd', 'en-IN', 'GMT+0530')
  //               ) {
  //                 if (
  //                   this.offrepwiselist[index][index1]['rcpt_type'] == 'New'
  //                 ) {
  //                   data[index2]['pnew'] = parseInt(data[index2]['pnew']) + 1;
  //                 } else if (
  //                   this.offrepwiselist[index][index1]['rcpt_type'] == 'Renew'
  //                 ) {
  //                   data[index2]['prenew'] =
  //                     parseInt(data[index2]['prenew']) + 1;
  //                 }
  //               } else {
  //                 data[index2]['prenew_baki'] =
  //                   parseInt(data[index2]['prenew_baki']) + 1;
  //               }
  //             } else if (
  //               this.offrepwiselist[index][index1]['subscription_type'] ==
  //               'सहयोगी'
  //             ) {
  //               data[index2]['stotal'] =
  //                 parseInt(data[index2]['stotal']) +
  //                 parseInt(this.offrepwiselist[index][index1]['total_amt']);
  //               if (
  //                 this.offrepwiselist[index][index1]['sub_perid_to_date'] >
  //                 formatDate(new Date(today), 'yyyy-MM-dd', 'en-IN', 'GMT+0530')
  //               ) {
  //                 if (
  //                   this.offrepwiselist[index][index1]['rcpt_type'] == 'New'
  //                 ) {
  //                   data[index2]['snew'] = parseInt(data[index2]['snew']) + 1;
  //                 } else if (
  //                   this.offrepwiselist[index][index1]['rcpt_type'] == 'Renew'
  //                 ) {
  //                   data[index2]['srenew'] =
  //                     parseInt(data[index2]['srenew']) + 1;
  //                 }
  //               } else {
  //                 data[index2]['srenew_baki'] =
  //                   parseInt(data[index2]['srenew_baki']) + 1;
  //               }
  //             } else if (
  //               this.offrepwiselist[index][index1]['subscription_type'] ==
  //               'अनामत'
  //             ) {
  //               data[index2]['anamat'] =
  //                 parseInt(data[index2]['anamat']) +
  //                 parseInt(this.offrepwiselist[index][index1]['total_amt']);
  //             } else if (
  //               this.offrepwiselist[index][index1]['subscription_type'] ==
  //               'पंचठेव'
  //             ) {
  //               data[index2]['punchthev'] =
  //                 parseInt(data[index2]['punchthev']) +
  //                 parseInt(this.offrepwiselist[index][index1]['total_amt']);
  //             } else if (
  //               this.offrepwiselist[index][index1]['subscription_type'] ==
  //               'इतर जमा'
  //             ) {
  //               data[index2]['other'] =
  //                 parseInt(data[index2]['other']) +
  //                 parseInt(this.offrepwiselist[index][index1]['total_amt']);
  //             }
  //           }
  //         }
  //         if (!flag) {
  //           data.push({
  //             district: this.offrepwiselist[index][index1]['district'],
  //             entry_date: this.offrepwiselist[index][index1]['entry_date'],
  //             name: this.offrepwiselist[index][index1]['name'],
  //             office_rep_name:
  //               this.offrepwiselist[index][index1]['office_rep_name'],
  //             rcpt_no: this.offrepwiselist[index][index1]['rcpt_no'],
  //             rcpt_type: this.offrepwiselist[index][index1]['rcpt_type'],
  //             rep_name: this.offrepwiselist[index][index1]['rep_name'],
  //             subscription_type:
  //               this.offrepwiselist[index][index1]['subscription_type'],
  //             taluka: this.offrepwiselist[index][index1]['taluka'],
  //             title: this.offrepwiselist[index][index1]['title'],
  //             total_amt: this.offrepwiselist[index][index1]['total_amt'],
  //             sub_perid_from_date:
  //               this.offrepwiselist[index][index1]['sub_perid_from_date'],
  //             sub_perid_to_date:
  //               this.offrepwiselist[index][index1]['sub_perid_to_date'],
  //             subs_total: 0,
  //             atotal: 0,
  //             anew: 0,
  //             arenew: 0,
  //             arenew_baki: 0,
  //             vnew: 0,
  //             vrenew: 0,
  //             vrenew_baki: 0,
  //             vtotal: 0,
  //             tnew: 0,
  //             trenew: 0,
  //             trenew_baki: 0,
  //             ttotal: 0,
  //             pnew: 0,
  //             prenew: 0,
  //             prenew_baki: 0,
  //             ptotal: 0,
  //             snew: 0,
  //             srenew: 0,
  //             srenew_baki: 0,
  //             stotal: 0,
  //             anamat: 0,
  //             punchthev: 0,
  //             other: 0,
  //           });
  //           let today = new Date();
  //           today.setMonth(today.getMonth() + 1);

  //           for (
  //             let i = Object.keys(data).length - 1;
  //             i < Object.keys(data).length;
  //             i++
  //           ) {
  //             data[i]['subs_total'] = parseInt(data[i]['subs_total']) + 1;
  //             if (
  //               data[i]['sub_perid_to_date'] >
  //               formatDate(new Date(today), 'yyyy-MM-dd', 'en-IN', 'GMT+0530')
  //             ) {
  //               if (data[i]['rcpt_type'] == 'New') {
  //                 data[i]['anew'] = parseInt(data[i]['anew']) + 1;
  //               } else if (data[i]['rcpt_type'] == 'Renew') {
  //                 data[i]['arenew'] = parseInt(data[i]['arenew']) + 1;
  //               }
  //             } else {
  //               data[i]['arenew_baki'] = parseInt(data[i]['arenew_baki']) + 1;
  //             }

  //             if (data[i]['subscription_type'] == 'वार्षिक') {
  //               data[i]['vtotal'] =
  //                 parseInt(data[i]['vtotal']) + parseInt(data[i]['total_amt']);
  //               if (
  //                 data[i]['sub_perid_to_date'] >
  //                 formatDate(new Date(today), 'yyyy-MM-dd', 'en-IN', 'GMT+0530')
  //               ) {
  //                 if (data[i]['rcpt_type'] == 'New') {
  //                   data[i]['vnew'] = parseInt(data[i]['vnew']) + 1;
  //                 } else if (data[i]['rcpt_type'] == 'Renew') {
  //                   data[i]['vrenew'] = parseInt(data[i]['vrenew']) + 1;
  //                 }
  //               } else {
  //                 data[i]['vrenew_baki'] = parseInt(data[i]['vrenew_baki']) + 1;
  //               }
  //             } else if (data[i]['subscription_type'] == 'त्रैवार्षिक') {
  //               data[i]['ttotal'] =
  //                 parseInt(data[i]['ttotal']) + parseInt(data[i]['total_amt']);
  //               if (
  //                 data[i]['sub_perid_to_date'] >
  //                 formatDate(new Date(today), 'yyyy-MM-dd', 'en-IN', 'GMT+0530')
  //               ) {
  //                 if (data[i]['rcpt_type'] == 'New') {
  //                   data[i]['tnew'] = parseInt(data[i]['tnew']) + 1;
  //                 } else if (data[i]['rcpt_type'] == 'Renew') {
  //                   data[i]['trenew'] = parseInt(data[i]['trenew']) + 1;
  //                 }
  //               } else {
  //                 data[i]['trenew_baki'] = parseInt(data[i]['trenew_baki']) + 1;
  //               }
  //             } else if (data[i]['subscription_type'] == 'पंचवार्षिक') {
  //               data[i]['ptotal'] =
  //                 parseInt(data[i]['ptotal']) + parseInt(data[i]['total_amt']);
  //               if (
  //                 data[i]['sub_perid_to_date'] >
  //                 formatDate(new Date(today), 'yyyy-MM-dd', 'en-IN', 'GMT+0530')
  //               ) {
  //                 if (data[i]['rcpt_type'] == 'New') {
  //                   data[i]['pnew'] = parseInt(data[i]['pnew']) + 1;
  //                 } else if (data[i]['rcpt_type'] == 'Renew') {
  //                   data[i]['prenew'] = parseInt(data[i]['prenew']) + 1;
  //                 }
  //               } else {
  //                 data[i]['prenew_baki'] = parseInt(data[i]['prenew_baki']) + 1;
  //               }
  //             } else if (data[i]['subscription_type'] == 'सहयोगी') {
  //               data[i]['stotal'] =
  //                 parseInt(data[i]['stotal']) + parseInt(data[i]['total_amt']);
  //               if (
  //                 data[i]['sub_perid_to_date'] >
  //                 formatDate(new Date(today), 'yyyy-MM-dd', 'en-IN', 'GMT+0530')
  //               ) {
  //                 if (data[i]['rcpt_type'] == 'New') {
  //                   data[i]['snew'] = parseInt(data[i]['snew']) + 1;
  //                 } else if (data[i]['rcpt_type'] == 'Renew') {
  //                   data[i]['srenew'] = parseInt(data[i]['srenew']) + 1;
  //                 }
  //               } else {
  //                 data[i]['srenew_baki'] = parseInt(data[i]['srenew_baki']) + 1;
  //               }
  //             } else if (data[i]['subscription_type'] == 'अनामत') {
  //               data[i]['anamat'] =
  //                 parseInt(data[i]['anamat']) + parseInt(data[i]['total_amt']);
  //             } else if (data[i]['subscription_type'] == 'पंचठेव') {
  //               data[i]['punchthev'] =
  //                 parseInt(data[i]['punchthev']) +
  //                 parseInt(data[i]['total_amt']);
  //             } else if (data[i]['subscription_type'] == 'इतर जमा') {
  //               data[i]['other'] =
  //                 parseInt(data[i]['other']) + parseInt(data[i]['total_amt']);
  //             }
  //           }
  //         }
  //       }
  //     }
  //     this.datalist.push(data);
  //     // console.log('datalist', this.datalist);
  //   }

  //   for (let index = 0; index < Object.keys(this.tempdata).length; index++) {
  //     this.subs_total =
  //       this.subs_total + parseInt(this.tempdata[index]['subs_total']);
  //     this.atotal_total =
  //       this.atotal_total + parseInt(this.tempdata[index]['atotal']);
  //     this.anew_total =
  //       this.anew_total + parseInt(this.tempdata[index]['anew']);
  //     this.arenew_total =
  //       this.arenew_total + parseInt(this.tempdata[index]['arenew']);
  //     this.arenew_baki_total =
  //       this.arenew_baki_total + parseInt(this.tempdata[index]['arenew_baki']);
  //     this.vnew_total =
  //       this.vnew_total + parseInt(this.tempdata[index]['vnew']);
  //     this.vrenew_total =
  //       this.vrenew_total + parseInt(this.tempdata[index]['vrenew']);
  //     this.vrenew_baki_total =
  //       this.vrenew_baki_total + parseInt(this.tempdata[index]['vrenew_baki']);
  //     this.vtotal_total =
  //       this.vtotal_total + parseInt(this.tempdata[index]['vtotal']);
  //     this.tnew_total =
  //       this.tnew_total + parseInt(this.tempdata[index]['tnew']);
  //     this.trenew_total =
  //       this.trenew_total + parseInt(this.tempdata[index]['trenew']);
  //     this.trenew_baki_total =
  //       this.trenew_baki_total + parseInt(this.tempdata[index]['trenew_baki']);
  //     this.ttotal_total =
  //       this.ttotal_total + parseInt(this.tempdata[index]['ttotal']);
  //     this.pnew_total =
  //       this.pnew_total + parseInt(this.tempdata[index]['pnew']);
  //     this.prenew_total =
  //       this.prenew_total + parseInt(this.tempdata[index]['prenew']);
  //     this.prenew_baki_total =
  //       this.prenew_baki_total + parseInt(this.tempdata[index]['prenew_baki']);
  //     this.ptotal_total =
  //       this.ptotal_total + parseInt(this.tempdata[index]['ptotal']);
  //     this.snew_total =
  //       this.snew_total + parseInt(this.tempdata[index]['snew']);
  //     this.srenew_total =
  //       this.srenew_total + parseInt(this.tempdata[index]['srenew']);
  //     this.srenew_baki_total =
  //       this.srenew_baki_total + parseInt(this.tempdata[index]['srenew_baki']);
  //     this.stotal_total =
  //       this.stotal_total + parseInt(this.tempdata[index]['stotal']);
  //     this.anamat_total =
  //       this.anamat_total + parseInt(this.tempdata[index]['anamat']);
  //     this.punchthev_total =
  //       this.punchthev_total + parseInt(this.tempdata[index]['punchthev']);
  //     this.other_total =
  //       this.other_total + parseInt(this.tempdata[index]['other']);
  //   }
  // }
}
