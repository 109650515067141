import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-pincodewise-statement',
  templateUrl: './pincodewise-statement.component.html',
  styleUrls: ['./pincodewise-statement.component.scss'],
})
export class PincodewiseStatementComponent implements OnInit {
  pincodewisedata;
  total_pin_sub_count = 0;
  totalparcel = 0;
  totalanksankhya = 0;
  filteredpincodewisesubcout = [];
  pincodewisesubcoutprinting = [];
  sundayankDate;
  dispatchankDate;

  constructor(
    private dialogref: MatDialogRef<PincodewiseStatementComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.pincodewisedata = this.data['data'];
    this.sundayankDate = this.data['sundaydate'];
    this.dispatchankDate = this.data['dispatchdate'];
    // console.log('pincodewisedata', this.pincodewisedata);

    this.total_pin_sub_count = 0;
    this.totalparcel = 0;
    this.totalanksankhya = 0;
    let tempdata = this.uniqueid(this.pincodewisedata, (a) => a['pincode']);

    // console.log({ tempdata });
    this.filteredpincodewisesubcout = [];
    for (let index = 0; index < Object.keys(tempdata).length; index++) {
      let arr = this.pincodewisedata.filter(
        (a) => a['pincode'] == tempdata[index]['pincode']
      );
      // console.log('*********Started***********');
      // console.log({ arr });

      let delivery_mode_unique = this.uniqueid(arr, (a) => a['delivery_mode']);
      // console.log({ delivery_mode_unique });

      let totalTapalCount = 0;
      let parcelval = 0;
      let anksankhya = 0;
      for (let j = 0; j < Object.keys(delivery_mode_unique).length; j++) {
        let arr1 = arr.filter(
          (a) => a['delivery_mode'] == delivery_mode_unique[j]['delivery_mode']
        );
        // console.log({ arr1 });
        let hasteTapalJPO = [];
        if (arr1[0]['delivery_mode'] === 'टपाल') {
          totalTapalCount = Object.keys(arr1).length;
          // console.log({ totalTapalCount });
        } else if (arr1[0]['delivery_mode'] === 'हस्ते') {
          // console.log('this might be hand delivery');

          hasteTapalJPO = arr1.filter(
            (a) => a['dis_mode'] == 'टपाल' && a['dis_sub_mode'] == 'जी.पी.ओ'
          );
          // console.log({ hasteTapalJPO });
          if (hasteTapalJPO.length > 0) {
            let hasteTapalJPOUnique = this.uniqueid(
              hasteTapalJPO,
              (a) => a['distribution_rep']
            );
            // console.log({ hasteTapalJPOUnique });
            parcelval = hasteTapalJPOUnique.length;
            hasteTapalJPOUnique.map((tapal) => {
              let ank = hasteTapalJPO.filter(
                (a) => a['distribution_rep'] === tapal['distribution_rep']
              );
              // console.log({ ank });
              anksankhya += ank.length;
            });
            // console.log({ anksankhya });
          }
        }
      }
      // console.log({ totalTapalCount });
      // console.log('*********Ended***********');

      this.filteredpincodewisesubcout.push({
        name: tempdata[index]['name'],
        Subscription_Type: tempdata[index]['Subscription_Type'],
        address: tempdata[index]['address'],
        country: tempdata[index]['country'],
        delivery_mode: tempdata[index]['delivery_mode'],
        district: tempdata[index]['district'],
        pincode: tempdata[index]['pincode'],
        state: tempdata[index]['state'],
        sub_perid_to_date: tempdata[index]['sub_perid_to_date'],
        taluka: tempdata[index]['taluka'],
        sub: totalTapalCount,
        parcel: `${parcelval}=${anksankhya}`,
      });

      this.total_pin_sub_count += totalTapalCount;
      this.totalparcel += parcelval;
      this.totalanksankhya += anksankhya;
    }

    // console.log('filteredpincodewisesubcout', this.filteredpincodewisesubcout);

    let totalcount = Object.keys(this.filteredpincodewisesubcout).length;
    let tempcount = Math.ceil(totalcount / 3);
    // console.log(Math.ceil(totalcount / 3));
    let val = 0;
    let a = 0;
    let b = 1;
    let c = 2;
    for (let i = 0; i < tempcount; i++) {
      val += 3;
      this.pincodewisesubcoutprinting.push({
        pincode1: 0,
        sub1: 0,
        parcel1: 0,
        pincode2: 0,
        sub2: 0,
        parcel2: 0,
        pincode3: 0,
        sub3: 0,
        parcel3: 0,
      });
      for (
        let index = 0;
        index < Object.keys(this.filteredpincodewisesubcout).length;
        index++
      ) {
        if (index < val) {
          // console.log('val works');
          if (index == a) {
            // console.log('a works');

            this.pincodewisesubcoutprinting[i]['pincode1'] =
              this.filteredpincodewisesubcout[index]['pincode'];
            this.pincodewisesubcoutprinting[i]['sub1'] =
              this.filteredpincodewisesubcout[index]['sub'];
            this.pincodewisesubcoutprinting[i]['parcel1'] =
              this.filteredpincodewisesubcout[index]['parcel'];
          } else if (index == b) {
            // console.log('b works');

            this.pincodewisesubcoutprinting[i]['pincode2'] =
              this.filteredpincodewisesubcout[index]['pincode'];
            this.pincodewisesubcoutprinting[i]['sub2'] =
              this.filteredpincodewisesubcout[index]['sub'];
            this.pincodewisesubcoutprinting[i]['parcel2'] =
              this.filteredpincodewisesubcout[index]['parcel'];
          } else if (index == c) {
            // console.log('c works');

            this.pincodewisesubcoutprinting[i]['pincode3'] =
              this.filteredpincodewisesubcout[index]['pincode'];
            this.pincodewisesubcoutprinting[i]['sub3'] =
              this.filteredpincodewisesubcout[index]['sub'];
            this.pincodewisesubcoutprinting[i]['parcel3'] =
              this.filteredpincodewisesubcout[index]['parcel'];
          } else {
            // console.log('continue works');

            continue;
          }
        }
      }

      a += 3;
      b += 3;
      c += 3;
    }

    // console.log('pincodewisesubcoutprinting', this.pincodewisesubcoutprinting);
  }

  uniqueid(data, key) {
    return [...new Map(data.map((x) => [key(x), x])).values()];
  }
}
