import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DigisubservicesService } from '../services/digisubservices.service';

@Component({
  selector: 'app-statewisestatement',
  templateUrl: './statewisestatement.component.html',
  styleUrls: ['./statewisestatement.component.scss']
})

export class StatewisestatementComponent {
  filtereddistrictwisesubcout:any
  sundayankDate;
  dispatchankDate;
  type: any;
  state : any
  totalcount: any;
  issuedate :any;
  country_names :any;
  data1:any
  groupedStates: { state: string; count: any; }[];

  constructor(
    private dialogref: MatDialogRef<StatewisestatementComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private service: DigisubservicesService
  ) {}

  ngOnInit(): void {
    debugger;
  // this.districtwiseData = this.data['data'];
  this.sundayankDate = this.data['sundaydate'];
  this.dispatchankDate = this.data['dispatchdate'];
  this.type = this.data['type'];
  this.state = this.data.state;
  this.country_names = this.data.country_names;
  this.issuedate = this.data.issuedate;
  this.data1 = this.data.data
  debugger;
console.log(this.data1,"statecount")
  const req:any = {
    "state": this.state,
    "issuedate" : this.issuedate,
    "country_names" : this.country_names,
    "type" : this.type,
    "sundaydate" : this.sundayankDate,
  }

  // this.service.getstatewisecount(req).subscribe((data: any) => {
  //   console.log("Get statewisedata",data)
  //     this.filtereddistrictwisesubcout = data as any;  
  //     this.totalcount = 0;
  //     for (let data1 of this.filtereddistrictwisesubcout) {
  //       debugger;
  //       this.totalcount += parseInt(data1.total_count) || 0;  
  //          }
  // });
  this.groupedStates = this.groupDataByState(this.data1);
  console.log(this.groupedStates);

 this.totalcount =0
  for (let data12 of this.groupedStates) {
    this.totalcount += parseInt(data12.count)
  }


  }
  groupDataByState(data: any[]) {
    // Use reduce to group by state and calculate the count
    const groupedData = data.reduce((acc, item) => {
      // Check if the state already exists in the accumulator
      if (!acc[item.state]) {
        acc[item.state] = 0; // Initialize with 0 if not present
      }
      acc[item.state] += 1; // Increment the count for the state
      return acc;
    }, {}); // Start with an empty object
  
    // Convert the result into an array of objects
    return Object.keys(groupedData).map(state => ({
      state: state,
      count: groupedData[state]
    }));

    

  }
  
  // Example usage
  
  

 

}
