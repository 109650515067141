<ng-container *ngIf="!isLoading">
    <div>
        <div style="margin-left: 70%; margin-top: -4%; margin-bottom: 2%">
            <button
                [printStyle]="{table : {'border-collapse':'collapse'}, td : {'border': 'solid 1px', 'font-size':'14px', 'font-weight':'600', 'text-align':'center'}, th : {'border': 'solid 1px', 'font-size':'16px', 'font-weight':'600', 'text-align':'center'}, div : {'font-size':'14px', 'font-weight':'600'}}"
                class="noPrint" style="margin-right: 2%; margin-left: 2%" mat-raised-button color="primary"
                printSectionId="print-section" ngxPrint>
                print
            </button>
            <button class="noPrint" style="margin-top: 2% 2% 0 2%;"
                (click)="exportExcel('Varganidar_Districtwise_Subscription_Report')" mat-raised-button color="primary">
                Export
            </button>
            <button mat-dialog-close class="btn noPrint">
                Close
            </button>
        </div>
    </div>
    <div>
        <div class="container" id="print-section">
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th *ngIf="totalth == 15" colspan="14">दि {{ endDate | date:"dd/MM/yyyy" }} च्या अंकाकरिता</th>
                        <th *ngIf="totalth == 16" colspan="15">दि {{ endDate | date:"dd/MM/yyyy" }} च्या अंकाकरिता</th>
                        <th *ngIf="totalth == 17" colspan="16">दि {{ endDate | date:"dd/MM/yyyy" }} च्या अंकाकरिता</th>
                        <th *ngIf="totalth == 18" colspan="17">दि {{ endDate | date:"dd/MM/yyyy" }} च्या अंकाकरिता</th>
                        <th *ngIf="totalth == 19" colspan="18">दि {{ endDate | date:"dd/MM/yyyy" }} च्या अंकाकरिता</th>
                        <th *ngIf="totalth == 20" colspan="19">दि {{ endDate | date:"dd/MM/yyyy" }} च्या अंकाकरिता</th>
                        <th *ngIf="totalth == 21" colspan="20">दि {{ endDate | date:"dd/MM/yyyy" }} च्या अंकाकरिता</th>
                        <th *ngIf="totalth == 22" colspan="21">दि {{ endDate | date:"dd/MM/yyyy" }} च्या अंकाकरिता</th>
                        <th *ngIf="totalth == 23" colspan="22">दि {{ endDate | date:"dd/MM/yyyy" }} च्या अंकाकरिता</th>
                        <th *ngIf="totalth == 24" colspan="23">दि {{ endDate | date:"dd/MM/yyyy" }} च्या अंकाकरिता</th>
                        <th *ngIf="totalth == 25" colspan="24">दि {{ endDate | date:"dd/MM/yyyy" }} च्या अंकाकरिता</th>
                        <th *ngIf="totalth == 26" colspan="25">दि {{ endDate | date:"dd/MM/yyyy" }} च्या अंकाकरिता</th>
                        <th *ngIf="totalth == 27" colspan="26">दि {{ endDate | date:"dd/MM/yyyy" }} च्या अंकाकरिता</th>
                        <th *ngIf="totalth == 28" colspan="27">दि {{ endDate | date:"dd/MM/yyyy" }} च्या अंकाकरिता</th>
                        <th *ngIf="totalth == 29" colspan="28">दि {{ endDate | date:"dd/MM/yyyy" }} च्या अंकाकरिता</th>
                        <th *ngIf="totalth == 30" colspan="29">दि {{ endDate | date:"dd/MM/yyyy" }} च्या अंकाकरिता</th>
                        <th *ngIf="totalth == 31" colspan="30">दि {{ endDate | date:"dd/MM/yyyy" }} च्या अंकाकरिता</th>
                        <th *ngIf="totalth == 32" colspan="31">दि {{ endDate | date:"dd/MM/yyyy" }} च्या अंकाकरिता</th>
                        <th *ngIf="totalth == 33" colspan="32">दि {{ endDate | date:"dd/MM/yyyy" }} च्या अंकाकरिता</th>
                        <th *ngIf="totalth == 34" colspan="33">दि {{ endDate | date:"dd/MM/yyyy" }} च्या अंकाकरिता</th>
                        <th *ngIf="totalth == 35" colspan="34">दि {{ endDate | date:"dd/MM/yyyy" }} च्या अंकाकरिता</th>
                        <th *ngIf="totalth == 36" colspan="35">दि {{ endDate | date:"dd/MM/yyyy" }} च्या अंकाकरिता</th>
                        <th *ngIf="totalth == 37" colspan="36">दि {{ endDate | date:"dd/MM/yyyy" }} च्या अंकाकरिता</th>
                        <th *ngIf="totalth == 38" colspan="37">दि {{ endDate | date:"dd/MM/yyyy" }} च्या अंकाकरिता</th>
                        <th *ngIf="totalth == 39" colspan="38">दि {{ endDate | date:"dd/MM/yyyy" }} च्या अंकाकरिता</th>
                        <th *ngIf="totalth == 40" colspan="39">दि {{ endDate | date:"dd/MM/yyyy" }} च्या अंकाकरिता</th>
                        <th *ngIf="totalth == 41" colspan="40">दि {{ endDate | date:"dd/MM/yyyy" }} च्या अंकाकरिता</th>
                        <th *ngIf="totalth == 42" colspan="41">दि {{ endDate | date:"dd/MM/yyyy" }} च्या अंकाकरिता</th>
                        <th *ngIf="totalth == 43" colspan="42">दि {{ endDate | date:"dd/MM/yyyy" }} च्या अंकाकरिता</th>
                        <th *ngIf="totalth == 44" colspan="43">दि {{ endDate | date:"dd/MM/yyyy" }} च्या अंकाकरिता</th>
                        <th *ngIf="totalth == 45" colspan="44">दि {{ endDate | date:"dd/MM/yyyy" }} च्या अंकाकरिता</th>
                        <th *ngIf="totalth == 46" colspan="45">दि {{ endDate | date:"dd/MM/yyyy" }} च्या अंकाकरिता</th>
                        <th *ngIf="totalth == 47" colspan="46">दि {{ endDate | date:"dd/MM/yyyy" }} च्या अंकाकरिता</th>
                    </tr>
                    <tr>
                        <th rowspan="2">अनु क्र.</th>
                        <th rowspan="2">जिल्हा</th>
                        <ng-container *ngFor="let item of subTypesName">
                            <th *ngIf="item['isVisible']" colspan="3">{{ item['name'] }}</th>
                        </ng-container>
                        <th colspan="3">एकूण</th>
                        <th rowspan="2"></th>
                    </tr>
                    <tr>
                        <ng-container *ngFor="let item of subTypesName">
                            <th *ngIf="item['isVisible']">पोस्ट</th>
                            <th *ngIf="item['isVisible']">हस्ते</th>
                            <th *ngIf="item['isVisible']">एकूण</th>
                        </ng-container>
                        <th>पोस्ट</th>
                        <th>हस्ते</th>
                        <th>एकूण</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of districtwiseData; let i=index">
                        <td>{{ i+1 }}</td>
                        <td>{{ item['districtName'] }}</td>
                        <ng-container *ngFor="let data of subTypesName">
                            <ng-container *ngIf="data['isVisible']">
                                <td>{{ item[getKey(data['name'])+'_पोस्ट'] }}</td>
                                <td>{{ item[getKey(data['name']+' हस्ते')] }}</td>
                                <td>{{ item[getKey(data['name']+' एकूण')] }}</td>
                            </ng-container>
                        </ng-container>
                        <td>{{ item['एकूण_पोस्ट'] }}</td>
                        <td>{{ item['एकूण_हस्ते'] }}</td>
                        <td>{{ item['एकूण'] }}</td>
                        <td>{{ item['districtName'] }}</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>एकूण</td>
                        <ng-container *ngFor="let data of subTypesName">
                            <ng-container *ngIf="data['isVisible']">
                                <td>{{ districtwiseDataTotal[getKey(data['name'])+'_पोस्ट'] }}</td>
                                <td>{{ districtwiseDataTotal[getKey(data['name']+' हस्ते')] }}</td>
                                <td>{{ districtwiseDataTotal[getKey(data['name']+' एकूण')] }}</td>
                            </ng-container>
                        </ng-container>
                        <td>{{ districtwiseDataTotal['एकूण_पोस्ट'] }}</td>
                        <td>{{ districtwiseDataTotal['एकूण_हस्ते'] }}</td>
                        <td>{{ districtwiseDataTotal['एकूण'] }}</td>
                        <td></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="isLoading">
    <div class="main-loading">
        <span class="loader"></span>
        <p>Loading...</p>
    </div>
</ng-container>