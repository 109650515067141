<ng-container>
  <div>
    <div style="margin-left: 70%; margin-bottom: 2%">
      <button
        [printStyle]="{table : {'border-collapse':'collapse'}, td : {'border': 'solid 1px', 'font-size':'12px', 'font-weight':'400', 'text-align':'center'}, th : {'border': 'solid 1px', 'font-size':'15px', 'font-weight':'600', 'text-align':'center'}, div : {'font-size':'14px', 'font-weight':'600'}, span : {'font-size':'14px', 'font-weight':'600', 'float':'left'}}"
        class="noPrint" style="margin-right: 2%; margin-left: 2%" mat-raised-button color="primary"
        printSectionId="print-section" ngxPrint>
        print
      </button>
      <button class="noPrint" style="margin-top: 2% 2% 0 2%;" (click)="exportExcel('Districtwise_Subscription_Report')"
        mat-raised-button color="primary">
        Export
      </button>
      <button mat-dialog-close class="btn noPrint">
        Close
      </button>
    </div>
  </div>
  <div>
    <div class="container" id="print-section">
      <table class="table table-bordered">
        <thead>
          <tr>
            <th *ngIf="totalth == 11" colspan="4" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
              साप्ताहिक - वर्गणी विभाग
            </th>
            <th *ngIf="totalth == 12" colspan="5" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
              साप्ताहिक - वर्गणी विभाग
            </th>
            <th *ngIf="totalth == 13" colspan="6" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
              साप्ताहिक - वर्गणी विभाग
            </th>
            <th *ngIf="totalth == 14" colspan="7" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
              साप्ताहिक - वर्गणी विभाग
            </th>
            <th *ngIf="totalth == 15" colspan="8" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
              साप्ताहिक - वर्गणी विभाग
            </th>
            <th *ngIf="totalth == 16" colspan="9" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
              साप्ताहिक - वर्गणी विभाग
            </th>
            <th *ngIf="totalth == 17" colspan="10" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
              साप्ताहिक - वर्गणी विभाग
            </th>
            <th *ngIf="totalth == 18" colspan="11" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
              साप्ताहिक - वर्गणी विभाग
            </th>
            <th *ngIf="totalth == 19" colspan="12" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
              साप्ताहिक - वर्गणी विभाग
            </th>
            <th *ngIf="totalth == 20" colspan="13" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
              साप्ताहिक - वर्गणी विभाग
            </th>
            <th *ngIf="totalth == 21" colspan="14" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
              साप्ताहिक - वर्गणी विभाग
            </th>
            <th *ngIf="totalth == 22" colspan="15" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
              साप्ताहिक - वर्गणी विभाग
            </th>
            <th *ngIf="totalth == 23" colspan="16" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
              साप्ताहिक - वर्गणी विभाग
            </th>
            <th *ngIf="totalth == 24" colspan="17" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
              साप्ताहिक - वर्गणी विभाग
            </th>
            <th *ngIf="totalth == 25" colspan="18" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
              साप्ताहिक - वर्गणी विभाग
            </th>
            <th *ngIf="totalth == 26" colspan="19" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
              साप्ताहिक - वर्गणी विभाग
            </th>
            <th *ngIf="totalth == 27" colspan="20" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
              साप्ताहिक - वर्गणी विभाग
            </th>
            <th *ngIf="totalth == 28" colspan="21" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
              साप्ताहिक - वर्गणी विभाग
            </th>
            <th *ngIf="totalth == 29" colspan="22" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
              साप्ताहिक - वर्गणी विभाग
            </th>
            <th *ngIf="totalth == 30" colspan="23" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
              साप्ताहिक - वर्गणी विभाग
            </th>
            <th *ngIf="totalth == 31" colspan="24" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
              साप्ताहिक - वर्गणी विभाग
            </th>
            <th *ngIf="totalth == 32" colspan="25" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
              साप्ताहिक - वर्गणी विभाग
            </th>
            <th *ngIf="totalth == 33" colspan="26" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
              साप्ताहिक - वर्गणी विभाग
            </th>
            <th *ngIf="totalth == 34" colspan="27" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
              साप्ताहिक - वर्गणी विभाग
            </th>
            <th *ngIf="totalth == 35" colspan="28" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
              साप्ताहिक - वर्गणी विभाग
            </th>
            <th *ngIf="totalth == 36" colspan="29" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
              साप्ताहिक - वर्गणी विभाग
            </th>
            <th *ngIf="totalth == 37" colspan="30" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
              साप्ताहिक - वर्गणी विभाग
            </th>
            <th *ngIf="totalth == 38" colspan="31" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
              साप्ताहिक - वर्गणी विभाग
            </th>
            <th *ngIf="totalth == 39" colspan="32" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
              साप्ताहिक - वर्गणी विभाग
            </th>
            <th *ngIf="totalth == 40" colspan="33" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
              साप्ताहिक - वर्गणी विभाग
            </th>
            <th *ngIf="totalth == 41" colspan="34" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
              साप्ताहिक - वर्गणी विभाग
            </th>
            <th *ngIf="totalth == 42" colspan="35" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
              साप्ताहिक - वर्गणी विभाग
            </th>
            <th *ngIf="totalth == 43" colspan="36" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
              साप्ताहिक - वर्गणी विभाग
            </th>
            <th *ngIf="totalth == 44" colspan="37" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
              साप्ताहिक - वर्गणी विभाग
            </th>
            <th *ngIf="totalth == 45" colspan="38" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
              साप्ताहिक - वर्गणी विभाग
            </th>
            <th *ngIf="totalth == 46" colspan="39" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
              साप्ताहिक - वर्गणी विभाग
            </th>
            <th *ngIf="totalth == 47" colspan="40" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
              साप्ताहिक - वर्गणी विभाग
            </th>
            <th *ngIf="totalth == 48" colspan="41" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
              साप्ताहिक - वर्गणी विभाग
            </th>
            <th *ngIf="totalth == 49" colspan="42" class="largeHeading">हिंदुस्थान प्रकाशन संस्था - विवेक
              साप्ताहिक - वर्गणी विभाग
            </th>
            <th>दिनांक -</th>
            <th>{{ today | date:'dd-MM-yyyy' }}</th>
            <th colspan="2"></th>
          </tr>
          <tr>
            <th *ngIf="totalth == 11" colspan="4" class="fontBold">
              <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण संख्या तपशील -</span> जिल्हा/तालुका निहाय
            </th>
            <th *ngIf="totalth == 12" colspan="5" class="fontBold">
              <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण संख्या तपशील -</span> जिल्हा/तालुका निहाय
            </th>
            <th *ngIf="totalth == 13" colspan="6" class="fontBold">
              <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण संख्या तपशील -</span> जिल्हा/तालुका निहाय
            </th>
            <th *ngIf="totalth == 14" colspan="7" class="fontBold">
              <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण संख्या तपशील -</span> जिल्हा/तालुका निहाय
            </th>
            <th *ngIf="totalth == 15" colspan="8" class="fontBold">
              <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण संख्या तपशील -</span> जिल्हा/तालुका निहाय
            </th>
            <th *ngIf="totalth == 16" colspan="9" class="fontBold">
              <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण संख्या तपशील -</span> जिल्हा/तालुका निहाय
            </th>
            <th *ngIf="totalth == 17" colspan="10" class="fontBold">
              <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण संख्या तपशील -</span> जिल्हा/तालुका निहाय
            </th>
            <th *ngIf="totalth == 18" colspan="11" class="fontBold">
              <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण संख्या तपशील -</span> जिल्हा/तालुका निहाय
            </th>
            <th *ngIf="totalth == 19" colspan="12" class="fontBold">
              <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण संख्या तपशील -</span> जिल्हा/तालुका निहाय
            </th>
            <th *ngIf="totalth == 20" colspan="13" class="fontBold">
              <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण संख्या तपशील -</span> जिल्हा/तालुका निहाय
            </th>
            <th *ngIf="totalth == 21" colspan="14" class="fontBold">
              <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण संख्या तपशील -</span> जिल्हा/तालुका निहाय
            </th>
            <th *ngIf="totalth == 22" colspan="15" class="fontBold">
              <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण संख्या तपशील -</span> जिल्हा/तालुका निहाय
            </th>
            <th *ngIf="totalth == 23" colspan="16" class="fontBold">
              <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण संख्या तपशील -</span> जिल्हा/तालुका निहाय
            </th>
            <th *ngIf="totalth == 24" colspan="17" class="fontBold">
              <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण संख्या तपशील -</span> जिल्हा/तालुका निहाय
            </th>
            <th *ngIf="totalth == 25" colspan="18" class="fontBold">
              <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण संख्या तपशील -</span> जिल्हा/तालुका निहाय
            </th>
            <th *ngIf="totalth == 26" colspan="19" class="fontBold">
              <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण संख्या तपशील -</span> जिल्हा/तालुका निहाय
            </th>
            <th *ngIf="totalth == 27" colspan="20" class="fontBold">
              <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण संख्या तपशील -</span> जिल्हा/तालुका निहाय
            </th>
            <th *ngIf="totalth == 28" colspan="21" class="fontBold">
              <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण संख्या तपशील -</span> जिल्हा/तालुका निहाय
            </th>
            <th *ngIf="totalth == 29" colspan="22" class="fontBold">
              <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण संख्या तपशील -</span> जिल्हा/तालुका निहाय
            </th>
            <th *ngIf="totalth == 30" colspan="23" class="fontBold">
              <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण संख्या तपशील -</span> जिल्हा/तालुका निहाय
            </th>
            <th *ngIf="totalth == 31" colspan="24" class="fontBold">
              <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण संख्या तपशील -</span> जिल्हा/तालुका निहाय
            </th>
            <th *ngIf="totalth == 32" colspan="25" class="fontBold">
              <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण संख्या तपशील -</span> जिल्हा/तालुका निहाय
            </th>
            <th *ngIf="totalth == 33" colspan="26" class="fontBold">
              <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण संख्या तपशील -</span> जिल्हा/तालुका निहाय
            </th>
            <th *ngIf="totalth == 34" colspan="27" class="fontBold">
              <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण संख्या तपशील -</span> जिल्हा/तालुका निहाय
            </th>
            <th *ngIf="totalth == 35" colspan="28" class="fontBold">
              <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण संख्या तपशील -</span> जिल्हा/तालुका निहाय
            </th>
            <th *ngIf="totalth == 36" colspan="29" class="fontBold">
              <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण संख्या तपशील -</span> जिल्हा/तालुका निहाय
            </th>
            <th *ngIf="totalth == 37" colspan="30" class="fontBold">
              <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण संख्या तपशील -</span> जिल्हा/तालुका निहाय
            </th>
            <th *ngIf="totalth == 38" colspan="31" class="fontBold">
              <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण संख्या तपशील -</span> जिल्हा/तालुका निहाय
            </th>
            <th *ngIf="totalth == 39" colspan="32" class="fontBold">
              <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण संख्या तपशील -</span> जिल्हा/तालुका निहाय
            </th>
            <th *ngIf="totalth == 40" colspan="33" class="fontBold">
              <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण संख्या तपशील -</span> जिल्हा/तालुका निहाय
            </th>
            <th *ngIf="totalth == 41" colspan="34" class="fontBold">
              <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण संख्या तपशील -</span> जिल्हा/तालुका निहाय
            </th>
            <th *ngIf="totalth == 42" colspan="35" class="fontBold">
              <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण संख्या तपशील -</span> जिल्हा/तालुका निहाय
            </th>
            <th *ngIf="totalth == 43" colspan="36" class="fontBold">
              <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण संख्या तपशील -</span> जिल्हा/तालुका निहाय
            </th>
            <th *ngIf="totalth == 44" colspan="37" class="fontBold">
              <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण संख्या तपशील -</span> जिल्हा/तालुका निहाय
            </th>
            <th *ngIf="totalth == 45" colspan="38" class="fontBold">
              <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण संख्या तपशील -</span> जिल्हा/तालुका निहाय
            </th>
            <th *ngIf="totalth == 46" colspan="39" class="fontBold">
              <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण संख्या तपशील -</span> जिल्हा/तालुका निहाय
            </th>
            <th *ngIf="totalth == 47" colspan="40" class="fontBold">
              <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण संख्या तपशील -</span> जिल्हा/तालुका निहाय
            </th>
            <th *ngIf="totalth == 48" colspan="41" class="fontBold">
              <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण संख्या तपशील -</span> जिल्हा/तालुका निहाय
            </th>
            <th *ngIf="totalth == 49" colspan="42" class="fontBold">
              <span class="left">वर्गणीदार नोंदणी - नवीन / नूतनीकरण संख्या तपशील -</span> जिल्हा/तालुका निहाय
            </th>
            <th>दिनांक -</th>
            <th>{{ startDate | date:'dd-MM-yyyy' }}</th>
            <th>पर्यंत</th>
            <th>{{ endDate | date:'dd-MM-yyyy' }}</th>
          </tr>
          <tr>
            <th rowspan="2">जिल्हा</th>
            <th rowspan="2">तालुका</th>
            <th colspan="5">एकूण</th>
            <ng-container *ngFor="let data of subTypesName">
              <ng-container *ngIf="data.isVisible">
                <th colspan="3">{{ data.name }}</th>
                <th rowspan="2">रक्कम रुपये</th>
              </ng-container>
            </ng-container>
          </tr>
          <tr>
            <th>वर्गणी दार संख्या</th>
            <th>नवीन</th>
            <th>नूतनी करण</th>
            <th>नूतनी करण बाकी</th>
            <th>रक्कम रुपये</th>
            <ng-container *ngFor="let data of subTypesName">
              <ng-container *ngIf="data.isVisible">
                <th>नवीन</th>
                <th>नूतनी करण</th>
                <th>नूतनी करण बाकी</th>
              </ng-container>
            </ng-container>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let item of finalData; let end=last;">
            <ng-container *ngIf="!end">
              <ng-container *ngFor="let data of item; let i=index; let end1=last">
                <tr *ngIf="!end1">
                  <td *ngIf="i == 0">{{ data['district'] }}</td>
                  <td *ngIf="i != 0"></td>
                  <td>{{ data['taluka'] }}</td>
                  <td>{{ data['sub'] }}</td>
                  <td>{{ data['नवीन'] }}</td>
                  <td>{{ data['नूतनीकरण'] }}</td>
                  <td>{{ data['नूतनीकरणबाकी'] }}</td>
                  <td>{{ data['एकूण'] }}</td>
                  <ng-container *ngFor="let sub of subTypesName">
                    <ng-container *ngIf="sub.isVisible">
                      <td>{{ data[getKey(sub.name)+ '_नवीन'] }}</td>
                      <td>{{ data[getKey(sub.name)+ '_नूतनीकरण']}}</td>
                      <td>{{ data[getKey(sub.name)+ '_नूतनीकरणबाकी'] }}</td>
                      <td>{{ data[getKey(sub.name)+ '_एकूण'] }}</td>
                    </ng-container>
                  </ng-container>
                </tr>
                <tr *ngIf="end1">
                  <td colspan="2" class="centerText">{{ data['name'] }}</td>
                  <td>{{ data['sub'] }}</td>
                  <td>{{ data['नवीन'] }}</td>
                  <td>{{ data['नूतनीकरण'] }}</td>
                  <td>{{ data['नूतनीकरणबाकी'] }}</td>
                  <td>{{ data['एकूण'] }}</td>
                  <ng-container *ngFor="let sub of subTypesName">
                    <ng-container *ngIf="sub.isVisible">
                      <td>{{ data[getKey(sub.name)+ '_नवीन'] }}</td>
                      <td>{{ data[getKey(sub.name)+ '_नूतनीकरण'] }}</td>
                      <td>{{ data[getKey(sub.name)+ '_नूतनीकरणबाकी'] }}</td>
                      <td>{{ data[getKey(sub.name)+ '_एकूण'] }}</td>
                    </ng-container>
                  </ng-container>
                </tr>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="end">
              <tr class="smallHeading">
                <td colspan="2">{{ item['name'] }}</td>
                <td>{{ item['sub'] }}</td>
                <td>{{ item['नवीन'] }}</td>
                <td>{{ item['नूतनीकरण'] }}</td>
                <td>{{ item['नूतनीकरणबाकी'] }}</td>
                <td>{{ item['एकूण'] }}</td>
                <ng-container *ngFor="let sub of subTypesName">
                  <ng-container *ngIf="sub.isVisible">
                    <td>{{ item[getKey(sub.name)+ '_नवीन'] }}</td>
                    <td>{{ item[getKey(sub.name)+ '_नूतनीकरण'] }}</td>
                    <td>{{ item[getKey(sub.name)+ '_नूतनीकरणबाकी'] }}</td>
                    <td>{{ item[getKey(sub.name)+ '_एकूण'] }}</td>
                  </ng-container>
                </ng-container>
              </tr>
            </ng-container>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>
</ng-container>
<div *ngIf="isLoading" class="main-loading">
  <span class="loader"></span>
  <p>Loading...</p>
</div>