import { DatePipe } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DigisubservicesService } from '../services/digisubservices.service';

@Component({
  selector: 'app-subexpcvdvreport',

  templateUrl: './subexpcvdvreport.component.html',
  styleUrl: './subexpcvdvreport.component.scss'
})
export class SubexpcvdvreportComponent {
  mainexp: any;
  subexp: any;
  startDate: any;
  endDate: any;
  allvoucherdata: any;
  dr_opening_bal: any;
  currentDate: Date;
  exp_name: any;
  cr_opening_bal: any;
  credittotal: any;
  debittotal: any;


  constructor(
    private datePipe: DatePipe,
    private dialogRef: MatDialogRef<SubexpcvdvreportComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private service: DigisubservicesService,
  ) {

  }
  getFormattedDate(): string {
    return this.datePipe.transform(this.currentDate, 'dd-MM-yyyy');
  }
  alldebitvoucher: any
  alldebitvoucher1: any
  getSubExpenseEedgeCvDvReport() {
    this.alldebitvoucher1 = [];
    let subexpid = this.subexp.map((id: any) => id).filter((id: any) => id != null).join(",")
    const cv: any = {
      "exp_id": this.mainexp,
      "sub_exp_ids": subexpid,
      "startdate": this.startDate,
      "enddate": this.endDate,

    }
    this.service.getSubExpenseEedgeCvDvReport(cv).subscribe((a) => {
      console.log(a);
      this.alldebitvoucher = a;
      this.alldebitvoucher.forEach(element => {
        let drAddition = Number(element.dr_opening_bal) + Number(element.total_debit_amount);
        let crAddition = Number(element.cr_opening_bal) + Number(element.total_credit_amount);

        if (Math.abs(drAddition) > Math.abs(crAddition)) {
          element.total_debit_amount = drAddition + crAddition;
          element.total_credit_amount = 0.0;
        } else {
          element.total_credit_amount = drAddition + crAddition;
          element.total_debit_amount = 0.0;
        }
        this.alldebitvoucher1.push(element)
      });
      this.exp_name = this.alldebitvoucher[0].exp_name
    });
  }


  getTotalDebit(): number {
    return this.alldebitvoucher.reduce((sum, item) => sum + (parseFloat(item.total_debit_amount) || 0), 0);
  }

  getTotalCredit(): number {
    return this.alldebitvoucher.reduce((sum, item) => sum + (parseFloat(item.total_credit_amount) || 0), 0);
  }

  calculation1() {


    this.allvoucherdata.forEach(element => {
      if (element['voucher'] === 'cr') {
        this.credittotal -= Number(element['exp_amt']);
      } else if (element['voucher'] === 'dr') {
        this.debittotal -= Number(element['exp_amt']);
      }

    });
  }

  ngOnInit(): void {
    this.currentDate = new Date();
    this.startDate = this.data['startDate'];
    this.endDate = this.data['endDate'];
    this.mainexp = this.data['mainexp'];
    this.subexp = this.data['subexp'];
    console.log(this.startDate, this.endDate, this.mainexp, this.subexp);
    this.getSubExpenseEedgeCvDvReport()
  }

  getdebitvocher() {
    throw new Error('Method not implemented.');
  }
  getcreditvocher() {
    throw new Error('Method not implemented.');
  }
  calculation() {
    throw new Error('Method not implemented.');
  }


}