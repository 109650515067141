<div mat-dialog-content>
    <div class="text-center">
        <h1 style="font-size: medium;font-weight: bold;" >Add New Book Stock</h1>
    </div>
    <form class="form" [formGroup]="BookStock" (ngSubmit)=update()>
        <div class="text-center">
            <h3 style="font-size: small;font-weight: bold; color: red;"></h3>
        </div>

        <div class="form-row">
            <div class="col form-group">
                <label style="margin-left: 3%; margin-top: 20px;"> Book Stock :</label>
                <input required style="width: 90%; margin-left: 3%;" formControlName="newstock" type="number" class="form-control">
            </div>

            <div class="col form-group">
                <label style="margin-left: 3%; margin-top: 20px;"> Comment :</label>
                <input required style="width: 90%; margin-left: 3%;" formControlName="comment" type="text" class="form-control">
            </div>

            <!-- form-group end.// -->
        </div>

        <div mat-dialog-actions style="float:right">
            <button mat-button mat-dialog-close>Close</button>
            <button  mat-button type="submit"> Update</button>
        </div>


    </form>

</div>