<div class="p-50">

  <form [formGroup]="CommissionPayment">
    <table>
        
          <thead>
            <tr>
              <th colspan="9" class="comm-head p-10">हिंदुस्थान प्रकाशन संस्था-विवेक साप्ताहिक</th>
            </tr>
            <tr>
              <th colspan="7" class="comm-head p-10">Advt Reprensantive Commission</th>
              <th colspan="2" class="comm-head p-10">Date</th>
            </tr>
  
            <tr class="comm-head-2">
              <th class="comm-head-2">Invoice No</th>
              <th>Invoice Date</th>
              <th>Advt Name</th>
              <th>Issue Name</th>
              <th>Issue Date</th>
              <th>Net Amount</th>
              <th>Received Amount</th>
              <th>Select For Commission</th>
              <th>Paid Commission Detail</th>
            </tr>
          </thead>
          <tbody class="comm-head-2">
            <tr *ngFor="let data of getmodaldata; let i = index">
              <td class="text-center">{{data.advt_invoice_no}}</td>
             <td class="text-center">{{data.advt_invoice_date}}</td>
             <td class="text-center">{{data.advt_name}}</td>
             <td class="text-center">{{data.DS_ISSUENAME}}</td>
             <td class="text-center">{{data.DS_ISSUEDATE}}</td>
             <td>{{data.net_amount}}</td>
             <td>{{data.rcv_amt}}</td>
             <td>{{data.copydata}}</td>
             <td></td>
            </tr>
          </tbody>
  
          <tfoot>
              <tr style="height: 40px;">
                  <td colspan="6">Total</td>
                  <td colspan="2">{{gettotal}}</td>
                  <td colspan="1"></td>
              </tr>
                  <tr style="height: 40px;">
                      <td colspan="6">Commission %</td>
                      <td colspan="1">15</td>
                      <td colspan="1">{{commission}}</td>
                      <td colspan="1"></td>
                  </tr>
                  <tr style="height: 40px;">
                    <td colspan="6">TDS %</td>
                    <td colspan="1">5</td>
                    <td colspan="1">{{tds}}</td>
                    <td colspan="1"></td>
                </tr>
                <tr style="height: 40px;">
                  <td colspan="6">Net Payble Commission</td>
                  <td colspan="1"></td>
                  <td colspan="1">{{NetPay}}</td>
                  <td colspan="1"></td>
              </tr>
              <tr>
                <td colspan="9" style="text-align: center !important; font-size: 20px;height: 60px;">Paid Commission Details</td>
              </tr>
              <tr style="height: 40px;" class="text-center">
                <td colspan="3" style="height: 40px;text-align: center !important;" >Cheque/Transaction details</td>
                <td colspan="3" style="text-align: center !important;" >Dates</td>
                <td colspan="3" style="text-align: center !important;" >Bank Name</td>
              </tr>
              <tr>
               
                <td colspan="1" style="text-align: center;padding: 5px;"> <label>Ac No</label> <input type="text" formControlName="bank_no" id="no" name="no" placeholder="No"></td>
                <td colspan="1" style="text-align: center;padding: 5px;"> <label>Ac Name</label> <input type="text" formControlName="bank_Acname" id="Acname" name="Acname" placeholder="Acc No"></td>
                <td colspan="1" style="text-align: center;padding: 5px;"> <label>Date</label> <input type="text" formControlName="bank_date" id="date" name="date" placeholder="Date"></td>
                <td colspan="1" style="text-align: center;padding: 5px;"> <label>Bank Name</label> <input type="text" formControlName="bank_bname" id="bname" name="bname" placeholder="Bank Name"></td>
                <td colspan="1" style="text-align: center;padding: 5px;"> <label>IFSC</label> <input type="text" formControlName="bank_IFSC" id="IFSC" name="IFSC" placeholder="IFSC code"></td>
                <td colspan="1" style="text-align: center;padding: 5px;"> <label>Branch</label> <input type="text" formControlName="bank_Branch" id="Branch" name="Branch" placeholder="Branch Name"></td>
                <td colspan="3" style="text-align: center;padding: 5px;"> <label>Cheque No</label> <input type="text" formControlName="bank_Cheque" id="Cheque" name="Cheque" placeholder="Branch Name"></td>
  
              </tr>
          </tfoot>
        </table>
      </form>
      <div class="row">
        <div class="col-md-9">

        </div>
        <div class="col-md-3">
          <div class="row">
            <div class="col-md-4">
              <button class="noPrint" style="margin-top: 2%; margin-left: 2%"
              (click)="savecomdetails()" mat-raised-button color="primary">
              Save
          </button>
    
            </div>
            <div class="col-md-4">
              <button class="noPrint" style="margin-top: 2%;" mat-raised-button color="primary"
              [printStyle]="{table : {'border-collapse':'collapse'}, tr : {'page-break-inside':'avoid'}, td : {'border': 'solid 1px', 'color':'#000'}, th : {'border': 'solid 1px'}, p : {'text-align': 'center','font-size':'18px','font-weight':'600'}, div : {'text-align':'left'}, span : {'text-align':'right','display':'block'}, h1 : {'width':'70px','font-size': '0.8125rem'}, h2 : {'width':'120px','font-size': '0.8125rem'}, h3 : {'width':'140px','font-size': '0.8125rem'}, h4 : {'width':'125px','font-size': '0.8125rem'}, h5 : {'font-size': '0.8125rem'}, h6 : {'width':'100px','font-size': '0.8125rem'}}"
              printSectionId="print-section" ngxPrint>
              print
          </button>
          
            </div>
            <div class="col-md-4">
              <button class="noPrint" style="margin-top: 2%" mat-button mat-dialog-close>
                Close
            </button>
            </div>
          </div>
         
      <!-- <button class="noPrint" style="margin-top: 2%;" mat-raised-button color="primary"
          [printStyle]="{table : {'border-collapse':'collapse'}, tr : {'page-break-inside':'avoid'}, td : {'border': 'solid 1px', 'color':'#000'}, th : {'border': 'solid 1px'}, p : {'text-align': 'center','font-size':'18px','font-weight':'600'}, div : {'text-align':'left'}, span : {'text-align':'right','display':'block'}, h1 : {'width':'70px','font-size': '0.8125rem'}, h2 : {'width':'120px','font-size': '0.8125rem'}, h3 : {'width':'140px','font-size': '0.8125rem'}, h4 : {'width':'125px','font-size': '0.8125rem'}, h5 : {'font-size': '0.8125rem'}, h6 : {'width':'100px','font-size': '0.8125rem'}}"
          printSectionId="print-section" ngxPrint>
          print
      </button> -->
      
      <!-- <button class="noPrint" style="margin-top: 2%" mat-button mat-dialog-close>
          Close
      </button> -->
        </div>
      </div>
        <div>
          <!-- <button class="noPrint" style="margin-top: 2%;" mat-raised-button color="primary"
              [printStyle]="{table : {'border-collapse':'collapse'}, td : {'border': 'solid 1px'}, th : {'border': 'solid 1px'}}"
              printSectionId="print-section2" ngxPrint>
              print
          </button> -->
          
      </div>



</div>



