import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DigisubservicesService } from '../services/digisubservices.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-updatejournalvoucher',
 
  templateUrl: './updatejournalvoucher.component.html',
  styleUrls:[ './updatejournalvoucher.component.scss']
})
export class UpdatejournalvoucherComponent {
data1:any
  mainExpData: any;
  getSubExpData1: any;
  id: any;
  getSubExpData: any;
  jform: FormGroup;
  status: any;
 
  constructor(
    public service: DigisubservicesService,
    private dialogRef: MatDialogRef<UpdatejournalvoucherComponent>,
    private formBuilder: FormBuilder,
    private toaster: ToastrService,
    public dialog: MatDialog,


    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.jform = formBuilder.group({
      expname: [''],
      subexp: [''],
      amt : [''],
      narration : [''],
      amt_status : [''],
      id : ['']
    });
  }

  ngOnInit(): void {
    this.data1 = this.data
    console.log(this.data1)

    this.service.getMainExpenseLedger().subscribe((res) => {
      // console.log(res);
      this.mainExpData = res;
    });

    // this.jform.get('expname').setValue(this.data.exp_name);
    this.jform.patchValue({
      expname:this.data.data.exp_id,
      subexp : this.data.data.sub_exp_id,
      amt : this.data.data.exp_amt,
      narration : this.data.data.narration,
      amt_status : this.data.data.amt_status,
      id : this.data.data.id
    })

    this.service.getSubExpenseLedgerWithId(this.data.data.exp_id).subscribe((res) => {
      this.getSubExpData = res;
    });

    
  }
  
  sub_expId = 0;
  sub_expName = '';
  getSubExpName1(event) {
    // console.log(this.getSubExpData, event.value);
debugger
    for (
      let index = 0;
      index < Object.keys(this.getSubExpData).length;
      index++
    ) {
      if (this.getSubExpData[index]['sub_id'] === event.value) {
        // console.log(this.getSubExpData[index]);
        this.sub_expId = this.getSubExpData[index]['sub_id'];
        this.sub_expName = this.getSubExpData[index]['sub_exp_name'];
      }
    }
  }

  mainexpId = 0;
  mainexpName = '';
  getMainExpName1(event) {
    for (let index = 0; index < Object.keys(this.mainExpData).length; index++) {
      if (this.mainExpData[index]['id'] === event.value) {
        this.mainexpId = this.mainExpData[index]['id'];
        this.mainexpName = this.mainExpData[index]['exp_name'];
      }
    }
    debugger;
    // console.log(exp, this.mainExpData);
    this.service.getSubExpenseLedgerWithId(event.value).subscribe((res) => {
      this.getSubExpData = res;
      // console.log(this.getSubExpData);
      
    });
  }

  selectstatus(event:any){
  this.status = event.value
  }

  updatejvocuher(){
    console.log(this.jform.value, "update value")
    this.service.updatejvoucher(this.jform.value).subscribe((res) => {
      // this.getSubExpData = res;
      // console.log(this.getSubExpData);
    });
    this.toaster.success('success', 'Journal Voucher Updated Sucessfully');
    this.ngOnInit();
    this.dialog.closeAll();
  }

}
