<div>
    <div style="margin-left: 80%; margin-bottom: 2%;">
        <button class="noPrint" style="margin-top: 2%;" mat-raised-button color="primary" [useExistingCss]="true"
            printSectionId="print-section" ngxPrint>
            print
        </button>
        
    </div>
    <div class="container" id="print-section" style="border: 2px solid black">
        
        <div class="row">
            <div class="col-2 image">
                <img style="margin-top: 6%" width="85%" src="../../../assets/img/brand/red.png" />
            </div>
            <div class="col-8" style="padding-top: 30px; text-align: center; padding-bottom: 10px">
                <span style="font-size: 35px;" class="ft-wt-500">हिंदुस्थान प्रकाशन संस्था</span>
                <span style="font-size: 14px; margin-left: 05px">(Reg.No.-BOM-3/1961(GBDSD))</span>
                <hr />
                <div class="row" style="text-align: left">
                    <div class="col-6" style="padding-top: 10px">
                        <span style="font-size: 14px"><span class="ft-wt-500" style="font-size: 16px">प्रशासकीय
                                कार्यालय :</span>
                            ३०३, वडाळा उद्योग भवन, नाईगाव क्रॉस रोड, वडाळा, मुंबई - ४०००३१<br /></span>
                        <span style="font-size: 14px"><span class="ft-wt-500" style=" font-size: 16px">दूरध्वनी:</span>
                            9967570531
                            <!-- <span style="font-weight: bold; font-size: 16px">फॅक्स:</span>
                            27810237 -->
                        </span>
                    </div>
                    <div class="col-6" style="
              border-style: solid;
              border-width: 0px 0px 0px 2px;
              padding-top: 10px;
            ">
                        <span style="font-size: 14px"><span class="ft-wt-500" style=" font-size: 16px">मुख्य कार्यालय :
                            </span>
                            5/12, कामत औद्योगिक वसाहत,<br />
                            396 स्वा. वीर सावरकर मार्ग, प्रभादेवी, मुंबई 4000 25.<br /></span>
                        <span style="font-size: 14px"><span  class="ft-wt-500" style=" font-size: 16px">दूरध्वनी :
                            </span>
                            24221440
                            <!-- <span style="font-weight: bold; font-size: 16px">फॅक्स : </span>
                            24363756 -->
                        </span>
                    </div>
                </div>
            </div>
            <div class="col-2" style="
          border-style: solid;
          border-width: 0px 0px 0px 2px;
          /* padding-top: 40px; */
          text-align: center;
          display: flex;
          align-items: center;
        ">
                <span style="font-size: 20px">क्रेडिट नोट पावती</span>
            </div>
        </div>
        <div class="row" style="
        border-style: solid;
        border-width: 2px 0px 0px 0px;
        text-align: center;
      ">
            <div class="col-6" style="padding-top: 20px; text-align: left">
                <span  class="ft-wt-500" style=" font-size: 16px">Advertieser Name : &nbsp;
                </span><span style="font-size: 14px">{{name}}</span><br />
                <span class="ft-wt-500" style=" font-size: 16px">Address : &nbsp;</span>{{add}} <span style="font-size: 14px"></span><br />
                <span class="ft-wt-500" style=" font-size: 16px">Mobile No :&nbsp;</span><span
                    style="font-size: 14px">{{mobile}}</span>
            </div>
            <div class="col-6" style="border-style: solid; border-width: 0px 0px 0px 2px">
                <div class="row" style="
            border-style: solid;
            border-width: 0px 0px 2px 0px;
            text-align: center;
            padding-top: 20px;
            padding-bottom: 10px;
          ">
                    <div class="col-6" style="text-align: left">
                        <span  style="font-weight: bold; font-size: 16px">क्रेडिट नोट क्रं.: </span><span style="font-size: 14px">{{invoicenum}}</span>
                    </div>
                    <div class="col-6" style="text-align: left">
                        <span style="font-weight: bold; font-size: 16px">दिनांक :&nbsp;</span><span
                            style="font-size: 14px">
                            {{invoicedate | date : "dd-MM-yyy"}}
                            <!-- {{this.date | date : "dd-MM-yyy"}} -->
                        </span>
                    </div>
                </div>
                <div class="row" style="padding-top: 20px">
                    <div class="col-12" style="text-align: left">
                        <span  style="font-weight: bold; font-size: 16px">यांच्याकडुन रुपये&nbsp;</span>
                        <span style="font-size: 14px">{{jama}} /-</span>
                    </div>
                </div>
                <!-- <div class="row">
                    <div class="col-12" style="text-align: right">
                        <span style="font-weight: bold; font-size: 16px">मात्र साभार पोहोचले.</span>
                    </div>
                </div> -->
                <div class="row" style="padding-top: 05px">
                    <div class="col-6" style="text-align: left">
                        <span class="ft-wt-500" style=" font-size: 16px">&nbsp;</span><span
                            style="font-size: 14px"></span>
                    </div>
                    <div class="col-6" style="text-align: left">
                        <span style="font-weight: bold; font-size: 16px">दिनांक :&nbsp;</span>
                        <span style="font-size: 14px">
                                {{invoicedate}}
                        </span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12" style="text-align: left; padding-top: 05px">
                        <span style="font-weight: bold; font-size: 16px">बँक :&nbsp;</span>
                        <span style="font-size: 14px">
                            <!-- {{
                            this.pendingAdvtData["branch_name"]
                            }} -->
                        </span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12" style="text-align: left; padding-top: 05px; padding-bottom: 10px">
                        <span style="font-weight: bold; font-size: 16px">जमा रक्कम रु.&nbsp;</span><span
                        style="font-size: 14px">{{jama}} /-</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12" style="text-align: left; padding-top: 05px; padding-bottom: 10px">
                        <span style="font-weight: bold; font-size: 16px">अक्षरी रक्कम.&nbsp;</span><span
                            style="font-size: 14px"> {{totalinWords}}</span>
                    </div>
                </div>
            </div>
        </div>
       
        <div class="row" style="
        border-style: solid;
        border-width: 2px 0px 0px 0px;
        text-align: center;
        padding-top: 20px;
      ">
            <div class="col-12" style="text-align: left">
                <span style="font-weight: bold; font-size: 16px">तपशील नंबर : </span>
                <span style="font-size: 14px"> {{invoicenum}}, 
                    &nbsp;</span>
            </div>
        </div>
        <div class="row">
            <table style="width: 100%; margin-left: 50px; margin-right: 50px; margin-top: 20px;">
                <thead class="br-top br-bottom" style="font-size: 15px; padding: 5px;">
                    <tr aria-rowspan="2">
                        <th class="br-right">Advt Name</th>
                        <th class="br-right">Bill No</th>
                        <th class="br-right">Date</th>
                       
                        <th class="br-right">TDS</th>
                        <th class="br-right">Other</th>
                        <th>Details</th>
                    </tr>
                </thead>
                <tbody class="br-top br-bottom" style="font-size: 16px; padding: 5px;">
                    <tr>
                    <td class="br-right">{{name}}</td>
                      <td class="br-right">{{ invoicenum }}</td>
                      <td class="br-right">{{ invoicedate }}</td>
                      
                      <!-- <td *ngIf="credit_type == 'GST'">{{ jama }}</td> -->
                     <td class="br-right"> <ng-container *ngIf="credit_type == 'GST'; else elseBlock">{{ jama }}</ng-container>
                      <ng-template #elseBlock>--</ng-template> </td>

                    <td class="br-right"><ng-container *ngIf="credit_type == 'Other'; else elseBlock1">{{ jama }}</ng-container>
                      <ng-template #elseBlock1>--</ng-template></td>
                      <td>{{remark}}</td>
                    </tr>
                  </tbody>
            </table>
        </div>
        <div class="row" style="margin-top: 100px; padding-bottom: 10px">
            <div class="col-6">
                <span style="font-weight: bold; font-size: 16px">(धनादेश वटल्यावरच पावती अधिकृत होईल)</span>
            </div>
            <div class="col-6" style="text-align: right; font-weight: bold; font-size: 16px">
                <span>व्यवस्थापक / लेखापाल</span>
            </div>
        </div>
    </div>
    <!-- <button class="noPrint" style="margin-top: 2%" (click)="save()" mat-raised-button color="primary">
        Save
    </button>
    <button class="noPrint" style="margin-top: 2%; margin-left: 10px" (click)="print()" mat-raised-button
        color="primary">
        Print
    </button>
    <button class="noPrint" style="margin-top: 2%; margin-left: 10px" mat-button mat-dialog-close>
        Close
    </button> -->
</div>