<ng-container *ngIf="!isLoading">
    <div>
        <div style="margin-left: 70%; margin-top: -4%; margin-bottom: 2%">
            <button
                [printStyle]="{table : {'border-collapse':'collapse'}, td : {'border': 'solid 1px', 'font-size':'14px', 'font-weight':'600', 'text-align':'center'}, th : {'border': 'solid 1px', 'font-size':'16px', 'font-weight':'600', 'text-align':'center'}, div : {'font-size':'14px', 'font-weight':'600'}}"
                class="noPrint" style="margin-right: 2%; margin-left: 2%" mat-raised-button color="primary"
                printSectionId="print-section" ngxPrint>
                print
            </button>
            <button class="noPrint" style="margin-top: 2% 2% 0 2%;"
                (click)="exportExcel('Prantwise_Subscription_Report')" mat-raised-button color="primary">
                Export
            </button>
            <button mat-dialog-close class="btn noPrint">
                Close
            </button>
        </div>
    </div>
    <div>
        <div class="container" id="print-section">
            <table class="table table-bordered">
                <thead class="br_tp_bt">
                    <tr class="br_tp_bt">
                        <th class="br_tp_bt" colspan="5" class="largeHeading">दि {{ endDate | date:"dd/MM/yyyy" }} च्या अंकाकरिता
                            वर्गणीदार सध्यस्थिती
                        </th>
                    </tr>
                    <tr>
                        <th class="br_right"></th>
                        <th class="br_right">जिल्हा</th>
                        <th class="br_right">पोस्ट</th>
                        <th class="br_right">हस्ते</th>
                        <th>एकूण</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let item of showPrantData">
                        <tr class="br_tp_bt">
                            <td colspan="5" class="smallHeading">{{ item['prantName'] }}</td>
                        </tr>
                        <ng-container *ngFor="let data of item.data; let i=index; let end=last">
                            <tr  *ngIf="!end">
                                <td class="br_right">{{ i+1 }}</td>
                                <td class="br_right">{{ data['districtName'] }}</td>
                                <td class="br_right">{{ data['post'] }}</td>
                                <td class="br_right">{{ data['haste'] }}</td>
                                <td >{{ data['total'] }}</td>
                            </tr>
                            <tr  *ngIf="end" class="fontBold">
                                <td class="br_right"></td>
                                <td class="br_right">{{ data['name'] }}</td>
                                <td class="br_right">{{ data['postTotal'] }}</td>
                                <td class="br_right">{{ data['hasteTotal'] }}</td>
                                <td >{{ data['total'] }}</td>
                            </tr>
                        </ng-container>
                    </ng-container>
                    <tr class="br_tp_bt">
                        <td colspan="5"></td>
                    </tr>
                    <tr *ngFor="let item of showOtherPrantData; let i=index">
                        <td class="br_right">{{ i+1 }}</td>
                        <td class="br_right">{{ item['name'] }}</td>
                        <td class="br_right">{{ item['post'] }}</td>
                        <td class="br_right">{{ item['haste'] }}</td>
                        <td >{{ item['total'] }}</td>
                    </tr>
                    <tr>
                        <td colspan="5"></td>
                    </tr>
                    <tr class="fontBold br_tp_bt">
                        <td class="br_right"></td>
                        <td class="br_right">एकूण</td>
                        <td class="br_right">{{ totalPostTotal }}</td>
                        <td class="br_right">{{ totalHasteTotal }}</td>
                        <td>{{ totalPostTotal + totalHasteTotal }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</ng-container>
<div *ngIf="isLoading" class="main-loading">
    <span class="loader"></span>
    <p>Loading...</p>
</div>