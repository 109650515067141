<div>
    <div style="margin-left: 80%; margin-bottom: 2%">
        <button [useExistingCss]="true" class="noPrint" style="margin-right: 2%; margin-left: 2%" mat-raised-button
            color="primary" printSectionId="print-section" ngxPrint>
            print
        </button>
        <button mat-dialog-close class="btn noPrint">Close</button>
    </div>
    <div class="container" id="print-section">
        <div style="margin: 0px 15px;">
            <div class="row">
                <div class="col-3">
                    <img src="../../../assets/img/brand/red.png" alt="logo" width="100" height="100">
                </div>
                <div class="col-6" style="margin-top: 25px;">
                    <span style="font-size: 18px;">
                        हिंदुस्थान प्रकाशन संस्था <br>
                        303, वडाळा उद्योग भवन, नायगाव क्राॅस रोड, <br>
                        वडाळा, मुंबई 400 031 दूरध्वनीः 9967570531
                    </span>
                </div>
                <div class="col-3" style="margin-top: 15px;">
                    <span style="font-size: 17px;">दिनांक - <span style="float: right;">{{ today }}</span><br />
                        अंक दिनांक - <span style="float: right;">{{ issuedate }}</span><br />
                        वितरण दिनांक - <span style="float: right;">{{ vitarandate }}</span>
                    </span>

                </div>
            </div>
            <div class="row" style="margin-top: 15px;">
                <div class="col-5">
                    <span style="margin-left: 7%;font-size: 18px;">प्रकाशन वितरण यादी - {{ dis_mode }}</span>
                </div>
                <div class="col-7">
                    <span style="font-size: 18px;">{{ sub_mode }}</span>
                </div>
            </div>
            <div class="row" style="margin-top: 15px;font-size: 18px;">
                <div class="col" style="border-width: 1px 1px 1px 1px;">नाव</div>
                <div class="col" style="border-width: 1px 1px 1px 0px;">ठिकाण</div>
                <div class="col" style="border-width: 1px 1px 1px 0px;">जिल्हा</div>
                <div class="col" style="border-width: 1px 1px 1px 0px;">अंक</div>
                <div class="col" style="border-width: 1px 1px 1px 0px;">आकार</div>
            </div>
            <ng-container *ngFor="let item of combinedList">
                <div class="row" style="font-size: 18px" *ngIf="item['sub']!=0">
                    <div class="col" style="border-width: 0px 1px 0px 1px;">{{ item['issueSellerName'] }}</div>
                    <div class="col" style="border-width: 0px 1px 0px 0px;">{{ item['dis_dist'] }}</div>
                    <div class="col" style="border-width: 0px 1px 0px 0px;">{{ item['dis_dist'] }}</div>
                    <div class="col" style="border-width: 0px 1px 0px 0px;">
                        {{ item['prati'] }}</div>
                    <div class="col" style="border-width: 0px 1px 0px 0px;"></div>
                </div> 
            </ng-container>
           
            <div class="row" style="font-size: 18px">
                <div class="col" style="border-width: 1px 1px 1px 1px;">एकूण</div>
                <div class="col" style="border-width: 1px 1px 1px 0px;"></div>
                <div class="col" style="border-width: 1px 1px 1px 0px;"></div>
                <div class="col" style="border-width: 1px 1px 1px 0px;">{{ total_sub_count }}</div>
                <div class="col" style="border-width: 1px 1px 1px 0px;"></div>
            </div>
            <div class="row" style="font-size: 18px;margin-top: 15px;">
                <div class="col-3" style="text-align: center;">
                    <span>स्वाक्षरी</span>
                </div>
                <div class="col-9"></div>
            </div>
            <div class="row" style="font-size: 18px;margin-top: 35px;text-align: center;">
                <div class="col-3">
                    <span>वितरण विभागाकरिता</span>
                </div>
                <div class="col-5"></div>
                <div class="col-4">
                    <span>व्यवस्थापक</span>
                </div>
            </div>
        </div>
    </div>
</div>