import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ContractBriefReportComponent } from '../pages/contract-brief-report/contract-brief-report.component';
import { DigisubservicesService } from '../services/digisubservices.service';


@Component({
  selector: 'app-commmodal',
  templateUrl: './commmodal.component.html',
  styleUrls: ['./commmodal.component.scss']
})
export class CommmodalComponent implements OnInit {
  myForm: FormGroup;
  data1: any;

   CommissionPayment = new FormGroup({
    bank_no: new FormControl(),
    bank_Acname: new FormControl(),
    bank_date: new FormControl(),
    end: new FormControl(),
    bank_bname: new FormControl(),
    bank_IFSC: new FormControl(),
    bank_Branch: new FormControl(),
    bank_Cheque:  new FormControl(),
    
  });
  setPaymentdata: any;

  // bank_date: any;
  // bank_bname: any;
  // bank_IFSC: any;
  // bank_no: any;
  // bank_Branch: any;
  // bank_Acname: any;

  // data: any;

  constructor(private dialogRef: MatDialogRef<ContractBriefReportComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private service: DigisubservicesService,
    private cdr: ChangeDetectorRef,private fb: FormBuilder)
   { }
   getmodaldata:any
   gettotal: any;
   commission: any;
   tds:any;
   NetPay:any;


  ngOnInit(): void {
    
    this.getmodaldata=  this.data['ModalList']

    //console.log(this.data,"newmodal data")
    this.gettotal = 0;
    for (let data1 of this.getmodaldata) {
      console.log(data1," data")
      this.gettotal += parseInt(data1.rcv_amt) || 0;
     }

     this.commission = 0;
     this.commission = this.gettotal * 0.15;

     this.tds = 0;
     this.tds = this.commission * 0.05;

     this.NetPay = 0;
     this.NetPay = this.commission - this.tds;
  }
  

  savecomdetails(){
    console.log("data saved")
    let data1=this.getmodaldata[0]
    const req:any = {
      data:{
        "advt_name":data1.advt_name,
        "invoice_no":data1.advt_invoice_no,
        "advt_no":data1.advt_id,
        "com_paid_date":this.CommissionPayment.value.bank_date,
        "creation_date":"",
        "com_amt":this.NetPay,
        "cheque_no":this.CommissionPayment.value.bank_Cheque,
        "rep_name":data1.rep_name,
        "bank_name":this.CommissionPayment.value.bank_bname,
        "ifsc_code":this.CommissionPayment.value.bank_IFSC,
        "account_no":this.CommissionPayment.value.bank_no,
        "branch":this.CommissionPayment.value.bank_Branch,
        "acc_name":this.CommissionPayment.value.bank_Acname
      }
    }
    console.log("new modal req",req)

      this.service.setPaymentcomm(req).subscribe((data:any) => {
        console.log("Payment comm data",data);
       this.setPaymentdata = (data as any);;
      
     });

}}
