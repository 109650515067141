import { formatDate } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-remaining-renewable-list-of-address',
  templateUrl: './remaining-renewable-list-of-address.component.html',
  styleUrls: ['./remaining-renewable-list-of-address.component.scss'],
})
export class RemainingRenewableListOfAddressComponent implements OnInit {
  renewable_rceipt_data = [];
  datalist;
  today;

  constructor(
    public dialogref: MatDialogRef<RemainingRenewableListOfAddressComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.renewable_rceipt_data = this.data['renewable_rceipt_data'];
    this.today = formatDate(new Date(), 'dd-MM-yyyy', 'en-IN', 'GMT+0530');

    this.renewable_remaining_list();
  }
  renewable_remaining_list() {
    let date = new Date();
    date.setMonth(date.getMonth() + 1);

    this.datalist = this.renewable_rceipt_data.filter((a) => {
      return !(
        a['sub_perid_to_date'] >
        formatDate(date, 'yyyy-MM-dd', 'en-IN', 'GMT+0530')
      );
    });

    // console.log('data', this.datalist);
  }
}
