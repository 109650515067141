<mat-card class="card">
    <form class="form" [formGroup]="Advtremainingamt">
      <div class="row text-center" >
        <div class="col-md-12" style="color: black; font-size: 25px; margin-bottom: 20px;">
          Advt Previous Data
        </div>
        <div class="col-md-12">
          <mat-form-field class="example-full-width" appearance="outline" style="width: 25%; margin-right: 3%">
            <mat-label>Advertiser Name :</mat-label>
            <input matInput formControlName="advtName" (keyup)="getAdvtNames($event)" [matAutocomplete]="auto1" />
            <mat-autocomplete autoActiveFirstOption #auto1="matAutocomplete">
              <mat-option (onSelectionChange)="selected_name1($event, option)" *ngFor="let option of advt_names"
                [value]="option['name']">
                {{ option["name"] }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>

          <mat-form-field appearance="outline" style="margin-right: 3%; width: 25%;">
            <mat-label>Representative Name : </mat-label>
            <input formControlName="rep_name" matInput [matAutocomplete]="autorepnames"  (keyup)="getRepNames($event)"/>
            <mat-autocomplete autoActiveFirstOption #autorepnames="matAutocomplete">
              <mat-option (selectionChange)="getContractRepNames($event)"
                *ngFor="let option of filterrep" [value]="option['rep_name']">
                {{ option["rep_name"] }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <mat-form-field appearance="outline" class="example-full-width" style="width: 25%; margin-right: 3%;">
            <mat-label>Invoice Number</mat-label>
            <input matInput formControlName="invoiceNumber" [(ngModel)]="invoiceNumber" (keyup)="generateinv($event)" maxlength="16" required/>
          </mat-form-field>
        
        </div>

        <div class="col-md-12">
          
          <mat-form-field appearance="outline" class="example-full-width" style="width: 25%; margin-right: 3%;">
            <mat-label>Invoice Date</mat-label>
            <input matInput [(ngModel)]="invoicedate" (dateChange)="GenerateCRno2()" formControlName="invoiceDate" required/>
          </mat-form-field>
         
          <mat-form-field class="example-full-width" style="margin-right: 3%; width: 25%" appearance="outline">
            <mat-label>Issue Name : </mat-label>
           
            <input formControlName="issueName" matInput [matAutocomplete]="autorepnames2"  (keyup)="getIssueNames($event)"/>
            <mat-autocomplete autoActiveFirstOption #autorepnames2="matAutocomplete">
              <mat-option (onSelectionChange)="getIssuesAdvtNames1($event)" *ngFor="let item of oldissuename" value="{{ item.issueName }}">
                {{ item.issueName }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
         
          <mat-form-field class="example-full-width" style="margin-right: 3%; width: 25%" appearance="outline">
            <mat-label>Issue Dates : </mat-label>
            <input  formControlName="issuedate" matInput [matAutocomplete]="autorepnames1"  (keyup)="getIssuedateNames($event)"/>
            <mat-autocomplete autoActiveFirstOption #autorepnames1="matAutocomplete">
              <mat-option *ngFor="let item of filterissuedate" (onSelectionChange)="setissueDate(item['issueDate'],item['issueName'],item['publishedDate'])"
              value="{{ [item['issueDate'] | date:'dd/MM/yyyy', item['issueName'],item['publishedDate'] | date : 'dd/MM/yyyy'] }}">{{
              item["issueDate"] | date : "dd-MM-yyy" }}
              {{ item["issueName"] }}
            </mat-option>
            </mat-autocomplete>
          </mat-form-field>

          <!-- <mat-form-field class="example-full-width" style="margin-right: 3%; width: 25%" appearance="outline">
            <mat-label>Issue Dates : </mat-label>
            <input formControlName="issueDate" matInput [matAutocomplete]="autorepnames1"  (keyup)="getIssuedateNames($event)"/>
            <mat-autocomplete autoActiveFirstOption #autorepnames1="matAutocomplete">
              <mat-option *ngFor="let item of filterissuedate" (onSelectionChange)="setissueDate(item['issueDate'])"
              value="{{ [item['issueDate'] | date:'dd/MM/yyyy', item['issueName'],item['publishedDate'] | date : 'dd/MM/yyyy'] }}">{{
              item["issueDate"] | date : "dd-MM-yyy" }}
              {{ item["issueName"] }}
            </mat-option>
            </mat-autocomplete>
          </mat-form-field> -->
        </div>

        <div class="col-md-12">
          <mat-form-field appearance="outline" class="example-full-width" style="width: 25%; margin-right: 3%; ">
            <mat-label>Total Amount</mat-label>
            <input matInput formControlName="totalamt" required/>
          </mat-form-field>

          <mat-form-field appearance="outline" class="example-full-width" style="width: 25%; margin-right: 3%;">
            <mat-label>Received Amount</mat-label>
            <input matInput formControlName="receivedAmount" />
          </mat-form-field>
          <mat-form-field appearance="outline" class="example-full-width" style="width: 25%; margin-right: 3%;">
            <mat-label>Pending Amount</mat-label>
            <input matInput formControlName="pendingamt" [value]="Advtremainingamt.get('pendingamt').value"  />
          </mat-form-field>

          
          </div>
          <div class="col-md-12">
            <mat-form-field style="margin-left: 3%" appearance="outline" style="width: 25%; margin-right: 3%">
              <mat-label>Office Representative : </mat-label>
              <mat-select #mySelr1 (selectionChange)="getContractOffRepNames($event)" formControlName="off_rep_name" >
                <!-- <mat-option value="ALL" (click)="toggleAllSelectionr1()">All</mat-option> -->
                <mat-option *ngFor="let item of filterofcrep" value="{{ item['name'] }}">{{
                  item["name"] }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <button type="button" class="new-button-css"  (click)="updateolddata()">
            Update
          </button>
         
       
      </div>
    </form>
  </mat-card>