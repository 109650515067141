import { formatDate } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DigisubservicesService } from 'src/app/services/digisubservices.service';
import { PrintGSTReportComponent } from '../../print-gstreport/print-gstreport.component';

@Component({
  selector: 'app-print-javak',
  templateUrl: './print-javak.component.html',
  styleUrls: ['./print-javak.component.scss'],
})
export class PrintJavakComponent implements OnInit {
  printJavakData;
  // tempData = [];
  tempDataTotal = {
    name: 'एकूण',
    totalVprati: 0,
    totalVkprati: 0,
    totalSbhet: 0,
    totalAikun: 0,
  };
  tempData = [
    { name: 'रेल्वे', vprati: 0, vkprati: 0, sbprati: 0, aikun: 0 },
    { name: 'एस. टी', vprati: 0, vkprati: 0, sbprati: 0, aikun: 0 },
    { name: 'वाहतूक', vprati: 0, vkprati: 0, sbprati: 0, aikun: 0 },
    { name: 'हस्ते', vprati: 0, vkprati: 0, sbprati: 0, aikun: 0 },
    { name: 'पोस्ट ', vprati: 0, vkprati: 0, sbprati: 0, aikun: 0 },
    { name: 'पोस्ट पार्सल', vprati: 0, vkprati: 0, sbprati: 0, aikun: 0 },

  ];
  // { name: '', vprati: 36, vkprati: 0, sbprati: 29, aikun: 65 },

  testData = [];
  // testData = [
  //   { name: 'जाहिरात ', aikun: 0 },
  //   { name: 'जादा विक्री ', aikun: 0 },
  //   { name: 'संपादकीय', aikun: 0 },
  //   { name: 'कार्यालय', aikun: 0 },
  // ];

  testData2 = [];
  // testData2 = [
  //   { name: 'एकूण जावक', aikun: 9997 },
  //   { name: 'छपाई', aikun: 10100 },
  //   { name: 'शिल्लक ', aikun: 130 },
  // ];
  sbhetData;
  startDate = '';
  endDate = '';
  delivery_mode = 'हस्ते';
  totalVargini = 0;
  totalVikri = 0;
  totalSBhet = 0;
  totalAikun = 0;
  disRepData: any;
  praticount: any;
  chapai: any;

  constructor(
    private dialogref: MatDialogRef<PrintGSTReportComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private service: DigisubservicesService
  ) {}

  ngOnInit(): void {
    

    console.log(this.data, "javak ngon")
   
    this.printJavakData = this.data['data'];

    this.totalVargini = Object.keys(this.printJavakData).length;

    this.endDate = this.data['issuedate'];
    let weekfirstday =
      new Date(this.data['issuedate']).getDate() -
      new Date(this.data['issuedate']).getDay();
    this.startDate = formatDate(
      new Date(this.data['issuedate']).setDate(weekfirstday - 7),
      'yyyy-MM-dd',
      'en-IN',
      'GMT+0530'
    );
    const req:any = {
      "issue_date_to":this.endDate
    }
    this.service.getJavakprati(req).subscribe((res) =>{
      this.praticount = res[0];
      console.log("praticount", this.praticount)
      debugger
      this.chapai = this.praticount['total_prati']


    })
    this.service
    .getDistributionRepresentativeSBhetHastewise()
    .subscribe((res) => {
      this.printJavakData = res;
      console.log(this.printJavakData, "javakdata");

      console.log(this.printJavakData)

      this.printJavakData.map((a) => {
        
        if (a.dis_s_bhet != null) {
          this.totalSBhet += parseInt(a.dis_s_bhet);
          console.log(a.dis_s_bhet, "a.dis_s_bhet")
        }
      });
  
      this.totalAikun = this.totalVargini + this.totalSBhet;
      console.log(this.totalSBhet, "this.totalSBhet")
  
      this.setJavakData();
    });
    this.getPrintOrderData();
  

    // this.service
    //   .getDistributionRepresentativeSBhetHastewise()
    //   .subscribe((res) => {
    //     this.sbhetData = res;
    //     // console.log(this.disRepData);
    //   });

    // this.printJavakData = this.data['data'].filter((a) => {
    //   return a['delivery_mode'] === this.delivery_mode;
    // });
    // console.log(this.printJavakData);

  
  }

  totalPrati = 0;
  getPrintOrderData() {
    if (this.startDate != '' && this.endDate != '') {
      this.service
        .getPrintOrderDataByDate(this.startDate, this.endDate)
        .subscribe((res: any) => {
          console.log(res);
          this.totalPrati = 0;
       if(res.type_data!=undefined){
        res.type_data.map((a) => {
          if (a.name === 'जाहिरात स. भेट') {
            this.testData.push({ name: 'जाहिरात', aikun: a.prati });
            this.totalPrati += parseInt(a.prati);
          } else if (a.name === 'जादा विक्री') {
            this.testData.push({ name: 'जादा विक्री', aikun: a.prati });
            this.totalPrati += parseInt(a.prati);
          } else if (a.name === 'स सप्रेम भेट') {
            this.testData.push({ name: 'संपादकीय', aikun: a.prati });
            this.totalPrati += parseInt(a.prati);
          } else if (a.name === 'कार्यालय') {
            this.testData.push({ name: 'कार्यालय', aikun: a.prati });
            this.totalPrati += parseInt(a.prati);
          }
        });
       }

         if(res.print_data!=undefined){
          this.testData2.push({
            name: 'एकूण जावक',
            aikun: this.tempDataTotal['totalAikun'] + this.totalPrati,
          });
          this.testData2.push({
            name: 'छपाई',
            aikun: res.print_data['total_prati'],
          });
          this.testData2.push({
            name: 'शिल्लक',
            aikun: Math.abs(
              this.tempDataTotal['totalAikun'] +
                this.totalPrati -
                parseInt(res.print_data['total_prati'])
            ),
          });
         }
        });
    }
  }

  setJavakData() {
    // console.log(this.printJavakData);
    let testingData = this.uniqueData(
      this.printJavakData,
      (a) => a.delivery_mode
    );
    for (let index = 0; index < testingData.length; index++) {
      // console.log(
      //   this.printJavakData.filter((a) => {
      //     return a.delivery_mode == testingData[index]['delivery_mode'];
      //   })
      // );
      let subData = this.printJavakData.filter((a) => {
        return a.delivery_mode == testingData[index]['delivery_mode'];
      });

      let data = this.uniqueData(subData, (a) => a.dis_mode);
      // console.log(data);

      for (let index = 0; index < Object.keys(data).length; index++) {
        let data1 = subData.filter((a) => {
          return a.dis_mode == data[index]['dis_mode'];
        });
        // console.log(data1);

        let totalsbhet = 0;
        data1.map((a) => {
          if (a.dis_s_bhet != null) {
            // this.totalSBhet = this.totalSBhet + parseInt(a.s_bhet);
            totalsbhet += parseInt(a.dis_s_bhet);
          }
        });
        if (
          data[index]['dis_mode'] == 'हस्ते' &&
          data[index]['delivery_mode'] !== 'टपाल'
        ) {
          this.addToObject('हस्ते', Object.keys(data1).length, totalsbhet);
        } else if (
          data[index]['dis_mode'] == 'ट्रान्सपोर्ट' &&
          data[index]['delivery_mode'] !== 'टपाल'
        ) {
          this.addToObject('वाहतूक', Object.keys(data1).length, totalsbhet);
        } else if (
          (data[index]['dis_mode'] == 'टपाल' &&
            data[index]['delivery_mode'] !== 'टपाल') ||
          data[index]['delivery_mode'] == 'टपाल'
        ) {
          this.addToObject('पोस्ट', Object.keys(data1).length, totalsbhet);
        } else if (
          (data[index]['delivery_mode'] !== 'टपाल' &&
            data[index]['dis_mode'] == 'एस . टी') ||
          (data[index]['delivery_mode'] !== 'टपाल' &&
            data[index]['dis_mode'] == 'एस. टी')
        ) {
          this.addToObject('एस. टी', Object.keys(data1).length, totalsbhet);
        } else if (
          data[index]['delivery_mode'] !== 'टपाल' &&
          data[index]['dis_mode'] == 'रेल्वे'
        ) {
          this.addToObject('रेल्वे', Object.keys(data1).length, totalsbhet);
        }
      }
    }

    // setTimeout(() => {
    let totalVprati = 0;
    let totalVkprati = 0;
    let totalSbhet = 0;
    let totalAikun = 0;
    this.tempData.map((a) => {
      totalVprati += a.vprati;
      totalVkprati += a.vkprati;
      totalSbhet += a.sbprati;
      totalAikun += a.aikun;
    });
    this.tempDataTotal['totalVprati'] = totalVprati;
    this.tempDataTotal['totalVkprati'] = totalVkprati;
    this.tempDataTotal['totalSbhet'] = totalSbhet;
    this.tempDataTotal['totalAikun'] = totalAikun;
    // console.log(this.tempData);
    // }, 1500);
  }

  addToObject(name: string, varganiCount: number, sbhet: number) {
    let data = this.tempData.find((a) => {
      return a.name.toLowerCase().indexOf(name.toLowerCase()) > -1;
    });
    // console.log(data);

    data['vprati'] += varganiCount;
    data['sbprati'] += sbhet;
    data['aikun'] += varganiCount + sbhet;
    // this.tempData.push({
    //   name: name,
    //   vprati: varganiCount,
    //   vkprati: 0,
    //   sbprati: sbhet,
    //   aikun: varganiCount + sbhet,
    // });
  }

  uniqueData(data, key) {
    return [...new Map(data.map((a) => [key(a), a])).values()];
  }
}
