<body>
  <div id="print-section">
    <div class="container">
      <div
        class="row"
        style="
          border-width: 1px 1px 0px 1px;
          border-color: black;
          border-style: solid;
          text-align: center;
          width: 1110px;
          margin-left: 0px;
        "
      >
        <div
          class="col-8"
          style="padding: 3px; font-size: 16px; font-weight: 600"
        >
          जिल्हाश्या व तालुकाश: नूतनीकरण बाकी
        </div>
        <div
          class="col-1"
          style="
            border-width: 0px 0px 0px 1px;
            border-color: black;
            border-style: solid;
            padding: 1px;
          "
        >
          दिनांक -
        </div>
        <div
          class="col-1"
          style="
            border-width: 0px 0px 0px 1px;
            border-color: black;
            border-style: solid;
            padding: 1px;
          "
        >
          {{ today }}
        </div>
        <div
          class="col-2"
          style="
            border-width: 0px 0px 0px 1px;
            border-color: black;
            border-style: solid;
            padding: 1px;
          "
        ></div>
      </div>
      <table
        class="table table-bordered"
        style="
          border-width: 1px 1px 1px 1px;
          border-color: black;
          border-style: solid;
        "
      >
        <thead>
          <tr>
            <th
              colspan="2"
              style="
                border-width: 0px 1px 0px 0px;
                border-color: black;
                border-style: solid;
              "
            >
              पावती क्रं
            </th>
            <th
              colspan="2"
              style="
                border-width: 0px 1px 0px 0px;
                border-color: black;
                border-style: solid;
              "
            >
              वर्गणीदाराचे नाव
            </th>
            <th
              colspan="2"
              style="
                border-width: 0px 1px 0px 0px;
                border-color: black;
                border-style: solid;
              "
            >
              मोबाईल नंबर
            </th>
            <th
              style="
                border-width: 0px 1px 0px 0px;
                border-color: black;
                border-style: solid;
              "
            >
              तालुका
            </th>
            <th
              style="
                border-width: 0px 1px 0px 0px;
                border-color: black;
                border-style: solid;
              "
            >
              जिल्हा
            </th>
            <th
              style="
                border-width: 0px 1px 0px 0px;
                border-color: black;
                border-style: solid;
              "
            >
              राज्य
            </th>
            <th
              style="
                border-width: 0px 1px 0px 0px;
                border-color: black;
                border-style: solid;
              "
            >
              देश
            </th>
            <th
              colspan="2"
              style="
                border-width: 0px 1px 1px 0px;
                border-color: black;
                border-style: solid;
              "
            >
              प्रतिनिधींचे नाव
            </th>
            <th
              colspan="3"
              style="
                border-width: 0px 1px 1px 0px;
                border-color: black;
                border-style: solid;
              "
            >
              कार्यालय प्रतिनिधींचे नाव
            </th>
          </tr>
          <!-- <tr>
              <th
                rowspan="2"
                style="
                  border-width: 0px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                "
              ></th>
              <th
                rowspan="2"
                style="
                  border-width: 0px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                "
              ></th>
              <th
                rowspan="2"
                style="
                  border-width: 0px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                "
              >
                वर्गणी दार संख्या
              </th>
              <th
                style="
                  border-width: 0px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                "
              >
                नवीन
              </th>
              <th
                style="
                  border-width: 0px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                "
              >
                नूतनी करण
              </th>
              <th
                style="
                  border-width: 0px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                "
              >
                नूतनी करण बाकी
              </th>
              <th
                style="
                  border-width: 0px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                "
              >
                रक्कम रुपये
              </th>
              <th
                style="
                  border-width: 0px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                "
              >
                नवीन
              </th>
              <th
                style="
                  border-width: 0px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                "
              >
                नूतनी करण
              </th>
              <th
                style="
                  border-width: 0px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                "
              >
                नूतनी करण बाकी
              </th>
              <th
                rowspan="2"
                style="
                  border-width: 0px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                "
              ></th>
              <th
                style="
                  border-width: 0px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                "
              >
                नवीन
              </th>
              <th
                style="
                  border-width: 0px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                "
              >
                नूतनी करण
              </th>
              <th
                style="
                  border-width: 0px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                "
              >
                नूतनी करण बाकी
              </th>
              <th
                rowspan="2"
                style="
                  border-width: 0px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                "
              ></th>
              <th
                style="
                  border-width: 0px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                "
              >
                नवीन
              </th>
              <th
                style="
                  border-width: 0px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                "
              >
                नूतनी करण
              </th>
              <th
                style="
                  border-width: 0px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                "
              >
                नूतनी करण बाकी
              </th>
              <th
                rowspan="2"
                style="
                  border-width: 0px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                "
              ></th>
              <th
                style="
                  border-width: 0px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                "
              >
                नवीन
              </th>
              <th
                style="
                  border-width: 0px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                "
              >
                नूतनी करण
              </th>
              <th
                style="
                  border-width: 0px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                "
              >
                नूतनी करण बाकी
              </th>
              <th
                rowspan="2"
                style="
                  border-width: 0px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                "
              ></th>
              <th
                rowspan="2"
                style="
                  border-width: 0px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                "
              ></th>
              <th
                rowspan="2"
                style="
                  border-width: 0px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                "
              ></th>
              <th
                rowspan="2"
                style="
                  border-width: 0px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                "
              ></th>
            </tr> -->
        </thead>
        <tbody>
          <tr *ngFor="let data of datalist; let first = index; let end = last">
            <td
              colspan="2"
              style="
                border-width: 1px 1px 0px 0px;
                border-color: black;
                border-style: solid;
              "
            >
              {{ data["rcpt_no"] }}
            </td>
            <td
              colspan="2"
              style="
                border-width: 1px 1px 0px 0px;
                border-color: black;
                border-style: solid;
              "
            >
              {{ data["name"] }}
            </td>
            <td
              colspan="2"
              style="
                border-width: 1px 1px 0px 0px;
                border-color: black;
                border-style: solid;
              "
            >
              {{ data["mobile_no"] }}
            </td>
            <td
              style="
                border-width: 1px 1px 0px 0px;
                border-color: black;
                border-style: solid;
              "
            >
              {{ data["taluka"] }}
            </td>
            <td
              style="
                border-width: 1px 1px 0px 0px;
                border-color: black;
                border-style: solid;
              "
            >
              {{ data["district"] }}
            </td>
            <td
              style="
                border-width: 1px 1px 0px 0px;
                border-color: black;
                border-style: solid;
              "
            >
              {{ data["state"] }}
            </td>
            <td
              style="
                border-width: 1px 1px 0px 0px;
                border-color: black;
                border-style: solid;
              "
            >
              {{ data["country"] }}
            </td>
            <td
              colspan="2"
              style="
                border-width: 1px 1px 0px 0px;
                border-color: black;
                border-style: solid;
              "
            >
              {{ data["rep_name"] }}
            </td>
            <td
              colspan="3"
              style="
                border-width: 1px 1px 0px 0px;
                border-color: black;
                border-style: solid;
              "
            >
              {{ data["office_rep_name"] }}
            </td>
          </tr>
          <!-- <tr *ngFor="let val of tempdata; let s = index">
              <td
                *ngIf="i == s"
                colspan="2"
                style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                  font-weight: bolder;
                  text-align: center;
                "
              >
                एकूण
              </td>
              <td
                *ngIf="i == s"
                style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                  font-weight: bolder;
                "
              >
                {{ val["subs_total"] }}
              </td>
              <td
                *ngIf="i == s"
                style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                  font-weight: bolder;
                "
              >
                {{ val["anew"] }}
              </td>
              <td
                *ngIf="i == s"
                style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                  font-weight: bolder;
                "
              >
                {{ val["arenew"] }}
              </td>
              <td
                *ngIf="i == s"
                style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                  font-weight: bolder;
                "
              >
                {{ val["arenew_baki"] }}
              </td>
              <td
                *ngIf="i == s"
                style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                  font-weight: bolder;
                "
              >
                {{ val["atotal"] }}
              </td>
              <td
                *ngIf="i == s"
                style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                  font-weight: bolder;
                "
              >
                {{ val["vnew"] }}
              </td>
              <td
                *ngIf="i == s"
                style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                  font-weight: bolder;
                "
              >
                {{ val["vrenew"] }}
              </td>
              <td
                *ngIf="i == s"
                style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                  font-weight: bolder;
                "
              >
                {{ val["vrenew_baki"] }}
              </td>
              <td
                *ngIf="i == s"
                style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                  font-weight: bolder;
                "
              >
                {{ val["vtotal"] }}
              </td>
              <td
                *ngIf="i == s"
                style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                  font-weight: bolder;
                "
              >
                {{ val["tnew"] }}
              </td>
              <td
                *ngIf="i == s"
                style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                  font-weight: bolder;
                "
              >
                {{ val["trenew"] }}
              </td>
              <td
                *ngIf="i == s"
                style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                  font-weight: bolder;
                "
              >
                {{ val["trenew_baki"] }}
              </td>
              <td
                *ngIf="i == s"
                style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                  font-weight: bolder;
                "
              >
                {{ val["ttotal"] }}
              </td>
              <td
                *ngIf="i == s"
                style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                  font-weight: bolder;
                "
              >
                {{ val["pnew"] }}
              </td>
              <td
                *ngIf="i == s"
                style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                  font-weight: bolder;
                "
              >
                {{ val["prenew"] }}
              </td>
              <td
                *ngIf="i == s"
                style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                  font-weight: bolder;
                "
              >
                {{ val["prenew_baki"] }}
              </td>
              <td
                *ngIf="i == s"
                style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                  font-weight: bolder;
                "
              >
                {{ val["ptotal"] }}
              </td>
              <td
                *ngIf="i == s"
                style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                  font-weight: bolder;
                "
              >
                {{ val["snew"] }}
              </td>
              <td
                *ngIf="i == s"
                style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                  font-weight: bolder;
                "
              >
                {{ val["srenew"] }}
              </td>
              <td
                *ngIf="i == s"
                style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                  font-weight: bolder;
                "
              >
                {{ val["srenew_baki"] }}
              </td>
              <td
                *ngIf="i == s"
                style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                  font-weight: bolder;
                "
              >
                {{ val["stotal"] }}
              </td>
              <td
                *ngIf="i == s"
                style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                  font-weight: bolder;
                "
              >
                {{ val["anamat"] }}
              </td>
              <td
                *ngIf="i == s"
                style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                  font-weight: bolder;
                "
              >
                {{ val["punchthev"] }}
              </td>
              <td
                *ngIf="i == s"
                style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                  font-weight: bolder;
                "
              >
                {{ val["other"] }}
              </td>
            </tr> -->
          <!-- <tr *ngIf="end">
              <td
                colspan="2"
                style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                  font-weight: bolder;
                  text-align: center;
                "
              >
                एकूण
              </td>
              <td
                style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                  font-weight: bolder;
                "
              >
                {{ subs_total }}
              </td>
              <td
                style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                  font-weight: bolder;
                "
              >
                {{ anew_total }}
              </td>
              <td
                style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                  font-weight: bolder;
                "
              >
                {{ arenew_total }}
              </td>
              <td
                style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                  font-weight: bolder;
                "
              >
                {{ arenew_baki_total }}
              </td>
              <td
                style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                  font-weight: bolder;
                "
              >
                {{ atotal_total }}
              </td>
              <td
                style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                  font-weight: bolder;
                "
              >
                {{ vnew_total }}
              </td>
              <td
                style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                  font-weight: bolder;
                "
              >
                {{ vrenew_total }}
              </td>
              <td
                style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                  font-weight: bolder;
                "
              >
                {{ vrenew_baki_total }}
              </td>
              <td
                style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                  font-weight: bolder;
                "
              >
                {{ vtotal_total }}
              </td>
              <td
                style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                  font-weight: bolder;
                "
              >
                {{ tnew_total }}
              </td>
              <td
                style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                  font-weight: bolder;
                "
              >
                {{ trenew_total }}
              </td>
              <td
                style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                  font-weight: bolder;
                "
              >
                {{ trenew_baki_total }}
              </td>
              <td
                style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                  font-weight: bolder;
                "
              >
                {{ ttotal_total }}
              </td>
              <td
                style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                  font-weight: bolder;
                "
              >
                {{ pnew_total }}
              </td>
              <td
                style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                  font-weight: bolder;
                "
              >
                {{ prenew_total }}
              </td>
              <td
                style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                  font-weight: bolder;
                "
              >
                {{ prenew_baki_total }}
              </td>
              <td
                style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                  font-weight: bolder;
                "
              >
                {{ ptotal_total }}
              </td>
              <td
                style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                  font-weight: bolder;
                "
              >
                {{ snew_total }}
              </td>
              <td
                style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                  font-weight: bolder;
                "
              >
                {{ srenew_total }}
              </td>
              <td
                style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                  font-weight: bolder;
                "
              >
                {{ srenew_baki_total }}
              </td>
              <td
                style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                  font-weight: bolder;
                "
              >
                {{ stotal_total }}
              </td>
              <td
                style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                  font-weight: bolder;
                "
              >
                {{ anamat_total }}
              </td>
              <td
                style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                  font-weight: bolder;
                "
              >
                {{ punchthev_total }}
              </td>
              <td
                style="
                  border-width: 1px 1px 0px 0px;
                  border-color: black;
                  border-style: solid;
                  font-weight: bolder;
                "
              >
                {{ other_total }}
              </td>
            </tr> -->
        </tbody>
      </table>
      <!-- </div> -->
    </div>
    <button
      [useExistingCss]="true"
      class="noPrint"
      style="margin-top: 2%"
      mat-raised-button
      color="primary"
      printSectionId="print-section"
      ngxPrint
    >
      print
    </button>
    <button
      class="noPrint"
      style="margin-top: 2%; margin-left: 2%"
      mat-raised-button
      color="info"
      mat-dialog-close
    >
      Close
    </button>
  </div>
</body>
