<div>
    <div style="margin-left: 70%;  margin-bottom: 2%">
        <!-- <button class="btn btn-info" mat-raised-button (click)="assigndisrep()">
            Add Distribution Rep.
        </button> -->
        <button [useExistingCss]="true" class="noPrint" style="margin-right: 2%; margin-left: 2%" mat-raised-button
            color="primary" printSectionId="print-section" ngxPrint>
            print
        </button>
        <!-- <button
        [printStyle]="{table : {'border-collapse':'collapse'}, td : {'border': 'solid 1px', 'font-size':'12px', 'font-weight':'400', 'text-align':'center'}, th : {'border': 'solid 1px', 'font-size':'15px', 'font-weight':'600', 'text-align':'center'}, div : {'font-size':'14px', 'font-weight':'600'}, span : {'font-size':'14px', 'font-weight':'600', 'float':'left'}}"
        class="noPrint" style="margin-right: 2%; margin-left: 2%" mat-raised-button color="primary"
        printSectionId="print-section" ngxPrint>
        print
    </button> -->
        <button mat-dialog-close class="btn noPrint">Close</button>
    </div>
    <div class="container">
        <div style="margin: 0px 15px;" id="print-section">
            <div class="row">
                <div class="col-3">
                    <img src="../../../assets/img/brand/red.png" alt="logo" width="100" height="100">
                </div>
                <div class="col-6" style="margin-top: 25px;">
                    <span style="font-size: 18px;">
                        हिंदुस्थान प्रकाशन संस्था <br>
                        303, वडाळा उद्योग भवन, नायगाव क्राॅस रोड, <br>
                        वडाळा, मुंबई 400 031 दूरध्वनीः 9967570531
                    </span>

                </div>
                <div class="col-3" style="margin-top: 15px;">
                    <button mat-raised-button color="primary" style="margin-left: 2%;width:100px" (click)="printchallan()">challan</button>

                </div>
            </div>
            <div class="row">
                <div class="col-12" style="border-width: 2px 2px 0px 2px;
                border-style: solid; border-color: black; text-align: center; font-weight: 600;
                padding-top: 5px;padding-bottom: 4px;">
                    {{ name }} - हस्तेमाध्यम
                </div>
            </div>
            <div class="row">
               
                <div class="col2" style="border-width: 2px 1px 2px 2px;font-weight: 600;">वितरकाचे नाव
                </div>
                <div class="col1" style="border-width: 2px 1px 2px 1px;font-weight: 600;">जिल्हा</div>
                <div class="col1" style="border-width: 2px 1px 2px 1px;font-weight: 600;">तालुका</div>

                <!-- <div class="col" style="border-width: 2px 1px 2px 1px;font-weight: 600;">अंकसंख्या
                </div> -->
                <div class="col" style="border-width: 2px 1px 2px 1px;font-weight: 600;">वर्गणीदार
                </div>
                <div class="col" style="border-width: 2px 1px 2px 1px;font-weight: 600;">विक्री
                </div>
                <div class="col" style="border-width: 2px 1px 2px 1px;font-weight: 600;">सप्रेमभेट
                </div>
                <div class="col" style="border-width: 2px 1px 2px 1px;font-weight: 600;">एकूण
                </div>
                <div class="col" style="border-width: 2px 1px 2px 1px;font-weight: 600;">आकार/रक्कम</div>
                <div class="col1" style="border-width: 2px 1px 2px 1px;font-weight: 600;">वितरण माध्यम</div>
                <div class="col1" style="border-width: 2px 1px 2px 1px;font-weight: 600;">सब वितरण माध्यम</div>
                <div class="col noPrint" style="border-width: 2px 2px 2px 1px;font-weight: 600;"></div>
            </div>
            <ng-container *ngFor="let item of combinedList">
            <div class="row">
                <!-- <div class="" style="border-width: 0px 1px 1px 1px;"><input type="checkbox" style="text-align: left;"></div> -->
                <div class="col2" style="border-width: 0px 1px 1px 2px;"><input type="checkbox" (change)="checkboxcheck($event, toInt(item['sub_count']) + toInt(item['s_bhet']), item['issueSellerName'])" style="text-align: left;">{{ item['issueSellerName'] }}</div>
                <div class="col1" style="border-width: 0px 1px 1px 1px;">{{ item['dis_dist'] }}</div>
                <div class="col1" style="border-width: 0px 1px 1px 1px;">{{ item['dis_tal'] }}</div>

                <div class="col" style="border-width: 0px 1px 1px 1px;">{{ item['sub_count'] }}</div>
                <div class="col" style="border-width: 0px 1px 1px 1px;">0</div>
                <div class="col" style="border-width: 0px 1px 1px 1px;">
                    <!-- <span *ngIf="item['dis_s_bhet'] === 'null'">0</span>
                    <span *ngIf="item['dis_s_bhet'] !== 'null'"></span> -->
                    {{ item['s_bhet'] }}
                </div>
                <div class="col" style="border-width: 0px 1px 1px 1px;">{{ toInt(item['sub_count']) +
                    toInt(item['s_bhet']) }}</div>
                <div class="col" style="border-width: 0px 1px 1px 1px;"></div>
                <div class="col1" style="border-width: 0px 1px 1px 1px;">
                    <input type="text" value="{{ item['dis_mode'] }}" size="8">
                </div>
                <div class="col1" style="border-width: 0px 1px 1px 1px;">
                    <input type="text" value="{{ item['dis_sub_mode'] }}" size="8">
                </div>
                <!-- *ngIf="item['dis_mode'] == null" -->
                <div class="col noPrint" style="border-width: 0px 2px 1px 1px;"><button class="btn-sm btn-outline-info"
                        mat-raised-button (click)="assigndisrep(item)">
                        Update
                    </button>
                </div>
            </div>
        </ng-container>
            <div class="row" style="font-weight: 600;">
                <div class="col2" style="border-width: 1px 1px 2px 2px;font-weight: 600;">एकूण</div>
                <div class="col1" style="border-width: 1px 1px 2px 1px;"></div>
                <div class="col1" style="border-width: 1px 1px 2px 1px;"></div>
                <div class="col" style="border-width: 1px 1px 2px 1px;font-weight: 600;">{{ total_sub }}</div>
                <div class="col" style="border-width: 1px 1px 2px 1px;font-weight: 600;"></div>
                <div class="col" style="border-width: 1px 1px 2px 1px;font-weight: 600;">{{ total_saprem_bhet }}</div>
                <div class="col" style="border-width: 1px 1px 2px 1px;font-weight: 600;">{{ toInt(total_sub) +
                    toInt(total_saprem_bhet) }}</div>
                <div class="col" style="border-width: 1px 1px 2px 1px;"></div>
                <div class="col1" style="border-width: 1px 1px 2px 1px;"></div>
                <div class="col1" style="border-width: 1px 1px 2px 1px;"></div>
                <div class="col" style="border-width: 1px 2px 2px 1px;"></div>

            </div>
        </div>
    </div>
</div>