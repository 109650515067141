import { ChangeDetectorRef, Component, Inject } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToWords } from 'to-words';
import { DigisubservicesService } from '../services/digisubservices.service';
import { TworeceiptComponent } from '../tworeceipt/tworeceipt.component';

@Component({
  selector: 'app-booktworecipt',
  standalone: true,
  imports: [],
  templateUrl: './booktworecipt.component.html',
  styleUrl: './booktworecipt.component.scss'
})
export class BooktworeciptComponent {


  receiptdata: any;
    totalinWords: String;
    toWords: any;
    receiptnumber: any
    recpt_no: any;
    data: any;
  
    constructor(private dialogRef: MatDialogRef<TworeceiptComponent>,
      @Inject(MAT_DIALOG_DATA) public data1: any,
      private service: DigisubservicesService,
      private cdr: ChangeDetectorRef, private fb: FormBuilder
    ) { }
  
    ngOnInit(): void {
  
  
      this.receiptdata = this.data1['receiptdata'];
      debugger;
  
      this.recpt_no = this.receiptdata.map(item => item.rcpt_no);
  
      console.log(this.recpt_no, "recpt number");
      
      this.service.getBookRcptwise(this.recpt_no).subscribe((res) => {
        
        this.data = res;
  
      });
  
     
  
    }
  
  
    numbertotext(rcv_amt:any){
      this.toWords = new ToWords();
      this.totalinWords = this.toWords.convert(rcv_amt, {
        currency: true,
      });
      console.log(this.totalinWords,"numbertotext")
      return this.totalinWords
    }
  
    print(): void {
      window.print();
    }
}
