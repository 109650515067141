<div class="dialog">
  <table class="table table-bordered border-primary">
    <thead>
      <tr>
        <th>Sr. No.</th>
        <th>Book Name</th>
        <th>Net Amount</th>
        <th>Book Copies</th>
        <th>Total</th>
        <th>Shipping Charges</th>
        <th>Total Value</th>
        <th>Received Amount</th>
        <th>Remaining Amount</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let book of bookdata; let i = index">
        <td>{{ i + 1 }}</td>
        <td>{{ book["book_name"] }}</td>
        <td>{{ book["netamt"] }}</td>
        <td>{{ book["copies"] }}</td>
        <td>{{ book["total"] }}</td>
        <td>{{ book["shipping_charges"] }}</td>
        <td>{{ book["total_amount"] }}</td>
        <td>
          <form [formGroup]="receivedamtform">
            <mat-form-field
              class="example-full-width"
              appearance="outline"
              style="width: 30%"
            >
              <input
                matInput
                (change)="calcamount($event, book)"
                formControlName="receivedamt"
                type="number"
              />
            </mat-form-field>
          </form>
          Previous Value : {{ book["rcvd_amt"] }}
        </td>
        <td>{{ book["remaining_amt"] }}</td>
      </tr>
      <tr>
        <td colspan="6"></td>
        <td></td>
        <td>{{ total_val }}</td>
        <td>{{ total_remaining_amt }}</td>
      </tr>
    </tbody>
  </table>
</div>

<div class="dialog">
  <button
    class="btn btn-primary"
    style="margin-top: 2%; width: 10%"
    (click)="update()"
    mat-button
  >
    Update
  </button>
  <!-- <button
    class="btn btn-danger noPrint"
    style="margin-top: 5%; margin-left: 2%"
    mat-button
    mat-dialog-close
  >
    Close
  </button> -->
</div>
