import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DigisubservicesService } from 'src/app/services/digisubservices.service';
import { ToWords } from 'to-words';

@Component({
  selector: 'app-book-crdtnote-rcpt',
  templateUrl: './book-crdtnote-rcpt.component.html',
  styleUrls: ['./book-crdtnote-rcpt.component.scss'],
})
export class BookCrdtnoteRcptComponent implements OnInit {
  crdtNoteData;
  BookBillingData;
  selectedBooks;
  crdtnotetotal;
  toWords;
  crdtnotetotalwords;
  totalamount = 0;
  crdtnoteamttotal = 0;
  address;

  constructor(
    public service: DigisubservicesService,
    private dialogRef: MatDialogRef<BookCrdtnoteRcptComponent>,
    @Inject(MAT_DIALOG_DATA) public dataa: any
  ) {
    console.log('bookcrdtnote data', this.dataa);
  }

  ngOnInit(): void {
    this.crdtNoteData = this.dataa['crdtnotedata'];
    this.BookBillingData = this.dataa['BookBillingData'];
    this.selectedBooks = this.dataa['selectedBooks'];
    this.crdtnotetotal = this.dataa['crdtnotetotal'];
    this.toWords = new ToWords();
    this.crdtnotetotalwords = this.toWords.convert(this.crdtnotetotal, {
      currency: true,
    });
    this.address = `${this.BookBillingData[0]['address']} ${this.BookBillingData[0]['taluka']}, ${this.BookBillingData[0]['district']}, ${this.BookBillingData[0]['state']}, ${this.BookBillingData[0]['country']}.`;

    for (
      let index = 0;
      index < Object.keys(this.selectedBooks).length;
      index++
    ) {
      this.totalamount =
        this.totalamount + parseInt(this.selectedBooks[index]['total']);
      this.crdtnoteamttotal =
        this.crdtnoteamttotal +
        parseInt(this.selectedBooks[index]['crdtnoteamt']);
    }
  }

  print() {
    window.print();
  }

  save() {
    let data = {
      crdtnote_no: this.crdtNoteData['crdtnoteno'],
      date: this.crdtNoteData['date'],
      rep_name: this.crdtNoteData['repname'],
      office_rep_name: this.crdtNoteData['offrepname'],
      book_seller_name: this.crdtNoteData['bsname'],
      crdtnote_total: this.crdtnotetotal,
      bookinvoice_id: this.BookBillingData[0]['id'],
    };

    this.service.insertCrdtNoteData(data).subscribe((data) => {
      console.log({ data });

      for (
        let index = 0;
        index < Object.keys(this.selectedBooks).length;
        index++
      ) {
        let books = {
          bookcrdtnote_id: data['id'],
          book_name: this.selectedBooks[index]['book_name'],
          copies: this.selectedBooks[index]['copies'],
          netamt: this.selectedBooks[index]['netamt'],
          shipping_charges: this.selectedBooks[index]['shipping_charges'],
          total: this.selectedBooks[index]['total'],
          received_amt: this.selectedBooks[index]['received_amt'],
          remaining_amt: this.selectedBooks[index]['remaining_amt'],
          returncopies: this.selectedBooks[index]['returncopies'],
          crdtnoteamt: this.selectedBooks[index]['crdtnoteamt'],
          remark: this.selectedBooks[index]['remark'],
        };

        this.service.insertCrdtNoteBooks(books).subscribe((data) => {
          // console.log({ data });
          if (index == Object.keys(this.selectedBooks).length - 1) {
            window.alert('Credit Note Added Successfully.');
            location.reload();
          }
        });
      }
    });
  }

  ConvertToInt(val) {
    return parseInt(val);
  }
}
