<head>
  <link
    rel="stylesheet"
    href="https://netdna.bootstrapcdn.com/bootstrap/3.0.0/css/bootstrap.min.css"
  />
  <link
    rel="stylesheet"
    href="https://code.jquery.com/ui/1.11.4/themes/smoothness/jquery-ui.css"
  />
</head>

<body>
  <div class="container" width="800px" id="recipt">
    <div class="row">
      <div class="col-2">
        <h1>विवेक</h1>
      </div>
      <div class="col-10">
        <p>हिंदुस्थान प्रकाशन संस्था</p>
        <p>
          मुख्य कार्यालय: १२, कामत औद्योगिक वसाहत, ३९६, वीर सावरकर मार्ग,
          प्रभादेवी, मुंबई - ४०० ०२५
        </p>
        <p>
          दूरध्वनी: २४३३१४४०/२४३७४१५८ फॅक्स: २४३६३७५६ ई-पत्र: vivekwkl&#64;yahoo.com
        </p>
        <p>
          प्रशासकीय कार्यालय: द्वारा हिंदुस्थान प्रकाशन संस्था, १२०२, विवेक भवन
          (कृष्णा रिजन्सी), प्लॉट क्र. 40, सेक्टर क्र. ३०
        </p>
        <p>
          सानपाडा, नवी मुंबई ४००७०५, दूरध्वनी: २७८१२०४८, २७८१०२३५, फॅक्स:
          २७८१०२३७.
        </p>
      </div>
    </div>
    <hr />
    <div class="row">
      <div class="col-3 heading">पावती क्र:</div>
      <div class="col-5 heading borders">जहिरात जमा पावती</div>
      <div class="col-4 heading">पावती दि:</div>
    </div>
    <div class="row">
      <div class="col-xs-12 marathi">
        <p class="text-center">{{ this.id }}</p>
      </div>
    </div>

  </div>
</body>
