import { formatDate } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DigisubservicesService } from 'src/app/services/digisubservices.service';

@Component({
  selector: 'app-return-receipt-dialog',
  templateUrl: './return-receipt-dialog.component.html',
  styleUrls: ['./return-receipt-dialog.component.scss'],
})
export class ReturnReceiptDialogComponent implements OnInit {
  returnRcptForm: FormGroup;
  today;
  bookdata;

  constructor(
    public fb: FormBuilder,
    private service: DigisubservicesService,
    private dialogref: MatDialogRef<ReturnReceiptDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.returnRcptForm = this.fb.group({
      ankdate: [''],
      date: [''],
      reason: [''],
      karyavahi: [''],
    });

    this.bookdata = this.data['bookdata'];

    this.today = formatDate(new Date(), 'yyyy-MM-dd', 'en-IN', 'GMT+0530');
    console.log(this.today);
    this.returnRcptForm.get('date').setValue(this.today);
    this.returnRcptForm.get('ankdate').setValue(this.bookdata['entry_date']);

    // console.log(
    //   formatDate(this.bookdata['entry_date'], 'dd-MM-yyyy', 'en-IN', 'GMT+0530')
    // );
  }

  submit() {
    let data = {
      id: this.bookdata['sub_id'],
      ankdate: this.returnRcptForm.get('ankdate').value,
      date: this.returnRcptForm.get('date').value,
      reason: this.returnRcptForm.get('reason').value,
      karyavahi: this.returnRcptForm.get('karyavahi').value,
    };

    // console.log({ data });

    this.service.insertReturnRcpt(data).subscribe((data) => {
      alert('Return receipt submitted successfully.');
    });

    this.dialogref.close({
      data: 'close',
    });
  }
}
