import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DigisubservicesService } from 'src/app/services/digisubservices.service';

@Component({
  selector: 'app-show-book-seller-outstanding-data',
  templateUrl: './show-book-seller-outstanding-data.component.html',
  styleUrls: ['./show-book-seller-outstanding-data.component.scss'],
})
export class ShowBookSellerOutstandingDataComponent implements OnInit {
  BookSellerOutStandingData;
  outstandingReport = [];
  officerepname;
  tempdata1 = [];
  data1 = [];
  total = 0;

  constructor(
    private service: DigisubservicesService,
    private dialogref: MatDialogRef<ShowBookSellerOutstandingDataComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.officerepname = this.data['officerepname'];
    this.BookSellerOutStandingData = this.data['booksellerdata'];
    let count = 0;
    for (
      let index = 0;
      index < Object.keys(this.BookSellerOutStandingData).length;
      index++
    ) {
      let flag = false;
      if (Object.keys(this.tempdata1).length == 0) {
        this.tempdata1.push(this.BookSellerOutStandingData[index]);
        count++;
      } else {
        for (
          let index1 = 0;
          index1 < Object.keys(this.tempdata1).length;
          index1++
        ) {
          if (
            this.tempdata1[index1]['rep_name'] ==
            this.BookSellerOutStandingData[index]['rep_name']
          ) {
            flag = true;
          }
        }
        if (!flag) {
          this.tempdata1.push(this.BookSellerOutStandingData[index]);
          count++;
        }
      }
    }
    // console.log(this.tempdata1);
    for (let index = 0; index < Object.keys(this.tempdata1).length; index++) {
      let arr = this.BookSellerOutStandingData.filter(
        (a) =>
          a['rep_name']
            .toLowerCase()
            .indexOf(this.tempdata1[index]['rep_name'].toLowerCase()) > -1
      );

      let addinvoicetotal = 0;
      let addremainingamt = 0;
      for (let index1 = 0; index1 < Object.keys(arr).length; index1++) {
        // if (arr[index1]['remaining_amt'] === '0') {
        //   addremainingamt =
        //     addremainingamt + parseInt(arr[index1]['remaining_amt']);
        // } else {
        //   addremainingamt =
        //     addremainingamt +
        //     (parseInt(arr[index1]['invoice_total']) -
        //       parseInt(arr[index1]['remaining_amt']));
        // }
        addremainingamt =
          addremainingamt + parseInt(arr[index1]['remaining_amt']);
        addinvoicetotal =
          addinvoicetotal + parseInt(arr[index1]['invoice_total']);
      }

      this.total = this.total + addremainingamt;
      this.data1.push({
        invoice_total: addinvoicetotal,
        remaining_amt: addremainingamt,
        received_amt: addinvoicetotal - addremainingamt,
        rep_name: arr[0]['rep_name'],
        rep_mob_no: arr[0]['rep_mob_no'],
      });
      this.outstandingReport.push(arr);
    }
    console.log(this.outstandingReport);
    console.log(this.data1);
  }

  ConvertToInt(num) {
    return parseInt(num);
  }

  print() {
    window.print();
  }
}
