import { formatDate } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DigisubservicesService } from 'src/app/services/digisubservices.service';

@Component({
  selector: 'app-bookstoresendmessage',
  templateUrl: './bookstoresendmessage.component.html',
  styleUrls: ['./bookstoresendmessage.component.scss'],
})
export class BookstoresendmessageComponent implements OnInit {
  sendmsgform: FormGroup;
  book;
  books;
  constructor(
    public fb: FormBuilder,
    public service: DigisubservicesService,
    private dialogRef: MatDialogRef<BookstoresendmessageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.sendmsgform = this.fb.group({
      bsname: [''],
      repname: [''],
      orderno: [''],
      orderdate: [''],
      billno: [''],
      billdate: [''],
      receiptno: [''],
      receiptdate: [''],
      address: [''],
      mobno: [''],
      date: [''],
      couriername: [''],
      courierdate: [''],
      courierwebsite: [''],
    });
  }

  ngOnInit(): void {
    this.book = this.data['book'];
    console.log('book data', this.book);
    // let add = `${this.book['address']}${this.book['taluka']}${this.book['district']}${this.book['state']}${this.book['country']}-${this.book['pincode']}`;
    this.sendmsgform.get('bsname').setValue(this.book['name']);
    this.sendmsgform.get('repname').setValue(this.book['rep']);
    let add = `${this.book['address']} ${this.book['taluka']}, ${this.book['district']}, ${this.book['state']}, ${this.book['country']} - ${this.book['pincode']}`;
    this.sendmsgform.get('address').setValue(add);
    this.sendmsgform.get('mobno').setValue(this.book['mobile_no']);
    this.sendmsgform.get('orderno').setValue(this.book['order_no']);
    this.sendmsgform
      .get('orderdate')
      .setValue(
        formatDate(this.book['bookingdate'], 'dd-MM-yyyy', 'en-IN', 'GMT+0530')
      );
    this.sendmsgform.get('billno').setValue(this.book['invoice_no']);
    this.sendmsgform
      .get('billdate')
      .setValue(
        formatDate(this.book['invoice_date'], 'dd-MM-yyyy', 'en-IN', 'GMT+0530')
      );
    this.sendmsgform.get('receiptno').setValue(this.book['rcptno']);
    this.sendmsgform
      .get('receiptdate')
      .setValue(
        formatDate(this.book['rcptdate'], 'dd-MM-yyyy', 'en-IN', 'GMT+0530')
      );
    console.log(this.book['id']);
    this.service.getBooks(this.book['id']).subscribe((data) => {
      this.books = data;
      console.log('books', this.books);
    });
  }

  sendmsg() {
    let sendmsg = {
      couriername: this.sendmsgform.get('couriername').value,
      courierdate: this.sendmsgform.get('courierdate').value,
      courierwebsite: this.sendmsgform.get('courierwebsite').value,
    };

    alert('msg not sent.');
    console.log('msg not sent.', sendmsg);
    this.sendmsgform.reset();
    console.log(this.book['id']);
    this.service.updateBooksAsDeliverd(this.book['id']).subscribe((data) => {
      console.log(data);
    });
    this.dialogRef.close();
  }

  print() {
    window.print();
  }
}
