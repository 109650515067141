import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { DigisubservicesService } from 'src/app/services/digisubservices.service';

export const _filter1 = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();

  return opt.filter((item) => item.toLowerCase().includes(filterValue));
};

@Component({
  selector: 'app-author-address',
  templateUrl: './author-address.component.html',
  styleUrls: ['./author-address.component.scss'],
})
export class AuthorAddressComponent implements OnInit {
  authorData;
  FilteredauthorData;
  authoraddressForm: FormGroup;
  filteredOptions1: Observable<string[]>;
  authornames = [];

  constructor(
    private fb: FormBuilder,
    private service: DigisubservicesService,
    private dialogRef: MatDialogRef<AuthorAddressComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.authoraddressForm = fb.group({
      authorname: [''],
    });
  }

  ngOnInit(): void {
    this.filteredOptions1 = this.authoraddressForm
      .get('authorname')
      .valueChanges.pipe(
        startWith(''),
        map((value) => (typeof value === 'string' ? value : value.name)),
        map((name) => (name ? this._filter1(name) : this.authornames.slice()))
      );

    this.getAuthorData();
  }

  private _filter1(name: string): any[] {
    const filterValue = name;
    return this.authornames.filter((option) =>
      // option.mobno.toLowerCase().includes(filterValue)
      option.name.toLowerCase().includes(filterValue)
    );
  }

  getAuthorData() {
    this.service.getAuthorData().subscribe((data) => {
      this.authorData = data;
      this.FilteredauthorData = this.authorData;
      // console.log(data);
      for (
        let index = 0;
        index < Object.keys(this.authorData).length;
        index++
      ) {
        this.authornames.push({
          id: this.authorData[index]['id'],
          name: this.authorData[index]['author_name'],
        });
      }
    });
  }

  selected_authorname(event, option) {
    if (event.isUserInput) {
      this.FilteredauthorData = this.authorData.filter(
        (a) =>
          a['author_name'].toLowerCase().indexOf(option['name'].toLowerCase()) >
          -1
      );
    }
  }

  print() {
    window.print();
  }
}
