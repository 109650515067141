import { Component, Inject } from '@angular/core';
import { SubscriptionDetailsComponent } from '../pages/subscription/subscription-details/subscription-details.component';
import { DigisubservicesService } from '../services/digisubservices.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-printchallan',
  templateUrl: './printchallan.component.html',
  styleUrls: ['./printchallan.component.scss']
})
export class PrintchallanComponent {
  dialogRef: any;
  issuedate: any;
  challandata: any;
  amount: [];
  price: Number;
  amounts=[]
  vitarandate: any;

  close(){
    this.dialogRef.close();
  }
  constructor(
    private dialogref: MatDialogRef<PrintchallanComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,  public dialog: MatDialog,
    private router: Router,
    private service: DigisubservicesService
  ) {}
  names:any
  ngOnInit() {
    console.log(this.data, "check data for challan")
    this.issuedate = this.data['issuedate'];
    this.amount = this.data['amount'];
    this.names = this.data['names'];
    this.vitarandate = this.data['vitarandate'];


    console.log("issuedate",this.issuedate, this.amount, this.names)
    this.runapi();
  }
  stringArray: string[] = [];
  runapi(){
    this.stringArray = this.amount.map((num:any) => num.toString());
    const req:any = {
      "issuedate":this.issuedate,
      "value" : this.stringArray,
      "name" : this.names
    }
    console.log(req, "req for challan")
    this.service.challandata(req).subscribe((data:any) => {
      console.log("challan=",data);
     this.challandata = (data as any);;
 
     this.price =Number( this.challandata.pricing[0]['price']);
     console.log("price",this.price)

     this.amounts=[]
     this.amount.forEach((element: any, index: number) => {
      // Check if the index is within the bounds of this.challandata.rep_add
      if (index < this.challandata.rep_add.length) {
        // Create a new object using the current element and corresponding address
        const req = {
          amount: Number(element),
          address: this.challandata.rep_add[index].address,
          pincode:this.challandata.rep_add[index].pincode,
          name:this.challandata.rep_add[index].issueSellerName,
          taluka:this.challandata.rep_add[index].taluka,
          mobile:this.challandata.rep_add[index].mobile_no,
        };
    
        // Push the new object to amounts array
        this.amounts.push(req);
      }
    });
  })
  }
//   runapi1(){
//     
//     // const req1:any = {
//     //   "value" : this.amount[0],
//     //   "name" : this.amount[1]
//     // }
//     if (this.amount.length >= 2) {
//       const req1: any = {
//         "value": this.amount[0],  // Assigning first element of this.amount to 'value'
//         "name": this.amount[1]    // Assigning second element of this.amount to 'name'
//       };
//   //   this.service.challandata1(req1).subscribe((data:any) => {
//   //     console.log("challan=",data);
//   //    this.challandata = (data as any);;
 
//   //    this.price = this.challandata[0]['price'];
//   //    console.log("price",this.price)
     
//   //  });
//    console.log(req1, "req run api")
//   }
 

// }
}
