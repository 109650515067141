<div>
  <div>
    <div style="margin-left: 50%; margin-top: -4%; margin-bottom: 2%">
      <!-- <button mat-raised-button class="btn btn-info noPrint" (click)="print()"
        style="margin-right: 2%; margin-left: 2%">
        Print
      </button> -->
      <button [useExistingCss]="true" class="noPrint" style="margin-right: 2%; margin-left: 2%" mat-raised-button
        color="primary" printSectionId="print-section" ngxPrint>
        print
      </button>
      <button mat-dialog-close class="btn noPrint">Close</button>
    </div>
  </div>
  <div class="container" id="print-section">
    <div style="margin-bottom: 1%; font-size: 17px">
      <span *ngIf="this.officerepname != ''"><b>Office Representative</b>: {{ this.officerepname }}</span>
    </div>
    <div *ngFor="let item of outstandingReport; let end = last; let i = index" style="font-size: 17px">
      <div *ngFor="let val of data1; let s = index">
        <div style="margin-bottom: 1%">
          <span *ngIf="i == s"><b>Representative Name:</b> {{ val["rep_name"] }}</span>
          <span *ngIf="i == s" style="display: flex; justify-content: right"><b>Mobile No:</b> {{ val["rep_mob_no"]
            }}</span>
        </div>
        <table *ngIf="i == s" class="table table-bordered" style="margin-bottom: 4%">
          <thead>
            <tr>
              <th style="font-size: 17px">Bill Date</th>
              <th style="font-size: 17px">Bill No</th>
              <th style="font-size: 17px">Book Seller Name<br />Mobile No</th>
              <th style="font-size: 17px">
                Invoice Amount<br />
                Total
              </th>
              <th style="font-size: 17px">
                Received<br />
                Amount
              </th>
              <th style="font-size: 17px">
                Pending<br />
                Amount
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of item">
              <td style="font-size: 17px">
                {{ data["invoice_date"] | date: "dd-MM-yyy" }}
              </td>
              <td style="font-size: 17px">{{ data["invoice_no"] }}</td>
              <td style="font-size: 17px">
                {{ data["name"] }}<br />{{ data["mobile"] }}
              </td>
              <td style="font-size: 17px">{{ data["invoice_total"] }}</td>
              <td style="font-size: 17px">
                <!-- <span *ngIf="data['remaining_amt'] === '0'">
                {{data["remaining_amt"]
                }}
              </span> -->
                <!-- <span *ngIf="data['remaining_amt'] !== '0'"> -->
                {{
                ConvertToInt(data["invoice_total"]) -
                ConvertToInt(data["remaining_amt"])
                }}
                <!-- </span> -->
              </td>
              <td style="font-size: 17px">
                <!-- <span *ngIf="data['remaining_amt'] === '0'">
                {{
                ConvertToInt(data["invoice_total"]) - ConvertToInt(data["remaining_amt"])
                }}
              </span>
              <span *ngIf="data['remaining_amt'] !== '0'"> -->
                {{ data["remaining_amt"] }}

              </td>
            </tr>
            <tr>
              <td colspan="3" style="font-size: 17px"><b>Total</b></td>
              <td style="font-size: 17px">{{ val["invoice_total"] }}</td>
              <td style="font-size: 17px">{{ val["received_amt"] }}</td>
              <td style="font-size: 17px">{{ val["remaining_amt"] }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div>
        <span *ngIf="end" style="font-size: 17px; margin-left: 85%">Total: {{ this.total }}</span>
      </div>
    </div>
  </div>
</div>