import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DigisubservicesService } from 'src/app/services/digisubservices.service';

@Component({
  selector: 'app-update-prant',
  templateUrl: './update-prant.component.html',
  styleUrls: ['./update-prant.component.scss'],
})
export class UpdatePrantComponent implements OnInit {
  prantData;
  prantForm: FormGroup;
  constructor(
    private dialogRef: MatDialogRef<UpdatePrantComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private service: DigisubservicesService,
    public fb: FormBuilder
  ) {
    this.prantForm = fb.group({
      prant_id: [''],
      prant_name: [''],
    });
  }

  ngOnInit(): void {
    this.prantData = this.data['prant'];
    this.prantForm.get('prant_id').setValue(this.prantData['id']);
    this.prantForm.get('prant_name').setValue(this.prantData['prant_name']);
  }

  updatePrant() {
    if (this.prantForm.get('prant_id').value > 0) {
      this.service
        .updatePrantData(
          parseInt(this.prantForm.get('prant_id').value),
          this.prantForm.get('prant_name').value
        )
        .subscribe((res) => {
          alert('Prant Name Updated Successfully...');
          this.dialogRef.close({ res: 'close' });
        });
    }
  }
}
