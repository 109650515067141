import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DigisubservicesService } from 'src/app/services/digisubservices.service';

@Component({
  selector: 'app-subscribersreceipt',
  templateUrl: './subscribersreceipt.component.html',
  styleUrls: ['./subscribersreceipt.component.scss'],
})
export class SubscribersreceiptComponent implements OnInit {
  subscriberdata = [];
  constructor(
    public service: DigisubservicesService,
    private dialogRef: MatDialogRef<SubscribersreceiptComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.subscriberdata = this.data;
  }
}
