<div>
    <div style="margin-left: 80%;  margin-bottom: 2%;">
        <button [useExistingCss]="true" class="noPrint" style="margin-right: 2%; margin-left: 2%; z-index: 10;font-size: 12px; font-weight: 600;"
            mat-raised-button color="primary" printTitle="Label" printSectionId="print-section" ngxPrint>
            print
        </button>
        <!-- <button mat-raised-button class="btn btn-info noPrint" (click)="print()"
            style="margin-right: 2%; margin-left: 2%">
            Print
        </button> margin-bottom: 4%;margin-top: 1.5%; -->
        <button mat-dialog-close class="btn noPrint" style=" z-index: 10">Close</button>
    </div>
    <div class="wrapper" id="print-section" style="background-color: white;">
        <table style="margin: 1cm 0.2cm 1cm 0.2cm">
            <thead>
                <tr>
                    <td>
                        <div class="header-space">&nbsp;</div>
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        <div class="row" >
                            <!-- *ngFor="let item of data" -->
                            <div class="col-3" *ngFor="let item of data1; let i=index" style="min-height: 190px !important;padding-left: 50px !important;" [style.page-break-inside]="'avoid'"
                            [ngClass]="{ 'print-margin-top': i > 27 , 'top-margin': (i > 11 && i < 27) , 'tops-margin': (i % 28 > 16)}" >
                            <div *ngIf="i > 4 &&  i % 28 < 4;  " class="page-break print-margin-top"></div>
                            
                                <!-- Your content here -->
                                <span style="font-size: 15px; font-weight: 400;" *ngIf="iscustomer=='yes'">
                                    {{ item['shortname'] }}{{ item['month']}}/{{ item['subno']}}-{{ item['year'] }}
                                </span><br />
                                <span style="font-size: 15px; font-weight: 400;"> {{ item["title"] }} {{ item["name"] }}</span><br />
                                <span style="font-size: 15px; font-weight: 400;">{{ item["address"] }}</span><br />
                                <span style="font-size: 15px; font-weight: 400;">{{ item["taluka"] }}</span><br />
                                <span style="font-size: 15px; font-weight: 400;">{{ item["district"] }}</span>
                                <span style="font-size: 15px; font-weight: 400;" *ngIf="item['pincode'] != 0"> - {{ item["pincode"] }} {{ item["state"] }}</span><br>
                                <span style="font-size: 15px; font-weight: 400;" >मो.
                                    नं. - {{
                                    item["mobileNumber"]
                                    }}</span>
                            </div>
                            
                            <!-- <h3 class="first"></h3>-->
                            <!-- <div id="insert"></div> -->
                
                        </div>
                       
                    </td>
                </tr>
            </tbody>
            <!-- <tfoot>
                <tr>
                    <td>
                        <div class="footer-space">&nbsp;</div>
                    </td>
                </tr>
            </tfoot> -->
        </table>
        <div class="header"> <span style="margin-left: 5%;">दि.{{today | date:'dd/MM/yyyy hh:mm:ss'}} साप्तहिक विवेक</span></div>
        <!-- <div class="footer">...</div> -->
    </div>
</div>

<!-- <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.2.1/jquery.min.js">
        var bottom = 0;
        $(document).ready(function () {
            $('.col-3:nth-child(100n)').each(function () {
                bottom -= 100;
                botString = bottom.toString();
                var $counter = $('h3.first').clone().removeClass('first');
                $counter.css('bottom', botString + 'vh');
                ($counter).insertBefore('.insert');
            });
        });
    </script> -->
<!-- </body> -->