import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hand-delivery-indetail-report',
  templateUrl: './hand-delivery-indetail-report.component.html',
  styleUrls: ['./hand-delivery-indetail-report.component.scss']
})
export class HandDeliveryIndetailReportComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
