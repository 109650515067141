import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DigisubservicesService } from 'src/app/services/digisubservices.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cancel-sub-dialog',
  templateUrl: './cancel-sub-dialog.component.html',
  styleUrls: ['./cancel-sub-dialog.component.scss']
})
export class CancelSubDialogComponent implements OnInit {

  form: FormGroup
  data2

  constructor(private router: Router, private fb: FormBuilder, public service: DigisubservicesService,
    private dialogRef: MatDialogRef<CancelSubDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.form = fb.group({
      otp: ['']
    });
  }

  ngOnInit(): void {
    if (sessionStorage.getItem("mobile") == null) {
      this.router.navigate(['/']);
    }

  }

  submitForm() {
    if (this.data.message == this.form.get("otp").value) {
            //console.log(this.data)
      console.log(this.data.magdata)

      for (let index = 0; index < Object.keys(this.data.magdata).length; index++) {

        if (this.data.magdata['cancelmags' + index]['name'] != "") {

          this.service.updateMagCancel(parseInt(this.data.magdata['mobileNumber']),
            this.data.magdata['cancelmags' + index]['name'], this.data.magdata['cancelmags' + index]['edition']).subscribe(a => {
              
              let msg = "Dear Subscriber (Sub.id : ) , Your subscription for "+this.data.magdata['cancelmags' + index]['name']+
        " has been cancelled. Thanks, Hindustan Prakashan Sanstha"
          let template="Vivek26"

              //let msg = "Dear Subscriber (Sub.id : ) , Your subscription for " + this.data.magdata['cancelmags' + index]['name'] +
                " has been cancelled."
              //let template = "Brahman11"

              this.service.sendSMS(parseInt(this.data.magdata['mobileNumber']), msg, template).subscribe(a => { });

            })
        }


      }
      this.dialogRef.close()

    }
    else {
      window.alert("Error While Canceling the Subscription");
    }
  }

}
