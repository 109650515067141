<div *ngIf="reportType == 'briefly'">
    <div style="margin-left: 80%; margin-bottom: 2%;">
        <button class="noPrint" [useExistingCss]="true" style="margin-top: 2%;" mat-raised-button color="primary"
            printSectionId="print-section" ngxPrint>
            print
        </button>
        <button class="noPrint" style="margin-top: 2%; margin-left: 2%" (click)="exportExcel('Contract_Details_Report')"
            mat-raised-button color="primary">
            Export
        </button>
        <button class="noPrint" style="margin-top: 2%" mat-button mat-dialog-close>
            Close
        </button>
    </div>
    <div class="container" id="print-section">
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th colspan="11" class="br-bottom br-top br-right">सा. विवेक जाहिरात वार्षिक करार २०२२-२३ नोंदणी तक्ता</th>
                    <th colspan="2" class="br-bottom br-top br-right">Brief</th>
                    <th colspan="2" class="br-bottom br-top"></th>
                </tr>
                <tr>
                    <th class="br-bottom br-right br-top">अक्र.</th>
                    <th class="br-bottom br-right br-top">जाहिरात कराराचे नाव</th>
                    <th class="br-bottom br-right br-top">प्रतिनिधी</th>
                    <th class="br-bottom br-right br-top">करार कालावधी</th>
                    <th class="br-bottom br-right br-top">मागील करार</th>
                    <th class="br-bottom br-right br-top">शिल्लक करार</th>
                    <th class="br-bottom br-right br-top">नवीन करार</th>
                    <th class="br-bottom br-right br-top">एजन्सी सूट</th>
                    <th class="br-bottom br-right br-top">एकूण</th>
                    <th class="br-bottom br-right br-top">जी एस टी</th>
                    <th class="br-bottom br-right br-top">एकूण</th>
                    <th class="br-bottom br-right br-top">एकूण मार्च अखेर</th>
                    <th class="br-bottom br-right br-top">पुढील वर्षासाठी</th>
                    <th class="br-bottom br-right br-top">आज अखेर वापरले</th>
                    <th class="br-bottom br-top">मार्च अखेर वापरणे बाकी</th>
                </tr>
            </thead>
            <tbody>
                <!-- <tr>
                    <td></td>
                    <td></td>
                    <td colspan="9"></td>
                    <td colspan="4"></td>
                </tr> -->
                <tr *ngFor="let data of contractData; let i = index">
                    <td class="br-bottom br-right">{{ i+1 }}</td>
                    <td class="br-bottom br-right">{{ data['advt_name'] }}</td>
                    <td class="br-bottom br-right">{{ data['rep_name'] }}</td>
                    <td class="br-bottom br-right">{{ data['contract_period_from'] | date:"MM yyyy" }} to {{ data['contract_period_to'] | date:"MM
                        yyyy" }}</td>
                    <td class="br-bottom br-right" style="text-align: end;">{{ data['contract_prev_bal'] }}</td>
                    <td class="br-bottom br-right" style="text-align: end;"></td>
                    <td class="br-bottom br-right" style="text-align: end;">{{ data['contract_amt'] }}</td>
                    <td class="br-bottom br-right" style="text-align: end;">{{ data['contract_discnt_amt'] }}</td>
                    <td class="br-bottom br-right" style="text-align: end;">{{ data['contract_net_amt'] }}</td>
                    <td class="br-bottom br-right" style="text-align: end;">{{ data['contract_net_gst_amt'] }}</td>
                    <td class="br-bottom br-right" style="text-align: end;">{{ data['contract_net_total'] }}</td>
                    <td class="br-bottom br-right" style="text-align: end;"></td>
                    <td class="br-bottom br-right" style="text-align: end;"></td>
                    <td class="br-bottom br-right" style="text-align: end;"></td>
                    <td class="br-bottom " style="text-align: end;"></td>
                </tr>
                <!-- <tr>
                    <td></td>
                    <td>एकूण</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr> -->
                <tr>
                    <td  class="br-bottom br-top br-right"></td>
                    <td  class="br-bottom br-top br-right">एकूण</td>
                    <td  class="br-bottom br-top br-right"></td>
                    <td  class="br-bottom br-top br-right"></td>
                    <td  class="br-bottom br-top br-right" style="text-align: end; font-weight: bold;">{{ lastcontract }}</td>
                    <td  class="br-bottom br-top br-right" style="text-align: end; font-weight: bold;">{{ totalData['remaining_contract'] }}</td>
                    <td  class="br-bottom br-top br-right" style="text-align: end; font-weight: bold;">{{ newcontract }}</td>
                    <td  class="br-bottom br-top br-right" style="text-align: end; font-weight: bold;">{{ agencydiscontract }}</td>
                    <td  class="br-bottom br-top br-right" style="text-align: end; font-weight: bold;">{{ totalcontract }}</td>
                    <td  class="br-bottom br-top br-right" style="text-align: end; font-weight: bold;">{{ gstcontract }}</td>
                    <td  class="br-bottom br-top br-right" style="text-align: end; font-weight: bold;">{{ grandtotalcontract }}</td>
                    <td  class="br-bottom br-top br-right" style="text-align: end; font-weight: bold;">{{ totalData['total_march_end'] }}</td>
                    <td  class="br-bottom br-top br-right" style="text-align: end; font-weight: bold;">{{ totalData['next_year'] }}</td>
                    <td  class="br-bottom br-top br-right" style="text-align: end; font-weight: bold;">{{ totalData['till_date_used'] }}</td>
                    <td  class="br-bottom br-top " style="text-align: end; font-weight: bold;">{{ totalData['use_till_march_end'] }}</td>
                </tr>
            </tbody>
            <!-- <tfoot>
                <tr>
                    <td></td>
                    <td>एकूण</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
            </tfoot> -->
        </table>

    </div>
</div>
<div *ngIf="reportType == 'indetailcontract'">
    <div style="margin-left: 80%;  margin-bottom: 2%;">
        <!-- <button class="noPrint" style="margin-top: 2%;" mat-raised-button color="primary"
            [printStyle]="{table : {'border-collapse':'collapse'}, td : {'border': 'solid 1px'}, th : {'border': 'solid 1px'}}"
            printSectionId="print-section2" ngxPrint>
            print
        </button> -->
        <button class="noPrint" style="margin-top: 2%; margin-left: 2%"
            (click)="exportExcel('Contract_InDetails_Report')" mat-raised-button color="primary">
            Export
        </button>
        <button class="noPrint" style="margin-top: 2%" mat-button mat-dialog-close>
            Close
        </button>
    </div>
    <div class="container" id="print-section">
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th colspan="11">सा. विवेक जाहिरात वार्षिक करार २०२२-२३ नोंदणी तक्ता</th>
                    <th colspan="4">Indetail-contract</th>
                    <th *ngIf="totalweeks == 54" colspan="131"></th>
                    <th *ngIf="totalweeks == 53" colspan="130"></th>
                    <th *ngIf="totalweeks == 52" colspan="129"></th>
                </tr>
                <tr>
                    <th rowspan="3">अक्र.</th>
                    <th rowspan="3">जाहिरात कराराचे नाव</th>
                    <th rowspan="3">प्रतिनिधी</th>
                    <th rowspan="3">करार कालावधी</th>
                    <th rowspan="3">मागील करार</th>
                    <th rowspan="3">शिल्लक करार</th>
                    <th rowspan="3">नवीन करार</th>
                    <th rowspan="3">एजन्सी सूट</th>
                    <th rowspan="3">एकूण</th>
                    <th rowspan="3">जी एस टी</th>
                    <th rowspan="3">एकूण</th>
                    <th *ngIf="this.sundays(today | date:'yyyy', 3) == 5" colspan="12">एप्रिल {{ today | date:"yyyy"
                        }}
                    </th>
                    <th *ngIf="this.sundays(today | date:'yyyy', 3) == 4" colspan="10">एप्रिल {{ today | date:"yyyy"
                        }}
                    </th>
                    <th *ngIf="this.sundays(today | date:'yyyy', 4) == 5" colspan="12">मे {{ today | date:"yyyy" }}
                    <th *ngIf="this.sundays(today | date:'yyyy', 4) == 4" colspan="10">मे {{ today | date:"yyyy" }}
                    </th>
                    <th *ngIf="this.sundays(today | date:'yyyy', 5) == 5" colspan="12">जुन {{ today | date:"yyyy" }}
                    </th>
                    <th *ngIf="this.sundays(today | date:'yyyy', 5) == 4" colspan="10">जुन {{ today | date:"yyyy" }}
                    </th>
                    <th *ngIf="this.sundays(today | date:'yyyy', 6) == 5" colspan="12">जुलै {{ today | date:"yyyy" }}
                    </th>
                    <th *ngIf="this.sundays(today | date:'yyyy', 6) == 4" colspan="10">जुलै {{ today | date:"yyyy" }}
                    </th>
                    <th *ngIf="this.sundays(today | date:'yyyy', 7) == 5" colspan="12">ऑगस्ट{{ today | date:"yyyy" }}
                    </th>
                    <th *ngIf="this.sundays(today | date:'yyyy', 7) == 4" colspan="10">ऑगस्ट {{ today | date:"yyyy"
                        }}
                    </th>
                    <th *ngIf="this.sundays(today | date:'yyyy', 8) == 5" colspan="12">सप्टेंबर {{ today |
                        date:"yyyy" }}
                    </th>
                    <th *ngIf="this.sundays(today | date:'yyyy', 8) == 4" colspan="10">सप्टेंबर {{ today |
                        date:"yyyy" }}
                    </th>
                    <th *ngIf="this.sundays(today | date:'yyyy', 9) == 5" colspan="12">ऑक्टोबर {{ today | date:"yyyy"
                        }}
                    </th>
                    <th *ngIf="this.sundays(today | date:'yyyy', 9) == 4" colspan="10">ऑक्टोबर {{ today | date:"yyyy"
                        }}
                    </th>
                    <th *ngIf="this.sundays(today | date:'yyyy', 10) == 5" colspan="12">नोव्हेंबर {{ today |
                        date:"yyyy"
                        }}
                    </th>
                    <th *ngIf="this.sundays(today | date:'yyyy', 10) == 4" colspan="10">नोव्हेंबर {{ today |
                        date:"yyyy"
                        }}
                    </th>
                    <th *ngIf="this.sundays(today | date:'yyyy', 11) == 5" colspan="12">डिसेंबर {{ today |
                        date:"yyyy" }}
                    </th>
                    <th *ngIf="this.sundays(today | date:'yyyy', 11) == 4" colspan="10">डिसेंबर {{ today |
                        date:"yyyy" }}
                    </th>
                    <th *ngIf="this.sundays(nexttoday | date:'yyyy', 0) == 5" colspan="12">जानेवारी {{ nexttoday |
                        date:"yyyy" }}</th>
                    <th *ngIf="this.sundays(nexttoday | date:'yyyy', 0) == 4" colspan="10">जानेवारी {{ nexttoday |
                        date:"yyyy" }}</th>
                    <th *ngIf="this.sundays(nexttoday | date:'yyyy', 1) == 5" colspan="12">फेब्रुवारी {{ nexttoday |
                        date:"yyyy" }}</th>
                    <th *ngIf="this.sundays(nexttoday | date:'yyyy', 1) == 4" colspan="10">फेब्रुवारी {{ nexttoday |
                        date:"yyyy" }}</th>
                    <th *ngIf="this.sundays(nexttoday | date:'yyyy', 2) == 5" colspan="12">मार्च {{ nexttoday |
                        date:"yyyy"
                        }}</th>
                    <th *ngIf="this.sundays(nexttoday | date:'yyyy', 2) == 4" colspan="10">मार्च {{ nexttoday |
                        date:"yyyy"
                        }}</th>
                    <th rowspan="3">एकूण मार्च अखेर</th>
                    <th rowspan="3">पुढील वर्षासाठी</th>
                    <th rowspan="3">आज अखेर वापरले</th>
                    <th rowspan="3">मार्च अखेर वापरणे बाकी</th>
                </tr>
                <tr>
                    <th colspan="2"></th>
                    <th colspan="2"></th>
                    <th colspan="2"></th>
                    <th colspan="2"></th>
                    <th *ngIf="this.sundays(today | date:'yyyy', 3) > 4" colspan="2"></th>
                    <th colspan="2" rowspan="2">एकूण</th>
                    <th colspan="2"></th>
                    <th colspan="2"></th>
                    <th colspan="2"></th>
                    <th colspan="2"></th>
                    <th *ngIf="this.sundays(today | date:'yyyy', 4) > 4" colspan="2"></th>
                    <th colspan="2" rowspan="2">एकूण</th>
                    <th colspan="2"></th>
                    <th colspan="2"></th>
                    <th colspan="2"></th>
                    <th colspan="2"></th>
                    <th *ngIf="this.sundays(today | date:'yyyy', 5) > 4" colspan="2"></th>
                    <th colspan="2" rowspan="2">एकूण</th>
                    <th colspan="2"></th>
                    <th colspan="2"></th>
                    <th colspan="2"></th>
                    <th colspan="2"></th>
                    <th *ngIf="this.sundays(today | date:'yyyy', 6) > 4" colspan="2"></th>
                    <th colspan="2" rowspan="2">एकूण</th>
                    <th colspan="2"></th>
                    <th colspan="2"></th>
                    <th colspan="2"></th>
                    <th colspan="2"></th>
                    <th *ngIf="this.sundays(today | date:'yyyy', 7) > 4" colspan="2"></th>
                    <th colspan="2" rowspan="2">एकूण</th>
                    <th colspan="2"></th>
                    <th colspan="2"></th>
                    <th colspan="2"></th>
                    <th colspan="2"></th>
                    <th *ngIf="this.sundays(today | date:'yyyy', 8) > 4" colspan="2"></th>
                    <th colspan="2" rowspan="2">एकूण</th>
                    <th colspan="2"></th>
                    <th colspan="2"></th>
                    <th colspan="2"></th>
                    <th colspan="2"></th>
                    <th *ngIf="this.sundays(today | date:'yyyy', 9) > 4" colspan="2"></th>
                    <th colspan="2" rowspan="2">एकूण</th>
                    <th colspan="2"></th>
                    <th colspan="2"></th>
                    <th colspan="2"></th>
                    <th colspan="2"></th>
                    <th *ngIf="this.sundays(today | date:'yyyy',10) > 4" colspan="2"></th>
                    <th colspan="2" rowspan="2">एकूण</th>
                    <th colspan="2"></th>
                    <th colspan="2"></th>
                    <th colspan="2"></th>
                    <th colspan="2"></th>
                    <th *ngIf="this.sundays(today | date:'yyyy', 11) > 4" colspan="2"></th>
                    <th colspan="2" rowspan="2">एकूण</th>
                    <th colspan="2"></th>
                    <th colspan="2"></th>
                    <th colspan="2"></th>
                    <th colspan="2"></th>
                    <th *ngIf="this.sundays(nexttoday | date:'yyyy', 0) > 4" colspan="2"></th>
                    <th colspan="2" rowspan="2">एकूण</th>
                    <th colspan="2"></th>
                    <th colspan="2"></th>
                    <th colspan="2"></th>
                    <th colspan="2"></th>
                    <th colspan="2" *ngIf="this.sundays(nexttoday | date:'yyyy', 1) > 4"></th>
                    <th colspan="2" rowspan="2">एकूण</th>
                    <th colspan="2"></th>
                    <th colspan="2"></th>
                    <th colspan="2"></th>
                    <th colspan="2"></th>
                    <th colspan="2" *ngIf="this.sundays(nexttoday | date:'yyyy', 2) > 4"></th>
                    <th colspan="2" rowspan="2">एकूण</th>
                </tr>
                <tr>
                    <th colspan="2">{{ firstSunday(3,today | date:"yyyy") | date:"dd/MM/yy" }}</th>
                    <th colspan="2">{{ secondSunday(3,today | date:"yyyy") | date:"dd/MM/yy" }}</th>
                    <th colspan="2">{{ thirdSunday(3,today | date:"yyyy") | date:"dd/MM/yy" }}</th>
                    <th colspan="2">{{ fourthSunday(3,today | date:"yyyy") | date:"dd/MM/yy" }}</th>
                    <th *ngIf="this.sundays(today | date:'yyyy', 3) > 4" colspan="2">{{ fifthSunday(3,today |
                        date:"yyyy") |
                        date:"dd/MM/yy" }}</th>
                    <th colspan="2">{{ firstSunday(4,today | date:"yyyy")| date:"dd/MM/yy" }}</th>
                    <th colspan="2">{{ secondSunday(4,today | date:"yyyy")| date:"dd/MM/yy" }}</th>
                    <th colspan="2">{{ thirdSunday(4,today | date:"yyyy") | date:"dd/MM/yy" }}</th>
                    <th colspan="2">{{ fourthSunday(4,today | date:"yyyy") | date:"dd/MM/yy" }}</th>
                    <th *ngIf="this.sundays(today | date:'yyyy', 4) > 4" colspan="2">{{ fifthSunday(4,today |
                        date:"yyyy") |
                        date:"dd/MM/yy" }}</th>
                    <th colspan="2">{{ firstSunday(5,today | date:"yyyy") | date:"dd/MM/yy" }}</th>
                    <th colspan="2">{{ secondSunday(5,today | date:"yyyy") | date:"dd/MM/yy" }}</th>
                    <th colspan="2">{{ thirdSunday(5,today | date:"yyyy") | date:"dd/MM/yy" }}</th>
                    <th colspan="2">{{ fourthSunday(5,today | date:"yyyy") | date:"dd/MM/yy" }}</th>
                    <th *ngIf="this.sundays(today | date:'yyyy', 5) > 4" colspan="2">{{ fifthSunday(5,today |
                        date:"yyyy") |
                        date:"dd/MM/yy" }}</th>
                    <th colspan="2">{{ firstSunday(6,today | date:"yyyy") | date:"dd/MM/yy" }}</th>
                    <th colspan="2">{{ secondSunday(6,today | date:"yyyy") | date:"dd/MM/yy" }}</th>
                    <th colspan="2">{{ thirdSunday(6,today | date:"yyyy") | date:"dd/MM/yy" }}</th>
                    <th colspan="2">{{ fourthSunday(6,today | date:"yyyy") | date:"dd/MM/yy" }}</th>
                    <th *ngIf="this.sundays(today | date:'yyyy', 6) > 4" colspan="2">{{ fifthSunday(6,today |
                        date:"yyyy") |
                        date:"dd/MM/yy" }}</th>
                    <th colspan="2">{{ firstSunday(7,today | date:"yyyy") | date:"dd/MM/yy" }}</th>
                    <th colspan="2">{{ secondSunday(7,today | date:"yyyy") | date:"dd/MM/yy" }}</th>
                    <th colspan="2">{{ thirdSunday(7,today | date:"yyyy") | date:"dd/MM/yy" }}</th>
                    <th colspan="2">{{ fourthSunday(7,today | date:"yyyy") | date:"dd/MM/yy" }}</th>
                    <th *ngIf="this.sundays(today | date:'yyyy', 7) > 4" colspan="2">{{ fifthSunday(7,today |
                        date:"yyyy") |
                        date:"dd/MM/yy" }}</th>
                    <th colspan="2">{{ firstSunday(8,today | date:"yyyy") | date:"dd/MM/yy" }}</th>
                    <th colspan="2">{{ secondSunday(8,today | date:"yyyy") | date:"dd/MM/yy" }}</th>
                    <th colspan="2">{{ thirdSunday(8,today | date:"yyyy") | date:"dd/MM/yy" }}</th>
                    <th colspan="2">{{ fourthSunday(8,today | date:"yyyy") | date:"dd/MM/yy" }}</th>
                    <th *ngIf="this.sundays(today | date:'yyyy', 8) > 4" colspan="2">{{ fifthSunday(8,today |
                        date:"yyyy") |
                        date:"dd/MM/yy" }}</th>
                    <th colspan="2">{{ firstSunday(9,today | date:"yyyy") | date:"dd/MM/yy" }}</th>
                    <th colspan="2">{{ secondSunday(9,today | date:"yyyy") | date:"dd/MM/yy" }}</th>
                    <th colspan="2">{{ thirdSunday(9,today | date:"yyyy") | date:"dd/MM/yy" }}</th>
                    <th colspan="2">{{ fourthSunday(9,today | date:"yyyy") | date:"dd/MM/yy" }}</th>
                    <th *ngIf="this.sundays(today | date:'yyyy', 9) > 4" colspan="2">{{ fifthSunday(9,today |
                        date:"yyyy") |
                        date:"dd/MM/yy" }}</th>
                    <th colspan="2">{{ firstSunday(10,today | date:"yyyy") | date:"dd/MM/yy" }}</th>
                    <th colspan="2">{{ secondSunday(10,today | date:"yyyy") | date:"dd/MM/yy" }}</th>
                    <th colspan="2">{{ thirdSunday(10,today | date:"yyyy") | date:"dd/MM/yy" }}</th>
                    <th colspan="2">{{ fourthSunday(10,today | date:"yyyy") | date:"dd/MM/yy" }}</th>
                    <th *ngIf="this.sundays(today | date:'yyyy', 10) > 4" colspan="2">{{ fifthSunday(10,today |
                        date:"yyyy") |
                        date:"dd/MM/yy" }}</th>
                    <th colspan="2">{{ firstSunday(11,today | date:"yyyy") | date:"dd/MM/yy" }}</th>
                    <th colspan="2">{{ secondSunday(11,today | date:"yyyy") | date:"dd/MM/yy" }}</th>
                    <th colspan="2">{{ thirdSunday(11,today | date:"yyyy") | date:"dd/MM/yy" }}</th>
                    <th colspan="2">{{ fourthSunday(11,today | date:"yyyy") | date:"dd/MM/yy" }}</th>
                    <th *ngIf="this.sundays(today | date:'yyyy', 11) > 4" colspan="2">{{ fifthSunday(11,today |
                        date:"yyyy") |
                        date:"dd/MM/yy" }}</th>
                    <th colspan="2">{{ firstSunday(0,nexttoday | date:"yyyy") | date:"dd/MM/yy" }}</th>
                    <th colspan="2">{{ secondSunday(0,nexttoday | date:"yyyy") | date:"dd/MM/yy" }}</th>
                    <th colspan="2">{{ thirdSunday(0,nexttoday | date:"yyyy") | date:"dd/MM/yy" }}</th>
                    <th colspan="2">{{ fourthSunday(0,nexttoday | date:"yyyy") | date:"dd/MM/yy" }}</th>
                    <th *ngIf="this.sundays(nexttoday | date:'yyyy', 0) > 4" colspan="2">{{ fifthSunday(0,nexttoday |
                        date:"yyyy") |
                        date:"dd/MM/yy" }}</th>
                    <th colspan="2">{{ firstSunday(1,nexttoday | date:"yyyy") | date:"dd/MM/yy" }}</th>
                    <th colspan="2">{{ secondSunday(1,nexttoday | date:"yyyy") | date:"dd/MM/yy" }}</th>
                    <th colspan="2">{{ thirdSunday(1,nexttoday | date:"yyyy") | date:"dd/MM/yy" }}</th>
                    <th colspan="2">{{ fourthSunday(1,nexttoday | date:"yyyy") | date:"dd/MM/yy" }}</th>
                    <th *ngIf="this.sundays(nexttoday | date:'yyyy', 1) > 4" colspan="2">{{ fifthSunday(1,nexttoday |
                        date:"yyyy") |
                        date:"dd/MM/yy" }}</th>
                    <th colspan="2">{{ firstSunday(2,nexttoday | date:"yyyy") | date:"dd/MM/yy" }}</th>
                    <th colspan="2">{{ secondSunday(2,nexttoday | date:"yyyy") | date:"dd/MM/yy" }}</th>
                    <th colspan="2">{{ thirdSunday(2,nexttoday | date:"yyyy") | date:"dd/MM/yy" }}</th>
                    <th colspan="2">{{ fourthSunday(2,nexttoday | date:"yyyy") | date:"dd/MM/yy" }}</th>
                    <th *ngIf="this.sundays(nexttoday | date:'yyyy', 2) > 4" colspan="2">{{ fifthSunday(2,nexttoday |
                        date:"yyyy") |
                        date:"dd/MM/yy" }}</th>
                </tr>
            </thead>
            <tbody>
                <!-- indetailsData -->
                <ng-container *ngFor="let item of contractData1; let s = index; let a = first; let b = last">
                    <tr style="background-color: rgb(120, 218, 233);">
                        <td style="color: red;">{{ s+1 }}</td>
                        <td style="color: red;font-size: large;font-weight: 600;">{{ item['name'] }}</td>
                        <td colspan="9"></td>
                        <td *ngIf="this.sundays(today | date:'yyyy', 3) == 5" colspan="12"></td>
                        <td *ngIf="this.sundays(today | date:'yyyy', 3) == 4" colspan="10"></td>
                        <td *ngIf="this.sundays(today | date:'yyyy', 4) == 5" colspan="12"></td>
                        <td *ngIf="this.sundays(today | date:'yyyy', 4) == 4" colspan="10"></td>
                        <td *ngIf="this.sundays(today | date:'yyyy', 5) == 5" colspan="12"></td>
                        <td *ngIf="this.sundays(today | date:'yyyy', 5) == 4" colspan="10"></td>
                        <td *ngIf="this.sundays(today | date:'yyyy', 6) == 5" colspan="12"></td>
                        <td *ngIf="this.sundays(today | date:'yyyy', 6) == 4" colspan="10"></td>
                        <td *ngIf="this.sundays(today | date:'yyyy', 7) == 5" colspan="12"></td>
                        <td *ngIf="this.sundays(today | date:'yyyy', 7) == 4" colspan="10"></td>
                        <td *ngIf="this.sundays(today | date:'yyyy', 8) == 5" colspan="12"></td>
                        <td *ngIf="this.sundays(today | date:'yyyy', 8) == 4" colspan="10"></td>
                        <td *ngIf="this.sundays(today | date:'yyyy', 9) == 5" colspan="12"></td>
                        <td *ngIf="this.sundays(today | date:'yyyy', 9) == 4" colspan="10"></td>
                        <td *ngIf="this.sundays(today | date:'yyyy', 10) == 5" colspan="12"></td>
                        <td *ngIf="this.sundays(today | date:'yyyy', 10) == 4" colspan="10"></td>
                        <td *ngIf="this.sundays(today | date:'yyyy', 11) == 5" colspan="12"></td>
                        <td *ngIf="this.sundays(today | date:'yyyy', 11) == 4" colspan="10"></td>
                        <td *ngIf="this.sundays(nexttoday | date:'yyyy', 0) == 5" colspan="12"></td>
                        <td *ngIf="this.sundays(nexttoday | date:'yyyy', 0) == 4" colspan="10"></td>
                        <td *ngIf="this.sundays(nexttoday | date:'yyyy', 1) == 5" colspan="12"></td>
                        <td *ngIf="this.sundays(nexttoday | date:'yyyy', 1) == 4" colspan="10"></td>
                        <td *ngIf="this.sundays(nexttoday | date:'yyyy', 2) == 5" colspan="12"></td>
                        <td *ngIf="this.sundays(nexttoday | date:'yyyy', 2) == 4" colspan="10"></td>
                        <td colspan="4"></td>
                    </tr>
                    <ng-container *ngFor="let data of contractData; let i = index">
                        <tr>
                            <td rowspan="2">{{ i+1 }}</td>
                            <td rowspan="2">{{ data['advt_name'] }}</td>
                            <td rowspan="2">{{ data['rep_name'] }}</td>
                            <td rowspan="2">{{ data['contract_period_from'] | date:"MM yyyy" }} to {{
                                data['contract_period_to'] |
                                date:"MM
                                yyyy" }}</td>
                            <td rowspan="2" class="amount">{{ data['contract_prev_bal'] }}</td>
                            <td rowspan="2"></td>
                            <td rowspan="2" class="amount">{{ data['contract_amt'] }}</td>
                            <td rowspan="2" class="amount">{{ data['contract_discnt_amt'] }}</td>
                            <td rowspan="2" class="amount">{{ data['contract_net_amt'] }}</td>
                            <td rowspan="2" class="amount">{{ data['contract_net_gst_amt'] }}</td>
                            <td rowspan="2" class="amount">{{ data['contract_net_total'] }}</td>
                            <td colspan="2"> {{ data['issue_name'] }}</td>
                            <td colspan="2"> {{ data['issue_name'] }}</td>
                            <td colspan="2"> {{ data['issue_name'] }}</td>
                            <td colspan="2"> {{ data['issue_name'] }}</td>
                            <td *ngIf="this.sundays(today | date:'yyyy', 3) > 4" colspan="2">{{ data['april5thWeekSize']
                                }}</td>
                            <td colspan="2" rowspan="2">
                                <span *ngIf="this.sundays(today | date:'yyyy', 3) == 4">{{
                                    calculateTotalAmount(data['april1stWeekAmt'],data['april2ndWeekAmt'],data['april3rdWeekAmt'],data['april4thWeekAmt'])
                                    }}</span>
                                <span *ngIf="this.sundays(today | date:'yyyy', 3) == 5">{{
                                    calculateTotalAmount(data['april1stWeekAmt'],data['april2ndWeekAmt'],data['april3rdWeekAmt'],data['april4thWeekAmt'],data['april5thWeekAmt'])
                                    }}</span>
                            </td>
                            <td colspan="2">{{ data['may1stWeekSize'] }}</td>
                            <td colspan="2">{{ data['may2ndWeekSize'] }}</td>
                            <td colspan="2">{{ data['may3rdWeekSize'] }}</td>
                            <td colspan="2">{{ data['may4thWeekSize'] }}</td>
                            <td *ngIf="this.sundays(today | date:'yyyy', 4) > 4" colspan="2">{{ data['may5thWeekSize']
                                }}</td>
                            <td colspan="2" rowspan="2">
                                <span *ngIf="this.sundays(today | date:'yyyy', 4) == 4">{{
                                    calculateTotalAmount(data['may1stWeekAmt'],data['may2ndWeekAmt'],data['may3rdWeekAmt'],data['may4thWeekAmt'])
                                    }}</span>
                                <span *ngIf="this.sundays(today | date:'yyyy', 4) == 5">{{
                                    calculateTotalAmount(data['may1stWeekAmt'],data['may2ndWeekAmt'],data['may3rdWeekAmt'],data['may4thWeekAmt'],data['may5thWeekAmt'])
                                    }}</span>
                            </td>
                            <td colspan="2">{{ data['june1stWeekSize'] }}</td>
                            <td colspan="2">{{ data['june2ndWeekSize'] }}</td>
                            <td colspan="2">{{ data['june3rdWeekSize'] }}</td>
                            <td colspan="2">{{ data['june4thWeekSize'] }}</td>
                            <td *ngIf="this.sundays(today | date:'yyyy', 5) > 4" colspan="2">{{ data['june5thWeekSize']
                                }}</td>
                            <td colspan="2" rowspan="2">
                                <span *ngIf="this.sundays(today | date:'yyyy', 5) == 4">{{
                                    calculateTotalAmount(data['june1stWeekAmt'],data['june2ndWeekAmt'],data['june3rdWeekAmt'],data['june4thWeekAmt'])
                                    }}</span>
                                <span *ngIf="this.sundays(today | date:'yyyy', 5) == 5">{{
                                    calculateTotalAmount(data['june1stWeekAmt'],data['june2ndWeekAmt'],data['june3rdWeekAmt'],data['june4thWeekAmt'],data['june5thWeekAmt'])
                                    }}</span>
                            </td>
                            <td colspan="2">{{ data['july1stWeekSize'] }}</td>
                            <td colspan="2">{{ data['july2ndWeekSize'] }}</td>
                            <td colspan="2">{{ data['july3rdWeekSize'] }}</td>
                            <td colspan="2">{{ data['july4thWeekSize'] }}</td>
                            <td *ngIf="this.sundays(today | date:'yyyy', 6) > 4" colspan="2">{{ data['july5thWeekSize']
                                }}</td>
                            <td colspan="2" rowspan="2">
                                <span *ngIf="this.sundays(today | date:'yyyy', 6) == 4">{{
                                    calculateTotalAmount(data['july1stWeekAmt'],data['july2ndWeekAmt'],data['july3rdWeekAmt'],data['july4thWeekAmt'])
                                    }}</span>
                                <span *ngIf="this.sundays(today | date:'yyyy', 6) == 5">{{
                                    calculateTotalAmount(data['july1stWeekAmt'],data['july2ndWeekAmt'],data['july3rdWeekAmt'],data['july4thWeekAmt'],data['july5thWeekAmt'])
                                    }}</span>
                            </td>
                            <td colspan="2">{{ data['august1stWeekSize'] }}</td>
                            <td colspan="2">{{ data['august2ndWeekSize'] }}</td>
                            <td colspan="2">{{ data['august3rdWeekSize'] }}</td>
                            <td colspan="2">{{ data['august4thWeekSize'] }}</td>
                            <td *ngIf="this.sundays(today | date:'yyyy', 7) > 4" colspan="2">{{
                                data['august5thWeekSize'] }}</td>
                            <td colspan="2" rowspan="2">
                                <span *ngIf="this.sundays(today | date:'yyyy', 7) == 4">{{
                                    calculateTotalAmount(data['august1stWeekAmt'],data['august2ndWeekAmt'],data['august3rdWeekAmt'],data['august4thWeekAmt'])
                                    }}</span>
                                <span *ngIf="this.sundays(today | date:'yyyy', 7) == 5">{{
                                    calculateTotalAmount(data['august1stWeekAmt'],data['august2ndWeekAmt'],data['august3rdWeekAmt'],data['august4thWeekAmt'],data['august5thWeekAmt'])
                                    }}</span>
                            </td>
                            <td colspan="2">{{ data['september1stWeekSize'] }}</td>
                            <td colspan="2">{{ data['september2ndWeekSize'] }}</td>
                            <td colspan="2">{{ data['september3rdWeekSize'] }}</td>
                            <td colspan="2">{{ data['september4thWeekSize'] }}</td>
                            <td *ngIf="this.sundays(today | date:'yyyy', 8) > 4" colspan="2">{{
                                data['september5thWeekSize'] }}</td>
                            <td colspan="2" rowspan="2">
                                <span *ngIf="this.sundays(today | date:'yyyy', 8) == 4">{{
                                    calculateTotalAmount(data['september1stWeekAmt'],data['september2ndWeekAmt'],data['september3rdWeekAmt'],data['september4thWeekAmt'])
                                    }}</span>
                                <span *ngIf="this.sundays(today | date:'yyyy', 8) == 5">{{
                                    calculateTotalAmount(data['september1stWeekAmt'],data['september2ndWeekAmt'],data['september3rdWeekAmt'],data['september4thWeekAmt'],data['september5thWeekAmt'])
                                    }}</span>
                            </td>
                            <td colspan="2">{{ data['october1stWeekSize'] }}</td>
                            <td colspan="2">{{ data['october2ndWeekSize'] }}</td>
                            <td colspan="2">{{ data['october3rdWeekSize'] }}</td>
                            <td colspan="2">{{ data['october4thWeekSize'] }}</td>
                            <td *ngIf="this.sundays(today | date:'yyyy', 9) > 4" colspan="2">{{
                                data['october5thWeekSize'] }}</td>
                            <td colspan="2" rowspan="2">
                                <span *ngIf="this.sundays(today | date:'yyyy', 9) == 4">{{
                                    calculateTotalAmount(data['october1stWeekAmt'],data['october2ndWeekAmt'],data['october3rdWeekAmt'],data['october4thWeekAmt'])
                                    }}</span>
                                <span *ngIf="this.sundays(today | date:'yyyy', 9) == 5">{{
                                    calculateTotalAmount(data['october1stWeekAmt'],data['october2ndWeekAmt'],data['october3rdWeekAmt'],data['october4thWeekAmt'],data['october5thWeekAmt'])
                                    }}</span>
                            </td>
                            <td colspan="2">{{ data['november1stWeekSize'] }}</td>
                            <td colspan="2">{{ data['november2ndWeekSize'] }}</td>
                            <td colspan="2">{{ data['november3rdWeekSize'] }}</td>
                            <td colspan="2">{{ data['november4thWeekSize'] }}</td>
                            <td *ngIf="this.sundays(today | date:'yyyy', 10) > 4" colspan="2">{{
                                data['november5thWeekSize'] }}</td>
                            <td colspan="2" rowspan="2">
                                <span *ngIf="this.sundays(today | date:'yyyy', 10) == 4">{{
                                    calculateTotalAmount(data['november1stWeekAmt'],data['november2ndWeekAmt'],data['november3rdWeekAmt'],data['november4thWeekAmt'])
                                    }}</span>
                                <span *ngIf="this.sundays(today | date:'yyyy', 10) == 5">{{
                                    calculateTotalAmount(data['november1stWeekAmt'],data['november2ndWeekAmt'],data['november3rdWeekAmt'],data['november4thWeekAmt'],data['november5thWeekAmt'])
                                    }}</span>
                            </td>
                            <td colspan="2">{{ data['december1stWeekSize'] }}</td>
                            <td colspan="2">{{ data['december2ndWeekSize'] }}</td>
                            <td colspan="2">{{ data['december3rdWeekSize'] }}</td>
                            <td colspan="2">{{ data['december4thWeekSize'] }}</td>
                            <td *ngIf="this.sundays(today | date:'yyyy', 11) > 4" colspan="2">{{
                                data['december5thWeekSize'] }}</td>
                            <td colspan="2" rowspan="2">
                                <span *ngIf="this.sundays(today | date:'yyyy', 11) == 4">{{
                                    calculateTotalAmount(data['december1stWeekAmt'],data['december2ndWeekAmt'],data['december3rdWeekAmt'],data['december4thWeekAmt'])
                                    }}</span>
                                <span *ngIf="this.sundays(today | date:'yyyy', 11) == 5">{{
                                    calculateTotalAmount(data['december1stWeekAmt'],data['december2ndWeekAmt'],data['december3rdWeekAmt'],data['december4thWeekAmt'],data['december5thWeekAmt'])
                                    }}</span>
                            </td>
                            <td colspan="2">{{ data['january1stWeekSize'] }}</td>
                            <td colspan="2">{{ data['january2ndWeekSize'] }}</td>
                            <td colspan="2">{{ data['january3rdWeekSize'] }}</td>
                            <td colspan="2">{{ data['january4thWeekSize'] }}</td>
                            <td *ngIf="this.sundays(nexttoday | date:'yyyy', 0) > 4" colspan="2">{{
                                data['january5thWeekSize'] }}</td>
                            <td colspan="2" rowspan="2">
                                <span *ngIf="this.sundays(today | date:'yyyy', 0) == 4">{{
                                    calculateTotalAmount(data['january1stWeekAmt'],data['january2ndWeekAmt'],data['january3rdWeekAmt'],data['january4thWeekAmt'])
                                    }}</span>
                                <span *ngIf="this.sundays(today | date:'yyyy', 0) == 5">{{
                                    calculateTotalAmount(data['january1stWeekAmt'],data['january2ndWeekAmt'],data['january3rdWeekAmt'],data['january4thWeekAmt'],data['january5thWeekAmt'])
                                    }}</span>
                            </td>
                            <td colspan="2">{{ data['feb1stWeekSize'] }}</td>
                            <td colspan="2">{{ data['feb2ndWeekSize'] }}</td>
                            <td colspan="2">{{ data['feb3rdWeekSize'] }}</td>
                            <td colspan="2">{{ data['feb4thWeekSize'] }}</td>
                            <td *ngIf="this.sundays(nexttoday | date:'yyyy', 1) > 4" colspan="2">{{
                                data['feb5thWeekSize'] }}</td>
                            <td colspan="2" rowspan="2">
                                <span *ngIf="this.sundays(today | date:'yyyy', 1) == 4">{{
                                    calculateTotalAmount(data['feb1stWeekAmt'],data['feb2ndWeekAmt'],data['feb3rdWeekAmt'],data['feb4thWeekAmt'])
                                    }}</span>
                                <span *ngIf="this.sundays(today | date:'yyyy', 1) == 5">{{
                                    calculateTotalAmount(data['feb1stWeekAmt'],data['feb2ndWeekAmt'],data['feb3rdWeekAmt'],data['feb4thWeekAmt'],data['feb5thWeekAmt'])
                                    }}</span>
                            </td>
                            <td colspan="2">{{ data['march1stWeekSize'] }}</td>
                            <td colspan="2">{{ data['march2ndWeekSize'] }}</td>
                            <td colspan="2">{{ data['march3rdWeekSize'] }}</td>
                            <td colspan="2">{{ data['march4thWeekSize'] }}</td>
                            <td *ngIf="this.sundays(nexttoday | date:'yyyy', 2) > 4" colspan="2">{{
                                data['march5thWeekSize'] }}</td>
                            <td colspan="2" rowspan="2">
                                <span *ngIf="this.sundays(today | date:'yyyy', 2) == 4">{{
                                    calculateTotalAmount(data['march1stWeekAmt'],data['march2ndWeekAmt'],data['march3rdWeekAmt'],data['march4thWeekAmt'])
                                    }}</span>
                                <span *ngIf="this.sundays(today | date:'yyyy', 2) == 5">{{
                                    calculateTotalAmount(data['march1stWeekAmt'],data['march2ndWeekAmt'],data['march3rdWeekAmt'],data['march4thWeekAmt'],data['march5thWeekAmt'])
                                    }}</span>
                            </td>
                            <td rowspan="2"></td>
                            <td rowspan="2"></td>
                            <td rowspan="2"></td>
                            <td rowspan="2"></td>
                        </tr>
                        <tr class="amount">
                            <td>{{ data['april1stWeekAmt'] }}</td>
                            <td>{{ data['april1stWeekPageNo'] }}</td>
                            <td>{{ data['april2ndWeekAmt'] }}</td>
                            <td>{{ data['april2ndWeekPageNo'] }}</td>
                            <td>{{ data['april3rdWeekAmt'] }}</td>
                            <td>{{ data['april3rdWeekPageNo'] }}</td>
                            <td>{{ data['april4thWeekAmt'] }}</td>
                            <td>{{ data['april4thWeekPageNo'] }}</td>
                            <td *ngIf="this.sundays(today | date:'yyyy', 3) > 4">{{ data['april5thWeekAmt'] }}</td>
                            <td *ngIf="this.sundays(today | date:'yyyy', 3) > 4">{{ data['april5thWeekPageNo'] }}</td>
                            <!-- <td></td>
                            <td></td> -->
                            <td>{{ data['may1stWeekAmt'] }}</td>
                            <td>{{ data['may1stWeekPageNo'] }}</td>
                            <td>{{ data['may2ndWeekAmt'] }}</td>
                            <td>{{ data['may2ndWeekPageNo'] }}</td>
                            <td>{{ data['may3rdWeekAmt'] }}</td>
                            <td>{{ data['may3rdWeekPageNo'] }}</td>
                            <td>{{ data['may4thWeekAmt'] }}</td>
                            <td>{{ data['may4thWeekPageNo'] }}</td>
                            <td *ngIf="this.sundays(today | date:'yyyy', 4) > 4">{{ data['may5thWeekAmt'] }}</td>
                            <td *ngIf="this.sundays(today | date:'yyyy', 4) > 4">{{ data['may5thWeekPageNo'] }}</td>
                            <!-- <td></td>
                            <td></td> -->
                            <td>{{ data['june1stWeekAmt'] }}</td>
                            <td>{{ data['june1stWeekPageNo'] }}</td>
                            <td>{{ data['june2ndWeekAmt'] }}</td>
                            <td>{{ data['june2ndWeekPageNo'] }}</td>
                            <td>{{ data['june3rdWeekAmt'] }}</td>
                            <td>{{ data['june3rdWeekPageNo'] }}</td>
                            <td>{{ data['june4thWeekAmt'] }}</td>
                            <td>{{ data['june4thWeekPageNo'] }}</td>
                            <td *ngIf="this.sundays(today | date:'yyyy', 5) > 4">{{ data['june5thWeekAmt'] }}</td>
                            <td *ngIf="this.sundays(today | date:'yyyy', 5) > 4">{{ data['june5thWeekPageNo'] }}</td>
                            <!-- <td></td>
                            <td></td> -->
                            <td>{{ data['july1stWeekAmt'] }}</td>
                            <td>{{ data['july1stWeekPageNo'] }}</td>
                            <td>{{ data['july2ndWeekAmt'] }}</td>
                            <td>{{ data['july2ndWeekPageNo'] }}</td>
                            <td>{{ data['july3rdWeekAmt'] }}</td>
                            <td>{{ data['july3rdWeekPageNo'] }}</td>
                            <td>{{ data['july4thWeekAmt'] }}</td>
                            <td>{{ data['july4thWeekPageNo'] }}</td>
                            <td *ngIf="this.sundays(today | date:'yyyy', 6) > 4">{{ data['july5thWeekAmt'] }}</td>
                            <td *ngIf="this.sundays(today | date:'yyyy', 6) > 4">{{ data['july5thWeekPageNo'] }}</td>
                            <!-- <td></td>
                            <td></td> -->
                            <td>{{ data['august1stWeekAmt'] }}</td>
                            <td>{{ data['august1stWeekPageNo'] }}</td>
                            <td>{{ data['august2ndWeekAmt'] }}</td>
                            <td>{{ data['august2ndWeekPageNo'] }}</td>
                            <td>{{ data['august3rdWeekAmt'] }}</td>
                            <td>{{ data['august3rdWeekPageNo'] }}</td>
                            <td>{{ data['august4thWeekAmt'] }}</td>
                            <td>{{ data['august4thWeekPageNo'] }}</td>
                            <td *ngIf="this.sundays(today | date:'yyyy', 7) > 4">{{ data['august5thWeekAmt'] }}</td>
                            <td *ngIf="this.sundays(today | date:'yyyy', 7) > 4">{{ data['august5thWeekPageNo'] }}</td>
                            <!-- <td></td>
                            <td></td> -->
                            <td>{{ data['september1stWeekAmt'] }}</td>
                            <td>{{ data['september1stWeekPageNo'] }}</td>
                            <td>{{ data['september2ndWeekAmt'] }}</td>
                            <td>{{ data['september2ndWeekPageNo'] }}</td>
                            <td>{{ data['september3rdWeekAmt'] }}</td>
                            <td>{{ data['september3rdWeekPageNo'] }}</td>
                            <td>{{ data['september4thWeekAmt'] }}</td>
                            <td>{{ data['september4thWeekPageNo'] }}</td>
                            <td *ngIf="this.sundays(today | date:'yyyy', 8) > 4">{{ data['september5thWeekAmt'] }}</td>
                            <td *ngIf="this.sundays(today | date:'yyyy', 8) > 4">{{ data['september5thWeekPageNo'] }}
                            </td>
                            <!-- <td></td>
                            <td></td> -->
                            <td>{{ data['october1stWeekAmt'] }}</td>
                            <td>{{ data['october1stWeekPageNo'] }}</td>
                            <td>{{ data['october2ndWeekAmt'] }}</td>
                            <td>{{ data['october2ndWeekPageNo'] }}</td>
                            <td>{{ data['october3rdWeekAmt'] }}</td>
                            <td>{{ data['october3rdWeekPageNo'] }}</td>
                            <td>{{ data['october4thWeekAmt'] }}</td>
                            <td>{{ data['october4thWeekPageNo'] }}</td>
                            <td *ngIf="this.sundays(today | date:'yyyy', 9) > 4">{{ data['october5thWeekAmt'] }}</td>
                            <td *ngIf="this.sundays(today | date:'yyyy', 9) > 4">{{ data['october5thWeekPageNo'] }}</td>
                            <!-- <td></td>
                            <td></td> -->
                            <td>{{ data['november1stWeekAmt'] }}</td>
                            <td>{{ data['november1stWeekPageNo'] }}</td>
                            <td>{{ data['november2ndWeekAmt'] }}</td>
                            <td>{{ data['november2ndWeekPageNo'] }}</td>
                            <td>{{ data['november3rdWeekAmt'] }}</td>
                            <td>{{ data['november3rdWeekPageNo'] }}</td>
                            <td>{{ data['november4thWeekAmt'] }}</td>
                            <td>{{ data['november4thWeekPageNo'] }}</td>
                            <td *ngIf="this.sundays(today | date:'yyyy', 10) > 4">{{ data['november5thWeekAmt'] }}</td>
                            <td *ngIf="this.sundays(today | date:'yyyy', 10) > 4">{{ data['november5thWeekPageNo'] }}
                            </td>
                            <!-- <td></td>
                            <td></td> -->
                            <td>{{ data['december1stWeekAmt'] }}</td>
                            <td>{{ data['december1stWeekPageNo'] }}</td>
                            <td>{{ data['december2ndWeekAmt'] }}</td>
                            <td>{{ data['december2ndWeekPageNo'] }}</td>
                            <td>{{ data['december3rdWeekAmt'] }}</td>
                            <td>{{ data['december3rdWeekPageNo'] }}</td>
                            <td>{{ data['december4thWeekAmt'] }}</td>
                            <td>{{ data['december4thWeekPageNo'] }}</td>
                            <td *ngIf="this.sundays(today | date:'yyyy', 11) > 4">{{ data['december5thWeekAmt'] }}</td>
                            <td *ngIf="this.sundays(today | date:'yyyy', 11) > 4">{{ data['december5thWeekPageNo'] }}
                            </td>
                            <!-- <td></td>
                            <td></td> -->
                            <td>{{ data['january1stWeekAmt'] }}</td>
                            <td>{{ data['january1stWeekPageNo'] }}</td>
                            <td>{{ data['january2ndWeekAmt'] }}</td>
                            <td>{{ data['january2ndWeekPageNo'] }}</td>
                            <td>{{ data['january3rdWeekAmt'] }}</td>
                            <td>{{ data['january3rdWeekPageNo'] }}</td>
                            <td>{{ data['january4thWeekAmt'] }}</td>
                            <td>{{ data['january4thWeekPageNo'] }}</td>
                            <td *ngIf="this.sundays(nexttoday | date:'yyyy', 0) > 4">{{ data['january5thWeekAmt'] }}
                            </td>
                            <td *ngIf="this.sundays(nexttoday | date:'yyyy', 0) > 4">{{ data['january5thWeekPageNo'] }}
                            </td>
                            <!-- <td></td>
                            <td></td> -->
                            <td>{{ data['feb1stWeekAmt'] }}</td>
                            <td>{{ data['feb1stWeekPageNo'] }}</td>
                            <td>{{ data['feb2ndWeekAmt'] }}</td>
                            <td>{{ data['feb2ndWeekPageNo'] }}</td>
                            <td>{{ data['feb3rdWeekAmt'] }}</td>
                            <td>{{ data['feb3rdWeekPageNo'] }}</td>
                            <td>{{ data['feb4thWeekAmt'] }}</td>
                            <td>{{ data['feb4thWeekPageNo'] }}</td>
                            <td *ngIf="this.sundays(nexttoday | date:'yyyy', 1) > 4">{{ data['feb5thWeekAmt'] }}</td>
                            <td *ngIf="this.sundays(nexttoday | date:'yyyy', 1) > 4">{{ data['feb5thWeekPageNo'] }}</td>
                            <!-- <td></td>
                            <td></td> -->
                            <td>{{ data['march1stWeekAmt'] }}</td>
                            <td>{{ data['march1stWeekPageNo'] }}</td>
                            <td>{{ data['march2ndWeekAmt'] }}</td>
                            <td>{{ data['march2ndWeekPageNo'] }}</td>
                            <td>{{ data['march3rdWeekAmt'] }}</td>
                            <td>{{ data['march3rdWeekPageNo'] }}</td>
                            <td>{{ data['march4thWeekAmt'] }}</td>
                            <td>{{ data['march4thWeekPageNo'] }}</td>
                            <td *ngIf="this.sundays(nexttoday | date:'yyyy', 2) > 4">{{ data['march5thWeekAmt'] }}</td>
                            <td *ngIf="this.sundays(nexttoday | date:'yyyy', 2) > 4">{{ data['march5thWeekPageNo'] }}
                            </td>
                            <!-- <td></td>
                            <td></td> -->
                        </tr>
                    </ng-container>
                    <tr>
                        <td></td>
                        <td style="font-weight: 600;">{{ item['name'] }} - एकूण</td>
                        <td></td>
                        <td></td>
                        <td>{{ item['contract_prev_bal'] }}</td>
                        <!-- <td>{{ item['remaining_contract'] }}</td> -->
                        <td></td>
                        <td>{{ item['contract_amt'] }}</td>
                        <td>{{ item['contract_discnt_amt'] }}</td>
                        <td>{{ item['contract_net_amt'] }}</td>
                        <td>{{ item['contract_net_gst_amt'] }}</td>
                        <td>{{ item['contract_net_total'] }}</td>
                        <td colspan="2"></td>
                        <td colspan="2"></td>
                        <td colspan="2"></td>
                        <td colspan="2"></td>
                        <td *ngIf="this.sundays(today | date:'yyyy', 3) > 4" colspan="2"></td>
                        <td colspan="2"></td>
                        <td colspan="2"></td>
                        <td colspan="2"></td>
                        <td colspan="2"></td>
                        <td colspan="2"></td>
                        <td *ngIf="this.sundays(today | date:'yyyy', 4) > 4" colspan="2"></td>
                        <td colspan="2"></td>
                        <td colspan="2"></td>
                        <td colspan="2"></td>
                        <td colspan="2"></td>
                        <td colspan="2"></td>
                        <td *ngIf="this.sundays(today | date:'yyyy', 5) > 4" colspan="2"></td>
                        <td colspan="2"></td>
                        <td colspan="2"></td>
                        <td colspan="2"></td>
                        <td colspan="2"></td>
                        <td colspan="2"></td>
                        <td *ngIf="this.sundays(today | date:'yyyy', 6) > 4" colspan="2"></td>
                        <td colspan="2"></td>
                        <td colspan="2"></td>
                        <td colspan="2"></td>
                        <td colspan="2"></td>
                        <td colspan="2"></td>
                        <td *ngIf="this.sundays(today | date:'yyyy', 7) > 4" colspan="2"></td>
                        <td colspan="2"></td>
                        <td colspan="2"></td>
                        <td colspan="2"></td>
                        <td colspan="2"></td>
                        <td colspan="2"></td>
                        <td *ngIf="this.sundays(today | date:'yyyy', 8) > 4" colspan="2"></td>
                        <td colspan="2"></td>
                        <td colspan="2"></td>
                        <td colspan="2"></td>
                        <td colspan="2"></td>
                        <td colspan="2"></td>
                        <td *ngIf="this.sundays(today | date:'yyyy', 9) > 4" colspan="2"></td>
                        <td colspan="2"></td>
                        <td colspan="2"></td>
                        <td colspan="2"></td>
                        <td colspan="2"></td>
                        <td colspan="2"></td>
                        <td *ngIf="this.sundays(today | date:'yyyy', 10) > 4" colspan="2"></td>
                        <td colspan="2"></td>
                        <td colspan="2"></td>
                        <td colspan="2"></td>
                        <td colspan="2"></td>
                        <td colspan="2"></td>
                        <td *ngIf="this.sundays(today | date:'yyyy', 11) > 4" colspan="2"></td>
                        <td colspan="2"></td>
                        <td colspan="2"></td>
                        <td colspan="2"></td>
                        <td colspan="2"></td>
                        <td colspan="2"></td>
                        <td *ngIf="this.sundays(nexttoday | date:'yyyy', 0) > 4" colspan="2"></td>
                        <td colspan="2"></td>
                        <td colspan="2"></td>
                        <td colspan="2"></td>
                        <td colspan="2"></td>
                        <td colspan="2"></td>
                        <td *ngIf="this.sundays(nexttoday | date:'yyyy', 1) > 4" colspan="2"></td>
                        <td colspan="2"></td>
                        <td colspan="2"></td>
                        <td colspan="2"></td>
                        <td colspan="2"></td>
                        <td colspan="2"></td>
                        <td *ngIf="this.sundays(nexttoday | date:'yyyy', 2) > 4" colspan="2"></td>
                        <td colspan="2"></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                </ng-container>
                <!-- <tr>
                    <td></td>
                    <td>एकूण</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr> -->
                <!-- <tr>
                    <td></td>
                    <td>एकूण</td>
                    <td></td>
                    <td></td>
                    <td>{{ totalData['contract_prev_bal'] }}</td>
                    <td>{{ totalData['remaining_contract'] }}</td>
                    <td>{{ totalData['contract_amt'] }}</td>
                    <td>{{ totalData['contract_discnt_amt'] }}</td>
                    <td>{{ totalData['contract_net_amt'] }}</td>
                    <td>{{ totalData['contract_net_gst_amt'] }}</td>
                    <td>{{ totalData['contract_net_total'] }}</td>
                    <td colspan="2"></td>
                    <td colspan="2"></td>
                    <td colspan="2"></td>
                    <td colspan="2"></td>
                    <td colspan="2" *ngIf="this.sundays(today | date:'yyyy', 3) > 4"></td>
                    <td colspan="2"></td>
                    <td colspan="2"></td>
                    <td colspan="2"></td>
                    <td colspan="2"></td>
                    <td colspan="2"></td>
                    <td colspan="2" *ngIf="this.sundays(today | date:'yyyy', 4) > 4"></td>
                    <td colspan="2"></td>
                    <td colspan="2"></td>
                    <td colspan="2"></td>
                    <td colspan="2"></td>
                    <td colspan="2"></td>
                    <td colspan="2" *ngIf="this.sundays(today | date:'yyyy', 5) > 4"></td>
                    <td colspan="2"></td>
                    <td colspan="2"></td>
                    <td colspan="2"></td>
                    <td colspan="2"></td>
                    <td colspan="2"></td>
                    <td colspan="2" *ngIf="this.sundays(today | date:'yyyy', 6) > 4"></td>
                    <td colspan="2"></td>
                    <td colspan="2"></td>
                    <td colspan="2"></td>
                    <td colspan="2"></td>
                    <td colspan="2"></td>
                    <td colspan="2" *ngIf="this.sundays(today | date:'yyyy', 7) > 4"></td>
                    <td colspan="2"></td>
                    <td colspan="2"></td>
                    <td colspan="2"></td>
                    <td colspan="2"></td>
                    <td colspan="2"></td>
                    <td colspan="2" *ngIf="this.sundays(today | date:'yyyy', 8) > 4"></td>
                    <td colspan="2"></td>
                    <td colspan="2"></td>
                    <td colspan="2"></td>
                    <td colspan="2"></td>
                    <td colspan="2"></td>
                    <td colspan="2" *ngIf="this.sundays(today | date:'yyyy', 9) > 4"></td>
                    <td colspan="2"></td>
                    <td colspan="2"></td>
                    <td colspan="2"></td>
                    <td colspan="2"></td>
                    <td colspan="2"></td>
                    <td colspan="2" *ngIf="this.sundays(today | date:'yyyy', 10) > 4"></td>
                    <td colspan="2"></td>
                    <td colspan="2"></td>
                    <td colspan="2"></td>
                    <td colspan="2"></td>
                    <td colspan="2"></td>
                    <td colspan="2" *ngIf="this.sundays(today | date:'yyyy', 11) > 4"></td>
                    <td colspan="2"></td>
                    <td colspan="2"></td>
                    <td colspan="2"></td>
                    <td colspan="2"></td>
                    <td colspan="2"></td>
                    <td colspan="2" *ngIf="this.sundays(nexttoday | date:'yyyy', 0) > 4"></td>
                    <td colspan="2"></td>
                    <td colspan="2"></td>
                    <td colspan="2"></td>
                    <td colspan="2"></td>
                    <td colspan="2"></td>
                    <td colspan="2" *ngIf="this.sundays(nexttoday | date:'yyyy', 1) > 4"></td>
                    <td colspan="2"></td>
                    <td colspan="2"></td>
                    <td colspan="2"></td>
                    <td colspan="2"></td>
                    <td colspan="2"></td>
                    <td colspan="2" *ngIf="this.sundays(nexttoday | date:'yyyy', 2) > 4"></td>
                    <td colspan="2"></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr> -->
            </tbody>
            <!-- <tfoot>
                <tr>
                    <td></td>
                    <td>एकूण</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
            </tfoot> -->
        </table>
        <!-- </div> -->
    </div>
</div>

<div *ngIf="reportType == 'InDetail'">
  
    <div class="container" id="print-section">
        <table class="table " style="margin-top: 50px;">            
            <thead >
                <tr>
                    <th colspan="3" style="border:none" class="br-bottom br-right br-top">Vivek Saptahik</th>
                    <th colspan="5" style="border:none" class="br-bottom br-right br-top">Advt. Payment Receipt Leadger</th>
                    <th colspan="3" style="border:none" class="br-bottom  br-top">Date From : {{startDate | date: 'dd-MM-yyyy'}} To : {{endDate | date: 'dd-MM-yyyy'}}</th>
                    <!-- <th *ngIf="totalweeks == 54" colspan="131"></th>
                    <th *ngIf="totalweeks == 53" colspan="130"></th>
                    <th *ngIf="totalweeks == 52" colspan="129"></th> -->
                </tr>
                <tr>
                    <th colspan="4" style="border:none" class="br-bottom br-right br-top">Datewise</th>
                    <th colspan="7" style="border:none" class="br-bottom  br-top">In Details</th>
                    
                    <!-- <th *ngIf="totalweeks == 54" colspan="131"></th>
                    <th *ngIf="totalweeks == 53" colspan="130"></th>
                    <th *ngIf="totalweeks == 52" colspan="129"></th> -->
                </tr>
                <!-- <tr>
                    <th colspan="11">सा. विवेक जाहिरात वार्षिक करार २०२२-२३ नोंदणी तक्ता</th>
                    <th colspan="4">Indetail</th>
                    <th *ngIf="totalweeks == 54" colspan="131"></th>
                    <th *ngIf="totalweeks == 53" colspan="130"></th>
                    <th *ngIf="totalweeks == 52" colspan="129"></th>
                </tr> -->
                <tr>
                    <th style="border:none" class="br-bottom br-right br-top">Receipt No</th>
                    <th style="border:none" class="br-bottom br-right br-top">Receipt Date</th>
                    <th style="border:none" class="br-bottom br-right br-top">Advertiser Name</th>
                    <th style="border:none" class="br-bottom br-right br-top">Reprensantive Name</th>
                    <th style="border:none" class="br-bottom br-right br-top">Office Reprensantive Name</th>
                    <th style="border:none" class="br-bottom br-right br-top">Issue Name</th>
                    <th style="border:none" class="br-bottom br-right br-top">Issue Date</th>
                    <th style="border:none" class="br-bottom br-right br-top">Invoice Number</th>
                    <th style="border:none" class="br-bottom br-right br-top">Invoice Date</th>
                    <th style="border:none; text-align: end;" class="br-bottom br-right br-top">Invoice Net Amount</th>
                    <th style="border:none; text-align: end;" class="br-bottom br-right br-top">Received Amount</th>

                </tr>
            </thead>

            <tbody *ngFor="let data of advtPaymentRegiInDetailsFormBeans; let i = index">
                
                <tr>
                    <td>{{data.rcpt_no}}</td>
                    <td>{{data.rcpt_date}}</td>
                    <td>{{data.advt_name}}</td>
                    <td>{{data.rep_name}}</td>
                    <td>{{data.off_rep_name}}</td>
                    <td>{{data.DS_ISSUENAME}} </td>
                    <td>{{data.DS_ISSUEDATE}}</td>
                    <td>{{data.advt_invoice_no}}</td>
                    <td>{{data.advt_invoice_date}}</td>
                    <td style="text-align: end;">{{data.net_amt}}</td>
                    <td style="text-align: end;">{{data.rcv_amt}}</td>

                    
                </tr>
            </tbody>

            <tfoot>
                <tr>
                  <td colspan="9" style="font-weight: bold;">Total:</td>
                  <td colspan="1" style="font-weight: bold; text-align: end;">{{PaymentadvtOffInDTotal_netamt}}</td>
                  <td colspan="1" style="font-weight: bold; text-align: end;">{{PaymentadvtOffInDTotal}}</td>

                </tr>
            </tfoot> 
        </table>
        <!-- </div> -->
    </div>

    <div style="margin-left: 80%; margin-bottom: 2%;">
        <!-- <button class="noPrint" style="margin-top: 2%;" mat-raised-button color="primary"
            [printStyle]="{table : {'border-collapse':'collapse'}, td : {'border': 'solid 1px'}, th : {'border': 'solid 1px'}}"
            printSectionId="print-section2" ngxPrint>
            print
        </button> -->
        <button class="noPrint" style="margin-top: 2%; margin-left: 2%"
            (click)="exportExcel('Contract_InDetails_Report')" mat-raised-button color="primary">
            Export
        </button>

        <button class="noPrint" style="margin-top: 2%;" mat-raised-button color="primary"
            printSectionId="print-section" ngxPrint  [useExistingCss]="true">
            print
        </button>
        
        <button class="noPrint" style="margin-top: 2%" mat-button mat-dialog-close>
            Close
        </button>
    </div>
</div>

<div *ngIf="reportType == 'briefly1'">
   
    <div class="container" id="print-section">
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th colspan="1">Vivek Saptahik</th>
                    <th colspan="3">Advt. Payment Receipt Leadger</th>
                    <th colspan="1">Briefly</th>
                    <th colspan="1">Date</th>
                </tr>
                <tr>
                    <th colspan="1">Datewise</th>
                    <th colspan="1">Date</th>
                    <th colspan="2">From : {{startDate | date: 'dd-MM-yyyy'}} To : {{endDate | date: 'dd-MM-yyyy'}}</th>
                    <th colspan="2">Page No</th>
                </tr>
                <tr>
                    <th>Receipt No</th>
                    <th>Receipt Date</th>
                    <th>Advertiser Name</th>
                    <th>Reprensantive Name</th>
                    <th>Office Reprensantive Name</th>
                    <th>Received Amount</th>
                </tr>
            </thead>

            <tbody >
                <tr *ngFor="let data1 of advtBrieflyFormBeans; let i = index">
                    <td>{{data1.rcpt_no}}</td>
                    <td>{{data1.rcpt_date}}</td>
                    <td>{{data1.advt_name}} </td>
                    <td>{{data1.rep_name}}</td>
                    <td>{{data1.office_rep}}</td>
                    <td style="text-align: end;">{{data1.rcv_amt}}</td>
                    
                </tr>

            </tbody>

            <tfoot>
                <tr>
                  <td colspan="4" style="font-weight: bold;">Total:</td>
                  <td colspan="2" style="font-weight: bold; text-align: end;">Rs {{advtBrieflyTotal}}</td>
                </tr>
              </tfoot> 
        </table>
        <!-- </div> -->
    </div>

    <div style="margin-left: 80%; margin-bottom: 2%;">
        <!-- <button class="noPrint" style="margin-top: 2%;" mat-raised-button color="primary"
            [printStyle]="{table : {'border-collapse':'collapse'}, td : {'border': 'solid 1px'}, th : {'border': 'solid 1px'}}"
            printSectionId="print-section2" ngxPrint>
            print
        </button> -->
        <button class="noPrint" style="margin-top: 2%; margin-left: 2%"
            (click)="exportExcel('Contract_InDetails_Report')" mat-raised-button color="primary">
            Export
        </button>

        <button class="noPrint" style="margin-top: 2%;" mat-raised-button color="primary"
            printSectionId="print-section" ngxPrint [useExistingCss]="true">
            print
        </button>
        
        <button class="noPrint" style="margin-top: 2%" mat-button mat-dialog-close>
            Close
        </button>
    </div>
</div>

<div *ngIf="reportType == 'Monthwise'">
    
    <div class="container" id="print-section">
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th colspan="2">Vivek Saptahik</th>
                    <th colspan="4">Advt. Payment Receipt Leadger</th>
                    <!-- <th colspan="1">Brifly</th>
                    <th colspan="1">Date</th> -->
                </tr>
                <tr>
                    <th colspan="2">Monthwise</th>
                    <th colspan="2">Date From : {{startDate | date: 'dd-MM-yyyy'}} To : {{endDate | date: 'dd-MM-yyyy'}}</th>
                    <th colspan="2">Page No</th>

                </tr>
                <tr >
                    <th colspan="2">Months</th>
                    <th colspan="4">Received Amount </th>
                </tr>
            </thead>

            <tbody >
                <tr *ngFor="let data of advtMonthwiseFormBeans; let i = index">
                    <td colspan="2">{{data.month}} - {{data.year}}</td>
                    <td colspan="4" style="text-align: end;">{{data.rcv_amt}}</td>
                </tr>
                
            </tbody>

            <tfoot>
                <tr>
                  <td colspan="2" style="font-weight: bold; text-align: end;">Total:</td>
                  <td colspan="4" style="font-weight: bold; text-align: end;">{{advtMonthwiseTotal}}</td>
                </tr>
              </tfoot> 
        </table>
        <!-- </div> -->
    </div>

    <div style="margin-left: 80%; margin-bottom: 2%;">
        <!-- <button class="noPrint" style="margin-top: 2%;" mat-raised-button color="primary"
            [printStyle]="{table : {'border-collapse':'collapse'}, td : {'border': 'solid 1px'}, th : {'border': 'solid 1px'}}"
            printSectionId="print-section2" ngxPrint>
            print
        </button> -->
        <button class="noPrint" style="margin-top: 2%; margin-left: 2%"
            (click)="exportExcel('Contract_InDetails_Report')" mat-raised-button color="primary">
            Export
        </button>

        <button class="noPrint" style="margin-top: 2%;" mat-raised-button color="primary"
            printSectionId="print-section" ngxPrint [useExistingCss]="true">
            print
        </button>
        
        <button class="noPrint" style="margin-top: 2%" mat-button mat-dialog-close>
            Close
        </button>
    </div>
</div>

<div *ngIf="reportType == 'InDetail-Officer'">
   
    <div class="container" id="print-section">
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th colspan="3">Vivek Saptahik</th>
                    <th colspan="6">Advt. Payment Receipt Leadger</th>
                    <th colspan="2">Date</th>
                    <!-- <th *ngIf="totalweeks == 54" colspan="131"></th>
                    <th *ngIf="totalweeks == 53" colspan="130"></th>
                    <th *ngIf="totalweeks == 52" colspan="129"></th> -->
                </tr>
                <tr>
                    <th colspan="3">Office Reprensantatiwise</th>
                    <th colspan="6">In Details</th>
                    <th colspan="1">From : {{startDate | date: 'dd-MM-yyyy'}} To : {{endDate | date: 'dd-MM-yyyy'}}</th>
                    <th colspan="1">Page No</th>

                    
                    <!-- <th *ngIf="totalweeks == 54" colspan="131"></th>
                    <th *ngIf="totalweeks == 53" colspan="130"></th>
                    <th *ngIf="totalweeks == 52" colspan="129"></th> -->
                </tr>

                <!-- <tr>
                    <th colspan="11">सा. विवेक जाहिरात वार्षिक करार २०२२-२३ नोंदणी तक्ता</th>
                    <th colspan="4">Indetail</th>
                    <th *ngIf="totalweeks == 54" colspan="131"></th>
                    <th *ngIf="totalweeks == 53" colspan="130"></th>
                    <th *ngIf="totalweeks == 52" colspan="129"></th>
                </tr> -->
                <tr>
                    <th>Receipt No</th>
                    <th>Receipt Date</th>
                    <th>Advertiser Name</th>
                    <th>Reprensantive Name</th>
                    <th>Office Reprensantive Name</th>
                    <th>Issue Name</th>
                    <th>Issue Date</th>
                    <th>Invoice Number</th>
                    <th>Invoice Date</th>
                    <th>Invoice Net Amount</th>
                    <th>Received Amount</th>
                </tr>
            </thead>

    <tbody >
            <ng-container *ngFor="let data of PaymentadvtOffInDFormBenas; let i = index">
                <tr>
                    <td style="font-weight: bold; font-size: 14px;">Office Reprensantatiwise</td>
                    <td style="font-weight: bold; font-size: 14px;">{{data.off_rep_name}}</td>
                </tr>
                <ng-container *ngFor="let repData of data.rep_data; let j = index">
                <tr > 
                    <td colspan="10" style="font-weight: bold;">{{repData.rep_name}}</td>
                    <td colspan="1" style="text-align: end; font-weight: bold;">{{repData.rep_total}}</td>
                </tr>
                <tr *ngFor="let advtData of repData.advt_data; let k = index">
                    <td>{{advtData.rcpt_no}}</td>
                    <td>{{advtData.rcpt_date}}</td>
                    <td>{{advtData.advt_name}}</td>
                    <td></td>
                    <td>{{advtData.office_rep}}</td>
                    <td>{{advtData.DS_ISSUENAME}}</td>
                    <td>{{advtData.DS_ISSUEDATE}}</td>
                    <td>{{advtData.advt_invoice_no}}</td>
                    <td>{{advtData.advt_invoice_date}}</td>
                    <td style="text-align: end;">{{advtData.net_amt}}</td>
                    <td style="text-align: end;">{{advtData.rcv_amt}}</td>
                </tr>
                <tr>
                    <td colspan="10">Rep Total:</td>
                    <td colspan="1" style="text-align: end; font-weight: bold;">{{repData.rep_total}}</td>
                </tr>
            </ng-container>
                <tr>
                    <td colspan="10">Off Total:</td>
                    <td colspan="1" style="text-align: end; font-weight: bold;">{{data.total}}</td>
                </tr>
            </ng-container>
</tbody>


            <tfoot>
                <tr>
                  <td colspan="10" style="font-weight: bold;">Total:</td>
                  <td colspan="1" style="font-weight: bold; text-align: end;">{{PaymentadvtOffInDTotal2}}</td>
                </tr>
              </tfoot> 
        </table>
        <!-- </div> -->
    </div>

    <div style="margin-left: 80%; margin-bottom: 2%;">
        <!-- <button class="noPrint" style="margin-top: 2%;" mat-raised-button color="primary"
            [printStyle]="{table : {'border-collapse':'collapse'}, td : {'border': 'solid 1px'}, th : {'border': 'solid 1px'}}"
            printSectionId="print-section2" ngxPrint>
            print
        </button> -->
        <button class="noPrint" style="margin-top: 2%; margin-left: 2%"
            (click)="exportExcel('Contract_InDetails_Report')" mat-raised-button color="primary">
            ExportOff
        </button>

        <button class="noPrint" style="margin-top: 2%;" mat-raised-button color="primary"
            printSectionId="print-section" ngxPrint [useExistingCss]="true">
            print
        </button>
        
        <button class="noPrint" style="margin-top: 2%" mat-button mat-dialog-close>
            Close
        </button>
    </div>
</div>
<div *ngIf="reportType == 'VeryBriefly-Officer'">
    
    <div class="container" id="print-section">
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th colspan="2">Vivek Saptahik</th>
                    <th colspan="4">Advt. Payment Receipt Leadger</th>
                    <th colspan="1">Very Briefly</th>
                    <th colspan="1">Date</th>
                </tr>
                <tr>
                    <th colspan="3">Office Reprensantive Wise</th>
                    <th colspan="3">From  {{startDate | date: 'dd-MM-yyyy'}} To {{endDate | date: 'dd-MM-yyyy'}}</th>
                    <th colspan="2">Page No</th>
                </tr>
                <tr>
                    <th colspan="2">Reprensantive Name</th>
                    <th colspan="2">Office Reprensantive Name</th>
                    <th colspan="2">Received Amount</th>
                    <th colspan="2">Total</th>
                </tr>
              
            </thead>

            <tbody *ngFor="let data of advtFormBeans; let i = index">
                <tr>
                    <td colspan="2" style="font-size: 14px; font-weight: bold;">Office Reprensantive</td>
                    <td colspan="2" style="font-size: 14px; font-weight: bold;">{{data.office_rep}}</td>
                    <td colspan="2"></td>
                    <td colspan="2"></td>
                </tr>

                <tr *ngFor="let data1 of data.rep_data; let i = index">
                    <td colspan="2">{{data1.rep_name}}</td>
                    <td colspan="2">{{data.office_rep}}</td>
                    <td colspan="2" style="text-align: end;">{{data1.rcv_amt}}</td>
                    <td colspan="2"></td>
                </tr>
                <tr>
                    <td colspan="2" style="font-weight: bold;">{{data.office_rep}}</td>
                    <td colspan="2" style="font-weight: bold;">Total</td>
                    <td colspan="2"></td>
                    <td colspan="2" style="font-weight: bold; text-align: end;">{{data.total}}</td>
                </tr>

            
                <!-- <tr *ngFor="let obj of advtFormBeans">
                    <td colspan="2">{{obj.rep_name}}</td>
                    <td colspan="2">{{obj.office_rep}}</td>
                    <td colspan="2">{{obj.rec_amt}}</td>
                    <td colspan="2"></td>
                </tr> -->
                <!-- <tr>
                    <td colspan="2">Ravindra</td>
                    <td colspan="2">Rakesh Sonar</td>
                    <td colspan="2">6,000</td>
                    <td colspan="2"></td>
                </tr> -->

                <!-- <tr style="font-weight: bold;">
                    <td colspan="2">Rakesh Sonar</td>
                    <td colspan="2">Total</td>
                    <td colspan="2"></td>
                    <td colspan="2">11,000</td>
                </tr> -->
            </tbody>

            <tfoot>
                <tr>
                  <td colspan="6" style="font-weight: bold;">Total:</td>
                  <td colspan="2" style="font-weight: bold; text-align: end;">{{advtVBTotal}}</td>
                </tr>
              </tfoot> 
        </table>
       
    </div>

    <div style="margin-left: 80%;  margin-bottom: 2%;">
        <!-- <button class="noPrint" style="margin-top: 2%;" mat-raised-button color="primary"
            [printStyle]="{table : {'border-collapse':'collapse'}, td : {'border': 'solid 1px'}, th : {'border': 'solid 1px'}}"
            printSectionId="print-section2" ngxPrint>
            print
        </button> -->
        <button class="noPrint" style="margin-top: 2%; margin-left: 2%"
            (click)="exportExcel('Contract_InDetails_Report')" mat-raised-button color="primary">
            Export
        </button>

        <button class="noPrint" style="margin-top: 2%;" mat-raised-button color="primary"
            printSectionId="print-section" ngxPrint [useExistingCss]="true">
            print
        </button>
        
        <button class="noPrint" style="margin-top: 2%" mat-button mat-dialog-close>
            Close
        </button>
    </div>
</div>

<div *ngIf="reportType == 'Briefly-Officer'">
   
    <div class="container" id="print-section">
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th colspan="1">Vivek Saptahik</th>
                    <th colspan="3">Advt. Payment Receipt Leadger</th>
                    <th colspan="1">Briefly-Officer</th>
                    <th colspan="1">Date</th>
                </tr>
                <tr>
                    <th colspan="2">Datewise</th>
                    <th colspan="3">From  {{startDate | date: 'dd-MM-yyyy'}} To {{endDate | date: 'dd-MM-yyyy'}}</th>
                    <th colspan="1">Page No</th>
                </tr>
                <tr>
                    <th>Receipt No</th>
                    <th>Receipt Date</th>
                    <th>Advertiser Name</th>
                    <th>Reprensantive Name</th>
                    <th>Office Reprensantive Name</th>
                    <th>Received Amount</th>
                </tr>

                
            </thead>

            <tbody>
                <ng-container  *ngFor="let data of advtOfficierbrieflyFormBeans; let i = index">
                <tr >
                    <td colspan="2" style="font-weight: bold;font-size: 15px;">Office Reprensantive</td>
                    <td colspan="1" style="font-weight: bold;font-size: 15px;">{{data.rep_name}}</td>
                    <td colspan="3"></td>
                </tr>
                 
                <tr *ngFor="let data2 of data.rep_data; let j = index">
                    <td colspan="1" >{{data2.rcpt_no}}</td>
                    <td colspan="1" >{{data2.rcpt_date}}</td>
                    <td colspan="1">{{data2.advt_name}}</td>
                    <td colspan="1">{{data.rep_name}}</td>
                    <td colspan="1">{{data.rep_name}}</td>
                    <td colspan="1" style="text-align: end;">{{data2.rcv_amt}}</td>
                </tr> 
                <tr>
                    <td colspan="5" style="font-weight: bold; font: 16px;">Total</td>
                    <td colspan="1" style="text-align: end;font-weight: bold; font: 16px;">{{data.total_amt}}</td>
                   
                </tr>
            </ng-container>
            </tbody>

            <tfoot>
                <tr>
                  <td colspan="4" style="font-weight: bold;">Total:</td>
                  <td colspan="2" style="font-weight: bold; text-align: end;">{{advtOfficierbrieflyTotal}}</td>
                </tr>
              </tfoot> 
        </table>
        <!-- </div> -->
    </div>

    <div style="margin-left: 80%; margin-bottom: 2%;">
        <!-- <button class="noPrint" style="margin-top: 2%;" mat-raised-button color="primary"
            [printStyle]="{table : {'border-collapse':'collapse'}, td : {'border': 'solid 1px'}, th : {'border': 'solid 1px'}}"
            printSectionId="print-section2" ngxPrint>
            print
        </button> -->
        <button class="noPrint" style="margin-top: 2%; margin-left: 2%"
            (click)="exportExcel('Contract_InDetails_Report')" mat-raised-button color="primary">
            Export
        </button>

        <button class="noPrint" style="margin-top: 2%;" mat-raised-button color="primary"
            printSectionId="print-section" ngxPrint [useExistingCss]="true">
            print
        </button>
        
        <button class="noPrint" style="margin-top: 2%" mat-button mat-dialog-close>
            Close
        </button>
    </div>
</div>

<!-- CREDIT NOTE -->
<div *ngIf="reportType == 'InDetailCreditNote'">
  
    <div class="container" id="print-section">
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th colspan="3">Vivek Saptahik</th>
                    <th colspan="6">Advt. Credit Note Register</th>
                    <th colspan="3">Date :</th>
                    <!-- <th *ngIf="totalweeks == 54" colspan="131"></th>
                    <th *ngIf="totalweeks == 53" colspan="130"></th>
                    <th *ngIf="totalweeks == 52" colspan="129"></th> -->
                </tr>
                <tr>
                    <th colspan="2">Datewise</th>
                    <th colspan="5">In Details</th>
                    <th colspan="3">Date From  {{startDate}} To {{endDate}}</th>
                    <th colspan="2">Page No</th>


                    
                    <!-- <th *ngIf="totalweeks == 54" colspan="131"></th>
                    <th *ngIf="totalweeks == 53" colspan="130"></th>
                    <th *ngIf="totalweeks == 52" colspan="129"></th> -->
                </tr>
                <!-- <tr>
                    <th colspan="11">सा. विवेक जाहिरात वार्षिक करार २०२२-२३ नोंदणी तक्ता</th>
                    <th colspan="4">Indetail</th>
                    <th *ngIf="totalweeks == 54" colspan="131"></th>
                    <th *ngIf="totalweeks == 53" colspan="130"></th>
                    <th *ngIf="totalweeks == 52" colspan="129"></th>
                </tr> -->
                <tr>
                    <th>Credit Note No</th>
                    <th>CR Date</th>
                    <th>Advertiser Name</th>
                    <th>Reprensantive Name</th>
                    <th>Office Reprensantive Name</th>
                    <th>Issue Name</th>
                    <th>Issue Date</th>
                    <th>Invoice Number</th>
                    <th>Invoice Date</th>
                    <th>Invoice Net Amount</th>
                    <th>Received Amount</th>
                    <th>Remarks</th>
                </tr>
            </thead>

            <tbody *ngFor="let data of advtCreditRegiInDetailsFormBeans; let i = index">
                
                <tr>
                    <td>{{data.credit_voc_no}}</td>
                    <td>{{data.voc_date}}</td>
                    <td>{{data.advt_name}}</td>
                    <td>{{data.rep_name}}</td>
                    <td>{{data.office_rep}}</td>
                    <td>{{data.DS_ISSUENAME}} </td>
                    <td>{{data.DS_ISSUEDATE}}</td>
                    <td>{{data.advt_invoice_no}}</td>
                    <td>{{data.advt_invoice_date}}</td>
                    <td>{{data.net_amt}}</td>
                    <td>{{data.credit_v_amt}}</td>
                    <td>{{data.remark}}</td>
                    
                </tr>
            </tbody>

            <tfoot>
                <tr>
                  <td colspan="10">Total:</td>
                  <td colspan="1">{{CredittadvtInDTotal}}</td>
                  <td colspan="1"></td>

                </tr>
              </tfoot> 
        </table>
        <!-- </div> -->
    </div>

    <div style="margin-left: 80%; margin-bottom: 2%;">
        <!-- <button class="noPrint" style="margin-top: 2%;" mat-raised-button color="primary"
            [printStyle]="{table : {'border-collapse':'collapse'}, td : {'border': 'solid 1px'}, th : {'border': 'solid 1px'}}"
            printSectionId="print-section2" ngxPrint>
            print
        </button> -->
        <button class="noPrint" style="margin-top: 2%; margin-left: 2%"
            (click)="exportExcel('Contract_InDetails_Report')" mat-raised-button color="primary">
            Export
        </button>

        <button class="noPrint" style="margin-top: 2%;" mat-raised-button color="primary"
           [useExistingCss]="true"
            printSectionId="print-section" ngxPrint>
            print
        </button>
        
        <button class="noPrint" style="margin-top: 2%" mat-button mat-dialog-close>
            Close
        </button>
    </div>
</div>

<div *ngIf="reportType == 'InDetail-CreditOfficer'">
   
    <div class="container" id="print-section">
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th colspan="3">Vivek Saptahik</th>
                    <th colspan="5">Advt. Credit Note Register</th>
                    <th colspan="3">Date</th>
                    <!-- <th *ngIf="totalweeks == 54" colspan="131"></th>
                    <th *ngIf="totalweeks == 53" colspan="130"></th>
                    <th *ngIf="totalweeks == 52" colspan="129"></th> -->
                </tr>
                <tr>
                    <th colspan="3">Office Reprensantatiwise</th>
                    <th colspan="5">In Details</th>
                    <th colspan="2">Date From  {{startDate}} To {{endDate}}</th>
                    <th colspan="1">Page No</th>
                </tr>
                <!-- <tr>
                    <th colspan="11">सा. विवेक जाहिरात वार्षिक करार २०२२-२३ नोंदणी तक्ता</th>
                    <th colspan="4">Indetail</th>
                    <th *ngIf="totalweeks == 54" colspan="131"></th>
                    <th *ngIf="totalweeks == 53" colspan="130"></th>
                    <th *ngIf="totalweeks == 52" colspan="129"></th>
                </tr> -->
                <tr>
                    <th>Credit Note No</th>
                    <th>CR Date</th>
                    <th>Advertiser Name</th>
                    <th>Reprensantive Name</th>
                    <th>Office Reprensantive Name</th>
                    <th>Issue Name</th>
                    <th>Issue Date</th>
                    <th>Invoice Number</th>
                    <th>Invoice Date</th>
                    <th>Invoice Net Amount</th>
                    <th>Received Amount</th>
                </tr>
            </thead>

            <tbody *ngFor="let data of CreditadvtOffInDFormBenas; let i = index">

                <tr>
                    <td style="font-weight: bold; font-size: 15px;" colspan="2">Office Reprensantatiwise</td>
                    <td style="font-weight: bold; font-size: 15px;" >{{data.off_rep_name}}</td>
                </tr>
                
                <tr *ngFor="let data1 of data.rep_data; let i = index">
                    <td>{{data1.credit_voc_no}}</td>
                    <td>{{data1.voc_date}}</td>
                    <td>{{data1.advt_name}}</td>
                    <td>{{data1.rep_name}}</td>
                    <td>{{data.off_rep_name}}</td>
                    <td>{{data1.DS_ISSUENAME}} </td>
                    <td>{{data1.DS_ISSUEDATE}}</td>
                    <td>{{data1.advt_invoice_no}}</td>
                    <td>{{data1.advt_invoice_date}}</td>
                    <td>{{data1.net_amt}}</td>
                    <td>{{data1.credit_v_amt}}</td>
                    
                </tr>
                <tr>
                    <td colspan="10">Total:</td>
                    <td colspan="1">{{data.total}}</td>
                  </tr>
            </tbody>

            <tfoot>
                <tr>
                  <td colspan="10">Total:</td>
                  <td colspan="1">{{CreditadvtOffInDTotal}}</td>
                </tr>
              </tfoot> 
        </table>
        <!-- </div> -->
    </div>

    <div style="margin-left: 80%; margin-bottom: 2%;">
        <!-- <button class="noPrint" style="margin-top: 2%;" mat-raised-button color="primary"
            [printStyle]="{table : {'border-collapse':'collapse'}, td : {'border': 'solid 1px'}, th : {'border': 'solid 1px'}}"
            printSectionId="print-section2" ngxPrint>
            print
        </button> -->
        <button class="noPrint" style="margin-top: 2%; margin-left: 2%"
            (click)="exportExcel('Contract_InDetails_Report')" mat-raised-button color="primary">
            ExportOff
        </button>

        <button class="noPrint" style="margin-top: 2%;" mat-raised-button color="primary"
           [useExistingCss]="true"
            printSectionId="print-section" ngxPrint>
            print
        </button>
        
        <button class="noPrint" style="margin-top: 2%" mat-button mat-dialog-close>
            Close
        </button>
    </div>
</div>

<div *ngIf="reportType == 'CreditNotebriefly'">
   
    <div class="container" id="print-section">
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th colspan="1">Vivek Saptahik</th>
                    <th colspan="4">Advt. Credit Note Register</th>
                    <th colspan="1">Date :</th>

                    <!-- <th *ngIf="totalweeks == 54" colspan="131"></th>
                    <th *ngIf="totalweeks == 53" colspan="130"></th>
                    <th *ngIf="totalweeks == 52" colspan="129"></th> -->
                </tr>
                <tr>
                    <th colspan="1">Datewise</th>
                    <th colspan="1">Briefly</th>
                    <th colspan="2">Date From  {{startDate}} To {{endDate}}</th>
                    <th colspan="2">Page No :</th>
                    <!-- <th *ngIf="totalweeks == 54" colspan="131"></th>
                    <th *ngIf="totalweeks == 53" colspan="130"></th>
                    <th *ngIf="totalweeks == 52" colspan="129"></th> -->
                </tr>
                <!-- <tr>
                    <th colspan="11">सा. विवेक जाहिरात वार्षिक करार २०२२-२३ नोंदणी तक्ता</th>
                    <th colspan="4">Indetail</th>
                    <th *ngIf="totalweeks == 54" colspan="131"></th>
                    <th *ngIf="totalweeks == 53" colspan="130"></th>
                    <th *ngIf="totalweeks == 52" colspan="129"></th>
                </tr> -->
                <tr>
                    <th>Credit Note No</th>
                    <th>CR Date</th>
                    <th>Advertiser Name</th>
                    <th>Reprensantive Name</th>
                    <th>Office Reprensantive Name</th>
                    <th>Credit Note Amount</th>
                </tr>
            </thead>

            <tbody *ngFor="let data of advtCreditRegiInBrieflyFormBeans; let i = index" >
                <tr *ngFor="let data1 of data.rep_data; let i = index">
                    <td>{{data1.rcpt_no}}</td>
                    <td>{{data1.rcpt_date}}</td>
                    <td>{{data1.advt_name}} </td>
                    <td>{{data1.rep_name}}</td>
                    <td>{{data1.office_rep}}</td>
                    <td>{{data1.rec_amt}}</td>
                    
                </tr>

                <tr>
                    <td colspan="5" style="font-weight: bold; ">Total:</td>
                    <td colspan="1" style="font-weight: bold;  ">{{data.total}}</td>
                </tr>
                <!-- <tr>
                    <td>Receipt No-2</td>
                    <td>Receipt Date</td>
                    <td>Advertiser Name</td>
                    <td>Reprensantive Name</td>
                    <td>Office Reprensantive Name</td>
                    <td>Received Amount</td>
                    
                </tr>

                <tr>
                    <td colspan="5">Total:</td>
                    <td colspan="1">67540</td>
                </tr>
                <tr>
                    <td>Receipt No-3</td>
                    <td>Receipt Date</td>
                    <td>Advertiser Name</td>
                    <td>Reprensantive Name</td>
                    <td>Office Reprensantive Name</td>
                    <td>Received Amount</td>
                    
                </tr>

                <tr>
                    <td colspan="5">Total:</td>
                    <td colspan="1">67540</td>
                </tr> -->
            </tbody>

            <tfoot>
                <tr>
                  <td colspan="4">Total:</td>
                  <td colspan="2">{{CredittadvtInBrieflyTotal}}</td>
                </tr>
              </tfoot> 
        </table>
        <!-- </div> -->
    </div>

    <div style="margin-left: 80%;  margin-bottom: 2%;">
        <!-- <button class="noPrint" style="margin-top: 2%;" mat-raised-button color="primary"
            [printStyle]="{table : {'border-collapse':'collapse'}, td : {'border': 'solid 1px'}, th : {'border': 'solid 1px'}}"
            printSectionId="print-section2" ngxPrint>
            print
        </button> -->
        <button class="noPrint" style="margin-top: 2%; margin-left: 2%"
            (click)="exportExcel('Contract_InDetails_Report')" mat-raised-button color="primary">
            Export
        </button>

        <button class="noPrint" style="margin-top: 2%;" mat-raised-button color="primary"
           [useExistingCss]="true"
            printSectionId="print-section" ngxPrint>
            print
        </button>
        
        <button class="noPrint" style="margin-top: 2%" mat-button mat-dialog-close>
            Close
        </button>
    </div>
</div>

<div *ngIf="reportType == 'Briefly-OfficerCredit'">
   
    <div class="container" id="print-section">
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th colspan="1">Vivek Saptahik</th>
                    <th colspan="4">Advt. Credit Note Register</th>
                    <th colspan="1">Date </th>
                </tr>
                <tr>
                    <th colspan="2">Datewise</th>
                    <th colspan="2">Briefly-Officer</th>
                    <th colspan="1">Date From {{startDate}} To {{endDate}}</th>
                    <th colspan="1">Page No</th>

                </tr>
                <tr>
                    <th>Credit Note No.</th>
                    <th>CR Date</th>
                    <th>Advertiser Name</th>
                    <th>Reprensantive Name</th>
                    <th>Office Reprensantive Name</th>
                    <th>Credit Note Amount</th>
                </tr>

                
            </thead>

            <tbody *ngFor="let data of CreditNoteVFormBeans; let i = index">
                <tr>
                    <td colspan="2" style="font-weight: bold; font-size: 15px;">Office Reprensantive</td>
                    <td colspan="1" style="font-weight: bold; font-size: 15px;">{{data.office_rep}}</td>
                    <td colspan="3"></td>
                </tr>
                <tr *ngFor="let data1 of data.rep_data; let i = index">
                    <td>{{data1.credit_voc_no}}</td>
                    <td>{{data1.voc_date}}</td>
                    <td>{{data1.advt_name}}</td>
                    <td>{{data1.rep_name}} </td>
                    <td>{{data.office_rep}}</td>
                    <td>{{data1.credit_amt}}</td>
                    
                </tr>

                <tr>
                    <td colspan="5" style="font-weight: bold;">Total:</td>
                    <td colspan="1" style="font-weight: bold;">{{data.total}}</td>
                </tr>
              
            </tbody>

            <tfoot>
                <tr>
                  <td colspan="4">Total:</td>
                  <td colspan="2">{{CreditadvtOffBrieflyTotal}}</td>
                </tr>
              </tfoot> 
        </table>
       
    </div>
    <div style="margin-left: 80%;  margin-bottom: 2%;">
        <!-- <button class="noPrint" style="margin-top: 2%;" mat-raised-button color="primary"
            [printStyle]="{table : {'border-collapse':'collapse'}, td : {'border': 'solid 1px'}, th : {'border': 'solid 1px'}}"
            printSectionId="print-section2" ngxPrint>
            print
        </button> -->
        <button class="noPrint" style="margin-top: 2%; margin-left: 2%"
            (click)="exportExcel('Contract_InDetails_Report')" mat-raised-button color="primary">
            ExportOff
        </button>

        <button class="noPrint" style="margin-top: 2%;" mat-raised-button color="primary"
           [useExistingCss]="true"
            printSectionId="print-section" ngxPrint>
            print
        </button>
        
        <button class="noPrint" style="margin-top: 2%" mat-button mat-dialog-close>
            Close
        </button>
    </div>
</div>

<div *ngIf="reportType == 'VeryBriefly-CreditNote'">
   
    <div class="container" id="print-section">
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th colspan="2">Vivek Saptahik</th>
                    <th colspan="4">Advt. Credit Note Register</th>
                    <th colspan="2">Date </th>
                </tr>
                <tr>
                    <th colspan="2">Office Reprensantive</th>
                    <th colspan="3">In Details</th>
                    <th colspan="2">Date From {{startDate}} To {{endDate}}</th>
                    <th colspan="1">Page No</th>

                </tr>
                <tr>
                    <th colspan="2">Reprensantive Name</th>
                    <th colspan="2">Office Reprensantive Name</th>
                    <th colspan="2">Credit Note Amount</th>
                    <th colspan="2">Total</th>
                </tr>
              
            </thead>

            <tbody *ngFor="let data of CreditNoteVBFormBeans; let i = index">
                

                <tr>
                    <td colspan="2" style="font-weight: bold; font-size: 15px;">Office Reprensantive</td>
                    <td colspan="2" style="font-weight: bold; font-size: 15px;">{{data.office_rep}}</td>
                    <td colspan="2"></td>
                    <td colspan="2"></td>
                </tr>

                <tr *ngFor="let data1 of data.rep_data; let i = index">
                    <td colspan="2">{{data1.rep_name}}</td>
                    <td colspan="2">{{data.office_rep}}</td>
                    <td colspan="2">{{data1.credit_amt}}</td>
                    <td colspan="2"></td>
                </tr>

                <tr>
                    <td colspan="2" style="font-weight: bold;">{{data.office_rep}}</td>
                    <td colspan="2" style="font-weight: bold;">Total</td>
                    <td colspan="2"></td>
                    <td colspan="2" style="font-weight: bold;">{{data.total}}</td>
                </tr>
                

            
                <!-- <tr *ngFor="let obj of advtFormBeans">
                    <td colspan="2">{{obj.rep_name}}</td>
                    <td colspan="2">{{obj.office_rep}}</td>
                    <td colspan="2">{{obj.rec_amt}}</td>
                    <td colspan="2"></td>
                </tr> -->
                <!-- <tr>
                    <td colspan="2">Ravindra</td>
                    <td colspan="2">Rakesh Sonar</td>
                    <td colspan="2">6,000</td>
                    <td colspan="2"></td>
                </tr> -->

                <!-- <tr style="font-weight: bold;">
                    <td colspan="2">Rakesh Sonar</td>
                    <td colspan="2">Total</td>
                    <td colspan="2"></td>
                    <td colspan="2">11,000</td>
                </tr> -->
            </tbody>

            <tfoot>
                <tr>
                  <td colspan="6">Total:</td>
                  <td colspan="2">{{CreditadvtOffVBMTotal}}</td>
                </tr>
              </tfoot> 
        </table>
       
    </div>
    <div style="margin-left: 80%;  margin-bottom: 2%;">
        <!-- <button class="noPrint" style="margin-top: 2%;" mat-raised-button color="primary"
            [printStyle]="{table : {'border-collapse':'collapse'}, td : {'border': 'solid 1px'}, th : {'border': 'solid 1px'}}"
            printSectionId="print-section2" ngxPrint>
            print
        </button> -->
        <button class="noPrint" style="margin-top: 2%; margin-left: 2%"
            (click)="exportExcel('Contract_InDetails_Report')" mat-raised-button color="primary">
            ExportOff
        </button>

        <button class="noPrint" style="margin-top: 2%;" mat-raised-button color="primary"
           [useExistingCss]="true"
            printSectionId="print-section" ngxPrint>
            print
        </button>
        
        <button class="noPrint" style="margin-top: 2%" mat-button mat-dialog-close>
            Close
        </button>
    </div>
</div>

<div *ngIf="reportType == 'CreditMonthwise'">
  
    <div class="container" id="print-section">
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th colspan="2">Vivek Saptahik</th>
                    <th colspan="4">Advt. Credit Note Register</th>
                </tr>
                <tr>
                    <th colspan="3">Monthwise</th>
                    <th colspan="3">Date From  {{startDate}} To {{endDate}} </th>
                </tr>
                <tr>
                    <th colspan="3">Month</th>
                    <th colspan="3">Credit Note Amount</th>
                </tr>
            </thead>

            <tbody >
                <tr *ngFor="let data of CreditNoteMFormBeans; let i = index">
                    <td colspan="3">{{data.Month}}</td>
                    <td colspan="3">{{data.total_amt}}</td>
                </tr>
                
            </tbody>

            <tfoot>
                <tr>
                  <td colspan="3">Total:</td>
                  <td colspan="3">{{CreditadvtOffMTotal}}</td>
                </tr>
              </tfoot> 
        </table>
        <!-- </div> -->
    </div>

    <div style="margin-left: 80%; margin-top: -4%; margin-bottom: 2%;">
        <!-- <button class="noPrint" style="margin-top: 2%;" mat-raised-button color="primary"
            [printStyle]="{table : {'border-collapse':'collapse'}, td : {'border': 'solid 1px'}, th : {'border': 'solid 1px'}}"
            printSectionId="print-section2" ngxPrint>
            print
        </button> -->
        <button class="noPrint" style="margin-top: 2%; margin-left: 2%"
            (click)="exportExcel('Contract_InDetails_Report')" mat-raised-button color="primary">
            Export
        </button>

        <button class="noPrint" style="margin-top: 2%;" mat-raised-button color="primary"
           [useExistingCss]="true"
            printSectionId="print-section" ngxPrint>
            print
        </button>
        
        <button class="noPrint" style="margin-top: 2%" mat-button mat-dialog-close>
            Close
        </button>
    </div>
</div>


<!-- Advt Outstanding -->
<div *ngIf="reportType == 'AdvtoutstandingInDetails'">
    
    <div class="container" id="print-section">
        <table class="table " style="margin-top: 50px;">
            <thead>
                <tr>
                    <th colspan="2" style="border:none" class="br-bottom br-right br-top">हिंदुस्तान प्रकाशन संस्था - विवेक साप्ताहिक</th>
                    <th colspan="4" style="border:none" class="br-bottom br-right br-top ">Advt. Outstanding Report</th>
                    <th colspan="2" style="border:none" class="br-bottom br-right br-top">दिनांक</th>
                    <th colspan="1" style="border:none" class="br-bottom br-top ">पृष्ठ क्र</th>
                </tr>
                <tr>
                    <th colspan="2" style="border:none" class="br-bottom br-right ">अंकाचे नाव</th>
                    <th colspan="4" style="border:none" class="br-bottom br-right ">All Advt List - In Details</th>
                    <!-- <th colspan="2"></th> -->
                    <!-- <th colspan="1">To {{endDate}}</th> -->
                    <th colspan="4" style="border:none" class="br-bottom ">Date : From {{startDate}} To {{endDate}}</th>
                    <!-- <th colspan="1"></th> -->
                </tr>
                <tr>
                    <th colspan="1" style="border:none"  class="br-bottom br-right">Invoice No</th>
                    <th colspan="1" style="border:none"  class="br-bottom br-right">Invoice Date</th>
                    <th colspan="1" style="border:none"  class="br-bottom br-right">Advt Name </th>
                    <th colspan="1" style="border:none"  class="br-bottom br-right">Contact No</th>
                    <th colspan="1" style="border:none"  class="br-bottom br-right">Issue Name</th>
                    <th colspan="1" style="border:none"  class="br-bottom br-right">Issue Date</th>
                    <th colspan="1" style="border:none"  class="br-bottom br-right">Advt Amt</th>
                    <th colspan="1" style="border:none"  class="br-bottom br-right">Received Amount</th>
                    <th colspan="1" style="border:none"  class="br-bottom ">Unpaid Amount</th>
                </tr>   
            </thead>
            <!-- *ngFor="let data of OutstandingInDetailFormBeans; let i = index" -->
            <tbody *ngFor="let data of OutstandingInDetailFormBeans; let i = index">
                <tr>
                    <td colspan="9" style=" font-size: 16px; text-align: start !important; border: none;" class="br-bottom br-top" >Office Representative Name : <span class="ft-wt-500">{{data.off_rep_name}} / {{data.office_rep_mob}}</span> </td>
                    <!-- <td colspan="2" style="font-size: 13px; text-align: end !important;"></td>
                    <td colspan="4" style="font-size: 13px;"></td> -->
                    <!-- <td colspan="3" style="font-weight: bold; font-size: 16px;"></td> -->
                </tr>
            <ng-container *ngFor="let data1 of data.rep_data; let j = index">
                <tr>
                    <td colspan="9" style="font-size: 16px;  text-align: start !important;border: none;" class="br-bottom">Representative Name : <span class="ft-wt-500">{{data1.rep_name}} / {{data1.rep_mob_no}}</span></td>
                    <!-- <td colspan="2" style="font-size: 13px; text-align: end !important;"></td>
                    <td colspan="4" style="font-size: 13px;"></td> -->
                    <!-- <td colspan="3" style="font-weight: bold; font-size: 16px;"></td> -->
                </tr>
               
                <tr *ngFor="let data2 of data1.advt_data; let k = index">
                    <td colspan="1" style="border:none" class="br-right">{{data2.advt_invoice_no}}</td>
                    <td colspan="1" style="border:none" class="br-right">{{data2.advt_invoice_date}}</td>
                    <td colspan="2" style="border:none" class="br-right">{{data2.advt_name}} <span><br>{{data.mobile}}</span></td>
                    <td colspan="1" style="border:none" class="br-right">{{data2.issue_name}}</td>                   
                    <td colspan="1" style="border:none" class="br-right">{{data2.issue_date}}</td>
                    <td colspan="1" style="border:none; text-align: end;" class="br-right">{{data2.advt_amt}}</td>
                    <td colspan="1" style="border:none; text-align: end;" class="br-right">{{data2.net_amt}}</td>
                    <td colspan="1" style="border:none; text-align: end;">{{data2.unpaid_amt}}</td>
                    
                </tr>
                <tr>
                    <td colspan="6" style="font-size: 16px;border:none"  class="br-bottom br-top br-right">Total: {{data1.rep_name}}</td>
                    <td colspan="1 " style="font-size: 16px; border:none; text-align: end; font-weight: bold;" class="br-bottom br-top br-right">{{data1.advt_amt_total}}</td>
                    <td colspan="1" style="font-size: 16px; border:none; text-align: end; font-weight: bold;" class="br-bottom br-top br-right">{{data1.net_amt_total}}</td>
                    <td colspan="1" style="font-size: 16px; border:none; text-align: end; font-weight: bold;" class="br-bottom br-top" >{{data1.unpaid_amt_total}}</td>
                </tr>
            </ng-container>
                 
            </tbody>

            <tfoot>
                <tr>
                    <td colspan="6" style="font-size: 16px;font-weight: 500; border:none" class="br-bottom br-top br-right">Total:</td>
                    <td colspan="1" style="font-size: 16px;font-weight: 500; border:none; text-align: end;" class="br-bottom br-top br-right">Rs : {{ OutStandingIDTotal }}</td>
                    <td colspan="1" style="font-size: 16px;font-weight: 500; border:none; text-align: end;" class="br-bottom br-top br-right">Rs : {{ OutStandingIDNetAmtTotal }}</td>
                    <td colspan="1" style="font-size: 16px;font-weight: 500; border:none; text-align: end;" class="br-bottom br-top">Rs : {{ OutStandingIDUnPaidTotal }}</td>
                </tr>
              
              </tfoot>  
        </table>
       
    </div>

    <div style="margin-left: 80%; margin-bottom: 2%;">
        <!-- <button class="noPrint" style="margin-top: 2%;" mat-raised-button color="primary"
            [printStyle]="{table : {'border-collapse':'collapse'}, td : {'border': 'solid 1px'}, th : {'border': 'solid 1px'}}"
            printSectionId="print-section2" ngxPrint>
            print
        </button> -->
        <button class="noPrint" style="margin-top: 2%; margin-left: 2%"
            (click)="exportExcel('Contract_InDetails_Report')" mat-raised-button color="primary">
            Export
        </button>

        <button class="noPrint" style="margin-top: 2%;" mat-raised-button color="primary"
          printSectionId="print-section"  [useExistingCss]="true"  ngxPrint>
        print
        </button>
        
        <button class="noPrint" style="margin-top: 2%" mat-button mat-dialog-close>
            Close
        </button>
    </div>
</div>

<div *ngIf="reportType == 'Advtoutstandingbriefly'">
    
    <div class="container" id="print-section">
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th colspan="3" style="border:none" class="br-bottom br-right br-top">हिंदुस्तान प्रकाशन संस्था - विवेक साप्ताहिक</th>
                    <th colspan="2" style="border:none" class="br-bottom br-right br-top">Advt. Outstanding Report</th>
                    <th colspan="1" style="border:none" class="br-bottom br-right br-top">दिनांक</th>
                    <th colspan="1" style="border:none" class="br-bottom br-top">पृष्ठ क्र</th>
                </tr>
                <tr>
                    <th colspan="2" style="border:none" class="br-bottom br-right">अंकाचे नाव</th>
                    <th colspan="1" style="border:none" class="br-bottom br-right">Date</th>
                    <th colspan="1" style="border:none" class="br-bottom br-right">From  {{startDate}}</th>
                    <th colspan="1" style="border:none" class="br-bottom br-right">To {{endDate}}</th>
                    <th colspan="1" style="border:none" class="br-bottom br-right">All Advt List</th>
                    <th colspan="1" style="border:none" class="br-bottom ">Brief</th>
                </tr>
                <tr>
                    <th colspan="2" style="border:none"  class="br-bottom br-right">Representative Name</th>
                    <th colspan="2" style="border:none"  class="br-bottom br-right">Contact No</th>
                    <th colspan="1" style="border:none"  class="br-bottom br-right">Advt Amount </th>
                    <th colspan="1" style="border:none"  class="br-bottom br-right">Net Amount</th>
                    <th colspan="1" style="border:none"  class="br-bottom"> Unpaid Amount</th>
                </tr>
            </thead>
            <tbody *ngFor="let data of OutstandingB; let i = index">
                <tr>
                    <td colspan="2" style="font-weight: bold; font-size: 15px;">{{data.off_rep_name}}</td>
                    <td colspan="2" style="font-weight: bold; font-size: 15px;">{{data.office_rep_mob}}</td>
                    <td colspan="1"></td>
                    <td colspan="1"> </td>
                    <td colspan="1"> </td>
                </tr>
                <tr *ngFor="let data1 of data.rep_data; let i = index">
                    <td colspan="2" style="border:none" class="br-right">{{data1.rep_name}}</td>
                    <td colspan="2" style="border:none" class="br-right">{{data1.rep_mob}}</td>
                    <td colspan="1" style="border:none;text-align: end;" class="br-right">{{data1.contract_bkng_advt_amt}}</td>
                    <td colspan="1" style="border:none;text-align: end;" class="br-right">{{data1.contract_net_total}} </td>
                    <td colspan="1" style="border:none;text-align: end;" >{{data1.contract_bal_amt}} </td> 
                </tr>
                <tr>
                    <td colspan="2"  style="border:none" class="br-right">Total {{data.off_rep_name}}:</td>
                    <td colspan="2" style="border:none" class="br-right"></td>
                    <td colspan="1" style="border:none;text-align: end;" class="br-right" >{{data.adv_total}}</td>
                    <td colspan="1"  style="border:none;text-align: end;" class="br-right">{{data.net_total}}</td>
                    <td colspan="1" style="border:none;text-align: end;" >{{data.bal_total}}</td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <td colspan="2" style="font-size: 13px;font-weight: 500; border:none" class="br-bottom br-top br-right">Total:</td>
                    <td colspan="2" style="font-size: 13px;font-weight: 500; border:none" class="br-bottom br-top br-right"></td>
                    <td colspan="1" style="font-size: 13px;font-weight: 500; border:none;text-align: end;" class="br-bottom br-top br-right">Rs-{{ totalAdvtAmount }}</td>
                    <td colspan="1" style="font-size: 13px;font-weight: 500; border:none;text-align: end;" class="br-bottom br-top br-right">Rs-{{ totalNetAmount }}</td>
                    <td colspan="1" style="font-size: 13px;font-weight: 500; border:none;text-align: end;" class="br-bottom br-top ">Rs-{{ totalUnpaidAmount }}</td>
                </tr>
              
              </tfoot>  
        </table>
       
    </div>
    <div style="margin-left: 80%; margin-bottom: 2%;">
        <!-- <button class="noPrint" style="margin-top: 2%;" mat-raised-button color="primary"
            [printStyle]="{table : {'border-collapse':'collapse'}, td : {'border': 'solid 1px'}, th : {'border': 'solid 1px'}}"
            printSectionId="print-section2" ngxPrint>
            print
        </button> -->
        <button class="noPrint" style="margin-top: 2%; margin-left: 2%"
            (click)="exportExcel('Contract_InDetails_Report')" mat-raised-button color="primary">
            Export
        </button>

        <button class="noPrint" style="margin-top: 2%;" mat-raised-button color="primary"
        [useExistingCss]="true" 
            printSectionId="print-section"  [useExistingCss]="true" ngxPrint>
            print
        </button>
        
        <button class="noPrint" style="margin-top: 2%" mat-button mat-dialog-close>
            Close
        </button>
    </div>
</div>

<div *ngIf="reportType == 'unpaidOutstandingInDetails'">
    
    <div class="container" id="print-section">
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th colspan="3"  style="border:none" class="br-bottom br-right br-top">हिंदुस्तान प्रकाशन संस्था - विवेक साप्ताहिक</th>
                    <th colspan="2"  style="border:none" class="br-bottom br-right br-top">Advt. Outstanding Report</th>
                    <th colspan="2"  style="border:none" class="br-bottom br-right br-top">दिनांक</th>
                    <th colspan="2"  style="border:none" class="br-bottom br-top">पृष्ठ क्र</th>
                </tr>
                <tr>
                    <th colspan="3"style="border:none" class="br-bottom br-right">अंकाचे नाव</th>
                    <th colspan="1"style="border:none" class="br-bottom br-right">Date</th>
                    <th colspan="1"style="border:none" class="br-bottom br-right">From  {{startDate}} To {{endDate}}</th>
                    <th colspan="1"style="border:none" class="br-bottom br-right">To {{endDate}}</th>
                    <th colspan="2"style="border:none" class="br-bottom br-right">Outstanding List</th>
                    <th colspan="1"style="border:none" class="br-bottom">In Details</th>
                </tr>
                <tr>
                    <th colspan="1" style="border:none"  class="br-bottom br-right">Invoice No</th>
                    <th colspan="1" style="border:none"  class="br-bottom br-right">Invoice Date</th>
                    <th colspan="1" style="border:none"  class="br-bottom br-right">Advt Name </th>
                    <th colspan="1" style="border:none"  class="br-bottom br-right">Contact No</th>
                    <th colspan="1" style="border:none"  class="br-bottom br-right">Issue Name</th>
                    <th colspan="1" style="border:none"  class="br-bottom br-right">Issue Date</th>
                    <th colspan="1" style="border:none"  class="br-bottom br-right">Advt Amt</th>
                    <th colspan="1" style="border:none"  class="br-bottom br-right">Net Amount</th>
                    <th colspan="1" style="border:none"  class="br-bottom ">Unpaid Amount</th>
                </tr>   
            </thead>
            <!-- *ngFor="let data of OutstandingInDetailFormBeans;OutstandingInDetailFormBeans let i = index" -->
            <tbody *ngFor="let data of onlyOutstandingInDetailFormBeans; let i = index">
                <tr>
                    <td colspan="3"  style="border:none" class="br-right br-top br-bottom" >Office Reprensantive Name</td>
                    <td colspan="3"  style="border:none" class="br-right  br-top br-bottom">{{data.off_rep_name}} &nbsp; &nbsp; {{data.office_rep_mob}} </td>
                    <td colspan="3"  style="border:none" class="br-right  br-top br-bottom"></td>
                </tr>
               <ng-container *ngFor="let data1 of data.rep_data; let j = index">
                <tr>
                    <td colspan="3"  style="border:none" class="br-right br-bottom">Reprensantive Name</td>
                    <td colspan="3"  style="border:none" class="br-right br-bottom">{{data1.rep_name}} &nbsp; &nbsp; {{data1.rep_mob_no}}</td>
                    <td colspan="3"  style="border:none" class="br-right br-bottom"></td>
                </tr>
               
                <tr *ngFor="let data2 of data1.advt_data; let k = index">
                    <td colspan="1"  style="border:none;text-align: end;" class="br-right br-bottom">{{data2.advt_invoice_no}}</td>
                    <td colspan="1"  style="border:none;text-align: end;" class="br-right br-bottom">{{data2.advt_invoice_date}}</td>
                    <td colspan="2"  style="border:none;text-align: start;" class="br-right br-bottom">{{data2.advt_name | slice:0:20}}</td>
                    <td colspan="1"  style="border:none;text-align: end;" class="br-right br-bottom">{{data2.issue_name}}</td>                   
                    <td colspan="1"  style="border:none;text-align: end;" class="br-right br-bottom">{{data2.issue_date}}</td>
                    <td colspan="1"  style="border:none;text-align: end;" class="br-right br-bottom">{{data2.advt_amt}}</td>
                    <td colspan="1"  style="border:none;text-align: end;" class="br-right br-bottom">{{data2.net_amt}}</td>
                    <td colspan="1"  style="border:none;text-align: end;" class="br-right br-bottom">{{data2.unpaid_amt}}</td>
                    
                </tr>
                <tr>
                    <td colspan="6"  style="border:none;text-align: end;" class="br-right br-bottom">Total:</td>
                    <td colspan="1 "  style="border:none;text-align: end;" class="br-right  br-top br-bottom">{{data1.advt_amt_total}}</td>
                    <td colspan="1"  style="border:none;text-align: end;" class="br-right  br-top br-bottom">{{data1.net_amt_total}}</td>
                    <td colspan="1"   style="border:none;text-align: end;" class="br-right  br-top br-bottom">{{data1.unpaid_amt_total}}</td>
                </tr>
            </ng-container>
                 
            </tbody>

            <tfoot>
                <tr>
                    <td colspan="6" style="font-size: 13px;font-weight: 500; border:none;" class="br-bottom br-top br-right">Total:</td>
                    <td colspan="1" style="font-size: 13px;font-weight: 500; border:none;text-align: end;" class="br-bottom br-top br-right">Rs-{{ onlyOutStandingIDTotal }}</td>
                    <td colspan="1" style="font-size: 13px;font-weight: 500; border:none;text-align: end;" class="br-bottom br-top br-right">Rs-{{ onlyOutStandingIDNetAmtTotal }}</td>
                    <td colspan="1" style="font-size: 13px;font-weight: 500; border:none;text-align: end;" class="br-bottom br-top br-right">Rs-{{ onlyOutStandingIDUnPaidTotal }}</td>
                </tr>
              
              </tfoot>  
        </table>
       
    </div>

    <div style="margin-left: 80%; margin-bottom: 2%;">
        <!-- <button class="noPrint" style="margin-top: 2%;" mat-raised-button color="primary"
            [printStyle]="{table : {'border-collapse':'collapse'}, td : {'border': 'solid 1px'}, th : {'border': 'solid 1px'}}"
            printSectionId="print-section2" ngxPrint>
            print
        </button> -->
        <button class="noPrint" style="margin-top: 2%; margin-left: 2%"
            (click)="exportExcel('Contract_InDetails_Report')" mat-raised-button color="primary">
            Export
        </button>

        <button class="noPrint" style="margin-top: 2%;" mat-raised-button color="primary"
           [useExistingCss]="true"
            printSectionId="print-section" ngxPrint>
            print
        </button>
        
        <button class="noPrint" style="margin-top: 2%" mat-button mat-dialog-close>
            Close
        </button>
    </div>
</div>

<div *ngIf="reportType == 'unpaidOutstandingbriefly'">
    
    <div class="container" id="print-section">
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th colspan="3">हिंदुस्तान प्रकाशन संस्था - विवेक साप्ताहिक</th>
                    <th colspan="2">Advt. Outstanding Report</th>
                    <th colspan="1">दिनांक</th>
                    <th colspan="1">पृष्ठ क्र</th>
                </tr>
                <tr>
                    <th colspan="2">अंकाचे नाव</th>
                    <th colspan="1">Date</th>
                    <th colspan="1">From  {{startDate}}</th>
                    <th colspan="1">To {{endDate}}</th>
                    <th colspan="1">Outstanding List</th>
                    <th colspan="1">Brief</th>
                </tr>
                <tr>
                    <th colspan="2">Representative Name</th>
                    <th colspan="2">Contact No</th>
                    <th colspan="1">Advt Amount </th>
                    <th colspan="1">Net Amount</th>
                    <th colspan="1"> Unpaid Amount</th>
                </tr>
            </thead>
            <tbody *ngFor="let data of onlyOutstandingB; let i = index">
                <tr>
                    <td colspan="2" style="font-weight: bold;">{{data.off_rep_name}}</td>
                    <td colspan="2" style="font-weight: bold;">{{data.office_rep_mob}}</td>
                    <td colspan="1"></td>
                    <td colspan="1"> </td>
                    <td colspan="1"> </td>
                </tr>
                <tr *ngFor="let data1 of data.rep_data; let i = index">
                    <td colspan="2">{{data1.rep_name}}</td>
                    <td colspan="2">{{data1.rep_mob}}</td>
                    <td colspan="1" style="text-align: end;">{{data1.contract_bkng_advt_amt}}</td>
                    <td colspan="1" style="text-align: end;">{{data1.contract_net_total}} </td>
                    <td colspan="1" style="text-align: end;">{{data1.contract_bal_amt}} </td> 
                </tr>
                <tr>
                    <td colspan="2" style="font-weight: bold; font-size: 15px;">Total {{data.off_rep_name}}:</td>
                    <td colspan="2" style="font-weight: bold; font-size: 15px;"></td>
                    <td colspan="1" style="font-weight: bold; font-size: 15px; text-align: end;">{{data.adv_total}}</td>
                    <td colspan="1" style="font-weight: bold; font-size: 15px; text-align: end;">{{data.net_total}}</td>
                    <td colspan="1" style="font-weight: bold; font-size: 15px; text-align: end;">{{data.bal_total}}</td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <td colspan="2" style="font-weight: bold; font-size: 15px;">Total:</td>
                    <td colspan="2" style="font-weight: bold; font-size: 15px;"></td>
                    <td colspan="1" style="font-weight: bold; font-size: 15px; text-align: end;">Rs-{{ onlytotalAdvtAmount }}</td>
                    <td colspan="1" style="font-weight: bold; font-size: 15px; text-align: end;">Rs-{{ onlytotalNetAmount }}</td>
                    <td colspan="1" style="font-weight: bold; font-size: 15px; text-align: end;">Rs-{{ onlytotalUnpaidAmount }}</td>
                </tr>
              
              </tfoot>  
        </table>
       
    </div>
    <div style="margin-left: 80%; margin-bottom: 2%;">
        <!-- <button class="noPrint" style="margin-top: 2%;" mat-raised-button color="primary"
            [printStyle]="{table : {'border-collapse':'collapse'}, td : {'border': 'solid 1px'}, th : {'border': 'solid 1px'}}"
            printSectionId="print-section2" ngxPrint>
            print
        </button> -->
        <button class="noPrint" style="margin-top: 2%; margin-left: 2%"
            (click)="exportExcel('Contract_InDetails_Report')" mat-raised-button color="primary">
            Export
        </button>

        <button class="noPrint" style="margin-top: 2%;" mat-raised-button color="primary"
           [useExistingCss]="true"
            printSectionId="print-section" ngxPrint>
            print
        </button>
        
        <button class="noPrint" style="margin-top: 2%" mat-button mat-dialog-close>
            Close
        </button>
    </div>
</div>

<div *ngIf="reportType == 'creditdOutstandingindetails'">
    
    <!-- <div class="container" id="print-section">
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th colspan="3">हिंदुस्तान प्रकाशन संस्था - विवेक साप्ताहिक</th>
                    <th colspan="2">Advt. Outstanding Report</th>
                    <th colspan="2">दिनांक</th>
                    <th colspan="2">पृष्ठ क्र</th>
                </tr>
                <tr>
                    <th colspan="3">अंकाचे नाव</th>
                    <th colspan="1">Date</th>
                    <th colspan="1">From  {{startDate}} To {{endDate}}</th>
                    <th colspan="1">To {{endDate}}</th>
                    <th colspan="2">Outstanding List</th>
                    <th colspan="1">In Details</th>
                </tr>
                <tr>
                    <th colspan="1">Invoice No</th>
                    <th colspan="1">Invoice Date</th>
                    <th colspan="1">Advt Name </th>
                    <th colspan="1">Contact No</th>
                    <th colspan="1">Issue Name</th>
                    <th colspan="1">Issue Date</th>
                    <th colspan="1">Advt Amt</th>
                    <th colspan="1">Net Amount</th>
                    <th colspan="1">Unpaid Amount</th>
                </tr>   
            </thead>
            <tbody *ngFor="let data of creditoutstandingindetails; let i = index">
                <tr>
                    <td colspan="3" style="font-weight: bold; font-size: 15px;" >Office Reprensantive Name</td>
                    <td colspan="3" style="font-weight: bold; font-size: 15px;">{{data.off_rep_name}} &nbsp; &nbsp; {{data.office_rep_mob}} </td>
                    <td colspan="3" style="font-weight: bold; font-size: 15px;"></td>
                </tr>
               <ng-container *ngFor="let data1 of data.rep_data; let j = index">
                <tr>
                    <td colspan="3" style="font-weight: bold; font-size: 15px;">Reprensantive Name</td>
                    <td colspan="3" style="font-weight: bold; font-size: 15px;">{{data1.rep_name}} &nbsp; &nbsp; {{data1.rep_mob_no}}</td>
                    <td colspan="3" style="font-weight: bold; font-size: 15px;"></td>
                </tr>

                <tr *ngFor="let data2 of data1.advt_data; let k = index">
                    <td colspan="1">{{data2.advt_invoice_no}}</td>
                    <td colspan="1">{{data2.advt_invoice_date}}</td>
                    <td colspan="2">{{data2.advt_name}}</td>
                    <td colspan="1">{{data2.issue_name}}</td>                   
                    <td colspan="1">{{data2.issue_date}}</td>
                    <td colspan="1">{{data2.advt_amt}}</td>
                    <td colspan="1">{{data2.net_amt}}</td>
                    <td colspan="1">{{data2.unpaid_amt}}</td>
                </tr>
                <tr>
                    <td colspan="6" style="font-weight: bold;font-size: 16px;">Total:</td>
                    <td colspan="1 " style="font-weight: bold;font-size: 16px;">{{data1.advt_amt_total}}</td>
                    <td colspan="1" style="font-weight: bold;font-size: 16px;">{{data1.net_amt_total}}</td>
                    <td colspan="1" style="font-weight: bold; font-size: 16px;">{{data1.unpaid_amt_total}}</td>
                </tr>
            </ng-container> 
            </tbody>
            <tfoot>
                <tr>
                    <td colspan="6" style="font-weight: bold; font-size: 16px;">Total:</td>
                    <td colspan="1" style="font-weight: bold; font-size: 16px;">Rs-{{ creditonlyOutStandingIDTotal }}</td>
                    <td colspan="1" style="font-weight: bold; font-size: 16px;">Rs-{{ creditonlyOutStandingIDNetAmtTotal }}</td>
                    <td colspan="1" style="font-weight: bold; font-size: 16px;">Rs-{{ creditonlyOutStandingIDUnPaidTotal }}</td>
                </tr>
              
              </tfoot>  
        </table>
       
    </div> -->

    <div class="container" id="print-section">
        <table class="table">
            <thead>
                <tr>
                    <th class="br-bottom fs-15 br-right">दिनांक</th>
                   
                    <th colspan="4" class="br-bottom fs-15 br-right">हिंदुस्थान प्रकाशन संस्था</th>
                    <th colspan="4" class="br-bottom fs-15 ">जाहिरात रुपये येणे बाकी यादी</th>

                </tr>
                <tr>
                    <th class="br-bottom fs-15 br-right">पृष्ठ क्र</th>
                    
                    <th colspan="4" class="br-bottom fs-15 br-right">विवेक साप्ताहिक</th>
                    <th colspan="4" class="br-bottom fs-15 ">{{startDate}} ते {{endDate}}</th>

                </tr>
               
                <tr>
                    <th class="br-bottom br-right">देयक क्र</th>
                    <th class="br-bottom br-right">देयक दिनांक</th>
                    <th class="br-bottom br-right" colspan="2">जाहिरातदार</th>
                    <th class="br-bottom br-right">प्रकाशनाचे नाव</th>
                    <th class="br-bottom br-right">प्रकाशन दिनांक</th>
                    <th class="br-bottom br-right">एकूण रक्कम</th>
                    <th class="br-bottom br-right">निव्वळ रक्कम</th>
                    <th class="br-bottom ">येणे बाकी रक्कम</th>
                </tr>
            </thead>
            <tbody *ngFor="let data of creditoutstandingindetails; let i = index">
                <tr>
                    <td class="br-bottom br-right" colspan="2">कार्यालानियाय प्रतिनिधी</td>
                    <td class="br-bottom " colspan="7">{{data.off_rep_name}}   {{data.office_rep_mob}}</td>
                </tr>

                <ng-container *ngFor="let data1 of data.rep_data; let j = index">
                    <tr>
                        <td class="br-bottom br-right" colspan="2">प्रतिनिधी</td>
                        <td class="br-bottom " colspan="7">{{data1.rep_name}}   {{data1.rep_mob_no}}</td>
                    </tr>

                    <tr *ngFor="let data2 of data1.advt_data; let k = index">
                        <td class="br-bottom br-right" colspan="1">{{data2.advt_invoice_no}}</td>
                        <td class="br-bottom br-right" colspan="1">{{data2.advt_invoice_date}}</td>
                        <td class="br-bottom br-right" colspan="2">{{data2.advt_name}}</td>
                        <td class="br-bottom br-right" colspan="1">{{data2.issue_name}}</td>                   
                        <td class="br-bottom br-right" colspan="1">{{data2.issue_date}}</td>
                        <td class="br-bottom br-right " style="text-align: end;" colspan="1">{{data2.advt_amt}}</td>
                        <td class="br-bottom br-right " style="text-align: end;" colspan="1">{{data2.net_amt}}</td>
                        <td class="br-bottom  " style="text-align: end;" colspan="1">{{data2.unpaid_amt}}</td>
                    </tr>
                </ng-container>
            </tbody>
         </table>

    </div>
    <div style="margin-left: 80%; margin-bottom: 2%;">
        <!-- <button class="noPrint" style="margin-top: 2%;" mat-raised-button color="primary"
            [printStyle]="{table : {'border-collapse':'collapse'}, td : {'border': 'solid 1px'}, th : {'border': 'solid 1px'}}"
            printSectionId="print-section2" ngxPrint>
            print
        </button> -->
        <button class="noPrint" style="margin-top: 2%; margin-left: 2%"
            (click)="exportExcel('Contract_InDetails_Report')" mat-raised-button color="primary">
            Export
        </button>

        <button class="noPrint" style="margin-top: 2%;" mat-raised-button color="primary"
        [useExistingCss]="true" 
            printSectionId="print-section" ngxPrint>
            print
        </button>
        
        <button class="noPrint" style="margin-top: 2%" mat-button mat-dialog-close>
            Close
        </button>
    </div>
</div>

<div *ngIf="reportType == 'creditOutstandingbriefly'">
    
    <div class="container" id="print-section">
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th colspan="3" class="br-bottom fs-15 br-right">हिंदुस्तान प्रकाशन संस्था - विवेक साप्ताहिक</th>
                    <th colspan="2" class="br-bottom fs-15 br-right">Advt. Outstanding Report</th>
                    <th colspan="1" class="br-bottom fs-15 br-right">दिनांक</th>
                    <th colspan="1" class="br-bottom fs-15 ">पृष्ठ क्र</th>
                </tr>
                <tr>
                    <th colspan="2" class="br-bottom fs-15 br-right">अंकाचे नाव</th>
                    <th colspan="1" class="br-bottom fs-15 br-right">Date</th>
                    <th colspan="1" class="br-bottom fs-15 br-right">From  {{startDate}}</th>
                    <th colspan="1" class="br-bottom fs-15 br-right">To {{endDate}}</th>
                    <th colspan="1" class="br-bottom fs-15 br-right">Outstanding List</th>
                    <th colspan="1" class="br-bottom fs-15 ">Brief</th>
                </tr>
                <tr>
                    <th colspan="2" class="br-bottom  br-right">Representative Name</th>
                    <th colspan="2" class="br-bottom  br-right">Contact No</th>
                    <th colspan="1" class="br-bottom  br-right">Advt Amount </th>
                    <th colspan="1" class="br-bottom  br-right">Net Amount</th>
                    <th colspan="1" class="br-bottom "> Unpaid Amount</th>
                </tr>
            </thead>
            <tbody *ngFor="let data of creditoutstandongbrief; let i = index">
                <tr>
                    <td colspan="2" class="br-bottom br-right" style="font-weight: bold;">{{data.off_rep_name}}</td>
                    <td colspan="2" class="br-bottom br-right" style="font-weight: bold;">{{data.office_rep_mob}}</td>
                    <td colspan="1" class="br-bottom br-right"></td>
                    <td colspan="1" class="br-bottom br-right"> </td>
                    <td colspan="1" class="br-bottom "> </td>
                </tr>
                <tr *ngFor="let data1 of data.rep_data; let i = index">
                    <td colspan="2" class="br-bottom br-right">{{data1.rep_name}}</td>
                    <td colspan="2" class="br-bottom br-right">{{data1.rep_mob}}</td>
                    <td colspan="1" class="br-bottom br-right">{{data1.contract_bkng_advt_amt}}</td>
                    <td colspan="1" class="br-bottom br-right">{{data1.contract_net_total}} </td>
                    <td colspan="1" class="br-bottom ">{{data1.contract_bal_amt}} </td> 
                </tr>
                <tr>
                    <td colspan="2" class="br-bottom br-right" style="font-weight: bold; font-size: 15px;">Total {{data.off_rep_name}}:</td>
                    <td colspan="2" class="br-bottom br-right" style="font-weight: bold; font-size: 15px;"></td>
                    <td colspan="1" class="br-bottom br-right" style="font-weight: bold; font-size: 15px;">{{data.adv_total}}</td>
                    <td colspan="1" class="br-bottom br-right" style="font-weight: bold; font-size: 15px;">{{data.net_total}}</td>
                    <td colspan="1" class="br-bottom " style="font-weight: bold; font-size: 15px;">{{data.bal_total}}</td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <td colspan="2" class="br-bottom br-right" style="font-weight: bold; font-size: 15px;">Total:</td>
                    <td colspan="2" class="br-bottom br-right" style="font-weight: bold; font-size: 15px;"></td>
                    <td colspan="1" class="br-bottom br-right" style="font-weight: bold; font-size: 15px;">Rs-{{ creditlastonlytotalAdvtAmount }}</td>
                    <td colspan="1" class="br-bottom br-right" style="font-weight: bold; font-size: 15px;">Rs-{{ creditlastonlytotalNetAmount }}</td>
                    <td colspan="1" class="br-bottom " style="font-weight: bold; font-size: 15px;">Rs-{{ creditlastonlytotalUnpaidAmount }}</td>
                </tr>
              
              </tfoot>  
        </table>
       
    </div>
    <div style="margin-left: 80%; margin-bottom: 2%;">
        <!-- <button class="noPrint" style="margin-top: 2%;" mat-raised-button color="primary"
            [printStyle]="{table : {'border-collapse':'collapse'}, td : {'border': 'solid 1px'}, th : {'border': 'solid 1px'}}"
            printSectionId="print-section2" ngxPrint>
            print
        </button> -->
        <button class="noPrint" style="margin-top: 2%; margin-left: 2%"
            (click)="exportExcel('Contract_InDetails_Report')" mat-raised-button color="primary">
            Export
        </button>

        <button class="noPrint" style="margin-top: 2%;" mat-raised-button color="primary"
           [useExistingCss]="true"
            printSectionId="print-section" ngxPrint>
            print
        </button>
        
        <button class="noPrint" style="margin-top: 2%" mat-button mat-dialog-close>
            Close
        </button>
    </div>
</div>


<!-- OutStanding GST Register  -->
<div *ngIf="reportType == 'GSTFullReport'">
    
    <div class="container-fluid" id="print-section" style="margin-top:50px; margin-right: 10px;overflow: auto;">
        <table class="table table-bordered" >
            <thead>
                <tr>
                    <th colspan="4" style="border:none" class="br-bottom br-right br-top">Hindustan - Vivek Saptahik</th>
                    <th colspan="9" style="border:none" class="br-bottom br-right br-top">Advt. GST Register</th>
                    <th colspan="3" style="border:none" class="br-bottom br-right br-top">Date :  From {{startDate}} To {{endDate}}</th>
                    <th colspan="2" style="border:none" class="br-bottom br-right br-top">Date : </th>
                    <th colspan="1" style="border:none" class="br-bottom br-top">Page No : </th>
                </tr>
                
                <tr>
                    <th colspan="1" style="border:none" class="br-bottom br-right">Invoice Date</th>
                    <th colspan="1" style="border:none" class="br-bottom br-right">Invoice No</th>
                    <th colspan="1" style="border:none" class="br-bottom br-right">Advt Name </th>
                    <th colspan="1" style="border:none" class="br-bottom br-right">Advt English Name </th>
                    <th colspan="1" style="border:none" class="br-bottom br-right">District</th>
                    <th colspan="1" style="border:none" class="br-bottom br-right">GST No</th>
                    <th colspan="1" style="border:none" class="br-bottom br-right">HSN No</th>
                    <th colspan="1" style="border:none" class="br-bottom br-right">Advt Size</th>
                    <th colspan="1" style="border:none" class="br-bottom br-right">Advt Amount</th>
                    <th colspan="1" style="border:none" class="br-bottom br-right">Discount</th>
                    <th colspan="1" style="border:none" class="br-bottom br-right">Advt Net Amt</th>
                    <th colspan="1" style="border:none" class="br-bottom br-right">CGST Rate</th>
                    <th colspan="1" style="border:none" class="br-bottom br-right">CGST Amt</th>
                    <th colspan="1" style="border:none" class="br-bottom br-right">SGST Rate</th>
                    <th colspan="1" style="border:none" class="br-bottom br-right">SGST Amt</th>
                    <th colspan="1" style="border:none" class="br-bottom br-right">IGST Rate</th>
                    <th colspan="1" style="border:none" class="br-bottom br-right">IGST Amount</th>
                    <th colspan="1" style="border:none" class="br-bottom br-right">Roundup Amt</th>
                    <th colspan="1" style="border:none" class="br-bottom">GST Amt</th>
                </tr>   
            </thead>
            <!-- *ngFor="let data of OutstandingInDetailFormBeans; let i = index" -->
            <tbody *ngFor="let data of GSTfullreportFormBeans; let i = index">
            
                <tr>
                    <td colspan="1" style="border:none"  class="br-right">{{data.invoice_date}}</td>
                    <td colspan="1" style="border:none"class="br-right">{{data.invoice_no}}</td>
                    <td colspan="1" style="border:none"class="br-right">{{data.advt_name}} </td>
                    <td colspan="1" style="border:none"class="br-right">{{data.eng_advt_name}} </td>
                    <td colspan="1" style="border:none"class="br-right">{{data.eng_district}}</td>
                    <td colspan="1" style="border:none"class="br-right">{{data.gst_no}}</td>
                    <td colspan="1" style="border:none"class="br-right">998363</td>
                    <td colspan="1" style="border:none"class="br-right">{{data.advt_size}}</td>
                    <td colspan="1" style="border:none;text-align: end;"class="br-right">{{data.adv_amt}}</td>
                    <td colspan="1" style="border:none;text-align: end;"class="br-right">{{data.advt_discount}}</td>
                    <td colspan="1" style="border:none;text-align: end;"class="br-right">{{data.net_amt}}</td>
                    <td colspan="1" style="border:none;text-align: end;"class="br-right">2.5%</td>
                    <td colspan="1" style="border:none;text-align: end;"class="br-right">{{data.cgst}}</td>
                    <td colspan="1" style="border:none;text-align: end;"class="br-right">2.5%</td>
                    <td colspan="1" style="border:none;text-align: end;"class="br-right">{{data.sgst}}</td>
                    <td colspan="1" style="border:none;text-align: end;"class="br-right">5%</td>
                    <td colspan="1" style="border:none;text-align: end;"class="br-right">{{data.igst}}</td>
                    <td colspan="1" style="border:none;text-align: end;"class="br-right">{{data.round_up_amt}}</td>
                    <td colspan="1" style="border:none;text-align: end;"class="">{{data.gst_amount}}</td>
                </tr>  
                 
            </tbody>

            <tfoot>
                <tr>
                    <td colspan="9" style="border:none; text-align: end;" class="br-bottom br-top br-right text-end">Total- {{GSTAdvtAmt}}</td>
                    <!-- <td colspan="1"></td> -->
                    <td colspan="1" style="border:none; text-align: end;" class="br-bottom br-top br-right">{{GSTDiscount}}</td>
                    <td colspan="1" style="border:none; text-align: end;" class="br-bottom br-top br-right">{{GSTNetAdvtAmt}}</td>
                    <td colspan="1" style="border:none; text-align: end;" class="br-bottom br-top br-right"></td>
                    <td colspan="1" style="border:none; text-align: end;"class="br-bottom br-top br-right">{{CGSTAmount}}</td>
                    <td colspan="1" style="border:none; text-align: end;" class="br-bottom br-top br-right"></td>
                    <td colspan="1" style="border:none; text-align: end;" class="br-bottom br-top br-right">{{SGSTAmount}}</td>
                    <td colspan="1" style="border:none; text-align: end;" class="br-bottom br-top br-right"></td>
                    <td colspan="1" style="border:none; text-align: end;" class="br-bottom br-top br-right">0</td>
                    <td colspan="1" style="border:none; text-align: end;" class="br-bottom br-top br-right">0</td>
                    <td colspan="1" style="border:none; text-align: end;" class="br-bottom br-top">{{AllGSTAmount}}</td>
                </tr>
              
              </tfoot>  
        </table>
       
    </div>

    <div style="margin-left: 80%; margin-bottom: 2%; margin-top: 5%;">
        <!-- <button class="noPrint" style="margin-top: 2%;" mat-raised-button color="primary"
            [printStyle]="{table : {'border-collapse':'collapse'}, td : {'border': 'solid 1px'}, th : {'border': 'solid 1px'}}"
            printSectionId="print-section2" ngxPrint>
            print
        </button> -->
        <button class="noPrint" style="margin-top: 2%; margin-left: 2%"
            (click)="exportExcel('Contract_InDetails_Report')" mat-raised-button color="primary">
            Export
        </button>

        <button class="noPrint" style="margin-top: 2%;" mat-raised-button color="primary" [useExistingCss]="true"
            printSectionId="print-section" ngxPrint>
            print
        </button>
        
        <button class="noPrint" style="margin-top: 2%" mat-button mat-dialog-close>
            Close
        </button>
    </div>
</div>

<!-- Commission Report -->
<div *ngIf="reportType == 'PendingCommReport'">
    
    <div class="container" id="print-section">
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th colspan="7">हिंदुस्तान प्रकाशन संस्था - विवेक साप्ताहिक</th>
                    <!-- <th colspan="2">Advt. Outstanding Report</th> -->
                    <th colspan="2">दिनांक</th>
                    <th colspan="2">पृष्ठ क्र</th>
                </tr>
                <tr>
                    <th colspan="6">अंकाचे नाव</th>
                    <th colspan="1">Date</th>
                    <th colspan="2">From  {{startDate}}</th>
                    <th colspan="2">To {{endDate}}</th>
                    <!-- <th colspan="2">All Advt List</th>
                    <th colspan="1">In Details</th> -->
                </tr>
                <tr>
                    <th colspan="1">Invoice No</th>
                    <th colspan="1">Invoice Date</th>
                    <th colspan="1">Advt Name </th>
                    <!-- <th colspan="1">Contact No</th> -->
                    <th colspan="1">Issue Name</th>
                    <th colspan="1">Issue Date</th>
                    <th colspan="1">Net Amt</th>
                    <th colspan="1">Pending Amount</th>
                    <th colspan="1">Received Amount</th>
                    <th colspan="1">Paid Commission Amount</th>
                    <th colspan="1">Selected For Commission</th>
                    <th colspan="1">Selected Amount</th>
                </tr>   
            </thead>
            <!-- *ngFor="let data of OutstandingInDetailFormBeans; let i = index" -->
            <tbody *ngFor="let data of getpaiddata; let i = index">
                <tr>
                    <td colspan="6" style="font-weight: bold; font-size: 16px;" >Office Reprensantive Name</td>
                    <td colspan="5" style="font-weight: bold; font-size: 16px;">{{data.off_rep_name}} </td>
                    <!-- <td colspan="3" style="font-weight: bold; font-size: 16px;">{{data.office_rep_mob}}</td> -->
                </tr>
               <ng-container *ngFor="let data1 of data.rep_data; let j = index">
                <!-- <tr>
                    <td colspan="3" style="font-weight: bold; font-size: 16px;">Reprensantive Name</td>
                    <td colspan="3" style="font-weight: bold; font-size: 16px;">{{data1.rep_name}}</td>
                    <td colspan="3" style="font-weight: bold; font-size: 16px;">{{data1.rep_mob_no}}</td>
                </tr> -->
               
                <tr *ngFor="let data2 of data1.advt_data; let k = index">
                    <td colspan="1">{{data2.advt_invoice_no}}</td>
                    <td colspan="1">{{data2.advt_invoice_date}}</td>
                    <td colspan="1">{{data2.advt_name}}</td>
                    <td colspan="1">{{data2.issue_name}}</td>                   
                    <td colspan="1">{{data2.issue_date}}</td>
                    <td colspan="1">{{data2.advt_amt}}</td>
                    <td colspan="1">{{data2.net_amt}}</td>
                    <td colspan="1">{{data2.unpaid_amt}}</td>
                    <td colspan="1">{{data2.advt_amt}}</td>
                    <td colspan="1">{{data2.net_amt}}</td>
                    <td colspan="1">{{data2.unpaid_amt}}</td>
                    
                </tr>
                <tr>
                    <td colspan="5" style="font-weight: bold;font-size: 16px;">Total:</td>
                    <td colspan="1 " style="font-weight: bold;font-size: 16px;">{{data1.netAmt}}</td>
                    <td colspan="1" style="font-weight: bold;font-size: 16px;">{{data1.pending_amt}}</td>
                    <td colspan="1" style="font-weight: bold; font-size: 16px;">{{data1.received_amt}}</td>
                    <td colspan="1" style="font-weight: bold; font-size: 16px;"></td>
                    <td colspan="1" style="font-weight: bold; font-size: 16px;"></td>
                    <td colspan="1" style="font-weight: bold; font-size: 16px;">{{data1.selected_amt}}</td>

                </tr>
            </ng-container>
                 
            </tbody>

            <tfoot>
                <!-- <tr>
                    <td colspan="6" style="font-weight: bold; font-size: 16px;">Total:</td>
                    <td colspan="1" style="font-weight: bold; font-size: 16px;">Rs-{{  }}</td>
                    <td colspan="1" style="font-weight: bold; font-size: 16px;">Rs-{{  }}</td>
                    <td colspan="1" style="font-weight: bold; font-size: 16px;">Rs-{{ j kk}}</td>
                </tr> -->
              
              </tfoot>  
        </table>
       
    </div>

    <div style="margin-left: 80%; margin-bottom: 2%;">
        <!-- <button class="noPrint" style="margin-top: 2%;" mat-raised-button color="primary"
            [printStyle]="{table : {'border-collapse':'collapse'}, td : {'border': 'solid 1px'}, th : {'border': 'solid 1px'}}"
            printSectionId="print-section2" ngxPrint>
            print
        </button> -->
        <button class="noPrint" style="margin-top: 2%; margin-left: 2%"
            (click)="exportExcel('Contract_InDetails_Report')" mat-raised-button color="primary">
            Export
        </button>

        <button class="noPrint" style="margin-top: 2%;" mat-raised-button color="primary"
           [useExistingCss]="true"
            printSectionId="print-section" ngxPrint>
            print
        </button>
        
        <button class="noPrint" style="margin-top: 2%" mat-button mat-dialog-close>
            Close
        </button>
    </div>
</div>

<div *ngIf="reportType == 'CompletedCommReport'">
    
    <div class="container" id="print-section">
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th colspan="7">हिंदुस्तान प्रकाशन संस्था - विवेक साप्ताहिक</th>
                    <!-- <th colspan="2">Advt. Outstanding Report</th> -->
                    <th colspan="2">दिनांक</th>
                    <th colspan="2">पृष्ठ क्र</th>
                </tr>
                <tr>
                    <th colspan="6">अंकाचे नाव</th>
                    <th colspan="1">Date</th>
                    <th colspan="2">From  {{startDate}}</th>
                    <th colspan="2">To {{endDate}}</th>
                    <!-- <th colspan="2">All Advt List</th>
                    <th colspan="1">In Details</th> -->
                </tr>
                <tr>
                    <th colspan="1">Invoice No</th>
                    <th colspan="1">Invoice Date</th>
                    <th colspan="2">Advt Name </th>
                    <!-- <th colspan="1">Contact No</th> -->
                    <th colspan="1">Issue Name</th>
                    <th colspan="1">Issue Date</th>
                    <th colspan="1">Net Amt</th>
                    <!-- <th colspan="1">Pending Amount</th> -->
                    <th colspan="1">Received Amount</th>
                    <!-- <th colspan="1">Paid Commission Amount</th> -->
                    <th colspan="1">Selected For Commission</th>
                    <th colspan="2">Paid Commission Date</th>
                </tr>   
            </thead>
            <!-- *ngFor="let data of OutstandingInDetailFormBeans; let i = index" -->
            <tbody *ngFor="let data of getpaiddata; let i = index">
                <tr>
                    <td colspan="6" style="font-weight: bold; font-size: 16px;" >Office Reprensantive Name</td>
                    <td colspan="5" style="font-weight: bold; font-size: 16px; text-align: start !important;">{{data.rep_name}} </td>
                    <!-- <td colspan="3" style="font-weight: bold; font-size: 16px;">{{data.office_rep_mob}}</td> -->
                </tr>
               
                <tr *ngFor="let data1 of data.rep_data; let k = index">
                    <td colspan="1">{{data1.advt_invoice_no}}</td>
                    <td colspan="1">{{data1.advt_invoice_date}}</td>
                    <td colspan="2">{{data1.advt_name}}</td>
                    <td colspan="1">{{data1.DS_ISSUENAME}}</td>                   
                    <td colspan="1">{{data1.DS_ISSUEDATE}}</td>
                    <!-- <td colspan="2">{{data1.advt_amt}}</td> -->
                    <td colspan="1">{{data1.net_amount}}</td>
                    <!-- <td colspan="1">{{data2.unpaid_amt}}</td> -->
                    <td colspan="1">{{data1.rcv_amt}}</td>
                    <td colspan="1">{{data1.rcv_amt}}</td>
                    <td colspan="1">{{data1.com_paid_date}}</td>
                    
                </tr>
               
                 
            </tbody>

            <tfoot>
                <tr>
                    <td colspan="7" style="font-weight: bold; font-size: 16px;">Total:</td>
                    <td colspan="2" style="font-weight: bold; font-size: 16px;">Rs-{{ paidcommTotal }}</td>
                    <td colspan="2" style="font-weight: bold; font-size: 16px;"></td>
                </tr>
              
              </tfoot>  
        </table>
       
    </div>

    <div style="margin-left: 80%; margin-bottom: 2%;">
       
        <button class="noPrint" style="margin-top: 2%; margin-left: 2%"
            (click)="exportExcel('Contract_InDetails_Report')" mat-raised-button color="primary">
            Export
        </button>

        <button class="noPrint" style="margin-top: 2%;" mat-raised-button color="primary"
           [useExistingCss]="true"
            printSectionId="print-section" ngxPrint>
            print
        </button>
        
        <button class="noPrint" style="margin-top: 2%" mat-button mat-dialog-close>
            Close
        </button>
    </div>
</div>

<!-- Personal Ledger -->
<div *ngIf="reportType == 'InDetail-personalledger'">
    
    <div class="container" id="print-section" style="margin-top: 50px;">
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th colspan="3" style="border:none" class="br-bottom br-right">हिंदुस्तान प्रकाशन संस्था - विवेक साप्ताहिक</th>
                    <th colspan="4" style="border:none" class="br-bottom br-right">Advertieser Personal Ledger - In Details</th>
                    <!-- <th colspan="1">In Details</th>  -->
                    <!-- <th colspan="2">Advt. Outstanding Report</th> -->
                   
                    <th colspan="1" style="border:none" class="br-bottom br-right"> Date : From {{ startDate | date: 'dd-MM-yyyy' }} - To {{ endDate | date: 'dd-MM-yyyy' }}</th>                    <th colspan="2" style="border:none" class="br-bottom br-right">दिनांक</th>
                    <th colspan="1" style="border:none" class="br-bottom ">पृष्ठ क्र</th>
                    
                </tr>
              
                <tr>
                    <th colspan="1" style="border:none" class="br-bottom br-right">Invoice No</th>
                    <th colspan="1" style="border:none" class="br-bottom br-right">Invoice Date</th>
                    <th colspan="1" style="border:none" class="br-bottom br-right">Issue Name </th>
                    <th colspan="1" style="border:none" class="br-bottom br-right">Invoice Amount</th>
                    <th colspan="1" style="border:none" class="br-bottom br-right">Receipt No</th>
                    <th colspan="1" style="border:none" class="br-bottom br-right">Receipt Date</th>
                    <th colspan="1" style="border:none" class="br-bottom br-right">Credit Vocher</th>
                    <th colspan="1" style="border:none" class="br-bottom br-right">Bank Details</th>
                    <th colspan="1" style="border:none"  class="br-bottom br-right">Invoice Number</th>
                    <th colspan="1" style="border:none" class="br-bottom br-right">Received Amount</th>
                    <th colspan="1" style="border:none" class="br-bottom">Balance</th>
                </tr>   
            </thead>
            <!-- *ngFor="let data of OutstandingInDetailFormBeans; let i = index" -->
            <tbody *ngFor="let data of getpersonalleddata; let i = index">
                <tr>
                  
                    <td colspan="11" style="font-size: 13px;border: none;text-align: left;" >Advertiser Name :  <span>{{data.advt_name}}</span></td>
                    
                </tr>
                <tr>
                    <td colspan="3" style="border:none" class="br-bottom br-right br-top">Opening Balance</td>
                    <td colspan="1" style="border:none" class="br-bottom br-right br-top">{{data.opening_bal}}</td>
                    <td colspan="7" style="border:none" class="br-bottom br-top">{{data.opening_bal}} येणे</td>
                </tr>
               
                <tr *ngFor="let data1 of data.rep_data; let k = index">
                    <td colspan="1" style="border:none" class=" br-right">{{data1.invoice_no}}</td>
                    <td colspan="1" style="border:none" class=" br-right">{{data1.invoice_date}}</td>
                    <td colspan="1" style="border:none" class=" br-right">{{data1.issue_name}}</td>
                    <td colspan="1" style="border:none" class="br-right">{{data1.net_amt}}</td>                   
                    <td colspan="1" style="border:none" class=" br-right">{{data1.rcpt_no}}</td>
                    <!-- <td colspan="2">{{data1.advt_amt}}</td> -->
                    <td colspan="1" style="border:none" class=" br-right">{{data1.rcpt_date}}</td>
                    <!-- <td colspan="1">{{data2.unpaid_amt}}</td> -->
                    <td colspan="1" style="border:none" class=" br-right">{{data1.credit_voc}}</td>
                    <td colspan="1" style="border:none" class=" br-right">Cheque No : {{data1.cheque_no}} , Pay. Mode : {{data1.pymt_options}}</td>
                    <td colspan="1" style="border:none" class=" br-right" >{{data1.invoice_no}}</td>
                    <td colspan="1" style="border:none" class="br-right">{{data1.rcv_amt}}</td>
                    <td colspan="1" style="border:none" class="br-bottom">{{data1.bal_amt}} येणे</td>
                </tr>

                <tr >
                    <td colspan="3" style="border:none" class="br-bottom br-right br-top">Total</td>
                    <td colspan="1" style="border:none" class="br-bottom br-right br-top">{{data.invoice_total}}</td>
                    <td colspan="5" style="border:none" class="br-bottom br-right br-top"></td>
                    <td colspan="1" style="border:none" class="br-bottom br-top br-right">{{data.rcv_total}}</td>
                    <td colspan="1" style="border:none" class="br-bottom"></td>
                   </tr>
                   <tr>
                    <td colspan="3" style="border:none" class="br-bottom br-right">Balance C/F</td>
                    <td colspan="1" style="border:none" class="br-bottom br-right">{{data.invoice_total - data.rcv_total}}</td>
                    <td colspan="1" style="border:none" class="br-bottom br-right">येणे</td>
                    <td colspan="6" style="border:none" class="br-bottom"></td>
    
                   </tr>
                  
                 
            </tbody>

            <tfoot>
              
              
              </tfoot>  
        </table>
       
    </div>

    <div style="margin-left: 80%; margin-bottom: 2%;">
        <!-- <button class="noPrint" style="margin-top: 2%;" mat-raised-button color="primary"
            [printStyle]="{table : {'border-collapse':'collapse'}, td : {'border': 'solid 1px'}, th : {'border': 'solid 1px'}}"
            printSectionId="print-section2" ngxPrint>
            print
        </button> -->
        <button class="noPrint" style="margin-top: 2%; margin-left: 2%"
            (click)="exportExcel('Contract_InDetails_Report')" mat-raised-button color="primary">
            Export
        </button>

        <button class="noPrint" style="margin-top: 2%;" mat-raised-button color="primary"
           [useExistingCss]="true"
            printSectionId="print-section" ngxPrint>
            print
        </button>
        
        <button class="noPrint" style="margin-top: 2%" mat-button mat-dialog-close>
            Close
        </button>
    </div>
</div>

<div *ngIf="reportType == 'briefly-personalledger'">
    
    <div class="container" id="print-section">
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th colspan="6" class="text-center" style="font-size: 16px;">हिंदुस्तान प्रकाशन संस्था - विवेक साप्ताहिक</th>
                    <!-- <th colspan="2">Advt. Outstanding Report</th> -->
                    <th colspan="1" class="text-center" style="font-size: 16px;">दिनांक</th>
                    <th colspan="1" class="text-center" style="font-size: 16px;">पृष्ठ क्र</th>
                </tr>
                <tr>
                    <th colspan="4">Advertieser Personal Ledger</th>
                    <th colspan="1">Date</th>
                    <th colspan="1">From  {{startDate}}</th>
                    <th colspan="1">To {{endDate}}</th>
                    <th colspan="1">In Details</th> 
                </tr>
                <tr>
                    <th colspan="1">Advertieser Name</th>
                    <th colspan="1">Opening Balance</th>
                    <th colspan="1">Received Amount</th>
                    <th colspan="1">Debit</th>
                    <th colspan="1">Credit</th>
                    <th colspan="1">Balance</th>
                    <th colspan="2">Receipt Date</th>
                </tr>   
            </thead>
            <!-- *ngFor="let data of OutstandingInDetailFormBeans; let i = index" -->
            <tbody>
                <!-- <tr>
                    <td colspan="6" style="font-weight: bold; font-size: 16px;" >Advertieser Name</td>
                    <td colspan="5" style="font-weight: bold; font-size: 16px; text-align: start !important;">{{data.advt_name}} </td>
                    <td colspan="3" style="font-weight: bold; font-size: 16px;">{{data.office_rep_mob}}</td>
                </tr> -->
                <!-- <tr>
                    <td colspan="3">Opening Balance</td>
                    <td colspan="1">00</td>
                    <td colspan="8">00 येणे</td>
                </tr> -->
               
                <tr *ngFor="let data1 of getpersonalledBridflydata; let k = index">
                    <td colspan="1">{{data1.advt_name}}</td>
                    <td colspan="1">{{data1.opening_bal}}</td>
                    <td colspan="1">{{data1.net_amt}}</td>
                    <td colspan="1">{{data1.rcv_amt}}</td>
                    <td colspan="1">{{data1.credit_amt}}</td>                   
                    <td colspan="1">{{data1.totalbalance}}</td>
                    <td colspan="1">येणे</td>
                    <td colspan="1">येणे</td>

                    
                </tr>
                 
            </tbody>

            <tfoot>
               <tr>
                <td colspan="1">Total</td>
                <td colspan="1">{{Totalop}}</td>
                <td colspan="1">{{this.TotalDebit}}</td>
                <td colspan="1">{{TotalCredit}}</td>
                <td colspan="1">{{Totalbal}}</td>
                <td colspan="2"></td>

               </tr>
              
              </tfoot>  
        </table>
       
    </div>

    <div style="margin-left: 80%; margin-bottom: 2%;">
        <!-- <button class="noPrint" style="margin-top: 2%;" mat-raised-button color="primary"
            [printStyle]="{table : {'border-collapse':'collapse'}, td : {'border': 'solid 1px'}, th : {'border': 'solid 1px'}}"
            printSectionId="print-section2" ngxPrint>
            print
        </button> -->
        <button class="noPrint" style="margin-top: 2%; margin-left: 2%"
            (click)="exportExcel('Contract_InDetails_Report')" mat-raised-button color="primary">
            Export
        </button>

        <button class="noPrint" style="margin-top: 2%;" mat-raised-button color="primary"
           [useExistingCss]="true"
            printSectionId="print-section" ngxPrint>
            print
        </button>
        
        <button class="noPrint" style="margin-top: 2%" mat-button mat-dialog-close>
            Close
        </button>
    </div>
</div>


<!-- Send reminder -->

<div *ngIf="reportType == 'send-reminder1'">
    <div class="container" style="border: 2px solid black">
        <div class="row">
            <div class="col-2 image">
                <img style="margin-top: 6%" width="85%" src="../../../assets/img/brand/red.png" />
            </div>
            <div class="col-8" style="padding-top: 30px; text-align: center; padding-bottom: 10px">
                <span style="font-size: 35px; font-weight: bold">हिंदुस्थान प्रकाशन संस्था</span>
                <span style="font-size: 14px; margin-left: 05px">(Reg.No.-BOM-3/1961(GBDSD))</span>
                <hr />
                <div class="row" style="text-align: left">
                    <div class="col-6" style="padding-top: 10px">
                        <span style="font-size: 14px"><span style="font-weight: bold; font-size: 16px">प्रशासकीय
                                कार्यालय :</span>
                            ३०३, वडाळा उद्योग भवन, नाईगाव क्रॉस रोड, वडाळा, मुंबई - ४०००३१<br /></span>
                        <span style="font-size: 14px"><span style="font-weight: bold; font-size: 16px">दूरध्वनी:</span>
                            9967570531
                            <!-- <span style="font-weight: bold; font-size: 16px">फॅक्स:</span>
                            27810237 -->
                        </span>
                    </div>
                    <div class="col-6" style="
              border-style: solid;
              border-width: 0px 0px 0px 2px;
              padding-top: 10px;
            ">
                        <span style="font-size: 14px"><span style="font-weight: bold; font-size: 16px">मुख्य कार्यालय :
                            </span>
                            5/12, कामत औद्योगिक वसाहत,<br />
                            396 स्वा. वीर सावरकर मार्ग, प्रभादेवी, मुंबई 4000 25.<br /></span>
                        <span style="font-size: 14px"><span style="font-weight: bold; font-size: 16px">दूरध्वनी :
                            </span>
                            24221440
                            <!-- <span style="font-weight: bold; font-size: 16px">फॅक्स : </span>
                            24363756 -->
                        </span>
                    </div>
                </div>
            </div>
            <div class="col-2" style="
          border-style: solid;
          border-width: 0px 0px 0px 2px;
          /* padding-top: 40px; */
          text-align: center;
          display: flex;
          align-items: center;
        ">
                <span style="font-size: 20px"> ग्राहक स्मरणपत्र</span>
            </div>
        </div>
        <div class="row" style="border-style: solid;border-width: 2px 0px 0px 0px;text-align: center;">
            <div class="col-6" style="padding-top: 20px; padding-bottom: 20px; text-align: left">
                <span style="font-weight: bold; font-size: 16px">प्रति
                </span><br />
                <span style="font-weight: bold; font-size: 16px">Address :&nbsp;</span><span style="font-size: 14px"></span><br />
                <span style="font-weight: bold; font-size: 16px">Address 2nd line :&nbsp;</span><span
                    style="font-size: 14px"></span>
            </div>
            <div class="col-6" style="border-style: solid; border-width: 0px 0px 0px 2px; text-align: end;padding-top: 20px;">
                <!-- <div class="row" style="border-style: solid;border-width: 0px 0px 2px 0px;text-align: center;padding-top: 20px;padding-bottom: 10px;"> -->
                    <!-- <div class="col-6" style="text-align: left">
                        <span style="font-weight: bold; font-size: 16px">पावती क्रं.: </span><span>{{ this.receiptno
                            }}</span>
                    </div> -->
                    <div class="col-6" style="text-align: left">
                        <span style="font-weight: bold; font-size: 16px">दिनांक:&nbsp;</span><span
                            style="font-size: 14px">27/02/2024</span>
                    </div>
                <!-- </div> -->
                
            </div>
        </div>
        <div class="row" style="
        border-style: solid;
        border-width: 2px 0px 0px 0px;
        text-align: center;
        padding-top: 20px;
      ">
            <div class="col-12" style="text-align: left">
                <span>प्रिय नमस्कार</span>
                <p>ही पत्रेरी आपल्याला हवी असलेली बक्षीश रक्कम संपूर्ण करण्यासाठी आपल्याला नम्र विनंती करत आहे. आपल्या खात्यातील [रक्कम] रुपये एका किंवा अधिक कारणे आपल्या आवकाशी संपूर्ण ठरवण्यात आली पाहिजे.

                    आपल्या आवकाशी संपूर्ण करण्यात हमारी मदत करण्यात आपलं आत्मविश्वास होईल. कृपया, आपली बक्षीश रक्कम तात्काळ संपूर्ण करा आणि त्याची पुस्तक नमुना किंवा नकल आपल्या खात्यात पाठवा.
                    
                    यदि आप या तात्काळीन परिस्थितीत असता, तरी कृपया हमाक त्वरित सूचित करा आणि योग्य कारणे सांगा.
                    
                    आपल्याचं विशेषज्ञता आणि आपल्या सहकार्यानुसार हमाक आपल्या उत्तरात आपली सहाय्य करण्याची आशा आहे</p>
                <!-- <span style="font-size: 14px" *ngFor="let book of advtInvoicePricePerIssue">{{ book["invoice_no"] }},
                    &nbsp;</span> -->
                    <div>
                        <table style="width: 100% !important;">
                            <thead>
                                <tr style="text-align: center !important;">
                                    <th style="height: 40px;">बील क्रमांक</th>
                                    <th style="height: 40px;">बील तारीख</th>
                                    <th style="height: 40px;">प्रकाशनचे नाव</th>
                                    <th style="height: 40px;">एकूण रक्कम</th>
                                    <th style="height: 40px;">येणे बाकी</th>
                                    <th style="height: 40px;">ग्राहक</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style="text-align: center !important;" *ngFor="let data1 of getpendingreceipt; let k = index">
                                    <td style="height: 40px;text-align: center!important;">{{data1.invoice_no}}</td>
                                    <td style="height: 40px;text-align: center!important;">{{data1.invoice_date}}</td>
                                    <td style="height: 40px;text-align: center!important;">{{data1.ISSUENAME}}</td>
                                    <td style="height: 40px;text-align: center!important;">{{data1.advt_amt}}</td>
                                    <td style="height: 40px;text-align: center!important;">{{data1.remain_amt}}</td>
                                    <td style="height: 40px;text-align: center!important;">{{data1.advt_name}}</td>
                                </tr>
                            </tbody>
                            <tfoot>
                                
                                <tr style="text-align: center !important;">
                                    <td colspan="3" style="height: 40px;text-align: center!important;"> सर्व एकूण जाहिरात</td>
                                    <td style="height: 40px;text-align: center!important;">{{PendingReceiptAmt}}</td>
                                    <td style="height: 40px;text-align: center!important;">{{PendingBalanceAmt}}</td>
                                    <td style="height: 40px;text-align: center!important;"></td>
                                </tr>
                            </tfoot>
                        </table>
                        <p>
                            ही पत्रेरी आपल्याला हवी असलेली बक्षीश रक्कम संपूर्ण करण्यासाठी आपल्याला नम्र विनंती करत आहे.
                        </p>
                        <div style="text-align: end;">
                            <p>आमच्या प्रिय,</p> <br> <p>आमच्या प्रिय,</p>
                        </div>
                    </div>
            </div>
        </div>
        <div class="row" style="margin-top: 100px; padding-bottom: 10px">
            <div class="col-6">
                <span style="font-weight: bold; font-size: 16px">(धनादेश वटल्यावरच पावती अधिकृत होईल)</span>
            </div>
            <div class="col-6" style="text-align: right; font-weight: bold; font-size: 16px">
                <span>व्यवस्थापक / लेखापाल</span>
            </div>
        </div>
    </div>
</div>

<div *ngIf="reportType == 'send-reminder2'">
    <div class="container" style="border: 2px solid black">
        <div class="row">
            <div class="col-2 image">
                <img style="margin-top: 6%" width="85%" src="../../../assets/img/brand/red.png" />
            </div>
            <div class="col-8" style="padding-top: 30px; text-align: center; padding-bottom: 10px">
                <span style="font-size: 35px; font-weight: bold">हिंदुस्थान प्रकाशन संस्था</span>
                <span style="font-size: 14px; margin-left: 05px">(Reg.No.-BOM-3/1961(GBDSD))</span>
                <hr />
                <div class="row" style="text-align: left">
                    <div class="col-6" style="padding-top: 10px">
                        <span style="font-size: 14px"><span style="font-weight: bold; font-size: 16px">प्रशासकीय
                                कार्यालय :</span>
                            ३०३, वडाळा उद्योग भवन, नाईगाव क्रॉस रोड, वडाळा, मुंबई - ४०००३१<br /></span>
                        <span style="font-size: 14px"><span style="font-weight: bold; font-size: 16px">दूरध्वनी:</span>
                            9967570531
                            <!-- <span style="font-weight: bold; font-size: 16px">फॅक्स:</span>
                            27810237 -->
                        </span>
                    </div>
                    <div class="col-6" style="
              border-style: solid;
              border-width: 0px 0px 0px 2px;
              padding-top: 10px;
            ">
                        <span style="font-size: 14px"><span style="font-weight: bold; font-size: 16px">मुख्य कार्यालय :
                            </span>
                            5/12, कामत औद्योगिक वसाहत,<br />
                            396 स्वा. वीर सावरकर मार्ग, प्रभादेवी, मुंबई 4000 25.<br /></span>
                        <span style="font-size: 14px"><span style="font-weight: bold; font-size: 16px">दूरध्वनी :
                            </span>
                            24221440
                            <!-- <span style="font-weight: bold; font-size: 16px">फॅक्स : </span>
                            24363756 -->
                        </span>
                    </div>
                </div>
            </div>
            <div class="col-2" style="
          border-style: solid;
          border-width: 0px 0px 0px 2px;
          /* padding-top: 40px; */
          text-align: center;
          display: flex;
          align-items: center;
        ">
                <span style="font-size: 20px"> ग्राहक स्मरणपत्र</span>
            </div>
        </div>
        <div class="row" style="border-style: solid;border-width: 2px 0px 0px 0px;text-align: center;">
            <div class="col-6" style="padding-top: 20px; padding-bottom: 20px; text-align: left">
                <span style="font-weight: bold; font-size: 16px">प्रति
                </span><br />
                <span style="font-weight: bold; font-size: 16px">Address :&nbsp;</span><span style="font-size: 14px"></span><br />
                <span style="font-weight: bold; font-size: 16px">Address 2nd line :&nbsp;</span><span
                    style="font-size: 14px"></span>
            </div>
            <div class="col-6" style="border-style: solid; border-width: 0px 0px 0px 2px; text-align: end;padding-top: 20px;">
                <!-- <div class="row" style="border-style: solid;border-width: 0px 0px 2px 0px;text-align: center;padding-top: 20px;padding-bottom: 10px;"> -->
                    <!-- <div class="col-6" style="text-align: left">
                        <span style="font-weight: bold; font-size: 16px">पावती क्रं.: </span><span>{{ this.receiptno
                            }}</span>
                    </div> -->
                    <div class="col-6" style="text-align: left">
                        <span style="font-weight: bold; font-size: 16px">दिनांक:&nbsp;</span><span
                            style="font-size: 14px">27/02/2024</span>
                    </div>
                <!-- </div> -->
                
            </div>
        </div>
        <div class="row" style="
        border-style: solid;
        border-width: 2px 0px 0px 0px;
        text-align: center;
        padding-top: 20px;
      ">
            <div class="col-12" style="text-align: left">
                <span>प्रिय नमस्कार</span>
                <p>ही पत्रेरी आपल्याला हवी असलेली बक्षीश रक्कम संपूर्ण करण्यासाठी आपल्याला नम्र विनंती करत आहे. आपल्या खात्यातील [रक्कम] रुपये एका किंवा अधिक कारणे आपल्या आवकाशी संपूर्ण ठरवण्यात आली पाहिजे.

                    आपल्या आवकाशी संपूर्ण करण्यात हमारी मदत करण्यात आपलं आत्मविश्वास होईल. कृपया, आपली बक्षीश रक्कम तात्काळ संपूर्ण करा आणि त्याची पुस्तक नमुना किंवा नकल आपल्या खात्यात पाठवा.
                    
                    यदि आप या तात्काळीन परिस्थितीत असता, तरी कृपया हमाक त्वरित सूचित करा आणि योग्य कारणे सांगा.
                    
                    आपल्याचं विशेषज्ञता आणि आपल्या सहकार्यानुसार हमाक आपल्या उत्तरात आपली सहाय्य करण्याची आशा आहे</p>
                <!-- <span style="font-size: 14px" *ngFor="let book of advtInvoicePricePerIssue">{{ book["invoice_no"] }},
                    &nbsp;</span> -->
                    <div>
                        <table style="width: 100% !important;">
                            <thead>
                                <tr style="text-align: center !important;">
                                    <th style="height: 40px;">बील क्रमांक</th>
                                    <th style="height: 40px;">बील तारीख</th>
                                    <th style="height: 40px;"> ग्राहक नाव</th>
                                    <th style="height: 40px;">प्रकाशनचे नाव</th>
                                    <th style="height: 40px;">प्रकाशनचे तारीख </th>
                                    <th style="height: 40px;"> एकूण रक्कम</th>
                                    <th style="height: 40px;"> येणे बाकी</th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr style="text-align: center !important;" *ngFor="let data1 of getpendingreceiptrep; let k = index">
                                    <td style="height: 40px;text-align: center!important;">{{data1.invoice_no}}</td>
                                    <td style="height: 40px;text-align: center!important;">{{data1.invoice_date}}</td>
                                    <td style="height: 40px;text-align: center!important;">{{data1.advt_name}}</td>
                                    <td style="height: 40px;text-align: center!important;">{{data1.ISSUENAME}}</td>
                                    <td style="height: 40px;text-align: center!important;">{{data1.ISSUE_DATE}}</td>
                                    <td style="height: 40px;text-align: center!important;">{{data1.advt_amt}}</td>
                                    <td style="height: 40px;text-align: center!important;">{{data1.remain_amt}}</td>
                                </tr>
                            </tbody>
                            <tfoot>
                                
                                <tr style="text-align: center !important;">
                                    <td colspan="5" style="height: 40px;text-align: center!important;"> सर्व एकूण जाहिरात</td>
                                    <td style="height: 40px;text-align: center!important;">{{PendingReceiptAmt}}</td>
                                    <td style="height: 40px;text-align: center!important;">{{PendingBalanceAmt}}</td>
                                </tr>
                            </tfoot>
                        </table>
                        <p>
                            ही पत्रेरी आपल्याला हवी असलेली बक्षीश रक्कम संपूर्ण करण्यासाठी आपल्याला नम्र विनंती करत आहे.
                        </p>
                        <div style="text-align: end;">
                            <p>आमच्या प्रिय,</p> <br> <p>आमच्या प्रिय,</p>
                        </div>
                    </div>
            </div>
        </div>
        <div class="row" style="margin-top: 100px; padding-bottom: 10px">
            <div class="col-6">
                <span style="font-weight: bold; font-size: 16px">(धनादेश वटल्यावरच पावती अधिकृत होईल)</span>
            </div>
            <div class="col-6" style="text-align: right; font-weight: bold; font-size: 16px">
                <span>व्यवस्थापक / लेखापाल</span>
            </div>
        </div>
    </div>
</div>


<!-- Subscription -->
<div *ngIf="reportType == 'Renewaljilha'">
    <div id="print-section" class="container">
      
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th colspan="43" style="text-align: center !important; font-size: 20px;">जिल्हा निहाय </th>
                    <!-- <th colspan="2">Advt. Outstanding Report</th> -->
                    <!-- <th colspan="2">दिनांक</th>
                    <th colspan="2">पृष्ठ क्र</th> -->
                </tr>
                <tr>
                    <th colspan="29" style="text-align: center !important;">हिंदुस्तान प्रकाशन संस्था - विवेक साप्ताहिक - वर्गणी विभाग</th>
                    <th colspan="7" style="text-align: center !important;">Date</th>
                    <th colspan="7" style="text-align: center !important;">From  {{startDate}}</th>
                    <!-- <th colspan="2">To {{endDate}}</th>
                     <th colspan="1">In Details</th>  -->
                </tr>
                <tr>
                    <th colspan="20" style="text-align: center !important;">वर्गणीदार नोंदणी - नवीन व्यवसाय तपशिल </th>
                    <th colspan="5" style="text-align: center !important;">कोकण</th>
                    <th colspan="5" style="text-align: center !important;">Districtwise</th>
                    <th colspan="5" style="text-align: center !important;">Very Briefly</th>
                    <th colspan="4" style="text-align: center !important;">From</th>
                    <th colspan="4" style="text-align: center !important;">To</th> 
                </tr>
                <tr>
                    <th colspan="4">नूतनीकरण येणे बाकी</th>
                    <!-- <th colspan="3">January</th>
                    <th colspan="3">February</th>
                    <th colspan="3">March</th> -->
                    <th colspan="3">April</th>
                    <th colspan="3">May</th>
                    <th colspan="3">June</th>
                    <th colspan="3">July</th>
                    <th colspan="3">August</th>
                    <th colspan="3">September</th>
                    <th colspan="3">October</th>
                    <th colspan="3">November</th>
                    <th colspan="3">December</th>
                    <th colspan="3">January</th>
                    <th colspan="3">February</th>
                    <th colspan="3">March</th>
                    <th colspan="3">Total</th>
                </tr>
                <tr>
                    <th colspan="4">जिल्हा</th>
                    <th>पोस्ट</th>
                    <th>हस्ते</th>
                    <th>एकूण</th>
                    <th>पोस्ट</th>
                    <th>हस्ते</th>
                    <th>एकूण</th>
                    <th>पोस्ट</th>
                    <th>हस्ते</th>
                    <th>एकूण</th>
                    <th>पोस्ट</th>
                    <th>हस्ते</th>
                    <th>एकूण</th>
                    <th>पोस्ट</th>
                    <th>हस्ते</th>
                    <th>एकूण</th>
                    <th>पोस्ट</th>
                    <th>हस्ते</th>
                    <th>एकूण</th>
                    <th>पोस्ट</th>
                    <th>हस्ते</th>
                    <th>एकूण</th>
                    <th>पोस्ट</th>
                    <th>हस्ते</th>
                    <th>एकूण</th>
                    <th>पोस्ट</th>
                    <th>हस्ते</th>
                    <th>एकूण</th>
                    <th>पोस्ट</th>
                    <th>हस्ते</th>
                    <th>एकूण</th>
                     <th>पोस्ट</th>
                    <th>हस्ते</th>
                    <th>एकूण</th>
                    <th>पोस्ट</th>
                    <th>हस्ते</th>
                    <th>एकूण</th>
                    <th>पोस्ट</th>
                    <th>हस्ते</th>
                    <th>एकूण</th>
                  </tr>   
            </thead>
            <!-- *ngFor="let data of OutstandingInDetailFormBeans; let i = index" -->
            <tbody>

                <tr *ngFor="let data of SubByZilha; let i = index">
                    <td colspan="4">{{data.district}}</td>
                    <td>{{data.april_post_count}}</td>
                    <td>{{data.april_haste_count}}</td>
                    <td>{{data.april_total}}</td>
                    <td>{{data.may_post_count}}</td>
                    <td>{{data.may_haste_count}}</td>
                    <td>{{data.may_total}}</td>
                    <td>{{data.jun_post_count}}</td>
                    <td>{{data.jun_haste_count}}</td>
                    <td>{{data.jun_total}}</td>
                    <!-- april -->
                    <td>{{data.jul_post_count}}</td>
                    <td>{{data.jul_haste_count}}</td>
                    <td>{{data.jul_total}}</td>
                    <td>{{data.aug_post_count}}</td>
                    <td>{{data.aug_haste_count}}</td>
                    <td>{{data.aug_total}}</td>
                    <td>{{data.sep_post_count}}</td>
                    <td>{{data.sep_haste_count}}</td>
                    <td>{{data.sep_total}}</td>
                    <td>{{data.oct_post_count}}</td>
                    <td>{{data.oct_haste_count}}</td>
                    <td>{{data.oct_total}}</td>
                    <td>{{data.nov_post_count}}</td>
                    <td>{{data.nov_haste_count}}</td>
                    <td>{{data.nov_total}}</td>
                    <td>{{data.dec_post_count}}</td>
                    <td>{{data.dec_haste_count}}</td>
                    <td>{{data.dec_total}}</td>
                    <td>{{data.jan_post_count}}</td>
                    <td>{{data.jan_haste_count}}</td>
                    <td>{{data.jan_total}}</td>
                    <td>{{data.feb_post_count}}</td>
                    <td>{{data.feb_haste_count}}</td>
                    <td>{{data.feb_total}}</td>
                    <td>{{data.mar_post_count}}</td>
                    <td>{{data.mar_haste_count}}</td>
                    <td>{{data.mar_total}}</td>
                  
                    <td>{{data.post_total}}</td>
                    <td>{{data.haste_total}}</td>
                    <td>{{data.grand_total}}</td>
                </tr>
                 
            </tbody>

            <tfoot>
                <tr>
                  <td colspan="4">TOTAL</td>
                  <td>{{aprilpost}}</td>
                  <td>{{aprilhaste}}</td>
                  <td>{{apriltotal}}</td>
                  <td>{{maypost}}</td>
                  <td>{{mayhaste}}</td>
                  <td>{{maytotal}}</td>
                  <td>{{junepost}}</td>
                  <td>{{junehaste}}</td>
                  <td>{{junetotal}}</td>
                  <td>{{julypost}}</td>
                  <td>{{julyhaste}}</td>
                  <td>{{julytotal}}</td>
                  <td>{{augpost}}</td>
                  <td>{{aughaste}}</td>
                  <td>{{augtotal}}</td>
                  <td>{{seppost}}</td>
                  <td>{{sephaste}}</td>
                  <td>{{septotal}}</td>
                  <td>{{octopost}}</td>
                  <td>{{octohaste}}</td>
                  <td>{{octototal}}</td>
                  <td>{{novpost}}</td>
                  <td>{{novhaste}}</td>
                  <td>{{novtotal}}</td>
                  <td>{{decpost}}</td>
                  <td>{{dechaste}}</td>
                  <td>{{dectotal}}</td>
                  <td>{{janpost}}</td>
                  <td>{{janhaste}}</td>
                  <td>{{jantotal}}</td>
                  <td>{{febpost}}</td>
                  <td>{{febhaste}}</td>
                  <td>{{febtotal}}</td>
                  <td>{{marchpost}}</td>
                  <td>{{marchhaste}}</td>
                  <td>{{marchtotal}}</td>
                  <td>{{totalzilhahapost}}</td>
                  <td>{{totalzilhahahaste}}</td>
                  <td>{{totalzilhahatotal}}</td>
                </tr>
                
                </tfoot>  
                <!-- <tfoot>
                    <tr>
                        {{columnSums | json}}
                      <td *ngFor="let sum of columnSums">{{ sum }}</td>
                    </tr>
                </tfoot> -->
        </table>
      
      
    </div>
    <div style="margin-left: 80%; margin-bottom: 2%;">
        <!-- <button class="noPrint" style="margin-top: 2%;" mat-raised-button color="primary"
            [printStyle]="{table : {'border-collapse':'collapse'}, td : {'border': 'solid 1px'}, th : {'border': 'solid 1px'}}"
            printSectionId="print-section2" ngxPrint>
            print
        </button> -->
        <button class="noPrint" style="margin-top: 2%; margin-left: 2%"
            (click)="exportExcel('Contract_InDetails_Report')" mat-raised-button color="primary">
            Export
        </button>

        <button class="noPrint" style="margin-top: 2%;" mat-raised-button color="primary"
           [useExistingCss]="true"
            printSectionId="print-section" ngxPrint>
            print
        </button>
        
        <button class="noPrint" style="margin-top: 2%" mat-button mat-dialog-close>
            Close
        </button>
    </div>
</div>

<div *ngIf="reportType == 'Renewaltaluka'">
    <div class="container">
      
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th colspan="43" style="text-align: center !important; font-size: 20px;">जिल्हा निहाय </th>
                    <!-- <th colspan="2">Advt. Outstanding Report</th> -->
                    <!-- <th colspan="2">दिनांक</th>
                    <th colspan="2">पृष्ठ क्र</th> -->
                </tr>
                <tr>
                    <th colspan="29" style="text-align: center !important;">हिंदुस्तान प्रकाशन संस्था - विवेक साप्ताहिक - वर्गणी विभाग</th>
                    <th colspan="7" style="text-align: center !important;">Date</th>
                    <th colspan="7" style="text-align: center !important;">From  {{startDate}}</th>
                    <!-- <th colspan="2">To {{endDate}}</th>
                     <th colspan="1">In Details</th>  -->
                </tr>
                <tr>
                    <th colspan="20" style="text-align: center !important;">वर्गणीदार नोंदणी - नवीन व्यवसाय तपशिल </th>
                    <th colspan="5" style="text-align: center !important;">कोकण</th>
                    <th colspan="5" style="text-align: center !important;">Districtwise</th>
                    <th colspan="5" style="text-align: center !important;">Very Briefly</th>
                    <th colspan="4" style="text-align: center !important;">From</th>
                    <th colspan="4" style="text-align: center !important;">To</th> 
                </tr>
                <tr>
                    <th colspan="4">नूतनीकरण येणे बाकी (तालुका  )</th>
                    <!-- <th colspan="3">January</th>
                    <th colspan="3">February</th>
                    <th colspan="3">March</th> -->
                    <th colspan="3">April</th>
                    <th colspan="3">May</th>
                    <th colspan="3">June</th>
                    <th colspan="3">July</th>
                    <th colspan="3">August</th>
                    <th colspan="3">September</th>
                    <th colspan="3">October</th>
                    <th colspan="3">November</th>
                    <th colspan="3">December</th>
                    <th colspan="3">January</th>
                    <th colspan="3">February</th>
                    <th colspan="3">March</th>
                    <th colspan="3">Total</th>
                </tr>
                <tr>
                    <th colspan="2">जिल्हा</th>
                    <th colspan="2">तालुका </th>

                    <th>पोस्ट</th>
                    <th>हस्ते</th>
                    <th>एकूण</th>
                    <th>पोस्ट</th>
                    <th>हस्ते</th>
                    <th>एकूण</th>
                    <th>पोस्ट</th>
                    <th>हस्ते</th>
                    <th>एकूण</th>
                    <th>पोस्ट</th>
                    <th>हस्ते</th>
                    <th>एकूण</th>
                    <th>पोस्ट</th>
                    <th>हस्ते</th>
                    <th>एकूण</th>
                    <th>पोस्ट</th>
                    <th>हस्ते</th>
                    <th>एकूण</th>
                    <th>पोस्ट</th>
                    <th>हस्ते</th>
                    <th>एकूण</th>
                    <th>पोस्ट</th>
                    <th>हस्ते</th>
                    <th>एकूण</th>
                    <th>पोस्ट</th>
                    <th>हस्ते</th>
                    <th>एकूण</th>
                    <th>पोस्ट</th>
                    <th>हस्ते</th>
                    <th>एकूण</th>
                     <th>पोस्ट</th>
                    <th>हस्ते</th>
                    <th>एकूण</th>
                    <th>पोस्ट</th>
                    <th>हस्ते</th>
                    <th>एकूण</th>
                    <th>पोस्ट</th>
                    <th>हस्ते</th>
                    <th>एकूण</th>
                  </tr>   
            </thead>
            <!-- *ngFor="let data of OutstandingInDetailFormBeans; let i = index" -->
            <tbody *ngFor="let data of SubByTaluka; let i = index">

                <tr>
                    <td colspan="2">{{data.district}}</td>
                    <td colspan="2">{{data.taluka}}</td>

                    <td>{{data.april_post_count}}</td>
                    <td>{{data.april_haste_count}}</td>
                    <td>{{data.april_total}}</td>
                    <td>{{data.may_post_count}}</td>
                    <td>{{data.may_haste_count}}</td>
                    <td>{{data.may_total}}</td>
                    <td>{{data.jun_post_count}}</td>
                    <td>{{data.jun_haste_count}}</td>
                    <td>{{data.jun_total}}</td>
                    <!-- april -->
                    <td>{{data.jul_post_count}}</td>
                    <td>{{data.jul_haste_count}}</td>
                    <td>{{data.jul_total}}</td>
                    <td>{{data.aug_post_count}}</td>
                    <td>{{data.aug_haste_count}}</td>
                    <td>{{data.aug_total}}</td>
                    <td>{{data.sep_post_count}}</td>
                    <td>{{data.sep_haste_count}}</td>
                    <td>{{data.sep_total}}</td>
                    <td>{{data.oct_post_count}}</td>
                    <td>{{data.oct_haste_count}}</td>
                    <td>{{data.oct_total}}</td>
                    <td>{{data.nov_post_count}}</td>
                    <td>{{data.nov_haste_count}}</td>
                    <td>{{data.nov_total}}</td>
                    <td>{{data.dec_post_count}}</td>
                    <td>{{data.dec_haste_count}}</td>
                    <td>{{data.dec_total}}</td>
                    <td>{{data.jan_post_count}}</td>
                    <td>{{data.jan_haste_count}}</td>
                    <td>{{data.jan_total}}</td>
                    <td>{{data.feb_post_count}}</td>
                    <td>{{data.feb_haste_count}}</td>
                    <td>{{data.feb_total}}</td>
                    <td>{{data.mar_post_count}}</td>
                    <td>{{data.mar_haste_count}}</td>
                    <td>{{data.mar_total}}</td>
                    <td>{{data.post_total}}</td>
                    <td>{{data.haste_total}}</td>
                    <td>{{data.grand_total}}</td>
                </tr>
                 
            </tbody>

            <tfoot>
                <tr>
                  <td colspan="4">TOTAL</td>
                  <td>{{aprilpost}}</td>
                  <td>{{aprilhaste}}</td>
                  <td>{{apriltotal}}</td>
                  <td>{{maypost}}</td>
                  <td>{{mayhaste}}</td>
                  <td>{{maytotal}}</td>
                  <td>{{junepost}}</td>
                  <td>{{junehaste}}</td>
                  <td>{{junetotal}}</td>
                  <td>{{julypost}}</td>
                  <td>{{julyhaste}}</td>
                  <td>{{julytotal}}</td>
                  <td>{{augpost}}</td>
                  <td>{{aughaste}}</td>
                  <td>{{augtotal}}</td>
                  <td>{{seppost}}</td>
                  <td>{{sephaste}}</td>
                  <td>{{septotal}}</td>
                  <td>{{octopost}}</td>
                  <td>{{octohaste}}</td>
                  <td>{{octototal}}</td>
                  <td>{{novpost}}</td>
                  <td>{{novhaste}}</td>
                  <td>{{novtotal}}</td>
                  <td>{{decpost}}</td>
                  <td>{{dechaste}}</td>
                  <td>{{dectotal}}</td>
                  <td>{{janpost}}</td>
                  <td>{{janhaste}}</td>
                  <td>{{jantotal}}</td>
                  <td>{{febpost}}</td>
                  <td>{{febhaste}}</td>
                  <td>{{febtotal}}</td>
                  <td>{{marchpost}}</td>
                  <td>{{marchhaste}}</td>
                  <td>{{marchtotal}}</td>
                  <td>{{totalzilhahapost}}</td>
                  <td>{{totalzilhahahaste}}</td>
                  <td>{{totalzilhahatotal}}</td>
                </tr>
                
                </tfoot>    
        </table>
      
      
    </div>
</div>
<div *ngIf="reportType == 'Renewalrep'">
    <div class="container">
      
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th colspan="43" style="text-align: center !important; font-size: 20px;">जिल्हा निहाय </th>
                    <!-- <th colspan="2">Advt. Outstanding Report</th> -->
                    <!-- <th colspan="2">दिनांक</th>
                    <th colspan="2">पृष्ठ क्र</th> -->
                </tr>
                <tr>
                    <th colspan="29" style="text-align: center !important;">हिंदुस्तान प्रकाशन संस्था - विवेक साप्ताहिक - वर्गणी विभाग</th>
                    <th colspan="7" style="text-align: center !important;">Date</th>
                    <th colspan="7" style="text-align: center !important;">From  {{startDate}}</th>
                    <!-- <th colspan="2">To {{endDate}}</th>
                     <th colspan="1">In Details</th>  -->
                </tr>
                <tr>
                    <th colspan="20" style="text-align: center !important;">वर्गणीदार नोंदणी - नवीन व्यवसाय तपशिल </th>
                    <th colspan="5" style="text-align: center !important;">कोकण</th>
                    <th colspan="5" style="text-align: center !important;">Districtwise</th>
                    <th colspan="5" style="text-align: center !important;">Very Briefly</th>
                    <th colspan="4" style="text-align: center !important;">From</th>
                    <th colspan="4" style="text-align: center !important;">To</th> 
                </tr>
                <tr>
                    <th colspan="4">नूतनीकरण येणे बाकी (प्रतिनिधीनिहाय)</th>
                    <!-- <th colspan="3">January</th>
                    <th colspan="3">February</th>
                    <th colspan="3">March</th> -->
                    <th colspan="3">April</th>
                    <th colspan="3">May</th>
                    <th colspan="3">June</th>
                    <th colspan="3">July</th>
                    <th colspan="3">August</th>
                    <th colspan="3">September</th>
                    <th colspan="3">October</th>
                    <th colspan="3">November</th>
                    <th colspan="3">December</th>
                    <th colspan="3">January</th>
                    <th colspan="3">February</th>
                    <th colspan="3">March</th>
                    <th colspan="3">Total</th>
                </tr>
                <tr>
                    <th colspan="2">जिल्हा</th>
                    <th colspan="2">तालुका</th>
                    <!-- <th colspan="1">तालुका</th> -->
                    <th>पोस्ट</th>
                    <th>हस्ते</th>
                    <th>एकूण</th>
                    <th>पोस्ट</th>
                    <th>हस्ते</th>
                    <th>एकूण</th>
                    <th>पोस्ट</th>
                    <th>हस्ते</th>
                    <th>एकूण</th>
                    <th>पोस्ट</th>
                    <th>हस्ते</th>
                    <th>एकूण</th>
                    <th>पोस्ट</th>
                    <th>हस्ते</th>
                    <th>एकूण</th>
                    <th>पोस्ट</th>
                    <th>हस्ते</th>
                    <th>एकूण</th>
                    <th>पोस्ट</th>
                    <th>हस्ते</th>
                    <th>एकूण</th>
                    <th>पोस्ट</th>
                    <th>हस्ते</th>
                    <th>एकूण</th>
                    <th>पोस्ट</th>
                    <th>हस्ते</th>
                    <th>एकूण</th>
                    <th>पोस्ट</th>
                    <th>हस्ते</th>
                    <th>एकूण</th>
                     <th>पोस्ट</th>
                    <th>हस्ते</th>
                    <th>एकूण</th>
                    <th>पोस्ट</th>
                    <th>हस्ते</th>
                    <th>एकूण</th>
                    <th>पोस्ट</th>
                    <th>हस्ते</th>
                    <th>एकूण</th>
                  </tr>   
            </thead>
            <!-- *ngFor="let data of OutstandingInDetailFormBeans; let i = index" -->
            <tbody *ngFor="let data1 of SubByRep; let i = index">

                <tr>
                    <td colspan="4" style="font-size: 15px; font-weight: bold; text-align: center !important;">{{data1.rep_name}}</td>
                    <td colspan="39" style="font-size: 15px; font-weight: bold; text-align: center !important;"></td>
                </tr>

                <tr *ngFor="let data of data1.rep_data; let i = index">
                    <td colspan="2">{{data.district}}</td>
                    <td colspan="2">{{data.taluka}}</td>
                    <td>{{data.april_post_count}}</td>
                    <td>{{data.april_haste_count}}</td>
                    <td>{{data.april_total}}</td>
                    <td>{{data.may_post_count}}</td>
                    <td>{{data.may_haste_count}}</td>
                    <td>{{data.may_total}}</td>
                    <td>{{data.jun_post_count}}</td>
                    <td>{{data.jun_haste_count}}</td>
                    <td>{{data.jun_total}}</td>
                    <!-- april -->
                    <td>{{data.jul_post_count}}</td>
                    <td>{{data.jul_haste_count}}</td>
                    <td>{{data.jul_total}}</td>
                    <td>{{data.aug_post_count}}</td>
                    <td>{{data.aug_haste_count}}</td>
                    <td>{{data.aug_total}}</td>
                    <td>{{data.sep_post_count}}</td>
                    <td>{{data.sep_haste_count}}</td>
                    <td>{{data.sep_total}}</td>
                    <td>{{data.oct_post_count}}</td>
                    <td>{{data.oct_haste_count}}</td>
                    <td>{{data.oct_total}}</td>
                    <td>{{data.nov_post_count}}</td>
                    <td>{{data.nov_haste_count}}</td>
                    <td>{{data.nov_total}}</td>
                    <td>{{data.dec_post_count}}</td>
                    <td>{{data.dec_haste_count}}</td>
                    <td>{{data.dec_total}}</td>
                    <td>{{data.jan_post_count}}</td>
                    <td>{{data.jan_haste_count}}</td>
                    <td>{{data.jan_total}}</td>
                    <td>{{data.feb_post_count}}</td>
                    <td>{{data.feb_haste_count}}</td>
                    <td>{{data.feb_total}}</td>
                    <td>{{data.mar_post_count}}</td>
                    <td>{{data.mar_haste_count}}</td>
                    <td>{{data.mar_total}}</td>
                  
                    <td>{{data.post_total}}</td>
                    <td>{{data.haste_total}}</td>
                    <td>{{data.grand_total}}</td>
                </tr>
                 
            </tbody>

            <tfoot>
                <tr>
                  <td colspan="4">TOTAL</td>
                  <td>{{aprilpost}}</td>
                  <td>{{aprilhaste}}</td>
                  <td>{{apriltotal}}</td>
                  <td>{{maypost}}</td>
                  <td>{{mayhaste}}</td>
                  <td>{{maytotal}}</td>
                  <td>{{junepost}}</td>
                  <td>{{junehaste}}</td>
                  <td>{{junetotal}}</td>
                  <td>{{julypost}}</td>
                  <td>{{julyhaste}}</td>
                  <td>{{julytotal}}</td>
                  <td>{{augpost}}</td>
                  <td>{{aughaste}}</td>
                  <td>{{augtotal}}</td>
                  <td>{{seppost}}</td>
                  <td>{{sephaste}}</td>
                  <td>{{septotal}}</td>
                  <td>{{octopost}}</td>
                  <td>{{octohaste}}</td>
                  <td>{{octototal}}</td>
                  <td>{{novpost}}</td>
                  <td>{{novhaste}}</td>
                  <td>{{novtotal}}</td>
                  <td>{{decpost}}</td>
                  <td>{{dechaste}}</td>
                  <td>{{dectotal}}</td>
                  <td>{{janpost}}</td>
                  <td>{{janhaste}}</td>
                  <td>{{jantotal}}</td>
                  <td>{{febpost}}</td>
                  <td>{{febhaste}}</td>
                  <td>{{febtotal}}</td>
                  <td>{{marchpost}}</td>
                  <td>{{marchhaste}}</td>
                  <td>{{marchtotal}}</td>
                  <td>{{totalzilhahapost}}</td>
                  <td>{{totalzilhahahaste}}</td>
                  <td>{{totalzilhahatotal}}</td>
                </tr>
                
                </tfoot> 
        </table>
      
      
    </div>
</div>

<div *ngIf="reportType == 'PrantwiseDeliverymode'">
    <div id="print-section" class="container">
      
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th colspan="43" style="text-align: center !important; font-size: 20px;">जिल्हा निहाय </th>
                    <!-- <th colspan="2">Advt. Outstanding Report</th> -->
                    <!-- <th colspan="2">दिनांक</th>
                    <th colspan="2">पृष्ठ क्र</th> -->
                </tr>
                <tr>
                    <th colspan="29" style="text-align: center !important;">हिंदुस्तान प्रकाशन संस्था - विवेक साप्ताहिक - वर्गणी विभाग</th>
                    <th colspan="7" style="text-align: center !important;">Date</th>
                    <th colspan="7" style="text-align: center !important;">From  {{startDate}}</th>
                    <!-- <th colspan="2">To {{endDate}}</th>
                     <th colspan="1">In Details</th>  -->
                </tr>
                <tr>
                    <th colspan="20" style="text-align: center !important;">वर्गणीदार नोंदणी - नवीन व्यवसाय तपशिल </th>
                    <th colspan="5" style="text-align: center !important;">कोकण</th>
                    <th colspan="5" style="text-align: center !important;">Districtwise</th>
                    <th colspan="5" style="text-align: center !important;">Very Briefly</th>
                    <th colspan="4" style="text-align: center !important;">From</th>
                    <th colspan="4" style="text-align: center !important;">To</th> 
                </tr>
                <tr>
                    <th colspan="4">नूतनीकरण येणे बाकी</th>
                    <!-- <th colspan="3">January</th>
                    <th colspan="3">February</th>
                    <th colspan="3">March</th> -->
                    <th colspan="3">April</th>
                    <th colspan="3">May</th>
                    <th colspan="3">June</th>
                    <th colspan="3">July</th>
                    <th colspan="3">August</th>
                    <th colspan="3">September</th>
                    <th colspan="3">October</th>
                    <th colspan="3">November</th>
                    <th colspan="3">December</th>
                    <th colspan="3">January</th>
                    <th colspan="3">February</th>
                    <th colspan="3">March</th>
                    <th colspan="3">Total</th>
                </tr>
                <tr>
                    <th colspan="4">जिल्हा</th>
                    <th>पोस्ट</th>
                    <th>हस्ते</th>
                    <th>एकूण</th>
                    <th>पोस्ट</th>
                    <th>हस्ते</th>
                    <th>एकूण</th>
                    <th>पोस्ट</th>
                    <th>हस्ते</th>
                    <th>एकूण</th>
                    <th>पोस्ट</th>
                    <th>हस्ते</th>
                    <th>एकूण</th>
                    <th>पोस्ट</th>
                    <th>हस्ते</th>
                    <th>एकूण</th>
                    <th>पोस्ट</th>
                    <th>हस्ते</th>
                    <th>एकूण</th>
                    <th>पोस्ट</th>
                    <th>हस्ते</th>
                    <th>एकूण</th>
                    <th>पोस्ट</th>
                    <th>हस्ते</th>
                    <th>एकूण</th>
                    <th>पोस्ट</th>
                    <th>हस्ते</th>
                    <th>एकूण</th>
                    <th>पोस्ट</th>
                    <th>हस्ते</th>
                    <th>एकूण</th>
                     <th>पोस्ट</th>
                    <th>हस्ते</th>
                    <th>एकूण</th>
                    <th>पोस्ट</th>
                    <th>हस्ते</th>
                    <th>एकूण</th>
                    <th>पोस्ट</th>
                    <th>हस्ते</th>
                    <th>एकूण</th>
                  </tr>   
            </thead>
            <!-- *ngFor="let data of OutstandingInDetailFormBeans; let i = index" -->
            <tbody *ngFor="let data1 of getdeliverytype; let i = index">

                <tr>
                    <td colspan="4" style="font-size: 15px; font-weight: bold; text-align: center !important;">{{data1.prant}}</td>
                    <td colspan="39" style="font-size: 15px; font-weight: bold; text-align: center !important;"></td>
                </tr>

                <tr *ngFor="let data of data1.sub_data; let i = index">
                    <td colspan="4">{{data.district}}</td>
                    <td>{{data.april_post_count}}</td>
                    <td>{{data.april_haste_count}}</td>
                    <td>{{data.april_total}}</td>
                    <td>{{data.may_post_count}}</td>
                    <td>{{data.may_haste_count}}</td>
                    <td>{{data.may_total}}</td>
                    <td>{{data.jun_post_count}}</td>
                    <td>{{data.jun_haste_count}}</td>
                    <td>{{data.jun_total}}</td>
                    <!-- april -->
                    <td>{{data.jul_post_count}}</td>
                    <td>{{data.jul_haste_count}}</td>
                    <td>{{data.jul_total}}</td>
                    <td>{{data.aug_post_count}}</td>
                    <td>{{data.aug_haste_count}}</td>
                    <td>{{data.aug_total}}</td>
                    <td>{{data.sep_post_count}}</td>
                    <td>{{data.sep_haste_count}}</td>
                    <td>{{data.sep_total}}</td>
                    <td>{{data.oct_post_count}}</td>
                    <td>{{data.oct_haste_count}}</td>
                    <td>{{data.oct_total}}</td>
                    <td>{{data.nov_post_count}}</td>
                    <td>{{data.nov_haste_count}}</td>
                    <td>{{data.nov_total}}</td>
                    <td>{{data.dec_post_count}}</td>
                    <td>{{data.dec_haste_count}}</td>
                    <td>{{data.dec_total}}</td>
                    <td>{{data.jan_post_count}}</td>
                    <td>{{data.jan_haste_count}}</td>
                    <td>{{data.jan_total}}</td>
                    <td>{{data.feb_post_count}}</td>
                    <td>{{data.feb_haste_count}}</td>
                    <td>{{data.feb_total}}</td>
                    <td>{{data.mar_post_count}}</td>
                    <td>{{data.mar_haste_count}}</td>
                    <td>{{data.mar_total}}</td>
                  
                    <td>{{data.post_total}}</td>
                    <td>{{data.haste_total}}</td>
                    <td>{{data.grand_total}}</td>
                </tr>
                 
            </tbody>

            <tfoot>
                <tr>
                  <td colspan="4">TOTAL</td>
                  <td>{{aprilpost}}</td>
                  <td>{{aprilhaste}}</td>
                  <td>{{apriltotal}}</td>
                  <td>{{maypost}}</td>
                  <td>{{mayhaste}}</td>
                  <td>{{maytotal}}</td>
                  <td>{{junepost}}</td>
                  <td>{{junehaste}}</td>
                  <td>{{junetotal}}</td>
                  <td>{{julypost}}</td>
                  <td>{{julyhaste}}</td>
                  <td>{{julytotal}}</td>
                  <td>{{augpost}}</td>
                  <td>{{aughaste}}</td>
                  <td>{{augtotal}}</td>
                  <td>{{seppost}}</td>
                  <td>{{sephaste}}</td>
                  <td>{{septotal}}</td>
                  <td>{{octopost}}</td>
                  <td>{{octohaste}}</td>
                  <td>{{octototal}}</td>
                  <td>{{novpost}}</td>
                  <td>{{novhaste}}</td>
                  <td>{{novtotal}}</td>
                  <td>{{decpost}}</td>
                  <td>{{dechaste}}</td>
                  <td>{{dectotal}}</td>
                  <td>{{janpost}}</td>
                  <td>{{janhaste}}</td>
                  <td>{{jantotal}}</td>
                  <td>{{febpost}}</td>
                  <td>{{febhaste}}</td>
                  <td>{{febtotal}}</td>
                  <td>{{marchpost}}</td>
                  <td>{{marchhaste}}</td>
                  <td>{{marchtotal}}</td>
                  <td>{{totalzilhahapost}}</td>
                  <td>{{totalzilhahahaste}}</td>
                  <td>{{totalzilhahatotal}}</td>
                </tr>
                
                </tfoot>  
                <!-- <tfoot>
                    <tr>
                        {{columnSums | json}}
                      <td *ngFor="let sum of columnSums">{{ sum }}</td>
                    </tr>
                </tfoot> -->
        </table>
      
      
    </div>
    <div style="margin-left: 80%; margin-bottom: 2%;">
        <!-- <button class="noPrint" style="margin-top: 2%;" mat-raised-button color="primary"
            [printStyle]="{table : {'border-collapse':'collapse'}, td : {'border': 'solid 1px'}, th : {'border': 'solid 1px'}}"
            printSectionId="print-section2" ngxPrint>
            print
        </button> -->
        <button class="noPrint" style="margin-top: 2%; margin-left: 2%"
            (click)="exportExcel('Contract_InDetails_Report')" mat-raised-button color="primary">
            Export
        </button>

        <button class="noPrint" style="margin-top: 2%;" mat-raised-button color="primary"
           [useExistingCss]="true"
            printSectionId="print-section" ngxPrint>
            print
        </button>
        
        <button class="noPrint" style="margin-top: 2%" mat-button mat-dialog-close>
            Close
        </button>
    </div>
</div>

<div *ngIf="reportType == 'RenewalOff'">
    <div class="container">
      
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th colspan="43" style="text-align: center !important; font-size: 20px;">जिल्हा निहाय </th>
                    <!-- <th colspan="2">Advt. Outstanding Report</th> -->
                    <!-- <th colspan="2">दिनांक</th>
                    <th colspan="2">पृष्ठ क्र</th> -->
                </tr>
                <tr>
                    <th colspan="29" style="text-align: center !important;">हिंदुस्तान प्रकाशन संस्था - विवेक साप्ताहिक - वर्गणी विभाग</th>
                    <th colspan="7" style="text-align: center !important;">Date</th>
                    <th colspan="7" style="text-align: center !important;">From  {{startDate}}</th>
                    <!-- <th colspan="2">To {{endDate}}</th>
                     <th colspan="1">In Details</th>  -->
                </tr>
                <tr>
                    <th colspan="20" style="text-align: center !important;">वर्गणीदार नोंदणी - नवीन व्यवसाय तपशिल </th>
                    <th colspan="5" style="text-align: center !important;">कोकण</th>
                    <th colspan="5" style="text-align: center !important;">Districtwise</th>
                    <th colspan="5" style="text-align: center !important;">Very Briefly</th>
                    <th colspan="4" style="text-align: center !important;">From</th>
                    <th colspan="4" style="text-align: center !important;">To</th> 
                </tr>
                <tr>
                    <th colspan="4">नूतनीकरण येणे बाकी (कार्यालानियाय प्रतिनिधी )</th>
                    <!-- <th colspan="3">January</th>
                    <th colspan="3">February</th>
                    <th colspan="3">March</th> -->
                    <th colspan="3">April</th>
                    <th colspan="3">May</th>
                    <th colspan="3">June</th>
                    <th colspan="3">July</th>
                    <th colspan="3">August</th>
                    <th colspan="3">September</th>
                    <th colspan="3">October</th>
                    <th colspan="3">November</th>
                    <th colspan="3">December</th>
                    <th colspan="3">January</th>
                    <th colspan="3">February</th>
                    <th colspan="3">March</th>
                    <th colspan="3">Total</th>
                </tr>
                <tr>
                    <th colspan="2">प्रतिनिधी </th>
                    <th colspan="1">जिल्हा</th>
                    <th colspan="1">तालुका</th>
                    <!-- <th colspan="1">तालुका</th> -->
                    <th>पोस्ट</th>
                    <th>हस्ते</th>
                    <th>एकूण</th>
                    <th>पोस्ट</th>
                    <th>हस्ते</th>
                    <th>एकूण</th>
                    <th>पोस्ट</th>
                    <th>हस्ते</th>
                    <th>एकूण</th>
                    <th>पोस्ट</th>
                    <th>हस्ते</th>
                    <th>एकूण</th>
                    <th>पोस्ट</th>
                    <th>हस्ते</th>
                    <th>एकूण</th>
                    <th>पोस्ट</th>
                    <th>हस्ते</th>
                    <th>एकूण</th>
                    <th>पोस्ट</th>
                    <th>हस्ते</th>
                    <th>एकूण</th>
                    <th>पोस्ट</th>
                    <th>हस्ते</th>
                    <th>एकूण</th>
                    <th>पोस्ट</th>
                    <th>हस्ते</th>
                    <th>एकूण</th>
                    <th>पोस्ट</th>
                    <th>हस्ते</th>
                    <th>एकूण</th>
                     <th>पोस्ट</th>
                    <th>हस्ते</th>
                    <th>एकूण</th>
                    <th>पोस्ट</th>
                    <th>हस्ते</th>
                    <th>एकूण</th>
                    <th>पोस्ट</th>
                    <th>हस्ते</th>
                    <th>एकूण</th>
                  </tr>   
            </thead>
            <!-- *ngFor="let data of OutstandingInDetailFormBeans; let i = index" -->
            <tbody *ngFor="let data1 of SubByOffRep; let i = index">

                <tr>
                    <td colspan="4" style="font-size: 15px; font-weight: bold; text-align: center !important;">{{data1.off_rep_name}}</td>
                    <td colspan="39" style="font-size: 15px; font-weight: bold; text-align: center !important;"></td>
                </tr>

                <tr *ngFor="let data of data1.rep_data; let i = index">
                    <td colspan="2">{{data.rep_name}}</td>
                    <td colspan="1">{{data.district}}</td>
                    <td colspan="1">{{data.taluka}}</td>
                    <td>{{data.april_post_count}}</td>
                    <td>{{data.april_haste_count}}</td>
                    <td>{{data.april_total}}</td>
                    <td>{{data.may_post_count}}</td>
                    <td>{{data.may_haste_count}}</td>
                    <td>{{data.may_total}}</td>
                    <td>{{data.jun_post_count}}</td>
                    <td>{{data.jun_haste_count}}</td>
                    <td>{{data.jun_total}}</td>
                    <!-- april -->
                    <td>{{data.jul_post_count}}</td>
                    <td>{{data.jul_haste_count}}</td>
                    <td>{{data.jul_total}}</td>
                    <td>{{data.aug_post_count}}</td>
                    <td>{{data.aug_haste_count}}</td>
                    <td>{{data.aug_total}}</td>
                    <td>{{data.sep_post_count}}</td>
                    <td>{{data.sep_haste_count}}</td>
                    <td>{{data.sep_total}}</td>
                    <td>{{data.oct_post_count}}</td>
                    <td>{{data.oct_haste_count}}</td>
                    <td>{{data.oct_total}}</td>
                    <td>{{data.nov_post_count}}</td>
                    <td>{{data.nov_haste_count}}</td>
                    <td>{{data.nov_total}}</td>
                    <td>{{data.dec_post_count}}</td>
                    <td>{{data.dec_haste_count}}</td>
                    <td>{{data.dec_total}}</td>
                    <td>{{data.jan_post_count}}</td>
                    <td>{{data.jan_haste_count}}</td>
                    <td>{{data.jan_total}}</td>
                    <td>{{data.feb_post_count}}</td>
                    <td>{{data.feb_haste_count}}</td>
                    <td>{{data.feb_total}}</td>
                    <td>{{data.mar_post_count}}</td>
                    <td>{{data.mar_haste_count}}</td>
                    <td>{{data.mar_total}}</td>
                  
                    <td>{{data.post_total}}</td>
                    <td>{{data.haste_total}}</td>
                    <td>{{data.grand_total}}</td>
                </tr>
                 
            </tbody>

            <tfoot>
                <tr>
                  <td colspan="4">TOTAL</td>
                  <td>{{aprilpost}}</td>
                  <td>{{aprilhaste}}</td>
                  <td>{{apriltotal}}</td>
                  <td>{{maypost}}</td>
                  <td>{{mayhaste}}</td>
                  <td>{{maytotal}}</td>
                  <td>{{junepost}}</td>
                  <td>{{junehaste}}</td>
                  <td>{{junetotal}}</td>
                  <td>{{julypost}}</td>
                  <td>{{julyhaste}}</td>
                  <td>{{julytotal}}</td>
                  <td>{{augpost}}</td>
                  <td>{{aughaste}}</td>
                  <td>{{augtotal}}</td>
                  <td>{{seppost}}</td>
                  <td>{{sephaste}}</td>
                  <td>{{septotal}}</td>
                  <td>{{octopost}}</td>
                  <td>{{octohaste}}</td>
                  <td>{{octototal}}</td>
                  <td>{{novpost}}</td>
                  <td>{{novhaste}}</td>
                  <td>{{novtotal}}</td>
                  <td>{{decpost}}</td>
                  <td>{{dechaste}}</td>
                  <td>{{dectotal}}</td>
                  <td>{{janpost}}</td>
                  <td>{{janhaste}}</td>
                  <td>{{jantotal}}</td>
                  <td>{{febpost}}</td>
                  <td>{{febhaste}}</td>
                  <td>{{febtotal}}</td>
                  <td>{{marchpost}}</td>
                  <td>{{marchhaste}}</td>
                  <td>{{marchtotal}}</td>
                  <td>{{totalzilhahapost}}</td>
                  <td>{{totalzilhahahaste}}</td>
                  <td>{{totalzilhahatotal}}</td>
                </tr>
                
                </tfoot>  
        </table>
      
      
    </div>
</div>

<!-- Subscription Type -->
<div *ngIf="reportType == 'SubscriptionTypeAll'">
    <div id="print-section" class="container">
      
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th colspan="15" style="text-align: center !important; font-size: 20px;">जिल्हा निहाय </th>
                    <!-- <th colspan="2">Advt. Outstanding Report</th> -->
                    <!-- <th colspan="2">दिनांक</th>
                    <th colspan="2">पृष्ठ क्र</th> -->
                </tr>
                <tr>
                    <th colspan="10" style="text-align: center !important;">हिंदुस्तान प्रकाशन संस्था - विवेक साप्ताहिक - वर्गणी विभाग</th>
                    <th colspan="3" style="text-align: center !important;">Date From  {{startDate}}</th>
                    <th colspan="2" style="text-align: center !important;">Page No</th>
                    <!-- <th colspan="2">To {{endDate}}</th>
                     <th colspan="1">In Details</th>  -->
                </tr>
                <tr>
                    <th colspan="3" style="text-align: center !important;">वर्गणीदार नोंदणी - नवीन व्यवसाय तपशिल </th>
                    <th colspan="2" style="text-align: center !important;">कोकण प्रांत</th>
                    <th colspan="3" style="text-align: center !important;"></th>
                    <th colspan="7" style="text-align: center !important;">Date</th>
                    <!-- <th colspan="4" style="text-align: center !important;">From</th>
                    <th colspan="4" style="text-align: center !important;">To</th>  -->
                </tr>
                <tr>
                    <th colspan="2">नूतनीकरण येणे बाकी</th>
                    <!-- <th colspan="3">January</th>
                    <th colspan="3">February</th>
                    <th colspan="3">March</th> -->
                    <th colspan="6">पोस्ट </th>
                    <th colspan="6">हस्ते </th>
                    <th colspan="1">एकूण </th>
                </tr>
                <tr>
                    <th>जिल्हा</th>
                    <th>तालुका</th>
                    <th>वार्षिक</th>
                    <th>त्रेवार्षिक</th>
                    <th>पंचवार्षिक</th>
                    <th>हिंदूत्व</th>
                    <th>लो. वा</th>
                    <th>एकूण</th>
                    <th>वार्षिक</th>
                    <th>त्रेवार्षिक</th>
                    <th>पंचवार्षिक</th>
                    <th>हिंदूत्व</th>
                    <th>लो. वा</th>
                    <th>एकूण</th>
                    <th>एकूण</th>
                   
                  </tr>   
            </thead>
            <tbody>
                <tr *ngFor="let data of typewisedistrict; let i = index">
                   <td colspan="2">{{data.district}}</td>
                   <td>{{data.varshik_tapal_count}}</td>
                    <td>{{data.trivarshik_tapal_count}}</td>
                    <td>{{data.panvarshik_tapal_count}}</td>
                    <td>{{data.hindutva_tapal_count}}</td>
                   <td>null</td>
                   <td>{{data.post_total}}</td>
                   <td>{{data.varshik_haste_count}}</td>
                    <td>{{data.trivarshik_haste_count}}</td>
                    <td>{{data.panvarshik_haste_count}}</td>
                    <td>{{data.hindutva_haste_count}}</td>
                   <td>null</td>
                   <td>{{data.haste_total}}</td>
                   <td>{{data.grand_total}}</td>
                  </tr>  
                 
            </tbody>

            <tfoot>
                <tr >
                    <!-- <td>{{data.district}}</td> -->
                    <td colspan="2">Total</td>
                    <td>{{pvarshik}}</td>
                    <td>{{ptrevarshik}}</td>
                    <td>{{ppanchvarshik}}</td>
                    <td>{{phindutva}}</td>
                    <td>null</td>
                    <td>{{ptotal}}</td>
                    <td>{{hvarshik}}</td>
                    <td>{{htrevarshik}}</td>
                    <td>{{hpanchvarshik}}</td>
                    <td>{{hhindutva}}</td>
                    <td>null</td>
                    <td>{{htotal}}</td>
                    <td>{{gtotal}}</td>
                   </tr> 
                
            </tfoot>  
                <!-- <tfoot>
                    <tr>
                        {{columnSums | json}}
                      <td *ngFor="let sum of columnSums">{{ sum }}</td>
                    </tr>
                </tfoot> -->
        </table>
      
      
    </div>
    <div style="margin-left: 80%; margin-bottom: 2%;">
        <!-- <button class="noPrint" style="margin-top: 2%;" mat-raised-button color="primary"
            [printStyle]="{table : {'border-collapse':'collapse'}, td : {'border': 'solid 1px'}, th : {'border': 'solid 1px'}}"
            printSectionId="print-section2" ngxPrint>
            print
        </button> -->
        <button class="noPrint" style="margin-top: 2%; margin-left: 2%"
            (click)="exportExcel('Contract_InDetails_Report')" mat-raised-button color="primary">
            Export
        </button>

        <button class="noPrint" style="margin-top: 2%;" mat-raised-button color="primary"
           [useExistingCss]="true"
            printSectionId="print-section" ngxPrint>
            print
        </button>
        
        <button class="noPrint" style="margin-top: 2%" mat-button mat-dialog-close>
            Close
        </button>
    </div>
</div>

<div *ngIf="reportType == 'SubscriptionTypeTaluka'">
    <div id="print-section" class="container">
      
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th colspan="15" style="text-align: center !important; font-size: 20px;">जिल्हा निहाय </th>
                    <!-- <th colspan="2">Advt. Outstanding Report</th> -->
                    <!-- <th colspan="2">दिनांक</th>
                    <th colspan="2">पृष्ठ क्र</th> -->
                </tr>
                <tr>
                    <th colspan="10" style="text-align: center !important;">हिंदुस्तान प्रकाशन संस्था - विवेक साप्ताहिक - वर्गणी विभाग</th>
                    <th colspan="3" style="text-align: center !important;">Date From  {{startDate}}</th>
                    <th colspan="2" style="text-align: center !important;">Page No</th>
                    <!-- <th colspan="2">To {{endDate}}</th>
                     <th colspan="1">In Details</th>  -->
                </tr>
                <tr>
                    <th colspan="3" style="text-align: center !important;">वर्गणीदार नोंदणी - नवीन व्यवसाय तपशिल </th>
                    <th colspan="2" style="text-align: center !important;">कोकण प्रांत</th>
                    <th colspan="3" style="text-align: center !important;"></th>
                    <th colspan="7" style="text-align: center !important;">Date</th>
                    <!-- <th colspan="4" style="text-align: center !important;">From</th>
                    <th colspan="4" style="text-align: center !important;">To</th>  -->
                </tr>
                <tr>
                    <th colspan="2">नूतनीकरण येणे बाकी</th>
                    <!-- <th colspan="3">January</th>
                    <th colspan="3">February</th>
                    <th colspan="3">March</th> -->
                    <th colspan="6">पोस्ट </th>
                    <th colspan="6">हस्ते </th>
                    <th colspan="1">एकूण </th>
                </tr>
                <tr>
                    <th>जिल्हा</th>
                    <th>तालुका</th>
                    <th>वार्षिक</th>
                    <th>त्रेवार्षिक</th>
                    <th>पंचवार्षिक</th>
                    <th>हिंदूत्व</th>
                    <th>लो. वा</th>
                    <th>एकूण</th>
                    <th>वार्षिक</th>
                    <th>त्रेवार्षिक</th>
                    <th>पंचवार्षिक</th>
                    <th>हिंदूत्व</th>
                    <th>लो. वा</th>
                    <th>एकूण</th>
                    <th>एकूण</th>
                   
                  </tr>   
            </thead>
            <!-- *ngFor="let data of OutstandingInDetailFormBeans; let i = index" -->
            <tbody>

                <tr *ngFor="let data of typewisetaluka; let i = index">
                   <td>{{data.district}}</td>
                   <td>{{data.taluka}}</td>
                   <td>{{data.varshik_tapal_count}}</td>
                   <td>{{data.trivarshik_tapal_count}}</td>
                   <td>{{data.panvarshik_tapal_count}}</td>
                   <td>{{data.hindutva_tapal_count}}</td>
                   
                   <td>null</td>
                   <td>{{data.post_total}}</td>
                   <td>{{data.varshik_haste_count}}</td>
                   <td>{{data.trivarshik_haste_count}}</td>
                   <td>{{data.panvarshik_haste_count}}</td>
                   <td>{{data.hindutva_haste_count}}</td>
                   <td>null</td>
                   <td>{{data.haste_total}}</td>
                   <td>{{data.grand_total}}</td>
                   
                  </tr>  
                 
            </tbody>

            <tfoot>
                <tr >
                    <!-- <td>{{data.district}}</td> -->
                    <td colspan="2">Total</td>
                    <td>{{pvarshik}}</td>
                    <td>{{ptrevarshik}}</td>
                    <td>{{ppanchvarshik}}</td>
                    <td>{{phindutva}}</td>
                    <td>null</td>
                    <td>{{ptotal}}</td>
                    <td>{{hvarshik}}</td>
                    <td>{{htrevarshik}}</td>
                    <td>{{hpanchvarshik}}</td>
                    <td>{{hhindutva}}</td>
                    <td>null</td>
                    <td>{{htotal}}</td>
                    <td>{{gtotal}}</td>
                   </tr> 
                
            </tfoot>    
                <!-- <tfoot>
                    <tr>
                        {{columnSums | json}}
                      <td *ngFor="let sum of columnSums">{{ sum }}</td>
                    </tr>
                </tfoot> -->
        </table>
      
      
    </div>
    <div style="margin-left: 80%; margin-bottom: 2%;">
        <!-- <button class="noPrint" style="margin-top: 2%;" mat-raised-button color="primary"
            [printStyle]="{table : {'border-collapse':'collapse'}, td : {'border': 'solid 1px'}, th : {'border': 'solid 1px'}}"
            printSectionId="print-section2" ngxPrint>
            print
        </button> -->
        <button class="noPrint" style="margin-top: 2%; margin-left: 2%"
            (click)="exportExcel('Contract_InDetails_Report')" mat-raised-button color="primary">
            Export
        </button>

        <button class="noPrint" style="margin-top: 2%;" mat-raised-button color="primary"
           [useExistingCss]="true"
            printSectionId="print-section" ngxPrint>
            print
        </button>
        
        <button class="noPrint" style="margin-top: 2%" mat-button mat-dialog-close>
            Close
        </button>
    </div>
</div>
<div *ngIf="reportType == 'SubscriptionTypeOffReprensantive'">
    <div id="print-section" class="container">
      
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th colspan="16" style="text-align: center !important; font-size: 20px;">जिल्हा निहाय </th>
                    <!-- <th colspan="2">Advt. Outstanding Report</th> -->
                    <!-- <th colspan="2">दिनांक</th>
                    <th colspan="2">पृष्ठ क्र</th> -->
                </tr>
                <tr>
                    <th colspan="11" style="text-align: center !important;">हिंदुस्तान प्रकाशन संस्था - विवेक साप्ताहिक - वर्गणी विभाग</th>
                    <th colspan="3" style="text-align: center !important;">Date From  {{startDate}}</th>
                    <th colspan="2" style="text-align: center !important;">Page No</th>
                    <!-- <th colspan="2">To {{endDate}}</th>
                     <th colspan="1">In Details</th>  -->
                </tr>
                <tr>
                    <th colspan="4" style="text-align: center !important;">वर्गणीदार नोंदणी - नवीन व्यवसाय तपशिल </th>
                    <th colspan="2" style="text-align: center !important;">कोकण प्रांत</th>
                    <th colspan="3" style="text-align: center !important;"></th>
                    <th colspan="7" style="text-align: center !important;">Date</th>
                    <!-- <th colspan="4" style="text-align: center !important;">From</th>
                    <th colspan="4" style="text-align: center !important;">To</th>  -->
                </tr>
                <tr>
                    <th colspan="3">नूतनीकरण येणे बाकी(कार्यालानियाय प्रतिनिधी )</th>
                    <!-- <th colspan="3">January</th>
                    <th colspan="3">February</th>
                    <th colspan="3">March</th> -->
                    <th colspan="6">पोस्ट </th>
                    <th colspan="6">हस्ते </th>
                    <th colspan="1">एकूण </th>
                </tr>
                <tr>
                    <th>जिल्हा</th>
                    <th>तालुका</th>
                    <th>प्रतिनिधी</th>
                    <th>वार्षिक</th>
                    <th>त्रेवार्षिक</th>
                    <th>पंचवार्षिक</th>
                    <th>हिंदूत्व</th>
                    <th>लो. वा</th>
                    <th>एकूण</th>
                    <th>वार्षिक</th>
                    <th>त्रेवार्षिक</th>
                    <th>पंचवार्षिक</th>
                    <th>हिंदूत्व</th>
                    <th>लो. वा</th>
                    <th>एकूण</th>
                    <th>एकूण</th>
                   
                  </tr>   
            </thead>
            <!-- *ngFor="let data of OutstandingInDetailFormBeans; let i = index" -->
            <tbody *ngFor="let data1 of typewiseofficer; let i = index">

                <tr >
                    <td colspan="3">{{data1.off_rep_name}}</td>
                    <td colspan="13"></td>
                </tr>
                <tr *ngFor="let data of data1.rep_data; let i = index">
                    <td>{{data.district}}</td>
                   <td>{{data.taluka}}</td>
                   <td>{{data.rep_name}}</td>
                   <td>{{data.varshik_tapal_count}}</td>
                   <td>{{data.trivarshik_tapal_count}}</td>
                   <td>{{data.panvarshik_tapal_count}}</td>
                   <td>{{data.hindutva_tapal_count}}</td>
                   <td>null</td>
                   <td>{{data.post_total}}</td>
                   <td>{{data.varshik_haste_count}}</td>
                   <td>{{data.trivarshik_haste_count}}</td>
                   <td>{{data.panvarshik_haste_count}}</td>
                   <td>{{data.hindutva_haste_count}}</td>
                   <td>null</td>
                   <td>{{data.haste_total}}</td>
                   <td>{{data.grand_total}}</td>
                   
                  </tr>  
                 
            </tbody>

            <tfoot>
                <tr >
                    <!-- <td>{{data.district}}</td> -->
                    <td colspan="3">Total</td>
                    <td>{{pvarshik}}</td>
                    <td>{{ptrevarshik}}</td>
                    <td>{{ppanchvarshik}}</td>
                    <td>{{phindutva}}</td>
                    <td>null</td>
                    <td>{{ptotal}}</td>
                    <td>{{hvarshik}}</td>
                    <td>{{htrevarshik}}</td>
                    <td>{{hpanchvarshik}}</td>
                    <td>{{hhindutva}}</td>
                    <td>null</td>
                    <td>{{htotal}}</td>
                    <td>{{gtotal}}</td>
                   </tr> 
                
            </tfoot>    
                <!-- <tfoot>
                    <tr>
                        {{columnSums | json}}
                      <td *ngFor="let sum of columnSums">{{ sum }}</td>
                    </tr>
                </tfoot> -->
        </table>
      
      
    </div>
    <div style="margin-left: 80%; margin-bottom: 2%;">
        <!-- <button class="noPrint" style="margin-top: 2%;" mat-raised-button color="primary"
            [printStyle]="{table : {'border-collapse':'collapse'}, td : {'border': 'solid 1px'}, th : {'border': 'solid 1px'}}"
            printSectionId="print-section2" ngxPrint>
            print
        </button> -->
        <button class="noPrint" style="margin-top: 2%; margin-left: 2%"
            (click)="exportExcel('Contract_InDetails_Report')" mat-raised-button color="primary">
            Export
        </button>

        <button class="noPrint" style="margin-top: 2%;" mat-raised-button color="primary"
           [useExistingCss]="true"
            printSectionId="print-section" ngxPrint>
            print
        </button>
        
        <button class="noPrint" style="margin-top: 2%" mat-button mat-dialog-close>
            Close
        </button>
    </div>
</div>

<div *ngIf="reportType == 'SubscriptionTypeReprensantive'">
    <div id="print-section" class="container">
      
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th colspan="16" style="text-align: center !important; font-size: 20px;">जिल्हा निहाय </th>
                    <!-- <th colspan="2">Advt. Outstanding Report</th> -->
                    <!-- <th colspan="2">दिनांक</th>
                    <th colspan="2">पृष्ठ क्र</th> -->
                </tr>
                <tr>
                    <th colspan="11" style="text-align: center !important;">हिंदुस्तान प्रकाशन संस्था - विवेक साप्ताहिक - वर्गणी विभाग</th>
                    <th colspan="3" style="text-align: center !important;">Date From  {{startDate}}</th>
                    <th colspan="2" style="text-align: center !important;">Page No</th>
                    <!-- <th colspan="2">To {{endDate}}</th>
                     <th colspan="1">In Details</th>  -->
                </tr>
                <tr>
                    <th colspan="4" style="text-align: center !important;">वर्गणीदार नोंदणी - नवीन व्यवसाय तपशिल </th>
                    <th colspan="2" style="text-align: center !important;">कोकण प्रांत</th>
                    <th colspan="3" style="text-align: center !important;"></th>
                    <th colspan="7" style="text-align: center !important;">Date</th>
                    <!-- <th colspan="4" style="text-align: center !important;">From</th>
                    <th colspan="4" style="text-align: center !important;">To</th>  -->
                </tr>
                <tr>
                    <th colspan="3">नूतनीकरण येणे बाकी(कार्यालानियाय प्रतिनिधी )</th>
                    <!-- <th colspan="3">January</th>
                    <th colspan="3">February</th>
                    <th colspan="3">March</th> -->
                    <th colspan="6">पोस्ट </th>
                    <th colspan="6">हस्ते </th>
                    <th colspan="1">एकूण </th>
                </tr>
                <tr>
                    <th>जिल्हा</th>
                    <th>तालुका</th>
                    <th>प्रतिनिधी</th>
                    <th>वार्षिक</th>
                    <th>त्रेवार्षिक</th>
                    <th>पंचवार्षिक</th>
                    <th>हिंदूत्व</th>
                    <th>लो. वा</th>
                    <th>एकूण</th>
                    <th>वार्षिक</th>
                    <th>त्रेवार्षिक</th>
                    <th>पंचवार्षिक</th>
                    <th>हिंदूत्व</th>
                    <th>लो. वा</th>
                    <th>एकूण</th>
                    <th>एकूण</th>
                   
                  </tr>   
            </thead>
            <!-- *ngFor="let data of OutstandingInDetailFormBeans; let i = index" -->
            <tbody *ngFor="let data1 of typewiserep; let i = index">

                <tr >
                    <td colspan="3">{{data1.rep_name}}</td>
                    <td colspan="13"></td>
                </tr>
                <tr *ngFor="let data of data1.rep_data; let i = index">
                    <td>{{data.district}}</td>
                   <td>{{data.taluka}}</td>
                   <td>{{data.rep_name}}</td>
                   <td>{{data.varshik_tapal_count}}</td>
                   <td>{{data.trivarshik_tapal_count}}</td>
                   <td>{{data.panvarshik_tapal_count}}</td>
                   <td>{{data.hindutva_tapal_count}}</td>
                   <td>null</td>
                   <td>{{data.post_total}}</td>
                   <td>{{data.varshik_haste_count}}</td>
                   <td>{{data.trivarshik_haste_count}}</td>
                   <td>{{data.panvarshik_haste_count}}</td>
                   <td>{{data.hindutva_haste_count}}</td>
                   <td>null</td>
                   <td>{{data.haste_total}}</td>
                   <td>{{data.grand_total}}</td>
                   
                  </tr>  
                 
            </tbody>

            <tfoot>
                <tr >
                    <!-- <td>{{data.district}}</td> -->
                    <td colspan="3">Total</td>
                    <td>{{pvarshik}}</td>
                    <td>{{ptrevarshik}}</td>
                    <td>{{ppanchvarshik}}</td>
                    <td>{{phindutva}}</td>
                    <td>null</td>
                    <td>{{ptotal}}</td>
                    <td>{{hvarshik}}</td>
                    <td>{{htrevarshik}}</td>
                    <td>{{hpanchvarshik}}</td>
                    <td>{{hhindutva}}</td>
                    <td>null</td>
                    <td>{{htotal}}</td>
                    <td>{{gtotal}}</td>
                   </tr> 
                
            </tfoot>    
                <!-- <tfoot>
                    <tr>
                        {{columnSums | json}}
                      <td *ngFor="let sum of columnSums">{{ sum }}</td>
                    </tr>
                </tfoot> -->
        </table>
      
      
    </div>
    <div style="margin-left: 80%; margin-bottom: 2%;">
        <!-- <button class="noPrint" style="margin-top: 2%;" mat-raised-button color="primary"
            [printStyle]="{table : {'border-collapse':'collapse'}, td : {'border': 'solid 1px'}, th : {'border': 'solid 1px'}}"
            printSectionId="print-section2" ngxPrint>
            print
        </button> -->
        <button class="noPrint" style="margin-top: 2%; margin-left: 2%"
            (click)="exportExcel('Contract_InDetails_Report')" mat-raised-button color="primary">
            Export
        </button>

        <button class="noPrint" style="margin-top: 2%;" mat-raised-button color="primary"
           [useExistingCss]="true"
            printSectionId="print-section" ngxPrint>
            print
        </button>
        
        <button class="noPrint" style="margin-top: 2%" mat-button mat-dialog-close>
            Close
        </button>
    </div>
</div>
<!-- Subscription Remaining Renewal -->
<div *ngIf="reportType == 'RemainingSubscriptionDistrict'"> 
    <div id="print-section" class="container">
      
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th colspan="80" style="text-align: center !important; font-size: 20px;">जिल्हा निहाय </th>
                    <!-- <th colspan="2">Advt. Outstanding Report</th> -->
                    <!-- <th colspan="2">दिनांक</th>
                    <th colspan="2">पृष्ठ क्र</th> -->
                </tr>
                <tr>
                    <th colspan="60" style="text-align: center !important;">हिंदुस्तान प्रकाशन संस्था - विवेक साप्ताहिक - वर्गणी विभाग</th>
                    <th colspan="10" style="text-align: center !important;">Date</th>
                    <th colspan="10" style="text-align: center !important;">From  {{startDate}}</th>
                    <!-- <th colspan="2">To {{endDate}}</th>
                     <th colspan="1">In Details</th>  -->
                </tr>
                <tr>
                    <th colspan="30" style="text-align: center !important;">वर्गणीदार नोंदणी - नवीन व्यवसाय तपशिल </th>
                    <th colspan="10" style="text-align: center !important;">कोकण</th>
                    <th colspan="10" style="text-align: center !important;">Districtwise</th>
                    <th colspan="10" style="text-align: center !important;">Very Briefly</th>
                    <th colspan="10" style="text-align: center !important;">From</th>
                    <th colspan="10" style="text-align: center !important;">To</th> 
                </tr>
                <tr>
                    <th colspan="4">नूतनीकरण येणे बाकी</th>
                    <!-- <th colspan="3">January</th>
                    <th colspan="3">February</th>
                    <th colspan="3">March</th> -->
                    <th colspan="6">April</th>
                    <th colspan="6">May</th>
                    <th colspan="6">June</th>
                    <th colspan="6">July</th>
                    <th colspan="6">August</th>
                    <th colspan="6">September</th>
                    <th colspan="6">October</th>
                    <th colspan="6">November</th>
                    <th colspan="6">December</th>
                    <th colspan="6">January</th>
                    <th colspan="6">February</th>
                    <th colspan="6">March</th>
                    <th colspan="4">Total</th>
                </tr>
                <tr>
                    <th colspan="4"></th>
                    <th colspan="3">पोस्ट</th>
                    <th colspan="3">हस्ते</th>
                    <th colspan="3">पोस्ट</th>
                    <th colspan="3">हस्ते</th>
                    <th colspan="3">पोस्ट</th>
                    <th colspan="3">हस्ते</th>
                    <th colspan="3">पोस्ट</th>
                    <th colspan="3">हस्ते</th>
                    <th colspan="3">पोस्ट</th>
                    <th colspan="3">हस्ते</th>
                    <th colspan="3">पोस्ट</th>
                    <th colspan="3">हस्ते</th>
                    <th colspan="3">पोस्ट</th>
                    <th colspan="3">हस्ते</th>
                    <th colspan="3">पोस्ट</th>
                    <th colspan="3">हस्ते</th>
                    <th colspan="3">पोस्ट</th>
                    <th colspan="3">हस्ते</th>
                    <th colspan="3">पोस्ट</th>
                    <th colspan="3">हस्ते</th>
                    <th colspan="3">पोस्ट</th>
                    <th colspan="3">हस्ते</th>
                    <th colspan="3">पोस्ट</th>
                    <th colspan="3">हस्ते</th>
                    <th colspan="4"></th>
                </tr>   
                <tr>
                    <th colspan="4"></th>
                    <th>जमा</th>
                    <th>बाकी</th>
                    <th>एकूण</th>
                    <th>जमा</th>
                    <th>बाकी</th>
                    <th>एकूण</th>
                    <th>जमा</th>
                    <th>बाकी</th>
                    <th>एकूण</th>
                    <th>जमा</th>
                    <th>बाकी</th>
                    <th>एकूण</th>
                    <th>जमा</th>
                    <th>बाकी</th>
                    <th>एकूण</th>
                    <th>जमा</th>
                    <th>बाकी</th>
                    <th>एकूण</th>
                    <th>जमा</th>
                    <th>बाकी</th>
                    <th>एकूण</th>
                    <th>जमा</th>
                    <th>बाकी</th>
                    <th>एकूण</th>
                    <th>जमा</th>
                    <th>बाकी</th>
                    <th>एकूण</th>
                    <th>जमा</th>
                    <th>बाकी</th>
                    <th>एकूण</th>
                    <th>जमा</th>
                    <th>बाकी</th>
                    <th>एकूण</th>
                    <th>जमा</th>
                    <th>बाकी</th>
                    <th>एकूण</th>
                    <th>जमा</th>
                    <th>बाकी</th>
                    <th>एकूण</th>
                    <th>जमा</th>
                    <th>बाकी</th>
                    <th>एकूण</th>
                    <th>जमा</th>
                    <th>बाकी</th>
                    <th>एकूण</th>
                    <th>जमा</th>
                    <th>बाकी</th>
                    <th>एकूण</th>
                    <th>जमा</th>
                    <th>बाकी</th>
                    <th>एकूण</th>
                    <th>जमा</th>
                    <th>बाकी</th>
                    <th>एकूण</th>
                    <th>जमा</th>
                    <th>बाकी</th>
                    <th>एकूण</th>
                    <th>जमा</th>
                    <th>बाकी</th>
                    <th>एकूण</th>
                    <th>जमा</th>
                    <th>बाकी</th>
                    <th>एकूण</th>
                    <th>जमा</th>
                    <th>बाकी</th>
                    <th>एकूण</th>
                    <th>जमा</th>
                    <th>बाकी</th>
                    <th>एकूण</th>
                    <th>जमा</th>
                    <th>बाकी</th>
                    <th>एकूण</th>
                    <th colspan="4"></th>
                </tr>
            </thead>
            <!-- *ngFor="let data of OutstandingInDetailFormBeans; let i = index" -->
            <tbody>

                <tr *ngFor="let data of SubRemDistrict; let i = index">
                    <td colspan="4">{{data.district}}</td>
                    <td>{{data.tapal_Renew_4}}</td>
                        <td>{{data.tapal_New_4}}</td>
                        <td>{{data.rem_april_post_total}}</td>
                        <td>{{data.haste_Renew_4}}</td>
                        <td>{{data.haste_New_4}}</td>
                        <td>{{data.rem_april_haste_total}}</td>
    
                        <td>{{data.tapal_Renew_5}}</td>
                        <td>{{data.tapal_New_5}}</td>
                        <td>{{data.rem_may_post_total}}</td>
                        <td>{{data.haste_Renew_5}}</td>
                        <td>{{data.haste_New_5}}</td>
                        <td>{{data.rem_may_haste_total}}</td>
    
                        <td>{{data.tapal_Renew_6}}</td>
                        <td>{{data.tapal_New_6}}</td>
                        <td>{{data.rem_jun_post_total}}</td>
                        <td>{{data.haste_Renew_6}}</td>
                        <td>{{data.haste_New_6}}</td>
                        <td>{{data.rem_jun_haste_total}}</td>
    
                        <td>{{data.tapal_Renew_7}}</td>
                        <td>{{data.tapal_New_7}}</td>
                        <td>{{data.rem_jul_post_total}}</td>
                        <td>{{data.haste_Renew_7}}</td>
                        <td>{{data.haste_New_7}}</td>
                        <td>{{data.rem_jul_haste_total}}</td>
    
                        <td>{{data.tapal_Renew_8}}</td>
                        <td>{{data.tapal_New_8}}</td>
                        <td>{{data.rem_aug_post_total}}</td>
                        <td>{{data.haste_Renew_8}}</td>
                        <td>{{data.haste_New_8}}</td>
                        <td>{{data.rem_aug_haste_total}}</td>
    
                        <td>{{data.tapal_Renew_9}}</td>
                        <td>{{data.tapal_New_9}}</td>
                        <td>{{data.rem_sep_post_total}}</td>
                        <td>{{data.haste_Renew_9}}</td>
                        <td>{{data.haste_New_9}}</td>
                        <td>{{data.rem_sep_haste_total}}</td>
    
                        <td>{{data.tapal_Renew_10}}</td>
                        <td>{{data.tapal_New_10}}</td>
                        <td>{{data.rem_oct_post_total}}</td>
                        <td>{{data.haste_Renew_10}}</td>
                        <td>{{data.haste_New_10}}</td>
                        <td>{{data.rem_oct_haste_total}}</td>
    
                        <td>{{data.tapal_Renew_11}}</td>
                        <td>{{data.tapal_New_11}}</td>
                        <td>{{data.rem_nov_post_total}}</td>
                        <td>{{data.haste_Renew_11}}</td>
                        <td>{{data.haste_New_11}}</td>
                        <td>{{data.rem_nov_haste_total}}</td>
    
                        <td>{{data.tapal_Renew_12}}</td>
                        <td>{{data.tapal_New_12}}</td>
                        <td>{{data.rem_dec_post_total}}</td>
                        <td>{{data.haste_Renew_12}}</td>
                        <td>{{data.haste_New_12}}</td>
                        <td>{{data.rem_dec_haste_total}}</td>
    
                        <td>{{data.tapal_Renew_1}}</td>
                        <td>{{data.tapal_New_1}}</td>
                        <td>{{data.rem_jan_post_total}}</td>
                        <td>{{data.haste_Renew_1}}</td>
                        <td>{{data.haste_New_1}}</td>
                        <td>{{data.rem_jan_haste_total}}</td>
    
                        <td>{{data.tapal_Renew_2}}</td>
                        <td>{{data.tapal_New_2}}</td>
                        <td>{{data.rem_feb_post_total}}</td>
                        <td>{{data.haste_Renew_2}}</td>
                        <td>{{data.haste_New_2}}</td>
                        <td>{{data.rem_feb_haste_total}}</td>
    
                        <td>{{data.tapal_Renew_3}}</td>
                        <td>{{data.tapal_New_3}}</td>
                        <td>{{data.rem_march_post_total}}</td>
                        <td>{{data.haste_Renew_3}}</td>
                        <td>{{data.haste_New_3}}</td>
                        <td>{{data.rem_march_haste_total}}</td>


                    <td colspan="4">{{grandremtotal}}</td>
                   
                </tr>
                 
            </tbody>

            <tfoot>
                <tr>
                    <td colspan="80"></td>
                </tr>
                </tfoot>  
        </table>
      
      
    </div>
    <div style="margin-left: 80%; margin-bottom: 2%;">
        <!-- <button class="noPrint" style="margin-top: 2%;" mat-raised-button color="primary"
            [printStyle]="{table : {'border-collapse':'collapse'}, td : {'border': 'solid 1px'}, th : {'border': 'solid 1px'}}"
            printSectionId="print-section2" ngxPrint>
            print
        </button> -->
        <button class="noPrint" style="margin-top: 2%; margin-left: 2%"
            (click)="exportExcel('Contract_InDetails_Report')" mat-raised-button color="primary">
            Export
        </button>

        <button class="noPrint" style="margin-top: 2%;" mat-raised-button color="primary"
           [useExistingCss]="true"
            printSectionId="print-section" ngxPrint>
            print
        </button>
        
        <button class="noPrint" style="margin-top: 2%" mat-button mat-dialog-close>
            Close
        </button>
    </div>
</div>

<div *ngIf="reportType == 'RemainingSubscriptionTaluka'"> 
    <div id="print-section" class="container">
      
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th colspan="80" style="text-align: center !important; font-size: 20px;">तालुका निहाय</th>
                    <!-- <th colspan="2">Advt. Outstanding Report</th> -->
                    <!-- <th colspan="2">दिनांक</th>
                    <th colspan="2">पृष्ठ क्र</th> -->
                </tr>
                <tr>
                    <th colspan="60" style="text-align: center !important;">हिंदुस्तान प्रकाशन संस्था - विवेक साप्ताहिक - वर्गणी विभाग</th>
                    <th colspan="10" style="text-align: center !important;">Date</th>
                    <th colspan="10" style="text-align: center !important;">From  {{startDate}}</th>
                    <!-- <th colspan="2">To {{endDate}}</th>
                     <th colspan="1">In Details</th>  -->
                </tr>
                <tr>
                    <th colspan="30" style="text-align: center !important;">वर्गणीदार नोंदणी - नवीन व्यवसाय तपशिल </th>
                    <th colspan="10" style="text-align: center !important;">कोकण</th>
                    <th colspan="10" style="text-align: center !important;">Districtwise</th>
                    <th colspan="10" style="text-align: center !important;">Very Briefly</th>
                    <th colspan="10" style="text-align: center !important;">From</th>
                    <th colspan="10" style="text-align: center !important;">To</th> 
                </tr>
                <tr>
                    <th colspan="4">नूतनीकरण येणे बाकी</th>
                    <!-- <th colspan="3">January</th>
                    <th colspan="3">February</th>
                    <th colspan="3">March</th> -->
                    <ng-container *ngFor="let item of months"> <th colspan="6">{{item}}</th></ng-container>
                    <!-- <th colspan="6">April</th>
                    <th colspan="6">May</th>
                    <th colspan="6">June</th>
                    <th colspan="6">July</th>
                    <th colspan="6">August</th>
                    <th colspan="6">September</th>
                    <th colspan="6">October</th>
                    <th colspan="6">November</th>
                    <th colspan="6">December</th>
                    <th colspan="6">January</th>
                    <th colspan="6">February</th>
                    <th colspan="6">March</th> -->
                    <th colspan="4">Total</th>
                </tr>
                <tr>
                    <th colspan="2">zilha</th>
                    <th colspan="2">taluka</th>

                    <th colspan="3">पोस्ट</th>
                    <th colspan="3">हस्ते</th>
                    <th colspan="3">पोस्ट</th>
                    <th colspan="3">हस्ते</th>
                    <th colspan="3">पोस्ट</th>
                    <th colspan="3">हस्ते</th>
                    <th colspan="3">पोस्ट</th>
                    <th colspan="3">हस्ते</th>
                    <th colspan="3">पोस्ट</th>
                    <th colspan="3">हस्ते</th>
                    <th colspan="3">पोस्ट</th>
                    <th colspan="3">हस्ते</th>
                    <th colspan="3">पोस्ट</th>
                    <th colspan="3">हस्ते</th>
                    <th colspan="3">पोस्ट</th>
                    <th colspan="3">हस्ते</th>
                    <th colspan="3">पोस्ट</th>
                    <th colspan="3">हस्ते</th>
                    <th colspan="3">पोस्ट</th>
                    <th colspan="3">हस्ते</th>
                    <th colspan="3">पोस्ट</th>
                    <th colspan="3">हस्ते</th>
                    <th colspan="3">पोस्ट</th>
                    <th colspan="3">हस्ते</th>
                    <th colspan="4"></th>
                </tr>   
                <tr>
                    <th colspan="4"></th>
                    <th>जमा</th>
                    <th>बाकी</th>
                    <th>एकूण</th>
                    <th>जमा</th>
                    <th>बाकी</th>
                    <th>एकूण</th>
                    <th>जमा</th>
                    <th>बाकी</th>
                    <th>एकूण</th>
                    <th>जमा</th>
                    <th>बाकी</th>
                    <th>एकूण</th>
                    <th>जमा</th>
                    <th>बाकी</th>
                    <th>एकूण</th>
                    <th>जमा</th>
                    <th>बाकी</th>
                    <th>एकूण</th>
                    <th>जमा</th>
                    <th>बाकी</th>
                    <th>एकूण</th>
                    <th>जमा</th>
                    <th>बाकी</th>
                    <th>एकूण</th>
                    <th>जमा</th>
                    <th>बाकी</th>
                    <th>एकूण</th>
                    <th>जमा</th>
                    <th>बाकी</th>
                    <th>एकूण</th>
                    <th>जमा</th>
                    <th>बाकी</th>
                    <th>एकूण</th>
                    <th>जमा</th>
                    <th>बाकी</th>
                    <th>एकूण</th>
                    <th>जमा</th>
                    <th>बाकी</th>
                    <th>एकूण</th>
                    <th>जमा</th>
                    <th>बाकी</th>
                    <th>एकूण</th>
                    <th>जमा</th>
                    <th>बाकी</th>
                    <th>एकूण</th>
                    <th>जमा</th>
                    <th>बाकी</th>
                    <th>एकूण</th>
                    <th>जमा</th>
                    <th>बाकी</th>
                    <th>एकूण</th>
                    <th>जमा</th>
                    <th>बाकी</th>
                    <th>एकूण</th>
                    <th>जमा</th>
                    <th>बाकी</th>
                    <th>एकूण</th>
                    <th>जमा</th>
                    <th>बाकी</th>
                    <th>एकूण</th>
                    <th>जमा</th>
                    <th>बाकी</th>
                    <th>एकूण</th>
                    <th>जमा</th>
                    <th>बाकी</th>
                    <th>एकूण</th>
                    <th>जमा</th>
                    <th>बाकी</th>
                    <th>एकूण</th>
                    <th>जमा</th>
                    <th>बाकी</th>
                    <th>एकूण</th>
                    <th colspan="4"></th>
                </tr>
            </thead>
            <!-- *ngFor="let data of OutstandingInDetailFormBeans; let i = index" -->
            <tbody>

                <ng-container  *ngFor="let data1 of SubRemTaluka; let i = index">

                    <tr *ngFor="let data of data1.rep_info; let i = index">
                        <td colspan="2">{{data.district}}</td>
                        <td colspan="2">{{data.taluka}}</td>
                      
                        <ng-container *ngIf="data.old_month == 1">
                          <td>{{data.old_month == 1 ? data.haste_count : ''}}</td>
                          <td>{{data.old_month == 1 ? data.new_haste_count : ''}}</td>
                          <td>{{data.old_month == 1 ? data.rem_april_haste_total : ''}}</td>
                          <td>{{data.old_month == 1 ? data.tapal_count : ''}}</td>
                          <td>{{data.old_month == 1 ? data.new_tapal_count : ''}}</td>
                          <td>{{data.old_month == 1 ? data.rem_april_post_total : ''}}</td>
                        </ng-container>
                      
                        <ng-container *ngIf="data.old_month == 2">
                          <td>{{data.old_month == 2 ? data.haste_count : ''}}</td>
                          <td>{{data.old_month == 2 ? data.new_haste_count : ''}}</td>
                          <td>{{data.old_month == 2 ? data.rem_april_haste_total : ''}}</td>
                          <td>{{data.old_month == 2 ? data.tapal_count : ''}}</td>
                          <td>{{data.old_month == 2 ? data.new_tapal_count : ''}}</td>
                          <td>{{data.old_month == 2 ? data.rem_april_post_total : ''}}</td>
                        </ng-container>
                      
                        <ng-container *ngIf="data.old_month == 3">
                          <td>{{data.old_month == 3 ? data.haste_count : ''}}</td>
                          <td>{{data.old_month == 3 ? data.new_haste_count : ''}}</td>
                          <td>{{data.old_month == 3 ? data.rem_april_haste_total : ''}}</td>
                          <td>{{data.old_month == 3 ? data.tapal_count : ''}}</td>
                          <td>{{data.old_month == 3 ? data.new_tapal_count : ''}}</td>
                          <td>{{data.old_month == 3 ? data.rem_april_post_total : ''}}</td>
                        </ng-container>
                      
                        <ng-container *ngIf="data.old_month == 4">
                          <td>{{data.old_month == 4 ? data.haste_count : ''}}</td>
                          <td>{{data.old_month == 4 ? data.new_haste_count : ''}}</td>
                          <td>{{data.old_month == 4 ? data.rem_april_haste_total : ''}}</td>
                          <td>{{data.old_month == 4 ? data.tapal_count : ''}}</td>
                          <td>{{data.old_month == 4 ? data.new_tapal_count : ''}}</td>
                          <td>{{data.old_month == 4 ? data.rem_april_post_total : ''}}</td>
                        </ng-container>
                      
                        <ng-container *ngIf="data.old_month == 5">
                          <td>{{data.old_month == 5 ? data.haste_count : ''}}</td>
                          <td>{{data.old_month == 5 ? data.new_haste_count : ''}}</td>
                          <td>{{data.old_month == 5 ? data.rem_april_haste_total : ''}}</td>
                          <td>{{data.old_month == 5 ? data.tapal_count : ''}}</td>
                          <td>{{data.old_month == 5 ? data.new_tapal_count : ''}}</td>
                          <td>{{data.old_month == 5 ? data.rem_april_post_total : ''}}</td>
                        </ng-container>
                      
                        <ng-container *ngIf="data.old_month == 6">
                          <td>{{data.old_month == 6 ? data.haste_count : ''}}</td>
                          <td>{{data.old_month == 6 ? data.new_haste_count : ''}}</td>
                          <td>{{data.old_month == 6 ? data.rem_april_haste_total : ''}}</td>
                          <td>{{data.old_month == 6 ? data.tapal_count : ''}}</td>
                          <td>{{data.old_month == 6 ? data.new_tapal_count : ''}}</td>
                          <td>{{data.old_month == 6 ? data.rem_april_post_total : ''}}</td>
                        </ng-container>
                      
                        <ng-container *ngIf="data.old_month == 7">
                          <td>{{data.old_month == 7 ? data.haste_count : ''}}</td>
                          <td>{{data.old_month == 7 ? data.new_haste_count : ''}}</td>
                          <td>{{data.old_month == 7 ? data.rem_april_haste_total : ''}}</td>
                          <td>{{data.old_month == 7 ? data.tapal_count : ''}}</td>
                          <td>{{data.old_month == 7 ? data.new_tapal_count : ''}}</td>
                          <td>{{data.old_month == 7 ? data.rem_april_post_total : ''}}</td>
                        </ng-container>
                      
                        <ng-container *ngIf="data.old_month == 8">
                          <td>{{data.old_month == 8 ? data.haste_count : ''}}</td>
                          <td>{{data.old_month == 8 ? data.new_haste_count : ''}}</td>
                          <td>{{data.old_month == 8 ? data.rem_april_haste_total : ''}}</td>
                          <td>{{data.old_month == 8 ? data.tapal_count : ''}}</td>
                          <td>{{data.old_month == 8 ? data.new_tapal_count : ''}}</td>
                          <td>{{data.old_month == 8 ? data.rem_april_post_total : ''}}</td>
                        </ng-container>
                      
                        <ng-container *ngIf="data.old_month == 9">
                          <td>{{data.old_month == 9 ? data.haste_count : ''}}</td>
                          <td>{{data.old_month == 9 ? data.new_haste_count : ''}}</td>
                          <td>{{data.old_month == 9 ? data.rem_april_haste_total : ''}}</td>
                          <td>{{data.old_month == 9 ? data.tapal_count : ''}}</td>
                          <td>{{data.old_month == 9 ? data.new_tapal_count : ''}}</td>
                          <td>{{data.old_month == 9 ? data.rem_april_post_total : ''}}</td>
                        </ng-container>
                      
                        <ng-container *ngIf="data.old_month == 10">
                          <td>{{data.old_month == 10 ? data.haste_count : ''}}</td>
                          <td>{{data.old_month == 10 ? data.new_haste_count : ''}}</td>
                          <td>{{data.old_month == 10 ? data.rem_april_haste_total : ''}}</td>
                          <td>{{data.old_month == 10 ? data.tapal_count : ''}}</td>
                          <td>{{data.old_month == 10 ? data.new_tapal_count : ''}}</td>
                          <td>{{data.old_month == 10 ? data.rem_april_post_total : ''}}</td>
                        </ng-container>
                      
                        <ng-container *ngIf="data.old_month == 11">
                          <td>{{data.old_month == 11 ? data.haste_count : ''}}</td>
                          <td>{{data.old_month == 11 ? data.new_haste_count : ''}}</td>
                          <td>{{data.old_month == 11 ? data.rem_april_haste_total : ''}}</td>
                          <td>{{data.old_month == 11 ? data.tapal_count : ''}}</td>
                          <td>{{data.old_month == 11 ? data.new_tapal_count : ''}}</td>
                          <td>{{data.old_month == 11 ? data.rem_april_post_total : ''}}</td>
                        </ng-container>
                      
                        <ng-container *ngIf="data.old_month == 12">
                          <td>{{data.old_month == 12 ? data.haste_count : ''}}</td>
                          <td>{{data.old_month == 12 ? data.new_haste_count : ''}}</td>
                          <td>{{data.old_month == 12 ? data.rem_april_haste_total : ''}}</td>
                          <td>{{data.old_month == 12 ? data.tapal_count : ''}}</td>
                          <td>{{data.old_month == 12 ? data.new_tapal_count : ''}}</td>
                          <td>{{data.old_month == 12 ? data.rem_april_post_total : ''}}</td>
                        </ng-container>
                      
                        <!-- <td colspan="4">{{grandremtotal}}</td> -->
                      </tr>
                      

            </ng-container>
                 
            </tbody>

            <tfoot>
                <tr>
                    <td colspan="80"></td>
                </tr>
                </tfoot>  
        </table>
      
      
    </div>
    <div style="margin-left: 80%; margin-bottom: 2%;">
        <!-- <button class="noPrint" style="margin-top: 2%;" mat-raised-button color="primary"
            [printStyle]="{table : {'border-collapse':'collapse'}, td : {'border': 'solid 1px'}, th : {'border': 'solid 1px'}}"
            printSectionId="print-section2" ngxPrint>
            print
        </button> -->
        <button class="noPrint" style="margin-top: 2%; margin-left: 2%"
            (click)="exportExcel('Contract_InDetails_Report')" mat-raised-button color="primary">
            Export
        </button>

        <button class="noPrint" style="margin-top: 2%;" mat-raised-button color="primary"
           [useExistingCss]="true"
            printSectionId="print-section" ngxPrint>
            print
        </button>
        
        <button class="noPrint" style="margin-top: 2%" mat-button mat-dialog-close>
            Close
        </button>
    </div>
</div>

<div *ngIf="reportType == 'RemainingSubscriptionOffReprensantive'"> 
    <div id="print-section" class="container">
      
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th colspan="80" style="text-align: center !important; font-size: 20px;">कार्यलय प्रतिनिधी</th>
                    <!-- <th colspan="2">Advt. Outstanding Report</th> -->
                    <!-- <th colspan="2">दिनांक</th>
                    <th colspan="2">पृष्ठ क्र</th> -->
                </tr>
                <tr>
                    <th colspan="60" style="text-align: center !important;">हिंदुस्तान प्रकाशन संस्था - विवेक साप्ताहिक - वर्गणी विभाग</th>
                    <th colspan="10" style="text-align: center !important;">Date</th>
                    <th colspan="10" style="text-align: center !important;">From  {{startDate}}</th>
                    <!-- <th colspan="2">To {{endDate}}</th>
                     <th colspan="1">In Details</th>  -->
                </tr>
                <tr>
                    <th colspan="30" style="text-align: center !important;">वर्गणीदार नोंदणी - नवीन व्यवसाय तपशिल </th>
                    <th colspan="10" style="text-align: center !important;">कोकण</th>
                    <th colspan="10" style="text-align: center !important;">Districtwise</th>
                    <th colspan="10" style="text-align: center !important;">Very Briefly</th>
                    <th colspan="10" style="text-align: center !important;">From</th>
                    <th colspan="10" style="text-align: center !important;">To</th> 
                </tr>
                <tr>
                    <th colspan="4">नूतनीकरण येणे बाकी</th>
                    <!-- <th colspan="3">January</th>
                    <th colspan="3">February</th>
                    <th colspan="3">March</th> -->
                    <ng-container *ngFor="let item of months">
                         <th colspan="6" style=" width: 50px !important; ">{{item}}</th>
                    </ng-container>
                  
                    <th colspan="4">Total</th>
                </tr>

                <tr> 
                    <th colspan="4"> </th>
                    <ng-container *ngFor="let item1 of months"> 
                       
                         <th colspan="3">हस्ते</th>
                        <th colspan="3">पोस्ट</th>
                       
                    </ng-container>
                </tr>
                
               <tr>
                <th colspan="4"> </th>
                 <ng-container *ngFor="let item2 of months"> 
                   
                        <th>जमा</th>
                        <th>बाकी</th>  
                        <th>एकूण</th>  
                        <th>जमा</th>
                        <th>बाकी</th>  
                        <th>एकूण</th>  
                   
                </ng-container>
                <th colspan="4"></th>
            </tr> 
            </thead>
            <!-- *ngFor="let data of OutstandingInDetailFormBeans; let i = index" -->
            <tbody>
                <ng-container *ngFor="let data2 of SubRemOffRep; let i = index">

                    <tr>
                        <td colspan="4" style="font-weight: bold;">{{data2.office_representative}}</td>
                        <td colspan="76"></td>
                    </tr>

                    <ng-container *ngFor="let data1 of data2.representatives; let i = index">
                        <tr>
                            <td colspan="4">{{data1.rep_name}}</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                       
                    <tr *ngFor="let data of data1.rep_info; let i = index">
                        <td colspan="2">{{data.district}}</td>
                        <td colspan="2">{{data.taluka}}</td>
    
                        <ng-container *ngIf="data.old_month == 1">
                            <td>{{data.old_month == 1 ? data.haste_count : ''}}</td>
                            <td>{{data.old_month == 1 ? data.new_haste_count : ''}}</td>
                            <td>{{data.old_month == 1 ? data.haste_total_ROR : ''}}</td>
                            <td>{{data.old_month == 1 ? data.tapal_count : ''}}</td>
                            <td>{{data.old_month == 1 ? data.new_tapal_count : ''}}</td>
                            <td>{{data.old_month == 1 ? data.post_total_ROR : ''}}</td>
                          </ng-container>
                        
                          <ng-container *ngIf="data.old_month == 2">
                            <td>{{data.old_month == 2 ? data.haste_count : ''}}</td>
                            <td>{{data.old_month == 2 ? data.new_haste_count : ''}}</td>
                            <td>{{data.old_month == 2 ? data.rem_april_haste_total : ''}}</td>
                            <td>{{data.old_month == 2 ? data.tapal_count : ''}}</td>
                            <td>{{data.old_month == 2 ? data.new_tapal_count : ''}}</td>
                            <td>{{data.old_month == 2 ? data.rem_april_post_total : ''}}</td>
                          </ng-container>
                        
                          <ng-container *ngIf="data.old_month == 3">
                            <td>{{data.old_month == 3 ? data.haste_count : ''}}</td>
                            <td>{{data.old_month == 3 ? data.new_haste_count : ''}}</td>
                            <td>{{data.old_month == 3 ? data.rem_april_haste_total : ''}}</td>
                            <td>{{data.old_month == 3 ? data.tapal_count : ''}}</td>
                            <td>{{data.old_month == 3 ? data.new_tapal_count : ''}}</td>
                            <td>{{data.old_month == 3 ? data.rem_april_post_total : ''}}</td>
                          </ng-container>
                        
                          <ng-container *ngIf="data.old_month == 4">
                            <td>{{data.old_month == 4 ? data.haste_count : ''}}</td>
                            <td>{{data.old_month == 4 ? data.new_haste_count : ''}}</td>
                            <td>{{data.old_month == 4 ? data.haste_total_ROR : ''}}</td>
                            <td>{{data.old_month == 4 ? data.tapal_count : ''}}</td>
                            <td>{{data.old_month == 4 ? data.new_tapal_count : ''}}</td>
                            <td>{{data.old_month == 4 ? data.post_total_ROR : ''}}</td>
                          </ng-container>
                        
                          <ng-container *ngIf="data.old_month == 5">
                            <td>{{data.old_month == 5 ? data.haste_count : ''}}</td>
                            <td>{{data.old_month == 5 ? data.new_haste_count : ''}}</td>
                            <td>{{data.old_month == 5 ? data.rem_april_haste_total : ''}}</td>
                            <td>{{data.old_month == 5 ? data.tapal_count : ''}}</td>
                            <td>{{data.old_month == 5 ? data.new_tapal_count : ''}}</td>
                            <td>{{data.old_month == 5 ? data.rem_april_post_total : ''}}</td>
                          </ng-container>
                        
                          <ng-container *ngIf="data.old_month == 6">
                            <td>{{data.old_month == 6 ? data.haste_count : ''}}</td>
                            <td>{{data.old_month == 6 ? data.new_haste_count : ''}}</td>
                            <td>{{data.old_month == 6 ? data.rem_april_haste_total : ''}}</td>
                            <td>{{data.old_month == 6 ? data.tapal_count : ''}}</td>
                            <td>{{data.old_month == 6 ? data.new_tapal_count : ''}}</td>
                            <td>{{data.old_month == 6 ? data.rem_april_post_total : ''}}</td>
                          </ng-container>
                        
                          <ng-container *ngIf="data.old_month == 7">
                            <td>{{data.old_month == 7 ? data.haste_count : ''}}</td>
                            <td>{{data.old_month == 7 ? data.new_haste_count : ''}}</td>
                            <td>{{data.old_month == 7 ? data.rem_april_haste_total : ''}}</td>
                            <td>{{data.old_month == 7 ? data.tapal_count : ''}}</td>
                            <td>{{data.old_month == 7 ? data.new_tapal_count : ''}}</td>
                            <td>{{data.old_month == 7 ? data.rem_april_post_total : ''}}</td>
                          </ng-container>
                        
                          <ng-container *ngIf="data.old_month == 8">
                            <td>{{data.old_month == 8 ? data.haste_count : ''}}</td>
                            <td>{{data.old_month == 8 ? data.new_haste_count : ''}}</td>
                            <td>{{data.old_month == 8 ? data.rem_april_haste_total : ''}}</td>
                            <td>{{data.old_month == 8 ? data.tapal_count : ''}}</td>
                            <td>{{data.old_month == 8 ? data.new_tapal_count : ''}}</td>
                            <td>{{data.old_month == 8 ? data.rem_april_post_total : ''}}</td>
                          </ng-container>
                        
                          <ng-container *ngIf="data.old_month == 9">
                            <td>{{data.old_month == 9 ? data.haste_count : ''}}</td>
                            <td>{{data.old_month == 9 ? data.new_haste_count : ''}}</td>
                            <td>{{data.old_month == 9 ? data.rem_april_haste_total : ''}}</td>
                            <td>{{data.old_month == 9 ? data.tapal_count : ''}}</td>
                            <td>{{data.old_month == 9 ? data.new_tapal_count : ''}}</td>
                            <td>{{data.old_month == 9 ? data.rem_april_post_total : ''}}</td>
                          </ng-container>
                        
                          <ng-container *ngIf="data.old_month == 10">
                            <td>{{data.old_month == 10 ? data.haste_count : ''}}</td>
                            <td>{{data.old_month == 10 ? data.new_haste_count : ''}}</td>
                            <td>{{data.old_month == 10 ? data.rem_april_haste_total : ''}}</td>
                            <td>{{data.old_month == 10 ? data.tapal_count : ''}}</td>
                            <td>{{data.old_month == 10 ? data.new_tapal_count : ''}}</td>
                            <td>{{data.old_month == 10 ? data.rem_april_post_total : ''}}</td>
                          </ng-container>
                        
                          <ng-container *ngIf="data.old_month == 11">
                            <td>{{data.old_month == 11 ? data.haste_count : ''}}</td>
                            <td>{{data.old_month == 11 ? data.new_haste_count : ''}}</td>
                            <td>{{data.old_month == 11 ? data.rem_april_haste_total : ''}}</td>
                            <td>{{data.old_month == 11 ? data.tapal_count : ''}}</td>
                            <td>{{data.old_month == 11 ? data.new_tapal_count : ''}}</td>
                            <td>{{data.old_month == 11 ? data.rem_april_post_total : ''}}</td>
                          </ng-container>
                        
                          <ng-container *ngIf="data.old_month == 12">
                            <td>{{data.old_month == 12 ? data.haste_count : ''}}</td>
                            <td>{{data.old_month == 12 ? data.new_haste_count : ''}}</td>
                            <td>{{data.old_month == 12 ? data.rem_april_haste_total : ''}}</td>
                            <td>{{data.old_month == 12 ? data.tapal_count : ''}}</td>
                            <td>{{data.old_month == 12 ? data.new_tapal_count : ''}}</td>
                            <td>{{data.old_month == 12 ? data.rem_april_post_total : ''}}</td>
                          </ng-container>
    
    
                        <!-- <td colspan="4">{{grandremtotal}}</td> -->
                       
                    </tr>
                   

                </ng-container>
                </ng-container>

             
                 
            </tbody>

            <tfoot>
                <tr>
                    <td colspan="80"></td>
                </tr>
                </tfoot>  
        </table>
      
      
    </div>
    <div style="margin-left: 80%; margin-bottom: 2%;">
        <!-- <button class="noPrint" style="margin-top: 2%;" mat-raised-button color="primary"
            [printStyle]="{table : {'border-collapse':'collapse'}, td : {'border': 'solid 1px'}, th : {'border': 'solid 1px'}}"
            printSectionId="print-section2" ngxPrint>
            print
        </button> -->
        <button class="noPrint" style="margin-top: 2%; margin-left: 2%"
            (click)="exportExcel('Contract_InDetails_Report')" mat-raised-button color="primary">
            Export
        </button>

        <button class="noPrint" style="margin-top: 2%;" mat-raised-button color="primary"
           [useExistingCss]="true"
            printSectionId="print-section" ngxPrint>
            print
        </button>
        
        <button class="noPrint" style="margin-top: 2%" mat-button mat-dialog-close>
            Close
        </button>
    </div>
</div>

<div *ngIf="reportType == 'RemainingSubscriptionReprensantive'"> 
    <div id="print-section" class="container">
      
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th colspan="80" style="text-align: center !important; font-size: 20px;">जिल्हा निहाय </th>
                    <!-- <th colspan="2">Advt. Outstanding Report</th> -->
                    <!-- <th colspan="2">दिनांक</th>
                    <th colspan="2">पृष्ठ क्र</th> -->
                </tr>
                <tr>
                    <th colspan="60" style="text-align: center !important;">हिंदुस्तान प्रकाशन संस्था - विवेक साप्ताहिक - वर्गणी विभाग</th>
                    <th colspan="10" style="text-align: center !important;">Date</th>
                    <th colspan="10" style="text-align: center !important;">From  {{startDate}}</th>
                    <!-- <th colspan="2">To {{endDate}}</th>
                     <th colspan="1">In Details</th>  -->
                </tr>
                <tr>
                    <th colspan="30" style="text-align: center !important;">वर्गणीदार नोंदणी - नवीन व्यवसाय तपशिल </th>
                    <th colspan="10" style="text-align: center !important;">कोकण</th>
                    <th colspan="10" style="text-align: center !important;">Districtwise</th>
                    <th colspan="10" style="text-align: center !important;">Very Briefly</th>
                    <th colspan="10" style="text-align: center !important;">From</th>
                    <th colspan="10" style="text-align: center !important;">To</th> 
                </tr>
                <tr>
                    <th colspan="4">नूतनीकरण येणे बाकी</th>
                    <!-- <th colspan="3">January</th>
                    <th colspan="3">February</th>
                    <th colspan="3">March</th> -->
                    <th colspan="6">April</th>
                    <th colspan="6">May</th>
                    <th colspan="6">June</th>
                    <th colspan="6">July</th>
                    <th colspan="6">August</th>
                    <th colspan="6">September</th>
                    <th colspan="6">October</th>
                    <th colspan="6">November</th>
                    <th colspan="6">December</th>
                    <th colspan="6">January</th>
                    <th colspan="6">February</th>
                    <th colspan="6">March</th>
                    <th colspan="4">Total</th>
                </tr>
                <tr>
                    <th colspan="2">zilha</th>
                    <th colspan="2">taluka</th>
                    <!-- <th colspan="2">Reprensantive</th> -->


                    <th colspan="3">पोस्ट</th>
                    <th colspan="3">हस्ते</th>
                    <th colspan="3">पोस्ट</th>
                    <th colspan="3">हस्ते</th>
                    <th colspan="3">पोस्ट</th>
                    <th colspan="3">हस्ते</th>
                    <th colspan="3">पोस्ट</th>
                    <th colspan="3">हस्ते</th>
                    <th colspan="3">पोस्ट</th>
                    <th colspan="3">हस्ते</th>
                    <th colspan="3">पोस्ट</th>
                    <th colspan="3">हस्ते</th>
                    <th colspan="3">पोस्ट</th>
                    <th colspan="3">हस्ते</th>
                    <th colspan="3">पोस्ट</th>
                    <th colspan="3">हस्ते</th>
                    <th colspan="3">पोस्ट</th>
                    <th colspan="3">हस्ते</th>
                    <th colspan="3">पोस्ट</th>
                    <th colspan="3">हस्ते</th>
                    <th colspan="3">पोस्ट</th>
                    <th colspan="3">हस्ते</th>
                    <th colspan="3">पोस्ट</th>
                    <th colspan="3">हस्ते</th>
                    <th colspan="4"></th>
                </tr>   

                <!-- <tr>
                    <td colspan="4"></td>
                    <td colspan="76"></td>
                </tr> -->
                <tr>
                    <th colspan="4"></th>
                    <th>जमा</th>
                    <th>बाकी</th>
                    <th>एकूण</th>
                    <th>जमा</th>
                    <th>बाकी</th>
                    <th>एकूण</th>
                    <th>जमा</th>
                    <th>बाकी</th>
                    <th>एकूण</th>
                    <th>जमा</th>
                    <th>बाकी</th>
                    <th>एकूण</th>
                    <th>जमा</th>
                    <th>बाकी</th>
                    <th>एकूण</th>
                    <th>जमा</th>
                    <th>बाकी</th>
                    <th>एकूण</th>
                    <th>जमा</th>
                    <th>बाकी</th>
                    <th>एकूण</th>
                    <th>जमा</th>
                    <th>बाकी</th>
                    <th>एकूण</th>
                    <th>जमा</th>
                    <th>बाकी</th>
                    <th>एकूण</th>
                    <th>जमा</th>
                    <th>बाकी</th>
                    <th>एकूण</th>
                    <th>जमा</th>
                    <th>बाकी</th>
                    <th>एकूण</th>
                    <th>जमा</th>
                    <th>बाकी</th>
                    <th>एकूण</th>
                    <th>जमा</th>
                    <th>बाकी</th>
                    <th>एकूण</th>
                    <th>जमा</th>
                    <th>बाकी</th>
                    <th>एकूण</th>
                    <th>जमा</th>
                    <th>बाकी</th>
                    <th>एकूण</th>
                    <th>जमा</th>
                    <th>बाकी</th>
                    <th>एकूण</th>
                    <th>जमा</th>
                    <th>बाकी</th>
                    <th>एकूण</th>
                    <th>जमा</th>
                    <th>बाकी</th>
                    <th>एकूण</th>
                    <th>जमा</th>
                    <th>बाकी</th>
                    <th>एकूण</th>
                    <th>जमा</th>
                    <th>बाकी</th>
                    <th>एकूण</th>
                    <th>जमा</th>
                    <th>बाकी</th>
                    <th>एकूण</th>
                    <th>जमा</th>
                    <th>बाकी</th>
                    <th>एकूण</th>
                    <th>जमा</th>
                    <th>बाकी</th>
                    <th>एकूण</th>
                    <th>जमा</th>
                    <th>बाकी</th>
                    <th>एकूण</th>
                    <th colspan="4"></th>
                </tr>
            </thead>
            <!-- *ngFor="let data of OutstandingInDetailFormBeans; let i = index" -->
            <tbody>
                <ng-container *ngFor="let data2 of SubRemRep; let i = index">

                    <tr>
                        <td colspan="4">{{data2.rep_name}}</td>
                        <td colspan="76"></td>
                    </tr>


                    <tr *ngFor="let data of data2.rep_info; let i = index">
                        <td colspan="2">{{data.district}}</td>
                        <td colspan="2">{{data.taluka}}</td>
                        <!-- <td colspan="1">{{data.off_rep}}</td> -->

                        <td>{{data.tapal_Renew_4}}</td>
                        <td>{{data.tapal_New_4}}</td>
                        <td>{{data.rem_april_post_total}}</td>
                        <td>{{data.haste_Renew_4}}</td>
                        <td>{{data.haste_New_4}}</td>
                        <td>{{data.rem_april_haste_total}}</td>
    
                        <td>{{data.tapal_Renew_5}}</td>
                        <td>{{data.tapal_New_5}}</td>
                        <td>{{data.rem_may_post_total}}</td>
                        <td>{{data.haste_Renew_5}}</td>
                        <td>{{data.haste_New_5}}</td>
                        <td>{{data.rem_may_haste_total}}</td>
    
                        <td>{{data.tapal_Renew_6}}</td>
                        <td>{{data.tapal_New_6}}</td>
                        <td>{{data.rem_jun_post_total}}</td>
                        <td>{{data.haste_Renew_6}}</td>
                        <td>{{data.haste_New_6}}</td>
                        <td>{{data.rem_jun_haste_total}}</td>
    
                        <td>{{data.tapal_Renew_7}}</td>
                        <td>{{data.tapal_New_7}}</td>
                        <td>{{data.rem_jul_post_total}}</td>
                        <td>{{data.haste_Renew_7}}</td>
                        <td>{{data.haste_New_7}}</td>
                        <td>{{data.rem_jul_haste_total}}</td>
    
                        <td>{{data.tapal_Renew_8}}</td>
                        <td>{{data.tapal_New_8}}</td>
                        <td>{{data.rem_aug_post_total}}</td>
                        <td>{{data.haste_Renew_8}}</td>
                        <td>{{data.haste_New_8}}</td>
                        <td>{{data.rem_aug_haste_total}}</td>
    
                        <td>{{data.tapal_Renew_9}}</td>
                        <td>{{data.tapal_New_9}}</td>
                        <td>{{data.rem_sep_post_total}}</td>
                        <td>{{data.haste_Renew_9}}</td>
                        <td>{{data.haste_New_9}}</td>
                        <td>{{data.rem_sep_haste_total}}</td>
    
                        <td>{{data.tapal_Renew_10}}</td>
                        <td>{{data.tapal_New_10}}</td>
                        <td>{{data.rem_oct_post_total}}</td>
                        <td>{{data.haste_Renew_10}}</td>
                        <td>{{data.haste_New_10}}</td>
                        <td>{{data.rem_oct_haste_total}}</td>
    
                        <td>{{data.tapal_Renew_11}}</td>
                        <td>{{data.tapal_New_11}}</td>
                        <td>{{data.rem_nov_post_total}}</td>
                        <td>{{data.haste_Renew_11}}</td>
                        <td>{{data.haste_New_11}}</td>
                        <td>{{data.rem_nov_haste_total}}</td>
    
                        <td>{{data.tapal_Renew_12}}</td>
                        <td>{{data.tapal_New_12}}</td>
                        <td>{{data.rem_dec_post_total}}</td>
                        <td>{{data.haste_Renew_12}}</td>
                        <td>{{data.haste_New_12}}</td>
                        <td>{{data.rem_dec_haste_total}}</td>
    
                        <td>{{data.tapal_Renew_1}}</td>
                        <td>{{data.tapal_New_1}}</td>
                        <td>{{data.rem_jan_post_total}}</td>
                        <td>{{data.haste_Renew_1}}</td>
                        <td>{{data.haste_New_1}}</td>
                        <td>{{data.rem_jan_haste_total}}</td>
    
                        <td>{{data.tapal_Renew_2}}</td>
                        <td>{{data.tapal_New_2}}</td>
                        <td>{{data.rem_feb_post_total}}</td>
                        <td>{{data.haste_Renew_2}}</td>
                        <td>{{data.haste_New_2}}</td>
                        <td>{{data.rem_feb_haste_total}}</td>
    
                        <td>{{data.tapal_Renew_3}}</td>
                        <td>{{data.tapal_New_3}}</td>
                        <td>{{data.rem_march_post_total}}</td>
                        <td>{{data.haste_Renew_3}}</td>
                        <td>{{data.haste_New_3}}</td>
                        <td>{{data.rem_march_haste_total}}</td>
    
    
                        <td colspan="4">{{grandremtotal}}</td>
                       
                    </tr>
                </ng-container>

             
                 
            </tbody>

            <tfoot>
                <tr>
                    <td colspan="80"></td>
                </tr>
                </tfoot>  
        </table>
      
      
    </div>
    <div style="margin-left: 80%; margin-bottom: 2%;">
        <!-- <button class="noPrint" style="margin-top: 2%;" mat-raised-button color="primary"
            [printStyle]="{table : {'border-collapse':'collapse'}, td : {'border': 'solid 1px'}, th : {'border': 'solid 1px'}}"
            printSectionId="print-section2" ngxPrint>
            print
        </button> -->
        <button class="noPrint" style="margin-top: 2%; margin-left: 2%"
            (click)="exportExcel('Contract_InDetails_Report')" mat-raised-button color="primary">
            Export
        </button>

        <button class="noPrint" style="margin-top: 2%;" mat-raised-button color="primary"
           [useExistingCss]="true"
            printSectionId="print-section" ngxPrint>
            print
        </button>
        
        <button class="noPrint" style="margin-top: 2%" mat-button mat-dialog-close>
            Close
        </button>
    </div>
</div>
