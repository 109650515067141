import { formatDate } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { PrintchallanComponent } from 'src/app/printchallan/printchallan.component';
import { DigisubservicesService } from 'src/app/services/digisubservices.service';

@Component({
  selector: 'app-post-statement',
  templateUrl: './post-statement.component.html',
  styleUrls: ['./post-statement.component.scss'],
})
export class PostStatementComponent implements OnInit {
  poststatementdata;
  sub_mode;
 
  tempdata;
  total_sub_count:any;
  today;
  prakashandate;
  vitarandate;
  Filteredpoststatementdata = [];
  data1 = [];
  disRepData = [];
  total_s_bhet = 0;
  issuedate: any;
  FilteredlabelsforDispatch: any;
  dataone: any;
  datatwo: any;
  combinedList: any;
  dist_name:any;
  dis_mode:any;
  dis_sub_mode:any;
  constructor(
    private dialogref: MatDialogRef<PostStatementComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialog,
    private service: DigisubservicesService
  ) {}

  ngOnInit(): void {
    debugger;
    this.dist_name = this.data.dist_name,
    this.dis_mode = this.data.dis_mode,
    this.dis_sub_mode = this.data.dis_sub_mode,

    this.dataone = this.data.data;
    this.datatwo = this.data.data1
console.log(this.dataone, this.datatwo, "intailze data")

this.combinedList = this.dataone;
console.log(this.combinedList,"Intial list")

for (let j = 0; j < this.datatwo.length; j++) {
  let matchFound = false;

  for (let i = 0; i < this.combinedList.length; i++) {
    // Check if `dis_rep_name` matches `dsi_rep`
    if (this.combinedList[i].issueSellerName === this.datatwo[j].dsi_rep) {
      // Update the existing object
      this.combinedList[i].sub_count = this.datatwo[j].count;
      this.combinedList[i].dist = this.datatwo[j].district;
      this.combinedList[i].taluka = this.datatwo[j].taluka;
      this.combinedList[i].dis_mode = this.datatwo[j].dis_mode;
      this.combinedList[i].dis_sub_mode = this.datatwo[j].dis_sub_mode;
      this.combinedList[i].prati = parseInt(this.combinedList[i].sub_count)+ parseInt(this.combinedList[i].s_bhet);

      
      matchFound = true; // Set match found flag to true
      break; // Exit the loop as we've found a match
    }
  }

  // If no match was found, add a new object to `combinedList`
  if (!matchFound) {
    const newEntry = {
      issueSellerName: this.datatwo[j].dsi_rep,
      sub_count: this.datatwo[j].count,
      dis_dist: this.datatwo[j].district,
      dis_tal: this.datatwo[j].taluka,
      dis_mode: this.datatwo[j].dis_mode,
      dis_sub_mode: this.datatwo[j].dis_sub_mode,
      prati :this.datatwo[j].count
     
    };
    
    this.combinedList.push(newEntry); 
    console.log( this.combinedList, "finalelist")// Add new object to `combinedList`
  }
}

// for (let data1 of this.combinedList){
//   if(data1.sub_count === undefined || data1.sub_count === null){
//     data1.sub_count = 0;
//   }
//   // this.total_sub_count += parseInt(data1.sub_count) || 0;

//   }
 debugger;
  this.combinedList = this.combinedList.filter((element:any)=>{
    return this.dist_name == element.issueSellerName
  })
  this.combinedList.forEach(element => {
    this.total_sub_count =+ element.prati
    
  });

    // this.service
    //   .getDistributionRepresentativeSBhetHastewise()
    //   .subscribe((res) => {
    //     this.disRepData = res;
    //     console.log(this.disRepData);
    //   });

    // this.poststatementdata = this.data['data'].filter((a) => {
    //   return a['dis_mode'] === this.data['dis_mode'];
    // });
    // this.sub_mode = this.data['mode'];
    // this.dis_mode = this.data['dis_mode'];
    // this.prakashandate = this.data['prakashandate']
    //   ? formatDate(
    //       this.data['prakashandate'],
    //       'dd/MM/yyyy',
    //       'en-IN',
    //       'GMT+0530'
    //     )
    //   : '';
    // this.vitarandate = this.data['vitarandate'];
    // console.log(this.poststatementdata);
    this.today = formatDate(new Date(), 'dd/MM/yyyy', 'en-IN', 'GMT+0530');

    // this.tempdata = this.uniqueid(
    //   this.poststatementdata,
    //   (a) => a['distribution_rep']
    // );

    // let arr = [];
    // this.tempdata.map((a) => {
    //   arr.push(a.distribution_rep);
    // });

   

    // setTimeout(() => {
    //   if (this.dis_mode != '' && this.sub_mode != '') {
    //     this.disRepData = this.disRepData.filter(
    //       (a) =>
    //         !arr.includes(a.dis_rep_name) &&
    //         a['dis_mode'] === this.dis_mode &&
    //         a['dis_sub_mode'] === this.sub_mode
    //     );
    //   } else if (this.dis_mode != '' && this.sub_mode == '') {
    //     this.disRepData = this.disRepData.filter(
    //       (a) =>
    //         !arr.includes(a.dis_rep_name) && a['dis_mode'] === this.dis_mode
    //     );
    //   } else {
    //     this.disRepData = this.disRepData.filter(
    //       (a) => !arr.includes(a.dis_rep_name)
    //     );
    //   }
    //   console.log(this.disRepData);
    // }, 600);

    // this.sortDataRepresentativewise();
  }

  printchallan() {
    this.dialog.open(PrintchallanComponent, {
      data: {
        issuedate: this.issuedate,
        data: this.FilteredlabelsforDispatch,
      },
      width: '1200px',
      height: '1300px',
      maxWidth: '100%',
    });
  }

  // sortDataRepresentativewise() {
  //   this.total_sub_count = 0;
  //   this.total_s_bhet = 0;
  //   for (let index = 0; index < Object.keys(this.tempdata).length; index++) {
  //     let data = this.poststatementdata.filter(
  //       (a) => a['distribution_rep'] == this.tempdata[index]['distribution_rep']
  //     );

  //     let val =
  //       this.tempdata[index]['s_bhet'] === null
  //         ? 0
  //         : parseInt(this.tempdata[index]['s_bhet']);

  //     this.total_sub_count += Object.keys(data).length + val;
  //     this.data1.push(data);
  //     this.Filteredpoststatementdata.push({
  //       Subscription_Type: this.tempdata[index]['Subscription_Type'],
  //       address: this.tempdata[index]['address'],
  //       country: this.tempdata[index]['country'],
  //       delivery_mode: this.tempdata[index]['delivery_mode'],
  //       district: this.tempdata[index]['district'],
  //       entry_date: this.tempdata[index]['entry_date'],
  //       mobileNumber: this.tempdata[index]['mobileNumber'],
  //       name: this.tempdata[index]['name'],
  //       office_rep_name: this.tempdata[index]['office_rep_name'],
  //       pincode: this.tempdata[index]['pincode'],
  //       rep_mob_no: this.tempdata[index]['rep_mob_no'],
  //       rep_name: this.tempdata[index]['rep_name'],
  //       dis_rep_name: this.tempdata[index]['distribution_rep'],
  //       state: this.tempdata[index]['state'],
  //       sub_perid_to_date: this.tempdata[index]['sub_perid_to_date'],
  //       taluka: this.tempdata[index]['taluka'],
  //       title: this.tempdata[index]['title'],
  //       dis_mode: this.tempdata[index]['dis_mode'],
  //       dis_sub_mode: this.tempdata[index]['dis_sub_mode'],
  //       dis_add: this.tempdata[index]['dis_add'],
  //       dis_country: this.tempdata[index]['dis_country'],
  //       dis_dist: this.tempdata[index]['dis_dist'],
  //       dis_pin: this.tempdata[index]['dis_pin'],
  //       dis_state: this.tempdata[index]['dis_state'],
  //       dis_tal: this.tempdata[index]['dis_tal'],
  //       dis_s_bhet:
  //         this.tempdata[index]['s_bhet'] === null
  //           ? 0
  //           : this.tempdata[index]['s_bhet'],
  //       sub: Object.keys(data).length + val,
  //     });
  //   }
    
  //   console.log(this.Filteredpoststatementdata, "dispatch");
  //   setTimeout(() => {
  //     this.disRepData.map((a) => {
  //       let val = a['dis_s_bhet'] === null ? 0 : parseInt(a['dis_s_bhet']);
  //       this.total_sub_count += val;
  //       this.Filteredpoststatementdata.push({
  //         Subscription_Type: '',
  //         address: '',
  //         country: '',
  //         delivery_mode: a['delivery_mode'],
  //         district: '',
  //         entry_date: '',
  //         mobileNumber: '',
  //         name: '',
  //         office_rep_name: '',
  //         pincode: '',
  //         rep_mob_no: '',
  //         rep_name: '',
  //         dis_rep_name: a['dis_rep_name'],
  //         state: '',
  //         sub_perid_to_date: '',
  //         taluka: '',
  //         title: '',
  //         dis_mode: a['dis_mode'],
  //         dis_sub_mode: a['dis_sub_mode'],
  //         dis_add: a['dis_add'],
  //         dis_country: a['dis_country'],
  //         dis_dist: a['dis_dist'],
  //         dis_pin: a['dis_pin'],
  //         dis_state: a['dis_state'],
  //         dis_tal: a['dis_tal'],
  //         dis_s_bhet: a['dis_s_bhet'] === null ? 0 : a['dis_s_bhet'],
  //         sub: a['dis_s_bhet'] === null ? 0 : a['dis_s_bhet'],
  //       });
  //     });
  //   }, 600);
  // }

  // uniqueid(data, key) {
  //   return [...new Map(data.map((x) => [key(x), x])).values()];
  // }
}
