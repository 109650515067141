import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DigisubservicesService } from 'src/app/services/digisubservices.service';
import { ReciptComponent } from '../recipt/recipt.component';

@Component({
  selector: 'app-creditvoucher-dialog',
  templateUrl: './creditvoucher-dialog.component.html',
  styleUrls: ['./creditvoucher-dialog.component.scss'],
})
export class CreditvoucherDialogComponent implements OnInit {
  form: FormGroup;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    public dialog: MatDialog,
    public service: DigisubservicesService,
    private dialogRef: MatDialogRef<CreditvoucherDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.form = fb.group({
      name: [''],
    });
  }

  ngOnInit(): void {
    // console.log('data of dialog', this.data);
  }

  submitForm() {
    let id;
    if (this.form.get('name').value != '') {
      id = this.data['id'];
      let voucher_no = this.data['voucher_no'];
      let dept = this.data['dept'];
      this.service
        .getBookIdWithMobile(this.form.get('name').value)
        .subscribe((data) => {
          console.log('id', data);

          this.service
            .updateVoucheritemId(id, data[0]['bdp_id'], dept, voucher_no)
            .subscribe((data) => {
              // console.log(data);
            });
        });
    }
    this.dialogRef.close();
    window.alert('Representative Cancelled Successfully.');
    this.dialog.open(ReciptComponent, {
      data: {
        id: id,
        dept: 'advertisement',
        height: '500px',
        width: '1280px',
      },
    }); //show recipt
  }
}
