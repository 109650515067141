import { formatDate } from '@angular/common';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DigisubservicesService } from 'src/app/services/digisubservices.service';

@Component({
  selector: 'app-labels-of-hand-delivery',
  templateUrl: './labels-of-hand-delivery.component.html',
  styleUrls: ['./labels-of-hand-delivery.component.scss'],
})
export class  LabelsOfHandDeliveryComponent implements OnInit {
  labelsData;
  
  sub_mode;
  vitarandate;
  ankdate;
  tempdata;
  total_sub_count;
  data1 = [];
  FilteredlabelsData = [];
  disRepData = [];
  dataone: any;
  datatwo: any;
  combinedList: any;
  dispatchdate : any
  dist_name : any
  dis_mode : any
  dis_sub_mode : any
  filteredList: any;
  isuuedate: any;

  constructor(
    private dialogref: MatDialogRef<LabelsOfHandDeliveryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private service: DigisubservicesService
  ) {}

  ngOnInit(): void {
    debugger;
    this.dist_name = this.data.dist_name,
    this.dis_mode = this.data.dis_mode,
    this.dis_sub_mode = this.data.dis_sub_mode ,
    this.isuuedate = this.data.isuuedate
debugger;

    this.dataone = this.data.data1,
    this.datatwo = this.data.data2,
    this.dispatchdate = this.data.dispatchdate
    console.log(this.dataone , this.datatwo , "intial data")
    // this.service
    //   .getDistributionRepresentativeSBhetHastewise()
    //   .subscribe((res) => {
    //     this.disRepData = res;
    //     // console.log(this.disRepData);
       
        
    //   });
// *************************COMMENTED CODE IS PREVIOUS CODE*******************************************************
    // this.labelsData = this.data['data'].filter((a) => {
    //   return a['dis_mode'] ===this.data['dis_mode'];
    // });
    this.dis_mode = this.data['dis_mode'];
    this.sub_mode = this.data['mode'];

    this.combinedList = this.dataone;

    console.log(this.combinedList,"Intial list")

    this.combinedList.forEach(element => {
      element.sub_count = 0;
      
    });

    for (let j = 0; j < this.datatwo.length; j++) {
      let matchFound = false;
    
      for (let i = 0; i < this.combinedList.length; i++) {
        // Check if `dis_rep_name` matches `dsi_rep`
        if (this.combinedList[i].issueSellerName === this.datatwo[j].dsi_rep) {
          // Update the existing object
          this.combinedList[i].sub_count = this.datatwo[j].count;
          this.combinedList[i].dist = this.datatwo[j].district;
          this.combinedList[i].taluka = this.datatwo[j].taluka;
          this.combinedList[i].dis_mode = this.datatwo[j].dis_mode;
          this.combinedList[i].dis_sub_mode = this.datatwo[j].dis_sub_mode;
          this.combinedList[i].prati = parseInt(this.combinedList[i].sub_count)+ parseInt(this.combinedList[i].s_bhet);
          
          matchFound = true; // Set match found flag to true
          break; // Exit the loop as we've found a match
        }
      }
    
      // If no match was found, add a new object to `combinedList`
      if (!matchFound) {
        const newEntry = {
          issueSellerName: this.datatwo[j].dsi_rep,
          sub_count: this.datatwo[j].count,
          dis_dist: this.datatwo[j].district,
          dis_tal: this.datatwo[j].taluka,
          dis_mode: this.datatwo[j].dis_mode,
          dis_sub_mode: this.datatwo[j].dis_sub_mode,
          s_bhet : 0,
          mob_no :  this.datatwo[j].mob_no,
          full_add : this.datatwo[j].full_add,
         prati :this.datatwo[j].count
        };
        
        this.combinedList.push(newEntry); // Add new object to `combinedList`
      }
    }
    
    console.log(this.combinedList, "finale combinedList list");

    // this.filteredList = this.combinedList.filter(item =>
    //   item.dis_mode === this.dis_mode &&
    //   item.dis_sub_mode === this.dis_sub_mode &&
    //   item.issueSellerName === this.dist_name
    // );
    debugger;
   

      this.filteredList = this.combinedList.filter((a) => {
        debugger;
        let dismodeCondition = true;
        if (this.dis_mode != '' && this.dis_mode != undefined) {
          // dismodeCondition = this.dis_mode.includes(a['dis_mode']);
          dismodeCondition = a['dis_mode'] !== "" && this.dis_mode === a['dis_mode'];

        }

        let dis_subModeCondition = true;
        if (this.dis_sub_mode != '' && this.dis_sub_mode != undefined) {
          // dis_subModeCondition = this.dis_sub_mode.includes(a['dis_sub_mode']);
          dis_subModeCondition = a['dis_sub_mode'] !== "" && this.dis_sub_mode === a['dis_sub_mode'];

        }

        let dist_nameCondition = true;
        if (this.dist_name != '' && this.dist_name != undefined) {
          // dist_nameCondition = this.dist_name.includes(a['issueSellerName']);
          dist_nameCondition = a['issueSellerName'] !== "" && this.dist_name === a['issueSellerName'];
        }

        return (
          dismodeCondition &&
          dis_subModeCondition &&
          dist_nameCondition 
          // Add more conditions here
        );
      })

    console.log(this.filteredList , "filterlist")
    // this.vitarandate = this.data['vitarandate'];
    // this.ankdate =
    //   this.data['ankdate'] === ''
    //     ? ''
    //     : formatDate(this.data['ankdate'], 'dd/MM/yyyy', 'en-IN', 'GMT+0530');

    // if (this.dis_mode != '' && this.sub_mode != '') {
    //   this.labelsData = this.labelsData.filter((a) => {
    //     return (
    //       a['dis_mode'] == this.dis_mode && a['dis_sub_mode'] == this.sub_mode
    //     );
    //   });
    // } else if (this.dis_mode != '' && this.sub_mode == '') {
    //   this.labelsData = this.labelsData.filter((a) => {
    //     return a['dis_mode'] == this.dis_mode;
    //   });
    // }


    // this.tempdata = this.uniqueid(
    //   this.labelsData,
    //   (a) => a['distribution_rep']
    // );

    // let arr = [];
    // this.tempdata.map((a) => {
    //   arr.push(a.distribution_rep);
    // });

    // setTimeout(() => {
    //   if (this.dis_mode != '' && this.sub_mode != '') {
    //     this.disRepData = this.disRepData.filter(
    //       (a) =>
    //         !arr.includes(a.dis_rep_name) &&
    //         a['dis_mode'] === this.dis_mode &&
    //         a['dis_sub_mode'] === this.sub_mode
    //     );
    //   } else if (this.dis_mode != '' && this.sub_mode == '') {
    //     this.disRepData = this.disRepData.filter(
    //       (a) =>
    //         !arr.includes(a.dis_rep_name) && a['dis_mode'] === this.dis_mode
    //     );
    //   } else {
    //     this.disRepData = this.disRepData.filter(
    //       (a) => !arr.includes(a.dis_rep_name)
    //     );
    //   }
    // }, 600);

    // this.sortDataRepresentativewise();
  }

  // sortDataRepresentativewise() {
    
  //   this.total_sub_count = 0;
  //   for (let index = 0; index < Object.keys(this.tempdata).length; index++) {
  //     let data = this.labelsData.filter((a) => a['distribution_rep'] == this.tempdata[index]['distribution_rep'] && a['delivery_mode'] == this.tempdata[index]['delivery_mode']);
      
  //     let val =
  //       this.tempdata[index]['s_bhet'] === null
  //         ? 0
  //         : parseInt(this.tempdata[index]['s_bhet']);

  //     this.total_sub_count += Object.keys(data).length;
  //     this.data1.push(data);
  //     this.FilteredlabelsData.push({
  //       Subscription_Type: this.tempdata[index]['Subscription_Type'],
  //       address: this.tempdata[index]['address'],
  //       country: this.tempdata[index]['country'],
  //       delivery_mode: this.tempdata[index]['delivery_mode'],
  //       district: this.tempdata[index]['district'],
  //       entry_date: this.tempdata[index]['entry_date'],
  //       mobileNumber: this.tempdata[index]['mobileNumber'],
  //       name: this.tempdata[index]['name'],
  //       office_rep_name: this.tempdata[index]['office_rep_name'],
  //       pincode: this.tempdata[index]['pincode'],
  //       rep_mob_no: this.tempdata[index]['rep_mob_no'],
  //       rep_name: this.tempdata[index]['rep_name'],
  //       dis_rep_name: this.tempdata[index]['distribution_rep'],
  //       state: this.tempdata[index]['state'],
  //       sub_perid_to_date: this.tempdata[index]['sub_perid_to_date'],
  //       taluka: this.tempdata[index]['taluka'],
  //       title: this.tempdata[index]['title'],
  //       dis_mode: this.tempdata[index]['dis_mode'],
  //       dis_sub_mode: this.tempdata[index]['dis_sub_mode'],
  //       dis_add: this.tempdata[index]['dis_add'],
  //       dis_country: this.tempdata[index]['dis_country'],
  //       dis_dist: this.tempdata[index]['dis_dist'],
  //       dis_pin: this.tempdata[index]['dis_pin'],
  //       dis_state: this.tempdata[index]['dis_state'],
  //       dis_tal: this.tempdata[index]['dis_tal'],
  //       sub: Object.keys(data).length + val,
  //     });
  //   }
    
  //   console.log( this.FilteredlabelsData, " FilteredlabelsData");
  //   setTimeout(() => {
  //     this.disRepData.map((a) => {
  //       let val = a['dis_s_bhet'] === null ? 0 : parseInt(a['dis_s_bhet']);
  //       this.total_sub_count += val;
  //       this.FilteredlabelsData.push({
  //         Subscription_Type: '',
  //         address: '',
  //         country: '',
  //         delivery_mode: a['delivery_mode'],
  //         district: '',
  //         entry_date: '',
  //         mobileNumber: '',
  //         name: '',
  //         office_rep_name: '',
  //         pincode: '',
  //         rep_mob_no: '',
  //         rep_name: '',
  //         dis_rep_name: a['dis_rep_name'],
  //         state: '',
  //         sub_perid_to_date: '',
  //         taluka: '',
  //         title: '',
  //         dis_mode: a['dis_mode'],
  //         dis_sub_mode: a['dis_sub_mode'],
  //         dis_add: a['dis_add'],
  //         dis_country: a['dis_country'],
  //         dis_dist: a['dis_dist'],
  //         dis_pin: a['dis_pin'],
  //         dis_state: a['dis_state'],
  //         dis_tal: a['dis_tal'],
  //         dis_s_bhet: a['dis_s_bhet'] === null ? 0 : a['dis_s_bhet'],
  //         sub: a['dis_s_bhet'] === null ? 0 : parseInt(a['dis_s_bhet']),
  //       });
  //     });
  //   }, 600);
  // }

  // uniqueid(data, key) {
  //   return [...new Map(data.map((x) => [key(x), x])).values()];
  // }
}
