<div id="print-section">
  <div class="container">
    <div
      *ngFor="let item of finaldatalist; let i = index"
      style="margin-bottom: 10px"
    >
      <div class="row" *ngIf="i == 0">
        <h2>{{ item[0]["rep_name"] }}</h2>
      </div>
      <div class="row" *ngFor="let data of item">
        <div class="col-3">
          <span style="font-size: 20px">{{ data["name"] }}</span
          ><br />
          <span style="font-size: 20px"
            >{{ data["address"] }}, {{ data["taluka"] }},
            {{ data["district"] }},</span
          ><br />
          <span style="font-size: 20px">{{ data["state"] }}, </span>
          <span style="font-size: 20px">{{ data["country"] }} </span>
          <span style="font-size: 20px" *ngIf="data['pincode'] != 0"
            >- {{ data["pincode"] }}</span
          ><br *ngIf="data['pincode'] != 0" />
          <span style="font-size: 20px" *ngIf="data['mob_no'] != 0">{{
            data["mobile_no"]
          }}</span>
        </div>
      </div>
    </div>
    <button
      [useExistingCss]="true"
      class="noPrint"
      style="margin-top: 2%"
      mat-raised-button
      color="primary"
      printSectionId="print-section"
      ngxPrint
    >
      print
    </button>
    <button
      class="noPrint"
      style="margin-top: 2%; margin-left: 2%"
      mat-raised-button
      color="info"
      mat-dialog-close
    >
      Close
    </button>
  </div>
</div>
