import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-representativewise-address',
  templateUrl: './representativewise-address.component.html',
  styleUrls: ['./representativewise-address.component.scss'],
})
export class RepresentativewiseAddressComponent implements OnInit {
  representativelist;

  constructor(
    public dialogref: MatDialogRef<RepresentativewiseAddressComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.representativelist = this.data['repaddresslist'];
  }

  print() {
    let element = document.getElementById('print-section');
    let newWin = window.open('');
    newWin.document.write(element.outerHTML);
    newWin.print();
    newWin.close();
  }
}
