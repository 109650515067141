<div mat-dialog-content>
    <div class="text-center">
        <h1 style="font-size: medium;font-weight: bold;" >View Book</h1>
    </div>
   
    <form class="form" [formGroup]="BookForm" (ngSubmit)=save()>
        <div class="form-row">
            <div class="col form-group">
                <label style=" margin-top: 20px;">Book Name :</label>
                <input readonly required style="width: 90%;" formControlName="bookname" type="text" class="form-control" placeholder="">
            </div>
            <!-- form-group end.// -->

            <div class="col form-group">
                <label style="margin-left: 35px; margin-top: 20px;">Book Description :</label>
                <textarea readonly required style="width: 90%; margin-left: 35px;" formControlName="bookdesc" type="text" class="form-control" placeholder=""> </textarea>
            </div>
        </div>
        <div class="form-row">
            <div class="col form-group">
                <label style=" margin-top: 20px;">Author Name :</label>
                <input readonly required style="width: 90%; " formControlName="authorname" type="text" class="form-control" placeholder="">
            </div>
            <!-- form-group end.// -->

            <div class="col form-group">
                <label style="margin-left: 35px; margin-top: 20px;">Book Pages :</label>
                <input readonly required style="width: 90%; margin-left: 35px;" formControlName="bookpages" type="text" class="form-control" placeholder="">
            </div>
        </div>
        <div class="form-row">
            <div class="col form-group">
                <label style="margin-top: 20px;">Book Language :</label>
                <input readonly required style="width: 90%; " formControlName="booklang" type="text" class="form-control" placeholder="">
            </div>
            <!-- form-group end.// -->

            <div class="col form-group">
                <label style="margin-left: 35px; margin-top: 20px;">Book Publish Year :</label>
                <input readonly required style="width: 90%; margin-left: 35px;" formControlName="bookyear" type="text" class="form-control" placeholder="">
            </div>
        </div>
        <div class="form-row">
            <div class="col form-group">
                <label style="margin-top: 20px;">Book Price : </label>
                <input readonly required style="width: 60%;" formControlName="price" type="number" class="form-control">
            </div>
            <!-- form-group end.// -->


            <div class="col form-group">
                <label style="  margin-top: 20px;">Book Sale Price : </label>
                <input  readonly required (change)="getSalePrice($event)" style="width: 60%;" formControlName="saleprice" type="number" class="form-control" placeholder="">
            </div>
            <!-- form-group end.// -->

            <div class="col form-group">
                <label style="margin-top: 20px;">Book Category : </label>
                <input readonly required style="width: 100%; " formControlName="bookcategory" type="text" class="form-control">
            </div>
        </div>

        <div class="form-row">
            <div class="col form-group">
                <label style="margin-top: 20px;" >is Delivery Charges included : </label>
               <!--  <input formControlName="isdelivery" type="number" class="form-control" placeholder=""> -->
                <select  readonly style="width: 60%;" class="form-control" 
                    placeholder="Select One" formControlName="isdelivery" required>
                    <option value="Y">Yes</option>
                    <option value="N">No</option>
                    </select>
            </div>
            <!-- form-group end.// -->

            <div class="col form-group">
                <label style="margin-left: 35px;  margin-top: 20px;">Publish Status : </label>
                <select readonly style=" margin-left: 35px; width: 71%;"class="form-control" 
                    placeholder="Select One" formControlName="isupcoming" (change)="getSearchTerm($event)" required>
                    <option value="N">Publish Now</option>
                    <option value="Y">Upcomming</option>
                    </select>
            </div>
            <!-- form-group end.// -->

        </div>

        <div class="form-row">
            
            <div class="col form-group" *ngIf="displayDiscount">
                <label>Discount upto : </label>
                <input readonly style=" width: 61%;" formControlName="discountupto" type="date" class="form-control" placeholder="">
            </div>

            <div class="col form-group"  *ngIf="isupcoming">
                <label>Upcomming Book Date : </label>
               <!--  <input formControlName="isdelivery" type="number" class="form-control" placeholder=""> -->
               <input readonly style="width: 61%;" formControlName="upcomingdate" type="date" class="form-control">
            </div>
            <!-- form-group end.// -->
        </div>

        <div class="form-row">
            <div class="form-group">
                <label style="margin-left: 35px;">Uploaded Book Image : </label>
                <img src="{{this.bookimgpath}}" width="100">
                <!-- <input style="margin-left: 35px; margin-right: 45px; width: 90%;" type="file" name="file" ng2FileSelect [uploader]="uploader" class="form-control" placeholder=" "> -->
            </div>
        </div>

        <div mat-dialog-actions style="float:right">
            <button mat-button mat-dialog-close>Close</button>
        </div>
        <!-- form-group// -->


    </form>

</div>