import {
  Component,
  OnInit,
  Inject,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import { DatePipe, formatDate } from '@angular/common';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as XLSX from 'xlsx';
import { DigisubservicesService } from 'src/app/services/digisubservices.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-contract-brief-report',
  templateUrl: './contract-brief-report.component.html',
  styleUrls: ['./contract-brief-report.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContractBriefReportComponent implements OnInit {
  contractData = [];
  advtFormBeans:any=[]
  advtVBTotal:number=0;
  advtFormBean:any
  advtBrieflyFormBeans:any=[]
  advtBrieflyTotal: number=0;
  advtMonthwiseFormBeans:any=[]
  GSTfullreportFormBeans:any=[]
  advtMonthwiseTotal:number=0;
  advtOfficierbrieflyFormBeans:any=[]
  advtOfficierbrieflyTotal:number=0;
  advtPaymentRegiInDetailsFormBeans:any=[]
  advtCreditRegiInDetailsFormBeans:any=[]
  advtCreditRegiInBrieflyFormBeans:any=[]
  OutstandingInDetailFormBeans:any=[]
  CreditadvtOffMTotal:number=0;
  CreditadvtOffVBMTotal:number=0;
  OutStandingIDTotal:number=0;
  OutStandingIDNetAmtTotal:number=0;
  OutStandingIDUnPaidTotal:number=0;
  PaymentadvtOffInDFormBenas:any=[]
  CreditadvtOffInDFormBenas:any=[]
  PaymentadvtOffInDTotal:number=0;
  PaymentadvtOffInDTotal2:number=0;
  PaymentadvtOffInDTotal3:number=0;

  CreditadvtOffInDTotal:number=0;
  CredittadvtInDTotal:number=0;
  CredittadvtInBrieflyTotal:number=0;
  CreditNoteVBFormBeans:any=[]
  CreditNoteVFormBeans:any=[]
  CreditNoteMFormBeans:any=[]
  CreditNoteNBFormBeans:any=[]
  OutstandingB:any=[]
  totalAdvtAmount: number = 0;
  totalNetAmount: number = 0;
  totalUnpaidAmount: number = 0;
  totalCR: number = 0;
  totalPaymentRegiInDetails: number = 0;
  CreditadvtOffBrieflyTotal:number =0;
  // gst
  GSTAdvtAmt:number=0;
  GSTDiscount:number=0;
  GSTNetAdvtAmt:number=0;
  CGSTAmount:number=0;
  SGSTAmount:number=0;
  AllGSTAmount:number=0;
  filteredcontractData = [];
  startDate:any = '';
  endDate:any = '';
  repName = '';
  offRepName = '';
  advtName = '';
  reportType = '';
  totalData = {
    contract_prev_bal: 0,
    remaining_contract: 0,
    contract_amt: 0,
    contract_discnt_amt: 0,
    contract_net_amt: 0,
    contract_net_gst_amt: 0,
    contract_net_total: 0,
    total_march_end: 0,
    next_year: 0,
    till_date_used: 0,
    use_till_march_end: 0,
  };

  today;
  nexttoday;
  aprilFirstSunday;
  day = 0;
  diffDays = 0;
  totalweeks = 0;
  indetailsData = [];
  contractDetailsForm: FormGroup;
  selectedDateRange: string;
  currentDate= new Date();
  // currentDate= this.datePipe.transform(new Date(), 'dd-MM-yyyy ') || '';

  oneMonthAgo: any;
  onlyOutstandingInDetailFormBeans: any;
  onlyOutstandingB: any;
  onlyOutStandingIDTotal: number;
  onlyOutStandingIDNetAmtTotal: number;
  onlyOutStandingIDUnPaidTotal: number;
  onlytotalUnpaidAmount: number;
  onlytotalNetAmount: number;
  onlytotalAdvtAmount: number;
  getpaiddata: any;
  getpersonalleddata: any;
  balancecfamt: any;
  Totalop: number;
  TotalDebit: number;
  TotalCredit: number;
  Totalbal: number;
  getpersonalledBridflydata: any;
  paidcommTotal: number;
  getpendingreceipt: any;
  PendingReceiptAmt: number;
  PendingBalanceAmt: number;
  start: any;
  officer: any;
  taluka: any;
  district: any;
  end : any;
  rep : any;
  SubByZilha: any;
  districtName: any;
  talukaName: any;
  date: any;
  creditoutstandingindetails: any;
  creditonlyOutStandingIDTotal: number;
  creditonlyOutStandingIDNetAmtTotal: number;
  creditonlyOutStandingIDUnPaidTotal: number;
  creditlastonlytotalAdvtAmount: number;
  creditlastonlytotalNetAmount: number;
  creditlastonlytotalUnpaidAmount: number;
  creditoutstandongbrief: any;
  totalsubhilhapost: number;
  totalsubhilhahaste: number;
  totalsubhilhamagzine: number;
  totalsubhilhadigital: number;
  totalsubhilhatotal: number;
  SubByTaluka: any;
  SubByRep: any;
  SubByOffRep: any;
  totaloffrepsubhilhapost: number;
  totaloffrepsubhilhahaste: number;
  totaloffrepsubhilhatotal: number;
  totalrepsubhilhapost: number;
  totalrepsubhilhahaste: number;
  totalrepsubhilhatotal: number;
  totalzilhahapost: number;
  totalzilhahahaste: number;
  totalzilhahamagzine: number;
  totalzilhahadigital: number;
  totalzilhahatotal: number;
  aprilpost :number;
  aprilhaste : number;
  aprilmagzine : number;
  aprildigital : number;
  apriltotal : number;
  maypost :number;
  mayhaste : number;
  maymagzine : number;
  maydigital : number;
  maytotal : number;
  junepost :number;
  junehaste : number;
  junmagzine : number;
  jundigital : number;
  junetotal : number;
  julypost :number;
  julyhaste : number;
  julymagzine : number;
  julydigital : number;
  julytotal : number;
  augpost :number;
  aughaste : number;
  augmagzine : number;
  augdigital : number;
  augtotal : number;
  seppost :number;
  sephaste : number;
  sepmagzine : number;
  sepdigital : number;
  septotal : number;
  octopost :number;
  octohaste : number;
  octmagzine : number;
  octdigital : number;
  octototal : number;
  novpost :number;
  novhaste : number;
  novmagzine : number;
  novdigital : number;
  novtotal : number;
  decpost :number;
  dechaste : number;
  decmagzine : number;
  decdigital : number;
  dectotal : number;
  janpost :number;
  janhaste : number;
  janmagzine : number;
  jandigital : number;
  jantotal : number;
  febpost :number;
  febhaste : number;
  febmagzine : number;
  febdigital : number;
  febtotal : number;
  marchpost :number;
  marchhaste : number;
  marchmagzine : number;
  marchdigital : number;
  marchtotal : number;
  columnSums: number[] = []; 
  typewisedistrict: any;
  pvarshik: number;
  ptrevarshik: number;
  ppanchvarshik: number;
  phindutva: number;
  hlova: number;
  hpanchvarshik: number;
  ptotal: number;
  htrevarshik: number;
  hhindutva: number;
  htotal: number;
  plova: number;
  typewisetaluka: any;
  hvarshik: number;
  gtotal: number;
  typewiseofficer: any;
  typewiserep: any;
  SubRemDistrict: any;
  SubRemDistrictBriefly:any;
  tapal_Total_4: any;
  haste_Total_4: any;
  tapal_Total_5: any;
  haste_Total_5: any;
  tapal_Total_6: any;
  haste_Total_6: any;
  tapal_Total_7: any;
  haste_Total_7: any;
  tapal_Total_8: any;
  haste_Total_8: any;
  tapal_Total_9: any;
  haste_Total_9: any;
  tapal_Total_10: any;
  haste_Total_10: any;
  tapal_Total_11: any;
  haste_Total_11: any;
  tapal_Total_12: any;
  haste_Total_12: any;
  tapal_Total_1: any;
  haste_Total_1: any;
  tapal_Total_2: any;
  haste_Total_2: any;
  tapal_Total_3: any;
  haste_Total_3: any;
  tapal_New_4 :any;
  tapal_Renew_4:any;
  tapal_New_5 :any;
  tapal_Renew_5:any;
  tapal_New_6 :any;
  tapal_Renew_6:any;
  tapal_New_7 :any;
  tapal_Renew_7:any;
  tapal_New_8 :any;
  tapal_Renew_8:any;
  tapal_New_9 :any;
  tapal_Renew_9:any;
  tapal_New_10 :any;
  tapal_Renew_10:any;
  tapal_New_11 :any;
  tapal_Renew_11:any;
  tapal_New_12 :any;
  tapal_Renew_12:any;
  tapal_New_1 :any;
  tapal_Renew_1:any;
  tapal_New_2 :any;
  tapal_Renew_2:any;
  tapal_New_3 :any;
  tapal_Renew_3:any;
  haste_New_4:any;
  haste_Renew_4:any;
  haste_New_5:any;
  haste_Renew_5:any;
  haste_New_6:any;
  haste_Renew_6:any;
  haste_New_7:any;
  haste_Renew_7:any;
  haste_New_8:any;
  haste_Renew_8:any;
  haste_New_9:any;
  haste_Renew_9:any;
  haste_New_10:any;
  haste_Renew_10:any;
  haste_New_11:any;
  haste_Renew_11:any;
  haste_New_12:any;
  haste_Renew_12:any;
  haste_New_1:any;
  haste_Renew_1:any;
  haste_New_2:any;
  haste_Renew_2:any;
  haste_New_3:any;
  haste_Renew_3:any;

  magzine_New_4: any;
magzine_Renew_4: any;
magzine_Total_4: any;
magzine_New_5: any;
magzine_Renew_5: any;
magzine_Total_5: any;
magzine_New_6: any;
magzine_Renew_6: any;
magzine_Total_6: any;
magzine_New_7: any;
magzine_Renew_7: any;
magzine_Total_7: any;
magzine_New_8: any;
magzine_Renew_8: any;
magzine_Total_8: any;
magzine_New_9: any;
magzine_Renew_9: any;
magzine_Total_9: any;
magzine_New_10: any;
magzine_Renew_10: any;
magzine_Total_10: any;
magzine_New_11: any;
magzine_Renew_11: any;
magzine_Total_11: any;
magzine_New_12: any;
magzine_Renew_12: any;
magzine_Total_12: any;
magzine_New_1: any;
magzine_Renew_1: any;
magzine_Total_1: any;
magzine_New_2: any;
magzine_Renew_2: any;
magzine_Total_2: any;
magzine_New_3: any;
magzine_Renew_3: any;
magzine_Total_3: any;
digital_New_4: any;
digital_Renew_4: any;
digital_Total_4: any;
digital_New_5: any;
digital_Renew_5: any;
digital_Total_5: any;
digital_New_6: any;
digital_Renew_6: any;
digital_Total_6: any;
digital_New_7: any;
digital_Renew_7: any;
digital_Total_7: any;
digital_New_8: any;
digital_Renew_8: any;
digital_Total_8: any;
digital_New_9: any;
digital_Renew_9: any;
digital_Total_9: any;
digital_New_10: any;
digital_Renew_10: any;
digital_Total_10: any;
digital_New_11: any;
digital_Renew_11: any;
digital_Total_11: any;
digital_New_12: any;
digital_Renew_12: any;
digital_Total_12: any;
digital_New_1: any;
digital_Renew_1: any;
digital_Total_1: any;
digital_New_2: any;
digital_Renew_2: any;
digital_Total_2: any;
digital_New_3: any;
digital_Renew_3: any;
digital_Total_3: any;

tapal_New_total : any;
tapal_Renew_total : any;
tapal_Total_total : any;
haste_New_total : any;
haste_Renew_total : any;
haste_Total_total : any;
magzine_New_total : any;
magzine_Renew_total : any;
magzine_Total_total : any;
digital_New_total : any;
digital_Renew_total : any;
digital_Total_total: any;
//------------------------------
SubRemOffVBriefly:any;
SubRemRepVBriefly:any;
SubRemRepBriefly :any;
total_New_4 :any;
total_Renew_4 :any;
total_Total_4 :any;
total_New_5 :any;
total_Renew_5 :any;
total_Total_5 :any;
total_New_6 :any;
total_Renew_6 :any;
total_Total_6 :any;
total_New_7 :any;
total_Renew_7 :any;
total_Total_7 :any;
total_New_8 :any;
total_Renew_8 :any;
total_Total_8 :any;
total_New_9 :any;
total_Renew_9 :any;
total_Total_9 :any;
total_New_10 :any;
total_Renew_10 :any;
total_Total_10 :any;
total_New_11 :any;
total_Renew_11 :any;
total_Total_11 :any;
total_New_12 :any;
total_Renew_12 :any;
total_Total_12 :any;
total_New_1 :any;
total_Renew_1 :any;
total_Total_1 :any;
total_New_2 :any;
total_Renew_2 :any;
total_Total_2 :any;
total_New_3 :any;
total_Renew_3 :any;
total_Total_3 :any;
total_New_total :any;
total_Renew_total :any;
total_Total_total :any;
  grandremtotal = 0;
  Month_total  = 0;
  SubRemTaluka: any;
  SubRemOffRep: any;
  SubRemRep: any;
  old_data: any;
  getpendingreceiptrep: any;
  lastcontract: number;
  remaincontract: number;
  newcontract: number;
  agencydiscontract: number;
  totalcontract: number;
  gstcontract: number;
  grandtotalcontract: number;
  getdeliverytype: any;
  // prantname: any;

  totalrepsubhilhadigital :any;
  totalrepsubhilhamagzine :any;
  post_total_ROR: any;
  haste_total_ROR: any;
  post_jama:any;
  PaymentadvtOffInDTotal_netamt: any;
  beforegstamt: any;
  country: any;
  state:any;
  prant_name:any;
  districtWiseList: any;
  aprilpost1: number;
  aprilhaste1: number;
  aprilmagzine1: number;
  aprildigital1: number;
  apriltotal1: number;
  totalTalukaCount: number;
  stateWiseList: any[];
  officeWiseList: any[];
  representativeWiseList: any[];
  totalzilhahapost1: number;
  totalzilhahahaste1: number;
  totalzilhahamagzine1: number;
  totalzilhahadigital1: number;
  totalzilhahatotal1: number;
  prantWiseList: any[];

  renew_4: number;
  total_4: number;
  new_4: number;
  renew_5: number;
  new_5: number;
  total_5: number;
  renew_6: number;
  new_6: number;
  total_6: number;
  renew_7: number;
  new_7: number;
  total_7: number;
  renew_8: number;
  new_8: number;
  total_8: number;
  renew_9: number;
  new_9: number;
  total_9: number;
  renew_10: number;
  new_10: number;
  total_10: number;
  renew_11: number;
  new_11: number;
  total_11: number;
  renew_12: number;
  new_12: number;
  total_12: number;
  renew_1: number;
  new_1: number;
  total_1: number;
  renew_2: number;
  new_2: number;
  total_2: number;
  renew_3: number;
  new_3: number;
  total_3: number;
  renew_Total: number;
  new_Total: number;
  total_Total: number;
  total_Total4: number;
  SubRemOffRepbrief: any;
  totaloffrepsubhilhapost1: number;
  totaloffrepsubhilhahaste1: number;
  totaloffrepsubhildigital1: number;
  totalsubhilhamagzine1: number;
  totaloffrepsubhilhatotal1: number;
  totaloffrepsubhildigital: number;
  IGSTAmount: number;
  totalOffRepPost1: number;
  totalOffRepHaste1: number;
  totalOffRepmagzine1: number;
  totalOffRepdDigital1: number;
  totalOffReptotal1: number;
  totalOffRepDigital1: number;
  totalrepsubhilhapost1: number;
  totalrepsubhilhahaste1: number;
  totalrepsubhilhamagzine1: number;
  totalrepsubhilhadigital1: number;
  totalrepsubhilhatotal1: number;
  SubByZilhaBriefly: any;
  total_april: number;
  total_may: number;
  SubDelModeZilhaBriefly: any;
  total_grand_total: number;
  total_mar: number;
  total_feb: number;
  total_jun: number;
  total_jul: number;
  total_aug: number;
  total_sep: number;
  total_nov: number;
  total_oct: number;
  total_dec: number;
  total_jan: number;
  SubByTalukaBriefly: any;
  SubDelModeTalukaBriefly: any;

  
  constructor(
    private dialogRef: MatDialogRef<ContractBriefReportComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private service: DigisubservicesService,
    private cdr: ChangeDetectorRef,private fb: FormBuilder,private datePipe:DatePipe
  ) {
    this.contractDetailsForm = this.fb.group({
      start: ['', Validators.required],
      end: ['', Validators.required],
      // other form controls
    });
  }
  months:any
  issueDates:any
  ngOnInit(): void {
    // this.filterData()
    // this.getAdvtOfficerVb();
// this.getAdvtBriefly()
// this.getAdvtMonthwise()
// this.getAdvtOfficerBriefly()
// this.getIndetailsContractDetails()
this.service.getAllContractDetails().subscribe((res) => {
  // console.log(res);
  this.contractData = res;

  this.lastcontract = 0;
  this.remaincontract = 0;
  this.newcontract = 0;
  this.agencydiscontract = 0;
  this.totalcontract = 0;
  this.gstcontract = 0;
  this.grandtotalcontract = 0;
  for (let data1 of this.contractData) {
    this.lastcontract += parseInt(data1.contract_prev_bal) || 0;
    this.newcontract += parseInt(data1.contract_amt) || 0;
    this.agencydiscontract += parseInt(data1.contract_discnt_amt) || 0;
    this.totalcontract += parseInt(data1.contract_net_amt) || 0;
    this.gstcontract += parseInt(data1.contract_net_gst_amt) || 0;
    this.grandtotalcontract += parseInt(data1.contract_net_total) || 0;

         
       }
  // this.getContractDetails();
  console.log(res, "briefly");
});



    
    this.reportType = this.data['reportType'];
    this.months=this.data['months']
    this.today = new Date();
    this.nexttoday = new Date().setFullYear(new Date().getFullYear() + 1);
    // console.log(this.contractData);
    this.startDate =
      this.data['startDate'] === undefined ? '' : this.data['startDate'];
    this.endDate =
      this.data['endDate'] === undefined ? '' : this.data['endDate'];
    this.repName = this.data['repName'];
    this.offRepName = this.data['offRepName'];
    this.advtName = this.data['advtName'];
    this.districtName = this.data['districtName'];
    this.talukaName = this.data['talukaName'];
    this.date = this.data['endDate'];
    this.prant_name = this.data['prantname'];
    // this.prantname = this.data['prant_name'];
    this.country = this.data['country'];
    this.state = this.data['state'];
    this.issueDates=this.data.issueDates

    // this.startdate = this.data['start']
    this.getReportSubscription()
    debugger
    // console.log('reportType', this.reportType);

    if (this.reportType == 'indetail') {
      this.getIndetailsContractDetails();
      // setTimeout(() => {
      //   this.filterData();
      //   this.getData();
      // }, 500);
    } else if(this.reportType == 'indetailcontract'){
      this.getIndetailsContractDetails();
    }else {
      this.contractData = this.data['contractData'];
      this.filterData();
    }
    this.aprilFirstSunday = formatDate(
      new Date(new Date().setMonth(3)),
      'YYYY-MM-DD',
      'en-IN',
      'GMT+0530'
    );
    // console.log('aprilFirstSunday', this.aprilFirstSunday);





    this.filters()
    this.calculatetotalweeks();
   
    this.getCreditNoteVB()
this.getCreditNoteV()
this.getCreditNoteM()
this.getCreditNoteNB()
// this.getOutstandingB()
// this.getPaymentRegiInDetails()
// this.getPaymentRegiOffInDetails()
this.getCreditRegiInDetails()
this.getCreditRegiInBriefly()
this.getCreditRegiOffInDetails()
// this.getAdvtOutstanding() 
// this.filters()
  }

  getIndetailsContractDetails() {
    const req:any = {
      "rep_name":this.repName,
      "start_date":this.startDate,
      "end_date":this.endDate,
      "off_rep_name":this.offRepName,
      "issue_name":""
    }


    this.service.getAllIndetailsContractDetails(req).subscribe((a) => {
      // console.log(a);
      this.contractData = a;
      this.filteredcontractData=a
      console.log(this.contractData,"contract deatail");
      // this.filterData();
      this.calculateTotal();
      this.getData();
    });
  }


    // page number wise print 
    // ngAfterViewInit() {
    //   setTimeout(() => {
    //     let pageHeaders = document.querySelectorAll('.pageNo');
    //     pageHeaders.forEach((header, index) => {
    //       header.innerHTML = (index + 1).toString();
    //     });
    //   }, 500);
    // }
   
    // ngAfterViewInit() {
    //   window.addEventListener('beforeprint', () => {
    //     setTimeout(() => {
    //       let rows = document.querySelectorAll('tbody tr'); // Select all rows
    //       let pageHeaders = document.querySelectorAll('.pageNo'); // Select all page numbers
    //       let currentPage = 1;
    //       let rowsPerPage = 10; // Adjust based on visible rows per page
    
    //       for (let i = 0; i < rows.length; i++) {
    //         if (i % rowsPerPage === 0 && i !== 0) {
    //           currentPage++; // Increase page count after every full page
    //         }
    //         let headerIndex = Math.floor(i / rowsPerPage);
    //         if (pageHeaders[headerIndex]) {
    //           pageHeaders[headerIndex].innerHTML = currentPage.toString();
    //         }
    //       }
    //     }, 100); // Small delay to ensure DOM updates before print
    //   });
    // }
    
    

  getChangedDateContract() {
    // update the selectedDateRange variable when the date range changes
    const startDate = this.contractDetailsForm.get('start').value;
    const endDate = this.contractDetailsForm.get('end').value;

    if (startDate && endDate) {
      this.selectedDateRange = `${startDate} to ${endDate}`;
    }
  }

  // getAdvtOfficerVb() {
   
  //   this.service.getAdvtOfficerVb().subscribe((data:any) => {
  //      console.log("ADVT Data=",data);
  //     this.advtFormBeans = (data as any);;

  //     // Reset totals
  //     this.advtVBTotal = 0;

  //     // Calculate totals
  //     for (let repData of this.advtFormBeans) {
  //         for (let data1 of repData.rep_data) {
  //             this.advtVBTotal += parseInt(data1.rec_amt) || 0;
  //         }
  //     }
  //   });
  // }

  // getAdvtBriefly() {
  
  //   this.service.getAdvtBriefly().subscribe((data:any) => {
  //      console.log("ADVT Brielfy Data=",data);
  //     this.advtBrieflyFormBeans = (data as any);;

  //     this.advtBrieflyTotal = 0;

  //     // Calculate totals
  //     // for (let data of this.advtBrieflyFormBeans) {
          
  //     //         this.advtBrieflyTotal += parseInt(data.rec_amt) || 0;
  //     //        }
  //     for (let repData of this.advtBrieflyFormBeans) {
  //       for (let data1 of repData.rep_data) {
  //           this.advtBrieflyTotal += parseInt(data1.rec_amt) || 0;
           
  //       }
  //   }
  //   });
  // }
  
  // getAdvtMonthwise() {
   
  //   this.service.getAdvtMonthwise().subscribe((data:any) => {
  //      console.log("ADVT Data=",data);
  //     this.advtMonthwiseFormBeans = (data as any);;
  //     // Reset totals
  //     this.advtMonthwiseTotal = 0;

  //     // Calculate totals
  //     for (let data of this.advtMonthwiseFormBeans) {
          
  //             this.advtMonthwiseTotal += parseInt(data.total_amt) || 0;
  //            }
  //   });
  // }

  // getAdvtOfficerBriefly() {
   
  //   this.service.getAdvtOfficerBriefly().subscribe((data:any) => {
  //      console.log("ADVT Data=",data);
  //     this.advtOfficierbrieflyFormBeans = (data as any);;
  //     // Reset totals
  //     this.advtOfficierbrieflyTotal = 0;

  //     // Calculate totals
  //     for (let data of this.advtOfficierbrieflyFormBeans) {
  //       for (let data1 of data.rep_info) {
  //           this.advtOfficierbrieflyTotal += parseInt(data1.rep_total) || 0;
           
  //       }
  //   }
      
  //   });
  // }

  
  getCreditNoteVB() {
    const req:any = {
      "rep_name":this.repName,
      "start_date":this.startDate,
      "end_date":this.endDate,
      "off_rep_name":this.offRepName,
      "issue_name":""
    }
   
    this.service.getCreditNoteVB(req).subscribe((data:any) => {
       console.log("ADVT Data=",data);
       this.CreditadvtOffVBMTotal = 0;


      this.CreditNoteVBFormBeans = (data as any);;
      for (let data of this.CreditNoteVBFormBeans) {
         
        this.CreditadvtOffVBMTotal += parseInt(data.total) || 0;
       }
    });
  }
  
  getCreditNoteV() {

    const req:any = {
      "rep_name":this.repName,
      "start_date":this.startDate,
      "end_date":this.endDate,
      "off_rep_name":this.offRepName,
      "issue_name":""
    }
   
    this.service.getCreditNoteV(req).subscribe((data:any) => {
       console.log("ADVT Data=",data);
      this.CreditNoteVFormBeans = (data as any);;

      this.CreditadvtOffBrieflyTotal = 0;

     // Calculate totals
     for (let data of this.CreditNoteVFormBeans) {
         
             this.CreditadvtOffBrieflyTotal += parseInt(data.total) || 0;
            }
    });
  }
  
  getCreditNoteM() {
    
    const req:any = {
      "rep_name":this.repName,
      "start_date":this.startDate,
      "end_date":this.endDate,
      "off_rep_name":this.offRepName,
      "issue_name":""
    }
    this.service.getCreditNoteM(req).subscribe((data:any) => {
       console.log("ADVT Data=",data);
      this.CreditNoteMFormBeans = (data as any);;

      // Reset totals
     this.CreditadvtOffMTotal = 0;

     // Calculate totals
     for (let data of this.CreditNoteMFormBeans) {
         
             this.CreditadvtOffMTotal += parseInt(data.total_amt) || 0;
            }

    });
  }

  
  getCreditNoteNB() {
    this.service.getCreditNoteNB().subscribe((data:any) => {
       console.log("ADVT Data=",data);
      this.CreditNoteNBFormBeans = (data as any);;

       // Reset totals
       this.totalCR = 0;

       // Calculate totals
       for (let data of this.CreditNoteNBFormBeans) {
           
               this.totalCR += parseInt(data.credit_amt) || 0;
              }
    });
  }
  
//   getOutstandingB() {
//     this.service.getOutstandingB().subscribe((data: any) => {
//         this.OutstandingB = data as any;

//         // Reset totals
//         this.totalAdvtAmount = 0;
//         this.totalNetAmount = 0;
//         this.totalUnpaidAmount = 0;

//         // Calculate totals
//         for (let repData of this.OutstandingB) {
//             for (let data1 of repData.rep_data) {
//                 this.totalAdvtAmount += parseInt(data1.contract_bkng_advt_amt) || 0;
//                 this.totalNetAmount += parseInt(data1.contract_net_total) || 0;
//                 this.totalUnpaidAmount += parseInt(data1.contract_bal_amt) || 0;
//             }
//         }
//     });
// }

// getPaymentRegiInDetails() {
  
//   this.service.getPaymentRegiInDetails().subscribe((data:any) => {
//      console.log("ADVT Indetailsoff Data=",data);
//     this.advtPaymentRegiInDetailsFormBeans = (data as any);;

//      // Reset totals
//      this.PaymentadvtOffInDTotal = 0;

//      // Calculate totals
//      for (let data of this.advtPaymentRegiInDetailsFormBeans) {
         
//              this.PaymentadvtOffInDTotal += parseInt(data.rcv_amt) || 0;
//             }
//   });
// }
getCreditRegiInDetails() {
  const req:any = {
    "rep_name":this.repName,
    "start_date":this.startDate,
    "end_date":this.endDate,
    "off_rep_name":this.offRepName,
    "issue_name":""
  }
  this.service.getCreditRegiInDetails(req).subscribe((data:any) => {
     console.log("ADVT Indetailscredit Data=",data);
    this.advtCreditRegiInDetailsFormBeans = (data as any);;

     // Reset totals
     this.CredittadvtInDTotal = 0;

     // Calculate totals
     for (let data of this.advtCreditRegiInDetailsFormBeans) {
         
             this.CredittadvtInDTotal += parseInt(data.credit_v_amt) || 0;
            }
  });
}

getCreditRegiInBriefly() {
  const req:any = {
    "rep_name":this.repName,
    "start_date":this.startDate,
    "end_date":this.endDate,
    "off_rep_name":this.offRepName,
    "issue_name":""
  }
  this.service.getCreditRegiInBriefly(req).subscribe((data:any) => {
     console.log("ADVT Indetailscredit Data=",data);
    this.advtCreditRegiInBrieflyFormBeans = (data as any);;

     // Reset totals
     this.CredittadvtInBrieflyTotal = 0;

     // Calculate totals
     for (let repData of this.advtCreditRegiInBrieflyFormBeans) {
      for (let data1 of repData.rep_data) {
          this.CredittadvtInBrieflyTotal += parseInt(data1.rec_amt) || 0;
      }
  }
  });
}

// getPaymentRegiOffInDetails() {
//   this.service.getPaymentRegiOffInDetails().subscribe((data: any) => {
//     console.log("new",data)
//       this.PaymentadvtOffInDFormBenas = data as any;
//       // Reset totals
//       this.PaymentadvtOffInDTotal = 0;

//       // Calculate totals
//       for (let repData of this.PaymentadvtOffInDFormBenas) {
//           for (let data1 of repData.rep_data) {
//               this.PaymentadvtOffInDTotal += parseInt(data1.credit_v_amt) || 0;
//           }
//       }
//       console.log("offcr total",this.PaymentadvtOffInDTotal)
//   });
// }

getCreditRegiOffInDetails() {
  const req:any = {
    "rep_name":this.repName,
    "start_date":this.startDate,
    "end_date":this.endDate,
    "off_rep_name":this.offRepName,
    "issue_name":""
  }
  this.service.getCreditRegiOffInDetails(req).subscribe((data: any) => {
    console.log("creditIndetailoff",data)
      this.CreditadvtOffInDFormBenas = data as any;
      // Reset totals
      // this.CreditadvtOffInDTotal = 0;

      // Calculate totals
      this.CreditadvtOffInDTotal = 0; // Reset total before summing

      for (let rep_data of this.CreditadvtOffInDFormBenas) {
          if (rep_data.total) {  // Ensure value exists
              this.CreditadvtOffInDTotal += parseFloat(rep_data.total) || 0;
          }
      }
      
  });
}
// getAdvtOutstanding() {
//   this.service.getAdvtOutstanding().subscribe((data: any) => {
//     console.log("outstanding",data)
//       this.OutstandingInDetailFormBeans = data as any;
      
//       this.OutStandingIDTotal = 0;
//       this.OutStandingIDNetAmtTotal = 0;
//       this.OutStandingIDUnPaidTotal = 0;
//       for (let data of this.OutstandingInDetailFormBeans) {
//         for(let data1 of data.rep_data){
//           this.OutStandingIDTotal += parseInt(data1.advt_amt_total) || 0;
//           this.OutStandingIDNetAmtTotal += parseInt(data1.net_amt_total) || 0;
//           this.OutStandingIDUnPaidTotal += parseInt(data1.unpaid_amt_total) || 0;
//         } 
//       }
//   });
// }

getReportSubscription(){
  
  debugger
  const req:any = {
    

    "off_rep_name":this.offRepName,
    "financialYear":this.date,
    "rep_name":this.repName,
    "district":this.districtName,
    "taluka":this.talukaName,
    "startDate":this.startDate,
    "endDate":this.endDate,
    "prant_name" : this.prant_name,
    "country":this.country,
    "state":this.state,

  }
  const req1:any = {
    
    "off_rep_name":this.offRepName,
    // "financialYear":this.date,
    "rep_name":this.repName,
    "district":this.districtName,
    "taluka":this.talukaName,
    "startDate":this.startDate,
    "endDate":this.endDate,
    "country":this.country,
    "state":this.state,
    "prant_name" : this.prant_name,

  }
  const req3:any = {
    
    // "financialYear":this.date,
    "rep_name":this.repName,
    "district":this.districtName,
    "taluka":this.talukaName,
    "country":this.country,
    "state":this.state,
    

  }
  const req4:any = {
    
    "off_rep_name":this.offRepName,
    "financialYear":this.date,
    "rep_name":this.repName,
    "district":this.districtName,
    "taluka":this.talukaName,
    "startDate":this.startDate,
    "endDate":this.endDate,
    "country":this.country,
    "state":this.state,
    
  }
  // const reqArray: any[] = Object.entries(req);
  // const payloadList: any[] = [];
  // payloadList.push(req1);

if(this.reportType=="Renewaljilha"){
  this.service.getSubByZilha(req).subscribe((data) => {
    this.SubByZilha = data;
    this.stateWiseList = this.groupByDistrict1(this.SubByZilha);
    // this.PaymentadvtOffInDTotal = 0;
    console.log("subbyjilha", data);
    this.totalzilhahapost = 0;
    this.totalzilhahahaste = 0;
    this. totalzilhahamagzine = 0;
    this.totalzilhahadigital = 0;
    this.totalzilhahatotal = 0;
    // month cal
    this.aprilpost = 0;
  this.aprilhaste  = 0;
  this.aprilmagzine = 0;
  this.aprildigital = 0;
  this.apriltotal  = 0;
  this.maypost = 0;
  this.mayhaste  = 0;
  this.maymagzine = 0;
  this.maydigital = 0;
  this.maytotal  = 0;
  this.junepost = 0;
  this.junehaste  = 0;
  this.junmagzine = 0;
  this.jundigital = 0;
  this.junetotal  = 0;
  this.julypost = 0;
  this.julyhaste  = 0;
  this.julymagzine = 0;
  this.julydigital = 0;
  this.julytotal  = 0;
  this.augpost = 0;
  this.aughaste  = 0;
  this.augmagzine = 0;
  this.augdigital = 0;
  this.augtotal  = 0;
  this.seppost = 0;
  this.sephaste  = 0;
  this.sepmagzine = 0;
  this.sepdigital = 0;
  this.septotal  = 0;
  this.octopost = 0;
  this.octohaste  = 0;
  this.octmagzine = 0;
  this.octdigital = 0;
  this.octototal  = 0;
  this.novpost = 0;
  this.novhaste  = 0;
  this.novmagzine = 0;
  this.novdigital = 0;
  this.novtotal  = 0;
  this.decpost = 0;
  this.dechaste  = 0;
  this.decmagzine = 0;
  this.decdigital = 0;
  this.dectotal  = 0;
  this.janpost = 0;
  this.janhaste  = 0;
  this.janmagzine = 0;
  this.jandigital = 0;
  this.jantotal  = 0;
  this.febpost = 0;
  this.febhaste  = 0;
  this.febmagzine = 0;
  this.febdigital = 0;
  this.febtotal  = 0;
  this.marchpost = 0;
  this.marchhaste  = 0;
  this.marchmagzine = 0;
  this.marchdigital = 0;
  this.marchtotal  = 0;

//------------------- State Wise Count ------------------------------------------------
for(let data1 of this.stateWiseList){

  data1.totalzilhahapost1 = 0;
  data1.totalzilhahahaste1 = 0;
  data1.totalzilhahamagzine1 = 0;
  data1.totalzilhahadigital1 = 0;
  data1.totalzilhahatotal1 = 0;

data1.aprilpost =0
data1.aprilhaste=0
data1.aprilmagzine=0
data1.aprildigital=0
data1.apriltotal=0
data1.maypost=0
data1.mayhaste=0
data1.maymagzine=0
data1.maydigital=0
data1.maytotal=0
data1.junepost=0
data1.junehaste=0
data1.junmagzine=0
data1.jundigital=0
data1.junetotal=0
data1.julypost=0
data1.julyhaste=0
data1.julymagzine=0
data1.julydigital=0
data1.julytotal=0
data1.augpost=0
data1.aughaste=0
data1.augmagzine=0
data1.augdigital=0
data1.augtotal=0
data1.seppost=0
data1.sephaste=0
data1.sepmagzine=0
data1.sepdigital=0
data1.septotal=0
data1.octopost=0
data1.octohaste=0
data1.octmagzine=0
data1.octdigital=0
data1.octototal=0
data1.novpost=0
data1.novhaste=0
data1.novmagzine=0
data1.novdigital=0
data1.novtotal=0
data1.decpost=0
data1.dechaste=0
data1.decmagzine=0
data1.decdigital=0
data1.dectotal=0
data1.janpost=0
data1.janhaste=0
data1.janmagzine=0
data1.jandigital=0
data1.jantotal=0
data1.febpost=0
data1.febhaste=0
data1.febmagzine=0
data1.febdigital=0
data1.febtotal=0
data1.marchpost=0
data1.marchhaste=0
data1.marchmagzine=0
data1.marchdigital=0
data1.marchtotal=0

data1.district.forEach(element => {
data1.aprilpost +=parseInt(element.april_post_count)
data1.aprilhaste +=parseInt(element.april_haste_count);
data1.aprilmagzine +=parseInt(element.april_magzine_count);
data1.aprildigital +=parseInt(element.april_digital_count);
data1.apriltotal +=parseInt(element.april_total);
data1.maypost +=parseInt(element.may_post_count);
data1.mayhaste +=parseInt(element.may_haste_count);
data1.maymagzine +=parseInt(element.may_magzine_count);
data1.maydigital +=parseInt(element.may_digital_count);
data1.maytotal +=parseInt(element.may_total);
data1.junepost +=parseInt(element.jun_post_count);
data1.junehaste +=parseInt(element.jun_haste_count);
data1.junmagzine +=parseInt(element.jun_magzine_count);
data1.jundigital +=parseInt(element.jun_digital_count);
data1.junetotal +=parseInt(element.jun_total);
data1.julypost +=parseInt(element.jul_post_count);
data1.julyhaste +=parseInt(element.jul_haste_count);
data1.julymagzine +=parseInt(element.jul_magzine_count);
data1.julydigital +=parseInt(element.jul_digital_count);
data1.julytotal +=parseInt(element.jul_total);
data1.augpost +=parseInt(element.aug_post_count);
data1.aughaste +=parseInt(element.aug_haste_count);
data1.augmagzine +=parseInt(element.aug_magzine_count);
data1.augdigital +=parseInt(element.aug_digital_count);
data1.augtotal +=parseInt(element.aug_total);
data1.seppost +=parseInt(element.sep_post_count);
data1.sephaste +=parseInt(element.sep_haste_count);
data1.sepmagzine +=parseInt(element.sep_magzine_count);
data1.sepdigital +=parseInt(element.sep_digital_count);
data1.septotal +=parseInt(element.sep_total);
data1.octopost+=parseInt(element.oct_post_count);
data1.octohaste +=parseInt(element.oct_haste_count);
data1.octmagzine +=parseInt(element.oct_magzine_count);
data1.octdigital +=parseInt(element.oct_digital_count);
data1.octototal +=parseInt(element.oct_total);
data1.novpost+=parseInt(element.nov_post_count);
data1.novhaste +=parseInt(element.nov_haste_count);
data1.novmagzine +=parseInt(element.nov_magzine_count);
data1.novdigital +=parseInt(element.nov_digital_count);
data1.novtotal +=parseInt(element.nov_total);
data1.decpost+=parseInt(element.dec_post_count);
data1.dechaste +=parseInt(element.dec_haste_count);
data1.decmagzine +=parseInt(element.dec_magzine_count);
data1.decdigital +=parseInt(element.dec_digital_count);

data1.dectotal +=parseInt(element.dec_total);
data1.janpost+=parseInt(element.jan_post_count);
data1.janhaste +=parseInt(element.jan_haste_count);
data1.janmagzine +=parseInt(element.jan_magzine_count);
data1.jandigital +=parseInt(element.jan_digital_count);
data1.jantotal +=parseInt(element.jan_total);
data1.febpost+=parseInt(element.feb_post_count);
data1.febhaste +=parseInt(element.feb_haste_count);
data1.febmagzine +=parseInt(element.feb_magzine_count);
data1.febdigital +=parseInt(element.feb_digital_count);
data1.febtotal +=parseInt(element.feb_total);
data1.marchpost+=parseInt(element.mar_post_count);
data1.marchhaste +=parseInt(element.mar_haste_count);
data1.marchmagzine +=parseInt(element.mar_magzine_count);
data1.marchdigital +=parseInt(element.mar_digital_count);
data1.marchtotal +=parseInt(element.mar_total);


data1.totalzilhahapost1 += parseInt(element.post_total)|| 0;
data1.totalzilhahahaste1 += parseInt(element.haste_total)|| 0;
data1.totalzilhahamagzine1 += parseInt(element.magzine_total)|| 0;
data1.totalzilhahadigital1 += parseInt(element.digital_total)|| 0;

data1.totalzilhahatotal1 += parseInt(element.grand_total)|| 0;

});

}   


     for (let data1 of this.SubByZilha) {
      // pending work
       
             this.totalzilhahapost += parseInt(data1.post_total)|| 0;
             this.totalzilhahahaste += parseInt(data1.haste_total)|| 0;
             this.totalzilhahamagzine += parseInt(data1.magzine_total)|| 0;
             this.totalzilhahadigital += parseInt(data1.digital_total)|| 0;

             this.totalzilhahatotal += parseInt(data1.grand_total)|| 0;

            
            //  month
            this.aprilpost +=parseInt(data1.april_post_count)
            this.aprilhaste +=parseInt(data1.april_haste_count);
            this.aprilmagzine +=parseInt(data1.april_magzine_count);
            this.aprildigital +=parseInt(data1.april_digital_count);
            this.apriltotal +=parseInt(data1.april_total);
            this.maypost+=parseInt(data1.may_post_count);
            this.mayhaste +=parseInt(data1.may_haste_count);
            this.maymagzine +=parseInt(data1.may_magzine_count);
            this.maydigital +=parseInt(data1.may_digital_count);
            this.maytotal +=parseInt(data1.may_total);
            this.junepost+=parseInt(data1.jun_post_count);
            this.junehaste +=parseInt(data1.jun_haste_count);
            this.junmagzine +=parseInt(data1.jun_magzine_count);
            this.jundigital +=parseInt(data1.jun_digital_count);
            this.junetotal +=parseInt(data1.jun_total);
            this.julypost+=parseInt(data1.jul_post_count);
            this.julyhaste +=parseInt(data1.jul_haste_count);
            this.julymagzine +=parseInt(data1.jul_magzine_count);
            this.julydigital +=parseInt(data1.jul_digital_count);
            this.julytotal +=parseInt(data1.jul_total);
            this.augpost+=parseInt(data1.aug_post_count);
            this.aughaste +=parseInt(data1.aug_haste_count);
            this.augmagzine +=parseInt(data1.aug_magzine_count);
            this.augdigital +=parseInt(data1.aug_digital_count);
            this.augtotal +=parseInt(data1.aug_total);
            this.seppost+=parseInt(data1.sep_post_count);
            this.sephaste +=parseInt(data1.sep_haste_count);
            this.sepmagzine +=parseInt(data1.sep_magzine_count);
            this.sepdigital +=parseInt(data1.sep_digital_count);
            this.septotal +=parseInt(data1.sep_total);
            this.octopost+=parseInt(data1.oct_post_count);
            this.octohaste +=parseInt(data1.oct_haste_count);
            this.octmagzine +=parseInt(data1.oct_magzine_count);
            this.octdigital +=parseInt(data1.oct_digital_count);
            this.octototal +=parseInt(data1.oct_total);
            this.novpost+=parseInt(data1.nov_post_count);
            this.novhaste +=parseInt(data1.nov_haste_count);
            this.novmagzine +=parseInt(data1.nov_magzine_count);
            this.novdigital +=parseInt(data1.nov_digital_count);
            this.novtotal +=parseInt(data1.nov_total);
            this.decpost+=parseInt(data1.dec_post_count);
            this.dechaste +=parseInt(data1.dec_haste_count);
            this.decmagzine +=parseInt(data1.dec_magzine_count);
            this.decdigital +=parseInt(data1.dec_digital_count);
           
            this.dectotal +=parseInt(data1.dec_total);
            this.janpost+=parseInt(data1.jan_post_count);
            this.janhaste +=parseInt(data1.jan_haste_count);
            this.janmagzine +=parseInt(data1.jan_magzine_count);
            this.jandigital +=parseInt(data1.jan_digital_count);
            this.jantotal +=parseInt(data1.jan_total);
            this.febpost+=parseInt(data1.feb_post_count);
            this.febhaste +=parseInt(data1.feb_haste_count);
            this.febmagzine +=parseInt(data1.feb_magzine_count);
            this.febdigital +=parseInt(data1.feb_digital_count);
            this.febtotal +=parseInt(data1.feb_total);
            this.marchpost+=parseInt(data1.mar_post_count);
            this.marchhaste +=parseInt(data1.mar_haste_count);
            this.marchmagzine +=parseInt(data1.mar_magzine_count);
            this.marchdigital +=parseInt(data1.mar_digital_count);
            this.marchtotal +=parseInt(data1.mar_total);
          
             console.log("total", this.PaymentadvtOffInDTotal)
            }
     });
}

//------------- Delivary Mode Zillha Briefly ---------------
if(this.reportType=="RenewaljilhaBriefly"){
  this.service.getSubByZilhaBriefly(req).subscribe((data) => {
  
    this.SubByZilhaBriefly = data;
    console.log("Zillha Data Briefly", data);
    

    this.total_april = 0;
    this.total_may = 0;
    this.total_jun = 0;
    this.total_jul = 0;
    this.total_aug = 0;
    this.total_sep = 0;
    this.total_oct = 0;
    this.total_nov = 0;
    this.total_dec = 0;
    this.total_jan = 0;
    this.total_feb = 0;
    this.total_mar = 0;
    this.total_grand_total = 0;


  for (let data of this.SubDelModeZilhaBriefly) {
    this.total_april   += parseInt(data.jan);
    this.total_may  += parseInt(data.feb);
    this.total_jun  += parseInt(data.mar);
    this.total_jul   += parseInt(data.april);
    this.total_aug  += parseInt(data.may);
    this.total_sep  += parseInt(data.jun);
    this.total_oct   +=parseInt(data.jul);
    this.total_nov   += parseInt(data.aug);
    this.total_dec  += parseInt(data.sep);
    this.total_jan   += parseInt(data.oct);
    this.total_feb  += parseInt(data.nov);
    this.total_mar  += parseInt(data.dec);
    this.total_grand_total   += parseInt(data.grand_total);
   
  }

    console.log("taluka renewal reming Briefly", data);
     });
}

if(this.reportType=="PrantwiseDeliverymode"){
  this.service.getDeliverytype(req).subscribe((data) => {
    this.getdeliverytype = data;
    this.prantWiseList = this.groupByPrantDistrict(this.getdeliverytype);
    // this.PaymentadvtOffInDTotal = 0;
    console.log("subbyjilha", data);
    this.totalzilhahapost = 0;
    this.totalzilhahahaste = 0;
    this.totalzilhahamagzine = 0;
    this.totalzilhahadigital = 0;
    this.totalzilhahatotal = 0;
    // month cal
    this.aprilpost = 0;
    this.aprilhaste  = 0;
    this.aprilmagzine = 0;
    this.aprildigital = 0;
    this.apriltotal  = 0;
    this.maypost = 0;
    this.mayhaste  = 0;
    this.maymagzine = 0;
    this.maydigital = 0;
    this.maytotal  = 0;
    this.junepost = 0;
    this.junehaste  = 0;
    this.junmagzine = 0;
    this.jundigital = 0;
    this.junetotal  = 0;
    this.julypost = 0;
    this.julyhaste  = 0;
    this.julymagzine = 0;
    this.julydigital = 0;
    this.julytotal  = 0;
    this.augpost = 0;
    this.aughaste  = 0;
    this.augmagzine = 0;
    this.augdigital = 0;
    this.augtotal  = 0;
    this.seppost = 0;
    this.sephaste  = 0;
    this.sepmagzine = 0;
    this.sepdigital = 0;
    this.septotal  = 0;
    this.octopost = 0;
    this.octohaste  = 0;
    this.octmagzine = 0;
    this.octdigital = 0;
    this.octototal  = 0;
    this.novpost = 0;
    this.novhaste  = 0;
    this.novmagzine = 0;
    this.novdigital = 0;
    this.novtotal  = 0;
    this.decpost = 0;
    this.dechaste  = 0;
    this.decmagzine = 0;
    this.decdigital = 0;
    this.dectotal  = 0;
    this.janpost = 0;
    this.janhaste  = 0;
    this.janmagzine = 0;
    this.jandigital = 0;
    this.jantotal  = 0;
    this.febpost = 0;
    this.febhaste  = 0;
    this.febmagzine = 0;
    this.febdigital = 0;
    this.febtotal  = 0;
    this.marchpost = 0;
    this.marchhaste  = 0;
    this.marchmagzine = 0;
    this.marchdigital = 0;
    this.marchtotal  = 0;

//------------------- Prant Wise Count ------------------------------------------------
for(let data1 of this.prantWiseList){

  data1.totalzilhahapost1 = 0;
  data1.totalzilhahahaste1 = 0;
  data1.totalzilhahamagzine1 = 0;
  data1.totalzilhahadigital1 = 0;
  data1.totalzilhahatotal1 = 0;

data1.aprilpost =0
data1.aprilhaste=0
data1.aprilmagzine=0
data1.aprildigital=0
data1.apriltotal=0
data1.maypost=0
data1.mayhaste=0
data1.maymagzine=0
data1.maydigital=0
data1.maytotal=0
data1.junepost=0
data1.junehaste=0
data1.junmagzine=0
data1.jundigital=0
data1.junetotal=0
data1.julypost=0
data1.julyhaste=0
data1.julymagzine=0
data1.julydigital=0
data1.julytotal=0
data1.augpost=0
data1.aughaste=0
data1.augmagzine=0
data1.augdigital=0
data1.augtotal=0
data1.seppost=0
data1.sephaste=0
data1.sepmagzine=0
data1.sepdigital=0
data1.septotal=0
data1.octopost=0
data1.octohaste=0
data1.octmagzine=0
data1.octdigital=0
data1.octototal=0
data1.novpost=0
data1.novhaste=0
data1.novmagzine=0
data1.novdigital=0
data1.novtotal=0
data1.decpost=0
data1.dechaste=0
data1.decmagzine=0
data1.decdigital=0
data1.dectotal=0
data1.janpost=0
data1.janhaste=0
data1.janmagzine=0
data1.jandigital=0
data1.jantotal=0
data1.febpost=0
data1.febhaste=0
data1.febmagzine=0
data1.febdigital=0
data1.febtotal=0
data1.marchpost=0
data1.marchhaste=0
data1.marchmagzine=0
data1.marchdigital=0
data1.marchtotal=0

data1.sub_data.forEach(element1 => {
  element1.sub_data.forEach(element => {
    
data1.aprilpost +=parseInt(element.april_post_count)
data1.aprilhaste +=parseInt(element.april_haste_count);
data1.aprilmagzine +=parseInt(element.april_magzine_count);
data1.aprildigital +=parseInt(element.april_digital_count);
data1.apriltotal +=parseInt(element.april_total);
data1.maypost +=parseInt(element.may_post_count);
data1.mayhaste +=parseInt(element.may_haste_count);
data1.maymagzine +=parseInt(element.may_magzine_count);
data1.maydigital +=parseInt(element.may_digital_count);
data1.maytotal +=parseInt(element.may_total);
data1.junepost +=parseInt(element.jun_post_count);
data1.junehaste +=parseInt(element.jun_haste_count);
data1.junmagzine +=parseInt(element.jun_magzine_count);
data1.jundigital +=parseInt(element.jun_digital_count);
data1.junetotal +=parseInt(element.jun_total);
data1.julypost +=parseInt(element.jul_post_count);
data1.julyhaste +=parseInt(element.jul_haste_count);
data1.julymagzine +=parseInt(element.jul_magzine_count);
data1.julydigital +=parseInt(element.jul_digital_count);
data1.julytotal +=parseInt(element.jul_total);
data1.augpost +=parseInt(element.aug_post_count);
data1.aughaste +=parseInt(element.aug_haste_count);
data1.augmagzine +=parseInt(element.aug_magzine_count);
data1.augdigital +=parseInt(element.aug_digital_count);
data1.augtotal +=parseInt(element.aug_total);
data1.seppost +=parseInt(element.sep_post_count);
data1.sephaste +=parseInt(element.sep_haste_count);
data1.sepmagzine +=parseInt(element.sep_magzine_count);
data1.sepdigital +=parseInt(element.sep_digital_count);
data1.septotal +=parseInt(element.sep_total);
data1.octopost+=parseInt(element.oct_post_count);
data1.octohaste +=parseInt(element.oct_haste_count);
data1.octmagzine +=parseInt(element.oct_magzine_count);
data1.octdigital +=parseInt(element.oct_digital_count);
data1.octototal +=parseInt(element.oct_total);
data1.novpost+=parseInt(element.nov_post_count);
data1.novhaste +=parseInt(element.nov_haste_count);
data1.novmagzine +=parseInt(element.nov_magzine_count);
data1.novdigital +=parseInt(element.nov_digital_count);
data1.novtotal +=parseInt(element.nov_total);
data1.decpost+=parseInt(element.dec_post_count);
data1.dechaste +=parseInt(element.dec_haste_count);
data1.decmagzine +=parseInt(element.dec_magzine_count);
data1.decdigital +=parseInt(element.dec_digital_count);

data1.dectotal +=parseInt(element.dec_total);
data1.janpost+=parseInt(element.jan_post_count);
data1.janhaste +=parseInt(element.jan_haste_count);
data1.janmagzine +=parseInt(element.jan_magzine_count);
data1.jandigital +=parseInt(element.jan_digital_count);
data1.jantotal +=parseInt(element.jan_total);
data1.febpost+=parseInt(element.feb_post_count);
data1.febhaste +=parseInt(element.feb_haste_count);
data1.febmagzine +=parseInt(element.feb_magzine_count);
data1.febdigital +=parseInt(element.feb_digital_count);
data1.febtotal +=parseInt(element.feb_total);
data1.marchpost+=parseInt(element.mar_post_count);
data1.marchhaste +=parseInt(element.mar_haste_count);
data1.marchmagzine +=parseInt(element.mar_magzine_count);
data1.marchdigital +=parseInt(element.mar_digital_count);
data1.marchtotal +=parseInt(element.mar_total);


data1.totalzilhahapost1 += parseInt(element.post_total)|| 0;
data1.totalzilhahahaste1 += parseInt(element.haste_total)|| 0;
data1.totalzilhahamagzine1 += parseInt(element.magzine_total)|| 0;
data1.totalzilhahadigital1 += parseInt(element.digital_total)|| 0;

data1.totalzilhahatotal1 += parseInt(element.grand_total)|| 0;

  });
});

}  

  for (let data of this.getdeliverytype){


     for (let data1 of data.sub_data) {
      // pending work
       
             this.totalzilhahapost += parseInt(data1.post_total)|| 0;
             this.totalzilhahahaste += parseInt(data1.haste_total)|| 0;
             this.totalzilhahamagzine += parseInt(data1.magzine_total)|| 0;
             this.totalzilhahadigital += parseInt(data1.digital_total)|| 0;

             this.totalzilhahatotal += parseInt(data1.grand_total)|| 0;

             this.aprilpost +=parseInt(data1.april_post_count)
             this.aprilhaste +=parseInt(data1.april_haste_count);
             this.aprilmagzine +=parseInt(data1.april_magzine_count);
             this.aprildigital +=parseInt(data1.april_digital_count);
             this.apriltotal +=parseInt(data1.april_total);
             this.maypost+=parseInt(data1.may_post_count);
             this.mayhaste +=parseInt(data1.may_haste_count);
             this.maymagzine +=parseInt(data1.may_magzine_count);
             this.maydigital +=parseInt(data1.may_digital_count);
             this.maytotal +=parseInt(data1.may_total);
             this.junepost+=parseInt(data1.jun_post_count);
             this.junehaste +=parseInt(data1.jun_haste_count);
             this.junmagzine +=parseInt(data1.jun_magzine_count);
             this.jundigital +=parseInt(data1.jun_digital_count);
             this.junetotal +=parseInt(data1.jun_total);
             this.julypost+=parseInt(data1.jul_post_count);
             this.julyhaste +=parseInt(data1.jul_haste_count);
             this.julymagzine +=parseInt(data1.jul_magzine_count);
             this.julydigital +=parseInt(data1.jul_digital_count);
             this.julytotal +=parseInt(data1.jul_total);
             this.augpost+=parseInt(data1.aug_post_count);
             this.aughaste +=parseInt(data1.aug_haste_count);
             this.augmagzine +=parseInt(data1.aug_magzine_count);
             this.augdigital +=parseInt(data1.aug_digital_count);
             this.augtotal +=parseInt(data1.aug_total);
             this.seppost+=parseInt(data1.sep_post_count);
             this.sephaste +=parseInt(data1.sep_haste_count);
             this.sepmagzine +=parseInt(data1.sep_magzine_count);
             this.sepdigital +=parseInt(data1.sep_digital_count);
             this.septotal +=parseInt(data1.sep_total);
             this.octopost+=parseInt(data1.oct_post_count);
             this.octohaste +=parseInt(data1.oct_haste_count);
             this.octmagzine +=parseInt(data1.oct_magzine_count);
             this.octdigital +=parseInt(data1.oct_digital_count);
             this.octototal +=parseInt(data1.oct_total);
             this.novpost+=parseInt(data1.nov_post_count);
             this.novhaste +=parseInt(data1.nov_haste_count);
             this.novmagzine +=parseInt(data1.nov_magzine_count);
             this.novdigital +=parseInt(data1.nov_digital_count);
             this.novtotal +=parseInt(data1.nov_total);
             this.decpost+=parseInt(data1.dec_post_count);
             this.dechaste +=parseInt(data1.dec_haste_count);
             this.decmagzine +=parseInt(data1.dec_magzine_count);
             this.decdigital +=parseInt(data1.dec_digital_count);
            
             this.dectotal +=parseInt(data1.dec_total);
             this.janpost+=parseInt(data1.jan_post_count);
             this.janhaste +=parseInt(data1.jan_haste_count);
             this.janmagzine +=parseInt(data1.jan_magzine_count);
             this.jandigital +=parseInt(data1.jan_digital_count);
             this.jantotal +=parseInt(data1.jan_total);
             this.febpost+=parseInt(data1.feb_post_count);
             this.febhaste +=parseInt(data1.feb_haste_count);
             this.febmagzine +=parseInt(data1.feb_magzine_count);
             this.febdigital +=parseInt(data1.feb_digital_count);
             this.febtotal +=parseInt(data1.feb_total);
             this.marchpost+=parseInt(data1.mar_post_count);
             this.marchhaste +=parseInt(data1.mar_haste_count);
             this.marchmagzine +=parseInt(data1.mar_magzine_count);
             this.marchdigital +=parseInt(data1.mar_digital_count);
             this.marchtotal +=parseInt(data1.mar_total);
             console.log("total", this.PaymentadvtOffInDTotal)
            }}
     });
}

// if(this.reportType=="RemainingSubscriptionDistrict"){
//   this.service.getSubRemDist(req).subscribe((data) => {
//     this.SubRemDistrict = data;
//     // this.PaymentadvtOffInDTotal = 0;
//   this.tapal_Total_4 = 0;
//   this.haste_Total_4 = 0;
//   this.tapal_Total_5 = 0;
//   this.haste_Total_5 = 0;
//   this.tapal_Total_6 = 0;
//   this.haste_Total_6 = 0;
//   this.tapal_Total_7 = 0;
//   this.haste_Total_7 = 0;
//   this.tapal_Total_8 = 0;
//   this.haste_Total_8 = 0;
//   this.tapal_Total_9 = 0;
//   this.haste_Total_9 = 0;
//   this.tapal_Total_10 = 0;
//   this.haste_Total_10 = 0;
//   this.tapal_Total_11 = 0;
//   this.haste_Total_11 = 0;
//   this.tapal_Total_12 = 0;
//   this.haste_Total_12 = 0;
//   this.tapal_Total_1 = 0;
//   this.haste_Total_1 = 0;
//   this.tapal_Total_2 = 0;
//   this.haste_Total_2 = 0;
//   this.tapal_Total_3 = 0;
//   this.haste_Total_3 = 0;
//   this.tapal_New_4 = 0;
//   this.tapal_Renew_4 = 0;
//   this.haste_New_4 = 0;
//   this.haste_Renew_4 = 0;
//   this.tapal_New_5  = 0;
//   this.tapal_Renew_5 = 0;
//   this.tapal_New_6  = 0;
//   this.tapal_Renew_6 = 0;
//   this.tapal_New_7  = 0;
//   this.tapal_Renew_7 = 0;
//   this.tapal_New_8  = 0;
//   this.tapal_Renew_8 = 0;
//   this.tapal_New_9  = 0;
//   this.tapal_Renew_9 = 0;
//   this.tapal_New_10  = 0;
//   this.tapal_Renew_10 = 0;
//   this.tapal_New_11  = 0;
//   this.tapal_Renew_11 = 0;
//   this.tapal_New_12  = 0;
//   this.tapal_Renew_12 = 0;
//   this.tapal_New_1  = 0;
//   this.tapal_Renew_1 = 0;
//   this.tapal_New_2  = 0;
//   this.tapal_Renew_2 = 0;
//   this.tapal_New_3  = 0;
//   this.tapal_Renew_3 = 0;
//   this.haste_New_5 = 0;
//   this.haste_Renew_5 = 0;
//   this.haste_New_6 = 0;
//   this.haste_Renew_6 = 0;
//   this.haste_New_7 = 0;
//   this.haste_Renew_7 = 0;
//   this.haste_New_8 = 0;
//   this.haste_Renew_8 = 0;
//   this.haste_New_9 = 0;
//   this.haste_Renew_9 = 0;
//   this.haste_New_10 = 0;
//   this.haste_Renew_10 = 0;
//   this.haste_New_11 = 0;
//   this.haste_Renew_11 = 0;
//   this.haste_New_12 = 0;
//   this.haste_Renew_12 = 0;
//   this.haste_New_1 = 0;
//   this.haste_Renew_1 = 0;
//   this.haste_New_2 = 0;
//   this.haste_Renew_2 = 0;
//   this.haste_New_3 = 0;
//   this.haste_Renew_3 = 0;
//   this.haste_Renew_10 = 0;
//   this.haste_New_11 = 0;
//   this.haste_Renew_11 = 0;
//   this.haste_New_12 = 0;
//   this.haste_Renew_12 = 0;
//   this.haste_New_1 = 0;
//   this.haste_Renew_1 = 0;
//   this.haste_New_2 = 0;
//   this.haste_Renew_2 = 0;
//   this.haste_New_3 = 0;
//   this.haste_Renew_3 = 0;
//   this.haste_Renew_10 = 0;
//   this.haste_New_11 = 0;
//   this.haste_Renew_11 = 0;
//   this.haste_New_12 = 0;
//   this.haste_Renew_12 = 0;
//   this.haste_New_1 = 0;
//   this.haste_Renew_1 = 0;
//   this.haste_New_2 = 0;
//   this.haste_Renew_2 = 0;
//   this.haste_New_3 = 0;
//   this.haste_Renew_3 = 0;
//   this.haste_Renew_10 = 0;
//   this.haste_New_11 = 0;
//   this.haste_Renew_11 = 0;
//   this.haste_New_12 = 0;
//   this.haste_Renew_12 = 0;
//   this.haste_New_1 = 0;
//   this.haste_Renew_1 = 0;
//   this.haste_New_2 = 0;
//   this.haste_Renew_2 = 0;
//   this.haste_New_3 = 0;
//   this.haste_Renew_3 = 0;
//   this.magzine_New_4  =0;
//     this.magzine_Renew_4 =0;
//     this.magzine_Total_4 =0;
//     this.magzine_New_5  =0;
//     this.magzine_Renew_5 =0;
//     this.magzine_Total_5 =0;
//     this.magzine_New_6  =0;
//     this.magzine_Renew_6 =0;
//     this.magzine_Total_6 =0;
//     this.magzine_New_7  =0;
//     this.magzine_Renew_7 =0;
//     this.magzine_Total_7 =0;
//     this.magzine_New_8  =0;
//     this.magzine_Renew_8 =0;
//     this.magzine_Total_8 =0;
//     this.magzine_New_9  =0;
//     this.magzine_Renew_9 =0;
//     this.magzine_Total_9 =0;
//     this.magzine_New_10  =0;
//     this.magzine_Renew_10=0;
//     this.magzine_Total_10 =0;
//     this.magzine_New_11  =0;
//     this.magzine_Renew_11 =0;
//     this.magzine_Total_11 =0;
//     this.magzine_New_12  =0;
//     this.magzine_Renew_12 =0;
//     this.magzine_Total_12 =0;
//     this.magzine_New_1  =0;
//     this.magzine_Renew_1 =0;
//     this.magzine_Total_1 =0;
//     this.magzine_New_2  =0;
//     this.magzine_Renew_2 =0;
//     this.magzine_Total_2 =0;
//     this.magzine_New_3  =0;
//     this.magzine_Renew_3 =0;
//     this.magzine_Total_3 =0;

//   this.digital_New_4  = 0;
// this.digital_Renew_4 = 0;
// this.digital_Total_4 = 0;
// this.digital_New_5  = 0;
// this.digital_Renew_5 = 0;
// this.digital_Total_5 = 0;
// this.digital_New_6  = 0;
// this.digital_Renew_6 = 0;
// this.digital_Total_6 = 0;
// this.digital_New_7  = 0;
// this.digital_Renew_7 = 0;
// this.digital_Total_7 = 0;
// this.digital_New_8  = 0;
// this.digital_Renew_8 = 0;
// this.digital_Total_8 = 0;
// this.digital_New_9  = 0;
// this.digital_Renew_9 = 0;
// this.digital_Total_9 = 0;
// this.digital_New_10  = 0;
// this.digital_Renew_10 = 0;
// this.digital_Total_10 = 0;
// this.digital_New_11  = 0;
// this.digital_Renew_11 = 0;
// this.digital_Total_11 = 0;
// this.digital_New_12  = 0;
// this.digital_Renew_12 = 0;
// this.digital_Total_12 = 0;
// this.digital_New_1  = 0;
// this.digital_Renew_1 = 0;
// this.digital_Total_1 = 0;
// this.digital_New_2  = 0;
// this.digital_Renew_2 = 0;
// this.digital_Total_2 = 0;
// this.digital_New_3  = 0;
// this.digital_Renew_3 = 0;
// this.digital_Total_3 = 0;
// this.tapal_New_total = 0;
// this.tapal_Renew_total = 0;
// this.tapal_Total_total = 0;
// this.haste_New_total = 0;
// this.haste_Renew_total = 0;
// this.haste_Total_total = 0;
// this.magzine_New_total = 0;
// this.magzine_Renew_total = 0;
// this.magzine_Total_total = 0;
// this.digital_New_total  = 0;
// this.digital_Renew_total = 0;
// this.digital_Total_total = 0;
//   debugger

  

//   for (let data2 of this.SubRemDistrict) {
//     debugger

//     this.tapal_New_4 += parseInt(data2.tapal_New_4);
//     this.tapal_Renew_4 += parseInt(data2.tapal_Renew_4);
//     this.tapal_Total_4 += parseInt(data2.tapal_Total_4);
//     this.tapal_New_5 += parseInt(data2.tapal_New_5);
//     this.tapal_Renew_5 += parseInt(data2.tapal_Renew_5);
//     this.tapal_Total_5 += parseInt(data2.tapal_Total_5);
//     this.tapal_New_6 += parseInt(data2.tapal_New_6);
//     this.tapal_Renew_6 += parseInt(data2.tapal_Renew_6);
//     this.tapal_Total_6 += parseInt(data2.tapal_Total_6);
//     this.tapal_New_7 += parseInt(data2.tapal_New_7);
//     this.tapal_Renew_7 += parseInt(data2.tapal_Renew_7);
//     this.tapal_Total_7 += parseInt(data2.tapal_Total_7);
//     this.tapal_New_8 += parseInt(data2.tapal_New_8);
//     this.tapal_Renew_8 += parseInt(data2.tapal_Renew_8);
//     this.tapal_Total_8 += parseInt(data2.tapal_Total_8);
//     this.tapal_New_9 += parseInt(data2.tapal_New_9);
//     this.tapal_Renew_9 += parseInt(data2.tapal_Renew_9);
//     this.tapal_Total_9 += parseInt(data2.tapal_Total_9);
//     this.tapal_New_10 += parseInt(data2.tapal_New_10);
//     this.tapal_Renew_10 += parseInt(data2.tapal_Renew_10);
//     this.tapal_Total_10 += parseInt(data2.tapal_Total_10);
//     this.tapal_New_11 += parseInt(data2.tapal_New_11);
//     this.tapal_Renew_11 += parseInt(data2.tapal_Renew_11);
//     this.tapal_Total_11 += parseInt(data2.tapal_Total_11);
//     this.tapal_New_12 += parseInt(data2.tapal_New_12);
//     this.tapal_Renew_12 += parseInt(data2.tapal_Renew_12);
//     this.tapal_Total_12 += parseInt(data2.tapal_Total_12);
//     this.tapal_New_1 += parseInt(data2.tapal_New_1);
//     this.tapal_Renew_1 += parseInt(data2.tapal_Renew_1);
//     this.tapal_Total_1 += parseInt(data2.tapal_Total_1);
//     this.tapal_New_2 += parseInt(data2.tapal_New_2);
//     this.tapal_Renew_2 += parseInt(data2.tapal_Renew_2);
//     this.tapal_Total_2 += parseInt(data2.tapal_Total_2);
//     this.tapal_New_3 += parseInt(data2.tapal_New_3);
//     this.tapal_Renew_3 += parseInt(data2.tapal_Renew_3);
//     this.tapal_Total_3 += parseInt(data2.tapal_Total_3);

//     this.haste_New_4  += parseInt(data2.haste_New_4);
//     this.haste_Renew_4 += parseInt(data2.haste_Renew_4);
//     this.haste_Total_4 += parseInt(data2.haste_Total_4);
//     this.haste_New_5  += parseInt(data2.haste_New_5);
//     this.haste_Renew_5 += parseInt(data2.haste_Renew_5);
//     this.haste_Total_5 += parseInt(data2.haste_Total_5);
//     this.haste_New_6  += parseInt(data2.haste_New_6);
//     this.haste_Renew_6 += parseInt(data2.haste_Renew_6);
//     this.haste_Total_6 += parseInt(data2.haste_Total_6);
//     this.haste_New_7  += parseInt(data2.haste_New_7);
//     this.haste_Renew_7 += parseInt(data2.haste_Renew_7);
//     this.haste_Total_7 += parseInt(data2.haste_Total_7);
//     this.haste_New_8  += parseInt(data2.haste_New_8);
//     this.haste_Renew_8 += parseInt(data2.haste_Renew_8);
//     this.haste_Total_8 += parseInt(data2.haste_Total_8);
//     this.haste_New_9  += parseInt(data2.haste_New_9);
//     this.haste_Renew_9 += parseInt(data2.haste_Renew_9);
//     this.haste_Total_9 += parseInt(data2.haste_Total_9);
//     this.haste_New_10  += parseInt(data2.haste_New_10);
//     this.haste_Renew_10 += parseInt(data2.haste_Renew_10);
//     this.haste_Total_10 += parseInt(data2.haste_Total_10);
//     this.haste_New_11  += parseInt(data2.haste_New_11);
//     this.haste_Renew_11 += parseInt(data2.haste_Renew_11);
//     this.haste_Total_11 += parseInt(data2.haste_Total_11);
//     this.haste_New_12  += parseInt(data2.haste_New_12);
//     this.haste_Renew_12 += parseInt(data2.haste_Renew_12);
//     this.haste_Total_12 += parseInt(data2.haste_Total_12);
//     this.haste_New_1  += parseInt(data2.haste_New_1);
//     this.haste_Renew_1 += parseInt(data2.haste_Renew_1);
//     this.haste_Total_1 += parseInt(data2.haste_Total_1);
//     this.haste_New_2  += parseInt(data2.haste_New_2);
//     this.haste_Renew_2 += parseInt(data2.haste_Renew_2);
//     this.haste_Total_2 += parseInt(data2.haste_Total_2);
//     this.haste_New_3  += parseInt(data2.haste_New_3);
//     this.haste_Renew_3 += parseInt(data2.haste_Renew_3);
//     this.haste_Total_3 += parseInt(data2.haste_Total_3);

//     this.magzine_New_4  += parseInt(data2.magzine_New_4);
// this.magzine_Renew_4 += parseInt(data2.magzine_Renew_4);
// this.magzine_Total_4 += parseInt(data2.magzine_Total_4);
// this.magzine_New_5  += parseInt(data2.magzine_New_5);
// this.magzine_Renew_5 += parseInt(data2.magzine_Renew_5);
// this.magzine_Total_5 += parseInt(data2.magzine_Total_5);
// this.magzine_New_6  += parseInt(data2.magzine_New_6);
// this.magzine_Renew_6 += parseInt(data2.magzine_Renew_6);
// this.magzine_Total_6 += parseInt(data2.magzine_Total_6);
// this.magzine_New_7  += parseInt(data2.magzine_New_7);
// this.magzine_Renew_7 += parseInt(data2.magzine_Renew_7);
// this.magzine_Total_7 += parseInt(data2.magzine_Total_7);
// this.magzine_New_8  += parseInt(data2.magzine_New_8);
// this.magzine_Renew_8 += parseInt(data2.magzine_Renew_8);
// this.magzine_Total_8 += parseInt(data2.magzine_Total_8);
// this.magzine_New_9  += parseInt(data2.magzine_New_9);
// this.magzine_Renew_9 += parseInt(data2.magzine_Renew_9);
// this.magzine_Total_9 += parseInt(data2.magzine_Total_9);
// this.magzine_New_10  += parseInt(data2.magzine_New_10);
// this.magzine_Renew_10 += parseInt(data2.magzine_Renew_10);
// this.magzine_Total_10 += parseInt(data2.magzine_Total_10);
// this.magzine_New_11  += parseInt(data2.magzine_New_11);
// this.magzine_Renew_11 += parseInt(data2.magzine_Renew_11);
// this.magzine_Total_11 += parseInt(data2.magzine_Total_11);
// this.magzine_New_12  += parseInt(data2.magzine_New_12);
// this.magzine_Renew_12 += parseInt(data2.magzine_Renew_12);
// this.magzine_Total_12 += parseInt(data2.magzine_Total_12);
// this.magzine_New_1  += parseInt(data2.magzine_New_1);
// this.magzine_Renew_1 += parseInt(data2.magzine_Renew_1);
// this.magzine_Total_1 += parseInt(data2.magzine_Total_1);
// this.magzine_New_2  += parseInt(data2.magzine_New_2);
// this.magzine_Renew_2 += parseInt(data2.magzine_Renew_2);
// this.magzine_Total_2 += parseInt(data2.magzine_Total_2);
// this.magzine_New_3  += parseInt(data2.magzine_New_3);
// this.magzine_Renew_3 += parseInt(data2.magzine_Renew_3);
// this.magzine_Total_3 += parseInt(data2.magzine_Total_3);

// this.digital_New_4  += parseInt(data2.digital_New_4);
// this.digital_Renew_4 += parseInt(data2.digital_Renew_4);
// this.digital_Total_4 += parseInt(data2.digital_Total_4);
// this.digital_New_5  += parseInt(data2.digital_New_5);
// this.digital_Renew_5 += parseInt(data2.digital_Renew_5);
// this.digital_Total_5 += parseInt(data2.digital_Total_5);
// this.digital_New_6  += parseInt(data2.digital_New_6);
// this.digital_Renew_6 += parseInt(data2.digital_Renew_6);
// this.digital_Total_6 += parseInt(data2.digital_Total_6);
// this.digital_New_7  += parseInt(data2.digital_New_7);
// this.digital_Renew_7 += parseInt(data2.digital_Renew_7);
// this.digital_Total_7 += parseInt(data2.digital_Total_7);
// this.digital_New_8  += parseInt(data2.digital_New_8);
// this.digital_Renew_8 += parseInt(data2.digital_Renew_8);
// this.digital_Total_8 += parseInt(data2.digital_Total_8);
// this.digital_New_9  += parseInt(data2.digital_New_9);
// this.digital_Renew_9 += parseInt(data2.digital_Renew_9);
// this.digital_Total_9 += parseInt(data2.digital_Total_9);
// this.digital_New_10  += parseInt(data2.digital_New_10);
// this.digital_Renew_10 += parseInt(data2.digital_Renew_10);
// this.digital_Total_10 += parseInt(data2.digital_Total_10);
// this.digital_New_11  += parseInt(data2.digital_New_11);
// this.digital_Renew_11 += parseInt(data2.digital_Renew_11);
// this.digital_Total_11 += parseInt(data2.digital_Total_11);
// this.digital_New_12  += parseInt(data2.digital_New_12);
// this.digital_Renew_12 += parseInt(data2.digital_Renew_12);
// this.digital_Total_12 += parseInt(data2.digital_Total_12);
// this.digital_New_1  += parseInt(data2.digital_New_1);
// this.digital_Renew_1 += parseInt(data2.digital_Renew_1);
// this.digital_Total_1 += parseInt(data2.digital_Total_1);
// this.digital_New_2  += parseInt(data2.digital_New_2);
// this.digital_Renew_2 += parseInt(data2.digital_Renew_2);
// this.digital_Total_2 += parseInt(data2.digital_Total_2);
// this.digital_New_3  += parseInt(data2.digital_New_3);
// this.digital_Renew_3 += parseInt(data2.digital_Renew_3);
// this.digital_Total_3 += parseInt(data2.digital_Total_3);

// this.magzine_New_total += parseInt(data2.magzine_New_total);
// this.magzine_Renew_total += parseInt(data2.magzine_Renew_total);
// this.magzine_Total_total += parseInt(data2.magzine_Total_total);

// this.tapal_New_total += parseInt(data2.tapal_New_total);
// this.tapal_Renew_total += parseInt(data2.tapal_Renew_total);
// this.tapal_Total_total += parseInt(data2.tapal_Total_total);
// this.haste_New_total  += parseInt(data2.haste_New_total);
// this.haste_Renew_total += parseInt(data2.haste_Renew_total);
// this.haste_Total_total += parseInt(data2.haste_Total_total);
// // this.magzine_New_total  += parseInt(data2.magzine_New_Total);
// // this.magzine_Renew_total += parseInt(data2.magzine_Renew_Total);
// // this.magzine_Total_total += parseInt(data2.magzine_Total_Total);
// this.digital_New_total  += parseInt(data2.digital_New_total);
// this.digital_Renew_total += parseInt(data2.digital_Renew_total);
// this.digital_Total_total += parseInt(data2.digital_Total_total);




    
//     //   data2.haste_Total_5 = parseInt(data2.haste_Renew_5) + parseInt(data2.haste_New_5);
  
//     //   data2.tapal_Total_6 = parseInt(data2.tapal_Renew_6) + parseInt(data2.tapal_New_6);
//     //   data2.haste_Total_6 = parseInt(data2.haste_Renew_6) + parseInt(data2.haste_New_6);
  
//     //   data2.tapal_Total_7 = parseInt(data2.tapal_Renew_7) + parseInt(data2.tapal_New_7);
//     //   data2.haste_Total_7 = parseInt(data2.haste_Renew_7) + parseInt(data2.haste_New_7);
  
//     //   data2.tapal_Total_8 = parseInt(data2.tapal_Renew_8) + parseInt(data2.tapal_New_8);
//     //   data2.haste_Total_8 = parseInt(data2.haste_Renew_8) + parseInt(data2.haste_New_8);
  
//     //   data2.tapal_Total_9 = parseInt(data2.tapal_Renew_9) + parseInt(data2.tapal_New_9);
//     //   data2.haste_Total_9 = parseInt(data2.haste_Renew_9) + parseInt(data2.haste_New_9);
  
//     //   data2.tapal_Total_10 = parseInt(data2.tapal_Renew_10) + parseInt(data2.tapal_New_10);
//     //   data2.haste_Total_10 = parseInt(data2.haste_Renew_10) + parseInt(data2.haste_New_10);
  
//     //   data2.tapal_Total_11 = parseInt(data2.tapal_Renew_11) + parseInt(data2.tapal_New_11);
//     //   data2.haste_Total_11 = parseInt(data2.haste_Renew_11) + parseInt(data2.haste_New_11);
  
//     //   data2.tapal_Total_12 = parseInt(data2.tapal_Renew_12) + parseInt(data2.tapal_New_12);
//     //   data2.haste_Total_12 = parseInt(data2.haste_Renew_12) + parseInt(data2.haste_New_12);
  
//     //   data2.tapal_Total_1 = parseInt(data2.tapal_Renew_1) + parseInt(data2.tapal_New_1);
//     //   data2.haste_Total_1 = parseInt(data2.haste_Renew_1) + parseInt(data2.haste_New_1);
  
//     //   data2.tapal_Total_2  = parseInt(data2.tapal_Renew_2) + parseInt(data2.tapal_New_2);
//     //   data2.haste_Total_2  = parseInt(data2.haste_Renew_2) + parseInt(data2.haste_New_2);
  
//     //   data2.tapal_Total_3  = parseInt(data2.tapal_Renew_3) + parseInt(data2.tapal_New_3);
//     //   data2.haste_Total_3  = parseInt(data2.haste_Renew_3) + parseInt(data2.haste_New_3);
//     // // const total = this.haste_Total_3 + this.tapal_Total_3

//     data2.grandremtotal =  parseInt(data2.haste_Total_3) + parseInt(data2.tapal_Total_3);
//     console.log(data2.grandremtotal);  




//   }


//     console.log("subbyjilha renewal reming", data);
//      });
// }


if(this.reportType=="RemainingSubscriptionDistrict"){
  this.service.getSubRemDist(req).subscribe((data) => {
    this.SubRemDistrict = data;
    // this.PaymentadvtOffInDTotal = 0;
  this.tapal_Total_4 = 0;
  this.haste_Total_4 = 0;
  this.tapal_Total_5 = 0;
  this.haste_Total_5 = 0;
  this.tapal_Total_6 = 0;
  this.haste_Total_6 = 0;
  this.tapal_Total_7 = 0;
  this.haste_Total_7 = 0;
  this.tapal_Total_8 = 0;
  this.haste_Total_8 = 0;
  this.tapal_Total_9 = 0;
  this.haste_Total_9 = 0;
  this.tapal_Total_10 = 0;
  this.haste_Total_10 = 0;
  this.tapal_Total_11 = 0;
  this.haste_Total_11 = 0;
  this.tapal_Total_12 = 0;
  this.haste_Total_12 = 0;
  this.tapal_Total_1 = 0;
  this.haste_Total_1 = 0;
  this.tapal_Total_2 = 0;
  this.haste_Total_2 = 0;
  this.tapal_Total_3 = 0;
  this.haste_Total_3 = 0;
  this.tapal_New_4 = 0;
  this.tapal_Renew_4 = 0;
  this.haste_New_4 = 0;
  this.haste_Renew_4 = 0;
  this.tapal_New_5  = 0;
  this.tapal_Renew_5 = 0;
  this.tapal_New_6  = 0;
  this.tapal_Renew_6 = 0;
  this.tapal_New_7  = 0;
  this.tapal_Renew_7 = 0;
  this.tapal_New_8  = 0;
  this.tapal_Renew_8 = 0;
  this.tapal_New_9  = 0;
  this.tapal_Renew_9 = 0;
  this.tapal_New_10  = 0;
  this.tapal_Renew_10 = 0;
  this.tapal_New_11  = 0;
  this.tapal_Renew_11 = 0;
  this.tapal_New_12  = 0;
  this.tapal_Renew_12 = 0;
  this.tapal_New_1  = 0;
  this.tapal_Renew_1 = 0;
  this.tapal_New_2  = 0;
  this.tapal_Renew_2 = 0;
  this.tapal_New_3  = 0;
  this.tapal_Renew_3 = 0;
  this.haste_New_5 = 0;
  this.haste_Renew_5 = 0;
  this.haste_New_6 = 0;
  this.haste_Renew_6 = 0;
  this.haste_New_7 = 0;
  this.haste_Renew_7 = 0;
  this.haste_New_8 = 0;
  this.haste_Renew_8 = 0;
  this.haste_New_9 = 0;
  this.haste_Renew_9 = 0;
  this.haste_New_10 = 0;
  this.haste_Renew_10 = 0;
  this.haste_New_11 = 0;
  this.haste_Renew_11 = 0;
  this.haste_New_12 = 0;
  this.haste_Renew_12 = 0;
  this.haste_New_1 = 0;
  this.haste_Renew_1 = 0;
  this.haste_New_2 = 0;
  this.haste_Renew_2 = 0;
  this.haste_New_3 = 0;
  this.haste_Renew_3 = 0;
  this.haste_Renew_10 = 0;
  this.haste_New_11 = 0;
  this.haste_Renew_11 = 0;
  this.haste_New_12 = 0;
  this.haste_Renew_12 = 0;
  this.haste_New_1 = 0;
  this.haste_Renew_1 = 0;
  this.haste_New_2 = 0;
  this.haste_Renew_2 = 0;
  this.haste_New_3 = 0;
  this.haste_Renew_3 = 0;
  this.haste_Renew_10 = 0;
  this.haste_New_11 = 0;
  this.haste_Renew_11 = 0;
  this.haste_New_12 = 0;
  this.haste_Renew_12 = 0;
  this.haste_New_1 = 0;
  this.haste_Renew_1 = 0;
  this.haste_New_2 = 0;
  this.haste_Renew_2 = 0;
  this.haste_New_3 = 0;
  this.haste_Renew_3 = 0;
  this.haste_Renew_10 = 0;
  this.haste_New_11 = 0;
  this.haste_Renew_11 = 0;
  this.haste_New_12 = 0;
  this.haste_Renew_12 = 0;
  this.haste_New_1 = 0;
  this.haste_Renew_1 = 0;
  this.haste_New_2 = 0;
  this.haste_Renew_2 = 0;
  this.haste_New_3 = 0;
  this.haste_Renew_3 = 0;
  this.magzine_New_4  =0;
    this.magzine_Renew_4 =0;
    this.magzine_Total_4 =0;
    this.magzine_New_5  =0;
    this.magzine_Renew_5 =0;
    this.magzine_Total_5 =0;
    this.magzine_New_6  =0;
    this.magzine_Renew_6 =0;
    this.magzine_Total_6 =0;
    this.magzine_New_7  =0;
    this.magzine_Renew_7 =0;
    this.magzine_Total_7 =0;
    this.magzine_New_8  =0;
    this.magzine_Renew_8 =0;
    this.magzine_Total_8 =0;
    this.magzine_New_9  =0;
    this.magzine_Renew_9 =0;
    this.magzine_Total_9 =0;
    this.magzine_New_10  =0;
    this.magzine_Renew_10=0;
    this.magzine_Total_10 =0;
    this.magzine_New_11  =0;
    this.magzine_Renew_11 =0;
    this.magzine_Total_11 =0;
    this.magzine_New_12  =0;
    this.magzine_Renew_12 =0;
    this.magzine_Total_12 =0;
    this.magzine_New_1  =0;
    this.magzine_Renew_1 =0;
    this.magzine_Total_1 =0;
    this.magzine_New_2  =0;
    this.magzine_Renew_2 =0;
    this.magzine_Total_2 =0;
    this.magzine_New_3  =0;
    this.magzine_Renew_3 =0;
    this.magzine_Total_3 =0;

  this.digital_New_4  = 0;
this.digital_Renew_4 = 0;
this.digital_Total_4 = 0;
this.digital_New_5  = 0;
this.digital_Renew_5 = 0;
this.digital_Total_5 = 0;
this.digital_New_6  = 0;
this.digital_Renew_6 = 0;
this.digital_Total_6 = 0;
this.digital_New_7  = 0;
this.digital_Renew_7 = 0;
this.digital_Total_7 = 0;
this.digital_New_8  = 0;
this.digital_Renew_8 = 0;
this.digital_Total_8 = 0;
this.digital_New_9  = 0;
this.digital_Renew_9 = 0;
this.digital_Total_9 = 0;
this.digital_New_10  = 0;
this.digital_Renew_10 = 0;
this.digital_Total_10 = 0;
this.digital_New_11  = 0;
this.digital_Renew_11 = 0;
this.digital_Total_11 = 0;
this.digital_New_12  = 0;
this.digital_Renew_12 = 0;
this.digital_Total_12 = 0;
this.digital_New_1  = 0;
this.digital_Renew_1 = 0;
this.digital_Total_1 = 0;
this.digital_New_2  = 0;
this.digital_Renew_2 = 0;
this.digital_Total_2 = 0;
this.digital_New_3  = 0;
this.digital_Renew_3 = 0;
this.digital_Total_3 = 0;
this.tapal_New_total = 0;
this.tapal_Renew_total = 0;
this.tapal_Total_total = 0;
this.haste_New_total = 0;
this.haste_Renew_total = 0;
this.haste_Total_total = 0;
this.magzine_New_total = 0;
this.magzine_Renew_total = 0;
this.magzine_Total_total = 0;
this.digital_New_total  = 0;
this.digital_Renew_total = 0;
this.digital_Total_total = 0;

  for (let dataset of this.SubRemDistrict) {

    
dataset.tapal_Total_4 = 0;
dataset.haste_Total_4 = 0;
dataset.tapal_Total_5 = 0;
dataset.haste_Total_5 = 0;
dataset.tapal_Total_6 = 0;
dataset.haste_Total_6 = 0;
dataset.tapal_Total_7 = 0;
dataset.haste_Total_7 = 0;
dataset.tapal_Total_8 = 0;
dataset.haste_Total_8 = 0;
dataset.tapal_Total_9 = 0;
dataset.haste_Total_9 = 0;
dataset.tapal_Total_10 = 0;
dataset.haste_Total_10 = 0;
dataset.tapal_Total_11 = 0;
dataset.haste_Total_11 = 0;
dataset.tapal_Total_12 = 0;
dataset.haste_Total_12 = 0;
dataset.tapal_Total_1 = 0;
dataset.haste_Total_1 = 0;
dataset.tapal_Total_2 = 0;
dataset.haste_Total_2 = 0;
dataset.tapal_Total_3 = 0;
dataset.haste_Total_3 = 0;
dataset.tapal_New_4 = 0;
dataset.tapal_Renew_4 = 0;
dataset.haste_New_4 = 0;
dataset.haste_Renew_4 = 0;
dataset.tapal_New_5  = 0;
dataset.tapal_Renew_5 = 0;
dataset.tapal_New_6  = 0;
dataset.tapal_Renew_6 = 0;
dataset.tapal_New_7  = 0;
dataset.tapal_Renew_7 = 0;
dataset.tapal_New_8  = 0;
dataset.tapal_Renew_8 = 0;
dataset.tapal_New_9  = 0;
dataset.tapal_Renew_9 = 0;
dataset.tapal_New_10  = 0;
dataset.tapal_Renew_10 = 0;
dataset.tapal_New_11  = 0;
dataset.tapal_Renew_11 = 0;
dataset.tapal_New_12  = 0;
dataset.tapal_Renew_12 = 0;
dataset.tapal_New_1  = 0;
dataset.tapal_Renew_1 = 0;
dataset.tapal_New_2  = 0;
dataset.tapal_Renew_2 = 0;
dataset.tapal_New_3  = 0;
dataset.tapal_Renew_3 = 0;
dataset.haste_New_5 = 0;
dataset.haste_Renew_5 = 0;
dataset.haste_New_6 = 0;
dataset.haste_Renew_6 = 0;
dataset.haste_New_7 = 0;
dataset.haste_Renew_7 = 0;
dataset.haste_New_8 = 0;
dataset.haste_Renew_8 = 0;
dataset.haste_New_9 = 0;
dataset.haste_Renew_9 = 0;
dataset.haste_New_10 = 0;
dataset.haste_Renew_10 = 0;
dataset.haste_New_11 = 0;
dataset.haste_Renew_11 = 0;
dataset.haste_New_12 = 0;
dataset.haste_Renew_12 = 0;
dataset.haste_New_1 = 0;
dataset.haste_Renew_1 = 0;
dataset.haste_New_2 = 0;
dataset.haste_Renew_2 = 0;
dataset.haste_New_3 = 0;
dataset.haste_Renew_3 = 0;
dataset.haste_Renew_10 = 0;
dataset.haste_New_11 = 0;
dataset.haste_Renew_11 = 0;
dataset.haste_New_12 = 0;
dataset.haste_Renew_12 = 0;
dataset.haste_New_1 = 0;
dataset.haste_Renew_1 = 0;
dataset.haste_New_2 = 0;
dataset.haste_Renew_2 = 0;
dataset.haste_New_3 = 0;
dataset.haste_Renew_3 = 0;
dataset.haste_Renew_10 = 0;
dataset.haste_New_11 = 0;
dataset.haste_Renew_11 = 0;
dataset.haste_New_12 = 0;
dataset.haste_Renew_12 = 0;
dataset.haste_New_1 = 0;
dataset.haste_Renew_1 = 0;
dataset.haste_New_2 = 0;
dataset.haste_Renew_2 = 0;
dataset.haste_New_3 = 0;
dataset.haste_Renew_3 = 0;
dataset.magzine_New_4  =0;
dataset.magzine_Renew_4 =0;
dataset.magzine_Total_4 =0;
dataset.magzine_New_5  =0;
dataset.magzine_Renew_5 =0;
dataset.magzine_Total_5 =0;
dataset.magzine_New_6  =0;
dataset.magzine_Renew_6 =0;
dataset.magzine_Total_6 =0;
dataset.magzine_New_7  =0;
dataset.magzine_Renew_7 =0;
dataset.magzine_Total_7 =0;
dataset.magzine_New_8  =0;
dataset.magzine_Renew_8 =0;
dataset.magzine_Total_8 =0;
dataset.magzine_New_9  =0;
dataset.magzine_Renew_9 =0;
dataset.magzine_Total_9 =0;
dataset.magzine_New_10  =0;
dataset.magzine_Renew_10=0;
dataset.magzine_Total_10 =0;
dataset.magzine_New_11  =0;
dataset.magzine_Renew_11 =0;
dataset.magzine_Total_11 =0;
dataset.magzine_New_12  =0;
dataset.magzine_Renew_12 =0;
dataset.magzine_Total_12 =0;
dataset.magzine_New_1  =0;
dataset.magzine_Renew_1 =0;
dataset.magzine_Total_1 =0;
dataset.magzine_New_2  =0;
dataset.magzine_Renew_2 =0;
dataset.magzine_Total_2 =0;
dataset.magzine_New_3  =0;
dataset.magzine_Renew_3 =0;
dataset.magzine_Total_3 =0;

dataset.digital_New_4  = 0;
dataset.digital_Renew_4 = 0;
dataset.digital_Total_4 = 0;
dataset.digital_New_5  = 0;
dataset.digital_Renew_5 = 0;
dataset.digital_Total_5 = 0;
dataset.digital_New_6  = 0;
dataset.digital_Renew_6 = 0;
dataset.digital_Total_6 = 0;
dataset.digital_New_7  = 0;
dataset.digital_Renew_7 = 0;
dataset.digital_Total_7 = 0;
dataset.digital_New_8  = 0;
dataset.digital_Renew_8 = 0;
dataset.digital_Total_8 = 0;
dataset.digital_New_9  = 0;
dataset.digital_Renew_9 = 0;
dataset.digital_Total_9 = 0;
dataset.digital_New_10  = 0;
dataset.digital_Renew_10 = 0;
dataset.digital_Total_10 = 0;
dataset.digital_New_11  = 0;
dataset.digital_Renew_11 = 0;
dataset.digital_Total_11 = 0;
dataset.digital_New_12  = 0;
dataset.digital_Renew_12 = 0;
dataset.digital_Total_12 = 0;
dataset.digital_New_1  = 0;
dataset.digital_Renew_1 = 0;
dataset.digital_Total_1 = 0;
dataset.digital_New_2  = 0;
dataset.digital_Renew_2 = 0;
dataset.digital_Total_2 = 0;
dataset.digital_New_3  = 0;
dataset.digital_Renew_3 = 0;
dataset.digital_Total_3 = 0;
dataset.tapal_New_total = 0;
dataset.tapal_Renew_total = 0;
dataset.tapal_Total_total = 0;
dataset.haste_New_total = 0;
dataset.haste_Renew_total = 0;
dataset.haste_Total_total = 0;
dataset.magzine_New_total = 0;
dataset.magzine_Renew_total = 0;
dataset.magzine_Total_total = 0;
dataset.digital_New_total  = 0;
dataset.digital_Renew_total = 0;
dataset.digital_Total_total = 0;

  for (let data2 of dataset.districts) {
  //  debugger
  
    this.tapal_New_4 += parseInt(data2.tapal_New_4);
    this.tapal_Renew_4 += parseInt(data2.tapal_Renew_4);
    this.tapal_Total_4 += parseInt(data2.tapal_Total_4);
    this.tapal_New_5 += parseInt(data2.tapal_New_5);
    this.tapal_Renew_5 += parseInt(data2.tapal_Renew_5);
    this.tapal_Total_5 += parseInt(data2.tapal_Total_5);
    this.tapal_New_6 += parseInt(data2.tapal_New_6);
    this.tapal_Renew_6 += parseInt(data2.tapal_Renew_6);
    this.tapal_Total_6 += parseInt(data2.tapal_Total_6);
    this.tapal_New_7 += parseInt(data2.tapal_New_7);
    this.tapal_Renew_7 += parseInt(data2.tapal_Renew_7);
    this.tapal_Total_7 += parseInt(data2.tapal_Total_7);
    this.tapal_New_8 += parseInt(data2.tapal_New_8);
    this.tapal_Renew_8 += parseInt(data2.tapal_Renew_8);
    this.tapal_Total_8 += parseInt(data2.tapal_Total_8);
    this.tapal_New_9 += parseInt(data2.tapal_New_9);
    this.tapal_Renew_9 += parseInt(data2.tapal_Renew_9);
    this.tapal_Total_9 += parseInt(data2.tapal_Total_9);
    this.tapal_New_10 += parseInt(data2.tapal_New_10);
    this.tapal_Renew_10 += parseInt(data2.tapal_Renew_10);
    this.tapal_Total_10 += parseInt(data2.tapal_Total_10);
    this.tapal_New_11 += parseInt(data2.tapal_New_11);
    this.tapal_Renew_11 += parseInt(data2.tapal_Renew_11);
    this.tapal_Total_11 += parseInt(data2.tapal_Total_11);
    this.tapal_New_12 += parseInt(data2.tapal_New_12);
    this.tapal_Renew_12 += parseInt(data2.tapal_Renew_12);
    this.tapal_Total_12 += parseInt(data2.tapal_Total_12);
    this.tapal_New_1 += parseInt(data2.tapal_New_1);
    this.tapal_Renew_1 += parseInt(data2.tapal_Renew_1);
    this.tapal_Total_1 += parseInt(data2.tapal_Total_1);
    this.tapal_New_2 += parseInt(data2.tapal_New_2);
    this.tapal_Renew_2 += parseInt(data2.tapal_Renew_2);
    this.tapal_Total_2 += parseInt(data2.tapal_Total_2);
    this.tapal_New_3 += parseInt(data2.tapal_New_3);
    this.tapal_Renew_3 += parseInt(data2.tapal_Renew_3);
    this.tapal_Total_3 += parseInt(data2.tapal_Total_3);

    this.haste_New_4  += parseInt(data2.haste_New_4);
    this.haste_Renew_4 += parseInt(data2.haste_Renew_4);
    this.haste_Total_4 += parseInt(data2.haste_Total_4);
    this.haste_New_5  += parseInt(data2.haste_New_5);
    this.haste_Renew_5 += parseInt(data2.haste_Renew_5);
    this.haste_Total_5 += parseInt(data2.haste_Total_5);
    this.haste_New_6  += parseInt(data2.haste_New_6);
    this.haste_Renew_6 += parseInt(data2.haste_Renew_6);
    this.haste_Total_6 += parseInt(data2.haste_Total_6);
    this.haste_New_7  += parseInt(data2.haste_New_7);
    this.haste_Renew_7 += parseInt(data2.haste_Renew_7);
    this.haste_Total_7 += parseInt(data2.haste_Total_7);
    this.haste_New_8  += parseInt(data2.haste_New_8);
    this.haste_Renew_8 += parseInt(data2.haste_Renew_8);
    this.haste_Total_8 += parseInt(data2.haste_Total_8);
    this.haste_New_9  += parseInt(data2.haste_New_9);
    this.haste_Renew_9 += parseInt(data2.haste_Renew_9);
    this.haste_Total_9 += parseInt(data2.haste_Total_9);
    this.haste_New_10  += parseInt(data2.haste_New_10);
    this.haste_Renew_10 += parseInt(data2.haste_Renew_10);
    this.haste_Total_10 += parseInt(data2.haste_Total_10);
    this.haste_New_11  += parseInt(data2.haste_New_11);
    this.haste_Renew_11 += parseInt(data2.haste_Renew_11);
    this.haste_Total_11 += parseInt(data2.haste_Total_11);
    this.haste_New_12  += parseInt(data2.haste_New_12);
    this.haste_Renew_12 += parseInt(data2.haste_Renew_12);
    this.haste_Total_12 += parseInt(data2.haste_Total_12);
    this.haste_New_1  += parseInt(data2.haste_New_1);
    this.haste_Renew_1 += parseInt(data2.haste_Renew_1);
    this.haste_Total_1 += parseInt(data2.haste_Total_1);
    this.haste_New_2  += parseInt(data2.haste_New_2);
    this.haste_Renew_2 += parseInt(data2.haste_Renew_2);
    this.haste_Total_2 += parseInt(data2.haste_Total_2);
    this.haste_New_3  += parseInt(data2.haste_New_3);
    this.haste_Renew_3 += parseInt(data2.haste_Renew_3);
    this.haste_Total_3 += parseInt(data2.haste_Total_3);

    this.magzine_New_4  += parseInt(data2.magzine_New_4);
this.magzine_Renew_4 += parseInt(data2.magzine_Renew_4);
this.magzine_Total_4 += parseInt(data2.magzine_Total_4);
this.magzine_New_5  += parseInt(data2.magzine_New_5);
this.magzine_Renew_5 += parseInt(data2.magzine_Renew_5);
this.magzine_Total_5 += parseInt(data2.magzine_Total_5);
this.magzine_New_6  += parseInt(data2.magzine_New_6);
this.magzine_Renew_6 += parseInt(data2.magzine_Renew_6);
this.magzine_Total_6 += parseInt(data2.magzine_Total_6);
this.magzine_New_7  += parseInt(data2.magzine_New_7);
this.magzine_Renew_7 += parseInt(data2.magzine_Renew_7);
this.magzine_Total_7 += parseInt(data2.magzine_Total_7);
this.magzine_New_8  += parseInt(data2.magzine_New_8);
this.magzine_Renew_8 += parseInt(data2.magzine_Renew_8);
this.magzine_Total_8 += parseInt(data2.magzine_Total_8);
this.magzine_New_9  += parseInt(data2.magzine_New_9);
this.magzine_Renew_9 += parseInt(data2.magzine_Renew_9);
this.magzine_Total_9 += parseInt(data2.magzine_Total_9);
this.magzine_New_10  += parseInt(data2.magzine_New_10);
this.magzine_Renew_10 += parseInt(data2.magzine_Renew_10);
this.magzine_Total_10 += parseInt(data2.magzine_Total_10);
this.magzine_New_11  += parseInt(data2.magzine_New_11);
this.magzine_Renew_11 += parseInt(data2.magzine_Renew_11);
this.magzine_Total_11 += parseInt(data2.magzine_Total_11);
this.magzine_New_12  += parseInt(data2.magzine_New_12);
this.magzine_Renew_12 += parseInt(data2.magzine_Renew_12);
this.magzine_Total_12 += parseInt(data2.magzine_Total_12);
this.magzine_New_1  += parseInt(data2.magzine_New_1);
this.magzine_Renew_1 += parseInt(data2.magzine_Renew_1);
this.magzine_Total_1 += parseInt(data2.magzine_Total_1);
this.magzine_New_2  += parseInt(data2.magzine_New_2);
this.magzine_Renew_2 += parseInt(data2.magzine_Renew_2);
this.magzine_Total_2 += parseInt(data2.magzine_Total_2);
this.magzine_New_3  += parseInt(data2.magzine_New_3);
this.magzine_Renew_3 += parseInt(data2.magzine_Renew_3);
this.magzine_Total_3 += parseInt(data2.magzine_Total_3);

this.digital_New_4  += parseInt(data2.digital_New_4);
this.digital_Renew_4 += parseInt(data2.digital_Renew_4);
this.digital_Total_4 += parseInt(data2.digital_Total_4);
this.digital_New_5  += parseInt(data2.digital_New_5);
this.digital_Renew_5 += parseInt(data2.digital_Renew_5);
this.digital_Total_5 += parseInt(data2.digital_Total_5);
this.digital_New_6  += parseInt(data2.digital_New_6);
this.digital_Renew_6 += parseInt(data2.digital_Renew_6);
this.digital_Total_6 += parseInt(data2.digital_Total_6);
this.digital_New_7  += parseInt(data2.digital_New_7);
this.digital_Renew_7 += parseInt(data2.digital_Renew_7);
this.digital_Total_7 += parseInt(data2.digital_Total_7);
this.digital_New_8  += parseInt(data2.digital_New_8);
this.digital_Renew_8 += parseInt(data2.digital_Renew_8);
this.digital_Total_8 += parseInt(data2.digital_Total_8);
this.digital_New_9  += parseInt(data2.digital_New_9);
this.digital_Renew_9 += parseInt(data2.digital_Renew_9);
this.digital_Total_9 += parseInt(data2.digital_Total_9);
this.digital_New_10  += parseInt(data2.digital_New_10);
this.digital_Renew_10 += parseInt(data2.digital_Renew_10);
this.digital_Total_10 += parseInt(data2.digital_Total_10);
this.digital_New_11  += parseInt(data2.digital_New_11);
this.digital_Renew_11 += parseInt(data2.digital_Renew_11);
this.digital_Total_11 += parseInt(data2.digital_Total_11);
this.digital_New_12  += parseInt(data2.digital_New_12);
this.digital_Renew_12 += parseInt(data2.digital_Renew_12);
this.digital_Total_12 += parseInt(data2.digital_Total_12);
this.digital_New_1  += parseInt(data2.digital_New_1);
this.digital_Renew_1 += parseInt(data2.digital_Renew_1);
this.digital_Total_1 += parseInt(data2.digital_Total_1);
this.digital_New_2  += parseInt(data2.digital_New_2);
this.digital_Renew_2 += parseInt(data2.digital_Renew_2);
this.digital_Total_2 += parseInt(data2.digital_Total_2);
this.digital_New_3  += parseInt(data2.digital_New_3);
this.digital_Renew_3 += parseInt(data2.digital_Renew_3);
this.digital_Total_3 += parseInt(data2.digital_Total_3);

this.magzine_New_total += parseInt(data2.magzine_New_total);
this.magzine_Renew_total += parseInt(data2.magzine_Renew_total);
this.magzine_Total_total += parseInt(data2.magzine_Total_total);

this.tapal_New_total += parseInt(data2.tapal_New_total);
this.tapal_Renew_total += parseInt(data2.tapal_Renew_total);
this.tapal_Total_total += parseInt(data2.tapal_Total_total);
this.haste_New_total  += parseInt(data2.haste_New_total);
this.haste_Renew_total += parseInt(data2.haste_Renew_total);
this.haste_Total_total += parseInt(data2.haste_Total_total);
this.digital_New_total  += parseInt(data2.digital_New_total);
this.digital_Renew_total += parseInt(data2.digital_Renew_total);
this.digital_Total_total += parseInt(data2.digital_Total_total);


dataset.tapal_New_4 += parseInt(data2.tapal_New_4);
dataset.tapal_Renew_4 += parseInt(data2.tapal_Renew_4);
dataset.tapal_Total_4 += parseInt(data2.tapal_Total_4);
dataset.tapal_New_5 += parseInt(data2.tapal_New_5);
dataset.tapal_Renew_5 += parseInt(data2.tapal_Renew_5);
dataset.tapal_Total_5 += parseInt(data2.tapal_Total_5);
dataset.tapal_New_6 += parseInt(data2.tapal_New_6);
dataset.tapal_Renew_6 += parseInt(data2.tapal_Renew_6);
dataset.tapal_Total_6 += parseInt(data2.tapal_Total_6);
dataset.tapal_New_7 += parseInt(data2.tapal_New_7);
dataset.tapal_Renew_7 += parseInt(data2.tapal_Renew_7);
dataset.tapal_Total_7 += parseInt(data2.tapal_Total_7);
dataset.tapal_New_8 += parseInt(data2.tapal_New_8);
dataset.tapal_Renew_8 += parseInt(data2.tapal_Renew_8);
dataset.tapal_Total_8 += parseInt(data2.tapal_Total_8);
dataset.tapal_New_9 += parseInt(data2.tapal_New_9);
dataset.tapal_Renew_9 += parseInt(data2.tapal_Renew_9);
dataset.tapal_Total_9 += parseInt(data2.tapal_Total_9);
dataset.tapal_New_10 += parseInt(data2.tapal_New_10);
dataset.tapal_Renew_10 += parseInt(data2.tapal_Renew_10);
dataset.tapal_Total_10 += parseInt(data2.tapal_Total_10);
dataset.tapal_New_11 += parseInt(data2.tapal_New_11);
dataset.tapal_Renew_11 += parseInt(data2.tapal_Renew_11);
dataset.tapal_Total_11 += parseInt(data2.tapal_Total_11);
dataset.tapal_New_12 += parseInt(data2.tapal_New_12);
dataset.tapal_Renew_12 += parseInt(data2.tapal_Renew_12);
dataset.tapal_Total_12 += parseInt(data2.tapal_Total_12);
dataset.tapal_New_1 += parseInt(data2.tapal_New_1);
dataset.tapal_Renew_1 += parseInt(data2.tapal_Renew_1);
dataset.tapal_Total_1 += parseInt(data2.tapal_Total_1);
dataset.tapal_New_2 += parseInt(data2.tapal_New_2);
dataset.tapal_Renew_2 += parseInt(data2.tapal_Renew_2);
dataset.tapal_Total_2 += parseInt(data2.tapal_Total_2);
dataset.tapal_New_3 += parseInt(data2.tapal_New_3);
dataset.tapal_Renew_3 += parseInt(data2.tapal_Renew_3);
dataset.tapal_Total_3 += parseInt(data2.tapal_Total_3);

dataset.haste_New_4  += parseInt(data2.haste_New_4);
dataset.haste_Renew_4 += parseInt(data2.haste_Renew_4);
dataset.haste_Total_4 += parseInt(data2.haste_Total_4);
dataset.haste_New_5  += parseInt(data2.haste_New_5);
dataset.haste_Renew_5 += parseInt(data2.haste_Renew_5);
dataset.haste_Total_5 += parseInt(data2.haste_Total_5);
dataset.haste_New_6  += parseInt(data2.haste_New_6);
dataset.haste_Renew_6 += parseInt(data2.haste_Renew_6);
dataset.haste_Total_6 += parseInt(data2.haste_Total_6);
dataset.haste_New_7  += parseInt(data2.haste_New_7);
dataset.haste_Renew_7 += parseInt(data2.haste_Renew_7);
dataset.haste_Total_7 += parseInt(data2.haste_Total_7);
dataset.haste_New_8  += parseInt(data2.haste_New_8);
dataset.haste_Renew_8 += parseInt(data2.haste_Renew_8);
dataset.haste_Total_8 += parseInt(data2.haste_Total_8);
dataset.haste_New_9  += parseInt(data2.haste_New_9);
dataset.haste_Renew_9 += parseInt(data2.haste_Renew_9);
dataset.haste_Total_9 += parseInt(data2.haste_Total_9);
dataset.haste_New_10  += parseInt(data2.haste_New_10);
dataset.haste_Renew_10 += parseInt(data2.haste_Renew_10);
dataset.haste_Total_10 += parseInt(data2.haste_Total_10);
dataset.haste_New_11  += parseInt(data2.haste_New_11);
dataset.haste_Renew_11 += parseInt(data2.haste_Renew_11);
dataset.haste_Total_11 += parseInt(data2.haste_Total_11);
dataset.haste_New_12  += parseInt(data2.haste_New_12);
dataset.haste_Renew_12 += parseInt(data2.haste_Renew_12);
dataset.haste_Total_12 += parseInt(data2.haste_Total_12);
dataset.haste_New_1  += parseInt(data2.haste_New_1);
dataset.haste_Renew_1 += parseInt(data2.haste_Renew_1);
dataset.haste_Total_1 += parseInt(data2.haste_Total_1);
dataset.haste_New_2  += parseInt(data2.haste_New_2);
dataset.haste_Renew_2 += parseInt(data2.haste_Renew_2);
dataset.haste_Total_2 += parseInt(data2.haste_Total_2);
dataset.haste_New_3  += parseInt(data2.haste_New_3);
dataset.haste_Renew_3 += parseInt(data2.haste_Renew_3);
dataset.haste_Total_3 += parseInt(data2.haste_Total_3);

dataset.magzine_New_4  += parseInt(data2.magzine_New_4);
dataset.magzine_Renew_4 += parseInt(data2.magzine_Renew_4);
dataset.magzine_Total_4 += parseInt(data2.magzine_Total_4);
dataset.magzine_New_5  += parseInt(data2.magzine_New_5);
dataset.magzine_Renew_5 += parseInt(data2.magzine_Renew_5);
dataset.magzine_Total_5 += parseInt(data2.magzine_Total_5);
dataset.magzine_New_6  += parseInt(data2.magzine_New_6);
dataset.magzine_Renew_6 += parseInt(data2.magzine_Renew_6);
dataset.magzine_Total_6 += parseInt(data2.magzine_Total_6);
dataset.magzine_New_7  += parseInt(data2.magzine_New_7);
dataset.magzine_Renew_7 += parseInt(data2.magzine_Renew_7);
dataset.magzine_Total_7 += parseInt(data2.magzine_Total_7);
dataset.magzine_New_8  += parseInt(data2.magzine_New_8);
dataset.magzine_Renew_8 += parseInt(data2.magzine_Renew_8);
dataset.magzine_Total_8 += parseInt(data2.magzine_Total_8);
dataset.magzine_New_9  += parseInt(data2.magzine_New_9);
dataset.magzine_Renew_9 += parseInt(data2.magzine_Renew_9);
dataset.magzine_Total_9 += parseInt(data2.magzine_Total_9);
dataset.magzine_New_10  += parseInt(data2.magzine_New_10);
dataset.magzine_Renew_10 += parseInt(data2.magzine_Renew_10);
dataset.magzine_Total_10 += parseInt(data2.magzine_Total_10);
dataset.magzine_New_11  += parseInt(data2.magzine_New_11);
dataset.magzine_Renew_11 += parseInt(data2.magzine_Renew_11);
dataset.magzine_Total_11 += parseInt(data2.magzine_Total_11);
dataset.magzine_New_12  += parseInt(data2.magzine_New_12);
dataset.magzine_Renew_12 += parseInt(data2.magzine_Renew_12);
dataset.magzine_Total_12 += parseInt(data2.magzine_Total_12);
dataset.magzine_New_1  += parseInt(data2.magzine_New_1);
dataset.magzine_Renew_1 += parseInt(data2.magzine_Renew_1);
dataset.magzine_Total_1 += parseInt(data2.magzine_Total_1);
dataset.magzine_New_2  += parseInt(data2.magzine_New_2);
dataset.magzine_Renew_2 += parseInt(data2.magzine_Renew_2);
dataset.magzine_Total_2 += parseInt(data2.magzine_Total_2);
dataset.magzine_New_3  += parseInt(data2.magzine_New_3);
dataset.magzine_Renew_3 += parseInt(data2.magzine_Renew_3);
dataset.magzine_Total_3 += parseInt(data2.magzine_Total_3);

dataset.digital_New_4  += parseInt(data2.digital_New_4);
dataset.digital_Renew_4 += parseInt(data2.digital_Renew_4);
dataset.digital_Total_4 += parseInt(data2.digital_Total_4);
dataset.digital_New_5  += parseInt(data2.digital_New_5);
dataset.digital_Renew_5 += parseInt(data2.digital_Renew_5);
dataset.digital_Total_5 += parseInt(data2.digital_Total_5);
dataset.digital_New_6  += parseInt(data2.digital_New_6);
dataset.digital_Renew_6 += parseInt(data2.digital_Renew_6);
dataset.digital_Total_6 += parseInt(data2.digital_Total_6);
dataset.digital_New_7  += parseInt(data2.digital_New_7);
dataset.digital_Renew_7 += parseInt(data2.digital_Renew_7);
dataset.digital_Total_7 += parseInt(data2.digital_Total_7);
dataset.digital_New_8  += parseInt(data2.digital_New_8);
dataset.digital_Renew_8 += parseInt(data2.digital_Renew_8);
dataset.digital_Total_8 += parseInt(data2.digital_Total_8);
dataset.digital_New_9  += parseInt(data2.digital_New_9);
dataset.digital_Renew_9 += parseInt(data2.digital_Renew_9);
dataset.digital_Total_9 += parseInt(data2.digital_Total_9);
dataset.digital_New_10  += parseInt(data2.digital_New_10);
dataset.digital_Renew_10 += parseInt(data2.digital_Renew_10);
dataset.digital_Total_10 += parseInt(data2.digital_Total_10);
dataset.digital_New_11  += parseInt(data2.digital_New_11);
dataset.digital_Renew_11 += parseInt(data2.digital_Renew_11);
dataset.digital_Total_11 += parseInt(data2.digital_Total_11);
dataset.digital_New_12  += parseInt(data2.digital_New_12);
dataset.digital_Renew_12 += parseInt(data2.digital_Renew_12);
dataset.digital_Total_12 += parseInt(data2.digital_Total_12);
dataset.digital_New_1  += parseInt(data2.digital_New_1);
dataset.digital_Renew_1 += parseInt(data2.digital_Renew_1);
dataset.digital_Total_1 += parseInt(data2.digital_Total_1);
dataset.digital_New_2  += parseInt(data2.digital_New_2);
dataset.digital_Renew_2 += parseInt(data2.digital_Renew_2);
dataset.digital_Total_2 += parseInt(data2.digital_Total_2);
dataset.digital_New_3  += parseInt(data2.digital_New_3);
dataset.digital_Renew_3 += parseInt(data2.digital_Renew_3);
dataset.digital_Total_3 += parseInt(data2.digital_Total_3);

dataset.magzine_New_total += parseInt(data2.magzine_New_total);
dataset.magzine_Renew_total += parseInt(data2.magzine_Renew_total);
dataset.magzine_Total_total += parseInt(data2.magzine_Total_total);

dataset.tapal_New_total += parseInt(data2.tapal_New_total);
dataset.tapal_Renew_total += parseInt(data2.tapal_Renew_total);
dataset.tapal_Total_total += parseInt(data2.tapal_Total_total);
dataset.haste_New_total  += parseInt(data2.haste_New_total);
dataset.haste_Renew_total += parseInt(data2.haste_Renew_total);
dataset.haste_Total_total += parseInt(data2.haste_Total_total);

dataset.digital_New_total  += parseInt(data2.digital_New_total);
dataset.digital_Renew_total += parseInt(data2.digital_Renew_total);
dataset.digital_Total_total += parseInt(data2.digital_Total_total);



    data2.grandremtotal =  parseInt(data2.haste_Total_3) + parseInt(data2.tapal_Total_3);
    console.log(data2.grandremtotal);  

  }


  }


    console.log("subbyjilha renewal reming", data);
     });
}

if(this.reportType=="RemainingSubscriptionDistrictBriefly"){
  this.service.getSubRemDistBriefly(req).subscribe((data) => {
    this.SubRemDistrictBriefly = data;

    this.PaymentadvtOffInDTotal = 0;
  this.tapal_Total_4 = 0;
  this.haste_Total_4 = 0;
  this.tapal_Total_5 = 0;
  this.haste_Total_5 = 0;
  this.tapal_Total_6 = 0;
  this.haste_Total_6 = 0;
  this.tapal_Total_7 = 0;
  this.haste_Total_7 = 0;
  this.tapal_Total_8 = 0;
  this.haste_Total_8 = 0;
  this.tapal_Total_9 = 0;
  this.haste_Total_9 = 0;
  this.tapal_Total_10 = 0;
  this.haste_Total_10 = 0;
  this.tapal_Total_11 = 0;
  this.haste_Total_11 = 0;
  this.tapal_Total_12 = 0;
  this.haste_Total_12 = 0;
  this.tapal_Total_1 = 0;
  this.haste_Total_1 = 0;
  this.tapal_Total_2 = 0;
  this.haste_Total_2 = 0;
  this.tapal_Total_3 = 0;
  this.haste_Total_3 = 0;
  this.tapal_New_4 = 0;
  this.tapal_Renew_4 = 0;
  this.haste_New_4 = 0;
  this.haste_Renew_4 = 0;
  this.tapal_New_5  = 0;
  this.tapal_Renew_5 = 0;
  this.tapal_New_6  = 0;
  this.tapal_Renew_6 = 0;
  this.tapal_New_7  = 0;
  this.tapal_Renew_7 = 0;
  this.tapal_New_8  = 0;
  this.tapal_Renew_8 = 0;
  this.tapal_New_9  = 0;
  this.tapal_Renew_9 = 0;
  this.tapal_New_10  = 0;
  this.tapal_Renew_10 = 0;
  this.tapal_New_11  = 0;
  this.tapal_Renew_11 = 0;
  this.tapal_New_12  = 0;
  this.tapal_Renew_12 = 0;
  this.tapal_New_1  = 0;
  this.tapal_Renew_1 = 0;
  this.tapal_New_2  = 0;
  this.tapal_Renew_2 = 0;
  this.tapal_New_3  = 0;
  this.tapal_Renew_3 = 0;
  
  this.tapal_New_total  = 0;
  this.tapal_Renew_total  = 0;
  this.tapal_Total_total  = 0;
 

  for (let data2 of this.SubRemDistrictBriefly) {

  //  debugger
  
    this.tapal_New_4 += parseInt(data2.New_4);
    this.tapal_Renew_4 += parseInt(data2.Renew_4);
    this.tapal_Total_4 += parseInt(data2.Total_4);
    this.tapal_New_5 += parseInt(data2.New_5);
    this.tapal_Renew_5 += parseInt(data2.Renew_5);
    this.tapal_Total_5 += parseInt(data2.Total_5);
    this.tapal_New_6 += parseInt(data2.New_6);
    this.tapal_Renew_6 += parseInt(data2.Renew_6);
    this.tapal_Total_6 += parseInt(data2.Total_6);
    this.tapal_New_7 += parseInt(data2.New_7);
    this.tapal_Renew_7 += parseInt(data2.Renew_7);
    this.tapal_Total_7 += parseInt(data2.Total_7);
    this.tapal_New_8 += parseInt(data2.New_8);
    this.tapal_Renew_8 += parseInt(data2.Renew_8);
    this.tapal_Total_8 += parseInt(data2.Total_8);
    this.tapal_New_9 += parseInt(data2.New_9);
    this.tapal_Renew_9 += parseInt(data2.Renew_9);
    this.tapal_Total_9 += parseInt(data2.Total_9);
    this.tapal_New_10 += parseInt(data2.New_10);
    this.tapal_Renew_10 += parseInt(data2.Renew_10);
    this.tapal_Total_10 += parseInt(data2.Total_10);
    this.tapal_New_11 += parseInt(data2.New_11);
    this.tapal_Renew_11 += parseInt(data2.Renew_11);
    this.tapal_Total_11 += parseInt(data2.Total_11);
    this.tapal_New_12 += parseInt(data2.New_12);
    this.tapal_Renew_12 += parseInt(data2.Renew_12);
    this.tapal_Total_12 += parseInt(data2.Total_12);
    this.tapal_New_1 += parseInt(data2.New_1);
    this.tapal_Renew_1 += parseInt(data2.Renew_1);
    this.tapal_Total_1 += parseInt(data2.Total_1);
    this.tapal_New_2 += parseInt(data2.New_2);
    this.tapal_Renew_2 += parseInt(data2.Renew_2);
    this.tapal_Total_2 += parseInt(data2.Total_2);
    this.tapal_New_3 += parseInt(data2.New_3);
    this.tapal_Renew_3 += parseInt(data2.Renew_3);
    this.tapal_Total_3 += parseInt(data2.Total_3);


      this.tapal_New_total += parseInt(data2.New_total);
      this.tapal_Renew_total += parseInt(data2.Renew_total);
      this.tapal_Total_total += parseInt(data2.Total_total);




  }


    console.log("subbyjilha renewal reming", data);
     });
}



if(this.reportType=="RemainingSubscriptionTaluka"){
  this.service.getSubRemTaluka(req).subscribe((data) => {
    this.SubRemTaluka = data;
    // this.PaymentadvtOffInDTotal = 0;
    this.tapal_Total_4 = 0;
    this.haste_Total_4 = 0;
    this.tapal_Total_5 = 0;
    this.haste_Total_5 = 0;
    this.tapal_Total_6 = 0;
    this.haste_Total_6 = 0;
    this.tapal_Total_7 = 0;
    this.haste_Total_7 = 0;
    this.tapal_Total_8 = 0;
    this.haste_Total_8 = 0;
    this.tapal_Total_9 = 0;
    this.haste_Total_9 = 0;
    this.tapal_Total_10 = 0;
    this.haste_Total_10 = 0;
    this.tapal_Total_11 = 0;
    this.haste_Total_11 = 0;
    this.tapal_Total_12 = 0;
    this.haste_Total_12 = 0;
    this.tapal_Total_1 = 0;
    this.haste_Total_1 = 0;
    this.tapal_Total_2 = 0;
    this.haste_Total_2 = 0;
    this.tapal_Total_3 = 0;
    this.haste_Total_3 = 0;
    this.tapal_New_4 = 0;
    this.tapal_Renew_4 = 0;
    this.haste_New_4 = 0;
    this.haste_Renew_4 = 0;
    this.tapal_New_5  = 0;
    this.tapal_Renew_5 = 0;
    this.tapal_New_6  = 0;
    this.tapal_Renew_6 = 0;
    this.tapal_New_7  = 0;
    this.tapal_Renew_7 = 0;
    this.tapal_New_8  = 0;
    this.tapal_Renew_8 = 0;
    this.tapal_New_9  = 0;
    this.tapal_Renew_9 = 0;
    this.tapal_New_10  = 0;
    this.tapal_Renew_10 = 0;
    this.tapal_New_11  = 0;
    this.tapal_Renew_11 = 0;
    this.tapal_New_12  = 0;
    this.tapal_Renew_12 = 0;
    this.tapal_New_1  = 0;
    this.tapal_Renew_1 = 0;
    this.tapal_New_2  = 0;
    this.tapal_Renew_2 = 0;
    this.tapal_New_3  = 0;
    this.tapal_Renew_3 = 0;
    this.haste_New_5 = 0;
    this.haste_Renew_5 = 0;
    this.haste_New_6 = 0;
    this.haste_Renew_6 = 0;
    this.haste_New_7 = 0;
    this.haste_Renew_7 = 0;
    this.haste_New_8 = 0;
    this.haste_Renew_8 = 0;
    this.haste_New_9 = 0;
    this.haste_Renew_9 = 0;
    this.haste_New_10 = 0;
    this.haste_Renew_10 = 0;
    this.haste_New_11 = 0;
    this.haste_Renew_11 = 0;
    this.haste_New_12 = 0;
    this.haste_Renew_12 = 0;
    this.haste_New_1 = 0;
    this.haste_Renew_1 = 0;
    this.haste_New_2 = 0;
    this.haste_Renew_2 = 0;
    this.haste_New_3 = 0;
    this.haste_Renew_3 = 0;
    this.haste_Renew_10 = 0;
    this.haste_New_11 = 0;
    this.haste_Renew_11 = 0;
    this.haste_New_12 = 0;
    this.haste_Renew_12 = 0;
    this.haste_New_1 = 0;
    this.haste_Renew_1 = 0;
    this.haste_New_2 = 0;
    this.haste_Renew_2 = 0;
    this.haste_New_3 = 0;
    this.haste_Renew_3 = 0;
    this.haste_Renew_10 = 0;
    this.haste_New_11 = 0;
    this.haste_Renew_11 = 0;
    this.haste_New_12 = 0;
    this.haste_Renew_12 = 0;
    this.haste_New_1 = 0;
    this.haste_Renew_1 = 0;
    this.haste_New_2 = 0;
    this.haste_Renew_2 = 0;
    this.haste_New_3 = 0;
    this.haste_Renew_3 = 0;
    this.haste_Renew_10 = 0;
    this.haste_New_11 = 0;
    this.haste_Renew_11 = 0;
    this.haste_New_12 = 0;
    this.haste_Renew_12 = 0;
    this.haste_New_1 = 0;
    this.haste_Renew_1 = 0;
    this.haste_New_2 = 0;
    this.haste_Renew_2 = 0;
    this.haste_New_3 = 0;
    this.haste_Renew_3 = 0;
  

    
    this.magzine_New_4  =0;
    this.magzine_Renew_4 =0;
    this.magzine_Total_4 =0;
    this.magzine_New_5  =0;
    this.magzine_Renew_5 =0;
    this.magzine_Total_5 =0;
    this.magzine_New_6  =0;
    this.magzine_Renew_6 =0;
    this.magzine_Total_6 =0;
    this.magzine_New_7  =0;
    this.magzine_Renew_7 =0;
    this.magzine_Total_7 =0;
    this.magzine_New_8  =0;
    this.magzine_Renew_8 =0;
    this.magzine_Total_8 =0;
    this.magzine_New_9  =0;
    this.magzine_Renew_9 =0;
    this.magzine_Total_9 =0;
    this.magzine_New_10  =0;
    this.magzine_Renew_10=0;
    this.magzine_Total_10 =0;
    this.magzine_New_11  =0;
    this.magzine_Renew_11 =0;
    this.magzine_Total_11 =0;
    this.magzine_New_12  =0;
    this.magzine_Renew_12 =0;
    this.magzine_Total_12 =0;
    this.magzine_New_1  =0;
    this.magzine_Renew_1 =0;
    this.magzine_Total_1 =0;
    this.magzine_New_2  =0;
    this.magzine_Renew_2 =0;
    this.magzine_Total_2 =0;
    this.magzine_New_3  =0;
    this.magzine_Renew_3 =0;
    this.magzine_Total_3 =0;

    this.digital_New_4  = 0;
this.digital_Renew_4 = 0;
this.digital_Total_4 = 0;
this.digital_New_5  = 0;
this.digital_Renew_5 = 0;
this.digital_Total_5 = 0;
this.digital_New_6  = 0;
this.digital_Renew_6 = 0;
this.digital_Total_6 = 0;
this.digital_New_7  = 0;
this.digital_Renew_7 = 0;
this.digital_Total_7 = 0;
this.digital_New_8  = 0;
this.digital_Renew_8 = 0;
this.digital_Total_8 = 0;
this.digital_New_9  = 0;
this.digital_Renew_9 = 0;
this.digital_Total_9 = 0;
this.digital_New_10  = 0;
this.digital_Renew_10 = 0;
this.digital_Total_10 = 0;
this.digital_New_11  = 0;
this.digital_Renew_11 = 0;
this.digital_Total_11 = 0;
this.digital_New_12  = 0;
this.digital_Renew_12 = 0;
this.digital_Total_12 = 0;
this.digital_New_1  = 0;
this.digital_Renew_1 = 0;
this.digital_Total_1 = 0;
this.digital_New_2  = 0;
this.digital_Renew_2 = 0;
this.digital_Total_2 = 0;
this.digital_New_3  = 0;
this.digital_Renew_3 = 0;
this.digital_Total_3 = 0;

this.tapal_New_total  = 0;
this.tapal_Renew_total = 0;
this.tapal_Total_total = 0;
this.haste_New_total  = 0;
this.haste_Renew_total = 0;
this.haste_Total_total = 0;
this.magzine_New_total = 0;
this.magzine_Renew_total = 0;
this.magzine_Total_total = 0;
this.digital_New_total  = 0;
this.digital_Renew_total = 0;
this.digital_Total_total = 0;


  for (let data of this.SubRemTaluka) {

    for (let data11 of data.districts) {

      data11.tapal_Total_4 = 0;
      data11.haste_Total_4 = 0;
      data11.tapal_Total_5 = 0;
      data11.haste_Total_5 = 0;
      data11.tapal_Total_6 = 0;
      data11.haste_Total_6 = 0;
      data11.tapal_Total_7 = 0;
      data11.haste_Total_7 = 0;
      data11.tapal_Total_8 = 0;
      data11.haste_Total_8 = 0;
      data11.tapal_Total_9 = 0;
      data11.haste_Total_9 = 0;
      data11.tapal_Total_10 = 0;
      data11.haste_Total_10 = 0;
      data11.tapal_Total_11 = 0;
      data11.haste_Total_11 = 0;
      data11.tapal_Total_12 = 0;
      data11.haste_Total_12 = 0;
      data11.tapal_Total_1 = 0;
      data11.haste_Total_1 = 0;
      data11.tapal_Total_2 = 0;
      data11.haste_Total_2 = 0;
      data11.tapal_Total_3 = 0;
      data11.haste_Total_3 = 0;
      data11.tapal_New_4 = 0;
      data11.tapal_Renew_4 = 0;
      data11.haste_New_4 = 0;
      data11.haste_Renew_4 = 0;
      data11.tapal_New_5  = 0;
      data11.tapal_Renew_5 = 0;
      data11.tapal_New_6  = 0;
      data11.tapal_Renew_6 = 0;
      data11.tapal_New_7  = 0;
      data11.tapal_Renew_7 = 0;
      data11.tapal_New_8  = 0;
      data11.tapal_Renew_8 = 0;
      data11.tapal_New_9  = 0;
      data11.tapal_Renew_9 = 0;
      data11.tapal_New_10  = 0;
      data11.tapal_Renew_10 = 0;
      data11.tapal_New_11  = 0;
      data11.tapal_Renew_11 = 0;
      data11.tapal_New_12  = 0;
      data11.tapal_Renew_12 = 0;
      data11.tapal_New_1  = 0;
      data11.tapal_Renew_1 = 0;
      data11.tapal_New_2  = 0;
      data11.tapal_Renew_2 = 0;
      data11.tapal_New_3  = 0;
      data11.tapal_Renew_3 = 0;
      data11.haste_New_5 = 0;
      data11.haste_Renew_5 = 0;
      data11.haste_New_6 = 0;
      data11.haste_Renew_6 = 0;
      data11.haste_New_7 = 0;
      data11.haste_Renew_7 = 0;
      data11.haste_New_8 = 0;
      data11.haste_Renew_8 = 0;
      data11.haste_New_9 = 0;
      data11.haste_Renew_9 = 0;
      data11.haste_New_10 = 0;
      data11.haste_Renew_10 = 0;
      data11.haste_New_11 = 0;
      data11.haste_Renew_11 = 0;
      data11.haste_New_12 = 0;
      data11.haste_Renew_12 = 0;
      data11.haste_New_1 = 0;
      data11.haste_Renew_1 = 0;
      data11.haste_New_2 = 0;
      data11.haste_Renew_2 = 0;
      data11.haste_New_3 = 0;
      data11.haste_Renew_3 = 0;
      data11.haste_Renew_10 = 0;
      data11.haste_New_11 = 0;
      data11.haste_Renew_11 = 0;
      data11.haste_New_12 = 0;
      data11.haste_Renew_12 = 0;
      data11.haste_New_1 = 0;
      data11.haste_Renew_1 = 0;
      data11.haste_New_2 = 0;
      data11.haste_Renew_2 = 0;
      data11.haste_New_3 = 0;
      data11.haste_Renew_3 = 0;
      
      data11.magzine_New_4  =0;
      data11.magzine_Renew_4 =0;
      data11.magzine_Total_4 =0;
      data11.magzine_New_5  =0;
      data11.magzine_Renew_5 =0;
      data11.magzine_Total_5 =0;
      data11.magzine_New_6  =0;
      data11.magzine_Renew_6 =0;
      data11.magzine_Total_6 =0;
      data11.magzine_New_7  =0;
      data11.magzine_Renew_7 =0;
      data11.magzine_Total_7 =0;
      data11.magzine_New_8  =0;
      data11.magzine_Renew_8 =0;
      data11.magzine_Total_8 =0;
      data11.magzine_New_9  =0;
      data11.magzine_Renew_9 =0;
      data11.magzine_Total_9 =0;
      data11.magzine_New_10  =0;
      data11.magzine_Renew_10=0;
      data11.magzine_Total_10 =0;
      data11.magzine_New_11  =0;
      data11.magzine_Renew_11 =0;
      data11.magzine_Total_11 =0;
      data11.magzine_New_12  =0;
      data11.magzine_Renew_12 =0;
      data11.magzine_Total_12 =0;
      data11.magzine_New_1  =0;
      data11.magzine_Renew_1 =0;
      data11.magzine_Total_1 =0;
      data11.magzine_New_2  =0;
      data11.magzine_Renew_2 =0;
      data11.magzine_Total_2 =0;
      data11.magzine_New_3  =0;
      data11.magzine_Renew_3 =0;
      data11.magzine_Total_3 =0;
      
      data11.digital_New_4  = 0;
      data11.digital_Renew_4 = 0;
      data11.digital_Total_4 = 0;
      data11.digital_New_5  = 0;
      data11.digital_Renew_5 = 0;
      data11.digital_Total_5 = 0;
      data11.digital_New_6  = 0;
      data11.digital_Renew_6 = 0;
      data11.digital_Total_6 = 0;
      data11.digital_New_7  = 0;
      data11.digital_Renew_7 = 0;
      data11.digital_Total_7 = 0;
      data11.digital_New_8  = 0;
      data11.digital_Renew_8 = 0;
      data11.digital_Total_8 = 0;
      data11.digital_New_9  = 0;
      data11.digital_Renew_9 = 0;
      data11.digital_Total_9 = 0;
      data11.digital_New_10  = 0;
      data11.digital_Renew_10 = 0;
      data11.digital_Total_10 = 0;
      data11.digital_New_11  = 0;
      data11.digital_Renew_11 = 0;
      data11.digital_Total_11 = 0;
      data11.digital_New_12  = 0;
      data11.digital_Renew_12 = 0;
      data11.digital_Total_12 = 0;
      data11.digital_New_1  = 0;
      data11.digital_Renew_1 = 0;
      data11.digital_Total_1 = 0;
      data11.digital_New_2  = 0;
      data11.digital_Renew_2 = 0;
      data11.digital_Total_2 = 0;
      data11.digital_New_3  = 0;
      data11.digital_Renew_3 = 0;
      data11.digital_Total_3 = 0;
      
      data11.tapal_New_total  = 0;
      data11.tapal_Renew_total = 0;
      data11.tapal_Total_total = 0;
      data11.haste_New_total  = 0;
      data11.haste_Renew_total = 0;
      data11.haste_Total_total = 0;
      data11.magzine_New_total = 0;
      data11.magzine_Renew_total = 0;
      data11.magzine_Total_total = 0;
      data11.digital_New_total  = 0;
      data11.digital_Renew_total = 0;
      data11.digital_Total_total = 0;
      


      for (let data1 of data11.talukas) {
this.tapal_New_4 += parseInt(data1.tapal_New_4);
this.tapal_Renew_4 += parseInt(data1.tapal_Renew_4);
this.tapal_Total_4 += parseInt(data1.tapal_Total_4);
this.tapal_New_5 += parseInt(data1.tapal_New_5);
this.tapal_Renew_5 += parseInt(data1.tapal_Renew_5);
this.tapal_Total_5 += parseInt(data1.tapal_Total_5);
this.tapal_New_6 += parseInt(data1.tapal_New_6);
this.tapal_Renew_6 += parseInt(data1.tapal_Renew_6);
this.tapal_Total_6 += parseInt(data1.tapal_Total_6);
this.tapal_New_7 += parseInt(data1.tapal_New_7);
this.tapal_Renew_7 += parseInt(data1.tapal_Renew_7);
this.tapal_Total_7 += parseInt(data1.tapal_Total_7);
this.tapal_New_8 += parseInt(data1.tapal_New_8);
this.tapal_Renew_8 += parseInt(data1.tapal_Renew_8);
this.tapal_Total_8 += parseInt(data1.tapal_Total_8);
this.tapal_New_9 += parseInt(data1.tapal_New_9);
this.tapal_Renew_9 += parseInt(data1.tapal_Renew_9);
this.tapal_Total_9 += parseInt(data1.tapal_Total_9);
this.tapal_New_10 += parseInt(data1.tapal_New_10);
this.tapal_Renew_10 += parseInt(data1.tapal_Renew_10);
this.tapal_Total_10 += parseInt(data1.tapal_Total_10);
this.tapal_New_11 += parseInt(data1.tapal_New_11);
this.tapal_Renew_11 += parseInt(data1.tapal_Renew_11);
this.tapal_Total_11 += parseInt(data1.tapal_Total_11);
this.tapal_New_12 += parseInt(data1.tapal_New_12);
this.tapal_Renew_12 += parseInt(data1.tapal_Renew_12);
this.tapal_Total_12 += parseInt(data1.tapal_Total_12);
this.tapal_New_1 += parseInt(data1.tapal_New_1);
this.tapal_Renew_1 += parseInt(data1.tapal_Renew_1);
this.tapal_Total_1 += parseInt(data1.tapal_Total_1);
this.tapal_New_2 += parseInt(data1.tapal_New_2);
this.tapal_Renew_2 += parseInt(data1.tapal_Renew_2);
this.tapal_Total_2 += parseInt(data1.tapal_Total_2);
this.tapal_New_3 += parseInt(data1.tapal_New_3);
this.tapal_Renew_3 += parseInt(data1.tapal_Renew_3);
this.tapal_Total_3 += parseInt(data1.tapal_Total_3);

this.haste_New_4 += parseInt(data1.haste_New_4);
this.haste_Renew_4 += parseInt(data1.haste_Renew_4);
this.haste_Total_4 += parseInt(data1.haste_Total_4);
this.haste_New_5 += parseInt(data1.haste_New_5);
this.haste_Renew_5 += parseInt(data1.haste_Renew_5);
this.haste_Total_5 += parseInt(data1.haste_Total_5);
this.haste_New_6 += parseInt(data1.haste_New_6);
this.haste_Renew_6 += parseInt(data1.haste_Renew_6);
this.haste_Total_6 += parseInt(data1.haste_Total_6);
this.haste_New_7 += parseInt(data1.haste_New_7);
this.haste_Renew_7 += parseInt(data1.haste_Renew_7);
this.haste_Total_7 += parseInt(data1.haste_Total_7);
this.haste_New_8 += parseInt(data1.haste_New_8);
this.haste_Renew_8 += parseInt(data1.haste_Renew_8);
this.haste_Total_8 += parseInt(data1.haste_Total_8);
this.haste_New_9 += parseInt(data1.haste_New_9);
this.haste_Renew_9 += parseInt(data1.haste_Renew_9);
this.haste_Total_9 += parseInt(data1.haste_Total_9);
this.haste_New_10 += parseInt(data1.haste_New_10);
this.haste_Renew_10 += parseInt(data1.haste_Renew_10);
this.haste_Total_10 += parseInt(data1.haste_Total_10);
this.haste_New_11 += parseInt(data1.haste_New_11);
this.haste_Renew_11 += parseInt(data1.haste_Renew_11);
this.haste_Total_11 += parseInt(data1.haste_Total_11);
this.haste_New_12 += parseInt(data1.haste_New_12);
this.haste_Renew_12 += parseInt(data1.haste_Renew_12);
this.haste_Total_12 += parseInt(data1.haste_Total_12);
this.haste_New_1 += parseInt(data1.haste_New_1);
this.haste_Renew_1 += parseInt(data1.haste_Renew_1);
this.haste_Total_1 += parseInt(data1.haste_Total_1);
this.haste_New_2 += parseInt(data1.haste_New_2);
this.haste_Renew_2 += parseInt(data1.haste_Renew_2);
this.haste_Total_2 += parseInt(data1.haste_Total_2);
this.haste_New_3 += parseInt(data1.haste_New_3);
this.haste_Renew_3 += parseInt(data1.haste_Renew_3);
this.haste_Total_3 += parseInt(data1.haste_Total_3);

this.magzine_New_4 += parseInt(data1.magzine_New_4);
this.magzine_Renew_4 += parseInt(data1.magzine_Renew_4);
this.magzine_Total_4 += parseInt(data1.magzine_Total_4);
this.magzine_New_5 += parseInt(data1.magzine_New_5);
this.magzine_Renew_5 += parseInt(data1.magzine_Renew_5);
this.magzine_Total_5 += parseInt(data1.magzine_Total_5);
this.magzine_New_6 += parseInt(data1.magzine_New_6);
this.magzine_Renew_6 += parseInt(data1.magzine_Renew_6);
this.magzine_Total_6 += parseInt(data1.magzine_Total_6);
this.magzine_New_7 += parseInt(data1.magzine_New_7);
this.magzine_Renew_7 += parseInt(data1.magzine_Renew_7);
this.magzine_Total_7 += parseInt(data1.magzine_Total_7);
this.magzine_New_8 += parseInt(data1.magzine_New_8);
this.magzine_Renew_8 += parseInt(data1.magzine_Renew_8);
this.magzine_Total_8 += parseInt(data1.magzine_Total_8);
this.magzine_New_9 += parseInt(data1.magzine_New_9);
this.magzine_Renew_9 += parseInt(data1.magzine_Renew_9);
this.magzine_Total_9 += parseInt(data1.magzine_Total_9);
this.magzine_New_10 += parseInt(data1.magzine_New_10);
this.magzine_Renew_10 += parseInt(data1.magzine_Renew_10);
this.magzine_Total_10 += parseInt(data1.magzine_Total_10);
this.magzine_New_11 += parseInt(data1.magzine_New_11);
this.magzine_Renew_11 += parseInt(data1.magzine_Renew_11);
this.magzine_Total_11 += parseInt(data1.magzine_Total_11);
this.magzine_New_12 += parseInt(data1.magzine_New_12);
this.magzine_Renew_12 += parseInt(data1.magzine_Renew_12);
this.magzine_Total_12 += parseInt(data1.magzine_Total_12);
this.magzine_New_1 += parseInt(data1.magzine_New_1);
this.magzine_Renew_1 += parseInt(data1.magzine_Renew_1);
this.magzine_Total_1 += parseInt(data1.magzine_Total_1);
this.magzine_New_2 += parseInt(data1.magzine_New_2);
this.magzine_Renew_2 += parseInt(data1.magzine_Renew_2);
this.magzine_Total_2 += parseInt(data1.magzine_Total_2);
this.magzine_New_3 += parseInt(data1.magzine_New_3);
this.magzine_Renew_3 += parseInt(data1.magzine_Renew_3);
this.magzine_Total_3 += parseInt(data1.magzine_Total_3);

this.digital_New_4 += parseInt(data1.digital_New_4);
this.digital_Renew_4 += parseInt(data1.digital_Renew_4);
this.digital_Total_4 += parseInt(data1.digital_Total_4);
this.digital_New_5 += parseInt(data1.digital_New_5);
this.digital_Renew_5 += parseInt(data1.digital_Renew_5);
this.digital_Total_5 += parseInt(data1.digital_Total_5);
this.digital_New_6 += parseInt(data1.digital_New_6);
this.digital_Renew_6 += parseInt(data1.digital_Renew_6);
this.digital_Total_6 += parseInt(data1.digital_Total_6);
this.digital_New_7 += parseInt(data1.digital_New_7);
this.digital_Renew_7 += parseInt(data1.digital_Renew_7);
this.digital_Total_7 += parseInt(data1.digital_Total_7);
this.digital_New_8 += parseInt(data1.digital_New_8);
this.digital_Renew_8 += parseInt(data1.digital_Renew_8);
this.digital_Total_8 += parseInt(data1.digital_Total_8);
this.digital_New_9 += parseInt(data1.digital_New_9);
this.digital_Renew_9 += parseInt(data1.digital_Renew_9);
this.digital_Total_9 += parseInt(data1.digital_Total_9);
this.digital_New_10 += parseInt(data1.digital_New_10);
this.digital_Renew_10 += parseInt(data1.digital_Renew_10);
this.digital_Total_10 += parseInt(data1.digital_Total_10);
this.digital_New_11 += parseInt(data1.digital_New_11);
this.digital_Renew_11 += parseInt(data1.digital_Renew_11);
this.digital_Total_11 += parseInt(data1.digital_Total_11);
this.digital_New_12 += parseInt(data1.digital_New_12);
this.digital_Renew_12 += parseInt(data1.digital_Renew_12);
this.digital_Total_12 += parseInt(data1.digital_Total_12);
this.digital_New_1 += parseInt(data1.digital_New_1);
this.digital_Renew_1 += parseInt(data1.digital_Renew_1);
this.digital_Total_1 += parseInt(data1.digital_Total_1);
this.digital_New_2 += parseInt(data1.digital_New_2);
this.digital_Renew_2 += parseInt(data1.digital_Renew_2);
this.digital_Total_2 += parseInt(data1.digital_Total_2);
this.digital_New_3 += parseInt(data1.digital_New_3);
this.digital_Renew_3 += parseInt(data1.digital_Renew_3);
this.digital_Total_3 += parseInt(data1.digital_Total_3);

this.tapal_New_total += parseInt(data1.tapal_New_Total);
this.tapal_Renew_total += parseInt(data1.tapal_Renew_Total);
this.tapal_Total_total += parseInt(data1.tapal_Total_Total);
this.haste_New_total += parseInt(data1.haste_New_Total);
this.haste_Renew_total += parseInt(data1.haste_Renew_Total);
this.haste_Total_total += parseInt(data1.haste_Total_Total);
this.magzine_New_total += parseInt(data1.magzine_New_Total);
this.magzine_Renew_total += parseInt(data1.magzine_Renew_Total);
this.magzine_Total_total += parseInt(data1.magzine_Total_Total);
this.digital_New_total += parseInt(data1.digital_New_Total);
this.digital_Renew_total += parseInt(data1.digital_Renew_Total);
this.digital_Total_total += parseInt(data1.digital_Total_Total);

        
data11.tapal_New_4 += parseInt(data1.tapal_New_4);
data11.tapal_Renew_4 += parseInt(data1.tapal_Renew_4);
data11.tapal_Total_4 += parseInt(data1.tapal_Total_4);
data11.tapal_New_5 += parseInt(data1.tapal_New_5);
data11.tapal_Renew_5 += parseInt(data1.tapal_Renew_5);
data11.tapal_Total_5 += parseInt(data1.tapal_Total_5);
data11.tapal_New_6 += parseInt(data1.tapal_New_6);
data11.tapal_Renew_6 += parseInt(data1.tapal_Renew_6);
data11.tapal_Total_6 += parseInt(data1.tapal_Total_6);
data11.tapal_New_7 += parseInt(data1.tapal_New_7);
data11.tapal_Renew_7 += parseInt(data1.tapal_Renew_7);
data11.tapal_Total_7 += parseInt(data1.tapal_Total_7);
data11.tapal_New_8 += parseInt(data1.tapal_New_8);
data11.tapal_Renew_8 += parseInt(data1.tapal_Renew_8);
data11.tapal_Total_8 += parseInt(data1.tapal_Total_8);
data11.tapal_New_9 += parseInt(data1.tapal_New_9);
data11.tapal_Renew_9 += parseInt(data1.tapal_Renew_9);
data11.tapal_Total_9 += parseInt(data1.tapal_Total_9);
data11.tapal_New_10 += parseInt(data1.tapal_New_10);
data11.tapal_Renew_10 += parseInt(data1.tapal_Renew_10);
data11.tapal_Total_10 += parseInt(data1.tapal_Total_10);
data11.tapal_New_11 += parseInt(data1.tapal_New_11);
data11.tapal_Renew_11 += parseInt(data1.tapal_Renew_11);
data11.tapal_Total_11 += parseInt(data1.tapal_Total_11);
data11.tapal_New_12 += parseInt(data1.tapal_New_12);
data11.tapal_Renew_12 += parseInt(data1.tapal_Renew_12);
data11.tapal_Total_12 += parseInt(data1.tapal_Total_12);
data11.tapal_New_1 += parseInt(data1.tapal_New_1);
data11.tapal_Renew_1 += parseInt(data1.tapal_Renew_1);
data11.tapal_Total_1 += parseInt(data1.tapal_Total_1);
data11.tapal_New_2 += parseInt(data1.tapal_New_2);
data11.tapal_Renew_2 += parseInt(data1.tapal_Renew_2);
data11.tapal_Total_2 += parseInt(data1.tapal_Total_2);
data11.tapal_New_3 += parseInt(data1.tapal_New_3);
data11.tapal_Renew_3 += parseInt(data1.tapal_Renew_3);
data11.tapal_Total_3 += parseInt(data1.tapal_Total_3);

data11.haste_New_4  += parseInt(data1.haste_New_4);
data11.haste_Renew_4 += parseInt(data1.haste_Renew_4);
data11.haste_Total_4 += parseInt(data1.haste_Total_4);
data11.haste_New_5  += parseInt(data1.haste_New_5);
data11.haste_Renew_5 += parseInt(data1.haste_Renew_5);
data11.haste_Total_5 += parseInt(data1.haste_Total_5);
data11.haste_New_6  += parseInt(data1.haste_New_6);
data11.haste_Renew_6 += parseInt(data1.haste_Renew_6);
data11.haste_Total_6 += parseInt(data1.haste_Total_6);
data11.haste_New_7  += parseInt(data1.haste_New_7);
data11.haste_Renew_7 += parseInt(data1.haste_Renew_7);
data11.haste_Total_7 += parseInt(data1.haste_Total_7);
data11.haste_New_8  += parseInt(data1.haste_New_8);
data11.haste_Renew_8 += parseInt(data1.haste_Renew_8);
data11.haste_Total_8 += parseInt(data1.haste_Total_8);
data11.haste_New_9  += parseInt(data1.haste_New_9);
data11.haste_Renew_9 += parseInt(data1.haste_Renew_9);
data11.haste_Total_9 += parseInt(data1.haste_Total_9);
data11.haste_New_10  += parseInt(data1.haste_New_10);
data11.haste_Renew_10 += parseInt(data1.haste_Renew_10);
data11.haste_Total_10 += parseInt(data1.haste_Total_10);
data11.haste_New_11  += parseInt(data1.haste_New_11);
data11.haste_Renew_11 += parseInt(data1.haste_Renew_11);
data11.haste_Total_11 += parseInt(data1.haste_Total_11);
data11.haste_New_12  += parseInt(data1.haste_New_12);
data11.haste_Renew_12 += parseInt(data1.haste_Renew_12);
data11.haste_Total_12 += parseInt(data1.haste_Total_12);
data11.haste_New_1  += parseInt(data1.haste_New_1);
data11.haste_Renew_1 += parseInt(data1.haste_Renew_1);
data11.haste_Total_1 += parseInt(data1.haste_Total_1);
data11.haste_New_2  += parseInt(data1.haste_New_2);
data11.haste_Renew_2 += parseInt(data1.haste_Renew_2);
data11.haste_Total_2 += parseInt(data1.haste_Total_2);
data11.haste_New_3  += parseInt(data1.haste_New_3);
data11.haste_Renew_3 += parseInt(data1.haste_Renew_3);
data11.haste_Total_3 += parseInt(data1.haste_Total_3);

data11.magzine_New_4  += parseInt(data1.magzine_New_4);
data11.magzine_Renew_4 += parseInt(data1.magzine_Renew_4);
data11.magzine_Total_4 += parseInt(data1.magzine_Total_4);
data11.magzine_New_5  += parseInt(data1.magzine_New_5);
data11.magzine_Renew_5 += parseInt(data1.magzine_Renew_5);
data11.magzine_Total_5 += parseInt(data1.magzine_Total_5);
data11.magzine_New_6  += parseInt(data1.magzine_New_6);
data11.magzine_Renew_6 += parseInt(data1.magzine_Renew_6);
data11.magzine_Total_6 += parseInt(data1.magzine_Total_6);
data11.magzine_New_7  += parseInt(data1.magzine_New_7);
data11.magzine_Renew_7 += parseInt(data1.magzine_Renew_7);
data11.magzine_Total_7 += parseInt(data1.magzine_Total_7);
data11.magzine_New_8  += parseInt(data1.magzine_New_8);
data11.magzine_Renew_8 += parseInt(data1.magzine_Renew_8);
data11.magzine_Total_8 += parseInt(data1.magzine_Total_8);
data11.magzine_New_9  += parseInt(data1.magzine_New_9);
data11.magzine_Renew_9 += parseInt(data1.magzine_Renew_9);
data11.magzine_Total_9 += parseInt(data1.magzine_Total_9);
data11.magzine_New_10  += parseInt(data1.magzine_New_10);
data11.magzine_Renew_10 += parseInt(data1.magzine_Renew_10);
data11.magzine_Total_10 += parseInt(data1.magzine_Total_10);
data11.magzine_New_11  += parseInt(data1.magzine_New_11);
data11.magzine_Renew_11 += parseInt(data1.magzine_Renew_11);
data11.magzine_Total_11 += parseInt(data1.magzine_Total_11);
data11.magzine_New_12  += parseInt(data1.magzine_New_12);
data11.magzine_Renew_12 += parseInt(data1.magzine_Renew_12);
data11.magzine_Total_12 += parseInt(data1.magzine_Total_12);
data11.magzine_New_1  += parseInt(data1.magzine_New_1);
data11.magzine_Renew_1 += parseInt(data1.magzine_Renew_1);
data11.magzine_Total_1 += parseInt(data1.magzine_Total_1);
data11.magzine_New_2  += parseInt(data1.magzine_New_2);
data11.magzine_Renew_2 += parseInt(data1.magzine_Renew_2);
data11.magzine_Total_2 += parseInt(data1.magzine_Total_2);
data11.magzine_New_3  += parseInt(data1.magzine_New_3);
data11.magzine_Renew_3 += parseInt(data1.magzine_Renew_3);
data11.magzine_Total_3 += parseInt(data1.magzine_Total_3);

data11.digital_New_4  += parseInt(data1.digital_New_4);
data11.digital_Renew_4 += parseInt(data1.digital_Renew_4);
data11.digital_Total_4 += parseInt(data1.digital_Total_4);
data11.digital_New_5  += parseInt(data1.digital_New_5);
data11.digital_Renew_5 += parseInt(data1.digital_Renew_5);
data11.digital_Total_5 += parseInt(data1.digital_Total_5);
data11.digital_New_6  += parseInt(data1.digital_New_6);
data11.digital_Renew_6 += parseInt(data1.digital_Renew_6);
data11.digital_Total_6 += parseInt(data1.digital_Total_6);
data11.digital_New_7  += parseInt(data1.digital_New_7);
data11.digital_Renew_7 += parseInt(data1.digital_Renew_7);
data11.digital_Total_7 += parseInt(data1.digital_Total_7);
data11.digital_New_8  += parseInt(data1.digital_New_8);
data11.digital_Renew_8 += parseInt(data1.digital_Renew_8);
data11.digital_Total_8 += parseInt(data1.digital_Total_8);
data11.digital_New_9  += parseInt(data1.digital_New_9);
data11.digital_Renew_9 += parseInt(data1.digital_Renew_9);
data11.digital_Total_9 += parseInt(data1.digital_Total_9);
data11.digital_New_10  += parseInt(data1.digital_New_10);
data11.digital_Renew_10 += parseInt(data1.digital_Renew_10);
data11.digital_Total_10 += parseInt(data1.digital_Total_10);
data11.digital_New_11  += parseInt(data1.digital_New_11);
data11.digital_Renew_11 += parseInt(data1.digital_Renew_11);
data11.digital_Total_11 += parseInt(data1.digital_Total_11);
data11.digital_New_12  += parseInt(data1.digital_New_12);
data11.digital_Renew_12 += parseInt(data1.digital_Renew_12);
data11.digital_Total_12 += parseInt(data1.digital_Total_12);
data11.digital_New_1  += parseInt(data1.digital_New_1);
data11.digital_Renew_1 += parseInt(data1.digital_Renew_1);
data11.digital_Total_1 += parseInt(data1.digital_Total_1);
data11.digital_New_2  += parseInt(data1.digital_New_2);
data11.digital_Renew_2 += parseInt(data1.digital_Renew_2);
data11.digital_Total_2 += parseInt(data1.digital_Total_2);
data11.digital_New_3  += parseInt(data1.digital_New_3);
data11.digital_Renew_3 += parseInt(data1.digital_Renew_3);
data11.digital_Total_3 += parseInt(data1.digital_Total_3);

data11.tapal_New_total  += parseInt(data1.tapal_New_Total);
data11.tapal_Renew_total += parseInt(data1.tapal_Renew_Total);
data11.tapal_Total_total += parseInt(data1.tapal_Total_Total);
data11.haste_New_total  += parseInt(data1.haste_New_Total);
data11.haste_Renew_total += parseInt(data1.haste_Renew_Total);
data11.haste_Total_total += parseInt(data1.haste_Total_Total);
data11.magzine_New_total  += parseInt(data1.magzine_New_Total);
data11.magzine_Renew_total += parseInt(data1.magzine_Renew_Total);
data11.magzine_Total_total += parseInt(data1.magzine_Total_Total);
data11.digital_New_total  += parseInt(data1.digital_New_Total);
data11.digital_Renew_total += parseInt(data1.digital_Renew_Total);
data11.digital_Total_total += parseInt(data1.digital_Total_Total);


      }
    }

  }


    console.log("taluka renewal reming", data);
     });
}






if(this.reportType=="RemainingSubscriptionOffReprensantive"){
  this.service.getSubRemOffRep(req).subscribe((data) => {
    this.SubRemOffRep = data;

    this.tapal_New_4 = 0;
    this.tapal_Renew_4 = 0;
    this.tapal_Total_4 = 0;

    this.tapal_New_5  = 0;
    this.tapal_Renew_5 = 0;
    this.tapal_Total_5 = 0;
   
    this.tapal_New_6  = 0;
    this.tapal_Renew_6 = 0;
    this.tapal_Total_6 = 0;

    this.tapal_New_7  = 0;
    this.tapal_Renew_7 = 0;
    this.tapal_Total_7 = 0;

    this.tapal_New_8  = 0;
    this.tapal_Renew_8 = 0;
    this.tapal_Total_8 = 0;

    this.tapal_New_9  = 0;
    this.tapal_Renew_9 = 0;
    this.tapal_Total_9 = 0;

    this.tapal_New_10  = 0;
    this.tapal_Renew_10 = 0;
    this.tapal_Total_10 = 0;

    this.tapal_New_11  = 0;
    this.tapal_Renew_11 = 0;
    this.tapal_Total_11 = 0;

    this.tapal_New_12  = 0;
    this.tapal_Renew_12 = 0;
    this.tapal_Total_12 = 0;

    this.tapal_New_1  = 0;
    this.tapal_Renew_1 = 0;
    this.tapal_Total_1 = 0;

    this.tapal_New_2  = 0;
    this.tapal_Renew_2 = 0;
    this.tapal_Total_2 = 0;

    this.tapal_New_3  = 0;
    this.tapal_Renew_3 = 0;
    this.tapal_Total_3 = 0;

    this.haste_New_4 = 0;
    this.haste_Renew_4 = 0;
    this.haste_Total_4 = 0;

    
    this.haste_New_5 = 0;
    this.haste_Renew_5 = 0;
    this.haste_Total_5 = 0;

    this.haste_New_6 = 0;
    this.haste_Renew_6 = 0;
    this.haste_Total_6 = 0;
    
    this.haste_New_7 = 0;
    this.haste_Renew_7 = 0;
    this.haste_Total_7 = 0;

    this.haste_New_8 = 0;
    this.haste_Renew_8 = 0;
    this.haste_Total_8 = 0;
   
    this.haste_New_9 = 0;
    this.haste_Renew_9 = 0;
    this.haste_Total_9 = 0;

    this.haste_New_10 = 0;
    this.haste_Renew_10 = 0;
    this.haste_Total_10 = 0;
   
    this.haste_New_11 = 0;
    this.haste_Renew_11 = 0;
    this.haste_Total_11 = 0;
    
    this.haste_New_12 = 0;
    this.haste_Renew_12 = 0;
    this.haste_Total_12 = 0;
    
    this.haste_Total_1 = 0;
    this.haste_New_1 = 0;
    this.haste_Renew_1 = 0;
    
    this.haste_Total_2 = 0;
    this.haste_New_2 = 0;
    this.haste_Renew_2 = 0;
  
    this.haste_Total_3 = 0;
    this.haste_New_3 = 0;
    this.haste_Renew_3 = 0;

    this.magzine_New_4 = 0;
    this.magzine_Renew_4 = 0;
    this.magzine_Total_4 = 0;

    this.magzine_New_5  = 0;
    this.magzine_Renew_5 = 0;
    this.magzine_Total_5 = 0;

    this.magzine_New_6  = 0;
    this.magzine_Renew_6 = 0;
    this.magzine_Total_6 = 0;

    this.magzine_New_7  = 0;
    this.magzine_Renew_7 = 0;
    this.magzine_Total_7 = 0;

    this.magzine_New_8  = 0;
    this.magzine_Renew_8 = 0;
    this.magzine_Total_8 = 0;

    this.magzine_New_9  = 0;
    this.magzine_Renew_9 = 0;
    this.magzine_Total_9 = 0;

    this.magzine_New_10  = 0;
    this.magzine_Renew_10 = 0;
    this.magzine_Total_10 = 0;

    this.magzine_New_11  = 0;
    this.magzine_Renew_11 = 0;
    this.magzine_Total_11 = 0;

    this.magzine_New_12  = 0;
    this.magzine_Renew_12 = 0;    
    this.magzine_Total_12 = 0;

    this.magzine_New_1  = 0;
    this.magzine_Renew_1 = 0;
    this.magzine_Total_1 = 0;

    this.magzine_New_2  = 0;
    this.magzine_Renew_2 = 0;
    this.magzine_Total_2 = 0;

    this.magzine_New_3  = 0;
    this.magzine_Renew_3 = 0;
    this.magzine_Total_3 = 0;

    this.digital_New_4 = 0;
    this.digital_Renew_4 = 0;
    this.digital_Total_4 = 0;

    this.digital_New_5 = 0;
    this.digital_Renew_5 = 0;
    this.digital_Total_5 = 0;

  
    this.digital_New_6 = 0;
    this.digital_Renew_6 = 0;
    this.digital_Total_6 = 0;
    
    this.digital_New_7 = 0;
    this.digital_Renew_7 = 0;
    this.digital_Total_7 = 0;
    
    this.digital_New_8 = 0;
    this.digital_Renew_8 = 0;
    this.digital_Total_8 = 0;
   
    this.digital_New_9 = 0;
    this.digital_Renew_9 = 0;
    this.digital_Total_9 = 0;
    
    this.digital_New_10 = 0;
    this.digital_Renew_10 = 0;
    this.digital_Total_10 = 0;
    
    this.digital_New_11 = 0;
    this.digital_Renew_11 = 0;
    this.digital_Total_11 = 0;
   
    this.digital_New_12 = 0;
    this.digital_Renew_12 = 0;
    this.digital_Total_12 = 0;

    this.digital_New_1 = 0;
    this.digital_Renew_1 = 0;
    this.digital_Total_1 = 0;
    
    this.digital_New_2 = 0;
    this.digital_Renew_2 = 0;
    this.digital_Total_2 = 0;
    
    this.digital_New_3 = 0;
    this.digital_Renew_3 = 0;
    this.digital_Total_3 = 0;
    

    this.tapal_New_total = 0;
    this.tapal_Renew_total = 0;
    this.tapal_Total_total = 0;
    this.haste_New_total = 0;
    this.haste_Renew_total = 0;
    this.haste_Total_total = 0;
    this.magzine_New_total = 0;
    this.magzine_Renew_total = 0;
    this.magzine_Total_total = 0;
    this.digital_New_total = 0;
    this.digital_Renew_total = 0;
    this.digital_Total_total = 0;


  for (let data2 of this.SubRemOffRep){


    data2.tapal_New_4 = 0;
    data2.tapal_Renew_4 = 0;
    data2.tapal_Total_4 = 0;
    
    data2.tapal_New_5 = 0;
    data2.tapal_Renew_5 = 0;
    data2.tapal_Total_5 = 0;
    
    data2.tapal_New_6 = 0;
    data2.tapal_Renew_6 = 0;
    data2.tapal_Total_6 = 0;
    
    data2.tapal_New_7 = 0;
    data2.tapal_Renew_7 = 0;
    data2.tapal_Total_7 = 0;
    
    data2.tapal_New_8 = 0;
    data2.tapal_Renew_8 = 0;
    data2.tapal_Total_8 = 0;
    
    data2.tapal_New_9 = 0;
    data2.tapal_Renew_9 = 0;
    data2.tapal_Total_9 = 0;
    
    data2.tapal_New_10 = 0;
    data2.tapal_Renew_10 = 0;
    data2.tapal_Total_10 = 0;
    
    data2.tapal_New_11 = 0;
    data2.tapal_Renew_11 = 0;
    data2.tapal_Total_11 = 0;
    
    data2.tapal_New_12 = 0;
    data2.tapal_Renew_12 = 0;
    data2.tapal_Total_12 = 0;
    
    data2.tapal_New_1 = 0;
    data2.tapal_Renew_1 = 0;
    data2.tapal_Total_1 = 0;
    
    data2.tapal_New_2 = 0;
    data2.tapal_Renew_2 = 0;
    data2.tapal_Total_2 = 0;
    
    data2.tapal_New_3 = 0;
    data2.tapal_Renew_3 = 0;
    data2.tapal_Total_3 = 0;
    
    data2.haste_New_4 = 0;
    data2.haste_Renew_4 = 0;
    data2.haste_Total_4 = 0;
    
    data2.haste_New_5 = 0;
    data2.haste_Renew_5 = 0;
    data2.haste_Total_5 = 0;
    
    data2.haste_New_6 = 0;
    data2.haste_Renew_6 = 0;
    data2.haste_Total_6 = 0;
    
    data2.haste_New_7 = 0;
    data2.haste_Renew_7 = 0;
    data2.haste_Total_7 = 0;
    
    data2.haste_New_8 = 0;
    data2.haste_Renew_8 = 0;
    data2.haste_Total_8 = 0;
    
    data2.haste_New_9 = 0;
    data2.haste_Renew_9 = 0;
    data2.haste_Total_9 = 0;
    
    data2.haste_New_10 = 0;
    data2.haste_Renew_10 = 0;
    data2.haste_Total_10 = 0;
    
    data2.haste_New_11 = 0;
    data2.haste_Renew_11 = 0;
    data2.haste_Total_11 = 0;
    
    data2.haste_New_12 = 0;
    data2.haste_Renew_12 = 0;
    data2.haste_Total_12 = 0;
    
    data2.haste_Total_1 = 0;
    data2.haste_New_1 = 0;
    data2.haste_Renew_1 = 0;
    
    data2.haste_Total_2 = 0;
    data2.haste_New_2 = 0;
    data2.haste_Renew_2 = 0;
    
    data2.haste_Total_3 = 0;
    data2.haste_New_3 = 0;
    data2.haste_Renew_3 = 0;
    
    data2.magzine_New_4 = 0;
    data2.magzine_Renew_4 = 0;
    data2.magzine_Total_4 = 0;
    
    data2.magzine_New_5 = 0;
    data2.magzine_Renew_5 = 0;
    data2.magzine_Total_5 = 0;
    
    data2.magzine_New_6 = 0;
    data2.magzine_Renew_6 = 0;
    data2.magzine_Total_6 = 0;
    
    data2.magzine_New_7 = 0;
    data2.magzine_Renew_7 = 0;
    data2.magzine_Total_7 = 0;
    
    data2.magzine_New_8 = 0;
    data2.magzine_Renew_8 = 0;
    data2.magzine_Total_8 = 0;
    
    data2.magzine_New_9 = 0;
    data2.magzine_Renew_9 = 0;
    data2.magzine_Total_9 = 0;
    
    data2.magzine_New_10 = 0;
    data2.magzine_Renew_10 = 0;
    data2.magzine_Total_10 = 0;
    
    data2.magzine_New_11 = 0;
    data2.magzine_Renew_11 = 0;
    data2.magzine_Total_11 = 0;
    
    data2.magzine_New_12 = 0;
    data2.magzine_Renew_12 = 0;
    data2.magzine_Total_12 = 0;
    
    data2.magzine_New_1 = 0;
    data2.magzine_Renew_1 = 0;
    data2.magzine_Total_1 = 0;
    
    data2.magzine_New_2 = 0;
    data2.magzine_Renew_2 = 0;
    data2.magzine_Total_2 = 0;
    
    data2.magzine_New_3 = 0;
    data2.magzine_Renew_3 = 0;
    data2.magzine_Total_3 = 0;
    
    data2.digital_New_4 = 0;
    data2.digital_Renew_4 = 0;
    data2.digital_Total_4 = 0;
    
    data2.digital_New_5 = 0;
    data2.digital_Renew_5 = 0;
    data2.digital_Total_5 = 0;
    
    data2.digital_New_6 = 0;
    data2.digital_Renew_6 = 0;
    data2.digital_Total_6 = 0;
    
    data2.digital_New_7 = 0;
    data2.digital_Renew_7 = 0;
    data2.digital_Total_7 = 0;
    
    data2.digital_New_8 = 0;
    data2.digital_Renew_8 = 0;
    data2.digital_Total_8 = 0;
    
    data2.digital_New_9 = 0;
    data2.digital_Renew_9 = 0;
    data2.digital_Total_9 = 0;
    
    data2.digital_New_10 = 0;
    data2.digital_Renew_10 = 0;
    data2.digital_Total_10 = 0;
    
    data2.digital_New_11 = 0;
    data2.digital_Renew_11 = 0;
    data2.digital_Total_11 = 0;
    
    data2.digital_New_12 = 0;
    data2.digital_Renew_12 = 0;
    data2.digital_Total_12 = 0;
    
    data2.digital_New_1 = 0;
    data2.digital_Renew_1 = 0;
    data2.digital_Total_1 = 0;
    
    data2.digital_New_2 = 0;
    data2.digital_Renew_2 = 0;
    data2.digital_Total_2 = 0;
    
    data2.digital_New_3 = 0;
    data2.digital_Renew_3 = 0;
    data2.digital_Total_3 = 0;
    
    data2.tapal_New_total = 0;
    data2.tapal_Renew_total = 0;
    data2.tapal_Total_total = 0;
    data2.haste_New_total = 0;
    data2.haste_Renew_total = 0;
    data2.haste_Total_total = 0;
    data2.magzine_New_total = 0;
    data2.magzine_Renew_total = 0;
    data2.magzine_Total_total = 0;
    data2.digital_New_total = 0;
    data2.digital_Renew_total = 0;
    data2.digital_Total_total = 0;
    


    for(let data11 of data2.districts){



      data11.tapal_New_4 = 0;
      data11.tapal_Renew_4 = 0;
      data11.tapal_Total_4 = 0;
      
      data11.tapal_New_5 = 0;
      data11.tapal_Renew_5 = 0;
      data11.tapal_Total_5 = 0;
      
      data11.tapal_New_6 = 0;
      data11.tapal_Renew_6 = 0;
      data11.tapal_Total_6 = 0;
      
      data11.tapal_New_7 = 0;
      data11.tapal_Renew_7 = 0;
      data11.tapal_Total_7 = 0;
      
      data11.tapal_New_8 = 0;
      data11.tapal_Renew_8 = 0;
      data11.tapal_Total_8 = 0;
      
      data11.tapal_New_9 = 0;
      data11.tapal_Renew_9 = 0;
      data11.tapal_Total_9 = 0;
      
      data11.tapal_New_10 = 0;
      data11.tapal_Renew_10 = 0;
      data11.tapal_Total_10 = 0;
      
      data11.tapal_New_11 = 0;
      data11.tapal_Renew_11 = 0;
      data11.tapal_Total_11 = 0;
      
      data11.tapal_New_12 = 0;
      data11.tapal_Renew_12 = 0;
      data11.tapal_Total_12 = 0;
      
      data11.tapal_New_1 = 0;
      data11.tapal_Renew_1 = 0;
      data11.tapal_Total_1 = 0;
      
      data11.tapal_New_2 = 0;
      data11.tapal_Renew_2 = 0;
      data11.tapal_Total_2 = 0;
      
      data11.tapal_New_3 = 0;
      data11.tapal_Renew_3 = 0;
      data11.tapal_Total_3 = 0;
      
      data11.haste_New_4 = 0;
      data11.haste_Renew_4 = 0;
      data11.haste_Total_4 = 0;
      
      data11.haste_New_5 = 0;
      data11.haste_Renew_5 = 0;
      data11.haste_Total_5 = 0;
      
      data11.haste_New_6 = 0;
      data11.haste_Renew_6 = 0;
      data11.haste_Total_6 = 0;
      
      data11.haste_New_7 = 0;
      data11.haste_Renew_7 = 0;
      data11.haste_Total_7 = 0;
      
      data11.haste_New_8 = 0;
      data11.haste_Renew_8 = 0;
      data11.haste_Total_8 = 0;
      
      data11.haste_New_9 = 0;
      data11.haste_Renew_9 = 0;
      data11.haste_Total_9 = 0;
      
      data11.haste_New_10 = 0;
      data11.haste_Renew_10 = 0;
      data11.haste_Total_10 = 0;
      
      data11.haste_New_11 = 0;
      data11.haste_Renew_11 = 0;
      data11.haste_Total_11 = 0;
      
      data11.haste_New_12 = 0;
      data11.haste_Renew_12 = 0;
      data11.haste_Total_12 = 0;
      
      data11.haste_Total_1 = 0;
      data11.haste_New_1 = 0;
      data11.haste_Renew_1 = 0;
      
      data11.haste_Total_2 = 0;
      data11.haste_New_2 = 0;
      data11.haste_Renew_2 = 0;
      
      data11.haste_Total_3 = 0;
      data11.haste_New_3 = 0;
      data11.haste_Renew_3 = 0;
      
      data11.magzine_New_4 = 0;
      data11.magzine_Renew_4 = 0;
      data11.magzine_Total_4 = 0;
      
      data11.magzine_New_5 = 0;
      data11.magzine_Renew_5 = 0;
      data11.magzine_Total_5 = 0;
      
      data11.magzine_New_6 = 0;
      data11.magzine_Renew_6 = 0;
      data11.magzine_Total_6 = 0;
      
      data11.magzine_New_7 = 0;
      data11.magzine_Renew_7 = 0;
      data11.magzine_Total_7 = 0;
      
      data11.magzine_New_8 = 0;
      data11.magzine_Renew_8 = 0;
      data11.magzine_Total_8 = 0;
      
      data11.magzine_New_9 = 0;
      data11.magzine_Renew_9 = 0;
      data11.magzine_Total_9 = 0;
      
      data11.magzine_New_10 = 0;
      data11.magzine_Renew_10 = 0;
      data11.magzine_Total_10 = 0;
      
      data11.magzine_New_11 = 0;
      data11.magzine_Renew_11 = 0;
      data11.magzine_Total_11 = 0;
      
      data11.magzine_New_12 = 0;
      data11.magzine_Renew_12 = 0;
      data11.magzine_Total_12 = 0;
      
      data11.magzine_New_1 = 0;
      data11.magzine_Renew_1 = 0;
      data11.magzine_Total_1 = 0;
      
      data11.magzine_New_2 = 0;
      data11.magzine_Renew_2 = 0;
      data11.magzine_Total_2 = 0;
      
      data11.magzine_New_3 = 0;
      data11.magzine_Renew_3 = 0;
      data11.magzine_Total_3 = 0;
      
      data11.digital_New_4 = 0;
      data11.digital_Renew_4 = 0;
      data11.digital_Total_4 = 0;
      
      data11.digital_New_5 = 0;
      data11.digital_Renew_5 = 0;
      data11.digital_Total_5 = 0;
      
      data11.digital_New_6 = 0;
      data11.digital_Renew_6 = 0;
      data11.digital_Total_6 = 0;
      
      data11.digital_New_7 = 0;
      data11.digital_Renew_7 = 0;
      data11.digital_Total_7 = 0;
      
      data11.digital_New_8 = 0;
      data11.digital_Renew_8 = 0;
      data11.digital_Total_8 = 0;
      
      data11.digital_New_9 = 0;
      data11.digital_Renew_9 = 0;
      data11.digital_Total_9 = 0;
      
      data11.digital_New_10 = 0;
      data11.digital_Renew_10 = 0;
      data11.digital_Total_10 = 0;
      
      data11.digital_New_11 = 0;
      data11.digital_Renew_11 = 0;
      data11.digital_Total_11 = 0;
      
      data11.digital_New_12 = 0;
      data11.digital_Renew_12 = 0;
      data11.digital_Total_12 = 0;
      
      data11.digital_New_1 = 0;
      data11.digital_Renew_1 = 0;
      data11.digital_Total_1 = 0;
      
      data11.digital_New_2 = 0;
      data11.digital_Renew_2 = 0;
      data11.digital_Total_2 = 0;
      
      data11.digital_New_3 = 0;
      data11.digital_Renew_3 = 0;
      data11.digital_Total_3 = 0;
      
      data11.tapal_New_total = 0;
      data11.tapal_Renew_total = 0;
      data11.tapal_Total_total = 0;
      data11.haste_New_total = 0;
      data11.haste_Renew_total = 0;
      data11.haste_Total_total = 0;
      data11.magzine_New_total = 0;
      data11.magzine_Renew_total = 0;
      data11.magzine_Total_total = 0;
      data11.digital_New_total = 0;
      data11.digital_Renew_total = 0;
      data11.digital_Total_total = 0;
      

      for(let data1 of data11.talukas){

        data1.tapal_New_4 = 0;
        data1.tapal_Renew_4 = 0;
        data1.tapal_Total_4 = 0;

        data1.tapal_New_5 = 0;
        data1.tapal_Renew_5 = 0;
        data1.tapal_Total_5 = 0;

        data1.tapal_New_6 = 0;
        data1.tapal_Renew_6 = 0;
        data1.tapal_Total_6 = 0;

        data1.tapal_New_7 = 0;
        data1.tapal_Renew_7 = 0;
        data1.tapal_Total_7 = 0;

        data1.tapal_New_8 = 0;
        data1.tapal_Renew_8 = 0;
        data1.tapal_Total_8 = 0;

        data1.tapal_New_9 = 0;
        data1.tapal_Renew_9 = 0;
        data1.tapal_Total_9 = 0;

        data1.tapal_New_10 = 0;
        data1.tapal_Renew_10 = 0;
        data1.tapal_Total_10 = 0;

        data1.tapal_New_11 = 0;
        data1.tapal_Renew_11 = 0;
        data1.tapal_Total_11 = 0;

        data1.tapal_New_12 = 0;
        data1.tapal_Renew_12 = 0;
        data1.tapal_Total_12 = 0;

        data1.tapal_New_1 = 0;
        data1.tapal_Renew_1 = 0;
        data1.tapal_Total_1 = 0;

        data1.tapal_New_2 = 0;
        data1.tapal_Renew_2 = 0;
        data1.tapal_Total_2 = 0;

        data1.tapal_New_3 = 0;
        data1.tapal_Renew_3 = 0;
        data1.tapal_Total_3 = 0;

        data1.haste_New_4 = 0;
        data1.haste_Renew_4 = 0;
        data1.haste_Total_4 = 0;

        data1.haste_New_5 = 0;
        data1.haste_Renew_5 = 0;
        data1.haste_Total_5 = 0;

        data1.haste_New_6 = 0;
        data1.haste_Renew_6 = 0;
        data1.haste_Total_6 = 0;

        data1.haste_New_7 = 0;
        data1.haste_Renew_7 = 0;
        data1.haste_Total_7 = 0;

        data1.haste_New_8 = 0;
        data1.haste_Renew_8 = 0;
        data1.haste_Total_8 = 0;

        data1.haste_New_9 = 0;
        data1.haste_Renew_9 = 0;
        data1.haste_Total_9 = 0;

        data1.haste_New_10 = 0;
        data1.haste_Renew_10 = 0;
        data1.haste_Total_10 = 0;

        data1.haste_New_11 = 0;
        data1.haste_Renew_11 = 0;
        data1.haste_Total_11 = 0;

        data1.haste_New_12 = 0;
        data1.haste_Renew_12 = 0;
        data1.haste_Total_12 = 0;

        data1.haste_Total_1 = 0;
        data1.haste_New_1 = 0;
        data1.haste_Renew_1 = 0;

        data1.haste_Total_2 = 0;
        data1.haste_New_2 = 0;
        data1.haste_Renew_2 = 0;

        data1.haste_Total_3 = 0;
        data1.haste_New_3 = 0;
        data1.haste_Renew_3 = 0;

        data1.magzine_New_4 = 0;
        data1.magzine_Renew_4 = 0;
        data1.magzine_Total_4 = 0;

        data1.magzine_New_5 = 0;
        data1.magzine_Renew_5 = 0;
        data1.magzine_Total_5 = 0;

        data1.magzine_New_6 = 0;
        data1.magzine_Renew_6 = 0;
        data1.magzine_Total_6 = 0;

        data1.magzine_New_7 = 0;
        data1.magzine_Renew_7 = 0;
        data1.magzine_Total_7 = 0;

        data1.magzine_New_8 = 0;
        data1.magzine_Renew_8 = 0;
        data1.magzine_Total_8 = 0;

        data1.magzine_New_9 = 0;
        data1.magzine_Renew_9 = 0;
        data1.magzine_Total_9 = 0;

        data1.magzine_New_10 = 0;
        data1.magzine_Renew_10 = 0;
        data1.magzine_Total_10 = 0;

        data1.magzine_New_11 = 0;
        data1.magzine_Renew_11 = 0;
        data1.magzine_Total_11 = 0;

        data1.magzine_New_12 = 0;
        data1.magzine_Renew_12 = 0;
        data1.magzine_Total_12 = 0;

        data1.magzine_New_1 = 0;
        data1.magzine_Renew_1 = 0;
        data1.magzine_Total_1 = 0;

        data1.magzine_New_2 = 0;
        data1.magzine_Renew_2 = 0;
        data1.magzine_Total_2 = 0;

        data1.magzine_New_3 = 0;
        data1.magzine_Renew_3 = 0;
        data1.magzine_Total_3 = 0;

        data1.digital_New_4 = 0;
        data1.digital_Renew_4 = 0;
        data1.digital_Total_4 = 0;

        data1.digital_New_5 = 0;
        data1.digital_Renew_5 = 0;
        data1.digital_Total_5 = 0;

        data1.digital_New_6 = 0;
        data1.digital_Renew_6 = 0;
        data1.digital_Total_6 = 0;

        data1.digital_New_7 = 0;
        data1.digital_Renew_7 = 0;
        data1.digital_Total_7 = 0;

        data1.digital_New_8 = 0;
        data1.digital_Renew_8 = 0;
        data1.digital_Total_8 = 0;

        data1.digital_New_9 = 0;
        data1.digital_Renew_9 = 0;
        data1.digital_Total_9 = 0;

        data1.digital_New_10 = 0;
        data1.digital_Renew_10 = 0;
        data1.digital_Total_10 = 0;

        data1.digital_New_11 = 0;
        data1.digital_Renew_11 = 0;
        data1.digital_Total_11 = 0;

        data1.digital_New_12 = 0;
        data1.digital_Renew_12 = 0;
        data1.digital_Total_12 = 0;

        data1.digital_New_1 = 0;
        data1.digital_Renew_1 = 0;
        data1.digital_Total_1 = 0;

        data1.digital_New_2 = 0;
        data1.digital_Renew_2 = 0;
        data1.digital_Total_2 = 0;

        data1.digital_New_3 = 0;
        data1.digital_Renew_3 = 0;
        data1.digital_Total_3 = 0;

        data1.tapal_New_total = 0;
        data1.tapal_Renew_total = 0;
        data1.tapal_Total_total = 0;

        data1.haste_New_total = 0;
        data1.haste_Renew_total = 0;
        data1.haste_Total_total = 0;

        data1.magzine_New_total = 0;
        data1.magzine_Renew_total = 0;
        data1.magzine_Total_total = 0;

        data1.digital_New_total = 0;
        data1.digital_Renew_total = 0;
        data1.digital_Total_total = 0;

        



      for(let data of data1.rep_info){

        this.tapal_New_4 += parseInt(data.tapal_New_4);
        this.tapal_Renew_4 += parseInt(data.tapal_Renew_4);
        this.tapal_Total_4 += parseInt(data.tapal_Total_4);

        this.tapal_New_5 += parseInt(data.tapal_New_5);
        this.tapal_Renew_5 += parseInt(data.tapal_Renew_5);
        this.tapal_Total_5 += parseInt(data.tapal_Total_5);

        this.tapal_New_6 += parseInt(data.tapal_New_6);
        this.tapal_Renew_6 += parseInt(data.tapal_Renew_6);
        this.tapal_Total_6 += parseInt(data.tapal_Total_6);

        this.tapal_New_7 += parseInt(data.tapal_New_7);
        this.tapal_Renew_7 += parseInt(data.tapal_Renew_7);
        this.tapal_Total_7 += parseInt(data.tapal_Total_7);

        this.tapal_New_8 += parseInt(data.tapal_New_8);
        this.tapal_Renew_8 += parseInt(data.tapal_Renew_8);
        this.tapal_Total_8 += parseInt(data.tapal_Total_8);

        this.tapal_New_9 += parseInt(data.tapal_New_9);
        this.tapal_Renew_9 += parseInt(data.tapal_Renew_9);
        this.tapal_Total_9 += parseInt(data.tapal_Total_9);

        this.tapal_New_10 += parseInt(data.tapal_New_10);
        this.tapal_Renew_10 += parseInt(data.tapal_Renew_10);
        this.tapal_Total_10 += parseInt(data.tapal_Total_10);

        this.tapal_New_11 += parseInt(data.tapal_New_11);
        this.tapal_Renew_11 += parseInt(data.tapal_Renew_11);
        this.tapal_Total_11 += parseInt(data.tapal_Total_11);

        this.tapal_New_12 += parseInt(data.tapal_New_12);
        this.tapal_Renew_12 += parseInt(data.tapal_Renew_12);
        this.tapal_Total_12 += parseInt(data.tapal_Total_12);

        this.tapal_New_1 += parseInt(data.tapal_New_1);
        this.tapal_Renew_1 += parseInt(data.tapal_Renew_1);
        this.tapal_Total_1 += parseInt(data.tapal_Total_1);

        this.tapal_New_2 += parseInt(data.tapal_New_2);
        this.tapal_Renew_2 += parseInt(data.tapal_Renew_2);
        this.tapal_Total_2 += parseInt(data.tapal_Total_2);

        this.tapal_New_3 += parseInt(data.tapal_New_3);
        this.tapal_Renew_3 += parseInt(data.tapal_Renew_3);
        this.tapal_Total_3 += parseInt(data.tapal_Total_3);
        
        this.haste_New_4 += parseInt(data.haste_New_4);
        this.haste_Renew_4 += parseInt(data.haste_Renew_4);
        this.haste_Total_4 += parseInt(data.haste_Total_4);

        this.haste_New_5 += parseInt(data.haste_New_5);
        this.haste_Renew_5 += parseInt(data.haste_Renew_5);
        this.haste_Total_5 += parseInt(data.haste_Total_5);

        this.haste_New_6 += parseInt(data.haste_New_6);
        this.haste_Renew_6 += parseInt(data.haste_Renew_6);
        this.haste_Total_6 += parseInt(data.haste_Total_6);

        this.haste_New_7 += parseInt(data.haste_New_7);
        this.haste_Renew_7 += parseInt(data.haste_Renew_7);
        this.haste_Total_7 += parseInt(data.haste_Total_7);

        this.haste_New_8 += parseInt(data.haste_New_8);
        this.haste_Renew_8 += parseInt(data.haste_Renew_8);
        this.haste_Total_8 += parseInt(data.haste_Total_8);

        this.haste_New_9 += parseInt(data.haste_New_9);
        this.haste_Renew_9 += parseInt(data.haste_Renew_9);
        this.haste_Total_9 += parseInt(data.haste_Total_9);

        this.haste_New_10 += parseInt(data.haste_New_10);
        this.haste_Renew_10 += parseInt(data.haste_Renew_10);
        this.haste_Total_10 += parseInt(data.haste_Total_10);

        this.haste_New_11 += parseInt(data.haste_New_11);
        this.haste_Renew_11 += parseInt(data.haste_Renew_11);
        this.haste_Total_11 += parseInt(data.haste_Total_11);

        this.haste_New_12 += parseInt(data.haste_New_12);
        this.haste_Renew_12 += parseInt(data.haste_Renew_12);
        this.haste_Total_12 += parseInt(data.haste_Total_12);

        this.haste_New_1 += parseInt(data.haste_New_1);
        this.haste_Renew_1 += parseInt(data.haste_Renew_1);
        this.haste_Total_1 += parseInt(data.haste_Total_1);

        this.haste_New_2 += parseInt(data.haste_New_2);
        this.haste_Renew_2 += parseInt(data.haste_Renew_2);
        this.haste_Total_2 += parseInt(data.haste_Total_2);

        this.haste_New_3 += parseInt(data.haste_New_3);
        this.haste_Renew_3 += parseInt(data.haste_Renew_3);
        this.haste_Total_3 += parseInt(data.haste_Total_3);

       
        this.magzine_New_4 += parseInt(data.magzine_New_4);
        this.magzine_Renew_4 += parseInt(data.magzine_Renew_4);
        this.magzine_Total_4 += parseInt(data.magzine_Total_4);

        this.magzine_New_5 += parseInt(data.magzine_New_5);
        this.magzine_Renew_5 += parseInt(data.magzine_Renew_5);
        this.magzine_Total_5 += parseInt(data.magzine_Total_5);

        this.magzine_New_6 += parseInt(data.magzine_New_6);
        this.magzine_Renew_6 += parseInt(data.magzine_Renew_6);
        this.magzine_Total_6 += parseInt(data.magzine_Total_6);

        this.magzine_New_7 += parseInt(data.magzine_New_7);
        this.magzine_Renew_7 += parseInt(data.magzine_Renew_7);
        this.magzine_Total_7 += parseInt(data.magzine_Total_7);

        this.magzine_New_8 += parseInt(data.magzine_New_8);
        this.magzine_Renew_8 += parseInt(data.magzine_Renew_8);
        this.magzine_Total_8 += parseInt(data.magzine_Total_8);

        this.magzine_New_9 += parseInt(data.magzine_New_9);
        this.magzine_Renew_9 += parseInt(data.magzine_Renew_9);
        this.magzine_Total_9 += parseInt(data.magzine_Total_9);

        this.magzine_New_10 += parseInt(data.magzine_New_10);
        this.magzine_Renew_10 += parseInt(data.magzine_Renew_10);
        this.magzine_Total_10 += parseInt(data.magzine_Total_10);

        this.magzine_New_11 += parseInt(data.magzine_New_11);
        this.magzine_Renew_11 += parseInt(data.magzine_Renew_11);
        this.magzine_Total_11 += parseInt(data.magzine_Total_11);

        this.magzine_New_12 += parseInt(data.magzine_New_12);
        this.magzine_Renew_12 += parseInt(data.magzine_Renew_12);
        this.magzine_Total_12 += parseInt(data.magzine_Total_12);

        this.magzine_New_1 += parseInt(data.magzine_New_1);
        this.magzine_Renew_1 += parseInt(data.magzine_Renew_1);
        this.magzine_Total_1 += parseInt(data.magzine_Total_1);

        this.magzine_New_2 += parseInt(data.magzine_New_2);
        this.magzine_Renew_2 += parseInt(data.magzine_Renew_2);
        this.magzine_Total_2 += parseInt(data.magzine_Total_2);

        this.magzine_New_3 += parseInt(data.magzine_New_3);
        this.magzine_Renew_3 += parseInt(data.magzine_Renew_3);
        this.magzine_Total_3 += parseInt(data.magzine_Total_3);
        
        this.digital_New_4 += parseInt(data.digital_New_4);
        this.digital_Renew_4 += parseInt(data.digital_Renew_4);
        this.digital_Total_4 += parseInt(data.digital_Total_4);

        this.digital_New_5 += parseInt(data.digital_New_5);
        this.digital_Renew_5 += parseInt(data.digital_Renew_5);
        this.digital_Total_5 += parseInt(data.digital_Total_5);

        this.digital_New_6 += parseInt(data.digital_New_6);
        this.digital_Renew_6 += parseInt(data.digital_Renew_6);
        this.digital_Total_6 += parseInt(data.digital_Total_6);

        this.digital_New_7 += parseInt(data.digital_New_7);
        this.digital_Renew_7 += parseInt(data.digital_Renew_7);
        this.digital_Total_7 += parseInt(data.digital_Total_7);

        this.digital_New_8 += parseInt(data.digital_New_8);
        this.digital_Renew_8 += parseInt(data.digital_Renew_8);
        this.digital_Total_8 += parseInt(data.digital_Total_8);

        this.digital_New_9 += parseInt(data.digital_New_9);
        this.digital_Renew_9 += parseInt(data.digital_Renew_9);
        this.digital_Total_9 += parseInt(data.digital_Total_9);

        this.digital_New_10 += parseInt(data.digital_New_10);
        this.digital_Renew_10 += parseInt(data.digital_Renew_10);
        this.digital_Total_10 += parseInt(data.digital_Total_10);

        this.digital_New_11 += parseInt(data.digital_New_11);
        this.digital_Renew_11 += parseInt(data.digital_Renew_11);
        this.digital_Total_11 += parseInt(data.digital_Total_11);

        this.digital_New_12 += parseInt(data.digital_New_12);
        this.digital_Renew_12 += parseInt(data.digital_Renew_12);
        this.digital_Total_12 += parseInt(data.digital_Total_12);

        this.digital_New_1 += parseInt(data.digital_New_1);
        this.digital_Renew_1 += parseInt(data.digital_Renew_1);
        this.digital_Total_1 += parseInt(data.digital_Total_1);

        this.digital_New_2 += parseInt(data.digital_New_2);
        this.digital_Renew_2 += parseInt(data.digital_Renew_2);
        this.digital_Total_2 += parseInt(data.digital_Total_2);

        this.digital_New_3 += parseInt(data.digital_New_3);
        this.digital_Renew_3 += parseInt(data.digital_Renew_3);
        this.digital_Total_3 += parseInt(data.digital_Total_3);

                
        this.tapal_New_total+= parseInt(data.tapal_New_Total);
        this.tapal_Renew_total += parseInt(data.tapal_Renew_Total);
        this.tapal_Total_total+= parseInt(data.tapal_Total_Total);
        this.haste_New_total += parseInt(data.haste_New_Total);
        this.haste_Renew_total += parseInt(data.haste_Renew_Total);
        this.haste_Total_total += parseInt(data.haste_Total_Total);
        this.magzine_New_total += parseInt(data.magzine_New_Total);
        this.magzine_Renew_total += parseInt(data.magzine_Renew_Total);
        this.magzine_Total_total += parseInt(data.magzine_Total_Total);
        this.digital_New_total+= parseInt(data.digital_New_Total);
        this.digital_Renew_total += parseInt(data.digital_Renew_Total);
        this.digital_Total_total += parseInt(data.digital_Total_Total);

        

        data2.tapal_New_4 += parseInt(data.tapal_New_4);
        data2.tapal_Renew_4 += parseInt(data.tapal_Renew_4);
        data2.tapal_Total_4 += parseInt(data.tapal_Total_4);

        data2.tapal_New_5 += parseInt(data.tapal_New_5);
        data2.tapal_Renew_5 += parseInt(data.tapal_Renew_5);
        data2.tapal_Total_5 += parseInt(data.tapal_Total_5);

        data2.tapal_New_6 += parseInt(data.tapal_New_6);
        data2.tapal_Renew_6 += parseInt(data.tapal_Renew_6);
        data2.tapal_Total_6 += parseInt(data.tapal_Total_6);

        data2.tapal_New_7 += parseInt(data.tapal_New_7);
        data2.tapal_Renew_7 += parseInt(data.tapal_Renew_7);
        data2.tapal_Total_7 += parseInt(data.tapal_Total_7);

        data2.tapal_New_8 += parseInt(data.tapal_New_8);
        data2.tapal_Renew_8 += parseInt(data.tapal_Renew_8);
        data2.tapal_Total_8 += parseInt(data.tapal_Total_8);

        data2.tapal_New_9 += parseInt(data.tapal_New_9);
        data2.tapal_Renew_9 += parseInt(data.tapal_Renew_9);
        data2.tapal_Total_9 += parseInt(data.tapal_Total_9);

        data2.tapal_New_10 += parseInt(data.tapal_New_10);
        data2.tapal_Renew_10 += parseInt(data.tapal_Renew_10);
        data2.tapal_Total_10 += parseInt(data.tapal_Total_10);

        data2.tapal_New_11 += parseInt(data.tapal_New_11);
        data2.tapal_Renew_11 += parseInt(data.tapal_Renew_11);
        data2.tapal_Total_11 += parseInt(data.tapal_Total_11);

        data2.tapal_New_12 += parseInt(data.tapal_New_12);
        data2.tapal_Renew_12 += parseInt(data.tapal_Renew_12);
        data2.tapal_Total_12 += parseInt(data.tapal_Total_12);

        data2.tapal_New_1 += parseInt(data.tapal_New_1);
        data2.tapal_Renew_1 += parseInt(data.tapal_Renew_1);
        data2.tapal_Total_1 += parseInt(data.tapal_Total_1);

        data2.tapal_New_2 += parseInt(data.tapal_New_2);
        data2.tapal_Renew_2 += parseInt(data.tapal_Renew_2);
        data2.tapal_Total_2 += parseInt(data.tapal_Total_2);

        data2.tapal_New_3 += parseInt(data.tapal_New_3);
        data2.tapal_Renew_3 += parseInt(data.tapal_Renew_3);
        data2.tapal_Total_3 += parseInt(data.tapal_Total_3);

        data2.haste_New_4 += parseInt(data.haste_New_4);
        data2.haste_Renew_4 += parseInt(data.haste_Renew_4);
        data2.haste_Total_4 += parseInt(data.haste_Total_4);

        data2.haste_New_5 += parseInt(data.haste_New_5);
        data2.haste_Renew_5 += parseInt(data.haste_Renew_5);
        data2.haste_Total_5 += parseInt(data.haste_Total_5);

        data2.haste_New_6 += parseInt(data.haste_New_6);
        data2.haste_Renew_6 += parseInt(data.haste_Renew_6);
        data2.haste_Total_6 += parseInt(data.haste_Total_6);

        data2.haste_New_7 += parseInt(data.haste_New_7);
        data2.haste_Renew_7 += parseInt(data.haste_Renew_7);
        data2.haste_Total_7 += parseInt(data.haste_Total_7);

        data2.haste_New_8 += parseInt(data.haste_New_8);
        data2.haste_Renew_8 += parseInt(data.haste_Renew_8);
        data2.haste_Total_8 += parseInt(data.haste_Total_8);

        data2.haste_New_9 += parseInt(data.haste_New_9);
        data2.haste_Renew_9 += parseInt(data.haste_Renew_9);
        data2.haste_Total_9 += parseInt(data.haste_Total_9);

        data2.haste_New_10 += parseInt(data.haste_New_10);
        data2.haste_Renew_10 += parseInt(data.haste_Renew_10);
        data2.haste_Total_10 += parseInt(data.haste_Total_10);

        data2.haste_New_11 += parseInt(data.haste_New_11);
        data2.haste_Renew_11 += parseInt(data.haste_Renew_11);
        data2.haste_Total_11 += parseInt(data.haste_Total_11);

        data2.haste_New_12 += parseInt(data.haste_New_12);
        data2.haste_Renew_12 += parseInt(data.haste_Renew_12);
        data2.haste_Total_12 += parseInt(data.haste_Total_12);

        data2.magzine_New_4 += parseInt(data.magzine_New_4);
        data2.magzine_Renew_4 += parseInt(data.magzine_Renew_4);
        data2.magzine_Total_4 += parseInt(data.magzine_Total_4);

        data2.magzine_New_5 += parseInt(data.magzine_New_5);
        data2.magzine_Renew_5 += parseInt(data.magzine_Renew_5);
        data2.magzine_Total_5 += parseInt(data.magzine_Total_5);

        data2.magzine_New_6 += parseInt(data.magzine_New_6);
        data2.magzine_Renew_6 += parseInt(data.magzine_Renew_6);
        data2.magzine_Total_6 += parseInt(data.magzine_Total_6);

        data2.magzine_New_7 += parseInt(data.magzine_New_7);
        data2.magzine_Renew_7 += parseInt(data.magzine_Renew_7);
        data2.magzine_Total_7 += parseInt(data.magzine_Total_7);

        data2.magzine_New_8 += parseInt(data.magzine_New_8);
        data2.magzine_Renew_8 += parseInt(data.magzine_Renew_8);
        data2.magzine_Total_8 += parseInt(data.magzine_Total_8);

        data2.magzine_New_9 += parseInt(data.magzine_New_9);
        data2.magzine_Renew_9 += parseInt(data.magzine_Renew_9);
        data2.magzine_Total_9 += parseInt(data.magzine_Total_9);

        data2.magzine_New_10 += parseInt(data.magzine_New_10);
        data2.magzine_Renew_10 += parseInt(data.magzine_Renew_10);
        data2.magzine_Total_10 += parseInt(data.magzine_Total_10);

        data2.magzine_New_11 += parseInt(data.magzine_New_11);
        data2.magzine_Renew_11 += parseInt(data.magzine_Renew_11);
        data2.magzine_Total_11 += parseInt(data.magzine_Total_11);

        data2.magzine_New_12 += parseInt(data.magzine_New_12);
        data2.magzine_Renew_12 += parseInt(data.magzine_Renew_12);
        data2.magzine_Total_12 += parseInt(data.magzine_Total_12);

        data2.tapal_New_total += parseInt(data.tapal_New_Total);
        data2.tapal_Renew_total += parseInt(data.tapal_Renew_Total);
        data2.tapal_Total_total += parseInt(data.tapal_Total_Total);
        data2.haste_New_total += parseInt(data.haste_New_Total);
        data2.haste_Renew_total += parseInt(data.haste_Renew_Total);
        data2.haste_Total_total += parseInt(data.haste_Total_Total);
        data2.magzine_New_total += parseInt(data.magzine_New_Total);
        data2.magzine_Renew_total += parseInt(data.magzine_Renew_Total);
        data2.magzine_Total_total += parseInt(data.magzine_Total_Total);
        data2.digital_New_total += parseInt(data.digital_New_Total);
        data2.digital_Renew_total += parseInt(data.digital_Renew_Total);
        data2.digital_Total_total += parseInt(data.digital_Total_Total);


        data1.tapal_New_4 += parseInt(data.tapal_New_4);
        data1.tapal_Renew_4 += parseInt(data.tapal_Renew_4);
        data1.tapal_Total_4 += parseInt(data.tapal_Total_4);

        data1.tapal_New_5 += parseInt(data.tapal_New_5);
        data1.tapal_Renew_5 += parseInt(data.tapal_Renew_5);
        data1.tapal_Total_5 += parseInt(data.tapal_Total_5);

        data1.tapal_New_6 += parseInt(data.tapal_New_6);
        data1.tapal_Renew_6 += parseInt(data.tapal_Renew_6);
        data1.tapal_Total_6 += parseInt(data.tapal_Total_6);

        data1.tapal_New_7 += parseInt(data.tapal_New_7);
        data1.tapal_Renew_7 += parseInt(data.tapal_Renew_7);
        data1.tapal_Total_7 += parseInt(data.tapal_Total_7);

        data1.tapal_New_8 += parseInt(data.tapal_New_8);
        data1.tapal_Renew_8 += parseInt(data.tapal_Renew_8);
        data1.tapal_Total_8 += parseInt(data.tapal_Total_8);

        data1.tapal_New_9 += parseInt(data.tapal_New_9);
        data1.tapal_Renew_9 += parseInt(data.tapal_Renew_9);
        data1.tapal_Total_9 += parseInt(data.tapal_Total_9);

        data1.tapal_New_10 += parseInt(data.tapal_New_10);
        data1.tapal_Renew_10 += parseInt(data.tapal_Renew_10);
        data1.tapal_Total_10 += parseInt(data.tapal_Total_10);

        data1.tapal_New_11 += parseInt(data.tapal_New_11);
        data1.tapal_Renew_11 += parseInt(data.tapal_Renew_11);
        data1.tapal_Total_11 += parseInt(data.tapal_Total_11);

        data1.tapal_New_12 += parseInt(data.tapal_New_12);
        data1.tapal_Renew_12 += parseInt(data.tapal_Renew_12);
        data1.tapal_Total_12 += parseInt(data.tapal_Total_12);

        data1.tapal_New_1 += parseInt(data.tapal_New_1);
        data1.tapal_Renew_1 += parseInt(data.tapal_Renew_1);
        data1.tapal_Total_1 += parseInt(data.tapal_Total_1);

        data1.tapal_New_2 += parseInt(data.tapal_New_2);
        data1.tapal_Renew_2 += parseInt(data.tapal_Renew_2);
        data1.tapal_Total_2 += parseInt(data.tapal_Total_2);

        data1.tapal_New_3 += parseInt(data.tapal_New_3);
        data1.tapal_Renew_3 += parseInt(data.tapal_Renew_3);
        data1.tapal_Total_3 += parseInt(data.tapal_Total_3);

        data1.haste_New_4 += parseInt(data.haste_New_4);
        data1.haste_Renew_4 += parseInt(data.haste_Renew_4);
        data1.haste_Total_4 += parseInt(data.haste_Total_4);

        data1.haste_New_5 += parseInt(data.haste_New_5);
        data1.haste_Renew_5 += parseInt(data.haste_Renew_5);
        data1.haste_Total_5 += parseInt(data.haste_Total_5);

        data1.haste_New_6 += parseInt(data.haste_New_6);
        data1.haste_Renew_6 += parseInt(data.haste_Renew_6);
        data1.haste_Total_6 += parseInt(data.haste_Total_6);

        data1.haste_New_7 += parseInt(data.haste_New_7);
        data1.haste_Renew_7 += parseInt(data.haste_Renew_7);
        data1.haste_Total_7 += parseInt(data.haste_Total_7);

        data1.haste_New_8 += parseInt(data.haste_New_8);
        data1.haste_Renew_8 += parseInt(data.haste_Renew_8);
        data1.haste_Total_8 += parseInt(data.haste_Total_8);

        data1.haste_New_9 += parseInt(data.haste_New_9);
        data1.haste_Renew_9 += parseInt(data.haste_Renew_9);
        data1.haste_Total_9 += parseInt(data.haste_Total_9);

        data1.haste_New_10 += parseInt(data.haste_New_10);
        data1.haste_Renew_10 += parseInt(data.haste_Renew_10);
        data1.haste_Total_10 += parseInt(data.haste_Total_10);

        data1.haste_New_11 += parseInt(data.haste_New_11);
        data1.haste_Renew_11 += parseInt(data.haste_Renew_11);
        data1.haste_Total_11 += parseInt(data.haste_Total_11);

        data1.haste_New_12 += parseInt(data.haste_New_12);
        data1.haste_Renew_12 += parseInt(data.haste_Renew_12);
        data1.haste_Total_12 += parseInt(data.haste_Total_12);

        data1.magzine_New_4 += parseInt(data.magzine_New_4);
        data1.magzine_Renew_4 += parseInt(data.magzine_Renew_4);
        data1.magzine_Total_4 += parseInt(data.magzine_Total_4);

        data1.magzine_New_5 += parseInt(data.magzine_New_5);
        data1.magzine_Renew_5 += parseInt(data.magzine_Renew_5);
        data1.magzine_Total_5 += parseInt(data.magzine_Total_5);

        data1.magzine_New_6 += parseInt(data.magzine_New_6);
        data1.magzine_Renew_6 += parseInt(data.magzine_Renew_6);
        data1.magzine_Total_6 += parseInt(data.magzine_Total_6);

        data1.magzine_New_7 += parseInt(data.magzine_New_7);
        data1.magzine_Renew_7 += parseInt(data.magzine_Renew_7);
        data1.magzine_Total_7 += parseInt(data.magzine_Total_7);

        data1.magzine_New_8 += parseInt(data.magzine_New_8);
        data1.magzine_Renew_8 += parseInt(data.magzine_Renew_8);
        data1.magzine_Total_8 += parseInt(data.magzine_Total_8);

        data1.magzine_New_9 += parseInt(data.magzine_New_9);
        data1.magzine_Renew_9 += parseInt(data.magzine_Renew_9);
        data1.magzine_Total_9 += parseInt(data.magzine_Total_9);

        data1.magzine_New_10 += parseInt(data.magzine_New_10);
        data1.magzine_Renew_10 += parseInt(data.magzine_Renew_10);
        data1.magzine_Total_10 += parseInt(data.magzine_Total_10);

        data1.magzine_New_11 += parseInt(data.magzine_New_11);
        data1.magzine_Renew_11 += parseInt(data.magzine_Renew_11);
        data1.magzine_Total_11 += parseInt(data.magzine_Total_11);

        data1.magzine_New_12 += parseInt(data.magzine_New_12);
        data1.magzine_Renew_12 += parseInt(data.magzine_Renew_12);
        data1.magzine_Total_12 += parseInt(data.magzine_Total_12);

        data1.tapal_New_total += parseInt(data.tapal_New_Total);
        data1.tapal_Renew_total += parseInt(data.tapal_Renew_Total);
        data1.tapal_Total_total += parseInt(data.tapal_Total_Total);
        data1.haste_New_total += parseInt(data.haste_New_Total);
        data1.haste_Renew_total += parseInt(data.haste_Renew_Total);
        data1.haste_Total_total += parseInt(data.haste_Total_Total);
        data1.magzine_New_total += parseInt(data.magzine_New_Total);
        data1.magzine_Renew_total += parseInt(data.magzine_Renew_Total);
        data1.magzine_Total_total += parseInt(data.magzine_Total_Total);
        data1.digital_New_total += parseInt(data.digital_New_Total);
        data1.digital_Renew_total += parseInt(data.digital_Renew_Total);
        data1.digital_Total_total += parseInt(data.digital_Total_Total);


                data11.tapal_New_4 += parseInt(data.tapal_New_4);
        data11.tapal_Renew_4 += parseInt(data.tapal_Renew_4);
        data11.tapal_Total_4 += parseInt(data.tapal_Total_4);

        data11.tapal_New_5 += parseInt(data.tapal_New_5);
        data11.tapal_Renew_5 += parseInt(data.tapal_Renew_5);
        data11.tapal_Total_5 += parseInt(data.tapal_Total_5);

        data11.tapal_New_6 += parseInt(data.tapal_New_6);
        data11.tapal_Renew_6 += parseInt(data.tapal_Renew_6);
        data11.tapal_Total_6 += parseInt(data.tapal_Total_6);

        data11.tapal_New_7 += parseInt(data.tapal_New_7);
        data11.tapal_Renew_7 += parseInt(data.tapal_Renew_7);
        data11.tapal_Total_7 += parseInt(data.tapal_Total_7);

        data11.tapal_New_8 += parseInt(data.tapal_New_8);
        data11.tapal_Renew_8 += parseInt(data.tapal_Renew_8);
        data11.tapal_Total_8 += parseInt(data.tapal_Total_8);

        data11.tapal_New_9 += parseInt(data.tapal_New_9);
        data11.tapal_Renew_9 += parseInt(data.tapal_Renew_9);
        data11.tapal_Total_9 += parseInt(data.tapal_Total_9);

        data11.tapal_New_10 += parseInt(data.tapal_New_10);
        data11.tapal_Renew_10 += parseInt(data.tapal_Renew_10);
        data11.tapal_Total_10 += parseInt(data.tapal_Total_10);

        data11.tapal_New_11 += parseInt(data.tapal_New_11);
        data11.tapal_Renew_11 += parseInt(data.tapal_Renew_11);
        data11.tapal_Total_11 += parseInt(data.tapal_Total_11);

        data11.tapal_New_12 += parseInt(data.tapal_New_12);
        data11.tapal_Renew_12 += parseInt(data.tapal_Renew_12);
        data11.tapal_Total_12 += parseInt(data.tapal_Total_12);

        data11.tapal_New_1 += parseInt(data.tapal_New_1);
        data11.tapal_Renew_1 += parseInt(data.tapal_Renew_1);
        data11.tapal_Total_1 += parseInt(data.tapal_Total_1);

        data11.tapal_New_2 += parseInt(data.tapal_New_2);
        data11.tapal_Renew_2 += parseInt(data.tapal_Renew_2);
        data11.tapal_Total_2 += parseInt(data.tapal_Total_2);

        data11.tapal_New_3 += parseInt(data.tapal_New_3);
        data11.tapal_Renew_3 += parseInt(data.tapal_Renew_3);
        data11.tapal_Total_3 += parseInt(data.tapal_Total_3);

        data11.haste_New_4 += parseInt(data.haste_New_4);
        data11.haste_Renew_4 += parseInt(data.haste_Renew_4);
        data11.haste_Total_4 += parseInt(data.haste_Total_4);

        data11.haste_New_5 += parseInt(data.haste_New_5);
        data11.haste_Renew_5 += parseInt(data.haste_Renew_5);
        data11.haste_Total_5 += parseInt(data.haste_Total_5);

        data11.haste_New_6 += parseInt(data.haste_New_6);
        data11.haste_Renew_6 += parseInt(data.haste_Renew_6);
        data11.haste_Total_6 += parseInt(data.haste_Total_6);

        data11.haste_New_7 += parseInt(data.haste_New_7);
        data11.haste_Renew_7 += parseInt(data.haste_Renew_7);
        data11.haste_Total_7 += parseInt(data.haste_Total_7);

        data11.haste_New_8 += parseInt(data.haste_New_8);
        data11.haste_Renew_8 += parseInt(data.haste_Renew_8);
        data11.haste_Total_8 += parseInt(data.haste_Total_8);

        data11.haste_New_9 += parseInt(data.haste_New_9);
        data11.haste_Renew_9 += parseInt(data.haste_Renew_9);
        data11.haste_Total_9 += parseInt(data.haste_Total_9);

        data11.haste_New_10 += parseInt(data.haste_New_10);
        data11.haste_Renew_10 += parseInt(data.haste_Renew_10);
        data11.haste_Total_10 += parseInt(data.haste_Total_10);

        data11.haste_New_11 += parseInt(data.haste_New_11);
        data11.haste_Renew_11 += parseInt(data.haste_Renew_11);
        data11.haste_Total_11 += parseInt(data.haste_Total_11);

        data11.haste_New_12 += parseInt(data.haste_New_12);
        data11.haste_Renew_12 += parseInt(data.haste_Renew_12);
        data11.haste_Total_12 += parseInt(data.haste_Total_12);

        data11.magzine_New_4 += parseInt(data.magzine_New_4);
        data11.magzine_Renew_4 += parseInt(data.magzine_Renew_4);
        data11.magzine_Total_4 += parseInt(data.magzine_Total_4);

        data11.magzine_New_5 += parseInt(data.magzine_New_5);
        data11.magzine_Renew_5 += parseInt(data.magzine_Renew_5);
        data11.magzine_Total_5 += parseInt(data.magzine_Total_5);

        data11.magzine_New_6 += parseInt(data.magzine_New_6);
        data11.magzine_Renew_6 += parseInt(data.magzine_Renew_6);
        data11.magzine_Total_6 += parseInt(data.magzine_Total_6);

        data11.magzine_New_7 += parseInt(data.magzine_New_7);
        data11.magzine_Renew_7 += parseInt(data.magzine_Renew_7);
        data11.magzine_Total_7 += parseInt(data.magzine_Total_7);

        data11.magzine_New_8 += parseInt(data.magzine_New_8);
        data11.magzine_Renew_8 += parseInt(data.magzine_Renew_8);
        data11.magzine_Total_8 += parseInt(data.magzine_Total_8);

        data11.magzine_New_9 += parseInt(data.magzine_New_9);
        data11.magzine_Renew_9 += parseInt(data.magzine_Renew_9);
        data11.magzine_Total_9 += parseInt(data.magzine_Total_9);

        data11.magzine_New_10 += parseInt(data.magzine_New_10);
        data11.magzine_Renew_10 += parseInt(data.magzine_Renew_10);
        data11.magzine_Total_10 += parseInt(data.magzine_Total_10);

        data11.magzine_New_11 += parseInt(data.magzine_New_11);
        data11.magzine_Renew_11 += parseInt(data.magzine_Renew_11);
        data11.magzine_Total_11 += parseInt(data.magzine_Total_11);

        data11.magzine_New_12 += parseInt(data.magzine_New_12);
        data11.magzine_Renew_12 += parseInt(data.magzine_Renew_12);
        data11.magzine_Total_12 += parseInt(data.magzine_Total_12);

        data11.tapal_New_total += parseInt(data.tapal_New_Total);
        data11.tapal_Renew_total += parseInt(data.tapal_Renew_Total);
        data11.tapal_Total_total += parseInt(data.tapal_Total_Total);
        data11.haste_New_total += parseInt(data.haste_New_Total);
        data11.haste_Renew_total += parseInt(data.haste_Renew_Total);
        data11.haste_Total_total += parseInt(data.haste_Total_Total);
        data11.magzine_New_total += parseInt(data.magzine_New_Total);
        data11.magzine_Renew_total += parseInt(data.magzine_Renew_Total);
        data11.magzine_Total_total += parseInt(data.magzine_Total_Total);


      }

    }
    }
  }

    console.log("taluka renewal reming", data);

     });
}

if(this.reportType=="RemainingSubscriptionReprensantive"){
  this.service.getSubRemRep(req).subscribe((data) => {
    this.SubRemRep = data;
    // this.PaymentadvtOffInDTotal = 0;
    this.tapal_New_4 =0;
    this.tapal_Renew_4 =0;
    this.tapal_Total_4 =0;
    this.tapal_New_5 =0;
    this.tapal_Renew_5 =0;
    this.tapal_Total_5 =0;
    this.tapal_New_6 =0;
    this.tapal_Renew_6 =0;
    this.tapal_Total_6 =0;
    this.tapal_New_7 =0;
    this.tapal_Renew_7 =0;
    this.tapal_Total_7 =0;
    this.tapal_New_8 =0;
    this.tapal_Renew_8 =0;
    this.tapal_Total_8=0;
    this.tapal_New_9 =0;
    this.tapal_Renew_9 =0;
    this.tapal_Total_9 =0;
    this.tapal_New_10 =0;
    this.tapal_Renew_10 =0;
    this.tapal_Total_10 =0;
    this.tapal_New_11 =0;
    this.tapal_Renew_11 =0;
    this.tapal_Total_11 =0;
    this.tapal_New_12 =0;
    this.tapal_Renew_12 =0;
    this.tapal_Total_12 =0;
    this.tapal_New_1 =0;
    this.tapal_Renew_1 =0;
    this.tapal_Total_1 =0;
    this.tapal_New_2 =0;
    this.tapal_Renew_2 =0;
    this.tapal_Total_2 =0;
    this.tapal_New_3 =0;
    this.tapal_Renew_3 =0;
    this.tapal_Total_3 =0;

    this.haste_New_4  =0;
    this.haste_Renew_4 =0;
    this.haste_Total_4 =0;
    this.haste_New_5 =0;
    this.haste_Renew_5 =0;
    this.haste_Total_5 =0;
    this.haste_New_6  =0;
    this.haste_Renew_6 =0;
    this.haste_Total_6 =0;
    this.haste_New_7  =0;
    this.haste_Renew_7 =0;
    this.haste_Total_7 =0;
    this.haste_New_8  =0;
    this.haste_Renew_8 =0;
    this.haste_Total_8 =0;
    this.haste_New_9  =0;
    this.haste_Renew_9 =0;
    this.haste_Total_9 =0;
    this.haste_New_10  =0;
    this.haste_Renew_10 =0;
    this.haste_Total_10 =0;
    this.haste_New_11  =0;
    this.haste_Renew_11 =0;
    this.haste_Total_11=0;
    this.haste_New_12 =0;
    this.haste_Renew_12 =0;
    this.haste_Total_12 =0;
    this.haste_New_1 =0;
    this.haste_Renew_1 =0;
    this.haste_Total_1 =0;
    this.haste_New_2 =0;
    this.haste_Renew_2 =0;
    this.haste_Total_2 =0;
    this.haste_New_3 =0;
    this.haste_Renew_3 =0;
    this.haste_Total_3 =0;

      this.magzine_New_4 =0;
        this.magzine_Renew_4 =0;
        this.magzine_Total_4 =0;

        this.magzine_New_5 =0;
        this.magzine_Renew_5 =0;
        this.magzine_Total_5 =0;

        this.magzine_New_6 =0;
        this.magzine_Renew_6 =0;
        this.magzine_Total_6 =0;
        this.magzine_New_7 =0;
        this.magzine_Renew_7 =0;
        this.magzine_Total_7 =0;

        this.magzine_New_8 =0;
        this.magzine_Renew_8 =0;
        this.magzine_Total_8 =0;
        this.magzine_New_9=0;
        this.magzine_Renew_9 =0;
        this.magzine_Total_9 =0;

        this.magzine_New_10 =0;
        this.magzine_Renew_10 =0;
        this.magzine_Total_10 =0;

        this.magzine_New_11 =0;
        this.magzine_Renew_11 =0;
        this.magzine_Total_11 =0;
        this.magzine_New_12=0;
        this.magzine_Renew_12=0;
        this.magzine_Total_12 =0;

        this.magzine_New_1 =0;
        this.magzine_Renew_1 =0;
        this.magzine_Total_1 =0;

        this.magzine_New_2 =0;
        this.magzine_Renew_2 =0;
        this.magzine_Total_2 =0;

        this.magzine_New_3 =0;
        this.magzine_Renew_3=0;
        this.magzine_Total_3 =0;
        
        this.digital_New_4 =0;
        this.digital_Renew_4 =0;
        this.digital_Total_4 =0;

        this.digital_New_5 =0;
        this.digital_Renew_5 =0;
        this.digital_Total_5 =0;

        this.digital_New_6 =0;
        this.digital_Renew_6 =0;
        this.digital_Total_6 =0;

        this.digital_New_7 =0;
        this.digital_Renew_7 =0;
        this.digital_Total_7 =0;

        this.digital_New_8 =0;
        this.digital_Renew_8 =0;
        this.digital_Total_8 =0;

        this.digital_New_9 =0;
        this.digital_Renew_9 =0;
        this.digital_Total_9 =0;

        this.digital_New_10 =0;
        this.digital_Renew_10 =0;
        this.digital_Total_10 =0;

        this.digital_New_11 =0;
        this.digital_Renew_11 =0;
        this.digital_Total_11 =0;

        this.digital_New_12 =0;
        this.digital_Renew_12 =0;
        this.digital_Total_12 =0;

        this.digital_New_1 =0;
        this.digital_Renew_1 =0;
        this.digital_Total_1 =0;

        this.digital_New_2 =0;
        this.digital_Renew_2 =0;
        this.digital_Total_2=0;

        this.digital_New_3 =0;
        this.digital_Renew_3 =0;
        this.digital_Total_3 =0;

                
        this.tapal_New_total =0;
        this.tapal_Renew_total =0;
        this.tapal_Total_total =0;
        this.haste_New_total =0;
        this.haste_Renew_total =0;
        this.haste_Total_total=0;
        this.magzine_New_total =0;
        this.magzine_Renew_total =0;
        this.magzine_Total_total =0;
        this.digital_New_total =0;
        this.digital_Renew_total=0;
        this.digital_Total_total =0;

  for (let data3 of this.SubRemRep) {

    data3.tapal_New_4 = 0;
    data3.tapal_Renew_4 = 0;
    data3.tapal_Total_4 = 0;
    data3.tapal_New_5 = 0;
    data3.tapal_Renew_5 = 0;
    data3.tapal_Total_5 = 0;
    data3.tapal_New_6 = 0;
    data3.tapal_Renew_6 = 0;
    data3.tapal_Total_6 = 0;
    data3.tapal_New_7 = 0;
    data3.tapal_Renew_7 = 0;
    data3.tapal_Total_7 = 0;
    data3.tapal_New_8 = 0;
    data3.tapal_Renew_8 = 0;
    data3.tapal_Total_8 = 0;
    data3.tapal_New_9 = 0;
    data3.tapal_Renew_9 = 0;
    data3.tapal_Total_9 = 0;
    data3.tapal_New_10 = 0;
    data3.tapal_Renew_10 = 0;
    data3.tapal_Total_10 = 0;
    data3.tapal_New_11 = 0;
    data3.tapal_Renew_11 = 0;
    data3.tapal_Total_11 = 0;
    data3.tapal_New_12 = 0;
    data3.tapal_Renew_12 = 0;
    data3.tapal_Total_12 = 0;
    data3.tapal_New_1 = 0;
    data3.tapal_Renew_1 = 0;
    data3.tapal_Total_1 = 0;
    data3.tapal_New_2 = 0;
    data3.tapal_Renew_2 = 0;
    data3.tapal_Total_2 = 0;
    data3.tapal_New_3 = 0;
    data3.tapal_Renew_3 = 0;
    data3.tapal_Total_3 = 0;

    data3.haste_New_4 = 0;
    data3.haste_Renew_4 = 0;
    data3.haste_Total_4 = 0;
    data3.haste_New_5 = 0;
    data3.haste_Renew_5 = 0;
    data3.haste_Total_5 = 0;
    data3.haste_New_6 = 0;
    data3.haste_Renew_6 = 0;
    data3.haste_Total_6 = 0;
    data3.haste_New_7 = 0;
    data3.haste_Renew_7 = 0;
    data3.haste_Total_7 = 0;
    data3.haste_New_8 = 0;
    data3.haste_Renew_8 = 0;
    data3.haste_Total_8 = 0;
    data3.haste_New_9 = 0;
    data3.haste_Renew_9 = 0;
    data3.haste_Total_9 = 0;
    data3.haste_New_10 = 0;
    data3.haste_Renew_10 = 0;
    data3.haste_Total_10 = 0;
    data3.haste_New_11 = 0;
    data3.haste_Renew_11 = 0;
    data3.haste_Total_11 = 0;
    data3.haste_New_12 = 0;
    data3.haste_Renew_12 = 0;
    data3.haste_Total_12 = 0;
    data3.haste_New_1 = 0;
    data3.haste_Renew_1 = 0;
    data3.haste_Total_1 = 0;

    data3.haste_New_2 = 0;
    data3.haste_Renew_2 = 0;
    data3.haste_Total_2 = 0;

    data3.haste_New_3 = 0;
    data3.haste_Renew_3 = 0;
    data3.haste_Total_3 = 0;

    data3.magzine_New_4 = 0;
    data3.magzine_Renew_4 = 0;
    data3.magzine_Total_4 = 0;

    data3.magzine_New_5 = 0;
    data3.magzine_Renew_5 = 0;
    data3.magzine_Total_5 = 0;

    data3.magzine_New_6 = 0;
    data3.magzine_Renew_6 = 0;
    data3.magzine_Total_6 = 0;
    data3.magzine_New_7 = 0;
    data3.magzine_Renew_7 = 0;
    data3.magzine_Total_7 = 0;

    data3.magzine_New_8 = 0;
    data3.magzine_Renew_8 = 0;
    data3.magzine_Total_8 = 0;
    data3.magzine_New_9 = 0;
    data3.magzine_Renew_9 = 0;
    data3.magzine_Total_9 = 0;

    data3.magzine_New_10 = 0;
    data3.magzine_Renew_10 = 0;
    data3.magzine_Total_10 = 0;

    data3.magzine_New_11 = 0;
    data3.magzine_Renew_11 = 0;
    data3.magzine_Total_11 = 0;
    data3.magzine_New_12 = 0;
    data3.magzine_Renew_12 = 0;
    data3.magzine_Total_12 = 0;

    data3.magzine_New_1 = 0;
    data3.magzine_Renew_1 = 0;
    data3.magzine_Total_1 = 0;

    data3.magzine_New_2 = 0;
    data3.magzine_Renew_2 = 0;
    data3.magzine_Total_2 = 0;

    data3.magzine_New_3 = 0;
    data3.magzine_Renew_3 = 0;
    data3.magzine_Total_3 = 0;
    
    data3.digital_New_4 = 0;
    data3.digital_Renew_4 = 0;
    data3.digital_Total_4 = 0;

    data3.digital_New_5 = 0;
    data3.digital_Renew_5 = 0;
    data3.digital_Total_5 = 0;

    data3.digital_New_6 = 0;
    data3.digital_Renew_6 = 0;
    data3.digital_Total_6 = 0;

    data3.digital_New_7 = 0;
    data3.digital_Renew_7 = 0;
    data3.digital_Total_7 = 0;

    data3.digital_New_8 = 0;
    data3.digital_Renew_8 = 0;
    data3.digital_Total_8 = 0;

    data3.digital_New_9 = 0;
    data3.digital_Renew_9 = 0;
    data3.digital_Total_9 = 0;

    data3.digital_New_10 = 0;
    data3.digital_Renew_10 = 0;
    data3.digital_Total_10 = 0;

    data3.digital_New_11 = 0;
    data3.digital_Renew_11 = 0;
    data3.digital_Total_11 = 0;

    data3.digital_New_12 = 0;
    data3.digital_Renew_12 = 0;
    data3.digital_Total_12 = 0;

    data3.digital_New_1 = 0;
    data3.digital_Renew_1 = 0;
    data3.digital_Total_1 = 0;

    data3.digital_New_2 = 0;
    data3.digital_Renew_2 = 0;
    data3.digital_Total_2 = 0;

    data3.digital_New_3 = 0;
    data3.digital_Renew_3 = 0;
    data3.digital_Total_3 = 0;

    data3.tapal_New_total = 0;
    data3.tapal_Renew_total = 0;
    data3.tapal_Total_total = 0;
    data3.haste_New_total = 0;
    data3.haste_Renew_total = 0;
    data3.haste_Total_total = 0;
    data3.magzine_New_total = 0;
    data3.magzine_Renew_total = 0;
    data3.magzine_Total_total = 0;
    data3.digital_New_total = 0;
    data3.digital_Renew_total = 0;
    data3.digital_Total_total = 0;

    

    for(let data2 of data3.rep_info){

      this.tapal_New_4 += parseInt(data2.tapal_New_4);
    this.tapal_Renew_4 += parseInt(data2.tapal_Renew_4);
    this.tapal_Total_4 += parseInt(data2.tapal_Total_4);
    this.tapal_New_5 += parseInt(data2.tapal_New_5);
    this.tapal_Renew_5 += parseInt(data2.tapal_Renew_5);
    this.tapal_Total_5 += parseInt(data2.tapal_Total_5);
    this.tapal_New_6 += parseInt(data2.tapal_New_6);
    this.tapal_Renew_6 += parseInt(data2.tapal_Renew_6);
    this.tapal_Total_6 += parseInt(data2.tapal_Total_6);
    this.tapal_New_7 += parseInt(data2.tapal_New_7);
    this.tapal_Renew_7 += parseInt(data2.tapal_Renew_7);
    this.tapal_Total_7 += parseInt(data2.tapal_Total_7);
    this.tapal_New_8 += parseInt(data2.tapal_New_8);
    this.tapal_Renew_8 += parseInt(data2.tapal_Renew_8);
    this.tapal_Total_8 += parseInt(data2.tapal_Total_8);
    this.tapal_New_9 += parseInt(data2.tapal_New_9);
    this.tapal_Renew_9 += parseInt(data2.tapal_Renew_9);
    this.tapal_Total_9 += parseInt(data2.tapal_Total_9);
    this.tapal_New_10 += parseInt(data2.tapal_New_10);
    this.tapal_Renew_10 += parseInt(data2.tapal_Renew_10);
    this.tapal_Total_10 += parseInt(data2.tapal_Total_10);
    this.tapal_New_11 += parseInt(data2.tapal_New_11);
    this.tapal_Renew_11 += parseInt(data2.tapal_Renew_11);
    this.tapal_Total_11 += parseInt(data2.tapal_Total_11);
    this.tapal_New_12 += parseInt(data2.tapal_New_12);
    this.tapal_Renew_12 += parseInt(data2.tapal_Renew_12);
    this.tapal_Total_12 += parseInt(data2.tapal_Total_12);
    this.tapal_New_1 += parseInt(data2.tapal_New_1);
    this.tapal_Renew_1 += parseInt(data2.tapal_Renew_1);
    this.tapal_Total_1 += parseInt(data2.tapal_Total_1);
    this.tapal_New_2 += parseInt(data2.tapal_New_2);
    this.tapal_Renew_2 += parseInt(data2.tapal_Renew_2);
    this.tapal_Total_2 += parseInt(data2.tapal_Total_2);
    this.tapal_New_3 += parseInt(data2.tapal_New_3);
    this.tapal_Renew_3 += parseInt(data2.tapal_Renew_3);
    this.tapal_Total_3 += parseInt(data2.tapal_Total_3);

    this.haste_New_4  += parseInt(data2.haste_New_4);
    this.haste_Renew_4 += parseInt(data2.haste_Renew_4);
    this.haste_Total_4 += parseInt(data2.haste_Total_4);
    this.haste_New_5  += parseInt(data2.haste_New_5);
    this.haste_Renew_5 += parseInt(data2.haste_Renew_5);
    this.haste_Total_5 += parseInt(data2.haste_Total_5);
    this.haste_New_6  += parseInt(data2.haste_New_6);
    this.haste_Renew_6 += parseInt(data2.haste_Renew_6);
    this.haste_Total_6 += parseInt(data2.haste_Total_6);
    this.haste_New_7  += parseInt(data2.haste_New_7);
    this.haste_Renew_7 += parseInt(data2.haste_Renew_7);
    this.haste_Total_7 += parseInt(data2.haste_Total_7);
    this.haste_New_8  += parseInt(data2.haste_New_8);
    this.haste_Renew_8 += parseInt(data2.haste_Renew_8);
    this.haste_Total_8 += parseInt(data2.haste_Total_8);
    this.haste_New_9  += parseInt(data2.haste_New_9);
    this.haste_Renew_9 += parseInt(data2.haste_Renew_9);
    this.haste_Total_9 += parseInt(data2.haste_Total_9);
    this.haste_New_10  += parseInt(data2.haste_New_10);
    this.haste_Renew_10 += parseInt(data2.haste_Renew_10);
    this.haste_Total_10 += parseInt(data2.haste_Total_10);
    this.haste_New_11  += parseInt(data2.haste_New_11);
    this.haste_Renew_11 += parseInt(data2.haste_Renew_11);
    this.haste_Total_11 += parseInt(data2.haste_Total_11);
    this.haste_New_12  += parseInt(data2.haste_New_12);
    this.haste_Renew_12 += parseInt(data2.haste_Renew_12);
    this.haste_Total_12 += parseInt(data2.haste_Total_12);
    this.haste_New_1  += parseInt(data2.haste_New_1);
    this.haste_Renew_1 += parseInt(data2.haste_Renew_1);
    this.haste_Total_1 += parseInt(data2.haste_Total_1);
    this.haste_New_2  += parseInt(data2.haste_New_2);
    this.haste_Renew_2 += parseInt(data2.haste_Renew_2);
    this.haste_Total_2 += parseInt(data2.haste_Total_2);
    this.haste_New_3  += parseInt(data2.haste_New_3);
    this.haste_Renew_3 += parseInt(data2.haste_Renew_3);
    this.haste_Total_3 += parseInt(data2.haste_Total_3);

      this.magzine_New_4 += parseInt(data2.magzine_New_4);
        this.magzine_Renew_4 += parseInt(data2.magzine_Renew_4);
        this.magzine_Total_4 += parseInt(data2.magzine_Total_4);

        this.magzine_New_5 += parseInt(data2.magzine_New_5);
        this.magzine_Renew_5 += parseInt(data2.magzine_Renew_5);
        this.magzine_Total_5 += parseInt(data2.magzine_Total_5);

        this.magzine_New_6 += parseInt(data2.magzine_New_6);
        this.magzine_Renew_6 += parseInt(data2.magzine_Renew_6);
        this.magzine_Total_6 += parseInt(data2.magzine_Total_6);

        this.magzine_New_7 += parseInt(data2.magzine_New_7);
        this.magzine_Renew_7 += parseInt(data2.magzine_Renew_7);
        this.magzine_Total_7 += parseInt(data2.magzine_Total_7);

        this.magzine_New_8 += parseInt(data2.magzine_New_8);
        this.magzine_Renew_8 += parseInt(data2.magzine_Renew_8);
        this.magzine_Total_8 += parseInt(data2.magzine_Total_8);

        this.magzine_New_9 += parseInt(data2.magzine_New_9);
        this.magzine_Renew_9 += parseInt(data2.magzine_Renew_9);
        this.magzine_Total_9 += parseInt(data2.magzine_Total_9);

        this.magzine_New_10 += parseInt(data2.magzine_New_10);
        this.magzine_Renew_10 += parseInt(data2.magzine_Renew_10);
        this.magzine_Total_10 += parseInt(data2.magzine_Total_10);

        this.magzine_New_11 += parseInt(data2.magzine_New_11);
        this.magzine_Renew_11 += parseInt(data2.magzine_Renew_11);
        this.magzine_Total_11 += parseInt(data2.magzine_Total_11);

        this.magzine_New_12 += parseInt(data2.magzine_New_12);
        this.magzine_Renew_12 += parseInt(data2.magzine_Renew_12);
        this.magzine_Total_12 += parseInt(data2.magzine_Total_12);

        this.magzine_New_1 += parseInt(data2.magzine_New_1);
        this.magzine_Renew_1 += parseInt(data2.magzine_Renew_1);
        this.magzine_Total_1 += parseInt(data2.magzine_Total_1);

        this.magzine_New_2 += parseInt(data2.magzine_New_2);
        this.magzine_Renew_2 += parseInt(data2.magzine_Renew_2);
        this.magzine_Total_2 += parseInt(data2.magzine_Total_2);

        this.magzine_New_3 += parseInt(data2.magzine_New_3);
        this.magzine_Renew_3 += parseInt(data2.magzine_Renew_3);
        this.magzine_Total_3 += parseInt(data2.magzine_Total_3);
        
        this.digital_New_4 += parseInt(data2.digital_New_4);
        this.digital_Renew_4 += parseInt(data2.digital_Renew_4);
        this.digital_Total_4 += parseInt(data2.digital_Total_4);

        this.digital_New_5 += parseInt(data2.digital_New_5);
        this.digital_Renew_5 += parseInt(data2.digital_Renew_5);
        this.digital_Total_5 += parseInt(data2.digital_Total_5);

        this.digital_New_6 += parseInt(data2.digital_New_6);
        this.digital_Renew_6 += parseInt(data2.digital_Renew_6);
        this.digital_Total_6 += parseInt(data2.digital_Total_6);

        this.digital_New_7 += parseInt(data2.digital_New_7);
        this.digital_Renew_7 += parseInt(data2.digital_Renew_7);
        this.digital_Total_7 += parseInt(data2.digital_Total_7);

        this.digital_New_8 += parseInt(data2.digital_New_8);
        this.digital_Renew_8 += parseInt(data2.digital_Renew_8);
        this.digital_Total_8 += parseInt(data2.digital_Total_8);

        this.digital_New_9 += parseInt(data2.digital_New_9);
        this.digital_Renew_9 += parseInt(data2.digital_Renew_9);
        this.digital_Total_9 += parseInt(data2.digital_Total_9);

        this.digital_New_10 += parseInt(data2.digital_New_10);
        this.digital_Renew_10 += parseInt(data2.digital_Renew_10);
        this.digital_Total_10 += parseInt(data2.digital_Total_10);

        this.digital_New_11 += parseInt(data2.digital_New_11);
        this.digital_Renew_11 += parseInt(data2.digital_Renew_11);
        this.digital_Total_11 += parseInt(data2.digital_Total_11);

        this.digital_New_12 += parseInt(data2.digital_New_12);
        this.digital_Renew_12 += parseInt(data2.digital_Renew_12);
        this.digital_Total_12 += parseInt(data2.digital_Total_12);

        this.digital_New_1 += parseInt(data2.digital_New_1);
        this.digital_Renew_1 += parseInt(data2.digital_Renew_1);
        this.digital_Total_1 += parseInt(data2.digital_Total_1);

        this.digital_New_2 += parseInt(data2.digital_New_2);
        this.digital_Renew_2 += parseInt(data2.digital_Renew_2);
        this.digital_Total_2 += parseInt(data2.digital_Total_2);

        this.digital_New_3 += parseInt(data2.digital_New_3);
        this.digital_Renew_3 += parseInt(data2.digital_Renew_3);
        this.digital_Total_3 += parseInt(data2.digital_Total_3);

                
        this.tapal_New_total+= parseInt(data2.tapal_New_Total);
        this.tapal_Renew_total += parseInt(data2.tapal_Renew_Total);
        this.tapal_Total_total+= parseInt(data2.tapal_Total);
        this.haste_New_total += parseInt(data2.haste_New_Total);
        this.haste_Renew_total += parseInt(data2.haste_Renew_Total);
        this.haste_Total_total += parseInt(data2.haste_Total);
        this.magzine_New_total += parseInt(data2.magzine_New_Total);
        this.magzine_Renew_total += parseInt(data2.magzine_Renew_Total);
        this.magzine_Total_total += parseInt(data2.magzine_Total);
        this.digital_New_total+= parseInt(data2.digital_New_Total);
        this.digital_Renew_total += parseInt(data2.digital_Renew_Total);
        this.digital_Total_total += parseInt(data2.digital_Total);



        data3.tapal_New_4 += parseInt(data2.tapal_New_4);
data3.tapal_Renew_4 += parseInt(data2.tapal_Renew_4);
data3.tapal_Total_4 += parseInt(data2.tapal_Total_4);
data3.tapal_New_5 += parseInt(data2.tapal_New_5);
data3.tapal_Renew_5 += parseInt(data2.tapal_Renew_5);
data3.tapal_Total_5 += parseInt(data2.tapal_Total_5);
data3.tapal_New_6 += parseInt(data2.tapal_New_6);
data3.tapal_Renew_6 += parseInt(data2.tapal_Renew_6);
data3.tapal_Total_6 += parseInt(data2.tapal_Total_6);
data3.tapal_New_7 += parseInt(data2.tapal_New_7);
data3.tapal_Renew_7 += parseInt(data2.tapal_Renew_7);
data3.tapal_Total_7 += parseInt(data2.tapal_Total_7);
data3.tapal_New_8 += parseInt(data2.tapal_New_8);
data3.tapal_Renew_8 += parseInt(data2.tapal_Renew_8);
data3.tapal_Total_8 += parseInt(data2.tapal_Total_8);
data3.tapal_New_9 += parseInt(data2.tapal_New_9);
data3.tapal_Renew_9 += parseInt(data2.tapal_Renew_9);
data3.tapal_Total_9 += parseInt(data2.tapal_Total_9);
data3.tapal_New_10 += parseInt(data2.tapal_New_10);
data3.tapal_Renew_10 += parseInt(data2.tapal_Renew_10);
data3.tapal_Total_10 += parseInt(data2.tapal_Total_10);
data3.tapal_New_11 += parseInt(data2.tapal_New_11);
data3.tapal_Renew_11 += parseInt(data2.tapal_Renew_11);
data3.tapal_Total_11 += parseInt(data2.tapal_Total_11);
data3.tapal_New_12 += parseInt(data2.tapal_New_12);
data3.tapal_Renew_12 += parseInt(data2.tapal_Renew_12);
data3.tapal_Total_12 += parseInt(data2.tapal_Total_12);
data3.tapal_New_1 += parseInt(data2.tapal_New_1);
data3.tapal_Renew_1 += parseInt(data2.tapal_Renew_1);
data3.tapal_Total_1 += parseInt(data2.tapal_Total_1);
data3.tapal_New_2 += parseInt(data2.tapal_New_2);
data3.tapal_Renew_2 += parseInt(data2.tapal_Renew_2);
data3.tapal_Total_2 += parseInt(data2.tapal_Total_2);
data3.tapal_New_3 += parseInt(data2.tapal_New_3);
data3.tapal_Renew_3 += parseInt(data2.tapal_Renew_3);
data3.tapal_Total_3 += parseInt(data2.tapal_Total_3);

data3.haste_New_4  += parseInt(data2.haste_New_4);
data3.haste_Renew_4 += parseInt(data2.haste_Renew_4);
data3.haste_Total_4 += parseInt(data2.haste_Total_4);
data3.haste_New_5  += parseInt(data2.haste_New_5);
data3.haste_Renew_5 += parseInt(data2.haste_Renew_5);
data3.haste_Total_5 += parseInt(data2.haste_Total_5);
data3.haste_New_6  += parseInt(data2.haste_New_6);
data3.haste_Renew_6 += parseInt(data2.haste_Renew_6);
data3.haste_Total_6 += parseInt(data2.haste_Total_6);
data3.haste_New_7  += parseInt(data2.haste_New_7);
data3.haste_Renew_7 += parseInt(data2.haste_Renew_7);
data3.haste_Total_7 += parseInt(data2.haste_Total_7);
data3.haste_New_8  += parseInt(data2.haste_New_8);
data3.haste_Renew_8 += parseInt(data2.haste_Renew_8);
data3.haste_Total_8 += parseInt(data2.haste_Total_8);
data3.haste_New_9  += parseInt(data2.haste_New_9);
data3.haste_Renew_9 += parseInt(data2.haste_Renew_9);
data3.haste_Total_9 += parseInt(data2.haste_Total_9);
data3.haste_New_10  += parseInt(data2.haste_New_10);
data3.haste_Renew_10 += parseInt(data2.haste_Renew_10);
data3.haste_Total_10 += parseInt(data2.haste_Total_10);
data3.haste_New_11  += parseInt(data2.haste_New_11);
data3.haste_Renew_11 += parseInt(data2.haste_Renew_11);
data3.haste_Total_11 += parseInt(data2.haste_Total_11);
data3.haste_New_12  += parseInt(data2.haste_New_12);
data3.haste_Renew_12 += parseInt(data2.haste_Renew_12);
data3.haste_Total_12 += parseInt(data2.haste_Total_12);
data3.haste_New_1  += parseInt(data2.haste_New_1);
data3.haste_Renew_1 += parseInt(data2.haste_Renew_1);
data3.haste_Total_1 += parseInt(data2.haste_Total_1);
data3.haste_New_2  += parseInt(data2.haste_New_2);
data3.haste_Renew_2 += parseInt(data2.haste_Renew_2);
data3.haste_Total_2 += parseInt(data2.haste_Total_2);
data3.haste_New_3  += parseInt(data2.haste_New_3);
data3.haste_Renew_3 += parseInt(data2.haste_Renew_3);
data3.haste_Total_3 += parseInt(data2.haste_Total_3);

data3.magzine_New_4 += parseInt(data2.magzine_New_4);
data3.magzine_Renew_4 += parseInt(data2.magzine_Renew_4);
data3.magzine_Total_4 += parseInt(data2.magzine_Total_4);

data3.magzine_New_5 += parseInt(data2.magzine_New_5);
data3.magzine_Renew_5 += parseInt(data2.magzine_Renew_5);
data3.magzine_Total_5 += parseInt(data2.magzine_Total_5);

data3.magzine_New_6 += parseInt(data2.magzine_New_6);
data3.magzine_Renew_6 += parseInt(data2.magzine_Renew_6);
data3.magzine_Total_6 += parseInt(data2.magzine_Total_6);

data3.magzine_New_7 += parseInt(data2.magzine_New_7);
data3.magzine_Renew_7 += parseInt(data2.magzine_Renew_7);
data3.magzine_Total_7 += parseInt(data2.magzine_Total_7);

data3.magzine_New_8 += parseInt(data2.magzine_New_8);
data3.magzine_Renew_8 += parseInt(data2.magzine_Renew_8);
data3.magzine_Total_8 += parseInt(data2.magzine_Total_8);

data3.magzine_New_9 += parseInt(data2.magzine_New_9);
data3.magzine_Renew_9 += parseInt(data2.magzine_Renew_9);
data3.magzine_Total_9 += parseInt(data2.magzine_Total_9);

data3.magzine_New_10 += parseInt(data2.magzine_New_10);
data3.magzine_Renew_10 += parseInt(data2.magzine_Renew_10);
data3.magzine_Total_10 += parseInt(data2.magzine_Total_10);

data3.magzine_New_11 += parseInt(data2.magzine_New_11);
data3.magzine_Renew_11 += parseInt(data2.magzine_Renew_11);
data3.magzine_Total_11 += parseInt(data2.magzine_Total_11);

data3.magzine_New_12 += parseInt(data2.magzine_New_12);
data3.magzine_Renew_12 += parseInt(data2.magzine_Renew_12);
data3.magzine_Total_12 += parseInt(data2.magzine_Total_12);

data3.magzine_New_1 += parseInt(data2.magzine_New_1);
data3.magzine_Renew_1 += parseInt(data2.magzine_Renew_1);
data3.magzine_Total_1 += parseInt(data2.magzine_Total_1);

data3.magzine_New_2 += parseInt(data2.magzine_New_2);
data3.magzine_Renew_2 += parseInt(data2.magzine_Renew_2);
data3.magzine_Total_2 += parseInt(data2.magzine_Total_2);

data3.magzine_New_3 += parseInt(data2.magzine_New_3);
data3.magzine_Renew_3 += parseInt(data2.magzine_Renew_3);
data3.magzine_Total_3 += parseInt(data2.magzine_Total_3);

data3.digital_New_4 += parseInt(data2.digital_New_4);
data3.digital_Renew_4 += parseInt(data2.digital_Renew_4);
data3.digital_Total_4 += parseInt(data2.digital_Total_4);

data3.digital_New_5 += parseInt(data2.digital_New_5);
data3.digital_Renew_5 += parseInt(data2.digital_Renew_5);
data3.digital_Total_5 += parseInt(data2.digital_Total_5);

data3.digital_New_6 += parseInt(data2.digital_New_6);
data3.digital_Renew_6 += parseInt(data2.digital_Renew_6);
data3.digital_Total_6 += parseInt(data2.digital_Total_6);

data3.digital_New_7 += parseInt(data2.digital_New_7);
data3.digital_Renew_7 += parseInt(data2.digital_Renew_7);
data3.digital_Total_7 += parseInt(data2.digital_Total_7);

data3.digital_New_8 += parseInt(data2.digital_New_8);
data3.digital_Renew_8 += parseInt(data2.digital_Renew_8);
data3.digital_Total_8 += parseInt(data2.digital_Total_8);

data3.digital_New_9 += parseInt(data2.digital_New_9);
data3.digital_Renew_9 += parseInt(data2.digital_Renew_9);
data3.digital_Total_9 += parseInt(data2.digital_Total_9);

data3.digital_New_10 += parseInt(data2.digital_New_10);
data3.digital_Renew_10 += parseInt(data2.digital_Renew_10);
data3.digital_Total_10 += parseInt(data2.digital_Total_10);

data3.digital_New_11 += parseInt(data2.digital_New_11);
data3.digital_Renew_11 += parseInt(data2.digital_Renew_11);
data3.digital_Total_11 += parseInt(data2.digital_Total_11);

data3.digital_New_12 += parseInt(data2.digital_New_12);
data3.digital_Renew_12 += parseInt(data2.digital_Renew_12);
data3.digital_Total_12 += parseInt(data2.digital_Total_12);

data3.digital_New_1 += parseInt(data2.digital_New_1);
data3.digital_Renew_1 += parseInt(data2.digital_Renew_1);
data3.digital_Total_1 += parseInt(data2.digital_Total_1);

data3.digital_New_2 += parseInt(data2.digital_New_2);
data3.digital_Renew_2 += parseInt(data2.digital_Renew_2);
data3.digital_Total_2 += parseInt(data2.digital_Total_2);

data3.digital_New_3 += parseInt(data2.digital_New_3);
data3.digital_Renew_3 += parseInt(data2.digital_Renew_3);
data3.digital_Total_3 += parseInt(data2.digital_Total_3);

data3.tapal_New_total+= parseInt(data2.tapal_New_Total);
data3.tapal_Renew_total += parseInt(data2.tapal_Renew_Total);
data3.tapal_Total_total+= parseInt(data2.tapal_Total);
data3.haste_New_total += parseInt(data2.haste_New_Total);
data3.haste_Renew_total += parseInt(data2.haste_Renew_Total);
data3.haste_Total_total += parseInt(data2.haste_Total);
data3.magzine_New_total += parseInt(data2.magzine_New_Total);
data3.magzine_Renew_total += parseInt(data2.magzine_Renew_Total);
data3.magzine_Total_total += parseInt(data2.magzine_Total);
data3.digital_New_total+= parseInt(data2.digital_New_Total);
data3.digital_Renew_total += parseInt(data2.digital_Renew_Total);
data3.digital_Total_total += parseInt(data2.digital_Total);

    
      
    }

    

  }


    console.log("taluka renewal reming", data);
     });
}


//-------------Remaining off rep very briefly ---------------
if(this.reportType=="RemainingSubscriptionReprensantiveOffvb"){
  this.service.getSubRemOffVbriefly(req).subscribe((data) => {
    this.SubRemOffVBriefly = data;

   
    this.total_New_4  =0;
this.total_Renew_4 =0;
this.total_Total_4 =0;
this.total_New_5  =0;
this.total_Renew_5 =0;
this.total_Total_5 =0;
this.total_New_6  =0;
this.total_Renew_6 =0;
this.total_Total_6 =0;
this.total_New_7  =0;
this.total_Renew_7 =0;
this.total_Total_7 =0;
this.total_New_8  =0;
this.total_Renew_8 =0;
this.total_Total_8 =0;
this.total_New_9  =0;
this.total_Renew_9 =0;
this.total_Total_9 =0;
this.total_New_10  =0;
this.total_Renew_10=0;
this.total_Total_10 =0;
this.total_New_11  =0;
this.total_Renew_11 =0;
this.total_Total_11 =0;
this.total_New_12  =0;
this.total_Renew_12 =0;
this.total_Total_12 =0;
this.total_New_1  =0;
this.total_Renew_1 =0;
this.total_Total_1 =0;
this.total_New_2  =0;
this.total_Renew_2 =0;
this.total_Total_2 =0;
this.total_New_3  =0;
this.total_Renew_3 =0;
this.total_Total_3 =0;
this.total_New_total =0;
this.total_Renew_total =0;
this.total_Total_total =0;



  for (let data2 of this.SubRemOffVBriefly) {
    this.total_New_4   += parseInt(data2.New_4);
    this.total_Renew_4  += parseInt(data2.Renew_4);
    this.total_Total_4  += parseInt(data2.Total_4);
    this.total_New_5   += parseInt(data2.New_5);
    this.total_Renew_5  += parseInt(data2.Renew_5);
    this.total_Total_5  += parseInt(data2.Total_5);
    this.total_New_6   +=parseInt(data2.New_6);
    this.total_Renew_6   += parseInt(data2.Renew_6);
    this.total_Total_6  += parseInt(data2.Total_6);
    this.total_New_7   += parseInt(data2.New_7);
    this.total_Renew_7  += parseInt(data2.Renew_7);
    this.total_Total_7  += parseInt(data2.Total_7);
    this.total_New_8   += parseInt(data2.New_8);
    this.total_Renew_8   += parseInt(data2.Renew_8);
    this.total_Total_8  += parseInt(data2.Total_8);
    this.total_New_9   += parseInt(data2.New_9);
    this.total_Renew_9   += parseInt(data2.Renew_9);
    this.total_Total_9  += parseInt(data2.Total_9);
    this.total_New_10  += parseInt(data2.New_10);
    this.total_Renew_10  += parseInt(data2.Renew_10);
    this.total_Total_10 += parseInt(data2.Total_10);
    this.total_New_11   += parseInt(data2.New_11);
    this.total_Renew_11  += parseInt(data2.Renew_11);
    this.total_Total_11  += parseInt(data2.Total_11);
    this.total_New_12   += parseInt(data2.New_12);
    this.total_Renew_12   += parseInt(data2.Renew_12);
    this.total_Total_12  += parseInt(data2.Total_12);
    this.total_New_1   += parseInt(data2.New_1);
    this.total_Renew_1   += parseInt(data2.Renew_1);
    this.total_Total_1  += parseInt(data2.Total_1);
    this.total_New_2   += parseInt(data2.New_2);
    this.total_Renew_2  += parseInt(data2.Renew_2);
    this.total_Total_2 += parseInt(data2.Total_2);
    this.total_New_3   += parseInt(data2.New_3);
    this.total_Renew_3   += parseInt(data2.Renew_3);
    this.total_Total_3  += parseInt(data2.Total_3);
    this.total_New_total  += parseInt(data2.new_total);
    this.total_Renew_total  += parseInt(data2.renew_total);
    this.total_Total_total  += parseInt(data2.total_total);

  }


    console.log("taluka renewal reming", data);
     });
}


//-------------Remaining  rep very  briefly ---------------
if(this.reportType=="RemainingSubscriptionReprensantiveRepvb"){
 
  this.service.getSubRemRepVbriefly(req).subscribe((data) => {
    this.SubRemRepVBriefly = data;

   
    this.total_New_4  =0;
this.total_Renew_4 =0;
this.total_Total_4 =0;
this.total_New_5  =0;
this.total_Renew_5 =0;
this.total_Total_5 =0;
this.total_New_6  =0;
this.total_Renew_6 =0;
this.total_Total_6 =0;
this.total_New_7  =0;
this.total_Renew_7 =0;
this.total_Total_7 =0;
this.total_New_8  =0;
this.total_Renew_8 =0;
this.total_Total_8 =0;
this.total_New_9  =0;
this.total_Renew_9 =0;
this.total_Total_9 =0;
this.total_New_10  =0;
this.total_Renew_10=0;
this.total_Total_10 =0;
this.total_New_11  =0;
this.total_Renew_11 =0;
this.total_Total_11 =0;
this.total_New_12  =0;
this.total_Renew_12 =0;
this.total_Total_12 =0;
this.total_New_1  =0;
this.total_Renew_1 =0;
this.total_Total_1 =0;
this.total_New_2  =0;
this.total_Renew_2 =0;
this.total_Total_2 =0;
this.total_New_3  =0;
this.total_Renew_3 =0;
this.total_Total_3 =0;
this.total_New_total =0;
this.total_Renew_total =0;
this.total_Total_total =0;



  for (let data2 of this.SubRemRepVBriefly) {
    this.total_New_4   += parseInt(data2.New_4);
    this.total_Renew_4  += parseInt(data2.Renew_4);
    this.total_Total_4  += parseInt(data2.Total_4);
    this.total_New_5   += parseInt(data2.New_5);
    this.total_Renew_5  += parseInt(data2.Renew_5);
    this.total_Total_5  += parseInt(data2.Total_5);
    this.total_New_6   +=parseInt(data2.New_6);
    this.total_Renew_6   += parseInt(data2.Renew_6);
    this.total_Total_6  += parseInt(data2.Total_6);
    this.total_New_7   += parseInt(data2.New_7);
    this.total_Renew_7  += parseInt(data2.Renew_7);
    this.total_Total_7  += parseInt(data2.Total_7);
    this.total_New_8   += parseInt(data2.New_8);
    this.total_Renew_8   += parseInt(data2.Renew_8);
    this.total_Total_8  += parseInt(data2.Total_8);
    this.total_New_9   += parseInt(data2.New_9);
    this.total_Renew_9   += parseInt(data2.Renew_9);
    this.total_Total_9  += parseInt(data2.Total_9);
    this.total_New_10  += parseInt(data2.New_10);
    this.total_Renew_10  += parseInt(data2.Renew_10);
    this.total_Total_10 += parseInt(data2.Total_10);
    this.total_New_11   += parseInt(data2.New_11);
    this.total_Renew_11  += parseInt(data2.Renew_11);
    this.total_Total_11  += parseInt(data2.Total_11);
    this.total_New_12   += parseInt(data2.New_12);
    this.total_Renew_12   += parseInt(data2.Renew_12);
    this.total_Total_12  += parseInt(data2.Total_12);
    this.total_New_1   += parseInt(data2.New_1);
    this.total_Renew_1   += parseInt(data2.Renew_1);
    this.total_Total_1  += parseInt(data2.Total_1);
    this.total_New_2   += parseInt(data2.New_2);
    this.total_Renew_2  += parseInt(data2.Renew_2);
    this.total_Total_2 += parseInt(data2.Total_2);
    this.total_New_3   += parseInt(data2.New_3);
    this.total_Renew_3   += parseInt(data2.Renew_3);
    this.total_Total_3  += parseInt(data2.Total_3);
    this.total_New_total  += parseInt(data2.new_total);
    this.total_Renew_total  += parseInt(data2.renew_total);
    this.total_Total_total  += parseInt(data2.total_total);

  }


    console.log("taluka renewal reming", data);
     });
}




//-------------Remaining  rep  briefly ---------------
if(this.reportType=="RemainingSubscriptionReprensantiveRepb"){

  this.service.getSubRemRepbriefly(req).subscribe((data) => {
    this.SubRemRepBriefly = data;

   
    this.total_New_4  =0;
this.total_Renew_4 =0;
this.total_Total_4 =0;
this.total_New_5  =0;
this.total_Renew_5 =0;
this.total_Total_5 =0;
this.total_New_6  =0;
this.total_Renew_6 =0;
this.total_Total_6 =0;
this.total_New_7  =0;
this.total_Renew_7 =0;
this.total_Total_7 =0;
this.total_New_8  =0;
this.total_Renew_8 =0;
this.total_Total_8 =0;
this.total_New_9  =0;
this.total_Renew_9 =0;
this.total_Total_9 =0;
this.total_New_10  =0;
this.total_Renew_10=0;
this.total_Total_10 =0;
this.total_New_11  =0;
this.total_Renew_11 =0;
this.total_Total_11 =0;
this.total_New_12  =0;
this.total_Renew_12 =0;
this.total_Total_12 =0;
this.total_New_1  =0;
this.total_Renew_1 =0;
this.total_Total_1 =0;
this.total_New_2  =0;
this.total_Renew_2 =0;
this.total_Total_2 =0;
this.total_New_3  =0;
this.total_Renew_3 =0;
this.total_Total_3 =0;
this.total_New_total =0;
this.total_Renew_total =0;
this.total_Total_total =0;



  for (let data2 of this.SubRemRepBriefly) {
    this.total_New_4   += parseInt(data2.New_4);
    this.total_Renew_4  += parseInt(data2.Renew_4);
    this.total_Total_4  += parseInt(data2.Total_4);
    this.total_New_5   += parseInt(data2.New_5);
    this.total_Renew_5  += parseInt(data2.Renew_5);
    this.total_Total_5  += parseInt(data2.Total_5);
    this.total_New_6   +=parseInt(data2.New_6);
    this.total_Renew_6   += parseInt(data2.Renew_6);
    this.total_Total_6  += parseInt(data2.Total_6);
    this.total_New_7   += parseInt(data2.New_7);
    this.total_Renew_7  += parseInt(data2.Renew_7);
    this.total_Total_7  += parseInt(data2.Total_7);
    this.total_New_8   += parseInt(data2.New_8);
    this.total_Renew_8   += parseInt(data2.Renew_8);
    this.total_Total_8  += parseInt(data2.Total_8);
    this.total_New_9   += parseInt(data2.New_9);
    this.total_Renew_9   += parseInt(data2.Renew_9);
    this.total_Total_9  += parseInt(data2.Total_9);
    this.total_New_10  += parseInt(data2.New_10);
    this.total_Renew_10  += parseInt(data2.Renew_10);
    this.total_Total_10 += parseInt(data2.Total_10);
    this.total_New_11   += parseInt(data2.New_11);
    this.total_Renew_11  += parseInt(data2.Renew_11);
    this.total_Total_11  += parseInt(data2.Total_11);
    this.total_New_12   += parseInt(data2.New_12);
    this.total_Renew_12   += parseInt(data2.Renew_12);
    this.total_Total_12  += parseInt(data2.Total_12);
    this.total_New_1   += parseInt(data2.New_1);
    this.total_Renew_1   += parseInt(data2.Renew_1);
    this.total_Total_1  += parseInt(data2.Total_1);
    this.total_New_2   += parseInt(data2.New_2);
    this.total_Renew_2  += parseInt(data2.Renew_2);
    this.total_Total_2 += parseInt(data2.Total_2);
    this.total_New_3   += parseInt(data2.New_3);
    this.total_Renew_3   += parseInt(data2.Renew_3);
    this.total_Total_3  += parseInt(data2.Total_3);
    this.total_New_total  += parseInt(data2.New_Total);
    this.total_Renew_total  += parseInt(data2.Renew_Total);
    this.total_Total_total  += parseInt(data2.Total_Total);

  }


    console.log("taluka renewal reming", data);
     });
}


if(this.reportType=="RemainingSubscriptionoffReprensantiveBriefly"){
  
  this.service.getSubRemOffRepbrief(req).subscribe((data) => {
    
    this.SubRemOffRepbrief = data;
    // this.PaymentadvtOffInDTotal = 0;

    this.renew_4 = 0;
    this.new_4 = 0;
    this.total_4 = 0;
    this.renew_5 = 0;
    this.new_5 = 0;
    this.total_5 = 0;
    this.renew_6 = 0;
    this.new_6 = 0;
    this.total_6 = 0;
    this.renew_7 = 0;
    this.new_7 = 0;
    this.total_7 = 0;
    this.renew_8 = 0;
    this.new_8 = 0;
    this.total_8 = 0;
    this.renew_9 = 0;
    this.new_9 = 0;
    this.total_9 = 0;
    this.renew_10 = 0;
    this.new_10 = 0;
    this.total_10 = 0;
    this.renew_11 = 0;
    this.new_11 = 0;
    this.total_11 = 0;
    this.renew_12 = 0;
    this.new_12 = 0;
    this.total_12 = 0;
    this.renew_1 = 0;
    this.new_1 = 0;
    this.total_1 = 0;
    this.renew_2 = 0;
    this.new_2 = 0;
    this.total_2 = 0;
    this.renew_3 = 0;
    this.new_3 = 0;
    this.total_3 = 0;
    this.renew_Total = 0;
    this.new_Total =  0;
    this.total_Total= 0;
   

   for (let data2 of this.SubRemOffRepbrief){

    data2.renew_4 = 0;
data2.new_4 = 0;
data2.total_4 = 0;
data2.renew_5 = 0;
data2.new_5 = 0;
data2.total_5 = 0;
data2.renew_6 = 0;
data2.new_6 = 0;
data2.total_6 = 0;
data2.renew_7 = 0;
data2.new_7 = 0;
data2.total_7 = 0;
data2.renew_8 = 0;
data2.new_8 = 0;
data2.total_8 = 0;
data2.renew_9 = 0;
data2.new_9 = 0;
data2.total_9 = 0;
data2.renew_10 = 0;
data2.new_10 = 0;
data2.total_10 = 0;
data2.renew_11 = 0;
data2.new_11 = 0;
data2.total_11 = 0;
data2.renew_12 = 0;
data2.new_12 = 0;
data2.total_12 = 0;
data2.renew_1 = 0;
data2.new_1 = 0;
data2.total_1 = 0;
data2.renew_2 = 0;
data2.new_2 = 0;
data2.total_2 = 0;
data2.renew_3 = 0;
data2.new_3 = 0;
data2.total_3 = 0;
data2.renew_Total = 0;
data2.new_Total =  0;
data2.total_Total = 0;


    for(let data11 of data2.districts){

      data11.renew_4 = 0;
    data11.new_4 = 0;
    data11.total_4 = 0;
    data11.renew_5 = 0;
    data11.new_5 = 0;
    data11.total_5 = 0;
    data11.renew_6 = 0;
    data11.new_6 = 0;
    data11.total_6 = 0;
    data11.renew_7 = 0;
    data11.new_7 = 0;
    data11.total_7 = 0;
    data11.renew_8 = 0;
    data11.new_8 = 0;
    data11.total_8 = 0;
    data11.renew_9 = 0;
    data11.new_9 = 0;
    data11.total_9 = 0;
    data11.renew_10 = 0;
    data11.new_10 = 0;
    data11.total_10 = 0;
    data11.renew_11 = 0;
    data11.new_11 = 0;
    data11.total_11 = 0;
    data11.renew_12 = 0;
    data11.new_12 = 0;
    data11.total_12 = 0;
    data11.renew_1 = 0;
    data11.new_1 = 0;
    data11.total_1 = 0;
    data11.renew_2 = 0;
    data11.new_2 = 0;
    data11.total_2 = 0;
    data11.renew_3 = 0;
    data11.new_3 = 0;
    data11.total_3 = 0;
    data11.renew_Total = 0;
    data11.new_Total =  0;
    data11.total_Total= 0;

    for(let data1 of data11.talukas){

      data1.renew_4 = 0;
      data1.new_4 = 0;
      data1.total_4 = 0;
      data1.renew_5 = 0;
      data1.new_5 = 0;
      data1.total_5 = 0;
      data1.renew_6 = 0;
      data1.new_6 = 0;
      data1.total_6 = 0;
      data1.renew_7 = 0;
      data1.new_7 = 0;
      data1.total_7 = 0;
      data1.renew_8 = 0;
      data1.new_8 = 0;
      data1.total_8 = 0;
      data1.renew_9 = 0;
      data1.new_9 = 0;
      data1.total_9 = 0;
      data1.renew_10 = 0;
      data1.new_10 = 0;
      data1.total_10 = 0;
      data1.renew_11 = 0;
      data1.new_11 = 0;
      data1.total_11 = 0;
      data1.renew_12 = 0;
      data1.new_12 = 0;
      data1.total_12 = 0;
      data1.renew_1 = 0;
      data1.new_1 = 0;
      data1.total_1 = 0;
      data1.renew_2 = 0;
      data1.new_2 = 0;
      data1.total_2 = 0;
      data1.renew_3 = 0;
      data1.new_3 = 0;
      data1.total_3 = 0;
      data1.renew_Total = 0;
      data1.new_Total =  0;
      data1.total_Total= 0;


      for(let data of data1.representatives){
        this.renew_4 += parseInt(data.renew_4);
        this.new_4 += parseInt(data.new_4) ;
        this.total_4 += parseInt(data.total_4) ;
        this.renew_5 += parseInt(data.renew_5) ;
        this.new_5 += parseInt(data.new_5) ;
        this.total_5 += parseInt(data.total_5) ;
        this.renew_6 += parseInt(data.renew_6) ;
        this.new_6 += parseInt(data.new_6) ;
        this.total_6 += parseInt(data.total_6) ;
        this.renew_7 += parseInt(data.renew_7) ;
        this.new_7 += parseInt(data.new_7) ;
        this.total_7 += parseInt(data.total_7) ;
        this.renew_8 += parseInt(data.renew_8) ;
        this.new_8 += parseInt(data.new_8) ;
        this.total_8 += parseInt(data.total_8) ;
        this.renew_9 += parseInt(data.renew_9) ;
        this.new_9 += parseInt(data.new_9) ;
        this.total_9 += parseInt(data.total_9) ;
        this.renew_10 += parseInt(data.renew_10) ;
        this.new_10 += parseInt(data.new_10);
        this.total_10 += parseInt(data.total_10) ;
        this.renew_11 += parseInt(data.renew_11) ;
        this.new_11 += parseInt(data.new_11) ;
        this.total_11 += parseInt(data.total_11) ;
        this.renew_12 += parseInt(data.renew_12) ;
        this.new_12 += parseInt(data.new_12);
        this.total_12 += parseInt(data.total_12) ;
        this.renew_1 += parseInt(data.renew_1) ;
        this.new_1 += parseInt(data.new_1) ;
        this.total_1 += parseInt(data.total_1);
        this.renew_2 += parseInt(data.renew_2) ;
        this.new_2 += parseInt(data.new_2) ;
        this.total_2 += parseInt(data.total_2) ;
        this.renew_3 += parseInt(data.renew_3);
        this.new_3 += parseInt(data.new_3);
        this.total_3 += parseInt(data.total_3) ;
        this.renew_Total += parseInt(data.renew_Total);
        this.new_Total += parseInt(data.new_Total) ;
        this.total_Total += parseInt(data.total_Total) ;

        data11.renew_4 += parseInt(data.renew_4);
        data11.new_4 += parseInt(data.new_4) ;
        data11.total_4 += parseInt(data.total_4) ;
        data11.renew_5 += parseInt(data.renew_5) ;
        data11.new_5 += parseInt(data.new_5) ;
        data11.total_5 += parseInt(data.total_5) ;
        data11.renew_6 += parseInt(data.renew_6) ;
        data11.new_6 += parseInt(data.new_6) ;
        data11.total_6 += parseInt(data.total_6) ;
        data11.renew_7 += parseInt(data.renew_7) ;
        data11.new_7 += parseInt(data.new_7) ;
        data11.total_7 += parseInt(data.total_7) ;
        data11.renew_8 += parseInt(data.renew_8) ;
        data11.new_8 += parseInt(data.new_8) ;
        data11.total_8 += parseInt(data.total_8) ;
        data11.renew_9 += parseInt(data.renew_9) ;
        data11.new_9 += parseInt(data.new_9) ;
        data11.total_9 += parseInt(data.total_9) ;
        data11.renew_10 += parseInt(data.renew_10) ;
        data11.new_10 += parseInt(data.new_10);
        data11.total_10 += parseInt(data.total_10) ;
        data11.renew_11 += parseInt(data.renew_11) ;
        data11.new_11 += parseInt(data.new_11) ;
        data11.total_11 += parseInt(data.total_11) ;
        data11.renew_12 += parseInt(data.renew_12) ;
        data11.new_12 += parseInt(data.new_12);
        data11.total_12 += parseInt(data.total_12) ;
        data11.renew_1 += parseInt(data.renew_1) ;
        data11.new_1 += parseInt(data.new_1) ;
        data11.total_1 += parseInt(data.total_1);
        data11.renew_2 += parseInt(data.renew_2) ;
        data11.new_2 += parseInt(data.new_2) ;
        data11.total_2 += parseInt(data.total_2) ;
        data11.renew_3 += parseInt(data.renew_3);
        data11.new_3 += parseInt(data.new_3);
        data11.total_3 += parseInt(data.total_3) ;
        data11.renew_Total += parseInt(data.renew_Total);
        data11.new_Total += parseInt(data.new_Total) ;
        data11.total_Total += parseInt(data.total_Total) ;
       
        data1.renew_4 += parseInt(data.renew_4);
        data1.new_4 += parseInt(data.new_4) ;
        data1.total_4 += parseInt(data.total_4) ;
        data1.renew_5 += parseInt(data.renew_5) ;
        data1.new_5 += parseInt(data.new_5) ;
        data1.total_5 += parseInt(data.total_5) ;
        data1.renew_6 += parseInt(data.renew_6) ;
        data1.new_6 += parseInt(data.new_6) ;
        data1.total_6 += parseInt(data.total_6) ;
        data1.renew_7 += parseInt(data.renew_7) ;
        data1.new_7 += parseInt(data.new_7) ;
        data1.total_7 += parseInt(data.total_7) ;
        data1.renew_8 += parseInt(data.renew_8) ;
        data1.new_8 += parseInt(data.new_8) ;
        data1.total_8 += parseInt(data.total_8) ;
        data1.renew_9 += parseInt(data.renew_9) ;
        data1.new_9 += parseInt(data.new_9) ;
        data1.total_9 += parseInt(data.total_9) ;
        data1.renew_10 += parseInt(data.renew_10) ;
        data1.new_10 += parseInt(data.new_10);
        data1.total_10 += parseInt(data.total_10) ;
        data1.renew_11 += parseInt(data.renew_11) ;
        data1.new_11 += parseInt(data.new_11) ;
        data1.total_11 += parseInt(data.total_11) ;
        data1.renew_12 += parseInt(data.renew_12) ;
        data1.new_12 += parseInt(data.new_12);
        data1.total_12 += parseInt(data.total_12) ;
        data1.renew_1 += parseInt(data.renew_1) ;
        data1.new_1 += parseInt(data.new_1) ;
        data1.total_1 += parseInt(data.total_1);
        data1.renew_2 += parseInt(data.renew_2) ;
        data1.new_2 += parseInt(data.new_2) ;
        data1.total_2 += parseInt(data.total_2) ;
        data1.renew_3 += parseInt(data.renew_3);
        data1.new_3 += parseInt(data.new_3);
        data1.total_3 += parseInt(data.total_3) ;
        data1.renew_Total += parseInt(data.renew_Total);
        data1.new_Total += parseInt(data.new_Total) ;
        data1.total_Total += parseInt(data.total_Total) ;

        data2.renew_4 += parseInt(data.renew_4);
data2.new_4 += parseInt(data.new_4);
data2.total_4 += parseInt(data.total_4);
data2.renew_5 += parseInt(data.renew_5);
data2.new_5 += parseInt(data.new_5);
data2.total_5 += parseInt(data.total_5);
data2.renew_6 += parseInt(data.renew_6);
data2.new_6 += parseInt(data.new_6);
data2.total_6 += parseInt(data.total_6);
data2.renew_7 += parseInt(data.renew_7);
data2.new_7 += parseInt(data.new_7);
data2.total_7 += parseInt(data.total_7);
data2.renew_8 += parseInt(data.renew_8);
data2.new_8 += parseInt(data.new_8);
data2.total_8 += parseInt(data.total_8);
data2.renew_9 += parseInt(data.renew_9);
data2.new_9 += parseInt(data.new_9);
data2.total_9 += parseInt(data.total_9);
data2.renew_10 += parseInt(data.renew_10);
data2.new_10 += parseInt(data.new_10);
data2.total_10 += parseInt(data.total_10);
data2.renew_11 += parseInt(data.renew_11);
data2.new_11 += parseInt(data.new_11);
data2.total_11 += parseInt(data.total_11);
data2.renew_12 += parseInt(data.renew_12);
data2.new_12 += parseInt(data.new_12);
data2.total_12 += parseInt(data.total_12);
data2.renew_1 += parseInt(data.renew_1);
data2.new_1 += parseInt(data.new_1);
data2.total_1 += parseInt(data.total_1);
data2.renew_2 += parseInt(data.renew_2);
data2.new_2 += parseInt(data.new_2);
data2.total_2 += parseInt(data.total_2);
data2.renew_3 += parseInt(data.renew_3);
data2.new_3 += parseInt(data.new_3);
data2.total_3 += parseInt(data.total_3);
data2.renew_Total += parseInt(data.renew_Total);
data2.new_Total += parseInt(data.new_Total);
data2.total_Total += parseInt(data.total_Total);


        // this.total_Total4 = (parseInt(data.renew_Total) || 0) + (parseInt(data.new_Total) || 0);
        // console.log(data.renew_4, data.new_4, data.total_4); // Check if all fields have valid values



       
        // console.log(data);        
        

      }
      
    }
       
    }
  }

    

     });
}

if(this.reportType=="Renewalrep"){
     this.service.getSubByRep(req).subscribe((data) => {
      this.SubByRep = data;
    
      this.totalrepsubhilhapost = 0;
      this.totalrepsubhilhahaste = 0;
      this.totalrepsubhilhamagzine = 0;
      this.totalrepsubhilhadigital = 0;
      this.totalrepsubhilhatotal = 0;

      // months
  this.aprilpost = 0;
  this.aprilhaste  = 0;
  this.aprilmagzine = 0;
  this.aprildigital = 0;
  this.apriltotal  = 0;
  this.maypost = 0;
  this.mayhaste  = 0;
  this.maymagzine = 0;
  this.maydigital = 0;
  this.maytotal  = 0;
  this.junepost = 0;
  this.junehaste  = 0;
  this.junmagzine = 0;
  this.jundigital = 0;
  this.junetotal  = 0;
  this.julypost = 0;
  this.julyhaste  = 0;
  this.julymagzine = 0;
  this.julydigital = 0;
  this.julytotal  = 0;
  this.augpost = 0;
  this.aughaste  = 0;
  this.augmagzine = 0;
  this.augdigital = 0;
  this.augtotal  = 0;
  this.seppost = 0;
  this.sephaste  = 0;
  this.sepmagzine = 0;
  this.sepdigital = 0;
  this.septotal  = 0;
  this.octopost = 0;
  this.octohaste  = 0;
  this.octmagzine = 0;
  this.octdigital = 0;
  this.octototal  = 0;
  this.novpost = 0;
  this.novhaste  = 0;
  this.novmagzine = 0;
  this.novdigital = 0;
  this.novtotal  = 0;
  this.decpost = 0;
  this.dechaste  = 0;
  this.decmagzine = 0;
  this.decdigital = 0;
  this.dectotal  = 0;
  this.janpost = 0;
  this.janhaste  = 0;
  this.janmagzine = 0;
  this.jandigital = 0;
  this.jantotal  = 0;
  this.febpost = 0;
  this.febhaste  = 0;
  this.febmagzine = 0;
  this.febdigital = 0;
  this.febtotal  = 0;
  this.marchpost = 0;
  this.marchhaste  = 0;
  this.marchmagzine = 0;
  this.marchdigital = 0;
  this.marchtotal  = 0;

  

  for (let data2 of this.SubByRep) {

    // data2.aprilpost = 0;
    // data2.aprilhaste  = 0;
    // data2.aprilmagzine = 0;
    // data2.aprildigital = 0;
    // data2.apriltotal  = 0;
    // data2.maypost = 0;
    // data2.mayhaste  = 0;
    // data2.maymagzine = 0;
    // data2.maydigital = 0;
    // data2.maytotal  = 0;
    // data2.junepost = 0;
    // data2.junehaste  = 0;
    // data2.junmagzine = 0;
    // data2.jundigital = 0;
    // data2.junetotal  = 0;
    // data2.julypost = 0;
    // data2.julyhaste  = 0;
    // data2.julymagzine = 0;
    // data2.julydigital = 0;
    // data2.julytotal  = 0;
    // data2.augpost = 0;
    // data2.aughaste  = 0;
    // data2.augmagzine = 0;
    // data2.augdigital = 0;
    // data2.augtotal  = 0;
    // data2.seppost = 0;
    // data2.sephaste  = 0;
    // data2.sepmagzine = 0;
    // data2.sepdigital = 0;
    // data2.septotal  = 0;
    // data2.octopost = 0;
    // data2.octohaste  = 0;
    // data2.octmagzine = 0;
    // data2.octdigital = 0;
    // data2.octototal  = 0;
    // data2.novpost = 0;
    // data2.novhaste  = 0;
    // data2.novmagzine = 0;
    // data2.novdigital = 0;
    // data2.novtotal  = 0;
    // data2.decpost = 0;
    // data2.dechaste  = 0;
    // data2.decmagzine = 0;
    // data2.decdigital = 0;
    // data2.dectotal  = 0;
    // data2.janpost = 0;
    // data2.janhaste  = 0;
    // data2.janmagzine = 0;
    // data2.jandigital = 0;
    // data2.jantotal  = 0;
    // data2.febpost = 0;
    // data2.febhaste  = 0;
    // data2.febmagzine = 0;
    // data2.febdigital = 0;
    // data2.febtotal  = 0;
    // data2.marchpost = 0;
    // data2.marchhaste  = 0;
    // data2.marchmagzine = 0;
    // data2.marchdigital = 0;
    // data2.marchtotal  = 0;

    // this.totalrepsubhilhapost1 = 0 ;
    // this.totalrepsubhilhahaste1 = 0;
    // this.totalrepsubhilhamagzine1 = 0;
    // this.totalrepsubhilhadigital1 = 0;
    // this.totalrepsubhilhatotal1  = 0;
    

    // for (let data1 of data2.district){

  this.aprilpost += parseInt(data2.april_post_count_total);
  this.aprilhaste += parseInt(data2.april_haste_count_total);
  this.aprilmagzine += parseInt(data2.april_magzine_count_total);
  this.aprildigital += parseInt(data2.april_digital_count_total);
  this.apriltotal  += parseInt(data2.april_total_total_total);

  this.maypost += parseInt(data2.may_post_count_total);
  this.mayhaste  += parseInt(data2.may_haste_count_total);
  this.maymagzine += parseInt(data2.may_magzine_count_total);
  this.maydigital += parseInt(data2.may_digital_count_total);
  this.maytotal += parseInt(data2.may_total_total_total);

  this.junepost += parseInt(data2.jun_post_count_total);
  this.junehaste  += parseInt(data2.jun_haste_count_total);
  this.junmagzine+= parseInt(data2.jun_magzine_count_total);
  this.jundigital += parseInt(data2.jun_digital_count_total);
  this.junetotal  += parseInt(data2.jun_total_total_total);

  this.julypost += parseInt(data2.jul_post_count_total);
  this.julyhaste  += parseInt(data2.jul_haste_count_total);
  this.julymagzine += parseInt(data2.jul_magzine_count_total);
  this.julydigital+= parseInt(data2.jul_digital_count_total);
  this.julytotal  += parseInt(data2.jul_total_total_total);

  this.augpost += parseInt(data2.aug_post_count_total);
  this.aughaste += parseInt(data2.aug_haste_count_total);
  this.augmagzine += parseInt(data2.aug_magzine_count_total);
  this.augdigital += parseInt(data2.aug_digital_count_total);
  this.augtotal  += parseInt(data2.aug_total_total_total);

  this.seppost += parseInt(data2.sep_post_count_total);
  this.sephaste  += parseInt(data2.sep_haste_count_total);
  this.sepmagzine += parseInt(data2.sep_magzine_count_total);
  this.sepdigital+= parseInt(data2.sep_digital_count_total);
  this.septotal += parseInt(data2.sep_total_total_total);

  this.octopost += parseInt(data2.oct_post_count_total);
  this.octohaste += parseInt(data2.oct_haste_count_total);
  this.octmagzine += parseInt(data2.oct_magzine_count_total);
  this.octdigital += parseInt(data2.oct_digital_count_total);
  this.octototal  += parseInt(data2.oct_total_total_total);

  this.novpost += parseInt(data2.nov_post_count_total);
  this.novhaste += parseInt(data2.nov_haste_count_total);
  this.novmagzine += parseInt(data2.nov_magzine_count_total);
  this.novdigital += parseInt(data2.nov_digital_count_total);
  this.novtotal += parseInt(data2.nov_total_total_total);

  this.decpost+= parseInt(data2.dec_post_count_total);
  this.dechaste += parseInt(data2.dec_haste_count_total);
  this.decmagzine += parseInt(data2.dec_magzine_count_total);
  this.decdigital += parseInt(data2.dec_digital_count_total);
  this.dectotal  += parseInt(data2.dec_total_total_total);

  this.janpost += parseInt(data2.jan_post_count_total);
  this.janhaste += parseInt(data2.jan_haste_count_total);
  this.janmagzine += parseInt(data2.jan_magzine_count_total);
  this.jandigital+= parseInt(data2.jan_digital_count_total);
  this.jantotal += parseInt(data2.jan_total_total_total);


  this.febpost += parseInt(data2.feb_post_count_total);
  this.febhaste  += parseInt(data2.feb_haste_count_total);
  this.febmagzine += parseInt(data2.feb_magzine_count_total);
  this.febdigital+= parseInt(data2.feb_digital_count_total);
  this.febtotal += parseInt(data2.feb_total_total_total);

  this.marchpost += parseInt(data2.mar_post_count_total);
  this.marchhaste  += parseInt(data2.mar_haste_count_total);
  this.marchmagzine += parseInt(data2.mar_magzine_count_total);
  this.marchdigital += parseInt(data2.mar_digital_count_total);
  this.marchtotal  += parseInt(data2.mar_total_total_total);

  this.totalrepsubhilhapost += parseInt(data2.post_count_total);
  this.totalrepsubhilhahaste  += parseInt(data2.haste_count_total);
  this.totalrepsubhilhamagzine += parseInt(data2.magzine_count_total);
  this.totalrepsubhilhadigital += parseInt(data2.digital_count_total);
  this.totalrepsubhilhatotal  += parseInt(data2.total_total_total);

// Calculation For District 
      // data1.aprilpost += parseInt(data2.april_post_count_total);
      // data1.aprilhaste += parseInt(data2.april_haste_count_total);
      // data1.aprilmagzine += parseInt(data2.april_magzine_count_total);
      // data1.aprildigital += parseInt(data2.april_digital_count_total);
      // data1.apriltotal += parseInt(data2.april_total_total_total);

      // data1.maypost += parseInt(data2.may_post_count_total);
      // data1.mayhaste += parseInt(data2.may_haste_count_total);
      // data1.maymagzine += parseInt(data2.may_magzine_count_total);
      // data1.maydigital += parseInt(data2.may_digital_count_total);
      // data1.maytotal += parseInt(data2.may_total_total_total);

      // data1.junepost += parseInt(data2.jun_post_count_total);
      // data1.junehaste += parseInt(data2.jun_haste_count_total);
      // data1.junmagzine += parseInt(data2.jun_magzine_count_total);
      // data1.jundigital += parseInt(data2.jun_digital_count_total);
      // data1.junetotal += parseInt(data2.jun_total_total_total);

      // data1.julypost += parseInt(data2.jul_post_count_total);
      // data1.julyhaste += parseInt(data2.jul_haste_count_total);
      // data1.julymagzine += parseInt(data2.jul_magzine_count_total);
      // data1.julydigital += parseInt(data2.jul_digital_count_total);
      // data1.julytotal += parseInt(data2.jul_total_total_total);

      // data1.augpost += parseInt(data2.aug_post_count_total);
      // data1.aughaste += parseInt(data2.aug_haste_count_total);
      // data1.augmagzine += parseInt(data2.aug_magzine_count_total);
      // data1.augdigital += parseInt(data2.aug_digital_count_total);
      // data1.augtotal += parseInt(data2.aug_total_total_total);

      // data1.seppost += parseInt(data2.sep_post_count_total);
      // data1.sephaste += parseInt(data2.sep_haste_count_total);
      // data1.sepmagzine += parseInt(data2.sep_magzine_count_total);
      // data1.sepdigital += parseInt(data2.sep_digital_count_total);
      // data1.septotal += parseInt(data2.sep_total_total_total);

      // data1.octopost += parseInt(data2.oct_post_count_total);
      // data1.octohaste += parseInt(data2.oct_haste_count_total);
      // data1.octmagzine += parseInt(data2.oct_magzine_count_total);
      // data1.octdigital += parseInt(data2.oct_digital_count_total);
      // data1.octototal += parseInt(data2.oct_total_total_total);

      // data1.novpost += parseInt(data2.nov_post_count_total);
      // data1.novhaste += parseInt(data2.nov_haste_count_total);
      // data1.novmagzine += parseInt(data2.nov_magzine_count_total);
      // data1.novdigital += parseInt(data2.nov_digital_count_total);
      // data1.novtotal += parseInt(data2.nov_total_total_total);

      // data1.decpost += parseInt(data2.dec_post_count_total);
      // data1.dechaste += parseInt(data2.dec_haste_count_total);
      // data1.decmagzine += parseInt(data2.dec_magzine_count_total);
      // data1.decdigital += parseInt(data2.dec_digital_count_total);
      // data1.dectotal += parseInt(data2.dec_total_total_total);

      // data1.janpost += parseInt(data2.jan_post_count_total);
      // data1.janhaste += parseInt(data2.jan_haste_count_total);
      // data1.janmagzine += parseInt(data2.jan_magzine_count_total);
      // data1.jandigital += parseInt(data2.jan_digital_count_total);
      // data1.jantotal += parseInt(data2.jan_total_total_total);


      // data1.febpost += parseInt(data2.feb_post_count_total);
      // data1.febhaste += parseInt(data2.feb_haste_count_total);
      // data1.febmagzine += parseInt(data2.feb_magzine_count_total);
      // data1.febdigital += parseInt(data2.feb_digital_count_total);
      // data1.febtotal += parseInt(data2.feb_total_total_total);

      // data1.marchpost += parseInt(data2.mar_post_count_total);
      // data1.marchhaste += parseInt(data2.mar_haste_count_total);
      // data1.marchmagzine += parseInt(data2.mar_magzine_count_total);
      // data1.marchdigital += parseInt(data2.mar_digital_count_total);
      // data1.marchtotal += parseInt(data2.mar_total_total_total);

      // data1.totalrepsubhilhapost += parseInt(data2.post_count_total);
      // data1.totalrepsubhilhahaste += parseInt(data2.haste_count_total);
      // data1.totalrepsubhilhamagzine += parseInt(data2.magzine_count_total);
      // data1.totalrepsubhilhadigital += parseInt(data2.digital_count_total);
      // data1.totalrepsubhilhatotal += parseInt(data2.total_total_total);

  }


// }
       
       });
      }

      if(this.reportType=="RenewalOff"){
        this.service.getSubByOffRep(req).subscribe((data) => {
         this.SubByOffRep = data;
         // this.PaymentadvtOffInDTotal = 0;
         console.log("subbyjilha", data);
         this.totaloffrepsubhilhapost = 0;
         this.totaloffrepsubhilhahaste = 0;
         this.totalsubhilhamagzine = 0;
         this.totalsubhilhadigital = 0;
 
         this.totaloffrepsubhilhatotal = 0;
         // months
         this.aprilpost = 0;
         this.aprilhaste  = 0;
         this.aprilmagzine = 0;
         this.aprildigital = 0;
         this.apriltotal  = 0;
         this.maypost = 0;
         this.mayhaste  = 0;
         this.maymagzine = 0;
         this.maydigital = 0;
         this.maytotal  = 0;
         this.junepost = 0;
         this.junehaste  = 0;
         this.junmagzine = 0;
         this.jundigital = 0;
         this.junetotal  = 0;
         this.julypost = 0;
         this.julyhaste  = 0;
         this.julymagzine = 0;
         this.julydigital = 0;
         this.julytotal  = 0;
         this.augpost = 0;
         this.aughaste  = 0;
         this.augmagzine = 0;
         this.augdigital = 0;
         this.augtotal  = 0;
         this.seppost = 0;
         this.sephaste  = 0;
         this.sepmagzine = 0;
         this.sepdigital = 0;
         this.septotal  = 0;
         this.octopost = 0;
         this.octohaste  = 0;
         this.octmagzine = 0;
         this.octdigital = 0;
         this.octototal  = 0;
         this.novpost = 0;
         this.novhaste  = 0;
         this.novmagzine = 0;
         this.novdigital = 0;
         this.novtotal  = 0;
         this.decpost = 0;
         this.dechaste  = 0;
         this.decmagzine = 0;
         this.decdigital = 0;
         this.dectotal  = 0;
         this.janpost = 0;
         this.janhaste  = 0;
         this.janmagzine = 0;
         this.jandigital = 0;
         this.jantotal  = 0;
         this.febpost = 0;
         this.febhaste  = 0;
         this.febmagzine = 0;
         this.febdigital = 0;
         this.febtotal  = 0;
         this.marchpost = 0;
         this.marchhaste  = 0;
         this.marchmagzine = 0;
         this.marchdigital = 0;
         this.marchtotal  = 0;


          for (let data2 of this.SubByOffRep) {

            data2.aprilpost = 0;
            data2.aprilhaste  = 0;
            data2.aprilmagzine = 0;
            data2.aprildigital = 0;
            data2.apriltotal  = 0;
            data2.maypost = 0;
            data2.mayhaste  = 0;
            data2.maymagzine = 0;
            data2.maydigital = 0;
            data2.maytotal  = 0;
            data2.junepost = 0;
            data2.junehaste  = 0;
            data2.junmagzine = 0;
            data2.jundigital = 0;
            data2.junetotal  = 0;
            data2.julypost = 0;
            data2.julyhaste  = 0;
            data2.julymagzine = 0;
            data2.julydigital = 0;
            data2.julytotal  = 0;
            data2.augpost = 0;
            data2.aughaste  = 0;
            data2.augmagzine = 0;
            data2.augdigital = 0;
            data2.augtotal  = 0;
            data2.seppost = 0;
            data2.sephaste  = 0;
            data2.sepmagzine = 0;
            data2.sepdigital = 0;
            data2.septotal  = 0;
            data2.octopost = 0;
            data2.octohaste  = 0;
            data2.octmagzine = 0;
            data2.octdigital = 0;
            data2.octototal  = 0;
            data2.novpost = 0;
            data2.novhaste  = 0;
            data2.novmagzine = 0;
            data2.novdigital = 0;
            data2.novtotal  = 0;
            data2.decpost = 0;
            data2.dechaste  = 0;
            data2.decmagzine = 0;
            data2.decdigital = 0;
            data2.dectotal  = 0;
            data2.janpost = 0;
            data2.janhaste  = 0;
            data2.janmagzine = 0;
            data2.jandigital = 0;
            data2.jantotal  = 0;
            data2.febpost = 0;
            data2.febhaste  = 0;
            data2.febmagzine = 0;
            data2.febdigital = 0;
            data2.febtotal  = 0;
            data2.marchpost = 0;
            data2.marchhaste  = 0;
            data2.marchmagzine = 0;
            data2.marchdigital = 0;
            data2.marchtotal  = 0;
   
            this.totalOffRepPost1 = 0;
            this.totalOffRepHaste1 = 0;
            this.totalOffRepmagzine1 = 0;
            this.totalOffRepDigital1 = 0;
            this.totalOffReptotal1 = 0;
         
           for(let data3 of data2.districts){

            data3.aprilpost = 0;
            data3.aprilhaste  = 0;
            data3.aprilmagzine = 0;
            data3.aprildigital = 0;
            data3.apriltotal  = 0;
            data3.maypost = 0;
            data3.mayhaste  = 0;
            data3.maymagzine = 0;
            data3.maydigital = 0;
            data3.maytotal  = 0;
            data3.junepost = 0;
            data3.junehaste  = 0;
            data3.junmagzine = 0;
            data3.jundigital = 0;
            data3.junetotal  = 0;
            data3.julypost = 0;
            data3.julyhaste  = 0;
            data3.julymagzine = 0;
            data3.julydigital = 0;
            data3.julytotal  = 0;
            data3.augpost = 0;
            data3.aughaste  = 0;
            data3.augmagzine = 0;
            data3.augdigital = 0;
            data3.augtotal  = 0;
            data3.seppost = 0;
            data3.sephaste  = 0;
            data3.sepmagzine = 0;
            data3.sepdigital = 0;
            data3.septotal  = 0;
            data3.octopost = 0;
            data3.octohaste  = 0;
            data3.octmagzine = 0;
            data3.octdigital = 0;
            data3.octototal  = 0;
            data3.novpost = 0;
            data3.novhaste  = 0;
            data3.novmagzine = 0;
            data3.novdigital = 0;
            data3.novtotal  = 0;
            data3.decpost = 0;
            data3.dechaste  = 0;
            data3.decmagzine = 0;
            data3.decdigital = 0;
            data3.dectotal  = 0;
            data3.janpost = 0;
            data3.janhaste  = 0;
            data3.janmagzine = 0;
            data3.jandigital = 0;
            data3.jantotal  = 0;
            data3.febpost = 0;
            data3.febhaste  = 0;
            data3.febmagzine = 0;
            data3.febdigital = 0;
            data3.febtotal  = 0;
            data3.marchpost = 0;
            data3.marchhaste  = 0;
            data3.marchmagzine = 0;
            data3.marchdigital = 0;
            data3.marchtotal  = 0;

            this.totaloffrepsubhilhapost1 = 0;
            this.totaloffrepsubhilhahaste1 = 0;
            this.totalsubhilhamagzine1 = 0;
            this.totaloffrepsubhildigital1 = 0;
            this.totaloffrepsubhilhatotal1 = 0;
            

            for (let data1 of data3.representatives){

              data3.totaloffrepsubhilhapost1 += parseInt(data1.post_total)|| 0;
              data3.totaloffrepsubhilhahaste1 += parseInt(data1.haste_total)|| 0;
              data3.totalsubhilhamagzine1  += parseInt(data1.magzine_total)|| 0;
              data3.totaloffrepsubhildigital1 += parseInt(data1.digital_total)|| 0;
              data3.totaloffrepsubhilhatotal1 += parseInt(data1.grand_total)|| 0;

              data3.aprilpost +=parseInt(data1.april_post_count, 10) || 0;
              data3.aprilhaste +=parseInt(data1.april_haste_count, 10) || 0;
              data3.aprilmagzine  +=parseInt(data1.april_magzine_count);
              data3.aprildigital +=parseInt(data1.april_digital_count);
              data3.apriltotal +=parseInt(data1.april_total);
              data3.maypost+=parseInt(data1.may_post_count);
              data3.mayhaste +=parseInt(data1.may_haste_count);
              data3.maymagzine +=parseInt(data1.may_magzine_count);
              data3.maydigital +=parseInt(data1.may_digital_count);
              data3.maytotal +=parseInt(data1.may_total);
              data3.junepost+=parseInt(data1.jun_post_count);
              data3.junehaste +=parseInt(data1.jun_haste_count);
              data3.junmagzine +=parseInt(data1.jun_magzine_count);
              data3.jundigital +=parseInt(data1.jun_digital_count);
              data3.junetotal +=parseInt(data1.jun_total);
              data3.julypost+=parseInt(data1.jul_post_count);
              data3.julyhaste +=parseInt(data1.jul_haste_count);
              data3.julymagzine +=parseInt(data1.jul_magzine_count);
              data3.julydigital +=parseInt(data1.jul_digital_count);
              data3.julytotal +=parseInt(data1.jul_total);
              data3.augpost+=parseInt(data1.aug_post_count);
              data3.aughaste +=parseInt(data1.aug_haste_count);
              data3.augmagzine +=parseInt(data1.aug_magzine_count);
              data3.augdigital +=parseInt(data1.aug_digital_count);
              data3.augtotal +=parseInt(data1.aug_total);
              data3.seppost+=parseInt(data1.sep_post_count);
              data3.sephaste +=parseInt(data1.sep_haste_count);
              data3.sepmagzine +=parseInt(data1.sep_magzine_count);
              data3.sepdigital +=parseInt(data1.sep_digital_count);
              data3.septotal +=parseInt(data1.sep_total);
              data3.octopost+=parseInt(data1.oct_post_count);
              data3.octohaste +=parseInt(data1.oct_haste_count);
              data3.octmagzine +=parseInt(data1.oct_magzine_count);
              data3.octdigital +=parseInt(data1.oct_digital_count);
              data3.octototal +=parseInt(data1.oct_total);
              data3.novpost+=parseInt(data1.nov_post_count);
              data3.novhaste +=parseInt(data1.nov_haste_count);
              data3.novmagzine +=parseInt(data1.nov_magzine_count);
              data3.novdigital +=parseInt(data1.nov_digital_count);
              data3.novtotal +=parseInt(data1.nov_total);
              data3.decpost+=parseInt(data1.dec_post_count);
              data3.dechaste +=parseInt(data1.dec_haste_count);
              data3.decmagzine +=parseInt(data1.dec_magzine_count);
              data3.decdigital +=parseInt(data1.dec_digital_count);
             
              data3.dectotal +=parseInt(data1.dec_total);
              data3.janpost+=parseInt(data1.jan_post_count);
              data3.janhaste +=parseInt(data1.jan_haste_count);
              data3.janmagzine +=parseInt(data1.jan_magzine_count);
              data3.jandigital +=parseInt(data1.jan_digital_count);
              data3.jantotal +=parseInt(data1.jan_total);
              data3.febpost+=parseInt(data1.feb_post_count);
              data3.febhaste +=parseInt(data1.feb_haste_count);
              data3.febmagzine +=parseInt(data1.feb_magzine_count);
              data3.febdigital +=parseInt(data1.feb_digital_count);
              data3.febtotal +=parseInt(data1.feb_total);
              data3.marchpost+=parseInt(data1.mar_post_count);
              data3.marchhaste +=parseInt(data1.mar_haste_count);
              data3.marchmagzine +=parseInt(data1.mar_magzine_count);
              data3.marchdigital +=parseInt(data1.mar_digital_count);
              data3.marchtotal +=parseInt(data1.mar_total);

              this.totaloffrepsubhilhapost += parseInt(data1.post_total)|| 0;
              this.totaloffrepsubhilhahaste += parseInt(data1.haste_total)|| 0;
              this.totalsubhilhamagzine += parseInt(data1.magzine_total)|| 0;
              this.totalsubhilhadigital += parseInt(data1.digital_total)|| 0;           
              this.totaloffrepsubhilhatotal += parseInt(data1.grand_total)|| 0;
              // months
              this.aprilpost +=parseInt(data1.april_post_count, 10) || 0;
              this.aprilhaste +=parseInt(data1.april_haste_count, 10) || 0;
              this.aprilmagzine +=parseInt(data1.april_magzine_count);
              this.aprildigital +=parseInt(data1.april_digital_count);
              this.apriltotal +=parseInt(data1.april_total);
              this.maypost+=parseInt(data1.may_post_count);
              this.mayhaste +=parseInt(data1.may_haste_count);
              this.maymagzine +=parseInt(data1.may_magzine_count);
              this.maydigital +=parseInt(data1.may_digital_count);
              this.maytotal +=parseInt(data1.may_total);
              this.junepost+=parseInt(data1.jun_post_count);
              this.junehaste +=parseInt(data1.jun_haste_count);
              this.junmagzine +=parseInt(data1.jun_magzine_count);
              this.jundigital +=parseInt(data1.jun_digital_count);
              this.junetotal +=parseInt(data1.jun_total);
              this.julypost+=parseInt(data1.jul_post_count);
              this.julyhaste +=parseInt(data1.jul_haste_count);
              this.julymagzine +=parseInt(data1.jul_magzine_count);
              this.julydigital +=parseInt(data1.jul_digital_count);
              this.julytotal +=parseInt(data1.jul_total);
              this.augpost+=parseInt(data1.aug_post_count);
              this.aughaste +=parseInt(data1.aug_haste_count);
              this.augmagzine +=parseInt(data1.aug_magzine_count);
              this.augdigital +=parseInt(data1.aug_digital_count);
              this.augtotal +=parseInt(data1.aug_total);
              this.seppost+=parseInt(data1.sep_post_count);
              this.sephaste +=parseInt(data1.sep_haste_count);
              this.sepmagzine +=parseInt(data1.sep_magzine_count);
              this.sepdigital +=parseInt(data1.sep_digital_count);
              this.septotal +=parseInt(data1.sep_total);
              this.octopost+=parseInt(data1.oct_post_count);
              this.octohaste +=parseInt(data1.oct_haste_count);
              this.octmagzine +=parseInt(data1.oct_magzine_count);
              this.octdigital +=parseInt(data1.oct_digital_count);
              this.octototal +=parseInt(data1.oct_total);
              this.novpost+=parseInt(data1.nov_post_count);
              this.novhaste +=parseInt(data1.nov_haste_count);
              this.novmagzine +=parseInt(data1.nov_magzine_count);
              this.novdigital +=parseInt(data1.nov_digital_count);
              this.novtotal +=parseInt(data1.nov_total);
              this.decpost+=parseInt(data1.dec_post_count);
              this.dechaste +=parseInt(data1.dec_haste_count);
              this.decmagzine +=parseInt(data1.dec_magzine_count);
              this.decdigital +=parseInt(data1.dec_digital_count);
             
              this.dectotal +=parseInt(data1.dec_total);
              this.janpost+=parseInt(data1.jan_post_count);
              this.janhaste +=parseInt(data1.jan_haste_count);
              this.janmagzine +=parseInt(data1.jan_magzine_count);
              this.jandigital +=parseInt(data1.jan_digital_count);
              this.jantotal +=parseInt(data1.jan_total);
              this.febpost+=parseInt(data1.feb_post_count);
              this.febhaste +=parseInt(data1.feb_haste_count);
              this.febmagzine +=parseInt(data1.feb_magzine_count);
              this.febdigital +=parseInt(data1.feb_digital_count);
              this.febtotal +=parseInt(data1.feb_total);
              this.marchpost+=parseInt(data1.mar_post_count);
              this.marchhaste +=parseInt(data1.mar_haste_count);
              this.marchmagzine +=parseInt(data1.mar_magzine_count);
              this.marchdigital +=parseInt(data1.mar_digital_count);
              this.marchtotal +=parseInt(data1.mar_total);
              console.log("total", this.PaymentadvtOffInDTotal);

              //Represntative Wise Calculation 
              // this.totalOffRepPost1 += parseInt(data1.post_total)|| 0;
              // this.totalOffRepHaste1 += parseInt(data1.haste_total)|| 0;
              // this.totalOffRepmagzine1  += parseInt(data1.magzine_total)|| 0;
              // this.totalOffRepDigital1 += parseInt(data1.digital_total)|| 0;
              // this.totalOffReptotal1 += parseInt(data1.grand_total)|| 0;

              data2.totalOffRepPost1 += parseInt(data1.post_total)|| 0;
              data2.totalOffRepHaste1 += parseInt(data1.haste_total)|| 0;
              data2.totalOffRepmagzine1  += parseInt(data1.magzine_total)|| 0;
              data2.totalOffRepDigital1 += parseInt(data1.digital_total)|| 0;
              data2.totalOffReptotal1 += parseInt(data1.grand_total)|| 0;
  
              data2.aprilpost +=parseInt(data1.april_post_count, 10) || 0;
              data2.aprilhaste +=parseInt(data1.april_haste_count, 10) || 0;
              data2.aprilmagzine  +=parseInt(data1.april_magzine_count) || 0;
              data2.aprildigital +=parseInt(data1.april_digital_count) || 0;
              data2.apriltotal +=parseInt(data1.april_total) || 0;
              data2.maypost+=parseInt(data1.may_post_count);
              data2.mayhaste +=parseInt(data1.may_haste_count);
              data2.maymagzine +=parseInt(data1.may_magzine_count);
              data2.maydigital +=parseInt(data1.may_digital_count);
              data2.maytotal +=parseInt(data1.may_total);
              data2.junepost+=parseInt(data1.jun_post_count);
              data2.junehaste +=parseInt(data1.jun_haste_count);
              data2.junmagzine +=parseInt(data1.jun_magzine_count);
              data2.jundigital +=parseInt(data1.jun_digital_count);
              data2.junetotal +=parseInt(data1.jun_total);
              data2.julypost+=parseInt(data1.jul_post_count);
              data2.julyhaste +=parseInt(data1.jul_haste_count);
              data2.julymagzine +=parseInt(data1.jul_magzine_count);
              data2.julydigital +=parseInt(data1.jul_digital_count);
              data2.julytotal +=parseInt(data1.jul_total);
              data2.augpost+=parseInt(data1.aug_post_count);
              data2.aughaste +=parseInt(data1.aug_haste_count);
              data2.augmagzine +=parseInt(data1.aug_magzine_count);
              data2.augdigital +=parseInt(data1.aug_digital_count);
              data2.augtotal +=parseInt(data1.aug_total);
              data2.seppost+=parseInt(data1.sep_post_count);
              data2.sephaste +=parseInt(data1.sep_haste_count);
              data2.sepmagzine +=parseInt(data1.sep_magzine_count);
              data2.sepdigital +=parseInt(data1.sep_digital_count);
              data2.septotal +=parseInt(data1.sep_total);
              data2.octopost+=parseInt(data1.oct_post_count);
              data2.octohaste +=parseInt(data1.oct_haste_count);
              data2.octmagzine +=parseInt(data1.oct_magzine_count);
              data2.octdigital +=parseInt(data1.oct_digital_count);
              data2.octototal +=parseInt(data1.oct_total);
              data2.novpost+=parseInt(data1.nov_post_count);
              data2.novhaste +=parseInt(data1.nov_haste_count);
              data2.novmagzine +=parseInt(data1.nov_magzine_count);
              data2.novdigital +=parseInt(data1.nov_digital_count);
              data2.novtotal +=parseInt(data1.nov_total);
              data2.decpost+=parseInt(data1.dec_post_count);
              data2.dechaste +=parseInt(data1.dec_haste_count);
              data2.decmagzine +=parseInt(data1.dec_magzine_count);
              data2.decdigital +=parseInt(data1.dec_digital_count);
             
              data2.dectotal +=parseInt(data1.dec_total);
              data2.janpost+=parseInt(data1.jan_post_count);
              data2.janhaste +=parseInt(data1.jan_haste_count);
              data2.janmagzine +=parseInt(data1.jan_magzine_count);
              data2.jandigital +=parseInt(data1.jan_digital_count);
              data2.jantotal +=parseInt(data1.jan_total);
              data2.febpost+=parseInt(data1.feb_post_count);
              data2.febhaste +=parseInt(data1.feb_haste_count);
              data2.febmagzine +=parseInt(data1.feb_magzine_count);
              data2.febdigital +=parseInt(data1.feb_digital_count);
              data2.febtotal +=parseInt(data1.feb_total);
              data2.marchpost+=parseInt(data1.mar_post_count);
              data2.marchhaste +=parseInt(data1.mar_haste_count);
              data2.marchmagzine +=parseInt(data1.mar_magzine_count);
              data2.marchdigital +=parseInt(data1.mar_digital_count);
              data2.marchtotal +=parseInt(data1.mar_total);


            }
            
               } 
             }

             console.log(this.SubByOffRep)
          });
         }

        if(this.reportType=="Renewaltaluka"){
     this.service.getSubByTaluka(req).subscribe((data) => {
      this.SubByTaluka = data;
      this.districtWiseList = this.groupByDistrict(this.SubByTaluka);

      console.log(this.districtWiseList);
      // this.PaymentadvtOffInDTotal = 0;
      console.log("subbyjilha", data);
      this.totalsubhilhapost = 0;
      this.totalsubhilhahaste = 0;
      this.totalsubhilhamagzine = 0;
      this.totalsubhilhadigital = 0;
      
      this.totalsubhilhatotal = 0;

      // months
      this.aprilpost = 0;
      this.aprilhaste  = 0;
      this.aprilmagzine = 0;
      this.aprildigital = 0;
      this.apriltotal  = 0;
      this.maypost = 0;
      this.mayhaste  = 0;
      this.maymagzine = 0;
      this.maydigital = 0;
      this.maytotal  = 0;
      this.junepost = 0;
      this.junehaste  = 0;
      this.junmagzine = 0;
      this.jundigital = 0;
      this.junetotal  = 0;
      this.julypost = 0;
      this.julyhaste  = 0;
      this.julymagzine = 0;
      this.julydigital = 0;
      this.julytotal  = 0;
      this.augpost = 0;
      this.aughaste  = 0;
      this.augmagzine = 0;
      this.augdigital = 0;
      this.augtotal  = 0;
      this.seppost = 0;
      this.sephaste  = 0;
      this.sepmagzine = 0;
      this.sepdigital = 0;
      this.septotal  = 0;
      this.octopost = 0;
      this.octohaste  = 0;
      this.octmagzine = 0;
      this.octdigital = 0;
      this.octototal  = 0;
      this.novpost = 0;
      this.novhaste  = 0;
      this.novmagzine = 0;
      this.novdigital = 0;
      this.novtotal  = 0;
      this.decpost = 0;
      this.dechaste  = 0;
      this.decmagzine = 0;
      this.decdigital = 0;
      this.dectotal  = 0;
      this.janpost = 0;
      this.janhaste  = 0;
      this.janmagzine = 0;
      this.jandigital = 0;
      this.jantotal  = 0;
      this.febpost = 0;
      this.febhaste  = 0;
      this.febmagzine = 0;
      this.febdigital = 0;
      this.febtotal  = 0;
      this.marchpost = 0;
      this.marchhaste  = 0;
      this.marchmagzine = 0;
      this.marchdigital = 0;
      this.marchtotal  = 0;

//------------------- Taluka Wise Count ------------------------------------------------
      for(let data1 of this.districtWiseList){

        data1.totalzilhahapost1 = 0;
        data1.totalzilhahahaste1 = 0;
        data1.totalzilhahamagzine1 = 0;
        data1.totalzilhahadigital1 = 0;
        data1.totalzilhahatotal1 = 0;

        data1.aprilpost =0
        data1.aprilhaste=0
        data1.aprilmagzine=0
        data1.aprildigital=0
        data1.apriltotal=0
        data1.maypost=0
        data1.mayhaste=0
        data1.maymagzine=0
        data1.maydigital=0
        data1.maytotal=0
        data1.junepost=0
        data1.junehaste=0
        data1.junmagzine=0
        data1.jundigital=0
        data1.junetotal=0
        data1.julypost=0
        data1.julyhaste=0
        data1.julymagzine=0
        data1.julydigital=0
        data1.julytotal=0
        data1.augpost=0
        data1.aughaste=0
        data1.augmagzine=0
        data1.augdigital=0
        data1.augtotal=0
        data1.seppost=0
        data1.sephaste=0
        data1.sepmagzine=0
        data1.sepdigital=0
        data1.septotal=0
        data1.octopost=0
        data1.octohaste=0
        data1.octmagzine=0
        data1.octdigital=0
        data1.octototal=0
        data1.novpost=0
        data1.novhaste=0
        data1.novmagzine=0
        data1.novdigital=0
        data1.novtotal=0
        data1.decpost=0
        data1.dechaste=0
        data1.decmagzine=0
        data1.decdigital=0
        data1.dectotal=0
        data1.janpost=0
        data1.janhaste=0
        data1.janmagzine=0
        data1.jandigital=0
        data1.jantotal=0
        data1.febpost=0
        data1.febhaste=0
        data1.febmagzine=0
        data1.febdigital=0
        data1.febtotal=0
        data1.marchpost=0
        data1.marchhaste=0
        data1.marchmagzine=0
        data1.marchdigital=0
        data1.marchtotal=0

        data1.talukas.forEach(element => {
          data1.aprilpost +=parseInt(element.april_post_count)
          data1.aprilhaste +=parseInt(element.april_haste_count);
          data1.aprilmagzine +=parseInt(element.april_magzine_count);
          data1.aprildigital +=parseInt(element.april_digital_count);
          data1.apriltotal +=parseInt(element.april_total);
          data1.maypost +=parseInt(element.may_post_count);
          data1.mayhaste +=parseInt(element.may_haste_count);
          data1.maymagzine +=parseInt(element.may_magzine_count);
          data1.maydigital +=parseInt(element.may_digital_count);
          data1.maytotal +=parseInt(element.may_total);
          data1.junepost +=parseInt(element.jun_post_count);
          data1.junehaste +=parseInt(element.jun_haste_count);
          data1.junmagzine +=parseInt(element.jun_magzine_count);
          data1.jundigital +=parseInt(element.jun_digital_count);
          data1.junetotal +=parseInt(element.jun_total);
          data1.julypost +=parseInt(element.jul_post_count);
          data1.julyhaste +=parseInt(element.jul_haste_count);
          data1.julymagzine +=parseInt(element.jul_magzine_count);
          data1.julydigital +=parseInt(element.jul_digital_count);
          data1.julytotal +=parseInt(element.jul_total);
          data1.augpost +=parseInt(element.aug_post_count);
          data1.aughaste +=parseInt(element.aug_haste_count);
          data1.augmagzine +=parseInt(element.aug_magzine_count);
          data1.augdigital +=parseInt(element.aug_digital_count);
          data1.augtotal +=parseInt(element.aug_total);
          data1.seppost +=parseInt(element.sep_post_count);
          data1.sephaste +=parseInt(element.sep_haste_count);
          data1.sepmagzine +=parseInt(element.sep_magzine_count);
          data1.sepdigital +=parseInt(element.sep_digital_count);
          data1.septotal +=parseInt(element.sep_total);
          data1.octopost+=parseInt(element.oct_post_count);
          data1.octohaste +=parseInt(element.oct_haste_count);
          data1.octmagzine +=parseInt(element.oct_magzine_count);
          data1.octdigital +=parseInt(element.oct_digital_count);
          data1.octototal +=parseInt(element.oct_total);
          data1.novpost+=parseInt(element.nov_post_count);
          data1.novhaste +=parseInt(element.nov_haste_count);
          data1.novmagzine +=parseInt(element.nov_magzine_count);
          data1.novdigital +=parseInt(element.nov_digital_count);
          data1.novtotal +=parseInt(element.nov_total);
          data1.decpost+=parseInt(element.dec_post_count);
          data1.dechaste +=parseInt(element.dec_haste_count);
          data1.decmagzine +=parseInt(element.dec_magzine_count);
          data1.decdigital +=parseInt(element.dec_digital_count);
         
          data1.dectotal +=parseInt(element.dec_total);
          data1.janpost+=parseInt(element.jan_post_count);
          data1.janhaste +=parseInt(element.jan_haste_count);
          data1.janmagzine +=parseInt(element.jan_magzine_count);
          data1.jandigital +=parseInt(element.jan_digital_count);
          data1.jantotal +=parseInt(element.jan_total);
          data1.febpost+=parseInt(element.feb_post_count);
          data1.febhaste +=parseInt(element.feb_haste_count);
          data1.febmagzine +=parseInt(element.feb_magzine_count);
          data1.febdigital +=parseInt(element.feb_digital_count);
          data1.febtotal +=parseInt(element.feb_total);
          data1.marchpost+=parseInt(element.mar_post_count);
          data1.marchhaste +=parseInt(element.mar_haste_count);
          data1.marchmagzine +=parseInt(element.mar_magzine_count);
          data1.marchdigital +=parseInt(element.mar_digital_count);
          data1.marchtotal +=parseInt(element.mar_total);

          data1.totalzilhahapost1 += parseInt(element.post_total)|| 0;
          data1.totalzilhahahaste1 += parseInt(element.haste_total)|| 0;
          data1.totalzilhahamagzine1 += parseInt(element.magzine_total)|| 0;
          data1.totalzilhahadigital1 += parseInt(element.digital_total)|| 0;

          data1.totalzilhahatotal1 += parseInt(element.grand_total)|| 0;
        });
       
      }

       for (let data1 of this.SubByTaluka) {
        // pending work
         
               this.totalsubhilhapost += parseInt(data1.post_total)|| 0;
               this.totalsubhilhahaste += parseInt(data1.haste_total)|| 0;
               this.totalsubhilhamagzine += parseInt(data1.magzine_total)|| 0;
               this.totalsubhilhadigital += parseInt(data1.digital_total)|| 0;
               
               
               this.totalsubhilhatotal += parseInt(data1.grand_total)|| 0;

              //  months
              this.aprilpost +=parseInt(data1.april_post_count)
              this.aprilhaste +=parseInt(data1.april_haste_count);
              this.aprilmagzine +=parseInt(data1.april_magzine_count);
              this.aprildigital +=parseInt(data1.april_digital_count);
              this.apriltotal +=parseInt(data1.april_total);
              this.maypost+=parseInt(data1.may_post_count);
              this.mayhaste +=parseInt(data1.may_haste_count);
              this.maymagzine +=parseInt(data1.may_magzine_count);
              this.maydigital +=parseInt(data1.may_digital_count);
              this.maytotal +=parseInt(data1.may_total);
              this.junepost+=parseInt(data1.jun_post_count);
              this.junehaste +=parseInt(data1.jun_haste_count);
              this.junmagzine +=parseInt(data1.jun_magzine_count);
              this.jundigital +=parseInt(data1.jun_digital_count);
              this.junetotal +=parseInt(data1.jun_total);
              this.julypost+=parseInt(data1.jul_post_count);
              this.julyhaste +=parseInt(data1.jul_haste_count);
              this.julymagzine +=parseInt(data1.jul_magzine_count);
              this.julydigital +=parseInt(data1.jul_digital_count);
              this.julytotal +=parseInt(data1.jul_total);
              this.augpost+=parseInt(data1.aug_post_count);
              this.aughaste +=parseInt(data1.aug_haste_count);
              this.augmagzine +=parseInt(data1.aug_magzine_count);
              this.augdigital +=parseInt(data1.aug_digital_count);
              this.augtotal +=parseInt(data1.aug_total);
              this.seppost+=parseInt(data1.sep_post_count);
              this.sephaste +=parseInt(data1.sep_haste_count);
              this.sepmagzine +=parseInt(data1.sep_magzine_count);
              this.sepdigital +=parseInt(data1.sep_digital_count);
              this.septotal +=parseInt(data1.sep_total);
              this.octopost+=parseInt(data1.oct_post_count);
              this.octohaste +=parseInt(data1.oct_haste_count);
              this.octmagzine +=parseInt(data1.oct_magzine_count);
              this.octdigital +=parseInt(data1.oct_digital_count);
              this.octototal +=parseInt(data1.oct_total);
              this.novpost+=parseInt(data1.nov_post_count);
              this.novhaste +=parseInt(data1.nov_haste_count);
              this.novmagzine +=parseInt(data1.nov_magzine_count);
              this.novdigital +=parseInt(data1.nov_digital_count);
              this.novtotal +=parseInt(data1.nov_total);
              this.decpost+=parseInt(data1.dec_post_count);
              this.dechaste +=parseInt(data1.dec_haste_count);
              this.decmagzine +=parseInt(data1.dec_magzine_count);
              this.decdigital +=parseInt(data1.dec_digital_count);
             
              this.dectotal +=parseInt(data1.dec_total);
              this.janpost+=parseInt(data1.jan_post_count);
              this.janhaste +=parseInt(data1.jan_haste_count);
              this.janmagzine +=parseInt(data1.jan_magzine_count);
              this.jandigital +=parseInt(data1.jan_digital_count);
              this.jantotal +=parseInt(data1.jan_total);
              this.febpost+=parseInt(data1.feb_post_count);
              this.febhaste +=parseInt(data1.feb_haste_count);
              this.febmagzine +=parseInt(data1.feb_magzine_count);
              this.febdigital +=parseInt(data1.feb_digital_count);
              this.febtotal +=parseInt(data1.feb_total);
              this.marchpost+=parseInt(data1.mar_post_count);
              this.marchhaste +=parseInt(data1.mar_haste_count);
              this.marchmagzine +=parseInt(data1.mar_magzine_count);
              this.marchdigital +=parseInt(data1.mar_digital_count);
              this.marchtotal +=parseInt(data1.mar_total);
               console.log("total", this.PaymentadvtOffInDTotal)
              }
       });
      }

      //------------- Delivary Mode Taluka Briefly ---------------
if(this.reportType=="RenewaltalukaBriefly"){
  this.service.getSubByTalukaBriefly(req).subscribe((data) => {
  
    this.SubByTalukaBriefly = data;
    console.log("Taluka Data Briefly", data);
    

    this.total_april = 0;
    this.total_may = 0;
    this.total_jun = 0;
    this.total_jul = 0;
    this.total_aug = 0;
    this.total_sep = 0;
    this.total_oct = 0;
    this.total_nov = 0;
    this.total_dec = 0;
    this.total_jan = 0;
    this.total_feb = 0;
    this.total_mar = 0;
    this.total_grand_total = 0;


  for (let data of this.SubDelModeTalukaBriefly) {
    this.total_april   += parseInt(data.jan);
    this.total_may  += parseInt(data.feb);
    this.total_jun  += parseInt(data.mar);
    this.total_jul   += parseInt(data.april);
    this.total_aug  += parseInt(data.may);
    this.total_sep  += parseInt(data.jun);
    this.total_oct   +=parseInt(data.jul);
    this.total_nov   += parseInt(data.aug);
    this.total_dec  += parseInt(data.sep);
    this.total_jan   += parseInt(data.oct);
    this.total_feb  += parseInt(data.nov);
    this.total_mar  += parseInt(data.dec);
    this.total_grand_total   += parseInt(data.grand_total);
   
  }

    console.log("taluka renewal reming Briefly", data);
     });
}

      if(this.reportType=="SubscriptionTypeAll"){
      //  type wise
      this.service.getSubByTypewise(req1).subscribe((data) => {
        
        this.typewisedistrict = data;
        console.log(data, "sub deli details")
        // this.PaymentadvtOffInDTotal = 0;
        this.pvarshik = 0;
        this.ptrevarshik = 0;
        this.ppanchvarshik = 0;
        this.phindutva = 0;
        this.plova = 0;
        this.ptotal = 0;
        this.pvarshik = 0;
        this.htrevarshik = 0;
        this.hpanchvarshik = 0;
        this.hhindutva = 0;
        this.hlova = 0;
        this.htotal = 0;
        this.gtotal=0
        this.hvarshik=0
        for (let data1 of this.typewisedistrict) {
          this.pvarshik +=parseInt(data1.varshik_tapal_count);
          this.ptrevarshik +=parseInt(data1.trivarshik_tapal_count);
          this.ppanchvarshik +=parseInt(data1.panvarshik_tapal_count);
          this.phindutva +=parseInt(data1.hindutva_tapal_count);
          // this.plova +=data1.;
          this.ptotal +=parseInt(data1.post_total);
          this.hvarshik +=parseInt(data1.varshik_haste_count);
          this.htrevarshik +=parseInt(data1.trivarshik_haste_count);
          this.hpanchvarshik +=parseInt(data1.panvarshik_haste_count);
          this.hhindutva +=parseInt(data1.hindutva_haste_count);
          // this.hlova +=data1.;
          this.htotal +=parseInt(data1.haste_total);
          this.gtotal += parseInt(data1.grand_total);

        }
        // months
      
         });
        }

      if(this.reportType=="SubscriptionTypeTaluka"){
         this.service.getSubByTalukawise(req1).subscribe((data) => {
          this.typewisetaluka = data;
          console.log(data, "sub taluka details")
          // this.PaymentadvtOffInDTotal = 0;
          this.pvarshik = 0;
          this.ptrevarshik = 0;
          this.ppanchvarshik = 0;
          this.phindutva = 0;
          this.plova = 0;
          this.ptotal = 0;
          this.pvarshik = 0;
          this.htrevarshik = 0;
          this.hpanchvarshik = 0;
          this.hhindutva = 0;
          this.hlova = 0;
          this.htotal = 0;
          this.gtotal=0
          this.hvarshik=0
          // months
          for (let data1 of this.typewisetaluka) {
            this.pvarshik +=parseInt(data1.varshik_tapal_count);
            this.ptrevarshik +=parseInt(data1.trivarshik_tapal_count);
            this.ppanchvarshik +=parseInt(data1.panvarshik_tapal_count);
            this.phindutva +=parseInt(data1.hindutva_tapal_count);
            // this.plova +=data1.;
            this.ptotal +=parseInt(data1.post_total);
            this.hvarshik +=parseInt(data1.varshik_haste_count);
            this.htrevarshik +=parseInt(data1.trivarshik_haste_count);
            this.hpanchvarshik +=parseInt(data1.panvarshik_haste_count);
            this.hhindutva +=parseInt(data1.hindutva_haste_count);
            // this.hlova +=data1.;
            this.htotal +=parseInt(data1.haste_total);
            this.gtotal += parseInt(data1.grand_total);
  
          }
        
           });
          }

      if(this.reportType=="SubscriptionTypeOffReprensantive"){
           this.service.getSubByoffcierwise(req1).subscribe((data) => {
            this.typewiseofficer = data;
            console.log(data, "sub off details")
            // this.PaymentadvtOffInDTotal = 0;
            this.pvarshik = 0;
            this.ptrevarshik = 0;
            this.ppanchvarshik = 0;
            this.phindutva = 0;
            this.plova = 0;
            this.ptotal = 0;
            this.pvarshik = 0;
            this.htrevarshik = 0;
            this.hpanchvarshik = 0;
            this.hhindutva = 0;
            this.hlova = 0;
            this.htotal = 0;
            this.gtotal=0
            this.hvarshik=0
            // months
            for (let data2 of this.typewiseofficer) {

              for(let data1 of data2.rep_data){
                this.pvarshik +=parseInt(data1.varshik_tapal_count);
                this.ptrevarshik +=parseInt(data1.trivarshik_tapal_count);
                this.ppanchvarshik +=parseInt(data1.panvarshik_tapal_count);
                this.phindutva +=parseInt(data1.hindutva_tapal_count);
                // this.plova +=data1.;
                this.ptotal +=parseInt(data1.post_total);
                this.hvarshik +=parseInt(data1.varshik_haste_count);
                this.htrevarshik +=parseInt(data1.trivarshik_haste_count);
                this.hpanchvarshik +=parseInt(data1.panvarshik_haste_count);
                this.hhindutva +=parseInt(data1.hindutva_haste_count);
                // this.hlova +=data1.;
                this.htotal +=parseInt(data1.haste_total);
                this.gtotal += parseInt(data1.grand_total);
              }
             
    
            }
          
             });
            }

      if(this.reportType=="SubscriptionTypeReprensantive"){
             this.service.getSubByReprwise(req1).subscribe((data) => {
              this.typewiserep = data;
              console.log(data, "sub rep details")
              // this.PaymentadvtOffInDTotal = 0;
              this.pvarshik = 0;
              this.ptrevarshik = 0;
              this.ppanchvarshik = 0;
              this.phindutva = 0;
              this.plova = 0;
              this.ptotal = 0;
              this.pvarshik = 0;
              this.htrevarshik = 0;
              this.hpanchvarshik = 0;
              this.hhindutva = 0;
              this.hlova = 0;
              this.htotal = 0;
              this.gtotal=0
              this.hvarshik=0
              // months
              for (let data2 of this.typewiserep) {
  
                for(let data1 of data2.rep_data){
                  this.pvarshik +=parseInt(data1.varshik_tapal_count);
                  this.ptrevarshik +=parseInt(data1.trivarshik_tapal_count);
                  this.ppanchvarshik +=parseInt(data1.panvarshik_tapal_count);
                  this.phindutva +=parseInt(data1.hindutva_tapal_count);
                  // this.plova +=data1.;
                  this.ptotal +=parseInt(data1.post_total);
                  this.hvarshik +=parseInt(data1.varshik_haste_count);
                  this.htrevarshik +=parseInt(data1.trivarshik_haste_count);
                  this.hpanchvarshik +=parseInt(data1.panvarshik_haste_count);
                  this.hhindutva +=parseInt(data1.hindutva_haste_count);
                  // this.hlova +=data1.;
                  this.htotal +=parseInt(data1.haste_total);
                  this.gtotal += parseInt(data1.grand_total);
                }
               
      
              }
            
               });
              }

      // if(this.reportType=="PendingAll"){
      //           this.service.getSubByReprwise(req3).subscribe((data) => {
      //            this.typewiserep = data;
      //            console.log(data, "sub rep details")
               
      //             });
      //            }

      

      
}

filters(){

let repname:any=this.repName
if(repname!=""){
  repname=[this.repName]
}
  const req:any = {
    "rep_name":repname,
    "start_date":this.startDate,
    "end_date":this.endDate,
    "off_rep_name":this.offRepName,
    "advt_name":"",
    "issue_name" : this.advtName,
    "issue_date":this.issueDates
  }
  const req1:any = {
   
    "start_date":this.startDate,
    "end_date":this.endDate,
    "advt_name" : this.advtName
  }
  // persoanl ledger
  this.service.getPersoanlLed(req1).subscribe((data: any) => {
    console.log("Get ledgerdata",data)
      this.getpersonalleddata = data as any;  
      this.balancecfamt =this.getpersonalleddata[0].opening_bal;

      for(let data1 of this.getpersonalleddata[0].rep_data){
       
          if(data1.net_amt!= null && data1.net_amt!= "" ){
            this.balancecfamt=parseFloat(data1.net_amt)+parseFloat(this.balancecfamt)
            data1.rem_amount=this.balancecfamt
            data1.act_amt=null
            
           }else{
           
            data1.act_amt=parseFloat(this.balancecfamt)-parseFloat(data1.actual_arc_amt)
            if(data1.credit_amt!= null && data1.credit_amt!= "" ){
              data1.rem_amount=parseFloat(data1.act_amt)-parseFloat(data1.credit_amt)
              this.balancecfamt=data1.rem_amount
            }else{
              this.balancecfamt=data1.act_amt
            }
            
             
           }
        // }else{
        //   data1.rem_amount=0
        // }
    
      }
  });
  

  this.service.getPaymentRegiInDetails1(req).subscribe((data) => {
    
   this.advtPaymentRegiInDetailsFormBeans = data;
   console.log( this.advtPaymentRegiInDetailsFormBeans , "off rep check")
   this.PaymentadvtOffInDTotal = 0;
   this.PaymentadvtOffInDTotal_netamt = 0
    
    for (let data1 of this.advtPaymentRegiInDetailsFormBeans) {
      
            this.PaymentadvtOffInDTotal += parseFloat(data1.rcv_amt) || 0;
            this.PaymentadvtOffInDTotal_netamt += parseFloat(data1.net_amt) || 0;

       
           }
    });

    // getoffBriefly
  this.service.getAdvtOfficerBriefly1(req).subscribe((data) => {
    this.advtOfficierbrieflyFormBeans = data;
    // this.OutStandingIDTotal = 0;
      // this.OutStandingIDNetAmtTotal = 0;
      // this.OutStandingIDUnPaidTotal = 0;
      // for (let data of this.advtOfficierbrieflyFormBeans) {
      //   for(let data1 of data.rep_data){
      //     this.OutStandingIDTotal += parseInt(data1.advt_amt_total) || 0;
      //     this.OutStandingIDNetAmtTotal += parseInt(data1.net_amt_total) || 0;
      //     this.OutStandingIDUnPaidTotal += parseInt(data1.unpaid_amt_total) || 0;
      //   } 
      // }
      this.advtOfficierbrieflyTotal = 0;

      // this.advtOfficierbrieflyTotal = 0;
      if(this.advtOfficierbrieflyFormBeans != null){  
   for (let data of this.advtOfficierbrieflyFormBeans) {
           for (let data1 of data.rep_data) {
               this.advtOfficierbrieflyTotal += parseInt(data1.rcv_amt) || 0;
              
           }
       }
     }
  });
  
 
// in officer vb
      this.service.getAdvtOfficerVb(req).subscribe((data:any) => {
        console.log("ADVT Data=",data);
      this.advtFormBeans = (data as any);;

      // Reset totals
      this.advtVBTotal = 0;

      // Calculate totals
      for (let repData of this.advtFormBeans) {
          for (let data1 of repData.rep_data) {
              this.advtVBTotal += parseInt(data1.rcv_amt) || 0;
          }
      }
      });

  // officer in detail
  this.service.getPaymentRegiOffInDetails(req).subscribe((data: any) => {
    console.log("new",data)
      this.PaymentadvtOffInDFormBenas = data as any;
      console.log(this.PaymentadvtOffInDFormBenas, "newlist 3 loop" )
      // Reset totals
      // this.PaymentadvtOffInDTotal = 0;
      this.PaymentadvtOffInDTotal2 = 0;
      this.PaymentadvtOffInDTotal3 = 0;



      // Calculate totals
      for (let repData of this.PaymentadvtOffInDFormBenas) {
        this.PaymentadvtOffInDTotal2 += parseInt(repData.total) || 0;

          for (let data1 of repData.rep_data) {
              // this.PaymentadvtOffInDTotal += parseInt(data1.rcv_amt) || 0;
              // // this.PaymentadvtOffInDTotal2 += parseInt(repdata.total) || 0;
              // this.PaymentadvtOffInDTotal3 += parseInt(data1.rcv_amt) || 0;
          }
      }
      console.log("offcr total",this.PaymentadvtOffInDTotal)
  });
    // payment briefly
    this.service.getAdvtBriefly(req).subscribe((data:any) => {
      console.log("ADVT Brielfy Data=",data);
     this.advtBrieflyFormBeans = (data as any);;

     this.advtBrieflyTotal = 0;
     for (let repData of this.advtBrieflyFormBeans) {
           this.advtBrieflyTotal += parseInt(repData.rcv_amt) || 0;   
       
   }
   });
  //  payment monthwise
  this.service.getAdvtMonthwise(req).subscribe((data:any) => {
    console.log("ADVT Data=",data);
   this.advtMonthwiseFormBeans = (data as any);;
   // Reset totals
   this.advtMonthwiseTotal = 0;

   // Calculate totals
   for (let data of this.advtMonthwiseFormBeans) {
       
           this.advtMonthwiseTotal += parseFloat(data.rcv_amt) || 0;
          }
 });

   // GST Report
        this.service.GSTFullReport(req).subscribe((data:any) => {
          console.log("GST",data);
        this.GSTfullreportFormBeans = (data as any);;
       
        this.GSTAdvtAmt=0;
        this.GSTDiscount=0;
        this.GSTNetAdvtAmt=0;
        this.CGSTAmount=0;
        this.SGSTAmount=0;
        this.IGSTAmount=0;
        this.AllGSTAmount=0;
        this.beforegstamt =0

        // Calculate totals
        for (let data of this.GSTfullreportFormBeans) {
            
                // this.advtMonthwiseTotal += parseInt(data.total_amt) || 0;
                  // this.advtMonthwiseTotal += parseInt(data.total_amt) || 0;
                  this.GSTAdvtAmt +=parseInt(data.adv_amt) || 0;
                  this.GSTDiscount +=parseInt(data.advt_discount) || 0;
                  this.GSTNetAdvtAmt +=parseInt(data.net_amt) || 0;
                  this.CGSTAmount +=parseInt(data.cgst) || 0;
                  this.SGSTAmount +=parseInt(data.sgst) || 0;
                  this.IGSTAmount +=parseInt(data.igst) || 0;
                  this.AllGSTAmount +=parseInt(data.gst_amount) || 0;
                  this.beforegstamt +=parseInt(data.before_gst_amt) || 0;
                        }
        });

        this.service.getAdvtOutstanding(req).subscribe((data: any) => {
              console.log("outstanding 3",data)
                this.OutstandingInDetailFormBeans = data as any;
                
                this.OutStandingIDTotal = 0;
                this.OutStandingIDNetAmtTotal = 0;
                this.OutStandingIDUnPaidTotal = 0;
                
                for (let data of this.OutstandingInDetailFormBeans) {

                  
                  for(let data1 of data.rep_data){
                    
                    this.OutStandingIDTotal += parseInt(data1.advt_amt_total) || 0;
                    this.OutStandingIDNetAmtTotal += parseInt(data1.net_amt_total) || 0;
                    this.OutStandingIDUnPaidTotal += parseInt(data1.unpaid_amt_total) || 0;
                  } 
                }

                console.log("this.OutStandingIDTotal",this.OutStandingIDTotal);
                console.log("this.OutStandingIDNetAmtTotal",this.OutStandingIDNetAmtTotal);
                console.log("this.OutStandingIDUnPaidTotal",this.OutStandingIDUnPaidTotal);
            });
            
this.service.getOutstandingB(req).subscribe((data: any) => {
              this.OutstandingB = data as any;
      
      // Reset overall totals
      this.totalAdvtAmount = 0;
      this.totalNetAmount = 0;
      this.totalUnpaidAmount = 0;
      
      // Calculate totals for each representative
      this.OutstandingB.forEach(repData => {
          let advTotal = 0;
          let netTotal = 0;
          let balTotal = 0;
      
          repData.rep_data.forEach(data1 => {
              advTotal += parseFloat(data1.contract_bkng_advt_amt) || 0;
              netTotal += parseFloat(data1.contract_net_total) || 0;
              balTotal += parseFloat(data1.contract_bal_amt) || 0;
          });
      
          // Assign computed totals to each representative
          repData.adv_total = advTotal;
          repData.net_total = netTotal;
          repData.bal_total = balTotal;
      
          // Add to overall totals
          this.totalAdvtAmount += advTotal;
          this.totalNetAmount += netTotal;
          this.totalUnpaidAmount += balTotal;
      });
      
          });

    // Paid Commission 
    this.service.getPaidCommData(req).subscribe((data: any) => {
      console.log("Get Paid Comm",data)
        this.getpaiddata = data as any; 

        this.paidcommTotal=0;
        for(let data1 of this.getpaiddata){
          for(let data2 of data1.rep_data){
            this.paidcommTotal += parseInt(data2.rcv_amt) || 0;
          }
        }
        
    });

     // persoanl ledger indeatils 
    

     // persoanl briefly ledger indeatils 
     this.service.getPersoanlLedBriefly(req).subscribe((data: any) => {
      console.log("Get ledgerdata brifly",data)
        this.getpersonalledBridflydata = data as any;  
        this.Totalop = 0;
        this.TotalDebit= 0;
        this.TotalCredit = 0;
        this.Totalbal=0;

        for(let data of this.getpersonalledBridflydata ){
          this.Totalop += parseInt(data.opening_bal) ||0;
          this.TotalDebit += parseInt(data.net_amt) ||0;
          this.TotalCredit += parseInt(data.credit_amt) ||0;
          this.Totalbal += parseInt(data.balance) ||0;
          data.totalbalance = parseFloat(data.bal_amt) +  parseFloat(data.opening_bal)

        }
    });

// creditoutstanidngindetails
this.service.creditoutstaqndingindetails(req).subscribe((data: any) => {
  console.log("credit outstanding ",data)
    this.creditoutstandingindetails = data as any;
    
    // this.creditonlyOutStandingIDTotal = 0;
    // this.creditonlyOutStandingIDNetAmtTotal = 0;
    // this.creditonlyOutStandingIDUnPaidTotal = 0;
    for (let data of this.creditoutstandingindetails) {
      for(let data1 of data.rep_data){
        this.creditonlyOutStandingIDTotal += parseInt(data1.advt_amt_total) || 0;
        this.creditonlyOutStandingIDNetAmtTotal += parseInt(data1.net_amt_total) || 0;
        this.creditonlyOutStandingIDUnPaidTotal += parseInt(data1.unpaid_amt_total) || 0;
      } 
    }
});



    // only outstanidng
    this.service.getonlyOutstandingindetail(req).subscribe((data: any) => {
      console.log("outstanding 3",data)
        this.onlyOutstandingInDetailFormBeans = data as any;
        
        this.onlyOutStandingIDTotal = 0;
        this.onlyOutStandingIDNetAmtTotal = 0;
        this.onlyOutStandingIDUnPaidTotal = 0;
        for (let data of this.onlyOutstandingInDetailFormBeans) {
          for(let data1 of data.rep_data){
            this.onlyOutStandingIDTotal += parseInt(data1.advt_amt_total) || 0;
            this.onlyOutStandingIDNetAmtTotal += parseInt(data1.net_amt_total) || 0;
            this.onlyOutStandingIDUnPaidTotal += parseInt(data1.unpaid_amt_total) || 0;
          } 
        }
    });
    // only outstanding briefly


    this.service.getonlyOutstandingB(req).subscribe((data: any) => {
      this.onlyOutstandingB = data as any;

      // Reset totals
      this.onlytotalAdvtAmount = 0;
      this.onlytotalNetAmount = 0;
      this.onlytotalUnpaidAmount = 0;

      // Calculate totals
      for (let repData of this.onlyOutstandingB) {
          for (let data1 of repData.rep_data) {
              this.onlytotalAdvtAmount += parseInt(data1.contract_bkng_advt_amt) || 0;
              this.onlytotalNetAmount += parseInt(data1.contract_net_total) || 0;
              this.onlytotalUnpaidAmount += parseInt(data1.contract_bal_amt) || 0;
          }
      }
  });

  // credit briefly outstanidng
  this.service.getcreditbrieflyoutstanding(req).subscribe((data: any) => {
    this.creditoutstandongbrief = data as any;

    // Reset totals
    this.creditlastonlytotalAdvtAmount = 0;
    this.creditlastonlytotalNetAmount = 0;
    this.creditlastonlytotalUnpaidAmount = 0;

    // Calculate totals
    for (let repData of this.creditoutstandongbrief) {
        for (let data1 of repData.rep_data) {
            this.creditlastonlytotalAdvtAmount += parseInt(data1.contract_bkng_advt_amt) || 0;
            this.creditlastonlytotalNetAmount += parseInt(data1.contract_net_total) || 0;
            this.creditlastonlytotalUnpaidAmount += parseInt(data1.contract_bal_amt) || 0;
        }
    }
});

  // pendingreceipt one
  this.service.getpendingreceipt(req).subscribe((data: any) => {
    this.getpendingreceipt = data as any;
    console.log("pending receipt",data)


    // Reset totals
    this.PendingReceiptAmt = 0;
    this.PendingBalanceAmt = 0;
    // this.onlytotalUnpaidAmount = 0;

    // Calculate totals
    for (let data1 of this.getpendingreceipt) {
            this.PendingReceiptAmt += parseInt(data1.advt_amt) || 0;
            this.PendingBalanceAmt += parseInt(data1.remain_amt) || 0;
            // this.onlytotalUnpaidAmount += parseInt(data1.contract_bal_amt) || 0;
       
    }
});

this.service.getpendingreceiptRep(req).subscribe((data: any) => {
  this.getpendingreceiptrep = data as any;
  console.log("pending receipt",data)


  // Reset totals
  this.PendingReceiptAmt = 0;
  this.PendingBalanceAmt = 0;
  // this.onlytotalUnpaidAmount = 0;

  // Calculate totals
  for (let data1 of this.getpendingreceiptrep) {
          this.PendingReceiptAmt += parseInt(data1.advt_amt) || 0;
          this.PendingBalanceAmt += parseInt(data1.remain_amt) || 0;
          // this.onlytotalUnpaidAmount += parseInt(data1.contract_bal_amt) || 0;
     
  }
});


}


// Function to group data by districts
groupByDistrict1(data: any[]): any[] {
  const grouped = new Map();

  data.forEach(item => {
    if (!grouped.has(item.state)) {
      grouped.set(item.state, []);
    }
    grouped.get(item.state).push(item);
  });

  // Convert Map to an array of objects for easier iteration
  let data1= Array.from(grouped.entries()).map(([state, district]) => ({
    state,
    district,
  }));
  return data1;
}



// Function to group data by districts
groupByDistrict(data: any[]): any[] {
  const grouped = new Map();

  data.forEach(item => {
    if (!grouped.has(item.district)) {
      grouped.set(item.district, []);
    }
    grouped.get(item.district).push(item);
  });

  // Convert Map to an array of objects for easier iteration
  let data1= Array.from(grouped.entries()).map(([district, talukas]) => ({
    district,
    talukas,
  }));
  return data1;
}

groupByOfficeReprentative(data: any[]): any[] {
  const grouped = new Map();

  data.forEach(item => {
    if (!grouped.has(item.off_rep_name)) {
      grouped.set(item.off_rep_name, []);
    }
    grouped.get(item.off_rep_name).push(item);
  });

  // Convert Map to an array of objects for easier iteration
  let data1= Array.from(grouped.entries()).map(([off_rep_name, rep_data]) => ({
    off_rep_name,
    rep_data,
  }));
  return data1;
}

groupByDistrictAndRepresentative(data) {
  const grouped = new Map();

  data.forEach(item => {
    // Initialize district if not present
    if (!grouped.has(item.district)) {
      grouped.set(item.district, new Map());
    }
    const districtGroup = grouped.get(item.district);

    // Initialize representative if not present
    if (!districtGroup.has(item.rep_name)) {
      districtGroup.set(item.rep_name, []);
    }
    districtGroup.get(item.rep_name).push(item);
  });

  // Convert the Map structure into an array of objects
  return Array.from(grouped.entries()).map(([district, representatives]) => ({
    district,
    representatives: Array.from(representatives.entries()).map(([rep_name, data]) => ({
      rep_name,
      data,
    })),
  }));
}

// Function to group data by Prantwise List
groupByPrantDistrict(data: any[]): any[] {
  const grouped = new Map();

  data.forEach(item => {
    if (!grouped.has(item.prant_name)) {
      grouped.set(item.prant_name, []);
    }
    grouped.get(item.prant_name).push(item);
  });

  // Convert Map to an array of objects for easier iteration
  let data1= Array.from(grouped.entries()).map(([prant_name, sub_data]) => ({
    prant_name,
    sub_data,
  }));
  return data1;
}


  calculatetotalweeks() {
    for (let index = 0; index < 12; index++) {
      if (index < 3) {
        this.totalweeks += this.sundays(
          formatDate(this.nexttoday, 'yyyy', 'en-IN', 'GMT+0530'),
          index
        );
      } else {
        this.totalweeks += this.sundays(
          formatDate(this.today, 'yyyy', 'en-IN', 'GMT+0530'),
          index
        );
      }
    }
    // console.log(this.totalweeks);
  }

  firstSunday(month, year) {
    let tempDate = new Date();
    tempDate.setHours(0, 0, 0, 0);
    // first SUNDAY of april
    tempDate.setMonth(month);
    tempDate.setFullYear(year);
    tempDate.setDate(1);

    let day = tempDate.getDay();
    let toNextSun = day !== 0 ? 7 - day : 0;
    tempDate.setDate(tempDate.getDate() + toNextSun);

    // return formatDate(tempDate, 'dd/MM/yy', 'en-IN', 'GMT+0530');
    return tempDate;
  }

  secondSunday(month, year) {
    // console.log(
    //   'this.firstSunday(month, year)',
    //   formatDate(
    //     this.firstSunday(month, year),
    //     'dd-MM-yyyy',
    //     'en-IN',
    //     'GMT+0530'
    //   )
    // );
    let tempDate = new Date(this.firstSunday(month, year));
    // console.log('tempdate', tempDate);

    tempDate.setHours(0, 0, 0, 0);
    // first SUNDAY of april
    // tempDate.setMonth(month);
    // tempDate.setFullYear(year);
    tempDate.setDate(tempDate.getDate() + 7);

    let day = tempDate.getDay();
    // console.log('day', day);

    let toNextSun = day !== 0 ? 7 - day : 0;
    // console.log('toNextSun', toNextSun);
    tempDate.setDate(tempDate.getDate() + toNextSun);

    return tempDate;
  }

  thirdSunday(month, year) {
    // console.log(
    //   'this.secondSunday(month, year)',
    //   formatDate(
    //     this.secondSunday(month, year),
    //     'dd-MM-yyyy',
    //     'en-IN',
    //     'GMT+0530'
    //   )
    // );
    let tempDate = new Date(this.secondSunday(month, year));
    // console.log('tempdate', tempDate);
    tempDate.setHours(0, 0, 0, 0);
    // first SUNDAY of april
    // tempDate.setMonth(month);
    // tempDate.setFullYear(year);
    tempDate.setDate(tempDate.getDate() + 7);

    let day = tempDate.getDay();
    // console.log('thirdsunday', day);

    let toNextSun = day !== 0 ? 7 - day : 0;
    // console.log('toNextSun', toNextSun);
    tempDate.setDate(tempDate.getDate() + toNextSun);

    return tempDate;
  }

  fourthSunday(month, year) {
    let tempDate = new Date(this.thirdSunday(month, year));
    tempDate.setHours(0, 0, 0, 0);
    // first SUNDAY of april
    // tempDate.setMonth(month);
    // tempDate.setFullYear(year);
    // console.log('tempDate', tempDate);
    tempDate.setDate(tempDate.getDate() + 7);
    let day = tempDate.getDay();
    // console.log('thirdsunday', day);
    let toNextSun = day !== 0 ? 7 - day : 0;
    // console.log('toNextSun', toNextSun);
    tempDate.setDate(tempDate.getDate() + toNextSun);

    return tempDate;
  }

  fifthSunday(month, year) {
    let tempDate = new Date(this.fourthSunday(month, year));
    tempDate.setHours(0, 0, 0, 0);
    // first SUNDAY of april
    // tempDate.setMonth(month);
    // tempDate.setFullYear(year);
    // console.log('tempDate', tempDate);
    tempDate.setDate(tempDate.getDate() + 7);

    let day = tempDate.getDay();
    // console.log('thirdsunday', day);
    let toNextSun = day !== 0 ? 7 - day : 0;
    // console.log('toNextSun', toNextSun);
    tempDate.setDate(tempDate.getDate() + toNextSun);

    return tempDate;
  }

  sundays(year, month) {
    let day = 1;
    let counter = 0;
    let date = new Date(year, month, day);
    while (date.getMonth() === month) {
      if (date.getDay() === 6) {
        counter++;
      }
      day++;
      date = new Date(year, month, day);
    }
    return counter;
  }

  filterData() {
    if (
      this.startDate === '' &&
      this.endDate === '' &&
      this.repName === '' &&
      this.offRepName === '' &&
      this.advtName === ''
    ) {
      this.filteredcontractData = this.contractData;
    } else if (
      this.startDate !== '' &&
      this.endDate !== '' &&
      this.repName == '' &&
      this.offRepName == '' &&
      this.advtName == ''
    ) {
      this.filteredcontractData = this.contractData.filter((a) => {
        return (
          a['contract_date'] >= this.startDate &&
          a['contract_date'] <= this.endDate
        );
      });
    } else if (
      this.startDate == '' &&
      this.endDate == '' &&
      this.repName !== '' &&
      this.offRepName == '' &&
      this.advtName == ''
    ) {
      this.filteredcontractData = this.contractData.filter((a) => {
        return this.repName.includes(a['rep_name']);
      });
    } else if (
      this.startDate == '' &&
      this.endDate == '' &&
      this.repName == '' &&
      this.offRepName !== '' &&
      this.advtName == ''
    ) {
      this.filteredcontractData = this.contractData.filter((a) => {
        return this.offRepName.includes(a['off_rep_name']);
      });
    } else if (
      this.startDate == '' &&
      this.endDate == '' &&
      this.repName == '' &&
      this.offRepName == '' &&
      this.advtName !== ''
    ) {
      this.filteredcontractData = this.contractData.filter((a) => {
        return this.advtName.includes(a['advt_name']);
      });
    } else if (
      this.startDate !== '' &&
      this.endDate !== '' &&
      this.repName !== '' &&
      this.offRepName == '' &&
      this.advtName == ''
    ) {
      this.filteredcontractData = this.contractData.filter((a) => {
        return (
          a['contract_date'] >= this.startDate &&
          a['contract_date'] <= this.endDate &&
          this.repName.includes(a['rep_name'])
        );
      });
    } else if (
      this.startDate !== '' &&
      this.endDate !== '' &&
      this.repName == '' &&
      this.offRepName !== '' &&
      this.advtName == ''
    ) {
      this.filteredcontractData = this.contractData.filter((a) => {
        return (
          a['contract_date'] >= this.startDate &&
          a['contract_date'] <= this.endDate &&
          this.offRepName.includes(a['off_rep_name'])
        );
      });
    } else if (
      this.startDate !== '' &&
      this.endDate !== '' &&
      this.repName == '' &&
      this.offRepName == '' &&
      this.advtName !== ''
    ) {
      this.filteredcontractData = this.contractData.filter((a) => {
        return (
          a['contract_date'] >= this.startDate &&
          a['contract_date'] <= this.endDate &&
          this.advtName.includes(a['advt_name'])
        );
      });
    } else if (
      this.startDate == '' &&
      this.endDate == '' &&
      this.repName !== '' &&
      this.offRepName !== '' &&
      this.advtName == ''
    ) {
      this.filteredcontractData = this.contractData.filter((a) => {
        return (
          this.repName.includes(a['rep_name']) &&
          this.offRepName.includes(a['off_rep_name'])
        );
      });
    } else if (
      this.startDate == '' &&
      this.endDate == '' &&
      this.repName !== '' &&
      this.offRepName == '' &&
      this.advtName !== ''
    ) {
      this.filteredcontractData = this.contractData.filter((a) => {
        return (
          this.repName.includes(a['rep_name']) &&
          this.advtName.includes(a['advt_name'])
        );
      });
    } else if (
      this.startDate == '' &&
      this.endDate == '' &&
      this.repName == '' &&
      this.offRepName !== '' &&
      this.advtName !== ''
    ) {
      this.filteredcontractData = this.contractData.filter((a) => {
        return (
          this.offRepName.includes(a['off_rep_name']) &&
          this.advtName.includes(a['advt_name'])
        );
      });
    } else if (
      this.startDate !== '' &&
      this.endDate !== '' &&
      this.repName !== '' &&
      this.offRepName !== '' &&
      this.advtName == ''
    ) {
      this.filteredcontractData = this.contractData.filter((a) => {
        return (
          a['contract_date'] >= this.startDate &&
          a['contract_date'] <= this.endDate &&
          this.repName.includes(a['rep_name']) &&
          this.offRepName.includes(a['off_rep_name'])
        );
      });
    } else if (
      this.startDate !== '' &&
      this.endDate !== '' &&
      this.repName !== '' &&
      this.offRepName == '' &&
      this.advtName !== ''
    ) {
      this.filteredcontractData = this.contractData.filter((a) => {
        return (
          a['contract_date'] >= this.startDate &&
          a['contract_date'] <= this.endDate &&
          this.repName.includes(a['rep_name']) &&
          this.advtName.includes(a['advt_name'])
        );
      });
    } else if (
      this.startDate == '' &&
      this.endDate == '' &&
      this.repName !== '' &&
      this.offRepName !== '' &&
      this.advtName !== ''
    ) {
      this.filteredcontractData = this.contractData.filter((a) => {
        return (
          this.repName.includes(a['rep_name']) &&
          this.offRepName.includes(a['off_rep_name']) &&
          this.advtName.includes(a['advt_name'])
        );
      });
    } else if (
      this.startDate !== '' &&
      this.endDate !== '' &&
      this.repName !== '' &&
      this.offRepName !== '' &&
      this.advtName !== ''
    )
     {
      this.filteredcontractData = this.contractData.filter((a) => {
        return (
          
          a['contract_date'] >= this.datePipe.transform(this.startDate,'yyyy-MM-dd') &&
          a['contract_date'] <= this.datePipe.transform(this.endDate,'yyyy-MM-dd') &&
          this.repName.includes(a['rep_name']) &&
          this.offRepName.includes(a['off_rep_name']) &&
          this.advtName.includes(a['advt_name'])
        );
      });
    }
    this.calculateTotal();
    // console.log(this.filteredcontractData);
  }

  calculateTotal() {
    for (
      let index = 0;
      index < Object.keys(this.filteredcontractData).length;
      index++
    ) {
      this.totalData['contract_prev_bal'] += parseFloat(
        this.filteredcontractData[index]['contract_prev_bal']
      );
      this.totalData['contract_amt'] += parseFloat(
        this.filteredcontractData[index]['contract_amt']
      );
      this.totalData['contract_discnt_amt'] += parseFloat(
        this.filteredcontractData[index]['contract_discnt_amt']
      );
      this.totalData['contract_net_amt'] += parseFloat(
        this.filteredcontractData[index]['contract_net_amt']
      );
      this.totalData['contract_net_gst_amt'] += parseFloat(
        this.filteredcontractData[index]['contract_net_gst_amt']
      );
      this.totalData['contract_net_total'] += parseFloat(
        this.filteredcontractData[index]['contract_net_total']
      );
    }
  }

  exportExcel(fileName): void {
    /* table id is passed over here */
    let element = document.getElementById('print-section1');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, fileName + '.xlsx');
  }

  //For Remaining Subscription Renewal District Report 
  exportExcel1(fileName): void {
    /* table id is passed over here */
    let element = document.getElementById('print-section2');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, fileName + '.xlsx');
  }

  //Main Report Method Use in all Printing Page 
  printPage() {
    const printContent = document.getElementById('print-section1')?.outerHTML;
    if (!printContent) {
        console.error("Print section not found!");
        return;
    }

    const printWindow = window.open('', '', 'width=1400,height=1000,top=50,left=50,scrollbars=yes');

    if (printWindow) {
        printWindow.document.open();
        printWindow.document.write(`
            <html>
                <head>
                    <title>Print Report</title>
                    <style>
                        @media print {
                            @page { 
                                size: A3 landscape; /* Set A3 size with landscape mode */
                                margin: 5mm; /* Adjust margins */
                            }
                            body { 
                                font-family: Arial, sans-serif; 
                                font-size: 15px; /* Adjust font size */
                                margin: 0; 
                                padding: 5mm; 
                                line-height: 1.2; 
                            }
                            table { 
                                width: 100%; 
                                height: 100%;
                                border-collapse: collapse; 
                                table-layout: fixed; /* Prevent column expansion */
                                page-break-inside: avoid; /* Avoid breaking the table */
                            }
                            th, td { 
                                padding: 1px; 
                                border: 1px solid black; 
                                text-align: center; 
                                font-size: 7px; /* Adjusted font size */
                                line-height: 1.2; 
                            }
                            .noPrint { 
                                display: none; /* Hide print button */
                            }
                        }
                    </style>
                </head>
                <body>
                    <div id="print-section1">
                        ${printContent}
                    </div>
                    <script>
                        window.onload = function() { 
                            window.print(); 
                            setTimeout(() => { window.close(); }, 500);
                        };
                    </script>
                </body>
            </html>
        `);
        printWindow.document.close();
    } else {
        console.error("Failed to open print window.");
    }
}

// Remaining Subscription Renewal District Report 
printPage1() {
  const printContent = document.getElementById('print-section2')?.outerHTML;
  if (!printContent) {
      console.error("Print section not found!");
      return;
  }

  const printWindow = window.open('', '', 'width=1400,height=1000,top=50,left=50,scrollbars=yes');

  if (printWindow) {
      printWindow.document.open();
      printWindow.document.write(`
          <html>
              <head>
                  <title>Print Report</title>
                  <style>
                      @media print {
                          @page { 
                              size: A3 landscape; /* Set A3 size with landscape mode */
                              margin: 5mm; /* Adjust margins */
                          }
                          body { 
                              font-family: Arial, sans-serif; 
                              font-size: 15px; /* Adjust font size */
                              margin: 0; 
                              padding: 5mm; 
                              line-height: 1.2; 
                          }
                          table { 
                              width: 100%; 
                              height: 100%;
                              border-collapse: collapse; 
                              table-layout: fixed; /* Prevent column expansion */
                              page-break-inside: avoid; /* Avoid breaking the table */
                          }
                          th, td { 
                              padding: 1px; 
                              border: 1px solid black; 
                              text-align: left; 
                              font-size: 6px; /* Adjusted font size */
                              line-height: 1.2; 
                          }
                          .noPrint { 
                              display: none; /* Hide print button */
                          }
                      }
                  </style>
              </head>
              <body>
                  <div id="print-section2">
                      ${printContent}
                  </div>
                  <script>
                      window.onload = function() { 
                          window.print(); 
                          setTimeout(() => { window.close(); }, 500);
                      };
                  </script>
              </body>
          </html>
      `);
      printWindow.document.close();
  } else {
      console.error("Failed to open print window.");
  }
}


  // printPage() {
  //   window.print();
  // }

//   printPage() {
//     const printContent = document.getElementById('print-section1')?.outerHTML;
//     if (!printContent) {
//         console.error("Print section not found!");
//         return;
//     }

//     const printWindow = window.open('', '', 'width=100,height=900,top=100,left=100,scrollbars=yes');

// if (printWindow) {
//   printWindow.document.open();
//   printWindow.document.write(`
//       <html>
//           <head>
//               <title>Print Report</title>
//               <style>
//                   @media print {
//                       @page { 
//                           size: A4 landscape; 
//                           margin: 5mm; 
//                       }
//                       body { 
//                           font-family: Arial, sans-serif; 
//                           font-size: 8px; /* Further reduce font size */
//                           margin: 0; 
//                           padding: 5mm; /* Reduce padding */
//                           line-height: 1.2; /* Reduce line height for more compact text */
//                       }
//                       table { 
//                           width: 100%; 
//                           border-collapse: collapse; 
//                           table-layout: fixed; /* Ensure columns don't expand unnecessarily */
//                           // transform: rotate(90deg); /* Rotate table 90 degrees */
//                           transform-origin: center center; /* Set the origin for rotation to the center */
//                           margin-top: 50px; /* Ensure space for the rotated table */
//                           // page-break-inside: avoid; /* Prevent content break inside the table */
//                       }
//                       th, td { 
//                           padding: 3px; /* Further reduced padding */
//                           border: 1px solid black; 
//                           text-align: center; 
//                           font-size: 4px; /* Smaller font size in table cells */
//                           line-height: 1.2; /* Reduce space between lines */
//                           // word-wrap: break-word; /* Prevent content overflow */
//                       }
//                       .noPrint { 
//                           display: none; /* Hide print button */
//                       }
//                   }
//               </style>
//           </head>
//           <body>
//               <div id="print-section1">
//                   ${printContent}
//               </div>
//               <script>
//                   window.onload = function() { 
//                       window.print(); 
//                       setTimeout(() => { window.close(); }, 500);
//                   };
//               </script>
//           </body>
//       </html>
//   `);
//   printWindow.document.close();
// }

//    else {
//       console.error("Failed to open print window.");
//   }



// }

//Print Report For Remaining Subscription off Reprensantive very Briefly
printPage2() {
  const printContent = document.getElementById('print-section1')?.outerHTML;
  if (!printContent) {
      console.error("Print section not found!");
      return;
  }

  const printWindow = window.open('', '', 'width=1400,height=1000,top=50,left=50,scrollbars=yes');

  if (printWindow) {
      printWindow.document.open();
      printWindow.document.write(`
          <html>
              <head>
                  <title>Print Report</title>
                  <style>
                      @media print {
                          @page { 
                              size: A3 landscape; /* Set A3 size with landscape mode */
                              margin: 5mm; /* Adjust margins */
                          }
                          body { 
                              font-family: Arial, sans-serif; 
                              font-size: 30px; /* Adjust font size */
                              margin: 0; 
                              padding: 5mm; 
                              line-height: 1.2; 
                          }
                          table { 
                              width: 100%; 
                              height: 50%;
                              border-collapse: collapse; 
                              table-layout: fixed; /* Prevent column expansion */
                              page-break-inside: avoid; /* Avoid breaking the table */
                          }
                          th, td { 
                              padding: 1px; 
                              border: 1px solid black; 
                              text-align: center; 
                              font-size: 12px; /* Adjusted font size */
                              line-height: 1.2; 
                          }
                          .noPrint { 
                              display: none; /* Hide print button */
                          }
                      }
                  </style>
              </head>
              <body>
                  <div id="print-section1">
                      ${printContent}
                  </div>
                  <script>
                      window.onload = function() { 
                          window.print(); 
                          setTimeout(() => { window.close(); }, 500);
                      };
                  </script>
              </body>
          </html>
      `);
      printWindow.document.close();
  } else {
      console.error("Failed to open print window.");
  }
}

  getData() {
    let data = this.uniqueData(
      this.filteredcontractData,
      (a) => a.off_rep_name
    );
    // console.log(data);

    for (let index = 0; index < data.length; index++) {
      let commonData = this.filteredcontractData.filter((a) => {
        return a.off_rep_name == data[index]['off_rep_name'];
      });

      // console.log(commonData);
      let sortedDataCommon = [];
      let contract_prev_bal = 0;
      let remaining_contract = 0;
      let contract_amt = 0;
      let contract_discnt_amt = 0;
      let contract_net_amt = 0;
      let contract_net_gst_amt = 0;
      let contract_net_total = 0;
      for (let i = 0; i < commonData.length; i++) {
        contract_prev_bal += parseFloat(commonData[i]['contract_prev_bal']);
        remaining_contract += parseFloat(commonData[i]['contract_bal_amt']);
        contract_amt += parseFloat(commonData[i]['contract_amt']);
        contract_discnt_amt += parseFloat(commonData[i]['contract_discnt_amt']);
        contract_net_amt += parseFloat(commonData[i]['contract_net_amt']);
        contract_net_gst_amt += parseFloat(
          commonData[i]['contract_net_gst_amt']
        );
        contract_net_total += parseFloat(commonData[i]['contract_net_total']);

        // console.log();

        sortedDataCommon.push({
          DS_ADVTID: commonData[i]['DS_ADVTID'],
          advt_amt: commonData[i]['advt_amt'],
          advt_discnt: commonData[i]['advt_discnt'],
          advt_name: commonData[i]['advt_name'],
          amount: commonData[i]['amount'],
          booking_date: commonData[i]['booking_date'],
          booking_no: commonData[i]['booking_no'],
          cgst: commonData[i]['cgst'],
          contract_amt: commonData[i]['contract_amt'],
          contract_bal_amt: commonData[i]['contract_bal_amt'],
          contract_bkng_advt_amt: commonData[i]['contract_bkng_advt_amt'],
          contract_date: commonData[i]['contract_date'],
          contract_discnt_amt: commonData[i]['contract_discnt_amt'],
          contract_id: commonData[i]['contract_id'],
          contract_net_amt: commonData[i]['contract_net_amt'],
          contract_net_gst_amt: commonData[i]['contract_net_gst_amt'],
          contract_net_total: commonData[i]['contract_net_total'],
          contract_no: commonData[i]['contract_no'],
          contract_period_from: commonData[i]['contract_period_from'],
          contract_period_to: commonData[i]['contract_period_to'],
          contract_prev_bal: commonData[i]['contract_prev_bal'],
          cust_name: commonData[i]['cust_name'],
          gst_include_exclude: commonData[i]['gst_include_exclude'],
          igst: commonData[i]['igst'],
          instruction: commonData[i]['instruction'],
          issue_date: commonData[i]['issue_date'],
          issue_name: commonData[i]['issue_name'],
          off_rep_name: commonData[i]['off_rep_name'],
          page_no: commonData[i]['page_no'],
          pages_no: commonData[i]['pages_no'],
          rep_mob_no: commonData[i]['rep_mob_no'],
          rep_name: commonData[i]['rep_name'],
          sgst: commonData[i]['sgst'],
          size: commonData[i]['size'],
          sub_gst_include_exclude: commonData[i]['sub_gst_include_exclude'],
          sub_id: commonData[i]['sub_id'],
          total_amount: commonData[i]['total_amount'],
          april1stWeekSize:
            this.formattedDate(
              this.firstSunday(3, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['size']
              : '',
          april1stWeekAmt:
            this.formattedDate(
              this.firstSunday(3, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['total_amount']
              : '',
          april1stWeekPageNo:
            this.formattedDate(
              this.firstSunday(3, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['pages_no']
              : '',
          april2ndWeekSize:
            this.formattedDate(
              this.secondSunday(3, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['size']
              : '',
          april2ndWeekAmt:
            this.formattedDate(
              this.secondSunday(3, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['total_amount']
              : '',
          april2ndWeekPageNo:
            this.formattedDate(
              this.secondSunday(3, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['pages_no']
              : '',
          april3rdWeekSize:
            this.formattedDate(
              this.thirdSunday(3, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['size']
              : '',
          april3rdWeekAmt:
            this.formattedDate(
              this.thirdSunday(3, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['total_amount']
              : '',
          april3rdWeekPageNo:
            this.formattedDate(
              this.thirdSunday(3, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['pages_no']
              : '',
          april4thWeekSize:
            this.formattedDate(
              this.fourthSunday(3, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['size']
              : '',
          april4thWeekAmt:
            this.formattedDate(
              this.fourthSunday(3, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['total_amount']
              : '',
          april4thWeekPageNo:
            this.formattedDate(
              this.fourthSunday(3, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['pages_no']
              : '',
          april5thWeekSize:
            this.formattedDate(
              this.fifthSunday(3, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['size']
              : '',
          april5thWeekAmt:
            this.formattedDate(
              this.fifthSunday(3, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['total_amount']
              : '',
          april5thWeekPageNo:
            this.formattedDate(
              this.fifthSunday(3, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['pages_no']
              : '',
          may1stWeekSize:
            this.formattedDate(
              this.firstSunday(4, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['size']
              : '',
          may1stWeekAmt:
            this.formattedDate(
              this.firstSunday(4, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['total_amount']
              : '',
          may1stWeekPageNo:
            this.formattedDate(
              this.firstSunday(4, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['pages_no']
              : '',
          may2ndWeekSize:
            this.formattedDate(
              this.secondSunday(4, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['size']
              : '',
          may2ndWeekAmt:
            this.formattedDate(
              this.secondSunday(4, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['total_amount']
              : '',
          may2ndWeekPageNo:
            this.formattedDate(
              this.secondSunday(4, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['pages_no']
              : '',
          may3rdWeekSize:
            this.formattedDate(
              this.thirdSunday(4, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['size']
              : '',
          may3rdWeekAmt:
            this.formattedDate(
              this.thirdSunday(4, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['total_amount']
              : '',
          may3rdWeekPageNo:
            this.formattedDate(
              this.thirdSunday(4, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['pages_no']
              : '',
          may4thWeekSize:
            this.formattedDate(
              this.fourthSunday(4, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['size']
              : '',
          may4thWeekAmt:
            this.formattedDate(
              this.fourthSunday(4, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['total_amount']
              : '',
          may4thWeekPageNo:
            this.formattedDate(
              this.fourthSunday(4, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['pages_no']
              : '',
          may5thWeekSize:
            this.formattedDate(
              this.fifthSunday(4, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['size']
              : '',
          may5thWeekAmt:
            this.formattedDate(
              this.fifthSunday(4, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['total_amount']
              : '',
          may5thWeekPageNo:
            this.formattedDate(
              this.fifthSunday(4, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['pages_no']
              : '',
          june1stWeekSize:
            this.formattedDate(
              this.firstSunday(5, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['size']
              : '',
          june1stWeekAmt:
            this.formattedDate(
              this.firstSunday(5, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['total_amount']
              : '',
          june1stWeekPageNo:
            this.formattedDate(
              this.firstSunday(5, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['pages_no']
              : '',
          june2ndWeekSize:
            this.formattedDate(
              this.secondSunday(5, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['size']
              : '',
          june2ndWeekAmt:
            this.formattedDate(
              this.secondSunday(5, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['total_amount']
              : '',
          june2ndWeekPageNo:
            this.formattedDate(
              this.secondSunday(5, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['pages_no']
              : '',
          june3rdWeekSize:
            this.formattedDate(
              this.thirdSunday(5, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['size']
              : '',
          june3rdWeekAmt:
            this.formattedDate(
              this.thirdSunday(5, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['total_amount']
              : '',
          june3rdWeekPageNo:
            this.formattedDate(
              this.thirdSunday(5, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['pages_no']
              : '',
          june4thWeekSize:
            this.formattedDate(
              this.fourthSunday(5, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['size']
              : '',
          june4thWeekAmt:
            this.formattedDate(
              this.fourthSunday(5, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['total_amount']
              : '',
          june4thWeekPageNo:
            this.formattedDate(
              this.fourthSunday(5, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['pages_no']
              : '',
          june5thWeekSize:
            this.formattedDate(
              this.fifthSunday(5, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['size']
              : '',
          june5thWeekAmt:
            this.formattedDate(
              this.fifthSunday(5, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['total_amount']
              : '',
          june5thWeekPageNo:
            this.formattedDate(
              this.fifthSunday(5, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['pages_no']
              : '',
          july1stWeekSize:
            this.formattedDate(
              this.firstSunday(6, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['size']
              : '',
          july1stWeekAmt:
            this.formattedDate(
              this.firstSunday(6, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['total_amount']
              : '',
          july1stWeekPageNo:
            this.formattedDate(
              this.firstSunday(6, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['pages_no']
              : '',
          july2ndWeekSize:
            this.formattedDate(
              this.secondSunday(6, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['size']
              : '',
          july2ndWeekAmt:
            this.formattedDate(
              this.secondSunday(6, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['total_amount']
              : '',
          july2ndWeekPageNo:
            this.formattedDate(
              this.secondSunday(6, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['pages_no']
              : '',
          july3rdWeekSize:
            this.formattedDate(
              this.thirdSunday(6, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['size']
              : '',
          july3rdWeekAmt:
            this.formattedDate(
              this.thirdSunday(6, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['total_amount']
              : '',
          july3rdWeekPageNo:
            this.formattedDate(
              this.thirdSunday(6, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['pages_no']
              : '',
          july4thWeekSize:
            this.formattedDate(
              this.fourthSunday(6, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['size']
              : '',
          july4thWeekAmt:
            this.formattedDate(
              this.fourthSunday(6, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['total_amount']
              : '',
          july4thWeekPageNo:
            this.formattedDate(
              this.fourthSunday(6, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['pages_no']
              : '',
          july5thWeekSize:
            this.formattedDate(
              this.fifthSunday(6, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['size']
              : '',
          july5thWeekAmt:
            this.formattedDate(
              this.fifthSunday(6, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['total_amount']
              : '',
          july5thWeekPageNo:
            this.formattedDate(
              this.fifthSunday(6, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['pages_no']
              : '',
          august1stWeekSize:
            this.formattedDate(
              this.firstSunday(7, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['size']
              : '',
          august1stWeekAmt:
            this.formattedDate(
              this.firstSunday(7, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['total_amount']
              : '',
          august1stWeekPageNo:
            this.formattedDate(
              this.firstSunday(7, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['pages_no']
              : '',
          august2ndWeekSize:
            this.formattedDate(
              this.secondSunday(7, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['size']
              : '',
          august2ndWeekAmt:
            this.formattedDate(
              this.secondSunday(7, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['total_amount']
              : '',
          august2ndWeekPageNo:
            this.formattedDate(
              this.secondSunday(7, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['pages_no']
              : '',
          august3rdWeekSize:
            this.formattedDate(
              this.thirdSunday(7, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['size']
              : '',
          august3rdWeekAmt:
            this.formattedDate(
              this.thirdSunday(7, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['total_amount']
              : '',
          august3rdWeekPageNo:
            this.formattedDate(
              this.thirdSunday(7, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['pages_no']
              : '',
          august4thWeekSize:
            this.formattedDate(
              this.fourthSunday(7, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['size']
              : '',
          august4thWeekAmt:
            this.formattedDate(
              this.fourthSunday(7, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['total_amount']
              : '',
          august4thWeekPageNo:
            this.formattedDate(
              this.fourthSunday(7, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['pages_no']
              : '',
          august5thWeekSize:
            this.formattedDate(
              this.fifthSunday(7, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['size']
              : '',
          august5thWeekAmt:
            this.formattedDate(
              this.fifthSunday(7, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['total_amount']
              : '',
          august5thWeekPageNo:
            this.formattedDate(
              this.fifthSunday(7, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['pages_no']
              : '',
          september1stWeekSize:
            this.formattedDate(
              this.firstSunday(8, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['size']
              : '',
          september1stWeekAmt:
            this.formattedDate(
              this.firstSunday(8, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['total_amount']
              : '',
          september1stWeekPageNo:
            this.formattedDate(
              this.firstSunday(8, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['pages_no']
              : '',
          september2ndWeekSize:
            this.formattedDate(
              this.secondSunday(8, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['size']
              : '',
          september2ndWeekAmt:
            this.formattedDate(
              this.secondSunday(8, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['total_amount']
              : '',
          september2ndWeekPageNo:
            this.formattedDate(
              this.secondSunday(8, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['pages_no']
              : '',
          september3rdWeekSize:
            this.formattedDate(
              this.thirdSunday(8, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['size']
              : '',
          september3rdWeekAmt:
            this.formattedDate(
              this.thirdSunday(8, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['total_amount']
              : '',
          september3rdWeekPageNo:
            this.formattedDate(
              this.thirdSunday(8, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['pages_no']
              : '',
          september4thWeekSize:
            this.formattedDate(
              this.fourthSunday(8, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['size']
              : '',
          september4thWeekAmt:
            this.formattedDate(
              this.fourthSunday(8, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['total_amount']
              : '',
          september4thWeekPageNo:
            this.formattedDate(
              this.fourthSunday(8, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['pages_no']
              : '',
          september5thWeekSize:
            this.formattedDate(
              this.fifthSunday(8, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['size']
              : '',
          september5thWeekAmt:
            this.formattedDate(
              this.fifthSunday(8, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['total_amount']
              : '',
          september5thWeekPageNo:
            this.formattedDate(
              this.fifthSunday(8, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['pages_no']
              : '',
          october1stWeekSize:
            this.formattedDate(
              this.firstSunday(9, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['size']
              : '',
          october1stWeekAmt:
            this.formattedDate(
              this.firstSunday(9, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['total_amount']
              : '',
          october1stWeekPageNo:
            this.formattedDate(
              this.firstSunday(9, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['pages_no']
              : '',
          october2ndWeekSize:
            this.formattedDate(
              this.secondSunday(9, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['size']
              : '',
          october2ndWeekAmt:
            this.formattedDate(
              this.secondSunday(9, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['total_amount']
              : '',
          october2ndWeekPageNo:
            this.formattedDate(
              this.secondSunday(9, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['pages_no']
              : '',
          october3rdWeekSize:
            this.formattedDate(
              this.thirdSunday(9, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['size']
              : '',
          october3rdWeekAmt:
            this.formattedDate(
              this.thirdSunday(9, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['total_amount']
              : '',
          october3rdWeekPageNo:
            this.formattedDate(
              this.thirdSunday(9, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['pages_no']
              : '',
          october4thWeekSize:
            this.formattedDate(
              this.fourthSunday(9, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['size']
              : '',
          october4thWeekAmt:
            this.formattedDate(
              this.fourthSunday(9, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['total_amount']
              : '',
          october4thWeekPageNo:
            this.formattedDate(
              this.fourthSunday(9, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['pages_no']
              : '',
          october5thWeekSize:
            this.formattedDate(
              this.fifthSunday(9, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['size']
              : '',
          october5thWeekAmt:
            this.formattedDate(
              this.fifthSunday(9, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['total_amount']
              : '',
          october5thWeekPageNo:
            this.formattedDate(
              this.fifthSunday(9, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['pages_no']
              : '',
          november1stWeekSize:
            this.formattedDate(
              this.firstSunday(10, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['size']
              : '',
          november1stWeekAmt:
            this.formattedDate(
              this.firstSunday(10, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['total_amount']
              : '',
          november1stWeekPageNo:
            this.formattedDate(
              this.firstSunday(10, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['pages_no']
              : '',
          november2ndWeekSize:
            this.formattedDate(
              this.secondSunday(10, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['size']
              : '',
          november2ndWeekAmt:
            this.formattedDate(
              this.secondSunday(10, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['total_amount']
              : '',
          november2ndWeekPageNo:
            this.formattedDate(
              this.secondSunday(10, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['pages_no']
              : '',
          november3rdWeekSize:
            this.formattedDate(
              this.thirdSunday(10, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['size']
              : '',
          november3rdWeekAmt:
            this.formattedDate(
              this.thirdSunday(10, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['total_amount']
              : '',
          november3rdWeekPageNo:
            this.formattedDate(
              this.thirdSunday(10, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['pages_no']
              : '',
          november4thWeekSize:
            this.formattedDate(
              this.fourthSunday(10, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['size']
              : '',
          november4thWeekAmt:
            this.formattedDate(
              this.fourthSunday(10, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['total_amount']
              : '',
          november4thWeekPageNo:
            this.formattedDate(
              this.fourthSunday(10, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['pages_no']
              : '',
          november5thWeekSize:
            this.formattedDate(
              this.fifthSunday(10, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['size']
              : '',
          november5thWeekAmt:
            this.formattedDate(
              this.fifthSunday(10, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['total_amount']
              : '',
          november5thWeekPageNo:
            this.formattedDate(
              this.fifthSunday(10, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['pages_no']
              : '',
          december1stWeekSize:
            this.formattedDate(
              this.firstSunday(11, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['size']
              : '',
          december1stWeekAmt:
            this.formattedDate(
              this.firstSunday(11, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['total_amount']
              : '',
          december1stWeekPageNo:
            this.formattedDate(
              this.firstSunday(11, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['pages_no']
              : '',
          december2ndWeekSize:
            this.formattedDate(
              this.secondSunday(11, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['size']
              : '',
          december2ndWeekAmt:
            this.formattedDate(
              this.secondSunday(11, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['total_amount']
              : '',
          december2ndWeekPageNo:
            this.formattedDate(
              this.secondSunday(11, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['pages_no']
              : '',
          december3rdWeekSize:
            this.formattedDate(
              this.thirdSunday(11, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['size']
              : '',
          december3rdWeekAmt:
            this.formattedDate(
              this.thirdSunday(11, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['total_amount']
              : '',
          december3rdWeekPageNo:
            this.formattedDate(
              this.thirdSunday(11, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['pages_no']
              : '',
          december4thWeekSize:
            this.formattedDate(
              this.fourthSunday(11, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['size']
              : '',
          december4thWeekAmt:
            this.formattedDate(
              this.fourthSunday(11, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['total_amount']
              : '',
          december4thWeekPageNo:
            this.formattedDate(
              this.fourthSunday(11, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['pages_no']
              : '',
          december5thWeekSize:
            this.formattedDate(
              this.fifthSunday(11, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['size']
              : '',
          december5thWeekAmt:
            this.formattedDate(
              this.fifthSunday(11, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['total_amount']
              : '',
          december5thWeekPageNo:
            this.formattedDate(
              this.fifthSunday(11, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['pages_no']
              : '',
          january1stWeekSize:
            this.formattedDate(
              this.firstSunday(0, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['size']
              : '',
          january1stWeekAmt:
            this.formattedDate(
              this.firstSunday(0, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['total_amount']
              : '',
          january1stWeekPageNo:
            this.formattedDate(
              this.firstSunday(0, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['pages_no']
              : '',
          january2ndWeekSize:
            this.formattedDate(
              this.secondSunday(0, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['size']
              : '',
          january2ndWeekAmt:
            this.formattedDate(
              this.secondSunday(0, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['total_amount']
              : '',
          january2ndWeekPageNo:
            this.formattedDate(
              this.secondSunday(0, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['pages_no']
              : '',
          january3rdWeekSize:
            this.formattedDate(
              this.thirdSunday(0, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['size']
              : '',
          january3rdWeekAmt:
            this.formattedDate(
              this.thirdSunday(0, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['total_amount']
              : '',
          january3rdWeekPageNo:
            this.formattedDate(
              this.thirdSunday(0, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['pages_no']
              : '',
          january4thWeekSize:
            this.formattedDate(
              this.fourthSunday(0, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['size']
              : '',
          january4thWeekAmt:
            this.formattedDate(
              this.fourthSunday(0, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['total_amount']
              : '',
          january4thWeekPageNo:
            this.formattedDate(
              this.fourthSunday(0, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['pages_no']
              : '',
          january5thWeekSize:
            this.formattedDate(
              this.fifthSunday(0, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['size']
              : '',
          january5thWeekAmt:
            this.formattedDate(
              this.fifthSunday(0, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['total_amount']
              : '',
          january5thWeekPageNo:
            this.formattedDate(
              this.fifthSunday(0, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['pages_no']
              : '',
          feb1stWeekSize:
            this.formattedDate(
              this.firstSunday(1, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['size']
              : '',
          feb1stWeekAmt:
            this.formattedDate(
              this.firstSunday(1, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['total_amount']
              : '',
          feb1stWeekPageNo:
            this.formattedDate(
              this.firstSunday(1, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['pages_no']
              : '',
          feb2ndWeekSize:
            this.formattedDate(
              this.secondSunday(1, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['size']
              : '',
          feb2ndWeekAmt:
            this.formattedDate(
              this.secondSunday(1, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['total_amount']
              : '',
          feb2ndWeekPageNo:
            this.formattedDate(
              this.secondSunday(1, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['pages_no']
              : '',
          feb3rdWeekSize:
            this.formattedDate(
              this.thirdSunday(1, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['size']
              : '',
          feb3rdWeekAmt:
            this.formattedDate(
              this.thirdSunday(1, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['total_amount']
              : '',
          feb3rdWeekPageNo:
            this.formattedDate(
              this.thirdSunday(1, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['pages_no']
              : '',
          feb4thWeekSize:
            this.formattedDate(
              this.fourthSunday(1, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['size']
              : '',
          feb4thWeekAmt:
            this.formattedDate(
              this.fourthSunday(1, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['total_amount']
              : '',
          feb4thWeekPageNo:
            this.formattedDate(
              this.fourthSunday(1, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['pages_no']
              : '',
          feb5thWeekSize:
            this.formattedDate(
              this.fifthSunday(1, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['size']
              : '',
          feb5thWeekAmt:
            this.formattedDate(
              this.fifthSunday(1, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['total_amount']
              : '',
          feb5thWeekPageNo:
            this.formattedDate(
              this.fifthSunday(1, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['pages_no']
              : '',
          march1stWeekSize:
            this.formattedDate(
              this.firstSunday(2, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['size']
              : '',
          march1stWeekAmt:
            this.formattedDate(
              this.firstSunday(2, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['total_amount']
              : '',
          march1stWeekPageNo:
            this.formattedDate(
              this.firstSunday(2, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['pages_no']
              : '',
          march2ndWeekSize:
            this.formattedDate(
              this.secondSunday(2, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['size']
              : '',
          march2ndWeekAmt:
            this.formattedDate(
              this.secondSunday(2, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['total_amount']
              : '',
          march2ndWeekPageNo:
            this.formattedDate(
              this.secondSunday(2, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['pages_no']
              : '',
          march3rdWeekSize:
            this.formattedDate(
              this.thirdSunday(2, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['size']
              : '',
          march3rdWeekAmt:
            this.formattedDate(
              this.thirdSunday(2, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['total_amount']
              : '',
          march3rdWeekPageNo:
            this.formattedDate(
              this.thirdSunday(2, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['pages_no']
              : '',
          march4thWeekSize:
            this.formattedDate(
              this.fourthSunday(2, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['size']
              : '',
          march4thWeekAmt:
            this.formattedDate(
              this.fourthSunday(2, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['total_amount']
              : '',
          march4thWeekPageNo:
            this.formattedDate(
              this.fourthSunday(2, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['pages_no']
              : '',
          march5thWeekSize:
            this.formattedDate(
              this.fifthSunday(2, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['size']
              : '',
          march5thWeekAmt:
            this.formattedDate(
              this.fifthSunday(2, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['total_amount']
              : '',
          march5thWeekPageNo:
            this.formattedDate(
              this.fifthSunday(2, this.formatYear(this.today))
            ) == commonData[i]['issue_date'].split(',')[0]
              ? commonData[i]['pages_no']
              : '',
        });
      }

      this.indetailsData.push({
        name: data[index]['off_rep_name'],
        data: sortedDataCommon,
        contract_prev_bal,
        remaining_contract,
        contract_amt,
        contract_discnt_amt,
        contract_net_amt,
        contract_net_gst_amt,
        contract_net_total,
      });

     

      // setTimeout(() => {
      //   this.count = 5;
      this.cdr.detectChanges();
      // }, 1000);
    }

    console.log(this.indetailsData,"indetailsData");
  }

  formatYear(date) {
    return formatDate(date, 'yyyy', 'en-IN', 'GMT+0530');
  }

  formattedDate(date) {
    return formatDate(date, 'dd/MM/yyyy', 'en-IN', 'GMT+0530');
  }

  uniqueData(data, key) {
    return [...new Map(data.map((a) => [key(a), a])).values()];
  }

  calculateTotalAmount(...args) {
    return args.reduce((acc, curr) => {
      // console.log(curr == '' ? 0 : parseFloat(curr));
      let a = curr == '' ? 0 : parseFloat(curr);
      return acc + a;
    }, 0);
  }
  
}
