import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-subscribers-address',
  templateUrl: './subscribers-address.component.html',
  styleUrls: ['./subscribers-address.component.scss'],
})
export class SubscribersAddressComponent implements OnInit {
  subscribersaddressdata;

  constructor(
    public dialogref: MatDialogRef<SubscribersAddressComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.subscribersaddressdata = this.data['subscribersaddressdata'];
  }

  // PrintData() {
  //   let element = document.getElementById('print-section');
  //   let newWin = window.open('');
  //   newWin.document.write(element.outerHTML);
  //   newWin.print();
  //   newWin.close();
  // }
}
