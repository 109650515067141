<ng-container *ngIf="!isLoading">
    <div>
        <div style="margin-left: 70%; margin-top: -4%; margin-bottom: 2%">
            <button
                [printStyle]="{table : {'border-collapse':'collapse', 'width': '100%'}, td : {'border': 'solid 1px', 'font-size':'14px', 'font-weight':'600', 'text-align':'center'}, th : {'border': 'solid 1px', 'font-size':'16px', 'font-weight':'600', 'text-align':'center'}, div : {'height':'14px', 'font-weight':'600'}, span : {'font-size':'14px', 'font-weight':'600', 'float':'left'}}"
                class="noPrint" style="margin-right: 2%; margin-left: 2%" mat-raised-button color="primary"
                printSectionId="print-section" ngxPrint>
                print
            </button>
            <button class="noPrint" style="margin-top: 2% 2% 0 2%;"
                (click)="exportExcel('Districtwise_Subscription_Report')" mat-raised-button color="primary">
                Export
            </button>
            <button mat-dialog-close class="btn noPrint">
                Close
            </button>
        </div>
    </div>
    <div *ngIf="isData">
        <div class="container" id="print-section">
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th colspan="15">प्रतिनिधीश: एप्रिल १८ ते मार्च १९ नूतनीकरण</th>
                    </tr>
                    <tr>
                        <th>क्र</th>
                        <th>प्रतिनिधी नाव</th>
                        <th>4</th>
                        <th>5</th>
                        <th>6</th>
                        <th>7</th>
                        <th>8</th>
                        <th>9</th>
                        <th>10</th>
                        <th>11</th>
                        <th>12</th>
                        <th>1</th>
                        <th>2</th>
                        <th>3</th>
                        <th>एकूण</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let data of finalData; let end=last;">
                        <ng-container *ngIf="!end">
                            <ng-container *ngFor="let item of data;let end1=last;let i=index;">
                                <tr *ngIf="i == 0">
                                    <td colspan="15">{{ item['off_name'] }}</td>
                                </tr>
                                <tr *ngIf="!end1">
                                    <td>{{ i+1 }}</td>
                                    <td>{{ item['name'] }}</td>
                                    <td>{{ item['April'] }}</td>
                                    <td>{{ item['May'] }}</td>
                                    <td>{{ item['June'] }}</td>
                                    <td>{{ item['July'] }}</td>
                                    <td>{{ item['August'] }}</td>
                                    <td>{{ item['September'] }}</td>
                                    <td>{{ item['October'] }}</td>
                                    <td>{{ item['November'] }}</td>
                                    <td>{{ item['December'] }}</td>
                                    <td>{{ item['January'] }}</td>
                                    <td>{{ item['February'] }}</td>
                                    <td>{{ item['March'] }}</td>
                                    <td>{{ item['total'] }}</td>
                                </tr>
                                <tr *ngIf="end1">
                                    <td colspan="2"></td>
                                    <td>{{ item['April'] }}</td>
                                    <td>{{ item['May'] }}</td>
                                    <td>{{ item['June'] }}</td>
                                    <td>{{ item['July'] }}</td>
                                    <td>{{ item['August'] }}</td>
                                    <td>{{ item['September'] }}</td>
                                    <td>{{ item['October'] }}</td>
                                    <td>{{ item['November'] }}</td>
                                    <td>{{ item['December'] }}</td>
                                    <td>{{ item['January'] }}</td>
                                    <td>{{ item['February'] }}</td>
                                    <td>{{ item['March'] }}</td>
                                    <td>{{ item['total'] }}</td>
                                </tr>
                            </ng-container>
                        </ng-container>
                        <tr *ngIf="end">
                            <td colspan="15">
                                <div></div>
                            </td>
                        </tr>
                        <tr *ngIf="end">
                            <td colspan="2"></td>
                            <td>{{ data['April'] }}</td>
                            <td>{{ data['May'] }}</td>
                            <td>{{ data['June'] }}</td>
                            <td>{{ data['July'] }}</td>
                            <td>{{ data['August'] }}</td>
                            <td>{{ data['September'] }}</td>
                            <td>{{ data['October'] }}</td>
                            <td>{{ data['November'] }}</td>
                            <td>{{ data['December'] }}</td>
                            <td>{{ data['January'] }}</td>
                            <td>{{ data['February'] }}</td>
                            <td>{{ data['March'] }}</td>
                            <td>{{ data['total'] }}</td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>
    </div>
    <div *ngIf="!isData" class="error">
        <h2>No data found!</h2>
    </div>
</ng-container>
<div *ngIf="isLoading" class="main-loading">
    <span class="loader"></span>
    <p>Loading...</p>
</div>