import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-show-subscription-nodani-receipt',
  templateUrl: './show-subscription-nodani-receipt.component.html',
  styleUrls: ['./show-subscription-nodani-receipt.component.scss'],
})
export class ShowSubscriptionNodaniReceiptComponent implements OnInit {
  subData :any;
  testdata = [1, 2];
  start: any;
  end: any;
  name: any;

  constructor(
    private dialogref: MatDialogRef<ShowSubscriptionNodaniReceiptComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private renderer: Renderer2 ) {}

  ngOnInit(): void {
    
    this.subData = this.data['val'];
    this.start = this.data['startDate'];
    this.end = this.data['endDate'];
    this.name = this.data.name;

    this.subData.forEach(element => {
      element.printEnabled=false
    });
    console.log(this.subData);

    // this.subData = this.subData.filter(element => element.name === this.name);
    // console.log(this.subData,this.start,this.end);
  }

  printSelectedSections() {
    const selectedSections = this.subData.filter(item => item.id);
    if (selectedSections.length === 0) {
      alert('No sections selected for printing.');
      return;
    }

    const printWindow = window.open('', '_blank');
    if (printWindow) {
      // printWindow.document.write('<html><head><title>Print</title></head><body>');
      printWindow.document.write('<html><head><title>Print</title>');
      printWindow.document.write(`
      <style>
      ${this.getClass()}
       .page-break {
            page-break-after: always;
        }
       .bottom-margin {
          margin-bottom: 30px; /* Adjust this value as needed */
      }   
          .bottom-top {
          margin-top: 60px; /* Adjust this value as needed */
      } 
      </style></head><body>
      `);
      selectedSections.forEach((section, index)  => {
        const sectionHtml = document.getElementById(section.id).innerHTML;
        // printWindow.document.write(sectionHtml);
        // if (index % 2 === 0) { // Apply bottom margin to the first section of each page
          printWindow.document.write(`<div class="bottom-margin bottom-top">${sectionHtml}</div>`);
        // } else {
        //   printWindow.document.write(`<div class="bottom-top">${sectionHtml}</div>`);
        // }
        // Add a page break after every two sections except after the last one
        if ((index + 1) % 2 === 0 && index + 1 !== selectedSections.length) {
            printWindow.document.write('<div class="page-break"></div>');
        }
      });
      printWindow.document.write('</body></html>');
      printWindow.document.close();
      printWindow.print();
    } else {
      alert('Failed to open print window. Please check your browser settings.');
    }
  }

  getClass(){
    return `/* Container Styles */
    .container {
        width: 80%;
        margin: 0 auto;
        font-family: Arial, sans-serif;
    }
      
    /* Row Styles */
    .row {
      display: flex;
      // flex-wrap: wrap;
      margin-right: -15px;
      margin-left: -15px;
  }
  .col-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
}
.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}
.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}
    .col-3 {
      flex: 0 0 25%;
      max-width: 25%;
  }
  .col-xl, .col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg, .col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md, .col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm, .col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col, .col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}
    /* Column Styles */
    .col-2 {
        width: 16.66667%;
        float: left;
    }
    
    .col-10 {
        width: 83.33333%;
        float: left;
    }
    
    /* Image Styles */
    img {
        max-width: 100%;
        height: auto;
    }
    
    /* Span Styles */
    span {
        display: inline-block;
    }
    
    /* Checkbox Label Styles */
    label {
        margin-left: 10px;
    }
    
    /* Last Border Styles */
    .row:last-child {
        border-bottom: none;
        margin-bottom: 20px;
    }
    
    /* Border Styles */
    .border-bottom {
        border-bottom: 1px solid black;
        margin-top: 5%;
        margin-bottom: 5%;
    }
    
    /* Additional Styles */
    .noPrint {
        display: none;
    }
    
    @media print {
        .noPrint {
            display: none !important;
        }

          .bottom-margin {
          margin-bottom: 90px; /* Adjust this value as needed */
      }   
          .bottom-top {
          margin-top: 60px; /* Adjust this value as needed */
      } 
    }
    .col-4 {
      flex: 0 0 33.3333333333%;
      max-width: 33.3333333333%;
  }

    `
  }
}
