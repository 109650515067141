<div style="margin-top: 5%; padding: 15px;">
    <div>
        <mat-form-field appearance="outline" style="width: 27%; margin-left: 5%">
            <mat-label>Credit Voucher Amount</mat-label>
            <input matInput type="number" [(ngModel)]="crdtvAmt" readonly />
        </mat-form-field>
        <mat-form-field appearance="outline" style="width: 27%; margin-left: 2%">
            <mat-label>Select Entry Amount</mat-label>
            <input matInput type="number" [(ngModel)]="selectedAmt" readonly />
        </mat-form-field>
        <mat-form-field appearance="outline" style="width: 27%; margin-left: 2%">
            <mat-label>Balance Amount</mat-label>
            <input matInput type="number" [(ngModel)]="balanceAmt" readonly />
        </mat-form-field>
    </div>
    <div>
        <table class="table table-bordered">
            <thead>
                <tr>
                    <td>Sr. No.</td>
                    <td>Advt. Name</td>
                    <td>Issue name</td>
                    <td>Issue Date</td>
                    <td>Amount</td>
                    <td>Received Amount</td>
                    <td>Remaining Amount</td>
                </tr>
            </thead>
            <tbody>
                <tr >
                    <td>  </td>
                    <td>{{ advtInvoiceData['advt_name'] }}</td>
                    <td>{{ advtInvoiceData['DS_ISSUENAME'] }}</td>
                    <td>{{ advtInvoiceData['DS_ISSUEDATE'] }}</td>
                    <td>{{ advtInvoiceData['net_amt'] }}</td>
                    <td>
                        <mat-form-field class="example-full-width" appearance="outline" style="width: 30%">
                            <input matInput type="number" (keyup)="calcamount($event, advtInvoiceData)" />
                        </mat-form-field>
                        Previous Value :{{ advtInvoiceData["rcv_amt"] }}
                    </td>
                    <td>{{ advtInvoiceData['remaining_amount'] }}</td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="dialog">
        <button class="btn btn-primary" style="margin-top: 2%; width: 10%" (click)="update1()" mat-button>
            Update
        </button>
    </div>
</div>