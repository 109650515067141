import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { DigisubservicesService } from 'src/app/services/digisubservices.service';

export const _filter7 = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();

  return opt.filter((item) => item.toLowerCase().includes(filterValue));
};

export const _filter8 = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();

  return opt.filter((item) => item.toLowerCase().includes(filterValue));
};

export const _filter9 = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();

  return opt.filter((item) => item.toLowerCase().includes(filterValue));
};

export const _filter10 = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();

  return opt.filter((item) => item.toLowerCase().includes(filterValue));
};

@Component({
  selector: 'app-update-office-representative',
  templateUrl: './update-office-representative.component.html',
  styleUrls: ['./update-office-representative.component.scss'],
})
export class UpdateOfficeRepresentativeComponent implements OnInit {
  repassignform: FormGroup;
  filteredOptions6: Observable<string[]>;
  filteredOptions7: Observable<string[]>;
  filteredOptions8: Observable<string[]>;
  filteredOptions9: Observable<string[]>;
  filteredOptions10: Observable<string[]>;

  constructor(
    public fb: FormBuilder,
    public service: DigisubservicesService,
    private dialogRef: MatDialogRef<UpdateOfficeRepresentativeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.repassignform = fb.group({
      rep: [''],
      district: [''],
      taluka: [''],
      bookstore: [''],
      subscription: [''],
      advt: [''],
      issue: [''],
    });
  }

  repdata;
  rep_id = 0;
  office_rep = [];
  issue_seller_name = [];
  type: string;
  book: boolean = false;
  advt: boolean = false;
  sub: boolean = false;
  issue: boolean = false;

  ngOnInit(): void {
    this.service.getIssuesellersname().subscribe((data) => {
      this.issue_seller_name = data;
      console.log('issue_seller_name', this.issue_seller_name);
    });

    this.repdata = this.data['item'];
    this.type = this.data['type'];
    this.rep_id = this.repdata['id'];
    console.log(this.data['item']);

    this.repassignform.get('rep').setValue(this.repdata['rep_name']);
    this.repassignform.get('district').setValue(this.repdata['district']);
    this.repassignform.get('taluka').setValue(this.repdata['taluka']);

    this.service.getOfficeRepName().subscribe((officedata) => {
      console.log('office_rep', officedata);
      this.office_rep = officedata;
    });

    this.filteredOptions7 = this.repassignform
      .get('bookstore')
      .valueChanges.pipe(
        startWith(''),
        map((value) => (typeof value === 'string' ? value : value.name)),
        map((name) => (name ? this._filter7(name) : this.office_rep.slice()))
      );

    this.filteredOptions8 = this.repassignform
      .get('subscription')
      .valueChanges.pipe(
        startWith(''),
        map((value) => (typeof value === 'string' ? value : value.name)),
        map((name) => (name ? this._filter8(name) : this.office_rep.slice()))
      );

    this.filteredOptions9 = this.repassignform.get('advt').valueChanges.pipe(
      startWith(''),
      map((value) => (typeof value === 'string' ? value : value.name)),
      map((name) => (name ? this._filter9(name) : this.office_rep.slice()))
    );

    this.filteredOptions10 = this.repassignform.get('issue').valueChanges.pipe(
      startWith(''),
      map((value) => (typeof value === 'string' ? value : value.name)),
      map((name) => (name ? this._filter10(name) : this.office_rep.slice()))
    );

    this.checktype(this.type);
  }

  checktype(type) {
    if (type == 'book') {
      this.repassignform
        .get('bookstore')
        .setValue(this.repdata['off_rep_name']);
      this.book = true;
    } else if (type == 'issue') {
      this.repassignform.get('issue').setValue(this.repdata['off_rep_name']);
      this.issue = true;
    } else if (type == 'advt') {
      this.repassignform.get('advt').setValue(this.repdata['off_rep_name']);
      this.advt = true;
    } else if (type == 'sub') {
      this.repassignform
        .get('subscription')
        .setValue(this.repdata['off_rep_name']);
      this.sub = true;
    }
  }

  private _filter7(name: string): any[] {
    const filterValue = name.toLowerCase();

    return this.office_rep.filter((option) =>
      option.name.toLowerCase().includes(filterValue)
    );
  }

  private _filter8(name: string): any[] {
    const filterValue = name.toLowerCase();

    return this.office_rep.filter((option) =>
      option.name.toLowerCase().includes(filterValue)
    );
  }

  private _filter9(name: string): any[] {
    const filterValue = name.toLowerCase();

    return this.office_rep.filter((option) =>
      option.name.toLowerCase().includes(filterValue)
    );
  }

  private _filter10(name: string): any[] {
    const filterValue = name.toLowerCase();

    return this.office_rep.filter((option) =>
      option.name.toLowerCase().includes(filterValue)
    );
  }

  selected_name(event) {
    this.rep_id = event['id'];
  }

  book_id = 0;
  selected_bookstore(event) {
    console.log('book id', event['id']);
    this.book_id = event['id'];
  }

  sub_id = 0;
  selected_subs(event) {
    console.log('sub id', event['id']);
    this.sub_id = event['id'];
  }

  advt_id = 0;
  selected_advt(event) {
    console.log('advt id', event['id']);
    this.advt_id = event['id'];
  }

  issue_id = 0;
  selected_issue(event) {
    console.log('issue id', event['id']);
    this.issue_id = event['id'];
  }

  updaterep() {
    if (this.type == 'book') {
      this.service
        .updateOfficeRep(this.rep_id, this.book_id, this.type)
        .subscribe((data) => {
          window.alert('Office Representative updated successfully...');
        });
    } else if (this.type == 'issue') {
      this.service
        .updateOfficeRep(this.rep_id, this.issue_id, 'issue')
        .subscribe((data1) => {
          window.alert('Office Representative updated successfully...');
        });
    } else if (this.type == 'advt') {
      this.service
        .updateOfficeRep(this.rep_id, this.advt_id, this.type)
        .subscribe((data) => {
          window.alert('Office Representative updated successfully...');
        });
    } else if (this.type == 'sub') {
      this.service
        .updateOfficeRep(this.rep_id, this.sub_id, this.type)
        .subscribe((data) => {
          window.alert('Office Representative updated successfully...');
        });
    }
    this.dialogRef.close();
  }
}
