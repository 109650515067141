<p style="text-align: center; font-size: 25px;">Update Journal Voucher</p>

<div>
    <!-- <mat-select (selectionChange)="getMainExpName2($event)">
        <mat-option *ngFor="let exp of mainExpData" value="{{ exp['id'] }}">{{ exp["exp_name"]}}</mat-option>
      </mat-select>

      <mat-select >
        <mat-option *ngFor="let exp of getSubExpData1" value="{{ exp['sub_id'] }}">{{ exp["sub_exp_name"]
          }}</mat-option>
      </mat-select> -->
      <div class="form-row">
        <form [formGroup]="jform">
        <mat-form-field style="margin-left: 7%; width: 40%" class="example-full-width" appearance="outline">
            <mat-label>Select Main Expense</mat-label>
            <mat-select formControlName="expname" (selectionChange)="getMainExpName1($event)">
              <!-- (selectionChange)="getMainExpName($event)" -->
              <mat-option *ngFor="let exp of mainExpData" value="{{ exp['id'] }}">{{ exp["exp_name"]
                }}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field  style="margin-left: 2%; width: 40%" class="example-full-width"
            appearance="outline">
            <mat-label>Select Sub Expense</mat-label>
            <mat-select formControlName="subexp" (selectionChange)="getSubExpName1($event)">
              <!-- (selectionChange)="getMainExpName($event)" -->
              <mat-option *ngFor="let exp of getSubExpData" value="{{ exp['sub_id'] }}">{{ exp["sub_exp_name"]
                }}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline" style="margin-left: 7%; width: 40%">
            <mat-label>Journal Amount</mat-label>
            <input matInput formControlName="amt"/>
          </mat-form-field>
          <mat-form-field appearance="outline" style="margin-left: 2%; width: 40%">
            <mat-label>Narration</mat-label>
            <input matInput formControlName="narration"/>
          </mat-form-field>
          <mat-form-field appearance="outline" style="margin-left: 7%; width: 40%">
          <mat-select formControlName="amt_status" (selectionChange)="selectstatus($event)">
            <mat-option value="DR">Debit</mat-option>
            <mat-option value="CR">Credit</mat-option>
            </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline" style="margin-left: 2%; width: 40%; visibility: hidden;">
                <mat-label>id</mat-label>
                <input matInput formControlName="id"/>
              </mat-form-field>
            <button  style="margin-left: 2%; width: 30%" type="button"
            mat-raised-button color="primary" (click)="updatejvocuher()">
           Update Voucher
            </button>
        </form>
      </div>
</div>
