import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DigisubservicesService } from 'src/app/services/digisubservices.service';
import { ToWords } from 'to-words';

@Component({
  selector: 'app-advertisement-invoice',
  templateUrl: './advertisement-invoice.component.html',
  styleUrls: ['./advertisement-invoice.component.scss']
})
export class AdvertisementInvoiceComponent implements OnInit {



  rep_details
  viewmode = false

  invoiceData = {
    rep_id: 0,
    rep_name: '',
    rep_mobile: '',
    rep_address: '',
    advt_data: [],
    invoice_id: 123,
    invoice_date: '08-10-2021'
  }
  totalAmount = 0
  totalCommision = 0
  cgstamt = 0
  sgstamt = 0
  igstamt = 0
  taxablevalue = 0
  discount = 0
  toWords
  invoiceValue = 0
  taxAmountInWords
  remaining_amount = 0
  part_amount=0
  discounted_price=0
  isGstinvoice
  gst_number
  rep_invoice
  row_span_value=0
  constructor(private fb: FormBuilder, public service: DigisubservicesService,
    private dialogRef: MatDialogRef<AdvertisementInvoiceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    console.log(this.data)
  }

  ngOnInit(): void {
    this.isGstinvoice = this.data['book']['GST']
    this.gst_number = this.data['book']['GST_no']
    this.viewmode = this.data['view']
    this.rep_invoice = this.data['rep_invoice']
    //Cust Invoice

    if (!this.viewmode) {
      this.invoiceData = {
        rep_id: this.data['book']['rep']['cust_id'],
        rep_name: this.data['book']['rep']['name'],
        rep_mobile: this.data['book']['rep']['mobile'],
        rep_address: this.data['book']['rep']['address'],
        advt_data: this.data['book']['advts'],
        invoice_id: 123,
        invoice_date: '08-10-2021'
      }

      this.totalAmount = parseInt(this.data['book']['rep']['advt_amount'])
      this.discount = parseInt(this.data['book']['rep']['advt_discount'])
      this.part_amount = parseInt(this.data['book']['rep']['part_amount'])      
      this.remaining_amount = parseInt(this.data['book']['rep']['remaining_amount'])
      this.cgstamt = parseInt(this.data['book']['rep']['cgst'])
      this.sgstamt = parseInt(this.data['book']['rep']['sgst'])
      this.igstamt = parseInt(this.data['book']['rep']['igst'])
      this.discounted_price = this.totalAmount - this.part_amount + (this.cgstamt + this.sgstamt + this.igstamt)

      this.taxablevalue =  (this.totalAmount - this.discounted_price) + this.cgstamt + this.sgstamt + this.igstamt
      this.invoiceValue = this.taxablevalue 
      // Convert Total Amount in Words
      this.toWords = new ToWords()
      this.taxAmountInWords = this.toWords.convert(this.invoiceValue, { currency: true })

    } else {
      console.log(this.data['book']['advts']);
      this.invoiceData = {
        rep_id: this.data['book']['rep']['id'],
        rep_name: this.data['book']['rep']['name'],
        rep_mobile: this.data['book']['rep']['mobile'],
        rep_address: this.data['book']['rep']['address'],
        advt_data: this.data['book']['advts'],
        invoice_id: this.data['book']['rep']['invoice_id'],
        invoice_date: this.data['book']['rep']['invoice_date']
      }

        this.totalAmount = parseInt(this.data['book']['rep']['invoice_amount']);
        this.discount = parseInt(this.data['book']['rep']['total_discount']);
        this.cgstamt = parseInt(this.data['book']['rep']['cgst'])
        this.sgstamt = parseInt(this.data['book']['rep']['sgst'])
        this.igstamt = parseInt(this.data['book']['rep']['igst'])
        this.discounted_price = parseInt(this.data['book']['rep']['discounted_price'])
        this.taxablevalue = parseInt(this.data['book']['rep']['total_amount'])
        this.invoiceValue = this.taxablevalue 

        // Convert Total Amount in Words
        this.toWords = new ToWords()
        this.taxAmountInWords = this.toWords.convert(this.invoiceValue, { currency: true })
      
    }
  }

  print() {
    window.print()
  }

  save() {

    //CustInvoice
    this.service.InsertCustInvoiceadvertisement(this.invoiceData.rep_name, this.invoiceData.rep_mobile, this.invoiceData.rep_id,
      this.invoiceData.rep_address, "", "", this.gst_number, this.totalAmount,
      this.totalCommision, this.cgstamt, this.sgstamt, this.igstamt , this.discount,this.discounted_price).subscribe(data => {
        console.log(data)
        for (let index = 0; index < Object.keys(this.invoiceData['advt_data']).length; index++) {
          this.service.InsertCustInvoiceDataAdvertisment(parseInt(data['id']), this.invoiceData['advt_data'][index]['issue_name'],
            this.invoiceData['advt_data'][index]['advt_size'], this.invoiceData['advt_data'][index]['publish_date'],
            this.invoiceData['advt_data'][index]['publish_page']).subscribe(invoice => {
              console.log(invoice)
            })
        }
        setTimeout(() => {
          window.alert("Invoice " + parseInt(data['id']) + " has been created.")
          this.dialogRef.close()

        }, ((Object.keys(this.invoiceData['advt_data']).length / 2) * 1000));

      })
  }

}
