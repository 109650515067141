import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { DigisubservicesService } from 'src/app/services/digisubservices.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-update-print-order',
  templateUrl: './update-print-order.component.html',
  styleUrls: ['./update-print-order.component.scss'],
})
export class UpdatePrintOrderComponent implements OnInit {
  printData;
  prusthaData;
  typeData;

  testdata = [
    { name: '५० जी एस एम मफलीथो पेपर ' },
    { name: '६५ जी एस एम एल डब्लु सी पेपर ' },
    { name: '९० जी एस एम आर्ट  पेपर' },
  ];

  constructor(
    private dialogRef: MatDialogRef<UpdatePrintOrderComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private service: DigisubservicesService,
    private toaster: ToastrService
  ) {}

  ngOnInit(): void {
    this.printData = this.data['data'];

    this.getPrintDataById(this.printData['order_id']);
  }

  getPrintDataById(id: number) {
    this.service.getPrintDataByID(id).subscribe((res) => {
      console.log(res);
      if (res['success']) {
        this.prusthaData = res['prustha_data'];
        this.typeData = res['type_data'];
      }
    });
  }

  exportexcel(fileName): void {
    /* table id is passed over here */
    let element = document.getElementById('print-section');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, fileName + '.xlsx');
  }

  addTempData(event, item, key) {
    this.prusthaData.map((prustha) => {
      prustha['isUpdate'] = false;
      if (item['id'] == prustha['id']) {
        prustha[key] = parseInt(event.target.value);
        prustha['isUpdate'] = true;
      }
    });
    // switch (key) {
    //   case 'prushta_kr':
    //     this.tempData[index]['prushta_kr'] = event.target.value;
    //     this.prusthaData.map((prustha) => {
    //       if(item['id'] == prustha['id']){
    //         prustha[key] = parseInt(event.target.value);
    //       }
    //     });
    //     break;

    //   case 'prushta_sankhya':
    //     this.tempData[index]['prushta_sankhya'] = event.target.value;
    //     break;

    //   case 'prakar':
    //     this.tempData[index]['prakar'] = event.target.value;
    //     break;

    //   case 'pane_denar':
    //     this.tempData[index]['pane_denar'] = event.target.value;
    //     break;
    // }
    // console.log(this.tempData);
    this.calculateTotal();
  }

  calculateTotal() {
    let totalpsankhya = 0;
    for (let index = 0; index < Object.keys(this.prusthaData).length; index++) {
      totalpsankhya += parseInt(this.prusthaData[index]['prustha_sankhya']);
    }
    this.printData['total_prustha'] = totalpsankhya;
    this.printData['isUpdate'] = true;
  }

  addtempdata(event, item) {
    this.typeData.map((type) => {
      type['isUpdate'] = false;
      if (type['id'] == item['id']) {
        type['prati'] = parseInt(event.target.value);
        type['isUpdate'] = true;
      }
    });
    this.totalPrati();
  }

  totalPrati() {
    let totalprati = 0;
    for (let index = 0; index < Object.keys(this.typeData).length; index++) {
      totalprati += parseInt(this.typeData[index]['prati']);
    }
    this.printData['total_prati'] = totalprati;
    this.printData['isUpdate'] = true;
    // this.typeData.push(totalprati)
  }

  update() {
    console.log(this.printData, "1");
    console.log(this.prusthaData, "2");
    console.log(this.typeData, "3");
    
    // const data:any = {
    //   "order_id":this.prusthaData.order_id,
    //   "pane_denar":this.prusthaData.pane_denar,
    //   "prakar":this.prusthaData.prakar,
    //   "prustha_kr":this.prusthaData.prustha_kr,
    //   "prustha_sankhya": this.prusthaData.prustha_sankhya
    // }
    this.service.updateprintorder(this.prusthaData).subscribe((res) => {}
  );
 

  this.service.updateprintorder2(this.typeData ).subscribe((res) => {}
  );

  let orderid = this.typeData[0].order_id;
  let total = this.printData.total_prati

  this.service.updateprintorder3(orderid , total ).subscribe((res) => {}
);

this.dialogRef.close();
this.toaster.success('success', 'Print Order Updated Succesfully');

this.ngOnInit();


  }
  
  
}
//     this.service.insertPrintOrderData(data).subscribe((res) => {

