import { DigisubservicesService } from './../../services/digisubservices.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { EncdecriptService } from 'src/app/services/encdecrypt.service';

import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  form : FormGroup;
  data1;
  hide = true;
  constructor(public fb : FormBuilder, public decryptService : EncdecriptService , 
    public service : DigisubservicesService, public router : Router) 
  {
    this.form = this.fb.group({
      mobilenumber : [''],
      password : ['']
    });
  }
  disableLoading = true;

  ngOnInit() {}
  ngOnDestroy() {}

  submitForm()
  {
    let encrypt = this.decryptService.set('123456$#@$^@1ERF',this.form.get('password').value);
    ////console.log("Decrypted : ",this.decryptService.get('123456$#@$^@1ERF', decrypt));
    
    this.disableLoading = false;
    //console.log(this.disableLoading)
    let mobile = parseInt(this.form.value.mobilenumber);
    this.form.patchValue({mobilenumber : mobile});
    this.form.patchValue({password : encrypt});
    let resultState = this.service.get_User(this.form.value);
    resultState.subscribe((data)=>{
      this.data1 = data;
      //console.log("Data from PHP : ",this.data1);
      
      if(this.form.value.mobilenumber == this.data1.mobile)
      {

        this.router.navigate(['/board/dashboard']);
        sessionStorage.setItem("mobile",this.data1.mobile);
        sessionStorage.setItem("id",this.data1.id);

      }
      else{
        this.disableLoading = true;
        window.alert("Login Failed, Please try again")
        this.router.navigate(['/']);
      }
    });
  }
}