import { Component, Inject } from '@angular/core';
import { DigisubservicesService } from '../services/digisubservices.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as XLSX from 'xlsx';


@Component({
  selector: 'app-activeinactivebusinessreport',
  
  templateUrl: './activeinactivebusinessreport.component.html',
  styleUrls: ['./activeinactivebusinessreport.component.scss']
})
export class ActiveinactivebusinessreportComponent {

  startDate = '';
  endDate = '';
  rep_name;
  today = new Date();
  rojmelData;
  isLoading: boolean = false;
  subTypesName = [
    { name: 'वार्षिक', isVisible: false },
    { name: 'त्रैवार्षिक', isVisible: false },
    { name: 'पंचवार्षिक', isVisible: false },
    { name: 'रा.स्व.संघ ग्रंथ', isVisible: false },
    // { name: 'मुदत ठेव (पंचवार्षिक वर्गणीदार)', isVisible: false },
    // { name: 'रामसेवक ग्रंथयात्री', isVisible: false },
    // { name: 'लोकनेता गंथयात्री वार्षिक', isVisible: false },
    // { name: 'लोकमान्य ग्रंथयात्री वार्षिक वर्गणी', isVisible: false },
    // { name: 'हिंदुत्व ग्रंथयात्री', isVisible: false },
    // { name: 'हिंदुत्व 1', isVisible: false },
    // { name: 'हिंदुत्व 2', isVisible: false },
    // { name: 'आजीव', isVisible: false },
    // { name: 'अनामत', isVisible: false },
  ];
  totalth = 0;
  finalData = [];
  datewisedata: any;

  constructor(
    private service: DigisubservicesService,
    private dialogRef: MatDialogRef<ActiveinactivebusinessreportComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    console.log(this.data, "thid data")
    this.isLoading = true;
    this.startDate = this.data['startDate'];
    this.endDate = this.data['endDate'];
    this.rep_name = this.data['rep_name'];
    // console.log(this.startDate, this.endDate);
    // console.log(this.data['data']);
    this.datewisedata = this.data['dataset'];
    debugger;
  }

  exportExcel(fileName): void {
    /* table id is passed over here */
    let element = document.getElementById('print-section');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, fileName + '.xlsx');
  }
}
