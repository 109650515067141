<div style="overflow: hidden;">
    <div>
        <mat-dialog-actions>
            <Button mat-dialog-close mat-raised-button class="btn" style="margin-left: 80%;">
                Close
            </Button>
        </mat-dialog-actions>
    </div>
    <div>
        <h2 style="text-align: center;    margin-top: 5%;">Update Prant Name</h2>
        <form [formGroup]="prantForm" (ngSubmit)="updatePrant()">
            <div class="form-row">
                <mat-form-field style="width: 80%; margin-top: 10%; margin-left: 10%" appearance="outline">
                    <mat-label>State Name:</mat-label>
                    <input type="text" matInput formControlName="prant_name" required />
                </mat-form-field>
            </div>
            <div class="form-group">
                <button style="width: 60%;margin-left: 20%;margin-top: 5%;" [disabled]="prantForm.invalid"
                    class="btn btn-info">Update</button>
            </div>
        </form>
    </div>
</div>