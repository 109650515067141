import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DigisubservicesService } from 'src/app/services/digisubservices.service';
import { ToWords } from 'to-words';

@Component({
  selector: 'app-show-book-invoice',
  templateUrl: './show-book-invoice.component.html',
  styleUrls: ['./show-book-invoice.component.scss'],
})
export class ShowBookInvoiceComponent implements OnInit {
  book;
  bookinvoice;
  bookinvoicedata;
  total_total = 0;
  shipping_charges_total = 0;
  totals;
  toWords;
  amtinwords;
  address;

  constructor(
    public service: DigisubservicesService,
    private dialogRef: MatDialogRef<ShowBookInvoiceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    console.log(data);
  }

  ngOnInit(): void {
    this.book = this.data['book'];
    console.log(this.book['id']);
    this.service.getBookInviceForView(this.book['id']).subscribe((data) => {
      console.log(data);
      this.bookinvoice = data;
      if (!this.bookinvoice.length) {
        this.dialogRef.close();
        alert('Receipt is not Present.');
      }
    });

    this.service.getBookInviceDataForView(this.book['id']).subscribe((data) => {
      console.log(data);
      this.bookinvoicedata = data;

      for (
        let index = 0;
        index < Object.keys(this.bookinvoicedata).length;
        index++
      ) {
        this.total_total =
          this.total_total + parseInt(this.bookinvoicedata[index]['total']);
        this.shipping_charges_total =
          this.shipping_charges_total +
          parseInt(this.bookinvoicedata[index]['shipping_charges']);
      }

      this.totals = this.total_total + this.shipping_charges_total;
      this.toWords = new ToWords();
      this.amtinwords = this.toWords.convert(this.totals, {
        currency: true,
      });
    });
    this.address = `${this.bookinvoice[0]['receiver_address']} ${this.bookinvoice[0]['taluka']}, ${this.bookinvoice[0]['district']}, ${this.bookinvoice[0]['state']}, ${this.bookinvoice[0]['country']},  ${this.bookinvoice[0]['pincode']}`;
    console.log(this.address);
  }

  print() {
    window.print();
  }
}
