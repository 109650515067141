import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-show-book-seller-out-standing-report',
  templateUrl: './show-book-seller-out-standing-report.component.html',
  styleUrls: ['./show-book-seller-out-standing-report.component.scss']
})
export class ShowBookSellerOutStandingReportComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
