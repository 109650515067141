import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-datewise',
  templateUrl: './datewise.component.html',
  styleUrls: ['./datewise.component.scss'],
})
export class DatewiseComponent implements OnInit {
  datewisedata;
  today = new Date();
  startdate = '';
  enddate = '';
  datewiselist = [];
  tempdata = [];
  // data1 = [];
  // atotal_total = 0;
  // anew_total = 0;
  // arenew_total = 0;
  // vnew_total = 0;
  // vrenew_total = 0;
  // vtotal_total = 0;
  // tnew_total = 0;
  // trenew_total = 0;
  // ttotal_total = 0;
  // pnew_total = 0;
  // prenew_total = 0;
  // ptotal_total = 0;
  // snew_total = 0;
  // srenew_total = 0;
  // stotal_total = 0;
  // anamat_total = 0;
  // punchthev_total = 0;
  // other_total = 0;
  isLoading: boolean = false;
  subTypesName = [
    { name: 'वार्षिक', isVisible: false },
    { name: 'त्रैवार्षिक', isVisible: false },
    { name: 'पंचवार्षिक', isVisible: false },
    // { name: 'मुदत ठेव (पंचवार्षिक वर्गणीदार)', isVisible: false },
    // { name: 'रामसेवक ग्रंथयात्री', isVisible: false },
    // { name: 'लोकनेता गंथयात्री वार्षिक', isVisible: false },
    // { name: 'लोकमान्य ग्रंथयात्री वार्षिक वर्गणी', isVisible: false },
    // { name: 'हिंदुत्व ग्रंथयात्री', isVisible: false },
    // { name: 'हिंदुत्व 1', isVisible: false },
    // { name: 'हिंदुत्व 2', isVisible: false },
    // { name: 'आजीव', isVisible: false },
    // { name: 'अनामत', isVisible: false },
  ];
  totalth = 0;
  finalData = [];

  constructor(
    private dialogref: MatDialogRef<DatewiseComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.datewisedata = this.data['datewisedata'];
    this.startdate = this.data['startdate'];
    this.enddate = this.data['enddate'];
    // console.log(formatDate(new Date(), 'MMMM', 'en-IN', 'GMT+0530'));
    let data = this.uniqueData(this.datewisedata, (a) => a.subscription_type);

    for (let i = 0; i < Object.keys(data).length; i++) {
      let flag = false;
      for (let j = 0; j < Object.keys(this.subTypesName).length; j++) {
        if (data[i]['subscription_type'] === this.subTypesName[j]['name']) {
          this.subTypesName[j]['isVisible'] = true;
          flag = true;
          break;
        }
      }
      if (!flag) {
        // if (
        //   data[i]['Subscription_Type'] !== 'हितचिंतक सप्रेम भेट' ||
        //   data[i]['Subscription_Type'] !== 'सप्रेम भेट - संपादकीय विभाग' ||
        //   data[i]['Subscription_Type'] !== 'जाहिरात स. भेट' ||
        //   data[i]['Subscription_Type'] !== 'संघ परिवार सप्रेम भेट' ||
        //   data[i]['Subscription_Type'] !== 'वर्गणी सप्रेम भेट'
        // ) {
        this.subTypesName.push({
          name: data[i]['subscription_type'],
          isVisible: true,
        });
        // }
      }
    }

    // console.log(this.subTypesName);
    this.totalth = this.subTypesName.length * 3 + 4;
    // this.getDateORrepwiselist();
    this.setData();
  }

  setData() {
    // console.log(this.rojmelData);
    let uniqueDateData = this.uniqueData(
      this.datewisedata,
      (a) => a.entry_date
    );
    uniqueDateData.sort((a: any, b: any) => {
      return (
        new Date(a.entry_date).getTime() - new Date(b.entry_date).getTime()
      );
    });
    // console.log(
    // );
    let finalTotal = {};
    this.subTypesName.map((b: any, i) => {
      if (b.isVisible) {
        let name = this.getKey(b.name);
        finalTotal[name + '_एकूण'] = 0;
        finalTotal[name + '_नवीन'] = 0;
        finalTotal[name + '_नूतनीकरण'] = 0;
        if (i == 0) {
          finalTotal['name'] = 'एकूण';
          finalTotal['एकूण'] = 0;
          finalTotal['नवीन'] = 0;
          finalTotal['नूतनीकरण'] = 0;
        }
      }
    });

    for (let i = 0; i < uniqueDateData.length; i++) {
      let datewiseData = this.datewisedata.filter((a) => {
        return a.entry_date === uniqueDateData[i]['entry_date'];
      });
      // console.log(datewiseData);
      let totalData = {};
      this.subTypesName.map((b: any, i) => {
        if (b.isVisible) {
          let name = this.getKey(b.name);
          totalData[name + '_एकूण'] = 0;
          totalData[name + '_नवीन'] = 0;
          totalData[name + '_नूतनीकरण'] = 0;
          if (i == 0) {
            totalData['एकूण'] = 0;
            totalData['नवीन'] = 0;
            totalData['नूतनीकरण'] = 0;
          }
        }
      });
      datewiseData.map((a: any, i) => {
        if (i == 0) totalData['entry_date'] = a.entry_date;
        let name = this.getKey(a.subscription_type);
        totalData['crdt_voucher_date']=a.crdt_voucher_date
        totalData[name + '_एकूण'] += parseInt(a.total_amt);
        finalTotal[name + '_एकूण'] += parseInt(a.total_amt);
        totalData[name + '_नवीन'] += a.rcpt_type == 'New' ? 1 : 0;
        finalTotal[name + '_नवीन'] += a.rcpt_type == 'New' ? 1 : 0;
        totalData[name + '_नूतनीकरण'] += a.rcpt_type == 'Renew' ? 1 : 0;
        finalTotal[name + '_नूतनीकरण'] += a.rcpt_type == 'Renew' ? 1 : 0;
        totalData['एकूण'] += parseInt(a.total_amt);
        finalTotal['एकूण'] += parseInt(a.total_amt);
        totalData['नवीन'] += a.rcpt_type == 'New' ? 1 : 0;
        finalTotal['नवीन'] += a.rcpt_type == 'New' ? 1 : 0;
        totalData['नूतनीकरण'] += a.rcpt_type == 'Renew' ? 1 : 0;
        finalTotal['नूतनीकरण'] += a.rcpt_type == 'Renew' ? 1 : 0;
      });
      // datewiseData.push(totalData);
      this.finalData.push(totalData);
    }
    this.finalData.push(finalTotal);
    // console.log(this.finalData);
    this.isLoading = false;
  }

  getKey(name) {
    return name.replaceAll(' ', '_');
  }

  uniqueData(data, key) {
    return [...new Map(data.map((a) => [key(a), a])).values()];
  }

  exportExcel(fileName) {
    /* table id is passed over here */
    let element = document.getElementById('print-section');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, fileName + '.xlsx');
  }

  // getDateORrepwiselist() {
  //   for (
  //     let index = 0;
  //     index < Object.keys(this.datewisedata).length;
  //     index++
  //   ) {
  //     let flag = false;
  //     if (Object.keys(this.tempdata).length == 0) {
  //       this.tempdata.push({
  //         district: this.datewisedata[index]['district'],
  //         entry_date: this.datewisedata[index]['entry_date'],
  //         name: this.datewisedata[index]['name'],
  //         office_rep_name: this.datewisedata[index]['office_rep_name'],
  //         rcpt_no: this.datewisedata[index]['rcpt_no'],
  //         rcpt_type: this.datewisedata[index]['rcpt_type'],
  //         rep_name: this.datewisedata[index]['rep_name'],
  //         subscription_type: this.datewisedata[index]['subscription_type'],
  //         taluka: this.datewisedata[index]['taluka'],
  //         title: this.datewisedata[index]['title'],
  //         total_amt: this.datewisedata[index]['total_amt'],
  //         atotal: 0,
  //         anew: 0,
  //         arenew: 0,
  //         vnew: 0,
  //         vrenew: 0,
  //         vtotal: 0,
  //         tnew: 0,
  //         trenew: 0,
  //         ttotal: 0,
  //         pnew: 0,
  //         prenew: 0,
  //         ptotal: 0,
  //         snew: 0,
  //         srenew: 0,
  //         stotal: 0,
  //         anamat: 0,
  //         punchthev: 0,
  //         other: 0,
  //       });
  //     } else {
  //       for (
  //         let index1 = 0;
  //         index1 < Object.keys(this.tempdata).length;
  //         index1++
  //       ) {
  //         if (
  //           this.tempdata[index1]['entry_date'] ==
  //           this.datewisedata[index]['entry_date']
  //         ) {
  //           flag = true;
  //         }
  //       }
  //       if (!flag) {
  //         this.tempdata.push({
  //           district: this.datewisedata[index]['district'],
  //           entry_date: this.datewisedata[index]['entry_date'],
  //           name: this.datewisedata[index]['name'],
  //           office_rep_name: this.datewisedata[index]['office_rep_name'],
  //           rcpt_no: this.datewisedata[index]['rcpt_no'],
  //           rcpt_type: this.datewisedata[index]['rcpt_type'],
  //           rep_name: this.datewisedata[index]['rep_name'],
  //           subscription_type: this.datewisedata[index]['subscription_type'],
  //           taluka: this.datewisedata[index]['taluka'],
  //           title: this.datewisedata[index]['title'],
  //           total_amt: this.datewisedata[index]['total_amt'],
  //           atotal: 0,
  //           anew: 0,
  //           arenew: 0,
  //           vnew: 0,
  //           vrenew: 0,
  //           vtotal: 0,
  //           tnew: 0,
  //           trenew: 0,
  //           ttotal: 0,
  //           pnew: 0,
  //           prenew: 0,
  //           ptotal: 0,
  //           snew: 0,
  //           srenew: 0,
  //           stotal: 0,
  //           anamat: 0,
  //           punchthev: 0,
  //           other: 0,
  //         });
  //       }
  //     }
  //   }

  //   // console.log('tempdata', this.tempdata);
  //   for (let index = 0; index < Object.keys(this.tempdata).length; index++) {
  //     let arr = this.datewisedata.filter(
  //       (a) => a['entry_date'] == this.tempdata[index]['entry_date']
  //     );

  //     let atotal = 0;
  //     let anew = 0;
  //     let arenew = 0;
  //     let vnew = 0;
  //     let vrenew = 0;
  //     let vtotal = 0;
  //     let tnew = 0;
  //     let trenew = 0;
  //     let ttotal = 0;
  //     let pnew = 0;
  //     let prenew = 0;
  //     let ptotal = 0;
  //     let snew = 0;
  //     let srenew = 0;
  //     let stotal = 0;
  //     let anamat = 0;
  //     let punchthev = 0;
  //     let other = 0;
  //     for (let index1 = 0; index1 < Object.keys(arr).length; index1++) {
  //       atotal = atotal + parseInt(arr[index1]['total_amt']);
  //       if (arr[index1]['rcpt_type'] == 'New') {
  //         anew = anew + 1;
  //       } else if (arr[index1]['rcpt_type'] == 'Renew') {
  //         arenew = arenew + 1;
  //       }

  //       if (arr[index1]['subscription_type'] == 'वार्षिक') {
  //         vtotal = vtotal + parseInt(arr[index1]['total_amt']);
  //         if (arr[index1]['rcpt_type'] == 'New') {
  //           vnew = vnew + 1;
  //         } else if (arr[index1]['rcpt_type'] == 'Renew') {
  //           vrenew = vrenew + 1;
  //         }
  //       } else if (arr[index1]['subscription_type'] == 'त्रैवार्षिक') {
  //         ttotal = ttotal + parseInt(arr[index1]['total_amt']);
  //         if (arr[index1]['rcpt_type'] == 'New') {
  //           tnew = tnew + 1;
  //         } else if (arr[index1]['rcpt_type'] == 'Renew') {
  //           trenew = trenew + 1;
  //         }
  //       } else if (arr[index1]['subscription_type'] == 'पंचवार्षिक') {
  //         ptotal = ptotal + parseInt(arr[index1]['total_amt']);
  //         if (arr[index1]['rcpt_type'] == 'New') {
  //           pnew = pnew + 1;
  //         } else if (arr[index1]['rcpt_type'] == 'Renew') {
  //           prenew = prenew + 1;
  //         }
  //       } else if (arr[index1]['subscription_type'] == 'सहयोगी') {
  //         stotal = stotal + parseInt(arr[index1]['total_amt']);
  //         if (arr[index1]['rcpt_type'] == 'New') {
  //           snew = snew + 1;
  //         } else if (arr[index1]['rcpt_type'] == 'Renew') {
  //           srenew = srenew + 1;
  //         }
  //       } else if (arr[index1]['subscription_type'] == 'सहयोगी') {
  //         anamat = anamat + parseInt(arr[index1]['total_amt']);
  //       } else if (arr[index1]['subscription_type'] == 'सहयोगी') {
  //         punchthev = punchthev + parseInt(arr[index1]['total_amt']);
  //       } else if (arr[index1]['subscription_type'] == 'सहयोगी') {
  //         other = other + parseInt(arr[index1]['total_amt']);
  //       }
  //     }

  //     this.tempdata[index]['atotal'] = atotal;
  //     this.tempdata[index]['anew'] = anew;
  //     this.tempdata[index]['arenew'] = arenew;
  //     this.tempdata[index]['vnew'] = vnew;
  //     this.tempdata[index]['vrenew'] = vrenew;
  //     this.tempdata[index]['vtotal'] = vtotal;
  //     this.tempdata[index]['tnew'] = tnew;
  //     this.tempdata[index]['trenew'] = trenew;
  //     this.tempdata[index]['ttotal'] = ttotal;
  //     this.tempdata[index]['pnew'] = pnew;
  //     this.tempdata[index]['prenew'] = prenew;
  //     this.tempdata[index]['ptotal'] = ptotal;
  //     this.tempdata[index]['snew'] = snew;
  //     this.tempdata[index]['srenew'] = srenew;
  //     this.tempdata[index]['stotal'] = stotal;
  //     this.tempdata[index]['anamat'] = anamat;
  //     this.tempdata[index]['punchthev'] = punchthev;
  //     this.tempdata[index]['other'] = other;

  //     this.datewiselist.push(arr);
  //   }
  //   // console.log('datewiselist', this.datewiselist);
  //   for (let index = 0; index < Object.keys(this.tempdata).length; index++) {
  //     this.atotal_total =
  //       this.atotal_total + parseInt(this.tempdata[index]['atotal']);
  //     this.anew_total =
  //       this.anew_total + parseInt(this.tempdata[index]['anew']);
  //     this.arenew_total =
  //       this.arenew_total + parseInt(this.tempdata[index]['arenew']);
  //     this.vnew_total =
  //       this.vnew_total + parseInt(this.tempdata[index]['vnew']);
  //     this.vrenew_total =
  //       this.vrenew_total + parseInt(this.tempdata[index]['vrenew']);
  //     this.vtotal_total =
  //       this.vtotal_total + parseInt(this.tempdata[index]['vtotal']);
  //     this.tnew_total =
  //       this.tnew_total + parseInt(this.tempdata[index]['tnew']);
  //     this.trenew_total =
  //       this.trenew_total + parseInt(this.tempdata[index]['trenew']);
  //     this.ttotal_total =
  //       this.ttotal_total + parseInt(this.tempdata[index]['ttotal']);
  //     this.pnew_total =
  //       this.pnew_total + parseInt(this.tempdata[index]['pnew']);
  //     this.prenew_total =
  //       this.prenew_total + parseInt(this.tempdata[index]['prenew']);
  //     this.ptotal_total =
  //       this.ptotal_total + parseInt(this.tempdata[index]['ptotal']);
  //     this.snew_total =
  //       this.snew_total + parseInt(this.tempdata[index]['snew']);
  //     this.srenew_total =
  //       this.srenew_total + parseInt(this.tempdata[index]['srenew']);
  //     this.stotal_total =
  //       this.stotal_total + parseInt(this.tempdata[index]['stotal']);
  //     this.anamat_total =
  //       this.anamat_total + parseInt(this.tempdata[index]['anamat']);
  //     this.punchthev_total =
  //       this.punchthev_total + parseInt(this.tempdata[index]['punchthev']);
  //     this.other_total =
  //       this.other_total + parseInt(this.tempdata[index]['other']);
  //   }
  // }
}
