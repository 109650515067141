<div *ngIf="!isLoading">
    <div style="margin-left: 80%; margin-bottom: 2%;">
        <button class="noPrint" style="margin-top: 2%;" mat-raised-button color="primary"
            [printStyle]="{table : {'border-collapse':'collapse'}, td : {'border': 'solid 1px'}, th : {'border': 'solid 1px'}, p : {'text-align': 'center','font-size':'15px','font-weight':'600'}, div : {'text-align':'left'}, span : {'text-align':'right','display':'block'}}"
            printSectionId="print-section" ngxPrint>
            print
        </button>
        <button class="noPrint" style="margin-top: 2%; margin-left: 2%" (click)="exportExcel('Advt_Invoice_Register')"
            mat-raised-button color="primary">
            Export
        </button>
        <button class="noPrint" style="margin-top: 2%" mat-button mat-dialog-close>
            Close
        </button>
    </div>
    <ng-container *ngIf="reportType == 'In Detail'">
        <div class="container" id="print-section">
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th colspan="13">हिंदुस्थान प्रकाशन संस्था - विवेक साप्ताहिक - जाहिरात विभाग - Advt Invoice
                            Register
                        </th>
                        <th colspan="2">In Detail</th>
                        <th colspan="3">दिनांक - {{ today | date:"dd-MM-yyy" }}</th>
                        <th colspan="2">पृष्ठ क्र. -</th>
                    </tr>
                    <tr>
                        <th></th>
                        <th colspan="7" class="leftAlign">
                            <!-- <div>Issue Name : {{ issueName }}</div> -->
                        </th>
                        <th colspan="6" class="leftAlign">
                            <div>Issue Date :</div>
                        </th>
                        <th colspan="6" class="leftAlign">
                            <div>Date : From : {{ startDate | date:"dd-MM-yyy" }} To : {{ endDate | date:"dd-MM-yyy" }}
                            </div>
                        </th>
                    </tr>
                    <tr>
                        <th rowspan="2" style="font-size: 14px !important;">Invoice No.</th>
                        <th rowspan="2" style="font-size: 14px !important;">Invoice Date</th>
                        <th rowspan="2" style="font-size: 14px !important;">Advertiser <br> Name</th>
                        <th rowspan="2" style="font-size: 14px !important;">Customer <br>  Name</th>
                        <th rowspan="2" style="font-size: 14px !important;">Issue  Name</th>
                        <th rowspan="2" style="font-size: 14px !important;">Issue Date</th>
                        <th rowspan="2" style="font-size: 14px !important;">Representative <br> Name</th>
                        <th rowspan="2" style="font-size: 14px !important;">Office <br> Representative <br> Name</th>
                        <th colspan="5" style="font-size: 14px !important;">Weekly Issue <br> Date</th>
                        <th rowspan="2" style="font-size: 14px !important;">Advt. <br> Amount</th>
                        <th rowspan="2" style="font-size: 14px !important;">Discount  <br>Amount</th>
                        <th rowspan="2" style="font-size: 14px !important;">Amount before GST</th>
                        <th rowspan="2" style="font-size: 14px !important;">GST/CGST <br> Amount</th>
                        <th rowspan="2" style="font-size: 14px !important;">Total Invoice <br> Amount</th>
                        <th rowspan="2" style="font-size: 14px !important;">Paid <br> Amount</th>
                        <th rowspan="2" style="font-size: 14px !important;">Unpaid <br> Amount</th>
                    </tr>
                    <tr>
                        <th>1st Week</th>
                        <th>2nd Week</th>
                        <th>3rd Week</th>
                        <th>4th Week</th>
                        <th>5th Week</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let data of filteredAdvtInvoiceData; ">
                        <ng-container *ngIf="!end">
                            <ng-container>
                                <tr>
                                    <td style="text-align: start;">{{ data['invoice_no'] }}</td>
                                    <td style="text-align: start;">{{ data['invoice_date'] }}</td>
                                    <td style="text-align: start;">{{ data['advt_name'] }}</td>
                                    <td style="text-align: start;">{{ data['cust_name'] }}</td>
                                    <td style="text-align: start;">{{ data['issue_name'] }}</td>
                                    <td style="text-align: start;"> {{ data['issue_date'] }}</td>
                                    <td style="text-align: start;">{{ data['rep_name'] }}</td>
                                    <td style="text-align: start;">{{ data['off_rep_name'] }}</td>

                                    <!-- <td>{{ data['firstweek_issue_date'] }}</td> -->
                                    <td><ng-container *ngIf="data['first_week'] != '0'; else week1">{{  data['first_week_amount'] }}</ng-container>
                                        <ng-template #week1>0</ng-template></td>
                                    <td><ng-container *ngIf="data['second_week'] != '0'; else week2">{{  data['second_week_amount']  }} </ng-container>
                                            <ng-template #week2>0</ng-template></td>
                                    <td><ng-container *ngIf="data['third_week'] != '0'; else week3">{{  data.third_week_amount }}</ng-container>
                                                <ng-template #week3>0</ng-template></td>
                                    <td><ng-container *ngIf="data['fourth_week'] != '0'; else week4">{{  data['fourth_week_amount'] }}</ng-container>
                                                    <ng-template #week4>0</ng-template></td>
                                    <td><ng-container *ngIf="data['fifth_week'] != '0'; else week5">{{  data['fifth_week_amount'] }} </ng-container>
                                                        <ng-template #week5>0</ng-template></td>
                                        
                                    <!-- <td>{{ data['secondweek_issue_date'] }}</td>
                                    <td>{{ data['thirdweek_issue_date'] }}</td>
                                    <td>{{ data['fourthweek_issue_date'] }}</td>
                                    <td>{{ data['fifthweek_issue_date'] }}</td> -->
                                    <td style="text-align: end;"><span>{{ data['advt_amt'] }}</span></td>
                                    <td style="text-align: end;"><span>{{ data['discount'] }}</span></td>
                                    <td style="text-align: end;"><span>{{ data['net_amt'] }}</span></td>
                                    <td style="text-align: end;"><span>{{ data['gst'] }}</span></td>
                                    <td style="text-align: end;"><span>{{ data['net_amt']  + data['gst'] }}</span></td>
                                    <td style="text-align: end;"><span>{{ data['received_amt'] }}</span></td>
                                    <td style="text-align: end;"><span>{{  data['net_amt']  + data['gst'] - data['received_amt'] }}</span></td>


                                </tr>
                                <!-- <tr *ngIf="end1">
                                    <td colspan="8" class="boldText">
                                        <p>महिन्यांची एकूण : {{ data['issue_date'] | date:"MMM" }}</p>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td class="right">
                                        <p><span>
                                                {{ data['totalAdvtAmt'] }}
                                            </span></p>
                                    </td>
                                    <td class="right">
                                        <p><span>{{ data['totalDisAmt'] }}</span></p>
                                    </td>
                                    <td class="right">
                                        <p><span>{{ data['totalNetAmt'] }}</span></p>
                                    </td>
                                    <td class="right">
                                        <p><span>{{ data['totalCGST'] + data['totalSGST'] }}</span></p>
                                    </td>
                                    <td class="right">
                                        <p><span>{{ data['totalInvoiceAmount'] }}</span></p>
                                    </td>
                                    <td></td>
                                    <td></td>
                                </tr> -->
                            </ng-container>
                        </ng-container>
                        <!-- <ng-container *ngIf="end">
                            <tr>
                                <td colspan="8" class="boldText">
                                    <p>एकूण</p>
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td class="right">
                                    <p><span>{{ item['totalAdvtAmt1'] }}</span></p>
                                </td>
                                <td class="right">
                                    <p><span>{{ item['totalDisAmt1'] }}</span></p>
                                </td>
                                <td class="right">
                                    <p><span>{{ item['totalNetAmt1'] }}</span></p>
                                </td>
                                <td class="right">
                                    <p><span>{{ item['totalCGST1'] + item['totalSGST1'] }}</span></p>
                                </td>
                                <td class="right">
                                    <p><span>{{ item['totalInvoiceAmount1'] }}</span></p>
                                </td>
                                <td></td>
                                <td></td>
                            </tr>
                        </ng-container> -->
                    </ng-container>
                </tbody>
                <tfoot>
                    <td style="font-weight: bold;  border-bottom: 1px solid #000; border-right: 1px solid #000; border-left: 1px solid #000;" class="right" colspan="13">Total</td>
                    <td style="text-align: end; font-weight: bold; border-bottom: 1px solid #000; border-right: 1px solid #000;" class="right">{{advtamount}}</td>
                    <td style="text-align: end; font-weight: bold; border-bottom: 1px solid #000; border-right: 1px solid #000;" class="right">{{discountamt}}</td>
                    <td style="text-align: end; font-weight: bold; border-bottom: 1px solid #000; border-right: 1px solid #000;" class="right">{{netamount}}</td>
                    <td style="text-align: end; font-weight: bold; border-bottom: 1px solid #000; border-right: 1px solid #000;" class="right">{{gstcst}}</td>
                    <td style="text-align: end; font-weight: bold; border-bottom: 1px solid #000; border-right: 1px solid #000;" class="right">{{netamount + gstcst}}</td>
                    <td style="text-align: end; font-weight: bold; border-bottom: 1px solid #000; border-right: 1px solid #000;" class="right">{{paidamount}}</td>
                    <td style="text-align: end; font-weight: bold; border-bottom: 1px solid #000; border-right: 1px solid #000;" class="right">{{netamount + gstcst - paidamount}}</td>
                </tfoot>
            </table>
        </div>
    </ng-container>
    <ng-container *ngIf="reportType == 'Briefly'">
        <div class="container" id="print-section">
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th colspan="12">हिंदुस्थान प्रकाशन संस्था - विवेक साप्ताहिक - जाहिरात विभाग
                        </th>
                        <th colspan="2">दिनांक - {{ today | date:"dd-MM-yyy" }}</th>
                        <th colspan="2">पृष्ठ क्र. -</th>
                    </tr>
                    <tr>
                        <th colspan="4"> Advt Invoice Register</th>
                        <th colspan="3">Briefly</th>
                        <th colspan="3">Date</th>
                        <th colspan="3">From :- </th>
                        <th colspan="3">To :-</th>
                    </tr>
                    
                    <tr>
                        <th>Invoice No.</th>
                        <th>Invoice Date</th>
                        <th>Advertiser Name</th>
                        <th>Customer Name</th>
                        <th>Issue Name</th>
                        <th>Issue Date</th>
                        <th>Advt. Amount</th>
                        <th>Discount Amount</th>
                        <!-- <th>Net Amount</th> -->
                        <th>Amount Before GST</th>
                        <th>CGST</th>
                        <th>SGST</th>
                        <th>IGST</th>
                        <th>Total GST</th>
                        <th>Total Invoice Amount</th>
                        <th>Paid Amount</th>
                        <th>Unpaid Amount</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container  *ngFor="let data of filteredAdvtInvoiceData; let i = index">
                        <tr>
                            <td>{{ data['invoice_no'] }}</td>
                            <td>{{ data['invoice_date'] }}</td>
                            <td>{{ data['advt_name'] }}</td>
                            <td>{{ data['cust_name'] }}</td>
                            <td>{{ data['issue_name'] }}</td>
                            <td>{{ data['issue_date'] }}</td>
                            <td class="right text-end" style="text-align: end;">{{ data['advt_amt'] }}</td>
                            <td class="right text-end" style="text-align: end;">{{ data['discount'] }}</td>
                            <!-- <td class="right text-end" style="text-align: end;">{{ data['net_amt'] }}</td> -->
                            <td class="right text-end" style="text-align: end;">{{ data['beforegst_amt']  }}</td>
                            <td class="right text-end" style="text-align: end;">{{ data['cgst'] }}</td>
                            <td class="right text-end" style="text-align: end;">{{ data['sgst'] }}</td>
                            <td class="right text-end" style="text-align: end;">{{ data['igst'] }}</td>
                            <td class="right text-end" style="text-align: end;">{{ data['gst']  }}</td>
                            <td class="right text-end" style="text-align: end;">{{ data['amount'] }}</td>
                            <td class="right text-end" style="text-align: end;">{{data.received_amt}}</td>
                            <td class="right text-end" style="text-align: end;">{{data.pending_amt}}</td>
            </tr>
                    </ng-container>
                   
                </tbody>
                <tfoot>
                    <tr>
                        
                        <td style="font-weight: bold; font-size: 16px; text-align: end;" colspan="6">Total:</td>
                        <td style="font-weight: bold; font-size: 16px; text-align: end;">{{advtamount}}</td>
                        <td style="font-weight: bold; font-size: 16px; text-align: end;">{{discountamt}}</td>
                        <!-- <td style="font-weight: bold; font-size: 16px; text-align: end;">{{netamount}}</td> -->
                        <td style="font-weight: bold; font-size: 16px; text-align: end;">{{netamount}}</td>
                        <td style="font-weight: bold; font-size: 16px; text-align: end;">{{tcgst}}</td>
                        <td style="font-weight: bold; font-size: 16px; text-align: end;">{{tsgst}}</td>
                        <td style="font-weight: bold; font-size: 16px; text-align: end;">{{tigst}}</td>
                        <td style="font-weight: bold; font-size: 16px; text-align: end;">{{tgst}}</td>
                        <td style="font-weight: bold; font-size: 16px; text-align: end;">{{netamount + gstcst}}</td>
                        <td style="font-weight: bold; font-size: 16px; text-align: end;">{{paidamount}}</td>
                        <td style="font-weight: bold; font-size: 16px; text-align: end;">{{netamount + gstcst - paidamount}}</td>


                    </tr>
                  </tfoot> 
            </table>
        </div>
    </ng-container>
    <ng-container *ngIf="reportType == 'Monthwise'">
        <div class="container" id="print-section">
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th colspan="8">हिंदुस्थान प्रकाशन संस्था - विवेक साप्ताहिक - जाहिरात विभाग
                        </th>
                        <th colspan="2">दिनांक - {{ today | date:"dd-MM-yyy" }}</th>
                        <th colspan="2">पृष्ठ क्र. -</th>
                    </tr>
                    <tr>
                        <th colspan="4"> Advt Invoice Register</th>
                        <th colspan="2">Briefly</th>
                        <th colspan="2">Date</th>
                        <th colspan="2">From :- </th>
                        <th colspan="2">To :-</th>
                    </tr>
                    
                    <tr>
                        <th>Invoice Month</th>
                        <th>Advt. Amount</th>
                        <th>Discount Amount</th>
                        <th>Net Amount</th>
                        <th>Amount Before GST</th>
                        <th>CGST</th>
                        <th>SGST</th>
                        <th>IGST</th>
                        <th>Total GST</th>
                        <th>Total Invoice Amount</th>
                        <th>Paid Amount</th>
                        <th>Unpaid Amount</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container  *ngFor="let data of filteredAdvtInvoiceDatamonthly; let i = index">
                        <tr>
                            <td class="right text-end">{{data.month}}</td>
                            <td class="right text-end" style="text-align: end;">{{ data['advt_amt'] }}</td>
                            <td class="right text-end" style="text-align: end;">{{ data['discount'] }}</td>
                            <td class="right text-end" style="text-align: end;">{{ data['net_amount'] }}</td>
                            <td class="right text-end" style="text-align: end;">{{ data['beforegst_amt']  }}</td>
                            <td class="right text-end" style="text-align: end;">{{ data['cgst'] }}</td>
                            <td class="right text-end" style="text-align: end;">{{ data['sgst'] }}</td>
                            <td class="right text-end" style="text-align: end;">{{ data['igst'] }}</td>
                            <td class="right text-end" style="text-align: end;">{{ data['gst']  }}</td>
                            <td class="right text-end" style="text-align: end;">{{ data['amount'] }}</td>
                            <td class="right text-end" style="text-align: end;">{{data.received_amt}}</td>
                            <td class="right text-end" style="text-align: end;">{{data.pending_amt}}</td>
            </tr>
                    </ng-container>
                   
                </tbody>
                <tfoot>
                    <tr>
                        <td></td>
                        <td style="font-weight: bold; font-size: 16px; text-align: end;">{{advtamount}}</td>
                        <td style="font-weight: bold; font-size: 16px; text-align: end;">{{discountamt}}</td>
                        <td style="font-weight: bold; font-size: 16px; text-align: end;">{{netamount}}</td>
                        <td style="font-weight: bold; font-size: 16px; text-align: end;">{{beforegst}}</td>
                        <td style="font-weight: bold; font-size: 16px; text-align: end;">{{tcgst}}</td>
                        <td style="font-weight: bold; font-size: 16px; text-align: end;">{{tsgst}}</td>
                        <td style="font-weight: bold; font-size: 16px; text-align: end;">{{tigst}}</td>
                        <td style="font-weight: bold; font-size: 16px; text-align: end;">{{tgst}}</td>
                        <td style="font-weight: bold; font-size: 16px; text-align: end;">{{netamount + gstcst}}</td>
                        <td style="font-weight: bold; font-size: 16px; text-align: end;">{{paidamount}}</td>
                        <td style="font-weight: bold; font-size: 16px; text-align: end;">{{netamount + gstcst - paidamount}}</td>


                    </tr>
                  </tfoot> 
            </table>
        </div>
    </ng-container>
  
</div>
<div *ngIf="isLoading" class="main-loading">
    <span class="loader"></span>
    <p>Loading...</p>
</div>