<div>
    <div style="margin-left: 80%;  margin-bottom: 2%">
        <button  class="noPrint" style="margin-right: 2%; margin-left: 2%" mat-raised-button
            color="primary" printTitle="Label" printSectionId="print" ngxPrint [useExistingCss]="true">
            print
        </button>
        <button mat-dialog-close class="btn noPrint" (click)="close()">Close </button>
    </div>
    <div class="mt-3" id="print">
        <div class="row" >
            <ng-container *ngFor="let data of amounts">
            <div class="col-md-6" style="padding-left: 40px;" >
                <div class="row">
                    <div class="col-md-8 ">
                        <div class="right" style="padding: 20px; ;">
                            <span>हिंदुस्थान प्रकाशन संस्था </span>
                            <span>303, वडाळा उद्योग भवन, नायगाव क्राॅस रोड,</span>
                            <span>वडाळा, मुंबई 400 031 दूरध्वनीः 9967570531</span>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <img src="../../../assets/img/brand/red.png" alt="" width="100">
                    </div>
                    <div class="col-md-8" style="padding-top: 15px;">
                        प्रति , <br>{{data.name}} <br> {{data.address}}<br>{{data.pincode}} {{data.taluka}} <br> {{data.mobile}}
    
                    </div>
                    <div class="col-md-4" style="padding-top: 15px;">
                        दिनांक <br>
                        {{this.vitarandate}}
                    </div>
                </div>
                <div>
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                               <th class="pd-15">अंक संख्या</th>
                               <th class="pd-15">रक्कम</th>
                               <th class="pd-15">गणीबंडल</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="pd-40">{{data.amount}}</td>
                                <td class="pd-40">{{data.amount * this.price}}</td>
                                <td class="pd-40"></td>
                            </tr>
                        </tbody>
    
                    </table>
                </div>
                <div style="text-align: end;border-bottom: 2px solid #000;">
                    <p>(वर्गणी व विक्री विभाग)</p>
                </div>
                <p class="text-center"><b>मुख्य कार्यलय :</b>12, कामत औद्योगिक वसाहत , 316, स्वा वीर सावरकर मार्ग , प्रभादेवी , मुंबई  - 400025</p>
                <p class="text-center">E-mail :  vivekwkl&#64;gmail.com • Website : www.vivek.com</p>
            </div>
        </ng-container>
            <!-- <div class="col-md-6">
                <div class="row">
                    <div class="col-md-8 ">
                        <div class="right" style="padding: 20px; ;">
                            <span>हिंदुस्थान प्रकाशन संस्था</span>
                            <span>विवेक भवन (कृष्णा रेजेन्सी), प्लॉट क्र. ४०, सेक्टर क्र. ३०, सानपाडा (प),</span>
                            <span>नवी मुंबई, जिल्हा
                                ठाणे - ४०० ७०५ दूरध्वनी : २७८१ ०२३५/३६</span>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <img src="../../../assets/img/brand/red.png" alt="" width="100">
                    </div>
                    <div class="col-md-8" style="padding-top: 15px;">
                        प्रति ,
                    </div>
                    <div class="col-md-4" style="padding-top: 15px;">
                        दिनांक 
                    </div>
                </div>
                <div>
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                               <th class="pd-15">अंक संख्या</th>
                               <th class="pd-15">रक्कम</th>
                               <th class="pd-15">गणीबंडल</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="pd-40">123</td>
                                <td class="pd-40">123</td>
                                <td class="pd-40"></td>
                            </tr>
                        </tbody>
    
                    </table>
                </div>
                <div style="text-align: end;border-bottom: 2px solid #000;">
                    <p>(वर्गणी व विक्री विभाग)</p>
                </div>
                <p class="text-center"><b>मुख्य कार्यलय :</b>12, कामत औद्योगिक वसाहत , 316, स्वा वीर सावरकर मार्ग , प्रभादेवी , मुंबई  - 400025</p>
                <p class="text-center">E-mail :  vivekwkl&#64;gmail.com • Website : www.vivek.com</p>
            </div>
             -->
            <!-- <div class="col-md-12 ">
                <p class="text-center"><b>मुख्य कार्यलय :</b>12, कामत औद्योगिक वसाहत , 316, स्वा वीर सावरकर मार्ग , प्रभादेवी , मुंबई  - 400025</p>
                <p class="text-center">E-mail :  vivekwkl&#64;gmail.com • Website : www.vivek.com</p>
            </div> -->
        </div>
    </div>
</div>

