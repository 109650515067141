import { formatDate } from '@angular/common';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DigisubservicesService } from 'src/app/services/digisubservices.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-print-order-statement',
  templateUrl: './print-order-statement.component.html',
  styleUrls: ['./print-order-statement.component.scss'],
})
export class PrintOrderStatementComponent implements OnInit {
  printorderData;
  today = new Date();
  startDate = '';
  endDate = '';
  tempdata = [
    { no: 1, name: 'अभिकर्ता', prati: 0, shera: '' },
    { no: 2, name: 'वर्गणी', prati: 0, shera: '' },
    { no: 3, name: 'स. भेट', prati: 0, shera: '' },
    { no: 4, name: 'प्रचार स. भेट', prati: 0, shera: '' },
    { no: 5, name: 'जाहिरात स. भेट', prati: 0, shera: '' },
    { no: 6, name: 'जादा वर्गणी', prati: 0, shera: '' },
    { no: 7, name: 'जादा विक्री', prati: 0, shera: '' },
    { no: 8, name: 'कार्यालय ', prati: 0, shera: '' },
    { no: 9, name: 'स सप्रेम भेट', prati: 0, shera: '' },
  ];

  testdata = [
    { name: '५० जी एस एम मफलीथो पेपर ' },
    { name: '६५ जी एस एम एल डब्लु सी पेपर ' },
    { name: '९० जी एस एम आर्ट  पेपर' },
  ];

  totalprati = 0;
  totalpsankhya = 0;

  tempData = [
    {
      prushta_kr: '01-02 व 51-52',
      prushta_sankhya: '4',
      prakar: 'रंगीत',
      pane_denar: 'गुरुवार',
    },
    {
      prushta_kr: '03-12 व 41-50',
      prushta_sankhya: '20',
      prakar: 'कृष्णधवळ',
      pane_denar: 'शुक्रवार',
    },
    {
      prushta_kr: '13-20 व 33-40',
      prushta_sankhya: '16',
      prakar: 'रंगीत',
      pane_denar: 'गुरुवार',
    },
    {
      prushta_kr: '21-26 व 27-32',
      prushta_sankhya: '12',
      prakar: 'कृष्णधवळ',
      pane_denar: 'बुधवार',
    },
    {
      prushta_kr: '',
      prushta_sankhya: '',
      prakar: '',
      pane_denar: '',
    },
  ];
  issue_type: any;

  constructor(
    private dialogref: MatDialogRef<PrintOrderStatementComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private service: DigisubservicesService
  ) {}

  year : any;
  sdate : any;
  edate : any;

  getweekcount() {
    const issuedate = new Date(this.data['issuedate']);
     this.issue_type = this.data.issuetype
    const month = issuedate.getMonth() + 1; // Adding 1 to get month starting from 1 (January is 1)
    const year = this.getYearFromDate(issuedate);
    
    if (month >= 1 && month <= 3) { // If month is January to March
        this.sdate = new Date(year - 1, 3, 1); // April 1st of the previous year
        this.edate = new Date(year, 2, 31); // March 31st of the current year
    } else { // If month is April to December
        this.sdate = new Date(year, 3, 1); // April 1st of the current year
        this.edate = new Date(year + 1, 2, 31); // March 31st of the next year
    }
    this.weeksCount = this.calculateWeeks(this.sdate, this.edate);
}

weeksCount: any;

calculateWeeks(startDate: Date, endDate: Date): number {
    let weekCount = 0;
    let currentDate = new Date(startDate);

    // Find the first Sunday on or after the start date
    while (currentDate.getDay() !== 0) {
        currentDate.setDate(currentDate.getDate() + 1);
    }

    // Count weeks from the first Sunday until the end date
    while (currentDate <= endDate) {
        if (new Date(this.data['issuedate']) >= currentDate) {
            weekCount++;
        }
        currentDate.setDate(currentDate.getDate() + 7); // Move to the next Sunday
    }

    return weekCount;
}

getYearFromDate(date: Date): number {
    return date.getFullYear();
}

  ngOnInit(): void {
    // this.printorderData = this.data['data'];
    this.endDate = this.data['issuedate'];
    let weekfirstday =
      new Date(this.data['issuedate']).getDate() -
      new Date(this.data['issuedate']).getDay();
    this.startDate = formatDate(
      new Date(this.data['issuedate']).setDate(weekfirstday - 6),
      'yyyy-MM-dd',
      'en-IN',
      'GMT+0530'
    );

    this.getweekcount()
    // console.log(this.endDate);
    // console.log(this.startDate);

    // console.log(this.data);

    this.getPrintOrderData();
    this.totalPrati();
  }

  getPrintOrderData() {
    this.service
      .getPrintOrderData(this.startDate, this.endDate, this.issue_type)
      .subscribe((res) => {
        // console.log(res);
        this.tempdata.map((a) => {
          if (a.name === 'वर्गणी') {
            a.prati = parseInt(res['vargani']);
          } else if (a.name === 'स. भेट') {
            a.prati = parseInt(res['s_bhet']);
          }
        });
        this.totalPrati();
      });
  }

  exportexcel(fileName): void {
    /* table id is passed over here */
    let element = document.getElementById('print-section');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, fileName + '.xlsx');
  }

  addTempData(event, index, key) {
    this.tempData[index][key] = event.target.value;
    // switch (key) {
    //   case 'prushta_kr':
    //     break;

    //   case 'prushta_sankhya':
    //     this.tempData[index]['prushta_sankhya'] = event.target.value;
    //     break;

    //   case 'prakar':
    //     this.tempData[index]['prakar'] = event.target.value;
    //     break;

    //   case 'pane_denar':
    //     this.tempData[index]['pane_denar'] = event.target.value;
    //     break;
    // }
    console.log(this.tempData);
    this.calculateTotal();
  }

  calculateTotal() {
    this.totalpsankhya = 0;
    for (let index = 0; index < Object.keys(this.tempData).length; index++) {
      this.totalpsankhya +=
        this.tempData[index]['prushta_sankhya'] == ''
          ? 0
          : parseInt(this.tempData[index]['prushta_sankhya']);
    }
  }

  addtempdata(event, index, name) {
    this.tempdata[index][name] =
      name === 'name' ? event.target.value : parseInt(event.target.value);
    this.totalPrati();
  }

  totalPrati() {
    this.totalprati = 0;
    for (let index = 0; index < Object.keys(this.tempdata).length; index++) {
      this.totalprati += this.tempdata[index]['prati'];
    }
  }

  closeDialog() {
    if (
      window.confirm(
        'Are you sure want to close, Please save data before close'
      )
    ) {
      this.dialogref.close();
    }
  }

  save() {
    let data = {
      order_date: formatDate(this.today, 'yyyy-MM-dd', 'en-IN', 'GMT+0530'),
      issue_date_from: this.startDate,
      issue_date_to: formatDate(
        this.endDate,
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      ),
      total_prati: this.totalprati,
      total_prustha: this.totalpsankhya,
    };

    // console.log(data);

    let prusthaData = [];
    this.tempData.map((a) => {
      if (
        a['prushta_kr'] != '' ||
        a['prushta_sankhya'] != '' ||
        a['prakar'] != '' ||
        a['pane_denar'] != ''
      ) {
        prusthaData.push(a);
      }
    });
    // console.log(prusthaData);
    // console.log(this.tempdata);

    this.service.insertPrintOrderData(data).subscribe((res) => {
      // console.log(res);

      if (res['success']) {
        this.service
          .insertPrintOrderPrustha(res['id'], prusthaData)
          .subscribe((res1) => {
            // console.log(res1);
          });

        this.service
          .insertPrintOrderTypes(res['id'], this.tempdata)
          .subscribe((res3) => {
            alert('Print order saved successfully');
            this.dialogref.close();
          });
      } else {
        alert(res['msg']);
      }
    });
  }

  addNewtempdata() {
    console.log(this.tempData);
    this.tempdata.push({
      no: this.tempdata.length + 1,
      name: '',
      prati: 0,
      shera: '',
    });
  }
}
