<div>
  <div>
    <div style="margin-left: 80%; margin-top: -4%; margin-bottom: 2%">
      <button [useExistingCss]="true" class="noPrint" style="margin-top: 2%;" mat-raised-button color="primary"
        printSectionId="print-section" ngxPrint>
        print
      </button>
      <!-- <button *ngIf="!viewmode" class="noPrint" style="margin-top: 2%; margin-left: 2%" (click)="save()" mat-raised-button
        color="primary">
        Save
      </button> -->
      <button class="noPrint" style="margin-top: 2%" mat-button mat-dialog-close>
        Close
      </button>
    </div>
  </div>
  <div class="container" id="print-section">
    <div style="border: 1px solid black">
      <div class="row" style="text-align: center; background-color: black">
        <h3 style="
              color: white;
              margin-left: auto;
              margin-right: auto;
              margin-top: auto;
              margin-bottom: auto;
            ">
          TAX INVOICE
        </h3>
      </div>
      <div class="row" style="border-bottom-style: solid">
        <div class="col-3">
          <img style="margin-top: 2%" width="65%" src="../../../assets/img/brand/red.png" />
        </div>
        <div class="col-9" style="padding-top: 5%; text-align: center">
          <span style="font-size: 35px; font-weight: bold">हिंदुस्थान प्रकाशन संस्था</span>
          <span style="float: right; font-size: 12px">(Reg.No.-BOM-3/1961(GBBSD)</span>
          <h3 style="margin-left: -130px; font-size: large; margin-top: 5%">
            साप्ताहिक विवेक - पुस्तक विभाग
          </h3>
          <span style="font-size: 12px">मुख्य कार्यालय: 5/12, कामत औद्योगिक वसाहत, 396 स्वा. वीर सावरकर
            मार्ग, प्रभादेवी, मुंबई-25.<br /></span>
          <span style="font-size: 12px">प्रशासकीय कार्यालय : विवेक भवन (कृष्णा रिजन्सी), 12 वा मजला, प्लॉट
            क्र. 40, सेक्टर क्र. 30, सानपाडा (प.), नवी मुंबई-400 705.<br /></span>
          <span style="font-size: 12px">दूरध्वनी: 27810235/236 Mob No: 9594961836 Email:
            vivekbookdept&#64;gmail.com</span>
        </div>
        <hr />
      </div>
      <div class="row" style="border-bottom-style: solid">
        <div class="col-6">
          <span style="font-weight: bold">GSTIN/Unique Id : 27AAATH0433Q1ZI</span>
        </div>

        <div class="col-6" style="border-style: solid; border-width: 0px 0px 0px 3px">
          <span style="font-weight: bold">PAN No. : AAATH0433Q</span>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <span>
            <b>Invoice No. </b>: {{ this.bookinvoice[0]["invoice_no"] }}</span>
        </div>
        <div class="col-6" style="border-style: solid; border-width: 0px 0px 0px 3px">
          <span>
            <b>Date </b>:
            {{ this.bookinvoice[0]["invoice_date"] | date: "dd-MM-yyy" }}</span>
        </div>
      </div>

      <div class="row" style="border-bottom-style: solid; border-block-start-style: solid">
        <div class="col-6">
          <h3 style="font-weight: bold; color: black">Details of Receiver :</h3>
          <span class="receiver" style="color: black">Name : {{ this.bookinvoice[0]["receiver_name"] }}<br /></span>
          <span class="receiver">Address : {{ this.bookinvoice[0]["receiver_address"] }}&nbsp;{{
            this.bookinvoice[0]["taluka"]
            }},&nbsp;{{ this.bookinvoice[0]["district"] }},&nbsp;{{
            this.bookinvoice[0]["state"]
            }},&nbsp;{{ this.bookinvoice[0]["country"] }},&nbsp;{{
            this.bookinvoice[0]["pincode"]
            }}<br /></span>
          <span class="receiver">Phone : {{ this.bookinvoice[0]["mobile"] }}<br /></span>
          <span class="receiver">GSTIN : {{ this.bookinvoice[0]["gst"] }}<br /></span>
        </div>
        <div class="col-6" style="border-style: solid; border-width: 0px 0px 0px 3px">
          <h3 style="font-weight: bold; color: black">Details of Cosignee :</h3>
          <span class="receiver">Name : <br /></span>
          <span class="receiver">Address : <br /></span>
          <span class="receiver">Phone : <br /></span>
          <span class="receiver">GSTIN : <br /></span>
        </div>
      </div>

      <div class="row">
        <!-- <div class="col-1 tableDescheader">S.No</div>
          <div class="col-3 tableDescheader">Description of Goods</div>
          <div class="col-2 tableDescheader">Qty</div>
          <div class="col-2 tableDescheader">Rate</div>
          <div class="col-2 tableDescheader">Total</div>
          <div class="col-2 tableDescheader">Taxable Value</div> -->
        <div class="col-1 tableDescheader">S.No</div>
        <div class="col-3 tableDescheader">Book Name</div>
        <div class="col-1 tableDescheader">HSN Code</div>
        <!-- <div class="col-2 tableDescheader">Author Name</div> -->
        <div class="col-1 tableDescheader">Book Price</div>
        <div class="col-1 tableDescheader">Book Sale Pirce</div>
        <div class="col-1 tableDescheader">Discount Amount</div>
        <div class="col-1 tableDescheader">Net Amount</div>
        <div class="col-1 tableDescheader">Book Copies</div>
        <div class="col-2 tableDescheader">Total Taxable Value</div>
        <!-- <div class="col-1 tableDescheader">Shipping Charges</div> -->
      </div>
      <div class="row" *ngFor="let item of this.bookinvoicedata; let i = index">
        <div class="col-1 tableDesc">{{ i + 1 }}</div>
        <div class="col-3 tableDesc" style="text-align: left">
          {{ item["book_name"] }}
        </div>
        <div class="col-1 tableDesc" style="text-align: right">4902</div>
        <div class="col-1 tableDesc" style="text-align: right">
          {{ item["rate"] }}
        </div>
        <div class="col-1 tableDesc" style="text-align: right">
          <!-- <span *ngIf="item['discount'] > 0">0.00</span> -->
          <span>{{ item["sale_rate"] }}</span>
        </div>
        <div class="col-1 tableDesc" style="text-align: right">
          {{ item["discountamt"] }}
        </div>
        <div class="col-1 tableDesc" style="text-align: right">
          {{ item["rate"] - item["discountamt"] }}
        </div>

        <div class="col-1 tableDesc" style="text-align: right">
          {{ item["copies"] }}
        </div>
        <div class="col-2 tableDesc" style="text-align: right">
          {{ item["total"] }}
        </div>
        <!-- <div class="col-1 tableDesc">{{ item["shippingcharges"] }}</div> -->
      </div>
      <div class="row">
        <div class="col-1 tableDesc"></div>
        <div class="col-3 tableDesc"></div>
        <div class="col-1 tableDesc"></div>
        <div class="col-1 tableDesc"></div>
        <div class="col-1 tableDesc"></div>
        <div class="col-1 tableDesc"></div>
        <div class="col-1 tableDesc" style="text-align: right"></div>
        <div class="col-1 tableDesc"></div>
        <div class="col-2 tableDesc" style="text-align: right"></div>
        <!-- <div class="col-1 tableDesc">{{ this.shipping_charges_total }}</div> -->
      </div>
      <div class="row" style="
            border-bottom-style: solid;
            border-top-style: solid;
            border-width: 1px 0px 2px 0px;
          ">
        <div class="col-8" style="margin-top: 2%">
          <b>Invoice Total in word :</b> {{ amtinwords }}
        </div>
        <div class="col-4" style="border-style: solid; border-width: 0px 0px 0px 2px">
          <div class="row">
            <div class="col-6 tableDesc">CGST</div>
            <div class="col-6 tableDesc"></div>
          </div>
          <div class="row">
            <div class="col-6 tableDesc">SGST</div>
            <div class="col-6 tableDesc"></div>
          </div>
          <div class="row">
            <div class="col-6 tableDesc">IGST</div>
            <div class="col-6 tableDesc"></div>
          </div>
          <div class="row">
            <div class="col-6 tableDesc">SGST</div>
            <div class="col-6 tableDesc"></div>
          </div>
        </div>
      </div>

      <div class="row" style="font-weight: bold">
        <div class="col-8">
          <span>Bank Detail : TJSB Sahakari Bank Ltd. <br /> </span>
          <span>A/C Name : Hindustan Prakashan Sanstha - Pustak Vibhag <br />
          </span>
          <span>A/C No. : 012120100001216 <br /> </span>
          <span>IFSC No. : TJSB0000012</span>
        </div>
        <div class="col-4" style="border-style: solid; border-width: 0px 0px 0px 2px;padding: 0px;">
          <div class="row">
            <div class="col-6 tableDesc">Total</div>
            <div class="col-6 tableDesc" style="text-align: right">
              {{ this.total_total }}
            </div>
          </div>
          <div class="row">
            <div class="col-6 tableDesc">Shipping Charges</div>
            <div class="col-6 tableDesc" style="text-align: right">
              {{ this.shipping_charges_total }}
            </div>
          </div>
          <div class="row">
            <div class="col-6 tableDesc">Round Off</div>
            <div class="col-6 tableDesc"></div>
          </div>
          <div class="row">
            <div class="col-6 tableDesc">Invoice Total</div>
            <div class="col-6 tableDesc" style="text-align: right">
              {{ this.totals }}
            </div>
          </div>
          <div class="row">
            <div class="col-6 tableDesc">Previous Deposit</div>
            <div class="col-6 tableDesc"></div>
          </div>
          <div class="row">
            <div class="col-6 tableDesc">Previous Dues</div>
            <div class="col-6 tableDesc"></div>
          </div>
          <div class="row">
            <div class="col-12 tableDesc">Total Dues</div>
          </div>
        </div>
      </div>

      <div class="row" style="border-bottom-style: solid; border-block-start-style: solid">
        <div class="col-8">
          <h3>Terms:</h3>
          <span>1) Complaints of any nature must be communicated writing within 10
            Days from the dateof delivery. <br />
          </span>
          <span>2) A/C payee cheques to be drawn in favor of :- <br /></span>
          <span>Hindustan Prakashan Sanstha - Pustak Vibhag</span>
        </div>
        <div class="col-4" style="border-style: solid; border-width: 0px 0px 0px 3px">
          <span>For Hindustan Prakashan Sanstha</span>
          <div style="padding: 17%"></div>
          <span>Authorised Signatory</span>
        </div>
      </div>
    </div>
  </div>
  <!-- <button class="noPrint" style="margin-top: 2%" (click)="print()" mat-raised-button color="primary">
    Print
  </button> -->
  <!-- <button
    *ngIf="!viewmode"
    class="noPrint"
    style="margin-top: 2%; margin-left: 2%"
    (click)="save()"
    mat-raised-button
    color="primary"
  >
    Save
  </button> -->
  <!-- <button class="noPrint" style="margin-top: 2%" mat-button mat-dialog-close>
    Close
  </button> -->
</div>