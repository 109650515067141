import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DigisubservicesService } from 'src/app/services/digisubservices.service';

@Component({
  selector: 'app-edit-book-stock',
  templateUrl: './edit-book-stock.component.html',
  styleUrls: ['./edit-book-stock.component.scss']
})
export class EditBookStockComponent implements OnInit {

  BookStock: FormGroup;

  constructor(private fb: FormBuilder, public service: DigisubservicesService ,
    private dialogRef : MatDialogRef<EditBookStockComponent>,
    @Inject(MAT_DIALOG_DATA) public data : any) { 

      console.log(this.data)
      this.BookStock = this.fb.group({
        bookid:this.data.book['bookid'],
        bookname:this.data.book['bookname'],
        total_stock:this.data.book['total_stock'],
        available_stock:this.data.book['available_stock'],
        newstock:0,
        comment:''
      })

    }

  ngOnInit(): void {

    
  }

  update()
  {
    let new_available = parseInt(this.data.book['available_stock']) + parseInt(this.BookStock.get('newstock').value);
    let new_total = parseInt(this.data.book['total_stock']) + parseInt(this.BookStock.get('newstock').value)
    
    this.service.updateBookStock(this.data.book['bs_id']).subscribe(bs=>{
      this.service.AddupdatedBookStock(this.data.book['bookid'], new_total , new_available , this.BookStock.get('comment').value).subscribe(data => 
        {
          console.log(data)
          window.alert("Records has been updated ! Please Refresh the page ")
          this.dialogRef.close()
        })
    })


  }
}
