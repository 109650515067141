<div>
    <!-- [printStyle]="{table : {'border-collapse':'collapse'}, td : {'border': 'solid 1px'}, th : {'border': 'solid 1px'}, p :
    {'text-align': 'center','font-size':'15px','font-weight':'600'}, div : {'text-align':'left'}, span :
    {'text-align':'right','display':'block'}}" -->
    <div style="margin-left: 70%; margin-bottom: 2%">
        <button type="button" mat-raised-button matTooltip="Download Execelsheet" class="btn btn-danger"
            (click)="exportexcel('Print_Order')">Export</button>
        <button [useExistingCss]="true" class="noPrint" style="margin-right: 2%; margin-left: 2%" mat-raised-button
            color="primary" printTitle="Label" printSectionId="print-section" ngxPrint>
            print
        </button>
        <button style="margin-right: 2%" mat-raised-button color="primary" (click)="save()">
            Save
        </button>
        <!-- mat-dialog-close -->
        <button (click)="closeDialog()" class="btn noPrint">Close</button>
    </div>
    <div class="container" id="print-section">
        <div style="padding-left: 0%; padding-right: 0%; font-weight: 600;">
            <table class="table table-striped text-center">
                <tr style="width: 100%;">
                    <td colspan="7" style="border-width: 2px 2px 0 2px; width: 70%;"><span class="fontBold">सा. विवेक -
                            अंक
                            छपाई आदेश</span></td>
                    <td colspan="3" style="border-width: 2px 2px 0 0; width: 30%;">दिनांक : {{ today | date:'dd-MM-yyyy'
                        }}</td>
                </tr>
                <tr>
                    <td colspan="5" style="border-width: 2px 2px 0 2px; width: 50%;">आदेश क्र : {{weeksCount}}</td>
                    <td colspan="2" style="border-width: 2px 2px 0 0;width: 20%;">पृष्ठ क्रं.</td>
                    <td style="border-width: 2px 2px 0 0; width: 10%;">पृष्ठ संख्या</td>
                    <td style="border-width: 2px 2px 0 0; width: 10%;">प्रकार</td>
                    <td style="border-width: 2px 2px 0 0; width: 10%;">पाने देणार</td>
                </tr>
                <tr>
                    <td colspan="5" style="border-width: 2px 2px 0 2px; width: 50%;">अंक दिनांक : {{ startDate |
                        date:"dd MMMM yy" }} ते {{ endDate | date:"dd MMMM yy" }}</td>
                    <td colspan="2" style="border-width: 2px 2px 0 0;width: 20%;">
                        <input type="text" value="{{ tempData[0]['prushta_kr'] }}"
                            (change)="addTempData($event, 0, 'prushta_kr')">
                        <!-- <span *ngIf="tempData[0]['prushta_kr'] !== ''">{{ tempData[0]['prushta_kr'] }}</span>
                        <span *ngIf="tempData[0]['prushta_kr'] == ''"><input type="text"
                                value="{{ tempData[0]['prushta_kr'] }}"
                                (change)="addTempData($event, 0, 'prushta_kr')"></span> -->
                    </td>
                    <td style="border-width: 2px 2px 0 0; width: 10%;">
                        <input type="number" value="{{ tempData[0]['prushta_sankhya'] }}"
                            (change)="addTempData($event, 0, 'prushta_sankhya')">
                        <!-- <span *ngIf="tempData[0]['prushta_sankhya'] !== ''">{{ tempData[0]['prushta_sankhya'] }}</span>
                        <span *ngIf="tempData[0]['prushta_sankhya'] == ''">
                            <input type="number" value="{{ tempData[0]['prushta_sankhya'] }}"
                                (change)="addTempData($event, 0, 'prushta_sankhya')"></span> -->
                    </td>
                    <td style="border-width: 2px 2px 0 0; width: 10%;">
                        <input type="text" value="{{ tempData[0]['prakar'] }}"
                            (change)="addTempData($event, 0, 'prakar')">
                        <!-- <span *ngIf="tempData[0]['prakar'] !== ''">{{
                            tempData[0]['prakar'] }}</span>
                        <span *ngIf="tempData[0]['prakar'] == ''"><input type="text" value="{{ tempData[0]['prakar'] }}"
                                (change)="addTempData($event, 0, 'prakar')"></span> -->
                    </td>
                    <td style="border-width: 2px 2px 0 0; width: 10%;">
                        <input type="text" value="{{ tempData[0]['pane_denar'] }}"
                            (change)="addTempData($event, 0, 'pane_denar')">
                        <!-- <span *ngIf="tempData[0]['pane_denar'] !== ''">{{
                            tempData[0]['pane_denar'] }}</span>
                        <span *ngIf="tempData[0]['pane_denar'] == ''"><input type="text"
                                value="{{ tempData[0]['pane_denar'] }}"
                                (change)="addTempData($event, 0, 'pane_denar')"></span> -->
                    </td>
                </tr>
                <tr>
                    <td colspan="5" style="border-width: 0 2px 0 2px; width: 50%;">च्या अंकाकरिता</td>
                    <td colspan="2" style="border-width: 2px 2px 0 0;width: 20%;">
                        <input type="text" value="{{ tempData[1]['prushta_kr'] }}"
                            (change)="addTempData($event, 1, 'prushta_kr')">
                        <!-- <span *ngIf="tempData[1]['prushta_kr'] !== ''">{{ tempData[1]['prushta_kr'] }}</span>
                        <span *ngIf="tempData[1]['prushta_kr'] == ''"><input type="text"
                                value="{{ tempData[1]['prushta_kr'] }}"
                                (change)="addTempData($event, 1, 'prushta_kr')"></span> -->
                    </td>
                    <td style="border-width: 2px 2px 0 0; width: 10%;">
                        <!-- <span *ngIf="tempData[1]['prushta_sankhya'] !== ''">{{ tempData[1]['prushta_sankhya'] }}</span>
                        <span *ngIf="tempData[1]['prushta_sankhya'] == ''">
                            <input type="number" value="{{ tempData[1]['prushta_sankhya'] }}"
                                (change)="addTempData($event, 1, 'prushta_sankhya')"></span> -->
                        <input type="number" value="{{ tempData[1]['prushta_sankhya'] }}"
                            (change)="addTempData($event, 1, 'prushta_sankhya')">
                    </td>
                    <td style="border-width: 2px 2px 0 0; width: 10%;">
                        <input type="text" value="{{ tempData[1]['prakar'] }}"
                            (change)="addTempData($event, 1, 'prakar')">
                        <!-- <span *ngIf="tempData[1]['prakar'] !== ''">{{
                            tempData[1]['prakar']
                            }}</span>
                        <span *ngIf="tempData[1]['prakar'] == ''"><input type="text" value="{{ tempData[1]['prakar'] }}"
                                (change)="addTempData($event, 1, 'prakar')"></span> -->
                    </td>
                    <td style="border-width: 2px 2px 0 0; width: 10%;">
                        <input type="text" value="{{ tempData[1]['pane_denar'] }}"
                            (change)="addTempData($event, 1, 'pane_denar')">
                        <!-- <span *ngIf="tempData[1]['pane_denar'] !== ''">{{
                            tempData[1]['pane_denar'] }}</span>
                        <span *ngIf="tempData[1]['pane_denar'] == ''"><input type="text"
                                value="{{ tempData[1]['pane_denar'] }}"
                                (change)="addTempData($event, 1, 'pane_denar')"></span> -->
                    </td>
                </tr>
                <tr>
                    <td colspan="5" style="border-width: 0 2px 0 2px; width: 50%;"></td>
                    <td colspan="2" style="border-width: 2px 2px 0 0;width: 20%;">
                        <input type="text" value="{{ tempData[2]['prushta_kr'] }}"
                            (change)="addTempData($event, 2, 'prushta_kr')">
                        <!-- <span *ngIf="tempData[2]['prushta_kr'] !== ''">{{ tempData[2]['prushta_kr'] }}</span>
                        <span *ngIf="tempData[2]['prushta_kr'] == ''"><input type="text"
                                value="{{ tempData[2]['prushta_kr'] }}"
                                (change)="addTempData($event, 2, 'prushta_kr')"></span> -->
                    </td>
                    <td style="border-width: 2px 2px 0 0; width: 10%;">
                        <input type="number" value="{{ tempData[2]['prushta_sankhya'] }}"
                            (change)="addTempData($event, 2, 'prushta_sankhya')">
                        <!-- <span *ngIf="tempData[2]['prushta_sankhya'] !== ''">{{ tempData[2]['prushta_sankhya'] }}</span>
                        <span *ngIf="tempData[2]['prushta_sankhya'] == ''">
                            <input type="number" value="{{ tempData[2]['prushta_sankhya'] }}"
                                (change)="addTempData($event, 2, 'prushta_sankhya')"></span> -->
                    </td>
                    <td style="border-width: 2px 2px 0 0; width: 10%;">
                        <input type="text" value="{{ tempData[2]['prakar'] }}"
                            (change)="addTempData($event, 2, 'prakar')">
                        <!-- <span *ngIf="tempData[2]['prakar'] !== ''">{{
                            tempData[2]['prakar']
                            }}</span>
                        <span *ngIf="tempData[2]['prakar'] == ''"><input type="text" value="{{ tempData[2]['prakar'] }}"
                                (change)="addTempData($event, 2, 'prakar')"></span> -->
                    </td>
                    <td style="border-width: 2px 2px 0 0; width: 10%;">
                        <input type="text" value="{{ tempData[2]['pane_denar'] }}"
                            (change)="addTempData($event, 2, 'pane_denar')">
                        <!-- <span *ngIf="tempData[2]['pane_denar'] !== ''">{{
                            tempData[2]['pane_denar'] }}</span>
                        <span *ngIf="tempData[2]['pane_denar'] == ''"><input type="text"
                                value="{{ tempData[2]['pane_denar'] }}"
                                (change)="addTempData($event, 2, 'pane_denar')"></span> -->
                    </td>
                </tr>
                <tr>
                    <td colspan="5" style="border-width: 2px 2px 0 2px; width: 50%;"></td>
                    <td colspan="2" style="border-width: 2px 2px 0 0;width: 20%;">
                        <input type="text" value="{{ tempData[3]['prushta_kr'] }}"
                            (change)="addTempData($event, 3, 'prushta_kr')">
                        <!-- <span *ngIf="tempData[3]['prushta_kr'] !== ''">{{ tempData[3]['prushta_kr'] }}</span>
                        <span *ngIf="tempData[3]['prushta_kr'] == ''"><input type="text"
                                value="{{ tempData[3]['prushta_kr'] }}"
                                (change)="addTempData($event, 3, 'prushta_kr')"></span> -->
                    </td>
                    <td style="border-width: 2px 2px 0 0; width: 10%;">
                        <input type="number" value="{{ tempData[3]['prushta_sankhya'] }}"
                            (change)="addTempData($event, 3, 'prushta_sankhya')">
                        <!-- <span *ngIf="tempData[3]['prushta_sankhya'] !== ''">{{ tempData[3]['prushta_sankhya'] }}</span>
                        <span *ngIf="tempData[3]['prushta_sankhya'] == ''">
                            <input type="number" value="{{ tempData[3]['prushta_sankhya'] }}"
                                (change)="addTempData($event, 3, 'prushta_sankhya')"></span> -->
                    </td>
                    <td style="border-width: 2px 2px 0 0; width: 10%;">
                        <input type="text" value="{{ tempData[3]['prakar'] }}"
                            (change)="addTempData($event, 3, 'prakar')">
                        <!-- <span *ngIf="tempData[3]['prakar'] !== ''">{{
                            tempData[3]['prakar']
                            }}</span>
                        <span *ngIf="tempData[3]['prakar'] == ''"><input type="text" value="{{ tempData[3]['prakar'] }}"
                                (change)="addTempData($event, 3, 'prakar')"></span> -->
                    </td>
                    <td style="border-width: 2px 2px 0 0; width: 10%;">
                        <input type="text" value="{{ tempData[3]['pane_denar'] }}"
                            (change)="addTempData($event, 3, 'pane_denar')">
                        <!-- <span *ngIf="tempData[3]['pane_denar'] !== ''">{{
                            tempData[3]['pane_denar'] }}</span>
                        <span *ngIf="tempData[3]['pane_denar'] == ''"><input type="text"
                                value="{{ tempData[3]['pane_denar'] }}"
                                (change)="addTempData($event, 3, 'pane_denar')"></span> -->
                    </td>
                </tr>
                <tr>
                    <td colspan="5" style="border-width: 0 2px 0 2px; width: 50%;"></td>
                    <td colspan="2" style="border-width: 2px 2px 0 0;width: 20%;">
                        <input type="text" value="{{ tempData[4]['prushta_kr'] }}"
                            (change)="addTempData($event, 4, 'prushta_kr')">
                        <!-- <span *ngIf="tempData[4]['prushta_kr'] !== ''">{{ tempData[4]['prushta_kr'] }}</span>
                        <span *ngIf="tempData[4]['prushta_kr'] == ''"><input type="text"
                                value="{{ tempData[4]['prushta_kr'] }}"
                                (change)="addTempData($event, 4, 'prushta_kr')"></span> -->
                    </td>
                    <td style="border-width: 2px 2px 0 0; width: 10%;">
                        <input type="number" value="{{ tempData[4]['prushta_sankhya'] }}"
                            (change)="addTempData($event, 4, 'prushta_sankhya')">
                        <!-- <span *ngIf="tempData[4]['prushta_sankhya'] !== ''">{{ tempData[4]['prushta_sankhya'] }}</span>
                        <span *ngIf="tempData[4]['prushta_sankhya'] == ''">
                            <input type="number" value="{{ tempData[4]['prushta_sankhya'] }}"
                                (change)="addTempData($event, 4, 'prushta_sankhya')"></span> -->
                    </td>
                    <td style="border-width: 2px 2px 0 0; width: 10%;">
                        <input type="text" value="{{ tempData[4]['prakar'] }}"
                            (change)="addTempData($event, 4, 'prakar')">
                        <!-- <span *ngIf="tempData[4]['prakar'] !== ''">{{
                            tempData[4]['prakar']
                            }}</span>
                        <span *ngIf="tempData[4]['prakar'] == ''"><input type="text" value="{{ tempData[4]['prakar'] }}"
                                (change)="addTempData($event, 4, 'prakar')"></span> -->
                    </td>
                    <td style="border-width: 2px 2px 0 0; width: 10%;">
                        <input type="text" value="{{ tempData[4]['pane_denar'] }}"
                            (change)="addTempData($event, 4, 'pane_denar')">
                        <!-- <span *ngIf="tempData[4]['pane_denar'] !== ''">{{
                            tempData[4]['pane_denar'] }}</span>
                        <span *ngIf="tempData[4]['pane_denar'] == ''"><input type="text"
                                value="{{ tempData[4]['pane_denar'] }}"
                                (change)="addTempData($event, 4, 'pane_denar')"></span> -->
                    </td>
                </tr>
                <tr>
                    <td colspan="5" style="border-width: 0 2px 0 2px; width: 50%;">एकूण प्रती : {{ totalprati }}</td>
                    <td colspan="2" style="border-width: 2px 2px 0 0;width: 20%;"></td>
                    <td style="border-width: 2px 2px 0 0; width: 10%;"></td>
                    <td style="border-width: 2px 2px 0 0; width: 10%;"></td>
                    <td style="border-width: 2px 2px 0 0; width: 10%;"></td>
                </tr>
                <tr>
                    <td colspan="5" style="border-width: 0 2px 0 2px; width: 50%;"></td>
                    <td colspan="2" style="border-width: 2px 2px 0 0;width: 20%;">एकूण पृष्ठ :</td>
                    <td style="border-width: 2px 2px 0 0; width: 10%;">{{ totalpsankhya }}</td>
                    <td style="border-width: 2px 2px 0 0; width: 10%;"></td>
                    <td style="border-width: 2px 2px 0 0; width: 10%;"></td>
                </tr>
                <tr>
                    <td colspan="1" style="border-width: 2px 2px 0 2px; width: 10%;">क्रमांक</td>
                    <td colspan="2" style="border-width: 2px 2px 0 0; width: 20%;">विगत</td>
                    <td colspan="2" style="border-width: 2px 2px 0 0; width: 20%;">प्रती</td>
                    <td colspan="3" style="border-width: 2px 2px 0 0;width: 30%;">शेरा</td>
                    <td colspan="2" style="border-width: 2px 2px 0 0; width: 20%;"></td>
                </tr>
                <tr *ngFor="let item of tempdata;let i=index;let e=last">
                    <td colspan="1" style="border-width: 2px 2px 0 2px; width: 10%;">{{ item['no'] }}</td>
                    <td colspan="2" style="border-width: 2px 2px 0 0; width: 20%;">
                        <span *ngIf="item['name'] !== ''">{{ item['name'] }}</span>
                        <span style="float: right;" *ngIf="item['name'] == ''"><input type="text"
                                value="{{ item['name'] }}" (change)="addtempdata($event, i, 'name')"></span>
                    </td>
                    <td colspan="2" style="border-width: 2px 2px 0 0; width: 20%;">
                        <span *ngIf="item['prati'] !== 0">{{
                            item['prati'] }}</span>
                        <span *ngIf="item['prati'] == 0"><input type="text" value="{{ item['prati'] }}"
                                (change)="addtempdata($event, i, 'prati')"></span>
                    </td>
                    <td colspan="3" style="border-width: 2px 2px 0 0;width: 30%;"></td>
                    <td colspan="2" style="border-width: 2px 2px 0 0; width: 20%;">
                        <span *ngIf="e"><button mat-raised-button class="btn btn-outline-info"
                                (click)="addNewtempdata()">Add</button></span>
                    </td>
                </tr>
                <tr>
                    <td colspan="1" style="border-width: 2px 2px 0 2px; width: 10%;"></td>
                    <td colspan="2" style="border-width: 2px 2px 0 0; width: 20%;">एकूण</td>
                    <td colspan="2" style="border-width: 2px 2px 0 0; width: 20%;">{{ totalprati }}</td>
                    <td colspan="3" style="border-width: 2px 2px 0 0;width: 30%;"></td>
                    <td colspan="2" style="border-width: 2px 2px 0 0; width: 20%;"></td>
                </tr>
                <tr>
                    <td colspan="12"
                        style="border-width: 2px 2px 0 2px; width: 100%;padding: 1%; text-align: end;padding-top: 5%;">
                        कार्यालय प्रमुख / व्यवस्थापक
                    </td>
                </tr>
                <tr>
                    <td colspan="12"
                        style="border-width: 2px 2px 0 2px; padding: 0.5%; text-align: center; width: 100%;">
                        पेपर तपशिल</td>
                </tr>
                <tr>
                    <td colspan="1" style="border-width: 2px 2px 0 2px; width: 10%;">पेपर प्रकार</td>
                    <td colspan="2" style="border-width: 2px 2px 0 0; width: 20%;">मागील शिल्लक पेपर रीम</td>
                    <td colspan="2" style="border-width: 2px 2px 0 0; width: 20%;">नवीन आला</td>
                    <td colspan="2" style="border-width: 2px 2px 0 0;width: 20%;">एकूण रीम</td>
                    <td colspan="2" style="border-width: 2px 2px 0 0; width: 20%;">या अंकाला लागणारा पेपर</td>
                    <td style="border-width: 2px 2px 0 0; width: 10%;">शिल्लक पेपर रीम</td>
                </tr>
                <tr *ngFor="let data of testdata">
                    <td colspan="1" style="border-width: 2px 2px 0 2px; width: 10%;">{{ data['name'] }}</td>
                    <td colspan="2" style="border-width: 2px 2px 0 0; width: 20%;"></td>
                    <td colspan="2" style="border-width: 2px 2px 0 0; width: 20%;"></td>
                    <td colspan="2" style="border-width: 2px 2px 0 0;width: 20%;"></td>
                    <td colspan="2" style="border-width: 2px 2px 0 0; width: 20%;"></td>
                    <td style="border-width: 2px 2px 0 0; width: 10%;"></td>
                </tr>
                <tr>
                    <td colspan="12" style="border-width: 2px 0 0 0; width: 100%;"></td>
                </tr>
            </table>
            <!-- <div class="row">
                <div class="col-9" style=" font-size: 18px; border-width: 2px 0 0 2px; padding: 1.5%;">
                    सा. विवेक - अंक छपाई आदेश
                </div>
                <div class="col-3" style="border-width: 2px 2px 0 2px; padding: 1.5%; text-align: start;">
                    दिनांक : 31/05/2022
                </div>
            </div>
            <div class="row">
                <div class="col-7" style="border-width: 2px 2px 0 2px; padding: 1%; text-align: start;">
                    आदेश क्र : 11
                </div>
                <div class="col-2" style="border-width: 2px 2px 0 0; padding: 1%;">
                    पृष्ठ क्रं.
                </div>
                <div class="col-1" style="border-width: 2px 1px 0 0; padding: 1%;">
                    पृष्ठ संख्या
                </div>
                <div class="col-1" style="border-width: 2px 1px 0 0; padding: 1%; text-align: start;">
                    प्रकार
                </div>
                <div class="col-1" style="border-width: 2px 2px 0 0; padding: 1%; text-align: start;">
                    पाने देणार
                </div>
            </div>
            <div class="row">
                <div class="col-7" style="border-width: 2px 2px 0 2px; padding: 1%; text-align: start;">
                    अंक दिनांक : ६ जून २०२२ ते १२ जून २०२२
                </div>
                <div class="col-2" style="border-width: 2px 2px 0 0; padding: 1%;">
                    ०१-०२ व ४३-४४
                </div>
                <div class="col-1" style="border-width: 2px 1px 0 0; padding: 1%;">
                    ४
                </div>
                <div class="col-1" style="border-width: 2px 1px 0 0; padding: 1%; text-align: start;">
                    रंगीत
                </div>
                <div class="col-1" style="border-width: 2px 2px 0 0; padding: 1%; text-align: start;">
                    बुधवार
                </div>
            </div>
            <div class="row">
                <div class="col-7" style="border-width: 0 2px 0 2px; padding: 1%; font-size: 16px;">
                    च्या अंकाकरिता
                </div>
                <div class="col-2" style="border-width: 2px 2px 0 0; padding: 1%;">
                    ०३-१० व ३५-४२
                </div>
                <div class="col-1" style="border-width: 2px 1px 0 0; padding: 1%;">
                    १६
                </div>
                <div class="col-1" style="border-width: 2px 1px 0 0; padding: 1%; text-align: start;">
                    कृष्णधवल
                </div>
                <div class="col-1" style="border-width: 2px 2px 0 0; padding: 1%; text-align: start;">
                    गुरुवार
                </div>
            </div>
            <div class="row">
                <div class="col-7" style="border-width: 0 2px 0 2px; padding: 1%;">

                </div>
                <div class="col-2" style="border-width: 2px 2px 0 0; padding: 1%;">
                    ११-१४ व ३१-३४
                </div>
                <div class="col-1" style="border-width: 2px 1px 0 0; padding: 1%;">
                    ८
                </div>
                <div class="col-1" style="border-width: 2px 1px 0 0; padding: 1%; text-align: start;">
                    रंगीत
                </div>
                <div class="col-1" style="border-width: 2px 2px 0 0; padding: 1%; text-align: start;">
                    बुधवार
                </div>
            </div>
            <div class="row">
                <div class="col-7" style="border-width: 2px 2px 0 2px; padding: 1%;">

                </div>
                <div class="col-2" style="border-width: 2px 2px 0 0; padding: 1%;">
                    १५-०८ व ३७-३०
                </div>
                <div class="col-1" style="border-width: 2px 1px 0 0; padding: 1%;">
                    ८
                </div>
                <div class="col-1" style="border-width: 2px 1px 0 0; padding: 1%; text-align: start;">
                    कृष्णधवल
                </div>
                <div class="col-1" style="border-width: 2px 2px 0 0; padding: 1%; text-align: start;">
                    मंगळवार
                </div>
            </div>
            <div class="row">
                <div class="col-7" style="border-width: 0 2px 0 2px; padding: 1%;">

                </div>
                <div class="col-2" style="border-width: 2px 2px 0 0; padding: 1%;">
                    १९-२२ व २३-२६
                </div>
                <div class="col-1" style="border-width: 2px 1px 0 0; padding: 1%;">
                    ८
                </div>
                <div class="col-1" style="border-width: 2px 1px 0 0; padding: 1%; text-align: start;">
                    रंगीत
                </div>
                <div class="col-1" style="border-width: 2px 2px 0 0; padding: 1%; text-align: start;">
                    मंगळवार
                </div>
            </div>
            <div class="row">
                <div class="col-7" style="border-width: 0 2px 0 2px; padding: 1%; text-align: start;">
                    एकूण प्रती : १०१००
                </div>
                <div class="col-2" style="border-width: 2px 2px 0 0; padding: 1%;">
                </div>
                <div class="col-1" style="border-width: 2px 1px 0 0; padding: 1%;">

                </div>
                <div class="col-1" style="border-width: 2px 1px 0 0; padding: 1%; text-align: start;">

                </div>
                <div class="col-1" style="border-width: 2px 2px 0 0; padding: 1%; text-align: start;">

                </div>
            </div>
            <div class="row">
                <div class="col-7" style="border-width: 0 2px 0 2px; padding: 1%; text-align: start;">
                    प्रती : १०१००
                </div>
                <div class="col-2" style="border-width: 2px 2px 0 0; padding: 1%;">
                    एकूण पृष्ठ
                </div>
                <div class="col-1" style="border-width: 2px 1px 0 0; padding: 1%;">
                    ४४
                </div>
                <div class="col-1" style="border-width: 2px 1px 0 0; padding: 1%; text-align: start;">

                </div>
                <div class="col-1" style="border-width: 2px 2px 0 0; padding: 1%; text-align: start;">

                </div>
            </div>
            <div class="row">
                <div class="col-2" style="border-width: 2px 2px 0 2px; padding: 1%; text-align: start;">
                    क्रमांक
                </div>
                <div class="col-3" style="border-width: 2px 2px 0 0; padding: 1%;">
                    विगत
                </div>
                <div class="col-2" style="border-width: 2px 2px 0 0; padding: 1%;">
                    प्रती
                </div>
                <div class="col-2" style="border-width: 2px 2px 0 0; padding: 1%; text-align: start;">
                    शेरा
                </div>
                <div class="col-3" style="border-width: 2px 2px 0 0; padding: 1%; text-align: start;">

                </div>
            </div>
            <div class="row" *ngFor="let item of tempdata">
                <div class="col-2" style="border-width: 2px 2px 0 2px; padding: 1%; text-align: start;">
                    {{ item['no'] }}
                </div>
                <div class="col-3" style="border-width: 2px 2px 0 0; padding: 1%; text-align: start;">
                    {{ item['name'] }}
                </div>
                <div class="col-2" style="border-width: 2px 2px 0 0; padding: 1%;text-align: end;">
                    {{ item['prati'] }}
                </div>
                <div class="col-2" style="border-width: 2px 2px 0 0; padding: 1%; text-align: start;">

                </div>
                <div class="col-3" style="border-width: 2px 2px 0 0; padding: 1%; text-align: start;">

                </div>
            </div>
            <div class="row">
                <div class="col-2" style="border-width: 2px 2px 0 2px; padding: 1%; text-align: start;">

                </div>
                <div class="col-3" style="border-width: 2px 2px 0 0; padding: 1%; text-align: start;">
                    एकूण
                </div>
                <div class="col-2" style="border-width: 2px 2px 0 0; padding: 1%;text-align: end;">
                    {{ totalprati }}
                </div>
                <div class="col-2" style="border-width: 2px 2px 0 0; padding: 1%; text-align: start;">

                </div>
                <div class="col-3" style="border-width: 2px 2px 0 0; padding: 1%; text-align: start;">

                </div>
            </div>
            <div class="row">
                <div class="col-12" style="border-width: 2px 2px 0 2px; padding: 1%; text-align: end;padding-top: 5%;">
                    कार्यालय प्रमुख / व्यवस्थापक
                </div>
            </div>
            <div class="row">
                <div class="col-12" style="border-width: 2px 2px 0 2px; padding: 0.5%; text-align: center;">
                    पेपर तपशिल
                </div>
            </div>
            <div class="row">
                <div class="col-2" style="border-width: 2px 2px 0 2px; padding: 1%; text-align: start;">
                    पेपर प्रकार
                </div>
                <div class="col-2" style="border-width: 2px 2px 0 0; padding: 1%; text-align: start;">
                    मागील शिल्लक पेपर रीम
                </div>
                <div class="col-2" style="border-width: 2px 2px 0 0; padding: 1%;text-align: end;">
                    नवीन आला
                </div>
                <div class="col-2" style="border-width: 2px 2px 0 0; padding: 1%;text-align: end;">
                    एकूण रीम
                </div>
                <div class="col-2" style="border-width: 2px 2px 0 0; padding: 1%; text-align: start;">
                    या अंकाला लागणारा पेपर
                </div>
                <div class="col-2" style="border-width: 2px 2px 0 0; padding: 1%; text-align: start;">
                    शिल्लक पेपर रीम
                </div>
            </div>
            <div class="row" *ngFor="let data of testdata">
                <div class="col-2" style="border-width: 2px 2px 0 2px; padding: 1%; text-align: start;">
                    {{ data['name'] }}
                </div>
                <div class="col-2" style="border-width: 2px 2px 0 0; padding: 1%; text-align: start;">
                </div>
                <div class="col-2" style="border-width: 2px 2px 0 0; padding: 1%;text-align: end;">
                </div>
                <div class="col-2" style="border-width: 2px 2px 0 0; padding: 1%;text-align: end;">
                </div>
                <div class="col-2" style="border-width: 2px 2px 0 0; padding: 1%; text-align: start;">
                </div>
                <div class="col-2" style="border-width: 2px 2px 0 0; padding: 1%; text-align: start;">
                </div>
            </div>
            <div class="row">
                <div class="col-12" style="border-width: 2px 0 0 0;"></div>
            </div> -->
        </div>
    </div>
</div>