import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DigisubservicesService } from 'src/app/services/digisubservices.service';

@Component({
  selector: 'app-update-taluka-master',
  templateUrl: './update-taluka-master.component.html',
  styleUrls: ['./update-taluka-master.component.scss'],
})
export class UpdateTalukaMasterComponent implements OnInit {
  talukaData;
  talukaForm: FormGroup;

  constructor(
    private dialogref: MatDialogRef<UpdateTalukaMasterComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private service: DigisubservicesService
  ) {
    this.talukaForm = fb.group({
      talukaid: [''],
      talukaname: [''],
      talukapincode: [''],
    });
  }

  ngOnInit(): void {
    this.talukaData = this.data['taluka'];
    this.talukaForm.get('talukaid').setValue(this.talukaData['taluka_id']);
    this.talukaForm.get('talukaname').setValue(this.talukaData['taluka_name']);
    this.talukaForm.get('talukapincode').setValue(this.talukaData['pincode']);
  }

  updateTaluka() {
    if (this.talukaForm.get('talukaid').value > 0) {
      this.service
        .updateTalukaName(
          parseInt(this.talukaForm.get('talukaid').value),
          this.talukaForm.get('talukaname').value,
          parseInt(this.talukaForm.get('talukapincode').value)
        )
        .subscribe((data) => {
          alert('Taluka updated successfully...');
          this.dialogref.close({ res: 'close' });
        });
    }
  }
}
