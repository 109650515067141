import { ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ContractBriefReportComponent } from '../pages/contract-brief-report/contract-brief-report.component';
import { DigisubservicesService } from '../services/digisubservices.service';
import { AdvertisementDetailsComponent } from '../pages/advertisement-details/advertisement-details.component';
import { MatOption, MatSelect } from '@angular/material/select';

@Component({
  selector: 'app-openselectmodal',
  templateUrl: './openselectmodal.component.html',
  styleUrls: ['./openselectmodal.component.scss']
})
export class OpenselectmodalComponent implements OnInit {
  myForm: FormGroup;
  advtCreditNoteModal: any;
  contractData: {};
  contractOffRepName: any;
  uniqueid: any;
  contractRepName: any;
  contractAdvtName: any;
  repname: any;

 
  constructor(private dialogRef: MatDialogRef<ContractBriefReportComponent>,
  @Inject(MAT_DIALOG_DATA) public data: any,
  private service: DigisubservicesService,
  private cdr: ChangeDetectorRef,private fb: FormBuilder,public activeModal: MatDialogRef<AdvertisementDetailsComponent>,
  @Inject(MAT_DIALOG_DATA) public data1: any
)
 {

  }

  ngOnInit(): void {
    this.replist();
    this.myForm = this.fb.group({
      CreitVC: ['', Validators.required],
      invcnum: ['', Validators.required],
      advtname: ['', Validators.required],
      advtno: ['', Validators.required],
      crndate: ['', Validators.required],
      Vcdate: ['', Validators.required],
      CRamt: ['', Validators.required],
      Repname: ['', Validators.required],
      remark: ['', Validators.required],
      Crtype: ['', Validators.required],
      otherAmt: ['', Validators.required],
      tdsAmt: ['', Validators.required]
    });

    
  this.myForm.patchValue({
    CreitVC:  this.data['cr_vocher'],
      invcnum: this.data['invoice_no'],
      advtname: this.data['advt_name1'],
      advtno: this.data['advertisement_id'],
      crndate: this.data['create_date'],
      Vcdate:this.data['receipt_date'],
      CRamt: this.data['remainvalue'],
      Repname: this.data['rep_name'][0],
      remark: null,
      Crtype: null,
  })
  }

  getModaldata(){
    console.log("Hello")
    

    // const req2 = {
    //   creditvc: this.myForm.value.CreitVC,
    //   invoice: this.myForm.value.invcnum,
    //   advt_name: this.myForm.value.advtname,
    //   AdvtNo: this.myForm.value.advtno,
    //   Creditdate: this.myForm.value.crndate,
    //   VocherDate: this.myForm.value.Vcdate,
    //   CreditAmt: this.myForm.value.CRamt,
    //   type: this.myForm.value.Crtype,
    //   Repname: this.myForm.value.Repname,
    //   Remark: this.myForm.value.remark,

    // }
    const req = {
      "data" :{
        credit_voc_no: this.myForm.value.CreitVC,
        invoice_no: this.myForm.value.invcnum,
        advt_name: this.myForm.value.advtname,
        advt_no: this.myForm.value.advtno,
        creation_date: this.myForm.value.crndate,
        voc_date: this.myForm.value.Vcdate,
        credit_amt: this.myForm.value.CRamt,
        credit_type: this.myForm.value.Crtype,
        rep_name: this.myForm.value.Repname,
        credit_remark: this.myForm.value.remark,
        credit_count:this.data['credit_count'],
      }
    }
    this.service.getCreditNoteModal(req).subscribe((data: any) => {
      console.log("ADVT new Credit name Data=", data);
      this.advtCreditNoteModal = (data as any);;
      // this.ngOnInit();
      this.id=data.id
      this.closeModalWithResult()
      this.myForm.reset()
      
    });
    console.log(req)
  }

  id:any
  closeModalWithResult() {
    const dataToSend = {
      key1: this.id,
      credit_type: this.myForm.value.Crtype,
      remark : this.myForm.value.remark
      // Add more key-value pairs as needed
    };
    this.activeModal.close(dataToSend);
  }

  replist() {
    this.service.getNewRepList().subscribe((data:any) => {
       
       this.repname = (data as any);;

       console.log("replist", this.repname);

  
    });
  }



  getallcontarctdetails() {
    this.service.getAllContractDetails().subscribe((res) => {
      // console.log(res);
      this.contractData = res;
      this.getContractDetails();
      console.log(res);
    });
  }
  contractReportRepName = '';
  getContractRepName(event) {
    this.contractReportRepName = event.value;
  }


  getContractDetails() {
    for (
      let index = 0;
      index < Object.keys(this.contractData).length;
      index++
    ) {
      this.contractRepName.push({
        name: this.contractData[index]['rep_name'],
      });
      this.contractOffRepName.push({
        name: this.contractData[index]['off_rep_name'],
      });
      this.contractAdvtName.push({
        name: this.contractData[index]['advt_name'],
      });
    }

    this.contractRepName = this.uniqueid(this.contractRepName, (a) => a.name);
    this.contractOffRepName = this.uniqueid(
      this.contractOffRepName,
      (a) => a.name
    );
    this.contractAdvtName = this.uniqueid(this.contractAdvtName, (a) => a.name);
  }

  allSelected = false;
  @ViewChild('mySel') skillSel: MatSelect;
  toggleAllSelection() {
    this.allSelected = !this.allSelected; // to control select-unselect
    // console.log(this.skillSel.options);
    if (this.allSelected) {
      this.skillSel.options.forEach((item: MatOption) => item.select());
    } else {
      this.skillSel.options.forEach((item: MatOption) => {
        item.deselect();
      });
    }
    this.skillSel.close();
  }
}
