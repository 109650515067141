import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DigisubservicesService } from 'src/app/services/digisubservices.service';

@Component({
  selector: 'app-add-book-per-price',
  templateUrl: './add-book-per-price.component.html',
  styleUrls: ['./add-book-per-price.component.scss'],
})
export class AddBookPerPriceComponent implements OnInit {
  bookreceipt;
  bookdata = [];
  receivedamtform: FormGroup;

  constructor(
    public service: DigisubservicesService,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<AddBookPerPriceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.receivedamtform = fb.group({
      receivedamt: [''],
    });
  }

  ngOnInit(): void {
    this.bookreceipt = this.data['book'];
    console.log('rcpt data', this.bookreceipt);
    if (this.bookreceipt['invoice_no'].includes('BDO')) {
      // alert('Before Invoice Generate');
      this.service
        .getRcptWiseBooks1(parseInt(this.bookreceipt['id']))
        .subscribe((data) => {
          console.log({ data });
          for (let index = 0; index < Object.keys(data).length; index++) {
            this.bookdata.push({
              id: this.bookreceipt['id'],
              bookid: data[index]['id'],
              book_name: data[index]['book_name'],
              netamt: data[index]['netamt'],
              copies: data[index]['copies'],
              total: data[index]['total'],
              shipping_charges: data[index]['shipping_charges'],
              total_amount:
                parseInt(data[index]['total']) +
                parseInt(data[index]['shipping_charges']),
              rcvd_amt: data[index]['received_amt'],
              remaining_amt: data[index]['remaining_amt'],
              invoice_no: this.bookreceipt['invoice_no'],
            });
          }
        });
    } else {
      this.service
        .getRcptWiseBooks(parseInt(this.bookreceipt['id']))
        .subscribe((data) => {
          console.log({ data });
          for (let index = 0; index < Object.keys(data).length; index++) {
            this.bookdata.push({
              id: this.bookreceipt['id'],
              bookid: data[index]['id'],
              book_name: data[index]['book_name'],
              netamt: data[index]['netamt'],
              copies: data[index]['copies'],
              total: data[index]['total'],
              shipping_charges: data[index]['shipping_charges'],
              total_amount:
                parseInt(data[index]['total']) +
                parseInt(data[index]['shipping_charges']),
              rcvd_amt: data[index]['received_amt'],
              remaining_amt: data[index]['remaining_amt'],
              invoice_no: this.bookreceipt['invoice_no'],
            });
          }
        });
    }
  }

  total_remaining_amt = 0;
  total_val = 0;
  value = 0;
  calcamount(event, book) {
    let val = event.target.value;
    this.total_val = 0;
    // console.log({ book });
    let remaining_amt =
      parseInt(book['total_amount']) -
      (parseInt(book['rcvd_amt']) + parseInt(val));
    this.total_remaining_amt = 0;
    for (let index = 0; index < Object.keys(this.bookdata).length; index++) {
      if (this.bookdata[index]['bookid'] == book['bookid']) {
        this.bookdata[index]['rcvd_amt'] =
          parseInt(this.bookdata[index]['rcvd_amt']) + parseInt(val);
        this.value = this.value + parseInt(val);
        this.bookdata[index]['remaining_amt'] = remaining_amt;
      }
      this.total_val =
        this.total_val + parseInt(this.bookdata[index]['rcvd_amt']);
      this.total_remaining_amt =
        this.total_remaining_amt +
        parseInt(this.bookdata[index]['remaining_amt']);
    }
  }

  save() {
    console.log(this.value);

    this.dialogRef.close({
      data: this.bookdata,
      remaining_amt: this.total_remaining_amt,
      value: this.value,
      id: this.bookreceipt['id'],
    });
    // if (confirm('This operation is not reversable. Do you want to proceed.')) {
    //   for (let index = 0; index < Object.keys(this.bookdata).length; index++) {
    //     this.service
    //       .updateRcptWiseBooks(
    //         this.bookdata[index]['bookid'],
    //         this.bookdata[index]['rcvd_amt'],
    //         this.bookdata[index]['remaining_amt']
    //       )
    //       .subscribe((data) => {
    //         console.log(data);
    //         alert('Books price added successfully...');
    //         // this.dialogRef.close();
    //       });
    //   }
    // }
  }
}
