import { formatDate } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { DigisubservicesService } from 'src/app/services/digisubservices.service';

export const _filter = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();

  return opt.filter((item) => item.toLowerCase().includes(filterValue));
};

@Component({
  selector: 'app-show-distribution',
  templateUrl: './show-distribution.component.html',
  styleUrls: ['./show-distribution.component.scss'],
})
export class ShowDistributionComponent implements OnInit {
  vatnavalform: FormGroup;
  distform: FormGroup;
  startDate;
  endDate;
  // dateform = new FormGroup({
  //   start: new FormControl(),
  //   end: new FormControl(),
  // });
  filteredOptions: Observable<String[]>;
  rep_namesData = [];

  constructor(
    private service: DigisubservicesService,
    private dialogRef: MatDialogRef<ShowDistributionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder
  ) {
    this.distform = fb.group({
      rep_name: [''],
    });

    // this.dateform = fb.group({
    //   start: [''],
    //   end: [''],
    // });

    this.vatnavalform = fb.group({
      vatdar: [''],
    });
  }

  ngOnInit(): void {
    this.startDate = this.data['start'];
    this.endDate = this.data['end'];

    this.filteredOptions = this.distform.get('rep_name').valueChanges.pipe(
      startWith(''),
      map((value) => (typeof value === 'string' ? value : value.rep_name)),
      map((rep_name) =>
        rep_name ? this._filter(rep_name) : this.rep_namesData.slice()
      )
    );
  }

  private _filter(rep_name: string): any[] {
    const filterValue = rep_name;

    return this.rep_namesData.filter((option) =>
      // option.mobno.toLowerCase().includes(filterValue)
      option.rep_name.toLowerCase().includes(filterValue)
    );
  }

  calcamount(event) {}

  rep_name1 = '';
  getrep_name(event, option) {
    if (event.isUserInput) {
      this.rep_name1 = option['rep_name'];
    }
  }

  reset_rep_name() {
    this.rep_name1 = '';
    this.distform.get('rep_name').value;
  }

  // startDate;
  // endDate;
  // getChangedDaterep() {
  //   if (
  //     this.dateform.get('start').value != '' &&
  //     this.dateform.get('end').value != ''
  //   ) {
  //     this.startDate = formatDate(
  //       this.dateform.get('start').value,
  //       'yyyy-MM-dd',
  //       'en-IN',
  //       'GMT+0530'
  //     );

  //     this.endDate = formatDate(
  //       this.dateform.get('end').value,
  //       'yyyy-MM-dd',
  //       'en-IN',
  //       'GMT+0530'
  //     );
  //   }
  // }
}
