<div mat-dialog-content>
    <div class="text-center">
        <h1 style="font-size: medium;font-weight: bold;" *ngIf="!viewmode">Edit Book delivery</h1>
        <h1 style="font-size: medium;font-weight: bold;" *ngIf="viewmode">View Book delivery</h1>
    </div>
    <form class="form" [formGroup]="BookForm" (ngSubmit)=save()>
        <div class="text-center">
            <h3 style="font-size: small;font-weight: bold; color: red;">Personal Details</h3>
        </div>

        <div class="form-row">
            <div class="col form-group">
                <label style="margin-left: 3%; margin-top: 20px;"> Name :</label>
                <input required style="width: 90%; margin-left: 3%;" formControlName="name" type="text" class="form-control" readonly>
            </div>
            <!-- form-group end.// -->
        </div>

        <div class="form-row">

            <div class="col form-group">
                <label style="margin-left: 3%; ">Mobile :</label>
                <input required style="width: 90%; margin-left: 3%;" readonly formControlName="mobile" type="text" class="form-control" placeholder="">
            </div>

            <div class="col form-group">
                <label style="margin-left: 3%; ">Email :</label>
                <input required style="width: 90%; margin-left: 3%;" readonly formControlName="email" type="text" class="form-control" placeholder="">
            </div>

        </div>
        <div class="form-row">
            <div class="col form-group">
                <label style="margin-left: 2%;">Address : </label>
                <input required style="width: 90%; margin-left: 2%;" readonly formControlName="address" type="textarea" class="form-control">
            </div>
            <!-- form-group end.// -->
        </div>

        <div class="form-row">
            <div class="col form-group">
                <label style="margin-left: 3%; ">City : </label>
                <input required style="width: 90%;" formControlName="city" readonly type="text" class="form-control" placeholder="">
            </div>
            <!-- form-group end.// -->

            <div class="col form-group">
                <label style="margin-left: 3%; ">State : </label>
                <input required style="width: 90%;" formControlName="state" readonly type="text" class="form-control" placeholder="">
            </div>
            <!-- form-group end.// -->
        </div>

        <div class="form-row">

            <div class="col form-group">
                <label style="margin-left: 3%; ">Country :</label>
                <input required style="width: 90%; margin-left: 3%;" readonly formControlName="country" type="text" class="form-control" placeholder="">
            </div>

            <div class="col form-group">
                <label style="margin-left: 3%; ">Pincode :</label>
                <input required style="width: 90%; margin-left: 3%;" readonly formControlName="pincode" type="text" class="form-control" placeholder="">
            </div>

        </div>

        <div class="text-center">
            <h3 style="font-size: small;font-weight: bold; color: red;">Book Details</h3>
        </div>

        <div class="form-row">

            <div class="col form-group">
                <label style="margin-left: 3%; ">Book Name :</label>
                <input required style="width: 90%; margin-left: 3%;" readonly formControlName="book_name" type="text" class="form-control" placeholder="">
            </div>

            <div class="col form-group">
                <label style="margin-left: 3%; ">Book Amount :</label>
                <input required style="width: 90%; margin-left: 3%;" readonly formControlName="book_amount" type="text" class="form-control" placeholder="">
            </div>

        </div>

        <div class="form-row">

            <div class="col form-group">
                <label style="margin-left: 3%; ">Book Quantity :</label>
                <input required style="width: 90%; margin-left: 3%;" readonly formControlName="book_qty" type="text" class="form-control" placeholder="">
            </div>

            <div class="col form-group">
                <label style="margin-left: 3%; ">Payment Mode :</label>
                <input required style="width: 90%; margin-left: 3%;" readonly formControlName="paymentmode" type="text" class="form-control" placeholder="">
            </div>

        </div>

        <div class="text-center">
            <h3 style="font-size: small;font-weight: bold; color: red;">Tracking Details</h3>
        </div>


        <div class="form-row" >

            <div class="col form-group">
                <label style="margin-left: 3%; ">Tracking Details :</label>
                <textarea required style="width: 90%; margin-left: 3%;" [readonly]="viewmode" formControlName="tracking_id" type="text" class="form-control" (change)="getTrackingId($event)"></textarea>
            </div>

            <div class="col form-group">
                <label style="margin-left: 3%; ">Trasnport Mode :</label>
                
                <select style="width: 90%; margin-left: 3%;" class="form-control" 
                placeholder="Select One" formControlName="transport_method" (change)="getTrackingMethod($event)" required>
                <option value="self">Self</option>
                <option value="byhand">हस्ते</option>
                <option value="byhandpost">हस्ते by Post</option>
                <option value="post">Post</option>
                <option value="courier">Courier</option>
                <option value="st">ST</option>
                <option value="railway">Railway</option>
                </select>
            </div>

        </div>

        <div class="form-row" *ngIf="displayByhand">
            <div class="col form-group">
                <label style="margin-left: 3%; ">हस्ते वितरकाचे नाव :</label>
                <input required style="width: 90%; margin-left: 3%;" [readonly]="viewmode" formControlName="transporter_name" type="text" class="form-control" placeholder="">
            </div>
        </div>

        <!-- <div class="form-row" *ngIf="viewmode">

            <div class="col form-group">
                <label style="margin-left: 3%; ">Tracking Id :</label>
                <input required style="width: 90%; margin-left: 3%;" readonly formControlName="tracking_id" type="text" class="form-control" (change)="getTrackingId($event)">
            </div>

            <div class="col form-group">
                <label style="margin-left: 3%; ">Trasnport Mode :</label>
                <select style="width: 90%; margin-left: 3%;" class="form-control" 
                placeholder="Select One" formControlName="transport_method" (change)="getTrackingMethod($event)" readonly>
                <option value="self">Self</option>
                <option value="selfpost">Cust. Post/Courier</option>
                <option value="post">Cust. Transport/ST Parcel</option>
                </select>
            </div>

        </div> -->




        <div mat-dialog-actions style="float:right">
            <button mat-button mat-dialog-close>Close</button>
            <button [disabled]="enablebutton" *ngIf="!viewmode" mat-button (click)="update()"> Update</button>
        </div>


        <div class="form-group">

        </div>
        <!-- form-group// -->


    </form>

</div>