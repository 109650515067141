<div>
  <form class="form" [formGroup]="sendmsgform" (ngSubmit)="sendmsg()">
    <div style="border: 2px solid black; margin: 15px 0px">
      <!-- <mat-card> -->

      <div class="row">
        <div
          class="col-6"
          style="
            border-style: dashed;
            border-width: 0px 2px 0px 0px;
            padding-top: 20px;
          "
        >
          <div class="form-row">
            <label style="margin-top: 20px; margin-left: 05%">बुक सेलर :</label>
            <mat-form-field class="example-full-width" style="width: 65%">
              <mat-label></mat-label>
              <input matInput formControlName="bsname" />
            </mat-form-field>
          </div>
          <div class="form-row">
            <label style="margin-top: 20px; margin-left: 05%"
              >प्रतिनिधी नाव :</label
            >
            <mat-form-field class="example-full-width" style="width: 65%">
              <mat-label></mat-label>
              <input matInput formControlName="repname" />
            </mat-form-field>
          </div>
          <div class="form-row">
            <label style="margin-top: 20px; margin-left: 05%"
              >ऑर्डर नंबर :</label
            >
            <mat-form-field class="example-full-width" style="width: 45%">
              <mat-label></mat-label>
              <input matInput formControlName="orderno" />
            </mat-form-field>
            <label style="margin-top: 20px; margin-left: 05%">दिनांक :</label>
            <mat-form-field class="example-full-width" style="width: 20%">
              <mat-label></mat-label>
              <input matInput formControlName="orderdate" />
            </mat-form-field>
          </div>
          <div class="form-row">
            <label style="margin-top: 20px; margin-left: 05%">बिल नंबर :</label>
            <mat-form-field class="example-full-width" style="width: 46.5%">
              <mat-label></mat-label>
              <input matInput formControlName="billno" />
            </mat-form-field>
            <label style="margin-top: 20px; margin-left: 05%">दिनांक :</label>
            <mat-form-field class="example-full-width" style="width: 20%">
              <mat-label></mat-label>
              <input matInput formControlName="billdate" />
            </mat-form-field>
          </div>
          <div class="form-row">
            <label style="margin-top: 20px; margin-left: 05%"
              >पावती नंबर :</label
            >
            <mat-form-field class="example-full-width" style="width: 45%">
              <mat-label></mat-label>
              <input matInput formControlName="receiptno" />
            </mat-form-field>
            <label style="margin-top: 20px; margin-left: 05%">दिनांक :</label>
            <mat-form-field class="example-full-width" style="width: 20%">
              <mat-label></mat-label>
              <input matInput formControlName="receiptdate" />
            </mat-form-field>
          </div>
          <div class="row" style="margin-left: 03%; margin-top: 30px">
            <div class="col-6 tableDescheader">पुस्तकांचे नाव -</div>
            <div class="col-6 tableDescheader">प्रती संख्या :</div>
          </div>
          <div
            class="row"
            style="margin-left: 03%"
            *ngFor="let item of this.books; let i = index"
          >
            <div class="col-6 tableDesc">
              {{ i + 1 }}){{ item["bookname"] }}
            </div>
            <div class="col-6 tableDesc">{{ item["bookcopies"] }}</div>
          </div>
          <div class="row" style="margin-top: 50px">
            <label style="margin-top: 20px; margin-left: 05%"
              >कुरियर नाव :</label
            >
            <mat-form-field class="example-full-width" style="width: 45%">
              <mat-label></mat-label>
              <input matInput formControlName="couriername" />
            </mat-form-field>
            <label style="margin-top: 20px; margin-left: 05%">दिनांक :</label>
            <mat-form-field class="example-full-width" style="width: 20%">
              <mat-label></mat-label>
              <input matInput formControlName="courierdate" />
            </mat-form-field>
          </div>
          <div class="row">
            <label style="margin-top: 20px; margin-left: 05%">वेबसाईट :</label>
            <mat-form-field class="example-full-width" style="width: 47.5%">
              <mat-label></mat-label>
              <input matInput formControlName="courierwebsite" />
            </mat-form-field>
          </div>
        </div>
        <div class="col-6" style="margin-top: 10px">
          <div class="row" style="margin-bottom: 80px">
            <span
              style="font-size: medium; margin-left: 04%; margin-top: 10px"
              >{{ this.book["deliverymode"] }}</span
            >
          </div>
          <!-- <br /> -->
          <span style="font-size: medium; font-weight: bold; margin-left: 04%">
            प्रति,<br />
          </span>
          <span style="font-size: medium; margin-left: 04%; margin-top: 10px"
            >बुक सेलरचे नाव : {{ this.book["name"] }}</span
          >
          <div class="form-row">
            <label style="margin-top: 30px; margin-left: 05%">पत्ता :</label>
            <mat-form-field class="example-full-width" style="width: 78%">
              <mat-label></mat-label>
              <textarea
                matInput
                rows="2"
                placeholder="Address"
                formControlName="address"
              ></textarea>
            </mat-form-field>
          </div>
          <div class="form-row">
            <label style="margin-top: 20px; margin-left: 05%"
              >मोबाईल नंबर :</label
            >
            <mat-form-field class="example-full-width" style="width: 70%">
              <mat-label></mat-label>
              <input matInput formControlName="mobno" />
            </mat-form-field>
          </div>
          <div class="row" style="margin-top: 80px">
            <div class="col-5">
              <img
                style="margin-top: 2%; margin-left: 05%"
                width="70%"
                src="../../../assets/img/brand/red.png"
              />
            </div>
            <div class="col-7">
              <span style="font-size: medium; font-weight: bold">प्रेषक,</span
              ><br />
              <span style="font-size: small"
                >हिंदुस्थान प्रकाशन संस्था - पुस्तक विभाग,</span
              ><br />
              <span style="font-size: small"
                >विवेक भवन (कृष्णा रिजन्सी), सेक्टर क्र. ३०,<br />
                प्लॉट- ४०, सानपाडा (प.), नवी मुंबई,<br />
                जि. ठाणे - ४००७०५.<br
              /></span>
              <span style="font-size: small">दूरध्वनी: ०२२-२७८१०२३५</span>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="form-row">
      <label style="margin-right: 10px; margin-top: 05px; margin-left: 10%"
        >दिनांक</label
      >
      <input type="date" formControlName="date" style="width: 70%" />
    </div>
    <div class="form-row">
      <mat-form-field
        appearance="outline"
        class="example-full-width"
        style="margin-top: 10px; width: 80%; margin-left: 10%"
      >
        <mat-label>कुरिअर नाव </mat-label>
        <input matInput formControlName="couriername" />
      </mat-form-field>
    </div>
    <div class="form-row">
      <mat-form-field
        appearance="outline"
        class="example-full-width"
        style="width: 80%; margin-left: 10%"
      >
        <mat-label>कुरिअर पावती क्रं </mat-label>
        <input matInput formControlName="courierreceiptno" />
      </mat-form-field>
    </div>
    <div class="form-row">
      <mat-form-field
        appearance="outline"
        class="example-full-width"
        style="width: 80%; margin-left: 10%"
      >
        <mat-label>कुरिअर वेबसाईट / मोबाईल नंबर </mat-label>
        <input matInput formControlName="courierwebsiteormobileno" />
      </mat-form-field>
    </div> -->
    </div>
    <div class="form-group">
      <button
        style="margin-left: 03%; margin-top: 2%; width: 10%"
        [disabled]="sendmsgform.invalid"
        type="submit"
        mat-raised-button
        color="primary"
        class="btn btn-primary btn-block noPrint"
      >
        Send Message
      </button>
      <button
        class="noPrint"
        type="button"
        style="margin-top: 2%; margin-right: 2%; margin-left: 2%"
        (click)="print()"
        mat-raised-button
        color="primary"
      >
        Print
      </button>
      <button
        class="noPrint"
        style="margin-top: 2%"
        mat-button
        mat-dialog-close
      >
        Close
      </button>
    </div>
  </form>
</div>
