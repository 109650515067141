import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DigisubservicesService } from 'src/app/services/digisubservices.service';
import { ToWords } from 'to-words';

@Component({
  selector: 'app-show-book-crdt-note-rcpt',
  templateUrl: './show-book-crdt-note-rcpt.component.html',
  styleUrls: ['./show-book-crdt-note-rcpt.component.scss'],
})
export class ShowBookCrdtNoteRcptComponent implements OnInit {
  bookcrdtnotedata;
  towords;
  crdtnotetotalwords;
  selectedBooks;
  totalamount = 0;
  crdtnoteamttotal = 0;

  constructor(
    public service: DigisubservicesService,
    private dialogRef: MatDialogRef<ShowBookCrdtNoteRcptComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.bookcrdtnotedata = this.data['data'];
    console.log(this.bookcrdtnotedata);
    this.towords = new ToWords();
    this.crdtnotetotalwords = this.towords.convert(
      this.bookcrdtnotedata['crdtnote_total'],
      {
        currency: true,
      }
    );

    this.service
      .getBookCrdtNoteBooks(this.bookcrdtnotedata['id'])
      .subscribe((data) => {
        this.selectedBooks = data;
        // console.log({ data });
        for (
          let index = 0;
          index < Object.keys(this.selectedBooks).length;
          index++
        ) {
          this.crdtnoteamttotal =
            this.crdtnoteamttotal +
            parseInt(this.selectedBooks[index]['crdtnote_amt']);
          this.totalamount =
            this.totalamount + parseInt(this.selectedBooks[index]['total']);
        }
      });
  }

  print() {
    window.print();
  }

  ConvertToInt(val) {
    return parseInt(val);
  }
}
