import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DigisubservicesService } from 'src/app/services/digisubservices.service';
import { FileUploader } from 'ng2-file-upload';
import { formatDate } from '@angular/common';

const uploadAPI = 'https://vivekweb.in/VivekApplication/portal_fileUpload.php';
@Component({
  selector: 'app-edit-book',
  templateUrl: './edit-book.component.html',
  styleUrls: ['./edit-book.component.scss'],
})
export class EditBookComponent implements OnInit {
  BookForm: FormGroup;
  bookData = [];
  isupcoming = false;
  displayDiscount = false;
  imgPath: string;
  bookimgpath = 'https://vivekweb.in/VivekApplication/uploads/';
  totalpages: number = 0;

  public uploader: FileUploader = new FileUploader({
    url: uploadAPI,
    itemAlias: 'file',
  });
  constructor(
    private fb: FormBuilder,
    public service: DigisubservicesService,
    private dialogRef: MatDialogRef<EditBookComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.BookForm = this.fb.group({
      bookid: [''],
      bookname: [''],
      bookdesc: [''],
      price: [''],
      saleprice: [''],
      bookimg: [''],
      authorname: [''],
      bookpagesbw: [''],
      bkpinsidecolor: [''],
      bkpcover: [''],
      booksize: [''],
      paperbw: [''],
      paperinsidecolor: [''],
      papercover: [''],
      totalpages: [''],
      binding: [''],
      booklang: [''],
      bs_id: [''],
      bookyear: [''],
      bookedition: [''],
      bookedtndate: [''],
      bookedtncpoies: [''],
      opening_stock: [''],
      author_honorarium: [''],
      isdelivery: [''],
      isupcoming: [''],
      upcomingdate: [''],
      discountupto: [''],
      bookcategory: [''],
    });
  }

  ngOnInit(): void {
    console.log(this.data);
    this.bookData = this.data['book'];

    this.selectBook();

    this.bookimgpath =
      'https://vivekweb.in/VivekApplication/uploads/' +
      this.bookData['bookimg'];

    if (this.data.book['isupcoming'] == 'Y') {
      this.isupcoming = true;
    } else {
      this.isupcoming = false;
    }

    if (parseInt(this.data.book['saleprice']) > 0) {
      this.displayDiscount = true;
    } else {
      this.displayDiscount = false;
    }

    this.uploader.onAfterAddingFile = (file) => {
      file.withCredentials = false;
    };

    this.uploader.onCompleteItem = (
      item: any,
      response: any,
      status: any,
      headers: any
    ) => {
      console.log('FileUpload:uploaded successfully:', item, status, response);
      this.imgPath = item['file']['name'];
      this.BookForm.get('bookimg').setValue(this.imgPath);
      alert('Your file has been uploaded successfully');
    };
  }

  selectBook() {
    this.BookForm.get('bookid').setValue(this.bookData['bookid']);
    this.BookForm.get('bookname').setValue(this.bookData['bookname']);
    this.BookForm.get('bookdesc').setValue(this.bookData['bookdesc']);
    this.BookForm.get('bookimg').setValue(this.bookData['bookimg']);
    this.BookForm.get('authorname').setValue(this.bookData['author']);
    this.BookForm.get('bookpagesbw').setValue(this.bookData['bkpagesbw']);
    this.BookForm.get('bkpinsidecolor').setValue(
      this.bookData['bkpinsidecolor']
    );
    this.BookForm.get('bkpcover').setValue(this.bookData['bkpcover']);
    this.BookForm.get('totalpages').setValue(this.bookData['book_pages']);
    this.BookForm.get('booksize').setValue(this.bookData['booksize']),
      this.BookForm.get('paperbw').setValue(this.bookData['paperbw']);
    this.BookForm.get('paperinsidecolor').setValue(
      this.bookData['paperinsidecolor']
    );
    this.BookForm.get('papercover').setValue(this.bookData['papercover']);
    this.BookForm.get('binding').setValue(this.bookData['binding']);
    this.BookForm.get('booklang').setValue(this.bookData['language']);
    this.BookForm.get('isupcoming').setValue(this.bookData['isupcoming']);
    this.BookForm.get('upcomingdate').setValue(this.bookData['upcomingdate']);
    this.BookForm.get('author_honorarium').setValue(
      this.bookData['author_honorarium']
    );
    this.BookForm.get('bookcategory').setValue(this.bookData['bookcategory']);
    // another table details
    this.BookForm.get('bs_id').setValue(this.bookData['bs_id']);
    this.BookForm.get('bookyear').setValue(this.bookData['year']);
    this.BookForm.get('price').setValue(this.bookData['price']);
    this.BookForm.get('saleprice').setValue(this.bookData['saleprice']);
    this.BookForm.get('bookedition').setValue(this.bookData['bookedition']);
    this.BookForm.get('bookedtndate').setValue(
      this.bookData['bookeditiondate'] == '0000-00-00 00:00:00'
        ? ''
        : formatDate(
            this.bookData['bookeditiondate'],
            'yyyy-MM-dd',
            'en-IN',
            'GMT+0530'
          )
    );
    this.BookForm.get('bookedtncpoies').setValue(this.bookData['total_copies']);
    this.BookForm.get('opening_stock').setValue(
      this.bookData['available_stock']
    );
    this.BookForm.get('isdelivery').setValue(this.bookData['deliveryIncluded']);
  }

  save() {
    if (this.imageChange) {
      this.uploader.uploadAll();
    }
    setTimeout(() => {
      if (this.imageChange) {
        // console.log(bookbasicsdata);
        this.updateBooksData();
      } else {
        // console.log(bookbasicsdata);
        this.updateBooksData();
      }
    }, 3500);
  }

  updateBooksData() {
    let bookbasicsdata = {
      bookid: this.BookForm.get('bookid').value,
      bookname: this.BookForm.get('bookname').value,
      authorname: this.BookForm.get('authorname').value,
      bookdesc: this.BookForm.get('bookdesc').value,
      bookpagesbw: this.BookForm.get('bookpagesbw').value,
      bkpinsidecolor: this.BookForm.get('bkpinsidecolor').value,
      bkpcover: this.BookForm.get('bkpcover').value,
      totalpages: this.BookForm.get('totalpages').value,
      booksize: this.BookForm.get('booksize').value,
      paperbw: this.BookForm.get('paperbw').value,
      paperinsidecolor: this.BookForm.get('paperinsidecolor').value,
      papercover: this.BookForm.get('papercover').value,
      binding: this.BookForm.get('binding').value,
      booklang: this.BookForm.get('booklang').value,
      book_img: this.BookForm.get('bookimg').value,
      isupcoming: this.BookForm.get('isupcoming').value,
      upcomingdate: this.BookForm.get('upcomingdate').value,
      bookcategory: this.BookForm.get('bookcategory').value,
    };
    // console.log('bookbasicsdata', bookbasicsdata);
    let bookeditiondata = {
      bs_id: this.BookForm.get('bs_id').value,
      bookid: this.BookForm.get('bookid').value,
      bookyear: this.BookForm.get('bookyear').value,
      bookprice: this.BookForm.get('price').value,
      booksaleprice: this.BookForm.get('saleprice').value,
      discountupto: this.BookForm.get('discountupto').value,
      bookedition: this.BookForm.get('bookedition').value,
      bookedtndate: this.BookForm.get('bookedtndate').value,
      bookedtncpoies: this.BookForm.get('bookedtncpoies').value,
      opening_stock: this.BookForm.get('opening_stock').value,
      author_honorarium: this.BookForm.get('author_honorarium').value,
      deliverychargesinclude: this.BookForm.get('isdelivery').value,
    };

    this.service.updateBook(bookbasicsdata).subscribe((up) => {
      // console.log('bookeditiondata', bookeditiondata);
      this.service
        .UpdateNewBookAvailableStock(
          this.BookForm.get('bookid').value,
          this.BookForm.get('bookedtncpoies').value,
          this.BookForm.get('opening_stock').value
        )
        .subscribe((data) => {});

      this.service
        .updateBookSubStockDetails(bookeditiondata)
        .subscribe((res) => {
          window.alert(
            'Book details has been added. Please try to refresh the page.'
          );
          setTimeout(() => {
            this.dialogRef.close();
          }, 700);
        });
    });
  }

  imageChange = false;
  ImageUploads(event) {
    console.log(event.target.value);
    if (event.target.value == '') {
      this.imageChange = false;
    } else {
      this.imageChange = true;
    }
  }

  getSearchTerm(event) {
    if (event.target.value == 'Y') {
      this.isupcoming = true;
    } else {
      this.isupcoming = false;
    }
  }

  getSalePrice(event) {
    if (parseInt(event.target.value) > 0) {
      this.displayDiscount = true;
    } else {
      this.displayDiscount = false;
    }
  }

  caltotalpages(event) {
    let val = parseInt(event.target.value);
    console.log('val', event.target.value);

    this.totalpages = this.totalpages + val;
    this.BookForm.get('totalpages').setValue(this.totalpages);
  }
}
