<div>
    <div class="mt-5">
        <mat-form-field appearance="outline" style="width: 27%; margin-left: 5%">
            <mat-label>Credit Voucher Amount</mat-label>
            <input matInput type="number" [(ngModel)]="crdtvAmt" readonly />
        </mat-form-field>
        <mat-form-field appearance="outline" style="width: 27%; margin-left: 2%">
            <mat-label>Select Entry Amount</mat-label>
            <input matInput type="number" [(ngModel)]="selectedAmt" readonly />
        </mat-form-field>
        <mat-form-field appearance="outline" style="width: 27%; margin-left: 2%">
            <mat-label>Balance Amount</mat-label>
            <input matInput type="number" [(ngModel)]="balanceAmt" readonly />
        </mat-form-field>
    </div>
    <div style="margin: 10px;">
        <table class="table table-bordered">
            <thead>
                <tr>
                    <td>Sr. No.</td>
                    <td>Invoice Name</td>
                    <td>Invoice No</td>
                    <td>Invoice Date</td>
                    <td>Booking No</td>
                    <td>Advt. Name</td>
                    <td>Invoice Amt</td>
                    <td>Receive Amt</td>
                    <td>Remaining Amt </td>
                    <!-- <td>Amount</td>
                    <td>Received Amount</td>
                    <td>Remaining Amount</td> -->
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of advtInvoiceList; let i=index">
                    <td>{{ i + 1 }}</td>
                    <!-- <td>{{ item['advt_name'] }}</td>
                    <td>{{ item['DS_ISSUENAME'] }}</td>
                    <td>{{ item['DS_ISSUEDATE'] }}</td>
                    <td>{{ item['booking_no'] }}</td>
                    <td>{{ item['booking_date'] }}</td>
                    <td>{{ item['invoice_no'] }}</td>
                    <td>{{ item['invoice_date'] }}</td>
                    <td>{{ item['total_amount'] }}</td> -->
                    <td>{{ item['DS_ISSUENAME'] }}</td>
                    <td>{{ item['advt_invoice_no'] }}</td>
                    <td>{{ item['advt_invoice_date'] }}</td>
                    <td>{{ item['booking_no'] }}</td>
                    <td>{{ item['advt_name'] }}</td>
                    <td>{{ item['amount'] }}</td>
                    <td>{{ item['rcvd_amt'] }}</td>
                    <td>{{ item['remaining_amount'] }}</td>
                    <td>{{ item['amount'] }}</td>
                    <td>
                        <mat-form-field class="example-full-width" appearance="outline" style="width: 30%;">
                            <input matInput (blur)="calcamount($event, item)" type="number" style="height: 35px !important;"/>
                        </mat-form-field>
                        Previous Value :{{ item['rcvd_amt'] }}
                    </td>
                    <td>{{ item['remaining_amount'] }}</td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="dialog">
        <button class="btn btn-primary" style="margin-top: 2%; width: 10%" (click)="save()" mat-button>
            Save
        </button>
    </div>
</div>