<div class="header bg-gradient-danger pb-1 pt-5 pt-md-8">
  <div class="container-fluid">
    <div class="header-body"></div>
  </div>
</div>

<div class="container-fluid" style="margin-top: -4.1em">
  <mat-tab-group dynamicHeight [(selectedIndex)]="demo1TabIndex" (selectedTabChange)="onTabClick($event)">
    <mat-tab label="Add New Advertisement">
      <div class="add-new-subscriber mat-elevation-z4">
        <form class="form" [formGroup]="ionicForm">
          <div class="form-row">
            <mat-form-field class="example-full-width" style="width: 60%; margin-left: 5%; margin-top: 2%">
              <mat-label>Advertiser Name :</mat-label>
              <input #myInput matInput formControlName="sadvtname" (keyup)="getAdvtNames($event)"
                [matAutocomplete]="auto" />
              <mat-autocomplete #auto="matAutocomplete">
                <mat-option (onSelectionChange)="selected_name1($event, option)"
                  *ngFor="let option of filteredOptions | async" [value]="option['name']">
                  {{ option["name"] }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <span style="margin-left: 2%; margin-top: 3%; font-size: large; font-weight: bold;">OR</span>
          </div>
          <div class="form-row">
            <mat-form-field class="example-full-width" style="width: 30%; margin-left: 5%; margin-top: 2%">
              <mat-label>Mobile No :</mat-label>
              <input matInput formControlName="smobno" [matAutocomplete]="automob" />
              <mat-autocomplete autoActiveFirstOption #automob="matAutocomplete">
                <mat-option (onSelectionChange)="selected_mob_no($event, option)"
                  *ngFor="let option of filteredOptions2 | async" [value]="option['mobile']">
                  {{ option["mobile"] }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <span style="margin-left: 2%; margin-top: 3%; font-size: large; font-weight: bold;">OR</span>
            <mat-form-field class="example-full-width" style="width: 30%; margin-left: 3%; margin-top: 2%">
              <mat-label>Owner Name :</mat-label>
              <input matInput formControlName="sownername" [matAutocomplete]="autoownername" />
              <mat-autocomplete autoActiveFirstOption #autoownername="matAutocomplete">
                <mat-option (onSelectionChange)="selected_owner_name($event, option)"
                  *ngFor="let option of filteredOptions3 | async" [value]="option['owner_name']">
                  {{ option["owner_name"] }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>

            <div style="margin-top: 3%;margin-left: 2%;">
              <button style="margin-left: 7%" type="button" mat-raised-button color="primary" (click)="selected_name()">
                Find
              </button>
            </div>
          </div>

          <div class="tableFixHead1">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th>Sr. No.</th>
                  <th>Booking Date</th>
                  <th>Advertiser Name</th>
                  <th>Customer Name</th>
                  <th>Issue Name</th>
                  <th>Issue Date</th>
                  <th>Advt. Size</th>
                  <th>Advt. Amount</th>
                  <th>Page No</th>
                  <th>Representative Name</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of advtSubDetails; let i = index">
                  <td>{{ i + 1 }}</td>
                  <td>{{ item["booking_date"] }}</td>
                  <td>{{ item["advt_name"] }}</td>
                  <td>{{ item["cust_name"] }}</td>
                  <td>{{ item["issue_name1"] }}</td>
                  <td>{{ item["issue_date1"] }}</td>
                  <td>{{ item["advt_size"] }}</td>
                  <td>{{ item["amount"] }}</td>
                  <td>{{ item["page_no"] }}</td>
                  <td>{{ item["rep_name"] }}</td>
                  <td>
                    <button class="btn-sm btn-outline-info" type="button" (click)="selectAdvtIssue(item)">
                      Select
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="form-row" style="
              justify-content: center;
              background-color: #8bbdbd;
              margin-bottom: 2%;
              margin-top: 2%;
            ">
            <h2 style="text-align: center; margin-top: 10px; color: black">
              Personal Details
            </h2>
          </div>

          <div class="form-row">
            <mat-form-field class="example-full-width" style="width: 40%; margin-left: 5%">
              <mat-label>Advertiser Name :</mat-label>
              <input matInput formControlName="name" />
            </mat-form-field>

            <mat-form-field class="example-full-width" style="width: 40%; margin-left: 5%">
              <mat-label>Company Owner Name :</mat-label>
              <input matInput formControlName="ownername" />
            </mat-form-field>
          </div>

          <div class="form-row">
            <mat-form-field class="example-full-width" style="width: 85%; margin-left: 5%">
              <mat-label>Address</mat-label>
              <textarea matInput placeholder="Address" formControlName="address" required></textarea>
            </mat-form-field>
          </div>

          <div class="form-row">
            <mat-form-field style="margin-left: 5%; width: 25%" class="example-full-width">
              <mat-label>Pincode</mat-label>
              <input (change)="getPincode($event)" matInput #postalCode maxlength="6" placeholder=""
                formControlName="pincode" required />
              <mat-hint align="end">{{ postalCode.value.length }} / 6</mat-hint>
            </mat-form-field>

            <mat-form-field style="width: 25%; margin-left: 5%">
              <mat-label>Country</mat-label>
              <mat-select (selectionChange)="getCountry($event)" formControlName="country" required>
                <mat-option *ngFor="let c of countries" value="{{ c['country_name'] }}">{{ c["country_name"] }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field style="width: 25%; margin-left: 5%">
              <mat-label>State</mat-label>
              <mat-select (selectionChange)="getState($event)" formControlName="state">
                <mat-option *ngFor="let c of states" value="{{ c['state_name'] }}">{{ c["state_name"] }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="form-row">
            <mat-form-field style="width: 25%; margin-left: 5%">
              <mat-label>District</mat-label>
              <mat-select (selectionChange)="getDistricts($event)" formControlName="district">
                <mat-option *ngFor="let c of cities" value="{{ c['district_name'] }}">
                  {{ c["district_name"] }}
                </mat-option>
              </mat-select>
              <span>{{ selectedCity }}</span>
            </mat-form-field>

            <mat-form-field class="example-full-width" style="width: 25%; margin-left: 5%">
              <mat-label>Taluka</mat-label>
              <mat-select formControlName="taluka">
                <mat-option *ngFor="let c of talukas" value="{{ c['taluka_name'] }}">{{ c["taluka_name"] }}</mat-option>
              </mat-select>
              <span>{{ selectedTaluka }}</span>
            </mat-form-field>
          </div>

          <div class="form-row">
            <mat-form-field class="example-full-width" style="margin-left: 5%; width: 25%"
              [style.color]="validmobile ? null : 'red'">
              <mat-label>Mobile</mat-label>
              <span matPrefix>+91 &nbsp;</span>
              <input type="tel" #mobiles (change)="validateMobile($event)" maxlength="10" formControlName="mobileNumber"
                matInput />
              <mat-hint align="end">{{ mobiles.value.length }} / 10</mat-hint>
              <mat-error *ngIf="validmobile">
                Please enter a valid mobile no.
              </mat-error>
              <mat-error *ngIf="validmobile">
                Mobile no. is <strong>required</strong>
              </mat-error>
            </mat-form-field>



            <mat-form-field class="example-full-width" style="margin-left: 5%; width: 25%">
              <mat-label>Landline No.</mat-label>
              <input type="text" #land maxlength="12" formControlName="landline_no" matInput />
              <mat-hint align="end">{{ land.value.length }} / 12</mat-hint>
            </mat-form-field>

            <mat-form-field class="example-full-width" style="width: 25%; margin-left: 5%"
              [style.color]="validemail ? null : 'red'">
              <mat-label>Email</mat-label>
              <input (change)="validateEmail($event)" type="email" matInput formControlName="email"
                placeholder="Ex. pat@example.com" />
              <mat-error *ngIf="validemail">
                Please enter a valid email address
              </mat-error>
              <mat-error *ngIf="validemail">
                Email is <strong>required</strong>
              </mat-error>
            </mat-form-field>
          </div>

          <div class="form-row">
            <mat-form-field style="margin-left: 5%; width: 25%">
              <mat-label>Date of Birth :</mat-label>
              <input formControlName="dob" matInput [matDatepicker]="datepicker" (dateChange)="validatedob($event)" />
              <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
              <mat-datepicker #datepicker> </mat-datepicker>
              <mat-error *ngIf="validdob"> Please enter a valid DOB </mat-error>
              <mat-error *ngIf="validdob">
                DOB is <strong>required</strong>
              </mat-error>
            </mat-form-field>

            <mat-form-field style="margin-left: 5%; width: 25%">
              <mat-label>Company Registration Date :</mat-label>
              <input formControlName="companyRegistration" matInput [matDatepicker]="datepicker1" />
              <mat-datepicker-toggle matSuffix [for]="datepicker1"></mat-datepicker-toggle>
              <mat-datepicker #datepicker1> </mat-datepicker>
            </mat-form-field>

            <mat-form-field style="margin-left: 5%; width: 25%">
              <mat-label>Advt. Type : </mat-label>
              <mat-select formControlName="advtType" required>
                <mat-option *ngFor="let item of advtTypes" value="{{ item['advt_types_select'] }}">
                  {{ item["advt_type"] }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="form-row">
            <mat-form-field class="example-full-width" style="width: 25%; margin-left: 5%">
              <mat-label>Advertiser Name (In English) :</mat-label>
              <input matInput formControlName="e_advt_name" />
            </mat-form-field>

            <mat-form-field class="example-full-width" style="width: 25%; margin-left: 5%">
              <mat-label>District (In English) :</mat-label>
              <input matInput formControlName="e_district" />
            </mat-form-field>

            <mat-form-field style="margin-left: 5%; width: 25%" class="example-full-width">
              <mat-label>Gst No.</mat-label>
              <input matInput type="text" placeholder="Gst No." formControlName="gstno" />
            </mat-form-field>
          </div>
          <div class="form-row">
            <mat-form-field style="margin-left: 5%; width: 40%">
              <mat-label>Representative Name :</mat-label>
              <input formControlName="rep_name" matInput [matAutocomplete]="autorepname" />
              <mat-autocomplete autoActiveFirstOption #autorepname="matAutocomplete">
                <mat-option (onSelectionChange)="SelectRepresentative(option)"
                  *ngFor="let option of filteredOptions1 | async" [value]="option['name']">
                  {{ option["name"] }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <mat-form-field style="margin-left: 5%; width: 40%">
              <mat-label>Representative Mobile No :</mat-label>
              <input type="number" formControlName="rep_mobile_no" matInput />
            </mat-form-field>
          </div>
          <div class="form-row">
            <mat-form-field style="margin-left: 5%; width: 40%">
              <mat-label>Instruction :</mat-label>
              <input type="text" formControlName="instruction" matInput />
            </mat-form-field>
          </div>

          <div>
            <button *ngIf="!advtshow" type="button" [disabled]="ionicForm.invalid" mat-raised-button
              class="btn btn-primary" style="width: 45%; margin-left: 15%; margin-right: 05%" (click)="saveAdvt()">
              Save
            </button>
            <button *ngIf="advtshow" type="button" mat-raised-button class="btn btn-info"
              style="width: 45%; margin-left: 15%; margin-right: 05%" (click)="updateAdvt()">
              Update
            </button>
            <button type="button" mat-raised-button color="warn" style="width: 20%" (click)="advtCancel()">
              Delete
            </button>
          </div>

          <div class="form-row" style="margin-top: 5%;">
            <button type="button" (click)="enableDisableRule('Special Issue')" [ngStyle]="{
                'background-color': toggle ? '#f4dd6a' : '#87e0ec'
              }" style="
                width: 20%;
                margin-left: 5%;
                height: 06vh;
                margin-top: 10px;
              ">
              Special Issue
            </button>
            <button type="button" (click)="enableDisableRule('Occasional')" [ngStyle]="{
                'background-color': toggle1 ? '#f4dd6a' : '#87e0ec'
              }" style="width: 20%; color: #0e1f7e; height: 06vh; margin-top: 10px">
              Occasional
            </button>
            <button type="button" (click)="enableDisableRule('Contract')" [ngStyle]="{
                'background-color': toggle2 ? '#f4dd6a' : '#87e0ec'
              }" style="width: 20%; color: #e82b0a; height: 06vh; margin-top: 10px">
              Contract
            </button>
          </div>
        </form>

        <form [formGroup]="contractForm">
          <div *ngIf="toggle2">
            <div class="form-row" style="
                                  justify-content: center;
                                  background-color: #8bbdbd;
                                  margin-bottom: 2%;
                                  margin-top: 2%;
                                ">
              <h2 style="text-align: center; margin-top: 10px; color: black">
                Contract Details
              </h2>
            </div>
            <div class="form-row">
              <mat-form-field style="margin-left: 5%; width: 40%">
                <mat-label>Contract No :</mat-label>
                <input type="text" formControlName="contractno" matInput required readonly />
              </mat-form-field>
              <mat-form-field style="margin-left: 5%; width: 40%">
                <mat-label>Date</mat-label>
                <input matInput type="date" formControlName="contractdate" required />
              </mat-form-field>
            </div>

            <div class="form-row">
              <!-- <mat-form-field style="margin-left: 5%; width: 40%">
                <mat-label>Representative Name :</mat-label>
                <input type="text" formControlName="repname" matInput />
              </mat-form-field> -->
              <mat-form-field style="margin-left: 5%; width: 40%">
                <mat-label>Representative Name :</mat-label>
                <input formControlName="repname" matInput [matAutocomplete]="autorepname2" />
                <mat-autocomplete autoActiveFirstOption #autorepname2="matAutocomplete">
                  <mat-option (onSelectionChange)="selected_rep_name1($event, option)"
                    *ngFor="let option of filteredOptions6 | async" [value]="option['name']">
                    {{ option["name"] }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
              <mat-form-field style="margin-left: 5%; width: 40%">
                <mat-label>Office Representative Name :</mat-label>
                <input type="text" formControlName="offrepname" matInput />
              </mat-form-field>
            </div>

            <div class="form-row">
              <mat-form-field style="margin-left: 5%; width: 40%">
                <mat-label>Advt Name :</mat-label>
                <input type="text" formControlName="advtname" matInput />
              </mat-form-field>
              <mat-form-field style="margin-left: 5%; width: 40%">
                <mat-label>Privious Balance Rs.(1/4/22)Net Rs :</mat-label>
                <input type="number" formControlName="priviousbal" matInput />
              </mat-form-field>
            </div>

            <div class="form-row">
              <mat-form-field style="margin-left: 5%; width: 40%">
                <mat-label>Contract Amount Rs :</mat-label>
                <input type="number" formControlName="contractamt" matInput />
              </mat-form-field>
              <mat-form-field style="margin-left: 5%; width: 40%">
                <mat-label>Discount Rs :</mat-label>
                <input type="number" formControlName="discountamt" matInput />
              </mat-form-field>
            </div>

            <div style="margin-left: 5%">
              <mat-radio-group aria-label="Select an option" formControlName="gstie">
                <mat-radio-button value="gstInclude" style="margin-right: 5%" (change)="calculateNetGstAmt($event)">GST
                  Including
                </mat-radio-button>
                <mat-radio-button value="gstExclude" (change)="calculateNetGstAmt($event)">GST Excluding
                </mat-radio-button>
              </mat-radio-group>
            </div>

            <div class="form-row">
              <mat-form-field style="margin-left: 5%; width: 40%">
                <mat-label>Net Amount :</mat-label>
                <input type="number" formControlName="netamt" matInput />
              </mat-form-field>
              <mat-form-field style="margin-left: 5%; width: 18%">
                <mat-label>GST Rs. :</mat-label>
                <input type="number" formControlName="netgstprice" matInput />
              </mat-form-field>
              <mat-form-field style="margin-left: 4%; width: 18%">
                <mat-label>Total Rs. :</mat-label>
                <input type="number" formControlName="nettotal" matInput />
              </mat-form-field>
            </div>

            <div class="form-row">
              <mat-form-field style="margin-left: 5%; width: 40%">
                <mat-label>Contract Period form</mat-label>
                <input matInput type="date" formControlName="contractfromdate" required />
              </mat-form-field>
              <mat-form-field style="margin-left: 5%; width: 40%">
                <mat-label>To</mat-label>
                <input matInput type="date" formControlName="contracttodate" required />
              </mat-form-field>
            </div>

            <div class="form-row">
              <mat-form-field style="margin-left: 5%; width: 40%">
                <mat-label>Booking Advt Amount :</mat-label>
                <input type="number" formControlName="bookingadvtamt" (keyup)="calculatebookingamt()" matInput />
              </mat-form-field>
              <mat-form-field style="margin-left: 5%; width: 40%">
                <mat-label>Balance Amount(31.3.22) Net :</mat-label>
                <input type="number" formControlName="balamt" matInput readonly />
              </mat-form-field>
            </div>

            <div class="form-row">
              <mat-form-field style="margin-left: 5%; width: 40%">
                <mat-label>Instruction :</mat-label>
                <textarea type="number" formControlName="instruction" matInput></textarea>
              </mat-form-field>
            </div>

            <div style="margin-top: 3%; margin-bottom: 3%">
              <button *ngIf="!contractshow" type="button" [disabled]="contractForm.invalid" mat-raised-button
                class="btn btn-primary" style="width: 45%; margin-left: 15%; margin-right: 05%"
                (click)="saveContract()">
                Save
              </button>
              <button *ngIf="contractshow" type="button" mat-raised-button class="btn btn-info"
                style="width: 45%; margin-left: 15%; margin-right: 05%" (click)="updateContract()">
                Update
              </button>
              <button type="button" mat-raised-button color="warn" style="width: 20%" (click)="cancelContract()">
                Delete
              </button>
            </div>
          </div>
        </form>

        <div class="form-row" style="
                      justify-content: center;
                      background-color: #8bbdbd;
                      margin-bottom: 2%;
                      margin-top: 2%;
                    ">
          <h2 style="text-align: center; margin-top: 10px; color: black">
            Advt. Booking Details
          </h2>
        </div>

        <form [formGroup]="issueForm" (ngSubmit)="saveAdvtSubDetails()">
          <div class="form-row">
            <mat-form-field style="margin-left: 5%; width: 40%">
              <mat-label>Booking Date</mat-label>
              <input matInput type="date" formControlName="bookingdate" (change)="bookingdateChange($event)" />
            </mat-form-field>

            <mat-form-field style="margin-left: 5%; width: 40%">
              <mat-label>Booking No :</mat-label>
              <input type="text" formControlName="bookingno" matInput readonly />
            </mat-form-field>
          </div>

          <div class="form-row">
            <mat-form-field style="margin-left: 5%; width: 40%">
              <mat-label>Advertiser Name :</mat-label>
              <input type="text" formControlName="advtname" matInput />
            </mat-form-field>
            <mat-form-field style="margin-left: 5%; width: 40%">
              <mat-label>Customer Name :</mat-label>
              <input type="text" formControlName="custname" matInput />
            </mat-form-field>
          </div>

          <div class="form-row">
            <div style="margin-left: 5%; width: 25%">
              <mat-form-field style="width: 100%">
                <mat-label>Representative Name :</mat-label>
                <input formControlName="repname" matInput [matAutocomplete]="autorepname1" required />
                <mat-autocomplete autoActiveFirstOption #autorepname1="matAutocomplete">
                  <mat-option (onSelectionChange)="selected_rep_name($event, option)"
                    *ngFor="let option of filteredOptions4 | async" [value]="option['name']">
                    {{ option["name"] }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
              <div style="color: red;font-size: 12px;
    margin: -15px 10px;" *ngIf="!isrepValid">
                Please fill Representative Name
              </div>
            </div>

            <mat-form-field style="margin-left: 5%; width: 25%">
              <mat-label>Representative Mobile No :</mat-label>
              <input type="number" formControlName="rep_mobile_no" matInput />
            </mat-form-field>
            <mat-form-field style="margin-left: 5%; width: 25%">
              <mat-label>Office Representative Name :</mat-label>
              <input type="text" formControlName="offrepname" matInput />
            </mat-form-field>
          </div>

          <div class="form-row" style="margin-top: 3%">
            <mat-form-field style="margin-left: 5%; width: 24%">
              <mat-label>Issue/grantha : </mat-label>

              <mat-select (selectionChange)="getIssues($event)" formControlName="issue" required>
                <mat-option *ngFor="let item of advtCategorylist" value="{{ item.category }}">
                  {{ item.category }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field style="margin-left: 3%; width: 24%">
              <mat-label>Issue Dates : </mat-label>
              <mat-select #mySel (selectionChange)="getAdvtDates($event);  addcate($event)" formControlName="issuedates"
                required>
                <!-- <mat-option value="all" (click)="toggleAllSelection()">All</mat-option> -->
                <mat-option *ngFor="let item of getMagIssueDatesandNames" value="{{ [item['issueDate'] | date : 'dd/MM/yyyy',item['issueName'],
                  item['publishedDate'] | date : 'dd/MM/yyyy',- item.id] }}">{{ item["issueDate"] | date : "dd/MM/yyyy"
                  }} {{ item["issueName"] }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field style="margin-left: 3%; width: 24%">
              <mat-label>Advt. Sizes : </mat-label>
              <mat-select (selectionChange)="getAdvtSize($event)" formControlName="advtsize" required>
                <mat-option *ngFor="let item of advtsizebyCategory" value="{{ item['Advt_size'] }}">
                  {{ item["Advt_size"] }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <button *ngIf="ds_advtId == 0" type="button" style="height: 1%; margin-left: 3%; margin-top: 1%"
              mat-raised-button color="warn" (click)="addAdvt()">
              Add
            </button>
            <button *ngIf="ds_advtId !== 0" type="button" style="height: 1%; margin-left: 3%; margin-top: 1%"
              mat-raised-button color="primary" (click)="modifyAdvt()">
              Update
            </button>
          </div>

          <div class="form-row">
            <table class="table table-striped text-center">
              <thead>
                <tr>
                  <th>Sr.No.</th>
                  <th>Issue Granth</th>
                  <th>Issue Name</th>
                  <th>Dates</th>
                  <th>Advt. Size</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let sub of AdvtDetails; let i = index">
                  <td>{{ sub.s_no }}</td>
                  <td>{{ sub["issue"] }}</td>
                  <td>{{ sub["issue_name1"] }}</td>
                  <td>{{ sub["issue_date1"] }}</td>
                  <td>{{ sub["advtsize"] }}</td>
                  <td>
                    <button *ngIf="ds_advtId == 0" type="button" class="btn btn-danger btn-sm" (click)="deleteAdvt(i)">
                      Remove
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="form-row">
            <mat-form-field style="margin-left: 5%; margin-top: 10px; width: 40%" class="example-full-width">
              <mat-label>RO Number</mat-label>
              <input type="text" formControlName="ro_number" matInput />
            </mat-form-field>

            <mat-form-field style="margin-top: 10px; margin-left: 5%; width: 40%" class="example-full-width">
              <mat-label>RO Date : </mat-label>
              <input matInput type="date" formControlName="ro_dates" />
            </mat-form-field>
          </div>

          <div class="form-row">
            <mat-form-field style="margin-left: 5%; width: 40%" class="example-full-width">
              <mat-label>Instruction</mat-label>
              <textarea matInput type="text" placeholder="Instruction" formControlName="instruction">
              </textarea>
            </mat-form-field>

            <mat-form-field style="margin-left: 5%; width: 40%" class="example-full-width">
              <mat-label>Other Details</mat-label>
              <textarea matInput type="text" placeholder="Tapshil" formControlName="otherdetls">
              </textarea>
            </mat-form-field>
          </div>

          <div class="form-row">
            <mat-form-field style="margin-left: 5%; width: 40%" class="example-full-width">
              <mat-label>Page No. :</mat-label>
              <input matInput type="number" formControlName="pageno" />
            </mat-form-field>
          </div>

          <div class="form-group">
            <mat-form-field class="example-full-width" style="width: 40%; margin-left: 5%">
              <mat-label>Advt. Amount : </mat-label>
              <span matPrefix>₹ &nbsp;</span>
              <input matInput formControlName="advtamout" type="number" required />
            </mat-form-field>

            <mat-form-field class="example-full-width" style="width: 40%; margin-left: 5%">
              <mat-label>Advt. Discount : </mat-label>

              <input matInput type="number" formControlName="advtdiscount" />
              <span matPrefix>% &nbsp;</span>
            </mat-form-field>
          </div>

          <div class="form-row">
            <span style="
                margin-top: 0%;
                margin-left: 5%;
                margin-bottom: 2%;
                font-weight: bold;
              ">
              Total Discount :
              {{
              this.advtamount * (this.discount / 100)
              | currency : "INR" : "symbol"
              }}
            </span>
            <span style="margin-left: 32%; font-weight: bold">
              Total Amount :{{
              this.advtamount - this.advtamount * (this.discount / 100)
              | currency : "INR" : "symbol"
              }}
            </span>
          </div>

          <div class="form-group" style="margin-left: 5%">
            <mat-radio-group aria-label="Select an option" formControlName="gstie">
              <mat-radio-button value="gstInclude" style="margin-right: 5%" (change)="changeType($event)">GST Including
              </mat-radio-button>
              <mat-radio-button value="gstExclude" (change)="changeType($event)">GST Excluding</mat-radio-button>
            </mat-radio-group>
          </div>

          <div class="form-group">
            <mat-form-field class="example-full-width" *ngIf="!showigst" style="width: 25%; margin-left: 5%">
              <mat-label>GST 5% (SGST-2.5% + CGST-2.5%): </mat-label>
              <span matPrefix>₹ &nbsp;</span>
              <input readonly matInput formControlName="gstamount" type="number" />
            </mat-form-field>

            <mat-form-field class="example-full-width" *ngIf="showigst" style="width: 25%; margin-left: 5%">
              <mat-label>IGST 5% : </mat-label>
              <span matPrefix>₹ &nbsp;</span>
              <input readonly matInput type="number" formControlName="igstamount" />
            </mat-form-field>
            <span style="margin-left: 2%; font-weight: bold">+</span>
            <mat-form-field class="example-full-width" style="width: 25%; margin-left: 2%; margin-top: 20px">
              <mat-label>Amount: </mat-label>
              <span matPrefix>₹ &nbsp;</span>
              <input readonly matInput formControlName="totalamt" type="number" />
            </mat-form-field>
            <span style="margin-left: 2%; font-weight: bold">=</span>
            <mat-form-field class="example-full-width" style="width: 25%; margin-left: 2%; margin-top: 20px">
              <mat-label>Total Amount: </mat-label>
              <span matPrefix>₹ &nbsp;</span>
              <input readonly matInput formControlName="amount" type="number" />
            </mat-form-field>
          </div>

          <div style="margin-top: 3%">
            <button *ngIf="!issueshow" type="submit" [disabled]="issueForm.invalid" mat-raised-button
              class="btn btn-primary" style="width: 45%; margin-left: 15%; margin-right: 05%">
              Save
            </button>
            <button *ngIf="issueshow" type="button" mat-raised-button class="btn btn-info"
              style="width: 45%; margin-left: 15%; margin-right: 05%" (click)="updateAdvtSubDetails()">
              Update
            </button>
            <button type="button" mat-raised-button color="warn" style="width: 20%" (click)="cancelAdvtSubDetails()">
              Delete
            </button>
          </div>
        </form>
      </div>
    </mat-tab>

    <!-- <mat-tab label="Search Advertisement">
      <mat-form-field class="example-form-field" style="margin-top: 5%">
        <mat-label>Advt. Name</mat-label>
        <input matInput type="text" [(ngModel)]="advt_name" (ngModelChange)="get_advt_name_filter($event)" />
      </mat-form-field>

      <span style="margin-left: 1%; font-size: large; font-weight: bold">
        OR
      </span>

      <mat-form-field class="example-form-field" style="margin-left: 1%">
        <mat-label>Cust. Mobile No.</mat-label>
        <input matInput type="tel" [(ngModel)]="cust_mobile_filter" #cust_mobile1 maxlength="10"
          (ngModelChange)="getcust_mobile_filter($event)" />
        <button *ngIf="cust_mobile_filter" matSuffix mat-icon-button aria-label="Clear"
          (click)="reset_cust_mobile_filter($event)">
          <mat-icon>close</mat-icon>
          <mat-hint align="end">{{ cust_mobile1.value.length }} / 10</mat-hint>
        </button>
      </mat-form-field>

      <span style="margin-left: 1%; font-size: large; font-weight: bold">
        OR
      </span>

      <mat-form-field style="margin-left: 1%">
        <mat-label>Issue Wise Advt.</mat-label>
        <mat-select name="issue" (selectionChange)="getIssuesSearchAdvt($event)">
          <mat-option *ngFor="let issue of advt_mag_name_details" [value]="issue['issue_name']">
            {{ issue["issue_name"] }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <span style="margin-left: 1%; font-size: large; font-weight: bold">
        OR
      </span>

      <mat-form-field class="example-form-field" style="margin-left: 1%">
        <mat-label>Rep. Name</mat-label>
        <input matInput type="text" [(ngModel)]="rep_name" (ngModelChange)="get_rep_name_filter($event)" />
      </mat-form-field>

      <button style="margin-left: 2%" (click)="getAdvtReport()" mat-raised-button color="primary">
        Find
      </button>

      <div class="add-new-subscriber mat-elevation-z4" style="overflow-x: scroll !important" id="excel-table">
        <div style="margin-top: 20px" class="tableFixHead">
          <table class="table table-striped text-center">
            <thead>
              <tr>
                <th>अ.क्र.</th>
                <th>नोंदणी दिनांक</th>
                <th>ग्राहकाचे नाव</th>
                <th>संपर्क क्रमांक</th>
                <th>प्रतिनिधींचे नाव</th>
                <th>Issue Name / Grantha</th>
                <th colspan="5">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let advt of this.getAdvtDetails; let i = index">
                <td>{{ i + 1 }}</td>
                <td>{{ advt["registration_date"] | date : "dd-MM-yyy" }}</td>
                <td>{{ advt["name"] }}</td>
                <td>{{ advt["mobile"] }}</td>
                <td>{{ advt["rep_name"] }}</td>
                <td>{{ advt["issue_name"] }}</td>
                <td>
                  <button type="button" class="btn btn-danger btn-sm" (click)="view_advt(advt)">
                    View
                  </button>
                </td>
                
                <td>
                  <button type="button" class="btn btn-success btn-sm" (click)="addIssueDates(advt)">
                    Manage Issue Date
                  </button>
                </td>
                <td>
                  <button type="button" class="btn btn-success btn-sm" (click)="addPublishDates(advt)">
                    Add Publish Date
                  </button>
                </td>
                <td>
                  <button type="button" class="btn btn-danger btn-sm" (click)="Cancel_advt(advt)">
                    Delete Advt
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </mat-tab> -->

    <mat-tab label="Advt. Registration Report">
      <form class="form" [formGroup]="RegistrationreportRange">
        <mat-form-field style="margin-top: 5%">
          <mat-label>Advt. Received Date</mat-label>

          <mat-date-range-input [rangePicker]="picker2">
            <input matStartDate formControlName="start" placeholder="Start date" />
            <input matEndDate formControlName="end" placeholder="End date"
              (dateChange)="getChangedDateRegistrationReport()" />
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
          <mat-date-range-picker #picker2></mat-date-range-picker>

          <mat-error *ngIf="
            RegistrationreportRange.controls.start.hasError(
              'matStartDateInvalid'
            )
          ">Invalid start date
          </mat-error>
          <mat-error *ngIf="
            RegistrationreportRange.controls.end.hasError('matEndDateInvalid')
          ">Invalid end date
          </mat-error>
        </mat-form-field>

        <span style="margin-left: 3%; font-size: large; font-weight: bold">
          OR
        </span>

        <mat-form-field style="margin-left: 3%">
          <mat-label>Representative : </mat-label>
          <mat-select #mySelr2 (selectionChange)="getRepName1($event)" formControlName="repname" multiple>
            <mat-option value="ALL" (click)="toggleAllSelectionr2()">All</mat-option>
            <mat-option *ngFor="let item of repNamesOfRegReport" value="{{  item['rep_name'] }}">{{ item["rep_name"] }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <span style="margin-left: 3%; font-size: large; font-weight: bold">
          OR
        </span>

        <mat-form-field style="margin-left: 3%">
          <mat-label>Office Representative : </mat-label>
          <mat-select #mySelr1 (selectionChange)="getOfficeRep($event)" formControlName="offrepname" multiple>
            <mat-option value="ALL" (click)="toggleAllSelectionr1()">All</mat-option>
            <mat-option *ngFor="let item of pp_off_rep_names" value="{{ item['off_rep_name'] }}">{{
              item["off_rep_name"] }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <span style="margin-left: 3%; font-size: large; font-weight: bold">
          OR
        </span>

        <mat-form-field style="margin-left: 3%">
          <mat-label>Issue/grantha : </mat-label>
          <mat-select (selectionChange)="getIssues($event)" formControlName="issuename" required>
            <mat-option *ngFor="let item of advtCategorylist" value="{{ item.category }}">
              {{ item.category }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field style="margin-left: 0%">
          <mat-label>Issue Dates : </mat-label>
          <mat-select #mySel2 (selectionChange)="getAdvtDates($event)" formControlName="issuedate" multiple required>
            <mat-option value="ALL" (click)="toggleAllSelection2()">All</mat-option>
            <!-- <mat-option *ngFor="let item of getMagIssueDatesandNames" value="{{
                                [
                                  item['issueDate'] | date : 'dd/MM/yyyy',
                                  item['issueName']'
                                ]
                              }}">{{ item["issueDate"] | date :
            "dd/MM/yyyy" }}
            {{ item["issueName"] }} 
          </mat-option> -->
            <mat-option *ngFor="let item of getMagIssueDatesandNames" value="{{ item.id }}">{{ item["issueDate"] | date
              :
              "dd/MM/yyyy" }}
              {{ item["issueName"] }}

            </mat-option>
          </mat-select>
        </mat-form-field>

        <span style="margin-left: 3%; font-size: large; font-weight: bold">
          OR
        </span>

        <mat-form-field style="margin-left: 3%">
          <mat-label>Select Type Of Report</mat-label>
          <mat-select (selectionChange)="checkReportType($event)">
            <mat-option value="InDetail"> In Detail </mat-option>
            <mat-option value="Briefly"> Briefly </mat-option>
            <mat-option value="Represantative Indetails">Indetails Represantative</mat-option>
            <mat-option value="Represantative Briefly">Represantative Brief </mat-option>
            <mat-option value="Represantative very Briefly">Represantative Very Brief</mat-option>
            <mat-option value="Off_Represantative Indetails">Off-Represantative Indetails </mat-option>
            <mat-option value="Off_Represantative Briefly">Off-Represantative Brief </mat-option>
            <mat-option value="Off_Represantative very Briefly">Off-Represantative Very Brief </mat-option>
            <mat-option value="Datewise Indetails">Indetails datewise</mat-option>
            <mat-option value="Datewise Briefly">Datewise Brief</mat-option>
            <mat-option value="datewise very Briefly">datewise very Briefly</mat-option>
          </mat-select>
        </mat-form-field>

      </form>

      <button style="margin-left: 3%" (click)="getAdvtRegistrationReport()" mat-raised-button color="primary">
        Find
      </button>

      <mat-card *ngIf="showindetail">
        <div class="tableFixHead" id="excel-table">
          <button type="button" mat-raised-button matTooltip="Download Execelsheet" class="btn btn-danger"
            (click)="exportexcel('Registration Report')" style="float: right">
            Export
          </button>
          <table class="table table-bordered">
            <thead>
              <tr>
                <th colspan="3">Vivek Saptahik</th>
                <th colspan="5">Advt. Registreation Report</th>
                <th colspan="2">In Detail</th>
                <th colspan="2"></th>
                <th colspan="3"></th>
                <th colspan="3">Date :</th>
              </tr>
              <tr>
                <th colspan="3">Date. From :</th>
                <th colspan="2">To :</th>
                <th colspan="3">Issue/Grantha Categary :</th>
                <th colspan="2">Issue Date :</th>
                <th colspan="5">Issue Name :</th>
                <th colspan="3">Page No</th>
              </tr>
              <tr>
                <th>Issue Sr. No</th>
                <th>Booking Date</th>
                <th>Booking No</th>
                <th>Advertiser Name</th>
                <th>Customer Name</th>
                <th>Representative Name</th>
                <th>Mobile No</th>
                <th>Office Represantative Name</th>
                <th>RO Number</th>
                <th>Instruction</th>
                <th>Advt.Size</th>
                <th>Advt Amount Rs</th>
                <th>Net Amount</th>
                <th>GST 5%</th>
                <th>Total Amount</th>
                <th>Received Amount</th>
                <th>Receipt No. and Date</th>
                <th>Pending Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of filteredinDetailsRegReport; let i = index">
                <td>{{ i + 1 }}</td>
                <td>{{ item["booking_date"] | date : "dd-MM-yyy" }}</td>
                <td>{{ item["booking_no"] }}</td>
                <td>{{ item["advt_name"] }}</td>
                <td>{{ item["cust_name"] }}</td>
                <td>{{ item["rep_name"] }}</td>
                <td>{{ item["rep_mob_no"] }}</td>
                <td>{{ item["off_rep_name"] }}</td>
                <td>{{ item["ro_no"] }}</td>
                <td>{{ item["instruction"] }}</td>
                <td>{{ item["advtsize"] }}</td>
                <td>{{ item["advt_amt"] }}</td>
                <td>{{ item["total_amount"] }}</td>
                <td>{{ toInt(item["sgst"]) + toInt(item["cgst"]) }}</td>
                <td>{{ item["amount"] }}</td>
                <td>{{ item["part_pymt_amt"] }}</td>
                <td>
                  {{ item["rep_rcpt_no"] }}<br />
                  {{ item["rcpt_date"] | date : "dd-MM-yyy" }}
                </td>
                <td>{{ item["part_pymt_remaning_amt"] }}</td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td colspan="2">Total</td>
                <td colspan="2">
                  Total No of Advt :
                  {{ this.inDetailsRegReportTotal.total_advt }}
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>{{ this.inDetailsRegReportTotal.advt_total }}</td>
                <td>{{ this.inDetailsRegReportTotal.net_amt }}</td>
                <td>{{ this.inDetailsRegReportTotal.gst }}</td>
                <td>{{ this.inDetailsRegReportTotal.total_amt }}</td>
                <td>{{ this.inDetailsRegReportTotal.rcvd_amt }}</td>
                <td></td>
                <td>{{ this.inDetailsRegReportTotal.pndg_amt }}</td>
              </tr>
            </tfoot>
          </table>
        </div>
      </mat-card>

      <mat-card *ngIf="showindetaildatewise">
        <div class="tableFixHead" id="excel-table">
          <button type="button" mat-raised-button matTooltip="Download Execelsheet" class="btn btn-danger"
            (click)="exportexcel('Registration Report')" style="float: right">
            Export
          </button>
          <div *ngFor="let data of inDetailsRegReportsubTotalData;
                      let s = index; let end = last
                      ">
            <table class="table table-bordered">
              <thead *ngIf="s == 0">
                <tr>
                  <th colspan="3">Vivek Saptahik</th>
                  <th colspan="5">Advt. Registreation Report</th>
                  <th colspan="2">Datewise</th>
                  <th colspan="2">In Detail</th>
                  <th colspan="3"></th>
                  <th colspan="3">Date :</th>
                </tr>
                <tr>
                  <th colspan="3">Date. From :</th>
                  <th colspan="2">To :</th>
                  <th colspan="3">Issue/Grantha Categary :</th>
                  <th colspan="2">Issue Date :</th>
                  <th colspan="5">Issue Name :</th>
                  <th colspan="3">Page No</th>
                </tr>
                <tr>
                  <th>Issue Sr. No</th>
                  <th>Booking Date</th>
                  <th>Booking No</th>
                  <th>Advertiser Name</th>
                  <th>Customer Name</th>
                  <th>Representative Name</th>
                  <th>Mobile No</th>
                  <th>Office Represantative Name</th>
                  <th>RO Number</th>
                  <th>Instruction</th>
                  <th>Advt.Size</th>
                  <th>Advt Amount Rs</th>
                  <th>Net Amount</th>
                  <th>GST 5%</th>
                  <th>Total Amount</th>
                  <th>Received Amount</th>
                  <th>Receipt No. and Date</th>
                  <th>Pending Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of data; let i = index; let last = last">
                  <td *ngIf="i == 0" colspan="3">Date</td>
                  <td *ngIf="i == 0">{{ item["booking_date"] | date : "dd-MM-yyy" }}</td>
                  <td *ngIf="i == 0"></td>
                  <td *ngIf="i == 0"></td>
                  <td *ngIf="i == 0"></td>
                  <td *ngIf="i == 0"></td>
                  <td *ngIf="i == 0"></td>
                  <td *ngIf="i == 0"></td>
                  <td *ngIf="i == 0"></td>
                  <td *ngIf="i == 0"></td>
                  <td *ngIf="i == 0"></td>
                  <td *ngIf="i == 0"></td>
                  <td *ngIf="i == 0"></td>
                  <td *ngIf="i == 0"></td>
                  <td *ngIf="i == 0"></td>
                  <td *ngIf="i == 0"></td>
                </tr>
                <tr *ngFor="let item of data; let i = index; let last = last">
                  <td *ngIf="!last">{{ i + 1 }}</td>
                  <td *ngIf="!last">{{ item["booking_date"] | date : "dd-MM-yyy" }}</td>
                  <td *ngIf="!last">{{ item["booking_no"] }}</td>
                  <td *ngIf="!last">{{ item["advt_name"] }}</td>
                  <td *ngIf="!last">{{ item["cust_name"] }}</td>
                  <td *ngIf="!last">{{ item["rep_name"] }}</td>
                  <td *ngIf="!last">{{ item["rep_mob_no"] }}</td>
                  <td *ngIf="!last">{{ item["off_rep_name"] }}</td>
                  <td *ngIf="!last">{{ item["ro_no"] }}</td>
                  <td *ngIf="!last">{{ item["instruction"] }}</td>
                  <td *ngIf="!last">{{ item["advtsize"] }}</td>
                  <td *ngIf="!last">{{ item["advt_amt"] }}</td>
                  <td *ngIf="!last">{{ item["total_amount"] }}</td>
                  <td *ngIf="!last">{{ toInt(item["sgst"]) + toInt(item["cgst"]) }}</td>
                  <td *ngIf="!last">{{ item["amount"] }}</td>
                  <td *ngIf="!last">{{ item["part_pymt_amt"] }}</td>
                  <td *ngIf="!last">
                    {{ item["rep_rcpt_no"] }}<br />
                    {{ item["rcpt_date"] | date : "dd-MM-yyy" }}
                  </td>
                  <td *ngIf="!last">{{ item["part_pymt_remaning_amt"] }}</td>
                </tr>
                <tr *ngFor="let item of data; let i = index; let last = last">
                  <td *ngIf="last" colspan="2">Total</td>
                  <td *ngIf="last" colspan="2">
                    Total No of Advt : {{ item["total_advt"] }}
                  </td>
                  <td *ngIf="last"></td>
                  <td *ngIf="last"></td>
                  <td *ngIf="last"></td>
                  <td *ngIf="last"></td>
                  <td *ngIf="last"></td>
                  <td *ngIf="last"></td>
                  <td *ngIf="last"></td>
                  <td *ngIf="last">{{ item["advt_total"] }}</td>
                  <td *ngIf="last">{{ item["net_amt"] }}</td>
                  <td *ngIf="last">{{ item["gst"] }}</td>
                  <td *ngIf="last">{{ item["total_amt"] }}</td>
                  <td *ngIf="last">{{ item["rcvd_amt"] }}</td>
                  <td *ngIf="last"></td>
                  <td *ngIf="last">{{ item["pndg_amt"] }}</td>
                </tr>
              </tbody>
              <tfoot *ngIf="end">
                <tr>
                  <td colspan="2">Total</td>
                  <td colspan="2">
                    Total No of Advt :
                    {{ this.inDetailsRegReportTotal.total_advt }}
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>{{ this.inDetailsRegReportTotal.advt_total }}</td>
                  <td>{{ this.inDetailsRegReportTotal.net_amt }}</td>
                  <td>{{ this.inDetailsRegReportTotal.gst }}</td>
                  <td>{{ this.inDetailsRegReportTotal.total_amt }}</td>
                  <td>{{ this.inDetailsRegReportTotal.rcvd_amt }}</td>
                  <td></td>
                  <td>{{ this.inDetailsRegReportTotal.pndg_amt }}</td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </mat-card>

      <mat-card *ngIf="showindetailoffrep">
        <div class="tableFixHead" id="excel-table">
          <button type="button" mat-raised-button matTooltip="Download Execelsheet" class="btn btn-danger"
            (click)="exportexcel('Registration Report')" style="float: right">
            Export
          </button>
          <div *ngFor="let data of inDetailsRegReportsubTotalData; let s = index; let end = last">
            <table class="table table-bordered">
              <thead *ngIf="s == 0">
                <tr>
                  <th colspan="3">Vivek Saptahik</th>
                  <th colspan="3">Advt. Registreation Report</th>
                  <th colspan="2"></th>
                  <th colspan="4">Office Represantativewise</th>
                  <th colspan="2">In Detail</th>
                  <th colspan="4">Date :</th>
                </tr>
                <tr>
                  <th colspan="3">Date. From :</th>
                  <th>To :</th>
                  <th colspan="3">Issue/Grantha Categary :</th>
                  <th colspan="2">Issue Date :</th>
                  <th colspan="6">Issue Name :</th>
                  <th colspan="3">Page No</th>
                </tr>
                <tr>
                  <th>Issue Sr. No</th>
                  <th>Booking Date</th>
                  <th>Booking No</th>
                  <th>Advertiser Name</th>
                  <th>Customer Name</th>
                  <th>Representative Name</th>
                  <th>Mobile No</th>
                  <th>Office Represantative Name</th>
                  <th>RO Number</th>
                  <th>Instruction</th>
                  <th>Advt.Size</th>
                  <th>Advt Amount Rs</th>
                  <th>Net Amount</th>
                  <th>GST 5%</th>
                  <th>Total Amount</th>
                  <th>Received Amount</th>
                  <th>Receipt No. and Date</th>
                  <th>Pending Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of data; let i = index; let last = last">
                  <td *ngIf="i == 0" colspan="3">Office Represantativewise</td>
                  <td *ngIf="i == 0">{{ item['off_rep_name'] }}</td>
                  <td *ngIf="i == 0"></td>
                  <td *ngIf="i == 0"></td>
                  <td *ngIf="i == 0"></td>
                  <td *ngIf="i == 0"></td>
                  <td *ngIf="i == 0"></td>
                  <td *ngIf="i == 0"></td>
                  <td *ngIf="i == 0"></td>
                  <td *ngIf="i == 0"></td>
                  <td *ngIf="i == 0"></td>
                  <td *ngIf="i == 0"></td>
                  <td *ngIf="i == 0"></td>
                  <td *ngIf="i == 0"></td>
                  <td *ngIf="i == 0"></td>
                  <td *ngIf="i == 0"></td>
                </tr>
                <tr *ngFor="let item of data; let i = index; let last = last">
                  <td *ngIf="i == 0">Represantativewise</td>
                  <td *ngIf="i == 0">{{ item['rep_name'] }}</td>
                  <td *ngIf="i == 0">{{ item['rep_mob_no'] }}</td>
                  <td *ngIf="i == 0"></td>
                  <td *ngIf="i == 0"></td>
                  <td *ngIf="i == 0"></td>
                  <td *ngIf="i == 0"></td>
                  <td *ngIf="i == 0"></td>
                  <td *ngIf="i == 0"></td>
                  <td *ngIf="i == 0"></td>
                  <td *ngIf="i == 0"></td>
                  <td *ngIf="i == 0"></td>
                  <td *ngIf="i == 0"></td>
                  <td *ngIf="i == 0"></td>
                  <td *ngIf="i == 0"></td>
                  <td *ngIf="i == 0"></td>
                  <td *ngIf="i == 0">
                  </td>
                  <td *ngIf="i == 0"></td>
                </tr>
                <tr *ngFor="let item of data; let i = index; let last = last">
                  <td *ngIf="!last">{{ i + 1 }}</td>
                  <td *ngIf="!last">{{ item['booking_date'] | date:"dd-MM-yyy" }}</td>
                  <td *ngIf="!last">{{ item['booking_no'] }}</td>
                  <td *ngIf="!last">{{ item['advt_name'] }}</td>
                  <td *ngIf="!last">{{ item['cust_name'] }}</td>
                  <td *ngIf="!last"></td>
                  <td *ngIf="!last"></td>
                  <td *ngIf="!last"></td>
                  <td *ngIf="!last">{{ item['ro_no'] }}</td>
                  <td *ngIf="!last">{{ item['instruction'] }}</td>
                  <td *ngIf="!last">{{ item['advtsize'] }}</td>
                  <td *ngIf="!last">{{ item['advt_amt'] }}</td>
                  <td *ngIf="!last">{{ item['total_amount'] }}</td>
                  <td *ngIf="!last">{{ toInt(item['sgst']) + toInt(item['cgst']) }}</td>
                  <td *ngIf="!last">{{ item['amount'] }}</td>
                  <td *ngIf="!last">{{ item['part_pymt_amt'] }}</td>
                  <td *ngIf="!last">{{ item['rep_rcpt_no'] }}<br />
                    {{ item['rcpt_date'] | date:"dd-MM-yyy" }}</td>
                  <td *ngIf="!last">{{ item['part_pymt_remaning_amt'] }}</td>
                </tr>
                <tr *ngFor="let item of data; let i = index; let last = last">
                  <td *ngIf="last" colspan="2">Total</td>
                  <td *ngIf="last" colspan="2">
                    Total No of Advt : {{ item["total_advt"] }}
                  </td>
                  <td *ngIf="last"></td>
                  <td *ngIf="last"></td>
                  <td *ngIf="last"></td>
                  <td *ngIf="last"></td>
                  <td *ngIf="last"></td>
                  <td *ngIf="last"></td>
                  <td *ngIf="last"></td>
                  <td *ngIf="last">{{ item["advt_total"] }}</td>
                  <td *ngIf="last">{{ item["net_amt"] }}</td>
                  <td *ngIf="last">{{ item["gst"] }}</td>
                  <td *ngIf="last">{{ item["total_amt"] }}</td>
                  <td *ngIf="last">{{ item["rcvd_amt"] }}</td>
                  <td *ngIf="last"></td>
                  <td *ngIf="last">{{ item["pndg_amt"] }}</td>
                </tr>
              </tbody>
              <tfoot *ngIf="end">
                <tr>
                  <td colspan="2">Total</td>
                  <td colspan="2">
                    Total No of Advt : {{ this.inDetailsOffRegReportTotal.total_advt }}
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>{{ this.inDetailsOffRegReportTotal.advt_total }}</td>
                  <td>{{ this.inDetailsOffRegReportTotal.net_amt }}</td>
                  <td>{{ this.inDetailsOffRegReportTotal.gst }}</td>
                  <td>{{ this.inDetailsOffRegReportTotal.total_amt }}</td>
                  <td>{{ this.inDetailsOffRegReportTotal.rcvd_amt }}</td>
                  <td></td>
                  <td>{{ this.inDetailsOffRegReportTotal.pndg_amt }}</td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </mat-card>

      <mat-card *ngIf="showdatewisevrybrfly">
        <div class="tableFixHead" id="excel-table">
          <button type="button" mat-raised-button matTooltip="Download Execelsheet" class="btn btn-danger"
            (click)="exportexcel('Registration Report')" style="float: right">
            Export
          </button>

          <table class="table table-bordered">
            <thead>
              <tr>
                <th colspan="3">Vivek Saptahik</th>
                <th colspan="4">Advt. Registreation Report-</th>
                <th>Datewise</th>
                <th>Very Brief</th>
              </tr>
              <tr>
                <th colspan="3">Date. From :</th>
                <th>To :</th>
                <th colspan="3">Advt. Registreation Report-</th>
                <th>Issue Date :</th>
                <th>Issue Name :</th>
              </tr>
              <tr>
                <th></th>
                <th>Booking Date</th>
                <th>Total No of Advt</th>
                <th>Advt Amount Rs</th>
                <th>Net Amount</th>
                <th>GST 5%</th>
                <th>Total Amount</th>
                <th>Received Amount</th>
                <th>Pending Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of inDetailsRegReportsubTotalData">
                <td></td>
                <td>{{ item["date"] | date : "dd-MM-yyy" }}</td>
                <td>{{ item["total_advt"] }}</td>
                <td>{{ item["advt_total"] }}</td>
                <td>{{ item["net_amt"] }}</td>
                <td>{{ item["gst"] }}</td>
                <td>{{ item["total_amt"] }}</td>
                <td>{{ item["rcvd_amt"] }}</td>
                <td>{{ item["pndg_amt"] }}</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td>Total</td>
                <td colspan="2">
                  Total No of Advt :
                  {{ this.inDetailsRegReportTotal.total_advt }}
                </td>
                <td>{{ this.inDetailsRegReportTotal.advt_total }}</td>
                <td>{{ this.inDetailsRegReportTotal.net_amt }}</td>
                <td>{{ this.inDetailsRegReportTotal.gst }}</td>
                <td>{{ this.inDetailsRegReportTotal.total_amt }}</td>
                <td>{{ this.inDetailsRegReportTotal.rcvd_amt }}</td>
                <td>{{ this.inDetailsRegReportTotal.pndg_amt }}</td>
              </tr>
            </tfoot>
          </table>
        </div>
      </mat-card>

      <mat-card *ngIf="showoffrepvrybrfly">
        <div class="tableFixHead" id="excel-table">
          <button type="button" mat-raised-button matTooltip="Download Execelsheet" class="btn btn-danger"
            (click)="exportexcel('Registration Report')" style="float: right">
            Export
          </button>
          <div *ngFor="let item of inDetailsRegReportsubTotalData; let i = index; let last = last">
            <table class="table table-bordered">
              <thead *ngIf="i == 0">
                <tr>
                  <th colspan="3">Vivek Saptahik</th>
                  <th colspan="3">Advt. Registreation Report-</th>
                  <th colspan="2">Office Represantativewise</th>
                  <th>Very Brief</th>
                </tr>
                <tr>
                  <th colspan="3">Date. From :</th>
                  <th>To :</th>
                  <th colspan="3">Advt. Registreation Report-</th>
                  <th>Issue Date :</th>
                  <th>Issue Name :</th>
                </tr>
                <tr>
                  <th colspan="2">Represantative Name</th>
                  <th>Total No of Advt</th>
                  <th>Advt Amount Rs</th>
                  <th>Net Amount</th>
                  <th>GST 5%</th>
                  <th>Total Amount</th>
                  <th>Received Amount</th>
                  <th>Pending Amount</th>
                </tr>
              </thead>

              <tbody>
                <tr *ngIf="i == 0">
                  <td colspan="3">Office Represantativewise</td>
                  <td>{{ item['off_rep_name'] }}</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td colspan="2">{{ item['rep_name'] }}</td>
                  <td>{{ item["total_advt"] }}</td>
                  <td>{{ item["advt_total"] }}</td>
                  <td>{{ item["net_amt"] }}</td>
                  <td>{{ item["gst"] }}</td>
                  <td>{{ item["total_amt"] }}</td>
                  <td>{{ item["rcvd_amt"] }}</td>
                  <td>{{ item["pndg_amt"] }}</td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
              <tfoot *ngIf="last">
                <tr>
                  <td>Total</td>
                  <td colspan="2">Total No of Advt : {{ this.inDetailsOffRegReportTotal.total_advt }}</td>
                  <td>{{ this.inDetailsOffRegReportTotal.net_amt }}</td>
                  <td>{{ this.inDetailsOffRegReportTotal.net_amt }}</td>
                  <td>{{ this.inDetailsOffRegReportTotal.gst }}</td>
                  <td>{{ this.inDetailsOffRegReportTotal.total_amt }}</td>
                  <td>{{ this.inDetailsOffRegReportTotal.rcvd_amt }}</td>
                  <td>{{ this.inDetailsOffRegReportTotal.pndg_amt }}</td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </mat-card>
    </mat-tab>

    <mat-tab label="Advt. Contract Register">
      <mat-card style="margin-top: 2%;">
        <form [formGroup]="contractDetailsForm">
          <div class="form-row">
            <mat-form-field style="margin-left: 7%; width: 35%;">
              <mat-label>Contract Received Date</mat-label>
              <mat-date-range-input [rangePicker]="pickerr">
                <input matStartDate formControlName="start" placeholder="Start date" />
                <input matEndDate formControlName="end" placeholder="End date"
                  (dateChange)="getChangedDateContract()" />
              </mat-date-range-input>
              <mat-datepicker-toggle matSuffix [for]="pickerr"></mat-datepicker-toggle>
              <mat-date-range-picker #pickerr></mat-date-range-picker>

              <mat-error *ngIf="
                                  contractDetailsForm.controls.start.hasError(
                                    'matStartDateInvalid'
                                  )
                                ">Invalid start date
              </mat-error>
              <mat-error *ngIf="
                                  contractDetailsForm.controls.end.hasError('matEndDateInvalid')
                                ">Invalid end date
              </mat-error>
            </mat-form-field>

            <mat-form-field style="margin-left: 3%; width: 35%;">
              <mat-label>Representative Name : </mat-label>
              <mat-select #mySelc1 (selectionChange)="getContractRepName($event)" formControlName="rep_name" multiple>
                <mat-option value="ALL" (click)="toggleAllSelectionc1()">All</mat-option>
                <mat-option *ngFor="let item of contractRepName" value="{{  item['name'] }}">{{ item["name"]
                  }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="form-row">
            <mat-form-field style="margin-left: 7%; width: 35%;">
              <mat-label>Office Representative : </mat-label>
              <mat-select #mySelc2 (selectionChange)="getContractOffRepName($event)" formControlName="off_rep_name"
                multiple>
                <mat-option value="ALL" (click)="toggleAllSelectionc2()">All</mat-option>
                <mat-option *ngFor="let item of pp_off_rep_names" value="{{  item['off_rep_name'] }}">{{
                  item["off_rep_name"]
                  }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field style="margin-left: 3%; width: 35%;">
              <mat-label>Advertiser Name : </mat-label>
              <mat-select #mySelc3 (selectionChange)="getContractAdvtName($event)" formControlName="advt_name" multiple>
                <mat-option value="ALL" (click)="toggleAllSelectionc3()">All</mat-option>
                <mat-option *ngFor="let item of contractAdvtName" value="{{  item['name'] }}">{{ item["name"]
                  }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="form-row">
            <mat-form-field style="margin-left: 7%; width: 35%;">
              <mat-label>Select type of report</mat-label>
              <mat-select #mySel1 (selectionChange)="getContractReportType($event)">
                <mat-option value="indetailcontract">In Detail
                </mat-option>
                <mat-option value="briefly">Briefly
                </mat-option>
              </mat-select>
            </mat-form-field>
            <div style="margin-left: 3%;">
              <button mat-raised-button type="button" class="
              btn btn-info" (click)="showContractoneReport()">Search</button>
            </div>
          </div>
        </form>
        <div class="tableFixHead">
          <table class="table table-striped">
            <thead>
              <tr>
                <th>Contract No</th>
                <th>Contract Date</th>
                <th>Advt. Name</th>
                <th>Rep. Name</th>
                <th>Off. Rep. Name</th>
                <th>Contract Amount</th>
                <th>Discount</th>
                <th>Net Amount</th>
                <th>GST</th>
                <th>Total</th>
                <th>Contract from</th>
                <th>Contract to</th>
                <th>Booking Advt. Amount</th>
                <th>Balance Amount</th>
                <th>Instruction</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of contractData">
                <td>{{ item['contract_no'] }}</td>
                <td>{{ item['contract_date'] }}</td>
                <td>{{ item['advt_name'] }}</td>
                <td>{{ item['rep_name'] }}</td>
                <td>{{ item['off_rep_name'] }}</td>
                <td>{{ item['contract_amt'] }}</td>
                <td>{{ item['contract_discnt_amt'] }}</td>
                <td>{{ item['contract_net_amt'] }}</td>
                <td>{{ item['contract_net_gst_amt'] }}</td>
                <td>{{ item['contract_net_total'] }}</td>
                <td>{{ item['contract_period_from'] | date:"dd-MM-yyy" }}</td>
                <td>{{ item['contract_period_to'] | date:"dd-MM-yyy" }}</td>
                <td>{{ item['contract_bkng_advt_amt'] }}</td>
                <td>{{ item['contract_bal_amt'] }}</td>
                <td>{{ item['instruction'] }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </mat-card>
    </mat-tab>

    <!-- Create Occassional -->
    <mat-tab label="Prasiddhi Patrak">
      <mat-form-field style="margin-top: 5%; margin-left: 3%">
        <mat-label>Advt. Received Date</mat-label>
        <mat-date-range-input [formGroup]="PrasiddhipatrakRange" [rangePicker]="pickerppatrak">
          <input matStartDate formControlName="start" placeholder="Start date" />
          <input matEndDate formControlName="end" placeholder="End date"
            (dateChange)="getChangedDatePrasiddhiPatrak()" />
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="pickerppatrak"></mat-datepicker-toggle>
        <mat-date-range-picker #pickerppatrak></mat-date-range-picker>
        <mat-error *ngIf="
                  PrasiddhipatrakRange.controls.start.hasError(
                    'matStartDateInvalid'
                  )
                ">Invalid start date
        </mat-error>
        <mat-error *ngIf="
                  PrasiddhipatrakRange.controls.end.hasError('matEndDateInvalid')
                ">Invalid end date
        </mat-error>
      </mat-form-field>

      <mat-form-field style="margin-left: 3%">
        <mat-label>Issue/grantha : </mat-label>
        <mat-select (selectionChange)="getIssues($event)" formControlName="issuename" required>
          <mat-option value="ALL" (click)="toggleAllSelection()">All</mat-option>

          <mat-option *ngFor="let item of advtCategorylist" value="{{ item.category }}">
            {{ item.category }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field style="margin-left: 3%">
        <mat-label>Issue Dates : </mat-label>
        <mat-select (selectionChange)="getAdvtDates($event)" formControlName="issueDates" multiple required>
          <mat-option value="ALL">All</mat-option>
          <mat-option *ngFor="let item of getMagIssueDatesandNames" value="{{ item.id }}">{{ item["issueDate"] | date :
            "dd/MM/yyyy" }}
            {{ item["issueName"] }}

          </mat-option>
        </mat-select>
      </mat-form-field>

      <!-- <mat-option *ngFor="let item of getMagIssueDatesandNames" value="{{
                                [
                                  item['issueDate'] | date : 'dd/MM/yyyy',
                                  item['issueName']'
                                ]
                              }}">{{ item["issueDate"] | date :
            "dd/MM/yyyy" }}
            {{ item["issueName"] }} 
          </mat-option> -->

      <!-- <mat-form-field style="margin-left: 3%">
        <mat-label>Advt Name : </mat-label>
        <mat-select #mySel1 (selectionChange)="getAdvtName($event)" multiple>
          <mat-option value="ALL" (click)="toggleAllSelection()">All</mat-option>
          <mat-option *ngFor="let item of pp_advt_names" value="{{  item['advt_name'] }}">{{ item["advt_name"] }}
          </mat-option>
        </mat-select>
      </mat-form-field> -->

      <mat-form-field style="margin-left: 3%">
        <mat-label>Advertiser Name :</mat-label>
        <input #myInput matInput (keyup)="getAdvtNames($event)" [matAutocomplete]="auto" />
        <mat-autocomplete #auto="matAutocomplete">
          <mat-option (onSelectionChange)="selected_name1($event, option)"
            *ngFor="let option of filteredOptions | async" [value]="option['name']">
            {{ option["name"] }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <mat-form-field style="margin-left: 3%">
        <mat-label>Representative : </mat-label>
        <mat-select #mySel1 (selectionChange)="getRepName2($event)" multiple>
          <mat-option value="ALL" (click)="toggleAllSelection()">All</mat-option>
          <mat-option *ngFor="let item of pp_rep_names" value="{{  item['rep_name'] }}">{{ item["rep_name"] }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field style="margin-left: 3%">
        <mat-label>Office Representative : </mat-label>
        <mat-select #mySel1 (selectionChange)="getOfficeRep1($event)" multiple>
          <mat-option value="ALL" (click)="toggleAllSelection()">All</mat-option>
          <mat-option *ngFor="let item of pp_off_rep_names" value="{{ item['off_rep_name'] }}">{{
            item["off_rep_name"] }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field style="margin-left: 3%">
        <mat-label>Select Pagewise</mat-label>
        <mat-select (selectionChange)="getPagewise($event)" formControlName="pagetype">
          <mat-option value="pageno">पान नंबर भरलेले
          </mat-option>
          <mat-option value="nopageno">पान नंबर न भरलेले
          </mat-option>
          <mat-option value="ALL">एकत्रित
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field style="margin-left: 3%">
        <mat-label>Report Type</mat-label>
        <mat-select (selectionChange)="getReportType($event)">
          <mat-option value="Report">Report
          </mat-option>
          <mat-option value="Browse">Browse
          </mat-option>
          <mat-option value="Address">Address
          </mat-option>
        </mat-select>
      </mat-form-field>


      <button style="margin-left: 3%" (click)="getPrasiddhiPatrak()" mat-raised-button color="primary">
        Search
      </button>

      <!-- <div class="add-new-subscriber mat-elevation-z4" style="overflow-x: scroll !important" id="excel-table">
        <div style="margin-top: 20px" class="tableFixHead">
          <button type="button" (click)="exportexcel('advt_prasiddhi_patrak')" class="btn btn-danger">
            Export
          </button>
          <table class="table table-striped text-center">
            <thead>
              <tr>
                <th>अ.क्र.</th>
                <th>नोंदणी क्र.</th>
                <th>जाहिरातदार</th>
                <th>ग्राहकाचे नाव</th>
                <th>जाहि.आकार</th>
                <th>जाहि. सूचना</th>
                <th>पृष्ठ क्र.</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let advt of this.prasiddhiPatrak; let i = index">
                <td>{{ i + 1 }}</td>
                <td>{{ advt["advt_id"] }}</td>
                <td>{{ advt["advt_person_name"] }}</td>
                <td>{{ advt["cust_name"] }}</td>
                <td>{{ advt["advt_size"] }}</td>
                <td>{{ advt["comment"] }}</td>
                <td>{{ advt["advt_page"] || "--" }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div> -->
    </mat-tab>

    <mat-tab label="Advt. Pending Credit Voucher">
      <div class="add-new-subscriber mat-elevation-z4">
        <button class="noPrint" style=" margin-left: 1%; margin-top: 2%;" mat-raised-button color="primary"
          printSectionId="print-section" [useExistingCss]="true" ngxPrint>
          print
        </button>
        <div style="margin-top: 20px" class="tableFixHead" id="print-section">
          <table class="table table-striped text-center ">
            <thead>
              <tr>
                <th>Action</th>
                <th>Sr.No.</th>

                <th>Voucher No.</th>
                <th>Voucher Date</th>
                <th>Entry Date</th>
                <th>Rep.Name</th>
                <th>Advt.Name</th>
                <th>Amount</th>
                <th>Remaining Amount</th>
                <th>Cheque no</th>
                <th>Cheque date</th>
                <th>Branch Name</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let book of this.getallVouchersData; let i = index">
                <td>
                  <button type="button" class="btn btn-danger btn-sm" (click)="displayItemId(book);">
                    Mark as Completed
                  </button>
                </td>
                <td>{{ i + 1 }}</td>

                <td>{{ book["voucher_no"] }}</td>
                <td>{{ book["voucher_date"] }}</td>
                <td>{{ book["entry_date"]}}</td>
                <td>{{ book["rep_name"] }}</td>
                <td>{{ book["advtName"] }}</td>
                <td>{{ book["price"] }}</td>
                <td>{{ book['remaining_amt'] }}</td>
                <td>{{ book["cheque_no"] }}</td>
                <td>{{ book["chequeDate"] | date : "dd-MM-yyy" }}</td>
                <td>{{ book["branch_name"] }}</td>

              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td></td>
                <td>{{getallVouchersData.length}}</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>{{totalTotal | number:'1.2-2' }}</td>
                <td>{{pendingTotal | number:'1.2-2' }}</td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </mat-tab>

    <mat-tab label="Advt Payment Receipt">
      <mat-card class="card">
        <form class="form" [formGroup]="advtpymtrecptform">
          <div class="form-row">
            <mat-form-field appearance="outline" style="width: 40%; margin-left: 5%">
              <mat-label>Credit Voucher No</mat-label>
              <input matInput type="text" formControlName="crdtvno" readonly />
            </mat-form-field>
            <mat-form-field appearance="outline" style="width: 40%; margin-left: 5%">
              <mat-label>Date</mat-label>
              <input matInput type="date" formControlName="date" readonly />
            </mat-form-field>

          </div>
          <div class="form-row">
            <h3 style="padding-top: 10px;margin-left: 5%;padding-right: 20px;">Select receipt is New/Delete</h3>
            <mat-button-toggle-group formControlName="newordeleted" (change)="newordel($event)" name="fontStyle"
              aria-label="Font Style" style="height: 50px;">
              <mat-button-toggle value="new">New</mat-button-toggle>
              <mat-button-toggle value="deleted">Deleted</mat-button-toggle>
            </mat-button-toggle-group>


            <!-- <mat-form-field appearance="outline" style="width: 40%; margin-left: 5%">
              <mat-label>Receipt Date</mat-label>
              <input matInput type="date" formControlName="recptdate" readonly />
            </mat-form-field> -->
            <mat-form-field appearance="outline" style="width: 40%; margin-left: 17%">
              <mat-label>Receipt Date</mat-label>
              <input matInput formControlName="recptdate" required />
              <!-- <mat-datepicker-toggle matSuffix [for]="pickerR1"></mat-datepicker-toggle>
              <mat-datepicker #pickerR1></mat-datepicker> -->
            </mat-form-field>

          </div>
          <div class="form-row">
            <mat-form-field appearance="outline" style="width: 40%; margin-left: 5%">
              <mat-label>Representative Name</mat-label>
              <input matInput type="text" formControlName="repname" readonly />
            </mat-form-field>
            <mat-form-field *ngIf="!deleterec" appearance="outline" style="width: 40%; margin-left: 5%">
              <mat-label>Receipt No</mat-label>
              <input matInput type="text" formControlName="recptno" readonly />
            </mat-form-field>
            <!-- <mat-form-field *ngIf="deleterec" appearance="outline" style="width: 40%; margin-left: 5%">
              <mat-label>Receipt No (delete)</mat-label>
              <input matInput type="text" formControlName="recptno"  />
            </mat-form-field> -->
            <mat-form-field *ngIf="deleterec" appearance="outline" style="width: 40%; margin-left: 5%">
              <mat-label>Receipt No (delete)</mat-label>
              <mat-select formControlName="recptno">
                <mat-option *ngFor="let receipt of getdeleterecipt" [value]="receipt.rcpt_no" readonly>
                  {{ receipt.rcpt_no }}
                </mat-option>
              </mat-select>
            </mat-form-field>

          </div>
          <div class="form-row">
            <mat-form-field appearance="outline" style="width: 27%; margin-left: 5%">
              <mat-label>Credit Voucher Amount</mat-label>
              <input matInput type="number" formControlName="crdtvamt" readonly />
            </mat-form-field>
            <mat-form-field appearance="outline" style="width: 27%; margin-left: 2%">
              <mat-label>Select Entry Amount</mat-label>
              <input matInput type="number" formControlName="seamt" readonly />
            </mat-form-field>
            <mat-form-field appearance="outline" style="width: 27%; margin-left: 2%">
              <mat-label>Balance Amount</mat-label>
              <input matInput type="number" formControlName="balamt" readonly />
            </mat-form-field>
          </div>
          <div class="form-row">
            <mat-form-field appearance="outline" style="width: 40%; margin-left: 5%">
              <mat-label>Advt. Name</mat-label>
              <input matInput type="text" formControlName="advtname" readonly />
            </mat-form-field>
          </div>
          <div class="form-group">
            <mat-form-field class="example-full-width" appearance="outline" style="width: 40%; margin-left: 5%">
              <mat-label>Advt. Name Search</mat-label>
              <input matInput formControlName="advtnamesearch" (keyup)="getAdvtNames($event)"
                [matAutocomplete]="auto1" />
              <mat-autocomplete autoActiveFirstOption #auto1="matAutocomplete">
                <mat-option (onSelectionChange)="selected_name1($event, option)" *ngFor="let option of advt_names"
                  [value]="option['name']">
                  {{ option["name"] }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <!-- <mat-form-field class="example-full-width" appearance="outline" style="width: 25%; margin-right: 3%">
              <mat-label>Advertiser Name :</mat-label>
              <input matInput formControlName="advtnamesearch" (keyup)="getAdvtNames($event)" [matAutocomplete]="auto1" />
              <mat-autocomplete autoActiveFirstOption #auto1="matAutocomplete">
                <mat-option (onSelectionChange)="selected_name1($event, option)" *ngFor="let option of advt_names"
                  [value]="option['name']">
                  {{ option["name"] }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>    -->
            <button type="button" mat-raised-button color="primary" style="margin-left: 5%" (click)="searchAdvtName1()">
              Search
            </button>
          </div>
          <div style="margin-top: 20px; margin-left: 20px" class="tableFixHead1">
            <table class="table table-bordered table-hover text-center">
              <thead>
                <tr>
                  <th>Invoice Date</th>
                  <th>Invoice No</th>
                  <th>Booking No</th>
                  <th>Advt. Name</th>
                  <th>Invoice Amt</th>
                  <th>Receive Amt</th>
                  <th>Remaining Amount</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let book of getentrybyrepnamelist ">

                  <td>{{ book.invoice_date | date: "dd-MM-yyy" }}</td>
                  <td>{{ book["invoice_no"] }}</td>
                  <td>{{ book["booking_no"] }}</td>
                  <td>{{ book["advt_name"] }}</td>
                  <td>{{ book["pay_amt"] }}</td>
                  <td>{{ book["rcv_amt"] || 0}}</td>
                  <td>{{ (book["pay_amt"] - book["rcv_amt"]).toFixed(2) || "0.00" }}</td>
                  <td style="display: flex;">
                    <button [(disabled)]="this.click" type="button" mat-raised-button color="primary"
                      (click)="selectedItem(book)">
                      Select
                    </button>
                    <button style="margin-left: 2%" type="button" mat-raised-button class="btn btn-info"
                      (click)="updateIssueAnk(book)">
                      Update
                    </button>

                    <!-- <button mat-icon-button matTooltip="View" class="btn-sm btn-outline-primary"
                        (click)="viewAdvtInvoice(data.id)"><mat-icon>visibility</mat-icon></button>
                     
                      <button mat-icon-button matTooltip="Cancel" class="btn-sm btn-outline-danger"
                        (click)="cancelAdvtInvoice(data['id'])"><mat-icon>delete</mat-icon></button> -->

                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="form-group" style="display: flex; justify-content: center;">
            <button style="
                      width: 60%;
                      margin-top: 20px;" [disabled]="advtpymtrecptform.invalid" type="button" mat-raised-button
              color="primary" class="btn btn-primary btn-block" (click)="addadvtpymtrcpt()">
              Add
            </button>
          </div>
        </form>
      </mat-card>
    </mat-tab>

    <mat-tab label="Advt. Payment Receipt Register">
      <mat-card style="margin-top: 2%;">
        <form [formGroup]="PaymentReceiptRegister">
          <div class="form-row">
            <mat-form-field style="margin-left: 7%; width: 35%;">
              <mat-label>Receipt Date</mat-label>
              <mat-date-range-input [rangePicker]="pickerrPR">
                <input matStartDate formControlName="start" placeholder="Start date" />
                <input matEndDate formControlName="end" placeholder="End date"
                  (dateChange)="getChangedDateContractM1()" />
              </mat-date-range-input>
              <mat-datepicker-toggle matSuffix [for]="pickerrPR"></mat-datepicker-toggle>
              <mat-date-range-picker #pickerrPR></mat-date-range-picker>

              <mat-error *ngIf="
                                  contractDetailsForm.controls.start.hasError(
                                    'matStartDateInvalid'
                                  )
                                ">Invalid start date
              </mat-error>
              <mat-error *ngIf="
                                  contractDetailsForm.controls.end.hasError('matEndDateInvalid')
                                ">Invalid end date
              </mat-error>
            </mat-form-field>

            <mat-form-field style="margin-left: 3%; width: 35%;">
              <mat-label>Representative Name : </mat-label>
              <mat-select #mySel1 (selectionChange)="getContractRepName($event)" formControlName="rep_name" multiple>
                <mat-option value="ALL" (click)="toggleAllSelection()">All</mat-option>
                <mat-option *ngFor="let item of contractRepName" value="{{  item['name'] }}">{{ item["name"]
                  }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="form-row">
            <mat-form-field style="margin-left: 7%; width: 35%;">
              <mat-label>Office Representative : </mat-label>
              <mat-select #mySel1 (selectionChange)="getContractOffRepName($event)" formControlName="off_rep_name"
                multiple>
                <!-- <mat-option value="all" (click)="toggleAllSelection()">All</mat-option> -->
                <mat-option value="ALL" (click)="toggleAllSelection()">All</mat-option>
                <mat-option *ngFor="let item of pp_off_rep_names" value="{{  item['off_rep_name'] }}">{{
                  item["off_rep_name"]
                  }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field style="margin-left: 3%">
              <mat-label>Issue/grantha : </mat-label>
              <mat-select (selectionChange)="getIssues($event)" formControlName="issuename" required>
                <mat-option value="ALL" (click)="toggleAllSelection()">All</mat-option>
      
                <mat-option *ngFor="let item of advtCategorylist" value="{{ item.category }}">
                  {{ item.category }}
                </mat-option>
              </mat-select>
            </mat-form-field>
      
            <mat-form-field style="margin-left: 3%">
              <mat-label>Issue Dates : </mat-label>
              <mat-select (selectionChange)="getAdvtDates($event)" formControlName="issueDates" multiple required>
                <mat-option value="ALL">All</mat-option>
                <mat-option *ngFor="let item of getMagIssueDatesandNames" value="{{ item.id }}">{{ item["issueDate"] | date :
                  "dd/MM/yyyy" }}
                  {{ item["issueName"] }}
      
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="form-row">
            <mat-form-field style="margin-left: 7%; width: 35%;">
              <mat-label>Select type of report</mat-label>
              <mat-select #mySel1 (selectionChange)="getContractReportType($event)">
                <!-- <mat-option value="all" (click)="toggleAllSelection()">All</mat-option> -->
                <mat-option value="InDetail">In Detail
                </mat-option>
                <mat-option value="briefly1">Briefly
                </mat-option>
                <mat-option value="Monthwise">Monthwise
                </mat-option>
                <mat-option value="InDetail-Officer">InDetail-Officer
                </mat-option>
                <mat-option value="VeryBriefly-Officer">VeryBriefly-Officer
                </mat-option>
                <mat-option value="Briefly-Officer">Briefly-Officer
                </mat-option>
              </mat-select>
            </mat-form-field>

            <!-- New Div For Issue Date Start-->

            <!-- <mat-form-field style="margin-left: 3%">
              <mat-label>Issue Dates : </mat-label>
              <mat-select (selectionChange)="getAdvtPaymentDates($event)" formControlName="issueDates" multiple required>
                <mat-option value="ALL">All</mat-option>
                <mat-option *ngFor="let item of getMagIssueDatesandNames" value="{{ item.id }}">{{ item["issueDate"] |
                  date :
                  "dd/MM/yyyy" }}
                  {{ item["issueName"] }}

                </mat-option>
              </mat-select>
            </mat-form-field> -->

            <!-- End Issue Date Tab-->

            <div style="margin-left: 3%;">
              <button mat-raised-button type="button" class="
              btn btn-info" (click)="showContractReportPRR()">Search</button>
            </div>
            <div style="margin-left: 3%;">
              <button mat-raised-button type="button" class="
              btn btn-info" (click)="opentworeceipt()">Print</button>
            </div>

          </div>
        </form>
        <div class="tableFixHead">
          <table class="table table-striped">
            <thead>
              <tr>
                <th>
                  <mat-checkbox class="example-margin" [checked]="allSelectedtable" color="primary"
                    (change)="selectall($event)">
                  </mat-checkbox>
                </th>
                <th>Receipt No</th>
                <th>Receipt Date</th>
                <th>Advt. Name</th>
                <th>Rep. Name</th>
                <th>Off. Rep. Name</th>
                <th>Issue Name</th>
                <th>Issue Date</th>
                <th>Invoice Number</th>
                <th>Invoice Date</th>
                <th>Net Amount</th>
                <th>Received Amount</th>
                <th>Action</th>

              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let item of advtPaymentRegiInDetailsFormBeans">

                <tr>
                  <td>
                    <mat-checkbox [(ngModel)]="item.is_selected" color="primary" (change)="copydata1(item, $event)">
                    </mat-checkbox>
                  </td>
                  <td>{{ item['rcpt_no'] }}</td>
                  <td>{{ item['rcpt_date'] }}</td>
                  <td>{{ item['advt_name'] }}</td>
                  <td>{{ item['rep_name'] }}</td>
                  <td>{{ item['off_rep_name'] }}</td>
                  <td>{{ item['DS_ISSUENAME'] }}</td>
                  <td>{{ item['DS_ISSUEDATE'] }}</td>
                  <td>{{ item["advt_invoice_no"] }}</td>
                  <td>{{ item["advt_invoice_date"] }}</td>
                  <td>{{ item['net_amt'] }}</td>
                  <td>{{ item['rcv_amt'] }}</td>
                  <td>
                    <button style="margin-left: 2%" type="button" mat-raised-button class="btn btn-info"
                      (click)="updateIssueAnk(item)">
                      Update
                    </button>

                    <button mat-icon-button class="btn-sm btn-outline-danger" matTooltip="Delete"
                      style="margin-left: 2%;"
                      (click)="deletePymtreceipt(item['id'])"><mat-icon>delete</mat-icon></button>
                  </td>

                </tr>
              </ng-container>

            </tbody>

          </table>
        </div>
      </mat-card>
    </mat-tab>

    <mat-tab label="Advt Invoice">
      <mat-card class="card">
        <form [formGroup]="advtbillForm">
          <div class="form-row">
            <mat-form-field style="margin-left: 7%; margin-top: 10px; width: 40%" appearance="outline">
              <mat-label>Issue/grantha : </mat-label>
              <mat-select (selectionChange)="getIssuesAdvtNames($event)" formControlName="issuename" required>
                <mat-option *ngFor="let item of advtCategorylist" value="{{ item.category }}">
                  {{ item.category }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field style="margin-left: 20px; margin-top: 10px; width: 40%" appearance="outline">
              <mat-label>Issue Dates : </mat-label>
              <mat-select #mySel4 (selectionChange)="getAdvtNameDates($event)" formControlName="issuedate" multiple
                required>
                <!-- <mat-option value="" (click)="toggleAllSelection4()">All</mat-option> -->
                <mat-option *ngFor="let item of getMagIssueDatesandAdvtNames" value="{{ item['id'] }}">{{
                  item["issueDate"] | date : "dd-MM-yyyy" }}
                  {{ item["issueName"] }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="form-row">
            <mat-form-field style="margin-left: 7%; margin-top: 10px; width: 40%" appearance="outline">
              <mat-label>Advt. Name : </mat-label>
              <mat-select #isn (selectionChange)="getISN($event)" multiple required formControlName="advt_name">
                <mat-option value="all" (click)="toggleAllSelectionISN()">All</mat-option>
                <mat-option *ngFor="let item of advtInvoiceNames" value="{{ item['advt_name'] }}">
                  {{ item["advt_name"] }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <h3 style="padding-top: 10px;margin-left: 5%;padding-right: 20px;">Select receipt is New/Delete</h3>
            <mat-button-toggle-group formControlName="newordeleted" (change)="newordelinvoice($event)" name="fontStyle"
              aria-label="Font Style" style="height: 50px;">
              <mat-button-toggle value="new">New</mat-button-toggle>
              <mat-button-toggle value="deleted">Deleted</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <div class="form-row">
            <mat-form-field style="margin-left: 7%;width: 40%" class="example-full-width" appearance="outline">
              <mat-label>Date</mat-label>
              <input matInput [matDatepicker]="picker8" formControlName="date" (dateChange)="advtInvoiceDate($event)"
                required />
              <mat-datepicker-toggle matSuffix [for]="picker8"></mat-datepicker-toggle>
              <mat-datepicker #picker8 [dateFilter]="dateFilter"></mat-datepicker>
            </mat-form-field>
            <mat-form-field *ngIf="!deleterec" style="margin-left: 20px; width: 40%" class="example-full-width"
              appearance="outline">
              <mat-label>Bill No :</mat-label>
              <input type="text" formControlName="billno" matInput required readonly />
            </mat-form-field>
            <mat-form-field *ngIf="deleterec" style="margin-left: 20px; width: 40%" class="example-full-width"
              appearance="outline">
              <mat-label>Bill No (deleted) :</mat-label>
              <mat-select formControlName="billno" required>
                <mat-option *ngFor="let bill of getdeletereciptinvoice" [value]="bill.rcpt_no">
                  {{ bill.rcpt_no }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="form-group">
            <button style="margin-left: 125px; width: 70%; margin-top: 10px" type="submit"
              [disabled]="advtbillForm.invalid" (click)="openAdvtBill()" mat-raised-button color="primary">
              Add Advt Bill
            </button>
          </div>
        </form>
      </mat-card>

      <mat-card class="card">
        <h2 style="text-align:center">List of Advt Invoice</h2>

        <div>
          <form [formGroup]="advtbillFormsecond">
            <mat-form-field style="margin-left: 7%; width: 35%;">
              <mat-label>Search Date</mat-label>
              <mat-date-range-input [rangePicker]="pickerrAi">
                <input matStartDate formControlName="start" placeholder="Start date" />
                <input matEndDate formControlName="end" placeholder="End date"
                  (dateChange)="getChangedDateContractM11()" />
              </mat-date-range-input>
              <mat-datepicker-toggle matSuffix [for]="pickerrAi"></mat-datepicker-toggle>
              <mat-date-range-picker #pickerrAi></mat-date-range-picker>

              <mat-error *ngIf="
                                contractDetailsForm.controls.start.hasError(
                                  'matStartDateInvalid'
                                )
                              ">Invalid start date
              </mat-error>
              <mat-error *ngIf="
                                contractDetailsForm.controls.end.hasError('matEndDateInvalid')
                              ">Invalid end date
              </mat-error>
            </mat-form-field>
          </form>
        </div>
        <div class="tableFixHead">
          <button [ngStyle]="{'display':allSelectedtable || someComplete() ? 'block' : 'none'}" style="float: right;"
            mat-raised-button class="btn btn-primary" (click)="printAdvtInvoice()">Print
            Invoices</button>
          <table class="table table-striped">
            <thead>
              <tr>
                <th>
                  <mat-checkbox class="example-margin" [checked]="allSelectedtable" color="primary"
                    [indeterminate]="someComplete()" (change)="setAll($event.checked)">
                  </mat-checkbox>
                </th>
                <th>Sr. No.</th>
                <th>Invoice No.</th>
                <th>Invoice Date</th>
                <th>Advt. Name</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of advtInvoiceList; let i=index">
                <td>
                  <mat-checkbox [(ngModel)]="data.is_selected" color="primary" (ngModelChange)="updateAllComplete()">
                  </mat-checkbox>
                </td>
                <td>{{ i+1 }}</td>
                <td>{{ data['invoice_no'] }}</td>
                <td>{{ data['invoice_date'] }}</td>
                <td>{{ data['advt_name'] }}</td>
                <td>
                  <button mat-icon-button matTooltip="View" class="btn-sm btn-outline-primary"
                    (click)="viewAdvtInvoice(data.id)"><mat-icon>visibility</mat-icon></button>

                  <button mat-icon-button matTooltip="Edit" class="btn-sm btn-outline-danger"
                    (click)="updateAdvtInvoice(data)"><mat-icon>edit</mat-icon></button>

                  <button mat-icon-button matTooltip="Cancel" class="btn-sm btn-outline-danger"
                    (click)="cancelAdvtInvoice(data['id'])"><mat-icon>delete</mat-icon></button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </mat-card>

      <mat-card class="card">
        <h2 style="text-align:center">List without page no</h2>

        <table>
          <thead>
            <tr>
              <th>Sr</th>
              <th>Advt ID</th>
              <th>Name</th>
              <th>Reprensantive Name</th>
              <th>OffReprensantive Name</th>
              <th>Booking Date</th>
              <th>Booking No</th>
              <th>Issue Name</th>
              <th>Issue Date</th>
              <th>Advt Size</th>
              <th>Page</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of PPwithoutnumber; let i = index">
              <td>{{i+1}}</td>
              <td>{{data.advt_count}}</td>
              <td>{{data.DS_ADVTID}}</td>
              <td>{{data.name}}</td>
              <td>{{data.rep_name}}</td>
              <td>{{data.off_rep_name}}</td>
              <td>{{data.booking_date}}</td>
              <td>{{data.booking_no}}</td>
              <td>{{data.DS_ISSUENAME}}</td>
              <td>{{data.DS_ISSUEDATE}}</td>
              <td>{{data.DS_advtsize}}</td>
              <td> <input [(ngModel)]="data.pages_no" style="width: 60px; height: 30px;" type="number" /></td>
              <td><button type="button" class="btn-sm btn-outline-info" matTooltip="Add" (click)="addpageno(data)">
                  <mat-icon>add</mat-icon>
                </button>
              </td>
            </tr>
          </tbody>
        </table>

        <!-- <div class="mat-elevation-z8 matTable" tabindex="0">
          <table mat-table [dataSource]="PPwithoutnumber">
            <ng-container matColumnDef="advt_count">
              <th mat-header-cell *matHeaderCellDef>Sr</th>
              <td mat-cell *matCellDef="let element"> {{element.advt_count}} </td>
            </ng-container>

            <ng-container matColumnDef="DS_ADVTID">
              <th mat-header-cell *matHeaderCellDef> Advt ID </th>
              <td mat-cell *matCellDef="let element"> {{element.DS_ADVTID}} </td>
            </ng-container>

            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef> Name </th>
              <td mat-cell *matCellDef="let element"> {{element.name}} </td>
            </ng-container>

            <ng-container matColumnDef="rep_name">
              <th mat-header-cell *matHeaderCellDef> Reprensantive Name </th>
              <td mat-cell *matCellDef="let element"> {{element.rep_name}} </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="off_rep_name">
              <th mat-header-cell *matHeaderCellDef> OffReprensantive Name </th>
              <td mat-cell *matCellDef="let element"> {{element.off_rep_name}} </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="booking_date">
              <th mat-header-cell *matHeaderCellDef> Booking Date </th>
              <td mat-cell *matCellDef="let element"> {{element.booking_date}} </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="booking_no">
              <th mat-header-cell *matHeaderCellDef> Booking No </th>
              <td mat-cell *matCellDef="let element"> {{element.booking_no}} </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="DS_ISSUENAME">
              <th mat-header-cell *matHeaderCellDef> Issue Name</th>
              <td mat-cell *matCellDef="let element"> {{element.DS_ISSUENAME}} </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="DS_ISSUEDATE">
              <th mat-header-cell *matHeaderCellDef> Issue Date</th>
              <td mat-cell *matCellDef="let element"> {{element.DS_ISSUEDATE}} </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="DS_advtsize">
              <th mat-header-cell *matHeaderCellDef>Advt Size </th>
              <td mat-cell *matCellDef="let element"> {{element.DS_advtsize}} </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="distribution_rep">
              <th mat-header-cell *matHeaderCellDef> Distribution Representative Name </th>
              <td mat-cell *matCellDef="let element"> {{element.distribution_rep}} </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="temp_rcpt_no">
              <th mat-header-cell *matHeaderCellDef> Temp Receipt No</th>
              <td mat-cell *matCellDef="let element"> {{element.temp_rcpt_no}} </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="temp_rcpt_date">
              <th mat-header-cell *matHeaderCellDef> Date </th>
              <td mat-cell *matCellDef="let element"> {{element.temp_rcpt_date}} </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="rep_name">
              <th mat-header-cell *matHeaderCellDef> Representative Name</th>
              <td mat-cell *matCellDef="let element"> {{element.rep_name}} </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="office_rep_name">
              <th mat-header-cell *matHeaderCellDef> Office Representative Name </th>
              <td mat-cell *matCellDef="let element"> {{element.office_rep_name}} </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="total_amt">
              <th mat-header-cell *matHeaderCellDef> Total Amount </th>
              <td mat-cell *matCellDef="let element"> {{element.total_amt}} </td>
              <td mat-footer-cell *matFooterCellDef>{{getTotalTotalAmount()}}</td>
            </ng-container>

            <ng-container matColumnDef="discount">
              <th mat-header-cell *matHeaderCellDef> Discount </th>
              <td mat-cell *matCellDef="let element"> {{element.discount}} </td>
              <td mat-footer-cell *matFooterCellDef>{{getTotalDiscountAmount()}}</td>
            </ng-container>

            <ng-container matColumnDef="net_amt">
              <th mat-header-cell *matHeaderCellDef> Net Amount </th>
              <td mat-cell *matCellDef="let element"> {{element.net_amt}} </td>
              <td mat-footer-cell *matFooterCellDef> {{getTotalNetAmount()}} </td>
            </ng-container>

            <ng-container matColumnDef="rep_com_amt">
              <th mat-header-cell *matHeaderCellDef> Rrpresentative Commission </th>
              <td mat-cell *matCellDef="let element"> {{element.rep_com_amt}}</td>
              <td mat-footer-cell *matFooterCellDef>{{getTotalRepComAmount()}}</td>
            </ng-container>

            <ng-container matColumnDef="crdt_voucher_no">
              <th mat-header-cell *matHeaderCellDef> Voucher No </th>
              <td mat-cell *matCellDef="let element"> {{element.crdt_voucher_no}} </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="crdt_voucher_entry_date">
              <th mat-header-cell *matHeaderCellDef> Voucher Entry Date </th>
              <td mat-cell *matCellDef="let element"> {{element.crdt_voucher_entry_date}} </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="magzine_edition">
              <th mat-header-cell *matHeaderCellDef> Edition </th>
              <td mat-cell *matCellDef="let element"> {{element.magzine_edition}} </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="remark">
              <th mat-header-cell *matHeaderCellDef> Remark </th>
              <td mat-cell *matCellDef="let element"> {{element.remark}} </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="remark1">
              <th mat-header-cell *matHeaderCellDef> Remark1 </th>
              <td mat-cell *matCellDef="let element"> {{element.remark1}} </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="remark2">
              <th mat-header-cell *matHeaderCellDef> Remark2 </th>
              <td mat-cell *matCellDef="let element"> {{element.remark2}} </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="rcptstopdate">
              <th mat-header-cell *matHeaderCellDef> Receipt Stop Date </th>
              <td mat-cell *matCellDef="let element"> {{element.rcptstopdate}} </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="rcptstopremark">
              <th mat-header-cell *matHeaderCellDef> Receipt Stop Remark </th>
              <td mat-cell *matCellDef="let element"> {{element.rcptstopremark}} </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="rcptstartdate">
              <th mat-header-cell *matHeaderCellDef> Receipt Start Date</th>
              <td mat-cell *matCellDef="let element"> {{element.rcptstartdate}} </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="rcptstartremark">
              <th mat-header-cell *matHeaderCellDef> Receipt Start Remark </th>
              <td mat-cell *matCellDef="let element"> {{element.rcptstartremark}} </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="action">
              <th colspan="2" mat-header-cell *matHeaderCellDef> Action </th>
              <td colspan="2" mat-cell *matCellDef="let element">
                <button *ngIf="element['is_active'] == 'N'" class="btn btn-primary" mat-raised-button type="button"
                  (click)="activeReceipt(element)">
                  Active
                </button>
                <button class="btn btn-danger" mat-raised-button type="button" (click)="returnReceipt(element)">
                  Return
                </button>
              </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true"></tr>
          </table>
        </div> -->

        <!-- <mat-paginator (page)="handlePageEvent($event)" [length]="length" [pageSizeOptions]="[500, 1000, 2000,5000]"
          showFirstLastButtons aria-label="Select page of periodic elements">
        </mat-paginator> -->
      </mat-card>
    </mat-tab>

    <mat-tab label="Advt Invoice Register">
      <mat-card class="card">
        <form [formGroup]="advtinvoiceForm">
          <div class="form-row">
            <mat-form-field style="margin-left: 7%; margin-top: 10px; width: 40%" appearance="outline">
              <mat-label>Select Date</mat-label>
              <mat-date-range-input [formGroup]="advtinvoiceForm" [rangePicker]="advtInvoiceR">
                <input matStartDate formControlName="start" placeholder="Start date" />
                <input matEndDate formControlName="end" placeholder="End date"
                  (dateChange)="getChangedDateAdvtInvoice()" />
              </mat-date-range-input>
              <mat-datepicker-toggle matSuffix [for]="advtInvoiceR"></mat-datepicker-toggle>
              <mat-date-range-picker #advtInvoiceR></mat-date-range-picker>
              <mat-error *ngIf="
                                  advtinvoiceForm.controls.start.hasError(
                                    'matStartDateInvalid'
                                  )
                                ">Invalid start date
              </mat-error>
              <mat-error *ngIf="
                                  advtinvoiceForm.controls.end.hasError('matEndDateInvalid')
                                ">Invalid end date
              </mat-error>
            </mat-form-field>
          </div>

          <div class="form-row">
            <mat-form-field style="margin-left: 7%; margin-top: 10px; width: 40%" appearance="outline">
              <mat-label>Issue/grantha : </mat-label>
              <mat-select (selectionChange)="getIssuesAdvtNames($event)" formControlName="issuename" required>
                <mat-option *ngFor="let item of advtCategorylist" value="{{ item.category }}">
                  {{ item.category }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field style="margin-left: 20px; margin-top: 10px; width: 40%" appearance="outline">
              <mat-label>Issue Dates : </mat-label>
              <mat-select #mySel4 (selectionChange)="getAdvtNameDates($event)" formControlName="issuedate" multiple
                required>
                <!-- <mat-option value="" (click)="toggleAllSelection4()">All</mat-option> -->
                <mat-option *ngFor="let item of getMagIssueDatesandAdvtNames" value="{{ item.id }}">{{
                  item["issueDate"] | date : "dd-MM-yyyy" }}
                  {{ item["issueName"] }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="form-row">
            <mat-form-field style="margin-left: 7%; width: 40%;" appearance="outline">
              <mat-label>Report Type</mat-label>
              <mat-select (selectionChange)="getAdvtInvoiceReportType($event)">
                <mat-option value="In Detail">In Detail
                </mat-option>
                <mat-option value="Briefly">Briefly
                </mat-option>
                <mat-option value="Monthwise">Monthwise
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="form-group">
            <button style="margin-left: 125px; width: 70%; margin-top: 10px" type="submit"
              (click)="openAdvtInvoiceRegister()" mat-raised-button color="primary">
              OK
            </button>
          </div>
        </form>
      </mat-card>
    </mat-tab>

    <!-- create advance stamp Receipt and Register -->
    <mat-tab label="Advt Advance Stamp Receipt">
      <mat-card class="card">
        <form class="form" [formGroup]="advtAdvanceStampform">
          <div class="form-row">
            <mat-form-field appearance="outline" style="width: 40%; margin-left: 5%">
              <mat-label>Today Date</mat-label>
              <input matInput type="text" formControlName="date" />
            </mat-form-field>

            <mat-form-field appearance="outline" style="width: 40%; margin-left: 5%">
              <mat-label>Receipt Date</mat-label>
              <input matInput [matDatepicker]="startDatePicker70" (dateChange)="GenerateAdvanceStampno()"
                formControlName="recptdate" />
              <mat-datepicker-toggle matSuffix [for]="startDatePicker70"></mat-datepicker-toggle>
              <mat-datepicker #startDatePicker70></mat-datepicker>
            </mat-form-field>

          </div>

          <div class="form-row">
            <mat-form-field appearance="outline" style="margin-left: 5%; width: 40%;">
              <mat-label>Representative Name : </mat-label>
              <mat-select #mySel1 (selectionChange)="getContractRepName($event)">
                <mat-option value="ALL" (click)="toggleAllSelection()">All</mat-option>
                <mat-option *ngFor="let item of contractRepName" value="{{  item['name'] }}">{{ item["name"]
                  }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field *ngIf="!deleterec" appearance="outline" style="width: 40%; margin-left: 5%">
              <mat-label>Advance Stamp Number</mat-label>
              <input matInput type="text" formControlName="crdtvno" />
            </mat-form-field>
            <mat-form-field *ngIf="deleterec" appearance="outline" style="width: 40%; margin-left: 5%">
              <mat-label>Advance Stamp Number(Deleted)</mat-label>
              <mat-select formControlName="crdtvno">
                <mat-option *ngFor="let stamp of getdeleterecitstamp" [value]="stamp.rcpt_no">
                  {{ stamp.rcpt_no }}
                </mat-option>
              </mat-select>
            </mat-form-field>

          </div>

          <div class="form-row">
            <mat-form-field class="example-full-width" appearance="outline" class="example-full-width"
              style="width: 40%; margin-left: 5%;">
              <mat-label>Advertiser Name :</mat-label>
              <input #myInput matInput formControlName="advtname" (keyup)="getAdvtNames($event)"
                [matAutocomplete]="auto1" />
              <mat-autocomplete #auto1="matAutocomplete">
                <mat-option (onSelectionChange)="selected_name1($event, option)" *ngFor="let option of advt_names"
                  [value]="option['name']">
                  {{ option["name"] }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>

            <!-- <mat-form-field class="example-full-width" appearance="outline" style="width: 25%; margin-right: 3%">
                <mat-label>Advertiser Name :</mat-label>
                <input matInput formControlName="advtName" (keyup)="getAdvtNames($event)" [matAutocomplete]="auto1" />
                <mat-autocomplete autoActiveFirstOption #auto1="matAutocomplete">
                  <mat-option (onSelectionChange)="selected_name1($event, option)" *ngFor="let option of advt_names"
                    [value]="option['name']">
                    {{ option["name"] }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field> -->
            <h3 style="padding-top: 10px;margin-left: 5%;padding-right: 20px;">Select receipt is New/Delete</h3>
            <mat-button-toggle-group formControlName="newordeleted" (change)="newordelstamp($event)" name="fontStyle"
              aria-label="Font Style" style="height: 50px;">
              <mat-button-toggle value="new">New</mat-button-toggle>
              <mat-button-toggle value="deleted">Deleted</mat-button-toggle>
            </mat-button-toggle-group>

          </div>
          <div>
            <button type="button" mat-raised-button color="primary" style="margin-left: 5%" (click)="addadvtCNrcpt1()">
              Search
            </button>
          </div>

        </form>

        <div style="margin-top: 20px; margin-left: 20px" class="tableFixHead1">
          <table class="table table-bordered table-hover text-center">
            <thead>
              <tr>
                <mat-checkbox class="example-margin" [checked]="allSelectedtable" color="primary"
                  (change)="selectall($event)">
                </mat-checkbox>
                <th>Invoice Date</th>
                <th>Invoice No</th>
                <th>Advt. Name</th>
                <th>Invoice Amount</th>
                <!-- <th>Amount Remaining</th> -->
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let book of advtCreditNote">
                <td>
                  <mat-checkbox [(ngModel)]="book.is_selected" color="primary" (change)="copydata1(book, $event)">
                  </mat-checkbox>
                </td>
                <td>{{ book.invoice_date}}</td>
                <td>{{ book["invoice_no"] }}</td>
                <td>{{ book["advt_name"] }}</td>
                <td>{{ book["advt_amt"] }}</td>
                <!-- <td>{{ book["remain_amt"] }}</td> -->
                <td style="display: flex;">
                  <!-- <button [(disabled)]="this.click" type="button" mat-raised-button color="primary"
                      (click)="openAdvtBill4(book)">
                      Select
                    </button> -->
                  <!-- <button style="margin-left: 2%" type="button" mat-raised-button class="btn btn-info"
                      (click)="updateIssueAnk(book)">
                      Update
                    </button> -->
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="form-group" style="display: flex; justify-content: center;">
          <button style="
                      width: 60%;
                      margin-top: 20px;
                    " type="button" mat-raised-button color="primary" class="btn btn-primary btn-block"
            (click)="openAdvtBill4()">
            Add
          </button>
        </div>

      </mat-card>

      <mat-card>
        <h1 style="padding-top: 20px; text-align: center; font-weight: 500;">Advt Stamp Receipt List</h1>

        <form [formGroup]="stampgetdata">
          <div class="form-row">
            <mat-form-field style="margin-left: 7%; width: 30%" appearance="outline">
              <mat-label>Select Date</mat-label>
              <mat-date-range-input [rangePicker]="advtInvoiceR1">
                <input matStartDate formControlName="start" placeholder="Start date" />
                <input matEndDate formControlName="end" placeholder="End date" (dateChange)="getdateforstamp()" />
              </mat-date-range-input>
              <mat-datepicker-toggle matSuffix [for]="advtInvoiceR1"></mat-datepicker-toggle>
              <mat-date-range-picker #advtInvoiceR1></mat-date-range-picker>
            </mat-form-field>

            <mat-form-field class="example-full-width" appearance="outline" class="example-full-width"
              style="width: 30%; margin-left: 5%;">
              <mat-label>Advertiser Name :</mat-label>
              <input #myInput matInput formControlName="advtname" (keyup)="getAdvtNames($event)"
                [matAutocomplete]="auto2" />
              <mat-autocomplete #auto2="matAutocomplete">
                <mat-option (onSelectionChange)="selected_name2($event)" *ngFor="let option of advt_names"
                  [value]="option['name']">
                  {{ option["name"] }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
        </form>

        <table class="table table-bordered table-hover text-center">
          <thead>
            <tr>
              <th>Sr. No</th>
              <!-- <th>Creation Date</th> -->
              <th>Receipt Date</th>
              <th>Receipt No</th>
              <th>Advt. Name</th>
              <th>Amount</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let book of stampdata; let i = index">
              <td>{{i+1}}</td>
              <td>{{ book.receipt_date | date: "dd-MM-yyy" }}</td>
              <td>{{ book["receipt_no"] }}</td>
              <td>{{ book["advt_name"] }}</td>
              <td>{{ book["amt"] }}</td>
              <!-- <td>{{ book["remain_amt"] }}</td> -->
              <td style="display: block;">
                <!-- <button [(disabled)]="this.click" type="button" mat-raised-button color="primary"
                    (click)="openAdvtBill4(book)">
                    Select
                  </button> -->
                <button mat-raised-button type="button" class="
              btn btn-info" (click)="opentwostampreceipt(book)">Print</button>

                <button style="margin-left: 2%" type="button" mat-raised-button class="btn btn-info"
                  (click)="deletestamp(book)">
                  Delete
                </button>
              </td>
            </tr>
          </tbody>
        </table>

      </mat-card>
    </mat-tab>



    <!-- Advt credit note recipt -->
    <mat-tab label="Advt Credit Note Receipt">
      <mat-card class="card">
        <form class="form" [formGroup]="advtCreditNoteform">
          <div class="form-row">
            <mat-form-field appearance="outline" style="width: 40%; margin-left: 5%">
              <mat-label>Today Date</mat-label>
              <input matInput type="text" formControlName="date" />
            </mat-form-field>
            <!-- <mat-form-field class="example-full-width" style="width: 40%; margin-left: 5%;">
              <mat-label>Advertiser Name :</mat-label>
              <input #myInput matInput formControlName="advtname" (keyup)="getAdvtNames($event)"
                [matAutocomplete]="mc" required/>
              <mat-autocomplete #mc="matAutocomplete">
                <mat-option (selectionChange)="getContractOffRepName($event )"
                  *ngFor="let option of filteredOptions | async" [value]="option['name']">
                  {{ option["name"] }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field> -->
            <mat-form-field class="example-full-width" appearance="outline" style="width: 40%; margin-left: 5%">
              <mat-label>Advertiser Name :</mat-label>
              <!-- formControlName="sadvtname" -->
              <input matInput formControlName="advtname" (keyup)="getAdvtNames($event)" [matAutocomplete]="auto1"
                required />
              <mat-autocomplete autoActiveFirstOption #auto1="matAutocomplete">
                <mat-option (onSelectionChange)="selected_name1($event, option)" *ngFor="let option of advt_names"
                  [value]="option['name']">
                  {{ option["name"] }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
          <div class="form-row">


            <!-- <mat-form-field appearance="outline" style="width: 40%; margin-left: 5%">
              <mat-label>Receipt Date</mat-label>
              <input matInput type="date" (change)="GenerateCRno()" formControlName="recptdate"  />
            </mat-form-field> -->
            <mat-form-field appearance="outline" style="width: 40%; margin-left: 5%">
              <mat-label>Credit Note Date</mat-label>
              <input matInput [matDatepicker]="startDatePicker07" (dateChange)="GenerateCRno()"
                formControlName="recptdate" required />
              <mat-datepicker-toggle matSuffix [for]="startDatePicker07"></mat-datepicker-toggle>
              <mat-datepicker #startDatePicker07></mat-datepicker>
            </mat-form-field>


            <h3 style="padding-top: 10px;margin-left: 5%;padding-right: 20px;">Select receipt is New/Delete</h3>
            <mat-button-toggle-group formControlName="newordeleted" (change)="newordelcn($event)" name="fontStyle"
              aria-label="Font Style" style="height: 50px;">
              <mat-button-toggle value="new">New</mat-button-toggle>
              <mat-button-toggle value="deleted">Deleted</mat-button-toggle>
            </mat-button-toggle-group>


            <!-- <mat-form-field appearance="outline" style="width: 40%; margin-left: 5%">
              <mat-label>Credit Note No</mat-label>
              <input matInput type="text" formControlName="crdtvno" required />
            </mat-form-field> -->


            <mat-form-field *ngIf="!deleterec" appearance="outline" style="width: 40%; margin-left: 5%">
              <mat-label>Credit Note No</mat-label>
              <input matInput type="text" formControlName="crdtvno" />
            </mat-form-field>
            <mat-form-field *ngIf="deleterec" appearance="outline" style="width: 40%; margin-left: 5%">
              <mat-label>Credit Note No(Deleted)</mat-label>
              <mat-select formControlName="crdtvno">
                <mat-option *ngFor="let cn of getdeleterecitcn" [value]="cn.rcpt_no">
                  {{ cn.rcpt_no }}
                </mat-option>
              </mat-select>
            </mat-form-field>



            <!-- <mat-form-field style="margin-left: 5%; width: 40%;">
              <mat-label>Representative Name : </mat-label>
              <mat-select #mySel1 multiple>
                <mat-option value="all" (click)="toggleAllSelection()">All</mat-option>
                <mat-option *ngFor="let item of getdeleterecitcn" value="{{  item['rcpt_no'] }}">{{ item["rcpt_no"]
                  }}
                </mat-option>
              </mat-select>
            </mat-form-field> -->
          </div>
          <div class="form-row">
            <!-- <mat-form-field style="margin-left: 5%; width: 40%;">
              <mat-label>Representative Name : </mat-label>
              <mat-select #mySel1 (selectionChange)="getContractRepName($event)" formControlName="creditRepName" multiple>
                <mat-option value="all" (click)="toggleAllSelection()">All</mat-option>
                <mat-option *ngFor="let item of advtnewreplistForm" value="{{  item['rep_name'] }}">{{ item["rep_name"]
                  }}
                </mat-option>
              </mat-select>
            </mat-form-field> -->
            <!-- <mat-form-field appearance="outline" style="width: 40%; margin-left: 5%">
              <mat-label>Receipt No</mat-label>
              <input matInput type="text" formControlName="CrNno" readonly />
            </mat-form-field> -->

          </div>
          <div class="form-group">

            <button type="button" [disabled]="!advtCreditNoteform.value.recptdate" mat-raised-button color="primary"
              style="margin-left: 5%" (click)="addadvtCNrcpt()">
              Search
            </button>
          </div>
          <div style="margin-top: 20px; margin-left: 20px" class="tableFixHead1">
            <table class="table table-bordered table-hover text-center">
              <thead>
                <tr>
                  <th>Invoice Date</th>
                  <th>Invoice No</th>
                  <th>Rep Name</th>
                  <th>Advt. Name</th>
                  <th>Invoice Amount</th>
                  <th>Amount Remaining</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let book of advtCreditNote">
                  <td>{{ book.invoice_date }}</td>
                  <td>{{ book["invoice_no"] }}</td>
                  <td>{{ book["rep_name"] }}</td>
                  <td>{{ book["advt_name"] }}</td>
                  <td>{{ book["advt_amt"] }}</td>
                  <td>{{ book["remain_amt"] }}</td>
                  <td style="display: flex;">
                    <button [(disabled)]="this.click" type="button" mat-raised-button color="primary"
                      (click)="openAdvtBill1(book)">
                      Select
                    </button>
                    <!-- <button style="margin-left: 2%" type="button" mat-raised-button class="btn btn-info"
                      (click)="updateIssueAnk(book)">
                      Update
                    </button> -->
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="form-group" style="display: flex; justify-content: center;">
            <button style="
                      width: 60%;
                      margin-top: 20px;
                    " type="button" mat-raised-button color="primary" class="btn btn-primary btn-block"
              (click)="openAdvtBill3()">
              Add
            </button>
          </div>
        </form>
      </mat-card>
    </mat-tab>

    <mat-tab label="Advt Credit Note Register">
      <mat-card style="padding: 30px 0px;">
        <form [formGroup]="CreditNoteForm">
          <div class="form-row">
            <mat-form-field style="margin-left: 7%; width: 35%;">
              <mat-label>Enter Date</mat-label>
              <mat-date-range-input [rangePicker]="pickerrCN">
                <input matStartDate formControlName="start" placeholder="Start date" />
                <input matEndDate formControlName="end" placeholder="End date"
                  (dateChange)="getChangedDateContractM2()" />
              </mat-date-range-input>
              <mat-datepicker-toggle matSuffix [for]="pickerrCN"></mat-datepicker-toggle>
              <mat-date-range-picker #pickerrCN></mat-date-range-picker>

              <mat-error *ngIf="contractDetailsForm.controls.start.hasError('matStartDateInvalid')">Invalid start
                date</mat-error>
              <mat-error *ngIf="
                                  contractDetailsForm.controls.end.hasError('matEndDateInvalid')
                                ">Invalid end date
              </mat-error>
            </mat-form-field>

            <mat-form-field style="margin-left: 3%; width: 35%;">
              <mat-label>Representative Name : </mat-label>
              <mat-select #mySel1 (selectionChange)="getContractRepName($event)" multiple>
                <mat-option value="ALL" (click)="toggleAllSelection()">All</mat-option>
                <mat-option *ngFor="let item of contractRepName" value="{{  item['name'] }}">{{ item["name"]
                  }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="form-row">
            <mat-form-field style="margin-left: 7%; width: 35%;">
              <mat-label>Office Representative : </mat-label>
              <mat-select #mySel1 (selectionChange)="getContractOffRepName($event)" multiple>
                <mat-option value="ALL" (click)="toggleAllSelection()">All</mat-option>
                <mat-option *ngFor="let item of pp_off_rep_names" value="{{  item['off_rep_name'] }}">{{
                  item["off_rep_name"]
                  }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field style="margin-left: 3%; width: 35%;">
              <mat-label>Issue Categary : </mat-label>
              <mat-select #mySel1 (selectionChange)="getContractAdvtName($event)" multiple>
                <mat-option value="ALL" (click)="toggleAllSelection()">All</mat-option>
                <mat-option *ngFor="let item of advtCategorylist" value="{{  item['category'] }}">{{ item["category"]
                  }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="form-row">
            <mat-form-field style="margin-left: 7%; width: 35%;">
              <mat-label>Select type of report</mat-label>
              <mat-select #mySel1 (selectionChange)="getContractReportType($event)">
                <!-- <mat-option value="all" (click)="toggleAllSelection()">All</mat-option> -->
                <mat-option value="InDetailCreditNote">In Detail
                </mat-option>
                <mat-option value="CreditNotebriefly">Briefly
                </mat-option>
                <mat-option value="CreditMonthwise">Monthwise
                </mat-option>
                <mat-option value="InDetail-CreditOfficer">InDetail-Officer
                </mat-option>
                <mat-option value="VeryBriefly-CreditNote">VeryBriefly-Officer
                </mat-option>
                <mat-option value="Briefly-OfficerCredit">Briefly-Officer
                </mat-option>
              </mat-select>
            </mat-form-field>
            <div style="margin-left: 3%;">
              <button mat-raised-button type="button" class="
              btn btn-info" (click)="showContractReport()">Search</button>
            </div>
          </div>
        </form>
        <div class="tableFixHead">
          <table class="table table-striped">
            <thead>
              <tr>
                <th>
                  <mat-checkbox class="example-margin" [checked]="allSelectedtable" color="primary"
                    (change)="selectall($event)">
                  </mat-checkbox>
                </th>
                <th>Credit Note No</th>
                <th>CR Date</th>
                <th>Advt. Name</th>
                <th>Rep. Name</th>
                <th>Off. Rep. Name</th>
                <th>Issue Name</th>
                <th>Issue Date</th>
                <th>Invoice Number</th>
                <th>Invoice Date</th>
                <th>Net Amount</th>
                <th>Received Amount</th>
                <th>Remark</th>
                <th>Action</th>

              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let item of advtcreditnotetRegiInDetailsFormBeans">

                <tr>
                  <td>
                    <mat-checkbox [(ngModel)]="item.is_selected" color="primary" (change)="copydata2(item, $event)">
                    </mat-checkbox>
                  </td>
                  <td>{{ item['credit_voc_no'] }}</td>
                  <td>{{ item['voc_date'] }}</td>
                  <td>{{ item['advt_name'] }}</td>
                  <td>{{ item['rep_name'] }}</td>
                  <td>{{ item['office_rep'] }}</td>
                  <td>{{ item['DS_ISSUENAME'] }}</td>
                  <td>{{ item['DS_ISSUEDATE'] }}</td>
                  <td>{{ item["advt_invoice_no"] }}</td>
                  <td>{{ item["advt_invoice_date"] }}</td>
                  <td>{{ item['net_amt'] }}</td>
                  <td>{{ item['credit_v_amt'] }}</td>
                  <td>{{ item['remark'] }}</td>
                  <td>
                    <!-- <button style="margin-left: 2%" type="button" mat-raised-button class="btn btn-info"
                      (click)="updateIssueAnk(item)">
                      Update
                    </button> -->

                    <button mat-icon-button class="btn-sm btn-outline-danger" matTooltip="Delete"
                      style="margin-left: 2%;"
                      (click)="deleteCreditNote(item['id'])"><mat-icon>delete</mat-icon></button>
                  </td>

                </tr>
              </ng-container>

            </tbody>

          </table>
        </div>
      </mat-card>
    </mat-tab>

    <mat-tab label="GST Ledger">
      <mat-card style="margin-top: 2%;">
        <form [formGroup]="GSTFullReportForm" class="mt-5">
          <div class="form-row">
            <mat-form-field style="margin-left: 7%; width: 35%;">
              <mat-label>Enter Date</mat-label>
              <mat-date-range-input [rangePicker]="pickergst">
                <input matStartDate formControlName="start" placeholder="Start date" />
                <input matEndDate formControlName="end" placeholder="End date"
                  (dateChange)="getChangedDateContractM4()" />
              </mat-date-range-input>
              <mat-datepicker-toggle matSuffix [for]="pickergst"></mat-datepicker-toggle>
              <mat-date-range-picker #pickergst></mat-date-range-picker>

              <mat-error *ngIf="
                                  contractDetailsForm.controls.start.hasError(
                                    'matStartDateInvalid'
                                  )
                                ">Invalid start date
              </mat-error>
              <mat-error *ngIf="
                                  contractDetailsForm.controls.end.hasError('matEndDateInvalid')
                                ">Invalid end date
              </mat-error>
            </mat-form-field>
            <mat-form-field style="margin-left: 7%; width: 35%;">
              <mat-label>Select type of report</mat-label>
              <mat-select #mySel1 (selectionChange)="getContractReportType($event)">
                <mat-option value="GSTFullReport">GST Full Report
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>


          <div class="form-row">

            <!-- <div style="margin-left: 3%;">
              <button mat-raised-button type="button" class="
              btn btn-info" (click)="showContractReport()">Search</button>
            </div> -->
            <div style="display: flex; justify-content: center; margin-left: 45%;">
              <button mat-raised-button type="button" class="btn btn-info"
                (click)="showContractReport()">Search</button>
            </div>

          </div>
        </form>
        <div class="tableFixHead">

        </div>
      </mat-card>
    </mat-tab>

    <mat-tab label="Advt. Outstanding Reports">
      <mat-card style="margin-top: 2%; margin-top: 2%;height: 400px;padding-top: 30px;">
        <h3 class="text-center" style="font-size: 25px; color: black; margin-bottom: 20px;">Advt Outstanding Reports
        </h3>

        <form [formGroup]="OutStandingForm">
          <div class="form-row">
            <mat-form-field style="margin-left: 10%; width: 35%;">
              <mat-label>Enter Date</mat-label>
              <mat-date-range-input [rangePicker]="pickerOT">
                <input matStartDate formControlName="start" placeholder="Start date" />
                <input matEndDate formControlName="end" placeholder="End date"
                  (dateChange)="getChangedDateContractM3()" />
              </mat-date-range-input>
              <mat-datepicker-toggle matSuffix [for]="pickerOT"></mat-datepicker-toggle>
              <mat-date-range-picker #pickerOT></mat-date-range-picker>
              <mat-error *ngIf="
                                  contractDetailsForm.controls.start.hasError(
                                    'matStartDateInvalid'
                                  )
                                ">Invalid start date
              </mat-error>
              <mat-error *ngIf="
                                  contractDetailsForm.controls.end.hasError('matEndDateInvalid')
                                ">Invalid end date
              </mat-error>
            </mat-form-field>

            <!-- <mat-form-field style="margin-left: 3%; width: 35%;">
              <mat-label>Representative Name : </mat-label>
              <input matInput formControlName="repname"/>
              <mat-select #mySel1 (selectionChange)="getContractRepName($event)" multiple>
                <mat-option value="ALL" (click)="toggleAllSelection()">All</mat-option>
                <mat-option *ngFor="let item of contractRepName" value="{{  item['name'] }}">{{ item["name"]
                  }}
                </mat-option>
              </mat-select>
            </mat-form-field> -->
            <!-- <mat-form-field style="margin-left: 3%; width: 35%;">
              <mat-label>Representative Name : </mat-label>
              <input formControlName="repname" matInput [matAutocomplete]="autorepnames"  (keyup)="getRepNames($event)"/>
              <mat-autocomplete autoActiveFirstOption #autorepnames="matAutocomplete">
                <mat-option (onSelectionChange)="getContractRepNames($event)"
                  *ngFor="let option of filterrep" [value]="option['rep_name']">
                  {{ option["rep_name"] }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field> -->
            <mat-form-field style="margin-left: 3%; width: 35%;">
              <mat-label>Representative Name : </mat-label>
              <input matInput formControlName="repname" [matAutocomplete]="autorepnames3" (keyup)="getRepNames($event)"
                multiple />
              <mat-autocomplete autoActiveFirstOption #autorepnames3="matAutocomplete">
                <mat-option value="ALL" (click)="toggleAllSelection()">All</mat-option>

                <mat-option (onSelectionChange)="getContractRepNames($event) " *ngFor="let option of filterrep"
                  [value]="option['rep_name']">
                  {{ option["rep_name"] }}
                </mat-option>
              </mat-autocomplete>
              <!-- <mat-select #mySel1 (selectionChange)="getContractRepNames($event)"  multiple>
                <mat-option value="ALL" (click)="toggleAllSelection()">All</mat-option>
                <mat-option  
                *ngFor="let option of filterrep" [value]="option['rep_name']">
                {{ option["rep_name"] }}
              </mat-option>
              </mat-select> -->
            </mat-form-field>
          </div>
          <div class="form-row">
            <mat-form-field style="margin-left: 10%; width: 35%;">
              <mat-label>Office Representative : </mat-label>
              <mat-select #mySel1 formControlName="offrepname" (selectionChange)="getContractOffRepName($event)"
                multiple>
                <mat-option value="ALL" (click)="toggleAllSelection()">All</mat-option>
                <mat-option *ngFor="let item of pp_off_rep_names" value="{{  item['off_rep_name'] }}">{{
                  item["off_rep_name"]
                  }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field style="margin-left: 3%; width: 35%;">
              <mat-label>Issue Categary : </mat-label>
              <mat-select formControlName="issuecate" #mySel1 (selectionChange)="getContractAdvtName($event)" multiple>
                <mat-option value="ALL" (click)="toggleAllSelection()">All</mat-option>
                <mat-option *ngFor="let item of advtCategorylist" value="{{  item['category'] }}">{{ item["category"]
                  }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="form-row">
            <mat-form-field style="margin-left: 10%; width: 35%;">
              <mat-label>Advertiser Name : </mat-label>
              <mat-select formControlName="advtname" #mySel1 (selectionChange)="getContractAdvtName($event)" multiple>
                <mat-option value="ALL" (click)="toggleAllSelection()">All</mat-option>
                <mat-option *ngFor="let item of newadvtname" value="{{  item['name'] }}">{{ item["name"]
                  }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field style="margin-left: 3%; width: 35%;">
              <mat-label>Select type of report</mat-label>
              <mat-select #mySel1 (selectionChange)="getContractReportType($event)">
                <mat-option value="AdvtoutstandingInDetails">All Outstanding In Detail
                </mat-option>
                <mat-option value="Advtoutstandingbriefly">All Briefly
                </mat-option>
                <mat-option value="unpaidOutstandingInDetails">Unpaid OutStanding In Detail
                </mat-option>
                <mat-option value="unpaidOutstandingbriefly">Unpaid OutStanding Briefly
                </mat-option>
                <mat-option value="creditdOutstandingindetails">Credit OutStanding In Detail
                </mat-option>
                <mat-option value="creditOutstandingbriefly">Credit OutStanding Briefly
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="form-row">

            <div style="margin-left: 43%;margin-top: 15px;">
              <button mat-raised-button type="button" class="
              btn btn-info" color="primary" (click)="showContractReport()">Search</button>
            </div>
          </div>
        </form>
        <div class="tableFixHead">

        </div>
      </mat-card>

      <mat-card class="card">
        <h3 class="text-center" style="font-size: 25px; color: black; margin-bottom: 20px;">Send Reminder Letter</h3>
        <form class="form" [formGroup]="Sendreminderform">
          <div class="form-row">
            <mat-form-field style="margin-left: 10%; width: 35%;">
              <mat-label>Enter Date</mat-label>
              <mat-date-range-input [rangePicker]="pickerSendreminder">
                <input matStartDate formControlName="startDate" placeholder="Start date" />
                <input matEndDate formControlName="endDate" placeholder="End date"
                  (dateChange)="getChangedDateAdvtRemainder()" />
              </mat-date-range-input>
              <mat-datepicker-toggle matSuffix [for]="pickerSendreminder"></mat-datepicker-toggle>
              <mat-date-range-picker #pickerSendreminder></mat-date-range-picker>

              <mat-error *ngIf="
                                  contractDetailsForm.controls.start.hasError(
                                    'matStartDateInvalid'
                                  )
                                ">Invalid start date
              </mat-error>
              <mat-error *ngIf="
                                  contractDetailsForm.controls.end.hasError('matEndDateInvalid')
                                ">Invalid end date
              </mat-error>
            </mat-form-field>

            <mat-form-field style="margin-left: 10%; width: 35%;">
              <mat-label>Select type of report</mat-label>
              <mat-select #mySel1 (selectionChange)="getContractReportType($event)">
                <!-- <mat-option value="all" (click)="toggleAllSelection()">All</mat-option> -->
                <mat-option value="send-reminder1">To User
                </mat-option>
                <mat-option value="send-reminder2">To Reprensantive
                </mat-option>
              </mat-select>
            </mat-form-field>


          </div>
          <div class="form-row">
            <mat-form-field style="margin-left: 10%; width: 35%;" *ngIf="contractReportType=='send-reminder2'">
              <mat-label>Representative Name : </mat-label>
              <mat-select #mySel1 (selectionChange)="getContractRepName($event)" formControlName="Repname" multiple>
                <!-- <mat-option value="ALL" (click)="toggleAllSelection()">All</mat-option> -->
                <mat-option *ngFor="let item of contractRepName" value="{{  item['name'] }}">{{ item["name"]
                  }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field style="margin-left: 10%; width: 35%;" *ngIf="contractReportType=='send-reminder1'">
              <mat-label>Advertiser Name : </mat-label>
              <mat-select #mySel1 (selectionChange)="getContractAdvtName($event)" formControlName="Advtname" multiple>
                <!-- <mat-option value="ALL" (click)="toggleAllSelection()">All</mat-option> -->
                <mat-option *ngFor="let item of newadvtname" value="{{  item['name'] }}">{{ item["name"]
                  }}
                </mat-option>
              </mat-select>
            </mat-form-field>


          </div>
          <div class="form-row">

            <button type="button" mat-raised-button color="primary" style="margin-left: 5%"
              (click)="showContractReport()">
              Search
            </button>
            <!-- <mat-form-field style="margin-left: 5%; width: 40%;">
              <mat-label>Representative Name : </mat-label>
              <mat-select #mySel1 (selectionChange)="getContractRepName($event)" formControlName="creditRepName" multiple>
                <mat-option *ngFor="let item of advtnewreplistForm" value="{{  item['rep_name'] }}">{{ item["rep_name"]
                  }}
                </mat-option>
              </mat-select>
            </mat-form-field> -->
            <!-- <mat-form-field appearance="outline" style="width: 40%; margin-left: 5%">
              <mat-label>Receipt No</mat-label>
              <input matInput type="text" formControlName="CrNno" readonly />
            </mat-form-field> -->

          </div>
          <div class="form-group">


          </div>

        </form>
      </mat-card>
    </mat-tab>

    <!-- create a business report -->

    <mat-tab label="Advt Personal Ledger">
      <mat-card class="card">
        <form class="form" [formGroup]="PersonalNoteform">
          <div class="form-row">
            <mat-form-field style="margin-left: 7%; width: 35%;">
              <mat-label>Enter Date</mat-label>
              <mat-date-range-input [rangePicker]="pickerpersonalledger">
                <input matStartDate formControlName="start" placeholder="Start date" />
                <input matEndDate formControlName="end" placeholder="End date"
                  (dateChange)="getChangedDateContractM7()" />
              </mat-date-range-input>
              <mat-datepicker-toggle matSuffix [for]="pickerpersonalledger"></mat-datepicker-toggle>
              <mat-date-range-picker #pickerpersonalledger></mat-date-range-picker>

              <mat-error *ngIf="
                                  contractDetailsForm.controls.start.hasError(
                                    'matStartDateInvalid'
                                  )
                                ">Invalid start date
              </mat-error>
              <mat-error *ngIf="
                                  contractDetailsForm.controls.end.hasError('matEndDateInvalid')
                                ">Invalid end date
              </mat-error>
            </mat-form-field>

            <mat-form-field class="example-full-width" style="width: 40%; margin-left: 5%;">
              <mat-label>Advertiser Name :</mat-label>
              <input #myInput matInput formControlName="advtname" (keyup)="getAdvtNames($event)"
                [matAutocomplete]="advtNames" />
              <mat-autocomplete #advtNames="matAutocomplete">
                <mat-option (selectionChange)="getContractOffRepName($event )" *ngFor="let option of advt_names"
                  [value]="option['name']">
                  {{ option["name"] }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <!-- <mat-form-field class="example-full-width" style="width: 60%; margin-left: 5%; margin-top: 2%">
              <mat-label>Advertiser Name :</mat-label>
              <input #myInput matInput formControlName="sadvtname" (keyup)="getAdvtNames($event)"
                [matAutocomplete]="auto" />
              <mat-autocomplete #auto="matAutocomplete">
                <mat-option (onSelectionChange)="selected_name1($event, option)"
                  *ngFor="let option of filteredOptions | async" [value]="option['name']">
                  {{ option["name"] }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field> -->

          </div>
          <div class="form-row">
            <!-- <mat-form-field appearance="outline" style="width: 40%; margin-left: 5%">
              <mat-label>Credit Note No</mat-label>
              <input matInput type="text" formControlName="crdtvno"  />
            </mat-form-field> -->
            <!-- <mat-form-field appearance="outline" style="width: 40%; margin-left: 5%">
              <mat-label>Receipt Date</mat-label>
              <input matInput type="date" (change)="GenerateCRno()" formControlName="recptdate"  />
            </mat-form-field> -->
            <mat-form-field style="margin-left: 7%; width: 35%;">
              <mat-label>Select type of report</mat-label>
              <mat-select #mySel1 (selectionChange)="getContractReportType($event)">
                <!-- <mat-option value="all" (click)="toggleAllSelection()">All</mat-option> -->
                <mat-option value="InDetail-personalledger">In Detail
                </mat-option>
                <mat-option value="briefly-personalledger">Briefly
                </mat-option>
              </mat-select>
            </mat-form-field>

            <button type="button" mat-raised-button color="primary" style="margin-left: 5%"
              (click)="showPersonalLedgerReport()">
              Search
            </button>
          </div>
          <div class="form-row">
            <!-- <mat-form-field style="margin-left: 5%; width: 40%;">
              <mat-label>Representative Name : </mat-label>
              <mat-select #mySel1 (selectionChange)="getContractRepName($event)" formControlName="creditRepName" multiple>
                <mat-option *ngFor="let item of advtnewreplistForm" value="{{  item['rep_name'] }}">{{ item["rep_name"]
                  }}
                </mat-option>
              </mat-select>
            </mat-form-field> -->
            <!-- <mat-form-field appearance="outline" style="width: 40%; margin-left: 5%">
              <mat-label>Receipt No</mat-label>
              <input matInput type="text" formControlName="CrNno" readonly />
            </mat-form-field> -->

          </div>
          <div class="form-group">


          </div>

        </form>
      </mat-card>
    </mat-tab>

    <mat-tab label="Advt Reprensantive Commission">
      <mat-card style="margin-top: 2%; padding: 40px;">
        <form [formGroup]="CommissionReportForm">
          <div class="form-row">
            <mat-form-field style="margin-left: 7%; width: 35%;">
              <mat-label> Enter Date</mat-label>
              <mat-date-range-input [rangePicker]="pickerrcomm">
                <input matStartDate formControlName="start" placeholder="Start date" />
                <input matEndDate formControlName="end" placeholder="End date"
                  (dateChange)="getChangedDateContractM5()" />
              </mat-date-range-input>
              <mat-datepicker-toggle matSuffix [for]="pickerrcomm"></mat-datepicker-toggle>
              <mat-date-range-picker #pickerrcomm></mat-date-range-picker>

              <mat-error *ngIf="
                                  contractDetailsForm.controls.start.hasError(
                                    'matStartDateInvalid'
                                  )
                                ">Invalid start date
              </mat-error>
              <mat-error *ngIf="
                                  contractDetailsForm.controls.end.hasError('matEndDateInvalid')
                                ">Invalid end date
              </mat-error>
            </mat-form-field>
            <mat-form-field style="margin-left: 3%; width: 35%;">
              <mat-label>Representative Name : </mat-label>
              <mat-select #mySel1 (selectionChange)="getContractRepName($event)" multiple>
                <!-- <mat-option value="ALL" (click)="toggleAllSelection()">All</mat-option> -->
                <mat-option *ngFor="let item of contractRepName" value="{{  item['name'] }}">{{ item["name"]
                  }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="form-row">
            <!-- <mat-form-field style="margin-left: 7%; width: 35%;">
              <mat-label>Advertiser Name : </mat-label>
              <mat-select #mySel1 (selectionChange)="getContractAdvtName($event)" multiple>
                <mat-option value="ALL" (click)="toggleAllSelection()">All</mat-option>
                <mat-option *ngFor="let item of newadvtname" value="{{  item['name'] }}">{{ item["name"]
                  }}
                </mat-option>
                
              </mat-select>
            </mat-form-field> -->


            <mat-form-field style="margin-left: 7%; width: 35%;">
              <mat-label>Select type of report</mat-label>
              <mat-select #mySel1 (selectionChange)="getContractReportType($event)">
                <mat-option value="PendingCommReport">Pending Commission
                </mat-option>
                <mat-option value="CompletedCommReport">Commission Paid
                </mat-option>
              </mat-select>
            </mat-form-field>

            <!-- <mat-form-field style="margin-left: 3%; width: 35%;">
              <mat-label>Issue Categary : </mat-label>
              <mat-select #mySel1 (selectionChange)="getContractAdvtName($event)" multiple>
                <mat-option value="all" (click)="toggleAllSelection()">All</mat-option>
                <mat-option *ngFor="let item of advtCategorylist" value="{{  item['category'] }}">{{ item["category"]
                  }}
                </mat-option>
              </mat-select>
            </mat-form-field> -->
          </div>

          <div>

          </div>


          <div class="form-row" style="margin: 3%;">

            <!-- <div style="margin-left: 3%;">
              <button mat-raised-button type="button" class="
              btn btn-info" (click)="showContractReport()">Search</button>
            </div> -->
            <div style="display: flex; justify-content: center; margin-left: 45%;">
              <button mat-raised-button type="button" class="btn btn-info" (click)="practice()">Search</button>
            </div>
            <div style="display: flex; justify-content: center; margin-left: 20px;">
              <button mat-raised-button type="button" class="btn btn-info" (click)="showContractReport()">Get
                Report</button>
            </div>

          </div>
        </form>
        <!-- <div class="tableFixHead">
         
        </div> -->
      </mat-card>

      <mat-card class="mt-5">

        <table>
          <thead>
            <tr>
              <th colspan="11" class="comm-head">हिंदुस्थान प्रकाशन संस्था-विवेक साप्ताहिक</th>
            </tr>
            <tr>
              <th colspan="8" class="comm-head">Advt Reprensantive Commission</th>
              <th colspan="2" class="comm-head">Date</th>
              <th colspan="1" class="comm-head">Page No</th>
            </tr>

            <tr class="comm-head-2">
              <th class="comm-head-2">Invoice No</th>
              <th>Invoice Date</th>
              <th>Advt Name</th>
              <th>Issue Name</th>
              <th>Issue Date</th>
              <th>Net Amount</th>
              <th>Pending Amount</th>
              <th>Received Amount</th>
              <th>Paid Commission</th>
              <th>Select For Commission</th>
              <th>Select Amount</th>
            </tr>
          </thead>
          <tbody class="comm-head-2" *ngFor="let data of CommissionData; let j = index">
            <!-- <tr>
              <td colspan="11">{{data.rep_name}}</td>
            </tr> -->
            <tr *ngFor="let data1 of data.rep_data; let k = index">
              <td>{{data1.advt_invoice_no}}</td>
              <td>{{data1.advt_invoice_date}}</td>
              <td>{{data1.advt_name}}</td>
              <td>{{data1.DS_ISSUENAME}}</td>
              <td>{{data1.DS_ISSUEDATE}}</td>
              <td>{{data1.net_amount}}</td>
              <td>{{data1.pending_amt}}</td>
              <td>{{data1.rcv_amt}}</td>
              <td>{{data1.com_paid_date}}</td>
              <td *ngIf="data1.com_paid_date==null"><input type="checkbox" (change)="copydata(data1, $event)"></td>
              <td>{{data1.copydata}}</td>
            </tr>
          </tbody>
        </table>
        <div style="display: flex; justify-content: center; margin-top: 30px; margin: 20px;">
          <button mat-raised-button type="button" class="btn btn-info" (click)="openAdvtBill2()">Next</button>
        </div>
      </mat-card>
    </mat-tab>





    <!-- <mat-tab label="Rep.wise Registration Report">
      <mat-form-field style="margin-top: 5%">
        <mat-label>Advt. Received Date</mat-label>
        <mat-date-range-input [formGroup]="RepwiseRegistrationreportRange" [rangePicker]="picker3">
          <input matStartDate formControlName="start" placeholder="Start date" />
          <input matEndDate formControlName="end" placeholder="End date"
            (dateChange)="getChangedDateRepRegistrationReport()" />
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
        <mat-date-range-picker #picker3></mat-date-range-picker>

        <mat-error *ngIf="
            RepwiseRegistrationreportRange.controls.start.hasError(
              'matStartDateInvalid'
            )
          ">Invalid start date
        </mat-error>
        <mat-error *ngIf="
            RepwiseRegistrationreportRange.controls.end.hasError(
              'matEndDateInvalid'
            )
          ">Invalid end date
        </mat-error>
      </mat-form-field>

      <mat-form-field style="margin-left: 3%">
        <mat-label>Representative Name : </mat-label>
        <mat-select name="rep" (selectionChange)="getRepNameReport($event)">
          <mat-option *ngFor="let rep of getAllRepList" [value]="rep['name']">
            {{ rep["name"] }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <button style="margin-left: 3%" (click)="getAdvtRepRegistrationReport()" mat-raised-button color="primary">
        Get Report
      </button>

      <div class="add-new-subscriber mat-elevation-z4" style="overflow-x: scroll !important" id="excel-table">
        <div style="margin-top: 20px" class="tableFixHead">
          <button type="button" (click)="exportexcel('Rep_wise_registration_report')" class="btn btn-danger">
            Export
          </button>
          <table class="table table-striped text-center">
            <thead>
              <tr>
                <th>अ.क्र.</th>
                <th>नोंदणी दिनांक</th>
                <th>Issue / Granth Name</th>
                <th>जाहिरातदार</th>
                <th>ग्राहकाचे नाव</th>
                <th>संपर्क क्रमांक</th>
                <th>प्रतिनिधींचे नाव</th>
                <th>जाहिरातीचा आकार</th>
                <th>सूचना</th>
                <th>जाहिरात रक्कम</th>
                <th>सूट रक्कम</th>
                <th>निव्वळ रक्कम</th>
                <th>GST Amount</th>
                <th>GST Bill Amount</th>
                <th>जमा रक्कम</th>
                <th>येणे बाकी</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let advt of this.getAdvtDetailsRepReport; let i = index">
                <td>{{ i + 1 }}</td>
                <td>{{ advt["registration_date"] | date : "dd-MM-yyy" }}</td>
                <td>{{ advt["issue_name"] }}</td>
                <td>{{ advt["advt_person_name"] }}</td>
                <td>{{ advt["advt_cust_name"] }}</td>
                <td>{{ advt["advt_person_mobile"] }}</td>
                <td>{{ advt["rep_name"] }}</td>
                <td>{{ advt["advt_size"] }}</td>
                <td>{{ advt["comment"] }}</td>
                <td>{{ advt["advt_amount"] | currency : "INR" : "symbol" }}</td>
                <td>
                  {{ advt["advt_discount"] | currency : "INR" : "symbol" }}
                </td>
                <td>
                  {{ advt["discounted_amount"] | currency : "INR" : "symbol" }}
                </td>
                <td>{{ advt["gst"] | currency : "INR" : "symbol" }}</td>
                <td>
                  {{ advt["total_amount"] | currency : "INR" : "symbol" }}
                </td>
                <td>{{ advt["part_amount"] | currency : "INR" : "symbol" }}</td>
                <td>
                  {{ advt["remaining_amount"] | currency : "INR" : "symbol" }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </mat-tab> -->



    <mat-tab label="Advt. Business Rep.wise / District wise">
      <mat-form-field style="margin-left: 3%; margin-top: 5%">
        <mat-label>Select Financial Year : </mat-label>
        <mat-select name="fnyear" (selectionChange)="getFinancialYearsDates($event)">
          <mat-option *ngFor="let fnyear of getFinancialYears" [value]="fnyear">
            {{ fnyear["startdate"] }} - {{ fnyear["enddate"] }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <button style="margin-left: 3%" (click)="getBussinessReport()" mat-raised-button color="primary">
        Get Report
      </button>

      <div class="add-new-subscriber mat-elevation-z4" style="overflow-x: scroll !important" id="excel-table">
        <div style="margin-top: 20px" class="tableFixHead">
          <button type="button" (click)="exportexcel('advt_bussiness_report')" class="btn btn-danger">
            Export
          </button>
          <table class="table table-striped text-center">
            <thead>
              <tr>
                <th>अ.क्र.</th>
                <th>प्रतिनिधींचे नाव</th>
                <th>जिल्हा</th>
                <th>तालुका</th>
                <th>एप्रिल</th>
                <th>मे</th>
                <th>जून</th>
                <th>जुलै</th>
                <th>ऑगस्ट</th>
                <th>सप्टेंबर</th>
                <th>ऑक्टोबर</th>
                <th>नोव्हेंबर</th>
                <th>डिसेंबर</th>
                <th>जानेवारी</th>
                <th>फेब्रुवारी</th>
                <th>मार्च</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let advt of this.bussinessReportData; let i = index">
                <td>{{ i + 1 }}</td>
                <td>{{ advt["rep_name"] }}</td>
                <td>{{ advt["district"] }}</td>
                <td>{{ advt["taluka"] }}</td>
                <td>{{ advt["April"] }}</td>
                <td>{{ advt["May"] }}</td>
                <td>{{ advt["June"] }}</td>
                <td>{{ advt["July"] }}</td>
                <td>{{ advt["August"] }}</td>
                <td>{{ advt["September"] }}</td>
                <td>{{ advt["October"] }}</td>
                <td>{{ advt["November"] }}</td>
                <td>{{ advt["December"] }}</td>
                <td>{{ advt["January"] }}</td>
                <td>{{ advt["February"] }}</td>
                <td>{{ advt["March"] }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </mat-tab>



    <!-- <mat-tab label="Advertisement Account">
      <div class="add-new-subscriber mat-elevation-z4" style="overflow-x: scroll !important">
        <div style="margin-top: 20px">
          <button (click)="getAdvtPayments()" mat-fab color="warn" class="btn"
            aria-label="Example icon button with a refresh icon">
            <mat-icon>refresh</mat-icon>
          </button>
          <table class="table table-striped text-center">
            <thead>
              <tr>
                <th>Sr.No.</th>
                <th>Rep. Name</th>
                <th>Outstanding</th>
                <th>Claim Against</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let book of this.advtpaymentList; let i = index">
                <td>{{ i + 1 }}</td>
                <td>{{ book["name"] }}</td>
                <td>₹ {{ book["totalAmount"] }}</td>
                <td>{{ book["claim_name"] }}</td>
                <td>
                  <button type="button" class="btn btn-danger btn-sm" (click)="displayBookPayment(book)">
                    View
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </mat-tab> -->





    <mat-tab label="Advertiesment Representative">
      <mat-card style="margin-top: 20px">
        <table class="table table-striped table-fixed">
          <thead>
            <tr>
              <th>Sr. No</th>
              <th>Representative Name</th>
              <th>Representative Mobile No</th>
              <th>Representative District</th>
              <th>Office Representative</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of advtwisereplist; let i = index">
              <td>{{ i + 1 }}</td>
              <td>{{ item["rep_name"] }}</td>
              <td>{{ item["rep_mob_no"] }}</td>
              <td>{{ item["district"] }}</td>
              <td>{{ item["off_rep_name"] }}</td>
              <td>
                <button type="button" class="btn btn-info" (click)="update(item)">
                  Update
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </mat-card>
    </mat-tab>




    <mat-tab label="Add Advt Type">
      <mat-card class="card">
        <h3>Add Advt Type</h3>
        <form class="form" [formGroup]="Addadvttype">
          <mat-form-field appearance="outline" class="example-full-width" style="width: 25%; margin-right: 3%;">
            <mat-label>Advertiser Type :</mat-label>
            <input matInput formControlName="advttype" maxlength="16" required />
          </mat-form-field>

          <mat-form-field appearance="outline" class="example-full-width" style="width: 25%; margin-right: 3%;">
            <mat-label>Advertiser Type Details :</mat-label>
            <input matInput formControlName="advttypedeatils" required />
          </mat-form-field>

          <button type="button" class="new-button-css" *ngIf="Addadvttype.get('id').value === ''"
            [disabled]="!Addadvttype.valid" (click)="saveadvttype()">
            Add
          </button>
          <button type="button" class="new-button-css " *ngIf="Addadvttype.get('id').value !== ''"
            [disabled]="!Addadvttype.valid" (click)="saveupdatedata()">
            Update
          </button>
          <button type="button" class="new-button-css" style="margin-left: 10px;" (click)="typeclear()">
            Clear
          </button>
        </form>


      </mat-card>

      <mat-card>
        <div class="col-md-12 mt-3">
          <table>
            <thead>
              <th>Sr</th>
              <th>Advt Type</th>
              <th>Advt Type Details</th>
              <th>Action</th>
            </thead>

            <tbody>
              <tr *ngFor="let data of oldadvttype; let i = index">
                <td>{{i+1}}</td>
                <td>{{data.advt_type}}</td>
                <td>{{data.advt_types_select}}</td>
                <!-- <td [>{{ data.id }}</td> -->
                <td>
                  <button type="button" class="btn-sm btn-outline-info" matTooltip="Update"
                    (click)="updateadvttype(data)">
                    <mat-icon>edit</mat-icon>
                  </button>
                  <!-- <button type="button" style="margin-left: 5%;" type="button" class="btn-outline-danger btn-sm"
                  matTooltip="Remove" (click)="removeState(item['state_id'])">
                  <mat-icon>delete</mat-icon>
                </button> -->
                  <button type="button" type="button" class="btn-outline-danger btn-sm" matTooltip="Remove"
                    (click)="removeadvttype(data['id'])">
                    <mat-icon>delete</mat-icon>
                  </button>
                </td>
              </tr>

            </tbody>

          </table>
        </div>
      </mat-card>


    </mat-tab>

    <mat-tab label="Add OutStanding Old Data">
      <mat-card class="card">
        <form class="form" [formGroup]="Advtremainingamt">
          <div class="row text-center">
            <div class="col-md-12" style="color: black; font-size: 25px; margin-bottom: 20px;">
              Advt Previous Data
            </div>
            <div class="col-md-12">
              <!-- <mat-form-field appearance="outline" class="example-full-width" style="width: 25%; margin-right: 3%;">
                <mat-label>Advertiser Name :</mat-label>
                <input  matInput formControlName="advtName" required (keyup)="getAdvtNames($event)"
                  [matAutocomplete]="autoadv" />
                <mat-autocomplete #autoadv="matAutocomplete">
                  <mat-option (onSelectionChange)="selected_name1($event, option)"
                    *ngFor="let option of filteredOptions | async" [value]="option['name']">
                    {{ option["name"] }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field> -->
              <!-- <mat-form-field class="example-form-field" style="margin-top: 5%">
                <mat-label>Advt. Name</mat-label>
                <input matInput type="text" [(ngModel)]="advt_name" (ngModelChange)="get_advt_name_filter($event)" />
              </mat-form-field> -->

              <mat-form-field class="example-full-width" appearance="outline" style="width: 25%; margin-right: 3%">
                <mat-label>Advertiser Name :</mat-label>
                <!-- formControlName="sadvtname" -->
                <input matInput formControlName="advtName" (keyup)="getAdvtNames($event)" [matAutocomplete]="auto1" />
                <mat-autocomplete autoActiveFirstOption #auto1="matAutocomplete">
                  <mat-option (onSelectionChange)="selected_name1($event, option)" *ngFor="let option of advt_names"
                    [value]="option['name']">
                    {{ option["name"] }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>

              <mat-form-field style="margin-left: 3%" appearance="outline" style="width: 25%; margin-right: 3%">
                <mat-label>Office Representative : </mat-label>
                <mat-select #mySelr1 (selectionChange)="getOfficeRep($event)" formControlName="off_rep_name">
                  <!-- <mat-option value="ALL" (click)="toggleAllSelectionr1()">All</mat-option> -->
                  <mat-option *ngFor="let item of pp_off_rep_names" value="{{ item['off_rep_name'] }}">{{
                    item["off_rep_name"] }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field appearance="outline" style="margin-right: 3%; width: 25%;">
                <mat-label>Representative Name : </mat-label>
                <input formControlName="rep_name" matInput [matAutocomplete]="autorepnames"
                  (keyup)="getRepNames($event)" />
                <!-- <mat-select  formControlName="rep_name" required #mySel1 (selectionChange)="getContractOffRepName($event)" >
                  <mat-option *ngFor="let item of GetOffRep" value="{{  item['rep_name'] }}">{{ item["rep_name"]
                    }}
                  </mat-option>
                </mat-select> -->
                <mat-autocomplete autoActiveFirstOption #autorepnames="matAutocomplete">
                  <mat-option (selectionChange)="getContractRepNames($event)" *ngFor="let option of filterrep"
                    [value]="option['rep_name']">
                    {{ option["rep_name"] }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
              <!-- <mat-form-field appearance="outline" style="margin-right: 3%; width: 25%;">
                <mat-label>Representative Name :</mat-label>
                <input formControlName="rep_name" matInput [matAutocomplete]="autorepname" />
                <mat-autocomplete autoActiveFirstOption #autorepname="matAutocomplete">
                  <mat-option (onSelectionChange)="getContractOffRepName(option)"
                    *ngFor="let option of filteredOptions1" [value]="option['name']">
                    {{ option["name"] }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field> -->



              <div class="col-md-12">

                <mat-form-field appearance="outline" class="example-full-width" style="width: 25%; margin-right: 3%;">
                  <mat-label>Invoice Number</mat-label>
                  <input matInput formControlName="invoiceNumber" [(ngModel)]="invoicenumber"
                    (keyup)="generateinv($event)" maxlength="16" required />
                </mat-form-field>

                <mat-form-field appearance="outline" class="example-full-width" style="width: 25%; margin-right: 3%;">
                  <mat-label>Invoice Date</mat-label>
                  <input matInput [matDatepicker]="startDatePicker10" formControlName="invoicedate"
                    (dateChange)="GenerateCRno2()" formControlName="invoiceDate" required />
                  <mat-datepicker-toggle matSuffix [for]="startDatePicker10"></mat-datepicker-toggle>
                  <mat-datepicker #startDatePicker10></mat-datepicker>
                </mat-form-field>
                <!-- <mat-form-field appearance="outline" class="example-full-width" style="width: 25%;">
                <mat-label>Advt Name</mat-label>
                <input matInput formControlName="advtName" required/>
              </mat-form-field> -->

                <mat-form-field class="example-full-width" style="margin-right: 3%; width: 25%" appearance="outline">
                  <mat-label>Issue Name : </mat-label>
                  <!-- <mat-select formControlName="issueName" (selectionChange)="getIssuesAdvtNames1($event)" required>
                  <mat-option *ngFor="let item of issuesub" value="{{ item.issueName }}">
                    {{ item.issueName }}
                  </mat-option>
                </mat-select> -->
                  <input formControlName="issueName" matInput [matAutocomplete]="autorepnames2"
                    (keyup)="getIssueNames($event)" />
                  <mat-autocomplete autoActiveFirstOption #autorepnames2="matAutocomplete">
                    <mat-option (onSelectionChange)="getIssuesAdvtNames1($event)" *ngFor="let item of oldissuename"
                      value="{{ item.issueName }}">
                      {{ item.issueName }}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </div>
              <!-- <mat-form-field appearance="outline" class="example-full-width" style="width: 25%; margin-right: 3%;">
                <mat-label>Invoice Date</mat-label>
                <input matInput formControlName="invoiceDate" required placeholder="YYYY/MM/DD"/>
              </mat-form-field> -->

              <!-- <mat-form-field appearance="outline" class="example-full-width" style="width: 25%; margin-right: 3%;">
                <mat-label>Invoice Date</mat-label>
                <input formControlName="invoiceDate" (dateChange)="addEvent($event)" matInput [matDatepicker]="pickerod">
                <mat-hint>MM/DD/YYYY</mat-hint>
                <mat-datepicker-toggle matSuffix [for]="pickerod"></mat-datepicker-toggle>
                <mat-datepicker #pickerod ></mat-datepicker>
              </mat-form-field> -->

              <!-- <mat-form-field appearance="outline" style="margin-right: 3%; width: 25%;">
                <mat-label>Representative Name : </mat-label>
                <input formControlName="rep_name" matInput [matAutocomplete]="autorepnames"  (keyup)="getRepNames($event)"/>
             
                <mat-autocomplete autoActiveFirstOption #autorepnames="matAutocomplete">
                  <mat-option (selectionChange)="getContractRepNames($event)"
                    *ngFor="let option of filterrep" [value]="option['rep_name']">
                    {{ option["rep_name"] }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field> -->



              <div class="col-md-12">
                <mat-form-field class="example-full-width" style="margin-right: 3%; width: 25%" appearance="outline">
                  <mat-label>Issue Dates : </mat-label>
                  <input formControlName="issueDate" matInput [matAutocomplete]="autorepnames1"
                    (keyup)="getIssuedateNames($event)" />
                  <mat-autocomplete autoActiveFirstOption #autorepnames1="matAutocomplete">
                    <mat-option *ngFor="let item of filterissuedate"
                      (onSelectionChange)="setissueDate(item['issueDate'])"
                      value="{{ [item['issueDate'] | date:'dd/MM/yyyy', item['issueName'],item['publishedDate'] | date : 'dd/MM/yyyy'] }}">{{
                      item["issueDate"] | date : "dd-MM-yyy" }}
                      {{ item["issueName"] }}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>

                <mat-form-field appearance="outline" class="example-full-width" style="width: 25%; margin-right: 3%; ">
                  <mat-label>Total Amount</mat-label>
                  <input matInput formControlName="totalamt" required />
                </mat-form-field>

                <mat-form-field appearance="outline" class="example-full-width" style="width: 25%; margin-right: 3%;">
                  <mat-label>Received Amount</mat-label>
                  <input matInput formControlName="receivedAmount" />
                </mat-form-field>



              </div>
              <div class="col-md-12">
                <mat-form-field appearance="outline" class="example-full-width"
                  style="width: 25%; margin-right: 3%; margin-left: -41%;">
                  <mat-label>Pending Amount</mat-label>
                  <input matInput formControlName="pendingamt" [value]="Advtremainingamt.get('pendingamt').value"
                    disabled />
                </mat-form-field>

                <button type="button" class="new-button-css" [disabled]="!Advtremainingamt.valid"
                  [class.disabled-button]="Advtremainingamt.invalid" (click)="saveolddata()">
                  Add
                </button>
                <!-- <button type="button" class="new-button-css" (click)="saveolddata()">Add</button> -->
                <!-- <button type="button" class="new-button-css" [disabled]="!Advtremainingamt.valid" (click)="saveolddata()">Add</button> -->

              </div>
            </div>
          </div>
        </form>
      </mat-card>
      <mat-card>
        <div class="col-md-12 mt-3">
          <table>
            <thead>
              <th>Invoice Number</th>
              <th>Invoice Date</th>
              <th>Creation Date</th>
              <th>Advt Name</th>
              <th>Issue Name</th>
              <th>Issue Date</th>
              <th>Total Amount</th>
              <th>Received Amount</th>
              <th>Pending Amount</th>
              <th>Action</th>
            </thead>

            <tbody>
              <tr *ngFor="let data of old_data; let i = index">
                <td>{{data.invoice_number}}</td>
                <td>{{data.invoice_date}}</td>
                <td>{{data.creation_date}}</td>
                <td>{{data.advt_name}}</td>
                <td>{{data.issue_name}}</td>
                <td>{{data.issue_date}}</td>
                <td>{{data.total_amt}}</td>
                <td>{{data.rcv_amt}}</td>
                <td>{{data.pending_amt}}</td>
                <!-- <td [>{{ data.id }}</td> -->
                <td>
                  <button type="button" class="btn-sm btn-outline-info" matTooltip="Update"
                    (click)="updateolddata(data)">
                    <mat-icon>edit</mat-icon>
                  </button>
                  <!-- <button type="button" style="margin-left: 5%;" type="button" class="btn-outline-danger btn-sm"
                  matTooltip="Remove" (click)="removeState(item['state_id'])">
                  <mat-icon>delete</mat-icon>
                </button> -->
                  <button type="button" type="button" class="btn-outline-danger btn-sm" matTooltip="Remove"
                    (click)="removeolddata(data['id'])">
                    <mat-icon>delete</mat-icon>
                  </button>
                </td>
              </tr>

            </tbody>

          </table>
        </div>
      </mat-card>

    </mat-tab>
  </mat-tab-group>
</div>