import { formatDate } from '@angular/common';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-districtwise-varganidar-subscription-report',
  templateUrl: './districtwise-varganidar-subscription-report.component.html',
  styleUrls: ['./districtwise-varganidar-subscription-report.component.scss'],
})
export class DistrictwiseVarganidarSubscriptionReportComponent
  implements OnInit
{
  isLoading = false;
  startDate = '';
  endDate = '';
  subData;
  districtwiseData = [];
  districtwiseDataTotal = {};
  districtwiseDataTotalTest = {};
  subTypesName = [
    { name: 'वार्षिक', isVisible: false },
    { name: 'त्रैवार्षिक', isVisible: false },
    { name: 'पंचवार्षिक', isVisible: false },
    { name: 'मुदत ठेव (पंचवार्षिक वर्गणीदार)', isVisible: false },
    // { name: 'रामसेवक ग्रंथयात्री', isVisible: false },
    // { name: 'लोकनेता गंथयात्री वार्षिक', isVisible: false },
    // { name: 'लोकमान्य ग्रंथयात्री वार्षिक वर्गणी', isVisible: false },
    // { name: 'हिंदुत्व ग्रंथयात्री', isVisible: false },
    // { name: 'हिंदुत्व 1', isVisible: false },
    // { name: 'हिंदुत्व 2', isVisible: false },
    // { name: 'आजीव', isVisible: false },
    // { name: 'अनामत', isVisible: false },
  ];
  test = [
    'हितचिंतक सप्रेम भेट',
    'सप्रेम भेट - संपादकीय विभाग',
    'जाहिरात स. भेट',
    'संघ परिवार सप्रेम भेट',
    'वर्गणी सप्रेम भेट',
  ];
  totalth = 0;

  constructor(
    private dialogRef: MatDialogRef<DistrictwiseVarganidarSubscriptionReportComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.subData = this.data['data'];
    let data = this.uniqueData(this.subData, (a) => a.Subscription_Type);
    // console.log(this.getKey('वार्षिक' + '_पोस्ट'));

    for (let i = 0; i < Object.keys(data).length; i++) {
      let flag = false;
      for (let j = 0; j < Object.keys(this.subTypesName).length; j++) {
        if (data[i]['Subscription_Type'] === this.subTypesName[j]['name']) {
          this.subTypesName[j]['isVisible'] = true;
          flag = true;
          break;
        }
      }
      if (!flag) {
        if (
          data[i]['Subscription_Type'] !== 'हितचिंतक सप्रेम भेट' ||
          data[i]['Subscription_Type'] !== 'सप्रेम भेट - संपादकीय विभाग' ||
          data[i]['Subscription_Type'] !== 'जाहिरात स. भेट' ||
          data[i]['Subscription_Type'] !== 'संघ परिवार सप्रेम भेट' ||
          data[i]['Subscription_Type'] !== 'वर्गणी सप्रेम भेट'
        ) {
          this.subTypesName.push({
            name: data[i]['Subscription_Type'],
            isVisible: true,
          });
        }
      }
    }

    this.subTypesName.map((a: any) => {
      let name = this.getKey(a['name']);
      // console.log(name + '_Post');
      this.districtwiseDataTotalTest[name + '_पोस्ट'] = 0;
      this.districtwiseDataTotalTest[name + '_हस्ते'] = 0;
      this.districtwiseDataTotalTest[name + '_एकूण'] = 0;
    });

    // console.log(this.subTypesName);

    this.totalth = this.subTypesName.length * 3 + 7;

    this.districtwiseDataTotal = {
      ...this.districtwiseDataTotalTest,
      एकूण_पोस्ट: 0,
      एकूण_हस्ते: 0,
      एकूण: 0,
    };

    // console.log(this.districtwiseDataTotal);

    this.endDate = this.data['issuedate'];
    let weekfirstday =
      new Date(this.data['issuedate']).getDate() -
      new Date(this.data['issuedate']).getDay();
    this.startDate = formatDate(
      new Date(this.data['issuedate']).setDate(weekfirstday - 7),
      'yyyy-MM-dd',
      'en-IN',
      'GMT+0530'
    );

    this.setSubData();
  }

  setSubData() {
    let data = this.uniqueData(this.subData, (a) => a.district);
    data.sort((a: any, b: any) => {
      return b.district.localeCompare(a.district);
    });

    for (let i = 0; i < data.length; i++) {
      let districtData = this.subData.filter((a) => {
        return a.district == data[i]['district'];
      });
      let subType = this.uniqueData(districtData, (a) => a.Subscription_Type);

      for (let j = 0; j < subType.length; j++) {
        let subTypeData = districtData.filter((a) => {
          return a.Subscription_Type == subType[j]['Subscription_Type'];
        });

        let post = subTypeData.filter((a) => {
          return a.delivery_mode == 'टपाल';
        });
        // console.log(post);

        let haste = subTypeData.filter((a) => {
          return a.delivery_mode == 'हस्ते';
        });
        // console.log(haste);

        this.setSubTypeData(
          data[i]['district'],
          this.getKey(subType[j]['Subscription_Type']),
          post.length,
          haste.length,
          j == subType.length - 1 ? true : false
        );
      }
    }
    // console.log(this.districtwiseData);

    this.calculateTotal();
  }

  getKey(name) {
    return name.replaceAll(' ', '_');
  }

  setSubTypeData(
    districtName: string,
    subType: string,
    post: number,
    haste: number,
    last: boolean
  ) {
    let foundDistrict = this.districtwiseData.filter((a) => {
      return a.districtName == districtName;
    });

    if (foundDistrict.length == 0) {
      this.subTypesName.map((a: any) => {
        let name = this.getKey(a['name']);
        // this.districtwiseDataTotalTest[name] = 0;
        this.districtwiseDataTotalTest[name + '_पोस्ट'] = 0;
        this.districtwiseDataTotalTest[name + '_हस्ते'] = 0;
        this.districtwiseDataTotalTest[name + '_एकूण'] = 0;
      });

      this.districtwiseData.push({
        districtName,
        ...this.districtwiseDataTotalTest,
        एकूण_पोस्ट: 0,
        एकूण_हस्ते: 0,
        एकूण: 0,
      });
    }

    if (!this.test.includes(subType)) {
      this.setDistrictData(districtName, subType, post, haste);
    } else {
      this.districtwiseData.map((a) => {
        if (a.districtName == districtName) {
          a[subType + '_पोस्ट'] = post;
          a[subType + '_हस्ते'] = haste;
          a[subType + '_एकूण'] = post + haste;
          a['एकूण_पोस्ट'] += post;
          a['एकूण_हस्ते'] += haste;
          a['एकूण'] += post + haste;
          return;
        }
      });
    }
  }

  setDistrictData(
    districtName: string,
    subtype: string,
    post: number,
    haste: number
  ) {
    this.districtwiseData.map((a) => {
      if (a.districtName == districtName) {
        a[subtype + '_पोस्ट'] = post;
        a[subtype + '_हस्ते'] = haste;
        a[subtype + '_एकूण'] = post + haste;
        a['एकूण_पोस्ट'] += post;
        a['एकूण_हस्ते'] += haste;
        a['एकूण'] += post + haste;
        return;
      }
    });
  }

  uniqueData(data, key) {
    return [...new Map(data.map((a) => [key(a), a])).values()];
  }

  calculateTotal() {
    this.districtwiseData.map((a) => {
      this.subTypesName.map((b) => {
        let key = this.getKey(b.name);
        this.districtwiseDataTotal[key + '_पोस्ट'] += a[key + '_पोस्ट'];
        this.districtwiseDataTotal[key + '_हस्ते'] += a[key + '_हस्ते'];
        this.districtwiseDataTotal[key + '_एकूण'] += a[key + '_एकूण'];
      });
      this.districtwiseDataTotal['एकूण_पोस्ट'] += a['एकूण_पोस्ट'];
      this.districtwiseDataTotal['एकूण_हस्ते'] += a['एकूण_हस्ते'];
      this.districtwiseDataTotal['एकूण'] += a['एकूण'];
    });
    this.isLoading = false;
    // console.log(this.districtwiseDataTotal);
  }

  exportExcel(fileName) {
    /* table id is passed over here */
    let element = document.getElementById('print-section');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, fileName + '.xlsx');
  }
}
