<form [formGroup] = "form" (ngSubmit) = submitForm()>

    <div class="form-group">
			<label>Enter OTP</label>
		  	<input formControlName = "otp" type="text" class="form-control" placeholder=" ">
    </div> <!-- form-group end.// -->
 
    <div class="form-group">
        <button type="submit" class="btn btn-primary btn-block">Submit</button>
    </div> <!-- form-group// -->                                                
</form>