
<div class="header bg-gradient-danger py-7 py-lg-8">
  <!-- <div class="container">
    <div class="header-body text-center mb-7">
      <div class="row justify-content-center">
        <div class="col-lg-5 col-md-6">
          <h1 class="text-white">Welcome!</h1>
          <p class="text-lead text-light">Use these awesome forms to login or create new account in your project for free.</p>
        </div>
      </div>
    </div>
  </div> -->
  <div class="separator separator-bottom separator-skew zindex-100">
    <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <polygon class="fill-default" points="2560 0 2560 100 0 100"></polygon>
    </svg>
  </div>
</div>
<!-- Page content -->
<div class="loginForm">
<div class="container mt--8 pb-5">
  <div class="row justify-content-center">
    <div class="col-lg-5 col-md-7">
      <div class="card bg-secondary shadow border-0">
        <div class="card-header bg-transparent pb-5" style="text-align:center">
          <span style="margin-top:2% ; font-size:xx-large ;font-weight:700">Login</span>
        </div>
        <div class="card-body px-lg-5 py-lg-5">
          <form role="form" [formGroup] = "form" (ngSubmit) = submitForm() >
            <div class="form-group mb-3">
              <div class="input-group input-group-alternative">
                <div class="input-group-prepend">
                  <span class="input-group-text"><i class="ni ni-mobile-button"></i></span>
                </div>
                <input required formControlName = "mobilenumber" class="form-control" placeholder="Phone No" type="number">
              </div>
            </div>
            <div class="form-group">
              <div class="input-group input-group-alternative">
                <div class="input-group-prepend">
                  <span class="input-group-text"><i class="ni ni-lock-circle-open"></i></span>
                </div>
                <input required formControlName = "password" class="form-control" 
                placeholder="Password" [type]="this.hide ? 'password' : 'text'">
                <div class="input-group-prepend">
                  <span class="input-group-text">
                    <i slot="end"  [class]="this.hide ? 'ni ni-key-25 ' : 'ni ni-lock-circle-open'" (click)="hide = !hide"></i>
                  </span>
                </div>
                
              </div>
            </div>            

            <div class="text-center"  >
              <button *ngIf="!disableLoading" class="btn btn-primary" type="button" disabled>
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                Loading...
              </button>
              <button *ngIf="disableLoading" type="submit" [disabled]="form.invalid" class="btn btn-primary my-4">Sign in</button>
            </div>
                        
          </form>
          
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-6">
          <a href="javascript:void(0)" class="text-light"><small>Forgot password?</small></a>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
