<div>
    <div class="container" style="border: 1px solid black;">
        <div class="row" style="text-align: center;background-color: black;">
            <h3 style="color: white; margin-left: auto; margin-right: auto; margin-top: auto; margin-bottom: auto;"> TAX
                INVOICE</h3>
        </div>
        <div class="row" style="border-bottom-style: solid;">
            <div class="col-3">
                <img style="margin-top: 2%;" width="55%" src="../../../assets/img/brand/red.png">
            </div>
            <div class="col-9" style="padding-top: 5%;text-align: center;">
                <span style="font-size: x-large; font-weight: bold;">हिंदुस्थान प्रकाशन संस्था</span>
                <span style="float: right;font-size: x-small;">(Reg.No.-BOM-3/1961(GBBSD)</span>
                <h3 style="text-align: center;font-size: large;margin-top: 5%;">साप्ताहिक विवेक</h3>
                <span style="font-size: x-small;">मुख्य कार्यालय: 5/12, कामत औद्योगिक वसाहत, 396 स्वा. वीर सावरकर मार्ग,
                    प्रभादेवी, मुंबई-25.<br /></span>
                <span style="font-size: x-small;">प्रशासकीय कार्यालय : विवेक भवन (कृष्णा रिजन्सी), 12 वा मजला, प्लॉट
                    क्र. 40, सेक्टर क्र. 30, सानपाडा (प.), नवी मुंबई-400 705.</span>
            </div>
            <hr />

        </div>
        <div class="row" style=" border-bottom-style: solid;">
            <div class="col-6">
                <span style="font-weight: bold;">GSTIN/Unique Id : 27AAATH0433Q1ZI</span>
            </div>

            <div class="col-6" style="border-style: solid; border-width: 0px 0px 0px 3px;">
                <span style="font-weight: bold;">PAN No. : AAATH0433Q</span>
            </div>
        </div>

        <div class="row" *ngIf="viewmode">
            <div class="col-6">
                <span> <b>Invoice No. </b>: {{invoiceData['invoice_id']}}</span>
            </div>
            <div class="col-6" style="border-style: solid; border-width: 0px 0px 0px 3px;">
                <span> <b>Date </b>: {{invoiceData['invoice_date']|date:'dd-MM-yyy'}}</span>
            </div>
        </div>

        <div class="row" style="border-bottom-style: solid; border-block-start-style: solid;">

            <div class="col-6">
                <h3 style="font-weight: bold;color: black;">Details of Receiver : </h3>
                <span class="receiver">Name : {{invoiceData['rep_name']}}<br /></span>
                <span class="receiver">Address : {{invoiceData['rep_address']}}<br /></span>
                <span class="receiver">Phone : {{invoiceData['rep_mobile']}}<br /></span>
                <span class="receiver">GSTIN : {{gst_number}}<br /></span>
            </div>
            <div class="col-6" style="border-style: solid; border-width: 0px 0px 0px 3px;">
                <h3 style="font-weight: bold;color: black;">Details of Cosignee : </h3>
                <span class="receiver">Name : <br /></span>
                <span class="receiver">Address : <br /></span>
                <span class="receiver">Phone : <br /></span>
                <span class="receiver">GSTIN : <br /></span>
            </div>

        </div>

        <div class="row">
            <div class="col-1 tableDescheader">S.No</div>
            <div class="col-4 tableDescheader">Description of Goods</div>
            <div class="col-3 tableDescheader">Advt. Size</div>
            <div class="col-2 tableDescheader">Published Date</div>
            <div class="col-2 tableDescheader">Page No.</div>
        </div>
        <div class="row" *ngFor="let item of invoiceData['advt_data'] ; let i=index">
            <div class="col-1 tableDesc">{{i+1}}</div>
            <div class="col-4 tableDesc">{{item['issue_name']}}</div>
            <div class="col-3 tableDesc">{{item['advt_size']}}</div>
            <div class="col-2 tableDesc">{{item['publish_date']}}</div>
            <div class="col-2 tableDesc">{{item['publish_page']}}</div>
        </div>


        <div class="row">
            <div class="col-10 tableDescheader">Total Amount</div>
            <div class="col-2 tableDescheader">₹ {{totalAmount}}</div>
        </div>

        <div class="row">
            <div class="col-10 tableDescheader">Total Discount({{this.discount}}%) </div>
            <div class="col-2 tableDescheader">₹ {{discounted_price}}</div>
        </div>

        <div>
            <table class="table table-hover" border="0.5">
                <thead>
                    <tr>
                        <th style="text-align: center;">Taxable</th>
                        <th colspan="2" style="text-align: center;">Central Tax</th>
                        <th colspan="2" style="text-align: center;">State Tax</th>
                        <th colspan="2" style="text-align: center;">Integrated Tax</th>
                        <th style="text-align: center;">Total</th>
                    </tr>
                    <tr>
                        <th style="text-align: center;">Value</th>
                        <th style="text-align: center;">Rate</th>
                        <th style="text-align: center;">Amount</th>
                        <th style="text-align: center;">Rate</th>
                        <th style="text-align: center;">Amount</th>
                        <th style="text-align: center;">Rate</th>
                        <th style="text-align: center;">Amount</th>
                        <th style="text-align: center;">Tax Amount</th>
                    </tr>
                </thead>
                <tbody>
                    <tr style="    text-align: center;">
                        <td>₹ {{this.totalAmount - this.discounted_price}}</td>
                        <td>2.5%</td>
                        <td>₹ {{cgstamt}}</td>
                        <td>2.5%</td>
                        <td>₹ {{sgstamt}}</td>
                        <td>5%</td>
                        <td>₹ {{igstamt}}</td>
                        <td>₹ {{taxablevalue}}</td>
                    </tr>
                </tbody>
            </table>
        </div>


        <div class="row" style="border-bottom-style: solid; border-block-start-style: solid; text-align: center;">
            <div class="col-9">Total</div>
            <div class="col-3" style="border-style: solid; border-width: 0px 0px 0px 3px;">₹ {{taxablevalue}}</div>
        </div>

        <div class="row" style=" border-bottom-style: solid;">
            <div class="col-9"><b>Invoice Total in word :</b> {{taxAmountInWords}} </div>
            <div class="col-3" style="border-style: solid; border-width: 0px 0px 0px 3px;">
                <span *ngIf="this.rep_invoice == 'Y'"><b>Rep. Commision : </b>₹ {{totalCommision}} <br /> </span>
                <span><b>Invoice Total : </b>₹ {{invoiceValue}} <br /> </span>
                <span><b>Total :</b> ₹ {{invoiceValue}}</span>
            </div>
        </div>

        <div class="row" style="font-weight: bold;">
            <div class="col-1"></div>

            <div class="col-10">
                <span>Bank Detail : TJSB Sahakari Bank Ltd. <br /> </span>
                <span>A/C Name : Hindustan Prakashan Sanstha - Pustak Vibhag <br /> </span>
                <span>A/C No. : 012120100001216 <br /> </span>
                <span>IFSC No. : TJSB0000012</span>
            </div>
            <div class="col-1"></div>
        </div>

        <div class="row" style="border-bottom-style: solid; border-block-start-style: solid;">
            <div class="col-8">
                <h3>Terms:</h3>
                <span>1) Complaints of any nature must be communicated writing within 10 Days from the dateof delivery.
                    <br /> </span>
                <span>2) A/C payee cheques to be drawn in favor of :- <br /></span>
                <span>Hindustan Prakashan Sanstha - Pustak Vibhag</span>
            </div>
            <div class="col-4" style="border-style: solid; border-width: 0px 0px 0px 3px;">
                <span>For Hindustan Prakashan Sanstha</span>
                <div style="padding: 17%;"></div>
                <span>Authorised Signatory</span>
            </div>
        </div>






    </div>
    <button *ngIf="viewmode" class="noPrint" style="margin-top: 2%;" (click)="print()" mat-raised-button
        color="primary">Print</button>
    <button *ngIf="!viewmode" style="margin-top: 2%;" (click)="save()" mat-raised-button color="primary">Save</button>
    <button class="noPrint" style="margin-top: 2%;" mat-button mat-dialog-close>Close</button>

</div>