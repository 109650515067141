<div id="print-section">
  <div class="container">
    <div class="row" style="margin-left: 50px;">
      <div class="col-2" style="margin-top: -20px; margin-left: 20px">
        <img src="../../../assets/img/brand/red.png" alt="Vivek Sapthaik" width="70%" />
      </div>
      <div class="col-10" style="margin-left: -20px">
        <div class="row" style="padding: 0px 0px 05px 0px; font-size: 17px">
          हिंदुस्थान प्रकाशन संस्था
        </div>
        <div class="row" style="padding: 0px 0px 05px 0px; font-size: 17px">
          विवेक साप्तहिक
        </div>
        <div class="row" style="font-size: 17px">
          विवेक भवन (कृष्णा रिजन्सी), प्लॉट क्र. 40, सेक्टर क्र. 30, सानपाडा
          (प.), नवी मुंबई, जि. ठाणे - 400 705.
        </div>
      </div>
    </div>
    <div class="row" style="
        border-width: 1.5px 0px 1.5px 0px;
        border-color: black;
        border-style: solid;
        padding: 5px 5px;
        display: block;
        text-align: center;
        margin-left: 50px;
      ">
      <span style="font-size: 17px">वर्गणी नूतनीकरण स्मरणपत्र</span>
    </div>
    <div class="row" style="padding: 5px 5px;margin-left: 50px; display: block; text-align: end; font-size: 17px">
      दिनांक : {{ today }}
    </div>
    <div class="row" style="margin-left: 110px">
      <span style="font-size: 17px; padding-bottom: 30px">प्रति,</span>
    </div>
    <div class="row" style="margin-left: 164px">
      <span style="font-size: 17px">श्री. राहुल डुंबरे</span>
    </div>
    <div class="row" style="margin-left: 110px">
      <span style="font-size: 17px">पत्ता : <span style="margin-left: 15px">लॉट क्र. 408, प्रथमेश बिल्डिंग, श्री गणेश
          विश्वा सोसायटी,
        </span>
      </span>
    </div>
    <div class="row" style="margin-left: 164px">
      <span style="font-size: 17px">
        गोकुळ नगर, कोंढवा, पिन कोड : 411046 <br />शहर/जिल्हा : पुणे<br /> राज्य
        : महाराष्ट्र्रदेशाचे नाव : भारत
      </span>
    </div>
    <div class="row" style="margin-left: 100px">
      <span style="font-size: 17px">संपर्क : <span style="margin-left: 15px">9689100460</span></span>
    </div>
    <div class="row" style="margin-left: 58px">
      <span style="font-size: 17px">वर्गणीदार क्रं : <span style="margin-left: 15px">07/96046</span></span>
    </div>
    <div class="row" style="margin-top: 20px;margin-left: 50px;">
      <span style="font-size: 17px">स.न.वि.वि.</span>
    </div>
    <div class="row" style="font-size: 17px;margin-left: 50px;">
      <p>सा.विवेक गेली ७३ वर्षे विविध विषय, आकर्षक मांडणी, उत्कृष्ट छपाई, विशेषांक-पुरवण्या याद्वारे आपल्याकडे नियमितपणे
        येत आहे.
        सा.विवेक अक्षर चळवळीत सकारात्मक योगदान देत आहे. आपल्यासारख्या वाचकांच्या सहकार्यानेच विवेकची वाटचाल यशस्वी होत
        आहे.
        दिवसेंदिवस कागद, छपाई, शाई व अन्य विविध सामुग्रीच्या खर्चात होत असलेली वाढ लक्षात घेऊन विवेकने आपल्या वर्गणीत १
        जानेवारी
        २००१ पासून वाढ करण्याचा निर्णय घेतला असून वर्गणीचे सुधारित दर खालील प्रमाणे आहेत.</p>
      <p>(वार्षिक रु ७५०/-, त्रैवार्षिक रु २०००/- व पंचवार्षिक रु ३२००/-)</p>
      <p>आपली वार्षिक वर्गणी दिनांक ३०/०६/२०२२ रोजी संपत आहे. आपणांस विनंती आहे की. आपली पढील वर्गणी प्रत्यक्ष किंवा
        विवेकच्या
        पत्त्यांवर "हिंदुस्थान प्रकाशन संस्था" या नावे धनादेश / ड्राफ्ट / म.ऑ. ने किंवा आमच्या स्थानिक प्रतिनिधीकडे
        पाठवून
        सहकार्य करावे, हि विनंती.</p>
      <p>वर्गणी पाठविताना आपला ग्राहक क्रमांक व पिनकोड अवश्य लिहावा.</p>
      <p>नियमित अंकासंबंधीचा आपला अभिप्राय अवश्य कळवावा. कळावे, लोभ आहेच, वृद्धिगंत व्हावा. धन्यवाद !</p>
    </div>
    <div class="row" style="margin-left: 75%">
      <span style="font-size: 17px">आपला विश्वासू</span>
    </div>
    <div class="row" style="margin-left: 75.5%;margin-top: 50px;">
      <span style="font-size: 17px">राहुल पाठारे</span>
    </div>
    <div class="row" style="margin-left: 74.5%;margin-top: 10px;">
      <span style="font-size: 17px">प्रतिनिधी संपर्क</span>
    </div>
    <div class="row" style="margin-left: 15.5%;margin-top: 25px;">
      <span style="font-size: 18px; border: 2px solid black;padding: 7px 150px 7px 100px;">ता.क. आपण वर्गणी यापूर्वीच
        पाठविली असल्यास हे स्मरणपत्र
        रद्द समजावे.</span>
    </div>
  </div>
  <button [useExistingCss]="true" class="noPrint" style="margin-top: 2%" mat-raised-button color="primary"
    printSectionId="print-section" ngxPrint>
    print
  </button>
  <button class="noPrint" style="margin-top: 2%; margin-left: 2%" mat-raised-button color="info" mat-dialog-close>
    Close
  </button>
</div>