import { Component, Inject } from '@angular/core';
import { DigisubservicesService } from '../services/digisubservices.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as XLSX from 'xlsx';
import { formatDate } from '@angular/common';
@Component({
  selector: 'app-districtwisebusiness',
  templateUrl: './districtwisebusiness.component.html',
  styleUrls: ['./districtwisebusiness.component.scss']
})
export class DistrictwisebusinessComponent {
  
  datewisedata;
  today = new Date();
  startdate = '';
  enddate = '';
  datewiselist = [];
  tempdata = [];
  district:any;
  
  isLoading: boolean = false;
  subTypesName = [
    { name: 'वार्षिक', isVisible: false },
    { name: 'त्रैवार्षिक', isVisible: false },
    { name: 'पंचवार्षिक', isVisible: false },
    // { name: 'मुदत ठेव (पंचवार्षिक वर्गणीदार)', isVisible: false },
    // { name: 'रामसेवक ग्रंथयात्री', isVisible: false },
    // { name: 'लोकनेता गंथयात्री वार्षिक', isVisible: false },
    // { name: 'लोकमान्य ग्रंथयात्री वार्षिक वर्गणी', isVisible: false },
    // { name: 'हिंदुत्व ग्रंथयात्री', isVisible: false },
    // { name: 'हिंदुत्व 1', isVisible: false },
    // { name: 'हिंदुत्व 2', isVisible: false },
    // { name: 'आजीव', isVisible: false },
    // { name: 'अनामत', isVisible: false },
  ];
  totalth = 0;
  finalData = [];
  state: any;
  country: any;

  constructor(
    private dialogref: MatDialogRef<DistrictwisebusinessComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    debugger
    this.isLoading = true;
     this.district = this.data.district
    this.datewisedata = this.data['data'];
    console.log( this.datewisedata , "first data")
    this.datewisedata = this.datewisedata.filter((item:any) =>{
       return item.district_name === this.district
       })
       
       console.log(this.datewisedata, "statewise" )
    this.startdate = this.data['startdate'];
    this.enddate = this.data['enddate'];
    // console.log(formatDate(new Date(), 'MMMM', 'en-IN', 'GMT+0530'));
    let data = this.uniqueData(this.datewisedata, (a) => a.subscription_type);

    for (let i = 0; i < Object.keys(data).length; i++) {
      let flag = false;
      for (let j = 0; j < Object.keys(this.subTypesName).length; j++) {
        if (data[i]['subscription_type'] === this.subTypesName[j]['name']) {
          this.subTypesName[j]['isVisible'] = true;
          flag = true;
          break;
        }
      }
      if (!flag) {
        // if (
        //   data[i]['Subscription_Type'] !== 'हितचिंतक सप्रेम भेट' ||
        //   data[i]['Subscription_Type'] !== 'सप्रेम भेट - संपादकीय विभाग' ||
        //   data[i]['Subscription_Type'] !== 'जाहिरात स. भेट' ||
        //   data[i]['Subscription_Type'] !== 'संघ परिवार सप्रेम भेट' ||
        //   data[i]['Subscription_Type'] !== 'वर्गणी सप्रेम भेट'
        // ) {
        this.subTypesName.push({
          name: data[i]['subscription_type'],
          isVisible: true,
        });
        // }
      }
    }

    // console.log(this.subTypesName);
    this.totalth = this.subTypesName.length * 3 + 4;
    // this.getDateORrepwiselist();
    this.setData();
  }

  setData() {
    // console.log(this.rojmelData);
    debugger;
    let uniqueDateData = this.uniqueData(
      this.datewisedata,
      (a) => a.district_name
    );

   
    console.log(uniqueDateData
    );
    let finalTotal = {};
    this.subTypesName.map((b: any, i) => {
      if (b.isVisible) {
        let name = this.getKey(b.name);
        finalTotal[name + '_एकूण'] = 0;
        finalTotal[name + '_नवीन'] = 0;
        finalTotal[name + '_नूतनीकरण'] = 0;
        if (i == 0) {
          finalTotal['name'] = 'एकूण';
          finalTotal['एकूण'] = 0;
          finalTotal['नवीन'] = 0;
          finalTotal['नूतनीकरण'] = 0;
        }
      }
    });

    for (let i = 0; i < uniqueDateData.length; i++) {
      let datewiseData = this.datewisedata.filter((a) => {
        return a.district_name === uniqueDateData[i]['district_name'];
      });
      console.log(uniqueDateData[i]['state'], datewiseData);
      // let datewiseData = this.uniqueData(
      //   districtwiseData,
      //   (a) => a.taluka
      // );
      // let datewiseData = this.datewisedata.filter((a) => {
      //   return a.entry_date === uniqueDateData[i]['entry_date'];
      // });
      // console.log(datewiseData);
      let totalData = {};
      this.subTypesName.map((b: any, i) => {
        if (b.isVisible) {
          let name = this.getKey(b.name);
          totalData[name + '_एकूण'] = 0;
          totalData[name + '_नवीन'] = 0;
          totalData[name + '_नूतनीकरण'] = 0;
          if (i == 0) {
            totalData['एकूण'] = 0;
            totalData['नवीन'] = 0;
            totalData['नूतनीकरण'] = 0;
          }
        }
      });
      datewiseData.map((a: any, i) => {
        // if (i == 0) totalData['entry_date'] = a.entry_date;
        let name = this.getKey(a.subscription_type);
        totalData['crdt_voucher_date']=a.crdt_voucher_date
        totalData[name + '_एकूण'] += parseInt(a.total_amt);
        finalTotal[name + '_एकूण'] += parseInt(a.total_amt);
        totalData[name + '_नवीन'] += a.rcpt_type == 'New' ? 1 : 0;
        finalTotal[name + '_नवीन'] += a.rcpt_type == 'New' ? 1 : 0;
        totalData[name + '_नूतनीकरण'] += a.rcpt_type == 'Renew' ? 1 : 0;
        finalTotal[name + '_नूतनीकरण'] += a.rcpt_type == 'Renew' ? 1 : 0;
        totalData['एकूण'] += parseInt(a.total_amt);
        finalTotal['एकूण'] += parseInt(a.total_amt);
        totalData['नवीन'] += a.rcpt_type == 'New' ? 1 : 0;
        finalTotal['नवीन'] += a.rcpt_type == 'New' ? 1 : 0;
        totalData['नूतनीकरण'] += a.rcpt_type == 'Renew' ? 1 : 0;
        finalTotal['नूतनीकरण'] += a.rcpt_type == 'Renew' ? 1 : 0;
        totalData['taluka'] = a.taluka 
        totalData['state'] = a.state 


      });
      // datewiseData.push(totalData);
      this.finalData.push(totalData);
    }
    this.finalData.push(finalTotal);
    console.log(this.finalData);
    this.isLoading = false;
  }

  getKey(name) {
    return name.replaceAll(' ', '_');
  }

  uniqueData(data, key) {
    return [...new Map(data.map((a) => [key(a), a])).values()];
  }

  exportExcel(fileName) {
    /* table id is passed over here */
    let element = document.getElementById('print-section');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, fileName + '.xlsx');
  }
}
