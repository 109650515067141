import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DigisubservicesService } from 'src/app/services/digisubservices.service';

@Component({
  selector: 'app-credit-debit-ledger-report',
  templateUrl: './credit-debit-ledger-report.component.html',
  styleUrls: ['./credit-debit-ledger-report.component.scss'],
})
export class CreditDebitLedgerReportComponent implements OnInit {
  creditdebitledgerData = [];
  bankwiseData;
  creditledgerData;
  debitledgerData;
  debittotal = 0;
  credittotal = 0;
  previousbaki = 0;
  nextbaki = 0;
  startDate;
  endDate;
  bank;
  bankname: number;
  contraData: any;

  constructor(
    private dialogref: MatDialogRef<CreditDebitLedgerReportComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    private service: DigisubservicesService
  ) {}

  ngOnInit(): void {
    this.startDate = this.data['startDate'];
    this.endDate = this.data['endDate'];
    this.bank = this.data['bank'];
    this.getAllCreditVouchers();
  }

  getAllCreditVouchers() {
    
    if (this.startDate !== '' && this.endDate !== '' && this.bank !== '') {
      this.service.readBankwiseOpeningBalance(this.bank).subscribe((res) => {
        this.bankwiseData = res;
        this.previousbaki = parseInt(this.bankwiseData['opening_bal']);
        this.bankname = this.bankwiseData['sub_exp_name'];

      });

      const req:any = {
        
        "startdate":this.startDate,
        "enddate":this.endDate,
        "banktype":this.bank,
      }

      this.service
      .getAllcontra(req)
      .subscribe((res) => {
        // console.log(res);
        this.contraData = res;
        // this.creditdebitledgerData.push(this.contraData);
        this.contraData.map((a) => {
          // this.previousbaki = this.previousbaki + parseInt(a.deposit_amt);
          this.creditdebitledgerData.push(a);
          console.log(this.creditdebitledgerData, "contra entry")
        });
        // console.log(
        //   this.creditdebitledgerData.sort((a, b) =>
        //     a.voucher_date < b.voucher_date ? -1 : 1
        //   )
        // );
        // if (this.creditledgerData) {
        //   this.calculateCreditVouchersData();
        // }
      });

      this.service
        .getAllCreditVouchersData(this.startDate, this.endDate, this.bank)
        .subscribe((res) => {
          // console.log(res);
          this.creditledgerData = res;
          this.creditledgerData.map((a) => {
            // this.previousbaki = this.previousbaki + parseInt(a.deposit_amt);
            this.creditdebitledgerData.push(a);
          });
          // console.log(
          //   this.creditdebitledgerData.sort((a, b) =>
          //     a.voucher_date < b.voucher_date ? -1 : 1
          //   )
          // );
          // if (this.creditledgerData) {
          //   this.calculateCreditVouchersData();
          // }
        });

      this.service
        .getAllDebitVouchersData(this.startDate, this.endDate, this.bank)
        .subscribe((res) => {
          // console.log(res);
          this.debitledgerData = res;
          this.debitledgerData.map((a) => {
            // this.previousbaki = this.previousbaki - parseInt(a.deposit_amt);
            this.creditdebitledgerData.push(a);
          });
          // console.log(
          //   this.creditdebitledgerData.sort((a, b) =>
          //     a.voucher_date < b.voucher_date ? -1 : 1
          //   )
          // );
          // if (this.debitledgerData) {
          //   this.calculateDebitVouchersData();
          // }
        });
    } else if (
      this.startDate !== '' &&
      this.endDate !== '' &&
      this.bank === ''
    ) {
      this.service
        .getAllCreditVouchersData(this.startDate, this.endDate, 'xyz')
        .subscribe((res) => {
          // console.log(res);
          this.creditledgerData = res;
          this.creditledgerData.map((a) => {
            this.creditdebitledgerData.push(a);
          });
          // console.log(
          //   this.creditdebitledgerData.sort((a, b) =>
          //     a.voucher_date < b.voucher_date ? -1 : 1
          //   )
          // );
          // if (this.creditledgerData) {
          //   this.calculateCreditVouchersData();
          // }
        });

      this.service
        .getAllDebitVouchersData(this.startDate, this.endDate, 'xyz')
        .subscribe((res) => {
          // console.log(res);
          this.debitledgerData = res;
          this.debitledgerData.map((a) => {
            this.creditdebitledgerData.push(a);
          });
          // console.log(
          //   this.creditdebitledgerData.sort((a, b) =>
          //     a.voucher_date < b.voucher_date ? -1 : 1
          //   )
          // );
          // if (this.debitledgerData) {
          //   this.calculateDebitVouchersData();
          // }
        });
        
    } else if (
      this.startDate === '' &&
      this.endDate === '' &&
      this.bank !== ''
    ) {
      this.service.readBankwiseOpeningBalance(this.bank).subscribe((res) => {
        
        this.bankwiseData = res;
        this.previousbaki = parseInt(this.bankwiseData['opening_bal']);
        this.bankname = this.bankwiseData['sub_exp_name'];

      });
      

      this.service
        .getAllCreditVouchersData('abc', 'pqr', this.bank)
        .subscribe((res) => {
          // console.log(res);
          this.creditledgerData = res;
          this.creditledgerData.map((a) => {
            // this.previousbaki = this.previousbaki + parseInt(a.deposit_amt);
            this.creditdebitledgerData.push(a);
          });
          // console.log(
          //   this.creditdebitledgerData.sort((a, b) =>
          //     a.voucher_date < b.voucher_date ? -1 : 1
          //   )
          // );
          // if (this.creditledgerData) {
          //   this.calculateCreditVouchersData();
          // }
        });

      this.service
        .getAllDebitVouchersData('abc', 'pqr', this.bank)
        .subscribe((res) => {
          // console.log(res);
          this.debitledgerData = res;
          this.debitledgerData.map((a) => {
            // this.previousbaki = this.previousbaki - parseInt(a.deposit_amt);
            this.creditdebitledgerData.push(a);
          });
          // console.log(
          //   this.creditdebitledgerData.sort((a, b) =>
          //     a.voucher_date < b.voucher_date ? -1 : 1
          //   )
          // );
          // if (this.debitledgerData) {
          //   this.calculateDebitVouchersData();
          // }
        });

      // console.log(this.bank);
    }
    setTimeout(() => {
      // console.log(this.creditdebitledgerData);
      let data = this.creditdebitledgerData.sort((a, b) =>
        a.voucher_date < b.voucher_date ? -1 : 1
      );

      this.creditdebitledgerData = [];

      data.map((a) => {
        if (a.type=='Cr' ) {
          this.previousbaki = this.previousbaki + parseFloat(a.deposit_amt);
        } else if (a.type=='Dr' ) {
          this.previousbaki = this.previousbaki - parseFloat(a.deposit_amt);
        } else if ( a.type=='contra') {
          this.previousbaki = this.previousbaki - parseFloat(a.deposit_amt);
        }
        this.creditdebitledgerData.push({
          ...a,
          total: this.previousbaki,
          type:a.type=='Dr'? 'CR':'DR'
          
        });
      });
      // console.log(this.creditdebitledgerData);
      this.calculatecrdtdebtVouchersData();
    }, 1000);
  }

  // calculateCreditVouchersData() {
  //   for (let i = 0; i < this.creditledgerData.length; i++) {
  //     this.debittotal =
  //       this.debittotal + parseInt(this.creditledgerData[i]['deposit_amt']);
  //     this.nextbaki = this.debittotal - this.credittotal;
  //   }
  // }

  // calculateDebitVouchersData() {
  //   for (let i = 0; i < this.debitledgerData.length; i++) {
  //     this.credittotal =
  //       this.credittotal + parseInt(this.debitledgerData[i]['deposit_amt']);
  //     this.nextbaki = this.debittotal - this.credittotal;
  //   }
  // }

  calculatecrdtdebtVouchersData() {
    // this.debittotal = 0;
    // this.credittotal = 0;
    if (this.bankwiseData['crdt_debt'] === 'Cr') {
      this.credittotal += parseFloat(this.bankwiseData['opening_bal']);
    } else if (this.bankwiseData['crdt_debt'] === 'Dr') {
      this.debittotal += parseFloat(this.bankwiseData['opening_bal']);
    }
    for (let i = 0; i < this.creditdebitledgerData.length; i++) {
      if (this.creditdebitledgerData[i].voucher_no.indexOf('CR') === 0) {
        this.debittotal =
          this.debittotal +
          parseFloat(this.creditdebitledgerData[i]['deposit_amt']);
      } else if (this.creditdebitledgerData[i].voucher_no.indexOf('DR') === 0) {
        this.credittotal =
          this.credittotal +
          parseFloat(this.creditdebitledgerData[i]['deposit_amt']);
      }
    }
    this.nextbaki = this.debittotal - this.credittotal;
  }

  toInt(amt) {
    return parseInt(amt);
  }
}
